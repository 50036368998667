import PigPercentSVG from "../../../assets/svg/PigPercent";
import PoorPig from "../../../assets/svg/PoorPig";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import TimerSVG from "../../../assets/svg/Timer";
import { useTranslation } from "react-i18next";
import {
  HouseStatusType,
  PigFaceType,
} from "../../../pages/house-dashboard/house-dashboard.type";
import GoodPig from "../../../assets/svg/GoodPig";
import MediumPig from "../../../assets/svg/MediumPig";

export default function DashboardPigStatus({
  pigFaceEmotion,
  edibleStatus,
  healthStatus,
  losePercentStatus,
  losePercent,
}: any) {
  const { t } = useTranslation();

  //
  const tailwindColor = mapStatusToColor(pigFaceEmotion);

  function getPigImageForStatus(pigFaceEmotion: PigFaceType) {
    if (pigFaceEmotion === "GOOD") {
      return <GoodPig />;
    } else if (pigFaceEmotion == "AVERAGE") {
      return <MediumPig />;
    } else {
      return <PoorPig />;
    }
  }

  function getPigTextForStatus(pigFaceEmotion: PigFaceType) {
    if (pigFaceEmotion === "GOOD") {
      return t("houseDashboard.SMILE");
    } else if (pigFaceEmotion == "AVERAGE") {
      return t("houseDashboard.NONE");
    } else {
      return t("houseDashboard.SAD");
    }
  }

  function mapStatusToColor(status: HouseStatusType) {
    switch (status) {
      case "GOOD":
        return "secondary";
      case "AVERAGE":
        return "warning";
      case "POOR":
        return "error";
      default:
        return "error";
    }
  }

  function mapStatusToColorTailwind(status: HouseStatusType) {
    switch (status) {
      case "GOOD":
        return "text-secondary";
      case "AVERAGE":
        return "text-warning";
      case "POOR":
        return "text-error";
      default:
        return "text-error";
    }
  }
  return (
    <div className="flex grow ">
      <div className="flex flex-col mr-8 pt-2 gap-2">
        {getPigImageForStatus(pigFaceEmotion)}
        <h4
          className={`text-2xl text-center font-medium ${mapStatusToColorTailwind(
            pigFaceEmotion
          )}`}
        >
          {getPigTextForStatus(pigFaceEmotion)}
        </h4>
      </div>
      <div className="flex grow flex-col gap-2">
        <div className="flex grow justify-between px-4 py-2 rounded-3xl items-center bg-[#DDF3E4]">
          <p className="text-primary font-semibold">
            {t("houseDashboard.edible")}
          </p>

          <Brightness1Icon color={mapStatusToColor(edibleStatus)} />
        </div>
        <div className="flex grow justify-between px-4 py-2 rounded-3xl bg-[#DDF3E4]">
          <p className="text-primary font-semibold">
            {t("houseDashboard.health")}
          </p>
          <Brightness1Icon color={mapStatusToColor(healthStatus)} />
        </div>
        <div className="flex grow justify-between px-4 py-2 rounded-3xl bg-[#DDF3E4]">
          <p className="text-primary font-semibold">
            {t("houseDashboard.losePercent")}
          </p>
          <p className="text-primary">{losePercent}</p>
          <Brightness1Icon color={mapStatusToColor(losePercentStatus)} />
        </div>
      </div>
    </div>
  );
}

function UploadFileIcon() {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.66797 15.1673L3.66797 16.084C3.66797 17.6028 4.89919 18.834 6.41797 18.834L15.5846 18.834C17.1034 18.834 18.3346 17.6028 18.3346 16.084L18.3346 15.1673M14.668 7.83398L11.0013 4.16732M11.0013 4.16732L7.33464 7.83398M11.0013 4.16732L11.0013 15.1673"
        stroke="#B0B0B0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default UploadFileIcon;

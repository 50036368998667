import { useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { getProfileFarmer } from "../../services/AccountCenter.service";

export const useAccountCenterController = () => {
  const [isShowEditAccountModal, setIsShowEditAccountModal] = useState(false);

  const onShowEditAccountModal = useCallback(() => {
    setIsShowEditAccountModal(true);
  }, []);

  const onHideEditAccountModal = useCallback(() => {
    setIsShowEditAccountModal(false);
  }, []);

  const { data: profileFarmer } = useQuery({
    queryKey: ["get_profile_farmer"],
    queryFn: async () => {
      const result = await getProfileFarmer();
      return result;
    },
  });

  return {
    isShowEditAccountModal,
    onShowEditAccountModal,
    onHideEditAccountModal,
    profileFarmer,
  };
};

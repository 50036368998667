import { TEditHistory } from "./OrderHistory.type";

export interface IGetListUpcomingClaimOrder {
  success: boolean;
  message: string;
  result: {
    count: number;
    rows: IDataClaimOrder[];
  };
}

interface IOrderFeedItems {
  id: string;
  feedAmount: number;
  totalItemPrice: number;
  feedPricePerUnit: number;
  feedBrand: {
    id: string;
    nameTh: string;
    nameEn: string;
  };
  feedBrandItem: {
    id: string;
    skuTh: string;
    skuEn: string;
    unit: "bag" | "kg";
    pricePerUnit: number;
  };
}

interface IProvince {
  code: number;
  nameTh: string;
  nameThShort: string;
  nameEn: string;
}
interface IDistrict {
  code: number;
  nameTh: string;
  nameEn: string;
}
interface ISubDistrict {
  code: number;
  zipCode: number;
  nameTh: string;
  nameEn: string;
}
interface IFarmer {
  id: string;
  registrationId: string;
  name: string;
  surname: string;
}
interface IFarm {
  id: string;
  name: string;
  latitude: string;
  longitude: string;
  address: string;
  postalCode: string;
  province: IProvince;
  district: IDistrict;
  subDistrict: ISubDistrict;
  farmer: IFarmer;
}
interface IHouse {
  id: string;
  farm: IFarm;
}

interface IConTract {
  id: string;
  credit: number;
  debt: string;
  contractNumber: string;
  creditRating: string;
  exceedAmount: number;
  newOrderAmount: number;
  creditUsed: number;
  creditUsedPercentage: number;
  creditTerm: number;
  dayOfDebtOutStanding: number;
  house: IHouse;
}

export interface IDataClaimOrder extends TOrderClaim {
  id: string;
  status: string;
  orderNumber: string;
  shippingDate: Date;
  createdAt: Date;
  totalFeedPrice: number;
  actualShippingDate: Date;

  orderFeedItems: IOrderFeedItems[];

  contract: IConTract;
  farmSize: string;
  orderFeedLogs: TEditHistory[];
  orderFeedFiles: ItemFiles[];
  orderClaimItems: TOrderClaimItem[];
  partnerId: string;
  partnerCode: string;
  partnerName: string;
  partnerSurname: string;
  partnerAddress: {
    address: string;
    province: IProvince;
    district: IDistrict;
    subDistrict: ISubDistrict;
  };
  sellerName: string;
  sellerSurname: string;
}

export enum EOrderFeedClaimStatus {
  Created = "created",
  Processing = "processing",
  Approved = "approved",
  Rejected = "rejected",
}

export enum EOrderFeedClaimRejectOption {
  InvalidReason = "invalid_reason",
}

export enum EClaimOrderOption {
  DeliverProduct = "deliver_product",
  RefundCredit = "refund_credit",
  AddDebt = "add_debt",
}

export enum EOrderFeedClaimReason {
  FeedDamaged = "feed_damaged",
  FeedLowQuality = "feed_low_quality",
  FeedNotMatchWithOrder = "feed_not_match_with_order",
}

export type TOrderClaim = {
  orderClaimItems: TOrderClaimItem[];
  orderClaimStatus: EOrderFeedClaimStatus;
  orderClaimRejectReason: string;
  orderClaimRejectOption: EOrderFeedClaimRejectOption;
  claimUpdatedBy: TClaimUpdatedBy;
};

export type TClaimUpdatedBy = {
  id: string;
  email: string;
  userRole: string;
  name: string;
};

export type TOrderClaimItem = {
  id: string;
  reportAmount: number;
  reportReason: EOrderFeedClaimReason;
  reportDetail: string;
  claimOption: EClaimOrderOption;
  claimRefundAmount: number;
  remark: string;
  claimCode: string;
  orderClaimItemFiles: ItemFiles[];
  orderFeedItem: TOrderFeedItem;
  isDeliverOrderFeed: boolean;
  newOrderFeed: { id: string; orderNumber: string };
};

export type ItemFiles = {
  id: string;
  fileName: string;
  fileSize: number;
};

export type TOrderFeedItem = {
  id: string;
  feedAmount: number;
  totalItemPrice: number;
  feedPricePerUnit: number;
  feedBrand: TFeedBrand;
  feedBrandItem: FeedBrandItem;
};

export type TFeedBrand = {
  id: string;
  nameTh: string;
  nameEn: string;
};

export type FeedBrandItem = {
  id: string;
  skuTh: string;
  skuEn: string;
  unit: "bag" | "kg";
  pricePerUnit: number;
};

export interface IListOrder {
  success: boolean;
  message: string;
  orderFeeds: IOrderFeeds[];
}

interface IOrderFeeds {
  id: string;
  orderNumber: string;
  totalFeedPrice: number;
  farmerId: string;
  name: string;
  surname: string;
  registrationId: string;
}

export interface IGetExportClaim {
  success: boolean;
  message: string;
  rows: RowExportClaim[];
}

export interface RowExportClaim {
  หมายเลขสั่งซื้อ: string;
  รหัสลูกค้า: string;
  ชื่อลูกค้า: string;
  วันที่จัดส่งสินค้า: string;
  วันที่สั่งสินค้า: Date;
  พนักงานขาย: string;
  รหัสพาร์ทเนอร์: string;
  ชื่อพาร์ทเนอร์: string;
  สถานะ: string;
  เลขที่สัญญา: string;
  ที่อยู่ลูกค้า: string;
  ที่อยู่พาร์ทเนอร์: string;
  วงเงินลูกค้า: string;
  จำนวนวงเงินที่ใช้ไป: string;
  จำนวนเงินที่เกินวงเงิน: string;
  วงเงินเครดิตคงเหลืิอ: string;
  ระยะเครดิต: string;
  เกรดลูกค้า: string;
  ราคารวมทั้งหมด: string;
  แบรนด์อาหารสัตว์: string;
  เบอร์อาหารสัตว์: string;
  จำนวน: string;
  จำนวนที่รายงานปัญหา: string;
  เหตุผล: string;
  รายละเอียดเหตุผล: string;
  "DO Number": string;
  "CN Number": string;
  "DN Number": string;
  ตัวเลือก: string;
  Remark: string;
  จำนวนเงิน: string;
  เลขที่ออเดอร์: string;
  เหตุผลที่ไม่อนุมัติ: string;
  รายละเอียดการไม่อนุมัติ: string;
}

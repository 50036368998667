import { TDropdownValue } from "../../../../group-area/views/Dropdown";

export type TFormTabHistory = {
  grade: TDropdownValue[];
  statusCredit: TDropdownValue[];
};

export const defaultFormTabHistory: TFormTabHistory = {
  grade: [],
  statusCredit: [],
};

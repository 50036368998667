export default function DocumentWithBgSvg() {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="40" height="40" rx="20" fill="#F57C00" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2969 12.8004C13.2969 11.4749 14.3714 10.4004 15.6969 10.4004H21.1998C21.8363 10.4004 22.4468 10.6532 22.8969 11.1033L26.9939 15.2004C27.444 15.6505 27.6969 16.2609 27.6969 16.8974V27.2004C27.6969 28.5259 26.6224 29.6004 25.2969 29.6004H15.6969C14.3714 29.6004 13.2969 28.5259 13.2969 27.2004V12.8004ZM15.6969 20.0004C15.6969 19.3376 16.2341 18.8004 16.8969 18.8004H24.0969C24.7596 18.8004 25.2969 19.3376 25.2969 20.0004C25.2969 20.6631 24.7596 21.2004 24.0969 21.2004H16.8969C16.2341 21.2004 15.6969 20.6631 15.6969 20.0004ZM16.8969 23.6004C16.2341 23.6004 15.6969 24.1376 15.6969 24.8004C15.6969 25.4631 16.2341 26.0004 16.8969 26.0004H24.0969C24.7596 26.0004 25.2969 25.4631 25.2969 24.8004C25.2969 24.1376 24.7596 23.6004 24.0969 23.6004H16.8969Z"
        fill="white"
      />
    </svg>
  );
}

import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  CartesianGrid,
  Label,
  LabelList,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import deadPigIcon from "../../../assets/images/HousePerformance/deadPigIcon.png";
import PigLossIcon from "../../../assets/images/HousePerformance/pigLoss.png";
import StandardLine from "../../../assets/images/HousePerformance/standardLine.png";
import TodayLine from "../../../assets/images/HousePerformance/todayLine.png";
import { getPerformanceDetailsService } from "../../../services/AuthService";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { IconButton } from "@mui/material";
import DateRangePickers from "../DatePicker/DateRangePicker";
const PerformanceGraph = ({
  houseId,
  standardEdible,
  houseStartDate,
  allEdible,
  pigStartAge,
}: any) => {
  dayjs.locale("en-us");
  const { t } = useTranslation();
  const [data, setData] = useState<any>([]);
  const [totalDeadPigs, setTotaldeadPigs] = useState(0);
  const [openCalender, setOpenCalender] = useState(false);
  const currentDate = dayjs().format("DD/MM");
  const [startDate, setStartDate] = useState<any>(houseStartDate);
  const [endDate, setEndDate] = useState<any>(dayjs());
  const handleSelect = (dateRange: any[]) => {
    const formattedStartDate = dayjs(dateRange[0].format("MM-DD-YY")).format(
      "MM-DD-YY"
    );
    const formattedEndDate = dayjs(dateRange[1].format("MM-DD-YY")).format(
      "MM-DD-YY"
    );
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  };
  const language = localStorage.getItem("i18nextLng");

  useEffect(() => {
    if (allEdible.length > 0) getDetails();
  }, [startDate, endDate, allEdible, pigStartAge]);

  const getDetails = async () => {
    try {
      const resp: any = await getPerformanceDetailsService(
        houseId,
        dayjs(startDate.toISOString()).format("MM-DD-YY"),
        dayjs(endDate.toISOString()).format("MM-DD-YY")
      );
      const emp: any = [
        {
          logDate: "",
          deadPigs: 0,
          feedGiven: 0,
          standardEdible: 0,
        },
      ];
      if (resp.data.success) {
        let tempData = resp?.data?.housePerformance?.dailyLogs || [];
        for (
          let i = 0;
          i <=
          dayjs(endDate.toISOString()).diff(
            dayjs(startDate.toISOString()),
            "d"
          );
          i++
        ) {
          const date = dayjs(startDate.toISOString()).add(i, "day");
          const diff = date.diff(houseStartDate.toISOString(), "d");
          const pigAge = pigStartAge + Math.ceil(diff / 7);
          let standard = allEdible.find((item: any) => item.pigAge === pigAge);

          emp.push({
            logDate: date.format("DD/MM"),
            feedGiven: 0,
            deadPigs: 0,
            standardEdible: standard.edible ? standard.edible : 0,
          });
        }
        if (tempData?.length > 0)
          tempData.forEach((item: any, i: number) => {
            tempData[i].logDate = dayjs(tempData[i].logDate).format("DD/MM");
            const index = emp.findIndex(
              (log: { logDate: any }) => log.logDate == tempData[i].logDate
            );
            const standard = emp[index].standardEdible;
            emp[index] = tempData[i];
            emp[index].standardEdible = standard;
          });
        emp[0].standardEdible = emp[1].standardEdible;
        setData(emp);
        if (totalDeadPigs === 0)
          setTotaldeadPigs(
            tempData.reduce((acc: number, value: { deadPigs: any }) => {
              return acc + Number(value.deadPigs);
            }, 0)
          );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenCalender = (event: any) => {
    event?.stopPropagation();
    setOpenCalender(!openCalender);
  };

  const handleCloseCalender = (event: any) => {
    event?.stopPropagation();
    setOpenCalender(false);
  };
  const handleCalenderSave = (event: any, value: any) => {
    event?.stopPropagation();
    const formattedStartDate = dayjs(value[0].startDate);
    const formattedEndDate = dayjs(value[0].endDate);
    if (Date.parse(value[0].startDate) <= Date.parse(value[0].endDate)) {
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
      /*
            setStartDate(dayjs(value[0].startDate).add(-6,'day').format('MM-DD-YY'))
            setEndDate(formattedStartDate)*/
    } else {
      /*
            setStartDate(formattedEndDate)
            setEndDate(dayjs(value[0].endDate).add(-6,'day').format('MM-DD-YY'))
            */
      setStartDate(formattedEndDate);
      setEndDate(formattedStartDate);
    }
    setOpenCalender(false);
  };
  const CustomTopTick = ({ x = 0, y = 0, index }: any) => {
    return (
      <>
        {data[index]?.deadPigs !== null && data[index]?.deadPigs !== 0 ? (
          <g transform={`translate(${x},${y})`} className="bg-[#FDF5F5]">
            <image xlinkHref={deadPigIcon} x={-15} y={-15} />
            <text
              x={0}
              y={0}
              dy={-30}
              fill="#777"
              textAnchor="middle"
              className="text-[12px] font-[600]"
            >
              {data[index]?.deadPigs}
            </text>
            <text
              x={0}
              y={0}
              dy={-20}
              fill="#777"
              textAnchor="middle"
              className="text-[12px] font-[600]"
            >
              {"(" + data[index]?.logDate + ")"}
            </text>
          </g>
        ) : (
          ""
        )}
      </>
    );
  };
  return (
    <div>
      <div className="bg-[#ffffff] rounded-[10px] px-[16px] py-[8px]">
        <div className="flex justify-between items-center">
          <p className="text-[#34407B] text-[16px] font-[600]">
            {language === "th"
              ? dayjs(startDate.toISOString())
                  .add(543, "year")
                  .format("DD/MM/YY")
              : dayjs(startDate.toISOString()).format("DD/MM/YY")}
            -{" "}
            {language === "th"
              ? dayjs(endDate.toISOString()).add(543, "year").format("DD/MM/YY")
              : dayjs(endDate.toISOString()).format("DD/MM/YY")}
          </p>
          <IconButton onClick={(e: any) => handleOpenCalender(e)}>
            <CalendarMonthIcon className="text-[#34407B]" />
          </IconButton>
        </div>
        <div className="flex justify-between">
          <DateRangePickers
            handleSelect={handleSelect}
            openCalender={openCalender}
            handleCloseCalender={handleCloseCalender}
            handleCalenderSave={handleCalenderSave}
          />
        </div>
        <div className="flex items-center p-[5px]">
          <div className="w-[30px] h-[31px] bg-[#FDF5F5] flex items-center justify-center rounded-[10px]">
            <img
              src={PigLossIcon}
              className="h-[11px] w-[14px]"
              alt="Pig Loss"
            />
          </div>
          <p className="text-[12px] text-[#DC5B5B] font-normal  ml-[10px]">
            {t("housePerformanceGraph.pigLossLabel")} :{" "}
          </p>
          <p className="text-[14px] text-[#DC5B5B] font-bold ml-[10px]">
            {totalDeadPigs}
          </p>
        </div>
      </div>
      {data.length > 0 && (
        <div className="bg-[#f8fbf9] mt-[12px] rounded-[10px]">
          <div className="bg-[#FDF5F5] pt-[10px] rounded-[10px]">
            <ResponsiveContainer className="" width="95%" aspect={5}>
              <LineChart
                width={328}
                height={64}
                data={data}
                syncId="test"
                margin={{ top: 20 }}
              >
                <XAxis
                  xAxisId="1"
                  axisLine={{ stroke: "#FDF5F5" }}
                  tickLine={false}
                  orientation="top"
                  dataKey="deadPigs"
                  style={{ fontSize: "12px" }}
                  tick={<CustomTopTick index={indexedDB} />}
                  width={10}
                  className="bg-[#FDF5F5]"
                >
                  <Label
                    position={{ x: -10, y: 0 }}
                    style={{
                      fill: "#D32F2F",
                      fontSize: "10px",
                      display: "flex",
                      wordWrap: "break-word",
                      background: "#D32F2F",
                      padding: "2px 5px",
                      borderRadius: "5px",
                    }}
                  >
                    {t("farmDashboard.xgraphvalue")}
                  </Label>
                  <Label
                    position={{ x: -7, y: 15 }}
                    style={{
                      fill: "#D32F2F",
                      fontSize: "10px",
                      display: "flex",
                      wordWrap: "break-word",
                      background: "#D32F2F",
                      padding: "2px 5px",
                      borderRadius: "5px",
                    }}
                  >
                    {t("farmDashboard.xgraphdeath")}
                  </Label>
                  <Label
                    position={{ x: -6, y: 30 }}
                    style={{
                      fill: "#D32F2F",
                      fontSize: "10px",
                      display: "flex",
                      wordWrap: "break-word",
                      background: "#D32F2F",
                      padding: "2px 5px",
                      borderRadius: "5px",
                    }}
                  >
                    {t("farmDashboard.xgraphtotal")}
                  </Label>
                </XAxis>
                <YAxis width={45} />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className="rounded-[10px] mb-[-10px] ">
            <ResponsiveContainer className="" width="95%" aspect={6}>
              <LineChart width={328} height={54} data={data} syncId="test">
                <YAxis
                  width={45}
                  axisLine={{ stroke: "#DDE4FF" }}
                  strokeDasharray="5 5"
                  tick={{ fill: "#f8fbf9" }}
                  style={{ fontSize: "12px" }}
                  dataKey="feedGiven"
                  tickLine={false}
                  label={
                    <Label
                      value={t("housePerformanceGraph.graphkg")!}
                      className="text-[8px] fill-[#68C184] font-[600]"
                      position={{ x: 35, y: 10 }}
                    />
                  }
                  domain={[0, "auto"]}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <ResponsiveContainer className="" width="95%" aspect={1.5}>
            <LineChart width={328} height={280} data={data} syncId="test">
              <XAxis
                dataKey="logDate"
                height={60}
                axisLine={{ stroke: "#DDE4FF" }}
                tick={{ fill: "#5572EB" }}
                strokeDasharray="3 3"
                tickLine={false}
                tickMargin={10}
                style={{ fontSize: "12px" }}
                xAxisId="0"
              >
                <Label
                  position={{ x: -20, y: 25 }}
                  className="text-[12px] fill-[#5572EB] font-[600]"
                >
                  {t("housePerformanceGraph.graphdate")}
                </Label>
              </XAxis>
              <YAxis
                tickLine={false}
                width={45}
                axisLine={{ stroke: "#DDE4FF" }}
                strokeDasharray="5 5"
                dataKey="feedGiven"
                tick={{ fill: "#68C184" }}
                tickMargin={10}
                style={{ fontSize: "12px" }}
              ></YAxis>
              <ReferenceLine
                x={currentDate}
                stroke="#34407B"
                strokeDasharray="4 4"
              />
              <Line
                style={{ fontSize: "12px" }}
                dataKey="feedGiven"
                stroke="#5572EB"
                dot={false}
              >
                {/* <LabelList position="bottom" offset={10} /> */}
              </Line>
              <Line
                dataKey="standardEdible"
                stroke="green"
                strokeDasharray="4 4"
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
          <div className="pb-[10px]">
            <div className="flex justify-evenly">
              <div className="flex flex-row ">
                <div className="h-[20px] w-[20px] rounded-[50%] bg-[#68C184] mr-[5px]"></div>
                <p className="text-[14px] font-normal">
                  {t("housePerformanceGraph.edibleAmountLabel")}
                </p>
              </div>
              <div className="flex flex-row ">
                <div className="h-[20px] w-[20px] rounded-[50%] bg-[#5572EB] mr-[5px]"></div>
                <p className="text-[14px] font-normal">
                  {t("housePerformanceGraph.dateLabel")}
                </p>
              </div>
            </div>
            <div className="flex  justify-evenly">
              <div className="flex flex-row items-center">
                <img
                  src={StandardLine}
                  className="h-[10px] w-[20px] mr-[5px]"
                />
                <p className="text-[14px] font-normal">
                  {t("housePerformanceGraph.standardLineLabel")}
                </p>
              </div>
              <div className="flex flex-row items-center ">
                <img src={TodayLine} className="h-[10px] w-[20px] mr-[5px]" />

                <p className="text-[14px] font-normal">
                  {t("housePerformanceGraph.todayLineLabel")}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default PerformanceGraph;

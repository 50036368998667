import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Backdrop,
  Box,
  Fade,
  IconButton,
  Modal,
  SxProps,
  Theme,
} from "@mui/material";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ListOfFile from "../../../../../components/common/File/ListOfFile";
import ListOfUploadedFile2 from "../../../../../components/common/File/ListOfUploadedFile2";
import { alertAction } from "../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../../../redux/store";
import {
  editStockRefillDocuments,
  getUploadUrlStockRefill,
} from "../../../../../services/ManageStock.service";
import getErrorMessage from "../../../../../utils/getErrorMessage";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import DragAndDropUploadImage from "../../../../components/Input/DragAndDropUploadImage";

const style: SxProps<Theme> = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "840px",
  bgcolor: "background.paper",
  boxShadow: 12,
  padding: "24px",
  borderRadius: "12px",
};

interface IPartnerImportDocumentModalProps {
  open: boolean;
  onClose: () => void;
  // onSubmit: (partnerPickingSlip: File[]) => void;
  partnerId: string;
  initialFile: {
    id: string;
    fileName: string;
    fileSize: number;
    documentType: string;
  }[];
  stockId: string;
  refetch: () => void;
  onDownloadInitialFile: (
    partnerId: string,
    stockRefillId: string,
    fileId: string,
    name: string,
    documentType: string
  ) => () => Promise<void>;
}

function PartnerImportDocumentModal({
  open,
  onClose,
  partnerId,
  stockId,
  refetch,
  initialFile,
  onDownloadInitialFile,
}: IPartnerImportDocumentModalProps) {
  const [partnerPickingSlip, setPartnerPickingSlip] = useState<File[]>([]);
  const dispatch = useDispatch<AppDispatch>();

  const handleResetDocument = () => {
    setPartnerPickingSlip([]);
  };

  const handleDownload = useCallback(
    async (file: File) => {
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${file.name}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    },
    [partnerPickingSlip]
  );

  const onSubmit = useCallback(() => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          const fileArray = [];
          try {
            dispatch(loadingActions.show({ message: "กำลังบันทึกข้อมูล..." }));
            // upload image
            const presign = await getUploadUrlStockRefill(
              partnerId,
              stockId,
              "salePickingSlip"
            );
            console.log("first", partnerPickingSlip[0]);
            await axios.put(presign.blob.blobUrl, partnerPickingSlip[0], {
              responseType: "blob",
              headers: {
                "Content-Type": partnerPickingSlip[0].type,
                "x-ms-blob-type": "BlockBlob",
              },
            });
            fileArray.push({
              id: presign.blob.fileId,
              fileName: partnerPickingSlip[0].name,
              fileSize: partnerPickingSlip[0].size,
              documentType: "salePickingSlip",
            });
            editStockRefillDocuments({
              partnerId: partnerId,
              stockRefillId: stockId,
              documents: fileArray,
            });
            setTimeout(() => {
              dispatch(alertAction.hide());
              dispatch(loadingActions.hide());
              dispatch(
                snakeActions.showMessage({
                  message: "บันทึกสำเร็จ",
                  type: "success",
                })
              );
              onCloseFn();
            }, 500);
          } catch (error) {
            setTimeout(() => {
              dispatch(alertAction.hide());
              dispatch(loadingActions.hide());
              dispatch(
                snakeActions.showMessage({
                  message: getErrorMessage(error),
                  type: "error",
                })
              );
            }, 500);
          }
        },
      })
    );
  }, [partnerPickingSlip]);

  const onCloseFn = useCallback(() => {
    onClose();
    refetch();
  }, []);

  useEffect(() => {
    handleResetDocument();
  }, [open]);

  return (
    <>
      <Modal
        aria-labelledby="import-document-modal-title"
        aria-describedby="import-document-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="w-full h-full">
              <div className="flex justify-between items-center">
                <span className="font-sans font-bold text-[1rem] text-[#191919]">
                  นำเข้าเอกสาร
                </span>
                <IconButton
                  aria-label="close-modal"
                  size="small"
                  onClick={onClose}
                >
                  <CloseRoundedIcon
                    sx={{
                      fontSize: "24px",
                      color: "#191919",
                    }}
                  />
                </IconButton>
              </div>
              <div className="flex flex-col mt-6">
                <p className="text-[#3777BC] font-bold text-[16px] mb-6">
                  พาร์ทเนอร์
                </p>
                <p className="font-bold text-[14px]">ใบ Picking Slip</p>
                <div className="flex flex-col gap-2 ml-2 my-4">
                  <ListOfFile
                    files={partnerPickingSlip}
                    onDownload={handleDownload}
                    onDelete={async (idx) => {
                      setPartnerPickingSlip([]);
                    }}
                  />
                  {partnerPickingSlip.length === 0 && (
                    <ListOfUploadedFile2
                      files={initialFile}
                      fileKey={"fileName"}
                      onDownload={(row) =>
                        onDownloadInitialFile(
                          partnerId,
                          stockId,
                          row.id,
                          row.fileName,
                          "salePickingSlip"
                        )()
                      }
                    />
                  )}
                </div>
                <DragAndDropUploadImage
                  onUpload={(files) => {
                    setPartnerPickingSlip(files);
                  }}
                  type={{ "application/pdf": [".pdf"] }}
                />
              </div>
              <div className="flex w-full justify-end gap-2 pt-6">
                <ButtonFillCustom title="ยืนยัน" onClick={onSubmit} />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* <CommonConfirmModal
        header={"ยืนยันการบันทึก"}
        body={"คุณต้องการบันทึกรายการนี้ใช่หรือไม่"}
        cancelBtnText={"กลับไปแก้ไข"}
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        onSubmit={() => {
          setOpenConfirmModal(false);
          onSubmit(partnerPickingSlip);
        }}
      /> */}
    </>
  );
}
export default PartnerImportDocumentModal;

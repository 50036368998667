import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { router } from "./App";
import reportWebVitals from "./reportWebVitals";
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import "./styles/index.scss";

//import i18n
import "./i18n/i18n";

//import store for redux toolkit
import { datadogRum } from "@datadog/browser-rum";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import GlobalAlert from "./components/global/GlobalAlert";
import GlobalLoading from "./components/global/GlobalLoading";
import GlobalSnake from "./components/global/GlobalSnake";
import FeedingProvider from "./pages/add-house/feeding-program/Provider";
import DailyLogProvider from "./provider/DailyLogProvider";
import { store } from "./redux/store";
import { getCurrentLanguage } from "./services/language.service";
import { updateLocaleApp } from "./services/locale.service";
import { theme } from "./theme/light";
import { AppLocale } from "./types";

const queryClient = new QueryClient();

updateLocaleApp(getCurrentLanguage() as AppLocale);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (process.env.NODE_ENV === "production") {
  datadogRum.init({
    applicationId: `${
      process.env.REACT_APP_DATADOG_APP_ID ||
      "4878cdfb-9949-48d2-a62d-5a77848ffc86"
    }`,
    clientToken: `${
      process.env.REACT_APP_DATADOG_CLIENT_TOKEN ||
      "pubb4f0806eb68e463972abd5c3daa7c8f8"
    }`,
    // site refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: `${process.env.REACT_APP_DATADOG_SITE || "datadoghq.com"}`,
    service: `${process.env.REACT_APP_DATADOG_SERVICE || "farmsook-fe"}`,
    env: `${(window as any).REACT_APP_PUBLIC_NODE_ENV || "development"}`,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });
}

console.log(`React Public ENV: ${JSON.stringify(process.env, null, 2)}`);
console.log(
  `Current window.REACT_APP_PUBLIC_NODE_ENV: ${
    (window as any).REACT_APP_PUBLIC_NODE_ENV
  }`
);
root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <FeedingProvider>
          <DailyLogProvider>
            <RouterProvider router={router} />
            <GlobalAlert />
            <GlobalSnake />
            <GlobalLoading />
          </DailyLogProvider>
        </FeedingProvider>
      </Provider>
    </ThemeProvider>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register();

import { Params } from "react-router-dom";
import { getFarmerContactInfo } from "../../services/PartnerOrderService";

export const loaderContactFarmer = async (params: Params) => {
  const id = params["id"] || "";
  const result = await getFarmerContactInfo(id);
  return {
    mobileNumber: (result.data?.mobileNumber as string) || null,
    email: (result.data?.email as string) || null,
  };
};

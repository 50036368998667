const Clock = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={29} height={30} fill="none">
    <path
      fill="#3777BC"
      fillRule="evenodd"
      d="M14.498 26.598c6.407 0 11.6-5.193 11.6-11.6 0-6.406-5.193-11.6-11.6-11.6-6.406 0-11.6 5.194-11.6 11.6 0 6.407 5.194 11.6 11.6 11.6Zm1.45-17.4a1.45 1.45 0 0 0-2.9 0v5.8c0 .385.153.754.425 1.026l4.101 4.101a1.45 1.45 0 0 0 2.051-2.05l-3.677-3.677v-5.2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default Clock;

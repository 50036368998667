export default function ChatErrorSvg() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.4 12.5L10 9.9L12.6 12.5L14 11.1L11.4 8.5L14 5.9L12.6 4.5L10 7.1L7.4 4.5L6 5.9L8.6 8.5L6 11.1L7.4 12.5ZM0 20.5V2.5C0 1.95 0.195833 1.47917 0.5875 1.0875C0.979167 0.695833 1.45 0.5 2 0.5H18C18.55 0.5 19.0208 0.695833 19.4125 1.0875C19.8042 1.47917 20 1.95 20 2.5V14.5C20 15.05 19.8042 15.5208 19.4125 15.9125C19.0208 16.3042 18.55 16.5 18 16.5H4L0 20.5ZM3.15 14.5H18V2.5H2V15.625L3.15 14.5Z"
        fill="#68C184"
      />
    </svg>
  );
}

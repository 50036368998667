import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import get from "lodash/get";
import {
  editDocument,
  getDownloadUrlOrderPartner,
  getOrderDetail,
  getUploadUrlOrderPartner,
} from "../../../../services/PartnerOrderService";
import { dtoOrderList } from "../dto/dtoOrderList";
import { IOrderItemProps } from "../../views/OrderItem";
import axios from "axios";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { loadingActions } from "../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../utils/getErrorMessage";

export const useProveShippingController = (orderId: string, isEdit = false) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [images1, setImages1] = useState<
    (File | { id: string; url: string })[]
  >([]);
  const [images2, setImages2] = useState<
    (File | { id: string; url: string })[]
  >([]);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const [isShowConfirmEditImage, setIsShowConfirmEditImage] = useState(false);
  const [isShowSummary, setIsShowSummary] = useState(false);

  const initImage = useCallback(async (detail: IOrderItemProps) => {
    const imgs = get(detail, ["images"], []);
    const imagesPartnerProofOfDelivery = [];
    const imagesPartnerPickingSlip = [];
    if (imgs.length > 0) {
      if (imgs.length > 0) {
        for (let i = 0; i < imgs.length; i++) {
          try {
            const img = imgs[i];
            const preSignURL = await getDownloadUrlOrderPartner(
              detail?.orderNo,
              img.documentType,
              img.id
            );
            if (preSignURL) {
              const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
                responseType: "blob",
              });
              const objectURL = window.URL.createObjectURL(response.data);
              if (img.documentType === "partnerPickingSlip") {
                imagesPartnerPickingSlip.push({ id: img.id, url: objectURL });
              } else if (img.documentType === "partnerProofOfDelivery") {
                imagesPartnerProofOfDelivery.push({
                  id: img.id,
                  url: objectURL,
                });
              }
            }
          } catch (e) {
            console.log("error to fetch image <Maybe timeout> e:", e);
          }
        }
      }
    }
    setImages1(imagesPartnerProofOfDelivery);
    setImages2(imagesPartnerPickingSlip);
  }, []);

  const {
    data: orderDetail,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["order", "detail", orderId],
    enabled: !!orderId,
    queryFn: async () => {
      const result = await getOrderDetail(orderId);
      if (result) {
        const order = dtoOrderList({ orderFeeds: [result] } as any);
        isEdit && initImage(order[0]);
        return order[0];
      }
      return undefined;
    },
  });

  const onBack = useCallback(() => {
    if (orderId) {
      navigate(`/order/detail/${orderId}`);
    }
  }, [orderId]);

  const onShowConfirmShipped = useCallback(() => {
    setIsShowConfirmModal(true);
  }, []);

  const onCloseConfirmShipped = useCallback(() => {
    setIsShowConfirmModal(false);
  }, []);

  const onShowConfirmEditImage = useCallback(() => {
    setIsShowConfirmEditImage(true);
  }, []);

  const onCloseConfirmEditImage = useCallback(() => {
    setIsShowConfirmEditImage(false);
  }, []);

  const onShowSummary = useCallback(() => {
    onCloseConfirmShipped();
    setTimeout(() => {
      setIsShowSummary(true);
    }, 400);
  }, []);

  const onCloseSummary = useCallback(() => {
    setIsShowSummary(false);
  }, []);

  const onAddImage = useCallback(
    (type: "image1" | "image2") => (images: File[]) => {
      if (type === "image1") {
        const maxFile = 2;
        setImages1((prev) => [...images, ...prev].slice(0, maxFile));
      } else if (type === "image2") {
        const maxFile = 1;
        setImages2((prev) => [...images, ...prev].slice(0, maxFile));
      }
    },
    []
  );

  const onRemoveImage = useCallback(
    (type: "image1" | "image2", index: number) => () => {
      if (type === "image1") {
        setImages1((prev) => prev.filter((_, i) => i !== index));
      } else if (type === "image2") {
        setImages2((prev) => prev.filter((_, i) => i !== index));
      }
    },
    []
  );

  const onConfirmEdit = useCallback(async () => {
    const imgs = [...images1, ...images2];
    const fileArray: {
      id: string;
      url: string;
      fileName: string;
      fileSize: number;
      documentType:
        | "partnerProofOfDelivery"
        | "partnerPickingSlip"
        | "partnerPickingSlipForSale";
    }[] = [];
    dispatch(loadingActions.show({ message: "กำลังบันทึกรูปภาพ..." }));
    onCloseConfirmEditImage();
    try {
      for (let i = 0; i < imgs.length; i++) {
        const img = imgs[i];
        if (img instanceof File) {
          // upload new image
          const documentType =
            i < imgs.length - 1
              ? "partnerProofOfDelivery"
              : "partnerPickingSlip";
          const presign = await getUploadUrlOrderPartner(
            orderDetail!.orderNo,
            documentType
          );
          await axios.put(presign.blob.blobUrl, img, {
            responseType: "blob",
            headers: {
              "Content-Type": img.type,
              "x-ms-blob-type": "BlockBlob",
            },
          });
          fileArray.push({
            id: presign.blob.fileId,
            url: presign.blob.url || "",
            fileName: img.name,
            fileSize: img.size,
            documentType,
          });
        } else {
          const detail = (
            orderDetail?.images?.filter(
              (d) => d.documentType !== "partnerPickingSlipForSale"
            ) || []
          ).find(({ id }) => id === img.id);
          if (detail) {
            fileArray.push({
              id: detail.id,
              documentType: detail.documentType,
              fileName: detail.fileName,
              fileSize: detail.fileSize,
              url: detail.url,
            });
          }
        }
      }
      if (orderDetail) {
        editDocument({
          id: orderDetail.id,
          documents: fileArray.map((f) => ({
            fileId: f.id,
            fileName: f.fileName,
            fileSize: f.fileSize,
            documentType: f.documentType,
            url: f.url,
          })),
        });
      }
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: "แก้ไขหลักฐานการจัดส่งสำเร็จ",
            type: "success",
          })
        );
        navigate(`/order/detail/${orderId}`);
      }, 1500);
    } catch (e) {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(e),
            type: "error",
          })
        );
      }, 1500);
    }
  }, [images1, images2, orderDetail]);

  return {
    onBack,
    images1,
    setImages1,
    images2,
    setImages2,
    isShowConfirmModal,
    onShowConfirmShipped,
    onCloseConfirmShipped,
    isShowConfirmEditImage,
    onShowConfirmEditImage,
    onCloseConfirmEditImage,
    isShowSummary,
    onShowSummary,
    onCloseSummary,
    onRemoveImage,
    onAddImage,
    orderDetail,
    onConfirmEdit,
  };
};

import { Button, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { object, ref, string } from "yup";
import AppBarFS from "../../../../components/app-bar/AppBar";
import AuthBox from "../../../../components/common/AuthBox";
import Loader from "../../../../components/common/Loader";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../../redux/store";
import { partnerResetPINService } from "../../../../services/PartnerResetPinService";
import { formError, formErrorMessage } from "../../../../utils/form-validator";
import getErrorMessage from "../../../../utils/getErrorMessage";

export default function PartnerUpdatePin() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const formik = useFormik({
    initialValues: {
      pin: "",
      confirmPin: "",
    },
    validationSchema: object({
      pin: string()
        .trim()
        .min(4, "Minimum 4 digits")
        .max(4, "Maximum 4 digits")
        .matches(/^\d+$/, "Only digits allowed")
        .required("PIN is required"),
      confirmPin: string()
        .trim()
        .min(4, "Minimum 4 digits")
        .max(4, "Maximum 4 digits")
        .required("Confirm PIN is required")
        .matches(/^\d+$/, "Only digits allowed")
        .oneOf([ref("pin")], "PIN and Confirm PIN must match"),
    }),
    onSubmit: async ({ pin }) => {
      setLoading(true);
      try {
        await partnerResetPINService(
          state.token,
          state.mobileNumber,
          state.otpCode,
          pin
        );
        setLoading(false);
        navigate("/partner/reset-pin/success");
      } catch (error) {
        dispatch(
          snakeActions.showMessage({
            type: "error",
            message: getErrorMessage(error),
          })
        );
        setLoading(false);
      }
    },
  });

  const loaderComponent = () => {
    if (loading) {
      return <Loader />;
    }
    return;
  };

  if (!(state.token && state.mobileNumber && state.otpCode)) {
    return <Navigate to="/partner/reset-pin/pin-authentication" />;
  }

  return (
    <div className="p-4 h-screen flex flex-col  w-full">
      <AppBarFS
        onIconPress={() => navigate("/partner/reset-pin/pin-authentication")}
        title={t("update_pin.app_header")}
      />
      {loaderComponent()}
      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col grow mt-8 gap-4 justify-between"
      >
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <Typography variant="h6" align="left">
              Set your PIN
            </Typography>
            <AuthBox
              boxLength={4}
              inputType="password"
              placeholder="*"
              errorMessage={formErrorMessage(formik, "pin")}
              error={formError(formik, "pin")}
              onChange={(val) => formik.setFieldValue("pin", val)}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Typography variant="h6" align="left">
              Confirm your PIN
            </Typography>
            <AuthBox
              boxLength={4}
              labelStyle={{
                whiteSpace: "nowrap",
                position: "absolute",
                bottom: "10%",
                transform: "translateX(-50%)",
                left: "50%",
              }}
              inputType="password"
              placeholder="*"
              onChange={(val) => formik.setFieldValue("confirmPin", val)}
              errorMessage={formErrorMessage(formik, "confirmPin")}
              error={formError(formik, "confirmPin")}
            />
          </div>
        </div>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          color="secondary"
          size="large"
        >
          {t("authentication.confirm_button")}
        </Button>
      </form>
    </div>
  );
}

import { TDropdownValue } from "../../../../admin/pages/group-area/views/Dropdown";

export type TFormSeeDetailsBasicAccounting = {
  christianYear: TDropdownValue[];
};

export const defaultFormSeeDetailsBasicAccounting: TFormSeeDetailsBasicAccounting =
  {
    christianYear: [],
  };

export type TFormFilterModal = {
  startDate: string;
  endDate: string;
};

export const defaultFormFilterModal: TFormFilterModal = {
  startDate: "",
  endDate: "",
};

export type TFormSelectBillType = {
  billType: TDropdownValue[];
};

export const defaultFormSelectBillType: TFormSelectBillType = {
  billType: [],
};

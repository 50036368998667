import { memo } from "react";
import AccordionCreditCustom from "../../../components/AccordionCreditCustom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { classes, tableCellGradeSetupHeader } from "../Modal/CreditModal";
import { TableCellHeaderCustom } from "../Modal/TableCellCustom";
import { tw } from "../../../../../../utils/tw";
import TextInputCustom from "../../../../../components/Input/TextInputCustom";
import { useFormContext, useWatch } from "react-hook-form";
import RHFTextInputCustom from "../../../../../components/Input/RHFTextInputCustom";
import { ICreditForm } from "../../../../../../types/admin/masterdata/creditForm";

type GradeSetupAccordionProps = {
  viewAction: boolean;
};

const GradeSetupAccordion = ({ viewAction }: GradeSetupAccordionProps) => {
  const {
    formState: { errors },
    control,
  } = useFormContext<ICreditForm>();

  const minimumA = useWatch({
    name: "gradeSetup.minimumA",
    control: control,
  });

  const minimumB = useWatch({
    name: "gradeSetup.minimumB",
    control: control,
  });

  const minimumC = useWatch({
    name: "gradeSetup.minimumC",
    control: control,
  });

  const minimumD = useWatch({
    name: "gradeSetup.minimumD",
    control: control,
  });

  return (
    <>
      <AccordionCreditCustom
        title={"Grade Setup"}
        rowChildren={
          <>
            <div className="m-2">
              <p className="text-[#d32f2f] font-normal text-[0.75rem]">
                {errors.gradeSetup?.minimumA?.message || ""}
              </p>
              <p className="text-[#d32f2f] font-normal text-[0.75rem]">
                {errors.gradeSetup?.minimumB?.message || ""}
              </p>
              <p className="text-[#d32f2f] font-normal text-[0.75rem]">
                {errors.gradeSetup?.minimumC?.message || ""}
              </p>
              <p className="text-[#d32f2f] font-normal text-[0.75rem]">
                {errors.gradeSetup?.minimumD?.message || ""}
              </p>
            </div>
            <TableContainer
              sx={{
                scrollBehavior: "smooth",
                overflow: "auto",
                borderRadius: "6px",
                boxShadow: "none",
                border: "1px solid #E1E1E1",
                borderBottom: "none",
                width: "100%",
                marginTop: "12px",
              }}
            >
              <Table aria-labelledby="table-show-data" size={"medium"}>
                <TableHead
                  sx={{
                    bgcolor: "#FBFBFB",
                    borderBottom: "1px solid #E1E1E1",
                  }}
                >
                  <TableRow>
                    {tableCellGradeSetupHeader.map((cell, index) => (
                      <TableCellHeaderCustom
                        key={index}
                        value={cell.value}
                        width={cell.width}
                      />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      "&:last-child td": {
                        borderLeft: "1px solid #E1E1E1",
                        borderRight: "1px solid #E1E1E1",
                        padding: "10px 12px !important",
                      },
                      "& td:first-of-type": {
                        borderLeft: "none",
                      },
                      "& td:last-of-type": {
                        borderRight: "none",
                      },
                    }}
                  >
                    <TableCell align="left">
                      <p>Scoring</p>
                    </TableCell>
                    <TableCell align="left">
                      <div className={tw(classes.inputSpace, "gap-2 mb-0")}>
                        <div>
                          <p className={classes.label}>Max</p>
                          <div className={tw(classes.input, "w-[100px] pb-0")}>
                            <RHFTextInputCustom
                              controlName=""
                              disabled
                              defaultValue="100"
                            />
                          </div>
                        </div>
                        <div>
                          <p className={classes.label}>Min</p>
                          <div className={tw(classes.input, "w-[100px] pb-0")}>
                            <RHFTextInputCustom
                              placeholder="กรอก"
                              controlName="gradeSetup.minimumA"
                              error={Boolean(errors.gradeSetup?.minimumA)}
                              type="number"
                              disabled={viewAction}
                            />
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className={tw(classes.inputSpace, "gap-2 mb-0")}>
                        <div>
                          <p className={classes.label}>Max</p>
                          <div className={tw(classes.input, "w-[100px] pb-0")}>
                            <TextInputCustom disabled value={minimumA} />
                          </div>
                        </div>
                        <div>
                          <p className={classes.label}>Min</p>
                          <div className={tw(classes.input, "w-[100px] pb-0")}>
                            <RHFTextInputCustom
                              placeholder="กรอก"
                              controlName="gradeSetup.minimumB"
                              error={Boolean(errors.gradeSetup?.minimumB)}
                              type="number"
                              disabled={viewAction}
                            />
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className={tw(classes.inputSpace, "gap-2 mb-0")}>
                        <div>
                          <p className={classes.label}>Max</p>
                          <div className={tw(classes.input, "w-[100px] pb-0")}>
                            <TextInputCustom
                              disabled
                              value={minimumB}
                              type="number"
                            />
                          </div>
                        </div>
                        <div>
                          <p className={classes.label}>Min</p>
                          <div className={tw(classes.input, "w-[100px] pb-0")}>
                            <RHFTextInputCustom
                              placeholder="กรอก"
                              controlName="gradeSetup.minimumC"
                              error={Boolean(errors.gradeSetup?.minimumC)}
                              type="number"
                              disabled={viewAction}
                            />
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className={tw(classes.inputSpace, "gap-2 mb-0")}>
                        <div>
                          <p className={classes.label}>Max</p>
                          <div className={tw(classes.input, "w-[100px] pb-0")}>
                            <TextInputCustom disabled value={minimumC} />
                          </div>
                        </div>
                        <div>
                          <p className={classes.label}>Min</p>
                          <div className={tw(classes.input, "w-[100px] pb-0")}>
                            <RHFTextInputCustom
                              placeholder="กรอก"
                              controlName="gradeSetup.minimumD"
                              error={Boolean(errors.gradeSetup?.minimumD)}
                              type="number"
                              disabled={viewAction}
                            />
                          </div>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className={tw(classes.inputSpace, "gap-2 mb-0")}>
                        <div>
                          <p className={classes.label}>Max</p>
                          <div className={tw(classes.input, "w-[100px] pb-0")}>
                            <TextInputCustom disabled value={minimumD} />
                          </div>
                        </div>
                        <div>
                          <p className={classes.label}>Min</p>
                          <div className={tw(classes.input, "w-[100px] pb-0")}>
                            <TextInputCustom defaultValue="0" disabled />
                          </div>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        }
      />
    </>
  );
};

export default memo(GradeSetupAccordion);

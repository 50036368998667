import { IGetListPerFlockResult } from "../../../../../types/BasicAccounting.type";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { tw } from "../../../../../utils/tw";
import { getCommaNumber } from "../../../../../utils/number";
import { getShortDate } from "../../../../../utils/date";
import { useNavigate, useParams } from "react-router-dom";

interface IListPerFlock {
  data: IGetListPerFlockResult[];
}

const ListPerFlock = (props: IListPerFlock) => {
  const { data } = props;
  const { farmId } = useParams();
  const navigate = useNavigate();

  return (
    <div className="bg-[#FFFFFF] rounded-[20px] p-4 flex flex-col gap-4 font-sans">
      {(data || []).length > 0 ? (
        (data || []).map((row, index) => (
          <div
            className="bg-[#FBFBFB] p-3 flex flex-col gap-[6px]"
            key={row.id + index}
          >
            <div className="flex flex-row justify-between items-center">
              <div className="text-[#34407B] font-bold text-[14px]">
                {row.flockName}
              </div>
              <div
                className="text-[#34407B] font-normal text-[14px] cursor-pointer"
                onClick={() =>
                  navigate(
                    `/farm/${farmId}/basic-accounting/see-details/${row.id}`
                  )
                }
              >
                ดูรายละเอียด <KeyboardArrowRightIcon />
              </div>
            </div>
            <div className="flex flex-row justify-between items-center">
              <div className="text-[#3777BC] font-normal text-[14px]">
                กำไร/ขาดทุน
              </div>
              <div
                className={tw(
                  "font-normal text-[14px]",
                  row.income - row.expense >= 0
                    ? "text-[#68C184]"
                    : "text-[#D32F2F]"
                )}
              >
                {row.income - row.expense >= 0 ? "+" : ""}
                {getCommaNumber(row.income - row.expense, true)} บาท
              </div>
            </div>
            <div className="flex flex-row justify-between items-center">
              <div className="text-[#3777BC] font-normal text-[14px]">
                วันที่ปิดรุ่น
              </div>
              <div className="text-[#34407B] font-normal text-[14px]">
                {row.isActive === false
                  ? getShortDate(row.generationCloseDate)
                  : "-"}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-[#3777BC] font-normal text-[14px] w-full text-center">
          ไม่มีข้อมูล
        </div>
      )}
    </div>
  );
};

export default ListPerFlock;

import { IGetCreditOrderIncoming } from "../../../types/EditOrderFeed";
import { IExportOrder } from "../../../types/ExportOrder.type";
import {
  BlobURL,
  ImportDocumentReq,
  ImportDocumentRes,
  SaveImportDocumentURLReq,
} from "../../../types/ImportDocument.type";
import adminAPI from "../../AdminApiService";

export const getApprovedOrder = async (searchParams?: URLSearchParams) => {
  try {
    const { data } = await adminAPI.get<IGetCreditOrderIncoming>(
      `/sale-order/approved-order?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const postUpdateDelivery = async (orderFeedId?: string) => {
  return adminAPI.post(
    "/sale-order/approved-order/" + orderFeedId + "/delivery"
  );
};
export const postConfirmDelivery = async (orderFeedId?: string) => {
  return adminAPI.post(
    "/sale-order/approved-order/" + orderFeedId + "/confirm-delivery"
  );
};
export const getListExportCreditApprovedOrder = async (ids: string[]) => {
  try {
    const { data } = await adminAPI.post<IExportOrder>(
      `/sale-order/approved-order/export`,
      { ids }
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getUploadPreSignURL = async (
  orderFeedId: string,
  body: ImportDocumentReq
) => {
  try {
    const { data } = await adminAPI.post<ImportDocumentRes>(
      `/sale-order/approved-order/upload/pre-sign/${orderFeedId}`,
      { ...body }
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const savePreSignURL = async (
  orderFeedId: string,
  body: SaveImportDocumentURLReq
) => {
  try {
    const { data } = await adminAPI.put(
      `/sale-order/approved-order/upload/pre-sign/${orderFeedId}`,
      { ...body }
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getDownloadPreSignURL = async (
  orderFeedId: string,
  url: string
) => {
  try {
    const { data } = await adminAPI.post<{ success: boolean; result: BlobURL }>(
      `/sale-order/approved-order/download/pre-sign/${orderFeedId}`,
      { url }
    );
    return data;
  } catch (error) {
    return null;
  }
};

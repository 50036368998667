import React, { useState } from "react";
import { BaseModal } from "../../../order-feed/OrderFeedBaseModal";
import { Button } from "@mui/material";

type TReceiptProductModalProps = {
  onOpenModal: boolean;
  onCloseModal: (isConfirm: boolean) => void;
};

const ReceiptProductModal = ({
  onOpenModal,
  onCloseModal,
}: TReceiptProductModalProps) => {
  return (
    <BaseModal isOpen={onOpenModal} handleClose={onCloseModal}>
      <div className="flex  flex-col text-center">
        <p className="text-[24px] font-medium items-center text-warning mb-4">
          ยืนยันการรับของ
        </p>
        <p className="text-[16px]">กรุณากดยืนยันการรับสินค้า</p>
        <p className="text-[12px]">
          ก่อนกดยืนยันการรับสินค้ากรุณาตรวจสอบสินค้าที่สั่งซื้อ
        </p>
        <div className="flex justify-between mt-12">
          <Button
            size="large"
            variant="outlined"
            sx={{
              color: "#68C184",
              borderColor: "#68C184",
              width: "45%",
              ":hover": {
                border: "1px solid #68C184",
              },
              ":active": {
                border: "1px solid #68C184",
              },
            }}
            onClick={() => onCloseModal(false)}
          >
            กลับไป
          </Button>
          <Button
            size="large"
            variant="contained"
            sx={{
              color: "#ffff",
              bgcolor: "#68C184",
              width: "45%",
              ":hover": {
                color: "#FFFFFF",
                border: "1px solid #68C184",
                bgcolor: "#68C184",
              },
              ":active": {
                color: "#FFFFFF",
                border: "1px solid #68C184",
                bgcolor: "#68C184",
              },
            }}
            onClick={() => onCloseModal(true)}
          >
            ยืนยัน
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};

export default ReceiptProductModal;

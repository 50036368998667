import { useQuery } from "@tanstack/react-query";
import { getListInvoice } from "../../../../services/Invoice.service";
import { useLoaderData } from "react-router-dom";
import { useCallback, useState } from "react";
import {
  defaultFormUnpaidBill,
  TFormUnpaidBill,
} from "./defaultFormUnpaidBill";
import dayjs from "dayjs";

export const useUnpaidBillController = () => {
  const { farmId } = useLoaderData() as { farmId: string };
  const [orderByType, setOrderByType] = useState<"desc" | "asc">("asc");

  const [formUnpaidBill, setFormUnpaidBill] = useState(defaultFormUnpaidBill);

  const onChangeFormUnpaidBill = useCallback(
    (key: keyof TFormUnpaidBill) => (value: any) => {
      setFormUnpaidBill((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const [currentPage, setCurrentPage] = useState(1);

  const { data: listInvoice, refetch: refetchListInvoice } = useQuery({
    queryKey: [
      "list_invoice_unpaid",
      orderByType,
      formUnpaidBill.direction,
      formUnpaidBill.startDate,
      formUnpaidBill.endDate,
      currentPage,
    ],
    queryFn: async () => {
      const result = await getListInvoice({
        page: currentPage,
        direction: formUnpaidBill.direction[0]?.value || orderByType,
        tabName: "unpaid",
        farmId,
        startDate: formUnpaidBill.startDate
          ? dayjs(formUnpaidBill.startDate, "YYYY-MM-DD")
              .startOf("day")
              .toDate()
          : undefined,
        endDate: formUnpaidBill.endDate
          ? dayjs(formUnpaidBill.endDate, "YYYY-MM-DD").startOf("day").toDate()
          : undefined,
      });
      return result;
    },
  });

  return {
    listInvoice,
    refetchListInvoice,
    orderByType,
    setOrderByType,
    formUnpaidBill,
    setFormUnpaidBill,
    onChangeFormUnpaidBill,
    currentPage,
    setCurrentPage,
  };
};

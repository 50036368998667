import React, { useState } from "react";
import dayjs from "dayjs";
import "dayjs/locale/th";
import "dayjs/locale/en-gb";
import buddhistEra from "dayjs/plugin/buddhistEra";
import updatedLocale from "dayjs/plugin/updateLocale";
import localizedFormat from "dayjs/plugin/localizedFormat";
import localeData from "dayjs/plugin/localeData";
import preParsePostFormat from "dayjs/plugin/preParsePostFormat";
import isLeapYear from "dayjs/plugin/isLeapYear";
import { AppLocale } from "../types";
import { getCurrentLanguage } from "../services/language.service";
import { updateLocaleApp } from "../services/locale.service";

dayjs.extend(localeData);
dayjs.extend(buddhistEra);
dayjs.extend(updatedLocale);
dayjs.extend(localizedFormat);
dayjs.extend(preParsePostFormat);
dayjs.extend(isLeapYear);

dayjs.updateLocale("th", {
  formats: {
    // abbreviated format options allowing localization
    LTS: "h:mm:ss A",
    LT: "h:mm A",
    L: "DD/MM/BBBB",
    LL: "MMMM D, BBBB",
    LLL: "MMMM D, BBBB h:mm A",
    LLLL: "dddd, MMMM D, BBBB h:mm A",
    // lowercase/short, optional formats for localization
    l: "D/M/BBBB",
    ll: "D MMM, BBBB",
    lll: "D MMM, BBBB h:mm A",
    llll: "ddd, MMM D, BBBB h:mm A",
  },
});

export const useDayjsLocale = () => {
  const [locale, setLocale] = useState(getCurrentLanguage() as AppLocale);

  updateLocaleApp(getCurrentLanguage() as AppLocale);

  // React.useEffect(() => {
  //   try {
  //     if (locale === AppLocale.ENGLISH) {
  //       dayjs.locale("en");
  //     } else if (locale === AppLocale.THAI) {
  //       dayjs.locale("th");
  //     }
  //     console.log(dayjs.locale());
  //   } catch (err) {
  //     console.error("Error While loading DayJS module", err);
  //   }
  // }, [locale]);

  return {
    locale,
    setLocale,
    dayjs,
  };
};

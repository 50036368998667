import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  Divider,
  Link,
  SvgIcon,
  Tab,
  TableCell,
  Tabs,
} from "@mui/material";
import { cloneDeep, get, isEmpty } from "lodash";
import MenuBar from "../../../components/MenuBar/MenuBar";
import { tw } from "../../../../utils/tw";
import TextInputSearchCustom from "../../../components/Input/TextInputSearchCustom";
import ButtonOutlinedCustom from "../../../components/Button/ButtonOutlinedCustom";
import { TabPanelCustom } from "../../../components/TabPanelCustom/TabPanelCustom";
import { DownloadSvg } from "../../../assets/svg/Download.svg";
import { Dropdown, TDropdownValue } from "../../group-area/views/Dropdown";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import TextInputCustom from "../../../components/Input/TextInputCustom";
import { CalendarSvg } from "../../../assets/svg/Calendar.svg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React from "react";
import DateRangePickerPopover from "../../../components/Input/DateRangePickerPopover/DateRangePickerPopover";
import dayjs from "dayjs";
import { FILTER_DATE_FORMAT } from "../../sale-order/components/SaleOrderSearchFilter";
import { useCreditStatusController } from "./controller";
import {
  filterCustomerTable,
  getCreditStatus,
  getExportCreditFeed,
} from "../../../../services/CreditFeed.service";
import { grade } from "../credit-feed/salesContract";
import DatePickerPopover from "../../../components/Input/DatePickerPopover/DatePickerPopover";
import { makeXlsx } from "../../../../utils/make-xlsx";
import TableCreditStatus from "./views/TableCreditStatus";

type TStatusCreditMap =
  | "approved"
  | "rejected"
  | "waiting_start"
  | "contract_expire";

interface DataExport {
  customerId: string;
  customerName: string;
  garde: string;
  saleName: string;
  date: string;
  contractNumber: string;
  statues: string;
}
function createData(
  customerId: string,
  contractNumber: string,
  customerName: string,
  garde: string,
  saleName: string,
  date: string,
  statues: string
): DataExport {
  return {
    customerId,
    contractNumber,
    customerName,
    garde,
    saleName,
    date,
    statues,
  };
}

const classes = {
  container: tw(`pt-16 w-full h-full relative font-sans`),
  title: tw(`text-black font-bold text-3xl`),
  mainContainer: tw(`pt-6 flex flex-col w-full`),
  filterRowContainer: tw(`flex flex-row flex-wrap items-end gap-3`),
  columnContainer: tw(`w-full flex flex-row overflow-x-auto mt-6 gap-x-3`),
};

const durationOptions = [
  {
    value: "1",
    label: "0 - 30 วัน",
  },
  {
    value: "2",
    label: "31 - 60 วัน",
  },
  {
    value: "3",
    label: "61 - 90 วัน",
  },
  {
    value: "4",
    label: "มากกว่า 90 วัน",
  },
];

const contractOptions = [
  { label: "จ่ายหลังหมดสัญญา", value: "pay_after_expire" },
  { label: "จ่ายตาม invoice", value: "fill_credit" },
];

const statusMap = {
  approved: "เปิดใช้งาน",
  rejected: "ปิดใช้งาน",
  waiting_start: "รอวันเริ่มสัญญา",
  contract_expire: "สิ้นสุดสัญญา",
  "": "",
};

const statusOptions = [
  { label: "เปิดใช้งาน", value: "true" },
  {
    label: "รอวันเริ่มสัญญา และ สิ้นสุดสัญญา",
    value: "false",
  },
];

const gradeOptions = [
  { label: "A", value: "a" },
  { label: "B", value: "b" },
  { label: "C", value: "c" },
  { label: "D", value: "d" },
  { label: "E", value: "e" },
  { label: "F", value: "f" },
];

const CreditStatus = () => {
  const {
    listCreditStatusPage,
    refetchListCreditStatusPage,
    searchText,
    onSearchText,
    onClearSearchText,
    form,
    setForm,
    onChangeForm,
    currentPage,
    setCurrentPage,
    countData,
    setCountData,
    currentOrderByKey,
    setCurrentOrderByKey,
    orderByType,
    setOrderByType,
  } = useCreditStatusController();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [grade, setGrade] = useState<TDropdownValue[]>([]);
  const [statusCredit, setStatusCredit] = useState<TDropdownValue[]>([]);
  const [isActive, setIsActive] = useState<TDropdownValue[]>([]);
  const [duration, setDuration] = useState<TDropdownValue[]>([]);

  const [itemSelected, setItemSelected] = useState<string[]>([]);

  const refCalendarStartDate =
    React.useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);
  const handleSelectStartDate = (value?: Date) => {
    const formattedDate = dayjs(value).format(FILTER_DATE_FORMAT);
    setStartDate(formattedDate);
  };

  const refCalendarEndDate =
    React.useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);
  const handleSelectEndDate = (value?: Date) => {
    const formattedDate = dayjs(value).format(FILTER_DATE_FORMAT);
    setEndDate(formattedDate);
  };

  const statusText = useCallback((d: any) => {
    const currentData = d;

    if (!currentData) {
      return "unknown";
    }

    const isActive = currentData.isActive;
    const _startDate = dayjs(currentData.startDate).valueOf();
    const expireDate = dayjs(currentData.expireDate).valueOf();
    const now = dayjs().valueOf();
    const re = currentData.reject;

    if (!re && !isActive && _startDate > now) {
      return "waiting_start";
    } else if (!re && !isActive && expireDate < now) {
      return "contract_expire";
    } else if (!re && !isActive) {
      return "rejected";
    } else if (!re && isActive) {
      return "approved";
    } else {
      return "error";
    }
  }, []);

  const handleExportExcel = async () => {
    const data = await getExportExcelData();
    const fileName = `credit_status_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  const getExportExcelData = async () => {
    type TColumnName =
      | "รหัสลูกค้า"
      | "เลขที่สัญญา"
      | "ชื่อลูกค้า"
      | "เกรดลูกค้า"
      | "พนักงานขาย"
      | "วันที่อัปเดตล่าสุด"
      | "สถานะเครดิต";
    type TExcelData = {
      [k in TColumnName]: string | number;
    };
    const allSelectItemData = await getExportCreditFeed(itemSelected);
    if (allSelectItemData) {
      const data: TExcelData[] = allSelectItemData?.map<TExcelData>((row) => {
        const mappedData = createData(
          row.registrationId,
          row.contractNumber,
          row.name + " " + row.surname,
          row.customerGrade.toLocaleUpperCase(),
          row.sellerName + " " + `${row.sellerSurname || ""}`,
          dayjs(row.updatedAt).format("DD/MM/YYYY"),
          statusMap[statusText(row) as TStatusCreditMap]
        );
        return {
          รหัสลูกค้า: mappedData.customerId,
          เลขที่สัญญา: mappedData.contractNumber,
          ชื่อลูกค้า: mappedData.customerName,
          เกรดลูกค้า: mappedData.garde,
          พนักงานขาย: mappedData.saleName,
          วันที่อัปเดตล่าสุด: mappedData.date,
          สถานะเครดิต: mappedData.statues,
        };
      });
      return data;
    }
    return [];
  };

  const onSearch = () => {
    onChangeForm("grade")(grade);
    onChangeForm("statusCredit")(statusCredit);
    onChangeForm("isActive")(isActive);
    onChangeForm("startDate")(startDate);
    onChangeForm("endDate")(endDate);
    onChangeForm("duration")(duration);
    setCurrentPage(1);
  };

  return (
    <>
      <MenuBar>
        <div className={classes.container}>
          <div className="p-10">
            <div className="flex justify-between">
              <span className={classes.title}>Credit Status</span>
            </div>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className="pt-6">
              <div className="w-full ">
                <div className="w-full mr-4 mb-[-8px] flex flex-col">
                  <div className="w-full grid grid-cols-3 gap-[12px]">
                    <Dropdown
                      label="เกรดลูกค้า"
                      placeholder="เลือก"
                      options={gradeOptions}
                      onChange={(value) => setGrade(value)}
                      renderValueArray={grade}
                      onReset={() => setGrade([])}
                    />
                    <div className="w-full mt-[-7px]">
                      <span className="font-medium text-[#191919] text-[14px] font-sans">
                        วันเริ่มสัญญา
                      </span>
                      <div className="pt-2">
                        <TextInputCustom
                          placeholder="เริ่มต้น"
                          value={startDate}
                          InputProps={{
                            endAdornment:
                              startDate !== "" ? (
                                <CloseRoundedIcon
                                  sx={{
                                    cursor: "pointer",
                                    fontSize: "18px !important",
                                  }}
                                  onClick={() => setStartDate("")}
                                />
                              ) : (
                                <SvgIcon
                                  sx={{
                                    fontSize: "24px !important",
                                    color: "#646464",
                                  }}
                                  fontSize="small"
                                >
                                  <CalendarSvg />
                                </SvgIcon>
                              ),
                          }}
                          onClick={(
                            e: React.MouseEvent<HTMLDivElement, MouseEvent>
                          ) => {
                            e.stopPropagation();
                            refCalendarStartDate.current?.onOpen(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-full mt-[-7px]">
                      <span className="font-medium text-[#191919] text-[14px] font-sans">
                        วันสิ้นสุดสัญญา
                      </span>
                      <div className="pt-2">
                        <TextInputCustom
                          placeholder="สิ้นสุด"
                          value={endDate}
                          InputProps={{
                            endAdornment:
                              startDate !== "" ? (
                                <CloseRoundedIcon
                                  sx={{
                                    cursor: "pointer",
                                    fontSize: "18px !important",
                                  }}
                                  onClick={() => setEndDate("")}
                                />
                              ) : (
                                <SvgIcon
                                  sx={{
                                    fontSize: "24px !important",
                                    color: "#646464",
                                  }}
                                  fontSize="small"
                                >
                                  <CalendarSvg />
                                </SvgIcon>
                              ),
                          }}
                          onClick={(
                            e: React.MouseEvent<HTMLDivElement, MouseEvent>
                          ) => {
                            e.stopPropagation();
                            refCalendarEndDate.current?.onOpen(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-full flex flex-row gap-[12px]">
                    <Dropdown
                      label="สถานะเครดิต"
                      placeholder="เลือก"
                      options={statusOptions}
                      onChange={(value) => setIsActive(value)}
                      renderValueArray={isActive}
                      onReset={() => setIsActive([])}
                    />
                    <Dropdown
                      label="เงื่อนไข"
                      placeholder="เลือก"
                      options={contractOptions}
                      onChange={(value) => setStatusCredit(value)}
                      renderValueArray={statusCredit}
                      onReset={() => setStatusCredit([])}
                    />
                    <Dropdown
                      label="จำนวนวัน"
                      placeholder="เลือก"
                      options={durationOptions}
                      onChange={(value) => setDuration(value)}
                      renderValueArray={duration}
                      onReset={() => setDuration([])}
                    />
                  </div>
                </div>
                <div className="pt-[18px] w-full flex justify-end">
                  <ButtonFillCustom title={"ค้นหา"} onClick={onSearch} />
                </div>
              </div>
              <div className="flex flex-row w-full justify-between pt-6">
                <div className="flex w-full max-w-[656px]">
                  <div className="w-full max-w-[470px] mr-4">
                    <TextInputSearchCustom
                      value={searchText}
                      placeholder="ค้นหาโดยรหัสลูกค้า, ชื่อลูกค้า"
                      onChange={onSearchText}
                      InputProps={{
                        endAdornment:
                          searchText.length > 0 ? (
                            <CloseRoundedIcon
                              sx={{
                                cursor: "pointer",
                                fontSize: "18px !important",
                              }}
                              onClick={onClearSearchText}
                            />
                          ) : null,
                      }}
                    />
                  </div>
                </div>
                <ButtonOutlinedCustom
                  title="นำออก"
                  btnTextColor="#68C184"
                  btnBorderColor="#68C184"
                  disabled={itemSelected.length === 0}
                  onClick={handleExportExcel}
                  startIcon={
                    <SvgIcon
                      sx={{
                        fontSize: "14px !important",
                        color: "currentColor",
                        marginBottom: "3px",
                      }}
                      fontSize="small"
                    >
                      <DownloadSvg />
                    </SvgIcon>
                  }
                />
              </div>
            </div>
            <div className="flex flex-col w-full pt-6">
              <TableCreditStatus
                data={listCreditStatusPage?.creditFeeds || []}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                itemSelected={itemSelected}
                setItemSelected={setItemSelected}
                countData={countData}
                currentOrderByKey={currentOrderByKey}
                setCurrentOrderByKey={setCurrentOrderByKey}
                orderByType={orderByType}
                setOrderByType={setOrderByType}
              />
            </div>
          </div>
          <DatePickerPopover
            ref={refCalendarStartDate}
            id={"date-picker-start-date"}
            date={startDate}
            handleCalenderSave={handleSelectStartDate}
          />
          <DatePickerPopover
            ref={refCalendarEndDate}
            id={"date-picker-end-date"}
            date={endDate}
            handleCalenderSave={handleSelectEndDate}
          />
        </div>
      </MenuBar>
    </>
  );
};

export default CreditStatus;

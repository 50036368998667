import React, { ReactNode, useEffect, useState } from "react";
import FeedingContext from "./FeedingContext";
import { useTranslation } from "react-i18next";

interface MyContextProviderProps {
  children: ReactNode; // This is the prop type definition for 'children'
}

const FeedingProvider: React.FC<MyContextProviderProps> = ({ children }) => {
  const { t } = useTranslation();
  const [feedBrands, setFeedBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [basicDetailsData, setBasicDetailsData] = useState(null);
  const [finalAddHouseData, setAddHouseData] = useState({});
  const [isEditFeed, setEditFeed] = useState<boolean>(false);
  const [isEditVaccine, setEditVaccine] = useState<boolean>(false);
  const [isEditHouse, setEditHouse] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [steps, setSteps] = useState<any>([
    {
      //STEP 1: Basic Details
      id: "step-1-details",
      label: t("addHouse.stepTitleBasicDetails"),
      completed: false,
    },
    {
      //STEP 2: Feeding Program
      id: "step-2-feeding",
      label: t("addHouse.stepTitleFeedingProgram"),
      completed: false,
    },
    {
      //STEP 3: Vaccine Program
      id: "step-2-vaccine",
      label: t("addHouse.stepTitleVaccineProgram"),
      completed: false,
    },
  ]);
  const [feedData, setFeedData] = useState([]);

  return (
    <FeedingContext.Provider
      value={{
        selectedBrand,
        feedBrands,
        setFeedBrands,
        setSelectedBrand,
        basicDetailsData,
        setBasicDetailsData,
        finalAddHouseData,
        setAddHouseData,
        isEditFeed,
        isEditVaccine,
        setEditFeed,
        setEditVaccine,
        isEditHouse,
        setEditHouse,
        activeStep,
        setActiveStep,
        steps,
        setSteps,
        setFeedData,
        feedData,
      }}
    >
      {children}
    </FeedingContext.Provider>
  );
};

export default FeedingProvider;

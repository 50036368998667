import { useLoaderData, useNavigate } from "react-router-dom";
import AppBarFS from "../../../components/app-bar/AppBar";
import { TOrderReportDetail } from "../../../types/Report";
import ClaimItem from "./OrderClaimItem";

type Props = {};

const ReportOrderDetail = ({}: Props) => {
  const navigate = useNavigate();
  const { reportOrder } = useLoaderData() as {
    reportOrder: TOrderReportDetail;
  };
  return (
    <div>
      <div className="min-h-screen w-full flex flex-col gap-4 bg-white">
        <AppBarFS title="รายงานปัญหา" onIconPress={() => navigate(-1)} />
        <p className="text-[20px] text-[#3777BC] font-bold pl-4">รายละเอียด</p>
        {reportOrder.orderClaimItems.map((order, idx) => (
          <ClaimItem order={order} idx={idx} />
        ))}
      </div>
    </div>
  );
};

export default ReportOrderDetail;

import { LoaderFunctionArgs } from "react-router-dom";
import { getPartnerHistoryStockById } from "../../../../services/partner/historyStock.service";

export async function historyStockDetailLoader(loader: LoaderFunctionArgs) {
  const { params } = loader;
  const stockId = params["stockId"] ?? "";
  const stockList = await getPartnerHistoryStockById(stockId);
  return {
    stockId,
    stockList,
  };
}

import { Button } from "@mui/material";
import { IPrimaryButton } from "./types";

const CustomButton = ({
  variant,
  size,
  disabled,
  label,
  onClick,
  className,
  style,
  datatestid,
  type,
  fullWidth = true,
  removeBoxShadow = false,
}: IPrimaryButton) => {
  return (
    <Button
      data-testid={datatestid}
      variant={
        variant === "secondary" || variant === "quaternary"
          ? "outlined"
          : "contained"
      }
      className={`mt-4 h-[59px] ${className}  ${
        variant === "secondary" || variant === "quaternary"
          ? "bg-white-important"
          : ""
      }`}
      fullWidth={fullWidth}
      type={type || "submit"}
      color={
        variant === "tertiary"
          ? "secondary"
          : variant === "quaternary"
          ? "tertiary"
          : variant || "primary"
      }
      size={size || "large"}
      onClick={onClick}
      disabled={disabled}
      sx={{
        textTransform: "none",
        fontSize: "17px",
        fontWeight: 700,
        ...(removeBoxShadow ? { boxShadow: "unset" } : {}),
      }}
    >
      {label}
    </Button>
  );
};

export default CustomButton;

import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ErrorSnackBar from "../../admin/components/SnackBar/ErrorSnackBar";
import SuccessSnackBar from "../../admin/components/SnackBar/SuccessSnackBar";
import { snakeActions } from "../../redux/slices/snakeSlice";
import { AppDispatch, RootState } from "../../redux/store";

const GlobalSnake = () => {
  const snakeState = useSelector((state: RootState) => state.snake);
  const dispatch = useDispatch<AppDispatch>();

  const handleCloseSnackbar = () => {
    dispatch(snakeActions.hide());
  };

  useEffect(() => {
    if (snakeState.isVisible) {
      setTimeout(() => {
        handleCloseSnackbar();
      }, 2500);
    }
  }, [snakeState.isVisible]);

  return (
    <>
      <SuccessSnackBar
        openSnackbar={snakeState.isVisible && snakeState.type === "success"}
        handleCloseSnackbar={handleCloseSnackbar}
        snackbarText={snakeState.message}
        horizontalAnchor={snakeState.horizontalAnchor}
      />
      <ErrorSnackBar
        openSnackbar={snakeState.isVisible && snakeState.type === "error"}
        handleCloseSnackbar={handleCloseSnackbar}
        snackbarText={snakeState.message}
        horizontalAnchor={snakeState.horizontalAnchor}
      />
    </>
  );
};

export default memo(GlobalSnake);

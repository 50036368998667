const CheckBoxUnCheck = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <rect width={18} height={18} x={3.25} y={3.75} fill="#FBFBFB" rx={1.5} />
    <rect
      width={18}
      height={18}
      x={3.25}
      y={3.75}
      style={{ strokeWidth: 2 }}
      stroke="#C9C9C9"
      rx={1.5}
    />
  </svg>
);
export default CheckBoxUnCheck;

import { Checkbox, Link, TableCell, TableRow } from "@mui/material";
import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { tw } from "../../../../../../utils/tw";
import { ICreditFeedData } from "../../../../../../types/CreditFeed.type";
import TableCustom, {
  ExpandableTableRow,
} from "../../../../../components/Table/TableCustom";
import dayjs from "dayjs";
import ChipOrderStatus from "../../../../../components/Chip/ChipOrderStatus";
import FillDataExpand from "../TabPending/FillDataExpand";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";

const classes = {
  container: tw(`min-h-[240px]`),
  emptyTable: tw(
    `font-sans text-center w-full text-[#979797] text-[14px] py-3`
  ),
};

const headerItems = [
  {
    id: "farmer_code",
    numeric: false,
    disablePadding: false,
    label: "รหัสลูกค้า",
    width: "auto",
    isSorting: true,
    className: "!whitespace-pre",
  },
  {
    id: "customerName",
    numeric: false,
    disablePadding: false,
    label: "ชื่อลูกค้า",
    width: "auto",
    isSorting: false,
    className: "!whitespace-pre",
  },
  {
    id: "credit_rating",
    numeric: false,
    disablePadding: false,
    label: "เกรดลูกค้า",
    width: "auto",
    isSorting: true,
    className: "!whitespace-pre",
  },
  {
    id: "saleName",
    numeric: false,
    disablePadding: false,
    label: "พนักงานขาย",
    width: "auto",
    isSorting: false,
    className: "!whitespace-pre",
  },
  {
    id: "updated_at",
    numeric: false,
    disablePadding: false,
    label: "วันที่อัปเดตล่าสุด",
    width: "auto",
    isSorting: true,
    className: "!whitespace-pre",
  },
  {
    id: "statusCredit",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "auto",
    isSorting: false,
    className: "!whitespace-pre",
  },
];

interface ITable {
  data: ICreditFeedData[];
  currentPage: number;
  setCurrentPage: (page: number) => void;
  itemSelected: string[];
  setItemSelected: (ids: string[]) => void;
  onOpenReject?: () => void;
  onOpenApproved?: (id: string) => void;
  idDenied: string;
  setIdDenied: Dispatch<SetStateAction<string>>;
  countData: number;
  currentOrderByKey: "farmer_code" | "credit_rating" | "updated_at";
  setCurrentOrderByKey: Dispatch<
    SetStateAction<"farmer_code" | "credit_rating" | "updated_at">
  >;
  orderByType: "desc" | "asc";
  setOrderByType: Dispatch<SetStateAction<"desc" | "asc">>;
}

const TableTabDenied = (props: ITable) => {
  const {
    data,
    currentPage,
    setCurrentPage,
    itemSelected,
    setItemSelected,
    onOpenReject,
    onOpenApproved,
    idDenied,
    setIdDenied,
    countData,
    currentOrderByKey,
    setCurrentOrderByKey,
    orderByType,
    setOrderByType,
  } = props;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = data.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };

  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };

  const onOpenRejectFn = useCallback((id: string) => {
    setIdDenied(id);
    onOpenReject && onOpenReject();
  }, []);

  const onOpenApprovedFn = useCallback((id: string) => {
    setIdDenied(id);
    onOpenApproved && onOpenApproved(id);
  }, []);

  const rowChildren = useMemo(() => {
    return data.map((row, index) => {
      const isItemSelected = isSelected(row.id);

      return (
        <ExpandableTableRow
          key={row.id + index}
          selected={isItemSelected}
          aria-checked={isItemSelected}
          expandComponent={
            <FillDataExpand
              data={row}
              isShowButton
              button={
                <div className="flex gap-4">
                  <ButtonFillCustom
                    title="ไม่อนุมัติ"
                    onClick={() => onOpenRejectFn(row.id)}
                    btnBgColor="#D32F2F"
                  />
                  <ButtonFillCustom
                    title={"Apply Credit"}
                    onClick={() => onOpenApprovedFn(row.id)}
                  />
                </div>
              }
            />
          }
        >
          <TableCell
            padding="checkbox"
            sx={{
              borderLeft: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            <Checkbox
              color="primary"
              checked={isItemSelected}
              onClick={(event) => handleClick(event, row.id)}
              sx={{
                borderWidth: "1px",
                color: "#C9C9C9",
                "&.Mui-checked": {
                  color: "#68C184",
                },
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              whiteSpace: "pre",
            }}
          >
            <Link
              component="button"
              variant="body2"
              sx={{
                color: "#68C184",
                textDecorationColor: "#68C184",
                ":hover": {
                  fontSize: "16px",
                },
              }}
            >
              {row.registrationId}
            </Link>
          </TableCell>
          <TableCell
            sx={{
              whiteSpace: "pre",
            }}
          >
            {row.name + " " + row.surname}
          </TableCell>
          <TableCell
            sx={{
              whiteSpace: "pre",
            }}
          >
            {row.customerGrade.toLocaleUpperCase()}
          </TableCell>
          <TableCell
            sx={{
              whiteSpace: "pre",
            }}
          >
            {row.sellerName +
              " " +
              (row.sellerSurname === null ? "" : row.sellerSurname)}
          </TableCell>
          <TableCell
            sx={{
              whiteSpace: "pre",
            }}
          >
            {dayjs(row.updatedAt).format("DD/MM/YYYY")}
          </TableCell>
        </ExpandableTableRow>
      );
    });
  }, [data, currentPage, itemSelected]);

  return (
    <div className={classes.container}>
      <TableCustom
        order={orderByType}
        onChangeOrder={setOrderByType}
        orderBy={currentOrderByKey}
        onChangeOrderBy={setCurrentOrderByKey as any}
        totalItemSize={countData}
        headerItems={headerItems}
        page={currentPage}
        onChangePage={setCurrentPage}
        rowsPerPage={10}
        rows={data}
        EmptyComponent={
          countData === 0 ? (
            <div className={classes.emptyTable}>ไม่มีข้อมูล</div>
          ) : null
        }
        rowChildren={rowChildren}
        currentPageItems={data.map(({ id }) => id)}
        itemsSelected={itemSelected}
        onSelectAllClick={handleSelectAllClick}
        onSelectItem={(val) => setItemSelected(val as string[])}
      />
    </div>
  );
};

export default memo(TableTabDenied);

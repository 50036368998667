import { Dialog, DialogContent, IconButton } from "@mui/material";
import { LogDetail } from "../../../../../types/invoice/log.type";
import CrossIcon from "../../../../assets/svg/CrossIcon.svg";
import dayjs from "dayjs";
import { thousandComma } from "../../../../../utils/common/string";

type OverdueModalProps = {
  isOpen: boolean;
  handleClose: (isSuccess: boolean, values?: any) => void;
  logDetail: LogDetail;
};

const classes = {
  container: `items-start w-[50%]`,
  label: `text-sm font-bold pb-3 mb-1`,
  title: `text-sm pb-3 mb-1`,
  flexCenter: `flex justify-between items-center`,
};

const TrackingInvoice = ({
  isOpen,
  handleClose,
  logDetail,
}: OverdueModalProps) => {
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => handleClose(true)}
        maxWidth="lg"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={{
          backdrop: {
            style: { backgroundColor: "#000000", opacity: "10%" },
            timeout: 500,
          },
        }}
        PaperProps={{
          sx: {
            borderRadius: "20px",
          },
        }}
      >
        <DialogContent sx={{ width: "600px" }}>
          <div className={classes.flexCenter}>
            <p className="text-lg font-bold pb-3">รายละเอียด</p>
            <IconButton onClick={() => handleClose(true)}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className="flex">
            <div className={classes.container}>
              <p className={classes.label}>วันที่นัดชำระ</p>
              <p className={classes.title}>
                {dayjs(logDetail.appointmentDate).format("DD/MM/YYYY")}
              </p>
            </div>
            <div className={classes.container}>
              <p className={classes.label}>ยอดนัดชำระ</p>
              <p className={classes.title}>
                {thousandComma(logDetail.paidAmount || 0, 2)}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className={classes.container}>
              <p className={classes.label}>รายละเอียด</p>
              <p className={classes.title}>{logDetail.detail || "-"}</p>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TrackingInvoice;

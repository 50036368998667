import { useLoaderData, useNavigate } from "react-router-dom";
import AppBarFS from "../../../../components/app-bar/AppBar";

import OrderFeedPartnerDetail from "../../stock-component/OrderFeedPartnerDetail";
import { TBaseGetListPartnerStockList } from "../../../../types/partner/stock.type";

type Props = {};

const UpcomingStockDetail = (props: Props) => {
  const navigate = useNavigate();
  const { stockList } = useLoaderData() as {
    stockList: TBaseGetListPartnerStockList;
  };
  return (
    <div className="pb-4 h-screen flex flex-col  bg-[#ffff] overflow-scroll">
      <div className="py-4 rounded-b-[40px] bg-white">
        <AppBarFS
          onIconPress={() => navigate(-1)}
          title={"รายละเอียดสต๊อกเข้าใหม่"}
        />
      </div>
      <OrderFeedPartnerDetail
        stockList={stockList.stockRefill}
        isTextDescription={true}
      />
    </div>
  );
};

export default UpcomingStockDetail;

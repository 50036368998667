const IconFeedPigSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
    <path
      fill="#fff"
      d="M3.328.078c-.075.072-.078.09-.078.5V1h9.5V.584c0-.375-.006-.421-.063-.484-.124-.131-.2-.125-.584.069l-.353.175-.344-.172C11.22.078 11.034 0 11 0c-.034 0-.219.078-.406.172l-.344.172-.344-.172C9.72.078 9.534 0 9.5 0c-.034 0-.219.078-.406.172L8.75.344 8.406.172C8.22.078 8.034 0 8 0c-.034 0-.219.078-.406.172L7.25.344 6.906.172C6.72.078 6.534 0 6.5 0c-.034 0-.219.078-.406.172L5.75.344 5.406.172C5.22.078 5.034 0 5 0c-.034 0-.219.078-.406.172L4.25.344 3.906.172C3.72.078 3.528 0 3.484 0a.257.257 0 0 0-.156.078ZM12.177 2.037c-.687.825-.906 1.134-1.125 1.571-.306.613-.281.247-.29 4.188-.01 2.331 0 3.538.021 3.684.097.638.382 1.144 1.253 2.244.27.335.532.675.591.753a.546.546 0 0 0 .122.144c.01 0 .078-.081.147-.178.072-.097.337-.419.594-.71.253-.293.537-.634.63-.762.223-.3.454-.738.545-1.034l.071-.235.01-3.569c.01-3.95.015-3.778-.194-4.265a5.426 5.426 0 0 0-.59-1c-.22-.303-1.185-1.494-1.213-1.494-.01 0-.269.3-.572.663Z"
    />
    <path
      fill="#fff"
      d="M2.794 1.978c-.669.838-1 1.394-1.19 2.006l-.088.282v7.312l.087.281c.097.32.31.778.497 1.079.069.112.344.509.612.884l.488.678h4.4c3.516 0 4.4-.01 4.381-.04-.015-.02-.215-.279-.45-.57-.637-.796-.934-1.28-1.112-1.806-.169-.496-.169-.471-.169-4.175.003-3.74-.003-3.65.188-4.162.2-.556.609-1.16 1.421-2.116l.116-.131H7.572l-4.4.003-.378.475Zm.24 2.66c.285.1.52.262.779.54l.218.234.256-.109c.944-.4 1.997-.4 2.92-.003l.259.112.29-.293c.235-.235.34-.319.54-.419.242-.119.26-.122.57-.122.29 0 .322.006.396.075.15.14.22.385.22.769.002.462-.095.734-.457 1.306-.106.163-.15.26-.134.297.012.028.037.147.062.262l.04.213h-.412c-.428.003-.475.016-.547.15-.05.094-.022.203.066.287.063.057.11.063.497.063h.428l.144.16c.2.221.3.44.322.709a1.506 1.506 0 0 1-1.132 1.584c-.159.04-.528.047-2.609.044-2.184 0-2.44-.006-2.628-.053-.853-.225-1.356-1.21-1-1.96.05-.103.153-.253.234-.337L2.5 8h.422c.41 0 .428-.003.5-.078a.23.23 0 0 0 0-.344c-.072-.075-.09-.078-.485-.078-.465 0-.437.025-.356-.328l.053-.219-.171-.266c-.344-.53-.447-.818-.444-1.265 0-.413.112-.706.31-.822.108-.063.477-.044.705.038Z"
    />
    <path
      fill="#fff"
      d="M2.548 5.11c-.109.29.01.83.27 1.215l.077.112.097-.134c.122-.162.294-.347.472-.497l.134-.115-.115-.144a1.904 1.904 0 0 0-.272-.253C2.948 5.1 2.592 5 2.548 5.109ZM8.616 5.11a1.312 1.312 0 0 0-.22.112c-.14.097-.459.41-.459.45 0 .019.088.112.197.21.107.093.257.259.335.365l.14.19.053-.068c.097-.125.266-.503.307-.678.037-.163.028-.525-.013-.594-.031-.047-.194-.04-.34.012ZM4.96 7.031c-.613.153-1.047.807-.934 1.407.078.43.33.762.718.946l.21.1h.781c.878 0 .972-.018 1.256-.234.675-.506.675-1.49.003-2-.284-.216-.362-.231-1.18-.237-.404-.007-.788.003-.854.018Zm.462 1.047c.22.216-.096.569-.321.36-.088-.085-.116-.194-.066-.288.087-.166.262-.2.387-.072Zm1 0c.22.216-.096.569-.321.36-.088-.085-.116-.194-.066-.288.087-.166.262-.2.387-.072ZM3.25 15.416c0 .375.006.421.063.484.124.131.2.125.584-.069l.353-.175.344.172c.187.094.372.172.406.172.034 0 .219-.078.406-.172l.344-.172.344.172c.187.094.372.172.406.172.034 0 .219-.078.406-.172l.344-.172.344.172c.187.094.372.172.406.172.034 0 .219-.078.406-.172l.344-.172.344.172c.187.094.372.172.406.172.034 0 .219-.078.406-.172l.344-.172.344.172c.187.094.372.172.406.172.034 0 .219-.078.406-.172l.344-.172.353.175c.385.194.46.2.585.069.056-.063.062-.11.062-.484V15h-9.5v.416Z"
    />
  </svg>
);
export default IconFeedPigSvg;

import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import AppBarFS from "../../../components/app-bar/AppBar";
import CustomButton from "../../../components/common/Button";
import EditSkuAndBrand from "./EditSkuAndBrand";
import GrowJourney from "./GrowJourney";
import FeedingContext from "./Provider/FeedingContext";

const EditFeedingProgram = () => {
  const {
    selectedBrand,
    feedBrands,
    setSelectedBrand,
    setEditFeed,
    setAddHouseData,
    finalAddHouseData,
    feedData,
    setFeedData,
    isEditHouse,
  } = useContext(FeedingContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  const [error, setError] = useState({});
  const [tempValue, setTempValue] = useState<any>();
  const [updatedFeedData, setUpdatedFeedData] = useState<any>();
  const [hasValidationError, setHasValidationError] = useState<any>([]);
  const [quantValues, setQuantValues] = useState<any>([]);

  React.useEffect(() => {
    setTempValue([]);
  }, []);

  React.useEffect(() => {
    setTempValue(selectedBrand);
  }, [selectedBrand]);

  React.useEffect(() => {
    setUpdatedFeedData(feedData);
  }, [feedData]);

  const onSelectedSku = (id: any, no: number) => {
    let tempArr = JSON.parse(JSON.stringify(tempValue));
    let brand_id = tempArr.feed_brand_items[no].brand_id;
    let newObjArr = feedBrands?.find(
      (ele: any) => ele.id === brand_id
    )?.feed_brand_items;
    let newFeedBrand = newObjArr?.find((ele: any) => ele.id === id);
    newFeedBrand.startWeight = tempArr.feed_brand_items[no].startWeight;
    newFeedBrand.endWeight = tempArr.feed_brand_items[no].endWeight;
    newFeedBrand.key = tempArr.feed_brand_items[no].key;
    tempArr.feed_brand_items[no] = newFeedBrand;
    setTempValue({ ...tempArr });

    let tempArr2 = JSON.parse(JSON.stringify(updatedFeedData));

    let brand_id2 = tempArr2.feed_brand_items[no].brand_id;
    let newObjArr2 = feedBrands?.find(
      (ele: any) => ele.id === brand_id2
    )?.feed_brand_items;
    let newFeedBrand2 = newObjArr2?.find((ele: any) => ele.id === id);
    if (newFeedBrand2) {
      newFeedBrand2.startWeight = tempArr2.feed_brand_items[no].key
        .split("-")[0]
        .trim();
      newFeedBrand2.endWeight = tempArr2.feed_brand_items[no].key
        .split("-")[1]
        .trim();
      newFeedBrand2.key = tempArr2.feed_brand_items[no].key;
      tempArr2.feed_brand_items[no] = newFeedBrand2;
      setUpdatedFeedData({ ...tempArr2 });
    }
  };

  const managQuantity = (value: number, index: number) => {
    let arr = quantValues;
    arr[index] = value;
    setQuantValues(arr);
  };

  const onSelectedBrand = (id: any, no: number) => {
    let tempArr = JSON.parse(JSON.stringify(tempValue));
    let brandNm = feedBrands.filter((ele: { id: any }) => ele.id == id);
    let obj = {
      brand_id: id,
      nameEn: brandNm[0].nameEn,
      nameTh: brandNm[0].nameTh,
      key: tempArr.feed_brand_items[no].key,
    };
    tempArr.feed_brand_items[no] = obj;
    setTempValue({ ...tempArr });

    let tempArr2 = JSON.parse(JSON.stringify(updatedFeedData));
    let brandNm2 = feedBrands.filter((ele: { id: any }) => ele.id == id);

    let obj2 = {
      brand_id: id,
      nameEn: brandNm2[0].nameEn,
      nameTh: brandNm2[0].nameTh,
      key: tempArr2.feed_brand_items[no].key,
    };
    tempArr2.feed_brand_items[no] = obj2;
    setUpdatedFeedData({ ...tempArr2 });
  };

  const validateData = () => {
    let errorObj = { isError: false, index: 0, msg: "Please select", type: "" };
    tempValue.feed_brand_items.forEach((item: any, index: number) => {
      if (!item.brand_id) {
        errorObj.index = index;
        errorObj.isError = true;
        errorObj.type = "brand";
      } else if (!item.skuEn || !item.skuTh) {
        errorObj.index = index;
        errorObj.isError = true;
        errorObj.type = "sku";
      }
    });
    setError(errorObj);

    updatedFeedData.feed_brand_items.forEach((item: any, index: number) => {
      if (!item.brand_id) {
        errorObj.index = index;
        errorObj.isError = true;
        errorObj.type = "brand";
      } else if (!item.skuEn || !item.skuTh) {
        errorObj.index = index;
        errorObj.isError = true;
        errorObj.type = "sku";
      }
    });

    setError(errorObj);

    if (errorObj.isError) return true;
    else return false;
  };

  const onSubmit = () => {
    if (validateData()) {
    } else {
      if (setFeedData) setFeedData(updatedFeedData);
      if (setSelectedBrand) setSelectedBrand(tempValue);
      updatedFeedData.feed_brand_items = updatedFeedData?.feed_brand_items.map(
        (obj: any, i: number) => {
          const { key, ...rest } = obj;
          const finalData = { ...rest, quantity: quantValues[i] };
          return finalData;
        }
      );
      setAddHouseData &&
        setAddHouseData({ ...finalAddHouseData, feedDetails: updatedFeedData });
      // if (set)
      setEditFeed && setEditFeed(true);
      // navigate(-1);
      isEditHouse
        ? navigate("/edit-house", { state: { ...state, edited: true } })
        : navigate("/add-house", { state: { edited: true } });
      // navigate("/add-house",{state:{edited:true}})
    }
  };

  return (
    <div className="flex flex-col gap-2 h-full">
      <AppBarFS
        title={t("feedingProgram.editfeedprogram")}
        onIconPress={() => {
          setEditFeed && setEditFeed(true);
          navigate(-1);
        }}
      ></AppBarFS>
      <div className="bg-[#D9EBFE]">
        <GrowJourney
          selectedFeedBrand={tempValue}
          feedBrands={feedBrands}
          feedData={feedData}
        />
        {/* <div>
          <p className='text-[#3777BC] font-medium text-xl py-3 pl-6 capitalize'>{t('feedingProgram.feedProgram')}</p>
          {
            tempValue && tempValue?.feed_brand_items?.map((val: any, index: number) => (
              < EditSkuAndBrand
                no={index}
                feedBrands={feedBrands}
                value={val}
                selectedFeedBrand={tempValue}
                onSelectedBrand={onSelectedBrand}
                onSelectedSku={onSelectedSku}
                errorObj={error}
                feedData={feedData}
              />
            ))
          }
        </div> */}

        <div>
          <p className="text-[#3777BC] font-medium text-xl py-3 pl-6 capitalize">
            {t("feedingProgram.feedProgram")}
          </p>
          <div className="pl-6 flex">
            <svg
              width="3"
              height="18"
              viewBox="0 0 3 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {" "}
              <rect width="3" height="18" rx="1.5" fill="#DC5B5B" />{" "}
            </svg>
            <p
              className="text-[#7A1B1B] font-normal text-base py-3 pl-3"
              style={{ paddingTop: "0px" }}
            >
              {t("feedingProgram.increaseDecreaseLimit")}
            </p>
          </div>
          <div className="pl-6 flex">
            <svg
              width="3"
              height="18"
              viewBox="0 0 3 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              {" "}
              <rect width="3" height="18" rx="1.5" fill="#DC5B5B" />{" "}
            </svg>
            <p
              className="text-[#7A1B1B] font-normal text-base py-3 pl-3"
              style={{ paddingTop: "0px" }}
            >
              {t("feedingProgram.removeFeed")}
            </p>
          </div>
          {updatedFeedData &&
            updatedFeedData?.feed_brand_items?.map(
              (val: any, index: number) => (
                <EditSkuAndBrand
                  no={index}
                  feedBrands={feedBrands}
                  value={val}
                  selectedFeedBrand={tempValue}
                  onSelectedBrand={onSelectedBrand}
                  onSelectedSku={onSelectedSku}
                  errorObj={error}
                  updatedFeedData={updatedFeedData}
                  hasValidationError={hasValidationError}
                  setHasValidationError={setHasValidationError}
                  managQuantity={managQuantity}
                  key={index}
                />
              )
            )}
        </div>
        <div className="pt-4 pb-5 px-5">
          <CustomButton
            variant="tertiary"
            onClick={onSubmit}
            label={t("feedingProgram.saveButtonLabel")}
            disabled={hasValidationError.length > 0}
          />
        </div>
      </div>
    </div>
  );
};

export default EditFeedingProgram;

const Stat = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={29} height={30} fill="none">
    <path
      fill="#62A665"
      fillRule="evenodd"
      d="M16.441 8.365c1.61-.42 2.38-1.216 2.524-2.702-1.735.894-2.55.94-4.233-.026-1.46-.894-4.108-.841-3.32.966.198.546.796 1.164 1.558 1.637-5.666 2.4-7.697 7.947-7.375 11.91.52 6.12 16.493 6.515 17.709.323.94-4.877-3.418-10.925-6.863-12.108Zm-1.485 10.721v.993h-.645v-.94a3.646 3.646 0 0 1-1.584-.395l.25-.618c.348.198.914.375 1.486.375.769 0 1.235-.348 1.235-.894 0-.493-.368-.795-1.163-1.091-1.065-.375-1.71-.77-1.71-1.584 0-.743.592-1.341 1.559-1.46v-.94h.67v.894c.618.027 1.039.171 1.335.322l-.25.599c-.224-.125-.644-.296-1.335-.296-.795 0-1.09.394-1.09.769 0 .493.374.743 1.261 1.065 1.092.394 1.637.841 1.637 1.683 0 .716-.572 1.387-1.656 1.538v-.02Z"
      clipRule="evenodd"
    />
  </svg>
);
export default Stat;

import { useState } from "react";
import { Order } from "../../../components/Table/TableCustom";
import { TDropdownFarmSize } from "../../farm-detail/components/FarmDetailFilter";

export const usePartnerDetailFilterHook = () => {
  const [groupArea, setGroupArea] = useState("");
  const [saleName, setSaleName] = useState("");
  const [partnerType, setPartnerType] = useState("");
  const [searchText, setSearchText] = useState("");
  const [direction, setDirection] = useState<Order>("desc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  return {
    groupArea,
    saleName,
    partnerType,
    searchText,
    direction,
    page,
    limit,
    setGroupArea,
    setSaleName,
    setPartnerType,
    setSearchText,
    setDirection,
    setPage,
    setLimit,
  };
};

export type TUsePartnerDetailFilter = ReturnType<
  typeof usePartnerDetailFilterHook
>;

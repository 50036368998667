import NotificationsIcon from "@mui/icons-material/Notifications";
import { IconButton } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import BackgroundImage from "../../assets/images/Farms/FarmBackground.png";
import FarmFrame from "../../assets/images/Farms/FarmFrame.svg";
import TrackterIcon from "../../assets/images/Farms/Trackter.svg";
import ArrowIcon from "../../assets/images/Farms/arrow_Icon.svg";
import dollarImg from "../../assets/images/Farms/dollar-img.svg";
import GreenPig from "../../assets/images/Farms/green-pig.svg";
import FarmIcon from "../../assets/images/OnboardingImages/FarmIcon.svg";
import topIcon from "../../assets/images/OnboardingImages/top_img-2.svg";
import topIcon3 from "../../assets/images/OnboardingImages/top_img-3.svg";
import { getFarmOverviewService } from "../../services/FarmService";
import "../../styles/farms.scss";
import DataChart from "./DataChart";
import PigPrice from "./PigPrice";
const RegisterFarms = () => {
  const { t } = useTranslation();
  const [farmOverviewData, setFarmOverviewData] = useState([]);
  useEffect(() => {
    getfarmOverviewData();
  }, []);

  const getfarmOverviewData = async () => {
    const resp = await getFarmOverviewService();
    if (resp.status === 200) {
      let tempData: any = resp.data.feedintake || [];
      if (tempData?.length > 0) {
        let tempPData: any = [];
        tempData.forEach((item: any, i: number) => {
          tempData[i].logDate = dayjs(tempData[i].logDate).format("DD/MM");
          tempPData.push(item);
        });
        setFarmOverviewData(tempPData);
      }
    }
  };
  const navigate = useNavigate();
  return (
    <div>
      {" "}
      <div className="flex ">
        <div className="flex-grow">
          <div
            className={`top-img-container bg-gray-200 flex flex-col bg-cover relative`}
            style={{
              backgroundColor: "rgb(229 231 235 / 0)",
              backgroundImage: `url(${BackgroundImage})`,
            }}
          >
            <img
              src={topIcon}
              className="w-[65%] top-10 right-4 opacity-25 absolute"
            />
            <img
              src={topIcon3}
              className="topIcon1 w-[20%] top-[19%] right-12 opacity-25 absolute"
            />
            <div className="flex w-11/12 relative justify-between items-center top-6 z-1 mx-auto">
              <img src={FarmIcon} className="w-[60%]" />
              <Link to={"/notifications"}>
                <IconButton className="relative">
                  <NotificationsIcon className="notification-icon" />
                  <span className="absolute w-[7px] h-[7px] bg-[#F57C00] rounded-full top-[12px] left-[12px]"></span>
                </IconButton>
              </Link>
            </div>
            <div className="farms-headTitle-container flex relative justify-left top-6 z-1 ml-4 mt-9">
              <h1 className="farms-headTitle text-[#ffff]">
                {t("farms.welcomeTitle")}
              </h1>
            </div>
            <div
              className="h-[32%] w-11/12 mt-[35px] m-auto flex flex-col bg-white-200 border-l border-r py-[10px] px-[25px] rounded-[30px] bg-[#ffffff] transform "
              style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
            >
              <div className="flex justify-start mt-[10px]">
                <img
                  src={TrackterIcon}
                  alt="LocationIcon"
                  className="w-6 mb-2"
                />
                <h1 className="farms-top-box-text text-[19px] font-bold text-[#3777BC] ml-2">
                  {t("farms.farmsCountText")}
                </h1>
              </div>
              <div className="farms-top-box w-11/12 my-auto flex justify-between loading-[18px] gap-5">
                <div>
                  <p className="farms-top-box-text text-[20px] font-bold text-[#34407B]">
                    100,000
                  </p>
                  <p className="farms-top-box-text text-[#34407B] text-start">
                    {t("farms.farmsText1")}
                  </p>
                </div>
                <div>
                  <p className="farms-top-box-text text-[20px] font-bold text-[#34407B]">
                    200,000
                  </p>
                  <p className="farms-top-box-text text-[#34407B] text-start">
                    {t("farms.farmsText2")}
                  </p>
                </div>
                <div>
                  <p className="farms-top-box-text text-[20px] font-bold text-[#34407B]">
                    300,000
                  </p>
                  <p className="farms-top-box-text text-[#34407B] text-start">
                    {t("farms.farmsText3")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* farms overview */}
          <div className="farms-overview-head w-full bg-white-200 border-l border-r transform mt-[-60px]">
            <h1 className="farms-overview-head-text text-[25px] flex justify-start ml-4 text-[#34407B] font-semibold">
              {t("farms.overviewTitle")}
            </h1>
            <p className="farms-manage-text text-[#747474] flex justify-start ml-4">
              {t("farms.farmOverviewSubTitle" as any)}
            </p>
            <DataChart />
            <div className="flex justify-between w-11/12 mt-5 m-auto">
              <div className="bg-[#EEF2FF] rounded-xl	flex w-[35%] p-[10px] items-end">
                <div className="h-full">
                  <div className="text-[#3777BC] text-[16px] font-semibold">
                    {t("farms.deadPigCountLabel")}
                  </div>
                  <div className="text-[#34407B] text-[24px] font-medium">
                    {t("farms.deadPigCount")}
                  </div>
                </div>
                <img src={GreenPig} alt="pig-icon" />
              </div>
              <div className="bg-[#EEF2FF] rounded-xl	p-[10px] w-[62%]">
                <div className="text-[#3777BC] text-[16px] text-start">
                  <span className="font-semibold">
                    {t("farms.incomeEstimateText")}
                  </span>
                  <br />
                  {t("farms.nearSaleText")} :{" "}
                  <span className="font-semibold">
                    {t("farms.nearSaleValue")}
                  </span>
                </div>
                <div className="flex items-center gap-2">
                  {" "}
                  <span className="bg-[#3777BC] text-[#ffff] rounded-full px-2 text-[10px] whitespace-nowrap">
                    {t("farms.incomeEstimateDate")}
                  </span>
                  <div className="text-[12px] text-[#3777BC]">
                    {t("farms.announcedPriceText")}
                  </div>
                </div>
                <div className="flex justify-between">
                  {" "}
                  <div className="text-[#34407B] text-[24px] font-medium">
                    {t("farms.announcedPriceValue")}
                  </div>
                  <img src={dollarImg} alt="dollar-icon" />
                </div>
              </div>
            </div>
          </div>
          {/* // */}
          {/*manage farms*/}
          <div className="mt-[50px] w-full bg-white-200 border-l border-r transform">
            <div className="flex justify-between">
              <h1 className="farms-overview-head-text text-[25px] flex justify-start ml-4 text-[#34407B] font-semibold">
                {t("farms.manageFarmTitle")}
              </h1>
              <div className="flex justify-around mr-[15px]">
                <h3 className="farms-manage-text text-[grey] text-[17px] flex items-center mr-2">
                  {t("farms.allFarmsText")}{" "}
                </h3>
                <img src={ArrowIcon} alt="ArrowIcon" className="w-3" />
              </div>
            </div>
            <h1 className="farms-manage-text flex justify-start text-[grey] text-[14px] ml-4  ">
              {t("farms.manageFarmText")}
            </h1>
            {/* <ManageFarm /> */}
            <button
              className="px-[26px] py-[10px] rounded-[40px] border m-auto flex border-green-500 mt-10 font-bold text-[#68C184]"
              onClick={() => navigate("/add-farm")}
            >
              {t("farms.buttonAddFarm")}
            </button>
          </div>
          {/* // */}
          {/*Financial Support */}
          <div className="mt-[50px] w-full bg-white-200 border-l border-r transform">
            <h1 className="farms-overview-head-text text-[25px] flex justify-start ml-4 text-[#34407B] font-semibold">
              {t("farms.financialSupportTitle")}
            </h1>
            <h1 className="farms-manage-text flex justify-start text-[grey] text-[14px] ml-4">
              {t("farms.commonSubTitle")}
            </h1>
            <img
              src={FarmFrame}
              alt="FarmFrame"
              className=" w-[95%] mx-auto px-[15px] py-[20px]"
            />
          </div>
          {/* // */}
          {/*pig Price */}
          <div className="mt-[50px] w-full bg-white-200 border-l border-r transform pb-[35vw]">
            <h1 className="farms-overview-head-text text-[25px] flex justify-start ml-4 text-[#34407B] font-semibold">
              {t("farms.pigPriceTitle")}
            </h1>
            <h1 className="farms-manage-text flex justify-start text-[grey] text-[14px] ml-4">
              {t("farms.commonSubTitle")}
            </h1>
            <PigPrice />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterFarms;

import adminAPI from "../../../../services/AdminApiService";
import { IGetDropdownGroupArea } from "../types/dropdownGroupArea.type";

export const getDropdownGroupArea = async () => {
  try {
    const { data } = await adminAPI.get<IGetDropdownGroupArea>(
      "/farm-detail/dropdown/group-area"
    );
    return data;
  } catch (error) {
    return null;
  }
};

import { memo, useCallback, useEffect, useState } from "react";
import { tw } from "../../../../utils/tw";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import CrossIcon from "../../../assets/svg/CrossIcon.svg";
import { Input } from "../../group-area/views/Input";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import useFormValidator from "../../../../hooks/useFormValidator.hook";
import * as Y from "yup";
import { Dropdown, TDropdownValue } from "../../group-area/views/Dropdown";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createFeed } from "../../../../services/FeedFoodApiService";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { loadingActions } from "../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../utils/getErrorMessage";
import get from "lodash/get";
import { alertAction } from "../../../../redux/slices/alertSlice";
import { isEmpty } from "lodash";
import { IFeedFoodBrandsResponse } from "../../../../types/FoodFeed.type";

interface IAddModalProps {
  isOpen: boolean;
  onCloseFn: () => void;
  refetchFeeds: () => void;
  brandsOptions: TDropdownValue[];
  brands: IFeedFoodBrandsResponse["result"];
  brand: TDropdownValue[];
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  container: tw(
    `w-[100vw] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary [&_.MuiDialog-paperScrollPaper]:!m-0 max-w-[490px]`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  row: tw(
    `w-full flex flex-col md:flex-row item-center justify-between gap-x-3`
  ),
  space: tw(`h-[12px]`),
};

type TForm = {
  brandName: TDropdownValue[];
  nameTH: string;
  nameEN: string;
  noTH: string;
  noEN: string;
  startWeight: string;
  endWeight: string;
  protein: string;
  consumption: string;
  unit: TDropdownValue[];
  price: string;
};

const defaultForm = {
  brandName: [],
  nameTH: "",
  nameEN: "",
  noTH: "",
  noEN: "",
  startWeight: "",
  endWeight: "",
  protein: "",
  consumption: "",
  unit: [],
  price: "",
};

const AddModal = (props: IAddModalProps) => {
  const { isOpen, onCloseFn, refetchFeeds, brandsOptions, brands, brand } =
    props;

  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();
  const [form, setForm] = useState<TForm>(defaultForm);

  const createMutation = useMutation({
    mutationFn: createFeed,
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ["food-feed", "brands"] });
      refetchFeeds();
      setTimeout(() => {
        dispatch(loadingActions.hide());
        onClose();
        setTimeout(() => {
          dispatch(
            snakeActions.showMessage({
              message: "เพิ่มอาหารสัตว์สำเร็จ",
              type: "success",
            })
          );
          setForm(defaultForm);
        }, 500);
      }, 1500);
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const { onSubmit, errors, resetError } = useFormValidator({
    errorSchema: Y.object({
      brandName: Y.array().required().min(1, "กรุณาเลือกแบรนด์อาหารสัตว์"),
      nameTH: Y.string().required("กรุณากรอกแบรนด์อาหารสัตว์ (TH)"),
      nameEN: Y.string().required("กรุณากรอกแบรนด์อาหารสัตว์ (EN)"),
      noTH: Y.string().required("กรุณากรอกเบอร์อาหารสัตว์ (TH)"),
      noEN: Y.string().required("กรุณากรอกเบอร์อาหารสัตว์ (EN)"),
      startWeight: Y.string().required("กรุณากรอกน้ำหนักเริ่มต้น"),
      endWeight: Y.string().required("กรุณากรอกน้ำหนักสูงสุด"),
      protein: Y.string().required("กรุณากรอกโปรตีน"),
      consumption: Y.string().required("กรุณากรอกการกินได้"),
      unit: Y.array().required().min(1, "กรุณาเลือกหน่วย"),
      price: Y.string().required("กรุณากรอกราคาต่อหน่อย"),
    }),
    form,
    onValidateSuccess: () => {
      dispatch(
        alertAction.showDialog({
          title: "ยืนยันการบันทึก",
          text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
          cancelText: "กลับไปแก้ไข",
          confirmText: "ยืนยัน",
          onCancel: () => {
            dispatch(alertAction.hide());
          },
          onSubmit: async () => {
            dispatch(alertAction.hide());
            dispatch(
              loadingActions.show({ message: "กำลังเพิ่มอาหารสัตว์..." })
            );
            createMutation.mutate({
              feedBrandId:
                form.brandName[0].value === "otherOption"
                  ? ""
                  : form.brandName[0].value,
              feedBrandName: form.nameTH,
              feedBrandNameEn: form.nameEN,
              feedBrandNumber: form.noTH,
              feedBrandNumberEn: form.noEN,
              startWeight: +form.startWeight || 0,
              endWeight: +form.endWeight || 0,
              protein: +form.protein || 0,
              consumption: +form.consumption || 0,
              orderFeedActive: true,
              pricePerUnit: +form.price || 0,
              unit: get(form, ["unit", 0, "value"]) as "kg" | "bag",
            });
          },
        })
      );
    },
  });

  const onChangeForm = useCallback(
    (key: keyof typeof form) => (value: any) => {
      setForm((prev) => ({ ...prev, [key]: value }));
    },
    []
  );

  const onClose = useCallback(() => {
    onCloseFn();
    setForm(defaultForm);
    resetError();
  }, []);

  useEffect(() => {
    if (form.brandName) {
      if (form.brandName[0]?.value === "otherOption") {
        setForm((prev) => ({
          ...prev,
          nameTH: "",
          nameEN: "",
        }));
      } else {
        const checkBrandName = brands.filter(
          (i) => i.id === form.brandName[0]?.value
        );

        setForm((prev) => ({
          ...prev,
          nameTH: checkBrandName[0]?.nameTh,
          nameEN: checkBrandName[0]?.nameEn,
        }));
      }
    }
  }, [form.brandName]);

  useEffect(() => {
    if (isOpen) {
      if (brand) {
        const checkBrandName = brands.filter((i) => i.id === brand[0]?.value);

        if (checkBrandName.length > 0) {
          setForm((prev) => ({
            ...prev,
            brandName: [
              {
                label: checkBrandName[0]?.nameTh,
                value: checkBrandName[0]?.id,
              },
            ],
            nameTH: checkBrandName[0]?.nameTh,
            nameEN: checkBrandName[0]?.nameEn,
          }));
        }
      }
    }
  }, [brand, isOpen]);

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: "w-full" }}
    >
      <DialogContent>
        <div className={classes.titleContainer}>
          <p className={classes.title}>เพิ่มอาหารสัตว์</p>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </div>
        <div className={tw(classes.row, "mt-3")}>
          <Dropdown
            label="เเบรนด์อาหารสัตว์"
            options={brandsOptions}
            onChange={onChangeForm("brandName")}
            renderValueArray={form.brandName}
            errorMessage={errors.brandName}
          />
        </div>
        <div className={tw(classes.row, "mt-3")}>
          <Input
            label="ชื่อเเบรนด์อาหารสัตว์ (TH)"
            onChangeText={onChangeForm("nameTH")}
            value={form.nameTH}
            errorMessage={errors.nameTH}
            disabled={
              isEmpty(form.brandName) ||
              !(form.brandName[0]?.value === "otherOption")
            }
          />
          <Input
            label="ชื่อเเบรนด์อาหารสัตว์ (EN)"
            onChangeText={onChangeForm("nameEN")}
            value={form.nameEN}
            errorMessage={errors.nameEN}
            disabled={
              isEmpty(form.brandName) ||
              !(form.brandName[0]?.value === "otherOption")
            }
          />
        </div>
        <div className={tw(classes.row, "mt-3")}>
          <Input
            label="เบอร์อาหารสัตว์ (TH)"
            onChangeText={onChangeForm("noTH")}
            value={form.noTH}
            errorMessage={errors.noTH}
          />
          <Input
            label="เบอร์อาหารสัตว์ (EN)"
            onChangeText={onChangeForm("noEN")}
            value={form.noEN}
            errorMessage={errors.noEN}
          />
        </div>
        <div className={tw(classes.row, "mt-3")}>
          <Input
            label="น้ำหนักเริ่มต้น"
            onChangeText={onChangeForm("startWeight")}
            value={form.startWeight}
            errorMessage={errors.startWeight}
            inputType="number"
          />
          <Input
            label="น้ำหนักสูงสุด"
            onChangeText={onChangeForm("endWeight")}
            value={form.endWeight}
            errorMessage={errors.endWeight}
            inputType="number"
          />
        </div>
        <div className={tw(classes.row, "mt-3")}>
          <Input
            label="โปรตีน"
            onChangeText={onChangeForm("protein")}
            value={form.protein}
            errorMessage={errors.protein}
            inputType="number"
          />
          <Input
            label="การกินได้"
            onChangeText={onChangeForm("consumption")}
            value={form.consumption}
            errorMessage={errors.consumption}
            inputType="number"
          />
        </div>
        <div className={tw(classes.row, "mt-3")}>
          <Dropdown
            label="หน่วย"
            options={[
              { label: "กิโลกรัม", value: "kg" },
              { label: "ถุง", value: "bag" },
            ]}
            onChange={onChangeForm("unit")}
            renderValueArray={form.unit}
            errorMessage={errors.unit}
          />
          <Input
            label="ราคาต่อหน่วย"
            onChangeText={onChangeForm("price")}
            value={form.price}
            errorMessage={errors.price}
            inputType="number"
          />
        </div>
        <div className="w-full flex justify-end items-end mt-3">
          <ButtonFillCustom title="ยืนยัน" onClick={onSubmit} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(AddModal);

const PencilAltSize20pxSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
    <path
      fill="#68C184"
      d="M17.042 2.955a1.9 1.9 0 0 0-2.687 0L7.148 10.16v2.687h2.687l7.207-7.206a1.9 1.9 0 0 0 0-2.687Z"
    />
    <path
      fill="#68C184"
      fillRule="evenodd"
      d="M2.398 6.198c0-1.049.851-1.9 1.9-1.9h3.8a.95.95 0 0 1 0 1.9h-3.8v9.5h9.5v-3.8a.95.95 0 0 1 1.9 0v3.8a1.9 1.9 0 0 1-1.9 1.9h-9.5a1.9 1.9 0 0 1-1.9-1.9v-9.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default PencilAltSize20pxSvg;

import React, { memo } from "react";
import CheckIcon from "@mui/icons-material/Done";
import { tw } from "../../../utils/tw";

interface IStepsProps {
  steps: {
    label: string;
    status?: "active" | "progress" | "idle";
  }[];
  isCanceled: boolean;
  containerClassName?: string;
}

const classes = {
  container: tw(`flex flex-row items-start justify-between relative`),
  // TODO: will handle line color follow status later
  line: tw(`absolute top-[9px] left-0 w-full h-[4px] bg-[#EBEBEB] -z-10`),
  itemContainer: tw(
    `flex flex-col justify-between text-center text-[14px] leading-[18px]`
  ),
  dot: tw(
    `w-[22px] h-[22px] rounded-full bg-[#EBEBEB] mb-[18px] self-center flex justify-center items-center`
  ),
};

const Steps = (props: IStepsProps) => {
  const { steps, isCanceled, containerClassName } = props;

  return (
    <div className={tw(classes.container, containerClassName)}>
      <div className={classes.line} />
      {steps.map(({ label, status }, index) => {
        const isFirst = index === 0;
        const isLast = index === steps.length - 1;
        const offset = 5;

        return (
          <div
            key={`${index}`}
            style={{
              width:
                !isFirst && !isLast
                  ? `${100 / steps.length + offset}%`
                  : `${100 / steps.length - offset / steps.length}%`,
            }}
            className={tw(classes.itemContainer)}
          >
            <div
              className={tw(
                "flex self-center bg-[white] px-1.5",
                isFirst && "self-start",
                isLast && "self-end"
              )}
            >
              <div
                className={tw(
                  classes.dot,
                  (status === "active" || status === "progress") &&
                    !isCanceled &&
                    "bg-secondary",
                  (status === "active" || status === "progress") &&
                    isCanceled &&
                    "bg-status-bad"
                )}
              >
                {status === "active" && (
                  <CheckIcon className="text-[white] !w-[16px]" />
                )}
              </div>
            </div>
            <div
              className={tw(
                "text-center",
                isFirst && "text-left",
                isLast && "text-right"
              )}
            >
              {label}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default memo(Steps);

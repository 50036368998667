import React, { memo } from "react";
import { get, isEmpty } from "lodash";
import { CircularProgress } from "@mui/material";
import CardList from "../CardList";
import { IGroupAreaGroupListColumnResponse } from "../../../../../types/GroupArea.type";
import { tw } from "../../../../../utils/tw";
import { TColumnSelected } from "../../defaultForm";
import { TDropdownValue } from "../Dropdown";

interface ICardTabProps {
  groupColumnSelected: IGroupAreaGroupListColumnResponse["userGroups"]["rows"][number] & {
    value: string;
    label: string;
  };
  groupAreaOptions: (IGroupAreaGroupListColumnResponse["userGroups"]["rows"][number] & {
    value: string;
    label: string;
  })[];
  options: {
    areaOptions: {
      value: string;
      label: string;
    }[];
    provinceOptions: {
      value: string;
      label: string;
      areaCode: string;
    }[];
    districtOptions: {
      value: string;
      label: string;
      provinceCode: string;
    }[];
    employeeOptions: {
      value: string;
      label: string;
    }[];
    partnerOptions: {
      value: string;
      label: string;
    }[];
    allProvinceOptions: {
      value: string;
      label: string;
      areaCode: string;
    }[];
    allDistrictOptions: {
      value: string;
      label: string;
      provinceCode: string;
    }[];
  };
  columnSelected: TColumnSelected;
  onSelectColumn: (
    key: keyof TColumnSelected,
    isSingle?: boolean
  ) => (value: TDropdownValue) => void;
  isLoadingAreaSelected: boolean;
}

const classes = {
  columnContainer: tw(`w-full flex flex-row overflow-x-auto mt-6 gap-x-3`),
  cardContainer: tw(`flex flex-row p-[12px] bg-[#F6FBFF] rounded-[10px]`),
  cardTitle: tw(`bg-[transparent] border-t-0`),
  cardItemContainer: tw(
    `px-[12px] border-t-[1px] border-l-0 rounded-l-none rounded-r-none bg-white`
  ),
};

const CardTab = (props: ICardTabProps) => {
  const {
    groupColumnSelected,
    groupAreaOptions,
    options,
    columnSelected,
    onSelectColumn,
    isLoadingAreaSelected,
  } = props;

  return (
    <div className={classes.columnContainer}>
      <CardList
        title={`ภาค (${options.areaOptions.length})`}
        items={options.areaOptions}
        selectedItems={columnSelected.area}
        onSelect={onSelectColumn("area", true)}
      />
      {isLoadingAreaSelected ? (
        <div className="w-[170px] h-[234px] flex justify-center items-center">
          <CircularProgress color="secondary" size={24} />
        </div>
      ) : (
        <>
          {groupAreaOptions.length > 0 && (
            <CardList
              title={`ชื่อกลุ่ม (${groupAreaOptions.length})`}
              items={groupAreaOptions}
              selectedItems={columnSelected.group}
              onSelect={onSelectColumn("group", true)}
            />
          )}
        </>
      )}
      {!isEmpty(get(columnSelected.group, 0)) && (
        <div className={classes.cardContainer}>
          <CardList
            title={`จังหวัด (${
              get(groupColumnSelected, "userGroupProvinces", []).length
            })`}
            items={get(groupColumnSelected, "userGroupProvinces", []).map(
              ({ id, province }) => ({
                value: `${id}`,
                label: province.nameTh,
              })
            )}
            selectedItems={[]}
            onSelect={() => {}}
            containerClassName={tw(
              "px-[12px] border-t-[1px] rounded-r-none bg-white"
            )}
            titleClassName={classes.cardTitle}
            disabled
          />
          <CardList
            title={`เขต/อำเภอ (${
              get(groupColumnSelected, "userGroupDistricts", []).length
            })`}
            items={get(groupColumnSelected, "userGroupDistricts", []).map(
              ({ id, district }) => ({
                value: `${id}`,
                label: district.nameTh,
              })
            )}
            selectedItems={[]}
            onSelect={() => {}}
            containerClassName={classes.cardItemContainer}
            titleClassName={classes.cardTitle}
            disabled
          />
          <CardList
            title={`พนักงานขาย (1)`}
            items={[
              {
                value: get(groupColumnSelected, ["seller", "id"], ""),
                label: `${get(groupColumnSelected, ["seller", "name"], "")} ${
                  groupColumnSelected.seller.surname || ""
                }`,
              },
            ]}
            selectedItems={[]}
            onSelect={() => {}}
            containerClassName={classes.cardItemContainer}
            titleClassName={classes.cardTitle}
            disabled
          />
          <CardList
            title={`ชื่อพาร์ทเนอร์ (1)`}
            items={[
              {
                value: get(groupColumnSelected, ["partner", "id"], ""),
                label: `${get(
                  groupColumnSelected,
                  ["partner", "name"],
                  ""
                )} ${get(groupColumnSelected, ["partner", "surname"], "")}`,
              },
            ]}
            selectedItems={[]}
            onSelect={() => {}}
            containerClassName={classes.cardItemContainer}
            titleClassName={classes.cardTitle}
            disabled
          />
          <CardList
            title={`ชื่อลูกค้า (${
              get(groupColumnSelected, "farmers", []).length
            })`}
            items={get(groupColumnSelected, "farmers", []).map(
              ({ id, name, surname }) => ({
                value: `${id}`,
                label: `${name} ${surname}`,
              })
            )}
            selectedItems={[]}
            onSelect={() => {}}
            containerClassName={classes.cardItemContainer}
            titleClassName={classes.cardTitle}
            disabled
          />
        </div>
      )}
    </div>
  );
};

export default memo(CardTab);

import React, { useState } from "react";
import { BaseModal } from "../../../order-feed/OrderFeedBaseModal";
import { Button } from "@mui/material";

type TConfirmModalProps = {
  onOpenModal: boolean;
  onCloseModal: (isSuccess: boolean) => void;
};

const ConfirmModal = ({ onOpenModal, onCloseModal }: TConfirmModalProps) => {
  return (
    <BaseModal
      isOpen={onOpenModal}
      handleClose={() => onCloseModal(false)}
      iconCloseModal={true}
    >
      <div className="flex  flex-col text-center">
        <p className="text-[24px] font-medium items-center text-warning mb-4">
          ยืนยันแก้ไขวันจัดส่ง
        </p>
        <p className="text-[16px]">คุณแน่ใจหรือไม่ว่า</p>
        <p className="text-[16px]">ต้องการแก้ไขวันจัดส่ง ?</p>

        <Button
          size="large"
          variant="outlined"
          sx={{
            marginTop: "64px",
            color: "#68C184",
            borderColor: "#68C184",
            width: "100%",
            ":hover": {
              border: "1px solid #68C184",
            },
            ":active": {
              border: "1px solid #68C184",
            },
          }}
          onClick={() => onCloseModal(false)}
        >
          ยกเลิก
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={() => onCloseModal(true)}
          sx={{
            marginTop: "16px",
            color: "#ffff",
            bgcolor: "#68C184",
            width: "100%",
            ":hover": {
              color: "#FFFFFF",
              border: "1px solid #68C184",
              bgcolor: "#68C184",
            },
            ":active": {
              color: "#FFFFFF",
              border: "1px solid #68C184",
              bgcolor: "#68C184",
            },
          }}
        >
          ยืนยัน
        </Button>
      </div>
    </BaseModal>
  );
};

export default ConfirmModal;

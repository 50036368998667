import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useCallback, useEffect, useState } from "react";
import FooterImg from "../../../assets/images/Farms/footer_img.svg";
import { useNavigate } from "react-router-dom";
import FooterBlueBtn from "../../../assets/images/Farms/footer-blue-btn.svg";
import CartSVG from "../../../assets/svg/Cart";
import UserSVG from "../../../assets/svg/User";
import UserGroupSvg from "../../../assets/svg/UserGroupSvg";
import "./Footer.scss";
import FooterPartnerSvg from "../../../assets/svg/FooterPartnerSvg";
import IconHomeSvg from "../../../assets/svg/IconHomeSvg";
import IconPersonSvg from "../../../assets/svg/IconPersonSvg";
import IconBagOfCoinSvg from "../../../assets/svg/IconBagOfCoinSvg";
import IconPigFeedSvg from "../../../assets/svg/IconPigFeedSvg";
import IconMyStockSvg from "../../../assets/svg/IconMyStockSvg";

const FooterPartner = ({ selectedTab = 0, handleChange }: any) => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const onNavigate = useCallback(
    (path: string) => () => {
      navigate(path);
    },
    []
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(selectedTab);
  }, [selectedTab]);

  return (
    <>
      <div
        className="footer-container fixed bottom-0 w-[95%] ml-[10px] md:ml-[20px] items-center flex justify-center  bg-cover"
        style={{ backgroundImage: `url(${FooterImg})` }}
      >
        <Tabs
          value={value}
          onChange={handleTabChange}
          className="bottom-0 footer-tabs md:mt-4 w-full"
          aria-label="icon tabs example"
          variant="fullWidth"
        >
          <Tab
            className="footer-btn "
            icon={
              <div className="text-[#FFFFFF]">
                <IconHomeSvg />
              </div>
            }
            aria-label="phone"
          />
          <Tab
            className="footer-btn md:!mr-[6%] !mr-[5%]"
            icon={<UserGroupSvg />}
            aria-label="favorite"
          />
          <Tab
            className="footer-btn"
            icon={<IconMyStockSvg />}
            aria-label="person"
            onClick={onNavigate("/partner/stock-details")}
          />
          <Tab
            className="footer-btn"
            icon={<IconPersonSvg />}
            onClick={onNavigate("/partner/account-center")}
          />
        </Tabs>
        <div
          className="footer-blue-img absolute  bottom-[50%] cursor-pointer"
          onClick={onNavigate("/order")}
        >
          <FooterPartnerSvg />
        </div>
      </div>
    </>
  );
};
export default FooterPartner;

import React from "react";
import {
  InputAttributes,
  NumericFormat,
  NumericFormatProps,
} from "react-number-format";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: any;
  prefix?: string;
  customInput?: React.ComponentType<InputAttributes>;
}

export const InputNumberStringNoDouble = React.forwardRef<
  NumericFormatProps,
  CustomProps
>(function NumericFormatCustom(props, ref) {
  const { onChange, value, prefix, customInput, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      type="text"
      inputMode="numeric"
      value={value}
      decimalScale={0}
      fixedDecimalScale={false}
      thousandSeparator=","
      allowLeadingZeros={false}
      valueIsNumericString
      prefix={prefix ?? ""}
      allowNegative={false}
      customInput={customInput}
    />
  );
});

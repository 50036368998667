import { TextField, TextFieldProps } from "@mui/material";

export default function TextInputHouse(props: TextFieldProps) {
  return (
    <TextField
      {...props}
      color="tertiary"
      variant="outlined"
      sx={{}}
      InputProps={{
        sx: {
          borderRadius: "10px",
          fontWeight: 500,
          color: "#191919",
          background: "#F7FBFF",
          borderColor: "#97BFEA",
          height: "40px",
          minWidth: "80px",
        },
        ...props?.InputProps,
      }}
    />
  );
}

import { createTheme } from "@mui/material/styles";

const themeCustom = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1170,
      xl: 1530,
    },
  },
});

export const theme = createTheme({
  palette: {
    primary: {
      main: "#34407B",
    },
    secondary: {
      main: "#68C184",
      contrastText: "white",
    },
    tertiary: {
      main: "#3777BC",
      light: "#D9EBFE",
      contrastText: "white",
    },
    info: {
      main: "#3777BC",
      light: "#D9EBFE",
      contrastText: "white",
    },
    warning: {
      main: "#F57C00",
      light: "#F57C00",
      contrastText: "#F57C00",
    },
  },
  typography: {
    fontFamily: [
      "IBM Plex Sans Thai",
      "Roboto",
      "Arial",
      '"Apple Color Emoji"',
    ].join(","),
    h5: {
      fontWeight: 500,
      color: "#68C184",
      [themeCustom.breakpoints.up("sm")]: {
        fontSize: 20,
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === "large" && {
            // Override styles when
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }),
          ...(ownerState.size === "medium" && {
            // Override styles when
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
            paddingLeft: "0.75rem",
            paddingRight: "0.75rem",
          }),
          borderRadius: "28px",
          textTransform: "none",
        }),
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: "#3777BC",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderColor: "#3777BC",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorWarning: {
          backgroundColor: "#FEF9F2",
        },
        colorPrimary: {
          backgroundColor: "#E2E2E2",
        },
        colorSuccess: {
          backgroundColor: "#E9FFEF",
        },
        colorError: {
          backgroundColor: "#FDF5F5",
        },
      },
    },
  },
});

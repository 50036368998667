import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getAcceptedAssignment,
  getDetailsAcceptedAssignment,
  getUpdateAcceptedAssignment,
} from "../../../services/Invoice.service";
import { get } from "lodash";
import { TDropdownValue } from "../../../admin/pages/group-area/views/Dropdown";

export const useAcceptedAssignmentController = () => {
  const [invoice, setInvoice] = useState<TDropdownValue[]>([]);

  const { data: listAcceptedAssignment, refetch: refetchAcceptedAssignment } =
    useQuery({
      queryKey: ["list_accepted_assignment"],
      queryFn: async () => {
        const result = await getAcceptedAssignment();
        return result;
      },
    });

  const [isShowDetailsAssignment, setIsShowDetailsAssignment] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const handleOnClick = (id: string) => {
    onShowDetailsAssignment(id);
  };

  const onShowDetailsAssignment = useCallback((id: string) => {
    setSelectedId(id);
    setIsShowDetailsAssignment(true);
  }, []);

  const onHideDetailsAssignment = useCallback(() => {
    setSelectedId("");
    setIsShowDetailsAssignment(false);
  }, []);

  const {
    data: DetailsAcceptedAssignment,
    refetch: RefetchDetailsAcceptedAssignment,
  } = useQuery({
    queryKey: ["details_accepted_assignment"],
    enabled: !!selectedId,
    queryFn: async () => {
      const result = await getDetailsAcceptedAssignment({
        assignmentId: selectedId,
      });
      return result;
    },
  });

  const {
    data: UpdateAcceptedAssignment,
    refetch: RefetchUpdateAcceptedAssignment,
  } = useQuery({
    queryKey: ["details_accepted_update_assignment"],
    enabled: !!selectedId,
    queryFn: async () => {
      const result = await getUpdateAcceptedAssignment({
        assignmentId: selectedId,
      });
      return result;
    },
  });

  const InvoiceOption = useMemo(() => {
    return get(UpdateAcceptedAssignment, ["invoiceAssignmentItems"], []).map(
      ({ id, invoiceId, invoice }) => ({
        label: invoice.invoiceNumber,
        value: invoiceId,
      })
    );
  }, [UpdateAcceptedAssignment]);

  return {
    listAcceptedAssignment,
    refetchAcceptedAssignment,
    DetailsAcceptedAssignment,
    isShowDetailsAssignment,
    onShowDetailsAssignment,
    onHideDetailsAssignment,
    InvoiceOption,
    invoice,
    setInvoice,
    selectedId,
    RefetchUpdateAcceptedAssignment,
    RefetchDetailsAcceptedAssignment,
    handleOnClick,
  };
};

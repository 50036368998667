import React from 'react'
import { get } from 'lodash'
import { tw } from '../../../utils/tw'
import AppBarFS from '../../../components/app-bar/AppBar'
import { useNewOrderController } from './controller'
import FilterOrder from '../../feed-order-and-delivery/my-order/my-order-component/filter/FilterOrder'
import OrderItem from '../views/OrderItem'
import Dialog from '../views/Dialog'
import { dialogWord } from '../constants'
import { useActionController } from '../actionController'

const classes = {
  container: tw(`flex flex-col min-h-screen w-full`),
  appbarContainer: tw(`py-4`),
  orderListContainer: tw(`flex flex-col w-full px-[16px]`),
}

const NewOrder = (props: { isHistory?: boolean }) => {
  const { isHistory = false } = props
  const {
    orderList,
    onBack,
    onMoreDetail,
    orderBy,
    setOrderBy,
    onAction,
    onCloseAction,
    actionModalDetail,
    isShowActionModal,
  } = useNewOrderController(isHistory)

  const {
    actionFunctions,
  } = useActionController({
    orderDetail: actionModalDetail,
    onCloseModal: onCloseAction,
  })

  return (
    <div className={classes.container}>
      <div className={classes.appbarContainer}>
        <AppBarFS title={!isHistory ? "ออเดอร์ใหม่" : "ประวัติออเดอร์"} onIconPress={onBack} />
      </div>
      <FilterOrder
        title="ออเดอร์ทั้งหมด"
        titleContainer="flex flex-1"
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
      <div className={classes.orderListContainer}>
        {(orderList || []).map((order, index) => (
          <OrderItem
            key={order.id}
            {...order}
            index={index}
            onMoreDetail={onMoreDetail(order.id)}
            onAction={onAction(order)}
          />
        ))}
      </div>
      <Dialog
        isOpen={isShowActionModal}
        title={get(dialogWord, [actionModalDetail?.status || '-', 'title'], '-')}
        text={get(dialogWord, [actionModalDetail?.status || '-', 'text'], '-')}
        confirmText="ยืนยัน"
        onClose={onCloseAction}
        onConfirm={actionFunctions[actionModalDetail?.status || 'new']}
      />
    </div>
  )
}

export default NewOrder

const IconPigWithBgSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={41} height={42} fill="none">
    <rect width={41} height={41} y={0.129} fill="#EFB2B2" rx={20.5} />
    <mask
      id="a"
      width={27}
      height={28}
      x={7}
      y={7}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M7.5 7.129h26v27h-26z" />
    </mask>
    <g fill="#fff" clipPath="url(#b)">
      <path d="M31.805 28.468h-1.11c-.049-.567-.097-1.126-.147-1.685-.1-1.097-.479-1.575-1.488-1.909-.866-.286-1.614-.782-2.271-1.428-.09-.089-.155-.126-.285-.064-1.657.797-3.402 1.035-5.205.848-.976-.102-1.949-.23-2.94-.349-.006.061-.018.136-.023.21-.037.488-.208.915-.444 1.341-.512.923-.975 1.874-1.445 2.82-.083.165-.166.244-.351.23a6.174 6.174 0 0 0-.737-.003c-.162.007-.206-.072-.163-.21.058-.183.127-.363.205-.538.256-.578.466-1.17.519-1.81.069-.849-.125-1.639-.47-2.4-.325.05-.638.11-.954.147a5.704 5.704 0 0 1-2.106-.13 9.98 9.98 0 0 0-2.99-.284c-.244.011-.49.004-.735-.015-.3-.024-.538-.185-.625-.484a44.73 44.73 0 0 1-.528-2.009c-.054-.226.079-.415.334-.422.621-.019.98-.434 1.311-.885.608-.83.97-1.785 1.252-2.774.02-.07-.02-.175-.063-.245-.31-.518-.636-1.026-.94-1.548a3.047 3.047 0 0 1-.244-.594c-.054-.169-.002-.259.198-.23.94.136 1.828.42 2.615.99.103.075.182.096.301.04a13.177 13.177 0 0 1 5.833-1.243c1.443.024 2.839-.286 4.237-.59 1.262-.273 2.527-.52 3.824-.488 1.56.038 2.996.46 4.211 1.526.401.352.74.759 1.006 1.232.224.397.55.623 1.01.587.266-.021.469-.2.532-.454.054-.22-.031-.443-.25-.596-.128-.089-.273-.153-.407-.226.362-.101.748.002.979.28.275.332.313.713.16 1.108-.234.601-.714.76-1.255.723.094.518.219 1.028.273 1.545.12 1.15-.096 2.245-.573 3.287-.128.278-.271.55-.388.834-.165.402-.203.836.021 1.209.646 1.075.57 2.242.464 3.418-.037.407-.097.812-.149 1.238Z" />
      <path d="m27.555 25.414 1.425.708c-.254.799-.557 1.556-.999 2.248a.251.251 0 0 1-.18.098c-.311.01-.623.004-.934.004l.688-3.058ZM19.153 25.375c.401 1.026.67 2.037.87 3.097-.301 0-.575.006-.849-.006-.047-.001-.108-.065-.136-.116-.213-.397-.42-.797-.625-1.198-.025-.048-.05-.123-.031-.164.244-.524.498-1.044.771-1.613Z" />
    </g>
    <defs>
      <clipPath id="b">
        <path fill="#fff" d="M7.5 12.754h26v15.73h-26z" />
      </clipPath>
    </defs>
  </svg>
);
export default IconPigWithBgSvg;

import { LinearProgress, styled } from "@mui/material";
import { useState } from "react";
import { Document, Page } from "react-pdf";
import { tw } from "../../../../../utils/tw";

type PdfRendererProps = {
  url: string;
  className?: string;
};

const PdfRenderer = ({ url, className }: PdfRendererProps) => {
  const [numPages, setNumPages] = useState([]);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(Array.from({ length: numPages }));
  }

  return (
    <Document
      file={url}
      onLoadSuccess={onDocumentLoadSuccess}
      loading={
        <>
          <LinearProgress color="success" />
        </>
      }
    >
      {numPages.map((_, page) => {
        return (
          <PDFPageWrapper
            key={`key ${page + 1}`}
            pageNumber={page + 1}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            renderForms={true}
            className={tw("w-full", className)}
          />
        );
      })}
    </Document>
  );
};

export default PdfRenderer;

const PDFPageWrapper = styled(Page)(() => ({
  ".react-pdf__Page__canvas": {
    width: "100% !important",
    height: "auto !important",
  },
}));

import { useNavigate } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import AppBarFS from "../../components/app-bar/AppBar";
import DashboardInfoCard from "../../components/common/DashboardInfoCard/DashboardInfoCard";
import OrderHistorySVG from "../../assets/svg/OrderHistory";
import ContractActive from "../../assets/svg/ContractActive";
import List from "../../assets/svg/List";
import ListBgBlue from "../../assets/svg/ListBgBlue";

const FinancialSupport = () => {
  let navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, []);

  const handleClickToNewContract = useCallback(() => {
    navigate("/financial-support/financial-new-contract");
  }, []);

  const handleClickToContract = useCallback(() => {
    navigate("/financial-support/financial-contract");
  }, []);

  const handleClickToHistory = useCallback(() => {
    navigate("/financial-support/financial-history");
  }, []);

  const handleClickToBillPayment = useCallback(() => {
    navigate("/financial-support/financial-bill-payment");
  }, []);

  return (
    <div>
      <div className="w-full h-full">
        <div className="h-[120px] flex items-center">
          <AppBarFS title={"Financial Support"} onIconPress={handleBack} />
        </div>
        <div className="flex flex-col w-full h-full gap-[16px] px-[16px]">
          <DashboardInfoCard
            title={"สัญญาใหม่"}
            subTitle={"นำเสนอสัญญาเครดิต"}
            icon={<List />}
            removeBgIcon={true}
            bgColor="#F0FDF4"
            onPress={handleClickToNewContract}
          />
          <DashboardInfoCard
            title={"สัญญาที่ใช้งาน"}
            subTitle={"สัญญาณที่ใช้งานปัจจุบัน"}
            icon={<ContractActive />}
            removeBgIcon={true}
            bgColor="#EEF2FF"
            onPress={handleClickToContract}
          />
          <DashboardInfoCard
            title={"ประวัติของสัญญาทั้งหมด"}
            subTitle={"ประวัติของสัญญาทั้งหมด"}
            icon={<OrderHistorySVG />}
            removeBgIcon={true}
            bgColor="#D9EBFEB2"
            onPress={handleClickToHistory}
          />
          <DashboardInfoCard
            title={"การจ่ายเงิน"}
            subTitle={"การจ่ายเงิน"}
            icon={<ListBgBlue />}
            removeBgIcon={true}
            bgColor="#F2F9FD"
            onPress={handleClickToBillPayment}
          />
        </div>
      </div>
    </div>
  );
};

export default FinancialSupport;

import ReactApexChart from "react-apexcharts";
import "./Farm.scss";
import { t } from "i18next";
import { useEffect, useState } from "react";
interface FeedDataState {
  feedData: number[];
  dateData1: string[];
}

export function FeedIntakeGraph({ feedIntake }: any) {
  const [feedDataState, setFeedDataState] = useState<FeedDataState>({
    feedData: [],
    dateData1: [],
  });

  const series: any = [
    {
      name: "STOCK ABC",
      data: feedDataState.feedData,
    },
  ];
  const options = {
    chart: {
      type: "area" as const,
      height: "100%",
      zoom: {
        enabled: false,
      },
      showToolbar: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight" as const,
    },
    title: {
      text: t("farms.intakeParDay"),
      offsetY: 10,
      align: "left" as const,
      style: {
        fontSize: "9.704px",
        color: "#5572EB",
        fontFamily: "IBM Plex Sans Thai",
      },
    },
    labels: feedDataState.dateData1,
    xaxis: {
      type: "category" as const,
    },
    yaxis: {
      opposite: false,
      labels: {
        formatter: function (value: any) {
          return value + "g"; // Append 'g' to Y-axis labels
        },
        style: {
          fontSize: "9.704px",
          color: "#5572EB", // Set the font size for Y-axis labels
        },
        cssClass: "custom-y-axis-label",
      },
    },

    legend: {
      horizontalAlign: "left" as const,
    },
    toolbar: {
      show: false, // Remove the menu icon
    },
  };

  useEffect(() => {
    let dataState: FeedDataState;
    dataState = {
      feedData: new Array<number>(),
      dateData1: new Array<string>(),
    };
    feedIntake.reverse();
    feedIntake.forEach((item: any) => {
      const dateParts = item.logDate.split("-"); // Split the date by hyphen
      const date = dateParts[2]; // Extract the day part
      const month = dateParts[1];
      const combinedValue = `${date}-${month}`;
      dataState.feedData.push(item?.feedIntake);
      dataState.dateData1.push(combinedValue);
    });
    setFeedDataState(dataState);
  }, []);

  return (
    <div className="w-[95%] overflow-scroll">
      <ReactApexChart
        options={options}
        style={{ color: "#FF5733" }}
        series={series}
        type="area"
        width={"100%"}
      />
    </div>
  );
}

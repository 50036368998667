import HeartSVG from "../../../assets/svg/Heart";
import { PigTypes } from "../../../pages/farm-dashboard/farm-dashboard.type";

interface PigCountProps {
  pigCount: number;
  pigLabel: string;
  pigType: PigTypes;
}

function mapPigToColorVariant(pigType: PigTypes) {
  switch (pigType) {
    case PigTypes.BREEDER:
      return "green";
    case PigTypes.FATTENER:
      return "blue";
    case PigTypes.NURSERY:
      return "orange";
    default:
      return "green";
  }
}

export default function PigCount({
  pigCount,
  pigLabel,
  pigType,
}: PigCountProps) {
  return (
    <div className="flex gap-2">
      <div className="flex items-center">
        <HeartSVG variant={mapPigToColorVariant(pigType)} />
      </div>
      <div>
        <p className="text-primary">{pigCount.toLocaleString() || "-"}</p>
        <p className="text-sm text-tertiary">{pigLabel}</p>
      </div>
    </div>
  );
}

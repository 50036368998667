export interface IMedicineTable {
  id: string;
  categoryTh: string;
  categoryEn: string;
  medicineTh: string;
  medicineEn: string;
  unit?: string;
}
// interface select medicine
export interface IGetCategoryMedicine {
  success: boolean;
  message: string;
  count: number;
  rows: CategoryMedicineRow[];
}

export interface CategoryMedicineRow {
  id: string;
  nameTh: string;
  nameEn: string;
  isActive: boolean;
}
// interface medicine
export interface IGetMedicine {
  success: boolean;
  message: string;
  count: number;
  rows: MedicineRow[];
}

export interface MedicineRow {
  id: string;
  tradeName: string;
  tradeNameEn: string;
  package: string;
  unit: string;
  isActive: boolean;
  medicineCategory: MedicineCategory;
}

export interface MedicineCategory {
  id: string;
  nameTh: string;
  nameEn: string;
  isActive: boolean;
}

// type create medicine form
export type TCreateMedicineForm = {
  tradeName: string;
  tradeNameEn: string;
  medicineCategoryId: string;
  categoryName: string;
  categoryNameEn: string;
  unit: string;
  unitSelect?: string;
};

export const selectUintMedicine = [
  "cc",
  "kg",
  "gm",
  "litre",
  "pound",
  "ml",
  "tablets",
  "lb",
  "dose",
  "pack",
  "box",
  "gallon",
  "ml/can",
  "อื่นๆ",
];

import { TextField, TextFieldProps } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";

export interface ITextInputSearchCustomProps {
  borderColor?: string;
  borderRadius?: string;
  textAlign?: string;
}

// TODO: will add with debounce later !!! IMPORTANT !!!
function TextInputSearchCustom({
  borderColor,
  borderRadius,
  textAlign,
  ...rest
}: TextFieldProps & ITextInputSearchCustomProps) {
  return (
    <TextField
      {...rest}
      fullWidth
      variant="outlined"
      autoComplete="off"
      InputProps={{
        sx: {
          borderRadius: borderRadius || "6px !important",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "20px",
          color: "#191919",
          background: "#FFFFFF",
          borderColor: borderColor || "#E2E2E2",
          textAlign: textAlign || "",
          height: "32px",
          width: "100%",
          paddingLeft: "12px",
          paddingRight: "6px",
        },
        startAdornment: (
          <SearchIcon
            sx={{
              color: "#E2E2E2",
              marginRight: "6px",
            }}
          />
        ),
        ...rest?.InputProps,
      }}
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#E2E2E2",
        },
        "& .Mui-focused": {
          "& > svg": {
            color: "#000000 !important",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px !important",
          },
        },
        "& .Mui-disabled": {
          color: "#979797 !important",
          WebkitTextFillColor: "#979797 !important",
          bgcolor: "#FFFFFF !important",
        },
        "& input::placeholder": {
          opacity: 1,
          color: "#979797",
        },
        "& .MuiInputBase-input": {
          ":-webkit-autofill": {
            height: "19px",
            bgcolor: "transparent",
          },
        },
      }}
      SelectProps={{
        sx: {
          borderRadius: borderRadius || "28px",
          fontWeight: 500,
          color: "#191919",
          background: "#F7FBFF",
          borderColor: borderColor || "#E2E2E2",
          textAlign: textAlign || "",

          "& .Mui-disabled": {
            color: "#979797 !important",
            WebkitTextFillColor: "#979797 !important",
            bgcolor: "#FFFFFF !important",
          },
        },
      }}
      InputLabelProps={{
        sx: {
          "&.Mui-disabled": {
            color: "#979797 !important",
          },
        },
      }}
    />
  );
}

export default TextInputSearchCustom;

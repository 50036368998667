import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import {
  AuthProvider,
  ProtectedRoute,
  UnprotectedRoute,
} from "./components/common/ProtectedRoute/ProtectedRoute";
import "./styles/App.scss";

import { Navigate, useOutlet } from "react-router-dom";
import { AdminAuthProvider } from "./admin/components/AdminAuth/AdminAuthProvider";
import { PartnerAuthProvider } from "./admin/components/PartnerAuth/PartnerAuthProvider";
import Claim from "./admin/pages/claim-order";
import CreditFeed from "./admin/pages/credit/credit-feed";
import CreditStatus from "./admin/pages/credit/credit-status";
import FarmDetail from "./admin/pages/farm-detail/pages/FarmDetail";
import FarmInformation from "./admin/pages/farm-detail/pages/FarmInformation";
import GroupArea from "./admin/pages/group-area";
import Home from "./admin/pages/home";
import LendingInvoice from "./admin/pages/invoice/lending-invoice";
import ManagerInvoice from "./admin/pages/invoice/manage-invoice";
import SaleInvoice from "./admin/pages/invoice/sale-invoice";
import SaleInvoiceDetail from "./admin/pages/invoice/sale-invoice/SaleInvoiceDetail/saleInvoiceDetail";
import Login from "./admin/pages/login";
import AzureCallBackPage from "./admin/pages/login/azure";
import { loginLoader } from "./admin/pages/login/loader";
import ManageStock from "./admin/pages/manage-stock";
import ManageStockById from "./admin/pages/manage-stock/[id]";
import { manageStockByIdLoader } from "./admin/pages/manage-stock/[id]/loader";
import { manageStockLoader } from "./admin/pages/manage-stock/loader";
import CreditMasterData from "./admin/pages/master-data/pages/credit/credit";
import MasterData from "./admin/pages/masterdata";
import PartnerDetail from "./admin/pages/partner-detail/pages/PartnerDetail";
import PartnerInformation from "./admin/pages/partner-detail/pages/PartnerInformation";
import Role from "./admin/pages/role";
import SaleOrder from "./admin/pages/sale-order";
import { SaleOrderProvider } from "./admin/pages/sale-order/Provider/SaleOrderProvider";
import { loadSaleOrderData } from "./admin/pages/sale-order/loader";
import UserManagement from "./admin/pages/user-management";
import { loadUserManagementData } from "./admin/pages/user-management/loader";
import DefaultError from "./components/common/ErrorPage/DefaultErrorPage";
import AccountCenter from "./pages/account-center";
import SettingPage from "./pages/account-center/page-setting";
import ChangePin from "./pages/account-center/page-setting/change-pin";
import RemoveAccount from "./pages/account-center/page-setting/remove-account";
import SupportPage from "./pages/account-center/page-support";
import AddFarm from "./pages/add-farm";
import EditFarmSuccess from "./pages/add-farm/edit-farm-sucess";
import { farmLoader } from "./pages/add-farm/loader";
import AddHouse from "./pages/add-house";
import EditHouse from "./pages/add-house/edit-house";
import EditHouseSuccess from "./pages/add-house/edit-house-success";
import EditFeedingProgram from "./pages/add-house/feeding-program/EditFeedingProgram";
import AddHouseSuccess from "./pages/add-house/house-success";
import EditVaccineProgram from "./pages/add-house/vaccine-program/edit-vaccine-program";
import ProgramOverview from "./pages/add-house/vaccine-program/program-overview";
import Authentication from "./pages/authentication/Authentication";
import { ContactFarmer } from "./pages/contact-farmer";
import { loaderContactFarmer } from "./pages/contact-farmer/loader";
import DailyLog from "./pages/daily-log";
import DailyLogEdit from "./pages/daily-log/daily-log-edit";
import EditSelectMedicine from "./pages/daily-log/edit-select-medicine";
import { dailyLogLoader } from "./pages/daily-log/loader";
import SelectMedicine from "./pages/daily-log/select-medicine";
import FarmDashboard from "./pages/farm-dashboard/FarmDashboard";
import { farmDashboardLoader } from "./pages/farm-dashboard/farm-dashboard.loader";
import ManageStockFarmer from "./pages/farm-dashboard/manage-stock";
import FarmPastPerformance from "./pages/farm-past-performance";
import { farmPastPerformanceLoader } from "./pages/farm-past-performance/farm-past-performance.loader";
import Farms from "./pages/farms/Farms";
import FeedOrderAndDelivery from "./pages/feed-order-and-delivery";
import MyOrder from "./pages/feed-order-and-delivery/my-order";
import { ContactSeller } from "./pages/feed-order-and-delivery/my-order/contact-seller";
import { myOrderLoader } from "./pages/feed-order-and-delivery/my-order/loader";
import { default as MyOrderDetail } from "./pages/feed-order-and-delivery/my-order/my-order-detail";
import { myOrderDetailLoader } from "./pages/feed-order-and-delivery/my-order/my-order-detail/loader";
import OrderFeed from "./pages/feed-order-and-delivery/order-feed";
import { orderFeedLoader } from "./pages/feed-order-and-delivery/order-feed/loader";
import { OrderFeedFormSuccess } from "./pages/feed-order-and-delivery/order-feed/success";
import { orderFeedSuccessLoader } from "./pages/feed-order-and-delivery/order-feed/success/loader";
import { default as OrderHistoryPage } from "./pages/feed-order-and-delivery/order-history";
import { orderHistoryLoader } from "./pages/feed-order-and-delivery/order-history/loader";
import OrderHistoryDetail from "./pages/feed-order-and-delivery/order-history/order-history-detail";
import OrderViewDocument from "./pages/feed-order-and-delivery/order-view-document";
import FinancialOverview from "./pages/financial-overview";
import FinancialSupport from "./pages/financial-support";
import FinancialBillPayment from "./pages/financial-support/financial-billPayment";
import HistoryBill from "./pages/financial-support/financial-billPayment/history-bill";
import BillPayment from "./pages/financial-support/financial-billPayment/unpaid-bill";
import VerifyBill from "./pages/financial-support/financial-billPayment/verify-bill";
import FinancialContract from "./pages/financial-support/financial-contract";
import FinancialHistory from "./pages/financial-support/financial-history";
import FinancialShowHistoryDetail from "./pages/financial-support/financial-history/show-history";
import FinancialNewContract from "./pages/financial-support/financial-newContract";
import NotificationPage from "./pages/homepage/notificaction-page";
import { HouseDashboard } from "./pages/house-dashboard/HouseDashboard";
import HouseCurrentProgram from "./pages/house-dashboard/current-program";
import {
  houseDashboardLoader,
  moveOutPigHouseLoader,
} from "./pages/house-dashboard/house-dashboard.loader";
import { HouseMoveOutPig } from "./pages/house-dashboard/move-out-pig";
import { HouseMoveOutPigSelling } from "./pages/house-dashboard/move-out-pig-selling";
import MoveOutPigSuccess from "./pages/house-dashboard/move-out-pig-success";
import HousePerformanceReport from "./pages/house-performance-report";
import Manualfile from "./pages/manual-file/Manualfile";
import OrderHistory from "./pages/order-history";
import NewOrder from "./pages/order-history/new-order";
import OrderDetail from "./pages/order-history/new-order/detail";
import ProveShipping from "./pages/order-history/new-order/proveShipping";
import PartnerPinAuthentication from "./pages/partner/reset-pin/1-pin-authentication";
import PartnerUpdatePin from "./pages/partner/reset-pin/2-update-pin";
import PartnerResetPinSuccess from "./pages/partner/reset-pin/3-success";
import RegisterFarmSuccess from "./pages/register-farm/RegisterSuccess";
import ReportOrderDetail from "./pages/report/reportOrderDetailPage";
import { reportDetailLoader } from "./pages/report/reportOrderDetailPage/loader";
import { ReportSuccess } from "./pages/report/success/ReportSuccess";
import { reportLoader } from "./pages/report/success/loader";
import PinAuthentication from "./pages/reset-pin/pin-authorization";
import ResetPinSuccess from "./pages/reset-pin/reset-pin-success";
import UpdatePin from "./pages/reset-pin/update-pin";
import { SellingHistory } from "./pages/selling-history";
import { moveOutPigSellingHistoryLoader } from "./pages/selling-history/selling-history.loader";
import { SellingReport } from "./pages/selling-report";
import { sellingReportLoader } from "./pages/selling-report/selling-report.loader";
import SetPin from "./pages/set-pin";
import SingupSuccess from "./pages/signup-success";
import Signup from "./pages/signup/signup";
import SignupPage from "./pages/signuppage";
import Stock from "./pages/stock";
import PartnerLanding from "./pages/stock/PartnerLanding";
import StockHistory from "./pages/stock/stock-history";
import { historyStockLoader } from "./pages/stock/stock-history/loader";
import StockHistoryDetail from "./pages/stock/stock-history/stock-history-detail";
import { historyStockDetailLoader } from "./pages/stock/stock-history/stock-history-detail/loader";
import StockUploadDocument from "./pages/stock/stock-upload-documents";
import UpcomingStock from "./pages/stock/upcoming-stock";
import { upComingStockLoader } from "./pages/stock/upcoming-stock/loader";
import StockProveShipping from "./pages/stock/upcoming-stock/proveShipping";
import UpcomingStockDetail from "./pages/stock/upcoming-stock/upcoming-stock-detail";
import { upComingStockDetailLoader } from "./pages/stock/upcoming-stock/upcoming-stock-detail/loader";
import TrackDebt from "./pages/track-debt";
import AcceptedAssignment from "./pages/track-debt/details-assignment";
import HistoryAssignment from "./pages/track-debt/history-assignment";
import NewAssignment from "./pages/track-debt/new-assignment";
import OnboardingPage from "./pages/welcome/OnboardingPage";
import { WelcomLoginPage } from "./pages/welcome/WelcomeLoginPage";
import { SnackBarProvider } from "./provider/SnackBarProvider";
import PartnerContract from "./pages/partner-contract";
import ViewDocumentPartnerContract from "./pages/partner-contract/view-document";
import HistoryPartnerContract from "./pages/partner-contract/history";
import VaccineMasterData from "./admin/pages/master-data/pages/vaccine/vaccine";
import MedicineMasterData from "./admin/pages/master-data/pages/medicine/Medicine";
import StockDetails from "./pages/stock/stock-see-details";
import Details from "./pages/stock/stock-see-details/stock-details";
import BasicAccounting from "./pages/farm-dashboard/basic-accounting";
import SaveBasicAccounting from "./pages/farm-dashboard/basic-accounting/save-accounting";
import SeeDetailsBasicAccounting from "./pages/farm-dashboard/basic-accounting/see-details";
import AccountCenterPartner from "./pages/account-center-partner";
import SupportPagePartner from "./pages/account-center-partner/page-support";
import SettingPagePartner from "./pages/account-center-partner/page-setting";
import ChangePinPartner from "./pages/account-center-partner/page-setting/change-pin";
import SeeHouseDetailsBasicAccounting from "./pages/farm-dashboard/basic-accounting/see-details/see-house-details";
import MoneyDebtSale from "./admin/pages/money-debt-and-collection/debt-and-collection/pages/MoneyDebtSale";
import MoneyDebtLending from "./admin/pages/money-debt-and-collection/debt-and-collection/pages/MoneyDebtLending";

export const AuthLayout = () => {
  const outlet = useOutlet();

  return <AuthProvider>{outlet}</AuthProvider>;
};
export const PartnerLayout = () => {
  const outlet = useOutlet();
  return <PartnerAuthProvider>{outlet}</PartnerAuthProvider>;
};

export const AdminAuthLayout = () => {
  const outlet = useOutlet();
  return (
    <AdminAuthProvider>
      <SnackBarProvider>{outlet}</SnackBarProvider>
    </AdminAuthProvider>
  );
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<AuthLayout />}>
        <Route
          path="/"
          element={
            <UnprotectedRoute>
              <OnboardingPage />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <UnprotectedRoute>
              <Signup />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/authentication"
          element={
            <UnprotectedRoute>
              <Authentication />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/welcome"
          element={
            <UnprotectedRoute>
              <WelcomLoginPage />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/onboarding"
          element={
            <UnprotectedRoute>
              <OnboardingPage />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/manualfile"
          element={
            <UnprotectedRoute>
              <Manualfile />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/login"
          element={
            <UnprotectedRoute>
              {/* <LoginPage /> */}
              <WelcomLoginPage />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/signup-page"
          element={
            <UnprotectedRoute>
              <SignupPage />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/set-pin"
          element={
            <UnprotectedRoute>
              <SetPin />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/signup-success"
          element={
            <UnprotectedRoute>
              <SingupSuccess />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/pin-authentication"
          element={
            <UnprotectedRoute>
              <PinAuthentication />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/update-pin"
          element={
            <UnprotectedRoute>
              <UpdatePin />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/reset-pin-success"
          element={
            <UnprotectedRoute>
              <ResetPinSuccess />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/add-farm"
          element={
            <ProtectedRoute>
              <AddFarm />
            </ProtectedRoute>
          }
          loader={() => farmLoader()}
        />
        <Route
          path="/edit-farm"
          element={
            <ProtectedRoute>
              <AddFarm />
            </ProtectedRoute>
          }
          loader={() => farmLoader()}
        />
        <Route
          path="/edit-farm-success"
          element={
            <ProtectedRoute>
              <EditFarmSuccess />
            </ProtectedRoute>
          }
        />
        <Route
          path="/farms"
          element={
            <ProtectedRoute>
              <Farms />
            </ProtectedRoute>
          }
        />
        <Route
          path="/error"
          element={
            <ProtectedRoute>
              <DefaultError />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-farm-success"
          element={
            <ProtectedRoute>
              <RegisterFarmSuccess />
            </ProtectedRoute>
          }
        />
        <Route
          path="/notifications"
          element={
            <ProtectedRoute>
              <NotificationPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-house"
          element={
            <ProtectedRoute>
              <AddHouse />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-house"
          element={
            <ProtectedRoute>
              <EditHouse />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit-vaccine"
          element={
            <ProtectedRoute>
              <EditVaccineProgram />
            </ProtectedRoute>
          }
        />
        <Route
          path="/program-overview"
          element={
            <ProtectedRoute>
              <ProgramOverview />
            </ProtectedRoute>
          }
        />
        <Route
          path="/house-success/:farmId"
          element={
            <ProtectedRoute>
              <AddHouseSuccess />
            </ProtectedRoute>
          }
        />
        <Route
          path="/quick-daily-log"
          element={
            <ProtectedRoute>
              <DailyLog />
            </ProtectedRoute>
          }
          loader={() => dailyLogLoader()}
        />
        <Route
          path="/daily-log"
          element={
            <ProtectedRoute>
              <DailyLog />
            </ProtectedRoute>
          }
          loader={() => dailyLogLoader()}
        />
        <Route
          path="/daily-log-edit"
          element={
            <ProtectedRoute>
              <DailyLogEdit />{" "}
            </ProtectedRoute>
          }
          loader={() => dailyLogLoader()}
        />
        <Route
          path="/select-medicine"
          element={
            <ProtectedRoute>
              <SelectMedicine />
            </ProtectedRoute>
          }
        />
        <Route path="/edit-feed-program" element={<EditFeedingProgram />} />
        <Route path="/edit-house-success" element={<EditHouseSuccess />} />
        <Route
          path="/houses/:houseId/program-overview"
          element={
            <ProtectedRoute>
              <HouseCurrentProgram />
            </ProtectedRoute>
          }
        />
        <Route
          path="/farm/:farmId"
          element={
            <ProtectedRoute>
              <FarmDashboard />
            </ProtectedRoute>
          }
          loader={({ params }) => farmDashboardLoader({ params })}
          errorElement={<Navigate to="/error" />}
        />
        <Route
          path="/my-order/:farmId"
          element={
            <ProtectedRoute>
              <MyOrder />
            </ProtectedRoute>
          }
          loader={(loader) => myOrderLoader(loader)}
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/my-order-detail/:farmId/:orderId"
          element={
            <ProtectedRoute>
              <MyOrderDetail />
            </ProtectedRoute>
          }
          loader={(loader) => myOrderDetailLoader(loader)}
        />
        <Route
          path="/order-history-detail/:farmId/:orderId"
          element={
            <ProtectedRoute>
              <OrderHistoryDetail />
            </ProtectedRoute>
          }
          loader={(loader) => myOrderDetailLoader(loader)}
        />
        <Route
          path="/order-history"
          element={
            <ProtectedRoute>
              <OrderHistoryPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner"
          element={
            <ProtectedRoute>
              <PartnerLanding />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/partner" />}
        />
        <Route
          path="/order"
          element={<OrderHistory />}
          errorElement={<Navigate to="/partner" />}
        />
        <Route
          path="/order/new"
          element={
            <ProtectedRoute>
              <NewOrder />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/partner" />}
        />
        <Route
          path="/order/detail/:id"
          element={
            <ProtectedRoute>
              <OrderDetail />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/partner" />}
        />
        <Route
          path="/order/prove-shipping/:orderId"
          element={
            <ProtectedRoute>
              <ProveShipping />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/partner" />}
        />
        <Route
          path="/order/history"
          element={
            <ProtectedRoute>
              <NewOrder isHistory />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/partner" />}
        />
        <Route
          path="/stock"
          element={
            <ProtectedRoute>
              <Stock />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/stock/upcoming-stock"
          element={
            <ProtectedRoute>
              <UpcomingStock />
            </ProtectedRoute>
          }
          loader={(loader) => upComingStockLoader(loader)}
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/stock/upcoming-stock/:stockId"
          element={
            <ProtectedRoute>
              <UpcomingStockDetail />
            </ProtectedRoute>
          }
          loader={(loader) => upComingStockDetailLoader(loader)}
        />
        <Route
          path="/stock/prove-shipping/:stockId"
          element={
            <ProtectedRoute>
              <StockProveShipping />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/partner" />}
        />
        <Route
          path="/stock/stock-history"
          element={
            <ProtectedRoute>
              <StockHistory />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/partner" />}
          loader={(loader) => historyStockLoader(loader)}
        />
        <Route
          path="/stock/stock-history/:stockId"
          element={
            <ProtectedRoute>
              <StockHistoryDetail />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/partner" />}
          loader={(loader) => historyStockDetailLoader(loader)}
        />
        <Route
          path="/stock/stock-upload-documents"
          element={
            <ProtectedRoute>
              <StockUploadDocument />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/partner" />}
        />
        <Route
          path="/partner/reset-pin/pin-authentication"
          element={
            <ProtectedRoute>
              <PartnerPinAuthentication />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner/reset-pin/update-pin"
          element={
            <ProtectedRoute>
              <PartnerUpdatePin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner/reset-pin/success"
          element={
            <ProtectedRoute>
              <PartnerResetPinSuccess />
            </ProtectedRoute>
          }
        />
        <Route
          path="/house-performance-report"
          element={<HousePerformanceReport />}
        />
        <Route
          path="/feed-order-and-delivery/order-feed/success"
          element={
            <ProtectedRoute>
              <OrderFeedFormSuccess />
            </ProtectedRoute>
          }
          loader={(loader) => orderFeedLoader(loader)}
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/order-history/:farmId"
          element={
            <ProtectedRoute>
              <OrderHistoryPage />
            </ProtectedRoute>
          }
          loader={(loader) => orderHistoryLoader(loader)}
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/contact-seller"
          element={
            <ProtectedRoute>
              <ContactSeller />
            </ProtectedRoute>
          }
        />
        <Route
          path="/report/success/:farmId"
          element={
            <ProtectedRoute>
              <ReportSuccess />
            </ProtectedRoute>
          }
          loader={(loader) => reportLoader(loader)}
        />
        <Route
          path="/edit-select-medicine"
          element={
            <ProtectedRoute>
              <EditSelectMedicine />
            </ProtectedRoute>
          }
        />
        <Route
          path="/house/:houseId"
          element={
            <ProtectedRoute>
              <HouseDashboard />
            </ProtectedRoute>
          }
          loader={({ params }) => houseDashboardLoader({ params })}
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/house/:houseId/move-out-pig"
          element={
            <ProtectedRoute>
              <HouseMoveOutPig />
            </ProtectedRoute>
          }
          loader={({ params }) =>
            moveOutPigHouseLoader({ params: params as { houseId: string } })
          }
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/house/:houseId/move-out-pig-selling"
          element={
            <ProtectedRoute>
              <HouseMoveOutPigSelling />
            </ProtectedRoute>
          }
          loader={({ params }) =>
            moveOutPigHouseLoader({ params: params as { houseId: string } })
          }
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/house/:moveOutPigId/move-out-pig-success"
          element={
            <ProtectedRoute>
              <MoveOutPigSuccess />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/house/selling-history/:houseId"
          element={
            <ProtectedRoute>
              <SellingHistory />
            </ProtectedRoute>
          }
          loader={({ params }) =>
            moveOutPigSellingHistoryLoader({
              params: params as { houseId: string },
            })
          }
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/house/selling-report/:houseId"
          element={
            <ProtectedRoute>
              <SellingReport />
            </ProtectedRoute>
          }
          loader={({ params }) =>
            sellingReportLoader({ params: params as { houseId: string } })
          }
          // errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/farm-past-performance/:farmId"
          element={
            <ProtectedRoute>
              <FarmPastPerformance />
            </ProtectedRoute>
          }
          loader={({ params }) => farmPastPerformanceLoader({ params })}
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/feed-order-and-delivery/order-feed/:farmId"
          element={
            <ProtectedRoute>
              <OrderFeed />
            </ProtectedRoute>
          }
          loader={(loader) => orderFeedLoader(loader)}
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/feed-order-and-delivery/order-feed/:farmId/success"
          element={
            <ProtectedRoute>
              <OrderFeedFormSuccess />
            </ProtectedRoute>
          }
          loader={(loader) => orderFeedSuccessLoader(loader)}
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/feed-order-and-delivery/:farmId/:orderId/view-document"
          element={
            <ProtectedRoute>
              <OrderViewDocument />
            </ProtectedRoute>
          }
          errorElement={<Navigate to="/farms" />}
          loader={(loader) => myOrderDetailLoader(loader)}
        />
        <Route
          path="/feed-order-and-delivery/:farmId"
          element={
            <ProtectedRoute>
              <FeedOrderAndDelivery />
            </ProtectedRoute>
          }
          loader={({ params }) => params}
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="/report-detail/:orderId"
          element={
            <ProtectedRoute>
              <ReportOrderDetail />
            </ProtectedRoute>
          }
          loader={(loader) => reportDetailLoader(loader)}
          errorElement={<Navigate to="/farms" />}
        />
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <Farms />
            </ProtectedRoute>
          }
        />
        <Route
          path="/financial-support"
          element={
            <ProtectedRoute>
              <FinancialSupport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/financial-support/financial-new-contract"
          element={
            <ProtectedRoute>
              <FinancialNewContract />
            </ProtectedRoute>
          }
        />
        <Route
          path="/financial-support/financial-contract"
          element={
            <ProtectedRoute>
              <FinancialContract />
            </ProtectedRoute>
          }
        />
        <Route
          path="/financial-support/financial-history"
          element={
            <ProtectedRoute>
              <FinancialHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/financial-support/financial-history/show-history"
          element={
            <ProtectedRoute>
              <FinancialShowHistoryDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner/contact-buyer/:id"
          element={
            <ProtectedRoute>
              <ContactFarmer />
            </ProtectedRoute>
          }
          loader={({ params }) => loaderContactFarmer(params)}
        />
        <Route
          path="/financial-support/financial-bill-payment"
          element={
            <ProtectedRoute>
              <FinancialBillPayment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/financial-support/financial-bill-payment/unpaid-bill/:farmId?"
          element={
            <ProtectedRoute>
              <BillPayment />
            </ProtectedRoute>
          }
          loader={({ params }) => params}
        />
        <Route
          path="/financial-support/financial-bill-payment/verify-bill"
          element={
            <ProtectedRoute>
              <VerifyBill />
            </ProtectedRoute>
          }
        />
        <Route
          path="/financial-support/financial-bill-payment/history-bill"
          element={
            <ProtectedRoute>
              <HistoryBill />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner/track-debt"
          element={
            <ProtectedRoute>
              <TrackDebt />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner/track-debt/new-assignment"
          element={
            <ProtectedRoute>
              <NewAssignment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner/track-debt/accepted-assignment"
          element={
            <ProtectedRoute>
              <AcceptedAssignment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner/track-debt/history-assignment"
          element={
            <ProtectedRoute>
              <HistoryAssignment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/farms/account-center"
          element={
            <ProtectedRoute>
              <AccountCenter />
            </ProtectedRoute>
          }
        />
        <Route
          path="/farms/account-center/support"
          element={
            <ProtectedRoute>
              <SupportPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/farms/account-center/setting"
          element={
            <ProtectedRoute>
              <SettingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/farms/account-center/setting/change-pin"
          element={
            <ProtectedRoute>
              <ChangePin />
            </ProtectedRoute>
          }
        />
        <Route
          path="/farms/account-center/setting/remove-account"
          element={
            <ProtectedRoute>
              <RemoveAccount />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner/account-center"
          element={
            <ProtectedRoute>
              <AccountCenterPartner />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner/account-center/support"
          element={
            <ProtectedRoute>
              <SupportPagePartner />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner/account-center/setting"
          element={
            <ProtectedRoute>
              <SettingPagePartner />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner/account-center/setting/change-pin"
          element={
            <ProtectedRoute>
              <ChangePinPartner />
            </ProtectedRoute>
          }
        />
        <Route
          path="/farm/:farmId/manage-stock"
          element={
            <ProtectedRoute>
              <ManageStockFarmer />
            </ProtectedRoute>
          }
          loader={({ params }) => farmDashboardLoader({ params })}
          errorElement={<Navigate to="/error" />}
        />
        <Route
          path="/partner/financial-overview"
          element={
            <ProtectedRoute>
              <FinancialOverview />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner/partner-contract"
          element={
            <ProtectedRoute>
              <PartnerContract />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner/partner-contract/view-document"
          element={
            <ProtectedRoute>
              <ViewDocumentPartnerContract />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner/partner-contract/history"
          element={
            <ProtectedRoute>
              <HistoryPartnerContract />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner/stock-details"
          element={
            <ProtectedRoute>
              <StockDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner/stock-details/:id"
          element={
            <ProtectedRoute>
              <Details />
            </ProtectedRoute>
          }
        />
        <Route
          path="/farm/:farmId/basic-accounting"
          element={
            <ProtectedRoute>
              <BasicAccounting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/farm/:farmId/basic-accounting/save-accounting"
          element={
            <ProtectedRoute>
              <SaveBasicAccounting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/farm/:farmId/basic-accounting/see-details"
          element={
            <ProtectedRoute>
              <SeeDetailsBasicAccounting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/farm/:farmId/basic-accounting/see-details/:houseId"
          element={
            <ProtectedRoute>
              <SeeHouseDetailsBasicAccounting />
            </ProtectedRoute>
          }
        />
      </Route>
      {/* Admin */}
      <Route path="/admin/login/azure" element={<AzureCallBackPage />} />
      <Route element={<AdminAuthLayout />}>
        <Route path="/admin" element={<Login />} loader={loginLoader} />
        <Route path="/admin/login" element={<Login />} loader={loginLoader} />
        <Route path="/admin/home" element={<Home />} />
        <Route path="/admin/master-data/feed" element={<MasterData />} />
        <Route
          path="/admin/sale-order"
          element={
            <SaleOrderProvider>
              <SaleOrder />
            </SaleOrderProvider>
          }
          loader={loadSaleOrderData}
        />
        <Route
          path="/admin/manage-stock"
          element={<ManageStock />}
          loader={manageStockLoader}
        />
        <Route
          path="/admin/manage-stock/:id"
          loader={({ params }) => manageStockByIdLoader(params)}
          element={<ManageStockById />}
        />
        <Route
          path="/admin/user-management"
          element={<UserManagement />}
          loader={loadUserManagementData}
        />
        <Route path="/admin/credit/credit-feed" element={<CreditFeed />} />
        <Route path="/admin/credit/credit-status" element={<CreditStatus />} />
        <Route path="/admin/group-area" element={<GroupArea />} />
        <Route path="/admin/role" element={<Role />} />
        <Route path="/admin/claim-order" element={<Claim />} />
        <Route path="/admin/sale-invoice" element={<SaleInvoice />} />
        <Route path="/admin/sale-invoice/:id" element={<SaleInvoiceDetail />} />
        <Route
          path="/admin/sale-management-invoice"
          element={<ManagerInvoice />}
        />
        <Route path="/admin/lending-invoice" element={<LendingInvoice />} />
        <Route
          path="/admin/master-data/credit"
          element={<CreditMasterData />}
        />
        <Route
          path="/admin/master-data/vaccine"
          element={<VaccineMasterData />}
        />
        <Route
          path="/admin/master-data/medicine"
          element={<MedicineMasterData />}
        />
        <Route path="/admin/farm/detail" element={<FarmDetail />} />
        <Route path="/admin/farm/detail/:id" element={<FarmInformation />} />
        <Route path="/admin/partner/detail" element={<PartnerDetail />} />
        <Route
          path="/admin/partner/detail/:id"
          element={<PartnerInformation />}
        />
        <Route
          path="/admin/sale/debt-and-collection"
          element={<MoneyDebtSale />}
        />
        <Route
          path="/admin/lending/debt-and-collection"
          element={<MoneyDebtLending />}
        />
      </Route>
    </>
  )
);
function App() {
  return (
    <>
      {/* <Helmet> */}
      {/* Default setup */}
      {/* <meta
            http-equiv="Content-Security-Policy"
            content="default-src 'self'  ; img-src https://*; child-src 'none';" 
        /> */}

      {/* More config */}
      {/* <meta http-equiv="Content-Security-Policy" content="
          default-src 'self';
          script-src 'self' https://cdn.example.com;
          style-src 'self' 'unsafe-inline';
          img-src 'self' data:;
          connect-src 'self' https://api.example.com;
          font-src 'self' https://fonts.gstatic.com;
          media-src 'self' https://media.example.com;
          object-src 'none';
          frame-src https://www.youtube.com;"
        /> */}
      {/* </Helmet> */}

      <div className="App">
        {/* <Routes>
          <Route path='/' element={<OnboardingPage />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/authentication' element={<Authentication />} />
          <Route path='/welcome' element={<WelcomLoginPage />} />
          <Route path='/onboarding' element={<OnboardingPage />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/signup-page' element={<SignupPage />} />
          <Route path='/set-pin' element={<SetPin />} />
          <Route path='/signup-success' element={<SingupSuccess />} />
          <Route path='/pin-authentication' element={<PinAuthentication />} />
          <Route path='/update-pin' element={<UpdatePin />} />
          <Route path='/reset-pin-success' element={<ResetPinSuccess />} />
          <Route path='/add-farm' element={<AddFarm />} loader={() => farmLoader()} />
          <Route path='/edit-farm' element={<AddFarm />} loader={() => farmLoader()} />
          <Route path='/farms' element={<Farms />} />
          <Route path='/add-farm-success' element={<RegisterFarmSuccess />} />
          <Route path="/notifications" element={<NotificationPage />} />
          <Route path='/add-house' element={<AddHouse />} />
          <Route path='/add-house/success' element={<AddHouseSuccess />} />
          <Route path='/edit-vaccine' element={<EditVaccineProgram />} />
          <Route path='/program-overview' element={<ProgramOverview />} />
        </Routes> */}
        {/* <FeedingProvider>
          <Routes>
            <Route path='/' element={<OnboardingPage />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/authentication' element={<Authentication />} />
            <Route path='/welcome' element={<WelcomLoginPage />} />
            <Route path='/onboarding' element={<OnboardingPage />} />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/signup-page' element={<SignupPage />} />
            <Route path='/set-pin' element={<SetPin />} />
            <Route path='/signup-success' element={<SingupSuccess />} />
            <Route path='/pin-authentication' element={<PinAuthentication />} />
            <Route path='/update-pin' element={<UpdatePin />} />
            <Route path='/reset-pin-success' element={<ResetPinSuccess />} />
            <Route path='/add-farm' element={<AddFarm />} />
            <Route path='/farms' element={<Farms />} />
            <Route path='/add-farm-success' element={<RegisterFarmSuccess />} />
            <Route path="/notifications" element={<NotificationPage />} />
            <Route path='/add-house' element={<AddHouse />} />
            <Route path='/edit-house' element={<EditHouse />} />
            <Route path='/edit-vaccine' element={<EditVaccineProgram />} />
            <Route path='/program-overview' element={<ProgramOverview />} />
            <Route path='/house-success' element={<AddHouseSuccess />} />
            <Route path='/daily-log' element={<DailyLog/>} />
          </Routes>
        </FeedingProvider> */}
      </div>
    </>
  );
}

export default App;

import adminAPI from "../../../../services/AdminApiService";
import {
  IExportCustomer,
  IGetCountTabCustomer,
  IGetCustomerInfo,
} from "../types/customerTable.type";

export const getCustomerInformation = async (
  partnerId: string,
  type: string,
  searchParams?: URLSearchParams
) => {
  try {
    const { data } = await adminAPI.get<IGetCustomerInfo>(
      `/partner-detail/${partnerId}/customer-info/${type}?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getCountCustomerInformation = async (partnerId: string) => {
  try {
    const { data } = await adminAPI.get<IGetCountTabCustomer>(
      `/partner-detail/${partnerId}/customer-info/count`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const postExportCustomer = async (
  ids: string[],
  partnerId: string,
  type: string
) => {
  try {
    const { data } = await adminAPI.post<IExportCustomer>(
      `/partner-detail/${partnerId}/customer-info/${type}/export`,
      { ids }
    );
    return data;
  } catch (error) {
    return null;
  }
};

import EventIcon from "@mui/icons-material/Event";
import { InputAdornment, Modal } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import * as Y from "yup";
import ButtonFillCustom from "../../../admin/components/Button/ButtonFillCustom";
import { Input } from "../../../admin/pages/group-area/views/Input";
import { FILTER_DATE_FORMAT } from "../../../admin/pages/sale-order/components/SaleOrderSearchFilter";
import AppBarFS from "../../../components/app-bar/AppBar";
import AuthBox from "../../../components/common/AuthBox";
import DatePickerFS from "../../../components/common/DatePicker";
import useFormValidator from "../../../hooks/useFormValidator.hook";
import { loadingActions } from "../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../redux/store";
import {
  checkDuplicateEmail,
  checkDuplicatePhone,
  checkVerifyPin,
  editAccount,
} from "../../../services/AccountCenter.service";
import { IProfileFarmer } from "../../../types/AccountCenter.type";
import { validateEmail } from "../../../utils/email";
import getErrorMessage from "../../../utils/getErrorMessage";
import { tw } from "../../../utils/tw";
import { useEditAccountModalController } from "./controller";

interface IEditAccountModal {
  isOpen: boolean;
  onClose: () => void;
  profileFarmer?: IProfileFarmer;
}

const classes = {
  wrapper: tw(`!z-0`),
  container: tw(`w-full h-full bg-[white] relative flex flex-col gap-4`),
  headerContainer: tw(`h-[120px] flex items-center rounded-b-[40px]`),
  detailsContainer: tw(`flex flex-col px-4 gap-4`),
  button: tw(`!h-[59px] w-full !rounded-[50px] !text-[16px] !font-bold`),
};

interface TForm {
  name: string;
  sureName: string;
  date: any;
  idCardNo: string;
  phoneNumber: string;
  email: string;
  pin: string;
  bouncedPhoneNumber?: string;
  bouncedEmail: string;
}

const defaultForm: TForm = {
  name: "",
  sureName: "",
  date: null,
  idCardNo: "",
  phoneNumber: "",
  email: "",
  pin: "",
  bouncedPhoneNumber: "",
  bouncedEmail: "",
};

const EditAccountModal = (props: IEditAccountModal) => {
  const { t } = useTranslation();
  const { isShowPinConfirm, onShowPinConfirm, onHidePinConfirm } =
    useEditAccountModalController();
  const { isOpen, onClose, profileFarmer } = props;

  const [form, setForm] = useState(defaultForm);
  const dispatch = useDispatch<AppDispatch>();
  const queryClient = useQueryClient();
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  function debounce(cb: (...args: any[]) => void, delay: number) {
    const tempTimeoutId = setTimeout(() => {
      cb();
    }, delay);
    clearTimeout(timeoutId);
    setTimeoutId(tempTimeoutId);
  }

  const { data: mobileNumber } = useQuery({
    queryKey: ["check_duplicate_phone", form.phoneNumber],
    enabled: form.phoneNumber.length === 10,
    queryFn: async () => {
      const result = await checkDuplicatePhone({
        mobileNumber: form.phoneNumber,
      });
      return result;
    },
  });

  const { data: email } = useQuery({
    queryKey: ["check_duplicate_email", form.bouncedEmail],
    enabled: !!validateEmail(form.bouncedEmail),
    queryFn: async () => {
      const result = await checkDuplicateEmail({ email: form.bouncedEmail });
      return result;
    },
  });

  const { data: verifyPin } = useQuery({
    queryKey: ["verify_pin", form.pin],
    enabled: form.pin.length === 4,
    queryFn: async () => {
      const result = await checkVerifyPin({ pin: form.pin });
      return result;
    },
  });

  const editAccountMutation = useMutation({
    mutationFn: editAccount,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["get_profile_farmer"],
      });
      onHidePinConfirm();
      setTimeout(() => {
        dispatch(loadingActions.hide());
        setForm((prev) => ({ ...prev, pin: "" }));
        setTimeout(() => {
          dispatch(
            snakeActions.showMessage({
              message: `${t("accountCenter.edit.success")}`,
              type: "success",
            })
          );
        }, 500);
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const [phoneErrorApi, setPhoneErrorApi] = useState("");
  const [emailErrorApi, setEmailErrorApi] = useState("");

  const onChangeForm = useCallback(
    (key: keyof typeof form) => (value: any) => {
      setForm((prev) => ({ ...prev, [key]: value }));
    },
    []
  );

  const errorSchema = Y.object({
    name: Y.string()
      .max(40, `${t("signup.must_be_15_characters_or_less")}`)
      .required(`${t("signup.name_required_error")}`)
      .matches(/^[a-zA-Zก-ํ]+(?<!฿)$/, `${t("signup.invalid_name_error")}`),
    sureName: Y.string()
      .max(40, `${t("signup.must_be_15_characters_or_less")}`)
      .required(`${t("signup.surname_required_error")}`)
      .matches(/^[a-zA-Zก-ํ]+(?<!฿)$/, `${t("signup.invalid_surname_error")}`),
    phoneNumber: Y.string()
      .required(`${t("signup.mobile_number_required_error")}`)
      .min(10, `${t("signup.mobile_number_length_error")}`)
      .max(10, `${t("signup.mobile_number_length_error")}`),
    email: Y.string()
      .email(`${t("signup.invalid_email_error")}`)
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        `${t("signup.invalid_email_error")}`
      )
      .test(
        "disallowed-domain",
        `${t("signup.invalid_email_error")}`, // Custom error message
        (value) => {
          return value ? !/^[a-zA-Z0-9._%+-]+@example\.com$/.test(value) : true;
        }
      ),

    idCardNo: Y.string().test(
      "thai-id-card",
      `${t("signup.citizen_id_error")}`,
      (value) => {
        const id = typeof value === "string" ? value : "";

        if (!id) return true;
        if (id.length != 13 || id.charAt(0).match(/[09]/)) return false;

        let sum = 0;
        for (let i = 0; i < 12; i++) {
          sum += parseInt(id.charAt(i)) * (13 - i);
        }

        return !((11 - (sum % 11)) % 10 != parseInt(id.charAt(12)));
      }
    ),
  });

  const { onSubmit, errors, resetError } = useFormValidator({
    form,
    errorSchema,
    onValidateSuccess: async () => {
      if (!phoneErrorApi && !emailErrorApi) onShowPinConfirm();
    },
  });

  const onChangePhone = useCallback((value: any) => {
    setForm((prev) => ({ ...prev, phoneNumber: value }));
  }, []);

  const onChangeEmail = useCallback((value: any) => {
    setForm((prev) => ({ ...prev, email: value }));
  }, []);

  const onCloseFn = useCallback(() => {
    onClose();
    onHidePinConfirm();
    setTimeout(() => {
      resetError();
      setForm(defaultForm);
    }, 500);
  }, []);

  useEffect(() => {
    setForm({
      name: profileFarmer?.name || "",
      sureName: profileFarmer?.surname || "",
      date: dayjs(profileFarmer?.dob).isValid()
        ? dayjs(profileFarmer?.dob)
        : null,
      idCardNo: profileFarmer?.idCardNumber || "",
      phoneNumber: profileFarmer?.mobileNumber || "",
      email: profileFarmer?.email || "",
      bouncedEmail: profileFarmer?.email || "",
      pin: "",
    });
  }, [isOpen]);

  useEffect(() => {
    const handleValidatePhone = async () => {
      if (form.phoneNumber.length >= 10) {
        const duplicate = mobileNumber?.isDuplicate || false;
        if (duplicate) {
          setPhoneErrorApi(`${t("signup.mobile_number_unusable")}`);
        } else {
          setPhoneErrorApi("");
        }
      }
    };
    handleValidatePhone();
  }, [form.phoneNumber, mobileNumber]);

  useEffect(() => {
    const handleValidateEmail = () => {
      if (validateEmail(form.email)) {
        const duplicate = email?.isDuplicate || false;
        if (duplicate) {
          setEmailErrorApi(`${t("signup.email_unusable")}`);
        } else {
          setEmailErrorApi("");
        }
      }
      if (!form.email) {
        setEmailErrorApi("");
      }
    };
    handleValidateEmail();
  }, [form.bouncedEmail, email]);

  useEffect(() => {
    debounce(() => {
      setForm((prev) => ({ ...prev, bouncedEmail: prev.email }));
    }, 1000);
  }, [form.email]);

  useEffect(() => {
    if (verifyPin?.isVerify) {
      editAccountMutation.mutate({
        name: form.name,
        surname: form.sureName,
        dob: dayjs(form.date, FILTER_DATE_FORMAT).startOf("day").toDate(),
        idCardNumber: form.idCardNo,
        mobileNumber: form.phoneNumber,
        email: form.bouncedEmail,
        pin: form.pin,
      });
    }
  }, [verifyPin, form]);

  return (
    <Modal open={isOpen} className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <AppBarFS title="Personal Information" onIconPress={onCloseFn} />
        </div>
        <div className={classes.detailsContainer}>
          <Input
            label2="Enter Name"
            focused
            placeholder="Enter Name"
            value={form.name}
            onChangeText={onChangeForm("name")}
            customInputStyle={tw(
              "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
            )}
            borderRadius={"28px"}
            errorMessage={errors.name}
          />
          <Input
            label2="Enter Surname"
            focused
            placeholder="Enter Surname"
            value={form.sureName}
            onChangeText={onChangeForm("sureName")}
            customInputStyle={tw(
              "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
            )}
            borderRadius={"28px"}
            errorMessage={errors.sureName}
          />
          <DatePickerFS
            value={form.date}
            placeholder={`Enter Date`}
            className="!pb-3"
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <EventIcon color="info" />
                    </InputAdornment>
                  ),
                },
              },
            }}
            onChange={onChangeForm("date")}
            onClose={() => {}}
          />
          <Input
            label2="ID card No. (Optional)"
            placeholder="ID card No. (Optional)"
            value={form.idCardNo}
            onChangeText={onChangeForm("idCardNo")}
            customInputStyle={tw(
              "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
            )}
            borderRadius={"28px"}
            errorMessage={errors.idCardNo}
            focused
          />
          <Input
            label2="Enter Phone Number"
            focused
            placeholder="Enter Phone Number"
            value={form.phoneNumber}
            onChangeText={onChangePhone}
            inputType="number"
            isNotComma
            customInputStyle={tw(
              "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
            )}
            borderRadius={"28px"}
            errorMessage={errors.phoneNumber || phoneErrorApi}
            bottomMessage="You will get One Time Password on this number "
          />
          <Input
            label2="Email (Optional)"
            placeholder="Email (Optional)"
            value={form.email}
            onChangeText={onChangeEmail}
            customInputStyle={tw(
              "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
            )}
            focused
            borderRadius={"28px"}
            errorMessage={errors.email || emailErrorApi}
          />
          <ButtonFillCustom
            onClick={onSubmit}
            title="ยืนยัน"
            btnTextColor="#ffffff"
            btnBgColor="#68C184"
            className={classes.button}
          />
        </div>
        <BottomSheet
          open={isShowPinConfirm}
          onDismiss={onHidePinConfirm}
          snapPoints={({ minHeight }) => minHeight}
          className="h-full w-full"
        >
          <div className="!min-h-[400px]">
            <div className="pt-7 px-[43px] flex flex-col gap-4 justify-center">
              <div className="text-[#34407B] text-[16px] font-medium text-center">
                Enter 4 Digit PIN
              </div>
              <AuthBox
                boxLength={4}
                inputType="password"
                sucess={verifyPin?.isVerify === true}
                datatestid="verifyPin"
                labelStyle={{ textAlign: "center" }}
                alignError="center"
                containerClassName={tw("flex flex-col gap-y-1 self-center")}
                onChange={onChangeForm("pin")}
                error={verifyPin?.isVerify === false}
                errorMessage={"Incorrect PIN please retry"}
              />
            </div>
          </div>
        </BottomSheet>
      </div>
    </Modal>
  );
};

export default memo(EditAccountModal);

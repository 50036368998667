import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, CheckboxProps } from "@mui/material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";

export default function CheckboxFS(props: CheckboxProps) {
  return (
    <Checkbox
      size="medium"
      color="secondary"
      icon={<CircleOutlinedIcon fontSize="small" color="primary" />}
      checkedIcon={<FontAwesomeIcon size="lg" icon={faCircleCheck} />}
      {...props}
    />
  );
}

import { useState } from "react";
import { Order } from "../admin/components/Table/TableCustom";
import { IInvoiceTable } from "../admin/pages/invoice/type";

export const useInvoiceSearchFilter = () => {
  const [searchText, setSearchText] = useState("");
  const [searchSaleStatus, setSearchStatus] = useState("");
  const [searchOverdueStatus, setSearchOverdueStatus] = useState("");
  const [orderBy, setOrderBy] = useState<keyof IInvoiceTable>("updateDate");
  const [direction, setDirection] = useState<Order>("desc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  return {
    searchText,
    searchSaleStatus,
    setSearchStatus,
    orderBy,
    direction,
    page,
    limit,
    setSearchText,
    setOrderBy,
    setDirection,
    setPage,
    setLimit,
    searchOverdueStatus,
    setSearchOverdueStatus,
  };
};

export type TUseInvoiceSearchFilter = ReturnType<typeof useInvoiceSearchFilter>;

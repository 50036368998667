import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate } from "react-router-dom";
import AppBarFS from "../../components/app-bar/AppBar";
import PastPerformanceInfoCard from "../../components/common/PastPerformanceInfoCard/PastPerformanceInfoCard";
import { IconButton } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import TableScrollBar from "./TableScrollBar";
import FilterModal from "./FilterModal";
import {
  IMoveOutPigFarmPastPerformanceQueryOption,
  IMoveOutPigFarmPastPerformanceResponse,
  IMovePutPigFarmPastPerformanceResponse,
} from "../../types/MoveOutPig.type";
import { ITableData, Order } from "./types";
import { getMoveOutPigHousesFarmPastPerformance } from "../../services/MoveOutPig.service";
import { thousandComma } from "../../utils/common/string";
import { useDayjsLocale } from "../../hooks/useDayjsLocale.hook";

function FarmPastPerformance() {
  useDayjsLocale();
  const { t } = useTranslation();
  const [isOpenFilterModal, setIsOpenFilterModal] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState<Order>("asc");
  const [sortBy, setSortBy] = useState<keyof ITableData | "generationClosedAt">(
    "name"
  );
  const navigate = useNavigate();
  const { farmPastPerformance } = useLoaderData() as {
    farmPastPerformance: IMoveOutPigFarmPastPerformanceResponse;
  };
  const [farmPastHouses, setFarmPastHouses] =
    useState<IMovePutPigFarmPastPerformanceResponse | null>();
  const [farmPastTableData, setFarmPastTableData] = useState<ITableData[]>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const handleOnResetFilter = async () => {
    await getFarmPastHouses({
      orderBy: "name",
      orderType: "asc",
    });
    setStartDate("");
    setEndDate("");
    setOrderBy("asc");
    setSortBy("name");
  };

  const handleOnSubmitFilter = async (
    startDate: string,
    endDate: string,
    orderBy: Order
  ) => {
    await getFarmPastHouses({
      orderBy: "generationClosedAt",
      orderType: orderBy,
      closeDateFrom: startDate,
      closeDateTo: endDate,
    });
    setStartDate(startDate);
    setEndDate(endDate);
    setOrderBy(orderBy);
    setSortBy("generationClosedAt");
  };

  const getFarmPastHouses = async (
    query: IMoveOutPigFarmPastPerformanceQueryOption
  ) => {
    try {
      const result = await getMoveOutPigHousesFarmPastPerformance(
        farmPastPerformance.farmId,
        {
          params: {
            ...(query?.orderBy ? { orderBy: query.orderBy } : {}),
            ...(query?.orderType ? { orderType: query.orderType } : {}),
            ...(query?.closeDateTo ? { closeDateTo: query.closeDateTo } : {}),
            ...(query?.closeDateFrom
              ? { closeDateFrom: query.closeDateFrom }
              : {}),
          },
        }
      );
      if (result.success) {
        setFarmPastHouses(result);
        if (result.items.length > 0) {
          const dataTable: ITableData[] = result.items.map((item) => {
            return {
              id: item.houseId ?? "-",
              name: item.houseName ?? "-",
              fcr: item.fcr ?? "-",
              fcg: item.fcg ?? "-",
              adg: item.adg ?? "-",
              death: item.percentDeath ?? "-",
              price: item.totalPrice ?? "-",
              pigAmount: item.amountOfPigs ?? "-",
            };
          });
          setFarmPastTableData(dataTable);
        } else {
          setFarmPastTableData([]);
        }
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  useEffect(() => {
    getFarmPastHouses({ orderBy: sortBy, orderType: orderBy });
  }, []);

  return (
    <div className="h-screen overflow-auto flex flex-col w-full bg-[#FFFFF]">
      <div className="p-4 rounded-b-[40px] bg-white">
        <AppBarFS
          onIconPress={() => navigate(`/farm/${farmPastPerformance?.farmId}`)}
          title={t("farmPastPerformance.titleBar")}
        />
      </div>
      <div className="flex flex-col p-4">
        <div className="w-full grid grid-cols-3 gap-1">
          <PastPerformanceInfoCard
            title="FCR"
            value={thousandComma(Number(farmPastPerformance?.avgFcr), 2) ?? "-"}
            bgColor="#F6FBFF"
            t={t}
          />
          <PastPerformanceInfoCard
            title="ADG"
            value={thousandComma(Number(farmPastPerformance?.avgAdg), 2) ?? "-"}
            bgColor="#F6FBFF"
            t={t}
          />
          <PastPerformanceInfoCard
            title="Loss (%)"
            value={
              farmPastPerformance?.avgLoss
                ? `${thousandComma(Number(farmPastPerformance.avgLoss), 2)}%`
                : "-"
            }
            bgColor="#F6FBFF"
            t={t}
          />
        </div>

        <div className="flex flex-col grow gap-4 pt-5">
          <div className="px-1 flex h-full w-full justify-between items-center">
            <span className="text-tertiary font-semibold ml-1 capitalize text-xl">
              {t("farmPastPerformance.titleTable")}
            </span>
            <div className="h-full flex gap-2 items-center">
              <span className="text-secondary font-normal ml-1 text-[1rem] items-center">
                {t("farmPastPerformance.titleFilter")}
              </span>
              <IconButton
                aria-label="filter"
                sx={{
                  color: "#68C184",
                  bgcolor: "#FFF",
                  border: "1px solid #68C184",
                  ":hover": {
                    color: "#FFFFFF",
                    border: "1px solid #68C184",
                    bgcolor: "#68C184",
                  },
                  ":active": {
                    color: "#FFFFFF",
                    border: "1px solid #68C184",
                    bgcolor: "#68C184",
                  },
                }}
                onClick={() => setIsOpenFilterModal(true)}
              >
                <FilterListIcon />
              </IconButton>
            </div>
          </div>
          <TableScrollBar
            onClick={(val) => {
              navigate(`/house/selling-history/${val}`, {
                state: {
                  from: `/farm-past-performance/${farmPastPerformance?.farmId}`,
                  subFrom: `/farm-past-performance/${farmPastPerformance?.farmId}`,
                },
              });
            }}
            onSort={async (key, order) => {
              setOrderBy(order);
              setSortBy(key);
              await getFarmPastHouses({
                orderBy: key,
                orderType: order,
                closeDateFrom: startDate,
                closeDateTo: endDate,
              });
            }}
            items={farmPastTableData}
            orderBy={orderBy}
            sortBy={sortBy}
          />
        </div>
        {/* <button
          onClick={() =>
            navigate(
              `/feed-order-delivery/${farmPastPerformance?.farmId}/view-document`
            )
          }
          className="h-[56px] rounded-3xl w-fit border-[1px] border-solid p-2"
        >
          Order View Document
        </button> */}
      </div>
      <FilterModal
        title=""
        isOpen={isOpenFilterModal}
        onClose={() => setIsOpenFilterModal(false)}
        t={t}
        onReset={() => {
          handleOnResetFilter();
        }}
        onSubmit={(startDate, endDate, orderCloseFarm) => {
          handleOnSubmitFilter(startDate, endDate, orderCloseFarm);
        }}
      />
    </div>
  );
}

export default FarmPastPerformance;

export default function LllustationSVG() {
  return (
    <svg
      width="233"
      height="181"
      viewBox="0 0 233 181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8877_279922)">
        <path
          d="M197.957 173.952C214.207 152.751 226.418 127.71 228.553 101.081C230.688 74.4528 221.687 46.227 201.469 28.7683C181.251 11.3095 149.395 7.02934 127.205 21.9077C107.033 35.4343 96.6552 61.9552 74.0254 70.7716C62.6184 75.2157 49.8187 74.3351 38.0328 77.6374C17.241 83.4638 1.23631 103.421 0.0689841 124.981C-1.09834 146.54 12.6485 168.11 33.7628 174.986C86.1851 173.834 138.607 172.688 197.957 173.952Z"
          fill="#EEF2FF"
        />
        <path
          d="M104.489 25.3475C99.1541 46.2826 78.0859 62.0569 56.4954 61.2787C45.0781 60.864 33.2717 55.6622 27.5836 45.7553C21.8954 35.8484 24.4758 21.2055 34.567 15.8553C43.235 11.2628 54.0686 14.3091 63.4431 11.4061C72.2851 8.667 79.33 0.782415 88.556 0.0502751C93.7373 -0.359314 99.0671 1.75519 102.554 5.61045C106.04 9.47082 107.162 14.8569 104.489 25.3475Z"
          fill="#EEF2FF"
        />
        <path
          d="M62.2692 85.0961L63.4416 79.3312L58.7569 78.3789C58.7211 76.8941 58.4293 75.4452 57.9224 74.0987L61.9108 71.4568L58.6699 66.552L54.6815 69.1938C53.632 68.1955 52.4134 67.3712 51.0618 66.7568L52.0141 62.067L46.2491 60.8945L45.2968 65.5843C43.8121 65.6202 42.3631 65.9069 41.0166 66.4137L38.3748 62.4254L33.4699 65.6714L36.1118 69.6597C35.1134 70.7042 34.2891 71.9227 33.6747 73.2744L28.9849 72.3221L27.8125 78.087L32.5023 79.0393C32.5381 80.5241 32.8248 81.973 33.3317 83.3247L29.3433 85.9665L32.5944 90.8662L36.5828 88.2244C37.6273 89.2227 38.8407 90.0521 40.1974 90.6665L39.2452 95.3512L45.0101 96.5237L45.9624 91.839C47.4472 91.8031 48.8961 91.5113 50.2477 91.0044L52.8896 94.9928L57.7893 91.7468L55.1474 87.7584C56.1458 86.7089 56.9752 85.4904 57.5896 84.1387L62.2743 85.091L62.2692 85.0961ZM44.1449 85.9819C40.1309 85.1627 37.5351 81.2511 38.3492 77.2269C39.1684 73.2129 43.0851 70.6171 47.1041 71.4312C51.1232 72.2453 53.7139 76.1671 52.8998 80.1862C52.0806 84.2053 48.164 86.7959 44.1449 85.9819Z"
          fill="#3777BC"
        />
        <path
          d="M81.7196 104.533L83.0559 100.483L79.7639 99.3981C79.8663 98.3229 79.7792 97.258 79.5283 96.2442L82.6259 94.6827L80.711 90.8735L77.6135 92.4351C76.9428 91.6261 76.139 90.9298 75.2123 90.3718L76.2977 87.0746L72.2479 85.7383L71.1625 89.0355C70.0873 88.9331 69.0224 89.015 68.0086 89.2659L66.4471 86.1683L62.6379 88.0883L64.1995 91.1858C63.3957 91.8514 62.6942 92.6603 62.1362 93.5819L58.8441 92.4965L57.5078 96.5463L60.7999 97.6317C60.6975 98.7069 60.7794 99.7718 61.0303 100.791L57.9328 102.352L59.8527 106.156L62.9502 104.595C63.6158 105.399 64.4196 106.105 65.3463 106.663L64.2609 109.955L68.3107 111.292L69.3961 107.999C70.4713 108.102 71.5362 108.015 72.5551 107.764L74.1166 110.861L77.9207 108.947L76.3591 105.849C77.1681 105.178 77.8695 104.375 78.4276 103.453L81.7196 104.538V104.533ZM68.5974 103.622C65.7764 102.69 64.2404 99.649 65.1722 96.8279C66.1041 94.0069 69.1453 92.4709 71.9663 93.4027C74.7874 94.3345 76.3233 97.3757 75.3915 100.197C74.4597 103.023 71.4185 104.554 68.5974 103.622Z"
          stroke="#3777BC"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
        />
        <path
          d="M88.9218 78.4766L89.4389 75.7785L87.2476 75.3586C87.222 74.6623 87.0786 73.9916 86.8329 73.3619L88.6811 72.1075L87.14 69.8343L85.2918 71.0887C84.7951 70.6279 84.2268 70.249 83.5869 69.9674L84.0067 67.771L81.3085 67.2539L80.8887 69.4503C80.1924 69.4759 79.5217 69.6142 78.8971 69.8599L77.6427 68.0116L75.3695 69.5527L76.6238 71.401C76.1631 71.8925 75.7842 72.4659 75.5026 73.1008L73.3062 72.681L72.7891 75.3791L74.9855 75.799C75.0111 76.4953 75.1493 77.166 75.3951 77.7957L73.5468 79.0501L75.0879 81.3233L76.9362 80.0689C77.4277 80.5297 78.0011 80.9086 78.636 81.1902L78.2161 83.3815L80.9143 83.8986L81.3341 81.7073C82.0304 81.6817 82.7011 81.5383 83.3309 81.2977L84.5852 83.146L86.8533 81.6049L85.599 79.7566C86.0597 79.26 86.4437 78.6917 86.7202 78.0517L88.9115 78.4715L88.9218 78.4766ZM80.4637 78.9835C78.5848 78.6251 77.3509 76.8076 77.7144 74.9286C78.0728 73.0496 79.8903 71.8208 81.7693 72.1792C83.6483 72.5376 84.8771 74.3552 84.5187 76.2341C84.1552 78.1131 82.3427 79.3419 80.4637 78.9835Z"
          stroke="#3777BC"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
        />
        <path
          d="M233 89.5066L232.509 83.3934L227.537 83.7928C227.071 82.3132 226.36 80.9462 225.459 79.743L228.694 75.9441L224.025 71.9659L220.789 75.7649C219.448 75.0686 217.989 74.5924 216.453 74.3671L216.053 69.3906L209.94 69.8821L210.34 74.8586C208.86 75.3245 207.493 76.0311 206.29 76.9322L202.491 73.6964L198.513 78.3709L202.312 81.6066C201.615 82.9429 201.139 84.4021 200.914 85.938L195.938 86.3374L196.429 92.4505L201.406 92.0511C201.871 93.5308 202.578 94.8978 203.479 96.1061L200.243 99.905L204.918 103.878L208.153 100.079C209.49 100.775 210.949 101.257 212.485 101.482L212.884 106.453L218.997 105.962L218.598 100.99C220.078 100.525 221.445 99.8128 222.653 98.9118L226.452 102.148L230.425 97.4782L226.626 94.2424C227.322 92.901 227.804 91.4419 228.029 89.9059L233 89.5066ZM215.091 95.6402C210.831 95.9832 207.094 92.8089 206.751 88.544C206.408 84.2843 209.587 80.5468 213.847 80.2038C218.106 79.8607 221.844 83.0402 222.187 87.2999C222.53 91.5699 219.351 95.2971 215.091 95.6402Z"
          fill="#3777BC"
        />
        <path
          d="M175.101 30.8477L175.619 28.1496L173.427 27.7297C173.402 27.0334 173.258 26.3627 173.013 25.733L174.861 24.4786L173.32 22.2054L171.471 23.4598C170.975 22.999 170.407 22.6201 169.767 22.3385L170.186 20.1421L167.488 19.625L167.068 21.8214C166.372 21.847 165.701 21.9853 165.077 22.231L163.822 20.3827L161.549 21.9238L162.804 23.7721C162.343 24.2636 161.964 24.837 161.682 25.4719L159.486 25.0521L158.969 27.7502L161.165 28.1701C161.191 28.8664 161.329 29.5371 161.575 30.1668L159.726 31.4212L161.268 33.6944L163.116 32.44C163.607 32.9008 164.181 33.2797 164.816 33.5613L164.396 35.7526L167.094 36.2697L167.514 34.0784C168.21 34.0528 168.881 33.9094 169.511 33.6688L170.765 35.5171L173.038 33.976L171.784 32.1277C172.245 31.6311 172.629 31.0628 172.905 30.4228L175.096 30.8426L175.101 30.8477ZM166.643 31.3546C164.764 30.9962 163.531 29.1787 163.894 27.2997C164.252 25.4207 166.07 24.1919 167.949 24.5503C169.828 24.9087 171.057 26.7263 170.698 28.6052C170.335 30.4842 168.522 31.713 166.643 31.3546Z"
          stroke="#3777BC"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
        />
        <path
          d="M74.7101 45.682C85.0564 45.682 93.4437 37.2947 93.4437 26.9484C93.4437 16.6022 85.0564 8.21484 74.7101 8.21484C64.3639 8.21484 55.9766 16.6022 55.9766 26.9484C55.9766 37.2947 64.3639 45.682 74.7101 45.682Z"
          fill="#68C184"
        />
        <path
          d="M74.7094 43.0087C83.5797 43.0087 90.7705 35.818 90.7705 26.9477C90.7705 18.0775 83.5797 10.8867 74.7094 10.8867C65.8392 10.8867 58.6484 18.0775 58.6484 26.9477C58.6484 35.818 65.8392 43.0087 74.7094 43.0087Z"
          fill="#FDFEFD"
        />
        <path
          d="M74.7158 11.6875L74.6953 13.8174"
          stroke="#212B3B"
          strokeWidth="1.02397"
          strokeMiterlimit="10"
        />
        <path
          d="M74.7236 40.0664L74.7031 42.1963"
          stroke="#212B3B"
          strokeWidth="1.02397"
          strokeMiterlimit="10"
        />
        <path
          d="M59.4531 26.9453L61.583 26.9607"
          stroke="#212B3B"
          strokeWidth="1.02397"
          strokeMiterlimit="10"
        />
        <path
          d="M87.8359 26.9336L89.9658 26.9541"
          stroke="#212B3B"
          strokeWidth="1.02397"
          strokeMiterlimit="10"
        />
        <path
          d="M63.9219 16.1562L65.4118 17.6768"
          stroke="#212B3B"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
        />
        <path
          d="M84 36.2188L85.4899 37.7342"
          stroke="#212B3B"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
        />
        <path
          d="M63.9141 37.7294L65.4347 36.2344"
          stroke="#212B3B"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
        />
        <path
          d="M83.9766 17.6539L85.492 16.1641"
          stroke="#212B3B"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
        />
        <path
          d="M74.707 28.2303C75.4167 28.2303 75.992 27.655 75.992 26.9452C75.992 26.2355 75.4167 25.6602 74.707 25.6602C73.9972 25.6602 73.4219 26.2355 73.4219 26.9452C73.4219 27.655 73.9972 28.2303 74.707 28.2303Z"
          fill="#212B3B"
        />
        <path
          d="M168.445 53.5758V44.744C168.445 40.8887 171.568 37.7656 175.424 37.7656H210.807C214.662 37.7656 217.785 40.8887 217.785 44.744V53.5758C217.785 57.431 214.662 60.5541 210.807 60.5541H175.424C171.568 60.549 168.445 57.4259 168.445 53.5758Z"
          fill="white"
        />
        <path
          d="M189.936 56.9141L188.344 72.0433L202.582 57.1547L189.936 56.9141Z"
          fill="white"
        />
        <path
          d="M179.028 52.6059C180.475 52.6059 181.649 51.4988 181.649 50.1331C181.649 48.7673 180.475 47.6602 179.028 47.6602C177.58 47.6602 176.406 48.7673 176.406 50.1331C176.406 51.4988 177.58 52.6059 179.028 52.6059Z"
          fill="#0A4494"
        />
        <path
          d="M193.309 52.6059C194.757 52.6059 195.93 51.4988 195.93 50.1331C195.93 48.7673 194.757 47.6602 193.309 47.6602C191.861 47.6602 190.688 48.7673 190.688 50.1331C190.688 51.4988 191.861 52.6059 193.309 52.6059Z"
          fill="#0A4494"
        />
        <path
          d="M207.2 52.6059C208.647 52.6059 209.821 51.4988 209.821 50.1331C209.821 48.7673 208.647 47.6602 207.2 47.6602C205.752 47.6602 204.578 48.7673 204.578 50.1331C204.578 51.4988 205.752 52.6059 207.2 52.6059Z"
          fill="#0A4494"
        />
        <path
          d="M128.21 45.8361C139.656 45.8361 148.935 36.4769 148.935 24.9317C148.935 13.3866 139.656 4.02734 128.21 4.02734C116.763 4.02734 107.484 13.3866 107.484 24.9317C107.484 36.4769 116.763 45.8361 128.21 45.8361Z"
          fill="#EEF2FF"
        />
        <path
          d="M129.585 47.4963C141.031 47.4963 150.31 38.1371 150.31 26.5919C150.31 15.0467 141.031 5.6875 129.585 5.6875C118.138 5.6875 108.859 15.0467 108.859 26.5919C108.859 38.1371 118.138 47.4963 129.585 47.4963Z"
          fill="white"
        />
        <path
          d="M123.947 32.0956C122.524 30.6364 120.204 30.6108 118.766 32.0393C117.327 33.4677 117.317 35.8075 118.74 37.2667C120.164 38.7258 122.483 38.7514 123.921 37.323C125.36 35.8945 125.37 33.5548 123.947 32.0956ZM120.665 37.1796L118.853 35.3211L119.534 32.8175L122.022 32.1724L123.834 34.0309L123.159 36.5294L120.67 37.1745L120.665 37.1796Z"
          fill="#3777BC"
        />
        <path
          d="M141.373 17.4803L138.495 20.3372C138.055 20.7775 137.338 20.7673 136.903 20.3167L136.007 19.3952C135.567 18.9446 135.572 18.2278 136.012 17.7875L138.889 14.9307C137.63 14.5723 136.191 14.9153 135.157 15.9393C133.647 17.4394 133.575 19.8355 135.003 21.2998C136.427 22.7589 138.807 22.7282 140.318 21.2281C141.352 20.2041 141.71 18.7552 141.378 17.4803H141.373Z"
          fill="#3777BC"
        />
        <path
          d="M124.292 34.3146L121.922 31.8826C125.813 27.5461 129.945 23.4451 134.307 19.5898L136.677 22.0218C132.756 26.3327 128.629 30.4286 124.292 34.3146Z"
          fill="#3777BC"
        />
        <path
          d="M132.703 43.5896L140.26 51.9964L140.173 37.9219L132.703 43.5896Z"
          fill="white"
        />
        <path
          d="M172.438 172.341C174.649 160.519 177.721 148.739 182.805 137.562C187.889 126.385 195.052 115.797 204.698 107.283C208.343 104.063 213.325 100.945 218.071 102.296C222.827 103.653 224.66 108.886 224.251 113.401C223.539 121.281 218.235 128.321 212.69 134.618C200.94 147.965 187.326 159.91 172.438 172.341Z"
          fill="url(#paint0_linear_8877_279922)"
        />
        <path
          d="M218.908 106.73C201.562 126.503 186.269 147.991 173.336 170.754"
          stroke="#3777BC"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M211.469 104.258C212.564 107.043 213.286 109.972 213.614 112.941C216.814 112.434 220.126 112.634 223.234 113.525"
          stroke="#3777BC"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M197.383 116.551C199.866 119.587 201.474 123.304 201.98 127.164C206.86 126.125 212.02 126.417 216.741 127.994"
          stroke="#3777BC"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M186.211 134.598C188.126 137.444 189.278 140.777 189.513 144.182C194.08 143.266 198.77 142.969 203.419 143.307"
          stroke="#3777BC"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M179.297 151.477C180.377 153.043 181.125 154.83 181.468 156.689C183.895 155.721 186.424 154.994 188.999 154.523"
          stroke="#3777BC"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M168.969 174.978C179.961 164.6 191.22 154.038 205.545 146.922C209.216 145.099 213.225 143.481 217.438 143.368C221.652 143.256 226.091 145.022 227.392 148.248C228.661 151.396 226.624 155.042 223.5 157.335C220.377 159.634 216.353 160.873 212.447 162.056C198.224 166.372 184.001 170.683 168.969 174.978Z"
          fill="url(#paint1_linear_8877_279922)"
        />
        <path
          d="M224.352 149.23C204.84 153.9 186.516 162.307 171.039 173.688"
          stroke="#3777BC"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M217.175 145.336C217.216 147.348 216.878 149.365 216.172 151.28C218.312 152.386 219.94 154.229 220.621 156.318"
          stroke="#3777BC"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M205.672 148.684C205.442 151.013 204.889 153.322 204.039 155.539C206.113 156.947 207.756 158.826 208.765 160.946"
          stroke="#3777BC"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M192.262 155.586C192.6 157.378 192.59 159.216 192.227 161.003C194.525 161.448 196.604 162.697 197.904 164.413"
          stroke="#3777BC"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M181.88 163.984C182.018 164.594 181.901 166.017 181.43 167.051C183.426 167.154 185.367 167.932 186.759 169.191"
          stroke="#3777BC"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M170.791 174.984C180.564 149.907 189.519 124.323 197.619 98.3296C199.477 92.3752 201.228 85.3251 198.581 79.98C195.827 74.4096 189.217 73.7133 184.645 76.9951C180.073 80.277 177.206 86.4054 175.163 92.5595C166.055 119.956 169.244 150.905 170.791 174.984Z"
          fill="url(#paint2_linear_8877_279922)"
        />
        <path
          d="M192.204 78.918C180.382 101.164 174.894 133.849 171.469 174.393"
          stroke="#3777BC"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M176.195 96.582C178.607 98.9832 180.598 102.009 182.063 105.445C185.908 102.951 190.162 101.41 194.504 100.949"
          stroke="#3777BC"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M170.828 117.168C173.455 119.39 175.82 122.078 177.863 125.124C181.119 122.052 184.887 119.779 188.865 118.474"
          stroke="#3777BC"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M170.695 138.017C172.195 139.159 173.527 140.634 174.602 142.349C176.87 140.291 179.399 138.657 182.067 137.516"
          stroke="#3777BC"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M182.742 81.6016C185.108 83.7212 186.725 87.0337 187.12 90.5716C190.325 88.4417 194.18 87.7966 197.738 88.7898"
          stroke="#3777BC"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M105.711 84.4102C116.37 106.497 123.6 130.238 127.061 154.522"
          stroke="url(#paint3_linear_8877_279922)"
          strokeWidth="1.02397"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M105.954 85.3248C101.213 84.2547 97.2452 80.7578 95.7553 76.3445C95.3048 75.0134 95.2075 73.2726 96.4209 72.4278C97.6395 71.5779 99.3239 72.223 100.507 73.0422C104.602 75.8786 107.086 80.6862 105.954 85.3248Z"
          fill="url(#paint4_linear_8877_279922)"
        />
        <path
          d="M107.99 88.8558C106.899 84.5091 106.956 79.8756 108.148 75.5544C108.317 74.9452 108.517 74.3257 108.891 73.8137C109.265 73.3017 109.848 72.9023 110.483 72.9023C111.118 72.9023 111.702 73.2966 112.065 73.8137C112.429 74.3308 112.598 74.9605 112.705 75.5852C113.105 77.9454 112.68 80.3876 111.835 82.6301C110.985 84.8726 109.731 86.9308 107.99 88.8558Z"
          fill="url(#paint5_linear_8877_279922)"
        />
        <path
          d="M111.293 95.647C111.026 91.5051 111.82 87.3016 113.576 83.5437C114.303 81.9872 115.225 80.4717 116.592 79.4222C117.308 78.8692 118.286 78.4596 119.105 78.8488C119.745 79.1559 120.099 79.8881 120.129 80.5997C120.165 81.3114 119.935 82.0026 119.684 82.6682C117.825 87.6447 114.692 92.1348 111.293 95.647Z"
          fill="url(#paint6_linear_8877_279922)"
        />
        <path
          d="M113.951 101.959C113.557 97.0033 116.327 91.9398 120.71 89.6C121.657 89.0931 122.86 88.7194 123.761 89.3082C124.478 89.7741 124.744 90.7315 124.616 91.5763C124.488 92.421 124.032 93.1737 123.551 93.8802C121.145 97.4283 117.914 100.423 113.951 101.959Z"
          fill="url(#paint7_linear_8877_279922)"
        />
        <path
          d="M116.32 109.81C116.653 104.475 119.986 99.4318 124.753 97.0203C125.51 96.6363 126.453 96.3291 127.164 96.7899C127.855 97.2405 127.973 98.2081 127.855 99.0222C127.579 100.891 126.442 102.509 125.203 103.937C122.833 106.676 119.976 108.991 116.32 109.81Z"
          fill="url(#paint8_linear_8877_279922)"
        />
        <path
          d="M118.875 116.268C120.175 111.168 123.995 106.781 128.864 104.784C129.668 104.456 130.594 104.19 131.388 104.554C132.171 104.912 132.591 105.834 132.571 106.699C132.55 107.564 132.166 108.378 131.705 109.11C130.308 111.337 128.178 113.032 125.869 114.292C123.555 115.551 121.056 116.406 118.875 116.268Z"
          fill="url(#paint9_linear_8877_279922)"
        />
        <path
          d="M108.367 91.0536C105.935 87.2956 101.742 84.7306 97.2877 84.28C96.6681 84.2186 96.0128 84.1981 95.465 84.4899C94.4308 85.0378 94.2055 86.5328 94.7431 87.5721C95.4394 88.9186 96.9907 89.5637 98.4447 89.9938C101.209 90.8078 104.097 91.2225 108.367 91.0536Z"
          fill="url(#paint10_linear_8877_279922)"
        />
        <path
          d="M112.159 98.079C109.942 94.2852 105.365 92.0171 101.003 92.5445C99.9432 92.6725 98.8526 92.9745 98.0744 93.7067C97.2962 94.4388 96.9173 95.6778 97.4293 96.6096C98.0079 97.6541 99.3646 97.9254 100.558 98.0483C104.075 98.4169 107.628 98.4989 112.159 98.079Z"
          fill="url(#paint11_linear_8877_279922)"
        />
        <path
          d="M114.994 106.001C111.937 102.494 107.063 100.666 102.455 101.306C101.688 101.413 100.909 101.593 100.285 102.053C99.6652 102.514 99.2402 103.308 99.4143 104.06C99.5679 104.706 100.131 105.197 100.751 105.438C101.37 105.678 102.051 105.714 102.711 105.75C106.684 105.945 110.657 106.139 114.994 106.001Z"
          fill="url(#paint12_linear_8877_279922)"
        />
        <path
          d="M117.943 114.308C116.361 112.097 114.313 110.151 111.83 109.05C109.341 107.944 106.382 107.755 103.914 108.892C103.203 109.219 102.481 109.742 102.348 110.509C102.22 111.267 102.742 112.015 103.412 112.394C104.078 112.772 104.867 112.865 105.629 112.947C108.717 113.29 111.804 113.633 117.943 114.308Z"
          fill="url(#paint13_linear_8877_279922)"
        />
        <path
          d="M119.848 120.942C117.109 117.65 112.885 115.648 108.605 115.602C107.479 115.592 106.286 115.73 105.375 116.401C104.463 117.071 103.967 118.403 104.545 119.375C105.124 120.348 106.424 120.553 107.551 120.65C110.413 120.901 113.269 121.152 119.848 120.942Z"
          fill="url(#paint14_linear_8877_279922)"
        />
        <path
          d="M121.189 126.37C118.224 123.636 113.775 123.185 109.787 123.774C108.758 123.928 107.672 124.168 106.925 124.895C106.177 125.622 105.973 126.989 106.756 127.67C107.334 128.172 108.189 128.136 108.952 128.075C112.531 127.788 116.11 127.496 121.189 126.37Z"
          fill="url(#paint15_linear_8877_279922)"
        />
        <path
          d="M123.094 132.924C121.696 131.245 119.52 130.334 117.344 130.134C115.168 129.934 112.977 130.38 110.893 131.04C110.084 131.296 109.26 131.603 108.656 132.197C108.052 132.791 107.719 133.744 108.077 134.506C108.589 135.607 110.084 135.73 111.293 135.628C114.718 135.336 118.092 134.45 123.094 132.924Z"
          fill="url(#paint16_linear_8877_279922)"
        />
        <path
          d="M124.651 139.894C120.611 137.692 115.665 136.658 111.375 138.307C110.31 138.716 109.158 139.53 109.23 140.672C109.276 141.379 109.824 141.983 110.464 142.28C111.109 142.577 111.836 142.623 112.547 142.628C116.157 142.643 119.69 141.66 124.651 139.894Z"
          fill="url(#paint17_linear_8877_279922)"
        />
        <path
          d="M125.373 146.158C122.035 144.514 118.082 144.161 114.504 145.185C113.505 145.472 112.491 145.886 111.816 146.68C111.14 147.473 110.914 148.723 111.539 149.557C112.184 150.417 113.418 150.52 114.493 150.494C117.601 150.402 120.673 149.808 125.373 146.158Z"
          fill="url(#paint18_linear_8877_279922)"
        />
        <path
          d="M121.102 123.422C122.008 119.367 125.059 115.86 128.95 114.396C129.959 114.017 131.06 113.766 132.114 113.992C133.169 114.217 134.152 115.021 134.326 116.08C134.459 116.915 134.08 117.77 133.507 118.395C132.934 119.019 132.186 119.444 131.439 119.844C128.592 121.364 125.597 122.598 121.102 123.422Z"
          fill="url(#paint19_linear_8877_279922)"
        />
        <path
          d="M122.594 131.144C124.053 126.234 128.185 122.21 133.13 120.879C133.75 120.71 134.462 120.602 134.984 120.966C135.731 121.488 135.629 122.635 135.265 123.47C134.359 125.538 132.429 126.972 130.437 128.036C128.451 129.101 126.306 129.91 122.594 131.144Z"
          fill="url(#paint20_linear_8877_279922)"
        />
        <path
          d="M123.891 136.817C124.72 134.825 126.266 133.177 128.104 132.04C129.937 130.903 132.047 130.263 134.187 129.961C134.97 129.854 135.784 129.792 136.537 130.038C137.289 130.284 137.965 130.908 138.052 131.692C138.129 132.332 137.801 132.972 137.33 133.417C136.859 133.863 136.26 134.139 135.661 134.38C132.303 135.742 128.673 136.238 123.891 136.817Z"
          fill="url(#paint21_linear_8877_279922)"
        />
        <path
          d="M125.383 143.671C128.066 139.513 132.443 136.134 137.384 135.935C138.633 135.883 140.143 136.226 140.538 137.414C140.788 138.167 140.456 139.017 139.893 139.575C139.329 140.133 138.577 140.445 137.824 140.696C133.626 142.089 128.752 141.95 125.383 143.671Z"
          fill="url(#paint22_linear_8877_279922)"
        />
        <path
          d="M9.49219 155.553C39.3768 153.295 69.7991 158.507 97.2262 170.585"
          stroke="url(#paint23_linear_8877_279922)"
          strokeWidth="1.02397"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M14.552 155.05C10.7377 152.398 5.18776 152.572 1.55266 155.464C1.04579 155.864 0.569647 156.325 0.293175 156.913C0.0167021 157.497 -0.0191369 158.229 0.339254 158.767C0.85636 159.55 1.95713 159.663 2.89407 159.617C7.22035 159.396 11.2804 157.558 14.552 155.05Z"
          fill="url(#paint24_linear_8877_279922)"
        />
        <path
          d="M21.9863 155.035C18.7403 155.788 15.822 157.877 14.071 160.708C13.6922 161.317 13.3645 161.973 13.2775 162.684C13.1904 163.396 13.3901 164.169 13.9277 164.645C14.4806 165.137 15.3152 165.219 16.0217 165.014C16.7334 164.804 17.3426 164.343 17.8802 163.836C20.8139 161.082 22.2935 156.714 21.9863 155.035Z"
          fill="url(#paint25_linear_8877_279922)"
        />
        <path
          d="M33.2665 155.543C28.3002 156.674 24.0968 160.653 22.694 165.547C22.4073 166.546 22.3356 167.841 23.2111 168.404C24.1583 169.008 25.3717 168.256 26.1857 167.488C29.6365 164.237 32.1657 160.146 33.2665 155.543Z"
          fill="url(#paint26_linear_8877_279922)"
        />
        <path
          d="M42.95 155.898C38.4035 157.675 34.8862 161.423 32.1829 165.488C31.6914 166.225 31.2101 167.009 31.077 167.889C30.9439 168.77 31.2511 169.768 32.019 170.208C32.6897 170.592 33.5601 170.464 34.2308 170.075C34.9015 169.686 35.4084 169.077 35.8845 168.468C38.5673 165.037 40.7484 161.249 42.95 155.898Z"
          fill="url(#paint27_linear_8877_279922)"
        />
        <path
          d="M49.5275 156.91C45.3804 158.369 42.2009 162.266 41.5917 166.617C41.4944 167.298 41.4637 168.005 41.6634 168.66C41.863 169.316 42.3392 169.92 42.9945 170.125C43.8137 170.381 44.7199 169.956 45.2831 169.31C45.8463 168.665 46.1381 167.831 46.4146 167.022C47.5922 163.602 48.7646 160.182 49.5275 156.91Z"
          fill="url(#paint28_linear_8877_279922)"
        />
        <path
          d="M55.4883 157.605C54.0445 159.443 52.5853 161.302 51.6126 163.427C50.6398 165.552 50.179 167.999 50.819 170.241C51.0084 170.902 51.3924 171.624 52.0682 171.731C52.657 171.828 53.2048 171.409 53.6144 170.973C55.1094 169.402 55.99 167.323 56.3945 165.198C56.799 163.068 56.7631 160.882 55.4883 157.605Z"
          fill="url(#paint29_linear_8877_279922)"
        />
        <path
          d="M65.8609 159.629C64.1355 160.361 62.7532 161.754 61.7599 163.341C60.7667 164.928 60.1267 166.71 59.5686 168.497C59.3433 169.213 59.1283 169.951 59.1795 170.703C59.2307 171.451 59.5891 172.224 60.2598 172.562C61.0585 172.966 62.0722 172.638 62.7071 172.009C63.342 171.379 63.6747 170.509 63.941 169.659C64.8728 166.715 65.2875 163.607 65.8609 159.629Z"
          fill="url(#paint30_linear_8877_279922)"
        />
        <path
          d="M18.4556 154.535C15.2096 153.537 12.4193 151.146 10.9396 148.095C10.7502 147.705 10.5761 147.28 10.6376 146.85C10.7348 146.118 11.4721 145.627 12.1991 145.499C13.5559 145.253 14.9485 145.949 15.8649 146.978C16.7814 148.007 17.2934 149.323 17.7132 150.634C18.133 151.945 18.4812 153.296 18.4556 154.535Z"
          fill="url(#paint31_linear_8877_279922)"
        />
        <path
          d="M28.0142 155.35C25.3928 153.829 23.2322 151.53 21.8755 148.822C21.5683 148.208 21.302 147.563 21.256 146.882C21.2099 146.201 21.43 145.469 21.9574 145.033C22.541 144.552 23.3961 144.516 24.1026 144.783C24.8091 145.049 25.3877 145.571 25.8946 146.129C27.794 148.208 28.9409 150.952 28.0142 155.35Z"
          fill="url(#paint32_linear_8877_279922)"
        />
        <path
          d="M38.239 155.557C34.6602 153.447 32.428 149.244 32.684 145.097C32.7198 144.539 32.8529 143.899 33.3496 143.648C33.7284 143.459 34.2046 143.576 34.5425 143.832C34.8804 144.088 35.1057 144.462 35.3156 144.831C36.8874 147.575 37.9933 150.585 38.239 155.557Z"
          fill="url(#paint33_linear_8877_279922)"
        />
        <path
          d="M47.3229 156.281C44.4148 154.586 42.4846 151.32 42.4078 147.951C42.3822 146.845 42.6587 145.565 43.6417 145.053C44.338 144.695 45.2135 144.843 45.874 145.268C46.5345 145.693 47.0055 146.349 47.4048 147.024C49.0688 149.856 49.6166 153.317 47.3229 156.281Z"
          fill="url(#paint34_linear_8877_279922)"
        />
        <path
          d="M54.654 157.885C53.1897 157.107 52.2477 155.566 51.833 153.958C51.4182 152.35 51.4797 150.666 51.5872 149.012C51.6486 148.039 51.7408 147.031 52.2221 146.181C52.7033 145.331 53.6966 144.696 54.6438 144.927C55.6473 145.172 56.2002 146.242 56.5176 147.225C57.6184 150.676 57.3112 154.547 54.654 157.885Z"
          fill="url(#paint35_linear_8877_279922)"
        />
        <path
          d="M62.8252 159.225C60.5673 155.907 60.4291 151.273 62.477 147.823C62.8815 147.142 63.5112 146.44 64.2946 146.522C65.0165 146.599 65.4875 147.311 65.7793 147.976C67.3921 151.668 66.6036 156.276 62.8252 159.225Z"
          fill="url(#paint36_linear_8877_279922)"
        />
        <path
          d="M135.723 117.957L133.092 108.992C133.092 108.992 126.016 112.049 123.768 116.744C118.679 127.393 115.474 148.087 113.411 150.975C111.348 153.863 85.9531 157.303 85.9531 157.303L88.554 170.395C88.554 170.395 119.611 168.157 123.768 164.486C129.4 159.515 136.194 137.894 136.194 137.894L135.723 117.957Z"
          fill="#68C184"
        />
        <path
          d="M152.552 64.2135C153.438 60.5426 157.395 58.0543 161.158 58.3615C164.921 58.6687 168.239 61.4283 169.749 64.8894C171.255 68.3504 171.106 72.39 169.883 75.9636C169.053 78.3802 167.748 80.9708 168.679 83.3516C169.371 85.1231 171.142 86.2238 172.883 86.9969C174.624 87.7649 176.503 88.3588 177.916 89.6337C179.329 90.9085 180.117 93.1561 179.063 94.7433C177.542 97.0421 173.323 96.6018 172.376 99.1873C171.89 100.503 172.576 101.957 172.494 103.36C172.396 105.121 171.06 106.632 169.473 107.4C167.886 108.168 166.079 108.321 164.312 108.362C161.21 108.439 157.974 108.178 155.286 106.632C152.598 105.08 150.622 101.947 151.267 98.9109C151.794 96.4226 153.955 94.1187 153.202 91.6918C152.659 89.9357 150.816 88.9937 149.495 87.7137C146.7 85.0053 146.26 80.5408 147.391 76.8186C148.512 73.0914 151.164 69.9836 152.552 64.2135Z"
          fill="#6B2E40"
        />
        <path
          d="M156.383 102.494C151.96 109.329 144.121 117.024 141.326 116.65C139.017 116.343 136.656 113.102 136.656 107.383C136.979 107.255 137.301 107.066 137.614 106.83C138.295 106.303 138.904 105.525 139.262 104.608C139.354 104.383 139.431 104.137 139.482 103.892C139.841 102.289 139.4 100.451 138.417 99.3605C138.366 99.3042 138.32 99.2478 138.269 99.1966C142.012 95.7971 145.749 93.3907 149.482 90.9844C149.625 93.2269 150.096 95.3004 150.915 97.077C151.104 97.4968 151.314 97.8962 151.545 98.2751C152.743 100.272 154.468 101.777 156.383 102.494Z"
          fill="#FAC8BF"
        />
        <path
          d="M150.903 97.068C148.154 100.882 143.402 106.913 137.602 106.826C138.283 106.299 138.892 105.521 139.25 104.604C139.342 104.379 139.419 104.133 139.47 103.888C139.829 102.285 139.388 100.447 138.405 99.3566C138.354 99.3002 138.308 99.2439 138.257 99.1927C142 95.7931 145.737 93.3868 149.469 90.9805C149.613 93.223 150.089 95.2965 150.903 97.068Z"
          fill="#EDA19F"
        />
        <path
          d="M154.763 101.426C154.763 101.426 148.44 114.876 140.898 115.239C136.587 115.449 137.309 106.157 137.309 106.157C137.309 106.157 130.116 109.531 128.042 112.106C125.651 115.07 122.697 133.097 122.953 137.813C123.414 146.312 127.883 151.856 127.883 156.475C127.883 161.093 124.53 172.182 124.53 172.182L170.23 172.059C170.23 172.059 167.818 162.224 167.347 151.79C166.876 141.356 172.196 118.045 170.276 111.476C168.914 106.858 154.763 101.426 154.763 101.426Z"
          fill="#AFEBC2"
        />
        <path
          d="M140.727 115.262C140.727 115.262 150.633 111.443 154.371 100.891C154.371 100.891 159.788 102.452 160.136 103.343C160.72 104.833 158.17 115.569 154.96 117.177C152.2 118.559 140.727 115.262 140.727 115.262Z"
          fill="#68C184"
        />
        <path
          d="M140.73 115.253C140.73 115.253 137.469 110.579 138.744 105.055C138.744 105.055 135.856 106.048 134.3 107.456C132.016 109.524 128.054 117.675 129.16 118.479C129.928 119.032 140.73 115.253 140.73 115.253Z"
          fill="#68C184"
        />
        <path
          d="M140.109 121.269C140.864 121.269 141.476 120.657 141.476 119.902C141.476 119.147 140.864 118.535 140.109 118.535C139.354 118.535 138.742 119.147 138.742 119.902C138.742 120.657 139.354 121.269 140.109 121.269Z"
          fill="#68C184"
        />
        <path
          d="M139.14 129.679C139.895 129.679 140.507 129.067 140.507 128.312C140.507 127.557 139.895 126.945 139.14 126.945C138.385 126.945 137.773 127.557 137.773 128.312C137.773 129.067 138.385 129.679 139.14 129.679Z"
          fill="#68C184"
        />
        <path
          d="M122.905 77.4342C126.115 76.8711 129.371 80.1375 130.175 84.73C130.979 89.3226 129.028 93.5004 125.818 94.0636C122.608 94.6267 119.352 91.3603 118.548 86.7678C117.744 82.1752 119.695 77.9974 122.905 77.4342Z"
          fill="#3777BC"
        />
        <path
          d="M157.316 82.6082C158.75 71.196 152.166 60.9461 142.607 59.7122C133.053 58.4834 122.711 66.6445 122.711 78.1488C122.711 93.6466 128.896 103.564 138.163 101.751C147.056 100.016 155.883 94.0204 157.316 82.6082Z"
          fill="#FAC8BF"
        />
        <path
          d="M155.532 81.153C152.429 80.6359 149.619 78.6135 148.139 75.8385C148.462 77.6203 147.714 79.5607 146.27 80.6563C145.722 81.0762 144.985 81.3834 144.35 81.1222C143.859 80.9226 143.546 80.4311 143.316 79.9549C142.502 78.2654 142.272 76.3045 142.671 74.4715C141.304 77.6356 138.309 80.042 134.925 80.7024C134.003 80.8816 132.923 80.8867 132.288 80.1955C131.766 79.6272 131.725 78.7415 131.986 78.0145C132.247 77.2875 132.764 76.6833 133.266 76.0997C130.107 78.3217 126.098 79.2996 122.273 78.7773C123.548 73.5295 126.743 68.6707 131.351 65.8497C135.959 63.0338 141.995 62.4552 146.854 64.8104C151.713 67.1655 155.071 72.5721 154.544 77.953"
          fill="#EDA19F"
        />
        <path
          d="M156.022 87.8918C155.694 86.0026 155.09 83.8266 154.266 81.5534C151.352 79.3877 149.151 76.2902 147.61 72.9776C147.599 73.1005 147.599 73.208 147.599 73.3309C147.528 75.9676 145.664 81.0004 144.84 80.4782C143.498 79.6437 143.703 70.4842 143.135 65.4873C143.524 68.5592 143.109 71.7898 141.635 74.5136C140.16 77.2373 137.534 79.3877 134.498 79.9714C134.098 80.043 133.627 80.0686 133.351 79.7768C133.013 79.403 133.182 78.8194 133.376 78.3637C134.902 74.7696 136.423 71.1908 137.923 67.5966C136.735 70.3665 135.368 73.0749 133.033 74.8668C130.422 76.8892 126.936 77.7801 123.055 77.7442C122.717 80.0686 122.778 82.1575 123.019 83.9034C122.026 82.0654 121.386 80.1045 121.084 78.1077C120.295 72.9674 121.688 67.5608 124.811 63.4598C129.142 57.7716 136.873 54.8943 143.867 56.3585C145.367 56.4456 146.831 56.6965 148.224 57.1214C153.825 58.8007 158.372 63.0246 160.322 68.3237C162.759 74.9334 161.003 82.8948 156.022 87.8918Z"
          fill="#904B57"
        />
        <path
          d="M148.219 57.1124C142.229 56.713 136.146 57.9572 131.124 61.2031C125.4 64.8946 121.36 71.3507 121.084 78.1038C120.295 72.9635 121.688 67.5569 124.811 63.4559C129.142 57.7677 136.873 54.8904 143.867 56.3546C145.362 56.4366 146.826 56.6925 148.219 57.1124Z"
          fill="#BD7D81"
        />
        <path
          d="M153.729 80.4969C153.729 80.4969 154.799 68.199 147.713 62.5774C139.137 55.7782 127.597 60.5243 127.597 60.5243C127.597 60.5243 126.901 58.9013 134.002 56.6844C137.478 55.599 145.921 54.5392 151.727 59.9253C158.649 66.3456 158.229 77.599 158.229 77.599L153.729 80.4969Z"
          fill="#0A4494"
        />
        <path
          d="M150.588 84.4904C151.198 79.8671 154.311 76.4624 157.541 76.8874C160.772 77.3123 162.902 81.4031 162.292 86.0263C161.683 90.6496 158.57 94.0543 155.34 93.6293C152.109 93.2044 149.979 89.1136 150.588 84.4904Z"
          fill="#3777BC"
        />
        <path
          d="M154.782 85.036C155.325 80.9094 157.465 77.7914 159.559 78.0678C161.658 78.3443 162.918 81.9077 162.375 86.0343C161.832 90.161 159.692 93.2789 157.598 93.0025C155.499 92.726 154.24 89.1626 154.782 85.036Z"
          fill="#97BFEA"
        />
        <path
          d="M143.702 82.109C141.772 80.1789 139.073 80.0048 137.297 80.7881"
          stroke="#904B57"
          strokeWidth="1.02397"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M130.013 81.0371C128.016 79.5216 125.405 80.0592 124.289 81.3955"
          stroke="#904B57"
          strokeWidth="1.02397"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M131.653 86.2969C131.592 87.8072 131.751 89.3227 132.13 90.8024"
          stroke="#B35267"
          strokeWidth="1.02397"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M139.62 87.6384C140.109 87.6384 140.506 87.0355 140.506 86.2918C140.506 85.5482 140.109 84.9453 139.62 84.9453C139.131 84.9453 138.734 85.5482 138.734 86.2918C138.734 87.0355 139.131 87.6384 139.62 87.6384Z"
          fill="#363857"
        />
        <path
          d="M127.151 87.6384C127.641 87.6384 128.037 87.0355 128.037 86.2918C128.037 85.5482 127.641 84.9453 127.151 84.9453C126.662 84.9453 126.266 85.5482 126.266 86.2918C126.266 87.0355 126.662 87.6384 127.151 87.6384Z"
          fill="#363857"
        />
        <path
          d="M138.807 96.5742C137.153 97.076 135.996 96.5538 135.223 95.7909C134.184 94.7721 133.82 93.318 133.82 93.318L134.04 93.2566L140.645 91.3008C140.645 91.3008 140.809 91.6592 140.952 92.1968C141.009 92.3964 141.055 92.6217 141.085 92.8675C141.27 94.1628 141.044 95.8984 138.807 96.5742Z"
          fill="#B35267"
        />
        <path
          d="M140.954 92.1968C139.223 92.8675 135.491 93.8402 134.984 93.8402C134.646 93.8402 134.298 93.5382 134.047 93.2566L140.651 91.3008C140.651 91.3008 140.815 91.6592 140.954 92.1968Z"
          fill="#F7FBFF"
        />
        <path
          d="M138.81 96.5713C137.157 97.073 136 96.5508 135.227 95.7879C137.177 93.5506 139.589 92.9771 141.089 92.8594C141.273 94.1598 141.048 95.8955 138.81 96.5713Z"
          fill="#E6919D"
        />
        <path
          d="M133.181 103.038C133.181 103.038 130.027 103.069 127.154 100.576C124.4 98.1794 124.077 95.1997 124.17 93.9453"
          stroke="#363857"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
        />
        <path
          d="M134.257 104.703C135.767 104.703 136.991 103.958 136.991 103.039C136.991 102.12 135.767 101.375 134.257 101.375C132.747 101.375 131.523 102.12 131.523 103.039C131.523 103.958 132.747 104.703 134.257 104.703Z"
          fill="#363857"
        />
        <path
          d="M6.71094 177.171V180.422L183.157 180.796L222.155 172.399V169.266L6.71094 177.171Z"
          fill="#1B2E34"
        />
        <path
          d="M70.4123 169.322L222.155 169.266L182.88 177.232L6.71094 177.171L70.4123 169.322Z"
          fill="white"
        />
        <g opacity="0.95">
          <path
            d="M86.956 170.165C83.6691 170.57 79.9623 170.826 76.0661 170.959C72.2927 171.107 68.3453 171.158 64.3672 171.158C65.3246 167.882 67.4545 164.917 70.3779 163.166C71.279 162.629 72.262 162.193 73.2911 161.912C75.5541 161.277 78.0065 161.308 80.2183 162.112C83.4233 163.289 86.3365 166.806 86.956 170.165Z"
            fill="#12252B"
          />
          <path
            d="M76.0661 170.961C72.2927 171.109 68.3453 171.161 64.3672 171.161C65.3246 167.884 67.4545 164.919 70.3779 163.168C71.279 162.631 72.262 162.196 73.2911 161.914C74.5864 162.856 75.4619 164.351 75.8766 165.928C76.3067 167.566 76.2709 169.292 76.0661 170.961Z"
            fill="#597375"
          />
          <path
            d="M88.8132 167.188C86.8523 168.11 85.2191 168.36 82.7769 168.166C81.927 169.277 77.6519 172.881 76.5512 171.924C75.7013 171.187 78.7937 168.171 79.4183 167.024C79.8688 166.195 80.2323 165.258 80.2579 164.275C79.2084 163.02 77.1195 161.817 75.7729 162.155C73.6738 162.683 71.0985 165.744 68.472 167.971C68.1085 168.279 67.7399 168.591 67.3047 168.724C66.8695 168.857 66.3626 168.77 66.0708 168.376C65.5588 167.685 65.9786 166.625 66.4292 165.877C68.8458 161.894 71.9484 158.484 75.4965 155.914C79.9303 156.462 82.9459 157.573 86.9496 159.355C88.0248 161.715 88.8491 164.843 88.8132 167.188Z"
            fill="#FAC8BF"
          />
        </g>
        <path
          d="M81.0547 169.763V172.2H151.033L166.495 169.169V167.633L81.0547 169.763Z"
          fill="#1B2E34"
        />
        <path
          d="M106.316 167.648L166.495 167.633L150.92 169.783L81.0547 169.763L106.316 167.648Z"
          fill="#324B4F"
        />
        <path
          d="M162.655 154.191C162.655 154.191 157.847 158.958 162.568 166.74C162.568 166.74 166.73 165.936 167.104 161.052C167.34 157.929 162.655 154.191 162.655 154.191Z"
          fill="#68C184"
        />
        <path
          d="M165.505 162.046C165.346 162.333 163.334 162.609 161.88 162.753C161.507 162.778 160.109 162.742 159.853 162.753C160.078 164.391 159.203 165.507 158.286 165.732C157.16 166.019 156.003 165.353 155.245 164.657C154.144 165.492 153.084 165.717 152.076 165.19C151.057 164.662 150.166 163.126 150.048 161.631C149.828 162.64 149.634 163.515 149.772 164.017C149.956 164.703 152.424 168.077 150.642 168.646C149.823 168.907 147.12 165.999 146.813 164.724C146.429 163.126 146.495 161.232 147.345 158.831C145.794 160.689 145.246 161.611 145.082 162.394C144.657 164.406 144.99 167.985 143.572 167.867C141.882 167.867 141.565 162.763 142.256 160.577C142.753 159 145.901 154.028 147.596 153.609C149.163 153.219 157.672 153.568 159.269 153.803C160.068 153.926 160.861 154.008 161.594 154.074C163.601 154.249 165.188 154.238 165.275 154.31C166.268 155.365 165.94 161.196 165.505 162.046Z"
          fill="#FAC8BF"
        />
        <path
          d="M165.503 162.045C165.344 162.331 163.332 162.608 161.878 162.751C162.226 161.901 162.472 161.026 162.579 160.13C162.845 158.067 162.492 155.937 161.586 154.078C163.593 154.252 165.18 154.242 165.267 154.314C166.265 155.363 165.938 161.195 165.503 162.045Z"
          fill="#EDA19F"
        />
        <path
          d="M153.086 158.531C153.24 160.815 153.757 162.34 155.011 164.255"
          stroke="#B35267"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M159.664 162.666C159.326 162.574 158.85 162.256 158.594 162.016"
          stroke="#B35267"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M158.557 161.039C158.649 161.71 158.536 162.401 158.234 163.01"
          stroke="#B35267"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M175.039 115.131C173.702 112.07 169.637 109.228 165.838 107.129C165.715 107.201 165.593 107.267 165.47 107.344C163.309 108.731 161.517 110.641 159.853 112.576C159.782 112.658 159.715 112.751 159.648 112.832C160.698 119.749 162.024 128.54 162.505 131.807C163.514 138.637 171.081 151.83 170.979 153.213C170.876 154.595 162.505 153.878 162.505 153.878C165.849 158.527 165.081 163.468 162.454 166.611C162.454 166.611 184.127 167.85 186.738 164.436C189.349 161.026 176.989 119.606 175.039 115.131Z"
          fill="#AFEBC2"
        />
        <path
          d="M165.962 112.25C162.547 122.797 163.034 134.557 167.309 144.787"
          stroke="#68C184"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M158.523 130.219C159.86 135.093 162.496 139.296 166.388 142.517"
          stroke="#68C184"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M162.984 153.812C164.218 154.094 165.488 154.217 166.753 154.176L162.984 153.812Z"
          fill="#68C184"
        />
        <path
          d="M162.984 153.812C164.218 154.094 165.488 154.217 166.753 154.176"
          stroke="#68C184"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M168.887 166.85C166.557 166.85 165.057 166.778 162.789 166.66L168.887 166.85Z"
          fill="#68C184"
        />
        <path
          d="M168.887 166.85C166.557 166.85 165.057 166.778 162.789 166.66"
          stroke="#68C184"
          strokeWidth="0.511986"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M23.4517 161.567L20.6562 97.8047H108.073L110.868 161.567H23.4517Z"
          fill="#1B2E34"
        />
        <path
          d="M107.874 161.567L105.078 97.8047H108.073L110.869 161.567H107.874Z"
          fill="#324B4F"
        />
        <path
          d="M48.6328 174.206L56.7888 139.965H72.6347L64.4788 174.206H48.6328Z"
          fill="#12252B"
        />
        <path
          d="M63.4609 174.206L71.6169 139.965H72.6357L64.4798 174.206H63.4609Z"
          fill="#324B4F"
        />
        <path
          d="M73.4286 170.629H41.1172V174.208H73.4286V170.629Z"
          fill="#12252B"
        />
        <path
          d="M73.4275 170.629H71.3438V174.208H73.4275V170.629Z"
          fill="#324B4F"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_8877_279922"
          x1="198.372"
          y1="101.973"
          x2="198.372"
          y2="172.341"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8877_279922"
          x1="198.372"
          y1="143.363"
          x2="198.372"
          y2="174.978"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8877_279922"
          x1="184.45"
          y1="75.0234"
          x2="184.45"
          y2="174.984"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8877_279922"
          x1="116.386"
          y1="84.4102"
          x2="116.386"
          y2="154.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8877_279922"
          x1="100.837"
          y1="72.0273"
          x2="100.837"
          y2="85.3248"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_8877_279922"
          x1="110.034"
          y1="72.9023"
          x2="110.034"
          y2="88.8558"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_8877_279922"
          x1="115.688"
          y1="78.6953"
          x2="115.688"
          y2="95.647"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_8877_279922"
          x1="119.28"
          y1="89.0078"
          x2="119.28"
          y2="101.959"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_8877_279922"
          x1="122.108"
          y1="96.5586"
          x2="122.108"
          y2="109.81"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_8877_279922"
          x1="125.723"
          y1="104.379"
          x2="125.723"
          y2="116.283"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_8877_279922"
          x1="101.414"
          y1="84.2422"
          x2="101.414"
          y2="91.0908"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_8877_279922"
          x1="104.677"
          y1="92.4688"
          x2="104.677"
          y2="98.3608"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_8877_279922"
          x1="107.185"
          y1="101.18"
          x2="107.185"
          y2="106.047"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_8877_279922"
          x1="110.135"
          y1="108.125"
          x2="110.135"
          y2="114.308"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_8877_279922"
          x1="112.073"
          y1="115.602"
          x2="112.073"
          y2="121.02"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_8877_279922"
          x1="113.723"
          y1="123.547"
          x2="113.723"
          y2="128.109"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_8877_279922"
          x1="115.512"
          y1="130.086"
          x2="115.512"
          y2="135.657"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_8877_279922"
          x1="116.939"
          y1="137.496"
          x2="116.939"
          y2="142.628"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_8877_279922"
          x1="118.272"
          y1="144.609"
          x2="118.272"
          y2="150.498"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_8877_279922"
          x1="127.727"
          y1="113.906"
          x2="127.727"
          y2="123.422"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_8877_279922"
          x1="129.069"
          y1="120.723"
          x2="129.069"
          y2="131.144"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_8877_279922"
          x1="130.977"
          y1="129.867"
          x2="130.977"
          y2="136.817"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_8877_279922"
          x1="133.003"
          y1="135.93"
          x2="133.003"
          y2="143.671"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_8877_279922"
          x1="53.3592"
          y1="155.039"
          x2="53.3592"
          y2="170.585"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_8877_279922"
          x1="7.31506"
          y1="153.172"
          x2="7.31506"
          y2="159.625"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_8877_279922"
          x1="17.642"
          y1="155.035"
          x2="17.642"
          y2="165.118"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_8877_279922"
          x1="27.8833"
          y1="155.543"
          x2="27.8833"
          y2="168.624"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_8877_279922"
          x1="36.9984"
          y1="155.898"
          x2="36.9984"
          y2="170.44"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_8877_279922"
          x1="45.5215"
          y1="156.91"
          x2="45.5215"
          y2="170.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_8877_279922"
          x1="53.5733"
          y1="157.605"
          x2="53.5733"
          y2="171.745"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_8877_279922"
          x1="62.5164"
          y1="159.629"
          x2="62.5164"
          y2="172.74"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_8877_279922"
          x1="14.541"
          y1="145.449"
          x2="14.541"
          y2="154.535"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_8877_279922"
          x1="24.7957"
          y1="144.617"
          x2="24.7957"
          y2="155.35"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_8877_279922"
          x1="35.4515"
          y1="143.555"
          x2="35.4515"
          y2="155.557"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_8877_279922"
          x1="45.6297"
          y1="144.852"
          x2="45.6297"
          y2="156.281"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_8877_279922"
          x1="54.3117"
          y1="144.879"
          x2="54.3117"
          y2="157.885"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_8877_279922"
          x1="63.801"
          y1="146.516"
          x2="63.801"
          y2="159.225"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDE4FF" />
          <stop offset="1" stopColor="#DDE4FF" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_8877_279922">
          <rect width="233" height="180.808" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

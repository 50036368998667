export function SettingSvg() {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.60386 2.14366C7.95919 0.679979 10.0408 0.67998 10.3961 2.14366C10.6257 3.08917 11.709 3.53788 12.5398 3.03161C13.8261 2.24789 15.298 3.71982 14.5143 5.00605C14.008 5.83694 14.4567 6.92021 15.4022 7.14975C16.8659 7.50509 16.8659 9.58671 15.4022 9.94204C14.4567 10.1716 14.008 11.2549 14.5143 12.0857C15.298 13.372 13.8261 14.8439 12.5398 14.0602C11.709 13.5539 10.6257 14.0026 10.3961 14.9481C10.0408 16.4118 7.95919 16.4118 7.60386 14.9481C7.37431 14.0026 6.29105 13.5539 5.46016 14.0602C4.17392 14.8439 2.70199 13.372 3.48571 12.0857C3.99198 11.2549 3.54327 10.1716 2.59776 9.94204C1.13408 9.58671 1.13408 7.50509 2.59776 7.14975C3.54327 6.92021 3.99198 5.83694 3.48571 5.00605C2.70199 3.71982 4.17392 2.24789 5.46015 3.03161C6.29105 3.53788 7.37431 3.08917 7.60386 2.14366Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 8.5459C11.5 9.92661 10.3807 11.0459 9 11.0459C7.61929 11.0459 6.5 9.92661 6.5 8.5459C6.5 7.16519 7.61929 6.0459 9 6.0459C10.3807 6.0459 11.5 7.16519 11.5 8.5459Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

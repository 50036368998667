export default function DeadPig() {
  return (
    <svg
      width="79"
      height="29"
      viewBox="0 0 79 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76.4788 20.9986C76.8063 20.5396 77 19.9801 77 19.3732C77 17.8246 75.7457 16.5689 74.1987 16.5689C73.8182 16.5689 73.4559 16.6456 73.1256 16.7837C72.6977 16.3219 72.0998 16.022 71.4309 15.9843C71.4741 15.7555 71.4978 15.5211 71.4978 15.2797C71.4978 13.2023 69.8156 11.5169 67.739 11.5169C66.2826 11.5169 65.02 12.3471 64.3956 13.5609C63.8744 13.1521 63.2193 12.9079 62.5058 12.9079C61.2334 12.9079 60.1435 13.6836 59.678 14.7872C59.2752 14.8389 58.9017 14.984 58.5812 15.203C58.4223 13.9097 57.3241 12.9079 55.989 12.9079C55.8301 12.9079 55.6754 12.9247 55.5235 12.9512C55.1514 11.8713 54.1298 11.0942 52.9257 11.0942C51.4651 11.0942 50.2749 12.234 50.1843 13.6739C49.7119 13.2009 49.0582 12.9079 48.3377 12.9079C47.3524 12.9079 46.4953 13.4548 46.0507 14.2599C45.7719 14.1538 45.4723 14.0938 45.1559 14.0938C44.6305 14.0938 44.1427 14.2557 43.7386 14.5305C43.3665 12.8828 41.8961 11.6522 40.1373 11.6522C38.3785 11.6522 37.0113 12.7977 36.5793 14.3589C36.3911 14.2654 36.1904 14.1915 35.98 14.1468C35.1048 13.4116 33.9773 12.9679 32.7453 12.9679C30.4638 12.9679 28.5378 14.4873 27.919 16.5703C27.8158 16.7279 27.728 16.8954 27.657 17.0725C27.6598 17.0265 27.6639 16.9805 27.6639 16.9344C27.6639 15.736 26.6925 14.7635 25.4954 14.7635C24.9045 14.7635 24.3693 15.0007 23.9791 15.3844C24.0195 15.1835 24.0418 14.977 24.0418 14.7635C24.0418 13.0446 22.6495 11.6509 20.9325 11.6509C20.0628 11.6509 19.2782 12.008 18.7138 12.5842C18.8239 12.294 18.8852 11.9801 18.8852 11.6509C18.8852 10.1873 17.6992 9 16.2372 9C14.7752 9 13.5892 10.1873 13.5892 11.6509C13.5892 11.6522 13.5892 11.6536 13.5892 11.655C13.0986 11.3021 12.498 11.0928 11.8471 11.0928C10.1928 11.0928 8.85072 12.4363 8.85072 14.0924C8.85072 14.1692 8.8563 14.2431 8.86187 14.3185C7.28981 14.4789 6.06337 15.8085 6.06337 17.4241C6.06337 17.7436 6.11076 18.052 6.19995 18.3422C5.49197 18.677 5 19.3969 5 20.2326C5 20.5047 5.05296 20.7628 5.14773 21H76.4788V20.9986Z"
        fill="#D9EBFE"
      />
      <g clipPath="url(#clip0_1602_63592)">
        <g clipPath="url(#clip1_1602_63592)">
          <path
            d="M24.5018 13.616C24.5018 13.616 25.8498 14.8247 26.1692 14.7548C26.4887 14.6849 27.2345 14.5125 27.2345 14.5125L26.8081 15.0656C26.8081 15.0656 28.6177 15.9636 29.683 16.2044C30.7483 16.4452 31.3505 17.1024 30.9256 17.6198C30.5008 18.1372 30.3938 21.3487 34.794 21.9018C34.794 21.9018 34.7237 21.1078 34.0481 20.4522C33.3725 19.7965 33.7989 19.6241 34.0481 19.7965C34.2973 19.969 37.3127 22.8355 43.5609 21.7992C43.5609 21.7992 44.5544 26.0454 48.3525 25.2857C48.3525 25.2857 49.1686 25.0433 49.488 24.63H51.0149L50.0918 23.0422C50.0918 23.0422 48.3525 23.9402 47.7136 22.3523C47.7136 22.3523 51.015 21.2477 50.6588 16.8974L51.4047 16.829C51.4047 16.829 52.8246 17.7612 53.4986 16.5524C53.4986 16.5524 53.037 16.9315 52.1138 16.6565C52.1138 16.6565 53.3916 15.931 52.3262 15.1029C51.2609 14.2747 50.8361 15.8968 51.1555 16.2774H50.4096C50.4096 16.2774 49.381 12.0996 41.8919 9.92443C41.8919 9.92443 46.5061 10.7417 46.4582 8.73897L45.3929 8.76227C45.3929 8.76227 47.6177 7.72597 47.1194 6.16141L45.1086 6.89785C45.1086 6.89785 48.0666 4.71182 46.316 3.62891C46.316 3.62891 45.2747 4.84855 41.8679 5.69996C38.4611 6.55138 38.3653 8.07088 39.076 9.01397C39.076 9.01397 36.3544 8.23091 32.9237 8.62244C32.9237 8.62244 31.0071 7.10294 30.3203 7.42611C30.3203 7.42611 29.8475 7.86269 30.5806 9.54377L30.6988 10.3035L30.2021 9.68205C30.2021 9.68205 28.8541 10.1419 28.2855 10.6034C27.7169 11.0648 27.6227 11.8929 25.2333 11.5931C25.2333 11.5931 24.4522 12.3761 24.5002 13.6191L24.5018 13.616Z"
            fill="#5572EB"
          />
          <path
            d="M51.9027 16.3576C51.9027 16.3576 52.8226 15.9102 52.0512 15.4627C52.0512 15.4627 51.7174 15.34 51.628 15.542C51.5385 15.7439 51.4427 16.2862 51.9027 16.3576Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1602_63592">
          <rect x="24.5" width="29" height="29" rx="4.51766" fill="white" />
        </clipPath>
        <clipPath id="clip1_1602_63592">
          <rect
            width="29"
            height="21.75"
            fill="white"
            transform="translate(24.5 3.625)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

import { useCallback, useEffect, useState } from "react";
import { IContracts } from "../../types/Financial.type";
import { getAllContract } from "../../services/Financial.service";
import { useQuery } from "@tanstack/react-query";

export const useFinancialController = () => {
  const [data, setData] = useState<IContracts[]>([]);

  const initData = useCallback(() => {
    Promise.all([getAllContract()])
      .then(([result]) => {
        setData(result!.contracts);
      })
      .catch((e) => {
        console.log("fail to initData error:", e);
      });
  }, []);

  const { data: listNewContract, refetch: refetchListNewContract } = useQuery({
    queryKey: ["list_new_contract"],
    queryFn: async () => {
      const result = await getAllContract();
      return result?.contracts;
    },
  });

  useEffect(() => {
    initData();
  }, []);

  return {
    data,
    listNewContract,
    refetchListNewContract,
  };
};

import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AppBarFS from "../../../components/app-bar/AppBar";
import { IconButton, Link, Tab } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import FenceSVG from "../../../assets/svg/Fence";
import { Swiper, SwiperSlide } from "swiper/react";
import { Controller, FreeMode, Pagination } from "swiper/modules";
import "./styles.css";
import Tabs from "../../../admin/pages/group-area/views/Tabs";
import { IContracts } from "../../../types/Financial.type";
import { useFinancialController } from "../controller";
import dayjs from "dayjs";
import InfoContract from "../financial-contract/pages/InfoContract";
import FilterModal from "../financial-contract/views/FilterModal";
import { tw } from "../../../utils/tw";
import Stat from "../../../assets/svg/Stat";
import { getCommaNumber } from "../../../utils/number";
import { get } from "lodash";
import Clock from "../../../assets/svg/Clock";
import Assignment2 from "../../../assets/svg/Assignment2";
import ButtonOutlinedCustom from "../../../admin/components/Button/ButtonOutlinedCustom";
import { EditSvg } from "../../../admin/assets/svg/EditSvg";
import EditCountPigModal from "./views/EditCountPigModal";
import { useFinancialNewContractController } from "./controller";
import { useMutation } from "@tanstack/react-query";
import {
  ApproveOrDeniedContract,
  UpdateContract,
} from "../../../services/Financial.service";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { loadingActions } from "../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../utils/getErrorMessage";
import ButtonFillCustom from "../../../admin/components/Button/ButtonFillCustom";
import { alertAction } from "../../../redux/slices/alertSlice";
import ApproveContractModal from "./views/ApproveContractModal";
import Receipt from "../../../assets/svg/Receipt";
import ViewDocumentModal from "./views/ViewDocumentModal";

type TStatusMap =
  | "pending_farmer_approve_offer"
  | "pending_sale_forward_consideration"
  | "pending_lending_confirm_consideration"
  | "pending_sale_approve_consideration"
  | "pending_confirm_reject";

const StatusMap = {
  "": "",
  pending_farmer_approve_offer: "รอแนบเอกสาร",
  pending_sale_forward_consideration: "รอพิจารณา",
  pending_lending_confirm_consideration: "รอพิจารณา",
  pending_sale_approve_consideration: "รอพิจารณา",
  pending_confirm_reject: "รอพิจารณา",
};

const FinancialNewContract = () => {
  let navigate = useNavigate();
  const { data, listNewContract, refetchListNewContract } =
    useFinancialController();
  const {
    formNewContract,
    setFormNewContract,
    onChangeFormNewContract,
    idContract,
    setIdContract,
    isOpenEditContract,
    onOpenEditContract,
    onHideEditContract,
  } = useFinancialNewContractController();

  const dispatch = useDispatch<AppDispatch>();

  const [startDate, setStartDate] = useState("");

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const [idViewDocument, setIdViewDocument] = useState("");

  const handleBack = useCallback(() => {
    navigate("/financial-support");
  }, []);

  const onSlideChange = ({ realIndex }: { realIndex: number }) => {
    setActiveIndex(realIndex);
  };

  const dataActive = useMemo(() => {
    const result = listNewContract?.filter(({ contractStatus }) => {
      return (
        contractStatus === "pending_farmer_approve_offer" ||
        contractStatus === "pending_sale_forward_consideration" ||
        contractStatus === "pending_lending_confirm_consideration" ||
        contractStatus === "pending_sale_approve_consideration" ||
        contractStatus === "pending_confirm_reject"
      );
    });
    return result;
  }, [listNewContract]);

  const [isOpenApprove, setIsOpenApprove] = useState(false);

  const onOpenApprove = useCallback(() => setIsOpenApprove(true), []);
  const onHideApprove = useCallback(() => setIsOpenApprove(false), []);

  const [isOpenViewDocument, setIsOpenViewDocument] = useState(false);

  const onOpenViewDocument = useCallback((id: string) => {
    setIsOpenViewDocument(true);
    setIdViewDocument(id);
  }, []);
  const onHideViewDocument = useCallback(
    () => setIsOpenViewDocument(false),
    []
  );

  const findIdData = dataActive?.find((i) => i.id === idViewDocument);

  const onOpenEditFn = useCallback((id: string) => {
    onOpenEditContract();
    setIdContract(id);
  }, []);

  const UpdateContractMutation = useMutation({
    mutationFn: UpdateContract,
    onSuccess: async (data) => {
      onHideEditContract();
      refetchListNewContract();
      setTimeout(() => {
        dispatch(loadingActions.hide());
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const ApproveOrDeniedContractMutation = useMutation({
    mutationFn: ApproveOrDeniedContract,
    onSuccess: async (data) => {
      refetchListNewContract();
      setTimeout(() => {
        dispatch(alertAction.hide());
        dispatch(loadingActions.hide());
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(alertAction.hide());
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const onDeniedContract = useCallback((ids: string) => {
    dispatch(
      alertAction.showDialog({
        title: `ยืนยันไม่ยอมรับการทำ\nสัญญาเครดิต`,
        text: `คุณเเน่ใจหรือไม่ว่ายอมรับการทำ\nสัญญาเครดิต?\nการดำเนินการนี้ไม่สามารถยกเลิกได้`,
        cancelText: "",
        confirmText: "ยืนยัน",
        isFullButtonStyle: true,
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(
              loadingActions.show({ message: "กำลังยืนยันไม่ยอมรับเครดิต..." })
            );
            ApproveOrDeniedContractMutation.mutate({
              id: ids,
              isApprove: false,
              citizenDocument: [],
              creditBureauDocument: [],
            });
            setTimeout(() => {
              dispatch(alertAction.hide());
              dispatch(loadingActions.hide());
              dispatch(
                snakeActions.showMessage({
                  message: "ไม่ยอมรับเครดิตสำเร็จ",
                  type: "success",
                })
              );
            }, 500);
          } catch (error) {
            setTimeout(() => {
              dispatch(alertAction.hide());
              dispatch(loadingActions.hide());
              dispatch(
                snakeActions.showMessage({
                  message: getErrorMessage(error),
                  type: "error",
                })
              );
            }, 500);
          }
        },
      })
    );
  }, []);

  const onUpdateContract = useCallback(() => {
    dispatch(loadingActions.show({ message: "กำลังแก้ไขสัญญา..." }));
    UpdateContractMutation.mutate({
      id: idContract,
      fattenerPigCount: +formNewContract.pigCount,
    });

    setTimeout(() => {
      dispatch(loadingActions.hide());
      dispatch(
        snakeActions.showMessage({
          message: "แก้ไขสัญญาสำเร็จ",
          type: "success",
        })
      );
    }, 500);
  }, [idContract, formNewContract]);

  return (
    <div className="bg-[#d9ebfe] w-full min-h-full">
      <div className="w-full h-full">
        <div className="h-[120px] flex items-center bg-[#FFFF] rounded-b-[40px]">
          <AppBarFS title={"สัญญาใหม่"} onIconPress={handleBack} />
        </div>
        <div className="flex flex-row py-[16px] px-4 justify-between">
          <span className="font-semibold text-[20px] text-[#3777BC] flex flex-col justify-center">
            สัญญาทั้งหมด
          </span>
        </div>
        {(dataActive?.length || 0) > 0 ? (
          <>
            <Swiper
              pagination={{
                clickable: true,
              }}
              freeMode={true}
              slidesPerView={1}
              modules={[FreeMode, Pagination, Controller]}
              loop={true}
              tabIndex={activeIndex}
              onSlideChange={onSlideChange}
            >
              {(dataActive || []).map((data, index) => {
                let statusContractType: any = {
                  pay_after_expire: "จ่ายหลังหมดสัญญา",
                  fill_credit: "จ่ายตาม invoice",
                };
                return (
                  <SwiperSlide key={`dataActive-${index}`}>
                    <div className="px-[16px]">
                      <div className="bg-[#FFFF] rounded-[15px] px-4 pt-4 pb-6 flex flex-col gap-3">
                        <div className="text-[#34407B] text-[16px] font-semibold flex flex-row gap-[11px]">
                          <FenceSVG />
                          {data.contractNumber}
                        </div>
                        <div className="flex flex-col gap-[6px] text-[#4B4B4B] text-[14px] font-medium leading-5">
                          <div className="flex flex-row justify-between">
                            <div className="">เลขที่สัญญา</div>
                            <div className="text-end">
                              {data.contractNumber}
                            </div>
                          </div>
                          <div className="flex flex-row justify-between">
                            <div className="">ประเภทสัญญา</div>
                            <div className="text-end">
                              {statusContractType[
                                data.creditInformation.contractType
                              ] || "-"}
                            </div>
                          </div>
                          <div className="flex flex-row justify-between">
                            <div className="">วันเริ่มสัญญา</div>
                            <div className="text-end">
                              {data.startDate && dayjs(data.startDate).isValid()
                                ? dayjs(data.startDate).format("DD/MM/YYYY")
                                : "-"}
                            </div>
                          </div>
                          <div className="flex flex-row justify-between">
                            <div className="">วันสิ้นสุดสัญญา</div>
                            <div className="text-end">
                              {data.expireDate &&
                              dayjs(data.expireDate).isValid()
                                ? dayjs(data.expireDate).format("DD/MM/YYYY")
                                : "-"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="px-4 pb-6">
              <div className="w-full h-auto bg-[#FFFFFF] rounded-[20px] flex flex-col justify-center">
                <div className="flex flex-col gap-3 w-full h-auto py-3">
                  <div className="grid grid-cols-2 px-3 gap-3">
                    <div
                      className={tw(
                        "bg-[#F6FBFF] text-start rounded-[20px] w-full"
                      )}
                    >
                      <div className="p-3 flex flex-col gap-[6px]">
                        <Stat />
                        <div className="text-[#3777BC] text-[14px] font-normal">
                          วงเงินลูกค้า
                        </div>
                        <div className="text-[#34407B] text-[18px] font-medium">
                          {getCommaNumber(
                            +get(dataActive, [activeIndex, "credit"], "")
                          )}
                          บาท
                        </div>
                      </div>
                    </div>
                    <div
                      className={tw(
                        "bg-[#F6FBFF] text-start rounded-[20px] w-full"
                      )}
                    >
                      <div className="p-3 flex flex-col gap-[6px]">
                        <Clock />
                        <div className="text-[#3777BC] text-[14px] font-normal">
                          ระยะเครดิต
                        </div>
                        <div className="text-[#34407B] text-[18px] font-medium">
                          {getCommaNumber(
                            +get(
                              dataActive,
                              [
                                activeIndex,
                                "creditInformation",
                                "creditPeriod",
                              ],
                              ""
                            )
                          )}{" "}
                          วัน
                        </div>
                      </div>
                    </div>
                    <div
                      className={tw(
                        "bg-[#F6FBFF] text-start rounded-[20px] w-full col-span-2"
                      )}
                    >
                      <div className="p-3 flex flex-col gap-[6px]">
                        <Assignment2 />
                        <div className="text-[#3777BC] text-[14px] font-normal">
                          สถานะ
                        </div>
                        <div className="text-[#34407B] text-[18px] font-medium">
                          {
                            StatusMap[
                              get(
                                dataActive,
                                [activeIndex, "contractStatus"],
                                ""
                              ) as TStatusMap
                            ]
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col px-3 gap-3">
                    <div className="bg-[#F6FBFF] rounded-[10px] gap-1">
                      <div className="flex flex-row justify-between p-3 border-[#D9EBFE] border-b-[1px]">
                        <div className="text-[#4B4B4B] text-[16px] font-normal">
                          ชื่อฟาร์ม
                        </div>
                        <div className="text-[#3777BC] text-right text-[16px] font-normal">
                          {get(dataActive, [
                            activeIndex,
                            "house",
                            "farm",
                            "name",
                          ])}
                        </div>
                      </div>
                      <div className="flex flex-row justify-between p-3 border-[#D9EBFE] border-b-[1px]">
                        <div className="text-[#4B4B4B] text-[16px] font-normal">
                          ชื่อโรงเรือน
                        </div>
                        <div className="text-[#3777BC] text-right text-[16px] font-normal">
                          {get(dataActive, [activeIndex, "house", "name"])}
                        </div>
                      </div>
                      <div className="flex flex-row justify-between p-3 border-[#D9EBFE] border-b-[1px]">
                        <div className="text-[#4B4B4B] text-[16px] font-normal">
                          ประเภทหมู
                        </div>
                        <div className="text-[#3777BC] text-right text-[16px] font-normal">
                          หมูขุน
                        </div>
                      </div>
                      <div className="flex flex-row justify-between p-3 ">
                        <div className="text-[#4B4B4B] text-[16px] font-normal">
                          จำนวนหมู
                        </div>
                        <div className="text-[#3777BC] text-right text-[16px] font-normal">
                          {getCommaNumber(
                            +get(dataActive, [activeIndex, "pigAmount"], "")
                          )}
                          ตัว
                        </div>
                      </div>
                    </div>
                  </div>
                  {get(dataActive, [activeIndex, "contractStatus"], "") ===
                    "pending_farmer_approve_offer" && (
                    <div className="w-full flex items-center justify-center ">
                      <ButtonOutlinedCustom
                        onClick={() =>
                          onOpenEditFn(get(dataActive, [activeIndex, "id"], ""))
                        }
                        title="แก้ไขสัญญา"
                        btnBorderColor="#68C184"
                        btnTextColor="#68C184"
                        btnBgColor="#FFFFFF"
                        startIcon={<EditSvg />}
                        className="!w-[199px] !h-[43px] !rounded-[50px] !px-[13.5px] !py-[4.5px]"
                      />
                    </div>
                  )}
                  {!(
                    get(dataActive, [activeIndex, "contractStatus"], "") ===
                    "pending_farmer_approve_offer"
                  ) && (
                    <>
                      {get(
                        dataActive,
                        [activeIndex, "creditInformation", "citizenDocument"],
                        []
                      ).length +
                        get(
                          dataActive,
                          [
                            activeIndex,
                            "creditInformation",
                            "creditBureauDocument",
                          ],
                          []
                        ).length >
                        0 && (
                        <div className="flex flex-col px-3 gap-2">
                          <div className="flex flex-row px-3 gap-2">
                            <Receipt />
                            <div className="text-[#3777BC] text-[16px] font-normal">
                              เอกสารสัญญา
                            </div>
                          </div>
                          <div className="flex flex-row justify-between">
                            <div className="text-[#979797] text-[16px] font-normal pl-[48px]">
                              เอกสารสัญญา
                            </div>
                            <Link
                              onClick={() =>
                                onOpenViewDocument(
                                  get(dataActive, [activeIndex, "id"], "")
                                )
                              }
                              underline="hover"
                              className="!text-[#979797] !text-[16px] pr-4"
                            >
                              ดูเอกสาร
                            </Link>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>

            {get(dataActive, [activeIndex, "contractStatus"], "") ===
              "pending_farmer_approve_offer" && (
              <div className="pb-6 px-4 flex flex-row w-full gap-2">
                <ButtonOutlinedCustom
                  title="ไม่ยอมรับ"
                  btnBorderColor="#68C184"
                  btnTextColor="#68C184"
                  btnBgColor="#FFFFFF"
                  className="!rounded-[50px] !px-[13.5px] !py-[4.5px] !h-[48px] !w-full"
                  onClick={() =>
                    onDeniedContract(get(dataActive, [activeIndex, "id"], ""))
                  }
                />
                <ButtonFillCustom
                  title="ยอมรับ"
                  className="!rounded-[50px] !px-[13.5px] !py-[4.5px] !h-[48px] !w-full"
                  onClick={onOpenApprove}
                />
              </div>
            )}
          </>
        ) : (
          <>
            <div className="w-full flex flex-row justify-center">
              ไม่มีข้อมูลสัญญาที่ใช้งาน
            </div>
          </>
        )}
      </div>
      <EditCountPigModal
        isOpen={isOpenEditContract}
        onClose={onHideEditContract}
        onSubmit={onUpdateContract}
        form={formNewContract}
        setForm={setFormNewContract}
        onChangeForm={onChangeFormNewContract}
        dataEdit={dataActive?.find((i) => i.id === idContract)}
      />
      <ApproveContractModal
        isOpen={isOpenApprove}
        onClose={onHideApprove}
        refetchListNewContract={refetchListNewContract}
        id={get(dataActive, [activeIndex, "id"], "")}
      />
      <ViewDocumentModal
        isOpen={isOpenViewDocument}
        onClose={onHideViewDocument}
        dataView={findIdData}
      />
    </div>
  );
};

export default FinancialNewContract;

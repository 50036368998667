import React, { useCallback } from 'react'
import { tw } from '../../../utils/tw'

const classes = {
  container: tw(`flex flex-row items-center justify-center w-full h-screen relative`),
  bg: tw(`absolute h-full w-full top-0 left-0 -z-10 object-cover object-left`),
  logo: tw(`h-[40px] md:h-[58px] w-auto object-contain absolute top-[32px] right-[32px]`),
  contentContainer: tw(`py-[24px] px-[24px] md:px-[65px] rounded-[20px] flex flex-col justify-center items-center text-center text-[white] bg-[rgba(0,0,0,0.1)]`),
  welcome: tw(`font-bold text-[32px] leading-[32px] tracking-[-5%] mb-[12px]`),
  subTitle: tw(`text-[16px] leading-[16px] tracking-[-5%] mb-[62px] font-medium`),
  button: tw(`flex justify-center tracking-[-5%] items-center text-[white] h-[32px] w-[140px] rounded-[4px] bg-secondary hover:brightness-[1.05]`),
  helper: tw(`mt-[12px] text-[white] text-[14px] leading-[14px] tracking-[-5%] font-medium`),
}

const EMAIL_SUPPORT = 'farmsook@betagro.com'

const AdminLogin = () => {
  const onLogin = useCallback(() => {
    if (typeof window !== 'undefined') {
      const BASE_URL = `${process.env.REACT_APP_BASE_API_URL || window.location.origin}/api`
      const url = `${BASE_URL}/authentication/aad?continue=${window.location.origin}/admin/login/azure`
      window.open(url, '_self')
    }
  }, [])

  return (
    <div className={classes.container}>
      <img
        src={require('../../assets/admin-bg-pig.png')}
        className={classes.bg}
      />
      <img
        src={require('../../assets/fs-admin-logo.png')}
        className={classes.logo}
      />
      <div className={classes.contentContainer}>
        <div className={classes.welcome}>Welcome!</div>
        <div className={classes.subTitle}>Please log in to website</div>
        <button
          className={classes.button}
          onClick={onLogin}
        >
          Login
        </button>
        <div className={classes.helper}>
          Help Support contact <a className="hover:underline" href={`mailto: ${EMAIL_SUPPORT}`}>{EMAIL_SUPPORT}</a>
        </div>
      </div>
    </div>
  )
}

export default AdminLogin

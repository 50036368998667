import { Dialog, DialogContent, IconButton } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { get } from "lodash";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import * as Y from "yup";
import useFormValidator from "../../../../hooks/useFormValidator.hook";
import { alertAction } from "../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../../redux/store";
import {
  approvedClaimOrder,
  createdClaim,
  getListClaimOrder,
} from "../../../../services/ClaimOrder.service";
import {
  EClaimOrderOption,
  IDataClaimOrder,
} from "../../../../types/ClaimOrder.type";
import getErrorMessage from "../../../../utils/getErrorMessage";
import { getCommaNumber } from "../../../../utils/number";
import { tw } from "../../../../utils/tw";
import CrossIcon from "../../../assets/svg/CrossIcon.svg";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import TextAreaCustom from "../../../components/Input/TextAreaCustom";
import { Dropdown } from "../../group-area/views/Dropdown";
import { Input } from "../../group-area/views/Input";

interface ICreateModal {
  isOpen: boolean;
  onCloseFn: () => void;
  refetchListClaimOrderProgress?: () => void;
  onEdit?: boolean;
  dataEdit?: IDataClaimOrder;
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  container: tw(
    `w-[100vw] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-[#FFFFFF]  [&_.MuiButtonBase-root.Mui-selected]:!text-[#000000] [&_.MuiDialog-paperScrollPaper]:!m-0 max-w-[530px]`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold`),
  row: tw(
    `w-full flex flex-col md:flex-row item-center justify-between gap-x-3`
  ),
  space: tw(`h-[12px]`),
};

const defaultForm = {
  refundAmount: "",
  remark: "",
  listOrder: [],
};

const errorSchemaEdit = Y.object({
  refundAmount: Y.string().required("กรุณากรอกเครดิตที่เพิ่ม"),
  remark: Y.string()
    .required("กรุณากรอกเหตุผล")
    .max(500, "เหตุผลต้องไม่เกิน 500 ตัวอักษร"),
});

const errorSchemaCreate = errorSchemaEdit.shape({
  listOrder: Y.array().min(1, "กรุณาเลือกหมายเลขสั่งซื้อ"),
});

const CreateReportModal = (props: ICreateModal) => {
  const { isOpen, onCloseFn, refetchListClaimOrderProgress, onEdit, dataEdit } =
    props;
  const [form, setForm] = useState(defaultForm);
  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();

  let initialIndex: number | null = null;

  const statusValues = ["deliver_product", "refund_credit", "add_debt"];

  if (
    get(dataEdit, ["orderClaimItems", 0, "claimOption"], "-") ===
    "deliver_product"
  ) {
    initialIndex = 0;
  } else if (
    get(dataEdit, ["orderClaimItems", 0, "claimOption"], "-") ===
    "refund_credit"
  ) {
    initialIndex = 1;
  } else if (
    get(dataEdit, ["orderClaimItems", 0, "claimOption"], "-") === "add_debt"
  ) {
    initialIndex = 2;
  }

  const getStatusValue = (index: number | null) => {
    if (index === null) {
      return "";
    }
    return statusValues[index];
  };

  const [activeIndex, setActiveIndex] = useState<number | null>(initialIndex);

  const onChangeForm = useCallback(
    (key: keyof typeof form) => (value: any) => {
      setForm((prev) => ({ ...prev, [key]: value }));
    },
    []
  );

  const { data: _listOrder } = useQuery({
    queryKey: ["list_claim_order"],
    queryFn: async () => {
      return await getListClaimOrder();
    },
  });

  const listOrderOptions = useMemo(() => {
    return get(_listOrder, ["orderFeeds"], []).map(({ id, orderNumber }) => ({
      label: orderNumber,
      value: id,
    }));
  }, [_listOrder, form.listOrder]);

  const fillOrderClaim = useMemo(() => {
    return get(_listOrder, ["orderFeeds"], [])
      .filter(({ id }) => id === get(form.listOrder, [0, "value"]))
      .map(
        ({
          id,
          orderNumber,
          totalFeedPrice,
          farmerId,
          name,
          surname,
          registrationId,
        }) => ({
          id,
          orderNumber,
          totalFeedPrice,
          farmerId,
          name,
          surname,
          registrationId,
        })
      );
  }, [_listOrder, form.listOrder]);

  const createMutation = useMutation({
    mutationFn: createdClaim,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["list_claim_order_upcoming"],
      });
      {
        refetchListClaimOrderProgress && refetchListClaimOrderProgress();
      }
      setTimeout(() => {
        dispatch(loadingActions.hide());
        onClose();
        setTimeout(() => {
          dispatch(
            snakeActions.showMessage({
              message: "สร้างรายการสำเร็จ",
              type: "success",
            })
          );
          setForm(defaultForm);
        }, 500);
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const upDateMutation = useMutation({
    mutationFn: approvedClaimOrder,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["list_claim_order_progress"],
      });
      {
        refetchListClaimOrderProgress && refetchListClaimOrderProgress();
      }
      setTimeout(() => {
        dispatch(loadingActions.hide());
        onClose();
        setTimeout(() => {
          dispatch(
            snakeActions.showMessage({
              message: "บันทึกสำเร็จ",
              type: "success",
            })
          );
          setForm(defaultForm);
        }, 500);
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const { onSubmit, errors, resetError } = useFormValidator({
    form: form,
    errorSchema: onEdit ? errorSchemaEdit : errorSchemaCreate,
    onValidateSuccess: () => {
      onEdit
        ? dispatch(
            alertAction.showDialog({
              title: "ยืนยันการบันทึก",
              text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
              cancelText: "กลับไปแก้ไข",
              confirmText: "ยืนยัน",
              onCancel: () => {
                dispatch(alertAction.hide());
              },
              onSubmit: async () => {
                dispatch(alertAction.hide());
                dispatch(
                  loadingActions.show({ message: "กำลังบันทึกรายการ..." })
                );
                upDateMutation.mutate({
                  orderClaimId: get(dataEdit, ["id"], "-"),
                  orderClaimItems: get(dataEdit, ["orderClaimItems"], []).map(
                    (row) => ({
                      orderClaimItemId: get(row, ["id"]),
                      claimOption: getStatusValue(
                        activeIndex
                      ) as EClaimOrderOption,
                      refundAmount: +get(form, ["refundAmount"], 0),
                      remark: form.remark || "",
                    })
                  ),
                });
              },
            })
          )
        : dispatch(
            alertAction.showDialog({
              title: "ยืนยันการสร้างรายการ",
              text: "คุณต้องการสร้างรายการนี้ใช่หรือไม่?",
              cancelText: "กลับไปแก้ไข",
              confirmText: "ยืนยัน",
              onCancel: () => {
                dispatch(alertAction.hide());
              },
              onSubmit: async () => {
                dispatch(alertAction.hide());
                dispatch(
                  loadingActions.show({ message: "กำลังสร้างรายการ..." })
                );
                createMutation.mutate({
                  orderFeedId: get(form, ["listOrder", 0, "value"]),
                  refundAmount: +get(form, ["refundAmount"]),
                  remark: form.remark || "",
                });
              },
            })
          );
    },
  });

  const onClose = useCallback(() => {
    onCloseFn();
    setActiveIndex(initialIndex);
    resetError();
  }, []);

  useEffect(() => {
    const setState = dataEdit?.orderClaimItems[0].claimRefundAmount
      ? dataEdit.orderClaimItems[0].claimRefundAmount.toString()
      : "";
    setForm((prev) => {
      return {
        ...prev,
        refundAmount: setState,
        remark: dataEdit?.orderClaimItems[0]?.remark || "",
      };
    });
  }, [dataEdit, isOpen]);

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: "w-full" }}
    >
      <DialogContent className="w-[530px]">
        <div className={classes.titleContainer}>
          <p className={classes.title}>
            {onEdit ? "แก้ไขรายการ" : "สร้างรายการใหม่"}
          </p>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </div>
        <div className="flex flex-col gap-6 pt-6">
          <div className="flex flex-col gap-3 w-1/2">
            <div className="font-bold text-[14px] text-[#1B2128]">
              หมายเลขสั่งซื้อ
            </div>
            {onEdit ? (
              <>{get(dataEdit, ["orderNumber"])}</>
            ) : (
              <Dropdown
                options={listOrderOptions}
                onChange={onChangeForm("listOrder")}
                renderValueArray={form.listOrder}
                placeholder="กรอก"
                errorMessage={errors.listOrder}
              />
            )}
          </div>
          <div className="flex flex-row gap-6">
            <div className="flex flex-col gap-3 w-1/2">
              <div className="font-bold text-[14px] text-[#1B2128">
                ชื่อลูกค้า
              </div>
              <div className="font-normal text-[14px] text-[#1B2128">
                {onEdit ? (
                  <>
                    {get(dataEdit, [
                      "contract",
                      "house",
                      "farm",
                      "farmer",
                      "name",
                    ]) +
                      " " +
                      get(dataEdit, [
                        "contract",
                        "house",
                        "farm",
                        "farmer",
                        "surname",
                      ])}
                  </>
                ) : (
                  <>
                    {fillOrderClaim[0]
                      ? get(fillOrderClaim, [0, "name"]) +
                        " " +
                        get(fillOrderClaim, [0, "surname"])
                      : "-"}
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-3 w-1/2">
              <div className="font-bold text-[14px] text-[#1B2128">
                รหัสลูกค้า
              </div>
              <div className="font-normal text-[14px] text-[#1B2128">
                {onEdit ? (
                  <>
                    {get(dataEdit, [
                      "contract",
                      "house",
                      "farm",
                      "farmer",
                      "registrationId",
                    ])}
                  </>
                ) : (
                  <>
                    {fillOrderClaim[0]
                      ? get(fillOrderClaim, [0, "registrationId"])
                      : "-"}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="font-bold text-[16px] text-[#3777BC]">
            รายการสินค้า
          </div>
          <div className="rounded-[20px] w-[482px] h-auto border-[1px] border-[#E2E2E2] p-6">
            <div className="flex flex-col gap-3">
              <div className="font-bold text-[16px] text-[#191919]">
                ตัวเลือกการแก้ไข
              </div>
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-3">
                  <div className="font-medium text-[14px] text-[#4B4B4B]">
                    แจ้งหนี้เพิ่ม
                  </div>
                  <div className="flex flex-col gap-[6px]">
                    {/* <div className="flex flex-row items-center gap-[10px] opacity-60 cursor-default">
                      <RadioButton disable checked={true} />
                      <div className="font-normal text-[14px] text-[#646464]">
                        แจ้งหนี้เพิ่ม
                      </div>
                    </div> */}
                    <div className="flex flex-row gap-[6px]">
                      <Input
                        label="เครดิตที่ใช้"
                        suffix="บาท"
                        placeholder=""
                        disabled
                        value={
                          onEdit
                            ? getCommaNumber(
                                get(dataEdit, ["totalFeedPrice"], 0),
                                true
                              )
                            : getCommaNumber(
                                get(fillOrderClaim, [0, "totalFeedPrice"], 0),
                                true
                              )
                        }
                        onChangeText={() => {}}
                      />
                      <Input
                        label="เครดิตที่เพิ่ม"
                        suffix="บาท"
                        placeholder="กรอก"
                        value={get(form, ["refundAmount"])}
                        onChangeText={onChangeForm("refundAmount")}
                        inputType="number"
                        errorMessage={errors.refundAmount}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="font-sans font-medium text-[14px] leading-5 text-[#191919] pb-2">
                    Remark
                  </span>
                  <TextAreaCustom
                    placeholder="กรอก"
                    value={form.remark}
                    onChange={(e) => onChangeForm("remark")(e.target.value)}
                    errormessage={errors.remark}
                  />
                  <div className="w-full flex justify-end pt-[2px]">
                    <span className="font-normal text-[12px] text-[#979797]">
                      (maximum 500 characters)
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-6 w-full flex justify-end">
          <ButtonFillCustom
            onClick={onSubmit}
            title="ยืนยัน"
            btnTextColor="#ffffff"
            btnBgColor="#68C184"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(CreateReportModal);

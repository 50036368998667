export function ClaimOrderSvg() {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.49984 8.5459H9.49984M4.49984 11.8792H9.49984M11.1665 16.0459H2.83317C1.9127 16.0459 1.1665 15.2997 1.1665 14.3792V2.71256C1.1665 1.79209 1.9127 1.0459 2.83317 1.0459H7.48799C7.70901 1.0459 7.92097 1.1337 8.07725 1.28998L12.5891 5.80182C12.7454 5.9581 12.8332 6.17006 12.8332 6.39108V14.3792C12.8332 15.2997 12.087 16.0459 11.1665 16.0459Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { IGetUploadUrl } from "../../types/ManageStock.type";
import { TBaseGetListPartnerStockList } from "../../types/partner/stock.type";
import api from "../ApiService";

export const getPartnerIncomingStock = async () => {
  try {
    const response = await api.get<TBaseGetListPartnerStockList>(
      "/partner/stock/upcoming"
    );

    if (![200, 201].includes(response.status)) {
      throw new Error();
    }

    return response.data;
  } catch (error) {
    return null;
  }
};
export const getPartnerIncomingStockById = async (stockId: string) => {
  try {
    const response = await api.get<TBaseGetListPartnerStockList>(
      "/partner/stock/upcoming/" + stockId
    );

    if (![200, 201].includes(response.status)) {
      throw new Error();
    }

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getUploadUrlOrderStock = async (
  orderNumber: string,
  documentType: string
) => {
  const { data } = await api.get<IGetUploadUrl>(
    `/partner/stock/upload/${orderNumber}/${documentType}`
  );
  return data;
};

export const putStockConfirmReceive = async (stockId: string) => {
  return api.put(`/partner/stock/upcoming/receive/` + stockId);
};

export const editDocumentStock = async (props: {
  id: string;
  documents: {
    fileId: string;
    fileName: string;
    fileSize: number;
    documentType: "partnerProofOfDelivery" | "partnerPickingSlip";
  }[];
}) => {
  const { id, documents } = props;
  const { data } = await api.put(`partner/stock/document/${id}`, { documents });
  return data;
};

import { memo } from "react";
import AccordionCreditCustom from "../../../components/AccordionCreditCustom";
import SegmentWeightInput from "../../../components/SegmentWeightInput";
import TableCreditCustom from "../../../components/TableCreditCustom";

type ApplicationUsageAccordionProps = { viewAction: boolean };

const ApplicationUsageAccordion = ({
  viewAction,
}: ApplicationUsageAccordionProps) => {
  return (
    <AccordionCreditCustom
      title={"Application Usage"}
      rowChildren={
        <>
          <SegmentWeightInput
            name={"applicationUsage"}
            viewAction={viewAction}
          />
          <TableCreditCustom
            isDisplayAddRow={true}
            name={"applicationUsage"}
            viewAction={viewAction}
          />
        </>
      }
    />
  );
};

export default memo(ApplicationUsageAccordion);

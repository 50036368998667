import React, { useState } from "react";
import { BaseModal } from "../../../order-feed/OrderFeedBaseModal";
import { Button } from "@mui/material";

type TConfirmToCancelModalProps = {
  onOpenModal: boolean;
  onCloseModal: (isSuccess: boolean) => void;
};

const ConfirmToCancelModal = ({
  onOpenModal,
  onCloseModal,
}: TConfirmToCancelModalProps) => {
  return (
    <BaseModal
      isOpen={onOpenModal}
      handleClose={() => onCloseModal(false)}
      iconCloseModal={true}
    >
      <div className="flex  flex-col text-center">
        <p className="text-[24px] font-medium items-center text-warning mb-4">
          ยืนยันการยกเลิก
        </p>
        <p className="text-[16px]">คุณต้องการยกเลิกคำสั่งซื้อนี้ใช่หรือไม่?</p>
        <div className="flex mt-8 justify-between">
          <Button
            size="large"
            variant="outlined"
            sx={{
              color: "#68C184",
              borderColor: "#68C184",
              width: "45%",
              ":hover": {
                border: "1px solid #68C184",
              },
              ":active": {
                border: "1px solid #68C184",
              },
            }}
            onClick={() => onCloseModal(false)}
          >
            ไม่
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={() => onCloseModal(true)}
            sx={{
              color: "#ffff",
              bgcolor: "#68C184",
              width: "45%",
              ":hover": {
                color: "#FFFFFF",
                border: "1px solid #68C184",
                bgcolor: "#68C184",
              },
              ":active": {
                color: "#FFFFFF",
                border: "1px solid #68C184",
                bgcolor: "#68C184",
              },
            }}
          >
            ยืนยัน
          </Button>
        </div>
      </div>
    </BaseModal>
  );
};

export default ConfirmToCancelModal;

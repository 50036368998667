import { Button } from "@mui/material";
import { BaseModal } from "../../feed-order-and-delivery/order-feed/OrderFeedBaseModal";

type TConfirmUploadDocumentsModalProps = {
  onOpenModal: boolean;
  onCloseModal: (isSuccess: boolean) => void;
};

const ConfirmUploadDocumentsModal = ({
  onOpenModal,
  onCloseModal,
}: TConfirmUploadDocumentsModalProps) => {
  return (
    <BaseModal
      isOpen={onOpenModal}
      handleClose={() => onCloseModal(false)}
      iconCloseModal={true}
    >
      <div className="flex  flex-col text-center">
        <p className="text-[24px] font-medium items-center text-warning mb-4">
          ยืนยันเเก้ไขหลักฐาน <br /> การจัดส่ง
        </p>
        <p className="text-[16px]">
          คุณเเน่ใจหรือไม่ว่าต้องการเเก้ไข <br />
          หลักฐานการจัดส่ง? <br />
          การดำเนินการนี้ไม่สามารถยกเลิกได้
        </p>

        <Button
          size="large"
          variant="outlined"
          sx={{
            marginTop: "64px",
            color: "#68C184",
            borderColor: "#68C184",
            width: "100%",
            ":hover": {
              border: "1px solid #68C184",
            },
            ":active": {
              border: "1px solid #68C184",
            },
          }}
          onClick={() => {
            onCloseModal(false);
          }}
        >
          กลับไปแก้ไข
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={() => {
            onCloseModal(true);
          }}
          sx={{
            marginTop: "16px",
            color: "#ffff",
            bgcolor: "#68C184",
            width: "100%",
            ":hover": {
              color: "#FFFFFF",
              border: "1px solid #68C184",
              bgcolor: "#68C184",
            },
            ":active": {
              color: "#FFFFFF",
              border: "1px solid #68C184",
              bgcolor: "#68C184",
            },
          }}
        >
          ยืนยัน
        </Button>
      </div>
    </BaseModal>
  );
};

export default ConfirmUploadDocumentsModal;

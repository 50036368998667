import { LoaderFunctionArgs } from "react-router-dom";
import { getMyOrderDetailById } from "../../../services/MyOrder.service";
import {
  getContactList,
  getFarmer,
  getHouseAddress,
  getOrderFeedBrands,
} from "../../../services/OrderFeed.service";
import { IGetMyOrderByID } from "../../../types/MyOrderFeed.type";

export async function orderFeedLoader(loader: LoaderFunctionArgs) {
  const { request, params } = loader;
  const orderId = new URL(request.url).searchParams.get("orderId") ?? "";
  const farmId = params["farmId"] ?? "";
  const feed = await getOrderFeedBrands();
  const feedBrand = feed?.feedBrands ?? [];
  const contracts = await getContactList(farmId);
  const contractDetail = contracts?.contractList ?? [];
  const house = await getHouseAddress(farmId);
  const houseAddress = house?.farm ?? "";
  const farmer = await getFarmer();
  const farmerPhoneNumber = farmer?.result.phoneNumber;
  let getOrderById: IGetMyOrderByID | null = null;
  if (orderId) {
    getOrderById = await getMyOrderDetailById(orderId);
  }

  return {
    feedBrand,
    farmId,
    contractDetail,
    houseAddress,
    farmerPhoneNumber,
    getOrderById: getOrderById || null,
  };
}

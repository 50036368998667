import { Modal, Stack, Typography, alpha, styled } from "@mui/material";

export const ModalContainer = styled(Modal)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ".MuiModal-backdrop": {
    backgroundColor: alpha(theme.palette.common.black, 0.1),
  },
}));

export const FontTypo = styled(Typography)(() => ({
  fontFamily: "IBM Plex Sans Thai, sans-serif",
  color: "#191919",
}));

export const InnerContainer = styled(Stack)(() => ({
  boxShadow: "0px 4px 12px 0px #33333333",
  backgroundColor: "white",
  minHeight: "100px",
  maxHeight: "90vh",
  padding: "24px",
  gap: "24px",
  borderRadius: "12px",
  overflowY: "scroll",
  msOverflowStyle: "none",
  scrollbarWidth: "none",
}));

export const FontTypoDetail = styled(FontTypo)(() => ({
  fontSize: "14px",
  fontWeight: 400,
}));

export const FontTypoHeader = styled(FontTypo)(() => ({
  fontSize: "14px",
  fontWeight: 700,
}));

export const BoxDetailStack = styled(Stack)(() => ({
  border: "1px solid #E2E2E2",
  borderRadius: "20px",
  padding: "12px",
  gap: "24px",
}));

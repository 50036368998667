import { TextField, TextFieldProps } from "@mui/material";
import React, { useCallback } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

interface IcustomStylesProps {
  borderRadius?: string;
  textAlign?: string;
  borderColor?: string;
  onChangeText?: (text: string) => void;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  }
);

export default function TextInputFS(
  props: TextFieldProps & IcustomStylesProps
) {
  const {
    onChangeText,
    borderRadius,
    textAlign,
    borderColor,
    type,
    ...restProps
  } = props;

  const onChangeFn = useCallback((e: any) => {
    const text = e.target.value;
    onChangeText && onChangeText(text);
  }, []);
  return (
    <TextField
      {...restProps}
      color="tertiary"
      variant="outlined"
      fullWidth
      onChange={onChangeText ? onChangeFn : restProps.onChange}
      InputProps={{
        inputComponent:
          type === "number" ? (NumericFormatCustom as any) : undefined,
        sx: {
          borderRadius: borderRadius || "28px",
          fontWeight: 500,
          color: "#191919",
          background: "#F7FBFF",
          borderColor: borderColor || "#3777BC",
          textAlign: textAlign || "",
        },
        ...restProps?.InputProps,
      }}
      InputLabelProps={{ shrink: restProps.value ? true : false }}
    />
  );
}

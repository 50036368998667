import { flow, set } from "lodash";
import { IOrderItemProps } from "./views/OrderItem";

export const statusWord = {
  new: "รายการใหม่",
  "ready-for-ship": "ที่ต้องจัดส่ง",
  shipping: "กำลังจัดส่ง",
  shipped: "จัดส่งสำเร็จ",
  received: "รับสินค้าสำเร็จ",
  canceled: "ออเดอร์ถูกยกเลิก",
};

export const actionWord = {
  new: "รับออเดอร์",
  "ready-for-ship": "จัดส่งสินค้า",
  shipping: "จัดส่งสำเร็จ",
  shipped: "-", // will show for farmer not for partner
  received: "-",
};

export const dialogWord = {
  new: {
    title: "ยืนยันการรับออเดอร์",
    text: "คุณแน่ใจหรือไม่ว่าต้องการรับออเดอร์? การดำเนินการนี้ไม่สามารถยกเลิกได้",
  },
  "ready-for-ship": {
    title: "ยืนยันการจัดส่งสินค้า",
    text: "คุณแน่ใจหรือไม่ว่าต้องการจัดส่งสินค้า? การดำเนินการนี้ไม่สามารถยกเลิกได้",
  },
  shipping: {
    title: "ยืนยันการจัดส่งสำเร็จ",
    text: "ก่อนกดยืนยันการจัดส่งสำเร็จ กรุณาตรวจสอบรายละเอียดก่อนยืนยัน",
  },
  shipped: {
    title: "ยืนยันการรับของ",
    text: "กรุณากดยืนยันการรับสินค้า ก่อนกดยืนยันการรับสินค้า กรุณาตรวจสอบสินค้าที่สั่งซื้อ",
  },
  received: {
    title: "-",
    text: "-",
  },
};

export const getStepsFromStatus = (status: IOrderItemProps["status"]) => {
  const baseSteps = [
    { label: "รายการใหม่" },
    { label: "ที่ต้องจัดส่ง" },
    { label: "กำลังจัดส่ง" },
    { label: "จัดส่งสำเร็จ" },
    { label: "รับสินค้าสำเร็จ" },
  ];
  if (status === "new") {
    return flow([
      (d) => set(d, [0, "status"], "active"),
      (d) => set(d, [1, "status"], "progress"),
    ])(baseSteps);
  }
  if (status === "ready-for-ship") {
    return flow([
      (d) => set(d, [0, "status"], "active"),
      (d) => set(d, [1, "status"], "active"),
      (d) => set(d, [2, "status"], "progress"),
    ])(baseSteps);
  }
  if (status === "shipping") {
    return flow([
      (d) => set(d, [0, "status"], "active"),
      (d) => set(d, [1, "status"], "active"),
      (d) => set(d, [2, "status"], "active"),
      (d) => set(d, [3, "status"], "progress"),
    ])(baseSteps);
  }
  if (status === "shipped") {
    return flow([
      (d) => set(d, [0, "status"], "active"),
      (d) => set(d, [1, "status"], "active"),
      (d) => set(d, [2, "status"], "active"),
      (d) => set(d, [3, "status"], "active"),
      (d) => set(d, [4, "status"], "progress"),
    ])(baseSteps);
  }
  if (status === "received") {
    return flow([
      (d) => set(d, [0, "status"], "active"),
      (d) => set(d, [1, "status"], "active"),
      (d) => set(d, [2, "status"], "active"),
      (d) => set(d, [3, "status"], "active"),
      (d) => set(d, [4, "status"], "active"),
    ])(baseSteps);
  }
};

import { getMoveOutPigFarmPastPerformance } from "../../services/MoveOutPig.service";

export async function farmPastPerformanceLoader({ params }: any) {
  try {
    const { farmId } = params;
    if (!farmId) {
      throw new Response("Not Found", { status: 404 });
    }

    const farmPastPerformance = await getMoveOutPigFarmPastPerformance(farmId);

    if (farmPastPerformance?.success) {
      return {
        farmPastPerformance,
      };
    } else {
      throw new Response("Not Found", { status: 404 });
    }
  } catch (err) {
    throw new Response("Not Found", { status: 404 });
  }
}

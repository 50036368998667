import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { tw } from "../../../utils/tw";
import AppBarFS from "../../../components/app-bar/AppBar";
import DashboardInfoCard from "../../../components/common/DashboardInfoCard/DashboardInfoCard";
import List from "../../../assets/svg/List";
import OrderHistoryBgPurpleSVG from "../../../assets/svg/OrderHistoryBgPurple";
import OrderHistorySVG from "../../../assets/svg/OrderHistory";

const classes = {
  container: tw(`w-full h-full`),
  headerContainer: tw(`h-[120px] flex items-center`),
  detailContainer: tw(`flex flex-col w-full h-full gap-[16px] px-[16px]`),
};

const FinancialBillPayment = () => {
  let navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, []);

  const handleClickToBillPayment = useCallback(() => {
    navigate("/financial-support/financial-bill-payment/unpaid-bill");
  }, []);

  const handleClickToVerifyBill = useCallback(() => {
    navigate("/financial-support/financial-bill-payment/verify-bill");
  }, []);

  const handleClickToHistoryBill = useCallback(() => {
    navigate("/financial-support/financial-bill-payment/history-bill");
  }, []);

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <AppBarFS title={"Bill Payment"} onIconPress={handleBack} />
        </div>
        <div className={classes.detailContainer}>
          <DashboardInfoCard
            title={"บิลที่ยังไม่ได้ชำระ"}
            subTitle={"bill payment"}
            icon={<List />}
            removeBgIcon={true}
            bgColor="#F0FDF4"
            onPress={handleClickToBillPayment}
          />
          <DashboardInfoCard
            title={"บิลที่รอตรวจสอบ"}
            subTitle={"บิลที่รอตรวจสอบทั้งหมด"}
            icon={<OrderHistoryBgPurpleSVG />}
            removeBgIcon={true}
            bgColor="#EEF2FF"
            onPress={handleClickToVerifyBill}
          />
          <DashboardInfoCard
            title={"ประวัติชำระบิล"}
            subTitle={"bill history record"}
            icon={<OrderHistorySVG />}
            removeBgIcon={true}
            bgColor="#D9EBFEB2"
            onPress={handleClickToHistoryBill}
          />
        </div>
      </div>
    </div>
  );
};

export default FinancialBillPayment;

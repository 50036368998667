import * as yup from "yup";

const yupSectionSchema = yup.object().shape({
  segmentWeight: yup.number().typeError("กรุณากรอก"),
  checklists: yup.array().of(
    yup.object().shape({
      isActive: yup.boolean(),
      weight: yup
        .string()
        .test("check-weight", "กรุณากรอก", (value, context) => {
          const { isActive } = context.parent;
          return !isActive || !!value;
        }),
    })
  ),
});

const CreditFormSchema = () => {
  const schema = yup.object().shape({
    name: yup.string().required("กรุณากรอก"),
    overallWeight: yup.number().max(100, "น้ำหนักรวมควรมีค่ามีไม่เกิน 100"),
    generalPersonalInformation: yupSectionSchema,
    generalLegalInformation: yupSectionSchema,
    generalFarmInformation: yupSectionSchema,
    farmPerformance: yupSectionSchema,
    farmFinancialStatusAndInstallment: yupSectionSchema,
    applicationUsage: yupSectionSchema,
    gradeSetup: yup.object().shape({
      minimumA: yup
        .number()
        .typeError("กรุณากรอก Min A")
        .lessThan(100, "ค่าของ Min A ต้องน้อยกว่า 100"),
      minimumB: yup
        .number()
        .typeError("กรุณากรอก Min B")
        .lessThan(yup.ref("minimumA"), "Min B ต้องมีค่าน้อยกว่า Max B"),
      minimumC: yup
        .number()
        .typeError("กรุณากรอก Min C")
        .lessThan(yup.ref("minimumB"), "Min C ต้องมีค่าน้อยกว่า Max C"),
      minimumD: yup
        .number()
        .typeError("กรุณากรอก Min D")
        .lessThan(yup.ref("minimumC"), "Min D ต้องมีค่าน้อยกว่า Max D"),
    }),
  });

  return { schema };
};

export default CreditFormSchema;

const IconMyStockSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={26} fill="none">
    <path
      fill="#fff"
      d="M4.8 3.867c-1.325 0-2.4 1.155-2.4 2.578 0 1.424 1.075 2.579 2.4 2.579h14.4c1.326 0 2.4-1.155 2.4-2.579 0-1.423-1.075-2.578-2.4-2.578H4.8Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M3.6 10.313h16.8v9.024c0 1.424-1.074 2.578-2.4 2.578H6c-1.325 0-2.4-1.154-2.4-2.578v-9.024Zm6 3.867c0-.712.537-1.289 1.2-1.289h2.4c.663 0 1.2.577 1.2 1.29 0 .711-.537 1.289-1.2 1.289h-2.4c-.663 0-1.2-.578-1.2-1.29Z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconMyStockSvg;

export default function PlusIconSVG() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="24" height="24" rx="12" fill="white" />
      <path
        d="M12 7.5V12.5M12 12.5V17.5M12 12.5H17M12 12.5L7 12.5"
        stroke="#68C184"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import TableCustom from "../../../components/Table/TableCustom";
import {
  IInvoice,
  IResultInvoiceManager,
  IRowProcessInvoiceManager,
} from "../../../../types/invoice/invoiceManager.type";
import dayjs from "dayjs";
import { thousandComma } from "../../../../utils/common/string";

type Props = {
  invoiceExpand: IResultInvoiceManager | IRowProcessInvoiceManager;
};

const ExpandInvoiceTable = ({ invoiceExpand }: Props) => {
  return (
    <TableCell colSpan={11} sx={{ bgcolor: "#F6FBFF" }}>
      <div className="w-3/4 h-full px-6 py-2">
        <div className="px-[40px] py-[24px] bg-[#FFFFFF] rounded-[20px]">
          <p className="text-[#68C184] font-bold text-xl mb-4">
            รายการ Invoice
          </p>
          <TableContainer
            sx={{
              scrollBehavior: "smooth",
              overflow: "auto",
            }}
          >
            <Table
              sx={{
                width: "100%",
              }}
              aria-labelledby="table-show-data"
              size={"medium"}
            >
              <TableHead
                sx={{
                  bgcolor: "#FBFBFB",
                  borderBottom: "1px solid #E1E1E1",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      height: "50px",
                      justifyContent: "start",
                      fontFamily: "IBM Plex Sans Thai",
                      fontWeight: 700,
                      fontSize: "14px",
                      border: "0px",
                      padding: "0px 16px",
                    }}
                  >
                    เลข Invoice
                  </TableCell>
                  <TableCell
                    sx={{
                      height: "50px",
                      justifyContent: "start",
                      fontFamily: "IBM Plex Sans Thai",
                      fontWeight: 700,
                      fontSize: "14px",
                      border: "0px",
                      padding: "0px 16px",
                    }}
                    align="left"
                  >
                    ยอดเงินที่ต้องชำระ
                  </TableCell>
                  <TableCell
                    sx={{
                      height: "50px",
                      justifyContent: "start",
                      fontFamily: "IBM Plex Sans Thai",
                      fontWeight: 700,
                      fontSize: "14px",
                      border: "0px",
                      padding: "0px 16px",
                    }}
                    align="left"
                  >
                    ยอดเงินที่ชำระเเล้ว
                  </TableCell>
                  <TableCell
                    sx={{
                      height: "50px",
                      justifyContent: "start",
                      fontFamily: "IBM Plex Sans Thai",
                      fontWeight: 700,
                      fontSize: "14px",
                      border: "0px",
                      padding: "0px 16px",
                    }}
                    align="left"
                  >
                    วันรับสินค้าสำเร็จ
                  </TableCell>
                  <TableCell
                    sx={{
                      height: "50px",
                      justifyContent: "start",
                      fontFamily: "IBM Plex Sans Thai",
                      fontWeight: 700,
                      fontSize: "14px",
                      border: "0px",
                      padding: "0px 16px",
                    }}
                    align="left"
                  >
                    วันครบกำหนดชำระ
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceExpand.invoices.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.invoiceNumber ? row.invoiceNumber : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {row.totalPrice
                        ? `${thousandComma(row.totalPrice, 2)} บาท`
                        : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {row.totalPaid
                        ? `${thousandComma(row.totalPaid, 2)} บาท`
                        : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {row.receiveDate
                        ? dayjs(row.receiveDate).format("DD/MM/YYYY")
                        : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {row.receiveDate
                        ? dayjs(row.dueDate).format("DD/MM/YYYY")
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </TableCell>
  );
};

export default ExpandInvoiceTable;

import adminAPI from "../../../../services/AdminApiService";
import {
  IGetVaccine,
  TCreateVaccineForm,
  VaccineRow,
} from "../types/vaccine.type";

export const getVaccine = async (searchParams?: URLSearchParams) => {
  try {
    const { data } = await adminAPI.get<IGetVaccine>(
      `/master-data/vaccine?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const createVaccine = async (payload: TCreateVaccineForm) => {
  try {
    const { data } = await adminAPI.post<IGetVaccine>(
      `/master-data/vaccine`,
      payload
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const editVaccine = async (
  payloadEdit: VaccineRow[],
  vaccineIds: string[]
) => {
  return adminAPI.put("/master-data/vaccine", {
    updateVaccines: payloadEdit,
    deleteVaccineIds: vaccineIds,
  });
};

export const toggleVaccine = async (body: {
  vaccines: {
    id: string;
    isActive: boolean;
  }[];
}) => {
  return adminAPI.put("/master-data/vaccine/toggle", body);
};

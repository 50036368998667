export function NotificationSvg() {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6253 15.7083H17.8337L16.3702 14.2449C15.9733 13.848 15.7503 13.3097 15.7503 12.7484V9.45834C15.7503 6.73705 14.0111 4.42197 11.5837 3.56397V3.20833C11.5837 2.05774 10.6509 1.125 9.50033 1.125C8.34973 1.125 7.41699 2.05774 7.41699 3.20833V3.56397C4.98951 4.42197 3.25033 6.73705 3.25033 9.45834V12.7484C3.25033 13.3097 3.02735 13.848 2.63046 14.2449L1.16699 15.7083H6.37533M12.6253 15.7083V16.75C12.6253 18.4759 11.2262 19.875 9.50033 19.875C7.77444 19.875 6.37533 18.4759 6.37533 16.75V15.7083M12.6253 15.7083H6.37533"
        // stroke="#68C184"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

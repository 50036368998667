import { Dialog, DialogContent, IconButton } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import ListOfFile from "../../../../components/common/File/ListOfFile";
import { alertAction } from "../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../../redux/store";
import {
  putReverseInvoice,
  uploadInvoiceOtherAPI,
} from "../../../../services/admin/invoice/invoiceSale.service";
import { IGetInvoiceSuccessRow } from "../../../../types/invoice/invoiceSale.type";
import { thousandComma } from "../../../../utils/common/string";
import {
  handleDownloadObject,
  handleUploadFileToAzure,
} from "../../../../utils/downloadFile";
import getErrorMessage from "../../../../utils/getErrorMessage";
import { tw } from "../../../../utils/tw";
import CrossIcon from "../../../assets/svg/CrossIcon.svg";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import DragAndDropUploadImage from "../../../components/Input/DragAndDropUploadImage";
import RHFTextAreaCustom from "../../../components/Input/RHFTextAreaCustom";

const classes = {
  flexCenter: tw(`flex justify-between items-center`),
  flex: tw(`flex w-[100%]`),
  flexCol: tw(`flex flex-col`),
  container: tw(`items-start w-[50%]`),
  header: tw(`text-sm font-bold pb-2 mb-1`),
  headerModal: tw(`text-lg font-bold`),
  title: tw(`text-sm pb-3 mb-1`),
  marginTop: tw(`mt-4`),
};

type TReverseDetailForm = {
  detail: string;
  otherDocuments: File[];
};

type Props = {
  isOpen: boolean;
  handleClose: (value: boolean) => void;
  invoiceId: string;
  reverseDetail?: IGetInvoiceSuccessRow;
};

type document = {
  fileId: string;
  fileName: string;
};
export interface IReverseInvoice {
  detail: string;
  otherDocuments: document[];
}

const ReverseInvoiceModal = ({
  isOpen,
  handleClose,
  invoiceId,
  reverseDetail,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const otherDocument: IReverseInvoice["otherDocuments"] = [];
  const submitAPI = async (value: TReverseDetailForm) => {
    if (value.otherDocuments.length) {
      const response = await uploadInvoiceOtherAPI(invoiceId);
      if (response?.blob?.blobUrl) {
        await handleUploadFileToAzure(
          response?.blob?.blobUrl,
          value.otherDocuments[0]
        );
        otherDocument.push({
          fileId: response.blob.fileId,
          fileName: value.otherDocuments[0].name,
        });
      }
    }
    const body: IReverseInvoice = {
      detail: value.detail,
      otherDocuments: otherDocument || [],
    };
    await putReverseInvoice(invoiceId, body);
  };

  const handleSubmit = (value: TReverseDetailForm) => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
            await submitAPI(value);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "บันทึกสำเร็จ",
              })
            );
            handleClose(true);
          } catch (error) {
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  const handleDeleteFile = async () => {
    methods.setValue("otherDocuments", []);
  };

  const handleUploadFiles = (files: File[]) => {
    methods.setValue("otherDocuments", files);
  };

  const methods = useForm<TReverseDetailForm>({
    defaultValues: {
      detail: "",
      otherDocuments: [],
    },
  });

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => handleClose(true)}
        maxWidth="lg"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={{
          backdrop: {
            style: { backgroundColor: "#000000", opacity: "10%" },
            timeout: 500,
          },
        }}
      >
        <DialogContent sx={{ width: "750px" }}>
          <div className={classes.flexCenter}>
            <p className={classes.headerModal}>ยืนยันการย้อนกลับรายการ</p>
            <IconButton onClick={() => handleClose(true)}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className={classes.marginTop}>
            <div className={classes.flex}>
              <div className={classes.container}>
                <p className={classes.header}>รหัสลูกค้า</p>
                <p className={classes.title}>
                  {reverseDetail?.farmerRegistrationId}
                </p>
              </div>
              <div className={classes.container}>
                <p className={classes.header}>ชื่อลูกค้า</p>
                <p className={classes.title}>
                  {reverseDetail?.farmerName} {reverseDetail?.farmerSurname}
                </p>
              </div>
            </div>
            <div className={classes.flex}>
              <div className={classes.container}>
                <p className={classes.header}>เลข Invoice</p>
                <p className={classes.title}>{reverseDetail?.invoiceNumber}</p>
              </div>
              <div className={classes.container}>
                <p className={classes.header}>ยอดเงินที่ต้องชำระ</p>
                <p className={classes.title}>
                  {reverseDetail
                    ? thousandComma(reverseDetail?.totalPaid)
                    : "-"}{" "}
                  บาท
                </p>
              </div>
            </div>
            <FormProvider {...methods}>
              <div className={classes.flexCol}>
                <span className={tw(classes.header, "font-medium pb-0")}>
                  รายละเอียด (ไม่บังคับ)
                </span>
                <RHFTextAreaCustom placeholder="กรอก" controlName="detail" />
                <div className="w-full flex justify-end pt-[2px]">
                  <span className="font-normal text-[12px] text-[#979797]">
                    (maximum 500 characters)
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <label className="font-bold" htmlFor="files">
                  เอกสาร (ไม่บังคับ)
                </label>
                <ListOfFile
                  files={methods.watch("otherDocuments")}
                  onDownload={handleDownloadObject}
                  onDelete={handleDeleteFile}
                />
                <DragAndDropUploadImage onUpload={handleUploadFiles} />
              </div>
              <div className="flex justify-end">
                <ButtonFillCustom
                  title="บันทึก"
                  onClick={methods.handleSubmit(handleSubmit)}
                />
              </div>
            </FormProvider>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReverseInvoiceModal;

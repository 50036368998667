import { IAdminLocation } from "../../../types/admin/location/location";
import adminAPI from "../../AdminApiService";

export const getAdminLocation = async () => {
  try {
    const { data } = await adminAPI.get<IAdminLocation>(`/locations`);
    return data;
  } catch (error) {
    return null;
  }
};

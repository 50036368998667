import { IGetCreditOrderIncoming } from "../../../types/EditOrderFeed";
import { IExportHistoryOrder } from "../../../types/OrderHistory.type";
import adminAPI from "../../AdminApiService";

export const getHistoryOrder = async (searchParams?: URLSearchParams) => {
  try {
    const { data } = await adminAPI.get<IGetCreditOrderIncoming>(
      `/sale-order/history-order?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getListExportCreditHistoryOrder = async (ids: string[]) => {
  try {
    const { data } = await adminAPI.post<IExportHistoryOrder>(
      `/sale-order/history-order/export`,
      { ids }
    );
    return data;
  } catch (error) {
    return null;
  }
};

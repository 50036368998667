import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { tw } from "../../../../../utils/tw";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import CrossIcon from "../../../../assets/svg/CrossIcon.svg";
import {
  TFormTabFarmer,
  defaultFormTabFarmer,
} from "../credit-tab/TabFarmer/DefaultFormTabFarmer";
import TextAreaCustom from "../../../../components/Input/TextAreaCustom";
import ButtonOutlinedCustom from "../../../../components/Button/ButtonOutlinedCustom";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import ListOfFile from "../../../../../components/common/File/ListOfFile";
import DragAndDropUploadImage from "../../../../components/Input/DragAndDropUploadImage";
import { ICreditFeedData } from "../../../../../types/CreditFeed.type";
import { getDownloadUrl } from "../../../../../services/CreditFeed.service";
import axios from "axios";
import { handleDownloadBlob } from "../../../../../utils/downloadFile";
import { get } from "lodash";
import DownloadLinkWithDelete from "../../../../../components/common/File/DownloadLinkWithDelete";

const classes = {
  containerModal: tw(
    `w-[100vw] !max-w-[884px] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary`
  ),
  container: tw(`flex flex-col gap-6`),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold text-[#191919]`),
  rowContainer: tw(`grid grid-cols-4 gap-3`),
  text: tw(`font-sans font-bold text-[16px]`),
};

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

interface IProps {
  data?: ICreditFeedData;
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
  form: TFormTabFarmer;
  setForm: Dispatch<SetStateAction<TFormTabFarmer>>;
  onChangeForm: (key: keyof TFormTabFarmer) => (value: any) => void;
}

const ModalImportDocument = (props: IProps) => {
  const { data, isOpen, onSubmit, onClose, form, setForm, onChangeForm } =
    props;

  const handleDownload = useCallback(
    async (file: File) => {
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${file.name}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    },
    [form.citizenDocument, form.creditBureauDocument]
  );

  const handleDownloadByUrl = useCallback(
    (id: string, fileId: string, name: string) => async () => {
      const preSignURL = await getDownloadUrl(id, fileId);
      if (preSignURL) {
        const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
          responseType: "blob",
        });
        const objectURL = window.URL.createObjectURL(response.data);
        handleDownloadBlob(objectURL, name);
      }
    },
    [form.citizenDocumentUpload, form.creditBureauDocumentUpload]
  );

  const onCloseFn = useCallback(() => {
    onClose();
    setForm(defaultFormTabFarmer);
  }, []);

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      citizenDocumentUpload: data?.creditInformation.citizenDocument || [],
      creditBureauDocumentUpload:
        data?.creditInformation.creditBureauDocument || [],
    }));
  }, [isOpen, data]);

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.containerModal}
      classes={{ paper: "w-[100vw] !max-w-[884px]" }}
    >
      <DialogContent>
        <div className={classes.container}>
          <div className={classes.titleContainer}>
            <p className={classes.title}>นำเข้าเอกสาร</p>
            <IconButton onClick={onCloseFn}>
              <CrossIcon />
            </IconButton>
          </div>
          <div>
            <p className="font-medium text-[#191919] mb-[6px] text-[14px] font-sans">
              รูปบัตรประชาชน
            </p>
            {(form.citizenDocumentUpload.length || 0) > 0 ? (
              form.citizenDocumentUpload.map((row, idx) => {
                return (
                  <DownloadLinkWithDelete
                    onDownload={handleDownloadByUrl(
                      get(data, ["id"], ""),
                      row.id,
                      row.fileName
                    )}
                    onDelete={async () => {
                      onChangeForm("citizenDocument")([]);
                      onChangeForm("citizenDocumentUpload")([]);
                    }}
                    name={row.fileName}
                  />
                );
              })
            ) : (
              <ListOfFile
                files={form.citizenDocument}
                onDownload={handleDownload}
                onDelete={async (idx) => {
                  onChangeForm("citizenDocument")([]);
                }}
              />
            )}
            <DragAndDropUploadImage
              onUpload={(files) => {
                onChangeForm("citizenDocument")(files);
              }}
              type={{ "application/pdf": [".pdf"] }}
            />
          </div>
          <div>
            <p className="font-medium text-[#191919] mb-[6px] text-[14px] font-sans">
              เอกสารเครดิตบูโร
            </p>
            {(form.creditBureauDocumentUpload.length || 0) > 0 ? (
              form.creditBureauDocumentUpload.map((row, idx) => {
                return (
                  <DownloadLinkWithDelete
                    onDownload={handleDownloadByUrl(
                      get(data, ["fullData", "id"]),
                      row.id,
                      row.fileName
                    )}
                    onDelete={async () => {
                      onChangeForm("creditBureauDocument")([]);
                      onChangeForm("creditBureauDocumentUpload")([]);
                    }}
                    name={row.fileName}
                  />
                );
              })
            ) : (
              <ListOfFile
                files={form.creditBureauDocument}
                onDownload={handleDownload}
                onDelete={async (idx) => {
                  onChangeForm("creditBureauDocument")([]);
                }}
              />
            )}
            <DragAndDropUploadImage
              onUpload={(files) => {
                onChangeForm("creditBureauDocument")(files);
              }}
              type={{ "application/pdf": [".pdf"] }}
            />
          </div>
          <div className="flex flex-row justify-end gap-[10px]">
            <ButtonFillCustom title={"ยืนยัน"} onClick={onSubmit} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(ModalImportDocument);

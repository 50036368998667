import { useQuery } from "@tanstack/react-query";
import { getAllContract } from "../../services/Financial.service";
import getNormalRole from "../../utils/getNormalRole";

export const useFarmController = () => {
  const role = getNormalRole();

  const {
    data: financialDetail,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["financialDetail"],
    enabled: role === "farmer",
    queryFn: async () => {
      const result = await getAllContract();
      return result;
    },
  });

  return {
    financialDetail,
  };
};

interface DataExport {
  flockName: string;
  year: number;
  month: string;
  generationCloseDate: string;
  incomeFromSellingPig: number;
  geneticBill: number;
  feedBill: number;
  vaccineAndMedicineBill: number;
  staffBill: number;
  waterBill: number;
  electricityBill: number;
  transportationBill: number;
  programBill: number;
  otherBill: number;
}

export function createData(
  flockName: string,
  year: number,
  month: string,
  generationCloseDate: string,
  incomeFromSellingPig: number,
  geneticBill: number,
  feedBill: number,
  vaccineAndMedicineBill: number,
  staffBill: number,
  waterBill: number,
  electricityBill: number,
  transportationBill: number,
  programBill: number,
  otherBill: number
): DataExport {
  return {
    flockName,
    year,
    month,
    generationCloseDate,
    incomeFromSellingPig,
    geneticBill,
    feedBill,
    vaccineAndMedicineBill,
    staffBill,
    waterBill,
    electricityBill,
    transportationBill,
    programBill,
    otherBill,
  };
}

interface DataExportPerFlockToMonth {
  flockName: string;
  generationCloseDate: string;
  incomeFromSellingPig: number;
  geneticBill: number;
  feedBill: number;
  vaccineAndMedicineBill: number;
  staffBill: number;
  waterBill: number;
  electricityBill: number;
  transportationBill: number;
  programBill: number;
  otherBill: number;
}

export function createDataPerFlockToMonth(
  flockName: string,
  generationCloseDate: string,
  incomeFromSellingPig: number,
  geneticBill: number,
  feedBill: number,
  vaccineAndMedicineBill: number,
  staffBill: number,
  waterBill: number,
  electricityBill: number,
  transportationBill: number,
  programBill: number,
  otherBill: number
): DataExportPerFlockToMonth {
  return {
    flockName,
    generationCloseDate,
    incomeFromSellingPig,
    geneticBill,
    feedBill,
    vaccineAndMedicineBill,
    staffBill,
    waterBill,
    electricityBill,
    transportationBill,
    programBill,
    otherBill,
  };
}

interface DataExportGraphYearAll {
  year: number;
  profit: string;
}

export function createDataGraphYearAll(
  year: number,
  profit: string
): DataExportGraphYearAll {
  return {
    year,
    profit,
  };
}

interface DataExportGraphYearToMonth {
  year: number;
  month: string;
  profit: string;
}

export function createDataGraphYearToMonth(
  year: number,
  month: string,
  profit: string
): DataExportGraphYearToMonth {
  return {
    year,
    month,
    profit,
  };
}

interface DataExportTwoGraphYear {
  year: number;
  income: string;
  expense: string;
}

export function createDataTwoGraphYear(
  year: number,
  income: string,
  expense: string
): DataExportTwoGraphYear {
  return {
    year,
    income,
    expense,
  };
}

interface DataExportTwoGraphMonth {
  year: number;
  month: string;
  income: string;
  expense: string;
}

export function createDataTwoGraphMonth(
  year: number,
  month: string,
  income: string,
  expense: string
): DataExportTwoGraphMonth {
  return {
    year,
    month,
    income,
    expense,
  };
}

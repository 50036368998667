import { useQuery } from "@tanstack/react-query";
import {
  getHouseDetailsPerFlockBasicAccounting,
  getHouseDetailsPerMonthBasicAccounting,
} from "../../../../../services/BasicAccounting.service";
import { useCallback, useState } from "react";
import {
  defaultFormSeeHouseDetailsBasicAccounting,
  TFormSeeHouseDetailsBasicAccounting,
} from "./defaultFormSeeHouseDetails";
import dayjs from "dayjs";

export const useSeeHouseDetailsBasicAccountingController = (
  farmId: string,
  houseId: string
) => {
  const [
    formSeeHouseDetailsBasicAccounting,
    setFormSeeHouseDetailsBasicAccounting,
  ] = useState<TFormSeeHouseDetailsBasicAccounting>(
    defaultFormSeeHouseDetailsBasicAccounting
  );

  const onChangeFormSeeHouseDetailsBasicAccounting = useCallback(
    (key: keyof TFormSeeHouseDetailsBasicAccounting) => (value: any) => {
      setFormSeeHouseDetailsBasicAccounting((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const { data: DataHouseDetails } = useQuery({
    queryKey: ["get-house-details-per-flock-basic-accounting", farmId, houseId],
    queryFn: async () => {
      const result = await getHouseDetailsPerFlockBasicAccounting({
        farmId,
        houseId,
      });
      return result.result;
    },
  });

  const { data: DataHousePerMonthDetails } = useQuery({
    queryKey: [
      "get-house-details-per-month-basic-accounting",
      farmId,
      houseId,
      formSeeHouseDetailsBasicAccounting.month,
    ],
    queryFn: async () => {
      const result = await getHouseDetailsPerMonthBasicAccounting({
        farmId,
        houseId,
        month: +formSeeHouseDetailsBasicAccounting.month[0].value,
        christianYear: dayjs().year(),
      });
      return result.result;
    },
  });

  return {
    formSeeHouseDetailsBasicAccounting,
    onChangeFormSeeHouseDetailsBasicAccounting,
    DataHouseDetails,
    DataHousePerMonthDetails,
  };
};

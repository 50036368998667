import { useNavigate } from "react-router";
import SuccessCard from "../../../../components/common/SuccessCard";

export default function PartnerResetPinSuccess() {
  const navigate = useNavigate();
  const handleBackToLogin = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("firstTimeLogin");
    navigate("/welcome");
  };
  return (
    <SuccessCard
      title="New PIN Saved"
      subtitle1="We Already set your new pin"
      subtitle2="Note out your new PIN for seamless
        log in next time"
      buttonLabel="Back to Login"
      onButtonPress={handleBackToLogin}
    />
  );
}

import { Button, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { object, string } from "yup";
import AppBarFS from "../../components/app-bar/AppBar";
import AuthBox from "../../components/common/AuthBox";
import { CountdownTime } from "../../components/common/CountdownTimer/CountdownTime";
import { AUTH_TOKEN_KEY, THAI_COUNTRY_CODE } from "../../const/app.constant";
import {
  getOTPByMobileService,
  verifyOTPService,
} from "../../services/AuthService";
import { setItem } from "../../services/StorageService";
import { formError, formErrorMessage } from "../../utils/form-validator";

export default function Authentication() {
  const { state } = useLocation();
  const { t } = useTranslation();
  let navigate = useNavigate();

  // State
  const [timeShowing, setTimeShowing] = useState<boolean>(true);
  const [checkOTP, setCheckOTP] = useState<number>(3);
  const [checkOTPLimit, setCheckOTPLimit] = useState(false);
  const [checkInternalError, setCheckInternalError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // Effect
  useEffect(() => {
    if (state && state.mobileNumber) {
      sendOTPMobile(state?.mobileNumber);
    } else {
      // Navigate Back to signup
      navigate("/signup");
    }
  }, [state?.mobileNumber]);

  const sendOTPMobile = async (mobileNumber: string) => {
    try {
      setTimeShowing(true);
      const resp = await getOTPByMobileService(mobileNumber);
      if (resp?.data?.success) {
        setItem(AUTH_TOKEN_KEY, resp?.data?.token);
      } else {
        if (resp.status === 422) {
          setCheckOTPLimit(true);
        } else if (resp.status === 500) {
          setCheckInternalError(true);
          setErrorMessage(resp?.data?.message);
        }
      }
    } catch (err) {
      // Handle Error Need to add toast here
    }
  };

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: object({
      otp: string()
        .trim()
        .matches(/^\d{6}$/, "")
        .required(`${t("authentication.otp_is_required")}`),
    }),
    onSubmit: (values: any) => {
      handleFormSubmit();
    },
  });

  const handleFormSubmit = async () => {
    try {
      const resp = await verifyOTPService({
        mobileNumber: String(state?.mobileNumber),
        otp: formik?.values?.otp,
      });
      if (resp.data.success) {
        setItem(AUTH_TOKEN_KEY, resp.data.token);
        navigate("/set-pin", { state: state });
      } else {
        formik.setFieldValue("otp", "");
        setTimeout(() => {
          // TODO: Little hack to bypass required error
          formik.setFieldError("otp", resp.data.message);
        }, 0);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleResendOTP = () => {
    let checkSend = checkOTP - 1;
    setCheckOTP(checkSend);
    sendOTPMobile(state?.mobileNumber);
  };

  const handleTimerComplete = () => {
    setTimeShowing(false);
  };

  const handleOTPChange = (val: string) => {
    formik.setFieldValue("otp", val);
  };

  const otpTemplate = (
    <div className="flex items-center gap-x-4">
      <CountdownTime duration={60} onComplete={handleTimerComplete} />
      <Typography variant="body2">
        {t("authentication.auto_verifying_otp")}
      </Typography>
    </div>
  );

  const sendOTPTemplate = (
    <div className="flex items-center">
      <Typography sx={{ color: "#3777BC" }}>
        {t("authentication.didnt_receive_otp")}
      </Typography>
      <Button
        variant="text"
        sx={{ textTransform: "none" }}
        color="error"
        onClick={handleResendOTP}
      >
        {t("authentication.ask_again_button")}
      </Button>
    </div>
  );

  const blockOTP = (
    <div className="flex items-center">
      <Typography sx={{ color: "#FF0000" }}>
        {t("authentication.check_otp_minute")}
      </Typography>
    </div>
  );

  const limitOTP = (
    <div className="flex items-center">
      <Typography sx={{ color: "#FF0000" }}>
        {t("authentication.limit_otp")}
      </Typography>
    </div>
  );

  const internalErrorOTP = (
    <div className="flex items-center">
      <Typography sx={{ color: "#FF0000" }}>{errorMessage}</Typography>
    </div>
  );

  const messageTemplate = () => {
    if (checkOTP === 0) {
      return blockOTP;
    } else if (checkInternalError) {
      return internalErrorOTP;
    } else if (checkOTPLimit) {
      return limitOTP;
    } else {
      if (timeShowing) {
        return otpTemplate;
      } else {
        return sendOTPTemplate;
      }
    }
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="p-4 h-screen flex flex-col justify-between w-full"
    >
      <AppBarFS
        title={t("authentication.app_header")}
        onIconPress={() => navigate("/signup", { state })}
      />
      <div className="flex flex-col grow mt-8 ">
        <div>
          <Typography variant="body2" textAlign="left">
            {t("authentication.otp_send_text")}
          </Typography>
          <Typography variant="subtitle2" textAlign="left">
            +{THAI_COUNTRY_CODE} {state?.mobileNumber}
          </Typography>
        </div>

        <div className="flex items-center justify-center mt-8 gap-x-4">
          {messageTemplate()}
        </div>
        <div className="flex items-center justify-center mt-8">
          <AuthBox
            alignError="center"
            errorMessage={formErrorMessage(formik, "otp")}
            error={formError(formik, "otp")}
            value={formik?.values?.otp}
            placeholder=""
            onChange={handleOTPChange}
          />
        </div>
      </div>
      <Button
        disabled={!!formik?.errors?.otp || formik?.values?.otp === ""}
        type="submit"
        variant="contained"
        fullWidth
        color="secondary"
        size="large"
      >
        {t("authentication.confirm_button")}
      </Button>
    </form>
  );
}

import { IDownloadUrl, IGetUploadUrl } from "../types/ManageStock.type";
import { IPartnerNewOrderListResponse } from "../types/ParnerOrder.type";
import api from "./ApiService";

export const getNewOrderList = async () => {
  const { data } = await api.get<IPartnerNewOrderListResponse>(
    "/partner/order/upcoming"
  );
  return data;
};

export const getHistoryOrderList = async () => {
  const { data } = await api.get<IPartnerNewOrderListResponse>(
    "/partner/order/history"
  );
  return data;
};

// Hacky way api not provide get single detail yet!
export const getOrderDetail = async (id: string) => {
  const [newOrderList, historyOrderList] = await Promise.all([
    api.get<IPartnerNewOrderListResponse>("/partner/order/upcoming"),
    api.get<IPartnerNewOrderListResponse>("/partner/order/history"),
  ]);

  const detail = [
    ...newOrderList.data.orderFeeds,
    ...historyOrderList.data.orderFeeds,
  ].find(({ id: _id }) => _id === id);
  return detail;
};

// ---------- ACTIONS ------------
export const receiveOrder = async (id: string) => {
  const { data } = await api.put(`partner/order/receive/${id}`);
  return data;
};

export const deliverOrder = async (id: string) => {
  const { data } = await api.put(`partner/order/deliver/${id}`);
  return data;
};

export const completeOrder = async (id: string) => {
  const { data } = await api.put(`partner/order/complete/${id}`);
  return data;
};

export const getUploadUrlOrderPartner = async (
  orderNumber: string,
  documentType: string
) => {
  const { data } = await api.get<IGetUploadUrl>(
    `partner/order/upload/${orderNumber}/${documentType}`
  );
  return data;
};

export const getDownloadUrlOrderPartner = async (
  orderNumber: string,
  documentType: string,
  fileId: string
) => {
  const { data } = await api.get<IDownloadUrl>(
    `partner/order/download/${orderNumber}/${documentType}/${fileId}`
  );
  return data;
};

export const editDocument = async (props: {
  id: string;
  documents: {
    fileId: string;
    fileName: string;
    fileSize: number;
    url: string;
    documentType:
      | "partnerProofOfDelivery"
      | "partnerPickingSlip"
      | "partnerPickingSlipForSale";
  }[];
}) => {
  const { id, documents } = props;
  const { data } = await api.put(`partner/order/document/${id}`, { documents });
  return data;
};

export const closeOrder = async (id: string) => {
  const { data } = await api.put(`partner/order/close/${id}`);
  return data;
};

export const getFarmerContactInfo = async (orderFeedId: string) => {
  return api.get("partner/order/contact/" + orderFeedId);
};

const IconProgramWithBgSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={41} height={42} fill="none">
    <rect width={41} height={41} y={0.129} fill="#DC5B5B" rx={20.5} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M11.047 13.88a2.7 2.7 0 0 1 2.7-2.7h13.5a2.7 2.7 0 0 1 2.7 2.7v10.8a2.7 2.7 0 0 1-2.7 2.7H24.25l.165.66 1.085 1.085a1.35 1.35 0 0 1-.954 2.305h-8.1a1.35 1.35 0 0 1-.955-2.305l1.086-1.085.165-.66h-2.996a2.7 2.7 0 0 1-2.7-2.7v-10.8Zm7.791 9.45H13.747v-9.45h13.5v9.45H18.838Z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconProgramWithBgSvg;

const IconCarWithBgSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={41} height={42} fill="none">
    <rect width={41} height={41} y={0.129} fill="#F57C00" rx={20.5} />
    <mask
      id="a"
      width={27}
      height={28}
      x={7}
      y={7}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M7 7.129h27v27H7z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#fff"
        d="M13.75 29.629c-.938 0-1.734-.328-2.39-.985-.657-.656-.985-1.453-.985-2.39h-2.25V13.879c0-.619.22-1.149.66-1.59.442-.44.971-.66 1.59-.66h15.75v4.5H29.5l3.375 4.5v5.625h-2.25c0 .937-.328 1.734-.984 2.39-.657.657-1.453.985-2.391.985s-1.734-.328-2.39-.985c-.657-.656-.985-1.453-.985-2.39h-6.75c0 .937-.328 1.734-.984 2.39-.657.657-1.453.985-2.391.985Zm0-2.25c.319 0 .586-.108.802-.324.215-.215.323-.482.323-.801 0-.319-.108-.586-.323-.802a1.089 1.089 0 0 0-.802-.323c-.319 0-.586.108-.802.323a1.089 1.089 0 0 0-.323.802c0 .319.108.586.323.801.216.216.483.324.802.324Zm13.5 0c.319 0 .586-.108.802-.324.215-.215.323-.482.323-.801 0-.319-.108-.586-.323-.802a1.089 1.089 0 0 0-.802-.323c-.319 0-.586.108-.802.323a1.089 1.089 0 0 0-.323.802c0 .319.108.586.323.801.216.216.483.324.802.324Zm-1.125-5.625h4.781l-2.531-3.375h-2.25v3.375Z"
      />
    </g>
  </svg>
);
export default IconCarWithBgSvg;

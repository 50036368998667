import CloseIcon from "@mui/icons-material/Close";
import { Fade, Grid, IconButton, Stack } from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { object, string } from "yup";
import ListOfFile from "../../../../components/common/File/ListOfFile";
import ListOfUploadedFile2 from "../../../../components/common/File/ListOfUploadedFile2";
import { loadingActions } from "../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../../redux/store";
import {
  editInvoiceDocumentAPI,
  getInvoiceDownloadUrlAPI,
  getReceiptDownloadUrlAPI,
  uploadInvoiceAPI,
  uploadReceiptPresignAPI,
} from "../../../../services/admin/invoice/invoiceSale.service";
import {
  EditInvoicePayload,
  IGetEditInvoiceDocumentResponse,
} from "../../../../services/admin/invoice/type";
import {
  covertToBlobUrl,
  handleDownloadBlob,
} from "../../../../utils/downloadFile";
import getErrorMessage from "../../../../utils/getErrorMessage";
import ButtonFillCustom from "../../Button/ButtonFillCustom";
import DragAndDropUploadImage from "../../Input/DragAndDropUploadImage";
import TextInputCustom from "../../Input/TextInputCustom";
import ConfirmCustomModal from "../ConfirmCustomModal";
import {
  BoxDetailStack,
  FontTypo,
  FontTypoDetail,
  FontTypoHeader,
  InnerContainer,
  ModalContainer,
} from "../Modal.styled";
import { TInvoiceDoc } from "./type";

export interface UpdateDocumentModalProps {
  handleModalClose: (isSuccess: boolean) => void;
  isOpen: boolean;
  data?: IGetEditInvoiceDocumentResponse["invoice"];
}

const validationSchema = object({
  invoiceNum: string().required(),
});

const UpdateDocumentModal = ({
  handleModalClose,
  isOpen = false,
  data,
}: UpdateDocumentModalProps) => {
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [isDiscard, setIsDiscard] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const invoiceFile = useMemo(() => {
    const findInvoice = data?.invoiceFiles.find(
      (row) => row.documentType === "invoice"
    );
    return findInvoice ? [findInvoice] : [];
  }, [data]);
  const onSubmit = async (values: TInvoiceDoc) => {
    if (data) {
      dispatch(loadingActions.show({ message: "กำลังบันทึกข้อมูล..." }));
      const payload: EditInvoicePayload = {
        invoiceNumber: values.invoiceNum,
        invoice: [],
        receipt: [],
      };
      if (values.invoiceDoc.length > 0) {
        const invoicePreSign = await uploadInvoiceAPI(data.id ?? "");
        if (invoicePreSign) {
          await axios.put(invoicePreSign.blob.blobUrl, values.invoiceDoc[0], {
            responseType: "blob",
            headers: {
              "Content-Type": values.invoiceDoc[0].type,
              "x-ms-blob-type": "BlockBlob",
            },
          });
          payload.invoice.push({
            fileId: invoicePreSign.blob.fileId,
            fileName: values.invoiceDoc[0].name,
          });
        }
      } else {
        if (invoiceFile[0]) {
          payload.invoice.push({
            fileId: invoiceFile[0].id,
            fileName: invoiceFile[0].fileOriginalName,
          });
        }
      }

      await Promise.allSettled(
        values.receipts.map(async (elem, index) => {
          if (elem.realFile[0]) {
            const receiptPreSign = await uploadReceiptPresignAPI("receipt");
            if (receiptPreSign) {
              await axios.put(receiptPreSign.blob.blobUrl, elem.realFile[0], {
                responseType: "blob",
                headers: {
                  "Content-Type": elem.realFile[0].type,
                  "x-ms-blob-type": "BlockBlob",
                },
              });
              payload.receipt.push({
                fileId: receiptPreSign.blob.fileId,
                fileName: elem.realFile[0].name,
                invoiceReceiptId: data.invoiceReceipts[index].id,
              });
            }
          } else {
            if (elem.file[0]) {
              payload.receipt.push({
                fileId: elem.file[0].id,
                fileName: elem.file[0].fileOriginalName,
                invoiceReceiptId: data.invoiceReceipts[index].id,
              });
            }
          }
        })
      );

      try {
        await editInvoiceDocumentAPI(data.id, payload);
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: "บันทึกสำเร็จ",
            type: "success",
          })
        );
      } catch (error) {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }
    }
    formik.resetForm();
    handleModalClose(true);
  };

  const formik = useFormik<TInvoiceDoc>({
    validationSchema,
    initialValues: {
      invoiceNum: data?.invoiceNumber || "",
      invoiceDoc: [],
      receipts: (data?.invoiceReceipts || []).map((row) => ({
        invoiceReceiptId: row.id,
        createdAt: row.createdAt,
        invoiceNumber: row.invoiceNumber,
        paidAmount: row.paidAmount,
        file: row.fileReceipt
          ? [
              {
                id: row.fileReceipt.id,
                documentType: row.fileReceipt.documentType,
                fileOriginalName: row.fileReceipt.fileOriginalName,
              },
            ]
          : [],
        realFile: [],
      })),
    },
    onSubmit,
  });

  const handleClose = () => {
    setIsDiscard(true);
  };

  const handleDiscard = () => {
    setIsDiscard(false);
    formik.resetForm();
    handleModalClose(false);
  };

  const onDownloadInvoice = async (row: {
    id: string;
    fileOriginalName: string;
    documentType: string;
  }) => {
    const response = await getInvoiceDownloadUrlAPI(data?.id || "", row.id);
    if (response) {
      const objectURL = await covertToBlobUrl(response.blob.blobUrl);
      handleDownloadBlob(objectURL, row.fileOriginalName);
    }
  };

  const onDownloadReceipt = async (row: {
    id: string;
    fileOriginalName: string;
    documentType: string;
  }) => {
    const response = await getReceiptDownloadUrlAPI(
      row.documentType || "",
      row.id
    );
    if (response) {
      const objectURL = await covertToBlobUrl(response.blob.blobUrl);
      handleDownloadBlob(objectURL, row.fileOriginalName);
    }
  };

  useEffect(() => {
    if (isOpen) {
      formik.setValues({
        invoiceNum: data?.invoiceNumber || "",
        invoiceDoc: [],
        receipts: (data?.invoiceReceipts || []).map((row) => ({
          invoiceReceiptId: row.id,
          createdAt: row.createdAt,
          invoiceNumber: row.invoiceNumber,
          paidAmount: row.paidAmount,
          file: row.fileReceipt
            ? [
                {
                  id: row.fileReceipt.id,
                  documentType: row.fileReceipt.documentType,
                  fileOriginalName: row.fileReceipt.fileOriginalName,
                },
              ]
            : [],
          realFile: [],
        })),
      });
    }
  }, [isOpen, data]);
  return (
    <ModalContainer
      aria-label="update-document-modal"
      data-testid="updateDocumentModal"
      open={isOpen}
      closeAfterTransition
    >
      <Fade in={isOpen}>
        <InnerContainer width={"820px"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <FontTypo fontFamily={"DM Sans"} fontSize={18} fontWeight={700}>
              เอกสาร
            </FontTypo>
            <IconButton onClick={handleClose} sx={{ padding: 0 }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack direction={"column"} alignItems={"flex-start"} mr={"12px"}>
            <FontTypoDetail>เลข Invoice</FontTypoDetail>
            <TextInputCustom
              style={{ width: "380px" }}
              placeholder="กรอก"
              {...formik.getFieldProps("invoiceNum")}
            />
          </Stack>
          <Stack>
            <FontTypoHeader>ใบ Invoice</FontTypoHeader>
            {formik.values.invoiceDoc.length === 0 && (
              <ListOfUploadedFile2
                files={invoiceFile}
                fileKey="fileOriginalName"
                onDownload={onDownloadInvoice}
              />
            )}
            <ListOfFile
              files={formik.values.invoiceDoc || []}
              onDelete={async () => {
                formik.setFieldValue("invoiceDoc", []);
              }}
            />
            <DragAndDropUploadImage
              onUpload={(files) => {
                formik.setFieldValue("invoiceDoc", files);
              }}
              type={{ "application/pdf": [".pdf"] }}
            />
          </Stack>
          <Stack direction={"column"}>
            <FontTypoHeader mb={"12px"}>ใบเสร็จรับเงิน</FontTypoHeader>
            {formik.values.receipts.map((item, index) => {
              return (
                <BoxDetailStack mb={"12px"} key={`key ${index}`}>
                  <Stack>
                    <Grid container mb={"24px"} rowGap={"12px"}>
                      <Grid item md={4} lg={4}>
                        <FontTypoHeader>วันเเละเวลาที่ชำระเงิน</FontTypoHeader>
                      </Grid>
                      <Grid item md={4} lg={4}>
                        <FontTypoHeader>ยอดเงินที่ได้รับ</FontTypoHeader>
                      </Grid>
                      <Grid item md={4} lg={4}>
                        <FontTypoHeader>เลขที่ใบเสร็จ</FontTypoHeader>
                      </Grid>
                      <Grid item md={4} lg={4}>
                        <FontTypoDetail>
                          {dayjs(item.createdAt).format("DD/MM/YYYY HH:mm")}
                        </FontTypoDetail>
                      </Grid>
                      <Grid item md={4} lg={4}>
                        <FontTypoDetail>{item.paidAmount} บาท</FontTypoDetail>
                      </Grid>
                      <Grid item md={4} lg={4}>
                        <FontTypoDetail>{item.invoiceNumber}</FontTypoDetail>
                      </Grid>
                    </Grid>
                    <FontTypoHeader>ใบเสร็จรับเงิน</FontTypoHeader>
                    {item.realFile.length === 0 && (
                      <ListOfUploadedFile2
                        files={item.file}
                        fileKey={"fileOriginalName"}
                        onDownload={onDownloadReceipt}
                      />
                    )}
                    <ListOfFile
                      files={item.realFile || []}
                      onDelete={async () => {
                        formik.setFieldValue(`receipts[${index}].realFile`, []);
                      }}
                    />
                    <DragAndDropUploadImage
                      onUpload={(files) => {
                        formik.setFieldValue(
                          `receipts[${index}].realFile`,
                          files
                        );
                      }}
                      type={{ "application/pdf": [".pdf"] }}
                    />
                  </Stack>
                </BoxDetailStack>
              );
            })}
          </Stack>
          <Stack direction={"row"} justifyContent={"flex-end"}>
            <ButtonFillCustom
              style={{ width: "63px" }}
              title="ยืนยัน"
              onClick={() => setOpenConfirmModal(true)}
            />
          </Stack>
          <ConfirmCustomModal
            title="ยืนยันการบันทึก"
            subtitleFirstRow="คุณต้องการบันทึกรายการนี้ใช่หรือไม่?"
            buttonPrimaryText="ยืนยัน"
            buttonSecondaryText="กลับไปแก้ไข"
            open={openConfirmModal}
            onClose={() => setOpenConfirmModal(false)}
            onSubmit={async () => {
              setOpenConfirmModal(false);
              formik.handleSubmit();
            }}
          />
          <ConfirmCustomModal
            title="ละทิ้งการเปลี่ยนเเปลง"
            subtitleFirstRow="คุณต้องการละทิ้งรายการนี้ใช่หรือไม่?"
            buttonPrimaryText="ยืนยัน"
            buttonSecondaryText="ยกเลิก"
            open={isDiscard}
            onClose={() => {
              setIsDiscard(false);
            }}
            onSubmit={async () => {
              handleDiscard();
            }}
          />
        </InnerContainer>
      </Fade>
    </ModalContainer>
  );
};
export default UpdateDocumentModal;

import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DefaultErrorImg from "../../../assets/images/ErrorImage/DefaultError.svg";
export default function DefaultError() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <view
      style={{
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        height: "-webkit-fill-available",
      }}
    >
      <div style={{ justifyContent: "center" }}>
        <img src={DefaultErrorImg} alt="DefaultError" />
        <label
          style={{
            justifyContent: "center",
            display: "flex",
            padding: "10px",
            textTransform: "none",
            fontSize: "17px",
            fontWeight: 700,
          }}
        >
          {t("error.defaulterrortop")}
        </label>
        <label
          style={{
            justifyContent: "center",
            display: "flex",
            padding: "10px",
            paddingTop: "0px",
            textTransform: "none",
            fontSize: "15px",
            fontWeight: 400,
          }}
        >
          {t("error.defaulterrorbot")}
        </label>
        <div className="flex flex-col grow gap-4 justify-between">
          <Button
            variant="contained"
            className={`mt-4 h-[59px] 'bg-white-important'`}
            fullWidth
            type={"submit"}
            color="primary"
            size="large"
            onClick={() => {
              navigate("/farms");
            }}
            sx={{ textTransform: "none", fontSize: "17px", fontWeight: 700 }}
          >
            {t("error.homebutton")}
          </Button>
        </div>
      </div>
    </view>
  );
}

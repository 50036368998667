import { Card, CardContent } from "@mui/material";
import { IGetPartnerStock } from "../../../../../types/ManageStock.type";

interface IInfoCard {
  data?: IGetPartnerStock;
}

function PartnerPartialInfoCard(props: IInfoCard) {
  const { data } = props;
  let userName = `${data?.result.name} ${data?.result.surname}`;
  let address = `${data?.result.address} ${data?.result.district.nameTh} ${data?.result.subDistrict.nameTh} ${data?.result.province.nameTh} ${data?.result.subDistrict.zipCode}`;

  return (
    <Card
      variant="outlined"
      sx={{
        background: "#F6FBFF",
        borderRadius: "20px",
        borderColor: "#E2E2E2",
        color: "#1B2128",
        fontSize: "14px",
      }}
    >
      <CardContent className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <p className="font-bold">ชื่อพาร์ทเนอร์</p>
          <p>{userName}</p>
        </div>
        <div className="flex flex-col gap-2">
          <p className="font-bold">ที่อยู่</p>
          <p>{address}</p>
        </div>
      </CardContent>
    </Card>
  );
}

export default PartnerPartialInfoCard;

export default function DollarCoin() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.1 17.5234H10.85V16.2734C11.6833 16.1234 12.4 15.7984 13 15.2984C13.6 14.7984 13.9 14.0568 13.9 13.0734C13.9 12.3734 13.7 11.7318 13.3 11.1484C12.9 10.5651 12.1 10.0568 10.9 9.62344C9.9 9.2901 9.20833 8.99844 8.825 8.74844C8.44167 8.49844 8.25 8.15677 8.25 7.72344C8.25 7.2901 8.40417 6.94844 8.7125 6.69844C9.02083 6.44844 9.46667 6.32344 10.05 6.32344C10.5833 6.32344 11 6.4526 11.3 6.71094C11.6 6.96927 11.8167 7.2901 11.95 7.67344L13.55 7.02344C13.3667 6.4401 13.0292 5.93177 12.5375 5.49844C12.0458 5.0651 11.5 4.82344 10.9 4.77344V3.52344H9.15V4.77344C8.31667 4.95677 7.66667 5.32344 7.2 5.87344C6.73333 6.42344 6.5 7.0401 6.5 7.72344C6.5 8.50677 6.72917 9.1401 7.1875 9.62344C7.64583 10.1068 8.36667 10.5234 9.35 10.8734C10.4 11.2568 11.1292 11.5984 11.5375 11.8984C11.9458 12.1984 12.15 12.5901 12.15 13.0734C12.15 13.6234 11.9542 14.0276 11.5625 14.2859C11.1708 14.5443 10.7 14.6734 10.15 14.6734C9.6 14.6734 9.1125 14.5026 8.6875 14.1609C8.2625 13.8193 7.95 13.3068 7.75 12.6234L6.1 13.2734C6.33333 14.0734 6.69583 14.7193 7.1875 15.2109C7.67917 15.7026 8.31667 16.0401 9.1 16.2234V17.5234ZM10 20.5234C8.61667 20.5234 7.31667 20.2609 6.1 19.7359C4.88333 19.2109 3.825 18.4984 2.925 17.5984C2.025 16.6984 1.3125 15.6401 0.7875 14.4234C0.2625 13.2068 0 11.9068 0 10.5234C0 9.1401 0.2625 7.8401 0.7875 6.62344C1.3125 5.40677 2.025 4.34844 2.925 3.44844C3.825 2.54844 4.88333 1.83594 6.1 1.31094C7.31667 0.785937 8.61667 0.523438 10 0.523438C11.3833 0.523438 12.6833 0.785937 13.9 1.31094C15.1167 1.83594 16.175 2.54844 17.075 3.44844C17.975 4.34844 18.6875 5.40677 19.2125 6.62344C19.7375 7.8401 20 9.1401 20 10.5234C20 11.9068 19.7375 13.2068 19.2125 14.4234C18.6875 15.6401 17.975 16.6984 17.075 17.5984C16.175 18.4984 15.1167 19.2109 13.9 19.7359C12.6833 20.2609 11.3833 20.5234 10 20.5234Z"
        fill="#68C184"
      />
    </svg>
  );
}

import dayjs from "dayjs";

export const getShortDate = (date?: string | number | Date) => {
  const d = dayjs(date);
  if (d.isValid()) {
    const year = +d.year() + 543;
    return d.format(`DD/MM/${year}`);
  }
  return "-";
};

export const dateToString = (date: Date | null | undefined) =>
  date
    ? date.toLocaleDateString("en-GB", {
        timeZone: "Asia/Bangkok",
        day: "2-digit",
        month: "long",
        year: "numeric",
      })
    : "";

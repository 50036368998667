import dayjs from "dayjs";
import { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import AppBarFS from "../../../../components/app-bar/AppBar";
import { IGetMyOrderByID } from "../../../../types/MyOrderFeed.type";
import ReportModal from "../../../report";
import OrderFeedFarmerDetail from "../../my-order/my-order-component/OrderFeedFarmerDetail";

export default function OrderHistoryDetail() {
  const { orderId, myOrderList, farmId } = useLoaderData() as {
    orderId: string;
    myOrderList: IGetMyOrderByID;
    farmId: string;
  };
  const navigate = useNavigate();
  //date
  const receiveDate = dayjs(myOrderList.result.receiveDate);
  const today = new Date();
  const [isOpenReportPage, setIsOpenReportPage] = useState<boolean>(false);

  //status
  const canceled = myOrderList.result.status === "canceled";
  const [toggleResetForm, setToggleResetForm] = useState(false);
  const reject = myOrderList.result.status === "reject";

  return (
    <div className="pb-4 h-screen flex flex-col w-full bg-[#ffff] overflow-scroll">
      <div className="py-4 rounded-b-[40px] bg-white">
        <AppBarFS
          onIconPress={() => navigate(-1)}
          title="รายละเอียดคำสั่งซื้อ"
        />
      </div>
      {canceled || reject ? (
        <OrderFeedFarmerDetail
          primaryButtonText="สั่งซื้ออีกครั้ง"
          isPrimaryDisabled={false}
          onPrimaryClick={() => {
            navigate(
              "/feed-order-and-delivery/order-feed/" +
                farmId +
                "?orderId=" +
                orderId
            );
          }}
          isDisplaySecondaryButton={false}
          isTotalItemPrice={true}
          isTradingDocument={true}
          orderDetail={myOrderList}
        />
      ) : (
        <OrderFeedFarmerDetail
          primaryButtonText="สั่งซื้ออีกครั้ง"
          isPrimaryDisabled={false}
          onPrimaryClick={() => {
            navigate(
              "/feed-order-and-delivery/order-feed/" +
                farmId +
                "?orderId=" +
                orderId
            );
          }}
          secondaryButtonText={
            myOrderList.result.isReportOrder ? "ดูรายงานปัญหา" : "รายงานปัญหา"
          }
          isSecondaryDisabled={Math.abs(receiveDate.diff(today, "day")) >= 6}
          onSecondaryClick={
            myOrderList.result.isReportOrder
              ? () => navigate("/report-detail/" + myOrderList.result.id)
              : () => {
                  setToggleResetForm((prev) => !prev);
                  setIsOpenReportPage(true);
                }
          }
          isDisplaySecondaryButton={true}
          isTotalItemPrice={true}
          isTradingDocument={true}
          orderDetail={myOrderList}
        />
      )}
      <ReportModal
        isOpen={isOpenReportPage}
        onClose={() => setIsOpenReportPage(false)}
        myOrderList={myOrderList}
        toggleResetForm={toggleResetForm}
        setToggleResetForm={setToggleResetForm}
      />
    </div>
  );
}

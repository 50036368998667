import { useMemo, useState } from "react";
import { getVaccine } from "../../services/vaccine.service";
import { IVaccineTable, VaccineRow } from "../../types/vaccine.type";
import { TUseVaccineFilterHook } from "./useVaccineFilter.hook";

export const useVaccineTableHook = (
  searchVaccineFilter: TUseVaccineFilterHook
) => {
  const [list, setList] = useState<IVaccineTable[]>([]);
  const [vaccine, setVaccine] = useState<VaccineRow[] | undefined>([]);
  const [currentPage, setCurrentPage] = useState(1);

  const createData = (
    id: string,
    vaccineNameTH: string,
    vaccineNameEN: string
  ): IVaccineTable => {
    return {
      id,
      vaccineNameTH,
      vaccineNameEN,
    };
  };

  const MapOrderByWithFilter: { [K in keyof IVaccineTable]?: string } = {
    vaccineNameTH: "name",
    vaccineNameEN: "name_en",
  };

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("searchText", searchVaccineFilter.searchText);
    searchParams.append("page", searchVaccineFilter.page.toString());
    searchParams.append("limit", searchVaccineFilter.limit.toString());
    searchParams.append("direction", searchVaccineFilter.direction);
    searchParams.append(
      "orderBy",
      MapOrderByWithFilter[searchVaccineFilter.orderBy] || ""
    );
    setList([]);

    const data = await getVaccine(searchParams);
    if (data?.rows) {
      const newList = data.rows.map((row) => {
        return createData(row.id, row.nameTh, row.nameEn);
      });
      searchVaccineFilter.setCount(data.count);
      setList(newList);
    }
  };
  const handleFetchEditData = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("limit", "999");

    const data = await getVaccine(searchParams);
    setVaccine(data?.rows);
  };

  const tableData = useMemo(() => {
    const listVaccine = vaccine || [];
    const _data = listVaccine;
    if (_data.length === 0) return [];
    const pageSize = 10;
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = currentPage * pageSize;
    return _data.slice(startIndex, endIndex);
  }, [vaccine, currentPage]);

  return {
    list,
    handleFetch,
    handleFetchEditData,
    vaccine,
    tableData: tableData ? tableData : [],
    totalTableSize: vaccine ? vaccine.length : 0,
    currentPage,
    setCurrentPage,
  };
};

import { useQuery } from "@tanstack/react-query";
import { getMyStockPartner } from "../../../services/partner/historyStock.service";
import { useCallback, useState } from "react";

export const useStockSeeDetailsController = () => {
  const [searchText, setSearchText] = useState("");

  const onSearchText = useCallback((e: any) => {
    const text = e.target.value;
    setSearchText(text);
  }, []);

  const onClearSearchText = useCallback(() => {
    setSearchText("");
  }, []);

  const [id, setId] = useState("");

  const { data: GetMyStock } = useQuery({
    queryKey: ["my-stock-partner", searchText],
    queryFn: async () => {
      const result = await getMyStockPartner({
        searchText: searchText,
      });
      return result;
    },
  });

  return {
    GetMyStock,
    searchText,
    onSearchText,
    onClearSearchText,
    id,
    setId,
  };
};

import { useCallback, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import get from "lodash/get";
import { getBrands, getFeeds } from "../../../../services/FeedFoodApiService";
import { TDropdownValue } from "../../group-area/views/Dropdown";

export const useFoodModalController = () => {
  const [brand, setBrand] = useState<TDropdownValue[]>([]);
  const [searchText, setSearchText] = useState("");
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [currentOrderByKey, setCurrentOrderByKey] = useState<
    "name_th" | "sku_th" | "name_en" | "sku_en"
  >("name_th");
  const [orderByType, setOrderByType] = useState<"desc" | "asc">("asc");
  const [currentPage, setCurrentPage] = useState(1);

  const { data: brands } = useQuery({
    queryKey: ["food-feed", "brands", "asc"],
    queryFn: async () => {
      return await getBrands({ direction: "asc" });
    },
  });

  const { data: feedResult, refetch: refetchFeeds } = useQuery({
    queryKey: [
      "food-feed",
      "feeds",
      get(brand, [0, "value"]),
      searchText,
      currentOrderByKey,
      orderByType,
    ],
    queryFn: async () => {
      return await getFeeds({
        direction: orderByType,
        orderBy: currentOrderByKey,
        feedBrandId: get(brand, [0, "value"]),
        searchText,
      });
    },
  });

  const brandsOptions = useMemo(() => {
    return get(brands, ["result"], []).map(({ id, nameEn, nameTh }) => ({
      label: nameTh,
      value: id,
    }));
  }, [brands]);

  const brandsOptionsAdd = useMemo(() => {
    return [
      ...get(brands, ["result"], []).map(({ id, nameEn, nameTh }) => ({
        label: nameTh,
        value: id,
      })),
      { label: "เพิ่มแบรนด์ใหม่", value: "otherOption" },
    ];
  }, [brands]);

  const fullData = feedResult?.listOfFeeds || [];
  const tableData = useMemo(() => {
    const _data = fullData;
    if (_data.length === 0) return [];
    const pageSize = 10;
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = currentPage * pageSize;
    return _data.slice(startIndex, endIndex);
  }, [fullData, currentPage]);

  const onSearch = useCallback(() => {}, []);

  const onSearchText = useCallback((e: any) => {
    const text = e.target.value;
    setSearchText(text);
  }, []);

  const onClearSearchText = useCallback((e: any) => {
    setSearchText("");
  }, []);

  const onShowAddModal = useCallback(() => {
    setIsShowAddModal(true);
  }, []);

  const onHideAddModal = useCallback(() => {
    setIsShowAddModal(false);
  }, []);

  const onShowEditModal = useCallback(() => {
    setIsShowEditModal(true);
  }, []);

  const onHideEditModal = useCallback(() => {
    setIsShowEditModal(false);
  }, []);

  return {
    feedResult,
    brandsOptions,
    brandsOptionsAdd,
    brands,
    brand,
    setBrand,
    onSearch,
    searchText,
    onSearchText,
    onClearSearchText,
    isShowAddModal,
    onShowAddModal,
    onHideAddModal,
    isShowEditModal,
    onShowEditModal,
    onHideEditModal,
    currentOrderByKey,
    setCurrentOrderByKey,
    orderByType,
    setOrderByType,
    tableData,
    currentPage,
    setCurrentPage,
    totalTableSize: fullData.length,
    refetchFeeds,
  };
};

import { Table } from "@mui/material";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { TableType } from "./types";
const HousePerformanceTable = ({ tableData, tableHeadData }: TableType) => {
  const language = localStorage.getItem("i18nextLng");
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#D9EBFE",
      color: "#4B4B4B",
      height: "100px",
      "&:first-of-type": {
        borderRadius: "20px 0px 0px 0px",
      },
      "&:last-child": {
        borderRadius: "0px 20px 0px 0px",
      },
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: 400,
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f6faff",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <div className="housePerformanceContainer">
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: "0px",
          boxShadow: "none",
          background: "transparent",
        }}
        className="tableContainer"
      >
        <Table aria-label="customized table" className="content">
          <TableHead>
            <TableRow className="!flex">
              {tableHeadData &&
                tableHeadData.map((item, index) => (
                  <StyledTableCell
                    align="center"
                    key={index}
                    sx={{
                      fontSize: "14px",
                      whiteSpace: "break-spaces",
                      fontWeight: "700",
                      display: "block",
                      width: "150px",
                    }}
                    className="!flex items-center justify-center"
                  >
                    {language === "th" ? (
                      <>{item?.nameTh}</>
                    ) : (
                      <>{item?.nameEn}</>
                    )}
                  </StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData.map((item, index) => (
                <StyledTableRow key={index} className="bg-[#fff] !flex">
                  {/* <StyledTableCell component="th" scope="row" align="center" sx={{ color: "#3777BC" }}>{dayjs(item?.logDate).format('DD-MM-YYYY')}</StyledTableCell> */}
                  <StyledTableCell
                    align="center"
                    sx={{ color: "#3777BC", display: "block", width: "150px" }}
                    className="!flex items-center justify-center"
                  >
                    {dayjs(item?.logDate).format("DD-MM-YYYY")}
                  </StyledTableCell>

                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#4B4B4B",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "165px",
                    }}
                    className="houseperformanceTableRow"
                  >
                    {item?.house?.pigAge}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ color: "#3777BC" }}
                    className="houseperformanceTableRow"
                  >
                    {item.feedIntake}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ color: "#3777BC" }}
                    className="houseperformanceTableRow"
                  >
                    {item.feed_brand?.nameTh || ""}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ color: "#3777BC" }}
                    className="houseperformanceTableRow"
                  >
                    {item.feed_brand_item?.skuTh || ""}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#4B4B4B",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "165px",
                    }}
                    className="houseperformanceTableRow"
                  >
                    {index > 0 ? (
                      <>
                        {tableData[index - 1].feedIntake * 7 +
                          item?.feedIntake * 7}{" "}
                      </>
                    ) : (
                      <>{item?.feedIntake * 7}</>
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ color: "#3777BC" }}
                    className="houseperformanceTableRow"
                  >
                    {" "}
                    {item.deadPigCount}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#4B4B4B",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "165px",
                    }}
                    className="houseperformanceTableRow"
                  >
                    {index > 0 ? (
                      <>
                        {tableData[index - 1].deadPigCount + item?.feedIntake}{" "}
                      </>
                    ) : (
                      <>{item?.feedIntake}</>
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ color: "#3777BC" }}
                    className="houseperformanceTableRow"
                  >
                    {language === "th" ? (
                      <>{item?.vaccineName?.nameTh}</>
                    ) : (
                      <>{item?.vaccineName?.nameEn}</>
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#4B4B4B",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "165px",
                    }}
                    className="houseperformanceTableRow"
                  >
                    {item?.vaccinePigCount}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ color: "#3777BC" }}
                    className="houseperformanceTableRow"
                  >
                    {item?.medicineNames && item?.medicineNames.join(", ")}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default HousePerformanceTable;

const IconFlashWithBgSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={41} height={42} fill="none">
    <rect width={41} height={41} y={0.129} fill="#F79836" rx={20.5} />
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="M21.42 7.23c-.064.027-.175.132-.249.227-.11.148-8.727 15.225-8.964 15.689-.143.274-.117.474.09.68l.178.18h3.723c2.047 0 3.724.015 3.724.042 0 .02-.211 2.104-.475 4.624-.258 2.521-.475 4.689-.475 4.81 0 .443.46.727.818.5.126-.078 1.091-1.755 4.614-8.03 3.212-5.717 4.461-7.985 4.472-8.127.02-.169-.005-.227-.164-.385l-.184-.185h-3.776c-3.328 0-3.776-.01-3.776-.079 0-.042.274-2.162.607-4.709.332-2.547.606-4.693.606-4.772 0-.317-.464-.596-.77-.464Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M7 7.129h27v27H7z" />
      </clipPath>
    </defs>
  </svg>
);
export default IconFlashWithBgSvg;

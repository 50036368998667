import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Table, TableProps } from "@mui/material";

export interface IDataTable {
  detailName?: number | string | null;
  detailValue?: Date | number | string | null;
  unit?: string | null | undefined;
  doFormat?: boolean | null | undefined;
  color?: string;
  fontSize?: string;
}
export interface IBasicDetailTableProps {
  tableData: IDataTable[];
  tableProps?: TableProps;
  borderCell?: string;
  swapCellColor?: boolean;
  isNewFormat?: boolean;
  showBottomLine?: boolean;
}
const BasicDetailTable = ({
  tableData,
  tableProps,
  borderCell,
  swapCellColor,
  isNewFormat,
  showBottomLine,
}: IBasicDetailTableProps) => {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#D9EBFE",
      color: "black",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: 500,
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: `${swapCellColor ? "#ffffff" : "#f6faff"}`,
    },
    "&:nth-of-type(even)": {
      backgroundColor: `${swapCellColor ? "#f6faff" : "#ffffff"}`,
    },
    "&:last-child td, &:last-child th": {
      ...(showBottomLine ? {} : { border: 0 }),
    },
  }));

  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: "0px", boxShadow: "none" }}
    >
      <Table aria-label="customized table" {...tableProps}>
        <TableBody>
          {tableData &&
            tableData.map((item: IDataTable, index: number) => (
              <StyledTableRow key={index}>
                <StyledTableCell
                  align="left"
                  sx={{
                    color: "#4B4B4B",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "165px",
                    fontSize: "1rem",
                    ...(borderCell ? { border: borderCell } : {}),
                    ...(item?.fontSize ? { fontSize: item.fontSize } : {}),
                    ...(isNewFormat
                      ? { borderBottom: "1px solid rgba(55, 119, 188, 0.25)" }
                      : {}),
                  }}
                >
                  {isNewFormat ? (
                    <span className="font-normal">{item?.detailName}</span>
                  ) : (
                    item?.detailName
                  )}
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="right"
                  sx={{
                    color: "#3777BC",
                    fontSize: "1rem",
                    ...(item?.fontSize ? { fontSize: item.fontSize } : {}),
                    ...(item?.color ? { color: item.color } : {}),
                    ...(borderCell ? { border: borderCell } : {}),
                    ...(isNewFormat
                      ? { borderBottom: "1px solid rgba(55, 119, 188, 0.25)" }
                      : {}),
                  }}
                >
                  {isNewFormat ? (
                    <span className="font-normal">
                      {item?.doFormat
                        ? item?.detailValue?.toLocaleString()
                        : String(item?.detailValue)}
                      {`${item?.unit ? ` ${item?.unit}` : ""}`}
                    </span>
                  ) : (
                    (item?.doFormat
                      ? item?.detailValue?.toLocaleString()
                      : String(item?.detailValue)) +
                    `${item?.unit ? ` ${item?.unit}` : ""}`
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default BasicDetailTable;

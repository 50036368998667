import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Modal,
  Typography,
} from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { cloneDeep, get, set } from "lodash";
import { Row } from "../../../../types/Invoice.type";
import { tw } from "../../../../utils/tw";
import AppBarFS from "../../../../components/app-bar/AppBar";
import { getCommaNumber } from "../../../../utils/number";
import FenceSVG from "../../../../assets/svg/Fence";
import Table from "./Table";
import ButtonFillCustom from "../../../../admin/components/Button/ButtonFillCustom";
import Dialog from "../../../order-history/views/Dialog";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { loadingActions } from "../../../../redux/slices/loadingSlice";
import { acceptedAssignment } from "../../../../services/Invoice.service";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../utils/getErrorMessage";
import { useNavigate } from "react-router-dom";
import { useAcceptedAssignmentController } from "../../details-assignment/controller";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface IDetailsAssignmentModal {
  isOpen: boolean;
  onClose: () => void;
  data: Row;
}

const classes = {
  wrapper: tw(`overflow-scroll`),
  container: tw(`w-full h-full bg-[white] relative`),
  headerContainer: tw(`h-[120px] flex items-center`),
  detailContainer: tw(
    `flex flex-col w-full h-auto pt-[16px] gap-[16px] px-[16px]`
  ),
  buttonContainer: tw(``),
  button: tw(`!h-[59px] w-full !rounded-[50px] !text-[16px] !font-bold`),
  text1: tw(`font-semibold text-[20px] text-[#3777BC]`),
  text2: tw(`font-semibold text-[16px] text-[#34407B]`),
  text3: tw(`font-medium text-[14px] text-[#4B4B4B]`),
};

const DetailsAssignmentModal = (props: IDetailsAssignmentModal) => {
  const { isOpen, onClose, data } = props;
  const { refetchAcceptedAssignment } = useAcceptedAssignmentController();
  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();
  let navigate = useNavigate();

  const handleClickToAcceptedAssignment = useCallback(() => {
    navigate("/partner/track-debt/accepted-assignment");
  }, []);

  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

  const onShowConfirm = useCallback(() => {
    setIsShowConfirmModal(true);
  }, []);

  const onCloseConfirm = useCallback(() => {
    setIsShowConfirmModal(false);
  }, []);

  const AcceptAssignmentMutation = useMutation({
    mutationFn: acceptedAssignment,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["list_accepted_assignment"],
      });
      setTimeout(() => {
        dispatch(loadingActions.hide());
        handleClickToAcceptedAssignment();
        dispatch(
          snakeActions.showMessage({
            message: "รับรายการสำเร็จ",
            type: "success",
          })
        );
      }, 1500);
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const onAcceptAssignment = useCallback(async () => {
    dispatch(loadingActions.show({ message: "กำลังรับรายการ..." }));
    AcceptAssignmentMutation.mutate({
      assignmentId: data.id,
    });
  }, [data]);

  const onCloseFn = useCallback(() => {
    onClose();
  }, []);

  return (
    <Modal open={isOpen} className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <AppBarFS
            title={`${get(data, ["farmerName"], "")}`}
            onIconPress={onCloseFn}
          />
        </div>
        <div className={classes.detailContainer}>
          <div className={classes.text1}>หนี้สินทั้งหมด</div>
          <div className="flex flex-row justify-between">
            <div className={tw(classes.text1, "text-[#34407B]")}>
              ยอดทั้งหมด
            </div>
            <div
              className={tw(
                classes.text1,
                "text-[18px] text-[#34407B] font-bold"
              )}
            >
              {getCommaNumber(get(data, ["totalPrice"], 0), true)} บาท
            </div>
          </div>
          <div className="flex flex-row gap-2">
            <FenceSVG />
            <div className={classes.text2}>
              {get(data, ["assignmentNumber"], "")}
            </div>
          </div>
          <Table data={get(data, ["invoices"], [])} />
        </div>
        <div className="absolute top-[684px] px-4 w-full">
          <ButtonFillCustom
            title="รับรายการ"
            className={classes.button}
            onClick={onShowConfirm}
          />
        </div>
        <Dialog
          isOpen={isShowConfirmModal}
          withoutCloseIcon
          title={`ยืนยันการรับรายการ`}
          text={`คุณเเน่ใจหรือไม่ว่าต้องการรับรายการนี้?\nการดำเนินการนี้ไม่สามารถยกเลิกได้`}
          confirmText="ยืนยัน"
          cancelText="ยกเลิก"
          onClose={onCloseConfirm}
          onConfirm={onAcceptAssignment}
          onCancel={onCloseConfirm}
        />
      </div>
    </Modal>
  );
};

export default memo(DetailsAssignmentModal);

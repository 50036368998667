const ContractActive = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={77} height={77} fill="none">
    <rect width={77} height={77} fill="#5572EB" rx={20} />
    <path
      fill="#fff"
      d="M36.25 20.5a2.25 2.25 0 0 0 0 4.5h4.5a2.25 2.25 0 0 0 0-4.5h-4.5Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M25 27.25a4.5 4.5 0 0 1 4.5-4.5 6.75 6.75 0 0 0 6.75 6.75h4.5a6.75 6.75 0 0 0 6.75-6.75 4.5 4.5 0 0 1 4.5 4.5V52a4.5 4.5 0 0 1-4.5 4.5h-18A4.5 4.5 0 0 1 25 52V27.25Zm21.841 12.841a2.25 2.25 0 1 0-3.182-3.182l-7.409 7.409-2.909-2.909a2.25 2.25 0 1 0-3.182 3.182l4.5 4.5a2.25 2.25 0 0 0 3.182 0l9-9Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ContractActive;

import api from "./ApiService";
import {
  IGetBasicAccountingByMount,
  IGetExportGraphYearAll,
  IGetExportGraphYearToMonth,
  IGetExportList,
  IGetExportPerFlockToMonth,
  IGetExportTwoGraphMonth,
  IGetExportTwoGraphYear,
  IGetGraphMonthBasicAccounting,
  IGetGraphYearBasicAccounting,
  IGetHouseDetailsPerFlock,
  IGetListPerFlock,
  IGetListPerMonth,
  IGetSummaryBasicAccounting,
} from "../types/BasicAccounting.type";

export const getBasicAccountingByMonth = async (params: {
  christianYear?: number;
  month?: string;
  farmId: string;
}) => {
  const { data } = await api.get<IGetBasicAccountingByMount>(
    `/farmers/accounting`,
    {
      params: {
        christianYear: params.christianYear,
        month: params.month,
        farmId: params.farmId,
      },
    }
  );
  return data;
};

export const getSummaryBasicAccounting = async (params: {
  christianYear: number;
  months?: string;
  farmId: string;
}) => {
  const { data } = await api.get<IGetSummaryBasicAccounting>(
    `/farmers/accounting/summary`,
    {
      params: {
        christianYear: params.christianYear,
        months: params.months,
        farmId: params.farmId,
      },
    }
  );
  return data;
};

export const saveAccounting = async (body: {
  farmId: string;
  christianYear: number;
  month: number;
  incomeFromPigSelling: number | null;
  geneticBill: number | null;
  feedBill: number | null;
  vaccineAndMedicineBill: number | null;
  staffBill: number | null;
  waterBill: number | null;
  electricityBill: number | null;
  transportationBill: number | null;
  programBill: number | null;
  otherBill: number | null;
}) => {
  const { data } = await api.put(`/farmers/accounting`, body);
  return data;
};

export const getGraphYearBasicAccounting = async (params: {
  farmId: string;
}) => {
  const { data } = await api.get<IGetGraphYearBasicAccounting>(
    `/farmers/accounting/summary/graph/annually`,
    {
      params: {
        farmId: params.farmId,
      },
    }
  );
  return data;
};

export const getGraphMonthBasicAccounting = async (params: {
  farmId: string;
  christianYear: number;
}) => {
  const { data } = await api.get<IGetGraphMonthBasicAccounting>(
    `/farmers/accounting/summary/graph/monthly`,
    {
      params: {
        farmId: params.farmId,
        christianYear: params.christianYear,
      },
    }
  );
  return data;
};

// "geneticBill" = ค่าพันธุ์สัตว์
//   "feedBill" = ค่าอาหาร
//   "vaccineAndMedicineBill" = ค่ายาเเละวัคซีน
//   "staffBill" = ค่าพนักงาน
//   "waterBill" = ค่าน้ำ
//   "electricityBill" = ค่าไฟ
//   "transportationBill" = ค่าขนส่ง
//   "programBill" = ค่าโปรแกรม
//   "otherBill" = ค่าใช้จ่ายอื่นๆ

export const getGraphMonthBasicAccountingWithFitter = async (params: {
  farmId: string;
  christianYear: number;
  expenseTypes?: string;
}) => {
  const { data } = await api.get<IGetGraphMonthBasicAccounting>(
    `/farmers/accounting/statement/graph/monthly`,
    {
      params: {
        farmId: params.farmId,
        christianYear: params.christianYear,
        expenseTypes: params.expenseTypes,
      },
    }
  );
  return data;
};

export const getListPerFlock = async (params: {
  farmId: string;
  christianYear: number;
  startDate?: Date;
  endDate?: Date;
}) => {
  const { data } = await api.get<IGetListPerFlock>(
    `/farmers/accounting/list/per-flock`,
    {
      params: {
        farmId: params.farmId,
        christianYear: params.christianYear,
        startDate: params.startDate,
        endDate: params.endDate,
      },
    }
  );
  return data;
};

export const getListPerMonth = async (params: {
  farmId: string;
  christianYear: number;
  startDate?: Date;
  endDate?: Date;
}) => {
  const { data } = await api.get<IGetListPerMonth>(
    `/farmers/accounting/list/per-month`,
    {
      params: {
        farmId: params.farmId,
        christianYear: params.christianYear,
        startDate: params.startDate,
        endDate: params.endDate,
      },
    }
  );
  return data;
};

export const getHouseDetailsPerFlockBasicAccounting = async (params: {
  farmId: string;
  houseId: string;
}) => {
  const { data } = await api.get<IGetHouseDetailsPerFlock>(
    `/farmers/accounting/detail/per-flock`,
    {
      params: {
        farmId: params.farmId,
        houseId: params.houseId,
      },
    }
  );
  return data;
};

export const getHouseDetailsPerMonthBasicAccounting = async (params: {
  farmId: string;
  houseId: string;
  month: number;
  christianYear: number;
}) => {
  const { data } = await api.get<IGetHouseDetailsPerFlock>(
    `/farmers/accounting/detail/per-month`,
    {
      params: {
        farmId: params.farmId,
        houseId: params.houseId,
        month: params.month,
        christianYear: params.christianYear,
      },
    }
  );
  return data;
};

export const getExportList = async (
  farmId: string,
  startDate?: Date,
  endDate?: Date
) => {
  try {
    const { data } = await api.get<IGetExportList>(
      `/farmers/accounting/list/per-month/export`,
      {
        params: {
          farmId,
          startDate,
          endDate,
        },
      }
    );
    return data.response;
  } catch (error) {
    return null;
  }
};

export const getExportPerFlockToMonth = async (
  farmId: string,
  startDate?: Date,
  endDate?: Date
) => {
  try {
    const { data } = await api.get<IGetExportPerFlockToMonth>(
      `/farmers/accounting/list/per-flock/export`,
      {
        params: {
          farmId,
          startDate,
          endDate,
        },
      }
    );
    return data.response;
  } catch (error) {
    return null;
  }
};

export const getExportGraphYearAll = async (farmId: string) => {
  try {
    const { data } = await api.get<IGetExportGraphYearAll>(
      `/farmers/accounting/summary/graph/annually/export`,
      {
        params: {
          farmId,
        },
      }
    );
    return data.response;
  } catch (error) {
    return null;
  }
};

export const getExportGraphYearToMonth = async (
  farmId: string,
  christianYear: number
) => {
  try {
    const { data } = await api.get<IGetExportGraphYearToMonth>(
      `/farmers/accounting/summary/graph/monthly/export`,
      {
        params: {
          farmId,
          christianYear,
        },
      }
    );
    return data.response;
  } catch (error) {
    return null;
  }
};

export const getExportTwoGraphYear = async (farmId: string) => {
  try {
    const { data } = await api.get<IGetExportTwoGraphYear>(
      `/farmers/accounting/statement/graph/annually/export`,
      {
        params: {
          farmId,
        },
      }
    );
    return data.response;
  } catch (error) {
    return null;
  }
};

export const getExportTwoGraphMonth = async (
  farmId: string,
  christianYear: number
) => {
  try {
    const { data } = await api.get<IGetExportTwoGraphMonth>(
      `/farmers/accounting/statement/graph/monthly/export`,
      {
        params: {
          farmId,
          christianYear,
        },
      }
    );
    return data.response;
  } catch (error) {
    return null;
  }
};

import { TextField, TextFieldProps } from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import TextInputFS from "./TextInput";
import TextInputForDatePickerFS from "./TextInputForDatePickerFS";

export default function CustomTextDate(props: any) {
  const customParsing = useCallback(() => {
    const customDate =
      props?.ownerState?.value?.format(props?.ownerState?.format) || "";
    return customDate;
  }, [props?.ownerState?.value]);

  return <TextInputForDatePickerFS {...props} value={customParsing()} />;
}

const CloudUploadSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={19} fill="none">
    <path
      stroke="#68C184"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6.833 13.458a3.958 3.958 0 0 1-.725-7.85 3.96 3.96 0 0 1 7.784 0 3.96 3.96 0 0 1-.725 7.85M7.625 9.5 10 7.125m0 0L12.375 9.5M10 7.125v9.5"
    />
  </svg>
);
export default CloudUploadSvg;

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { ChangeEvent, useState } from "react";
import { IAuthInput } from "./types";

const LabeledInput = ({
  placeHolder,
  inputValue,
  type,
  disabled,
  onChange,
  labelName,
  required,
  errorMessage,
  pattern,
  HelperTextMsg,
  datatestid,
}: IAuthInput) => {
  const [isFocused, setIsFocused] = useState(false);

  const matchPattern = (inputValue: string, regexPattern?: RegExp): boolean => {
    if (regexPattern && regexPattern.test(inputValue)) {
      return inputValue.match(regexPattern) ? true : false;
    } else {
      return false;
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const changeValue = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (pattern) {
      const isPatternMatched = matchPattern(newValue, pattern);
      setIsFocused(false);
      onChange(e);
      if (newValue.length === 0 || !isPatternMatched) {
        setIsFocused(true);
      }
    } else {
      setIsFocused(false);
      onChange(e);
    }
  };

  return (
    <div className="flex items-center justify-center flex-col">
      <TextField
        onFocus={handleFocus}
        onBlur={handleBlur}
        id="outlined-required"
        label={labelName}
        disabled={disabled}
        value={inputValue}
        placeholder={placeHolder}
        onChange={changeValue}
        required={required}
        error={
          isFocused && inputValue && !matchPattern(inputValue, pattern)
            ? true
            : false
        }
        inputProps={{ "data-testid": datatestid }}
        InputProps={{
          style: {
            borderRadius: "28px",
            width: "328px",
            height: "56px",
            borderColor: matchPattern(inputValue, pattern)
              ? "#3777BC !important"
              : "#ff0000",
          },
          endAdornment: (
            <InputAdornment position="end">
              {matchPattern(inputValue, pattern) && type == "checked" && (
                <CheckCircleOutlineIcon sx={{ color: "#68C184" }} />
              )}
            </InputAdornment>
          ),
        }}
        style={{
          borderColor: matchPattern(inputValue, pattern)
            ? "#3777BC"
            : "#ff0000",
        }}
        className="bg-[#F7FBFF] rounded-[28px] border-[1px] border-solid border-[#3777BC] py-[8px] px-[0px] flex flex-row justify-center items-center w-[328px]"
      />
      {(inputValue && matchPattern(inputValue, pattern)) ||
      inputValue.length == 0 ? (
        <label className="font-[400] text-[14px] font-['IBM Plex Sans Thai'] font-normal text-[#707070] flex px-[15px] w-[328px]">
          {HelperTextMsg}
        </label>
      ) : (
        <label className="error font-[400] text-[14px] font-['IBM Plex Sans Thai'] font-normal text-[#707070] flex px-[15px] w-[328px]">
          {errorMessage}
        </label>
      )}
    </div>
  );
};

export default LabeledInput;

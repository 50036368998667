import { useNavigate } from "react-router-dom";
import { IDataClaimOrder } from "../../../../../../types/ClaimOrder.type";
import ButtonLinkCustom from "../../../../../components/Button/ButtonLinkCustom";
import ButtonOutlinedCustom from "../../../../../components/Button/ButtonOutlinedCustom";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import { getCommaNumber } from "../../../../../../utils/number";
import { get } from "lodash";
import TableBasicCustom, {
  ITableBasicCustomHeadCell,
  StyledTableBasicCustomCell,
  StyledTableBasicCustomRow,
} from "../../../../../components/Table/TableBasicCustom";
import { UnitEnum } from "../../../../../../types/FeedBrand.type";

interface IOrderDetail {
  data: IDataClaimOrder;
  showRejectBtn?: boolean;
  showApproveBtn?: boolean;
  showDeliveryBtn?: boolean;
  showManageStockBtn?: boolean;
  ShowEditOrderBtn?: boolean;
  disableDeliveryBtn?: boolean;
}

const tableHeaders: ITableBasicCustomHeadCell[] = [
  {
    disablePadding: false,
    label: "",
    width: "60px",
  },
  {
    disablePadding: false,
    label: "เเบรนด์อาหารสัตว์",
    width: "120px",
  },
  {
    disablePadding: false,
    label: "เบอร์อาหารสัตว์",
    width: "100px",
  },
  {
    disablePadding: false,
    label: "ราคาต่อหน่วย",
    width: "100px",
  },
  {
    disablePadding: false,
    label: "จำนวน",
    width: "80px",
  },
  {
    disablePadding: false,
    label: "ราคารวม",
    width: "110px",
  },
  // {
  //   disablePadding: false,
  //   label: "สถานะ(รอ stock)",
  //   width: "120px",
  // },
];

const OrderDetail = (props: IOrderDetail) => {
  const {
    data,
    showRejectBtn,
    showApproveBtn,
    showDeliveryBtn,
    showManageStockBtn,
    ShowEditOrderBtn,
    disableDeliveryBtn,
  } = props;

  const navigate = useNavigate();

  return (
    <div className="relative h-full w-full pt-6">
      <div className="flex flex-row justify-between align-middle items-center">
        <span className="font-sans font-bold text-[20px] text-[#68C184]">
          รายละเอียดออเดอร์
        </span>
        <div className="flex flex-row gap-2">
          <div className="pr-6 self-center">
            {showManageStockBtn && (
              <ButtonLinkCustom
                title="จัดการสต๊อก"
                onClick={() =>
                  navigate("/admin/manage-stock/" + data.partnerId)
                }
              />
            )}
          </div>
          {ShowEditOrderBtn && (
            <ButtonOutlinedCustom
              title="แก้ไขออเดอร์"
              btnTextColor="#68C184"
              btnBorderColor="#68C184"
              disabled={data.status === "delivery"}
              //   onClick={() => setIsOpenEditOrderModal(true)}
            />
          )}
          {showRejectBtn && (
            <ButtonFillCustom
              //   onClick={() => setOpenRejectModal(true)}
              title="ไม่อนุมัติ"
              btnTextColor="#ffffff"
              btnBgColor="#D32F2F"
            />
          )}
          {showApproveBtn && (
            <ButtonFillCustom
              //   onClick={() => setOpenApproveModal(true)}
              title="อนุมัติ"
              btnTextColor="#ffffff"
              btnBgColor="#68C184"
            />
          )}
          {showDeliveryBtn &&
            (data.status === "delivery" ? (
              <ButtonFillCustom
                // onClick={() => setOpenDeliverySuccessModal(true)}
                disabled={disableDeliveryBtn}
                title="จัดส่งสำเร็จ"
                btnTextColor="#ffffff"
                btnBgColor="#68C184"
              />
            ) : (
              <ButtonFillCustom
                // onClick={() => setOpenDeliveryModal(true)}
                disabled={disableDeliveryBtn}
                title="จัดส่งสินค้า"
                btnTextColor="#ffffff"
                btnBgColor="#68C184"
              />
            ))}
        </div>
      </div>
      <div className="pt-6 w-[264px] min-w-[264px] flex justify-between">
        <span className="font-sans font-bold text-[14px] text-[#1B2128]">
          ราคารวมทั้งหมด
        </span>
        <span className="font-sans font-normal text-[14px] text-[#1B2128]">
          {getCommaNumber(+get(data, ["totalFeedPrice"], 0), true)} บาท
        </span>
      </div>
      <div className="pt-6 w-full">
        <TableBasicCustom
          headerItems={tableHeaders}
          tableBodyChildren={data.orderFeedItems.map((item, index) => (
            <StyledTableBasicCustomRow key={`row-${index}`}>
              <StyledTableBasicCustomCell
                align="left"
                sx={{
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "19px",
                }}
              >
                {index + 1}.
              </StyledTableBasicCustomCell>
              <StyledTableBasicCustomCell
                align="left"
                sx={{
                  fontFamily: "IBM Plex Sans Thai",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "19px",
                }}
              >
                {item.feedBrand.nameTh}
              </StyledTableBasicCustomCell>

              <StyledTableBasicCustomCell
                align="left"
                sx={{
                  fontFamily: "IBM Plex Sans Thai",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "19px",
                }}
              >
                {item.feedBrandItem.skuTh}
              </StyledTableBasicCustomCell>

              <StyledTableBasicCustomCell
                align="left"
                sx={{
                  fontFamily: "IBM Plex Sans Thai",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "19px",
                }}
              >
                {`${item.feedBrandItem.pricePerUnit} บาท`}
              </StyledTableBasicCustomCell>

              <StyledTableBasicCustomCell
                align="left"
                sx={{
                  fontFamily: "IBM Plex Sans Thai",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "19px",
                }}
              >
                {`${item.feedAmount} ${
                  UnitEnum[item.feedBrandItem.unit as keyof typeof UnitEnum]
                }`}
              </StyledTableBasicCustomCell>

              <StyledTableBasicCustomCell
                align="left"
                sx={{
                  fontFamily: "IBM Plex Sans Thai",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "19px",
                }}
              >
                {`${getCommaNumber(
                  get(item, ["totalItemPrice"], 0),
                  true
                )} บาท`}
              </StyledTableBasicCustomCell>
              {/*TODO : role leading */}
              {/* <StyledTableBasicCustomCell align="left">
                  <ChipOrderStatus
                    label={OrderStockStatusMap[item.status].label}
                    fontColor={OrderStockStatusMap[item.status].color}
                    bgColor={OrderStockStatusMap[item.status].bgColor}
                  />
                </StyledTableBasicCustomCell> */}
            </StyledTableBasicCustomRow>
          ))}
        />
      </div>
    </div>
  );
};

export default OrderDetail;

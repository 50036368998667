import { Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import { NoChartDataPig } from "../../../assets/svg/NoDataChartPig";
import pigLogo from "../../../assets/images/pigLogo.svg";

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

export default function PigCountPieChart({ dataset, colorCodes }: any) {
  return (
    <div className="flex items-center w-28 h-28 grow justify-center relative">
      {dataset?.length > 0 && (
        <>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={70} height={70}>
              <Pie
                activeIndex={0}
                activeShape={renderActiveShape}
                data={dataset}
                cx="50%"
                cy="50%"
                innerRadius={30}
                outerRadius={40}
                fill="#8884d8"
                dataKey="value"
              />
            </PieChart>
          </ResponsiveContainer>
          <img
            src={pigLogo}
            alt="pig-logo"
            width={"50px"}
            height={"auto"}
            className="absolute"
          />
        </>
      )}

      {!dataset?.length && <NoChartDataPig />}
    </div>
  );
}

import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
} from "@mui/material";
import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import CloseIcon from "@mui/icons-material/Close";
import TextInputCustom from "../../../../admin/components/Input/TextInputCustom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Input } from "../../../../admin/pages/group-area/views/Input";
import { tw } from "../../../../utils/tw";
import { TFormNewContract } from "../DefaultFormNewContract";
import { IContracts } from "../../../../types/Financial.type";
import { get } from "lodash";
import { getCommaNumber } from "../../../../utils/number";
import ButtonFillCustom from "../../../../admin/components/Button/ButtonFillCustom";

interface IEditCountPigModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  form: TFormNewContract;
  setForm: Dispatch<SetStateAction<TFormNewContract>>;
  onChangeForm: (key: keyof TFormNewContract) => (value: any) => void;
  dataEdit?: IContracts;
}

const EditCountPigModal = ({
  isOpen,
  onClose,
  onSubmit,
  form,
  setForm,
  onChangeForm,
  dataEdit,
}: IEditCountPigModalProps) => {
  useEffect(() => {
    onChangeForm("pigCount")(dataEdit?.pigAmount);
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      disableEnforceFocus={false}
      disableAutoFocus={true}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "313px",
          minWidth: "313px",
          bgcolor: "#000",
          borderRadius: "40px",
          boxShadow: 24,
        }}
      >
        <div className="flex flex-col relative px-6 pt-4 pb-6 gap-4 bg-white w-full h-full rounded-[40px]">
          <div className="flex flex-row justify-end">
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                padding: 0,
              }}
            >
              <CloseIcon sx={{ color: "#000" }} />
            </IconButton>
          </div>
          <div className="text-[#3777BC] text-[20px] font-semibold text-center">
            เเก้ไขสัญญา
          </div>
          <TextInputCustom
            focused={true}
            disabled
            label={"ฟาร์ม"}
            placeholder={`${get(dataEdit, ["house", "farm", "name"])}`}
            value={""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon color="disabled" fontSize="small" />
                </InputAdornment>
              ),
              style: {
                border: "1px",
              },
              className:
                "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] !border-[1px]",
              sx: {
                borderRadius: "28px !important",
                "& .MuiInputBase-input": {
                  padding: 0,
                  color: "#979797 !important",
                },
                "& .Mui-disabled": {
                  color: "#979797  !important",
                  WebkitTextFillColor: "#979797  !important",
                  bgcolor: "#FBFBFB !important",
                },
              },
            }}
          />
          <TextInputCustom
            focused={true}
            disabled
            label={"โรงเรือน"}
            placeholder={`${get(dataEdit, ["house", "name"])}`}
            value={""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon color="disabled" fontSize="small" />
                </InputAdornment>
              ),
              style: {
                border: "1px",
              },
              className:
                "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] !border-[1px]",
              sx: {
                borderRadius: "28px !important",
                "& .MuiInputBase-input": {
                  padding: 0,
                  color: "#979797 !important",
                },
                "& .Mui-disabled": {
                  color: "#979797  !important",
                  WebkitTextFillColor: "#979797  !important",
                  bgcolor: "#FBFBFB !important",
                },
              },
            }}
          />
          <TextInputCustom
            focused={true}
            disabled
            label={"วงเงินลูกค้า"}
            placeholder={`${getCommaNumber(
              +get(dataEdit, ["creditInformation", "creditAmount"], "")
            )}`}
            value={""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon color="disabled" fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    WebkitTextFillColor: "#979797 !important",
                  }}
                >
                  <div className="font-medium text-[16px] leading-[24px] !text-[#979797] flex flex-row gap-1">
                    | <span className="!text-[#979797]">บาท</span>
                  </div>
                </InputAdornment>
              ),
              style: {
                border: "1px",
              },
              className:
                "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] !border-[1px]",
              sx: {
                borderRadius: "28px !important",
                "& .MuiInputBase-input": {
                  padding: 0,
                  color: "#979797 !important",
                },
                "& .Mui-disabled": {
                  color: "#979797  !important",
                  WebkitTextFillColor: "#979797  !important",
                  bgcolor: "#FBFBFB !important",
                },
              },
            }}
          />
          <TextInputCustom
            focused={true}
            disabled
            label={"ประเภทหมู"}
            placeholder="หมูขุน"
            value={""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon color="disabled" fontSize="small" />
                </InputAdornment>
              ),
              style: {
                border: "1px",
              },
              className:
                "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] !border-[1px]",
              sx: {
                borderRadius: "28px !important",
                "& .MuiInputBase-input": {
                  padding: 0,
                  color: "#979797 !important",
                },
                "& .Mui-disabled": {
                  color: "#979797  !important",
                  WebkitTextFillColor: "#979797  !important",
                  bgcolor: "#FBFBFB !important",
                },
              },
            }}
          />
          <Input
            label2="จำนวนหมู"
            focused
            placeholder="ราคา"
            value={form.pigCount}
            onChangeText={onChangeForm("pigCount")}
            inputType="number"
            customInputStyle={tw(
              "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
            )}
            containerDetailClassName="pb-0"
            borderRadius={"28px"}
            borderColor={"#3777BC !important"}
            customSuffix={
              <div className="pr-[14px] font-medium text-[16px] leading-[24px] text-[#D9EBFE]">
                | <span className="text-[#3777BC]">ตัว</span>
              </div>
            }
          />
          <ButtonFillCustom
            title="ยืนยัน"
            onClick={onSubmit}
            className="!rounded-[50px] !h-[59px] !w-[255px] !px-[13.5px] !py-[4.5px]"
          />
        </div>
      </Box>
    </Modal>
  );
};

export default memo(EditCountPigModal);

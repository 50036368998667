import { useQuery } from "@tanstack/react-query";
import _, { get } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useShowButtonByRoles } from "../../../../hooks/useActionButtonByRoles";
import {
  getCreditFeedData,
  getCreditSetting,
  getFarmersCreditFeed,
} from "../../../../services/CreditFeed.service";
import {
  EContractStatus,
  ICreditFeedData,
} from "../../../../types/CreditFeed.type";
import { UserRole } from "../../../../utils/enum/user-role.enum";
import { TDropdownValue } from "../../group-area/views/Dropdown";
import {
  defaultFormTabApprove,
  TFormTabApprove,
} from "./credit-tab/TabApprove/DefaultFormTabApprove";
import {
  defaultFormTabDenied,
  TFormTabDenied,
} from "./credit-tab/TabDenied/DefaultFormTabDenied";
import {
  defaultFormTabFarmer,
  TDropdownValueId,
  TFormForDynamicDropdown,
  TFormTabFarmer,
} from "./credit-tab/TabFarmer/DefaultFormTabFarmer";
import {
  defaultFormTabHistory,
  TFormTabHistory,
} from "./credit-tab/TabHistory/DefaultFormTabHistory";
import {
  defaultFormTabPending,
  TFormTabPending,
} from "./credit-tab/TabPending/DefaultFormTabPending";
import {
  defaultFormForApprove,
  defaultFormTabProcessing,
  TFormForApprove,
  TFormTabProcessing,
} from "./credit-tab/TabProcessing/DefaultFormTabProcessing";

const optionGrade = [
  {
    label: "A",
    value: "a",
  },
  {
    label: "B",
    value: "b",
  },
  {
    label: "C",
    value: "c",
  },
  {
    label: "D",
    value: "d",
  },
  {
    label: "E",
    value: "e",
  },
  {
    label: "F",
    value: "f",
  },
];

export type TFarmerGrade = "a" | "b" | "c" | "d" | "e" | "f";

export type TStatusMapCreditFeed =
  | "pending_sale_lead_approve_offer"
  | "pending_lending_approve_offer"
  | "pending_farmer_approve_offer"
  | "pending_sale_forward_consideration"
  | "pending_lending_confirm_consideration"
  | "pending_sale_approve_consideration"
  | "pending_confirm_reject"
  | "approved"
  | "denied"
  | "farmer_denied";

const StatusMap = {
  pending_sale_lead_approve_offer: "รอออนุมัติจากหัวหน้าเซลล์",
  pending_lending_approve_offer: "รออนุมัติจาก Lending",
  pending_farmer_approve_offer: "รอลูกค้ายอมรับเครดิต",
  pending_sale_forward_consideration: "รอตรวจสอบเอกสาร",
  pending_lending_confirm_consideration: "รออนุมัติเครดิตจาก Lending",
  pending_sale_approve_consideration: "รออนุมัติเครดิตจากเซลล์",
  pending_confirm_reject: "รอยืนยัน",
  approved: "อนุมัติ",
  denied: "ไม่อนุมัติ",
  farmer_denied: "ยกเลิกโดยลูกค้า",
};

export const useCreditFeedController = (isDetail?: boolean) => {
  const [tabIndex, setTabIndex] = useState(0);

  const onChangeTab = useCallback((e: any, index: number) => {
    setTabIndex(index);
  }, []);

  const { showButton: roleSale } = useShowButtonByRoles([UserRole.Sale]);

  const { showButton: roleSaleLead } = useShowButtonByRoles([
    UserRole.SaleLead,
  ]);

  const { showButton: roleLending } = useShowButtonByRoles([UserRole.Lending]);

  ////////////////// orderByTab //////////////////

  const [currentOrderByKey1, setCurrentOrderByKey1] = useState<
    "farmer_code" | "credit_rating" | "updated_at"
  >("farmer_code");

  const [orderByType1, setOrderByType1] = useState<"desc" | "asc">("desc");

  const [currentOrderByKey2, setCurrentOrderByKey2] = useState<
    "farmer_code" | "credit_rating" | "updated_at"
  >("farmer_code");

  const [orderByType2, setOrderByType2] = useState<"desc" | "asc">("desc");

  const [currentOrderByKey3, setCurrentOrderByKey3] = useState<
    "farmer_code" | "credit_rating" | "updated_at"
  >("farmer_code");

  const [orderByType3, setOrderByType3] = useState<"desc" | "asc">("desc");

  const [currentOrderByKey4, setCurrentOrderByKey4] = useState<
    "farmer_code" | "credit_rating" | "updated_at"
  >("farmer_code");

  const [orderByType4, setOrderByType4] = useState<"desc" | "asc">("desc");

  const [currentOrderByKey5, setCurrentOrderByKey5] = useState<
    "farmer_code" | "credit_rating" | "updated_at"
  >("farmer_code");

  const [orderByType5, setOrderByType5] = useState<"desc" | "asc">("desc");

  const [currentOrderByKey6, setCurrentOrderByKey6] = useState<
    "farmer_code" | "credit_rating" | "updated_at"
  >("farmer_code");

  const [orderByType6, setOrderByType6] = useState<"desc" | "asc">("desc");

  ////////////////// TabFarmer //////////////////

  const [searchTextTabFarmer, setSearchTextTabFarmer] = useState("");

  const onSearchTextTabFarmer = useCallback((e: any) => {
    const text = e.target.value;
    setSearchTextTabFarmer(text);
  }, []);

  const onClearSearchTextTabFarmer = useCallback(() => {
    setSearchTextTabFarmer("");
  }, []);

  const [idFramers, setIdFramers] = useState("");

  const [itemSelectedTabFarmer, setItemSelectedTabFarmer] = useState<string[]>(
    []
  );

  const [currentPageFarmersCreditFeed, setCurrentPageFarmersCreditFeed] =
    useState(1);

  const [countDataFarmer, setCountDataFarmer] = useState(0);

  const [formTabFarmer, setFormTabFarmer] = useState(defaultFormTabFarmer);

  const onChangeFormTabFarmer = useCallback(
    (key: keyof TFormTabFarmer) => (value: any) => {
      setFormTabFarmer((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const { data: listFarmersCreditFeed, refetch: refetchListFarmersCreditFeed } =
    useQuery({
      queryKey: [
        "list_farmers_credit_feed",
        currentPageFarmersCreditFeed,
        searchTextTabFarmer,
        currentOrderByKey1,
        orderByType1,
      ],
      queryFn: async () => {
        const result = await getFarmersCreditFeed({
          page: currentPageFarmersCreditFeed,
          searchText: searchTextTabFarmer,
          orderBy: currentOrderByKey1,
          direction: orderByType1,
        });
        return result;
      },
      enabled: isDetail ? false : roleSale,
    });

  useEffect(() => {
    if (listFarmersCreditFeed && listFarmersCreditFeed.count > 0) {
      setCountDataFarmer(listFarmersCreditFeed.count);
    } else if (searchTextTabFarmer !== "") {
      setCountDataFarmer(listFarmersCreditFeed?.count || 0);
    }
  }, [listFarmersCreditFeed]);

  const [isOpenApplyCreditModal, setIsOpenApplyCreditModal] = useState(false);

  const onOpenApplyCreditModal = useCallback(
    () => setIsOpenApplyCreditModal(true),
    []
  );
  const onHideApplyCreditModal = useCallback(
    () => setIsOpenApplyCreditModal(false),
    []
  );

  ////////////////// TabPending //////////////////

  const [searchTextTabPending, setSearchTextTabPending] = useState("");

  const onSearchTextTabPending = useCallback((e: any) => {
    const text = e.target.value;
    setSearchTextTabPending(text);
  }, []);

  const onClearSearchTextTabPending = useCallback(() => {
    setSearchTextTabPending("");
  }, []);

  const [idPending, setIdPending] = useState("");

  const [itemSelectedTabPending, setItemSelectedTabPending] = useState<
    string[]
  >([]);

  const [currentPagePendingCreditFeed, setCurrentPagePendingCreditFeed] =
    useState(1);

  const [countDataPending, setCountDataPending] = useState(0);

  const [formTabPending, setFormTabPending] = useState(defaultFormTabPending);

  const onChangeFormTabPending = useCallback(
    (key: keyof TFormTabPending) => (value: any) => {
      setFormTabPending((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const {
    data: listCreditFeedTabPending,
    refetch: refetchListCreditFeedTabPending,
  } = useQuery({
    queryKey: [
      "list_credit_feed_tab_pending",
      currentPagePendingCreditFeed,
      searchTextTabPending,
      formTabPending.grade,
      formTabPending.statusCredit,
      currentOrderByKey2,
      orderByType2,
    ],
    queryFn: async () => {
      const result = await getCreditFeedData({
        page: currentPagePendingCreditFeed,
        searchText: searchTextTabPending,
        farmerGrade: formTabPending.grade[0]?.value as TFarmerGrade,
        status: formTabPending.statusCredit[0]?.value as EContractStatus,
        tabName: "pending",
        orderBy: currentOrderByKey2,
        direction: orderByType2,
      });
      return result;
    },
    enabled: isDetail ? false : roleSale || roleSaleLead || roleLending,
  });

  useEffect(() => {
    if (listCreditFeedTabPending && listCreditFeedTabPending.count > 0) {
      setCountDataPending(listCreditFeedTabPending.count);
    } else if (searchTextTabPending !== "") {
      setCountDataPending(listCreditFeedTabPending?.count || 0);
    }
  }, [listCreditFeedTabPending]);

  useEffect(() => {
    setCountDataPending(listCreditFeedTabPending?.count || 0);
  }, [formTabPending]);

  const gradeCustomerInDataTabPendingUnique = useMemo(() => {
    const array = get(listCreditFeedTabPending, ["creditFeeds"], []);
    // @ts-ignore
    const unique = [...new Set(array.map((obj) => obj.customerGrade))];

    return unique;
  }, [listCreditFeedTabPending]);

  const gradeCustomerInDataTabPending = useMemo(() => {
    const option = _.sortBy(gradeCustomerInDataTabPendingUnique);
    return option.map((grade) => ({
      label: grade.toLocaleUpperCase(),
      value: grade,
    }));
  }, [gradeCustomerInDataTabPendingUnique]);

  const statusCreditInDataTabPendingUnique = useMemo(() => {
    const array = get(listCreditFeedTabPending, ["creditFeeds"], []);
    // @ts-ignore
    const unique = [...new Set(array.map((obj) => obj.contractStatus))];

    return unique;
  }, [listCreditFeedTabPending]);

  const statusCreditInDataTabPending = useMemo(() => {
    const option = _.sortBy(statusCreditInDataTabPendingUnique);
    return option.map((status) => ({
      label: StatusMap[status as TStatusMapCreditFeed],
      value: status,
    }));
  }, [statusCreditInDataTabPendingUnique]);

  ////////////////// TabDenied //////////////////

  const [searchTextTabDenied, setSearchTextTabDenied] = useState("");

  const onSearchTextTabDenied = useCallback((e: any) => {
    const text = e.target.value;
    setSearchTextTabDenied(text);
  }, []);

  const onClearSearchTextTabDenied = useCallback(() => {
    setSearchTextTabDenied("");
  }, []);

  const [idDenied, setIdDenied] = useState("");

  const [itemSelectedTabDenied, setItemSelectedTabDenied] = useState<string[]>(
    []
  );

  const [currentPageDeniedCreditFeed, setCurrentPageDeniedCreditFeed] =
    useState(1);

  const [countDataDenied, setCountDataDenied] = useState(0);

  const [formTabDenied, setFormTabDenied] = useState(defaultFormTabDenied);

  const onChangeFormTabDenied = useCallback(
    (key: keyof TFormTabDenied) => (value: any) => {
      setFormTabDenied((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const {
    data: listCreditFeedTabDenied,
    refetch: refetchListCreditFeedTabDenied,
  } = useQuery({
    queryKey: [
      "list_credit_feed_tab_denied",
      currentPageDeniedCreditFeed,
      searchTextTabDenied,
      formTabDenied.grade,
      formTabDenied.statusCredit,
      currentOrderByKey3,
      orderByType3,
    ],
    queryFn: async () => {
      const result = await getCreditFeedData({
        page: currentPageDeniedCreditFeed,
        searchText: searchTextTabDenied,
        farmerGrade: formTabDenied.grade[0]?.value as TFarmerGrade,
        status: formTabDenied.statusCredit[0]?.value as EContractStatus,
        tabName: "denied",
        orderBy: currentOrderByKey3,
        direction: orderByType3,
      });
      return result;
    },
    enabled: isDetail ? false : roleSale,
  });

  useEffect(() => {
    if (listCreditFeedTabDenied && listCreditFeedTabDenied.count > 0) {
      setCountDataDenied(listCreditFeedTabDenied.count);
    } else if (searchTextTabDenied !== "") {
      setCountDataDenied(listCreditFeedTabDenied?.count || 0);
    }
  }, [listCreditFeedTabDenied]);

  useEffect(() => {
    setCountDataDenied(listCreditFeedTabDenied?.count || 0);
  }, [formTabDenied]);

  const gradeCustomerInDataTabDeniedUnique = useMemo(() => {
    const array = get(listCreditFeedTabDenied, ["creditFeeds"], []);
    // @ts-ignore
    const unique = [...new Set(array.map((obj) => obj.customerGrade))];

    return unique;
  }, [listCreditFeedTabDenied]);

  const gradeCustomerInDataTabDenied = useMemo(() => {
    const option = _.sortBy(gradeCustomerInDataTabDeniedUnique);
    return option.map((grade) => ({
      label: grade.toLocaleUpperCase(),
      value: grade,
    }));
  }, [gradeCustomerInDataTabDeniedUnique]);

  const statusCreditInDataTabDeniedUnique = useMemo(() => {
    const array = get(listCreditFeedTabDenied, ["creditFeeds"], []);
    // @ts-ignore
    const unique = [...new Set(array.map((obj) => obj.contractStatus))];

    return unique;
  }, [listCreditFeedTabDenied]);

  const statusCreditInDataTabDenied = useMemo(() => {
    const option = _.sortBy(statusCreditInDataTabDeniedUnique);
    return option.map((status) => ({
      label: StatusMap[status as TStatusMapCreditFeed],
      value: status,
    }));
  }, [statusCreditInDataTabDeniedUnique]);

  ////////////////// TabProcessing //////////////////

  const [searchTextTabProcessing, setSearchTextTabProcessing] = useState("");

  const onSearchTextTabProcessing = useCallback((e: any) => {
    const text = e.target.value;
    setSearchTextTabProcessing(text);
  }, []);

  const onClearSearchTextTabProcessing = useCallback(() => {
    setSearchTextTabProcessing("");
  }, []);

  const [idProcessing, setIdProcessing] = useState("");

  const [itemSelectedTabProcessing, setItemSelectedTabProcessing] = useState<
    string[]
  >([]);

  const [currentPageProcessingCreditFeed, setCurrentPageProcessingCreditFeed] =
    useState(1);

  const [countDataProcessing, setCountDataProcessing] = useState(0);

  const [formTabProcessing, setFormTabProcessing] = useState(
    defaultFormTabProcessing
  );

  const onChangeFormTabProcessing = useCallback(
    (key: keyof TFormTabProcessing) => (value: any) => {
      setFormTabProcessing((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const {
    data: listCreditFeedTabProcessing,
    refetch: refetchListCreditFeedTabProcessing,
  } = useQuery({
    queryKey: [
      "list_credit_feed_tab_processing",
      currentPageProcessingCreditFeed,
      searchTextTabProcessing,
      formTabProcessing.grade,
      currentOrderByKey4,
      orderByType4,
    ],
    queryFn: async () => {
      const result = await getCreditFeedData({
        page: currentPageProcessingCreditFeed,
        searchText: searchTextTabProcessing,
        farmerGrade: formTabProcessing.grade[0]?.value as TFarmerGrade,
        tabName: "processing",
        orderBy: currentOrderByKey4,
        direction: orderByType4,
      });
      return result;
    },
    enabled: isDetail ? false : roleSale || roleLending,
  });

  useEffect(() => {
    if (listCreditFeedTabProcessing && listCreditFeedTabProcessing.count > 0) {
      setCountDataProcessing(listCreditFeedTabProcessing.count);
    } else if (searchTextTabProcessing !== "") {
      setCountDataProcessing(listCreditFeedTabProcessing?.count || 0);
    }
  }, [listCreditFeedTabProcessing]);

  useEffect(() => {
    setCountDataProcessing(listCreditFeedTabProcessing?.count || 0);
  }, [formTabProcessing]);

  const gradeCustomerInDataTabProcessingUnique = useMemo(() => {
    const array = get(listCreditFeedTabProcessing, ["creditFeeds"], []);
    // @ts-ignore
    const unique = [...new Set(array.map((obj) => obj.customerGrade))];

    return unique;
  }, [listCreditFeedTabProcessing]);

  const gradeCustomerInDataTabProcessing = useMemo(() => {
    const option = _.sortBy(gradeCustomerInDataTabProcessingUnique);
    return option.map((grade) => ({
      label: grade.toLocaleUpperCase(),
      value: grade,
    }));
  }, [gradeCustomerInDataTabProcessingUnique]);

  ////////////////// TabHistory //////////////////

  const [searchTextTabHistory, setSearchTextTabHistory] = useState("");

  const onSearchTextTabHistory = useCallback((e: any) => {
    const text = e.target.value;
    setSearchTextTabHistory(text);
  }, []);

  const onClearSearchTextTabHistory = useCallback(() => {
    setSearchTextTabHistory("");
  }, []);

  const [itemSelectedTabHistory, setItemSelectedTabHistory] = useState<
    string[]
  >([]);

  const [currentPageHistoryCreditFeed, setCurrentPageHistoryCreditFeed] =
    useState(1);

  const [countDataHistory, setCountDataHistory] = useState(0);

  const [formTabHistory, setFormTabHistory] = useState(defaultFormTabHistory);

  const onChangeFormTabHistory = useCallback(
    (key: keyof TFormTabHistory) => (value: any) => {
      setFormTabHistory((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const {
    data: listCreditFeedTabHistory,
    refetch: refetchListCreditFeedTabHistory,
  } = useQuery({
    queryKey: [
      "list_credit_feed_tab_history",
      currentPageHistoryCreditFeed,
      searchTextTabHistory,
      formTabHistory.grade,
      formTabHistory.statusCredit,
      currentOrderByKey5,
      orderByType5,
    ],
    queryFn: async () => {
      const result = await getCreditFeedData({
        page: currentPageHistoryCreditFeed,
        searchText: searchTextTabHistory,
        farmerGrade: formTabHistory.grade[0]?.value as TFarmerGrade,
        status: formTabHistory.statusCredit[0]?.value as EContractStatus,
        tabName: "history",
        orderBy: currentOrderByKey5,
        direction: orderByType5,
      });

      return result;
    },
    enabled: isDetail ? false : roleSale || roleSaleLead || roleLending,
  });

  useEffect(() => {
    if (listCreditFeedTabHistory && listCreditFeedTabHistory.count > 0) {
      setCountDataHistory(listCreditFeedTabHistory.count);
    }
  }, [listCreditFeedTabHistory]);

  const gradeCustomerInDataTabHistoryUnique = useMemo(() => {
    const array = get(listCreditFeedTabHistory, ["creditFeeds"], []);
    // @ts-ignore
    const unique = [...new Set(array.map((obj) => obj.customerGrade))];

    return unique;
  }, [listCreditFeedTabHistory]);

  const gradeCustomerInDataTabHistory = useMemo(() => {
    const option = _.sortBy(gradeCustomerInDataTabHistoryUnique);
    return option.map((grade) => ({
      label: grade.toLocaleUpperCase(),
      value: grade,
    }));
  }, [gradeCustomerInDataTabHistoryUnique]);

  const statusCreditInDataTabHistoryUnique = useMemo(() => {
    const array = get(listCreditFeedTabHistory, ["creditFeeds"], []);
    // @ts-ignore
    const unique = [...new Set(array.map((obj) => obj.contractStatus))];

    return unique;
  }, [listCreditFeedTabHistory]);

  const statusCreditInDataTabHistory = useMemo(() => {
    const option = _.sortBy(statusCreditInDataTabHistoryUnique);
    return option.map((status) => ({
      label: StatusMap[status as TStatusMapCreditFeed],
      value: status,
    }));
  }, [statusCreditInDataTabHistoryUnique]);

  ////////////////// TabApprove //////////////////

  const [searchTextTabApprove, setSearchTextTabApprove] = useState("");

  const onSearchTextTabApprove = useCallback((e: any) => {
    const text = e.target.value;
    setSearchTextTabApprove(text);
  }, []);

  const onClearSearchTextTabApprove = useCallback(() => {
    setSearchTextTabApprove("");
  }, []);

  const [itemSelectedTabApprove, setItemSelectedTabApprove] = useState<
    string[]
  >([]);

  const [currentPageApproveCreditFeed, setCurrentPageApproveCreditFeed] =
    useState(1);

  const [countDataApprove, setCountDataApprove] = useState(0);

  const [formTabApprove, setFormTabApprove] = useState(defaultFormTabApprove);

  const onChangeFormTabApprove = useCallback(
    (key: keyof TFormTabApprove) => (value: any) => {
      setFormTabApprove((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const {
    data: listCreditFeedTabApprove,
    refetch: refetchListCreditFeedTabApprove,
  } = useQuery({
    queryKey: [
      "list_credit_feed_tab_approve",
      currentPageApproveCreditFeed,
      searchTextTabApprove,
      formTabApprove.grade,
      formTabApprove.statusCredit,
      currentOrderByKey6,
      orderByType6,
    ],
    queryFn: async () => {
      const result = await getCreditFeedData({
        page: currentPageApproveCreditFeed,
        searchText: searchTextTabApprove,
        farmerGrade: formTabApprove.grade[0]?.value as TFarmerGrade,
        status: formTabApprove.statusCredit[0]?.value as EContractStatus,
        tabName: "approved",
        orderBy: currentOrderByKey6,
        direction: orderByType6,
      });
      return result;
    },
    enabled: isDetail ? false : roleSaleLead || roleLending,
  });

  useEffect(() => {
    if (listCreditFeedTabApprove && listCreditFeedTabApprove.count > 0) {
      setCountDataApprove(listCreditFeedTabApprove.count);
    }
  }, [listCreditFeedTabApprove]);

  const gradeCustomerInDataTabApproveUnique = useMemo(() => {
    const array = get(listCreditFeedTabApprove, ["creditFeeds"], []);
    // @ts-ignore
    const unique = [...new Set(array.map((obj) => obj.customerGrade))];

    return unique;
  }, [listCreditFeedTabApprove]);

  const gradeCustomerInDataTabApprove = useMemo(() => {
    const option = _.sortBy(gradeCustomerInDataTabApproveUnique);
    return option.map((grade) => ({
      label: grade.toLocaleUpperCase(),
      value: grade,
    }));
  }, [gradeCustomerInDataTabApproveUnique]);

  const statusCreditInDataTabApproveUnique = useMemo(() => {
    const array = get(listCreditFeedTabApprove, ["creditFeeds"], []);
    // @ts-ignore
    const unique = [...new Set(array.map((obj) => obj.contractStatus))];

    return unique;
  }, [listCreditFeedTabApprove]);

  const statusCreditInDataTabApprove = useMemo(() => {
    const option = _.sortBy(statusCreditInDataTabApproveUnique);
    return option.map((status) => ({
      label: StatusMap[status as TStatusMapCreditFeed],
      value: status,
    }));
  }, [statusCreditInDataTabApproveUnique]);

  ////////////////// AllForm //////////////////

  const [FormGeneralPersonalInformation, setFormGeneralPersonalInformation] =
    useState<TFormForDynamicDropdown>([]);

  const onChangeFormGeneralPersonalInformation = useCallback(
    (newValue: TDropdownValue & { id: string }, index: number) => {
      setFormGeneralPersonalInformation((prev) => {
        const updated = [...prev];
        updated[index] = newValue;
        return updated;
      });
    },
    []
  );

  const [FormGeneralLegalInformation, setFormGeneralLegalInformation] =
    useState<TFormForDynamicDropdown>([]);

  const onChangeFormGeneralLegalInformation = useCallback(
    (newValue: TDropdownValue & { id: string }, index: number) => {
      setFormGeneralLegalInformation((prev) => {
        const updated = [...prev];
        updated[index] = newValue;
        return updated;
      });
    },
    []
  );

  const [FormGeneralFarmInformation, setFormGeneralFarmInformation] =
    useState<TFormForDynamicDropdown>([]);

  const onChangeFormGeneralFarmInformation = useCallback(
    (newValue: TDropdownValue & { id: string }, index: number) => {
      setFormGeneralFarmInformation((prev) => {
        const updated = [...prev];
        updated[index] = newValue;
        return updated;
      });
    },
    []
  );

  const [FormFarmPerformance, setFormFarmPerformance] =
    useState<TFormForDynamicDropdown>([]);

  const onChangeFormFarmPerformance = useCallback(
    (newValue: TDropdownValue & { id: string }, index: number) => {
      setFormFarmPerformance((prev) => {
        const updated = [...prev];
        updated[index] = newValue;
        return updated;
      });
    },
    []
  );

  const [
    FormFarmFinancialStatusAndInstallment,
    setFormFarmFinancialStatusAndInstallment,
  ] = useState<TFormForDynamicDropdown>([]);

  const onChangeFormFarmFinancialStatusAndInstallment = useCallback(
    (newValue: TDropdownValue & { id: string }, index: number) => {
      setFormFarmFinancialStatusAndInstallment((prev) => {
        const updated = [...prev];
        updated[index] = newValue;
        return updated;
      });
    },
    []
  );

  const [FormApplicationUsage, setFormApplicationUsage] =
    useState<TFormForDynamicDropdown>([]);

  const onChangeFormApplicationUsage = useCallback(
    (newValue: TDropdownValue & { id: string }, index: number) => {
      setFormApplicationUsage((prev) => {
        const updated = [...prev];
        updated[index] = newValue;
        return updated;
      });
    },
    []
  );

  const [FormApproveCredit, setFormApproveCredit] = useState<TFormForApprove>(
    defaultFormForApprove
  );
  const onChangeFormApproveCredit = useCallback(
    (key: keyof TFormForApprove) => (value: any) => {
      setFormApproveCredit((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  ////////////////// CreditSetting //////////////////

  const { data: listCreditSetting } = useQuery({
    queryKey: ["get_credit_setting"],
    queryFn: async () => {
      const result = await getCreditSetting();
      return result;
    },
  });

  const selectVersionCreditOption = useMemo(() => {
    return get(listCreditSetting, ["creditSettings"], []).map(
      ({ name, id }) => ({
        label: name,
        value: id,
      })
    );
  }, [listCreditSetting]);

  const versionCreditSelect = useMemo(() => {
    return get(listCreditSetting, ["creditSettings"], []).find(
      (i) => i.id === formTabFarmer.tabFirst_versionCredit[0]?.value
    );
  }, [listCreditSetting, formTabFarmer]);

  const businessTypeOption = useMemo(() => {
    const personalChecklist = get(versionCreditSelect, [
      "generalPersonalInformation",
      "checklists",
      0,
    ]);
    const legalChecklist = get(versionCreditSelect, [
      "generalLegalInformation",
      "checklists",
      0,
    ]);

    const options = [];
    if (personalChecklist?.general && personalChecklist.id) {
      options.push({
        label: personalChecklist.general,
        value: "personal",
      });
    }

    if (legalChecklist?.general && legalChecklist.id) {
      options.push({
        label: legalChecklist.general,
        value: "legal",
      });
    }

    return options;
  }, [versionCreditSelect]);

  const selectPersonal = useMemo(() => {
    const personalOptions = get(
      versionCreditSelect,
      ["generalPersonalInformation", "checklists"],
      []
    );
    const legalOptions = get(
      versionCreditSelect,
      ["generalLegalInformation", "checklists"],
      []
    );

    if (formTabFarmer.tabFirst_businessType[0]?.value === "personal") {
      return (personalOptions || []).reduce<TDropdownValueId>(
        (acc, checklist) => {
          if (checklist.isActive) {
            const options = checklist.options.map(
              (option: { key: string; value: any }) => ({
                label: option.key,
                value: option.value.toString(),
              })
            );
            acc.push({
              id: checklist.id,
              label: checklist.general,
              options: options,
            });
          }
          return acc;
        },
        []
      );
    }

    if (formTabFarmer.tabFirst_businessType[0]?.value === "legal") {
      return (legalOptions || []).reduce<TDropdownValueId>((acc, checklist) => {
        if (checklist.isActive) {
          const options = checklist.options.map(
            (option: { key: string; value: any }) => ({
              label: option.key,
              value: option.value.toString(),
            })
          );
          acc.push({
            id: checklist.id,
            label: checklist.general,
            options: options,
          });
        }
        return acc;
      }, []);
    }
  }, [versionCreditSelect, formTabFarmer]);

  const typePersonalOptions = useMemo(() => {
    const options = [];

    if (formTabFarmer.tabFirst_businessType[0]?.value === "personal") {
      const personalOptions = get(selectPersonal, [0], []);

      options.push(personalOptions);
    }

    return (options as TDropdownValueId) || [];
  }, [selectPersonal]);

  const agePersonalOptions = useMemo(() => {
    const options = [];

    if (formTabFarmer.tabFirst_businessType[0]?.value === "personal") {
      const personalOptions = get(selectPersonal, [1], []);

      options.push(personalOptions);
    }

    return (options as TDropdownValueId) || [];
  }, [selectPersonal]);

  const typeLegalOptions = useMemo(() => {
    const options = [];

    if (formTabFarmer.tabFirst_businessType[0]?.value === "legal") {
      const legalOptions = get(selectPersonal, [0], []);

      options.push(legalOptions);
    }

    return (options as TDropdownValueId) || [];
  }, [selectPersonal]);

  const dropdownValuesGeneralFarmInformation = useMemo<TDropdownValueId>(() => {
    const checklists = get(
      versionCreditSelect,
      ["generalFarmInformation", "checklists"],
      []
    );

    return (checklists || []).reduce<TDropdownValueId>((acc, checklist) => {
      if (checklist.isActive) {
        const options = checklist.options.map(
          (option: { key: string; value: any }) => ({
            label: option.key,
            value: option.value.toString(),
          })
        );
        acc.push({
          id: checklist.id,
          label: checklist.general,
          options: options,
        });
      }
      return acc;
    }, []);
  }, [versionCreditSelect]);

  const dropdownValuesFarmPerformance = useMemo<TDropdownValueId>(() => {
    const checklists = get(
      versionCreditSelect,
      ["farmPerformance", "checklists"],
      []
    );

    return (checklists || []).reduce<TDropdownValueId>((acc, checklist) => {
      if (checklist.isActive) {
        const options = checklist.options.map(
          (option: { key: string; value: any }) => ({
            label: option.key,
            value: option.value.toString(),
          })
        );
        acc.push({
          id: checklist.id,
          label: checklist.general,
          options: options,
        });
      }
      return acc;
    }, []);
  }, [versionCreditSelect]);

  const dropdownValuesFarmFinancialStatusAndInstallment =
    useMemo<TDropdownValueId>(() => {
      const checklists = get(
        versionCreditSelect,
        ["farmFinancialStatusAndInstallment", "checklists"],
        []
      );

      return (checklists || []).reduce<TDropdownValueId>((acc, checklist) => {
        if (checklist.isActive) {
          const options = checklist.options.map(
            (option: { key: string; value: any }) => ({
              label: option.key,
              value: option.value.toString(),
            })
          );
          acc.push({
            id: checklist.id,
            label: checklist.general,
            options: options,
          });
        }
        return acc;
      }, []);
    }, [versionCreditSelect]);

  const dropdownValuesApplicationUsage = useMemo<TDropdownValueId>(() => {
    const checklists = get(
      versionCreditSelect,
      ["applicationUsage", "checklists"],
      []
    );

    return (checklists || []).reduce<TDropdownValueId>((acc, checklist) => {
      if (checklist.isActive) {
        const options = checklist.options.map(
          (option: { key: string; value: any }) => ({
            label: option.key,
            value: option.value.toString(),
          })
        );
        acc.push({
          id: checklist.id,
          label: checklist.general,
          options: options,
        });
      }
      return acc;
    }, []);
  }, [versionCreditSelect]);

  ////////////////// OpenModalReasonTabPending //////////////////

  const [isOpenApproveTabPending, setIsOpenApproveTabPending] = useState(false);

  const onOpenApproveTabPending = useCallback(
    () => setIsOpenApproveTabPending(true),
    []
  );
  const onHideApproveTabPending = useCallback(
    () => setIsOpenApproveTabPending(false),
    []
  );

  const [isOpenRejectTabPending, setIsOpenRejectTabPending] = useState(false);

  const onOpenRejectTabPending = useCallback(
    () => setIsOpenRejectTabPending(true),
    []
  );
  const onHideRejectTabPending = useCallback(
    () => setIsOpenRejectTabPending(false),
    []
  );

  const [
    isOpenRejectWithOptionTabPending,
    setIsOpenRejectWithOptionTabPending,
  ] = useState(false);

  const onOpenRejectWithOptionTabPending = useCallback(
    () => setIsOpenRejectWithOptionTabPending(true),
    []
  );
  const onHideRejectWithOptionTabPending = useCallback(
    () => setIsOpenRejectWithOptionTabPending(false),
    []
  );

  ////////////////// OpenModalReasonTabDenied //////////////////

  const [isOpenRejectWithOptionTabDenied, setIsOpenRejectWithOptionTabDenied] =
    useState(false);

  const onOpenRejectWithOptionTabDenied = useCallback(
    () => setIsOpenRejectWithOptionTabDenied(true),
    []
  );
  const onHideRejectWithOptionTabDenied = useCallback(
    () => setIsOpenRejectWithOptionTabDenied(false),
    []
  );

  const [isOpenReapplyCredit, setIsOpenReapplyCredit] = useState(false);

  const [dataReapplyCredit, setDataReapplyCredit] = useState<ICreditFeedData>();

  const onOpenReapplyCredit = useCallback(
    (id: string) => {
      setIsOpenReapplyCredit(true);
      setDataReapplyCredit(
        listCreditFeedTabDenied?.creditFeeds.find((i) => i.id === id)
      );
    },
    [listCreditFeedTabDenied]
  );

  const onHideReapplyCredit = useCallback(
    () => setIsOpenReapplyCredit(false),
    []
  );

  ////////////////// OpenModalImportTabPending //////////////////

  const [isOpenImportTabPending, setIsOpenImportTabPending] = useState(false);

  const onOpenImportTabPending = useCallback(
    () => setIsOpenImportTabPending(true),
    []
  );
  const onHideImportTabPending = useCallback(
    () => setIsOpenImportTabPending(false),
    []
  );

  ////////////////// OpenModalReasonTabProcessing //////////////////

  const [isOpenRejectTabProcessing, setIsOpenRejectTabProcessing] =
    useState(false);

  const onOpenRejectTabProcessing = useCallback(
    () => setIsOpenRejectTabProcessing(true),
    []
  );
  const onHideRejectTabProcessing = useCallback(
    () => setIsOpenRejectTabProcessing(false),
    []
  );

  const [isOpenRejectTabProcessingSale, setIsOpenRejectTabProcessingSale] =
    useState(false);

  const onOpenRejectTabProcessingSale = useCallback(
    () => setIsOpenRejectTabProcessingSale(true),
    []
  );
  const onHideRejectTabProcessingSale = useCallback(
    () => setIsOpenRejectTabProcessingSale(false),
    []
  );

  ////////////////// OpenModalTabProcessing //////////////////

  const [isOpenApproveTabProcessing, setIsOpenApproveTabProcessing] =
    useState(false);

  const onOpenApproveTabProcessing = useCallback(
    () => setIsOpenApproveTabProcessing(true),
    []
  );
  const onHideApproveTabProcessing = useCallback(
    () => setIsOpenApproveTabProcessing(false),
    []
  );

  ////////////////// useEffect //////////////////

  useEffect(() => {
    onClearSearchTextTabFarmer();
    onClearSearchTextTabPending();
    onClearSearchTextTabDenied();
  }, [tabIndex]);

  useEffect(() => {
    if (
      FormGeneralFarmInformation.length === 0 &&
      dropdownValuesGeneralFarmInformation.length > 0
    ) {
      setFormGeneralFarmInformation(
        new Array(Math.abs(dropdownValuesGeneralFarmInformation.length)).fill(
          undefined
        )
      );
    }
    if (
      FormFarmPerformance.length === 0 &&
      dropdownValuesFarmPerformance.length > 0
    ) {
      setFormFarmPerformance(
        new Array(Math.abs(dropdownValuesFarmPerformance.length)).fill(
          undefined
        )
      );
    }
    if (
      FormFarmFinancialStatusAndInstallment.length === 0 &&
      dropdownValuesFarmFinancialStatusAndInstallment.length > 0
    ) {
      setFormFarmFinancialStatusAndInstallment(
        new Array(
          Math.abs(dropdownValuesFarmFinancialStatusAndInstallment.length)
        ).fill(undefined)
      );
    }
    if (
      FormApplicationUsage.length === 0 &&
      dropdownValuesApplicationUsage.length > 0
    ) {
      setFormApplicationUsage(
        new Array(Math.abs(dropdownValuesApplicationUsage.length)).fill(
          undefined
        )
      );
    }
  }, [versionCreditSelect]);

  useEffect(() => {
    if (
      FormGeneralPersonalInformation.length === 0 &&
      formTabFarmer.tabFirst_businessType[0]?.value === "personal"
    ) {
      setFormGeneralPersonalInformation(new Array(Math.abs(2)).fill(undefined));
      setFormGeneralLegalInformation([]);
    }
    if (
      FormGeneralLegalInformation.length === 0 &&
      formTabFarmer.tabFirst_businessType[0]?.value === "legal"
    ) {
      setFormGeneralLegalInformation(new Array(Math.abs(1)).fill(undefined));
      setFormGeneralPersonalInformation([]);
    }
  }, [formTabFarmer.tabFirst_businessType]);

  return {
    tabIndex,
    onChangeTab,
    ////////////////// TabFarmer //////////////////
    searchTextTabFarmer,
    onSearchTextTabFarmer,
    onClearSearchTextTabFarmer,
    listFarmersCreditFeed,
    idFramers,
    setIdFramers,
    itemSelectedTabFarmer,
    setItemSelectedTabFarmer,
    formTabFarmer,
    setFormTabFarmer,
    onChangeFormTabFarmer,
    currentPageFarmersCreditFeed,
    setCurrentPageFarmersCreditFeed,
    isOpenApplyCreditModal,
    onOpenApplyCreditModal,
    onHideApplyCreditModal,
    ////////////////// TabPending //////////////////
    searchTextTabPending,
    onSearchTextTabPending,
    onClearSearchTextTabPending,
    listCreditFeedTabPending,
    idPending,
    setIdPending,
    itemSelectedTabPending,
    setItemSelectedTabPending,
    formTabPending,
    setFormTabPending,
    onChangeFormTabPending,
    currentPagePendingCreditFeed,
    setCurrentPagePendingCreditFeed,
    gradeCustomerInDataTabPending,
    statusCreditInDataTabPending,
    ////////////////// TabDenied //////////////////
    searchTextTabDenied,
    onSearchTextTabDenied,
    onClearSearchTextTabDenied,
    listCreditFeedTabDenied,
    idDenied,
    setIdDenied,
    itemSelectedTabDenied,
    setItemSelectedTabDenied,
    formTabDenied,
    setFormTabDenied,
    onChangeFormTabDenied,
    currentPageDeniedCreditFeed,
    setCurrentPageDeniedCreditFeed,
    gradeCustomerInDataTabDenied,
    statusCreditInDataTabDenied,
    ////////////////// TabProcessing //////////////////
    searchTextTabProcessing,
    onSearchTextTabProcessing,
    onClearSearchTextTabProcessing,
    listCreditFeedTabProcessing,
    idProcessing,
    setIdProcessing,
    itemSelectedTabProcessing,
    setItemSelectedTabProcessing,
    formTabProcessing,
    setFormTabProcessing,
    onChangeFormTabProcessing,
    currentPageProcessingCreditFeed,
    setCurrentPageProcessingCreditFeed,
    gradeCustomerInDataTabProcessing,
    ////////////////// TabHistory //////////////////
    searchTextTabHistory,
    onSearchTextTabHistory,
    onClearSearchTextTabHistory,
    listCreditFeedTabHistory,
    itemSelectedTabHistory,
    setItemSelectedTabHistory,
    formTabHistory,
    setFormTabHistory,
    onChangeFormTabHistory,
    currentPageHistoryCreditFeed,
    setCurrentPageHistoryCreditFeed,
    gradeCustomerInDataTabHistory,
    statusCreditInDataTabHistory,
    ////////////////// TabApprove //////////////////
    searchTextTabApprove,
    onSearchTextTabApprove,
    onClearSearchTextTabApprove,
    listCreditFeedTabApprove,
    itemSelectedTabApprove,
    setItemSelectedTabApprove,
    formTabApprove,
    setFormTabApprove,
    onChangeFormTabApprove,
    currentPageApproveCreditFeed,
    setCurrentPageApproveCreditFeed,
    gradeCustomerInDataTabApprove,
    statusCreditInDataTabApprove,
    ////////////////// CreditSetting //////////////////
    selectVersionCreditOption,
    businessTypeOption,
    typePersonalOptions,
    agePersonalOptions,
    typeLegalOptions,
    dropdownValuesGeneralFarmInformation,
    dropdownValuesFarmPerformance,
    dropdownValuesFarmFinancialStatusAndInstallment,
    dropdownValuesApplicationUsage,
    ////////////////// AllForm //////////////////
    FormGeneralPersonalInformation,
    setFormGeneralPersonalInformation,
    onChangeFormGeneralPersonalInformation,
    FormGeneralLegalInformation,
    setFormGeneralLegalInformation,
    onChangeFormGeneralLegalInformation,
    FormGeneralFarmInformation,
    setFormGeneralFarmInformation,
    onChangeFormGeneralFarmInformation,
    FormFarmPerformance,
    setFormFarmPerformance,
    onChangeFormFarmPerformance,
    FormFarmFinancialStatusAndInstallment,
    setFormFarmFinancialStatusAndInstallment,
    onChangeFormFarmFinancialStatusAndInstallment,
    FormApplicationUsage,
    setFormApplicationUsage,
    onChangeFormApplicationUsage,
    FormApproveCredit,
    setFormApproveCredit,
    onChangeFormApproveCredit,
    ////////////////// reFetchData //////////////////
    refetchListFarmersCreditFeed,
    refetchListCreditFeedTabPending,
    refetchListCreditFeedTabDenied,
    refetchListCreditFeedTabProcessing,
    refetchListCreditFeedTabHistory,
    refetchListCreditFeedTabApprove,
    ////////////////// OpenModalReasonTabPending //////////////////
    isOpenApproveTabPending,
    onOpenApproveTabPending,
    onHideApproveTabPending,
    isOpenRejectTabPending,
    onOpenRejectTabPending,
    onHideRejectTabPending,
    isOpenRejectWithOptionTabPending,
    onOpenRejectWithOptionTabPending,
    onHideRejectWithOptionTabPending,
    ////////////////// OpenModalReasonTabDenied //////////////////
    isOpenRejectWithOptionTabDenied,
    onOpenRejectWithOptionTabDenied,
    onHideRejectWithOptionTabDenied,
    isOpenReapplyCredit,
    onOpenReapplyCredit,
    onHideReapplyCredit,
    ////////////////// OpenModalImportTabPending //////////////////
    isOpenImportTabPending,
    onOpenImportTabPending,
    onHideImportTabPending,
    ////////////////// OpenModalReasonTabProcessing //////////////////
    isOpenRejectTabProcessing,
    onOpenRejectTabProcessing,
    onHideRejectTabProcessing,
    ////////////////// OpenModalTabProcessing //////////////////
    isOpenApproveTabProcessing,
    onOpenApproveTabProcessing,
    onHideApproveTabProcessing,
    ////////////////// Role //////////////////
    roleSale,
    roleSaleLead,
    roleLending,
    dataReapplyCredit,
    ////////////////// AllCountData //////////////////
    countDataFarmer,
    setCountDataFarmer,
    countDataPending,
    setCountDataPending,
    countDataDenied,
    setCountDataDenied,
    countDataProcessing,
    setCountDataProcessing,
    countDataHistory,
    setCountDataHistory,
    countDataApprove,
    setCountDataApprove,
    ////////////////// option //////////////////
    optionGrade,
    ////////////////// OrderByKey //////////////////
    currentOrderByKey1,
    setCurrentOrderByKey1,
    orderByType1,
    setOrderByType1,
    currentOrderByKey2,
    setCurrentOrderByKey2,
    orderByType2,
    setOrderByType2,
    currentOrderByKey3,
    setCurrentOrderByKey3,
    orderByType3,
    setOrderByType3,
    currentOrderByKey4,
    setCurrentOrderByKey4,
    orderByType4,
    setOrderByType4,
    currentOrderByKey5,
    setCurrentOrderByKey5,
    orderByType5,
    setOrderByType5,
    currentOrderByKey6,
    setCurrentOrderByKey6,
    orderByType6,
    setOrderByType6,
    ///////////////////
    isOpenRejectTabProcessingSale,
    onOpenRejectTabProcessingSale,
    onHideRejectTabProcessingSale,
  };
};

export function FarmTechWarehouseSvg() {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8333 16.0459V2.71257C14.8333 1.79209 14.0871 1.0459 13.1667 1.0459H4.83333C3.91286 1.0459 3.16667 1.79209 3.16667 2.71256V16.0459M14.8333 16.0459L16.5 16.0459M14.8333 16.0459H10.6667M3.16667 16.0459L1.5 16.0459M3.16667 16.0459H7.33333M6.5 4.37921H7.33333M6.5 7.71255H7.33333M10.6667 4.37921H11.5M10.6667 7.71255H11.5M7.33333 16.0459V11.8792C7.33333 11.419 7.70643 11.0459 8.16667 11.0459H9.83333C10.2936 11.0459 10.6667 11.419 10.6667 11.8792V16.0459M7.33333 16.0459H10.6667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

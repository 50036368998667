import { memo, useCallback, useState } from "react";
import { Modal } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { IOrderItemProps } from "../../../views/OrderItem";
import { tw } from "../../../../../utils/tw";
import AppBarFS from "../../../../../components/app-bar/AppBar";
import "swiper/css";
import "swiper/css/pagination";
import PreviewAndZoomImageCarouselModal from "../../../../../components/common/ViewImageAndPreviewCarousel/PreviewAndZoomImageCarouselModal";
import ZoomImageSvg from "../../../../../assets/svg/ZoomImage";
import { useNavigate, useParams } from "react-router-dom";

type TImage = { id: string; url: string };

interface ISummaryModalPageProps {
  isOpen: boolean;
  onClose: () => void;
  images: (TImage | File)[];
  orderDetail?: IOrderItemProps;
}

const classes = {
  wrapper: tw(`overflow-scroll`),
  container: tw(`min-h-screen w-full flex flex-col bg-[white] pb-[24px]`),
  appbarContainer: tw(`py-4`),
  headerContainer: tw(
    `mb-[16px] px-[16.5px] text-[18px] leading-[21.6px] font-semibold flex flex-row items-center justify-between`
  ),
  label: tw(
    `border-[1px] border-[#2C702F] rounded-full font-normal flex justify-center items-center text-[14px] leading-[19.6px] text-[#2C702F] py-[2px] px-[8px]`
  ),
  swiperWrapper: tw(`[&_.swiper-wrapper]:items-center`),
  swiperContainer: tw(`px-[45px] relative pb-[24px]`),
  swiperImage: tw(`w-full h-auto object-contain max-w-[450px] m-auto`),
  danger: tw(
    `text-status-bad font-semibold text-[14px] leading-[21px] whitespace-pre-line text-center`
  ),
  actionContainer: tw(
    `flex flex-col gap-[16px] mt-[16px] max-w-[450px] px-[16px] w-full mx-auto`
  ),
  button: tw(
    `bg-secondary border-[1px] border-secondary flex justify-center items-center text-center text-[white] text-[16px] leading-[25.6px] font-bold w-full py-[16.5px] rounded-full hover:brightness-105 duration-150 transition-all`
  ),
};

const SummaryModalPage = (props: ISummaryModalPageProps) => {
  const { isOpen, onClose, images, orderDetail } = props;
  const navigate = useNavigate();

  const { orderId } = useParams();

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [slideIndex, setSlideIndex] = useState<number>(0);

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const onDownload = useCallback(
    (base64: any) => {
      var a = document.createElement("a") as any;
      a.href = base64;
      a.download = (images as any)[slideIndex].name;
      a.click();
    },
    [images]
  );

  const onCloseFn = useCallback(() => {
    onClose();
    navigate(`/order/detail/${orderId}`);
  }, []);

  return (
    <Modal open={isOpen} className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.appbarContainer}>
          <AppBarFS title="หลักฐานการจัดส่ง" onIconPress={onCloseFn} />
        </div>
        <div className={classes.headerContainer}>
          หมายเลขสั่งซื้อ {orderDetail?.orderNo}
          <div className={classes.label}>จัดส่งสำเร็จ</div>
        </div>
        <Swiper
          pagination
          modules={[Pagination]}
          loop
          className={classes.swiperWrapper}
          initialSlide={slideIndex}
          onActiveIndexChange={(event) => {
            setSlideIndex(event.realIndex);
          }}
        >
          {images.map((image, index) => {
            const src =
              image instanceof File ? URL.createObjectURL(image) : image.url;

            return (
              <SwiperSlide
                key={`${index}`}
                className={classes.swiperContainer}
                onClick={() => setIsOpenModal(true)}
              >
                <div className="absolute top-[45%] bottom-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2">
                  <div className="w-8 h-8">
                    <ZoomImageSvg />
                  </div>
                </div>
                <img src={src} className={classes.swiperImage} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="flex flex-1" />
        <div className={classes.danger}>
          {`หากไม่ได้กดบันทึกเอกสารการขาย\nคุณสามารถกลับมาดูย้อนหลังได้ที่เมนูประวัติคำสั่งซื้อ`}
        </div>
        <div className={classes.actionContainer}>
          <button
            onClick={async () => {
              if (images.length > 0) {
                // @ts-ignore
                const base64 = await toBase64(images[slideIndex]);
                onDownload(base64);
              }
            }}
            className={tw(classes.button)}
          >
            บันทึกรูปภาพ
          </button>
          <button
            onClick={onCloseFn}
            className={tw(classes.button, "bg-[white] text-secondary")}
          >
            ปิด
          </button>
        </div>
        <PreviewAndZoomImageCarouselModal
          isOpen={isOpenModal}
          initIndex={slideIndex}
          onClose={() => {
            setIsOpenModal(false);
          }}
          images={images}
        />
      </div>
    </Modal>
  );
};

export default memo(SummaryModalPage);

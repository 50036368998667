import { TDropdownValue } from "../../../../group-area/views/Dropdown";

export type TFormTabApprove = {
  grade: TDropdownValue[];
  statusCredit: TDropdownValue[];
};

export const defaultFormTabApprove: TFormTabApprove = {
  grade: [],
  statusCredit: [],
};

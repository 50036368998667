import { Checkbox, Link, TableCell } from "@mui/material";
import { useEffect, useState } from "react";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../../components/Button/ButtonOutlinedCustom";
import TableCustom, {
  ExpandableTableRow,
  ITableCustomHeadCell,
} from "../../../../components/Table/TableCustom";
import { useMoneyDebtFilter } from "../hooks/useMoneyDebtFilter.hook";
import { useNotAcceptDebtTableHook } from "../hooks/useNotAcceptDebtTable.hook";
import { AcceptDebtRow } from "../types/acceptDebt.type";
import { TNotAcceptDebtTable } from "../types/notAcceptDebt.type";
import ExpandTableMoneyDebt from "./ExpandTableMoneyDebt/ExpandTableMoneyDebt";
import { MoneyDebtSearch } from "./MoneyDeptSearch";
import UpdateDetailNotAcceptModal from "./Modals/UpdateDetailNotAcceptModal";
import CloseDebtNotAcceptModal from "./Modals/CloseDebtNotAcceptModal";
import AcceptDebtModal from "./Modals/AcceptDebtModal";

type Props = {
  tab: string;
  showSelect?: boolean;
  fetchCount: () => void;
};

const NotAcceptDebtTab = ({ tab, showSelect, fetchCount }: Props) => {
  const searchMoneyDebtFilter = useMoneyDebtFilter();
  const {
    list,
    itemSelected,
    setItemSelected,
    isSelected,
    handleClick,
    handleExportExcel,
    handleFetch,
    handleSelectAllClick,
    count,
    debtInstallment,
  } = useNotAcceptDebtTableHook(searchMoneyDebtFilter);

  const [isOpenUpdateDetail, setIsOpenUpdateDetail] = useState(false);
  const [isOpenCloseDebt, setIsOpenCloseDebt] = useState(false);
  const [isOpenAcceptDebt, setIsOpenAcceptDebt] = useState(false);
  // const [moneyDebtFile, setMoneyDebtFile] = useState<AcceptDebtRow>();
  const [invoiceId, setInvoiceId] = useState("");

  const headCells: ITableCustomHeadCell<keyof TNotAcceptDebtTable>[] = [
    {
      id: "orderId",
      numeric: false,
      disablePadding: false,
      label: "หมายเลขสั่งซื้อ",
      width: "150px",
      isSorting: false,
    },
    {
      id: "customerId",
      numeric: false,
      disablePadding: false,
      label: "รหัสลูกค้า",
      width: "150px",
      isSorting: false,
    },
    {
      id: "customerName",
      numeric: false,
      disablePadding: false,
      label: "ชื่อลูกค้า",
      width: "150px",
      isSorting: false,
    },
    {
      id: "invoiceNumber",
      numeric: false,
      disablePadding: false,
      label: "เลข Invoice",
      width: "150px",
      isSorting: false,
    },
    {
      id: "unPaidAmount",
      numeric: false,
      disablePadding: false,
      label: "ยอดเงินที่ต้องชำระ",
      width: "150px",
      isSorting: false,
    },
    {
      id: "dueDate",
      numeric: false,
      disablePadding: false,
      label: "วันครบกำหนดชำระ",
      width: "150px",
      isSorting: false,
    },
    {
      id: "updateDate",
      numeric: false,
      disablePadding: false,
      label: "วันที่อัปเดตล่าสุด",
      width: "150px",
      isSorting: true,
    },
    {
      id: "updateDate",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "80px",
      isSorting: false,
    },
    {
      id: "updateDate",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "80px",
      isSorting: false,
    },
    {
      id: "updateDate",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "100px",
      isSorting: false,
    },
    {
      id: "updateDate",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "20px",
      isSorting: false,
    },
  ];

  const filteredHeadCells =
    tab === "finished" ? headCells.toSpliced(7, 3) : headCells;

  const fetchDataAfterSubmit = () => {
    handleFetch();
    fetchCount();
  };

  useEffect(() => {
    searchMoneyDebtFilter.setTab(tab);
    if (searchMoneyDebtFilter.tab) {
      handleFetch();
    }
  }, [
    searchMoneyDebtFilter.direction,
    searchMoneyDebtFilter.page,
    tab,
    searchMoneyDebtFilter.tab,
  ]);

  return (
    <>
      <MoneyDebtSearch
        showSelect={showSelect}
        searchMoneyDeptFilter={searchMoneyDebtFilter}
        itemSelected={itemSelected}
        onExport={handleExportExcel}
        onSearch={handleFetch}
      />
      <div className="flex w-full pt-6">
        <TableCustom
          order={searchMoneyDebtFilter.direction}
          orderBy={""}
          rowsPerPage={10}
          rows={list}
          itemsSelected={itemSelected}
          headerItems={filteredHeadCells}
          page={searchMoneyDebtFilter.page}
          onChangeOrderBy={() => {}}
          onChangeOrder={(val) => {
            searchMoneyDebtFilter.setDirection(val);
          }}
          onChangePage={(val) => searchMoneyDebtFilter.setPage(val)}
          onSelectAllClick={(val) => handleSelectAllClick(val)}
          onSelectItem={(val) => setItemSelected(val as string[])}
          totalItemSize={count}
          currentPageItems={list.map((row) => row.id)}
          rowChildren={
            <>
              {list.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <ExpandableTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.orderId}
                    selected={isItemSelected}
                    expandComponent={
                      debtInstallment && (
                        <ExpandTableMoneyDebt
                          colSpan={18}
                          dataExpand={debtInstallment[index]}
                          isStatusFullAmount={true}
                        />
                      )
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(event) => handleClick(event, row.id)}
                        sx={{
                          borderWidth: "1px",
                          color: "#C9C9C9",
                          "&.Mui-checked": {
                            color: "#68C184",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        padding: "0px 16px",
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.orderId}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                        }}
                      >
                        {row.customerId}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.customerName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.invoiceNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.unPaidAmount}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.dueDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.updateDate}
                    </TableCell>
                    {tab !== "finished" && (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        <Link
                          component="button"
                          variant="body2"
                          sx={{
                            color: "#68C184",
                            textDecorationColor: "#68C184",
                          }}
                          onClick={() => {
                            setIsOpenAcceptDebt(true);
                            setInvoiceId(row.id);
                          }}
                        >
                          รับสภาพหนี้
                        </Link>
                      </TableCell>
                    )}
                    {tab !== "finished" && (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        <ButtonOutlinedCustom
                          btnBorderColor="#68C184"
                          btnTextColor="#68C184"
                          title={"ปิดเคส"}
                          onClick={() => {
                            setIsOpenCloseDebt(true);
                            setInvoiceId(row.id);
                          }}
                        />
                      </TableCell>
                    )}
                    {tab !== "finished" && (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        <ButtonFillCustom
                          title={"อัปเดตข้อมูล"}
                          onClick={() => {
                            setIsOpenUpdateDetail(true);
                            setInvoiceId(row.id);
                          }}
                        />
                      </TableCell>
                    )}
                  </ExpandableTableRow>
                );
              })}
            </>
          }
        ></TableCustom>
      </div>
      <UpdateDetailNotAcceptModal
        isOpen={isOpenUpdateDetail}
        onClose={() => {
          setIsOpenUpdateDetail(false);
        }}
        fetchData={fetchDataAfterSubmit}
        invoiceId={invoiceId}
      />
      <CloseDebtNotAcceptModal
        isOpen={isOpenCloseDebt}
        onClose={() => {
          setIsOpenCloseDebt(false);
          handleFetch();
        }}
        fetchData={fetchDataAfterSubmit}
        invoiceId={invoiceId}
      />
      <AcceptDebtModal
        isOpen={isOpenAcceptDebt}
        onClose={() => {
          setIsOpenAcceptDebt(false);
        }}
        fetchData={fetchDataAfterSubmit}
        invoiceId={invoiceId}
      />
    </>
  );
};

export default NotAcceptDebtTab;

import axios, { AxiosInstance, AxiosResponse } from "axios";

import { router } from "../App";
import { deleteItem, getItem } from "./StorageService";
import { AUTH_TOKEN_KEY } from "../const/app.constant";
import { decodeJWTToken } from "../utils/decode-jwt";
// Define the base URL for your API
const BASE_URL = `${
  process.env.REACT_APP_BASE_API_URL || window.location.origin
}/api/`;
// Create an instance of Axios with the base URL
const api: AxiosInstance = axios.create({
  baseURL: BASE_URL,
});

// Set the default headers for the requests
api.defaults.headers.common["Content-Type"] = "application/json";

// Interceptor for adding the token to requests
api.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY); // Retrieve the token from wherever you have stored it
    if (token && !config.disableToken) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor for handling token expiration or authentication errors
api.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      console.error("Unauthorized access or token expired");
      // TODO: See if this can be done in better way
      const decodedToken = decodeJWTToken(getItem(AUTH_TOKEN_KEY));
      if (decodedToken && decodedToken.isLoggedIn) {
        deleteItem(AUTH_TOKEN_KEY);
        router.navigate("/login");
      }
    }
    return error.response;
  }
);

// Define the API service functions
// const ApiService = {
//     getUsers: (): Promise<AxiosResponse> => api.get('/users'),
//     getPosts: (): Promise<AxiosResponse> => api.get('/posts'),
//     // Add more API functions here as needed
// };

export default api;

import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import SuccessCard from "../../../components/common/SuccessCard";

export default function EditHouseSuccess() {
  let navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    // if (!state && !state?.id) {
    //     navigate('/welcome');
    // }
  }, []);

  return (
    <SuccessCard
      title="Saved!"
      buttonLabel="Manage House"
      onButtonPress={() => navigate(`/house/${state.houseId}`)}
      subtitle1="We saved your change Your information is safe with us"
    />
  );
}

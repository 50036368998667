const BankSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} fill="none">
    <g fill="#4B4B4B" clipPath="url(#a)">
      <path d="m3.497 13.56 1.5-.88V16h26v-3.29l1.48.85a1 1 0 0 0 1-1.73l-15.48-8.91-15.5 8.91a1 1 0 1 0 1 1.73Zm14.35-6.45a.8.8 0 0 1 .8 0l6.72 3.89h-3.22l-3.9-2.24-3.9 2.24h-3.21l6.71-3.89ZM32.85 27H32v-.85A1.15 1.15 0 0 0 30.85 25H28v-7.37h-4V25h-4v-7.37h-4V25h-4v-7.37H8V25H5.15A1.15 1.15 0 0 0 4 26.15V27h-.85A1.15 1.15 0 0 0 2 28.15V31h32v-2.85A1.15 1.15 0 0 0 32.85 27Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h36v36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default BankSvg;

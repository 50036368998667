import { useNavigate, useParams } from "react-router-dom";
import AppBarFS from "../../../components/app-bar/AppBar";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import DollarWithCoinSvg from "../../../assets/svg/DollarWithCoinSvg";
import DollarWithCalculatorSvg from "../../../assets/svg/DollarWithCalculatorSvg";
import DollarWithCalculatorSvgBlue from "../../../assets/svg/DollarWithCalculatorSvgBlue";
import DollarWithCoinSvgBlue from "../../../assets/svg/DollarWithCoinSvgBlue";
import { Divider } from "@mui/material";
import { getCommaNumber } from "../../../utils/number";
import {
  Dropdown,
  TDropdownValue,
} from "../../../admin/pages/group-area/views/Dropdown";
import dayjs from "dayjs";
import ButtonOutlinedCustom from "../../../admin/components/Button/ButtonOutlinedCustom";
import PencilAltSize20pxSvg from "../../../assets/svg/PencilAltSize20pxSvg";
import DocumentSize20pxSvg from "../../../assets/svg/DocumentSize20pxSvg";
import { useBasicAccountingController } from "./controller";
import { tw } from "../../../utils/tw";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";

const BasicAccounting = () => {
  const { farmId } = useParams();

  const navigate = useNavigate();

  const { form, onChangeForm, summaryAllMonths, summaryCurrentMonth } =
    useBasicAccountingController(farmId || "");

  const monthNamesThai = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];

  const [selectMonth, setSelectMonth] = useState<TDropdownValue[]>([
    {
      label: monthNamesThai[dayjs().month()],
      value: (dayjs().month() + 1).toString(),
    },
  ]);

  useEffect(() => {
    if (selectMonth) {
      onChangeForm("month")(selectMonth);
    }
  }, [selectMonth]);

  return (
    <div
      style={{
        height: "auto !important",
        backgroundSize: "cover",
        backgroundColor: "#68C184",
      }}
      className="!h-auto !min-h-screen flex flex-col"
    >
      <div className="h-[120px] flex items-center bg-[#FFFF] rounded-b-[40px]">
        <AppBarFS title={"บัญชี"} onIconPress={() => navigate(-1)} />
      </div>
      <div className="flex flex-col gap-6 pt-6 pb-4 px-4 font-sans">
        <div className="text-[#FFFF] font-semibold text-[20px] py-2">
          เดือนมกราคม - ปัจจุบัน ปี {dayjs().year() + 543}
        </div>
        <div className="flex flex-col gap-3">
          <div
            className="bg-[#F0FDF4] p-4 gap-3 rounded-[20px]"
            style={{
              boxShadow: "4px 4px 20px 0px #0000001A",
            }}
          >
            <div className="flex flex-col gap-3 font-sans justify-center items-center">
              <div className="flex flex-row">
                <div
                  className={tw(
                    "text-[30px] font-medium ",

                    (summaryAllMonths?.totalIncome || 0) -
                      (summaryAllMonths?.totalExpense || 0) >=
                      0
                      ? "text-[#68C184]"
                      : "text-error-300"
                  )}
                >
                  {(summaryAllMonths?.totalIncome || 0) -
                    (summaryAllMonths?.totalExpense || 0) >=
                  0
                    ? "+"
                    : "-"}
                  {getCommaNumber(
                    (summaryAllMonths?.totalIncome || 0) -
                      (summaryAllMonths?.totalExpense || 0)
                  )}
                </div>
                <div className="flex flex-col justify-center items-center">
                  <ArrowDropUpIcon
                    className="text-[#68C184] -mt-[10px]"
                    fontSize="large"
                  />
                  <div className="text-[#34407B] text-[12px] font-semibold -mt-[15px]">
                    บาท
                  </div>
                </div>
              </div>
              <div className="text-[#646464] font-medium text-[12px]">
                กำไร/ขาดทุนของฟาร์ม
              </div>
            </div>
          </div>
          <div className="flex flex-row py-4 px-3 justify-evenly">
            <div className="flex flex-col gap-[6px] px-4">
              <DollarWithCalculatorSvg />
              <div className="flex flex-col gap-[6px]">
                <div className="flex flex-row">
                  <div className="text-[18px] font-medium text-[#FFFFFF]">
                    {getCommaNumber(summaryAllMonths?.totalIncome || 0)}
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <ArrowDropUpIcon
                      className="text-[#3777BC] -mt-[10px]"
                      fontSize="large"
                    />
                    <div className="text-[#FFFFFF] text-[12px] font-semibold -mt-[15px]">
                      บาท
                    </div>
                  </div>
                </div>
                <div className="text-[12px] text-[#FFFFFF] font-normal whitespace-pre">
                  รายได้ทั้งหมดของฟาร์ม
                </div>
              </div>
            </div>
            <Divider
              orientation="vertical"
              variant="fullWidth"
              flexItem
              sx={{
                borderColor: "#FFFFFF",
              }}
            />
            <div className="flex flex-col gap-[6px] px-4">
              <DollarWithCoinSvg />
              <div className="flex flex-col gap-[6px]">
                <div className="flex flex-row">
                  <div className="text-[18px] font-medium text-[#FFFFFF]">
                    {getCommaNumber(summaryAllMonths?.totalExpense || 0)}
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <ArrowDropUpIcon
                      className="text-[#3777BC] -mt-[10px]"
                      fontSize="large"
                    />
                    <div className="text-[#FFFFFF] text-[12px] font-semibold -mt-[15px]">
                      บาท
                    </div>
                  </div>
                </div>
                <div className="text-[12px] text-[#FFFFFF] font-normal whitespace-pre">
                  ค่าใช้จ่ายทั้งหมด
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#FFFF] px-4 py-6 rounded-t-[40px] flex flex-col gap-4 h-full flex-1">
        <div className="flex flex-row items-center justify-between">
          <div className="text-[#68C184] text-[16px] font-semibold">
            ปี {dayjs().year() + 543}
          </div>
          <div>
            <Dropdown
              options={[
                { label: "มกราคม", value: "1" },
                { label: "กุมภาพันธ์", value: "2" },
                { label: "มีนาคม", value: "3" },
                { label: "เมษายน", value: "4" },
                { label: "พฤษภาคม", value: "5" },
                { label: "มิถุนายน", value: "6" },
                { label: "กรกฎาคม", value: "7" },
                { label: "สิงหาคม", value: "8" },
                { label: "กันยายน", value: "9" },
                { label: "ตุลาคม", value: "10" },
                { label: "พฤศจิกายน", value: "11" },
                { label: "ธันวาคม", value: "12" },
              ]}
              placeholder="เดือน"
              onChange={(value) => setSelectMonth(value)}
              renderValueArray={selectMonth}
              choseStyle2
              containerDropdown="pb-0 text-center"
            />
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-[6px] font-sans justify-center items-center">
            <div className="flex flex-row">
              <div
                className={tw(
                  "text-[30px] font-medium ",

                  (summaryCurrentMonth?.totalIncome || 0) -
                    (summaryCurrentMonth?.totalExpense || 0) >=
                    0
                    ? "text-[#68C184]"
                    : "text-error-300"
                )}
              >
                {(summaryCurrentMonth?.totalIncome || 0) -
                  (summaryCurrentMonth?.totalExpense || 0) >=
                0
                  ? "+"
                  : "-"}
                {getCommaNumber(
                  (summaryCurrentMonth?.totalIncome || 0) -
                    (summaryCurrentMonth?.totalExpense || 0)
                )}
              </div>
              <div className="flex flex-col justify-center items-center">
                <ArrowDropUpIcon
                  className="text-[#68C184] -mt-[10px]"
                  fontSize="large"
                />
                <div className="text-[#34407B] text-[12px] font-semibold -mt-[15px]">
                  บาท
                </div>
              </div>
            </div>
            <div className="text-[#646464] font-medium text-[12px]">
              กำไร/ขาดทุนของฟาร์ม
            </div>
          </div>
          <div className="flex flex-row py-4 px-3 justify-evenly border-[#97BFEA] border-[1px] rounded-[20px] min-w-max">
            <div className="flex flex-col gap-[6px] px-4">
              <DollarWithCalculatorSvgBlue />
              <div className="flex flex-col gap-[6px]">
                <div className="flex flex-row">
                  <div className="text-[18px] font-medium text-[#34407B]">
                    {getCommaNumber(summaryCurrentMonth?.totalIncome || 0)}
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <ArrowDropUpIcon
                      className="text-[#68C184] -mt-[10px]"
                      fontSize="large"
                    />
                    <div className="text-[#191919] text-[12px] font-semibold -mt-[15px]">
                      บาท
                    </div>
                  </div>
                </div>
                <div className="text-[12px] text-[#646464] font-normal whitespace-pre">
                  รายได้ทั้งหมดของฟาร์ม
                </div>
              </div>
            </div>
            <Divider
              orientation="vertical"
              variant="fullWidth"
              flexItem
              sx={{
                borderColor: "#97BFEA",
              }}
            />
            <div className="flex flex-col gap-[6px] px-4">
              <DollarWithCoinSvgBlue />
              <div className="flex flex-col gap-[6px]">
                <div className="flex flex-row">
                  <div className="text-[18px] font-medium text-[#34407B]">
                    {getCommaNumber(summaryCurrentMonth?.totalExpense || 0)}
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <ArrowDropUpIcon
                      className="text-[#68C184] -mt-[10px]"
                      fontSize="large"
                    />
                    <div className="text-[#191919] text-[12px] font-semibold -mt-[15px]">
                      บาท
                    </div>
                  </div>
                </div>
                <div className="text-[12px] text-[#646464] font-normal whitespace-pre">
                  ค่าใช้จ่ายทั้งหมด
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center gap-[10px] px-6">
          <ButtonOutlinedCustom
            title="กรอกข้อมูล"
            className="!h-[30px] !px-[14px] !py-[5px] !rounded-[50px]"
            btnBorderColor="#68C184"
            startIcon={<PencilAltSize20pxSvg />}
            onClick={() =>
              navigate(`/farm/${farmId}/basic-accounting/save-accounting`, {
                state: form,
              })
            }
          />
          <ButtonOutlinedCustom
            title="ดูข้อมูลทั้งหมด"
            className="!h-[30px] !px-[14px] !py-[5px] !rounded-[50px]"
            btnBorderColor="#68C184"
            startIcon={<DocumentSize20pxSvg />}
            onClick={() =>
              navigate(`/farm/${farmId}/basic-accounting/see-details`)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default BasicAccounting;

import { CircularProgress, Typography } from "@mui/material";
import { ICountdownTime } from "./type";
import { useEffect, useState } from "react";

export function CountdownTime({ duration, onComplete }: ICountdownTime) {
  const [remainingSeconds, setRemainingSeconds] = useState(duration);

  useEffect(() => {
    if (remainingSeconds <= 0) {
      onComplete?.();
      return;
    }

    const timeInterval = setInterval(() => {
      setRemainingSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => {
      clearInterval(timeInterval);
    };
  }, [remainingSeconds]);

  const formatTime = (time: number) => {
    return time.toString().padStart(2, "0");
  };
  return (
    <div style={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        sx={{ color: "#D9EBFE" }}
        variant="determinate"
        thickness={22}
        value={Math.floor((remainingSeconds / 60) * 100)}
      />
      <Typography
        variant="h6"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {`${Math.round(Math.floor(remainingSeconds))}`}
      </Typography>
    </div>
  );
}

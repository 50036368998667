import { Order } from "../../../pages/farm-past-performance/types";
import { IGetMyOrderByID, IMyOrder } from "../../../types/MyOrderFeed.type";
import api from "../../ApiService";

export const getOrderHistory = async (
  farmId: string,
  orderBy: Order = "desc",
  startDate: string = "",
  endDate: string = "",
  nextPageCursor: string = ""
) => {
  try {
    const baseURL = "/farmers/" + farmId + "/order-history";
    const params = new URLSearchParams();
    params.append("direction", orderBy);
    params.append("startDate", startDate);
    params.append("endDate", endDate);
    params.append("nextPageCursor", nextPageCursor);
    const { data } = await api.get<IMyOrder>(baseURL + "?" + params.toString());
    return data;
  } catch (error) {
    return null;
  }
};

export const getOrderHistoryDetailById = async (
  farmId: string,
  orderFeedId: string
) => {
  try {
    const { data } = await api.get<IGetMyOrderByID>(
      "/farmers/" + farmId + "/order-history/" + orderFeedId
    );

    return data;
  } catch (error) {
    return null;
  }
};

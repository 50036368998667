const FeedPigSvgWithBg = () => (
  <div
    className="w-[54px] h-[54px] rounded-[10px] gap-[10px] p-[10px]"
    style={{
      background:
        "linear-gradient(90deg, #3777BC 0%, #68C184 71.01%, #77F2A1 100%)",
      boxShadow: "4px 4px 20px 0px #0000001A",
    }}
  >
    <svg xmlns="http://www.w3.org/2000/svg" width={35} height={34} fill="none">
      <path
        fill="#fff"
        d="M7.572.166c-.16.153-.166.193-.166 1.063v.896h20.188v-.883c0-.797-.014-.897-.133-1.03-.266-.278-.425-.265-1.242.147l-.75.371-.73-.365c-.4-.199-.791-.365-.864-.365-.073 0-.465.166-.863.365l-.73.365-.731-.365C21.152.166 20.76 0 20.688 0c-.073 0-.465.166-.864.365l-.73.365-.73-.365C17.963.166 17.572 0 17.5 0c-.073 0-.465.166-.863.365l-.73.365-.731-.365C14.777.166 14.386 0 14.312 0c-.072 0-.464.166-.863.365l-.73.365-.73-.365c-.4-.199-.791-.365-.864-.365-.073 0-.465.166-.863.365l-.73.365L8.8.365C8.402.166 7.997 0 7.904 0a.547.547 0 0 0-.332.166ZM26.367 4.328c-1.46 1.753-1.925 2.41-2.39 3.34-.65 1.302-.598.525-.618 8.899-.02 4.954 0 7.517.047 7.83.206 1.354.81 2.43 2.663 4.767.57.71 1.129 1.435 1.255 1.6.12.173.239.313.259.306.02 0 .166-.173.312-.378.152-.206.717-.89 1.262-1.508.537-.624 1.142-1.348 1.34-1.62.472-.638.964-1.567 1.156-2.198l.153-.498.02-7.584c.02-8.394.033-8.028-.412-9.064-.272-.631-.677-1.322-1.255-2.125-.465-.644-2.517-3.175-2.576-3.175-.02 0-.571.638-1.215 1.408Z"
      />
      <path
        fill="#fff"
        d="M6.435 4.204c-1.421 1.78-2.125 2.961-2.53 4.263l-.186.597v15.54l.186.597c.206.677.657 1.654 1.056 2.291.146.24.73 1.083 1.301 1.88l1.036 1.44h9.35c7.47 0 9.35-.02 9.31-.086-.033-.04-.458-.59-.956-1.208-1.355-1.694-1.986-2.723-2.364-3.839-.359-1.056-.359-1.002-.359-8.872.007-7.948-.006-7.756.399-8.845.425-1.182 1.295-2.464 3.021-4.496l.246-.279h-9.357l-9.35.007-.803 1.01Zm.511 5.65c.604.213 1.102.559 1.654 1.15l.464.498.545-.233a7.89 7.89 0 0 1 6.202-.007l.552.24.617-.625c.498-.498.724-.677 1.149-.89.511-.252.551-.258 1.208-.258.618 0 .684.013.844.159.319.299.465.817.465 1.633.006.983-.2 1.561-.97 2.776-.226.346-.319.551-.285.631.026.06.08.312.132.558l.087.451h-.877c-.91.007-1.01.034-1.162.32-.106.198-.046.43.14.61.132.12.232.133 1.055.133h.91l.306.339c.425.471.637.936.684 1.507a3.2 3.2 0 0 1-2.404 3.367c-.339.086-1.122.1-5.545.093-4.642 0-5.187-.013-5.585-.113-1.813-.478-2.882-2.57-2.125-4.164.106-.219.325-.538.498-.717L5.811 17h.896c.87 0 .91-.007 1.063-.166a.488.488 0 0 0 0-.73c-.153-.16-.193-.166-1.03-.166-.99 0-.93.053-.757-.698l.113-.465-.365-.564c-.73-1.129-.95-1.74-.943-2.69 0-.876.239-1.5.657-1.746.233-.133 1.016-.093 1.501.08Z"
      />
      <path
        fill="#fff"
        d="M5.914 10.857c-.232.618.02 1.767.572 2.584l.166.239.205-.286a6.744 6.744 0 0 1 1.003-1.056l.286-.245-.246-.306a4.046 4.046 0 0 0-.578-.538c-.558-.412-1.315-.624-1.408-.392ZM18.808 10.857c-.14.054-.345.16-.465.24-.299.205-.976.87-.976.956 0 .04.186.239.418.445.226.199.545.55.711.777l.299.405.113-.146c.206-.266.564-1.07.65-1.441.08-.346.06-1.116-.026-1.262-.066-.1-.412-.086-.724.026ZM11.039 14.94c-1.302.325-2.225 1.713-1.986 2.988.166.916.704 1.62 1.528 2.012l.445.212h1.66c1.866 0 2.065-.04 2.67-.498a2.64 2.64 0 0 0 .006-4.25c-.604-.458-.77-.491-2.51-.504-.857-.014-1.674.006-1.813.04Zm.983 2.224c.465.458-.206 1.209-.684.764-.186-.18-.246-.412-.14-.611.186-.352.558-.425.824-.153Zm2.125 0c.465.458-.206 1.209-.684.764-.186-.18-.246-.412-.14-.611.186-.352.558-.425.824-.153ZM7.406 32.758c0 .797.014.897.133 1.03.266.278.425.265 1.242-.147l.75-.371.73.365c.4.199.791.365.864.365.073 0 .465-.166.863-.365l.73-.365.731.365c.399.199.79.365.864.365.072 0 .464-.166.863-.365l.73-.365.73.365c.4.199.791.365.864.365.073 0 .465-.166.863-.365l.73-.365.731.365c.399.199.79.365.863.365.073 0 .465-.166.864-.365l.73-.365.73.365c.4.199.791.365.864.365.073 0 .465-.166.863-.365l.73-.365.751.371c.817.412.976.425 1.242.147.12-.133.133-.233.133-1.03v-.883H7.406v.883Z"
      />
    </svg>
  </div>
);
export default FeedPigSvgWithBg;

import { Order } from "../pages/feed-order-and-delivery/my-order/my-order-component/type";
import { IDownloadUrl } from "../types/ManageStock.type";
import { IGetMyOrderByID, IMyOrder } from "../types/MyOrderFeed.type";
import api from "./ApiService";

export const getMyOrder = async (farmId: string, orderBy: Order = "desc") => {
  try {
    const baseURL = "/farmers/" + farmId + "/order-feed";
    const params = new URLSearchParams();
    params.append("direction", orderBy);
    const { data } = await api.get<IMyOrder>(baseURL + "?" + params.toString());
    return data;
  } catch (error) {
    return null;
  }
};

export const getMyOrderNextPage = async (
  farmId: string,
  orderBy: Order = "desc",
  nextPage: string
): Promise<IMyOrder> => {
  try {
    const baseURL = "/farmers/" + farmId + "/order-feed";
    const params = new URLSearchParams();
    params.append("direction", orderBy);
    params.append("nextPageCursor", nextPage);
    const { data } = await api.get<IMyOrder>(baseURL + "?" + params.toString());
    if (!data) {
      return {
        success: true,
        result: { orderFeeds: [], nextPageCursor: "" },
      };
    }
    return data;
  } catch (error) {
    return {
      success: true,
      result: { orderFeeds: [], nextPageCursor: "" },
    };
  }
};

export const getDownloadUrlOrderFarmer = async (
  orderNumber: string,
  documentType: string,
  fileId: string
) => {
  const { data } = await api.get<IDownloadUrl>(
    `farmers/order-feed/download/${orderNumber}/${documentType}/${fileId}`
  )
  return data
}

export const getMyOrderDetailById = async (orderFeedId: string) => {
  try {
    const response = await api.get<IGetMyOrderByID>(
      "/farmers/order-feed/" + orderFeedId
    );

    if (![200, 201].includes(response.status)) {
      throw new Error();
    }

    return response.data;
  } catch (error) {
    return null;
  }
};

export const putConfirmReceive = async (orderId: string) => {
  return api.put(`/farmers/order-feed/${orderId}/confirm-receive`);
};

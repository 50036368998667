import AccordionCreditCustom from "../../../components/AccordionCreditCustom";
import SegmentWeightInput from "../../../components/SegmentWeightInput";
import TableCreditCustom from "../../../components/TableCreditCustom";

type FarmPerformanceAccordionProps = {
  viewAction: boolean;
};

const FarmPerformanceAccordion = ({
  viewAction,
}: FarmPerformanceAccordionProps) => {
  return (
    <AccordionCreditCustom
      title={"Farm Performance"}
      rowChildren={
        <>
          <SegmentWeightInput
            name={"farmPerformance"}
            viewAction={viewAction}
          />
          <TableCreditCustom
            isDisplayAddRow={true}
            name={"farmPerformance"}
            viewAction={viewAction}
          />
        </>
      }
    />
  );
};

export default FarmPerformanceAccordion;

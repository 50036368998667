export default function PaymentSVG() {
  return (
    <svg
      width="77"
      height="77"
      viewBox="0 0 77 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="77" height="77" rx="20" fill="#68C184" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 25C21.3954 25 20.5 25.8954 20.5 27V41C20.5 42.1046 21.3954 43 22.5 43H25V29.5H47.5V27C47.5 25.8954 46.6046 25 45.5 25H22.5ZM29.5 36C29.5 34.8954 30.3954 34 31.5 34H54.5001C55.6046 34 56.5001 34.8954 56.5001 36V50C56.5001 51.1046 55.6046 52 54.5001 52H31.5C30.3954 52 29.5 51.1046 29.5 50V36ZM43 47.5C45.4853 47.5 47.5 45.4853 47.5 43C47.5 40.5147 45.4853 38.5 43 38.5C40.5147 38.5 38.5 40.5147 38.5 43C38.5 45.4853 40.5147 47.5 43 47.5Z"
        fill="white"
      />
    </svg>
  );
}

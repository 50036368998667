import { TInvoiceOverdueStatus } from "../../admin/pages/invoice/type";

export interface InvoiceLog {
  string: string;
  date: Date;
  rows: InvoiceLogRow[];
}

export interface InvoiceLogRow {
  id: string;
  logType: string;
  logDetail: LogDetail;
  createdAt: string;
  updatedAt: string;
  user: User;
  partner: string;
  invoiceLogFiles: InvoiceLogFile[];
}

export interface InvoiceLogFile {
  id: string;
  fileOriginalName: string;
  documentType: string;
}

export interface LogDetail {
  //update_document
  invoiceNumber: string;
  isUseRemainingCredit?: boolean;
  //create_assignment
  assignmentNumber: string;
  //follow_invoice
  overdueStatus: TInvoiceOverdueStatus;
  paidAmount?: number;
  appointmentDate?: string;
  invoiceNumbers?: string[];
  debtStatus?: EInvoiceDebtStatus;
  debtPayPerMonth?: number;
  debtNumberOfMonth?: number;
  debtPaymentDate?: string;
  debtPaymentOption?: EInvoiceDebtPaymentOption;
  isCorrectPrice?: boolean;
  // lending_verify_payment
  pending: boolean;
  // invoiceNumber: string;
  // paidAmount: number;
  verifyAmount: number;
  //lending_revert_status
  detail: string;
  //partner_update_payment
  // overdueStatus: EInvoiceOverdueStatus;
  // paidAmount?: number;
  // appointmentDate?: Date;
  // invoiceNumbers?: string[];

  //manager_verify_assignment
  isApprove: boolean;
}

export interface User {
  id: string;
  name: string;
  surname: string;
  employeeCode: string;
  userRole: string;
}

export enum EInvoiceDebtStatusDisplay {
  accept_debt = "รับสภาพหนี้",
  not_accept_debt = "ไม่รับสภาพหนี้",
}
export enum EInvoiceDebtStatus {
  accept_debt = "accept_debt",
  not_accept_debt = "not_accept_debt",
}

export enum EInvoiceDebtPaymentOption {
  full_amount = "full_amount",
  pay_by_installments = "pay_by_installments",
}

export enum EInvoiceDebtPaymentOptionDisplay {
  full_amount = "เต็มจำนวน",
  pay_by_installments = "ผ่อน",
}

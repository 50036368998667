import dayjs from "dayjs";
import { useState } from "react";
import { DATE_FORMAT_DATE_PICKER2 } from "../../../../../const/app.constant";
import { thousandComma } from "../../../../../utils/common/string";
import { getNotAcceptDebt } from "../services/notAcceptDebt.service";
import {
  NotAcceptDebtRow,
  TNotAcceptDebtTable,
} from "../types/notAcceptDebt.type";
import { TUseMoneyDebtFilter } from "./useMoneyDebtFilter.hook";
import { AcceptDebtRow } from "../types/acceptDebt.type";
import { exportExcelSaleDebt } from "../services/acceptDebt.service";
import { makeXlsx } from "../../../../../utils/make-xlsx";

export const useNotAcceptDebtTableHook = (
  searchMoneyDebtFilter: TUseMoneyDebtFilter
) => {
  const [itemSelected, setItemSelected] = useState<string[]>([]);
  const [list, setList] = useState<TNotAcceptDebtTable[]>([]);
  const [count, setCount] = useState(0);
  const [debtInstallment, setDeptInstallment] = useState<
    AcceptDebtRow[] | undefined
  >();

  const createData = (
    id: string,
    orderId: string,
    customerId: string,
    customerName: string,
    invoiceNumber: string,
    unPaidAmount: string,
    dueDate: string,
    updateDate: string
  ): TNotAcceptDebtTable => {
    return {
      id,
      orderId,
      customerId,
      customerName,
      invoiceNumber,
      unPaidAmount: unPaidAmount ? thousandComma(+unPaidAmount) + " บาท" : "-",
      dueDate: dueDate ? dayjs(dueDate).format(DATE_FORMAT_DATE_PICKER2) : "-",
      updateDate: updateDate
        ? dayjs(dueDate).format(DATE_FORMAT_DATE_PICKER2)
        : "-",
    };
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = list.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };

  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("searchText", searchMoneyDebtFilter.searchText);
    searchParams.append("page", searchMoneyDebtFilter.page.toString());
    searchParams.append("limit", searchMoneyDebtFilter.limit.toString());
    searchParams.append("direction", searchMoneyDebtFilter.direction);
    searchParams.append("tab", searchMoneyDebtFilter.tab);

    setList([]);
    const data = await getNotAcceptDebt(searchParams);

    if (data?.rows) {
      const newList = data.rows.map((row) => {
        return createData(
          row.invoiceId,
          row.orderNumber,
          row.customerRegistrationId,
          row.customerName + " " + row.customerSurname,
          row.invoiceNumber,
          row.totalPrice ? row.totalPrice.toString() : "",
          row.debtNextPayment,
          row.updatedAt
        );
      });
      setCount(data.count);
      setList(newList);
      setDeptInstallment(data.rows);
    }
  };

  const getExportExcelData = async () => {
    type TColumnName =
      | "หมายเลขสั่งซื้อ"
      | "รหัสลูกค้า"
      | "ชื่อลูกค้า"
      | "เลข Invoice"
      | "ยอดเงินที่ต้องชำระ"
      | "วันครบกำหนดชำระ"
      | "วันที่อัปเดตล่าสุด";

    type TExcelData = {
      [k in TColumnName]: string | number;
    };
    const allSelectItemData = await exportExcelSaleDebt(
      "not-accept-debt",
      itemSelected
    );
    if (allSelectItemData?.rows) {
      return allSelectItemData.rows
        .map<TExcelData>((row) => {
          return {
            หมายเลขสั่งซื้อ: row.orderNumber,
            รหัสลูกค้า: row.customerRegistrationId,
            ชื่อลูกค้า: row.customerName + " " + row.customerSurname,
            "เลข Invoice": row.invoiceNumber,
            ยอดเงินที่ต้องชำระ: row.totalPrice,
            วันครบกำหนดชำระ: dayjs(row.debtNextPayment).format(
              DATE_FORMAT_DATE_PICKER2
            ),
            วันที่อัปเดตล่าสุด: dayjs(row.updatedAt).format(
              DATE_FORMAT_DATE_PICKER2
            ),
          };
        })
        .flat();
    }
    return [];
  };

  const handleExportExcel = async () => {
    const data = await getExportExcelData();
    const fileName = `not_accept_debt_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  return {
    handleSelectAllClick,
    handleClick,
    isSelected,
    count,
    list,
    itemSelected,
    setItemSelected,
    handleFetch,
    handleExportExcel,
    debtInstallment,
  };
};

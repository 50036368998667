import adminAPI from "../../../../services/AdminApiService";
import { IGetDropdownSellName } from "../types/dropdownSellName.type";

export const getDropdownSellName = async () => {
  try {
    const { data } = await adminAPI.get<IGetDropdownSellName>(
      "/farm-detail/dropdown/seller"
    );
    return data;
  } catch (error) {
    return null;
  }
};

import { useTranslation } from "react-i18next";
import CustomButton from "../../../components/common/Button";
import { BaseModal } from "./OrderFeedBaseModal";

interface IOrderFeedDeleteModal {
  isOpen: boolean;
  handleClose: (isSuccess: boolean) => void;
}

export function OrderFeedDeleteModal({
  isOpen,
  handleClose,
}: IOrderFeedDeleteModal) {
  const { t } = useTranslation();
  return (
    <BaseModal
      isOpen={isOpen}
      handleClose={() => handleClose(false)}
      iconCloseModal={true}
    >
      <div className="flex flex-col justify-between h-[300px]">
        <div className="text-center">
          <p className="text-[24px] font-medium text-warning mb-6">
            {t("orderFeed.summary.modal.header.delete")}
          </p>
          <p className="text-[16px]">
            {t("orderFeed.summary.modal.content.delete")}
          </p>
        </div>
        <div>
          <CustomButton
            variant="tertiary"
            label={t("orderFeed.button.submit")}
            onClick={() => handleClose(true)}
          />
        </div>
      </div>
    </BaseModal>
  );
}

import { IconButton, Toolbar, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export interface AppBarFSProps {
  title: string;
  endComponent?: any;
  onIconPress?: () => void;
}

export default function AppBarFS({
  title,
  endComponent,
  onIconPress,
}: AppBarFSProps) {
  return (
    <Toolbar>
      <IconButton
        size="large"
        edge="start"
        aria-label="menu"
        color="secondary"
        onClick={onIconPress}
      >
        <ChevronLeftIcon fontSize="large" />
      </IconButton>
      <Typography
        align="left"
        variant="h5"
        sx={{
          marginLeft: "-10px",
          paddingTop: "2px",
        }}
      >
        {title}
      </Typography>
      {endComponent}
    </Toolbar>
  );
}

import { useTranslation } from "react-i18next";
import {
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import AppBarFS from "../../components/app-bar/AppBar";
import BasicDetailTable, {
  IDataTable,
} from "../../components/common/Table/BasicDetailTable";
import { Typography } from "@mui/material";
import CustomButton from "../../components/common/Button";
import { useEffect, useState } from "react";
import "./SellingReport.styles.scss";
import { ButtonLink } from "../../components/common/ButtonLink/ButtonLink";
import PigStatusGaugeChart from "../../components/common/PigStatusGaugeChart/PigStatusGaugeChart";
import SellingHistoryInfoModal from "../../components/common/SellingHistoryInfoModal/SellingHistoryInfoModal";
import HousePerformanceStatusSlideV2 from "./HousePerformanceStatusSlideV2";
import { makeXlsx } from "../../utils/make-xlsx";
import dayjs from "dayjs";
import {
  IMoveOutPigHouseDetail,
  IMoveOutPigSellingReportResponse,
} from "../../types/MoveOutPig.type";
import { thousandComma } from "../../utils/common/string";
import { SellingReportLevelStatusEnum } from "../farm-past-performance/types";
import {
  getMoveOutPigHouseDetail,
  getMoveOutPigSellingReport,
} from "../../services/MoveOutPig.service";
import { useDayjsLocale } from "../../hooks/useDayjsLocale.hook";

type InfoType = "FCR" | "ADG" | "FCG";

const DEFAULT_DATE_FORMAT = "DD MMMM BBBB";

export function SellingReport() {
  useDayjsLocale();
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation() as {
    state: { from: string; subFrom: string };
  };
  const { sellingReport, house } = useLoaderData() as {
    sellingReport: IMoveOutPigSellingReportResponse;
    house: IMoveOutPigHouseDetail;
  };
  const params = useParams() as { houseId: string };
  const [serverError, setserverError] = useState<string>("");
  const [infoModal, setInfoModal] = useState<{
    title: string;
    subTitle: string;
    descriptions: string;
  }>({
    title: "",
    subTitle: "",
    descriptions: "",
  });
  const [houseData, setHouseData] = useState<IMoveOutPigHouseDetail>(house);
  const [reportData, setReportData] =
    useState<IMoveOutPigSellingReportResponse>(sellingReport);
  const [sellDetail, setSellDetail] = useState<IDataTable[]>([]);
  const [carCass, setCarCass] = useState<IDataTable[]>([]);
  const [summaryReport, setSummaryReport] = useState<IDataTable[]>([]);

  const getHouseDetail = async (houseId: string) => {
    try {
      const house = await getMoveOutPigHouseDetail(houseId);
      if (house?.success) {
        setHouseData(house);
      }
    } catch (err: any) {
      return err;
    }
  };

  const getReport = async (houseId: string) => {
    try {
      const sellingReport = await getMoveOutPigSellingReport(houseId);
      if (sellingReport?.success) {
        setReportData(sellingReport);
      }
    } catch (err: any) {
      return err;
    }
  };

  useEffect(() => {
    if (!house) {
      getHouseDetail(params.houseId);
    }
    if (!sellingReport) {
      getReport(params.houseId);
    }
  }, [params?.houseId]);

  useEffect(() => {
    if (houseData || reportData) {
      setSellDetail([
        {
          detailName: t("sellingReport.sellDetailTable.pigNumbers"),
          detailValue: thousandComma(sellingReport.sellDetail.numberOfPigs),
          unit: t("sellingReport.common.pig"),
          doFormat: false,
          color: "#4B4B4B",
          fontSize: "0.875rem",
        },
        {
          detailName: t("sellingReport.sellDetailTable.totalWeightOfPigs"),
          detailValue: thousandComma(
            Number(sellingReport.sellDetail.totalWeightOfPigs),
            2
          ),
          unit: t("sellingReport.common.kg"),
          doFormat: false,
          color: "#4B4B4B",
          fontSize: "0.875rem",
        },
        {
          detailName: t("sellingReport.sellDetailTable.totalPrice"),
          detailValue: thousandComma(
            Number(sellingReport.sellDetail.totalPrice),
            2
          ),
          unit: t("sellingReport.common.price"),
          doFormat: false,
          color: "#4B4B4B",
          fontSize: "0.875rem",
        },
      ]);

      setCarCass([
        {
          detailName: t("sellingReport.carCassTable.numberOfCarcassPigs"),
          detailValue: thousandComma(sellingReport.carcass.amountOfCarcass),
          unit: t("sellingReport.common.pig"),
          doFormat: false,
          color: "#4B4B4B",
          fontSize: "0.875rem",
        },
        {
          detailName: t("sellingReport.carCassTable.totalWeightOfCarcassPigs"),
          detailValue: thousandComma(
            Number(sellingReport.carcass.totalCarcassWeight),
            2
          ),
          unit: t("sellingReport.common.kg"),
          doFormat: false,
          color: "#4B4B4B",
          fontSize: "0.875rem",
        },
        {
          detailName: t("sellingReport.carCassTable.totalPrice"),
          detailValue: thousandComma(
            Number(sellingReport.carcass.totalCarcassPrice),
            2
          ),
          unit: t("sellingReport.common.price"),
          doFormat: false,
          color: "#4B4B4B",
          fontSize: "0.875rem",
        },
      ]);

      setSummaryReport([
        {
          detailName: t("sellingReport.summaryReportTable.numberOfMoveOutPigs"),
          detailValue: thousandComma(
            sellingReport.summaryReport.numberOfMoveOutPigs
          ),
          unit: t("sellingReport.common.pig"),
          doFormat: false,
          color: "#4B4B4B",
          fontSize: "0.875rem",
        },
        {
          detailName: t("sellingReport.summaryReportTable.averageFeedingDays"),
          detailValue: thousandComma(
            sellingReport.summaryReport.avgFeedingDays
          ),
          unit: t("sellingReport.common.day"),
          doFormat: false,
          color: "#4B4B4B",
          fontSize: "0.875rem",
        },
        {
          detailName: t("sellingReport.summaryReportTable.weightGain"),
          detailValue: thousandComma(
            Number(sellingReport.summaryReport.weightGain),
            2
          ),
          unit: t("sellingReport.common.kg"),
          doFormat: false,
          color: "#4B4B4B",
          fontSize: "0.875rem",
        },
        {
          detailName: t("sellingReport.summaryReportTable.amountOfFeedPerPig"),
          detailValue: thousandComma(
            Number(sellingReport.summaryReport.amtOfFeedPerPig),
            2
          ),
          unit: t("sellingReport.common.kg"),
          doFormat: false,
          color: "#4B4B4B",
          fontSize: "0.875rem",
        },
      ]);
    }
  }, [houseData, reportData]);

  const handleScenarioInfoModal = (type: InfoType) => {
    if (type === "FCR") {
      setInfoModal({
        title: t("sellingReport.modalInfo.fcr.title"),
        subTitle: t("sellingReport.modalInfo.fcr.subTitle"),
        descriptions: t("sellingReport.modalInfo.fcr.descriptions"),
      });
    } else if (type === "ADG") {
      setInfoModal({
        title: t("sellingReport.modalInfo.adg.title"),
        subTitle: t("sellingReport.modalInfo.adg.subTitle"),
        descriptions: t("sellingReport.modalInfo.adg.descriptions"),
      });
    } else if (type === "FCG") {
      setInfoModal({
        title: t("sellingReport.modalInfo.fcg.title"),
        subTitle: t("sellingReport.modalInfo.fcg.subTitle"),
        descriptions: t("sellingReport.modalInfo.fcg.descriptions"),
      });
    }

    setIsOpenModal(true);
  };

  const handleDownloadXlsx = () => {
    const payload = [
      {
        [t("sellingReport.excelColumn.houseName")]: houseData.houseName,
        FCR:
          thousandComma(Number(reportData.summaryReport?.fcr), 2) ??
          t("sellingReport.summaryReportTable.fcgNoData"),
        ADG:
          thousandComma(Number(reportData.summaryReport?.adg), 2) ??
          t("sellingReport.summaryReportTable.fcgNoData"),
        FCG:
          thousandComma(Number(reportData.summaryReport?.fcg), 2) ??
          t("sellingReport.summaryReportTable.fcgNoData"),
        [t("sellingReport.excelColumn.death")]: thousandComma(
          Number(reportData?.summaryReport?.death),
          2
        ),
        [t("sellingReport.excelColumn.salesPrice")]: thousandComma(
          Number(reportData?.sellDetail?.totalPrice),
          2
        ),
        [t("sellingReport.excelColumn.pigAmount")]: thousandComma(
          reportData.summaryReport?.numberOfMoveOutPigs
        ),
      },
    ];
    const fileName = `Farm ${houseData.farmName} - House ${
      houseData.houseName
    } ${dayjs().format("DD-MM-YYYY")}`;
    makeXlsx(payload, fileName);
  };

  return (
    <div className="relative w-full bg-[#FFFFF]">
      <div className="h-screen overflow-auto flex flex-col pb-24">
        <div className="p-4 rounded-b-[40px] bg-white">
          <AppBarFS
            onIconPress={() =>
              navigate(state?.from ?? `/farms`, {
                state: { from: state?.subFrom, subFrom: state?.from },
              })
            }
            title={t("sellingReport.titleBar")}
          />
        </div>
        <div className="flex p-4">
          <div className="flex flex-col grow gap-4 w-full">
            <div className="px-1 text-tertiary font-semibold ml-1 capitalize text-xl">
              {t("sellingReport.sellDetailTable.title")}
            </div>
            <div className="relative filter btg-drop-shadow-table">
              <div className="bg-[#D9EBFE] flex items-center p-4 rounded-t-[20px] justify-between">
                <span className="text-[#4B4B4B] font-medium">
                  {t("sellingReport.common.date")}
                </span>
                <span className="text-[#4B4B4B] font-medium">
                  {dayjs(reportData.sellDetail.timestamp).format(
                    DEFAULT_DATE_FORMAT
                  )}
                </span>
              </div>
              <BasicDetailTable
                tableData={sellDetail}
                tableProps={{
                  size: "medium",
                }}
                swapCellColor={true}
                borderCell="0px !important"
                isNewFormat={true}
              />
              <div className="bg-[#f6faff] rounded-b-[20px] h-[20px]"></div>
            </div>

            <div className="px-1 mt-4 text-tertiary font-semibold ml-1 capitalize text-xl">
              {t("sellingReport.carCassTable.title")}
            </div>
            <div className="relative filter btg-drop-shadow-table">
              <div className="bg-[#D9EBFE] flex items-center p-4 rounded-t-[20px] justify-between">
                <span className="text-[#4B4B4B] font-medium">
                  {t("sellingReport.common.date")}
                </span>
                <span className="text-[#4B4B4B] font-medium">
                  {dayjs(reportData.carcass.timestamp).format(
                    DEFAULT_DATE_FORMAT
                  )}
                </span>
              </div>
              <BasicDetailTable
                tableData={carCass}
                tableProps={{
                  size: "medium",
                }}
                swapCellColor={true}
                borderCell="0px !important"
                isNewFormat={true}
              />
              <div className="bg-[#f6faff] rounded-b-[20px] h-[20px]"></div>
            </div>

            <div className="px-1 mt-4 text-tertiary font-semibold ml-1 capitalize text-xl">
              {t("sellingReport.summaryReportTable.title")}
            </div>
            <div className="relative filter btg-drop-shadow-table">
              <div className="bg-[#D9EBFE] flex items-center p-4 rounded-t-[20px] justify-between">
                <span className="text-[#4B4B4B] font-medium">
                  {t("sellingReport.common.date")}
                </span>
                <span className="text-[#4B4B4B] font-medium">
                  {dayjs(reportData.summaryReport.timestamp).format(
                    DEFAULT_DATE_FORMAT
                  )}
                </span>
              </div>
              <BasicDetailTable
                tableData={summaryReport}
                tableProps={{
                  size: "medium",
                }}
                swapCellColor={true}
                borderCell="0px !important"
                isNewFormat={true}
              />
              <div className="bg-white py-6 w-full px-3">
                <div className="flex flex-col">
                  <span className="text-lg text-[#4B4B4B]">
                    {t(
                      "sellingReport.summaryReportTable.housePerformanceStandard"
                    )}
                  </span>
                  <HousePerformanceStatusSlideV2
                    status={
                      reportData.summaryReport?.housePerformanceStd ??
                      SellingReportLevelStatusEnum.None
                    }
                    t={t}
                  />
                </div>

                <div className="flex flex-row justify-between items-center">
                  <span
                    className="text-lg text-[#4B4B4B]"
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "sellingReport.summaryReportTable.yourFcrScore"
                      ) as any,
                    }}
                  ></span>
                  <ButtonLink
                    title={t("sellingReport.modalInfo.fcr.btnLabel")}
                    sx={{ color: "#0288D1" }}
                    onClick={() => handleScenarioInfoModal("FCR")}
                  />
                </div>
                <div className="w-full pb-6">
                  <PigStatusGaugeChart
                    key={"fcr"}
                    minRate={0}
                    maxRate={Number(reportData.summaryReport.stdFcr)}
                    value={
                      reportData.summaryReport.fcr === null ||
                      reportData.summaryReport.fcr === "0" ||
                      reportData.summaryReport.fcr === "0.00"
                        ? "-"
                        : Number(reportData.summaryReport.fcr)
                    }
                    status={
                      reportData.summaryReport.lvFcr ??
                      SellingReportLevelStatusEnum.None
                    }
                    t={t}
                  />
                </div>

                <div className="flex flex-row justify-between items-center">
                  <span
                    className="text-lg text-[#4B4B4B]"
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "sellingReport.summaryReportTable.yourAdgScore"
                      ) as any,
                    }}
                  ></span>
                  <ButtonLink
                    title={t("sellingReport.modalInfo.adg.btnLabel")}
                    sx={{ color: "#0288D1" }}
                    onClick={() => handleScenarioInfoModal("ADG")}
                  />
                </div>
                <div className="w-full pb-6">
                  <PigStatusGaugeChart
                    key={"adg"}
                    minRate={0}
                    maxRate={Number(reportData.summaryReport.stdAdg)}
                    value={
                      reportData.summaryReport.adg === null ||
                      reportData.summaryReport.adg === "0" ||
                      reportData.summaryReport.adg === "0.00"
                        ? "-"
                        : Number(reportData.summaryReport.adg)
                    }
                    status={
                      reportData.summaryReport.lvAdg ??
                      SellingReportLevelStatusEnum.None
                    }
                    t={t}
                  />
                </div>

                <div className="flex flex-row justify-between items-center pt-1">
                  <span
                    className="text-lg text-[#4B4B4B]"
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "sellingReport.summaryReportTable.yourDeathScore"
                      ) as any,
                    }}
                  ></span>
                </div>
                <div className="w-full pb-2 pt-2">
                  <PigStatusGaugeChart
                    key={"death"}
                    minRate={0}
                    maxRate={Number(reportData.summaryReport.stdDeath)}
                    value={Number(reportData.summaryReport.death)}
                    status={
                      reportData.summaryReport.lvDeath ??
                      SellingReportLevelStatusEnum.None
                    }
                    unit="%"
                    t={t}
                  />
                </div>

                <div className="flex flex-row justify-between items-center pt-6">
                  <span className="text-lg text-[#4B4B4B]">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t(
                          "sellingReport.summaryReportTable.yourFcgScore"
                        ) as any,
                      }}
                    />{" "}
                    {reportData.summaryReport?.fcg ? (
                      <span className="text-tertiary font-medium text-3xl">
                        {reportData.summaryReport.fcg}
                      </span>
                    ) : (
                      <span className="text-tertiary font-medium text-xl">
                        {t("sellingReport.summaryReportTable.fcgNoData")}
                      </span>
                    )}
                  </span>
                  <ButtonLink
                    title={t("sellingReport.modalInfo.fcg.btnLabel")}
                    sx={{ color: "#0288D1" }}
                    onClick={() => handleScenarioInfoModal("FCG")}
                  />
                </div>
              </div>

              <div className="bg-[#F6FBFF] rounded-b-[20px] py-6 w-full px-3 justify-center">
                <div className="flex justify-center">
                  <CustomButton
                    variant="secondary"
                    disabled={false}
                    label={t("sellingReport.button.downloadXlsx")}
                    onClick={() => handleDownloadXlsx()}
                    fullWidth={false}
                    className="w-[190px]"
                  />
                </div>
                <div className="flex justify-center mt-4 text-[#9B9B9B] text-sm">
                  {t(
                    "sellingReport.summaryReportTable.descriptionDownloadFile"
                  )}
                </div>
              </div>
            </div>

            <SellingHistoryInfoModal
              isOpen={isOpenModal}
              onClose={() => {
                setIsOpenModal(false);
              }}
              titleBtn={t("sellingReport.modalInfo.btnClose") as string}
              {...infoModal}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 px-4 absolute z-10 bottom-0 w-full bg-white">
        <div className="flex flex-col gap-4 my-4 px-4">
          {serverError && (
            <Typography
              variant="body2"
              className="text-[#D32F2F] px-4"
              align="center"
            >
              {serverError}
            </Typography>
          )}
          <CustomButton
            variant="tertiary"
            disabled={false}
            label={t("sellingReport.button.goHome")}
            onClick={() => navigate(`/farms`)}
          />
        </div>
      </div>
    </div>
  );
}

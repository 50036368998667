type totalPigWithIconVariant = "row" | "col";

interface TotalPigWithIconProps {
  variant: totalPigWithIconVariant;
  title: string;
  count: number;
  icon: any;
}

export default function TotalPigsWithIcon({
  title,
  count,
  icon,
  variant = "row",
}: TotalPigWithIconProps) {
  function getClassesForVariant() {
    if (variant === "row") {
      return "gap-4 items-center justify-between";
    } else {
      return "flex-col gap-1";
    }
  }

  function getElementsInOrder() {
    if (variant === "row") {
      return (
        <>
          <p className="text-tertiary text-sm flex-1">{title}</p>
          <h6 className="text-primary text-2xl font-[500] flex-1 text-end">
            {count.toLocaleString()}
          </h6>
        </>
      );
    } else {
      return (
        <>
          <h6 className="text-primary text-2xl font-[500] flex-1">
            {count.toLocaleString()}
          </h6>
          <p className="text-tertiary font-sm flex-1 text-sm">{title}</p>
        </>
      );
    }
  }

  return (
    <div className={`flex grow ${getClassesForVariant()}`}>
      <div className="flex-1">{icon}</div>
      {getElementsInOrder()}
    </div>
  );
}

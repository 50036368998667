import { SvgIcon } from "@mui/material";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import UploadFileIcon from "../../../assets/svg/UploadFile";

interface Accept {
  [key: string]: string[];
}
interface IDragAndDropUploadImage {
  multiple?: boolean;
  onUpload: (files: File[]) => void;
  type?: Accept;
}

function DragAndDropUploadImage({
  onUpload,
  multiple = false,
  type = {
    "image/png": [".png"],
    "image/jpeg": [".jpeg", ".jpg"],
    "application/pdf": [".pdf"],
  },
}: IDragAndDropUploadImage) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: type,
    onDrop: useCallback((files: File[]) => {
      onUpload(files);
    }, []),
    multiple,
  });

  return (
    <div
      {...getRootProps()}
      className="flex flex-col w-full h-[80px] rounded-lg bg-[#FBFBFB] text-[#B0B0B0] text-[14px] border border-dashed border-[#C9C9C9] items-center justify-center gap-1 cursor-copy"
    >
      <input {...getInputProps()} />
      <SvgIcon>
        <UploadFileIcon />
      </SvgIcon>
      {"เลือกหรือวางไฟล์เพื่ออัปโหลด"}
    </div>
  );
}
export default DragAndDropUploadImage;

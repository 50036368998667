import { ReactNode, createContext, useContext, useState } from "react";
import SuccessSnackBar from "../../admin/components/SnackBar/SuccessSnackBar";
import ErrorSnackBar from "../../admin/components/SnackBar/ErrorSnackBar";

type IShowSnackBar = (message: string) => void;

interface ISnackBarContext {
  showSnackBar: IShowSnackBar;
  showErrorSnackBar: IShowSnackBar;
}

const SnackBarContext = createContext<ISnackBarContext>({
  showSnackBar: () => {},
  showErrorSnackBar: () => {},
});

export const SnackBarProvider = ({ children }: { children: ReactNode }) => {
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const handleShowSnackBar = (message: string) => {
    setSnackBarMessage(message);
    setShowSnackBar(true);
  };
  const [showErrorSnackBar, setShowErrorSnackBar] = useState(false);
  const [errorSnackBarMessage, setErrorSnackBarMessage] = useState("");
  const handleShowErrorSnackBar = (message: string) => {
    setErrorSnackBarMessage(message);
    setShowErrorSnackBar(true);
  };

  return (
    <SnackBarContext.Provider
      value={{
        showSnackBar: handleShowSnackBar,
        showErrorSnackBar: handleShowErrorSnackBar,
      }}
    >
      {children}
      <SuccessSnackBar
        openSnackbar={showSnackBar}
        handleCloseSnackbar={() => setShowSnackBar(false)}
        snackbarText={snackBarMessage}
      />
      <ErrorSnackBar
        openSnackbar={showErrorSnackBar}
        handleCloseSnackbar={() => setShowErrorSnackBar(false)}
        snackbarText={errorSnackBarMessage}
      />
    </SnackBarContext.Provider>
  );
};

export const useSnackBar = () => useContext(SnackBarContext);

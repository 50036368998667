import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

export default function DashboardSectionHeader({
  title,
  navigatorTitle,
  info,
  onClick,
}: any) {
  return (
    <div className="flex flex-col">
      <div className="flex grow justify-between">
        <h2 className="text-primary text-2xl font-semibold">{title}</h2>
        {navigatorTitle && (
          <div className="flex items-center" onClick={onClick}>
            <p className="text-primary">{navigatorTitle}</p>
            <ChevronRightOutlinedIcon color="primary" />
          </div>
        )}
      </div>
      <p className="text-tertiary text-sm mt-1">{info}</p>
    </div>
  );
}

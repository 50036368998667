import { TDropdownValue } from "../../../../group-area/views/Dropdown";

export type TDropdownValueId = {
  id: string;
  label: string;
  options: TDropdownValue[];
}[];

type TFileBeforeUpload = {
  fileName: string;
  fileSize: number;
  id: string;
};

export type TFormTabFarmer = {
  tabFirst_versionCredit: TDropdownValue[];
  tabFirst_businessType: TDropdownValue[];
  tabFifth_farmSize: TDropdownValue[];
  tabFifth_farmName: TDropdownValue[];
  tabFifth_greenHouse: TDropdownValue[];
  tabFifth_pigType: string;
  tabFifth_countPig: string;
  tabFifth_grade: string;
  tabFifth_contractType: TDropdownValue[];
  tabFifth_timeCredit: string;
  tabFifth_customerCreditLimitCalculatedByTheSystem: string;
  tabFifth_creditLineOfferedToCustomer: string;
  tabFifth_reason: string;
  tabFifth_creditCalculationDocuments: File[];
  citizenDocument: File[];
  creditBureauDocument: File[];
  citizenDocumentUpload: TFileBeforeUpload[];
  creditBureauDocumentUpload: TFileBeforeUpload[];
  timeDate: TDropdownValue[];
};

export const defaultFormTabFarmer: TFormTabFarmer = {
  tabFirst_versionCredit: [],
  tabFirst_businessType: [],
  tabFifth_farmSize: [],
  tabFifth_farmName: [],
  tabFifth_greenHouse: [],
  tabFifth_pigType: "หมูขุน",
  tabFifth_countPig: "",
  tabFifth_grade: "",
  tabFifth_contractType: [],
  tabFifth_timeCredit: "",
  tabFifth_customerCreditLimitCalculatedByTheSystem: "",
  tabFifth_creditLineOfferedToCustomer: "",
  tabFifth_reason: "",
  tabFifth_creditCalculationDocuments: [],
  citizenDocument: [],
  creditBureauDocument: [],
  citizenDocumentUpload: [],
  creditBureauDocumentUpload: [],
  timeDate: [],
};

export type TFormForDynamicDropdown = (TDropdownValue & { id: string })[];

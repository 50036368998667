export default function NormalFaceStatus() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 -0.5 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99999 16.7252C4.89441 16.7252 0.465088 14.15 0.465088 8.49932C0.465088 2.84862 4.89441 0.273438 8.99999 0.273438C11.2809 0.273438 13.3852 1.02392 14.945 2.39245C16.6373 3.89341 17.5349 6.01242 17.5349 8.49932C17.5349 10.9862 16.6373 13.0905 14.945 14.5915C13.3852 15.96 11.2662 16.7252 8.99999 16.7252Z"
        fill="#F6C94B"
      />
      <path
        d="M16.0473 3.60938C16.8316 4.87489 17.2407 6.38175 17.2407 8.05782C17.2407 10.5447 16.3431 12.649 14.6508 14.15C13.091 15.5185 10.972 16.2837 8.70582 16.2837C6.04823 16.2837 3.26114 15.2021 1.61597 12.908C3.19934 15.5067 6.17772 16.7252 9.00013 16.7252C11.2663 16.7252 13.3853 15.96 14.9451 14.5914C16.6374 13.0905 17.535 10.9862 17.535 8.49928C17.535 6.62455 17.0244 4.95877 16.0473 3.60938Z"
        fill="#F6C94B"
      />
      <path
        d="M12.7378 12.1929H5.38012C5.05491 12.1929 4.7915 11.9294 4.7915 11.6042C4.7915 11.279 5.05491 11.0156 5.38012 11.0156H12.7378C13.063 11.0156 13.3264 11.279 13.3264 11.6042C13.3264 11.9294 13.063 12.1929 12.7378 12.1929Z"
        fill="#323232"
      />
      <path
        d="M6.21458 6.00391C5.59801 6.00391 5.03735 6.52483 5.03735 7.39009C5.03735 8.25535 5.59801 8.77481 6.21458 8.77481C6.83115 8.77481 7.39181 8.25388 7.39181 7.39009C7.39181 6.5263 6.83115 6.00391 6.21458 6.00391Z"
        fill="#323232"
      />
      <path
        d="M6.15685 6.5803C5.94789 6.48024 5.69626 6.56853 5.59472 6.77749C5.51673 6.94083 5.55205 7.13654 5.68302 7.26309C5.89197 7.36316 6.14361 7.27487 6.24514 7.06591C6.32313 6.90257 6.28782 6.70685 6.15685 6.5803Z"
        fill="#323232"
      />
      <path
        d="M11.8547 6.00391C11.2381 6.00391 10.6775 6.52483 10.6775 7.39009C10.6775 8.25535 11.2381 8.77481 11.8547 8.77481C12.4713 8.77481 13.0319 8.25388 13.0319 7.39009C13.0319 6.5263 12.4742 6.00391 11.8547 6.00391Z"
        fill="#323232"
      />
      <path
        d="M11.8004 6.5803C11.5914 6.48024 11.3398 6.56853 11.2383 6.77749C11.1603 6.94083 11.1956 7.13654 11.3266 7.26309C11.5355 7.36316 11.7872 7.27487 11.8887 7.06591C11.9667 6.90257 11.9314 6.70685 11.8004 6.5803Z"
        fill="#323232"
      />
    </svg>
  );
}

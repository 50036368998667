import { useQuery } from "@tanstack/react-query";
import { getRevenueRateChangeHistory } from "../../../services/partner/PartnerSectionContract.service";
import { useCallback, useState } from "react";
import {
  defaultFormFilterModalHistory,
  TFormFilterModalHistory,
} from "./defaultFormHistory";
import dayjs from "dayjs";
import { getProfilePartner } from "../../../services/partner/PartnerLanding.service";

export const usePartnerViewHistoryController = () => {
  const [orderByType, setOrderByType] = useState<"desc" | "asc">("desc");

  const [formFilterModal, setFormFilterModal] = useState(
    defaultFormFilterModalHistory
  );

  const onChangeFormFilterModal = useCallback(
    (key: keyof TFormFilterModalHistory) => (value: any) => {
      setFormFilterModal((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const { data: GetProfilePartner } = useQuery({
    queryKey: ["profile-partner"],
    queryFn: async () => {
      const result = await getProfilePartner();
      return result;
    },
  });

  const { data: RevenueRateChangeHistory } = useQuery({
    queryKey: ["revenue_rate_history", orderByType, formFilterModal],
    queryFn: async () => {
      const result = await getRevenueRateChangeHistory({
        orderBy: orderByType,
        dateStart: formFilterModal.startDate
          ? dayjs(formFilterModal.startDate, "YYYY-MM-DD")
              .startOf("day")
              .toDate()
          : undefined,
        dateEnd: formFilterModal.endDate
          ? dayjs(formFilterModal.endDate, "YYYY-MM-DD").startOf("day").toDate()
          : undefined,
      });
      return result;
    },
  });

  return {
    GetProfilePartner,
    RevenueRateChangeHistory,
    orderByType,
    setOrderByType,
    formFilterModal,
    setFormFilterModal,
    onChangeFormFilterModal,
  };
};

import { useNavigate, useParams } from "react-router-dom";
import AppBarFS from "../../../components/app-bar/AppBar";
import Tabs from "../../../admin/pages/group-area/views/Tabs";
import { Tab } from "@mui/material";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import ButtonFillCustom from "../../../admin/components/Button/ButtonFillCustom";
import { useManageStockFarmerController } from "./controller";
import AddFeedStockModal from "./views/AddFeedStockModal";
import AddVaccineStockModal from "./views/AddVaccineStockModal";
import AddOtherStockModal from "./views/AddOtherStockModal";
import AddMedicineStockModal from "./views/AddMedicineStockModal";
import TableFeedStock from "./table/TableFeedStock";
import TableVaccineStock from "./table/TableVaccineStock";
import TableMedicineStock from "./table/TableMedicineStock";
import TableOtherStock from "./table/TableOtherStock";
import HistoryFeedStockModal from "./views/HistoryFeedStockModal";
import HistoryVaccineStockModal from "./views/HistoryVaccineStockModal";
import HistoryMedicineStockModal from "./views/HistoryMedicineStockModal";
import ButtonOutlinedCustom from "../../../admin/components/Button/ButtonOutlinedCustom";
import HistoryOtherStockModal from "./views/HistoryOtherStockModal";

const ManageStockFarmer = () => {
  const { farmId } = useParams();

  const {
    tabIndex,
    onChangeTab,
    isOpenAddFeedModal,
    onOpenAddFeedModal,
    onHideAddFeedModal,
    isOpenAddVaccineModal,
    onOpenAddVaccineModal,
    onHideAddVaccineModal,
    isOpenAddMedicineModal,
    onOpenAddMedicineModal,
    onHideAddMedicineModal,
    isOpenAddOthersModal,
    onOpenAddOthersModal,
    onHideAddOthersModal,
    ////// Feed //////
    listFeedStock,
    feedBrandOption,
    skuOptions,
    formFeedStock,
    setFormFeedStock,
    onChangeFormFeedStock,
    itemByIdFeedStock,
    setItemIdFeedStock,
    isEditFeed,
    onOpenEditFeed,
    onHideEditFeedModal,
    isOpenHistoryFeedModal,
    onOpenHistoryFeedModal,
    onHideHistoryFeedModal,
    listHistoryFeedStock,
    ////// Vaccine //////
    listVaccineStock,
    vaccineOption,
    formVaccineStock,
    setFormVaccineStock,
    onChangeFormVaccineStock,
    itemByIdVaccineStock,
    setItemIdVaccineStock,
    isEditVaccine,
    onOpenEditVaccine,
    onHideEditVaccineModal,
    isOpenHistoryVaccineModal,
    onOpenHistoryVaccineModal,
    onHideHistoryVaccineModal,
    listHistoryVaccineStock,
    ////// Medicine //////
    listMedicineStock,
    medicineCategoryOption,
    medicineDetailIdOption,
    medicineAllDetailIdOption,
    formMedicineStock,
    setFormMedicineStock,
    onChangeFormMedicineStock,
    addMedicine,
    setAddMedicine,
    itemByIdMedicineStock,
    setItemIdMedicineStock,
    isEditMedicine,
    onOpenEditMedicine,
    onHideEditMedicineModal,
    isOpenHistoryMedicineModal,
    onOpenHistoryMedicineModal,
    onHideHistoryMedicineModal,
    listHistoryMedicineStock,
    ////// Other //////
    listOtherStock,
    formOtherStock,
    setFormOtherStock,
    onChangeFormOtherStock,
    itemByIdOtherStock,
    setItemIdOtherStock,
    isEditOther,
    onOpenEditOther,
    onHideEditOtherModal,
    isOpenHistoryOtherModal,
    onOpenHistoryOtherModal,
    onHideHistoryOtherModal,
    listHistoryOtherStock,
    ////// History //////
    formHistory,
    setFormHistory,
    onChangeFormHistory,
  } = useManageStockFarmerController(farmId || "");

  const navigate = useNavigate();

  return (
    <div
      style={{
        height: "auto !important",
        backgroundSize: "cover",
        backgroundColor: "#FFFFFF",
      }}
      className="!h-auto !min-h-screen"
    >
      <div className="h-[120px] flex items-center bg-[#FFFF] rounded-b-[40px]">
        <AppBarFS title={"สต๊อกสินค้า"} onIconPress={() => navigate(-1)} />
      </div>
      <div className="px-4 pt-[18px]">
        <Tabs tabIndex={tabIndex} onChange={onChangeTab} isScroll>
          <Tab label="Feed" />
          <Tab label="Vaccine" />
          <Tab label="Medicine" />
          <Tab label="Others" />
        </Tabs>
        {tabIndex === 0 && (
          <div className="pt-10 flex flex-col gap-6">
            <div className="text-[#3777BC] text-[20px] font-semibold">
              Feed Stock
            </div>
            {(listFeedStock?.length || 0) > 0 && (
              <div>
                <TableFeedStock
                  data={listFeedStock || []}
                  setItemId={setItemIdFeedStock}
                  onEdit={onOpenEditFeed}
                  onHistory={onOpenHistoryFeedModal}
                />
              </div>
            )}
            <div className="flex justify-center pb-10">
              <ButtonFillCustom
                title="Add Feed Stock"
                startIcon={<AddCircleOutlinedIcon />}
                className="!w-[189px] !h-[38px] !rounded-[50px] !py-[6px] !x-6"
                onClick={onOpenAddFeedModal}
              />
            </div>
          </div>
        )}
        {tabIndex === 1 && (
          <div className="pt-10 flex flex-col gap-6">
            <div className="text-[#3777BC] text-[20px] font-semibold">
              Vaccine Stock
            </div>
            {(listVaccineStock?.length || 0) > 0 && (
              <div>
                <TableVaccineStock
                  data={listVaccineStock || []}
                  setItemId={setItemIdVaccineStock}
                  onEdit={onOpenEditVaccine}
                  onHistory={onOpenHistoryVaccineModal}
                />
              </div>
            )}
            <div className="flex justify-center pb-10">
              <ButtonFillCustom
                title="Add Vaccine Stock"
                startIcon={<AddCircleOutlinedIcon />}
                className="!w-[189px] !h-[38px] !rounded-[50px] !py-[6px] !x-6"
                onClick={onOpenAddVaccineModal}
              />
            </div>
          </div>
        )}
        {tabIndex === 2 && (
          <div className="pt-10 flex flex-col gap-6">
            <div className="text-[#3777BC] text-[20px] font-semibold">
              Medicine Stock
            </div>
            {(listMedicineStock?.length || 0) > 0 && (
              <div>
                <TableMedicineStock
                  data={listMedicineStock || []}
                  setItemId={setItemIdMedicineStock}
                  onEdit={onOpenEditMedicine}
                  onHistory={onOpenHistoryMedicineModal}
                />
              </div>
            )}
            <div className="flex justify-center pb-10">
              <ButtonFillCustom
                title="Add Medicine Stock"
                startIcon={<AddCircleOutlinedIcon />}
                className="!w-[189px] !h-[38px] !rounded-[50px] !py-[6px] !x-6"
                onClick={onOpenAddMedicineModal}
              />
            </div>
          </div>
        )}
        {tabIndex === 3 && (
          <div className="pt-10 flex flex-col gap-6">
            <div className="flex flex-row justify-between items-center">
              <div className="text-[#3777BC] text-[20px] font-semibold">
                Other Stock
              </div>
              <ButtonOutlinedCustom
                title="ดูรายละเอียด"
                btnBorderColor="#68C184"
                btnTextColor="#68C184"
                btnBgColor="#FFFFFF"
                className="!rounded-[50px] !py-[4.5px] !px-[13.5px] !flex"
                onClick={onOpenHistoryOtherModal}
              />
            </div>
            {(listOtherStock?.length || 0) > 0 && (
              <div>
                <TableOtherStock
                  data={listOtherStock || []}
                  setItemId={setItemIdOtherStock}
                  onEdit={onOpenEditOther}
                />
              </div>
            )}
            <div className="flex justify-center pb-10">
              <ButtonFillCustom
                title="Add Other Stock"
                startIcon={<AddCircleOutlinedIcon />}
                className="!w-[189px] !h-[38px] !rounded-[50px] !py-[6px] !x-6"
                onClick={onOpenAddOthersModal}
              />
            </div>
          </div>
        )}
      </div>
      <AddFeedStockModal
        isOpen={isOpenAddFeedModal}
        onClose={onHideAddFeedModal}
        feedBrandOption={feedBrandOption}
        skuOptions={skuOptions}
        form={formFeedStock}
        setForm={setFormFeedStock}
        onChangeForm={onChangeFormFeedStock}
        itemEdit={itemByIdFeedStock || []}
        isEdit={isEditFeed}
        onCloseEdit={onHideEditFeedModal}
        setIdEdit={setItemIdFeedStock}
        farmId={farmId || ""}
      />
      <AddVaccineStockModal
        isOpen={isOpenAddVaccineModal}
        onClose={onHideAddVaccineModal}
        vaccineOption={vaccineOption}
        form={formVaccineStock}
        setForm={setFormVaccineStock}
        onChangeForm={onChangeFormVaccineStock}
        itemEdit={itemByIdVaccineStock || []}
        isEdit={isEditVaccine}
        onCloseEdit={onHideEditVaccineModal}
        setIdEdit={setItemIdVaccineStock}
        farmId={farmId || ""}
      />
      <AddOtherStockModal
        isOpen={isOpenAddOthersModal}
        onClose={onHideAddOthersModal}
        form={formOtherStock}
        setForm={setFormOtherStock}
        onChangeForm={onChangeFormOtherStock}
        itemEdit={itemByIdOtherStock || []}
        isEdit={isEditOther}
        onCloseEdit={onHideEditOtherModal}
        setIdEdit={setItemIdOtherStock}
        farmId={farmId || ""}
      />
      <AddMedicineStockModal
        isOpen={isOpenAddMedicineModal}
        onClose={onHideAddMedicineModal}
        medicineCategoryOption={medicineCategoryOption}
        medicineDetailIdOption={medicineDetailIdOption}
        medicineAllDetailIdOption={medicineAllDetailIdOption}
        form={formMedicineStock}
        setForm={setFormMedicineStock}
        onChangeForm={onChangeFormMedicineStock}
        addMedicine={addMedicine}
        setAddMedicine={setAddMedicine}
        itemEdit={itemByIdMedicineStock || []}
        isEdit={isEditMedicine}
        onCloseEdit={onHideEditMedicineModal}
        setIdEdit={setItemIdMedicineStock}
        farmId={farmId || ""}
      />
      <HistoryFeedStockModal
        isOpen={isOpenHistoryFeedModal}
        onClose={onHideHistoryFeedModal}
        form={formHistory}
        setForm={setFormHistory}
        onChangeForm={onChangeFormHistory}
        data={listHistoryFeedStock || []}
        item={itemByIdFeedStock || []}
        setItemId={setItemIdFeedStock}
      />
      <HistoryVaccineStockModal
        isOpen={isOpenHistoryVaccineModal}
        onClose={onHideHistoryVaccineModal}
        form={formHistory}
        setForm={setFormHistory}
        onChangeForm={onChangeFormHistory}
        data={listHistoryVaccineStock}
        setItemId={setItemIdVaccineStock}
      />
      <HistoryMedicineStockModal
        isOpen={isOpenHistoryMedicineModal}
        onClose={onHideHistoryMedicineModal}
        form={formHistory}
        setForm={setFormHistory}
        onChangeForm={onChangeFormHistory}
        data={listHistoryMedicineStock}
        setItemId={setItemIdMedicineStock}
      />
      <HistoryOtherStockModal
        isOpen={isOpenHistoryOtherModal}
        onClose={onHideHistoryOtherModal}
        form={formHistory}
        setForm={setFormHistory}
        onChangeForm={onChangeFormHistory}
        data={listHistoryOtherStock || []}
        setItemId={setItemIdOtherStock}
      />
    </div>
  );
};

export default ManageStockFarmer;

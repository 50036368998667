import { saveAs } from "file-saver";

import * as xlsx from "xlsx";

const DEFAULT_FILE_TYPE: xlsx.BookType = "xlsx";

const makeXlsx = (data: any[], fileName: string, sheetName?: string) => {
  /* make the worksheet */
  const ws = xlsx.utils.json_to_sheet(data);
  if (data.length > 0) {
    const colsSetting: xlsx.ColInfo[] = Object.entries(data[0]).map(
      ([key, value]) => {
        return {
          hidden: false,
          wch: 20,
        };
      }
    );
    const rowsSetting: xlsx.RowInfo[] = Object.entries(data[0]).map(
      ([key, value]) => {
        return {
          hidden: false,
          hpt: 16,
        };
      }
    );
    ws["!cols"] = colsSetting;
    ws["!rows"] = rowsSetting;
    /* add to workbook */
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, sheetName);

    /* write workbook (use type 'array') */
    const workBookOut = xlsx.write(wb, {
      bookType: DEFAULT_FILE_TYPE,
      type: "array",
    });

    saveAs(
      new Blob([workBookOut], { type: "application/octet-stream" }),
      `${fileName}.${DEFAULT_FILE_TYPE}`
    );
  }
};
export { makeXlsx };

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Checkbox, Link, TableCell, TableRow } from "@mui/material";
import { tw } from "../../../../utils/tw";
import { cloneDeep, get, set } from "lodash";
import OrderClaimDetail from "./orderClaimDetail";
import { IDataClaimOrder } from "../../../../types/ClaimOrder.type";
import dayjs from "dayjs";
import ChipOrderStatus from "../../../components/Chip/ChipOrderStatus";
import TableCustom, {
  ExpandableTableRow,
} from "../../../components/Table/TableCustom";

interface IOrderClaim {
  data: IDataClaimOrder[];
  count: number;
  currentPageUpComing: number;
  onChangeCurrentPageUpComing: (page: number) => void;
  refetchListClaimOrderUpcoming?: () => void;
  refetchListClaimOrderProgress?: () => void;
  refetchListClaimOrderHistory?: () => void;
  isFirst?: boolean;
  itemSelected: string[];
  setItemSelected: (ids: string[]) => void;
  currentOrderByKey:
    | "order_no"
    | "customer_no"
    | "delivery_date"
    | "order_date"
    | "actual_shipping_date";
  setCurrentOrderByKey: Dispatch<
    SetStateAction<
      | "order_no"
      | "customer_no"
      | "delivery_date"
      | "order_date"
      | "actual_shipping_date"
    >
  >;
  orderByType: "desc" | "asc";
  setOrderByType: Dispatch<SetStateAction<"desc" | "asc">>;
}

type TClaimOrder = "created" | "processing" | "approved" | "rejected";

const ChipStyle: {
  [k in TClaimOrder]: {
    label: string;
    fontColor: string;
    bgColor: string;
  };
} = {
  created: {
    label: "รอสร้างรายการ",
    fontColor: "#4B4E5F",
    bgColor: "#E2E2E2",
  },
  processing: {
    label: "รอการอัปเดต",
    fontColor: "#F57C00",
    bgColor: "#F7E6D3",
  },
  approved: {
    label: "สำเร็จ",
    fontColor: "#409261",
    bgColor: "#E9FFEF",
  },
  rejected: {
    label: "ไม่อนุมัติ",
    fontColor: "#D32F2F",
    bgColor: "#F9E0E0",
  },
};

const classes = {
  container: tw(`mt-6`),
  emptyTable: tw(
    `font-sans text-center w-full text-[#979797] text-[14px] py-3`
  ),
};

const headerItems = [
  {
    id: "order_no",
    numeric: false,
    disablePadding: false,
    label: "หมายเลขสั่งซื้อ",
    width: "auto",
    isSorting: true,
    className: tw(`whitespace-pre`),
  },
  {
    id: "customer_no",
    numeric: false,
    disablePadding: false,
    label: "รหัสลูกค้า",
    width: "auto",
    isSorting: true,
    className: tw(`whitespace-pre`),
  },
  {
    id: "customer_name",
    numeric: false,
    disablePadding: false,
    label: "ชื่อลูกค้า",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
  {
    id: "delivery_date",
    numeric: false,
    disablePadding: false,
    label: "วันที่จัดส่งสินค้า",
    width: "auto",
    isSorting: true,
    className: tw(`whitespace-pre`),
  },
  {
    id: "order_date",
    numeric: false,
    disablePadding: false,
    label: "วันที่สั่งสินค้า",
    width: "auto",
    isSorting: true,
    className: tw(`whitespace-pre`),
  },
  {
    id: "sell_name",
    numeric: false,
    disablePadding: false,
    label: "พนักงานขาย",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
  {
    id: "partner_no",
    numeric: false,
    disablePadding: false,
    label: "รหัสพาร์ทเนอร์",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
  {
    id: "partner_name",
    numeric: false,
    disablePadding: false,
    label: "ชื่อพาร์ทเนอร์",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "สถานะ",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
  {
    id: "",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
];

const OrderClaim = (props: IOrderClaim) => {
  const {
    data,
    count,
    currentPageUpComing,
    onChangeCurrentPageUpComing,
    refetchListClaimOrderUpcoming,
    refetchListClaimOrderProgress,
    refetchListClaimOrderHistory,
    isFirst,
    itemSelected,
    setItemSelected,
    currentOrderByKey,
    setCurrentOrderByKey,
    orderByType,
    setOrderByType,
  } = props;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = data.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };

  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };

  const rowChildren = useMemo(() => {
    return data.map((row, index) => {
      const isItemSelected = isSelected(row.id);
      const { bgColor, fontColor, label } =
        ChipStyle[row.orderClaimStatus as TClaimOrder];

      return (
        <ExpandableTableRow
          key={row.id}
          selected={isItemSelected}
          aria-checked={isItemSelected}
          expandComponent={
            <OrderClaimDetail
              data={row}
              refetchListClaimOrderUpcoming={refetchListClaimOrderUpcoming}
              refetchListClaimOrderProgress={refetchListClaimOrderProgress}
              refetchListClaimOrderHistory={refetchListClaimOrderHistory}
              isFirst={isFirst}
            />
          }
        >
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              onClick={(event) => handleClick(event, row.id)}
              sx={{
                borderWidth: "1px",
                color: "#C9C9C9",
                "&.Mui-checked": {
                  color: "#68C184",
                },
              }}
            />
          </TableCell>
          <TableCell>{row.orderNumber}</TableCell>
          <TableCell>
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                console.info("I'm a button.");
              }}
              sx={{
                color: "#68C184",
                textDecorationColor: "#68C184",
                ":hover": {
                  fontSize: "16px",
                },
              }}
            >
              {row.contract
                ? row.contract.house.farm.farmer.registrationId
                : "-"}
            </Link>
          </TableCell>
          <TableCell>
            {row.contract
              ? row.contract.house.farm.farmer.name +
                " " +
                row.contract.house.farm.farmer.surname
              : "-"}
          </TableCell>
          <TableCell>
            {row.actualShippingDate
              ? dayjs(row.actualShippingDate).format("DD/MM/YYYY")
              : "-"}
          </TableCell>
          <TableCell>
            {row.shippingDate
              ? dayjs(row.shippingDate).format("DD/MM/YYYY")
              : "-"}
          </TableCell>
          <TableCell>{row.sellerName + " " + row.sellerSurname}</TableCell>
          <TableCell>{row.partnerCode}</TableCell>
          <TableCell>{row.partnerName + " " + row.partnerSurname}</TableCell>
          <TableCell>
            <ChipOrderStatus
              label={label}
              fontColor={fontColor}
              bgColor={bgColor}
            />
          </TableCell>
        </ExpandableTableRow>
      );
    });
  }, [data, currentPageUpComing, itemSelected]);

  return (
    <TableCustom
      order={orderByType}
      onChangeOrder={setOrderByType}
      orderBy={currentOrderByKey}
      onChangeOrderBy={setCurrentOrderByKey as any}
      totalItemSize={count}
      headerItems={headerItems}
      page={currentPageUpComing}
      onChangePage={onChangeCurrentPageUpComing}
      rowsPerPage={10}
      rows={data!}
      EmptyComponent={
        data.length === 0 ? (
          <div className={classes.emptyTable}>ไม่มีข้อมูล</div>
        ) : null
      }
      rowChildren={rowChildren}
      currentPageItems={data!.map(({ id }) => id)}
      itemsSelected={itemSelected}
      onSelectAllClick={handleSelectAllClick}
      onSelectItem={(val) => setItemSelected(val as string[])}
    />
  );
};

export default OrderClaim;

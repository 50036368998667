import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Path, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import ButtonOutlinedCustom from "../../../components/Button/ButtonOutlinedCustom";
import { tableCellHeader } from "../pages/credit/Modal/CreditModal";
import {
  TableCellCustom,
  TableCellHeaderCustom,
} from "../pages/credit/Modal/TableCellCustom";
import { AllowedFieldName } from "./SegmentWeightInput";
import { ICreditForm } from "../../../../types/admin/masterdata/creditForm";
import { useMemo } from "react";
import PlusCircleIcon from "../../../assets/svg/PlusCircleIcon.svg";

export type AllowedFieldChecklistsName = Extract<
  Path<ICreditForm>,
  | "generalPersonalInformation"
  | "generalLegalInformation"
  | "generalFarmInformation"
  | "farmPerformance"
  | "farmFinancialStatusAndInstallment"
  | "applicationUsage"
>;

type Props = {
  isDisplayAddRow: boolean;
  name: AllowedFieldChecklistsName;
  viewAction: boolean;
};

const TableCreditCustom = ({ isDisplayAddRow, name, viewAction }: Props) => {
  const { control } = useFormContext<ICreditForm>();
  const { fields, append, remove } = useFieldArray({
    name: `${name}.checklists`,
    control,
  });

  const watch = useWatch({
    control,
    name: `${name}.checklists`,
  });

  const summaryWeight = useMemo(
    () =>
      watch.reduce(
        (acc, row) => (acc += row.isActive ? Number(row.weight || 0) : 0),
        0
      ),
    [watch]
  );

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{
          scrollBehavior: "smooth",
          overflow: "auto",
          borderRadius: "6px",
          boxShadow: "none",
          border: "1px solid #E1E1E1",
          borderBottom: "none",
          width: "100%",
        }}
      >
        <Table aria-labelledby="table-show-data" size={"medium"}>
          <TableHead
            sx={{
              bgcolor: "#FBFBFB",
              borderBottom: "1px solid #E1E1E1",
            }}
          >
            <TableRow>
              {tableCellHeader.map((cell, index) =>
                index === 2 ? (
                  <TableCellHeaderCustom
                    key={index}
                    value={`Weight (${summaryWeight})`}
                    width={cell.width}
                  />
                ) : (
                  <TableCellHeaderCustom
                    key={index}
                    value={cell.value}
                    width={cell.width}
                  />
                )
              )}
            </TableRow>
          </TableHead>
          {fields.map((field, index) => (
            <TableCellCustom
              key={field.id}
              name={name}
              index={index}
              removeCell={remove}
              viewAction={viewAction}
            />
          ))}
        </Table>
      </TableContainer>
      {isDisplayAddRow && !viewAction && (
        <div className="mt-4">
          <ButtonOutlinedCustom
            btnBorderColor="#68C184"
            btnTextColor="#68C184"
            startIcon={<PlusCircleIcon />}
            title={"Add Row"}
            onClick={() =>
              append({
                id: "",
                isActive: false,
                isActivatable: true,
                isDeletable: true,
                general: "",
                weight: "",
                options: [
                  {
                    key: "",
                    value: "1",
                  },
                  {
                    key: "",
                    value: "2",
                  },
                  {
                    key: "",
                    value: "3",
                  },
                  {
                    key: "",
                    value: "4",
                  },
                  {
                    key: "",
                    value: "5",
                  },
                ],
              })
            }
          />
        </div>
      )}
    </div>
  );
};

export default TableCreditCustom;

function ImportIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33203 8.33268L1.33203 8.91602C1.33203 9.88251 2.11553 10.666 3.08203 10.666L8.91537 10.666C9.88186 10.666 10.6654 9.88251 10.6654 8.91602L10.6654 8.33268M8.33203 3.66601L5.9987 1.33268M5.9987 1.33268L3.66536 3.66602M5.9987 1.33268L5.9987 8.33268"
        stroke="#68C184"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ImportIcon;

import {
  Backdrop,
  Box,
  Fade,
  Modal,
  SvgIcon,
  SxProps,
  Theme,
} from "@mui/material";
import ExclamationCircle from "../../../assets/svg/ExclamationCircle";
import ButtonFillCustom from "../Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../Button/ButtonOutlinedCustom";

const style: SxProps<Theme> = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "350px",
  bgcolor: "background.paper",
  boxShadow: 12,
  padding: "24px",
  borderRadius: "12px",
};

interface IConfirmOrderDeliveryModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

function ConfirmOrderDeliveryModal({
  open,
  onClose,
  onSubmit,
}: IConfirmOrderDeliveryModalProps) {
  return (
    <Modal
      aria-labelledby="confirm-order-delivery-modal-title"
      aria-describedby="confirm-order-delivery-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="w-full h-full">
            <div className="flex items-center gap-1">
              <SvgIcon sx={{ color: "#68C184" }}>
                <ExclamationCircle />
              </SvgIcon>
              <span className="font-sans font-bold text-[1rem] text-[#191919]">
                ยืนยันการจัดส่งสินค้าสำเร็จ
              </span>
            </div>
            <div className="pt-6 flex flex-col">
              <span className="font-sans font-medium text-[14px] leading-5 text-[#191919]">
                คุณต้องการจัดส่งสินค้าใช่หรือไม่ ?
              </span>
              <span className="font-sans font-bold text-[14px] leading-5 text-[#191919]">
                การดำเนินการนี้ไม่สามารถยกเลิกได้
              </span>
            </div>
            <div className="flex w-full justify-end gap-2 pt-6">
              <ButtonOutlinedCustom
                title="ยกเลิก"
                btnBorderColor="#68C184"
                btnTextColor="#68C184"
                onClick={onClose}
              />
              <ButtonFillCustom title="ยืนยัน" onClick={onSubmit} />
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
export default ConfirmOrderDeliveryModal;

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { theme } from "../../../theme/light";
import { IconButton, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

interface ConfirmDialogProps {
  open: boolean;
  title?: string;
  bodyText?: string;
  agreeButtonLabel?: string;
  disagreeButtonLabel?: string;
  handleClose?: (cofirm: boolean) => void;
}

export default function ConfirmDialog({
  title,
  bodyText,
  agreeButtonLabel,
  disagreeButtonLabel,
  open,
  handleClose,
}: ConfirmDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: "2rem",
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <div>
          <div className="flex justify-end text-grey-600">
            <FontAwesomeIcon
              icon={faClose}
              onClick={() => handleClose?.(false)}
            />
          </div>
          <div className="mt-4 flex justify-center">
            <Typography
              variant="h5"
              sx={{ color: (theme) => theme.palette.warning.main }}
            >
              {" "}
              {title}
            </Typography>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" align="center">
          {bodyText}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          marginBottom: "2rem",
          paddingX: "1.5rem",
        }}
      >
        {agreeButtonLabel && (
          <Button
            sx={{ textTransform: "none" }}
            onClick={() => handleClose?.(true)}
            type="submit"
            variant="outlined"
            fullWidth
            color="secondary"
            size="large"
          >
            {agreeButtonLabel}
          </Button>
        )}
        {disagreeButtonLabel && (
          <Button
            sx={{ textTransform: "none" }}
            onClick={() => handleClose?.(false)}
            type="submit"
            variant="contained"
            fullWidth
            color="secondary"
            size="large"
          >
            {disagreeButtonLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

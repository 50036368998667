type HeartSVGVariant = "green" | "blue" | "orange";

export interface HeartSVGProps {
  variant: HeartSVGVariant;
}

const COLOR = {
  green: {
    dark: "#68C184",
    light: "#DDF3E4",
    lightest: "#F0FDF4",
  },
  blue: {
    dark: "#5572EB",
    light: "#DDE4FF",
    lightest: "#EEF2FF",
  },
  orange: {
    dark: "#F57C00",
    light: "#FBCFA1",
    lightest: "#FEF9F2",
  },
};
export default function HeartSVG({ variant = "green" }: HeartSVGProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <mask
        id="mask0_5426_20354"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <rect y="0.648438" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5426_20354)">
        <path
          d="M12 22.6484L10.405 21.2315C8.55333 19.5785 7.0225 18.1525 5.8125 16.9536C4.6025 15.7547 3.64 14.6784 2.925 13.7247C2.21 12.7711 1.71042 11.8946 1.42625 11.0953C1.14208 10.296 1 9.47859 1 8.64299C1 6.93545 1.5775 5.50947 2.7325 4.36506C3.8875 3.22064 5.32667 2.64844 7.05 2.64844C8.00333 2.64844 8.91083 2.84826 9.7725 3.24789C10.6342 3.64753 11.3767 4.21065 12 4.93727C12.6233 4.21065 13.3658 3.64753 14.2275 3.24789C15.0892 2.84826 15.9967 2.64844 16.95 2.64844C18.6733 2.64844 20.1125 3.22064 21.2675 4.36506C22.4225 5.50947 23 6.93545 23 8.64299C23 9.47859 22.8579 10.296 22.5738 11.0953C22.2896 11.8946 21.79 12.7711 21.075 13.7247C20.36 14.6784 19.3975 15.7547 18.1875 16.9536C16.9775 18.1525 15.4467 19.5785 13.595 21.2315L12 22.6484Z"
          fill={COLOR[variant]?.lightest}
        />
        <path
          d="M12 19.6484L10.985 18.6566C9.80667 17.4995 8.8325 16.5013 8.0625 15.6621C7.2925 14.8228 6.68 14.0694 6.225 13.4018C5.77 12.7343 5.45208 12.1207 5.27125 11.5612C5.09042 11.0018 5 10.4295 5 9.84462C5 8.64935 5.3675 7.65116 6.1025 6.85007C6.8375 6.04898 7.75333 5.64844 8.85 5.64844C9.45667 5.64844 10.0342 5.78831 10.5825 6.06806C11.1308 6.3478 11.6033 6.74199 12 7.25062C12.3967 6.74199 12.8692 6.3478 13.4175 6.06806C13.9658 5.78831 14.5433 5.64844 15.15 5.64844C16.2467 5.64844 17.1625 6.04898 17.8975 6.85007C18.6325 7.65116 19 8.64935 19 9.84462C19 10.4295 18.9096 11.0018 18.7288 11.5612C18.5479 12.1207 18.23 12.7343 17.775 13.4018C17.32 14.0694 16.7075 14.8228 15.9375 15.6621C15.1675 16.5013 14.1933 17.4995 13.015 18.6566L12 19.6484Z"
          fill={COLOR[variant]?.light}
        />
        <path
          d="M12 16.6484L11.275 16.0108C10.4333 15.267 9.7375 14.6253 9.1875 14.0858C8.6375 13.5463 8.2 13.0619 7.875 12.6328C7.55 12.2036 7.32292 11.8092 7.19375 11.4495C7.06458 11.0899 7 10.722 7 10.346C7 9.57759 7.2625 8.9359 7.7875 8.42092C8.3125 7.90593 8.96667 7.64844 9.75 7.64844C10.1833 7.64844 10.5958 7.73836 10.9875 7.91819C11.3792 8.09803 11.7167 8.35143 12 8.67841C12.2833 8.35143 12.6208 8.09803 13.0125 7.91819C13.4042 7.73836 13.8167 7.64844 14.25 7.64844C15.0333 7.64844 15.6875 7.90593 16.2125 8.42092C16.7375 8.9359 17 9.57759 17 10.346C17 10.722 16.9354 11.0899 16.8063 11.4495C16.6771 11.8092 16.45 12.2036 16.125 12.6328C15.8 13.0619 15.3625 13.5463 14.8125 14.0858C14.2625 14.6253 13.5667 15.267 12.725 16.0108L12 16.6484Z"
          fill={COLOR[variant]?.dark}
        />
      </g>
    </svg>
  );
}

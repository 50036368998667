const DotWarningSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={19} height={19} fill="none">
    <circle cx={9.5} cy={9.5} r={5.5} fill="#F57C00" />
    <g filter="url(#a)">
      <circle cx={9.5} cy={9.5} r={5.5} fill="#F57C00" />
    </g>
    <defs>
      <filter
        id="a"
        width={19}
        height={19}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          result="effect1_foregroundBlur_16433_36926"
          stdDeviation={2}
        />
      </filter>
    </defs>
  </svg>
);
export default DotWarningSvg;

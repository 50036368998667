import {
  EClaimOrderOption,
  EOrderFeedClaimReason,
  IGetExportClaim,
  IGetListUpcomingClaimOrder,
  IListOrder,
  ItemFiles,
  TOrderClaimItem,
  TOrderFeedItem,
} from "../types/ClaimOrder.type";
import { IDownloadUrl } from "../types/ManageStock.type";
import adminAPI from "./AdminApiService";

export const getClaimOrderUpcoming = async (params?: {
  page?: number;
  searchText?: string;
  orderBy?:
    | "order_no"
    | "customer_no"
    | "delivery_date"
    | "order_date"
    | "actual_shipping_date";
  direction?: "desc" | "asc";
  orderDateStart?: Date;
  orderDateEnd?: Date;
  deliveryDateStart?: Date;
  deliveryDateEnd?: Date;
  feedBrandId?: string;
  feedBrandItemIds?: string;
}) => {
  try {
    const { data } = await adminAPI.get<IGetListUpcomingClaimOrder>(
      "/claim-order/upcoming",
      {
        params: {
          page: params?.page,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params?.direction,
          orderDateStart: params?.orderDateStart,
          orderDateEnd: params?.orderDateEnd,
          deliveryDateStart: params?.deliveryDateStart,
          deliveryDateEnd: params?.deliveryDateEnd,
          feedBrandId: params?.feedBrandId,
          feedBrandItemIds: params?.feedBrandItemIds,
        },
      }
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getClaimOrderProgress = async (params?: {
  page?: number;
  searchText?: string;
  orderBy?:
    | "order_no"
    | "customer_no"
    | "delivery_date"
    | "order_date"
    | "actual_shipping_date";
  direction?: "desc" | "asc";
  orderDateStart?: Date;
  orderDateEnd?: Date;
  deliveryDateStart?: Date;
  deliveryDateEnd?: Date;
  feedBrandId?: string;
  feedBrandItemIds?: string;
}) => {
  try {
    const { data } = await adminAPI.get<IGetListUpcomingClaimOrder>(
      "/claim-order/in-progress",
      {
        params: {
          page: params?.page,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params?.direction,
          orderDateStart: params?.orderDateStart,
          orderDateEnd: params?.orderDateEnd,
          deliveryDateStart: params?.deliveryDateStart,
          deliveryDateEnd: params?.deliveryDateEnd,
          feedBrandId: params?.feedBrandId,
          feedBrandItemIds: params?.feedBrandItemIds,
        },
      }
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getClaimOrderHistory = async (params?: {
  page?: number;
  searchText?: string;
  orderBy?:
    | "order_no"
    | "customer_no"
    | "delivery_date"
    | "order_date"
    | "actual_shipping_date";
  direction?: "desc" | "asc";
  orderDateStart?: Date;
  orderDateEnd?: Date;
  deliveryDateStart?: Date;
  deliveryDateEnd?: Date;
  feedBrandId?: string;
  feedBrandItemIds?: string;
}) => {
  try {
    const { data } = await adminAPI.get<IGetListUpcomingClaimOrder>(
      "/claim-order/history",
      {
        params: {
          page: params?.page,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params?.direction,
          orderDateStart: params?.orderDateStart,
          orderDateEnd: params?.orderDateEnd,
          deliveryDateStart: params?.deliveryDateStart,
          deliveryDateEnd: params?.deliveryDateEnd,
          feedBrandId: params?.feedBrandId,
          feedBrandItemIds: params?.feedBrandItemIds,
        },
      }
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getListClaimOrder = async () => {
  try {
    const { data } = await adminAPI.get<IListOrder>("/claim-order/order-feed");
    return data;
  } catch (error) {
    return null;
  }
};

export const getDownloadUrlClaimOrder = async (
  orderClaimItemId: string,
  fileId: string
) => {
  const { data } = await adminAPI.get<IDownloadUrl>(
    `claim-order/download/${orderClaimItemId}/${fileId}`
  );
  return data;
};

export const createdClaim = async (body: {
  orderFeedId: string;
  refundAmount: number;
  remark: string;
}) => {
  const { data } = await adminAPI.post(`/claim-order`, body);
  return data;
};

export const createdOrder = async (body: {
  orderClaimItemId: string;
  shippingDate: Date;
}) => {
  const { data } = await adminAPI.post(
    `claim-order/order-feed/${body.orderClaimItemId}`,
    body
  );
  return data;
};

export const updateClaimCode = async (body: {
  orderClaimItemId: string;
  claimCode: string;
}) => {
  const { data } = await adminAPI.put(
    `claim-order/claim-code/${body.orderClaimItemId}`,
    body
  );
  return data;
};

export const approvedClaimOrder = async (body: {
  orderClaimId: string;
  orderClaimItems: {
    orderClaimItemId: string;
    claimOption: EClaimOrderOption;
    refundAmount: number;
    remark: string;
  }[];
}) => {
  const { data } = await adminAPI.put(
    `claim-order/items/${body.orderClaimId}`,
    body
  );
  return data;
};

export const rejectClaimOrder = async (body: {
  orderClaimId: string;
  rejectOption: string;
  rejectReason: string;
}) => {
  const { data } = await adminAPI.put(
    `claim-order/upcoming/reject/${body.orderClaimId}`,
    body
  );
  return data;
};

export const closeClaimOrder = async (body: { orderClaimId: string }) => {
  const { data } = await adminAPI.put(
    `claim-order/in-progress/success/${body.orderClaimId}`
  );
  return data;
};

export const getExportClaimOrder = async (ids: string[]) => {
  try {
    const { data } = await adminAPI.post<IGetExportClaim>(
      `/claim-order/export`,
      { ids }
    );
    return data.rows;
  } catch (error) {
    return null;
  }
};

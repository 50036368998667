import {
  IGetInvoiceManage,
  IGetProcessInvoiceManager,
  IGetSuccessInvoiceManager,
  IUpdateStatusAssignment,
} from "../../../types/invoice/invoiceManager.type";
import { IGetCountInvoiceSale } from "../../../types/invoice/invoiceSale.type";
import adminAPI from "../../AdminApiService";

export const getInvoiceManager = async (searchParams?: URLSearchParams) => {
  try {
    const { data } = await adminAPI.get<IGetInvoiceManage>(
      `/invoice/manager/assignment-upcoming?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getListExportPastDueManager = async (ids: string[]) => {
  try {
    const { data } = await adminAPI.post<IGetInvoiceManage>(
      `/invoice/manager/assignment-upcoming/export`,
      { ids }
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getProcessInvoiceManager = async (
  searchParams?: URLSearchParams
) => {
  try {
    const { data } = await adminAPI.get<IGetProcessInvoiceManager>(
      `/invoice/manager/assignment-processed?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getListExportProcessManager = async (ids: string[]) => {
  try {
    const { data } = await adminAPI.post<IGetProcessInvoiceManager>(
      `/invoice/manager/assignment-processed/export`,
      { ids }
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getSuccessInvoiceManager = async (
  searchParams?: URLSearchParams
) => {
  try {
    const { data } = await adminAPI.get<IGetSuccessInvoiceManager>(
      `/invoice/manager/invoice-succeeded?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getListExportSuccessManager = async (ids: string[]) => {
  try {
    const { data } = await adminAPI.post<IGetSuccessInvoiceManager>(
      `/invoice/manager/invoice-succeeded/export`,
      { ids }
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getCountInvoiceTab1Manager = async () => {
  try {
    const { data } = await adminAPI.get<IGetCountInvoiceSale>(
      `/invoice/manager/assignment-upcoming/count`
    );
    return data;
  } catch (error) {
    return null;
  }
};
export const getCountInvoiceTab2Manager = async () => {
  try {
    const { data } = await adminAPI.get<IGetCountInvoiceSale>(
      `/invoice/manager/assignment-processed/count`
    );
    return data;
  } catch (error) {
    return null;
  }
};
export const getCountInvoiceTab3Manager = async () => {
  try {
    const { data } = await adminAPI.get<IGetCountInvoiceSale>(
      `/invoice/manager/invoice-succeeded/count`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const updateStatusAssignment = async (
  assignmentId: string,
  payload: IUpdateStatusAssignment
) => {
  return adminAPI.put(
    "/invoice/manager/assignment-upcoming/" + assignmentId,
    payload
  );
};

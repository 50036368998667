const invoice = {
  uploadInvoice: (invoiceId: string) =>
    `invoice/common/document/${invoiceId}/upload/invoice`,
  uploadOther: (invoiceId: string) =>
    `invoice/common/document/${invoiceId}/upload/other`,
  updateInvoice: (invoiceId: string) => `/invoice/sale/upcoming/${invoiceId}`,
  editInvoiceDoc: (invoiceId: string) =>
    `/invoice/common/edit-document/${invoiceId}`,
  getInvoiceDownloadURL: (
    invoiceId: string,
    documentType: string,
    fileId: string
  ) =>
    `/invoice/common/document/${invoiceId}/download/${documentType}/${fileId}`,
  getReceiptDownloadURL: (documentType: string, fileId: string) =>
    `/invoice/common/receipt/document/download/${documentType}/${fileId}`,
  editInvoiceDocument: (invoiceId: string) =>
    `/invoice/common/document/${invoiceId}`,
  uploadPresign: (documentType: string) =>
    `/invoice/common/receipt/document/upload/${documentType}`,
};

export const apiPaths = {
  invoice,
};

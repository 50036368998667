import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { InputAdornment, MenuItem, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { t } from "i18next";
import { InputNumberString } from "../../../components/common/Input/InputNumberString";
import SelectCustom from "../../../components/common/Input/SelectCustom";
import TextInputCustom from "../../../components/common/Input/TextInputCustom";
import { FeedBrands, UnitEnum } from "../../../types/FeedBrand.type";
import { IOrderFeedForm } from "../../../types/OrderFeed.type";
import {
  formArrayError,
  formArrayErrorMessage,
} from "../../../utils/form-validator";

interface IOrderFeedSingleOrder {
  formik: FormikProps<IOrderFeedForm>;
  feedBrands: FeedBrands[];
  orderIndex: number;
}

export default function OrderFeedSingleOrder({
  orderIndex,
  formik,
  feedBrands,
}: IOrderFeedSingleOrder) {
  const handleChangeFeedBrand = (value: any) => {
    const feedBrand = feedBrands.find((row) => row.id === value);
    formik.setFieldValue(
      `orders[${orderIndex}].nameTh`,
      feedBrand?.nameTh ?? ""
    );
    formik.setFieldValue(
      `orders[${orderIndex}].nameEn`,
      feedBrand?.nameEn ?? ""
    );
    formik.setFieldValue(`orders[${orderIndex}].brandSKU`, "");
    formik.setFieldValue(`orders[${orderIndex}].amount`, "");
    formik.setFieldValue(`orders[${orderIndex}].unit`, "");
    formik.setFieldValue(`orders[${orderIndex}].pricePerUnit`, "");
    formik.setFieldValue(`orders[${orderIndex}].unitLabel`, "");
    formik.setFieldValue(`orders[${orderIndex}].skuTh`, "");
    formik.setFieldValue(`orders[${orderIndex}].skuEn`, "");
    formik.setFieldTouched(`orders[${orderIndex}].brandSKU`, false);
    formik.setFieldTouched(`orders[${orderIndex}].amount`, false);
  };

  const handleChangeFeedSKU = (value: any) => {
    const feedSKU = getFeedSKU().find((row) => row.id === value);
    if (feedSKU) {
      formik.setFieldValue(`orders[${orderIndex}].skuTh`, feedSKU.skuTh ?? "");
      formik.setFieldValue(`orders[${orderIndex}].skuEn`, feedSKU.skuEn ?? "");
      formik.setFieldValue(`orders[${orderIndex}].unit`, feedSKU.unit ?? "");
      formik.setFieldValue(
        `orders[${orderIndex}].unitLabel`,
        UnitEnum[feedSKU.unit] ?? ""
      );
      formik.setFieldValue(
        `orders[${orderIndex}].pricePerUnit`,
        feedSKU.pricePerUnit ?? 0
      );
    } else {
      formik.setFieldValue(`orders[${orderIndex}].skuTh`, "");
      formik.setFieldValue(`orders[${orderIndex}].skuEn`, "");
      formik.setFieldValue(`orders[${orderIndex}].unit`, "");
      formik.setFieldValue(`orders[${orderIndex}].unitLabel`, "");
      formik.setFieldValue(`orders[${orderIndex}].pricePerUnit`, 0);
    }
  };

  const getFeedSKU = () => {
    const selectedFeedBrand = feedBrands.find(
      (row) => row.id === formik.values.orders[orderIndex].brandId
    );
    const feedSKUs = selectedFeedBrand?.feed_brand_items ?? [];
    return feedSKUs;
  };

  return (
    <>
      {/* Feed Brand Input */}
      <SelectCustom
        displayEmpty
        label={t("orderFeed.input.label.brandId") as any}
        fullWidth
        variant="outlined"
        shrink={true}
        error={formArrayError(formik, `orders[${orderIndex}].brandId`)}
        helperText={formArrayErrorMessage(
          formik,
          `orders[${orderIndex}].brandId`
        )}
        {...formik.getFieldProps(`orders[${orderIndex}].brandId`)}
        onChange={(e) => {
          const { onChange } = formik.getFieldProps(
            `orders[${orderIndex}].brandId`
          );
          onChange(e);
          handleChangeFeedBrand(e.target.value);
        }}
        renderValue={
          formik.values?.orders[orderIndex].brandId !== ""
            ? undefined
            : () => (
                <div className="text-[1rem] text-primary-grey-100">
                  {t("orderFeed.input.placeholder.brandId")}
                </div>
              )
        }
        menuItems={feedBrands.map((option, idx) => (
          <MenuItem key={idx} value={option.id}>
            {option.nameTh}
          </MenuItem>
        ))}
      />
      {/* Brand SKU Input */}
      <SelectCustom
        displayEmpty
        label={t("orderFeed.input.label.brandSKU") as any}
        fullWidth
        variant="outlined"
        shrink={true}
        error={formArrayError(formik, `orders[${orderIndex}].brandSKU`)}
        helperText={formArrayErrorMessage(
          formik,
          `orders[${orderIndex}].brandSKU`
        )}
        {...formik.getFieldProps(`orders[${orderIndex}].brandSKU`)}
        onChange={(e) => {
          const { onChange } = formik.getFieldProps(
            `orders[${orderIndex}].brandSKU`
          );
          onChange(e);
          handleChangeFeedSKU(e.target.value);
        }}
        renderValue={
          formik.values?.orders[orderIndex].brandSKU !== ""
            ? undefined
            : () => (
                <div className="text-[1rem] text-primary-grey-100">
                  {t("orderFeed.input.placeholder.brandSKU")}
                </div>
              )
        }
        menuItems={getFeedSKU().map((option, idx) => (
          <MenuItem key={idx} value={option.id}>
            {option.skuTh}
          </MenuItem>
        ))}
      />
      {/* Amount Input */}
      <TextInputCustom
        focused={true}
        type="tel"
        label={t("orderFeed.input.label.amount")}
        helperText={formArrayErrorMessage(
          formik,
          `orders[${orderIndex}].amount`
        )}
        error={formArrayError(formik, `orders[${orderIndex}].amount`)}
        placeholder={t("orderFeed.input.placeholder.amount") ?? ""}
        {...formik.getFieldProps(`orders[${orderIndex}].amount`)}
      />
      {/* Unit */}
      <TextInputCustom
        focused={true}
        disabled
        label={t("orderFeed.input.label.unit")}
        placeholder={t("orderFeed.input.placeholder.unit") ?? ""}
        value={formik.values.orders[orderIndex].unitLabel}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockOutlinedIcon color="disabled" fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
      {/* Price Per Unit */}
      <div>
        <TextInputCustom
          focused={true}
          disabled
          label={t("orderFeed.input.label.pricePerUnit")}
          placeholder={t("orderFeed.input.placeholder.pricePerUnit") ?? ""}
          value={formik.values.orders[orderIndex].pricePerUnit}
          InputProps={{
            inputComponent: InputNumberString as any,
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon color="disabled" fontSize="small" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Typography className="font-medium" color="disabled">
                  | {t("orderFeed.input.unit.baht")}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
        <span className="pt-2 text-primary-grey text-[0.75rem]">
          {t("orderFeed.input.notice.price")}
        </span>
      </div>
    </>
  );
}

import react from "react";

interface DailyLogContextData {
  finalDailyLogData: any;
  setEditMedicine?: React.Dispatch<React.SetStateAction<any>>;
  setDailyLogData?: React.Dispatch<React.SetStateAction<any>>;
  isEditMedicine: boolean;
}

const DailyLogContext = react.createContext<DailyLogContextData>({
  finalDailyLogData: {},
  isEditMedicine: false,
});

export default DailyLogContext;

import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { IOrderItemProps } from "./views/OrderItem";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { snakeActions } from "../../redux/slices/snakeSlice";
import { loadingActions } from "../../redux/slices/loadingSlice";
import {
  completeOrder,
  deliverOrder,
  editDocument,
  getUploadUrlOrderPartner,
  receiveOrder,
} from "../../services/PartnerOrderService";
import getErrorMessage, { getIsAPIError } from "../../utils/getErrorMessage";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { TBaseGetListPartnerStockList } from "../../types/partner/stock.type";
import {
  editDocumentStock,
  getUploadUrlOrderStock,
} from "../../services/partner/stock.service";

interface IUseActionControllerProps {
  orderDetail?: IOrderItemProps;
  onCloseModal: () => void;
  stockDetail?: TBaseGetListPartnerStockList;
  isNotClose?: boolean;
}

export const useActionController = ({
  orderDetail,
  onCloseModal,
  stockDetail,
  isNotClose,
}: IUseActionControllerProps) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const onError = (error: any) => {
    onCloseModal();
    setTimeout(() => {
      dispatch(loadingActions.hide());
      dispatch(
        snakeActions.showMessage({
          message: getErrorMessage(error),
          type: "error",
        })
      );
    }, 2000);
  };

  const refetch = async () => {
    if (!orderDetail) return false;
    await queryClient.refetchQueries({ queryKey: ["order", "history"] });
    await queryClient.refetchQueries({ queryKey: ["order", "new"] });
    await queryClient.refetchQueries({
      queryKey: ["order", "detail", orderDetail.id],
    });
  };

  const onGetOrder = useCallback(() => {
    if (!orderDetail) return false;
    dispatch(loadingActions.show({ message: "กำลังยืนยันรับออเดอร์..." }));

    receiveOrder(orderDetail.id)
      .then((result) => {
        const { isError, errorMessage } = getIsAPIError(result);
        if (!isError) {
          onCloseModal();
          refetch();
          setTimeout(() => {
            dispatch(loadingActions.hide());
            dispatch(
              snakeActions.showMessage({
                message: "ยืนยันรับออเดอร์สำเร็จ",
                type: "success",
              })
            );
          }, 2000);
        } else {
          onError(errorMessage);
        }
      })
      .catch((e) => {
        onError(e);
      });
  }, [orderDetail]);

  const onShipping = useCallback(() => {
    if (!orderDetail) return false;
    dispatch(loadingActions.show({ message: "กำลังยืนยันการจัดส่งสินค้า..." }));

    deliverOrder(orderDetail.id)
      .then((result) => {
        const { isError, errorMessage } = getIsAPIError(result);
        if (!isError) {
          onCloseModal();
          setTimeout(() => {
            refetch();
            dispatch(loadingActions.hide());
            dispatch(
              snakeActions.showMessage({
                message: "ยืนยันการจัดส่งสินค้าสำเร็จ",
                type: "success",
              })
            );
          }, 2000);
        } else {
          onError(errorMessage);
        }
      })
      .catch((e) => {
        onError(e);
      });
  }, [orderDetail]);

  const onShipped = useCallback(() => {
    if (!orderDetail) return false;
    onCloseModal();
    setTimeout(() => {
      navigate(`/order/prove-shipping/${orderDetail.id}`);
    }, 400);
  }, [orderDetail]);

  const onReceived = useCallback(() => {
    if (!orderDetail) return false;
    onCloseModal();
  }, [orderDetail]);

  const onProveShipped = useCallback(
    async (images: any, isStock?: boolean) => {
      if (!isStock) {
        if (!orderDetail) return false;
        dispatch(loadingActions.show({ message: "กำลังบันทึกรูปภาพ..." }));
        // TODO: will handle with promise.all later
        const fileArray: {
          id: string;
          url: string;
          fileName: string;
          fileSize: number;
          documentType: "partnerProofOfDelivery" | "partnerPickingSlip";
        }[] = [];
        if ((images || []).length > 0) {
          for (let i = 0; i < images.length; i++) {
            const image = images[i];
            const documentType =
              i < images.length - 1
                ? "partnerProofOfDelivery"
                : "partnerPickingSlip";
            const presign = await getUploadUrlOrderPartner(
              orderDetail!.orderNo,
              documentType
            );
            await axios.put(presign.blob.blobUrl, image, {
              responseType: "blob",
              headers: {
                "Content-Type": image.type,
                "x-ms-blob-type": "BlockBlob",
              },
            });
            fileArray.push({
              id: presign.blob.fileId,
              url: presign.blob.url || "",
              fileName: image.name,
              fileSize: image.size,
              documentType,
            });
          }
        }

        Promise.all([
          completeOrder(orderDetail.id),
          editDocument({
            id: orderDetail.id,
            documents: fileArray.map((f) => ({
              fileId: f.id,
              fileName: f.fileName,
              fileSize: f.fileSize,
              documentType: f.documentType,
              url: f.url,
            })),
          }),
        ])
          .then(([result1, result2]) => {
            const { isError: isError1, errorMessage: errorMessage1 } =
              getIsAPIError(result1);
            const { isError: isError2, errorMessage: errorMessage2 } =
              getIsAPIError(result2);
            if (!isError1 && !isError2) {
              refetch();
              setTimeout(() => {
                if (isNotClose === true) {
                } else onCloseModal();
                dispatch(loadingActions.hide());
                dispatch(
                  snakeActions.showMessage({
                    message: "บันทึกรูปภาพสำเร็จ",
                    type: "success",
                  })
                );
                if (isNotClose === true) {
                } else navigate(`/order/detail/${orderDetail.id}`);
              }, 2000);
            } else {
              onError(errorMessage1 || errorMessage2);
            }
          })
          .catch((e) => {
            onError(e);
          });
      } else {
        if (!stockDetail) return false;
        dispatch(loadingActions.show({ message: "กำลังบันทึกรูปภาพ..." }));

        // TODO: will handle with promise.all later
        const fileArray: {
          fileId: string;
          // url: string;
          fileName: string;
          fileSize: number;
          documentType: "partnerProofOfDelivery" | "partnerPickingSlip";
        }[] = [];
        if ((images || []).length > 0) {
          for (let i = 0; i < images.length; i++) {
            const image = images[i];
            const documentType =
              i < images.length - 1
                ? "partnerProofOfDelivery"
                : "partnerPickingSlip";
            const presign = await getUploadUrlOrderStock(
              stockDetail?.stockRefill.id!,
              documentType
            );
            await axios.put(presign.blob.blobUrl, image, {
              responseType: "blob",
              headers: {
                "Content-Type": image.type,
                "x-ms-blob-type": "BlockBlob",
              },
            });
            fileArray.push({
              fileId: presign.blob.fileId,
              // url: presign.blob.url || "",
              fileName: image.name,
              fileSize: image.size,
              documentType,
            });
          }
        }

        Promise.all([
          // completeOrder(stockDetail.stockRefill.id),
          editDocumentStock({
            id: stockDetail.stockRefill.id,
            documents: fileArray.map((f) => ({
              fileId: f.fileId,
              fileName: f.fileName,
              fileSize: f.fileSize,
              documentType: f.documentType,
              // url: f.url,
            })),
          }),
        ])
          .then(([result1]) => {
            const { isError: isError1, errorMessage: errorMessage1 } =
              getIsAPIError(result1);

            if (!isError1) {
              refetch();
              setTimeout(() => {
                if (isNotClose === true) {
                } else onCloseModal();
                dispatch(loadingActions.hide());
                dispatch(
                  snakeActions.showMessage({
                    message: "บันทึกรูปภาพสำเร็จ",
                    type: "success",
                  })
                );
                if (isNotClose === true) {
                } else
                  navigate(
                    `/stock/stock-history/${stockDetail.stockRefill.id}`
                  );
              }, 2000);
            } else {
              onError(errorMessage1);
            }
          })
          .catch((e) => {
            onError(e);
          });
      }
    },
    [orderDetail, stockDetail?.stockRefill]
  );

  const actionFunctions = {
    new: onGetOrder,
    "ready-for-ship": onShipping,
    shipping: onShipped,
    shipped: onReceived,
    proveShipped: onProveShipped,
    received: () => {},
    rejected: () => {},
    canceled: () => {},
  };

  return {
    onGetOrder,
    onShipping,
    onShipped,
    onReceived,
    actionFunctions,
  };
};

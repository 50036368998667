import { Box, IconButton, Modal } from "@mui/material";
import React from "react";
import { ISellingHistoryInfoModalProps } from "./types";
import CustomButton from "../Button";
import PopupImage from "../../../assets/images/FarmRegistration/popup_bg.png";
import CloseIcon from "@mui/icons-material/Close";

function SellingHistoryInfoModal({
  isOpen,
  onClose,
  title,
  subTitle,
  descriptions,
  haveBackgroundImage,
  textColor,
  titleBtn,
  height,
  haveCloseIcon,
}: ISellingHistoryInfoModalProps) {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      disableEnforceFocus={false}
      disableAutoFocus={true}
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "313px",
          minWidth: "313px",
          bgcolor: "#000",
          borderRadius: "40px",
          boxShadow: 24,
          ...(height ? { height } : {}),
        }}
      >
        <div
          className="flex flex-col relative px-6 pt-8 pb-6 bg-white w-full h-full rounded-[40px]"
          style={{
            ...(haveBackgroundImage
              ? {
                  backgroundImage: `url(${PopupImage})`,
                  backgroundRepeat: "no-repeat",
                }
              : {}),
          }}
        >
          {haveCloseIcon && (
            <div className="absolute right-2 top-2">
              <IconButton aria-label="close" onClick={() => onClose()}>
                <CloseIcon />
              </IconButton>
            </div>
          )}
          <span
            className={`text-3xl font-medium text-center ${
              textColor ? `text-[${textColor}]` : "text-primary"
            } ${haveCloseIcon ? "pt-6" : ""}`}
          >
            {title}
          </span>
          {subTitle && (
            <span className="text-sm text-tertiary text-center pt-4 font-medium">
              {subTitle}
            </span>
          )}
          {typeof descriptions === "string" ? (
            <span className="text-sm pb-12 pt-4">{descriptions}</span>
          ) : (
            descriptions
          )}
          <div className="flex h-full items-end align-bottom justify-center">
            <CustomButton
              variant="tertiary"
              label={titleBtn ?? "Close"}
              onClick={() => onClose()}
              removeBoxShadow={true}
            />
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default SellingHistoryInfoModal;

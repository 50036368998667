import { Chip } from "@mui/material";
import { StatusEnum, TColor } from "./type";

type TStatusOrderFeedProps = {
  status: StatusEnum;
  isReportOrder?: boolean;
};

type NewType = {
  label: string;
  color: TColor;
};

const labelWithColor: {
  [k in StatusEnum]?: NewType;
} = {
  waiting_for_sale_approval: {
    label: "สั่งซื้อสำเร็จ",
    color: "warning",
  },
  waiting_for_sale_send_to_lender: {
    label: "สั่งซื้อสำเร็จ",
    color: "warning",
  },
  waiting_for_lender_approval: {
    label: "สั่งซื้อสำเร็จ",
    color: "warning",
  },
  waiting_for_delivery: {
    label: "ที่ต้องได้รับ",
    color: "warning",
  },
  delivery: {
    label: "อยู่ระหว่างจัดส่ง",
    color: "warning",
  },
  waiting_for_acceptance: {
    label: "ยืนยันการรับ",
    color: "warning",
  },
  accepted: {
    label: "รับสินค้าสำเร็จ",
    color: "success",
  },
  canceled: {
    label: "ออเดอร์ถูกยกเลิก",
    color: "error",
  },
  rejected: {
    label: "ออเดอร์ถูกยกเลิก",
    color: "error",
  },
};

const StatusFarmerOrderFeed = ({
  status,
  isReportOrder,
}: TStatusOrderFeedProps) => {
  return (
    <>
      {isReportOrder ? (
        <Chip
          label="รายงานปัญหา"
          color="error"
          variant="outlined"
          size="small"
        />
      ) : (
        <Chip
          label={labelWithColor[status]?.label}
          color={labelWithColor[status]?.color}
          variant="outlined"
          size="small"
        />
      )}
    </>
  );
};

export default StatusFarmerOrderFeed;

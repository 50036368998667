const IconDotSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
    <path
      fill="#fff"
      d="M3.997 7.999a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0ZM8.664 7.999a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0ZM13.33 7.999a.667.667 0 1 1-1.332 0 .667.667 0 0 1 1.333 0Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3.33 7.999h.007m4.66 0h.007m4.66 0h.007m-8.674 0a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Zm4.667 0a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Zm4.667 0a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Z"
    />
  </svg>
);
export default IconDotSvg;

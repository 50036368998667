import adminAPI from "../../../../../services/AdminApiService";
import { TAcceptDebtForm } from "../components/Modals/AcceptDebtModal";
import { TCloseDebtNotAcceptDeptForm } from "../components/Modals/CloseDebtNotAcceptModal";
import { TDetailNotAcceptDeptForm } from "../components/Modals/UpdateDetailNotAcceptModal";
import { IGetAcceptDebt } from "../types/acceptDebt.type";
import { IGetNotAcceptDebt } from "../types/notAcceptDebt.type";

export const getNotAcceptDebt = async (searchParams?: URLSearchParams) => {
  try {
    const { data } = await adminAPI.get<IGetNotAcceptDebt>(
      `/money-debt/sale/not-accept-debt?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const updateDetailNotAcceptDebt = async (
  invoiceId: string,
  body: TDetailNotAcceptDeptForm
) => {
  return adminAPI.put(`/money-debt/sale/${invoiceId}/update`, body);
};

export const closeDebtNotAcceptDebt = async (
  invoiceId: string,
  body: TCloseDebtNotAcceptDeptForm
) => {
  return adminAPI.put(`/money-debt/sale/${invoiceId}/close`, body);
};

export const acceptDept = async (invoiceId: string, body: TAcceptDebtForm) => {
  return adminAPI.put(`/money-debt/sale/${invoiceId}/change-accept-debt`, body);
};

import axios from "axios";
import { IGetFeedBrands } from "../types/FeedBrand.type";
import {
  ImportDocumentReq,
  ImportDocumentRes,
} from "../types/ImportDocument.type";
import {
  IApproveOrEditCreditHoldOrder,
  IApproveUpcomingOrder,
  IGetListCreditHoldOrder,
  ISaleOrderMetadata,
} from "../types/SaleOrder.type";
import adminAPI from "./AdminApiService";

export const getAllFeedBrands = async () => {
  try {
    const { data } = await adminAPI.get<IGetFeedBrands>("/sale-order/brands");
    return data;
  } catch (error) {
    return null;
  }
};

export const getCountSaleOrder = async () => {
  try {
    const { data } = await adminAPI.get<ISaleOrderMetadata>(
      "/sale-order/metadata"
    );
    return data.result;
  } catch (error) {
    return null;
  }
};

export const getListCreditHoldOrder = async (
  searchParams?: URLSearchParams
) => {
  try {
    const { data } = await adminAPI.get<IGetListCreditHoldOrder>(
      `/sale-order/credit-hold-order?${searchParams?.toString()}`
    );
    return data.result;
  } catch (error) {
    return null;
  }
};

export const approveComingOrder = async (
  id: string,
  body: IApproveUpcomingOrder
) => {
  try {
    const { data } = await adminAPI.put(
      `/sale-order/upcoming-order/${id}/approve`,
      body
    );
    return data.result;
  } catch (error) {
    return null;
  }
};

export const getExportCreditHoldOrder = async (ids: string[]) => {
  try {
    const { data } = await adminAPI.post<IGetListCreditHoldOrder>(
      `/sale-order/credit-hold-order/export`,
      { ids }
    );
    return data.result;
  } catch (error) {
    return null;
  }
};

export const ApproveOrRejectCreditHoldOrder = async (
  body: IApproveOrEditCreditHoldOrder
) => {
  try {
    const { data } = await adminAPI.post("/sale-order/credit-hold-order", body);
    return data;
  } catch (error) {
    return null;
  }
};

export const getPreSignSaleOrder = async (
  orderFeedId: string,
  body: ImportDocumentReq
) => {
  try {
    const { data } = await adminAPI.post<ImportDocumentRes>(
      `/sale-order/approved-order/upload/pre-sign/${orderFeedId}`,
      body
    );
    return data.result;
  } catch (error) {
    return null;
  }
};

export const uploadFileToBlob = async (url: string, file: File) => {
  try {
    const response = await axios.put(url, file, {
      headers: { "Content-Type": file.type, "x-ms-blob-type": "BlockBlob" },
    });
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};

import { IGetMasterDataCredit } from "../../../types/admin/masterdata/credit";
import {
  ICreditForm,
  IGetCredit,
} from "../../../types/admin/masterdata/creditForm";
import adminAPI from "../../AdminApiService";

export const getMasterDataCredit = async (searchParams?: URLSearchParams) => {
  try {
    const { data } = await adminAPI.get<IGetMasterDataCredit>(
      `/credit-setting/?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};
export const getDefaultTemplateCredit = async (id?: string) => {
  try {
    if (id) {
      const { data } = await adminAPI.get<IGetCredit>("/credit-setting/" + id);
      return data;
    }
    const { data } = await adminAPI.get<IGetCredit>("/credit-setting/template");
    return data;
  } catch (error) {
    return null;
  }
};

export const getDetailTemplateCredit = async (creditId: string) => {
  try {
    const { data } = await adminAPI.get<IGetCredit>(
      `/credit-setting/${creditId}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const putToggleCreditSetting = async (creditId: string) => {
  return adminAPI.put(`/credit-setting/${creditId}/toggle`);
};

export const createCreditSettingDraft = async (payload: ICreditForm) => {
  return adminAPI.post(`/credit-setting/draft`, payload);
};

export const createCreditSetting = async (payload: ICreditForm) => {
  return adminAPI.post(`/credit-setting/finished-draft`, payload);
};

export const deleteCreditSetting = async (idCredit: string) => {
  return adminAPI.delete(`/credit-setting/${idCredit}`);
};

export const editDraftCreditSetting = async (
  creditId: string,
  payload: ICreditForm
) => {
  return adminAPI.put(`/credit-setting/draft/${creditId}`, payload);
};

export const editCreditSetting = async (
  creditId: string,
  payload: ICreditForm
) => {
  return adminAPI.put(`/credit-setting/finished-draft/${creditId}`, payload);
};

import { Dayjs } from "dayjs";
import { IGetHouseAddress } from "../types";
import { IGetContracts } from "../types/ContactList.type";
import { Farmer } from "../types/Farmer.type";
import { IGetFeedBrands } from "../types/FeedBrand.type";
import api from "./ApiService";

export const getOrderFeedBrands = async () => {
  try {
    const { data } = await api.get<IGetFeedBrands>(
      "/farmers/order-feed/brands"
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getContactList = async (farmId: string) => {
  try {
    const { data } = await api.get<IGetContracts>(
      "/farmers/contract/" + farmId
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getHouseAddress = async (farmId: string) => {
  try {
    const { data } = await api.get<IGetHouseAddress>(
      "/farmers/farm/" + farmId + "/address"
    );

    return data;
  } catch (error) {
    return null;
  }
};

export const getFarmer = async () => {
  try {
    const { data } = await api.get<Farmer>("/farmers/detail");
    return data;
  } catch (error) {
    return null;
  }
};

export const postOrderFeed = async (payload: object) => {
  return api.post("farmers/order-feed", payload);
};

export const putChangeShippingDate = async (
  orderFeedId: string,
  payload: Dayjs
) => {
  return api.put("/farmers/order-feed/" + orderFeedId + "/shipping-date", {
    shippingDate: payload,
  });
};

export const putCancelOrder = async (orderFeedId: string) => {
  return api.put("/farmers/order-feed/" + orderFeedId + "/cancel");
};

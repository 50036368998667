import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { IconButton } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import dayjs from "dayjs";
import AppBarFS from "../../../components/app-bar/AppBar";
import TextInputSearchCustom from "../../../admin/components/Input/TextInputSearchCustom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PersonIcon from "@mui/icons-material/Person";
import {
  randFirstName,
  randFullName,
  randNumber,
  randPhoneNumber,
  randUuid,
} from "@ngneat/falso";
import { useNewAssignmentController } from "./controller";
import { get } from "lodash";
import DetailsAssignmentModal from "./views/DetailsAssignmentModal";

const NewAssignment = () => {
  const {
    listNewAssignment,
    isShowDetailsAssignment,
    onShowDetailsAssignment,
    onHideDetailsAssignment,
    selectedIndex,
    setSelectedIndex,
  } = useNewAssignmentController();

  let navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate("/partner/track-debt");
  }, []);

  return (
    <div className="bg-[#d9ebfe] w-full min-h-full">
      <div className="w-full h-full">
        <div className="h-[120px] flex items-center bg-[#FFFF] rounded-b-[40px]">
          <AppBarFS title={"ใบงานใหม่"} onIconPress={handleBack} />
        </div>
        <div className="flex flex-col gap-4 py-[16px] px-4 justify-between">
          <span className="font-semibold text-[20px] text-[#3777BC] flex flex-col justify-center">
            ใบงานใหม่
          </span>
          <div className="w-full flex justify-between">
            <TextInputSearchCustom
              value={""}
              className="max-w-[470px]"
              onChange={() => {}}
              placeholder="ค้นหาโดยชื่อลูกค้า"
              InputProps={{
                endAdornment: (
                  <CloseRoundedIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {}}
                  />
                ),
                sx: {
                  borderRadius: "30px",
                  height: "48px",
                },
              }}
            />
          </div>
        </div>
        {get(listNewAssignment, ["count"], 0) > 0 ? (
          <>
            <div className="px-4 flex flex-col gap-4 pb-[37px]">
              {get(listNewAssignment, ["rows"], []).map((d, index) => {
                return (
                  <div
                    key={`history-${index}`}
                    className="bg-[#FFFF] rounded-[15px] px-4 pt-4 pb-6 flex flex-col gap-3"
                    onClick={() => onShowDetailsAssignment(index)}
                  >
                    <div className="flex flex-row gap-2 items-center">
                      <PersonIcon className="w-[22px] h-[22px] text-[#68C184]" />
                      <div className="text-[#34407B] text-[16px] font-semibold">
                        {d.farmerName}
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#4B4B4B] text-[14px] font-medium">
                        เลขใบงาน
                      </div>
                      <div className="text-[#4B4B4B] text-[14px] font-medium">
                        {d.assignmentNumber}
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#4B4B4B] text-[14px] font-medium">
                        เบอร์โทรศัพท์
                      </div>
                      <div className="text-[#4B4B4B] text-[14px] font-medium">
                        {d.farmerMobileNumber}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className="w-full flex flex-row justify-center">
            ไม่มีข้อมูลใบงานใหม่
          </div>
        )}
      </div>
      <DetailsAssignmentModal
        isOpen={isShowDetailsAssignment}
        onClose={onHideDetailsAssignment}
        data={
          listNewAssignment?.rows[selectedIndex] || {
            id: "",
            assignmentNumber: "",
            farmerName: "",
            farmerMobileNumber: "",
            totalPrice: 0,
            invoices: [],
          }
        }
      />
    </div>
  );
};

export default NewAssignment;

import {
  IGetAcceptedAssignment,
  IGetAssignment,
  IGetDetailsAcceptedAssignment,
  IGetListInvoice,
  IGetReceipt,
  IGetSummaryAssignment,
  IGetUpdateAcceptedAssignment,
  TFarmerCreateInvoice,
} from "../types/Invoice.type";
import { IDownloadUrl, IGetUploadUrl } from "../types/ManageStock.type";
import { TInvoicePaymentType } from "../utils/enum/invoice.enum";
import api from "./ApiService";

export const getListInvoice = async (params: {
  page?: number;
  tabName: "unpaid" | "verifying" | "history";
  farmId?: string;
  direction: string;
  startDate?: Date;
  endDate?: Date;
}) => {
  const { data } = await api.get<IGetListInvoice>(
    `/farmers/invoice/list/${params.tabName}/${params.farmId || ""}`,
    { params }
  );
  return data;
};

export const createdInvoice = async (body: TFarmerCreateInvoice) => {
  const requestBody: TFarmerCreateInvoice =
    body.paymentType === "upload_transfer_evidence"
      ? body
      : {
          ...body,
          transferEvidence: undefined,
        };
  const { data } = await api.post(`/farmers/invoice`, requestBody);
  return data;
};

export const getUpLoadReceiptUrl = async () => {
  const { data } = await api.get<IGetUploadUrl>(
    `farmers/invoice/document/upload/transfer_evidence`
  );
  return data;
};

export const getDownLoadReceiptUrl = async (body: {
  fileId: string;
  docType?: string;
}) => {
  const { data } = await api.get<IDownloadUrl>(
    `/farmers/invoice/document/download/${body?.docType || "receipt"}/${
      body.fileId
    }`
  );
  return data;
};

export const getReceiptInvoice = async (body: { invoiceId: string }) => {
  const { data } = await api.get<IGetReceipt>(
    `/farmers/invoice/${body.invoiceId}`
  );
  return data;
};

//////////////// Partner ////////////////

export const getNewAssignment = async () => {
  const { data } = await api.get<IGetAssignment>(
    `/partner/invoice/new-assignment`
  );
  return data;
};

export const acceptedAssignment = async (body: { assignmentId: string }) => {
  const { data } = await api.put(
    `/partner/invoice/new-assignment/${body.assignmentId}/accept`
  );
  return data;
};

export const getAcceptedAssignment = async () => {
  const { data } = await api.get<IGetAcceptedAssignment>(
    `/partner/invoice/accepted-assignment`
  );
  return data;
};

export const getHistoryAssignment = async () => {
  const { data } = await api.get<IGetAcceptedAssignment>(
    `/partner/invoice/assignment-history`
  );
  return data;
};

export const getSummaryAssignment = async () => {
  const { data } = await api.get<IGetSummaryAssignment>(
    `/partner/invoice/summary-assignment`
  );
  return data;
};

export const getDetailsAcceptedAssignment = async (body: {
  assignmentId: string;
}) => {
  const { data } = await api.get<IGetDetailsAcceptedAssignment>(
    `/partner/invoice/accepted-assignment/${body.assignmentId}`
  );
  return data;
};

export const getUpdateAcceptedAssignment = async (body: {
  assignmentId: string;
}) => {
  const { data } = await api.get<IGetUpdateAcceptedAssignment>(
    `/partner/invoice/accepted-assignment/${body.assignmentId}/update`
  );
  return data;
};

export const updateAssignment = async (body: {
  assignmentId: string;
  invoiceIds: string[];
  appointmentDate: Date;
  paidAmount: number;
}) => {
  const { data } = await api.put(
    `/partner/invoice/accepted-assignment/${body.assignmentId}/update`,
    body
  );
  return data;
};

export const notifyAssignment = async (body: {
  assignmentId: string;
  invoiceIds: string[];
}) => {
  const { data } = await api.put(
    `/partner/invoice/accepted-assignment/${body.assignmentId}/notify-lending`,
    body
  );
  return data;
};

export const completeAssignment = async (body: {
  assignmentId: string;
  isComplete: boolean;
  reason: string;
}) => {
  const { data } = await api.put(
    `/partner/invoice/accepted-assignment/${body.assignmentId}/complete`,
    body
  );
  return data;
};

import { Link, useNavigate } from "react-router-dom";
import LocationIcon from "../../assets/images/Farms/icon _location.svg";
// import Favorite from "../../assets/images/Farms/favorite.svg";
// import { PieChartData } from "./PieChartData";
import { Button } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "../../styles/farms.scss";

const ManageFarm = ({ farmData }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function handleAddHouse(event: any, farm: any) {
    event.preventDefault();
    navigate("/add-house", { state: { selectedFarm: farm?.id } });
  }

  return (
    <div className="mt-[20px]">
      {(farmData || []).slice(0, 3).map((item: any, index: number) => {
        return (
          <Link key={index} to={`/farm/${item.id}`}>
            <div className="manage-farm-main-box2 w-11/12 m-auto mt-5 rounded-[10px] px-[20px] py-[20px] my-[20px]">
              <div className="flex justify-between items-center manage-farm-container2">
                <div>
                  <h1 className="flex justify-start text-[20px] text-[#34407B] font-medium capitalize">
                    {item?.name}
                  </h1>
                  <div className="flex justify-start">
                    <img
                      src={LocationIcon}
                      className="w-4"
                      alt="LocationIcon"
                    />
                    <p className="text-[#3777BC] ml-2 whitespace-nowrap capitalize">
                      {item?.address}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  {/* <Button onClick={() => navigate('/add-house', { state: { selectedFarm: item?.id } })} variant="outlined" color="secondary" size="medium"><span className="font-semibold text-base">
                    {t("farms.addHouseButtonText")}
                  </span></Button> */}

                  {/* <Button onClick={(e)=>handleEditHouse()} variant="outlined" color="secondary" size="medium">
                        <span className="font-semibold text-base	">
                            {t("farms.editHouseButtonText")}
                        </span>
                    </Button> */}
                </div>
              </div>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default ManageFarm;

import { TDropdownValue } from "../../admin/pages/group-area/views/Dropdown";

export type TFormFinancialOverview = {
  mount: TDropdownValue[];
  year: TDropdownValue[];
};

export const defaultFormFinancialOverview: TFormFinancialOverview = {
  mount: [],
  year: [],
};

export type TFormFilterModalFinancialOverview = {
  startDate: string;
  endDate: string;
};

export const defaultFormFilterModalFinancialOverview: TFormFilterModalFinancialOverview =
  {
    startDate: "",
    endDate: "",
  };

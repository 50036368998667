export default function AirlineSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="46"
      viewBox="0 0 45 46"
      fill="none"
    >
      <mask
        id="mask0_5995_23266"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="45"
        height="46"
      >
        <rect y="0.0234375" width="45" height="45" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5995_23266)">
        <path
          d="M16.875 35.6484V31.8984H20.625C20.1562 27.6484 18.3203 24.0859 15.1172 21.2109C11.9141 18.3359 8.125 16.8984 3.75 16.8984V13.1484C7.90625 13.1484 11.6484 14.2344 14.9766 16.4062C18.3047 18.5781 20.8125 21.4297 22.5 24.9609C23.8125 22.1484 25.5078 19.7734 27.5859 17.8359C29.6641 15.8984 31.7031 14.3359 33.7031 13.1484H26.25V9.39844H39.375V22.5234H35.625V16.3359C33.3125 17.7422 30.9766 19.7109 28.6172 22.2422C26.2578 24.7734 24.8438 27.9922 24.375 31.8984H28.125V35.6484H16.875Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

import { Pagination } from "@mui/material";

interface IPaginationCustomProps {
  page: number;
  onChangePage: (page: number) => void;
  defaultCount?: number;
}
function PaginationCustom({
  page,
  defaultCount = 1,
  onChangePage,
}: IPaginationCustomProps) {
  return (
    <Pagination
      count={defaultCount}
      color="primary"
      onChange={(event, value) => onChangePage(value)}
      page={page}
      shape="rounded"
      disabled={defaultCount === 0}
      sx={{
        "& .MuiPaginationItem-root": {
          fontFamily: "DM Sans",
          fontWeight: 500,
          fontSize: "14px",
          margin: "0px",
          border: "unset",
          borderRadius: "0px",
          height: "32px !important",
          width: "32px",
          padding: "8px",
          "&.Mui-selected": {
            bgcolor: "#00C16A !important",
            color: "#ffffff !important",
          },
          "&.MuiPaginationItem-ellipsis": {
            borderRadius: "0px",
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "end",
            justifyContent: "center",
            padding: "4px 0px",
          },
        },
        "& .MuiPaginationItem-previousNext": {
          height: "32px !important",
          width: "32px",
          borderRadius: "1px",
        },
        "& .MuiPagination-ul": {
          bgcolor: "#ffffff",
          border: "1px solid #CBD5E1",
          "> li": {
            borderRight: "1px solid #CBD5E1",
          },
          "> li:last-child": {
            borderRight: "unset",
          },
          ":first-of-type": {
            borderStartStartRadius: "6px",
            borderEndStartRadius: "6px",
          },
          ":last-child": {
            borderEndEndRadius: "6px",
            borderStartEndRadius: "6px",
          },
        },
      }}
    />
  );
}

export default PaginationCustom;

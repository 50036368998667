import {
  Backdrop,
  Box,
  Fade,
  Modal,
  SvgIcon,
  SxProps,
  Theme,
} from "@mui/material";
import ExclamationCircle from "../../../assets/svg/ExclamationCircle";
import ButtonFillCustom from "../Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../Button/ButtonOutlinedCustom";

const style: SxProps<Theme> = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "370px",
  bgcolor: "background.paper",
  boxShadow: 12,
  padding: "24px",
  borderRadius: "12px",
};

interface IConfirmOrderDeliveryModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  title: string;
  subtitleFirstRow: string;
  subtitleSecondRow?: string;
  description?: string;
  buttonPrimaryText: string;
  buttonSecondaryText: string;
  disablePrimaryBtn?: boolean;
  disableSecondaryBtn?: boolean;
}

function ConfirmCustomModal({
  open,
  onClose,
  onSubmit,
  title,
  subtitleFirstRow,
  subtitleSecondRow,
  description,
  buttonPrimaryText,
  buttonSecondaryText,
  disablePrimaryBtn,
  disableSecondaryBtn,
}: IConfirmOrderDeliveryModalProps) {
  return (
    <Modal
      aria-labelledby="confirm-order-delivery-modal-title"
      aria-describedby="confirm-order-delivery-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="w-full h-full">
            <div className="flex items-center gap-1">
              <SvgIcon sx={{ color: "#68C184" }}>
                <ExclamationCircle />
              </SvgIcon>
              <span className="font-sans font-bold text-[18px] text-[#191919]">
                {title}
              </span>
            </div>
            {description ? (
              <p className="text-error-200 text-[14px] mt-6">{description}</p>
            ) : null}
            <div className="flex flex-col text-[16px] mt-3">
              <span className="font-sans font-medium  text-[#191919]">
                {subtitleFirstRow}
              </span>
              {subtitleSecondRow && (
                <span className="font-sans font-bold  text-[#191919]">
                  {subtitleSecondRow}
                </span>
              )}
            </div>
            <div className="flex w-full justify-end gap-2 mt-6">
              <ButtonOutlinedCustom
                disabled={disableSecondaryBtn}
                title={buttonSecondaryText}
                btnBorderColor="#68C184"
                btnTextColor="#68C184"
                onClick={onClose}
              />
              <ButtonFillCustom
                disabled={disablePrimaryBtn}
                title={buttonPrimaryText}
                onClick={onSubmit}
              />
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
export default ConfirmCustomModal;

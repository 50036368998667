import Brightness1Icon from "@mui/icons-material/Brightness1";

export default function HistoryStatusLabel({ color, label, icon }: any) {
  return (
    <div className="flex gap-2 items-center">
      {icon ? (
        icon
      ) : (
        <Brightness1Icon
          fontSize="small"
          color={color}
          style={{ height: "11px", width: "11px" }}
        />
      )}
      <p className="text-sm font-medium text-[#646464]">{label}</p>
    </div>
  );
}

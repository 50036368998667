import { TEditClaimDocument, TSubmitClaimForm } from "../pages/report/type";
import { IDownloadUrl, IGetUploadUrl } from "../types/ManageStock.type";
import { IGetMyOrderByID } from "../types/MyOrderFeed.type";
import { TOrderReportDetail } from "../types/Report";
import api from "./ApiService";

export const submitReportForm = async (
  orderFeedId: string,
  body: TSubmitClaimForm
) => {
  try {
    const response = await api.post(
      `/farmers/order-feed/report/${orderFeedId}`,
      body
    );
    return response;
  } catch (error: any) {
    throw new Error(error?.message ?? error);
  }
};
export const getUploadReportDocumentURL = async (orderClaimItemId: string) => {
  const response = await api.get<IGetUploadUrl>(
    `farmers/order-feed/report/upload/${orderClaimItemId}`
  );
  return response?.data || null;
};
export const editClaimDocument = async (
  orderClaimItemId: string,
  body: TEditClaimDocument
) => {
  await api.put(
    `/farmers/order-feed/report/document/${orderClaimItemId}`,
    body
  );
};

export const getReportDetail = async (orderId: string) => {
  try {
    //change type
    const response = await api.get<TOrderReportDetail>(
      "/farmers/order-feed/report/" + orderId
    );

    if (![200, 201].includes(response.status)) {
      throw new Error();
    }

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getDownloadUrlReportFarmer = async (
  orderId: string,
  fileId: string
) => {
  const { data } = await api.get<IDownloadUrl>(
    `farmers/order-feed/report/download/${orderId}/${fileId}`
  );
  return data;
};

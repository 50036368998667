import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircleIcon from "@mui/icons-material/Circle";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteIcon from "../../../assets/images/Farms/delete-icon.svg";
import AppBarFS from "../../../components/app-bar/AppBar";
import DeleteHouseNoticeModal from "../../../components/common/DeleteHouseNoticeModal/DeleteHouseNoticeModal";
import { StepperConnectorFS } from "../../../components/common/Stepper";
import { deleteHouseService } from "../../../services/AuthService";
import { getAllFarms } from "../../../services/FarmService";
import { Farm } from "../../../types";
import BasicDetails from "../basic-details";
import FeedingProgram from "../feeding-program";
import FeedingContext from "../feeding-program/Provider/FeedingContext";
import VaccineProgram from "../vaccine-program";
const EditHouse = () => {
  const { state } = useLocation();

  const [deleteHouseNoticeModal, setDeleteHouseNoticeModal] = useState(false);
  const [houseDetails, setHouseDetails] = useState<any>([]);
  const [farms, setFarms] = useState<Farm[]>([]);
  const [edited, setEdited] = useState<boolean>(false);
  // const [steps, setSteps] = useState<any[]>(stepsData);
  const { t } = useTranslation();
  const [preSelectedFarm, setPreSelectedFarm] = useState<string>("");
  let navigate = useNavigate();
  const {
    finalAddHouseData,
    setAddHouseData,
    setEditHouse,
    activeStep,
    setActiveStep,
    steps,
    setSteps,
  } = useContext(FeedingContext);

  useEffect(() => {
    if (state?.houseDetails) setHouseDetails(state?.houseDetails);
  }, []);

  const CustomStepIcon = (isComplete: boolean, isPending: boolean) => {
    // TODO : MOVE color code to one common themeing
    return isComplete ? (
      <FontAwesomeIcon
        color="secondary"
        className="text-secondary"
        size="lg"
        icon={faCircleCheck}
      />
    ) : (
      <CircleIcon
        fontSize="small"
        className="text-[#EBEBEB]"
        color={isPending ? "secondary" : undefined}
      />
    );
  };
  const handleDeleteModalClose = () => {
    setDeleteHouseNoticeModal(false);
  };
  const handleBack = (index: number) => {
    if (index < 0) {
      // navigate('/farms');
    }
    if (steps[index]?.completed) {
      if (setActiveStep) setActiveStep(index);
      if (setSteps)
        setSteps((currentSteps: any[]) => {
          return currentSteps.map((step, indexStep: number) => {
            return {
              ...step,
              completed: indexStep < index,
            };
          });
        });
    }
  };

  useEffect(() => {
    if (setEditHouse) setEditHouse(true);
    fetchFarms();
  }, []);

  async function fetchFarms() {
    try {
      const { data } = await getAllFarms();
      if (Array.isArray(data?.farms) && data.farms?.length) {
        setFarms(data.farms);
      }
    } catch (err) {
      console.error(err);
    }
  }

  function handleBasicDetailSubmit(value: any) {
    setAddHouseData &&
      setAddHouseData({ ...finalAddHouseData, basicDetails: value });
    if (value.fattenerPig || value.nurseryPig) {
      handleNext(0);
    } else {
      navigate("/program-overview");
    }
  }

  const handleNext = (current: number) => {
    if (current == 0) {
      setEdited(true);
    }
    if (setActiveStep) setActiveStep(current + 1);
    if (setSteps)
      setSteps((currentSteps: any[]) => {
        currentSteps[current] = {
          ...currentSteps[current],
          completed: true,
        };
        return currentSteps;
      });
  };

  const mapComponentToStep = () => {
    if (activeStep === 0) {
      return (
        <BasicDetails
          farms={farms}
          selectedFarm={preSelectedFarm}
          basicDetailForm={
            finalAddHouseData.basicDetails
              ? finalAddHouseData.basicDetails
              : houseDetails
          }
          onSubmit={handleBasicDetailSubmit}
          isEdit
        />
      );
    } else if (activeStep === 1) {
      return (
        <FeedingProgram
          handleNext={handleNext}
          edited={edited}
          basicDetailForm={
            finalAddHouseData.feedDetails
              ? finalAddHouseData.feedDetails
              : houseDetails
          }
          handleFeedingProgramSubmit={handleFeedingProgramSubmit}
        />
      );
    } else if (activeStep === 2) {
      return (
        <VaccineProgram
          houseId={houseDetails.id}
          basicDetailForm={
            finalAddHouseData.vaccineDetails
              ? finalAddHouseData.vaccineDetails
              : houseDetails
          }
        />
      );
    }
  };

  const handleDeleteHouse = async () => {
    const resp: any = await deleteHouseService(houseDetails.id);
    if (resp?.data?.success) {
      setDeleteHouseNoticeModal(false);
      navigate("/farms");
    }
  };

  function handleFeedingProgramSubmit(value: any) {
    !finalAddHouseData?.feedDetails &&
      setAddHouseData &&
      setAddHouseData({ ...finalAddHouseData, feedDetails: value });
    handleNext(1);
  }
  return (
    <div className="p-4 h-full  flex flex-col justify-between w-full">
      <div className="flex items-center justify-between">
        <AppBarFS
          title={t("editHouse.appHeader")}
          onIconPress={() =>
            activeStep == 0 ? navigate(-1) : handleBack(activeStep - 1)
          }
        ></AppBarFS>
        {activeStep == 0 && (
          <div
            className="w-[24px] h-[24px]"
            onClick={() => setDeleteHouseNoticeModal(true)}
          >
            <img
              className="cursor-pointer"
              src={DeleteIcon}
              alt="delete-icon"
            />
          </div>
        )}
      </div>
      <div className="px-4 py-2 mt-8">
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<StepperConnectorFS />}
        >
          {steps?.map((step: any, index: number) => {
            return (
              <Step key={step?.id} completed={step?.completed} disabled={false}>
                <StepLabel
                  StepIconComponent={() =>
                    CustomStepIcon(step?.completed, index <= activeStep)
                  }
                >
                  {step?.label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      <div className="flex grow mt-4">{mapComponentToStep()}</div>
      <DeleteHouseNoticeModal
        deleteHouseNoticeModal={deleteHouseNoticeModal}
        handleDeleteModalClose={handleDeleteModalClose}
        handleDeleteHouse={handleDeleteHouse}
      />
    </div>
  );
};

export default EditHouse;

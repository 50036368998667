import { useCallback, useState } from "react"

export const useMasterdataController = () => {
  const [isShowSwitchSelling, setIsShowSwitchSelling] = useState(false)
  const [isShowFoodDetail, setIsShowFoodDetail] = useState(false)

  const onAddCreditFeed = useCallback(() => {

  }, [])

  const onCreditDetail = useCallback(() => {

  }, [])

  const onFoodDetail = useCallback(() => {
    setIsShowFoodDetail(true)
  }, [])

  const onHideFoodDetail = useCallback(() => {
    setIsShowFoodDetail(false)
  }, [])

  const onShowSwitchSelling = useCallback(() => {
    setIsShowSwitchSelling(true)
  }, [])

  const onHideSwitchSelling = useCallback(() => {
    setIsShowSwitchSelling(false)
  }, [])

  return {
    onAddCreditFeed,
    onCreditDetail,
    isShowFoodDetail,
    onFoodDetail,
    onHideFoodDetail,
    isShowSwitchSelling,
    onShowSwitchSelling,
    onHideSwitchSelling,
  }
}

import { useTranslation } from "react-i18next";

const PigPrice = () => {
  const { t } = useTranslation();
  return (
    <div className="px-4 flex justify-between mt-[8px] pig-price-container">
      <div className="flex flex-col">
        <div className="bg-[#E9E9E9] h-[120px] w-[110px] rounded-2xl"></div>
        <div className="text-[#34407B] font-medium">
          {t("farms.pigPriceBlockTitleText")}
        </div>
        <div className="text-[#C9C9C9]">
          {t("farms.pigPriceBlockDescription")}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="bg-[#E9E9E9] h-[120px] w-[110px] rounded-2xl"></div>
        <div className="text-[#34407B] font-medium">
          {t("farms.pigPriceBlockTitleText")}
        </div>
        <div className="text-[#C9C9C9]">
          {t("farms.pigPriceBlockDescription")}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="bg-[#E9E9E9] h-[120px] w-[110px] rounded-2xl"></div>
        <div className="text-[#34407B] font-medium">
          {t("farms.pigPriceBlockTitleText")}
        </div>
        <div className="text-[#C9C9C9]">
          {t("farms.pigPriceBlockDescription")}
        </div>
      </div>
    </div>
  );
};

export default PigPrice;

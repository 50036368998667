import { cloneDeep, get, isEmpty, set } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useShowButtonByRoles } from "../../../hooks/useActionButtonByRoles";
import {
  filterCustomerTable,
  getAllDistrict,
  getAllProvince,
  getArea,
  getDistrict,
  getEmployee,
  getGroupAreaColumn,
  getPartner,
  getProvince,
} from "../../../services/GroupArea.service";
import {
  IGroupAreaFilterCustomerTableResponse,
  IGroupAreaGroupListColumnResponse,
  IGroupAreaTableDetail,
} from "../../../types/GroupArea.type";
import { UserRole } from "../../../utils/enum/user-role.enum";
import {
  TColumnSelected,
  TForm,
  defaultColumnSelected,
  defaultForm,
} from "./defaultForm";
import { TDropdownValue } from "./views/Dropdown";

export const useGroupAreaController = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [form, setForm] = useState(defaultForm);
  const [columnSelected, setColumnSelected] = useState(defaultColumnSelected);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [editGroupId, setEditGroupId] = useState("");
  const [groupAreaOptions, setGroupAreaOptions] = useState<
    (IGroupAreaGroupListColumnResponse["userGroups"]["rows"][number] & {
      value: string;
      label: string;
    })[]
  >([]);

  const [detail, setDetail] = useState<IGroupAreaTableDetail>();
  const [tableCurrentPage, setTableCurrentPage] = useState(1);
  const [sortType, setSortType] = useState<"DESC" | "ASC">("DESC");

  const [groupIdModalDetail, setGroupIdModalDetail] = useState("");
  const onCloseModalDetail = useCallback(() => {
    setGroupIdModalDetail("");
  }, []);

  const onOpenCreate = useCallback(() => setIsOpenCreate(true), []);
  const onHideCreate = useCallback(() => setIsOpenCreate(false), []);
  const onOpenEdit = useCallback((groupId: string) => {
    onOpenCreate();
    setEditGroupId(groupId);
  }, []);

  const [isLoadingAreaSelected, setIsLoadingAreaSelected] = useState(false);
  const [areaOptions, setAreaOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [provinceOptions, setProvonceOptions] = useState<
    { value: string; label: string; areaCode: string }[]
  >([]);
  const [districtOptions, setDistrictsOptions] = useState<
    { value: string; label: string; provinceCode: string }[]
  >([]);
  const [allProvinceOptions, setAllProvonceOptions] = useState<
    { value: string; label: string; areaCode: string }[]
  >([]);
  const [allDistrictOptions, setAllDistrictsOptions] = useState<
    { value: string; label: string; provinceCode: string }[]
  >([]);
  const [employeeOptions, setEmployeeOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [partnerOptions, setPartnerOptions] = useState<
    {
      value: string;
      label: string;
      provinceCode: string;
      districtCode: string;
    }[]
  >([]);
  const [customerTable, setCustomerTable] = useState<
    IGroupAreaFilterCustomerTableResponse["userGroups"]
  >([]);
  const [customerTableCount, setCustomerTableCount] = useState(0);

  const onToggleSort = useCallback(() => {
    setSortType((prev) => {
      if (prev === "DESC") return "ASC";
      return "DESC";
    });
  }, []);

  const initData = useCallback(() => {
    Promise.all([
      getArea(),
      getProvince(),
      getDistrict(),
      getEmployee(),
      getPartner(),
      getAllProvince(),
      getAllDistrict(),
      filterCustomerTable({
        direction: "DESC",
        page: 1,
        limit: 10,
        orderBy: "region",
        provinceCodes: undefined,
        districtCodes: undefined,
        regionCode: undefined,
      }),
    ])
      .then((result) => {
        const [
          area,
          province,
          district,
          employee,
          _partner,
          allProvince,
          allDistrict,
          customerTable,
        ] = result;
        const partner = {
          ..._partner,
          result: {
            ..._partner?.result,
            rows: _partner?.result.rows.filter(({ type }) => type === "core"),
          },
        } as typeof _partner;
        setAreaOptions(
          area!.regions.map(({ nameTh, code }) => ({
            value: `${code}`,
            label: nameTh,
          }))
        );
        setProvonceOptions(
          province!.provinces.map(({ nameTh, code, regionCode }) => ({
            value: `${code}`,
            label: nameTh,
            areaCode: `${regionCode}`,
          }))
        );
        setDistrictsOptions(
          district!.districts.map(({ nameTh, code, provinceCode }) => ({
            value: `${code}`,
            label: nameTh,
            provinceCode: `${provinceCode}`,
          }))
        );
        setEmployeeOptions(
          get(employee, "result", [])
            .filter((e) => e.userRole === "sale")
            .map((e) => ({
              value: `${e.id}`,
              label: get(e, "name", "-") !== "-" ? e.name : e.employeeCode,
            }))
        );
        setPartnerOptions(
          (get(partner, ["result", "rows"], []) || []).map((e) => ({
            value: e.id,
            label: `${e.name} ${e.surname}`,
            provinceCode: `${e.province.code}`,
            districtCode: `${e.district.code}`,
          }))
        );
        setAllProvonceOptions(
          allProvince!.provinces.map(({ nameTh, code, regionCode }) => ({
            value: `${code}`,
            label: nameTh,
            areaCode: `${regionCode}`,
          }))
        );
        setAllDistrictsOptions(
          allDistrict!.districts.map(({ nameTh, code, provinceCode }) => ({
            value: `${code}`,
            label: nameTh,
            provinceCode: `${provinceCode}`,
          }))
        );
        setCustomerTable(customerTable.userGroups);
        setCustomerTableCount(customerTable.count);
      })
      .catch((e) => {
        console.log("fail to initData error:", e);
      });
  }, []);

  const onChangeForm = useCallback(
    (key: keyof TForm) => (value: any) => {
      if (key === "area") {
        setForm((prev) => ({
          ...prev,
          province: [],
          district: [],
          [key]: value,
        }));
      } else if (key === "province") {
        setForm((prev) => ({
          ...prev,
          district: [],
          [key]: value,
        }));
      } else {
        setForm((prev) => {
          return { ...prev, [key]: value };
        });
      }
    },
    []
  );

  const onSelectColumn = useCallback(
    (key: keyof TColumnSelected, isSingle?: boolean) =>
      (value: TDropdownValue) => {
        setColumnSelected((prev) => {
          const prevState = cloneDeep(prev);
          const currentKeyState = get(prevState, key, []);
          const isAlreadySelected =
            currentKeyState.findIndex(({ value: v }) => v === value.value) !==
            -1;
          if (isSingle) {
            if (key === "area") {
              setIsLoadingAreaSelected(true);
              return set({ ...prevState, group: [] }, [key], [value]);
            }
            return set(prevState, [key], [value]);
          } else {
            if (!isAlreadySelected) {
              return set(prevState, [key], [...currentKeyState, value]);
            }
            // remove selected
            return set(
              prevState,
              [key],
              currentKeyState.filter(({ value: v }) => v !== value.value)
            );
          }
        });
      },
    []
  );

  const onSearch = useCallback(() => {}, []);

  const onChangeTab = useCallback((e: any, index: number) => {
    setTabIndex(index);
  }, []);

  const onSearchText = useCallback((e: any) => {
    const text = e.target.value;
    setSearchText(text);
  }, []);

  const onClearSearchText = useCallback(() => {
    setSearchText("");
  }, []);

  useEffect(() => {
    if (!isEmpty(columnSelected.area)) {
      getGroupAreaColumn({
        regionCode: get(columnSelected, ["area", 0, "value"]),
      }).then((result) => {
        // for smooth animation
        setTimeout(() => setIsLoadingAreaSelected(false), 400);
        setGroupAreaOptions(
          (get(result, ["userGroups", "rows"], []) || []).map((props) => ({
            ...props,
            value: props.id,
            label: props.name,
          }))
        );
      });
    } else {
      setGroupAreaOptions([]);
    }
  }, [columnSelected.area]);

  const getFilterCustomerTable = useCallback(() => {
    filterCustomerTable({
      direction: sortType,
      page: tableCurrentPage,
      limit: 10,
      searchText: searchText || undefined,
      orderBy: "region",
      provinceCodes: form.province.map(({ value }) => +value).join(","),
      districtCodes: form.district.map(({ value }) => +value).join(","),
      regionCode: get(form, ["area", 0, "value"])
        ? +get(form, ["area", 0, "value"])
        : undefined,
    })
      .then((customerTable) => {
        setCustomerTable(customerTable.userGroups);
        setCustomerTableCount(customerTable.count);
      })
      .catch((error) => {
        console.log("fail to filter customer table error:", error);
      });
  }, [
    tabIndex,
    form.area,
    form.province,
    form.district,
    tableCurrentPage,
    sortType,
    searchText,
  ]);

  const refetch = useCallback(() => {
    getFilterCustomerTable();
  }, [getFilterCustomerTable]);

  useEffect(() => {
    if (tabIndex === 1) {
      getFilterCustomerTable();
    }
  }, [
    tabIndex,
    form.area,
    form.province,
    form.district,
    tableCurrentPage,
    sortType,
    searchText,
  ]);

  useEffect(() => {
    initData();
  }, []);

  const { showButton } = useShowButtonByRoles([
    UserRole.Admin,
    UserRole.Management,
  ]);

  return {
    isOpenCreate,
    onOpenCreate,
    onHideCreate,
    options: {
      areaOptions,
      provinceOptions,
      districtOptions,
      employeeOptions,
      partnerOptions,
      allProvinceOptions,
      allDistrictOptions,
    },
    form,
    onChangeForm,
    onSearch,
    tabIndex,
    onChangeTab,
    searchText,
    onSearchText,
    onClearSearchText,
    columnSelected,
    onSelectColumn,
    groupAreaOptions,
    isLoadingAreaSelected,
    customerTable,
    tableCurrentPage,
    setTableCurrentPage,
    customerTableCount,
    getFilterCustomerTable,
    onToggleSort,
    sortType,
    groupIdModalDetail,
    setGroupIdModalDetail,
    onCloseModalDetail,
    onOpenEdit,
    editGroupId,
    setEditGroupId,
    detail,
    setDetail,
    refetch,
    showButton,
  };
};

import { Checkbox, FormControl, MenuItem, MenuProps } from "@mui/material";
import { get } from "lodash";
import { memo, useCallback, useMemo } from "react";
import { tw } from "../../../../utils/tw";
import SelectAdminCustom from "../../../components/Select/Select";

export type TDropdownValue = {
  label: string;
  value: string;
};

interface IDropdownProps {
  label?: string;
  label2?: string;
  required?: boolean;
  placeholder?: string;
  containerClassName?: string;
  options: TDropdownValue[];
  options2?: (TDropdownValue & { id: string })[];
  multiple?: boolean;
  onChange: (value: TDropdownValue[]) => void;
  onChange2?: (value: (TDropdownValue & { id: string })[]) => void;
  renderValueArray: TDropdownValue[];
  renderValueArray2?: (TDropdownValue & { id: string })[];
  disabled?: boolean;
  errorMessage?: string;
  choseStyle2?: boolean;
  containerDropdown?: string;
  borderRadius?: string;
  borderColor?: string;
  shrink?: boolean;
  switchStyle?: boolean;
  containerSelect?: string;
  isUseChangeValue2?: boolean;
  index?: number;
  onReset?: () => void;
  isCheckbox?: boolean;
  styleMenuItem?: Partial<MenuProps>;
}

export const Dropdown = memo((props: IDropdownProps) => {
  const {
    label,
    label2,
    required,
    options,
    options2,
    multiple,
    placeholder = "กรอก",
    containerClassName,
    renderValueArray,
    renderValueArray2,
    onChange,
    onChange2,
    disabled,
    errorMessage = "",
    choseStyle2,
    containerDropdown,
    borderRadius,
    borderColor,
    shrink,
    switchStyle,
    containerSelect,
    isUseChangeValue2 = false,
    index,
    onReset,
    isCheckbox,
    styleMenuItem,
  } = props;

  const onChangeFn = useCallback(
    (e: any) => {
      if (options.length > 0) {
        if (!multiple) {
          const op = options.find(({ value }) => value === e.target.value);
          op && onChange([op]);
        } else {
          const valueArray = e.target.value;
          const op = valueArray.map((v: string) =>
            options.find(({ value }) => value === v)
          );
          op && onChange(op);
        }
      }
    },
    [options, multiple]
  );

  const onChangeFn2 = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (options2 && options2.length > 0) {
        if (!multiple) {
          const op = options2.find(({ value }) => value === e.target.value);
          if (op) onChange2 && onChange2([op]);
        } else {
          const valueArray = Array.from(
            e.target.selectedOptions,
            (option) => option.value
          );
          const op = valueArray
            .map((v) => options2.find(({ value }) => value === v))
            .filter((row) => !!row) as (TDropdownValue & {
            id: string;
          })[];
          if (op.length) onChange2 && onChange2(op);
        }
      }
    },
    [options2, multiple, onChange2]
  );

  const value = useMemo(() => {
    if (multiple) return (renderValueArray || []).map(({ value: v }) => v);
    return get(
      renderValueArray.map(({ value: v }) => v),
      0
    );
  }, [renderValueArray, multiple]);

  const value2 = useMemo(() => {
    return renderValueArray2 &&
      typeof index === "number" &&
      renderValueArray2[index]
      ? renderValueArray2[index].value
      : "";
  }, [renderValueArray2, index]);

  const startOptions = isUseChangeValue2 ? options2 : options;

  return (
    <div className={tw("flex flex-col flex-1", containerClassName)}>
      {label && (
        <p className="font-medium text-[#191919] mb-[6px] text-[14px] font-sans">
          {label}
          {required && <span className="text-error-300">*</span>}
        </p>
      )}
      <div className={tw("w-full pb-3", containerDropdown, containerSelect)}>
        <FormControl className="w-full">
          <SelectAdminCustom
            disabled={disabled}
            onChange={isUseChangeValue2 ? (onChangeFn2 as any) : onChangeFn}
            multiple={multiple}
            isShowCountAtMultiple
            menuItems={(startOptions || []).map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {isCheckbox ? (
                  <>
                    <Checkbox
                      checked={value.indexOf(option.value) > -1}
                      color="primary"
                      sx={{
                        borderWidth: "1px",
                        color: "#C9C9C9",
                        "&.Mui-checked": {
                          color: "#68C184",
                        },
                      }}
                    />

                    {option.label}
                  </>
                ) : (
                  option.label
                )}
              </MenuItem>
            ))}
            placeholder={placeholder}
            renderValueArray={
              isUseChangeValue2 &&
              typeof index === "number" &&
              renderValueArray2
                ? renderValueArray2[index]
                  ? [renderValueArray2[index]]
                  : []
                : renderValueArray
            }
            value={(isUseChangeValue2 ? value2 : value) ?? ""}
            error={!!errorMessage}
            helperText={errorMessage}
            choseStyle2={choseStyle2}
            borderRadius={borderRadius}
            borderColor={borderColor}
            label={label2}
            shrink={shrink}
            switchStyle={switchStyle}
            onReset={onReset}
            // MenuProps={{
            // PaperProps: {
            //   sx: {
            //     "& .MuiMenuItem-root": {
            //       backgroundColor: "#F0FDF4", // พื้นหลังปกติของไอเทมในเมนู
            //       "&.Mui-selected": {
            //         backgroundColor: "#E2F3E9", // พื้นหลังเมื่อไอเทมถูกเลือก
            //       },
            //       "&:hover": {
            //         backgroundColor: "#D6F5D8", // พื้นหลังเมื่อ hover
            //       },
            //     },
            //   },
            // },
            // }}
            MenuProps={styleMenuItem}
          />
        </FormControl>
      </div>
    </div>
  );
});

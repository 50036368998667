import { InputAdornment } from "@mui/material";
import { memo, useEffect } from "react";
import RHFTextInputCustom from "../../../../../components/Input/RHFTextInputCustom";
import TextInputCustom from "../../../../../components/Input/TextInputCustom";
import { classes } from "../Modal/CreditModal";
import { useFormContext, useWatch } from "react-hook-form";
import { ICreditForm } from "../../../../../../types/admin/masterdata/creditForm";

type TOverallAccordionProps = {
  viewAction: boolean;
};
const OverallAccordion = ({ viewAction }: TOverallAccordionProps) => {
  const methods = useFormContext<ICreditForm>();
  const applicationUsageWeight = useWatch({
    name: "applicationUsage.segmentWeight",
    control: methods.control,
  });
  const farmFinancialStatusAndInstallmentWeight = useWatch({
    name: "farmFinancialStatusAndInstallment.segmentWeight",
    control: methods.control,
  });
  const farmPerformanceWeight = useWatch({
    name: "farmPerformance.segmentWeight",
    control: methods.control,
  });
  const generalFarmInformationWeight = useWatch({
    name: "generalFarmInformation.segmentWeight",
    control: methods.control,
  });

  const generalPersonalInformationWeight = useWatch({
    name: "generalPersonalInformation.segmentWeight",
    control: methods.control,
  });

  useEffect(() => {
    const totalWeight =
      Number(applicationUsageWeight) +
      Number(farmFinancialStatusAndInstallmentWeight) +
      Number(farmPerformanceWeight) +
      Number(generalFarmInformationWeight) +
      Number(generalPersonalInformationWeight);
    methods.setValue("overallWeight", totalWeight);
  }, [
    applicationUsageWeight,
    farmFinancialStatusAndInstallmentWeight,
    farmPerformanceWeight,
    generalFarmInformationWeight,
    generalPersonalInformationWeight,
  ]);

  return (
    <div className={classes.inputSpace}>
      <div>
        <p className={classes.label}>
          ชื่อหัวข้อ <span className={classes.redColor}>*</span>
        </p>
        <div className={classes.input}>
          <RHFTextInputCustom
            disabled={viewAction}
            controlName="name"
            placeholder="กรอก"
            error={Boolean(methods.formState.errors.name)}
            helperText={methods.formState.errors.name?.message || ""}
          />
          <div />
        </div>
      </div>
      <div>
        <p className={classes.label}>Overall Weight</p>
        <div className={classes.input}>
          <TextInputCustom
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            disabled
            value={methods.watch("overallWeight")}
            error={Boolean(methods.formState.errors.overallWeight)}
            helperText={methods.formState.errors.overallWeight?.message || ""}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(OverallAccordion);

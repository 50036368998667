const IconVaccinesSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
    <mask
      id="a"
      width={16}
      height={16}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M0 0h16v16H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#fff"
        d="M4.67 14.999a.645.645 0 0 1-.476-.192.645.645 0 0 1-.191-.475v-3h-.667c-.367 0-.68-.13-.942-.392A1.284 1.284 0 0 1 2.003 10v-5a.645.645 0 0 1-.475-.192.645.645 0 0 1-.192-.475c0-.189.064-.347.192-.475a.645.645 0 0 1 .475-.192h2v-1h-.334a.645.645 0 0 1-.475-.191.645.645 0 0 1-.191-.475c0-.19.063-.348.191-.475a.645.645 0 0 1 .475-.192h2c.19 0 .347.064.475.192a.645.645 0 0 1 .192.475.645.645 0 0 1-.192.475.645.645 0 0 1-.475.191h-.333v1h2c.189 0 .347.064.475.192a.645.645 0 0 1 .192.475.645.645 0 0 1-.192.475.645.645 0 0 1-.475.192v5c0 .366-.13.68-.392.941a1.284 1.284 0 0 1-.941.392h-.667v3a.645.645 0 0 1-.192.475.645.645 0 0 1-.475.192Zm-1.334-5h2.667v-1H4.836a.48.48 0 0 1-.35-.15.48.48 0 0 1 0-.7c.1-.1.217-.15.35-.15h1.167v-1H4.836a.48.48 0 0 1-.35-.15.48.48 0 0 1 0-.7c.1-.1.217-.15.35-.15h1.167v-1H3.336v5Zm6.667 4.666c-.367 0-.68-.13-.942-.391a1.284 1.284 0 0 1-.392-.942V8.999c0-.323.056-.59.167-.8a3.613 3.613 0 0 1 .683-.925c.1-.095.15-.186.15-.275v-.334a.645.645 0 0 1-.475-.191.645.645 0 0 1-.191-.475c0-.19.063-.348.191-.475a.645.645 0 0 1 .475-.192h3.334c.188 0 .347.064.475.192a.645.645 0 0 1 .191.475.645.645 0 0 1-.191.475.645.645 0 0 1-.475.191V7c0 .089.055.189.166.3.111.11.234.244.367.4.122.155.23.339.325.55.094.21.142.46.142.75v4.333c0 .367-.131.68-.392.942a1.284 1.284 0 0 1-.942.391h-2.666Zm0-5.333h2.666v-.333a.663.663 0 0 0-.15-.434c-.1-.122-.21-.255-.333-.4a4.161 4.161 0 0 1-.35-.483c-.111-.178-.167-.406-.167-.683v-.334h-.666V7c0 .266-.053.489-.159.666-.105.178-.22.34-.341.484-.123.144-.236.28-.342.408a.674.674 0 0 0-.158.442v.333Zm0 4h2.666v-1h-2.666v1Z"
      />
    </g>
  </svg>
);
export default IconVaccinesSvg;

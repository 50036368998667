import { Checkbox, Link, TableCell, TableRow } from "@mui/material";
import { useEffect } from "react";
import TableCustom, {
  ITableCustomHeadCell,
} from "../../../components/Table/TableCustom";
import { TUseCustomerFilterHook } from "../hooks/useCustomerFilter.hook";
import { useCustomerTableHook } from "../hooks/useCustomerTable.hook";
import { ICustomerTable } from "../types/customerTable.type";
import CustomerFilter from "./CustomerFilter";
import ChipOrderStatus from "../../../components/Chip/ChipOrderStatus";

type Props = {
  type: string;
  id: string;
  searchCustomerFilter: TUseCustomerFilterHook;
};

const CustomerTab = ({ type, id, searchCustomerFilter }: Props) => {
  const {
    list,
    itemSelected,
    handleFetch,
    handleSelectAllClick,
    setItemSelected,
    count,
    handleClick,
    isSelected,
    handleExportExcel,
    CustomerStatusMap,
  } = useCustomerTableHook(searchCustomerFilter, type, id);

  const headCells: ITableCustomHeadCell<keyof ICustomerTable>[] = [
    {
      id: "customerId",
      numeric: false,
      disablePadding: false,
      label: "รหัสลูกค้า",
      width: "200px",
      isSorting: true,
    },
    {
      id: "customerName",
      numeric: false,
      disablePadding: false,
      label: "ชื่อลูกค้า",
      width: "200px",
      isSorting: false,
    },
    {
      id: "activationDate",
      numeric: false,
      disablePadding: false,
      label: "วันที่เริ่มใช้งาน",
      width: "200px",
      isSorting: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "สถานะ",
      width: "200px",
      isSorting: false,
    },
  ];

  useEffect(() => {
    handleFetch();
  }, [
    searchCustomerFilter.direction,
    searchCustomerFilter.page,
    searchCustomerFilter.toggleFetch,
  ]);
  return (
    <>
      <CustomerFilter
        itemSelected={itemSelected}
        onSearch={handleFetch}
        searchCustomerFilter={searchCustomerFilter}
        onExport={handleExportExcel}
      />
      <div className="flex w-full pt-6">
        <div className="flex w-full pt-6">
          <TableCustom
            order={searchCustomerFilter.direction}
            orderBy={"customer"}
            rowsPerPage={10}
            rows={list}
            itemsSelected={itemSelected}
            headerItems={headCells}
            page={searchCustomerFilter.page}
            onChangeOrderBy={() => {}}
            onChangeOrder={(val) => {
              searchCustomerFilter.setDirection(val);
            }}
            onChangePage={(val) => searchCustomerFilter.setPage(val)}
            onSelectAllClick={(val) => handleSelectAllClick(val)}
            onSelectItem={(val) => setItemSelected(val as string[])}
            totalItemSize={count}
            currentPageItems={list.map((row) => row.id)}
            rowChildren={
              <>
                {list.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          onClick={(event) => handleClick(event, row.id)}
                          sx={{
                            borderWidth: "1px",
                            color: "#C9C9C9",
                            "&.Mui-checked": {
                              color: "#68C184",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        <Link
                          component="button"
                          variant="body2"
                          sx={{
                            color: "#68C184",
                            textDecorationColor: "#68C184",
                          }}
                        >
                          {row.customerId}
                        </Link>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        {row.customerName}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        {row.activationDate}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        <div className="flex flex-row w-full">
                          <ChipOrderStatus
                            bgColor={CustomerStatusMap[row.status].bgColor}
                            fontColor={CustomerStatusMap[row.status].color}
                            label={CustomerStatusMap[row.status].label}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            }
          ></TableCustom>
        </div>
      </div>
    </>
  );
};

export default CustomerTab;

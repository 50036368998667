const IconProgramSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M2.398 3.998a1.6 1.6 0 0 1 1.6-1.6h8a1.6 1.6 0 0 1 1.6 1.6v6.4a1.6 1.6 0 0 1-1.6 1.6h-1.775l.098.391.643.644a.8.8 0 0 1-.566 1.365h-4.8a.8.8 0 0 1-.565-1.365l.643-.644.098-.39H3.998a1.6 1.6 0 0 1-1.6-1.6v-6.4Zm4.617 5.6H3.998v-5.6h8v5.6H7.015Z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconProgramSvg;

export function HomeSvg() {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 8.5459L3.16667 6.87923M3.16667 6.87923L9 1.0459L14.8333 6.87923M3.16667 6.87923V15.2126C3.16667 15.6728 3.53976 16.0459 4 16.0459H6.5M14.8333 6.87923L16.5 8.5459M14.8333 6.87923V15.2126C14.8333 15.6728 14.4602 16.0459 14 16.0459H11.5M6.5 16.0459C6.96024 16.0459 7.33333 15.6728 7.33333 15.2126V11.8792C7.33333 11.419 7.70643 11.0459 8.16667 11.0459H9.83333C10.2936 11.0459 10.6667 11.419 10.6667 11.8792V15.2126C10.6667 15.6728 11.0398 16.0459 11.5 16.0459M6.5 16.0459H11.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function getWeatherData(provinceName: string, districtName: string) {
  // Create a new Date object
  const currentDate = new Date();
  // Extract individual components
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hours = String(currentDate.getHours()).padStart(2, "0");
  // Set minutes and seconds to 0
  const minutes = "00";
  const seconds = "00";
  // Format the date and time
  const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  // console.log(formattedDateTime);

  // const encodedProvince = encodeURI(provinceName);
  // const encodedDistrict = encodeURI(districtName);

  // console.log(provinceName,districtName )
  // #### I will fixed before deploy ######
  // return fetch("https://data.tmd.go.th/nwpapi/v1/forecast/area/place?domain=2&province=" + provinceName + "&amphoe=" + districtName + "&fields=tc,rh&starttime=" + formattedDateTime, {
  return fetch(
    // "https://data.tmd.go.th/nwpapi/v1/forecast/area/place?domain=2&province=" +
    //   provinceName +
    //   "&fields=tc,rh&starttime=" +
    //   formattedDateTime,
    "https://www.tmd.go.th/api/WeatherForecast7Day/weather-forecast-7day-by-province?FilterText=" +
      provinceName +
      "&MaxResultCount=1",
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        authorization:
          "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImUyOTg5OWI4NzMwMTI5MTU2ZDA2ZmYzNTNjZGViNjAzZjIzNGM4NzgwNjJkNzk5MDBhNzhkZDNlYTEyZDNlNTVkZDhjMWM2NTYzMTA4NDc2In0.eyJhdWQiOiIyIiwianRpIjoiZTI5ODk5Yjg3MzAxMjkxNTZkMDZmZjM1M2NkZWI2MDNmMjM0Yzg3ODA2MmQ3OTkwMGE3OGRkM2VhMTJkM2U1NWRkOGMxYzY1NjMxMDg0NzYiLCJpYXQiOjE2ODgwOTgzOTksIm5iZiI6MTY4ODA5ODM5OSwiZXhwIjoxNzE5NzIwNzk5LCJzdWIiOiIyNTg5Iiwic2NvcGVzIjpbXX0.G6tEh6wjnymJ0Bm4nxBQuIfpb4P8issm0bB1Y6kYuOTE-aAYQHcIbPk9v4VzPHqaQERfNEvngAKIcl-7FbJJfSYR9EU8EzcW-Hzv2zC4g1seCnei0RTN5mgFKDiAss7vEtsLuT2cdvTeLwBAJCxwPoc15hzJsDuHJvxXzyGhjuQsKny2I8AO_K1iDHOucPlR5tnAqw6txBIbRuEWXsNe-X7vL9KDlyJIYv8hmeJsFaM16dWbZtPrOOhpS1rpWEHL-VkizLtLcRzwomkY8WRQe5kPnZw63Ge49pN9SfgDAIe4yNxGsPh1rNvU7yWiWFT-fkYQsKAvKf-rOP3WCYpUhxWMUpdYSMGEW57ffZTN5kGarxnL1VdbFcqGAam2XXGOD138UunAHgnfRMQ-j3Az3Sl2-CBRbjxcDhc8sEd7dC6iTGUb0R9GpEKkevvh6ESVVb9fBu5iuUddu3Wte6cTgTZzMIy_R17cpzMaMTLeEi1aTI3RsF8bcYh537Gwc1j45ZKQR0LxxvcP6hi7qdeNkBAuf9eQiVGr13i08k4B_sitbZNzkIffwuUg6LLH5GFAFrwxD58dNdszn6Y3bYJZt-xMZwVbdvqNs45Fk-6-cpdt6ED9vKHPFXGAOz0X6SvcPh2ma8RK57OkIvVQUua_0nfIrl5yohKpJnx460-sqtw",
      },
    }
  ).then((res) => {
    return res.json();
  });
}

import { Button, ButtonProps } from "@mui/material";
import React from "react";

export interface IButtonOutlinedCustom extends ButtonProps {
  title: string;
  btnTextColor?: string;
  btnBgColor?: string;
  hoverTextColor?: string;
  hoverBgColor?: string;
  btnBorderColor?: string;
  onClick?: () => void;
}

function ButtonOutlinedCustom({
  title,
  btnTextColor,
  btnBgColor,
  hoverTextColor,
  hoverBgColor,
  btnBorderColor,
  onClick,
  ...rest
}: IButtonOutlinedCustom) {
  return (
    <Button
      {...rest}
      variant="outlined"
      sx={{
        lineHeight: "0px",
        bgcolor: btnBgColor || "#ffffff",
        fontWeight: 500,
        fontSize: "14px",
        color: btnTextColor || "#979797",
        height: "30px",
        borderRadius: "4px",
        borderColor: btnBorderColor || "#979797",
        ":hover": {
          borderColor: btnBorderColor || "#979797",
          opacity: 0.8,
        },
      }}
      onClick={onClick}
    >
      {title}
    </Button>
  );
}

export default ButtonOutlinedCustom;

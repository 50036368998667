export default function BookSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="47"
      viewBox="0 0 46 47"
      fill="none"
    >
      <mask
        id="mask0_5995_23252"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="46"
        height="47"
      >
        <rect y="0.0234375" width="46" height="46" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5995_23252)">
        <path
          d="M11.5001 42.1888C10.4459 42.1888 9.54348 41.8135 8.79279 41.0628C8.0421 40.3121 7.66675 39.4096 7.66675 38.3555V7.6888C7.66675 6.63464 8.0421 5.7322 8.79279 4.98151C9.54348 4.23082 10.4459 3.85547 11.5001 3.85547H34.5001C35.5542 3.85547 36.4567 4.23082 37.2074 4.98151C37.9581 5.7322 38.3334 6.63464 38.3334 7.6888V38.3555C38.3334 39.4096 37.9581 40.3121 37.2074 41.0628C36.4567 41.8135 35.5542 42.1888 34.5001 42.1888H11.5001ZM21.0834 21.1055L25.8751 18.2305L30.6667 21.1055V7.6888H21.0834V21.1055Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

import { DateCalendar, DateCalendarProps, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { AppLocale } from "../../../types";
import './DateCalendar.scss';
import buddhistEra from 'dayjs/plugin/buddhistEra'

export default function DateCalendarFS(props: DateCalendarProps<Dayjs>) {

  dayjs.extend(buddhistEra)
  const adapterLocale = 'th'
  // let textFiledPropsDefault = {
  //     locale: 'th'
  // };

  const [localeProps, setLocaleProps] = useState<any>(() => {
    const testDate = defaultAdapterLocale();
    return defaultAdapterLocale();
  })
  // const [textFiledProps, setTextFiledProps] = useState<any>(textFiledPropsDefault);
  // const [localeFormat, setLocaleFormat] = useState<any>(() => {
  //     return defaultLocaleFormat();
  // });


  function defaultAdapterLocale() {
    if (adapterLocale === AppLocale.THAI) {
      return {
        adapterLocale: AppLocale.THAI,
        dateFormats: {
          year: "BBBB",
          monthAndYear: "MMMM BBBB",
          fullDate: 'DD/MM/BBBB'
        }
      }
    }
    return {};
  }

  // function defaultLocaleFormat() {
  //     if (adapterLocale === AppLocale.THAI) {
  //         if (!props?.views || props?.views?.includes('day')) {
  //             return 'DD/MM/BBBB';
  //         }
  //     }
  // }




  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} {...localeProps} >
      <DateCalendar {...props} />
    </LocalizationProvider>
  )
}
import { Pie, PieChart, ResponsiveContainer, Sector, Cell } from "recharts";
import { NoChartDataPig } from "../../../assets/svg/NoDataChartPig";
import pigLogo from "../../../assets/images/pigLogo.svg";

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

export interface IHistoryPigCountChartProps {
  dataset: {
    name: string;
    value: number;
    fill: string;
  }[];
}

export default function HistoryPigCountChart({
  dataset,
}: IHistoryPigCountChartProps) {
  return (
    <div className="flex items-center w-32 h-32 grow justify-center relative">
      {dataset?.length > 0 && (
        <>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={95} height={95}>
              <Pie
                activeIndex={0}
                activeShape={renderActiveShape}
                data={dataset}
                cx="50%"
                cy="50%"
                innerRadius={40}
                outerRadius={45}
                startAngle={-270}
                fill="#8884d8"
                dataKey="value"
              >
                {dataset.map((value: any, index: number) => {
                  return (
                    <Cell
                      key={`cell-${index}`}
                      fill={value?.fill}
                      style={{
                        filter: `drop-shadow(0px 0px 5px ${value?.fill})`,
                      }}
                      stroke="0"
                    />
                  );
                })}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <img
            src={pigLogo}
            width="60px"
            height="60px"
            alt="pig-logo"
            className="absolute"
          />
        </>
      )}

      {!dataset?.length && <NoChartDataPig />}
    </div>
  );
}

import { Snackbar } from "@mui/material";
import CheckSuccessIcon from "../../../assets/images/check-success.svg";
import React from "react";
interface IOrderFeedBaseSnackBar {
  openSnackbar: boolean;
  handleCloseSnackbar: (
    _: React.SyntheticEvent | Event,
    reason?: string
  ) => void;
  snackbarText: string;
  styleCSS?: React.CSSProperties;
}
export function OrderFeedBaseSnackBar({
  openSnackbar,
  handleCloseSnackbar,
  snackbarText,
  styleCSS,
}: IOrderFeedBaseSnackBar) {
  return (
    <Snackbar
      style={styleCSS ?? { position: "sticky", bottom: "6em", zIndex: 1000 }}
      open={openSnackbar}
      autoHideDuration={3000}
      onClose={handleCloseSnackbar}
    >
      <div className="w-full h-[50px] pl-2 rounded-[10px] bg-[#F5F9F5] flex items-center gap-4">
        <img
          width={"24px"}
          height={"24px"}
          src={CheckSuccessIcon}
          alt="toast-successful"
        />
        <span>{snackbarText}</span>
      </div>
    </Snackbar>
  );
}

import ArrowDropDownCircleSharpIcon from "@mui/icons-material/ArrowDropDownCircleSharp";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import {
  AccordionDetails,
  AccordionProps,
  AccordionSummaryProps,
  Avatar,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Paper,
  SvgIcon,
  styled,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { DATE_FORMAT_TABLE } from "../../../../const/app.constant";
import { UnitEnum } from "../../../../types/FeedBrand.type";
import { TEditHistory } from "../../../../types/OrderHistory.type";
import { ArrowNarrowRightSvg } from "../../../assets/svg/ArrowNarrowRight.svg";
import TableBasicCustom, {
  ITableBasicCustomHeadCell,
  StyledTableBasicCustomCell,
  StyledTableBasicCustomRow,
} from "../../../components/Table/TableBasicCustom";

const tableHeaders: ITableBasicCustomHeadCell[] = [
  {
    disablePadding: false,
    label: "แบรนด์อาหารสัตว์",
    width: "fit-content",
  },
  {
    disablePadding: false,
    label: "เบอร์อาหารสัตว์",
    width: "fit-content",
  },
  {
    disablePadding: false,
    label: "จำนวน",
    width: "fit-content",
  },
];

interface IData {
  animalFoodBrands: string;
  animalFoodNumber: string;
  quantity: string;
}

const historyChangeDataMock: {
  updatedAt: string;
  items: {
    updatedBy: string;
    time: string;
    prev: IData[];
    curr: IData[];
  }[];
}[] = [
  {
    updatedAt: "วันนี้",
    items: [
      {
        updatedBy: "Nicolas S.",
        time: "08:20 AM",
        prev: [
          {
            animalFoodBrands: "Farm",
            animalFoodNumber: "SKU-0003",
            quantity: "20 ถุง",
          },
        ],
        curr: [
          {
            animalFoodBrands: "Betagro",
            animalFoodNumber: "SKU-0001",
            quantity: "10 ถุง",
          },
        ],
      },
      {
        time: "08:00 AM",
        updatedBy: "Nicolas S.",
        curr: [
          {
            animalFoodBrands: "Farm",
            animalFoodNumber: "SKU-0003",
            quantity: "20 ถุง",
          },
        ],
        prev: [
          {
            animalFoodBrands: "Betagro",
            animalFoodNumber: "SKU-0001",
            quantity: "10 ถุง",
          },
        ],
      },
    ],
  },
  {
    updatedAt: "22/01/2024",
    items: [
      {
        updatedBy: "Nicolas S.",
        time: "08:20 AM",
        prev: [
          {
            animalFoodBrands: "Farm",
            animalFoodNumber: "SKU-0003",
            quantity: "20 ถุง",
          },
        ],
        curr: [
          {
            animalFoodBrands: "Betagro",
            animalFoodNumber: "SKU-0001",
            quantity: "10 ถุง",
          },
        ],
      },
      {
        updatedBy: "Nicolas S.",
        time: "08:00 AM",
        curr: [
          {
            animalFoodBrands: "Farm",
            animalFoodNumber: "SKU-0003",
            quantity: "20 ถุง",
          },
        ],
        prev: [
          {
            animalFoodBrands: "Betagro",
            animalFoodNumber: "SKU-0001",
            quantity: "10 ถุง",
          },
        ],
      },
    ],
  },
  {
    updatedAt: "22/01/2024",
    items: [
      {
        updatedBy: "Nicolas S.",
        time: "08:20 AM",
        prev: [
          {
            animalFoodBrands: "Farm",
            animalFoodNumber: "SKU-0003",
            quantity: "20 ถุง",
          },
        ],
        curr: [
          {
            animalFoodBrands: "Betagro",
            animalFoodNumber: "SKU-0001",
            quantity: "10 ถุง",
          },
        ],
      },
      {
        updatedBy: "Nicolas S.",
        time: "08:00 AM",
        curr: [
          {
            animalFoodBrands: "Farm",
            animalFoodNumber: "SKU-0003",
            quantity: "20 ถุง",
          },
        ],
        prev: [
          {
            animalFoodBrands: "Betagro",
            animalFoodNumber: "SKU-0001",
            quantity: "10 ถุง",
          },
        ],
      },
    ],
  },
];

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  padding: "0px",
  // border: `1px solid ${theme.palette.divider}`,
  // "&:not(:last-child)": {
  //   borderBottom: 0,
  // },
  border: "0px",
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowDropDownCircleSharpIcon
        sx={{ fontSize: "36px", color: "currentcolor" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  padding: "0px",
  backgroundColor: "transparent",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "#D9D9D9",
    transform: "rotate(180deg)",
    "&.Mui-expanded": {
      color: "#68C184",
      transform: "rotate(0deg)",
    },
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

interface IHistoryChangePanelProps {
  orderFeedLogs: TEditHistory[];
}

function HistoryChangePanel({ orderFeedLogs }: IHistoryChangePanelProps) {
  const [expanded, setExpanded] = React.useState<number | false>(0);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div className="relative h-full w-full pt-6">
      {orderFeedLogs.length > 0 && (
        <span className="font-sans font-bold text-[20px] text-[#68C184]">
          การแก้ไข
        </span>
      )}
      {/* <div className="pt-4 max-h-[400px] overflow-auto overflow-x-hidden scroll-smooth"> */}
      <div className="pt-4">
        {orderFeedLogs.map((historyItem, hIndex) => (
          <Accordion
            key={`history-acc-${hIndex}`}
            expanded={expanded === hIndex}
            onChange={handleChange(hIndex)}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <span className="text-[14px] font-normal text-[#1B2128] font-sans">
                {dayjs().format(DATE_FORMAT_TABLE) ===
                dayjs(historyItem.date).format(DATE_FORMAT_TABLE)
                  ? "วันนี้"
                  : dayjs(historyItem.date).format(DATE_FORMAT_TABLE)}
              </span>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: "0px",
              }}
            >
              <div className="py-2">
                <Timeline
                  sx={{
                    padding: "2px 12px",
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0,
                    },
                  }}
                >
                  {historyItem.rows.map((item, dIndex) => (
                    <TimelineItem key={`item-detail-${hIndex + dIndex}`}>
                      <TimelineSeparator>
                        <TimelineDot
                          sx={{ bgcolor: "#3777BC", boxShadow: "unset" }}
                        />
                        {historyItem.rows.length - 1 > dIndex && (
                          <TimelineConnector />
                        )}
                      </TimelineSeparator>
                      <TimelineContent>
                        <div className="flex w-full justify-start gap-6">
                          <span className="text-[14px] font-normal text-[#1B2128] font-sans">
                            {dayjs(item.createdAt).format("hh:mm A")}
                          </span>
                          <Paper
                            elevation={0}
                            sx={{
                              bgcolor: "#FBFBFB",
                              border: "1px solid #EBEBEB",
                              width: "80%",
                              height: "100%",
                              borderRadius: "20px",
                              padding: "12px",
                            }}
                          >
                            <div className="flex w-full gap-4 items-center">
                              <Avatar
                                alt="user-avatar"
                                src="https://i.pravatar.cc/300"
                                sx={{
                                  width: "25px",
                                  height: "25px",
                                  border: "1px solid #D9D9D9",
                                }}
                              />
                              <span className="font-bold font-sans text-[1rem] leading-4 text-[#1B2128] align-middle pt-1">
                                {item.editedBy.email}
                              </span>
                            </div>
                            <div className="pt-5 flex flex-row gap-6 items-center">
                              <TableBasicCustom
                                headerItems={tableHeaders}
                                tableBodyChildren={
                                  <StyledTableBasicCustomRow>
                                    <StyledTableBasicCustomCell
                                      align="left"
                                      sx={{
                                        fontFamily: "IBM Plex Sans Thai",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: "19px",
                                      }}
                                    >
                                      {item.feedBrand.nameTh}
                                    </StyledTableBasicCustomCell>

                                    <StyledTableBasicCustomCell
                                      align="left"
                                      sx={{
                                        fontFamily: "IBM Plex Sans Thai",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: "19px",
                                      }}
                                    >
                                      {item.feedBrandItem.skuTh}
                                    </StyledTableBasicCustomCell>

                                    <StyledTableBasicCustomCell
                                      align="left"
                                      sx={{
                                        fontFamily: "IBM Plex Sans Thai",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: "19px",
                                      }}
                                    >
                                      {item.amount}{" "}
                                      {
                                        UnitEnum[
                                          item.feedBrandItem
                                            .unit as keyof typeof UnitEnum
                                        ]
                                      }
                                    </StyledTableBasicCustomCell>
                                  </StyledTableBasicCustomRow>
                                }
                              />
                              <SvgIcon
                                sx={{ color: "#000000", fontSize: "24px" }}
                              >
                                <ArrowNarrowRightSvg />
                              </SvgIcon>
                              <TableBasicCustom
                                headerItems={tableHeaders}
                                tableBodyChildren={
                                  <StyledTableBasicCustomRow>
                                    <StyledTableBasicCustomCell
                                      align="left"
                                      sx={{
                                        fontFamily: "IBM Plex Sans Thai",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: "19px",
                                      }}
                                    >
                                      {item.newFeedBrand.nameTh}
                                    </StyledTableBasicCustomCell>

                                    <StyledTableBasicCustomCell
                                      align="left"
                                      sx={{
                                        fontFamily: "IBM Plex Sans Thai",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: "19px",
                                      }}
                                    >
                                      {item.newFeedBrandItem.skuTh}
                                    </StyledTableBasicCustomCell>

                                    <StyledTableBasicCustomCell
                                      align="left"
                                      sx={{
                                        fontFamily: "IBM Plex Sans Thai",
                                        fontSize: "14px",
                                        fontWeight: 400,
                                        lineHeight: "19px",
                                      }}
                                    >
                                      {item.newAmount}{" "}
                                      {
                                        UnitEnum[
                                          item.newFeedBrandItem
                                            .unit as keyof typeof UnitEnum
                                        ]
                                      }
                                    </StyledTableBasicCustomCell>
                                  </StyledTableBasicCustomRow>
                                }
                              />
                            </div>
                          </Paper>
                        </div>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
}

export default HistoryChangePanel;

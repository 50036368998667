import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

type Props = {
  rowChildren: React.ReactNode;
  title: string;
};

const AccordionCreditCustom = ({ rowChildren, title }: Props) => {
  return (
    <Accordion
      sx={{
        margin: "0px !important",
        boxShadow: "none",
        width: "100%",
        marginBottom: "12px !important",
        "&:before": {
          display: "none",
        },
      }}
      defaultExpanded
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
        sx={{
          height: "36px !important",
          minHeight: "36px !important",
          backgroundColor: "#68C184",
          fontSize: "16px !important",
          fontWeight: "700",
          color: "#FFFFFF",
          borderRadius: "10px !important",
        }}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: "#FFFFFF",
          padding: "0 !important",
        }}
      >
        {rowChildren}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionCreditCustom;

export type TFormSaveAccounting = {
  incomeFromPigSelling: string;
  geneticBill: string;
  feedBill: string;
  vaccineAndMedicineBill: string;
  staffBill: string;
  waterBill: string;
  electricityBill: string;
  transportationBill: string;
  programBill: string;
  otherBill: string;
};

export const defaultFormSaveAccounting: TFormSaveAccounting = {
  incomeFromPigSelling: "",
  geneticBill: "",
  feedBill: "",
  vaccineAndMedicineBill: "",
  staffBill: "",
  waterBill: "",
  electricityBill: "",
  transportationBill: "",
  programBill: "",
  otherBill: "",
};

import React, { ReactNode, useState } from "react";
import DailyLogContext from "./DailyLogContext";

interface MyContextProviderProps {
  children: ReactNode; // This is the prop type definition for 'children'
}

const DailyLogProvider: React.FC<MyContextProviderProps> = ({ children }) => {
  const [finalDailyLogData, setDailyLogData] = useState({
    treatmentData: {
      cageNo: [],
      numberOfPig: [],
      symptom: [],
      medicineData: [],
      TotalofMedicine: [],
      medicineUnit: ["CC"],
      pictureofUnwellPig: [[]],
    },
  });
  const [isEditMedicine, setEditMedicine] = useState<boolean>(false);

  return (
    <DailyLogContext.Provider
      value={{
        finalDailyLogData,
        isEditMedicine,
        setEditMedicine,
        setDailyLogData,
      }}
    >
      {children}
    </DailyLogContext.Provider>
  );
};

export default DailyLogProvider;

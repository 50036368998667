import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import manualPDF from "../../assets/pdf/manual.pdf";
import { useTranslation } from "react-i18next";

// Set the PDF worker source
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

type Props = {};

const Manualfile = (props: Props) => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState<number>(22); // Specify the type of numPages
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pdfLoadingSuccess, setPdfLoadingSuccess] = useState<boolean>(false);
  const [buttonnext, setbuttonnext] = useState<boolean>(false);
  const [buttonprev, setbuttonprev] = useState<boolean>(false);

  // Function to handle successful load of the PDF
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };
  const next = () => {
    setbuttonprev(false);
    if (pageNumber < numPages) {
      const nextpage = pageNumber + 1;
      setPageNumber(nextpage);
      setbuttonnext(false);
    } else {
      setbuttonnext(true);
    }
  };

  const prev = () => {
    const prevpage = pageNumber - 1;
    setPageNumber(prevpage);
    // setbuttonprev(false);
  };

  return (
    <>
      <Document file={manualPDF} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          pageNumber={pageNumber}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          renderForms={true}
          width={400}
          // height={800}
          // className='manual_page'
        />
      </Document>
      <div>
        <div className="flex flex-row">
          <div>
            <Button onClick={prev} disabled={buttonprev}>
              {t("pdf.btnprev")}
            </Button>
          </div>
          <div className="ms-auto">
            <Button onClick={next} disabled={buttonnext}>
              {t("pdf.btnnext")}
            </Button>
          </div>
        </div>

        <div>
          {t("pdf.page")} {pageNumber} {t("pdf.of")} {numPages}
        </div>
      </div>
    </>
  );
};

export default Manualfile;

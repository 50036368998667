import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { getHistoryAssignment } from "../../../services/Invoice.service";

export const useHistoryAssignmentController = () => {
  const { data: listHistoryAssignment } = useQuery({
    queryKey: ["list_history_assignment"],
    queryFn: async () => {
      const result = await getHistoryAssignment();
      return result;
    },
  });

  const [isShowDetailsAssignment, setIsShowDetailsAssignment] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onShowDetailsAssignment = useCallback((index: number) => {
    setSelectedIndex(index);
    setIsShowDetailsAssignment(true);
  }, []);

  const onHideDetailsAssignment = useCallback(() => {
    setSelectedIndex(0);
    setIsShowDetailsAssignment(false);
  }, []);

  return {
    listHistoryAssignment,
    isShowDetailsAssignment,
    onShowDetailsAssignment,
    onHideDetailsAssignment,
    selectedIndex,
  };
};

import { FormProvider, useForm } from "react-hook-form";
import RHFTextInputCustom from "../../../../../components/Input/RHFTextInputCustom";
import AdminModal from "../../../../../components/Modal/AdminModal";
import { useDispatch } from "react-redux";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import { useEffect } from "react";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import { TCreateVaccineForm } from "../../../types/vaccine.type";
import { createVaccine } from "../../../services/vaccine.service";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  handleFetch: () => void;
};

const CreateVaccineModal = ({ isOpen, onClose, handleFetch }: Props) => {
  const dispatch = useDispatch();

  const schema = Yup.object().shape({
    nameTh: Yup.string().required("กรุณากรอก"),
    nameEn: Yup.string().required("กรุณากรอก"),
  });

  const methods = useForm<TCreateVaccineForm>({
    defaultValues: {
      nameTh: "",
      nameEn: "",
    },
    resolver: yupResolver<TCreateVaccineForm>(schema as any),
  });

  const handleSubmit = (value: TCreateVaccineForm) => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
            await createVaccine(value);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "บันทึกสำเร็จ",
              })
            );
            handleFetch();
            onClose();
          } catch (error) {
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  useEffect(() => {
    methods.reset();
  }, [isOpen]);

  return (
    <>
      <AdminModal
        label={"เพิ่มวัคซีน"}
        isOpen={isOpen}
        size={"xs"}
        handleClose={onClose}
        children={
          <>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleSubmit)}>
                <p className="text-[14px] mb-3 font-sans">
                  ชื่อวัคซีน (TH) <span className="text-error-300">*</span>
                </p>
                <div className="w-full pb-3 font-sans">
                  <RHFTextInputCustom
                    controlName="nameTh"
                    placeholder="กรอก"
                    error={Boolean(methods.formState.errors.nameTh)}
                    helperText={methods.formState.errors.nameTh?.message || ""}
                  />
                </div>
                <p className="text-[14px] mb-3 font-sans">
                  ชื่อวัคซีน (EN) <span className="text-error-300">*</span>
                </p>
                <div className="w-full pb-3 font-sans">
                  <RHFTextInputCustom
                    controlName="nameEn"
                    placeholder="กรอก"
                    error={Boolean(methods.formState.errors.nameEn)}
                    helperText={methods.formState.errors.nameEn?.message || ""}
                  />
                </div>
                <div className="flex justify-end">
                  <ButtonFillCustom title={"ยืนยัน"} type="submit" />
                </div>
              </form>
            </FormProvider>
          </>
        }
      ></AdminModal>
    </>
  );
};

export default CreateVaccineModal;

import { IGetAllMedicine, IPostAddMedicine } from "../types/DailyLog.type";
import api from "./ApiService";

export function getHousesFromFarmId(farmId: string) {
  return api.get(`/houses?farmId=${farmId}`);
}
export function getFeedBrands() {
  return api.get(`/feeds?weight=0`);
}
export function getMedicines() {
  return api.get(`/medicines`);
}

export const getMedicines2 = async () => {
  const { data } = await api.get<IGetAllMedicine>(`/medicines`);
  return data;
};

export const postAddMedicines = async (body: {
  medicineCategoryId: number;
  tradeName: string;
  unit: string;
}) => {
  const { data } = await api.post<IPostAddMedicine>(`/medicines`, body);
  return data;
};

export function setDailyLog(data: any) {
  return api.post(`/daily-log`, data);
}
export function setUploadImg(image: any) {
  // console.log("data",data)
  return api.post(`/upload`, image, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export function editDailyLog(data: any, dailyLogId: string) {
  return api.patch(`/daily-log/${dailyLogId}`, data);
}

export function getDailyLog(houseId: any) {
  return api.get(`/daily-log?houseId=${houseId}`);
}
export function getDailyLogById(dailylogId: any) {
  return api.get(`/daily-log/${dailylogId}`);
}
export function getMedicine(medicineId: any) {
  return api.get(`/medicines/${medicineId}`);
}

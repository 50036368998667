import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { getNewAssignment } from "../../../services/Invoice.service";

export const useNewAssignmentController = () => {
  const { data: listNewAssignment } = useQuery({
    queryKey: ["list_new_assignment"],
    queryFn: async () => {
      const result = await getNewAssignment();
      return result;
    },
  });

  const [isShowDetailsAssignment, setIsShowDetailsAssignment] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const onShowDetailsAssignment = useCallback((index: number) => {
    setSelectedIndex(index);
    setIsShowDetailsAssignment(true);
  }, []);

  const onHideDetailsAssignment = useCallback(() => {
    setSelectedIndex(-1);
    setIsShowDetailsAssignment(false);
  }, []);

  return {
    listNewAssignment,
    isShowDetailsAssignment,
    onShowDetailsAssignment,
    onHideDetailsAssignment,
    selectedIndex,
    setSelectedIndex,
  };
};

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const nameRegExp = /^[\u0E00-\u0E7Fa-zA-Z]+$/;

export const alphaNumericRegExpforen = /^[a-zA-Z0-9]*$/; /**for english */
export const alphaNumericRegExpforth = /^[ก-๙-zA-Z0-9 ]*$/; /**for thai */

export const alphaNumericSpaceRegExpforen =
  /^[A-Za-z0-9\s.'-]*$/; /**for english */
export const alphaNumericSpaceRegExpforth = /^[ก-๙a-zA-Z0-9 ]*$/; /**for thai */

export const numericRegExp = /^[0-9]*$/;
export const patternTwoDigisAfterComma = /^\d+(\.\d{1,2})?$/;

/**comman */
export const alphaNumericSpaceRegExp = /^[A-Za-z0-9\s.'-]*$/;
// export const alphaNumericRegExp = /^[a-zA-Z0-9]*$/;

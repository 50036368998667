import api from "./ApiService";
/**
 *
 * @returns Promise
 */
export function getLocations() {
  return api.get("/locations");
}

/**
 *
 * @returns
 */
export function getProvinces() {
  return api.get("/province");
}

/**
 *
 * @param provinceCode {number} Province code for which Districts are required
 * @returns Promise
 */
export function getDistricts(provinceCode?: number) {
  if (provinceCode) {
    return api.get(`/district?province_code=${provinceCode}`);
  } else {
    return api.get(`/district`);
  }
}

export function getSubDistricts(districtCode?: number) {
  if (districtCode) {
    return api.get(`/sub-district?district_code=${districtCode}`);
  } else {
    return api.get(`/sub-district`);
  }
}

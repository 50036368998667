import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import { Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../../../assets/images/OrderFeedImage/bg.png";
import LllustationSVG from "../../../../assets/svg/Lllustation";
import AppBarFS from "../../../../components/app-bar/AppBar";

export function ContactSeller() {
  const navigate = useNavigate();
  return (
    <div
      className="pb-4 flex flex-col justify-between w-full items-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        height: "100%",
        backgroundSize: "cover",
      }}
    >
      <div className="p-4 rounded-b-[40px] bg-white w-full">
        <AppBarFS onIconPress={() => navigate(-1)} title={"ติดต่อผู้ขาย"} />
      </div>
      <div className="flex flex-col gap-4 items-center h-[80%] justify-center">
        <p className="text-[24px] font-medium text-[#ffff]">ติดต่อเรา</p>
        <LllustationSVG />
        <p className="text-[#ffff]">ต้องการติดต่อเรา กรุณาโทร</p>
        <div className="text-[#ffff]">
          <CallIcon />
          <Link
            href={`tel:028338000`}
            sx={{
              color: "#ffff",
              fontWeight: "600",
              marginLeft: "8px",
              textDecorationColor: "white",
            }}
          >
            02-833-8000
          </Link>
        </div>
        <div className="text-[#ffff]">
          <EmailIcon />
          <Link
            href={`mailto:farmsook@betagro.com`}
            sx={{
              color: "#ffff",
              fontWeight: "600",
              marginLeft: "8px",
              textDecorationColor: "white",
            }}
          >
            farmsook@betagro.com
          </Link>
        </div>
      </div>
      <Link
        href={`tel:028338000`}
        sx={{
          width: "90%",
        }}
      >
        <Button
          size="large"
          variant="contained"
          sx={{
            width: "100%",
          }}
          startIcon={<CallIcon />}
        >
          ติดต่อผู้ขาย
        </Button>
      </Link>
    </div>
  );
}

import {
  IFeedFoodBrandsResponse,
  IFeedFoodListResponse,
} from "../types/FoodFeed.type";
import adminAPI from "./AdminApiService";

export const getBrands = async (params: { direction?: "asc" | "desc" }) => {
  const { data } = await adminAPI.get<IFeedFoodBrandsResponse>(
    "/feed-management/brands",
    { params }
  );
  return data;
};

export const getFeeds = async (params: {
  searchText?: string;
  feedBrandId?: string;
  orderBy?: "name_th" | "sku_th" | "name_en" | "sku_en";
  direction: "asc" | "desc";
}) => {
  const { data } = await adminAPI.get<IFeedFoodListResponse>(
    "/feed-management",
    { params }
  );
  return data;
};

export const createFeed = async (body: {
  feedBrandId: string;
  feedBrandName: string;
  feedBrandNameEn: string;
  feedBrandNumber: string;
  feedBrandNumberEn: string;
  unit: "kg" | "bag";
  pricePerUnit: number;
  startWeight: number;
  endWeight: number;
  protein: number;
  consumption: number;
  orderFeedActive: boolean;
}) => {
  return adminAPI.post("/feed-management", body);
};

export const removeFeed = async (body: { ids: string[] }) => {
  return adminAPI.delete("/feed-management", { data: body });
};

export const editFeed = async (body: {
  feedBrandItems: {
    id: string;
    feedBrandName: string;
    feedBrandNameEn: string;
    feedBrandNumber: string;
    feedBrandNumberEn: string;
    unit: "kg" | "bag";
    pricePerUnit: number;
    startWeight: number;
    endWeight: number;
    protein: number;
    consumption: number;
    orderFeedActive: boolean;
  }[];
}) => {
  return adminAPI.put("/feed-management", body);
};

export const editActiveBrands = async (body: {
  feedBrands: {
    id: string;
    orderFeedActive: boolean;
  }[];
}) => {
  return adminAPI.put("/feed-management/brands", body);
};

import { TFunction } from "i18next";
import { SellingReportLevelStatusEnum } from "../../../pages/farm-past-performance/types";

export enum GaugeStatusEnum {
  Bad = "bad",
  Medium = "medium",
  Good = "good",
}

export const GaugeStatusMap = new Map<
  SellingReportLevelStatusEnum,
  { name: string; color: string; value: number; key: string }
>([
  [
    SellingReportLevelStatusEnum.Poor,
    { color: "#D32F2F", name: "Poor", key: "poor", value: 10 },
  ],
  [
    SellingReportLevelStatusEnum.Medium,
    { color: "#F79836", name: "Medium", key: "medium", value: 50 },
  ],
  [
    SellingReportLevelStatusEnum.Good,
    { color: "#68C184", name: "Good", key: "good", value: 90 },
  ],
]);

export interface IPigStatusGaugeChartProps {
  minRate: number;
  maxRate: number;
  value: number | string;
  status: SellingReportLevelStatusEnum;
  unit?: string;
  t: TFunction<"translation", undefined, "translation">;
}

import CloseIcon from "@mui/icons-material/Close";
import {
  CircularProgress,
  Fade,
  IconButton,
  MenuItem,
  Stack,
  SvgIcon,
  Tab,
  Tabs,
  styled,
} from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getEditInvoiceDocumentAPI,
  getInvoiceDownloadUrlAPI,
  getInvoiceOtherDownloadUrlAPI,
  getReceiptDownloadUrlAPI,
} from "../../../../services/admin/invoice/invoiceSale.service";
import { IGetEditInvoiceDocumentResponse } from "../../../../services/admin/invoice/type";
import {
  covertToBlobUrl,
  handleDownloadBlob,
} from "../../../../utils/downloadFile";
import { DownloadSvg } from "../../../assets/svg/Download.svg";
import { IInvoiceTable } from "../../../pages/invoice/type";
import ButtonFillCustom from "../../Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../Button/ButtonOutlinedCustom";
import SelectAdminCustom from "../../Select/Select";
import { TabPanelCustom } from "../../TabPanelCustom/TabPanelCustom";
import { FontTypo, InnerContainer, ModalContainer } from "../Modal.styled";
import PdfRenderer from "./ModalPanel/PdfRenderer";
import UpdateDocumentModal from "./UpdateDocumentModal";

export interface DocumentModalProps {
  handleModalClose: (isSuccess: boolean) => void;
  isOpen: boolean;
  data?: IInvoiceTable;
}

export interface IInvoiceFiles {
  id: string;
  documentType: string;
  fileOriginalName: string;
  createdAt: string;
}

const DATE_FORMAT_WITH_TIME = "YYYYMMDDHHmmss";
const DocumentModal = ({
  handleModalClose,
  isOpen = false,
  data,
}: DocumentModalProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [openUpdateDocumentModal, setOpenUpdateDocumentModal] = useState(false);
  const [invoiceDoc, setInvoiceDoc] =
    useState<IGetEditInvoiceDocumentResponse["invoice"]>();
  const [transferEvidenceIndex, setTransferEvidenceIndex] = useState("");
  const [receiptIndex, setReceiptIndex] = useState("");
  const [otherFileIndex, setOtherFileIndex] = useState("");
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [transferEvidenceUrl, setTransferEvidenceUrl] = useState("");
  const [otherFileUrl, setOtherFileUrl] = useState("");
  const [receiptUrl, setReceiptUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [toggleFetch, setToggleFetch] = useState<boolean>(false);
  const receiptFiles = useMemo(
    () =>
      (invoiceDoc?.invoiceReceipts || [])
        .filter((row) => row.fileReceipt)
        .map(
          (row): IInvoiceFiles => ({
            ...row.fileReceipt,
            createdAt: row.createdAt,
          })
        ),
    [invoiceDoc]
  );
  const transferEvidenceFiles = useMemo(
    () =>
      (invoiceDoc?.invoiceReceipts || [])
        .filter((row) => row.fileTransferEvidence)
        .map(
          (row): IInvoiceFiles => ({
            ...row.fileTransferEvidence,
            createdAt: row.createdAt,
          })
        ),
    [invoiceDoc]
  );
  const otherFiles = useMemo(
    () =>
      invoiceDoc?.invoiceFiles?.filter((row) => row.documentType === "other") ||
      [],
    [invoiceDoc]
  );
  const handleExportInvoice = useCallback(async () => {
    if (invoiceUrl) {
      handleDownloadBlob(
        invoiceUrl,
        `invoice_${dayjs().format(DATE_FORMAT_WITH_TIME)}.pdf`
      );
    }
  }, [invoiceUrl]);
  const handleExportReceipts = useCallback(async () => {
    for (const file of receiptFiles) {
      const response = await getReceiptDownloadUrlAPI(
        file.documentType,
        file.id
      );
      if (response) {
        try {
          const objectURL = await covertToBlobUrl(response?.blob?.blobUrl);
          handleDownloadBlob(objectURL, file.fileOriginalName);
        } catch {}
      }
    }
  }, [receiptFiles]);
  const handleExportOthers = useCallback(async () => {
    for (const file of otherFiles) {
      const response = await getInvoiceOtherDownloadUrlAPI(
        invoiceDoc?.id || "",
        file.id
      );
      if (response) {
        try {
          const objectURL = await covertToBlobUrl(response?.blob?.blobUrl);
          handleDownloadBlob(objectURL, file.fileOriginalName);
        } catch {}
      }
    }
  }, [invoiceDoc, otherFiles]);
  const handleExportTransferEvidence = useCallback(async () => {
    for (const file of transferEvidenceFiles) {
      const response = await getReceiptDownloadUrlAPI(
        file.documentType,
        file.id
      );
      if (response) {
        try {
          const objectURL = await covertToBlobUrl(response?.blob?.blobUrl);
          handleDownloadBlob(objectURL, file.fileOriginalName);
        } catch {}
      }
    }
  }, [transferEvidenceFiles]);

  const exportHandler = useMemo(
    () => [
      handleExportInvoice,
      handleExportTransferEvidence,
      handleExportReceipts,
      handleExportOthers,
    ],
    [
      handleExportInvoice,
      handleExportReceipts,
      handleExportTransferEvidence,
      handleExportOthers,
    ]
  );
  const disableExportBtn = useMemo(
    () => [
      invoiceUrl ? [invoiceUrl] : [],
      transferEvidenceFiles,
      receiptFiles,
      otherFiles,
    ],
    [invoiceUrl, transferEvidenceFiles, receiptFiles, otherFiles]
  );

  const onChangeTabIndex = (
    _event: React.SyntheticEvent<Element, Event>,
    value: number
  ) => {
    setTabIndex(value);
  };

  const handleClose = (isSuccess: boolean = false) => {
    setTabIndex(0);
    setInvoiceUrl("");
    setReceiptUrl("");
    setTransferEvidenceUrl("");
    setOtherFileUrl("");
    setTransferEvidenceIndex("");
    setReceiptIndex("");
    setOtherFileIndex("");
    handleModalClose(isSuccess);
  };

  useEffect(() => {
    if (data && isOpen) {
      const fetch = async () => {
        setIsLoading(true);
        const res = await getEditInvoiceDocumentAPI(data.id);
        setInvoiceDoc(res.data.invoice);
        const invoiceFile = res.data.invoice.invoiceFiles.find(
          (row) => row.documentType === "invoice"
        );
        if (invoiceFile?.id) {
          const invoice = await getInvoiceDownloadUrlAPI(
            res.data.invoice.id,
            invoiceFile.id
          );
          if (invoice) {
            try {
              const objectURL = await covertToBlobUrl(invoice?.blob?.blobUrl);
              setInvoiceUrl(objectURL);
            } catch {
              setInvoiceUrl("");
            }
          }
        }
        setIsLoading(false);
      };
      fetch();
    }
  }, [data, isOpen, toggleFetch]);

  useEffect(() => {
    if (transferEvidenceFiles.length > 0 && isOpen && transferEvidenceIndex) {
      const fetch = async () => {
        setIsLoading(true);
        const response = await getReceiptDownloadUrlAPI(
          "transfer_evidence",
          transferEvidenceFiles[Number(transferEvidenceIndex)].id
        );
        if (response) {
          try {
            const objectURL = await covertToBlobUrl(response?.blob?.blobUrl);
            setTransferEvidenceUrl(objectURL);
          } catch {
            setTransferEvidenceUrl("");
          }
        }
        setIsLoading(false);
      };
      fetch();
    }
  }, [transferEvidenceFiles, transferEvidenceIndex, isOpen]);

  useEffect(() => {
    if (receiptFiles.length > 0 && isOpen && receiptIndex) {
      const fetch = async () => {
        setIsLoading(true);
        const response = await getReceiptDownloadUrlAPI(
          "receipt",
          receiptFiles[Number(receiptIndex)].id
        );
        if (response) {
          try {
            const objectURL = await covertToBlobUrl(response?.blob?.blobUrl);
            setReceiptUrl(objectURL);
          } catch {
            setReceiptUrl("");
          }
        }
        setIsLoading(false);
      };
      fetch();
    }
  }, [receiptFiles, receiptIndex, isOpen]);

  useEffect(() => {
    if (otherFiles.length > 0 && isOpen && otherFileIndex) {
      const fetch = async () => {
        setIsLoading(true);
        const response = await getInvoiceOtherDownloadUrlAPI(
          invoiceDoc?.id || "",
          otherFiles[Number(otherFileIndex)].id
        );
        if (response) {
          try {
            const objectURL = await covertToBlobUrl(response?.blob?.blobUrl);
            setOtherFileUrl(objectURL);
          } catch {
            setOtherFileUrl("");
          }
        }
        setIsLoading(false);
      };
      fetch();
    }
  }, [otherFiles, otherFileIndex, isOpen]);

  return (
    <ModalContainer
      aria-label="document-modal"
      data-testid="documentModal"
      open={isOpen}
      closeAfterTransition
    >
      <Fade in={isOpen}>
        <InnerContainer width={"1050px"} height={"720px"}>
          <div className="w-full h-full relative">
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <FontTypo fontFamily={"DM Sans"} fontSize={18} fontWeight={700}>
                เอกสาร
              </FontTypo>
              <IconButton
                onClick={() => handleClose(false)}
                sx={{ padding: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack
              pt={"24px"}
              mb={"24px"}
              direction={"row"}
              justifyContent={"space-between"}
            >
              <StyledTabs
                value={tabIndex}
                onChange={onChangeTabIndex}
                aria-label="sale-order-tab-control"
                TabIndicatorProps={{
                  sx: {
                    "&.MuiTabs-indicator": {
                      transform: "unset",
                      width: "unset",
                      bgcolor: "#68C184",
                      height: "3px",
                    },
                  },
                }}
              >
                <Tab label={"ใบ Invoice"} />
                <Tab label={"หลักฐานการโอนเงิน"} />
                <Tab label={"ใบเสร็จรับเงิน"} />
                <Tab label={"เอกสารอื่น ๆ"} />
              </StyledTabs>
              <div>
                <ButtonOutlinedCustom
                  title="นำออก"
                  btnTextColor="#68C184"
                  btnBorderColor="#68C184"
                  style={{ width: "90px", padding: "8px 12px 8px 16px" }}
                  disabled={disableExportBtn[tabIndex].length === 0}
                  onClick={() => exportHandler[tabIndex]()}
                  startIcon={
                    <SvgIcon
                      sx={{
                        fontSize: "14px !important",
                        color: "currentColor",
                        marginBottom: "3px",
                      }}
                      fontSize="small"
                    >
                      <DownloadSvg />
                    </SvgIcon>
                  }
                />
                <ButtonFillCustom
                  style={{
                    width: "115px",
                    padding: "8px 12px 8px 16px",
                    marginLeft: "12px",
                  }}
                  title="อัปเดตเอกสาร"
                  disabled={!invoiceDoc}
                  onClick={() => {
                    setOpenUpdateDocumentModal(true);
                  }}
                />
              </div>
            </Stack>
            <TabPanelCustom
              value={tabIndex}
              index={0}
              prefix="update-document-invoice"
            >
              {isLoading ? (
                <CircularProgress size={"10rem"} color="success" />
              ) : invoiceUrl ? (
                <PdfRenderer url={invoiceUrl} />
              ) : null}
            </TabPanelCustom>
            <TabPanelCustom
              value={tabIndex}
              index={1}
              prefix="transfer-evidence-slip"
            >
              <>
                <SelectAdminCustom
                  placeholder="เลือก"
                  value={`${transferEvidenceIndex}`}
                  renderValueArray={transferEvidenceFiles.map(
                    (elem, index) => ({
                      value: `${index}`,
                      label: ` หลักฐานการโอนเงิน ${dayjs(elem.createdAt).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}`,
                    })
                  )}
                  onChange={(event) => {
                    setTransferEvidenceIndex(event.target.value as string);
                  }}
                  menuItems={transferEvidenceFiles.map((elem, index) => {
                    return (
                      <MenuItem key={elem.id} value={`${index}`}>
                        หลักฐานการโอนเงิน{" "}
                        {dayjs(elem.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                      </MenuItem>
                    );
                  })}
                  style={{ width: "400px" }}
                />
                <div className="mt-3">
                  {isLoading ? (
                    <CircularProgress size={"10rem"} color="success" />
                  ) : transferEvidenceUrl ? (
                    transferEvidenceFiles[
                      Number(transferEvidenceIndex)
                    ]?.fileOriginalName.endsWith(".pdf") ? (
                      <PdfRenderer url={transferEvidenceUrl} />
                    ) : (
                      <img
                        alt="transfer-evidence-file"
                        src={transferEvidenceUrl}
                      />
                    )
                  ) : null}
                </div>
              </>
            </TabPanelCustom>
            <TabPanelCustom value={tabIndex} index={2} prefix="receipt-slip">
              <>
                <SelectAdminCustom
                  value={`${receiptIndex}`}
                  placeholder="เลือก"
                  renderValueArray={receiptFiles.map((elem, index) => ({
                    value: `${index}`,
                    label: ` ใบเสร็จรับเงิน ${dayjs(elem.createdAt).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}`,
                  }))}
                  onChange={(event) => {
                    setReceiptIndex(event.target.value as string);
                  }}
                  menuItems={receiptFiles.map((elem, index) => {
                    return (
                      <MenuItem key={elem.id} value={`${index}`}>
                        ใบเสร็จรับเงิน{" "}
                        {dayjs(elem.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                      </MenuItem>
                    );
                  })}
                  style={{ width: "400px" }}
                />
                <div className="mt-3">
                  {isLoading ? (
                    <CircularProgress size={"10rem"} color="success" />
                  ) : receiptUrl ? (
                    receiptFiles[
                      Number(receiptIndex)
                    ]?.fileOriginalName.endsWith(".pdf") ? (
                      <PdfRenderer url={receiptUrl} />
                    ) : (
                      <img alt="receipt-file" src={receiptUrl} />
                    )
                  ) : null}
                </div>
              </>
            </TabPanelCustom>
            <TabPanelCustom value={tabIndex} index={3} prefix="other-document">
              <>
                <SelectAdminCustom
                  value={`${otherFileIndex}`}
                  placeholder="เลือก"
                  renderValueArray={otherFiles.map((elem, index) => ({
                    value: `${index}`,
                    label: ` เอกสารอื่น ๆ : ${elem.fileOriginalName}`,
                  }))}
                  onChange={(event) => {
                    setOtherFileIndex(event.target.value as string);
                  }}
                  menuItems={otherFiles.map((elem, index) => {
                    return (
                      <MenuItem key={elem.id} value={`${index}`}>
                        เอกสารอื่น ๆ : {elem.fileOriginalName}
                      </MenuItem>
                    );
                  })}
                  style={{ width: "400px" }}
                />
                <div className="mt-3">
                  {isLoading ? (
                    <CircularProgress size={"10rem"} color="success" />
                  ) : otherFileUrl ? (
                    otherFiles[
                      Number(otherFileIndex)
                    ]?.fileOriginalName.endsWith(".pdf") ? (
                      <PdfRenderer url={otherFileUrl} />
                    ) : (
                      <img alt="other-file" src={otherFileUrl} />
                    )
                  ) : null}
                </div>
              </>
            </TabPanelCustom>
          </div>
          <UpdateDocumentModal
            handleModalClose={(isSuccess) => {
              setOpenUpdateDocumentModal(false);
              if (isSuccess) {
                setToggleFetch((prev) => !prev);
              }
            }}
            isOpen={openUpdateDocumentModal}
            data={invoiceDoc}
          />
        </InnerContainer>
      </Fade>
    </ModalContainer>
  );
};
export default DocumentModal;

const StyledTabs = styled(Tabs)(() => ({
  height: "36px",
  minHeight: "unset",
  "& .MuiTabs-scroller": {
    "& .MuiTabs-flexContainer": {
      alignItems: "end",
      height: "100%",
      display: "flex",
      gap: "12px",
      "& .MuiTab-root": {
        padding: "6px 28px",
        textTransform: "unset",
        fontFamily: "IBM Plex Sans Thai",
        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: "20px",
        height: "32px",
        minHeight: "unset",
        color: "#000000",
        borderBottom: "3px solid #DDF3E4",
        "& .Mui-selected": {
          color: "#000000",
        },
      },
    },
  },
}));

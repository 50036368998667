import { Link, LinkProps } from "@mui/material";
import React from "react";

interface IButtonLinkCustomProps extends LinkProps {
  title: string;
  textColor?: string;
  textDecorationColor?: string;
  onClick?: () => void;
}
function ButtonLinkCustom({
  title,
  textColor,
  textDecorationColor,
  onClick,
  ...rest
}: IButtonLinkCustomProps) {
  return (
    <Link
      component="button"
      variant="body2"
      onClick={onClick}
      {...rest}
      sx={{
        color: textColor || "#68C184",
        textDecorationColor: textDecorationColor || "#68C184",
        fontSize: "14px",
        fontWeight: 500,
        ...rest?.sx,
      }}
    >
      {title}
    </Link>
  );
}

export default ButtonLinkCustom;

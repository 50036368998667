export default function OrderFeedSVG() {
  return (
    <svg
      width="77"
      height="77"
      viewBox="0 0 77 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="77" height="77" rx="20" fill="#68C184" />
      <mask
        id="mask0_9733_77348"
        maskUnits="userSpaceOnUse"
        x="16"
        y="16"
        width="45"
        height="45"
      >
        <rect x="16" y="16" width="45" height="45" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_9733_77348)">
        <path
          d="M29.125 56.6472C28.0938 56.6472 27.2109 56.28 26.4766 55.5457C25.7422 54.8113 25.375 53.9285 25.375 52.8972C25.375 51.866 25.7422 50.9832 26.4766 50.2488C27.2109 49.5144 28.0938 49.1472 29.125 49.1472C30.1563 49.1472 31.0391 49.5144 31.7734 50.2488C32.5078 50.9832 32.875 51.866 32.875 52.8972C32.875 53.9285 32.5078 54.8113 31.7734 55.5457C31.0391 56.28 30.1563 56.6472 29.125 56.6472ZM47.875 56.6472C46.8438 56.6472 45.9609 56.28 45.2266 55.5457C44.4922 54.8113 44.125 53.9285 44.125 52.8972C44.125 51.866 44.4922 50.9832 45.2266 50.2488C45.9609 49.5144 46.8438 49.1472 47.875 49.1472C48.9063 49.1472 49.7891 49.5144 50.5234 50.2488C51.2578 50.9832 51.625 51.866 51.625 52.8972C51.625 53.9285 51.2578 54.8113 50.5234 55.5457C49.7891 56.28 48.9063 56.6472 47.875 56.6472ZM25.75 22.8972H53.4063C54.125 22.8972 54.6719 23.2175 55.0469 23.8582C55.4219 24.4988 55.4375 25.1472 55.0938 25.8035L48.4375 37.8035C48.0938 38.4285 47.6328 38.9128 47.0547 39.2566C46.4766 39.6003 45.8438 39.7722 45.1563 39.7722H31.1875L29.125 43.5222H51.625V47.2722H29.125C27.7188 47.2722 26.6563 46.655 25.9375 45.4207C25.2188 44.1863 25.1875 42.9597 25.8438 41.741L28.375 37.1472L21.625 22.8972H17.875V19.1472H23.9688L25.75 22.8972Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

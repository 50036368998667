import { useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { tw } from "../../../../utils/tw";
import { IconButton } from "@mui/material";
import AppBarFS from "../../../../components/app-bar/AppBar";
import FilterListIcon from "@mui/icons-material/FilterList";
import Table from "./views/Table";
import ButtonFillCustom from "../../../../admin/components/Button/ButtonFillCustom";
import { getCommaNumber } from "../../../../utils/number";
import PencilAlt from "../../../../assets/svg/PencilAlt";
import AddMoneyToPayModal from "./views/AddMoneyToPayModal";
import PaymentMethodModal from "./views/PaymentMethodModal";
import { useUnpaidBillController } from "./controller";
import FilterModal from "./views/FilterModal";

interface IState {
  value: string;
}

const classes = {
  container: tw(`w-full h-full`),
  headerContainer: tw(`h-[120px] flex items-center`),
  detailContainer: tw(`flex flex-col w-full h-full gap-[16px] px-[16px]`),
  tableContainer: tw(`pb-[163px]`),
  textAndFilterContainer: tw(`flex flex-row py-[16px] px-4 justify-between`),
  buttonContainer: tw(`pt-1`),
  button: tw(`!h-[59px] w-full !rounded-[50px] !text-[16px] !font-bold`),
  buttonTextContainer: tw(
    `flex flex-col gap-6 pt-8 px-[17.5px] pb-6 fixed bottom-0 bg-[#FFFFFF] w-full z-10`
  ),
  textContainer: tw(`flex flex-row justify-between`),
  text1: tw(`font-semibold text-[18px] text-[#3777BC]`),
  text2: tw(`font-normal text-[1rem] text-secondary`),
};

const BillPayment = () => {
  const {
    listInvoice,
    refetchListInvoice,
    orderByType,
    setOrderByType,
    formUnpaidBill,
    setFormUnpaidBill,
    onChangeFormUnpaidBill,
    currentPage,
    setCurrentPage,
  } = useUnpaidBillController();

  let navigate = useNavigate();

  const isCanBack =
    typeof window !== "undefined" ? window.history.state.idx > 0 : false;

  const onBack = useCallback(() => {
    if (!isCanBack) {
      navigate("/financial-support/financial-bill-payment");
    } else {
      navigate(-1);
    }
  }, [isCanBack]);

  const [summary, setSummary] = useState("");
  const [customPrice, setCustomPrice] = useState("");

  const [isShowAddMoneyToPay, setIsShowAddMoneyToPay] = useState(false);

  const onShowAddMoneyToPay = useCallback(() => {
    setIsShowAddMoneyToPay(true);
  }, []);

  const onHideAddMoneyToPay = useCallback(() => {
    setIsShowAddMoneyToPay(false);
  }, []);

  const [isShowPaymentMethod, setIsShowPaymentMethod] = useState(false);

  const onShowPaymentMethod = useCallback(() => {
    setIsShowPaymentMethod(true);
  }, []);

  const onHidePaymentMethod = useCallback(() => {
    setIsShowPaymentMethod(false);
  }, []);

  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);

  const onOpenFilterModal = useCallback(() => setIsOpenFilterModal(true), []);
  const onHideFilterModal = useCallback(() => setIsOpenFilterModal(false), []);

  const [itemSelected, setItemSelected] = useState<string[]>([]);

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <AppBarFS title={"บิลที่ยังไม่ได้ชำระ"} onIconPress={onBack} />
        </div>
        <div className={classes.detailContainer}>
          <div className={classes.textAndFilterContainer}>
            <span className={tw(classes.text1, "flex flex-col justify-center")}>
              บิลทั้งหมด
            </span>
            <div className="h-full flex gap-2 items-center">
              <span className={classes.text2}>กรองโดย</span>
              <IconButton
                aria-label="filter"
                sx={{
                  color: "#68C184",
                  border: "1px solid #68C184",
                  ":hover": {
                    color: "#FFFFFF",
                    border: "1px solid #68C184",
                    bgcolor: "#68C184",
                  },
                  ":active": {
                    color: "#FFFFFF",
                    border: "1px solid #68C184",
                    bgcolor: "#68C184",
                  },
                }}
                onClick={onOpenFilterModal}
              >
                <FilterListIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <div className={classes.tableContainer}>
          <Table
            data={listInvoice?.rows || []}
            summary={summary}
            setSummary={setSummary}
            orderByType={orderByType}
            setOrderByType={setOrderByType}
            itemSelected={itemSelected}
            setItemSelected={setItemSelected}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            count={listInvoice?.count || 0}
          />
        </div>
        <div className={classes.buttonContainer}>
          <div
            className={classes.buttonTextContainer}
            style={{
              boxShadow: "2px 0px 4px 0px #00000020",
            }}
          >
            <div className="flex flex-col gap-[6px]">
              <div className={classes.textContainer}>
                <div className={tw(classes.text1, "text-[16px]")}>
                  ยอดที่ค้างชำระ
                </div>
                <div
                  className={tw(classes.text1, "text-[16px] text-[#34407B]")}
                >
                  {getCommaNumber(+(customPrice || summary), true)} บาท
                </div>
              </div>
              {+summary > 0 && (
                <div
                  className={tw(
                    classes.textContainer,
                    "justify-end gap-[6px] cursor-pointer"
                  )}
                  onClick={onShowAddMoneyToPay}
                >
                  <PencilAlt />
                  <div
                    className={tw(
                      classes.text2,
                      "font-bold underline leading-normal"
                    )}
                  >
                    ระบุจำนวนเงิน
                  </div>
                </div>
              )}
            </div>
            <ButtonFillCustom
              title="ถัดไป"
              className={classes.button}
              disabled={!(+(customPrice || summary) > 0)}
              onClick={onShowPaymentMethod}
            />
          </div>
        </div>
      </div>
      <AddMoneyToPayModal
        isOpen={isShowAddMoneyToPay}
        onCloseFn={onHideAddMoneyToPay}
        summary={summary}
        customPrice={customPrice}
        setCustomPrice={setCustomPrice}
      />
      <PaymentMethodModal
        isOpen={isShowPaymentMethod}
        onClose={onHidePaymentMethod}
        summary={summary}
        customPrice={customPrice}
        data={listInvoice?.rows}
        itemSelected={itemSelected}
        setItemSelected={setItemSelected}
      />
      <FilterModal
        title="กรองโดย"
        isOpen={isOpenFilterModal}
        onClose={onHideFilterModal}
        form={formUnpaidBill}
        setForm={setFormUnpaidBill}
        onChangeForm={onChangeFormUnpaidBill}
      />
    </div>
  );
};

export default BillPayment;

import CloseIcon from "@mui/icons-material/Close";
import {
  Fade,
  IconButton,
  MenuItem,
  Stack,
  SvgIcon,
  Tab,
  Tabs,
  styled,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getReceiptDownloadUrlAPI } from "../../../../../../services/admin/invoice/invoiceSale.service";
import {
  covertToBlobUrl,
  handleDownloadBlob,
} from "../../../../../../utils/downloadFile";
import { DownloadSvg } from "../../../../../assets/svg/Download.svg";
import ButtonOutlinedCustom from "../../../../../components/Button/ButtonOutlinedCustom";
import PdfRenderer from "../../../../../components/Modal/DocumentModal/ModalPanel/PdfRenderer";
import {
  FontTypo,
  InnerContainer,
  ModalContainer,
} from "../../../../../components/Modal/Modal.styled";
import SelectAdminCustom from "../../../../../components/Select/Select";
import { TabPanelCustom } from "../../../../../components/TabPanelCustom/TabPanelCustom";
import {
  getMoneyDebtDownloadUrlAPI,
  getMoneyDebtLendingDocument,
  getMoneyDebtSaleDocument,
} from "../../services/documentFile.service";
import { IGetDocumentMoneyDebt } from "../../types/documentFile.type";

type Props = {
  isOpen: boolean;
  handleClose: (isSuccess: boolean) => void;
  invoiceId?: string;
  invoiceReceiptId?: string;
  installmentId?: string;
  tabLendingName?: string;
  roleName: string;
};

export interface IInvoiceFiles {
  id: string;
  documentType: string;
  fileOriginalName: string;
  createdAt: string;
}

const ViewDocumentModal = ({
  isOpen,
  handleClose,
  invoiceId,
  invoiceReceiptId,
  installmentId,
  tabLendingName,
  roleName,
}: Props) => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [allDocument, setAllDocument] =
    useState<IGetDocumentMoneyDebt["invoice"]>();
  const [transferEvidenceIndex, setTransferEvidenceIndex] = useState("");
  const [transferEvidenceUrl, setTransferEvidenceUrl] = useState("");
  const [receiptIndex, setReceiptIndex] = useState("");
  const [receiptUrl, setReceiptUrl] = useState("");
  const [debtAcknowledgementUrl, setDebtAcknowledgementUrl] = useState("");

  const onClose = (isSuccess: boolean = false) => {
    setTabIndex(0);
    setReceiptUrl("");
    setReceiptIndex("");
    setTransferEvidenceUrl("");
    setTransferEvidenceIndex("");
    setDebtAcknowledgementUrl("");
    handleClose(isSuccess);
  };

  const onChangeTabIndex = (
    _event: React.SyntheticEvent<Element, Event>,
    value: number
  ) => {
    setTabIndex(value);
  };

  const debtAcknowledgementFiles = useMemo(
    () =>
      (allDocument?.DebtAcknowledgement || []).map(
        (row): IInvoiceFiles => ({
          fileOriginalName: row.fileOriginalName,
          createdAt: row.createdAt,
          id: row.id,
          documentType: row.documentType,
        })
      ),
    [allDocument]
  );

  const transferEvidenceFiles = useMemo(
    () =>
      (allDocument?.fileTransferEvidences || [])
        .filter((row) => row.fileTransferEvidence)
        .map(
          (row): IInvoiceFiles => ({
            ...row.fileTransferEvidence,
            createdAt: row.createdAt,
          })
        ),
    [allDocument]
  );

  const receiptsFiles = useMemo(
    () =>
      (allDocument?.fileReceipts || [])
        .filter((row) => row.fileReceipt)
        .map(
          (row): IInvoiceFiles => ({
            ...row.fileReceipt,
            createdAt: row.createdAt,
          })
        ),
    [allDocument]
  );

  const handleExportReceipts = useCallback(async () => {
    for (const file of receiptsFiles) {
      const response = await getReceiptDownloadUrlAPI(
        file.documentType,
        file.id
      );
      if (response) {
        try {
          const objectURL = await covertToBlobUrl(response?.blob?.blobUrl);
          handleDownloadBlob(objectURL, file.fileOriginalName);
        } catch {}
      }
    }
  }, [receiptsFiles]);

  const handleExportDebt = useCallback(async () => {
    for (const file of debtAcknowledgementFiles) {
      const response = await getReceiptDownloadUrlAPI(
        file.documentType,
        file.id
      );
      if (response) {
        try {
          const objectURL = await covertToBlobUrl(response?.blob?.blobUrl);
          handleDownloadBlob(objectURL, file.fileOriginalName);
        } catch {}
      }
    }
  }, [debtAcknowledgementFiles]);

  const handleExportTransferEvidence = useCallback(async () => {
    for (const file of transferEvidenceFiles) {
      const response = await getReceiptDownloadUrlAPI(
        file.documentType,
        file.id
      );
      if (response) {
        try {
          const objectURL = await covertToBlobUrl(response?.blob?.blobUrl);
          handleDownloadBlob(objectURL, file.fileOriginalName);
        } catch {}
      }
    }
  }, [transferEvidenceFiles]);

  const exportHandler = useMemo(
    () => [
      handleExportDebt,
      handleExportTransferEvidence,
      handleExportReceipts,
    ],
    [handleExportDebt, handleExportReceipts, handleExportTransferEvidence]
  );

  useEffect(() => {
    if (isOpen && invoiceId && roleName === "sale") {
      const fetch = async () => {
        const res = await getMoneyDebtSaleDocument(invoiceId);
        setAllDocument(res.data.invoice);
      };
      fetch();
    }
  }, [invoiceId, isOpen]);

  useEffect(() => {
    if (isOpen && (invoiceReceiptId || installmentId) && tabLendingName) {
      const fetch = async () => {
        const res = await getMoneyDebtLendingDocument(
          tabLendingName,
          installmentId || invoiceReceiptId || ""
        );
        setAllDocument(res.data.invoice);
      };
      fetch();
    }
  }, [
    invoiceReceiptId,
    isOpen,
    installmentId,
    tabLendingName,
    invoiceReceiptId,
  ]);

  useEffect(() => {
    if (
      isOpen &&
      invoiceId &&
      transferEvidenceFiles.length > 0 &&
      transferEvidenceIndex &&
      roleName
    ) {
      const fetch = async () => {
        const response = await getMoneyDebtDownloadUrlAPI(
          roleName,
          invoiceId,
          "transfer_evidence",
          transferEvidenceFiles[Number(transferEvidenceIndex)]?.id
        );
        if (response) {
          try {
            const objectURL = await covertToBlobUrl(response?.blob?.blobUrl);
            setTransferEvidenceUrl(objectURL);
          } catch {
            setTransferEvidenceUrl("");
          }
        }
      };
      fetch();
    }
  }, [
    transferEvidenceFiles,
    transferEvidenceIndex,
    isOpen,
    invoiceId,
    roleName,
  ]);

  useEffect(() => {
    if (
      isOpen &&
      invoiceId &&
      debtAcknowledgementFiles.length > 0 &&
      roleName
    ) {
      const fetch = async () => {
        const response = await getMoneyDebtDownloadUrlAPI(
          roleName,
          invoiceId,
          "debt_acknowledgement_letter",
          debtAcknowledgementFiles[0]?.id
        );
        if (response) {
          try {
            const objectURL = await covertToBlobUrl(response?.blob?.blobUrl);
            setDebtAcknowledgementUrl(objectURL);
          } catch {
            setDebtAcknowledgementUrl("");
          }
        }
      };
      fetch();
    }
  }, [debtAcknowledgementFiles, isOpen, invoiceId, roleName]);

  useEffect(() => {
    if (
      isOpen &&
      invoiceId &&
      receiptsFiles.length > 0 &&
      receiptIndex &&
      roleName
    ) {
      const fetch = async () => {
        const response = await getMoneyDebtDownloadUrlAPI(
          roleName,
          invoiceId,
          "receipt",
          receiptsFiles[Number(receiptIndex)]?.id
        );
        if (response) {
          try {
            const objectURL = await covertToBlobUrl(response?.blob?.blobUrl);
            setReceiptUrl(objectURL);
          } catch {
            setReceiptUrl("");
          }
        }
      };
      fetch();
    }
  }, [receiptsFiles, receiptIndex, isOpen, invoiceId, roleName]);

  return (
    <ModalContainer
      aria-label="document-modal"
      data-testid="documentModal"
      open={isOpen}
      closeAfterTransition
    >
      <Fade in={isOpen}>
        <InnerContainer width={"1050px"} height={"720px"}>
          <div className="w-full h-full relative">
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <FontTypo fontFamily={"DM Sans"} fontSize={18} fontWeight={700}>
                เอกสาร
              </FontTypo>
              <IconButton onClick={() => onClose(false)} sx={{ padding: 0 }}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack
              pt={"24px"}
              mb={"24px"}
              direction={"row"}
              justifyContent={"space-between"}
            >
              <StyledTabs
                value={tabIndex}
                onChange={onChangeTabIndex}
                aria-label="sale-order-tab-control"
                TabIndicatorProps={{
                  sx: {
                    "&.MuiTabs-indicator": {
                      transform: "unset",
                      width: "unset",
                      bgcolor: "#68C184",
                      height: "3px",
                    },
                  },
                }}
              >
                <Tab label={"ใบรับสภาพหนี้"} />
                <Tab label={"หลักฐานการโอนเงิน"} />
                <Tab label={"ใบเสร็จรับเงิน"} />
              </StyledTabs>
              <div>
                <ButtonOutlinedCustom
                  title="นำออก"
                  btnTextColor="#68C184"
                  btnBorderColor="#68C184"
                  style={{ width: "90px", padding: "8px 12px 8px 16px" }}
                  onClick={() => exportHandler[tabIndex]()}
                  startIcon={
                    <SvgIcon
                      sx={{
                        fontSize: "14px !important",
                        color: "currentColor",
                        marginBottom: "3px",
                      }}
                      fontSize="small"
                    >
                      <DownloadSvg />
                    </SvgIcon>
                  }
                />
              </div>
            </Stack>
            <TabPanelCustom
              value={tabIndex}
              index={0}
              prefix="update-document-invoice"
            >
              {debtAcknowledgementFiles[0]?.fileOriginalName.endsWith(
                ".pdf"
              ) ? (
                <PdfRenderer url={debtAcknowledgementUrl} />
              ) : (
                <img
                  alt="debt-acknowledgement-file"
                  src={debtAcknowledgementUrl}
                />
              )}
            </TabPanelCustom>
            <TabPanelCustom
              value={tabIndex}
              index={1}
              prefix="transfer-evidence-slip"
            >
              <>
                <SelectAdminCustom
                  placeholder="เลือก"
                  value={`${transferEvidenceIndex}`}
                  renderValueArray={transferEvidenceFiles.map(
                    (elem, index) => ({
                      value: `${index}`,
                      label: ` หลักฐานการโอนเงิน ${dayjs(elem.createdAt).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}`,
                    })
                  )}
                  onChange={(event) => {
                    setTransferEvidenceIndex(event.target.value as string);
                  }}
                  menuItems={transferEvidenceFiles.map((elem, index) => {
                    return (
                      <MenuItem key={elem.id} value={`${index}`}>
                        หลักฐานการโอนเงิน{" "}
                        {dayjs(elem.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                      </MenuItem>
                    );
                  })}
                  style={{ width: "400px" }}
                />
                <div className="mt-3">
                  {transferEvidenceUrl ? (
                    transferEvidenceFiles[
                      Number(transferEvidenceIndex)
                    ]?.fileOriginalName.endsWith(".pdf") ? (
                      <PdfRenderer url={transferEvidenceUrl} />
                    ) : (
                      <img
                        alt="transfer-evidence-file"
                        src={transferEvidenceUrl}
                      />
                    )
                  ) : null}
                </div>
              </>
            </TabPanelCustom>
            <TabPanelCustom value={tabIndex} index={2} prefix="receipt">
              <>
                <SelectAdminCustom
                  placeholder="เลือก"
                  value={`${receiptIndex}`}
                  renderValueArray={receiptsFiles.map((elem, index) => ({
                    value: `${index}`,
                    label: ` ใบเสร็จรับเงิน ${dayjs(elem.createdAt).format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}`,
                  }))}
                  onChange={(event) => {
                    setReceiptIndex(event.target.value as string);
                  }}
                  menuItems={receiptsFiles.map((elem, index) => {
                    return (
                      <MenuItem key={elem.id} value={`${index}`}>
                        ใบเสร็จรับเงิน{" "}
                        {dayjs(elem.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                      </MenuItem>
                    );
                  })}
                  style={{ width: "400px" }}
                />
                <div className="mt-3">
                  {receiptUrl ? (
                    receiptsFiles[
                      Number(receiptIndex)
                    ]?.fileOriginalName.endsWith(".pdf") ? (
                      <PdfRenderer url={receiptUrl} />
                    ) : (
                      <img alt="transfer-evidence-file" src={receiptUrl} />
                    )
                  ) : null}
                </div>
              </>
            </TabPanelCustom>
          </div>
        </InnerContainer>
      </Fade>
    </ModalContainer>
  );
};

export default ViewDocumentModal;

const StyledTabs = styled(Tabs)(() => ({
  height: "36px",
  minHeight: "unset",
  "& .MuiTabs-scroller": {
    "& .MuiTabs-flexContainer": {
      alignItems: "end",
      height: "100%",
      display: "flex",
      gap: "12px",
      "& .MuiTab-root": {
        padding: "6px 28px",
        textTransform: "unset",
        fontFamily: "IBM Plex Sans Thai",
        fontWeight: 400,
        fontSize: "1rem",
        lineHeight: "20px",
        height: "32px",
        minHeight: "unset",
        color: "#000000",
        borderBottom: "3px solid #DDF3E4",
        "& .Mui-selected": {
          color: "#000000",
        },
      },
    },
  },
}));

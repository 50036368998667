import {
  ICheckDuplicate,
  ICheckVerifyPin,
  IGetMessage,
  IGetProfileFarmer,
} from "../types/AccountCenter.type";
import api from "./ApiService";

export const getProfileFarmer = async () => {
  const { data } = await api.get<IGetProfileFarmer>(`/profile`);
  return data.farmer;
};

export const checkDuplicatePhone = async (param: { mobileNumber: string }) => {
  const { data } = await api.get<ICheckDuplicate>(
    `/farmers/account-center/verify-phone?mobileNumber=${param.mobileNumber}`
  );
  return data;
};

export const checkDuplicateEmail = async (param: { email: string }) => {
  const { data } = await api.post<ICheckDuplicate>(
    `/farmers/account-center/verify-email`,
    param
  );
  return data;
};

export const checkVerifyPin = async (param: { pin: string }) => {
  const { data } = await api.post<ICheckVerifyPin>(
    `/farmers/account-center/verify-pin`,
    param
  );

  return data;
};

export const editAccount = async (body: {
  name: string;
  surname: string;
  dob: Date;
  idCardNumber: string;
  mobileNumber: string;
  email: string;
  pin: string;
}) => {
  const { data } = await api.put<IGetMessage>(`/farmers/account-center`, body);
  return data;
};

export const resetPin = async (body: { pin: string; newPin: string }) => {
  const { data } = await api.post<IGetMessage>(
    `/farmers/account-center/reset-pin`,
    body
  );

  if (!data.success) {
    throw new Error(data.message);
  }

  return data;
};

export const deleteAccount = async (reason: string) => {
  const { data } = await api.delete<IGetMessage>(`/farmers/account-center`, {
    data: { reason },
  });
  return data;
};

export const checkVerifyPinPartner = async (param: { pin: string }) => {
  const { data } = await api.post<ICheckVerifyPin>(
    `/partner/account-center/verify-pin`,
    param
  );
  return data;
};

export const resetPinPartner = async (body: {
  pin: string;
  newPin: string;
}) => {
  const { data } = await api.post<IGetMessage>(
    `/partner/account-center/reset-pin`,
    body
  );
  return data;
};

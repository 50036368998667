import { TDropdownValue } from "../group-area/views/Dropdown";

export type TFormClaimFilter = {
  orderDateStart: string;
  orderDateEnd: string;
  deliveryDateStart: string;
  deliveryDateEnd: string;
  feedBrandId: TDropdownValue[];
  feedBrandItemIds: TDropdownValue[];
};

export const defaultFormClaimFilter: TFormClaimFilter = {
  orderDateStart: "",
  orderDateEnd: "",
  deliveryDateStart: "",
  deliveryDateEnd: "",
  feedBrandId: [],
  feedBrandItemIds: [],
};

import CloseIcon from "@mui/icons-material/Close";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Button, IconButton, Modal } from "@mui/material";
import dayjs from "dayjs";
import React, { useCallback, useState } from "react";
import DateRangePickerPopover from "../../../../admin/components/Input/DateRangePickerPopover/DateRangePickerPopover";
import {
  defaultFormFilterModalHistory,
  TFormFilterModalHistory,
} from "../defaultFormHistory";

const FILTER_DATE_FORMAT = "YYYY-MM-DD";

interface IFilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  onReset?: () => void;
  title: string;
  form: TFormFilterModalHistory;
  setForm: (form: TFormFilterModalHistory) => void;
  onChangeForm: (key: keyof TFormFilterModalHistory) => (value: any) => void;
}

function FilterModal({
  title,
  isOpen,
  onClose,
  onReset,
  form,
  setForm,
  onChangeForm,
}: IFilterModalProps) {
  const [startCalendarDate, setStartCalendarDate] = useState("");
  const [endCalendarDate, setEndCalendarDate] = useState("");

  const refCalendarDateRange =
    React.useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);

  const [isOpenCalender, setIsOpenCalender] = useState<boolean>(false);

  const handleSelect = (dateRange: any[]) => {
    const formattedStartDate = dayjs(
      dateRange[0].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    const formattedEndDate = dayjs(
      dateRange[1].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    setStartCalendarDate(formattedStartDate);
    setEndCalendarDate(formattedEndDate);
  };

  const handleCloseCalender = () => {
    setIsOpenCalender(false);
  };
  const handleCalenderSave = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: any[]
  ) => {
    event?.stopPropagation();
    const formattedStartDate = dayjs(value[0].startDate).format(
      FILTER_DATE_FORMAT
    );
    const formattedEndDate = dayjs(value[0].endDate).format(FILTER_DATE_FORMAT);
    setStartCalendarDate(formattedStartDate);
    setEndCalendarDate(formattedEndDate);
  };

  const onResetFn = useCallback(() => {
    onReset && onReset();
    onClose();
    setStartCalendarDate("");
    setEndCalendarDate("");
    setForm(defaultFormFilterModalHistory);
  }, []);

  const onConfirm = () => {
    onChangeForm("startDate")(startCalendarDate);
    onChangeForm("endDate")(endCalendarDate);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      disableEnforceFocus={false}
      disableAutoFocus={true}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "313px",
          minWidth: "313px",
          bgcolor: "#000",
          borderRadius: "40px",
          boxShadow: 24,
        }}
      >
        <div className="flex flex-col relative px-6 pt-4 pb-6 bg-white w-full h-full rounded-[40px]">
          <div className="flex flex-row gap-2">
            <FilterListIcon
              sx={{
                color: "#68C184",
                fontWeight: 500,
                alignSelf: "center",
                height: "100%",
              }}
            />
            <span className="text-secondary font-medium text-2xl h-full self-center pt-1">
              {title || "ตัวกรอง"}
            </span>
          </div>
          <div className="absolute right-6 top-4">
            <IconButton aria-label="close" onClick={onResetFn}>
              <CloseIcon sx={{ color: "#000" }} />
            </IconButton>
          </div>
          <div className="flex flex-col h-full justify-start pt-6 gap-6">
            <div className="flex flex-col w-full">
              <span className="text-tertiary font-semibold text-xl pb-3">
                ช่วงวันที่
              </span>
              <Button
                variant="outlined"
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  e.stopPropagation();
                  refCalendarDateRange.current?.onOpen(e);
                }}
                endIcon={<EventOutlinedIcon />}
                sx={{
                  height: "2rem",
                  borderRadius: "10px !important",
                  minWidth: "132px",
                  padding: "10px",
                  border: "1px solid #68C184",
                  color: "#68C184",
                  bgcolor: "#F0FDF4",
                  justifyContent: "space-between",
                  width: "fit-content",
                  textAlign: "center",
                  fontWeight: "normal",
                  fontSize: "1rem",
                }}
                fullWidth={false}
              >
                เริ่มต้น - สิ้นสุด
              </Button>
              {startCalendarDate || endCalendarDate ? (
                <span className="text-sm font-normal text-secondary pt-2">
                  {dayjs(startCalendarDate).format("DD/MM/YYYY")} -{" "}
                  {dayjs(endCalendarDate).format("DD/MM/YYYY")}
                </span>
              ) : null}
            </div>
          </div>
          <div className="flex w-full gap-2 pt-6">
            <Button
              variant="outlined"
              sx={{
                border: "1px solid #68C184",
                color: "#68C184",
                ":active": {
                  border: "1px solid #68C184",
                  bgcolor: "#68C184",
                  color: "#FFFFFF",
                },
                ":hover": {
                  border: "1px solid #68C184",
                  bgcolor: "#68C184",
                  color: "#FFFFFF",
                },
                height: "37px",
              }}
              fullWidth
              onClick={onResetFn}
            >
              {"ตั้งค่าเริ่มต้น"}
            </Button>
            <Button
              sx={{
                bgcolor: "#68C184",
                color: "#FFFFFF",
                ":active": {
                  border: "1px solid #68C184",
                  bgcolor: "#FFFFFF",
                  color: "#68C184",
                },
                ":hover": {
                  border: "1px solid #68C184",
                  bgcolor: "#FFFFFF",
                  color: "#68C184",
                },
                height: "37px",
              }}
              fullWidth
              onClick={() => {
                onConfirm();
                onClose();
              }}
            >
              {"นำไปใช้"}
            </Button>
          </div>
        </div>
        <DateRangePickerPopover
          ref={refCalendarDateRange}
          id={"calendar-range-popover"}
          handleSelect={handleSelect}
          openCalender={isOpenCalender}
          onClose={handleCloseCalender}
          handleCalenderSave={handleCalenderSave}
          startDate={startCalendarDate}
          endDate={endCalendarDate}
        />
      </Box>
    </Modal>
  );
}

export default FilterModal;

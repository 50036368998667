import { AUTH_TOKEN_KEY } from "../const/app.constant";
import api from "./ApiService";

export function addFarmPost(data: any) {
  return api.post("/farm", data);
}

export function editFarmPut(data: any) {
  return api.put("/farm", data);
}

export function farmDelete(farmId: string) {
  return api.delete(`/farm/${farmId}`);
}

export function getFeed(weight: number) {
  let config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem(AUTH_TOKEN_KEY),
    },
  };
  return api.get(`/feeds?weight=${weight}`, config);
}
export function checkFarmAddress(formData: any) {
  return api.post("/check-farm-address", formData);
}

export function checkFarmAddressEdit(formData: any, farmId: string) {
  return api.post(`/check-farm-address/${farmId}`, formData);
}

export function getAllFarms() {
  return api.get("/farms");
}

export function getFarmDetail(farmId: string) {
  return api.get(`/farm/${farmId}`);
}

export function getAllHousesOfFarm(farmId: string) {
  return api.get(`/houses?farmId=${farmId}`);
}

export function checkHouseName(body: any) {
  return api.post("/check-house-name", body);
}

export function getHouseDetail(houseId: string) {
  return api.get(`/house/${houseId}`);
}

export function getHouseDashboardHouseStatus(houseId: string) {
  return api.get(`/house-status/${houseId}`);
}

export function getFarmDashboardStatus(farmId: string) {
  return api.get(`/farm-status/${farmId}`);
}

export function getHouseActivityCalendar(
  farmId: string,
  year: number,
  month: number
) {
  return api.get(
    `house-activity-calendar/${farmId}?year=${year}&month=${month}`
  );
}

export function getFarmOverviewService() {
  return api.get(`/farms/overview?days=7`);
}
export function getDeadPigData() {
  return api.get(`farmers/dead-pig-count`);
}
export function getAllEdible() {
  return api.get("/all-edible");
}

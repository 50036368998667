import { memo } from "react";
import { tw } from "../../../../../../utils/tw";
import { Accordion, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface IAccordionFillData {
  accordionTitle: string;
  showData: {
    label: string;
    value: string;
  }[];
}

const classes = {
  row: tw(
    `w-full flex flex-col md:flex-row item-start justify-between gap-x-3 pt-6`
  ),
  row2: tw(
    `w-full flex flex-col md:flex-row item-start justify-between gap-x-3 pt-2`
  ),
  space: tw(`h-3`),
  space2: tw(`h-6`),
  container: tw(
    `w-[100vw] max-w-[1247px] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-[#7E7E7E] font-medium text-[14px] font-sans`),
  text: tw(`text-[#1B2128] font-bold text-[14px] font-sans`),
};

export const AccordionFillData = memo((props: IAccordionFillData) => {
  const { accordionTitle, showData } = props;

  return (
    <Accordion
      sx={{
        margin: "0px !important",
        boxShadow: "none",
        width: "100%",
        ":before": {
          backgroundColor: "transparent",
        },
      }}
      defaultExpanded
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
        className="text-[14px] font-bold"
        style={{
          color: "#FFFFFF",
        }}
        sx={{
          minHeight: "36px !important",
          height: "36px !important",
          borderRadius: "10px !important",
          backgroundColor: "#68C184",
        }}
      >
        {accordionTitle}
      </AccordionSummary>
      <div className="w-full bg-[#FFFFFF]">
        <div className={classes.space2}></div>
        <div className="grid grid-cols-4 w-full gap-6 gap-y-[24px]">
          {showData
            .filter((row) => row.value)
            .map(({ label, value }, index) => {
              return (
                <div
                  key={index + label + value}
                  className="flex flex-col gap-3"
                >
                  <div className={classes.title}>{label}</div>
                  <div className={classes.text}>{value}</div>
                </div>
              );
            })}
        </div>
      </div>
    </Accordion>
  );
});

import adminAPI from "../../../../services/AdminApiService";
import { IGetCreditInformation } from "../types/creditInformation";
import { IGetFarmDetail } from "../types/farmDetailTable.type";
import { IGetFarmInformation } from "../types/farmInformation.type";
import {
  IGetPerformanceInfo,
  TExportExcelLogs,
} from "../types/performanceInformation.type";
import {
  IGetPersonalInformation,
  TPersonalInformationForm,
} from "../types/personalInformations.type";

export const getFarmDetailTable = async (searchParams?: URLSearchParams) => {
  try {
    const { data } = await adminAPI.get<IGetFarmDetail>(
      `/farm-detail?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getPersonalInformation = async (personalId: string) => {
  try {
    const { data } = await adminAPI.get<IGetPersonalInformation>(
      `/farm-detail/${personalId}/personal-info`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const putPersonalInformation = async (
  personalId: string,
  body: TPersonalInformationForm
) => {
  return adminAPI.put(`farm-detail/${personalId}/personal-info`, body);
};

export const getFarmInformation = async (farmId: string) => {
  try {
    const { data } = await adminAPI.get<IGetFarmInformation>(
      `/farm-detail/${farmId}/farm-info`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getPerformanceInformation = async (farmId: string) => {
  try {
    const { data } = await adminAPI.get<IGetPerformanceInfo>(
      `/farm-detail/${farmId}/performance-info`
    );
    return data;
  } catch (error) {
    return null;
  }
};
export const getCreditInformation = async (farmId: string) => {
  try {
    const { data } = await adminAPI.get<IGetCreditInformation>(
      `/farm-detail/${farmId}/credit-info`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getCalculateCredit = async (farmId: string) => {
  try {
    const { data } = await adminAPI.get<IGetCreditInformation>(
      `/farm-detail/${farmId}/calculate-credit-info`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getExportExcelDailyLog = async (
  farmId: string,
  houseId: string
) => {
  try {
    const { data } = await adminAPI.get<TExportExcelLogs>(
      `/farm-detail/${farmId}/daily-log/${houseId}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getExportExcelDailyLogFarmer = async (farmId: string) => {
  try {
    const { data } = await adminAPI.get<TExportExcelLogs>(
      `farm-detail/${farmId}/daily-log/`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const postExportDailyLogFarmDetail = async (
  pathParam: string,
  ids: string[]
) => {
  try {
    const { data } = await adminAPI.post(`/farm-detail/export/${pathParam}`, {
      ids,
    });
    return data;
  } catch (error) {
    return null;
  }
};

export const putFarmerCredit = async (body: {
  id: string;
  creditInformation: {
    farmSize: string;
    houseId: string;
    creditAmount: number;
    contractType: string;
    creditPeriod: number;
    reason: string;
    files?: {
      id: string;
      fileName: string;
      fileSize: number;
    }[];
  };
  creditSettingId: string;
  farmerType: string;
  generalPersonalInformation: { id: string; score: number }[] | [];
  generalLegalInformation: { id: string; score: number }[] | [];
  generalFarmInformation: { id: string; score: number }[] | [];
  farmPerformance: { id: string; score: number }[] | [];
  farmFinancialStatusAndInstallment: { id: string; score: number }[] | [];
  applicationUsage: { id: string; score: number }[] | [];
}) => {
  const { id, ...props } = body;
  const { data } = await adminAPI.put(`/farm-detail/${id}/credit-info`, props);
  return data;
};

export const calculateFarmerCredit = async (body: {
  id: string;
  creditInformation: {
    farmSize: string;
    houseId: string;
    creditAmount: number;
    contractType: string;
    creditPeriod: number;
    reason: string;
  };
  creditSettingId: string;
  farmerType: string;
  generalPersonalInformation: { id: string; score: number }[] | [];
  generalLegalInformation: { id: string; score: number }[] | [];
  generalFarmInformation: { id: string; score: number }[] | [];
  farmPerformance: { id: string; score: number }[] | [];
  farmFinancialStatusAndInstallment: { id: string; score: number }[] | [];
  applicationUsage: { id: string; score: number }[] | [];
}) => {
  const { id, ...props } = body;
  const { data } = await adminAPI.put(
    `/farm-detail/${id}/calculate-credit-info`,
    props
  );
  return data;
};

import { memo, useCallback, useState } from "react";
import { Modal } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { tw } from "../../../../../utils/tw";
import AppBarFS from "../../../../../components/app-bar/AppBar";
import "swiper/css";
import "swiper/css/pagination";
import PreviewAndZoomImageCarouselModal from "../../../../../components/common/ViewImageAndPreviewCarousel/PreviewAndZoomImageCarouselModal";
import ZoomImageSvg from "../../../../../assets/svg/ZoomImage";
import { useNavigate, useParams } from "react-router-dom";
import { IGetMyOrderByID } from "../../../../../types/MyOrderFeed.type";

type TImage = { id: string; url: string };

interface IViewSummaryModalProps {
  isOpen: boolean;
  onClose: () => void;
  images: (TImage | File | string)[];
  orderDetail?: IGetMyOrderByID;
}

const classes = {
  wrapper: tw(`overflow-scroll`),
  container: tw(`min-h-screen w-full flex flex-col bg-[white] pb-[24px]`),
  appbarContainer: tw(`py-4`),
  headerContainer: tw(
    `mb-[16px] px-[16.5px] text-[18px] leading-[21.6px] font-semibold flex flex-row items-center justify-between`
  ),
  label: tw(
    `border-[1px] border-[#2C702F] rounded-full font-normal flex justify-center items-center text-[14px] leading-[19.6px] text-[#2C702F] py-[2px] px-[8px]`
  ),
  swiperWrapper: tw(`[&_.swiper-wrapper]:items-center`),
  swiperContainer: tw(`px-[45px] relative pb-[24px]`),
  swiperImage: tw(`w-full h-auto object-contain max-w-[450px] m-auto`),
  danger: tw(
    `text-status-bad font-semibold text-[14px] leading-[21px] whitespace-pre-line text-center`
  ),
  actionContainer: tw(
    `flex flex-col gap-[16px] mt-[16px] max-w-[450px] px-[16px] w-full mx-auto`
  ),
  button: tw(
    `bg-secondary border-[1px] border-secondary flex justify-center items-center text-center text-[white] text-[16px] leading-[25.6px] font-bold w-full py-[16.5px] rounded-full hover:brightness-105 duration-150 transition-all`
  ),
};

const ViewSummaryModal = (props: IViewSummaryModalProps) => {
  const { isOpen, onClose, images, orderDetail } = props;

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [slideIndex, setSlideIndex] = useState<number>(0);

  const blobToBase64 = (blobUrl: string) => {
    return fetch(blobUrl)
      .then((response) => response.blob())
      .then((blob) => {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const result = reader.result as string;
            resolve(result); // base64 ที่มีข้อมูล data URI ทั้งหมด
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });
  };

  // ฟังก์ชันหลักที่ใช้ดาวน์โหลดภาพ
  const onDownload = useCallback(
    async (base64: string | undefined) => {
      if (base64) {
        const a = document.createElement("a") as any;
        a.href = base64;
        a.download = (images as any)[slideIndex].name || "image"; // กำหนดชื่อไฟล์
        a.click();
      }
    },
    [images, slideIndex] // รวม dependencies ที่จำเป็น
  );

  // ฟังก์ชันที่เรียกใช้การดาวน์โหลด
  const handleDownload = useCallback(async () => {
    const image = images[slideIndex];

    if (typeof image === "string") {
      if (image.startsWith("blob:")) {
        // แปลง blob URL เป็น base64 ก่อนดาวน์โหลด
        const base64 = await blobToBase64(image);
        onDownload(base64);
      } else {
        // ถ้าเป็น URL หรือ base64 string ให้ดาวน์โหลดได้เลย
        onDownload(image);
      }
    } else if (image instanceof File) {
      // ถ้าเป็น File ให้แปลงเป็น base64
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result as string;
        onDownload(base64);
      };
      reader.readAsDataURL(image);
    } else {
      // กรณี TImage ถ้ามี URL ที่เป็น blob ก็แปลงเป็น base64
      if (image.url.startsWith("blob:")) {
        const base64 = await blobToBase64(image.url);
        onDownload(base64);
      } else {
        onDownload(image.url);
      }
    }
  }, [images, slideIndex, onDownload]);

  const onCloseFn = useCallback(() => {
    onClose();
    setSlideIndex(0);
  }, []);

  return (
    <Modal open={isOpen} className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.appbarContainer}>
          <AppBarFS title="หลักฐานการจัดส่ง" onIconPress={onCloseFn} />
        </div>
        <div className={classes.headerContainer}>
          หมายเลขสั่งซื้อ {orderDetail?.result.orderNumber}
          <div className={classes.label}>จัดส่งสำเร็จ</div>
        </div>
        <Swiper
          pagination
          modules={[Pagination]}
          loop
          className={classes.swiperWrapper}
          initialSlide={slideIndex}
          onActiveIndexChange={(event) => {
            setSlideIndex(event.realIndex);
          }}
        >
          {images.map((image, index) => {
            const src =
              image instanceof File
                ? URL.createObjectURL(image)
                : typeof image === "object" && "url" in image
                ? image.url
                : image;

            return (
              <SwiperSlide
                key={`${index}`}
                className={classes.swiperContainer}
                onClick={() => setIsOpenModal(true)}
              >
                <div className="absolute top-[45%] bottom-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2">
                  <div className="w-8 h-8">
                    <ZoomImageSvg />
                  </div>
                </div>
                <img src={src} className={classes.swiperImage} />
              </SwiperSlide>
            );
          })}
        </Swiper>
        <div className="flex flex-1" />
        <div className={classes.danger}>
          {`หากไม่ได้กดบันทึกเอกสารการขาย\nคุณสามารถกลับมาดูย้อนหลังได้ที่เมนูประวัติคำสั่งซื้อ`}
        </div>
        <div className={classes.actionContainer}>
          <button
            onClick={async () => {
              if (images.length > 0) {
                handleDownload();
              }
            }}
            className={tw(classes.button)}
          >
            บันทึกรูปภาพ
          </button>
          <button
            onClick={onCloseFn}
            className={tw(classes.button, "bg-[white] text-secondary")}
          >
            ปิด
          </button>
        </div>
        <PreviewAndZoomImageCarouselModal
          isOpen={isOpenModal}
          initIndex={slideIndex}
          onClose={() => {
            setIsOpenModal(false);
          }}
          images={images}
        />
      </div>
    </Modal>
  );
};

export default memo(ViewSummaryModal);

import React, { useEffect } from "react";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { InputAdornment, MenuItem } from "@mui/material";
import { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import DatePickerFS from "../../../components/common/DatePicker";
import TextInputFS from "../../../components/common/Input/TextInput";
import { Farm } from "../../../types";
import { House } from "../../../types/House.type";
import { formError, formErrorMessage } from "../../../utils/form-validator";

interface BasicDetailDailyLogProps {
  formik: any;
  maxDate: Dayjs;
  minDate: Dayjs;
  farms: any[];
  houses: any[];
  formDisabled?: boolean;
  handleDateChange?: (val: Dayjs | null) => void;
  handleFarmChange: (val: any) => void;
  selectedHouse?: string;
  selectedFarm?: string;
  // handleChange: (val: any) => void;
}

export default function BasicDetailDailyLog({
  farms,
  houses,
  formik,
  maxDate,
  minDate,
  formDisabled,
  handleDateChange,
  handleFarmChange,
}: BasicDetailDailyLogProps) {
  useEffect(() => {
    if (formik.values.farmId) {
      handleFarmChange(formik.values.farmId);
    }
  }, [formik.values.farmId]);
  const { t, i18n } = useTranslation();
  return (
    <div className="flex flex-col gap-4 ">
      <DatePickerFS
        // format={i18n.language === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"}
        format={"DD/MM/YYYY"}
        value={formik?.values?.date}
        maxDate={maxDate}
        minDate={minDate}
        disabled={formDisabled}
        slotProps={{
          textField: {
            helperText: formErrorMessage(formik, "date"),
            error: formError(formik, "date"),
            InputProps: {
              endAdornment: (
                <InputAdornment position="start">
                  <EventOutlinedIcon
                    color={(!formDisabled && "info") || "disabled"}
                  />
                </InputAdornment>
              ),
              startAdornment: formDisabled && (
                <InputAdornment position="start">
                  <LockOutlinedIcon color="disabled" fontSize="small" />
                </InputAdornment>
              ),
            },
          },
        }}
        label={t("dailyLog.dateLabel")}
        onChange={(value) => handleDateChange?.(value)}
      />

      <TextInputFS
        select
        fullWidth
        helperText={formErrorMessage(formik, "farmId")}
        error={formError(formik, "farmId")}
        disabled={formDisabled}
        placeholder={t("addHouseDetail.farmNamePlaceholder") || ""}
        label={formik.values.farmId ? "" : t("addHouseDetail.farmNameLabel")}
        name="farmId"
        id="farmId"
        onChange={(e) => handleFarmChange(e.target.value)}
        value={formik?.values?.farmId || null}
        InputProps={{
          startAdornment: formDisabled && (
            <InputAdornment position="start">
              <LockOutlinedIcon color="disabled" fontSize="small" />
            </InputAdornment>
          ),
        }}
      >
        {farms?.map((option: Farm) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextInputFS>

      <TextInputFS
        select
        fullWidth
        helperText={formErrorMessage(formik, "houseId")}
        error={formError(formik, "houseId")}
        disabled={formDisabled}
        value={formik?.values?.houseId || null}
        name="houseId"
        id="houseId"
        label={t("dailyLog.houseNamelabel")}
        {...formik.getFieldProps("houseId")}
        InputProps={{
          startAdornment: formDisabled && (
            <InputAdornment position="start">
              <LockOutlinedIcon color="disabled" fontSize="small" />
            </InputAdornment>
          ),
        }}
      >
        {houses?.map((option: House) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextInputFS>
    </div>
  );
}

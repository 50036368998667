import adminAPI from "../../../../../services/AdminApiService";
import { getDownloadUrlResponse } from "../../../../../services/admin/invoice/type";
import { IGetDocumentMoneyDebt } from "../types/documentFile.type";

export const getMoneyDebtSaleDocument = async (invoiceId: string) => {
  return adminAPI.get<IGetDocumentMoneyDebt>(
    `/money-debt/sale/${invoiceId}/document`
  );
};

export const getMoneyDebtLendingDocument = async (
  tabName: string,
  id: string
) => {
  return adminAPI.get<IGetDocumentMoneyDebt>(
    `/money-debt/lending/${tabName}/${id}/document`
  );
};

export const getMoneyDebtDownloadUrlAPI = async (
  roleName: string,
  invoiceId: string,
  documentType: string,
  fileId: string
) => {
  try {
    const response = await adminAPI.get<getDownloadUrlResponse>(
      `/money-debt/${roleName}/${invoiceId}/download/${documentType}/${fileId}`
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log("getReceiptDownloadUrlAPI", error);
  }
};

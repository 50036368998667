import CloseIcon from "@mui/icons-material/Close";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Button, IconButton, Modal } from "@mui/material";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import DatePickerPopover from "../../../../admin/components/Input/DatePickerPopover/DatePickerPopover";
import DateRangePickerPopover from "../../../../admin/components/Input/DateRangePickerPopover/DateRangePickerPopover";
import {
  Dropdown,
  TDropdownValue,
} from "../../../../admin/pages/group-area/views/Dropdown";
import i18next from "i18next";

const FILTER_DATE_FORMAT = "YYYY-MM-DD";

interface IFilterModalProps {
  labelDateRangeFilter?: string;
  isOpen: boolean;
  onClose: () => void;
  onReset?: () => void;
  onSubmit: (startDate: string) => void;
  title: string;
  startDate: string;
  setStartDate: (startDate: string) => void;
  form: TForm;
  setForm: (form: TForm) => void;
  onChangeForm: (key: keyof TForm) => (value: any) => void;
}

type TForm = {
  status: TDropdownValue[];
};

const defaultForm: TForm = {
  status: [],
};

function FilterModal({
  title,
  labelDateRangeFilter,
  isOpen,
  onClose,
  onReset,
  onSubmit,
  startDate,
  setStartDate,
  form,
  setForm,
  onChangeForm,
}: IFilterModalProps) {
  const refCalendarStartDate =
    React.useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);
  const handleSelectStartDate = (date?: Date) => {
    const formattedDate = dayjs(date).format(FILTER_DATE_FORMAT);
    setStartCalendarDate(formattedDate);
  };

  const [startCalendarDate, setStartCalendarDate] = useState(startDate);
  const [status, setStatus] = useState<TDropdownValue[]>(form.status);

  const onResetFn = useCallback(() => {
    onReset && onReset();
    onClose();
    setStartDate("");
    setForm(defaultForm);
    setStartCalendarDate("");
  }, []);

  const onConfirm = () => {
    onChangeForm("status")(status);
    setStartDate(startCalendarDate);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      disableEnforceFocus={false}
      disableAutoFocus={true}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "313px",
          minWidth: "313px",
          bgcolor: "#000",
          borderRadius: "40px",
          boxShadow: 24,
        }}
      >
        <div className="flex flex-col relative px-6 pt-4 pb-6 bg-white w-full h-full rounded-[40px]">
          <div className="flex flex-row gap-2">
            <FilterListIcon
              sx={{
                color: "#68C184",
                fontWeight: 500,
                alignSelf: "center",
                height: "100%",
              }}
            />
            <span className="text-secondary font-medium text-2xl h-full self-center pt-1">
              {title || "ตัวกรอง"}
            </span>
          </div>
          <div className="absolute right-6 top-4">
            <IconButton aria-label="close" onClick={onResetFn}>
              <CloseIcon sx={{ color: "#000" }} />
            </IconButton>
          </div>
          <div className="flex flex-col h-full justify-start pt-6 gap-6">
            <div className="flex flex-col w-full">
              <span className="text-tertiary font-semibold text-xl pb-3">
                สถานะ
              </span>
              <Dropdown
                options={[
                  { label: "กำลังจัดการสัญญา", value: "1" },
                  { label: "สัญญาไม่อนุมัติ", value: "2" },
                  { label: "ใช้งานสัญญา", value: "3" },
                  { label: "สิ้นสุดสัญญา", value: "4" },
                ]}
                onChange={(value) => setStatus(value)}
                renderValueArray={status}
                choseStyle2
                containerDropdown="pb-0"
              />
            </div>
          </div>
          <div className="flex flex-col h-full justify-start pt-6 gap-6">
            <div className="flex flex-col w-full">
              <span className="text-tertiary font-semibold text-xl pb-3">
                {labelDateRangeFilter || "ช่วงวันที่ปิดรุ่น"}
              </span>
              <Button
                variant="outlined"
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  e.stopPropagation();
                  refCalendarStartDate.current?.onOpen(e);
                }}
                endIcon={<EventOutlinedIcon />}
                sx={{
                  height: "2rem",
                  borderRadius: "10px !important",
                  minWidth: "132px",
                  padding: "10px",
                  border: "1px solid #68C184",
                  color: "#68C184",
                  bgcolor: "#F0FDF4",
                  justifyContent: "space-between",
                  width: "fit-content",
                  textAlign: "center",
                  fontWeight: "normal",
                  fontSize: "1rem",
                }}
                fullWidth={false}
              >
                {"เริ่มต้น"}
              </Button>

              {startCalendarDate || startDate ? (
                <span className="text-sm font-normal text-secondary pt-2">
                  {/* {dayjs(startCalendarDate || startDate)
                    .locale(i18next.language)
                    .format(
                      i18next.language === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                    )} */}
                  {dayjs(startCalendarDate || startDate)
                    .locale(i18next.language)
                    .format("DD/MM/YYYY")}
                </span>
              ) : null}
            </div>
          </div>
          <div className="flex w-full gap-2 pt-6">
            <Button
              variant="outlined"
              sx={{
                border: "1px solid #68C184",
                color: "#68C184",
                ":active": {
                  border: "1px solid #68C184",
                  bgcolor: "#68C184",
                  color: "#FFFFFF",
                },
                ":hover": {
                  border: "1px solid #68C184",
                  bgcolor: "#68C184",
                  color: "#FFFFFF",
                },
                height: "37px",
              }}
              fullWidth
              onClick={onResetFn}
            >
              {"ตั้งค่าเริ่มต้น"}
            </Button>
            <Button
              sx={{
                bgcolor: "#68C184",
                color: "#FFFFFF",
                ":active": {
                  border: "1px solid #68C184",
                  bgcolor: "#FFFFFF",
                  color: "#68C184",
                },
                ":hover": {
                  border: "1px solid #68C184",
                  bgcolor: "#FFFFFF",
                  color: "#68C184",
                },
                height: "37px",
              }}
              fullWidth
              onClick={() => {
                onConfirm();
                onClose();
              }}
            >
              {"นำไปใช้"}
            </Button>
          </div>
        </div>
        <DatePickerPopover
          ref={refCalendarStartDate}
          id={"date-picker-start-date"}
          date={startCalendarDate}
          handleCalenderSave={(value) => handleSelectStartDate(value)}
        />
      </Box>
    </Modal>
  );
}

export default FilterModal;

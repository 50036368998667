export interface JWTPayload {
  isLoggedIn: boolean;
  type: string;
  value: string;
  exp: number;
}

export function decodeJWTToken(token: string | null): JWTPayload | null {
  if (!token) {
    return null;
  }
  const [firstPart, encodedToken, signedPart] = token?.split(".");
  if (!encodedToken) {
    return null;
  } else {
    const decoded = window.atob(encodedToken);
    if (!decoded) {
      return null;
    }
    try {
      return JSON.parse(decoded) as JWTPayload;
    } catch (err) {
      return null;
    }
  }
}

import { useNavigate } from "react-router-dom";
import AppBarFS from "../../../components/app-bar/AppBar";
import { useCallback, useMemo, useState } from "react";
import { IconButton } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import FenceSVG from "../../../assets/svg/Fence";
import { useFinancialController } from "../controller";
import dayjs from "dayjs";
import { IContracts } from "../../../types/Financial.type";
import FilterModal from "./views/FilterModal";
import { TDropdownValue } from "../../../admin/pages/group-area/views/Dropdown";
import i18next from "i18next";

type TForm = {
  status: TDropdownValue[];
};

const defaultForm: TForm = {
  status: [],
};

const FinancialHistory = () => {
  let navigate = useNavigate();
  const { data } = useFinancialController();

  const [form, setForm] = useState<TForm>(defaultForm);

  const onChangeForm = useCallback(
    (key: keyof TForm) => (value: any) => {
      setForm((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const [startDate, setStartDate] = useState("");

  const handleBack = useCallback(() => {
    navigate("/financial-support");
  }, []);

  const handleClickToShowHistory = useCallback(
    (data: IContracts) => {
      navigate("/financial-support/financial-history/show-history", {
        state: {
          data: data,
        },
      });
    },
    [navigate]
  );

  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);

  const onOpenFilterModal = useCallback(() => setIsOpenFilterModal(true), []);
  const onHideFilterModal = useCallback(() => setIsOpenFilterModal(false), []);

  const dataActive = useMemo(() => {
    const result = data;
    const startDayTimeStamp = dayjs(startDate).startOf("days").valueOf();
    if (startDate && !form.status[0]) {
      return result.filter(({ startDate: _startDate }) => {
        return dayjs(_startDate).startOf("days").valueOf() >= startDayTimeStamp;
      });
    } else if (form.status[0] && !startDate) {
      const check = form.status[0].value;
      if (check === "1") {
        return result.filter(({ contractStatus, isActive }) => {
          return contractStatus === "processing" && isActive === false;
        });
      } else if (check === "2") {
        return result.filter(({ contractStatus, isActive }) => {
          return contractStatus === "denied" && isActive === false;
        });
      } else if (check === "3") {
        return result.filter(({ contractStatus, isActive }) => {
          return contractStatus === "approved" && isActive === true;
        });
      } else if (check === "4") {
        return result.filter(({ contractStatus, isActive }) => {
          return contractStatus === "approved" && isActive === false;
        });
      }
    } else if (startDate && form.status[0]) {
      const check = form.status[0].value;
      const startDayTimeStamp = dayjs(startDate).startOf("days").valueOf();
      if (check === "1") {
        return result.filter(
          ({ contractStatus, isActive, startDate: _startDate }) => {
            return (
              contractStatus === "processing" &&
              isActive === false &&
              dayjs(_startDate).startOf("days").valueOf() >= startDayTimeStamp
            );
          }
        );
      } else if (check === "2") {
        return result.filter(
          ({ contractStatus, isActive, startDate: _startDate }) => {
            return (
              contractStatus === "denied" &&
              isActive === false &&
              dayjs(_startDate).startOf("days").valueOf() >= startDayTimeStamp
            );
          }
        );
      } else if (check === "3") {
        return result.filter(
          ({ contractStatus, isActive, startDate: _startDate }) => {
            return (
              contractStatus === "approved" &&
              isActive === true &&
              dayjs(_startDate).startOf("days").valueOf() >= startDayTimeStamp
            );
          }
        );
      } else if (check === "4") {
        return result.filter(
          ({ contractStatus, isActive, startDate: _startDate }) => {
            return (
              contractStatus === "approved" &&
              isActive === false &&
              dayjs(_startDate).startOf("days").valueOf() >= startDayTimeStamp
            );
          }
        );
      }
    } else return result;
  }, [data, startDate, form]);

  return (
    <div className="bg-[#d9ebfe] w-full min-h-full">
      <div className="w-full h-full">
        <div className="h-[120px] flex items-center bg-[#FFFF] rounded-b-[40px]">
          <AppBarFS title={"ประวัติของสัญญาทั้งหมด"} onIconPress={handleBack} />
        </div>
        <div className="flex flex-row py-[16px] px-4 justify-between">
          <span className="font-semibold text-[20px] text-[#3777BC] flex flex-col justify-center">
            สัญญาทั้งหมด
          </span>
          <div className="h-full flex gap-2 items-center">
            <span className="text-secondary font-normal ml-1 text-[1rem] flex flex-col items-center">
              กรองโดย
            </span>
            <IconButton
              aria-label="filter"
              sx={{
                color: "#68C184",
                border: "1px solid #68C184",
                ":hover": {
                  color: "#FFFFFF",
                  border: "1px solid #68C184",
                  bgcolor: "#68C184",
                },
                ":active": {
                  color: "#FFFFFF",
                  border: "1px solid #68C184",
                  bgcolor: "#68C184",
                },
              }}
              onClick={onOpenFilterModal}
            >
              <FilterListIcon />
            </IconButton>
          </div>
        </div>
        {(dataActive || []).length > 0 ? (
          <>
            <div className="px-4 flex flex-col gap-4 pb-[37px]">
              {data.map((d, index) => {
                let statusContractType: any = {
                  pay_after_expire: "จ่ายหลังหมดสัญญา",
                  fill_credit: "จ่ายตาม invoice",
                };

                return (
                  <div
                    key={`history-${index}`}
                    className="bg-[#FFFF] rounded-[15px] px-4 pt-4 pb-6 flex flex-col gap-3"
                    onClick={() => handleClickToShowHistory(d)}
                  >
                    <div className="text-[#34407B] text-[16px] font-semibold flex flex-row gap-[11px]">
                      <FenceSVG />
                      {d.contractNumber}
                    </div>
                    <div className="flex flex-col gap-[6px] text-[#4B4B4B] text-[14px] font-medium leading-5">
                      <div className="flex flex-row justify-between">
                        <div className="">เลขที่สัญญา</div>
                        <div className="text-end">{d.contractNumber}</div>
                      </div>
                      <div className="flex flex-row justify-between">
                        <div className="">ประเภทสัญญา</div>
                        <div className="text-end">
                          {statusContractType[
                            d.creditInformation.contractType
                          ] || "-"}
                        </div>
                      </div>
                      <div className="flex flex-row justify-between">
                        <div className="">วันเริ่มสัญญา</div>
                        <div className="text-end">
                          {/* {dayjs(d.startDate).isValid()
                            ? dayjs(d.startDate)
                                .locale(i18next.language)
                                .format(
                                  i18next.language === "th"
                                    ? "DD/MM/BBBB"
                                    : "DD/MM/YYYY"
                                )
                            : "-"} */}
                          {dayjs(d.startDate).isValid()
                            ? dayjs(d.startDate)
                                .locale(i18next.language)
                                .format("DD/MM/YYYY")
                            : "-"}
                        </div>
                      </div>
                      <div className="flex flex-row justify-between">
                        <div className="">วันสิ้นสุดสัญญา</div>
                        <div className="text-end">
                          {/* {dayjs(d.expireDate).isValid()
                            ? dayjs(d.expireDate)
                                .locale(i18next.language)
                                .format(
                                  i18next.language === "th"
                                    ? "DD/MM/BBBB"
                                    : "DD/MM/YYYY"
                                )
                            : "-"} */}
                          {dayjs(d.expireDate).isValid()
                            ? dayjs(d.expireDate)
                                .locale(i18next.language)
                                .format("DD/MM/YYYY")
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className="w-full flex flex-row justify-center">
            ไม่มีข้อมูลสัญญา
          </div>
        )}
      </div>
      <FilterModal
        title="กรองโดย"
        labelDateRangeFilter="วันเริ่มสัญญา"
        isOpen={isOpenFilterModal}
        onClose={onHideFilterModal}
        onSubmit={() => {}}
        startDate={startDate}
        setStartDate={setStartDate}
        form={form}
        setForm={setForm}
        onChangeForm={onChangeForm}
      />
    </div>
  );
};

export default FinancialHistory;

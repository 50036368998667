export default function PersonSvg() {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.75" width="40" height="40" rx="20" fill="#68C184" />
      <mask
        id="mask0_17680_126479"
        maskUnits="userSpaceOnUse"
        x="9"
        y="9"
        width="23"
        height="22"
      >
        <rect x="9.75" y="9" width="22" height="22" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_17680_126479)">
        <path
          d="M20.7474 19.9993C19.7391 19.9993 18.8759 19.6403 18.1578 18.9223C17.4398 18.2042 17.0807 17.341 17.0807 16.3327C17.0807 15.3243 17.4398 14.4612 18.1578 13.7431C18.8759 13.025 19.7391 12.666 20.7474 12.666C21.7557 12.666 22.6189 13.025 23.337 13.7431C24.055 14.4612 24.4141 15.3243 24.4141 16.3327C24.4141 17.341 24.055 18.2042 23.337 18.9223C22.6189 19.6403 21.7557 19.9993 20.7474 19.9993ZM13.4141 27.3327V24.766C13.4141 24.2466 13.5477 23.7691 13.8151 23.3337C14.0825 22.8983 14.4377 22.566 14.8807 22.3368C15.828 21.8632 16.7905 21.508 17.7682 21.2712C18.746 21.0344 19.7391 20.916 20.7474 20.916C21.7557 20.916 22.7488 21.0344 23.7266 21.2712C24.7043 21.508 25.6668 21.8632 26.6141 22.3368C27.0571 22.566 27.4123 22.8983 27.6797 23.3337C27.947 23.7691 28.0807 24.2466 28.0807 24.766V27.3327H13.4141Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

import { Divider, Link, TableCell, TableRow } from "@mui/material";
import { tw } from "../../../../../utils/tw";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import MenuBar from "../../../../components/MenuBar/MenuBar";
import { useEffect, useState } from "react";
import CreditModal from "./Modal/CreditModal";
import TableCustom, {
  ITableCustomHeadCell,
  Order,
} from "../../../../components/Table/TableCustom";
import dayjs from "dayjs";
import {
  deleteCreditSetting,
  getMasterDataCredit,
  putToggleCreditSetting,
} from "../../../../../services/admin/masterdata/Credit.service";
import ChipOrderStatus from "../../../../components/Chip/ChipOrderStatus";
import ButtonOutlinedCustom from "../../../../components/Button/ButtonOutlinedCustom";
import Switch from "../../../../../components/Switch";
import { DeleteOutlinedIcon } from "../../../../../assets/svg/DeleteOutlined";
import ConfirmCustomModal from "../../../../components/Modal/ConfirmCustomModal";
import { useDispatch } from "react-redux";
import { loadingActions } from "../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../utils/getErrorMessage";
import PlusCircleIcon from "../../../../assets/svg/PlusCircleIcon.svg";

type Props = {};

const classes = {
  container: tw(`pt-16 w-full h-full relative`),
  mainContainer: tw(`pt-6 flex flex-col w-full`),
  rowContainer: tw(`flex flex-row w-full items-center justify-between`),
  title: tw(`text-black font-bold text-3xl`),
  subTitle: tw(`text-tertiary font-bold text-[22px] leading-[28.64px]`),
  titleHeader: tw(
    `py-[16px] px-[20px] bg-[#FBFBFB] font-sans rounded-t-[6px] text-[#534D59] font-bold text-[14px] leading-[18.23px] border-[#E4E4E4] border-[0.5px]`
  ),
  subTitleHeader: tw(
    `py-[16px] px-[20px] bg-[#FBFBFB] font-sans text-[#534D59] text-[14px] leading-[18.23px] border-[#E4E4E4] border-[0.5px] border-y-0`
  ),
  detail: tw(
    `text-secondary text-[14px] leading-[14px] tracking-[-5%] font-medium underline hover:brightness-110 cursor-pointer`
  ),
};
type TCreditStatus = "draft" | "active" | "inactive";
interface ICreditForm {
  id: string;
  isActive: boolean;
  status: TCreditStatus;
  name: string;
  createdAt: string;
  updatedAt: string;
}

function createData(
  id: string,
  isActive: boolean,
  status: TCreditStatus,
  name: string,
  createdAt: string,
  updatedAt: string
): ICreditForm {
  return {
    id,
    isActive,
    status,
    name,
    createdAt: createdAt ? dayjs(createdAt).format("DD/MM/YYYY") : "-",
    updatedAt: updatedAt ? dayjs(updatedAt).format("DD/MM/YYYY") : "-",
  };
}

const headCells: ITableCustomHeadCell<keyof ICreditForm>[] = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "50px",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "ชื่อหัวข้อ",
    width: "300px",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: false,
    label: "วันที่อัปเดตล่าสุด",
    width: "150px",
    isSorting: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "สถานะ",
    width: "100px",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "50px",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "50px",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "50px",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "20px",
  },
];

const PartnerStatusMap: Record<
  TCreditStatus,
  { color: string; bgColor: string; label: string }
> = {
  draft: {
    color: "#3777BC",
    bgColor: "#D9EBFE",
    label: "ฉบับร่าง",
  },
  active: {
    color: "#409261",
    bgColor: "#E9FFEF",
    label: "เปิดใช้งาน",
  },
  inactive: {
    color: "#3F3748",
    bgColor: "#E4E4E4",
    label: "ปิดใช้งาน",
  },
};

const CreditMasterData = (props: Props) => {
  const [isOpenCreditModal, setIsOpenCreditModal] = useState<boolean>(false);
  const [credit, setCredit] = useState<ICreditForm[]>([]);
  const [direction, setDirection] = useState<Order>("desc");
  const [selectCreditId, setSelectCreditId] = useState("");
  const [actionModal, setActionModal] = useState("");
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("direction", direction);
    setCredit([]);
    const data = await getMasterDataCredit(searchParams);
    if (data?.rows) {
      const newList = data.rows.map((row) => {
        return createData(
          row.id,
          row.isActive,
          row.status as TCreditStatus,
          row.name,
          row.createdAt,
          row.updatedAt
        );
      });
      setCredit(newList);
    }
  };

  useEffect(() => {
    handleFetch();
  }, [direction]);

  const handleSubmitDelete = async () => {
    try {
      dispatch(loadingActions.show({ message: "กำลังลบ..." }));
      await deleteCreditSetting(selectCreditId);
      dispatch(loadingActions.hide());
      dispatch(
        snakeActions.showMessage({
          message: "ลบสำเร็จ",
          type: "success",
        })
      );
      setIsOpenDeleteModal(false);
      handleFetch();
    } catch (error) {
      dispatch(loadingActions.hide());
      dispatch(
        snakeActions.showMessage({
          message: getErrorMessage(error),
          type: "error",
        })
      );
      setIsOpenDeleteModal(false);
    }
  };

  const handleToggleChangeStatus = async (creditId: string) => {
    try {
      await putToggleCreditSetting(creditId);
      dispatch(
        snakeActions.showMessage({
          message: "เปลี่ยนสถานะสำเร็จ",
          type: "success",
        })
      );
      handleFetch();
    } catch (error) {
      dispatch(
        snakeActions.showMessage({
          message: getErrorMessage(error),
          type: "error",
        })
      );
    }
  };

  return (
    <>
      <MenuBar>
        <div className={classes.container}>
          <div className="p-10">
            <div className="flex justify-between">
              <span className={classes.title}>Credit</span>
            </div>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className={classes.mainContainer}>
              <div
                className={tw(classes.rowContainer, "mb-[12px] justify-end")}
              >
                <ButtonFillCustom
                  title="เพิ่ม"
                  startIcon={<PlusCircleIcon />}
                  onClick={() => {
                    setActionModal("create");
                    setIsOpenCreditModal(true);
                    setSelectCreditId("");
                  }}
                />
              </div>
            </div>
            <TableCustom
              order={direction}
              orderBy={"updatedAt"}
              itemsSelected={[]}
              rowsPerPage={0}
              page={0}
              headerItems={headCells}
              rows={credit}
              onChangePage={() => {}}
              onChangeOrder={(val) => setDirection(val)}
              onChangeOrderBy={() => {}}
              onSelectItem={() => {}}
              onSelectAllClick={() => {}}
              totalItemSize={0}
              currentPageItems={[]}
              hidePaginator
              hideSelect
              rowChildren={credit.map((row, index) => {
                return (
                  <TableRow hover key={index}>
                    {row.status !== "draft" ? (
                      <TableCell padding="normal">
                        <Switch
                          checked={row.isActive}
                          onChange={async () => {
                            handleToggleChangeStatus(row.id);
                          }}
                        />
                      </TableCell>
                    ) : (
                      <TableCell padding="normal"></TableCell>
                    )}
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.updatedAt}
                    </TableCell>
                    <TableCell align="left">
                      <div className="flex flex-row w-full">
                        <ChipOrderStatus
                          bgColor={PartnerStatusMap[row.status]["bgColor"]}
                          fontColor={PartnerStatusMap[row.status].color}
                          label={PartnerStatusMap[row.status].label}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        sx={{
                          width: "80px",
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                        }}
                        onClick={() => {
                          setIsOpenCreditModal(true);
                          setActionModal("view");
                          setSelectCreditId(row.id);
                        }}
                      >
                        ดูรายละเอียด
                      </Link>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <ButtonOutlinedCustom
                        btnBorderColor="#68C184"
                        btnTextColor="#68C184"
                        title={"ทำซ้ำ"}
                        onClick={() => {
                          setIsOpenCreditModal(true);
                          setSelectCreditId(row.id);
                          setActionModal("repeat");
                        }}
                      />
                    </TableCell>
                    {row.status === "draft" ? (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        <ButtonFillCustom
                          title={"แก้ไข"}
                          onClick={() => {
                            setIsOpenCreditModal(true);
                            setSelectCreditId(row.id);
                            setActionModal("edit");
                          }}
                        />
                      </TableCell>
                    ) : (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      ></TableCell>
                    )}
                    {row.status !== "active" ? (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                        onClick={() => {
                          setIsOpenDeleteModal(true);
                          setSelectCreditId(row.id);
                        }}
                      >
                        <DeleteOutlinedIcon />
                      </TableCell>
                    ) : (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      ></TableCell>
                    )}
                  </TableRow>
                );
              })}
            />
          </div>
        </div>
      </MenuBar>
      <CreditModal
        isOpen={isOpenCreditModal}
        onClose={() => setIsOpenCreditModal(false)}
        selectCreditId={selectCreditId}
        action={actionModal}
        handleFetch={handleFetch}
      />
      <ConfirmCustomModal
        open={isOpenDeleteModal}
        onClose={() => setIsOpenDeleteModal(false)}
        title="ยืนยันการอนุมัติ"
        onSubmit={handleSubmitDelete}
        subtitleFirstRow="คุณต้องการลบรายการนี้ใช่หรือไม่?"
        subtitleSecondRow="การดำเนินการนี้ไม่สามารถยกเลิกได้"
        buttonPrimaryText={"ยืนยัน"}
        buttonSecondaryText={"ยกเลิก"}
      />
    </>
  );
};

export default CreditMasterData;

import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  IconButton,
  MenuItem,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AppBarFS from "../../../components/app-bar/AppBar";
import CustomButton from "../../../components/common/Button";
import TextInputFS from "../../../components/common/Input/TextInput";
import DailyLogContext from "../../../provider/DailyLogProvider/DailyLogContext";
import { loadingActions } from "../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../redux/store";
import {
  getMedicines2,
  postAddMedicines,
} from "../../../services/DailyLogService";
import { formError, formErrorMessage } from "../../../utils/form-validator";
import getErrorMessage from "../../../utils/getErrorMessage";
interface MedicineInfo {
  inputValue?: string;
  categoryId: string;
  medicineId: string;
  medicineName: string;
  // Add other properties if necessary
}

const EditSelectMedicine: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [selectedMethod, setSelectedMethod] = useState<
    "filter" | "search" | null
  >(null);
  const [medicineCategories, setMedicineCategories] = useState<
    { id: string; medicineDetails: any }[]
  >([]);
  const [medicineList, setMedicineList] = useState<string[]>([]);
  const [allMedicineData, setAllMedicineData] = useState<MedicineInfo[]>([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setDailyLogData, finalDailyLogData, setEditMedicine } =
    useContext(DailyLogContext);
  const [isClear, setIsClear] = useState<boolean>(false);
  const [selectedMedicine, setSelectedMedicine] = useState<any>(null);
  const [isSet, setIsSet] = useState<boolean>(true);

  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();

  const { data: listAllMedicines } = useQuery({
    queryKey: ["list_all_medicines_daily_log"],
    queryFn: async () => {
      const result = await getMedicines2();
      return result;
    },
  });

  const CreateMedicineMutation = useMutation({
    mutationFn: postAddMedicines,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["list_all_medicines_daily_log"],
      });
      setTimeout(() => {
        dispatch(loadingActions.hide());
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const formik = useFormik({
    initialValues: {
      medicinecategory: "",
      nameOfMedicine: "",
      searchInputMed: "",
      searchMedicine: {
        medicinecategory: "",
        nameOfMedicine: "",
        medicineId: "",
      },
    },
    // validationSchema: object({
    //     medicinecategory: string(),
    //     nameOfMedicine: string(),
    //     searchMedicine: object(),
    //     searchInputMed: string()
    // }),
    onSubmit: async (values) => {
      try {
        if (formik.values.medicinecategory && !formik.values.nameOfMedicine) {
          formik.setFieldError(
            "nameOfMedicine",
            `${t("treatment.medicineNameErrorMessage")}`
          );
          return false;
        }
        const updatednameOfMedicine: any = finalDailyLogData?.treatmentData
          ? [...finalDailyLogData?.treatmentData["medicineData"]]
          : [];
        const updatedMedicine: any = finalDailyLogData?.treatmentData
          ? [...finalDailyLogData?.treatmentData["nameOfMedicine"]]
          : [];
        const medicineCategory = listAllMedicines?.medicinCategories.find((i) =>
          i.medicineDetails.some(
            (val) =>
              i.nameEn + "-" + val.tradeName ===
              values.searchMedicine.nameOfMedicine
          )
        );
        const isMatch = !!medicineCategory;
        if (formik.values.medicinecategory && formik.values.nameOfMedicine) {
          if (updatednameOfMedicine.length > 0)
            updatednameOfMedicine[state.index] = values["nameOfMedicine"];
          else updatednameOfMedicine[state.id] = values["nameOfMedicine"];
          if (setDailyLogData)
            setDailyLogData({
              ...finalDailyLogData,
              treatmentData: {
                ...finalDailyLogData.treatmentData,
                medicineData: updatednameOfMedicine,
              },
            });
        } else {
          updatednameOfMedicine[state.id] =
            values["searchMedicine"]?.medicineId;

          if (setDailyLogData)
            setDailyLogData({
              ...finalDailyLogData,
              treatmentData: {
                ...finalDailyLogData.treatmentData,
                medicineData: updatednameOfMedicine,
                nameOfMedicine: updatedMedicine,
              },
            });
        }
        if (setEditMedicine) setEditMedicine(true);
        if (!isMatch) {
          CreateMedicineMutation.mutate({
            medicineCategoryId: +values.searchMedicine.medicinecategory || 16,
            tradeName: values.searchMedicine.nameOfMedicine,
            unit: " ",
          });
        }
        navigate("/daily-log-edit", {
          state: {
            selectedFarm: state?.selectedFarm,
            houseId: state?.houseId,
            logDate: state.logDate.format("YYYY-MM-DD"),
            isEdit: true,
            step: 3,
            data: {
              ...state.data,
              medicineData: updatednameOfMedicine,
              nameOfMedicine: updatedMedicine,
            },
          },
        });
      } catch (err) {
        console.error(err);
      }
    },
  });

  async function fetchAllMedicines() {
    try {
      // const { data } = await getMedicines();
      if (
        Array.isArray(listAllMedicines?.medicinCategories) &&
        listAllMedicines.medicinCategories?.length
      ) {
        setMedicineCategories(listAllMedicines.medicinCategories);
        setAllMedicineData(
          listAllMedicines.medicinCategories
            .map((ele: any) => {
              return ele.medicineDetails.map((val: any) => {
                return {
                  categoryId: ele.id,
                  medicineId: val.id,
                  medicineName: ele.nameEn + "-" + val.tradeName,
                };
              });
            })
            .flat()
        );
      }
    } catch (err) {
      console.error(err);
    }
  }

  const onSelectMedicinecategory = (
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    formik.setFieldValue("medicinecategory", e.target.value as string);
  };
  useEffect(() => {
    getmedicineList(formik?.values?.medicinecategory);
    if (formik?.values?.medicinecategory)
      if (state.data.medicineData[state.index])
        if (!isClear)
          formik.setFieldValue(
            "nameOfMedicine",
            state.data.medicineData[state.index]
          );
        else formik.setFieldValue("nameOfMedicine", null);
  }, [formik?.values?.medicinecategory, state]);

  const getmedicineList = (value: string) => {
    setMedicineList(
      medicineCategories.find((ele: any) => ele.id == value)?.medicineDetails ||
        []
    );
  };

  useEffect(() => {
    fetchAllMedicines();
  }, []);
  useEffect(() => {
    if (state.data.medicineData[state.index]?.toString() !== "8979") {
      if (allMedicineData) {
        if (state.data.medicineData[state.index]) {
          const cag = allMedicineData.find(
            (med: any) =>
              med.medicineId === state.data.medicineData[state.index].toString()
          )?.categoryId;
          formik.setFieldValue("medicinecategory", cag);
        }
      }
    } else {
      if (isSet)
        formik.setFieldValue("searchMedicine", {
          medicineCategories: "9798",
          medicineId: "8979",
          nameOfMedicine: state.data.nameOfMedicine[state.index],
        });
      setIsSet(false);
      setSelectedMedicine({
        categoryId: "9798",
        medicineId: "8979",
        medicineName: state.data.nameOfMedicine[state.index],
      });
      setSelectedMethod("filter");
    }
  }, [allMedicineData, state]);
  const handleSearchChanges = (e: any) => {
    const value = e.target.value;
    const newData = {
      categoryId: "9798",
      medicineId: "8979",
      medicineName: value,
    };
    formik.setFieldValue("medicinecategory", "");
    formik.setFieldValue("nameOfMedicine", "");
    formik.setFieldValue("searchMedicine", {
      medicineId: "9798",
      medicinecategory: "8979",
      nameOfMedicine: value,
    });
    setAllMedicineData([...allMedicineData, newData]);
  };

  const filter = createFilterOptions<MedicineInfo>();

  return (
    <div className="p-4 h-full  flex flex-col justify-between w-full">
      <AppBarFS
        title={t("treatment.selectMedicineHeader")}
        onIconPress={() =>
          navigate("/daily-log-edit", {
            state: {
              selectedFarm: state?.selectedFarm,
              houseId: state?.houseId,
              logDate: state.logDate.format("YYYY-MM-DD"),
              isEdit: true,
              step: 3,
              data: state.data,
            },
          })
        }
      />

      <form
        onSubmit={formik.handleSubmit}
        className="flex flex-col grow gap-4 justify-between"
      >
        <div className="flex flex-col gap-4 py-6">
          <div className="flex justify-between mx-[10px]">
            <span className="text-[#3777BC] text-[16px] font-[500]">
              {t("treatment.searchbyFilterLabel")}
            </span>
          </div>
          <TextInputFS
            fullWidth
            select
            disabled={selectedMethod === "filter"}
            helperText={formErrorMessage(formik, "medicinecategory")}
            error={formError(formik, "medicinecategory")}
            label={t("treatment.medicinecategory")}
            value={formik.values.medicinecategory || null}
            onChange={(e) => {
              onSelectMedicinecategory(e);
              setSelectedMethod("search");
            }}
            InputProps={{
              endAdornment:
                formik.values.medicinecategory &&
                formik.values.medicinecategory !== "9798" ? (
                  <IconButton
                    size="small"
                    onClick={() => {
                      formik.setFieldValue("medicinecategory", null);
                      formik.setFieldValue("nameOfMedicine", null);
                      setIsClear(true);
                      setSelectedMethod(null);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : undefined,
            }}
          >
            {medicineCategories?.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {i18n.language == "en" ? option?.nameEn : option?.nameTh}
              </MenuItem>
            ))}
          </TextInputFS>
          <TextInputFS
            fullWidth
            select
            disabled={!formik.values.medicinecategory}
            helperText={formErrorMessage(formik, "nameOfMedicine")}
            error={formError(formik, "nameOfMedicine")}
            label={t("treatment.nameOfMedicine")}
            {...formik.getFieldProps("nameOfMedicine")}
            InputProps={{
              endAdornment:
                medicineList?.find(
                  (med: any) =>
                    med.id === formik.values.nameOfMedicine?.toString()
                ) && formik.values.nameOfMedicine !== "8979" ? (
                  <IconButton
                    size="small"
                    onClick={() => {
                      formik.setFieldValue("nameOfMedicine", null);
                      setIsClear(true);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : undefined,
            }}
          >
            {medicineList?.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option?.tradeName}
              </MenuItem>
            ))}
          </TextInputFS>
          <div className="flex justify-center mx-[10px]">
            <span className="text-[#3777BC] text-[14px] font-[400]">
              {t("treatment.orLabel")}
            </span>
          </div>
          <div className="flex justify-between mx-[10px]">
            <span className="text-[#3777BC] text-[16px] font-[500]">
              {t("treatment.searchMedicineLabel")}
            </span>
          </div>

          <div className="search-medicine">
            {allMedicineData && (
              <div>
                <Autocomplete
                  fullWidth
                  freeSolo
                  disabled={selectedMethod === "search"}
                  componentsProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "flip",
                          enabled: false,
                        },
                        {
                          name: "preventOverflow",
                          enabled: true,
                        },
                      ],
                    },
                  }}
                  value={selectedMedicine || null}
                  defaultValue={{
                    categoryId: "",
                    medicineId: "",
                    medicineName: state.data.nameOfMedicine[state.index] || "",
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={allMedicineData}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.medicineName;
                  }}
                  filterOptions={(options: any, params: any) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;

                    const isExisting = options.some(
                      (option: any) => inputValue === option.medicineName
                    );

                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        medicineName: `เพิ่ม "${inputValue}"`,
                        categoryId: "",
                        medicineId: "",
                      });
                    }
                    return filtered;
                  }}
                  onChange={(event, newValue) => {
                    {
                      if (newValue === null) {
                        setSelectedMethod(null);
                        formik.setFieldValue("searchMedicine", {
                          medicineId: "",
                          medicinecategory: "",
                          nameOfMedicine: "",
                        });
                        setSelectedMedicine(null);
                      } else if (newValue && newValue.inputValue) {
                        setSelectedMethod("filter");
                        formik.setFieldValue("medicinecategory", "");
                        formik.setFieldValue("nameOfMedicine", "");
                        formik.setFieldValue("searchMedicine", {
                          medicineId: newValue.medicineId,
                          medicinecategory: newValue.categoryId,
                          nameOfMedicine: newValue.inputValue,
                        });
                        setSelectedMedicine(newValue);
                      } else {
                        setSelectedMethod("filter");
                        formik.setFieldValue("medicinecategory", "");
                        formik.setFieldValue("nameOfMedicine", "");
                        formik.setFieldValue("searchMedicine", {
                          medicineId: newValue.medicineId,
                          medicinecategory: newValue.categoryId,
                          nameOfMedicine: newValue.medicineName,
                        });
                        setSelectedMedicine(newValue);
                      }
                    }
                  }}
                  // onInput={handleSearchChanges}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <li
                        {...props}
                        key={`${option.categoryId}-${option.medicineId}`}
                      >
                        {option.medicineName}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment:
                          //   selectedMedicine ? (
                          //   <React.Fragment>
                          //     <IconButton
                          //       size="small"
                          //       onClick={() => {
                          //         setSelectedMethod(null);
                          //         formik.setFieldValue("searchMedicine", {
                          //           medicineId: "",
                          //           medicinecategory: "",
                          //           nameOfMedicine: "",
                          //         });
                          //         setSelectedMedicine(null);
                          //       }}
                          //     >
                          //       <ClearIcon />
                          //     </IconButton>
                          //     {params.InputProps.endAdornment}
                          //   </React.Fragment>
                          // ) : (
                          params.InputProps.endAdornment,
                        // ),
                      }}
                      label={t("treatment.searchMedicine")}
                    />
                  )}
                />
                <SearchIcon
                  style={{
                    color: "#3777BC",
                    transform: "translate(20px, -40px)",
                  }}
                />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-4 mb-4 ">
            <CustomButton
              disabled={
                !(
                  formik.values.medicinecategory && formik.values.nameOfMedicine
                ) && !formik.values.searchMedicine
              }
              variant="tertiary"
              label={t("treatment.confirmButtonLabel")}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditSelectMedicine;

const PencilAlt = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none">
    <path
      fill="#68C184"
      d="M21.645 3.103a2.4 2.4 0 0 0-3.394 0l-9.103 9.103V15.6h3.395l9.102-9.103a2.4 2.4 0 0 0 0-3.394Z"
    />
    <path
      fill="#68C184"
      fillRule="evenodd"
      d="M3.148 7.2a2.4 2.4 0 0 1 2.4-2.4h4.8a1.2 1.2 0 1 1 0 2.4h-4.8v12h12v-4.8a1.2 1.2 0 1 1 2.4 0v4.8a2.4 2.4 0 0 1-2.4 2.4h-12a2.4 2.4 0 0 1-2.4-2.4v-12Z"
      clipRule="evenodd"
    />
  </svg>
);

export default PencilAlt;

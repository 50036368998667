import dayjs, { locale } from "dayjs";
import { useState } from "react";
import { CalendarProps, DateRange } from "react-date-range";
import localeTh from "date-fns/locale/th";
import localeEn from "date-fns/locale/en-US";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useTranslation } from "react-i18next";
const DateRangePickers = ({
  handleSelect,
  openCalender,
  handleCloseCalender,
  handleCalenderSave,
}: any) => {
  const { t, i18n } = useTranslation();

  const [selectedDateRange, setSelectedDateRange] = useState<any[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  // Calculate the end date as 6 days from the start date
  const endDate = dayjs(selectedDateRange[0].startDate).toDate();

  // Handle changes to the date range
  const handleDateRangeChange = (item: any) => {
    setSelectedDateRange([
      {
        startDate: item.selection.startDate,
        endDate: endDate, // Keep the end date as calculated
        key: "selection",
      },
    ]);
  };
  // const onClickClear = () => {
  //     setSelectedDateRange({
  //         startDate: new Date(),
  //         endDate: new Date(),
  //         key: "selection"
  //     });
  //     setShow(false);
  // };

  const isThai = i18n.language === "th";

  const navigatorRenderer = (
    currFocusedDate: Date,
    changeShownDate: (
      value: Date | number | string,
      mode?: "set" | "setYear" | "setMonth" | "monthOffset"
    ) => void,
    props: CalendarProps
  ) => {
    const onNextMonth = () => {
      const nextMonth = dayjs(currFocusedDate).add(1, "month").toDate();
      changeShownDate(nextMonth, "set");
    };
    const onPrevMonth = () => {
      const prevMonth = dayjs(currFocusedDate).add(-1, "month").toDate();
      changeShownDate(prevMonth, "set");
    };
    const changeLocale = () => {
      i18n.changeLanguage(isThai ? "en" : "th");
    };
    return (
      <div className="flex justify-between mb-6 font-sans px-10 items-center">
        <span className="font-semibold">
          {/* {dayjs(currFocusedDate)
            .locale(i18n.language)
            .format(isThai ? "MMMM BBBB" : "MMMM YYYY")} */}
          {dayjs(currFocusedDate).locale(i18n.language).format("MMMM YYYY")}
        </span>
        {/* <button onClick={changeLocale}>Swap locale</button> */}
        <div className="flex justify-evenly gap-5 -mr-[18px]">
          <button
            onClick={onPrevMonth}
            className="bg-[#EFF2F7] h-[24px] w-[24px] rounded-[5px]"
          >
            <ArrowLeftIcon />
          </button>
          <button
            onClick={onNextMonth}
            className="bg-[#EFF2F7] h-[24px] w-[24px] rounded-[5px]"
          >
            <ArrowRightIcon />
          </button>
        </div>
      </div>
    );
  };
  return (
    <div>
      {openCalender && (
        <div className="modal-overlay">
          <div className="modal">
            <DateRange
              navigatorRenderer={navigatorRenderer}
              locale={isThai ? localeTh : localeEn}
              showMonthAndYearPickers={false}
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              ranges={selectedDateRange}
              onChange={handleDateRangeChange}
            />
            <div className="text-right mt-2">
              <button
                onClick={handleCloseCalender}
                className="btn btn-transparent rounded-[50px] px-4 mr-2 border-[#68C184] text-[#68C184] w-[96px] h-[35px] font-[700] text-[16px] border-[1px]"
              >
                {t("datePicker.cancelButtonLabel")}
              </button>
              <button
                onClick={(e) => handleCalenderSave(e, selectedDateRange)}
                className="btn btn-transparent rounded-[50px] px-4 mr-2 bg-[#68C184] text-[white] w-[96px] h-[35px] font-[700] text-[16px]"
              >
                {t("datePicker.saveButtonLabel")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePickers;

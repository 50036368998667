import { TDropdownValue } from "../../../../admin/pages/group-area/views/Dropdown";

export type TFormUnpaidBill = {
  direction: TDropdownValue[];
  startDate: string;
  endDate: string;
};

export const defaultFormUnpaidBill: TFormUnpaidBill = {
  direction: [],
  startDate: "",
  endDate: "",
};

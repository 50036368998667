import { TableCell } from "@mui/material";
import { IGetStocks } from "../../../../../types/ManageStock.type";
import TableCustom, {
  ExpandableTableRow,
  ITableCustomHeadCell,
} from "../../../../components/Table/TableCustom";
import ExpandStockTable from "./ExpandStockTable";

interface Data {
  id: string;
  nameTh: string;
  skuTh: string;
  feedBrandId: string;
  feedBrandItemId: string;
  remainingProduct: string;
}

const headCells: ITableCustomHeadCell<keyof Data>[] = [
  {
    id: "nameTh",
    numeric: false,
    disablePadding: false,
    label: "แบรนด์อาหารสัตว์",
    width: "150px",
    isSorting: true,
  },
  {
    id: "skuTh",
    numeric: false,
    disablePadding: false,
    label: "เบอร์อาหารสัตว์",
    width: "200px",
    isSorting: false,
  },
  {
    id: "remainingProduct",
    numeric: false,
    disablePadding: false,
    label: "จำนวนสินค้า",
    width: "150px",
    isSorting: false,
  },
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "20px",
    isSorting: false,
  },
];

interface props {
  stock?: IGetStocks[];
}

function ProductPanel(props: props) {
  const { stock } = props;

  return (
    <TableCustom
      order={"asc"}
      orderBy={""}
      totalItemSize={0}
      rowsPerPage={999}
      page={1}
      headerItems={headCells}
      rows={[]}
      onChangePage={(_) => {}}
      onChangeOrder={(_) => {}}
      onChangeOrderBy={(_) => {}}
      currentPageItems={[]}
      itemsSelected={[]}
      onSelectItem={(_) => {}}
      onSelectAllClick={(_) => {}}
      hideSelect
      hidePaginator
      rowChildren={stock?.map((row, index) => {
        const translatedUnits =
          row.fbi.unit === "bag"
            ? "ถุง"
            : row.fbi.unit === "kg"
            ? "กิโลกรัม"
            : row.fbi.unit;

        let count = `${row.amount} ${translatedUnits}`;

        return (
          <ExpandableTableRow
            hover
            key={row.id}
            expandComponent={<ExpandStockTable stockExpand={stock[index]} />}
          >
            <TableCell>{row.fb.nameTh}</TableCell>
            <TableCell>{row.fbi.skuTh}</TableCell>
            <TableCell>{count}</TableCell>
          </ExpandableTableRow>
        );
      })}
    />
  );
}

export default ProductPanel;

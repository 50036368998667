export function sortByProperty(
  array: Array<any>,
  property: string,
  locale: string = "th-TH"
): Array<any> {
  return array.sort((a: any, b: any) => {
    const valueA = a[property];
    const valueB = b[property];
    return valueA.localeCompare(valueB, locale);
  });
}

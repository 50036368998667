import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import SuccessCard from "../../../components/common/SuccessCard";
import { useTranslation } from "react-i18next";

export default function MoveOutPigSuccess() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation() as {
    state: { houseId: string; farmId: string; isSellAll: boolean };
  };

  useEffect(() => {
    // if (!state && !state?.mobileNumber) {
    //     navigate('/welcome');
    // }
  }, []);

  return (
    <SuccessCard
      title={t("moveOutPigSuccess.title") as any}
      buttonLabel={t("moveOutPigSuccess.btnLabel") as any}
      onButtonPress={() => {
        navigate(`/house/selling-history/${state.houseId}`, {
          state: {
            from: state?.isSellAll
              ? `/farm/${state.farmId}`
              : `/house/${state?.houseId}/move-out-pig`,
            subFrom: state?.isSellAll
              ? `/farm/${state.farmId}`
              : `/house/${state?.houseId}/move-out-pig`,
          },
        });
      }}
      subtitle1={t("moveOutPigSuccess.subTitle1") as any}
      subtitle2={
        state?.isSellAll
          ? (t("moveOutPigSuccess.subTitle2All") as any)
          : (t("moveOutPigSuccess.subTitle2Partial") as any)
      }
      subtitle3={t("moveOutPigSuccess.subTitle3") as any}
    />
  );
}

import React, { memo } from 'react'
import { TableCell, TableRow } from '@mui/material'
import { tw } from '../../../../../utils/tw'
import TableCustom from '../../../../components/Table/TableCustom'
import { IGroupAreaFilterCustomerTableResponse } from '../../../../../types/GroupArea.type'

interface ITableTabProps {
  data: IGroupAreaFilterCustomerTableResponse['userGroups']
  totalData: number
  onSortArea: () => void
  currentPage: number
  onChangePage: (page: number) => void
  setGroupIdModalDetail: (id: string) => void
}

const classes = {
  container: tw(`mt-6`),
  emptyTable: tw(`font-sans text-center w-full text-[#979797] text-[14px] py-3`),
}

const TableTab = (props: ITableTabProps) => {
  const {
    data,
    totalData,
    currentPage,
    onChangePage,
    onSortArea,
    setGroupIdModalDetail,
  } = props

  return (
    <div className={classes.container}>
      <TableCustom
        order="desc"
        orderBy={""}
        totalItemSize={totalData}
        rowsPerPage={10}
        page={currentPage}
        headerItems={[
          {
            id: 'area',
            numeric: false,
            disablePadding: false,
            label: 'ภาค',
            width: 'auto',
            isSorting: true,
          },
          {
            id: 'groupName',
            numeric: false,
            disablePadding: false,
            label: 'ชื่อกลุ่ม',
            width: 'auto',
            isSorting: false,
          },
          {
            id: 'provinceCount',
            numeric: false,
            disablePadding: false,
            label: 'จำนวนจังหวัด',
            width: 'auto',
            isSorting: false,
            className: '!text-center'
          },
          {
            id: 'districtCount',
            numeric: false,
            disablePadding: false,
            label: 'จำนวนเขต/อำเภอ',
            width: 'auto',
            isSorting: false,
            className: '!text-center'
          },
          {
            id: 'farmerCount',
            numeric: false,
            disablePadding: false,
            label: 'จำนวนลูกค้า',
            width: 'auto',
            isSorting: false,
            className: '!text-center'
          },
          {
            id: 'moreDetail',
            numeric: false,
            disablePadding: false,
            label: '',
            width: '104px',
            isSorting: false,
          },
        ]}
        rows={data}
        onChangePage={onChangePage}
        currentPageItems={[]}
        itemsSelected={[]}
        onSelectItem={() => {}}
        onSelectAllClick={() => {}}
        hideSelect
        onChangeOrder={onSortArea}
        onChangeOrderBy={() => {}}
        EmptyComponent={data.length === 0 ? <div className={classes.emptyTable}>ไม่มีข้อมูล</div> : null}
        rowChildren={data.map((row) => {
          const onOpenDetail = () => {
            setGroupIdModalDetail(row.id)
          }

          return (
            <TableRow key={row.id} hover className="border-x-[1px] border-x-[#ddd]">
              <TableCell>{row.region.nameTh}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell className="!text-center">{row.provinceAmount}</TableCell>
              <TableCell className="!text-center">{row.districtAmount}</TableCell>
              <TableCell className="!text-center">{row.farmerAmount}</TableCell>
              <TableCell onClick={onOpenDetail} className="!text-secondary !text-center underline cursor-pointer">
                ดูรายละเอียด
              </TableCell>
            </TableRow>
          )
        })}
      />
    </div>
  )
}

export default memo(TableTab)

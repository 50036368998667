import {
  FormDashboardFormStatus,
  FarmStatusType,
  PigFaceType,
  FormStatusDetail,
} from "./farm-dashboard.type";

export default function mapFarmStatusWithBusinesLogic(
  farmStatus: FormDashboardFormStatus
): FormStatusDetail {
  const edibleStatus: FarmStatusType =
    farmStatus.edible >= 70
      ? "GOOD"
      : farmStatus.edible >= 50
      ? "AVERAGE"
      : "POOR";
  // console.log("farmStatus",farmStatus)
  // console.log("edibleStatus",edibleStatus)
  const healthStatus: FarmStatusType =
    farmStatus.health >= 70
      ? "GOOD"
      : farmStatus.health >= 50
      ? "AVERAGE"
      : "POOR";
  const losePercentStatus: FarmStatusType =
    farmStatus.loss >= 70 ? "GOOD" : farmStatus.loss >= 50 ? "AVERAGE" : "POOR";
  return {
    ...farmStatus,
    edibleStatus,
    healthStatus,
    losePercentStatus,
    pigFaceEmotion: getOverallStatus(
      edibleStatus,
      healthStatus,
      losePercentStatus
    ),
  };
}

function getOverallStatus(
  edibleStatus: FarmStatusType,
  healthStatus: FarmStatusType,
  losePercent: FarmStatusType
): PigFaceType {
  const total =
    mapStatusToNumber(edibleStatus) +
    mapStatusToNumber(healthStatus) +
    mapStatusToNumber(losePercent);

  if (total === 3) {
    return "GOOD";
  } else if (total === 2) {
    return "AVERAGE";
  } else {
    return "POOR";
  }
}

function mapStatusToNumber(status: FarmStatusType): number {
  return status === "GOOD" ? 1 : 0;
}

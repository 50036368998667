import AppBarFS from "../../../../components/app-bar/AppBar";
import DeleteIcon from "../../../../assets/images/Farms/delete-icon.svg";
import DeleteIconDisable from "../../../../assets/images/Farms/delete-icon-disable.svg";
import DeleteButton from "../../../../assets/images/Farms/delete-button.svg";
import { MenuItem } from "@mui/material";
import TextInputHouse from "../../../../components/common/Input/TextInputHouse";
import { useContext, useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { vaccinesList } from "../../../../services/AuthService";
import FeedingContext from "../../feeding-program/Provider/FeedingContext";
const EditVaccineProgram = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const language = localStorage.getItem("i18nextLng");
  const vaccineProgramDetailsData = location.state;
  const { t } = useTranslation();
  const [vaccineProgram, setVaccineProgram] = useState<any>();
  const [vaccineFeedData, setVaccineFeedData] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const { finalAddHouseData, setAddHouseData, setEditVaccine } =
    useContext(FeedingContext);

  const handleAddRow = () => {
    setVaccineProgram([
      {
        pigAge: 0,
        vaccineId: "",
        vaccine: {
          nameEn: "",
          nameTh: "",
          quantity: 2,
        },
      },
      ...vaccineProgram,
    ]);
  };

  useEffect(() => {
    const storedData = finalAddHouseData.vaccineDetails;
    if (storedData && storedData !== "undefined") {
      setVaccineProgram(storedData[0]?.vaccineProgramDetails);
    } else {
      setVaccineProgram(vaccineProgramDetailsData);
    }
  }, [finalAddHouseData]);

  const handleDeleteRow = (i: number) => {
    let vaccineData = [...vaccineProgram];
    vaccineData.splice(i, 1);
    setVaccineProgram([...vaccineData]);
  };

  const handleAgeChange = (e: any, i: number) => {
    const updatedVaccineProgram = [...vaccineProgram];
    updatedVaccineProgram[i] = {
      ...updatedVaccineProgram[i],
      pigAge: Number(e.target.value),
    };
    setVaccineProgram(updatedVaccineProgram);
  };

  const handleRecordEdit = () => {
    setAddHouseData &&
      setAddHouseData({
        ...finalAddHouseData,
        vaccineDetails: [
          {
            ...finalAddHouseData.vaccineDetails[0],
            vaccineProgramDetails: vaccineProgram,
          },
        ],
      });
    navigateToAddHouse();
  };

  const getVaccineNameById = (vaccineId: string) => {
    const foundVaccine: any = vaccineFeedData.find(
      (vaccine: any) => vaccine?.id === vaccineId
    );
    return foundVaccine
      ? {
          nameTh: foundVaccine.nameTh,
          nameEn: foundVaccine.nameEn,
          quantity: foundVaccine.quantity,
        }
      : "";
  };

  const handleVaccineChange = (e: any, i: number) => {
    const selectedId = e.target.value;
    const updatedVaccineProgram = [...vaccineProgram];
    updatedVaccineProgram[i] = {
      ...updatedVaccineProgram[i],
      vaccine: getVaccineNameById(selectedId),
      vaccineId: selectedId,
    };
    setVaccineProgram(updatedVaccineProgram);
  };

  useEffect(() => {
    if (vaccineProgram?.length === 1) {
      setShowDelete(false);
    }
  }, [vaccineProgram]);

  const getvaccinesList = async () => {
    const resp = await vaccinesList();
    if (resp?.status === 200) {
      setVaccineFeedData(resp?.data?.vaccines);
    }
  };

  useEffect(() => {
    getvaccinesList();
  }, []);

  const navigateToAddHouse = () => {
    setEditVaccine && setEditVaccine(true);
    navigate("/add-house", { state: { veccEdited: true } });
  };
  return (
    <div className="min-h-screen bg-[#f6fbff] justify-between flex flex-col w-full">
      <div>
        <div className="p-4 bg-[#fff] pb-8">
          <AppBarFS
            title={t("editVaccine.editVaccineHeading")}
            onIconPress={navigateToAddHouse}
          ></AppBarFS>
        </div>
        <div className="flex justify-between py-4 px-4">
          <div className="text-[#3777BC] text-[20px] font-semibold">
            {t("editVaccine.editVaccineTopText")}
          </div>
          {showDelete || vaccineProgram?.length === 1 ? (
            <img
              className="cursor-pointer"
              src={DeleteIconDisable}
              alt="delete-icon-disable"
              onClick={() => {
                setShowDelete(false);
              }}
            />
          ) : (
            <img
              className="cursor-pointer"
              src={DeleteIcon}
              alt="delete-icon"
              onClick={() => {
                vaccineProgram?.length > 1 && setShowDelete(true);
              }}
            />
          )}
        </div>
        {vaccineProgram?.length &&
          vaccineProgram.map((item: any, index: number) => {
            return (
              <div
                className="w-full flex items-center justify-between"
                key={index}
              >
                <div className="pt-2 px-4 bg-[#fff] w-full">
                  <div className="flex justify-between items-center py-2 pl-4">
                    <div className="text-[#979797]">
                      {t("editVaccine.editVaccineAgeText")}
                    </div>
                    <TextInputHouse
                      select
                      value={item?.pigAge}
                      onChange={(e) => {
                        handleAgeChange(e, index);
                      }}
                    >
                      {Array.from({ length: 15 }, (_, index) => index + 1).map(
                        (item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        )
                      )}
                    </TextInputHouse>
                  </div>
                  <div className="flex justify-between items-center py-2 pl-4 gap-[5px]">
                    <div className="text-[#979797]">
                      {t("editVaccine.editVaccineVaccineText")}
                    </div>
                    <TextInputHouse
                      select
                      value={item?.vaccineId}
                      className="max-w-[200px] somil"
                      onChange={(e) => {
                        handleVaccineChange(e, index);
                      }}
                    >
                      {vaccineFeedData &&
                        vaccineFeedData.map((item: any, index: number) => (
                          <MenuItem
                            value={item?.id}
                            data-name-th={item.nameTh}
                            key={index}
                          >
                            {language === "th" ? item?.nameTh : item?.nameEn}
                          </MenuItem>
                        ))}
                    </TextInputHouse>
                  </div>
                  <div
                    className="flex justify-between items-center py-2 px-4"
                    style={{ borderBottom: "1px solid #D9EBFE" }}
                  >
                    <div className="text-[#979797]">
                      {t("editVaccine.editVaccineQuantityText")}
                    </div>
                    <div className="text-[#979797]">
                      {item?.vaccine?.quantity ? item?.vaccine?.quantity : 1} |
                      CC
                    </div>
                  </div>
                </div>
                {showDelete && (
                  <div
                    className="px-3 flex min-h-[161px] items-center"
                    style={{ borderTop: "8px solid white" }}
                  >
                    <img
                      src={DeleteButton}
                      alt="Delete-button"
                      className="cursor-pointer h-fit"
                      onClick={() => {
                        handleDeleteRow(index);
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
        {vaccineProgram && vaccineProgram.length < 6 && (
          <div className="flex justify-center w-full">
            <Button
              sx={{ my: 2, py: 1 }}
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => {
                handleAddRow();
              }}
            >
              {t("editVaccine.addVaccineButtonText")}
            </Button>
          </div>
        )}
      </div>
      <Button
        onClick={handleRecordEdit}
        sx={{ m: 2, mt: 5 }}
        variant="contained"
        color="secondary"
        size="large"
      >
        {t("editVaccine.recordButtonText")}
      </Button>
    </div>
  );
};
export default EditVaccineProgram;

import { getCreditUpcomingOrder } from "../../../services/admin/sale-order/CreditUpcomingOrder.service";
import {
  getAllFeedBrands,
  getCountSaleOrder,
} from "../../../services/SaleOrderService";

export const loadSaleOrderData = async () => {
  const feed = await getAllFeedBrands();
  const count = await getCountSaleOrder();
  const feedBrands = feed?.feedBrands ?? [];
  return { feedBrands, count };
};

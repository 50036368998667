export function PencilAlternateIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.08317 2.58334H3.49984C2.48732 2.58334 1.6665 3.40415 1.6665 4.41667V14.5C1.6665 15.5125 2.48732 16.3333 3.49984 16.3333H13.5832C14.5957 16.3333 15.4165 15.5125 15.4165 14.5V9.91667M14.1201 1.28697C14.8361 0.57101 15.9969 0.57101 16.7129 1.28697C17.4288 2.00293 17.4288 3.16373 16.7129 3.8797L8.84256 11.75H6.24984L6.24984 9.15728L14.1201 1.28697Z"
        stroke="#68C184"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { TableType } from "./types";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Table } from "@mui/material";

const CurrentProgramTable = ({ tableData, tableHeadData }: TableType) => {
  const language = localStorage.getItem("i18nextLng");
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#D9EBFE",
      color: "#3777BC",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      fontWeight: 500,
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f6faff",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <TableContainer
      component={Paper}
      sx={{ borderRadius: "0px", boxShadow: "none" }}
    >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {tableHeadData &&
              tableHeadData.map((item, index) => (
                <StyledTableCell
                  align="center"
                  key={index}
                  sx={{ fontSize: "16px", whiteSpace: "nowrap" }}
                >
                  {language === "th" ? (
                    <>{item?.nameTh}</>
                  ) : (
                    <>{item?.nameEn}</>
                  )}
                </StyledTableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData &&
            tableData.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="center"
                  sx={{ color: "#3777BC" }}
                >
                  {item?.pigAge}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{
                    color: "#4B4B4B",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "165px",
                  }}
                >
                  {language === "th" ? (
                    <>{item?.nameTh}</>
                  ) : (
                    <>{item?.nameEn}</>
                  )}
                </StyledTableCell>
                <StyledTableCell align="center" sx={{ color: "#3777BC" }}>
                  {item?.quantity}
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default CurrentProgramTable;

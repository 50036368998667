import React, { FormEvent, useEffect, useState } from "react";
import SelectAdminCustom from "../../../components/Select/Select";
import { MenuItem, SvgIcon } from "@mui/material";
import TextInputSearchCustom from "../../../components/Input/TextInputSearchCustom";
import ButtonOutlinedCustom from "../../../components/Button/ButtonOutlinedCustom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { TUseInvoiceSearchFilter } from "../../../../hooks/useInvoiceSearchFilter.hook";
import { DownloadSvg } from "../../../assets/svg/Download.svg";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import { IInvoiceTable } from "../type";
import WastedDeptModal from "./WastedDeptModal";

type Props = {
  searchFilterInvoice: TUseInvoiceSearchFilter;
  isDisplaySelect?: boolean;
  isDisplayOverDueSelect?: boolean;
  onSearch: () => void;
  onExport?: () => void;
  onOpenCreate?: () => void;
  placeholder: string;
  itemSelected: (string | number)[];
  select?: { statusEN: string; statusTH: string }[];
  displayButtonManageBelt?: boolean;
  displayButtonCreateAssignment?: boolean;
  disabledBtnManageDept?: boolean;
  selectedInvoice?: IInvoiceTable[];
};

export const FilterInvoice = ({
  searchFilterInvoice,
  isDisplaySelect,
  isDisplayOverDueSelect,
  placeholder,
  onSearch,
  onExport,
  onOpenCreate,
  itemSelected,
  select,
  displayButtonManageBelt = false,
  displayButtonCreateAssignment = false,
  disabledBtnManageDept,
  selectedInvoice,
}: Props) => {
  const [toggleClear, setToggleClear] = useState<boolean>(false);
  const [isOpenWastedDept, setIsOpenWastedDept] = useState<boolean>(false);
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchFilterInvoice.setPage(1);
    onSearch();
  };

  useEffect(() => {
    onSearch();
  }, [toggleClear]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {isDisplaySelect && (
          <div className="flex justify-between">
            <div className="w-[30%] pb-6">
              <span className="text-[16px] font-sans font-medium">สถานะ</span>
              <SelectAdminCustom
                className="mt-2"
                placeholder="เลือก"
                value={searchFilterInvoice.searchSaleStatus}
                renderValueArray={select!.map((row) => ({
                  value: row.statusEN,
                  label: row.statusTH,
                }))}
                onChange={(event) =>
                  searchFilterInvoice.setSearchStatus(
                    event.target.value as string
                  )
                }
                menuItems={select!.map((row) => (
                  <MenuItem key={row.statusEN} value={row.statusEN}>
                    {row.statusTH}
                  </MenuItem>
                ))}
                endAdornment={
                  searchFilterInvoice.searchSaleStatus !== "" ? (
                    <CloseRoundedIcon
                      sx={{
                        cursor: "pointer",
                        fontSize: "16px !important",
                        marginRight: "20px",
                      }}
                      onClick={(_) => {
                        searchFilterInvoice.setSearchStatus("");
                        setToggleClear((prev) => !prev);
                      }}
                    />
                  ) : null
                }
              />
            </div>
            <div className="pt-8">
              <ButtonFillCustom
                type="submit"
                title="ค้นหา"
                onClick={() => handleSubmit}
              />
            </div>
          </div>
        )}
        {isDisplayOverDueSelect && (
          <div className="flex justify-between">
            <div className="w-[30%] pb-6">
              <span className="text-[16px] font-sans font-medium">สถานะ</span>
              <SelectAdminCustom
                className="mt-2"
                placeholder="เลือก"
                value={searchFilterInvoice.searchOverdueStatus}
                renderValueArray={select!.map((row) => ({
                  value: row.statusEN,
                  label: row.statusTH,
                }))}
                onChange={(event) =>
                  searchFilterInvoice.setSearchOverdueStatus(
                    event.target.value as string
                  )
                }
                menuItems={select!.map((row) => (
                  <MenuItem key={row.statusEN} value={row.statusEN}>
                    {row.statusTH}
                  </MenuItem>
                ))}
                endAdornment={
                  searchFilterInvoice.searchOverdueStatus !== "" ? (
                    <CloseRoundedIcon
                      sx={{
                        cursor: "pointer",
                        fontSize: "16px !important",
                        marginRight: "20px",
                      }}
                      onClick={(_) => {
                        searchFilterInvoice.setSearchOverdueStatus("");
                        setToggleClear((prev) => !prev);
                        searchFilterInvoice.setPage(1);
                      }}
                    />
                  ) : null
                }
              />
            </div>
            <div className="pt-8">
              <ButtonFillCustom
                type="submit"
                title="ค้นหา"
                onClick={() => handleSubmit}
              />
            </div>
          </div>
        )}
        <div className="flex flex-row w-full justify-between">
          <div className="flex w-full max-w-[656px]">
            <div className="w-full max-w-[470px] mr-4">
              <TextInputSearchCustom
                value={searchFilterInvoice.searchText}
                placeholder={placeholder}
                onChange={(event) =>
                  searchFilterInvoice.setSearchText(event.target.value)
                }
                InputProps={{
                  endAdornment:
                    searchFilterInvoice.searchText.length > 0 ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "18px !important",
                        }}
                        onClick={(_) => {
                          searchFilterInvoice.setSearchText("");
                          setToggleClear((prev) => !prev);
                        }}
                      />
                    ) : null,
                }}
              />
            </div>
            {itemSelected.length > 0 && (
              <div className="w-full max-w-[170px] flex items-center font-sans text-[14px] text-[#646464]">{`เลือกไว้ ${itemSelected.length} รายการ`}</div>
            )}
          </div>
          <div className="flex gap-3">
            <ButtonOutlinedCustom
              title="นำออก"
              btnTextColor="#68C184"
              btnBorderColor="#68C184"
              disabled={itemSelected.length === 0}
              onClick={onExport}
              startIcon={
                <SvgIcon
                  sx={{
                    fontSize: "14px !important",
                    color: "currentColor",
                    marginBottom: "3px",
                  }}
                  fontSize="small"
                >
                  <DownloadSvg />
                </SvgIcon>
              }
            />

            {displayButtonManageBelt && (
              <ButtonFillCustom
                title="จัดการหนี้เสีย"
                disabled={disabledBtnManageDept}
                onClick={() => setIsOpenWastedDept(true)}
              />
            )}

            {displayButtonCreateAssignment && (
              <ButtonFillCustom title="สร้างใบงาน" onClick={onOpenCreate} />
            )}
          </div>
        </div>
      </form>
      <WastedDeptModal
        isOpen={isOpenWastedDept}
        handleClose={() => setIsOpenWastedDept(false)}
        selectedInvoice={selectedInvoice}
      ></WastedDeptModal>
    </div>
  );
};

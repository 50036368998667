export function DeleteOutlinedIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4165 4.41667L14.6215 15.5473C14.5529 16.5067 13.7546 17.25 12.7928 17.25H5.20689C4.24505 17.25 3.44674 16.5067 3.37821 15.5473L2.58317 4.41667M7.1665 8.08333V13.5833M10.8332 8.08333V13.5833M11.7498 4.41667V1.66667C11.7498 1.16041 11.3394 0.75 10.8332 0.75H7.1665C6.66024 0.75 6.24984 1.16041 6.24984 1.66667V4.41667M1.6665 4.41667H16.3332"
        stroke="#68C184"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { useCallback, useEffect, useState } from "react";
import {
  defaultFormNewContract,
  TFormNewContract,
} from "./DefaultFormNewContract";

export const useFinancialNewContractController = () => {
  const [formNewContract, setFormNewContract] = useState(
    defaultFormNewContract
  );

  const onChangeFormNewContract = useCallback(
    (key: keyof TFormNewContract) => (value: any) => {
      setFormNewContract((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const [idContract, setIdContract] = useState("");

  const [isOpenEditContract, setIsOpenEditContract] = useState(false);

  const onOpenEditContract = useCallback(() => setIsOpenEditContract(true), []);
  const onHideEditContract = useCallback(
    () => setIsOpenEditContract(false),
    []
  );

  return {
    formNewContract,
    setFormNewContract,
    onChangeFormNewContract,
    idContract,
    setIdContract,
    isOpenEditContract,
    onOpenEditContract,
    onHideEditContract,
  };
};

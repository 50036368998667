const Receipt = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none">
    <mask
      id="a"
      width={24}
      height={25}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M0 .5h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#3777BC"
        d="M6 22.5a2.893 2.893 0 0 1-2.125-.875A2.893 2.893 0 0 1 3 19.5v-3h3v-14L7.5 4 9 2.5 10.5 4 12 2.5 13.5 4 15 2.5 16.5 4 18 2.5 19.5 4 21 2.5v17c0 .833-.292 1.542-.875 2.125A2.893 2.893 0 0 1 18 22.5H6Zm12-2c.283 0 .52-.096.712-.288A.968.968 0 0 0 19 19.5v-14H8v11h9v3c0 .283.096.52.288.712.191.192.429.288.712.288Zm-9-11v-2h6v2H9Zm0 3v-2h6v2H9Zm8-3a.968.968 0 0 1-.712-.287A.967.967 0 0 1 16 8.5c0-.283.096-.52.288-.713A.968.968 0 0 1 17 7.5c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 17 9.5Zm0 3a.968.968 0 0 1-.712-.287A.968.968 0 0 1 16 11.5c0-.283.096-.52.288-.713A.968.968 0 0 1 17 10.5c.283 0 .52.096.712.287.192.192.288.43.288.713s-.096.52-.288.713A.968.968 0 0 1 17 12.5Zm-11 8h9v-2H5v1c0 .283.096.52.287.712.192.192.43.288.713.288Z"
      />
    </g>
  </svg>
);
export default Receipt;

import EventIcon from "@mui/icons-material/Event";
import { Button, InputAdornment } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import DatePickerFS from "../../../../../components/common/DatePicker";
import { BaseModal } from "../../../order-feed/OrderFeedBaseModal";

type TChangeConfirmDateModalProps = {
  defaultDate: Dayjs;
  onOpenModal: boolean;
  onCloseModal: (isConfirm: boolean, value: Dayjs | null) => void;
};

const ChangeConfirmDateModal = ({
  defaultDate,
  onOpenModal,
  onCloseModal,
}: TChangeConfirmDateModalProps) => {
  const minDate = dayjs().startOf("day").add(3, "day");
  const [confirmDate, setConfirmDate] = useState<Dayjs | null>(defaultDate);

  useEffect(() => {
    setConfirmDate(defaultDate);
  }, [defaultDate]);

  return (
    <BaseModal
      isOpen={onOpenModal}
      handleClose={() => onCloseModal(false, confirmDate)}
      iconCloseModal={true}
    >
      <div className="flex  flex-col text-center">
        <p className="text-[24px] font-medium items-center text-tertiary mb-4">
          แก้ไขวันจัดส่ง
        </p>

        <DatePickerFS
          minDate={minDate}
          value={confirmDate}
          onChange={(e) => {
            if (e) {
              setConfirmDate(e);
            }
          }}
          slotProps={{
            textField: {
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <EventIcon color="info" />
                  </InputAdornment>
                ),
              },
            },
          }}
          label={"วันที่ต้องการให้จัดส่ง"}
        />
        <Button
          size="large"
          variant="contained"
          onClick={() => {
            onCloseModal(true, confirmDate);
          }}
          sx={{
            marginTop: "16px",
            color: "#ffff",
            bgcolor: "#68C184",
            width: "100%",
            ":hover": {
              color: "#FFFFFF",
              border: "1px solid #68C184",
              bgcolor: "#68C184",
            },
            ":active": {
              color: "#FFFFFF",
              border: "1px solid #68C184",
              bgcolor: "#68C184",
            },
          }}
        >
          ยืนยัน
        </Button>
      </div>
    </BaseModal>
  );
};

export default ChangeConfirmDateModal;

import NewLogin from "./index_new";
import OldLogin from "./index_old";

const IS_OLD_LOGIN = ["local", "dev", "qa"].includes(
  (window as any).REACT_APP_PUBLIC_NODE_ENV
);

const Login = () => {
  if (IS_OLD_LOGIN) return <OldLogin />;
  return <NewLogin />;
};

export default Login;

import { useCallback, useMemo, useState } from "react";
import React from "react";
import dayjs from "dayjs";
import DateRangePickerPopover from "../../components/Input/DateRangePickerPopover/DateRangePickerPopover";
import { FILTER_DATE_FORMAT } from "../sale-order/components/SaleOrderSearchFilter";
import { useQuery } from "@tanstack/react-query";
import {
  getClaimOrderHistory,
  getClaimOrderProgress,
  getClaimOrderUpcoming,
} from "../../../services/ClaimOrder.service";
import { getBrands, getFeeds } from "../../../services/FeedFoodApiService";
import { get } from "lodash";
import { TDropdownValue } from "../group-area/views/Dropdown";
import { defaultFormClaimFilter, TFormClaimFilter } from "./defaultForm";

export const useClaimOrderController = () => {
  const [tabIndex, setTabIndex] = useState(0);
  /////////////////////////////////// DateUp ///////////////////////////////////
  const [shippingStartDate, setShippingStartDate] = useState("");
  const [shippingEndDate, setShippingEndDate] = useState("");
  const [orderStartDate, setOrderStartDate] = useState("");
  const [orderEndDate, setOrderEndDate] = useState("");
  const [itemSelectedUpComing, setItemSelectedUpComing] = useState<string[]>(
    []
  );
  const [currentOrderByKey1, setCurrentOrderByKey1] = useState<
    | "order_no"
    | "customer_no"
    | "delivery_date"
    | "order_date"
    | "actual_shipping_date"
  >("order_no");

  const [orderByType1, setOrderByType1] = useState<"desc" | "asc">("desc");

  /////////////////////////////////// DateIn ///////////////////////////////////
  const [shippingStartDateIn, setShippingStartDateIn] = useState("");
  const [shippingEndDateIn, setShippingEndDateIn] = useState("");
  const [orderStartDateIn, setOrderStartDateIn] = useState("");
  const [orderEndDateIn, setOrderEndDateIn] = useState("");
  const [itemSelectedInProgress, setItemSelectedInProgress] = useState<
    string[]
  >([]);
  const [currentOrderByKey2, setCurrentOrderByKey2] = useState<
    | "order_no"
    | "customer_no"
    | "delivery_date"
    | "order_date"
    | "actual_shipping_date"
  >("order_no");

  const [orderByType2, setOrderByType2] = useState<"desc" | "asc">("desc");
  /////////////////////////////////// DateHis ///////////////////////////////////
  const [shippingStartDateHis, setShippingStartDateHis] = useState("");
  const [shippingEndDateHis, setShippingEndDateHis] = useState("");
  const [orderStartDateHis, setOrderStartDateHis] = useState("");
  const [orderEndDateHis, setOrderEndDateHis] = useState("");
  const [itemSelectedHistory, setItemSelectedHistory] = useState<string[]>([]);
  const [currentOrderByKey3, setCurrentOrderByKey3] = useState<
    | "order_no"
    | "customer_no"
    | "delivery_date"
    | "order_date"
    | "actual_shipping_date"
  >("order_no");

  const [orderByType3, setOrderByType3] = useState<"desc" | "asc">("desc");
  //////////////////////////////////////////////////////////////////////
  const [isShowCreateReport, setIsShowCreateReport] = useState(false);
  const [currentPageUpComing, setCurrentPageUpComing] = useState(1);
  const [currentPageProgress, setCurrentPageProgress] = useState(1);
  const [currentPageHistory, setCurrentPageHistory] = useState(1);
  //////////////////////////////////////////////////////////////////////
  const [searchTextUpComing, setSearchTextUpComing] = useState("");
  const [searchTextProgress, setSearchTextProgress] = useState("");
  const [searchTextHistory, setSearchTextHistory] = useState("");
  //////////////////////////////////////////////////////////////////////
  const [brandUpComing, setBrandUpComing] = useState<TDropdownValue[]>([]);
  const [feedUpComing, setFeedUpComing] = useState<TDropdownValue[]>([]);
  const [brandPageInProgress, setBrandPageInProgress] = useState<
    TDropdownValue[]
  >([]);
  const [feedPageInProgress, setFeedPageInProgress] = useState<
    TDropdownValue[]
  >([]);
  const [brandPageHistory, setBrandPageHistory] = useState<TDropdownValue[]>(
    []
  );
  const [feedPageHistory, setFeedPageHistory] = useState<TDropdownValue[]>([]);
  //////////////////////////////////////////////////////////////////////

  const [formTabUpComing, setFormTabUpComing] = useState(
    defaultFormClaimFilter
  );

  const onChangeFormUpComing = useCallback(
    (key: keyof TFormClaimFilter) => (value: any) => {
      setFormTabUpComing((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const [formTabInProcess, setFormTabInProcess] = useState(
    defaultFormClaimFilter
  );

  const onChangeFormInProgress = useCallback(
    (key: keyof TFormClaimFilter) => (value: any) => {
      setFormTabInProcess((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const [formTabHistory, setFormTabHistory] = useState(defaultFormClaimFilter);

  const onChangeFormHistory = useCallback(
    (key: keyof TFormClaimFilter) => (value: any) => {
      setFormTabHistory((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  //////////////////////////////////////////////////////////////////////

  const { data: listClaimOrder, refetch: refetchListClaimOrderUpcoming } =
    useQuery({
      queryKey: [
        "list_claim_order_upcoming",
        currentPageUpComing,
        searchTextUpComing,
        currentOrderByKey1,
        orderByType1,
        formTabUpComing,
      ],
      queryFn: async () => {
        const FeedIds = formTabUpComing.feedBrandItemIds
          .map((item) => item.value)
          .join(",");

        return await getClaimOrderUpcoming({
          page: currentPageUpComing,
          searchText: searchTextUpComing,
          orderBy: currentOrderByKey1,
          direction: orderByType1,
          orderDateStart: formTabUpComing.orderDateStart
            ? dayjs(formTabUpComing.orderDateStart, "DD-MM-YYYY")
                .startOf("day")
                .toDate()
            : undefined,
          orderDateEnd: formTabUpComing.orderDateEnd
            ? dayjs(formTabUpComing.orderDateEnd, "DD-MM-YYYY")
                .startOf("day")
                .toDate()
            : undefined,
          deliveryDateStart: formTabUpComing.deliveryDateStart
            ? dayjs(formTabUpComing.deliveryDateStart, "DD-MM-YYYY")
                .startOf("day")
                .toDate()
            : undefined,
          deliveryDateEnd: formTabUpComing.deliveryDateEnd
            ? dayjs(formTabUpComing.deliveryDateEnd, "DD-MM-YYYY")
                .startOf("day")
                .toDate()
            : undefined,
          feedBrandId: formTabUpComing.feedBrandId[0]?.value,
          feedBrandItemIds: FeedIds,
        });
      },
    });

  const {
    data: listClaimOrderProgress,
    refetch: refetchListClaimOrderProgress,
  } = useQuery({
    queryKey: [
      "list_claim_order_progress",
      currentPageProgress,
      searchTextProgress,
      currentOrderByKey2,
      orderByType2,
      formTabInProcess,
    ],
    queryFn: async () => {
      const FeedIds = formTabInProcess.feedBrandItemIds
        .map((item) => item.value)
        .join(",");

      return await getClaimOrderProgress({
        page: currentPageProgress,
        searchText: searchTextProgress,
        orderBy: currentOrderByKey2,
        direction: orderByType2,
        orderDateStart: formTabInProcess.orderDateStart
          ? dayjs(formTabInProcess.orderDateStart, "DD-MM-YYYY")
              .startOf("day")
              .toDate()
          : undefined,
        orderDateEnd: formTabInProcess.orderDateEnd
          ? dayjs(formTabInProcess.orderDateEnd, "DD-MM-YYYY")
              .startOf("day")
              .toDate()
          : undefined,
        deliveryDateStart: formTabInProcess.deliveryDateStart
          ? dayjs(formTabInProcess.deliveryDateStart, "DD-MM-YYYY")
              .startOf("day")
              .toDate()
          : undefined,
        deliveryDateEnd: formTabInProcess.deliveryDateEnd
          ? dayjs(formTabInProcess.deliveryDateEnd, "DD-MM-YYYY")
              .startOf("day")
              .toDate()
          : undefined,
        feedBrandId: formTabInProcess.feedBrandId[0]?.value,
        feedBrandItemIds: FeedIds,
      });
    },
  });

  const { data: listClaimOrderHistory, refetch: refetchListClaimOrderHistory } =
    useQuery({
      queryKey: [
        "list_claim_order_history",
        currentPageHistory,
        searchTextHistory,
        currentOrderByKey3,
        orderByType3,
        formTabHistory,
      ],
      queryFn: async () => {
        const FeedIds = formTabHistory.feedBrandItemIds
          .map((item) => item.value)
          .join(",");

        return await getClaimOrderHistory({
          page: currentPageHistory,
          searchText: searchTextHistory,
          orderBy: currentOrderByKey3,
          direction: orderByType3,
          orderDateStart: formTabHistory.orderDateStart
            ? dayjs(formTabHistory.orderDateStart, "DD-MM-YYYY")
                .startOf("day")
                .toDate()
            : undefined,
          orderDateEnd: formTabHistory.orderDateEnd
            ? dayjs(formTabHistory.orderDateEnd, "DD-MM-YYYY")
                .startOf("day")
                .toDate()
            : undefined,
          deliveryDateStart: formTabHistory.deliveryDateStart
            ? dayjs(formTabHistory.deliveryDateStart, "DD-MM-YYYY")
                .startOf("day")
                .toDate()
            : undefined,
          deliveryDateEnd: formTabHistory.deliveryDateEnd
            ? dayjs(formTabHistory.deliveryDateEnd, "DD-MM-YYYY")
                .startOf("day")
                .toDate()
            : undefined,
          feedBrandId: formTabHistory.feedBrandId[0]?.value,
          feedBrandItemIds: FeedIds,
        });
      },
    });

  const { data: brandsUp } = useQuery({
    queryKey: ["food-feed", "brands", "asc"],
    queryFn: async () => {
      return await getBrands({ direction: "asc" });
    },
  });

  const { data: feedResultUp } = useQuery({
    queryKey: ["food-feed", "feeds", get(brandUpComing, [0, "value"])],
    queryFn: async () => {
      return await getFeeds({
        direction: "asc",
        feedBrandId: get(brandUpComing, [0, "value"]),
      });
    },
  });

  const brandsOptionsUpComing = useMemo(() => {
    return get(brandsUp, ["result"], []).map(({ id, nameEn, nameTh }) => ({
      label: nameTh,
      value: id,
    }));
  }, [brandsUp]);

  const feedOptionsUpComing = useMemo(() => {
    return get(feedResultUp, ["listOfFeeds"], []).map(({ id, skuTh }) => ({
      label: skuTh,
      value: id,
    }));
  }, [feedResultUp]);

  const { data: brandsIn } = useQuery({
    queryKey: ["food-feed", "brands", "asc"],
    queryFn: async () => {
      return await getBrands({ direction: "asc" });
    },
  });

  const { data: feedResultIn } = useQuery({
    queryKey: ["food-feed", "feeds", get(brandPageInProgress, [0, "value"])],
    queryFn: async () => {
      return await getFeeds({
        direction: "asc",
        feedBrandId: get(brandPageInProgress, [0, "value"]),
      });
    },
  });

  const brandsOptionsInProgress = useMemo(() => {
    return get(brandsIn, ["result"], []).map(({ id, nameEn, nameTh }) => ({
      label: nameTh,
      value: id,
    }));
  }, [brandsIn]);

  const feedOptionsInProgress = useMemo(() => {
    return get(feedResultIn, ["listOfFeeds"], []).map(({ id, skuTh }) => ({
      label: skuTh,
      value: id,
    }));
  }, [feedResultIn]);

  const { data: brandsHis } = useQuery({
    queryKey: ["food-feed", "brands", "asc"],
    queryFn: async () => {
      return await getBrands({ direction: "asc" });
    },
  });

  const { data: feedResultHis } = useQuery({
    queryKey: ["food-feed", "feeds", get(brandPageInProgress, [0, "value"])],
    queryFn: async () => {
      return await getFeeds({
        direction: "asc",
        feedBrandId: get(brandPageInProgress, [0, "value"]),
      });
    },
  });

  const brandsOptionsHistory = useMemo(() => {
    return get(brandsHis, ["result"], []).map(({ id, nameEn, nameTh }) => ({
      label: nameTh,
      value: id,
    }));
  }, [brandsHis]);

  const feedOptionsHistory = useMemo(() => {
    return get(feedResultHis, ["listOfFeeds"], []).map(({ id, skuTh }) => ({
      label: skuTh,
      value: id,
    }));
  }, [feedResultHis]);

  const onSearchTextUpcoming = useCallback((e: any) => {
    const text = e.target.value;
    setSearchTextUpComing(text);
  }, []);

  const onClearSearchTextUpcoming = useCallback((e: any) => {
    setSearchTextUpComing("");
  }, []);

  const onSearchTextProgress = useCallback((e: any) => {
    const text = e.target.value;
    setSearchTextProgress(text);
  }, []);

  const onClearSearchTextProgress = useCallback((e: any) => {
    setSearchTextProgress("");
  }, []);

  const onSearchTextHistory = useCallback((e: any) => {
    const text = e.target.value;
    setSearchTextHistory(text);
  }, []);

  const onClearSearchTextHistory = useCallback((e: any) => {
    setSearchTextHistory("");
  }, []);

  const onShowCreateReport = useCallback(() => {
    setIsShowCreateReport(true);
  }, []);

  const onHideCreateReport = useCallback(() => {
    setIsShowCreateReport(false);
  }, []);

  const onChangeTab = useCallback((e: any, index: number) => {
    setTabIndex(index);
  }, []);

  /////////////////////////////////// CalenderUp ///////////////////////////////////

  const [isOpenCalenderShippingDate, setIsOpenCalenderShippingDate] =
    React.useState<boolean>(false);

  const refCalendarRangeShippingDate = React.useRef<
    React.ElementRef<typeof DateRangePickerPopover>
  >({
    clearData: () => {},
    onOpen: () => {},
  });

  const handleSelectShippingDate = (dateRange: any[]) => {
    const formattedStartDate = dayjs(
      dateRange[0].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    const formattedEndDate = dayjs(
      dateRange[1].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    setShippingStartDate(formattedStartDate);
    setShippingEndDate(formattedEndDate);
  };

  const handleCloseCalenderShippingDate = () => {
    setIsOpenCalenderShippingDate(false);
  };

  const handleCalenderSaveShippingDate = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: any[]
  ) => {
    event?.stopPropagation();
    const formattedStartDate = dayjs(value[0].startDate).format(
      FILTER_DATE_FORMAT
    );
    const formattedEndDate = dayjs(value[0].endDate).format(FILTER_DATE_FORMAT);
    setShippingStartDate(formattedStartDate);
    setShippingEndDate(formattedEndDate);
  };

  const [isOpenCalenderOrderDate, setIsOpenCalenderOrderDate] =
    React.useState<boolean>(false);

  const refCalendarRangeOrderDate = React.useRef<
    React.ElementRef<typeof DateRangePickerPopover>
  >({
    clearData: () => {},
    onOpen: () => {},
  });

  const handleSelectOrderDate = (dateRange: any[]) => {
    const formattedStartDate = dayjs(
      dateRange[0].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    const formattedEndDate = dayjs(
      dateRange[1].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    setOrderStartDate(formattedStartDate);
    setOrderEndDate(formattedEndDate);
  };

  const handleCloseCalenderOrderDate = () => {
    setIsOpenCalenderOrderDate(false);
  };

  const handleCalenderSaveOrderDate = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: any[]
  ) => {
    event?.stopPropagation();
    const formattedStartDate = dayjs(value[0].startDate).format(
      FILTER_DATE_FORMAT
    );
    const formattedEndDate = dayjs(value[0].endDate).format(FILTER_DATE_FORMAT);
    setOrderStartDate(formattedStartDate);
    setOrderEndDate(formattedEndDate);
  };

  /////////////////////////////////// CalenderIn ///////////////////////////////////

  const [isOpenCalenderShippingDateIn, setIsOpenCalenderShippingDateIn] =
    React.useState<boolean>(false);

  const refCalendarRangeShippingDateIn = React.useRef<
    React.ElementRef<typeof DateRangePickerPopover>
  >({
    clearData: () => {},
    onOpen: () => {},
  });

  const handleSelectShippingDateIn = (dateRange: any[]) => {
    const formattedStartDate = dayjs(
      dateRange[0].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    const formattedEndDate = dayjs(
      dateRange[1].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);

    setShippingStartDateIn(formattedStartDate);
    setShippingEndDateIn(formattedEndDate);
  };

  const handleCloseCalenderShippingDateIn = () => {
    setIsOpenCalenderShippingDateIn(false);
  };

  const handleCalenderSaveShippingDateIn = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: any[]
  ) => {
    event?.stopPropagation();
    const formattedStartDate = dayjs(value[0].startDate).format(
      FILTER_DATE_FORMAT
    );
    const formattedEndDate = dayjs(value[0].endDate).format(FILTER_DATE_FORMAT);

    setShippingStartDateIn(formattedStartDate);
    setShippingEndDateIn(formattedEndDate);
  };

  const [isOpenCalenderOrderDateIn, setIsOpenCalenderOrderDateIn] =
    React.useState<boolean>(false);

  const refCalendarRangeOrderDateIn = React.useRef<
    React.ElementRef<typeof DateRangePickerPopover>
  >({
    clearData: () => {},
    onOpen: () => {},
  });

  const handleSelectOrderDateIn = (dateRange: any[]) => {
    const formattedStartDate = dayjs(
      dateRange[0].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    const formattedEndDate = dayjs(
      dateRange[1].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);

    setOrderStartDateIn(formattedStartDate);
    setOrderEndDateIn(formattedEndDate);
  };

  const handleCloseCalenderOrderDateIn = () => {
    setIsOpenCalenderOrderDateIn(false);
  };

  const handleCalenderSaveOrderDateIn = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: any[]
  ) => {
    event?.stopPropagation();
    const formattedStartDate = dayjs(value[0].startDate).format(
      FILTER_DATE_FORMAT
    );
    const formattedEndDate = dayjs(value[0].endDate).format(FILTER_DATE_FORMAT);

    setOrderStartDateIn(formattedStartDate);
    setOrderEndDateIn(formattedEndDate);
  };

  /////////////////////////////////// CalenderHis ///////////////////////////////////

  const [isOpenCalenderShippingDateHis, setIsOpenCalenderShippingDateHis] =
    React.useState<boolean>(false);

  const refCalendarRangeShippingDateHis = React.useRef<
    React.ElementRef<typeof DateRangePickerPopover>
  >({
    clearData: () => {},
    onOpen: () => {},
  });

  const handleSelectShippingDateHis = (dateRange: any[]) => {
    const formattedStartDate = dayjs(
      dateRange[0].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    const formattedEndDate = dayjs(
      dateRange[1].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);

    setShippingStartDateHis(formattedStartDate);
    setShippingEndDateHis(formattedEndDate);
  };

  const handleCloseCalenderShippingDateHis = () => {
    setIsOpenCalenderShippingDateHis(false);
  };

  const handleCalenderSaveShippingDateHis = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: any[]
  ) => {
    event?.stopPropagation();
    const formattedStartDate = dayjs(value[0].startDate).format(
      FILTER_DATE_FORMAT
    );
    const formattedEndDate = dayjs(value[0].endDate).format(FILTER_DATE_FORMAT);

    setShippingStartDateHis(formattedStartDate);
    setShippingEndDateHis(formattedEndDate);
  };

  const [isOpenCalenderOrderDateHis, setIsOpenCalenderOrderDateHis] =
    React.useState<boolean>(false);

  const refCalendarRangeOrderDateHis = React.useRef<
    React.ElementRef<typeof DateRangePickerPopover>
  >({
    clearData: () => {},
    onOpen: () => {},
  });

  const handleSelectOrderDateHis = (dateRange: any[]) => {
    const formattedStartDate = dayjs(
      dateRange[0].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    const formattedEndDate = dayjs(
      dateRange[1].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);

    setOrderStartDateHis(formattedStartDate);
    setOrderEndDateHis(formattedEndDate);
  };

  const handleCloseCalenderOrderDateHis = () => {
    setIsOpenCalenderOrderDateHis(false);
  };

  const handleCalenderSaveOrderDateHis = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: any[]
  ) => {
    event?.stopPropagation();
    const formattedStartDate = dayjs(value[0].startDate).format(
      FILTER_DATE_FORMAT
    );
    const formattedEndDate = dayjs(value[0].endDate).format(FILTER_DATE_FORMAT);
    setOrderStartDateHis(formattedStartDate);
    setOrderEndDateHis(formattedEndDate);
  };

  return {
    tabIndex,
    setTabIndex,
    onChangeTab,
    onShowCreateReport,
    onHideCreateReport,
    isShowCreateReport,
    listClaimOrder,
    listClaimOrderProgress,
    listClaimOrderHistory,
    currentPageUpComing,
    setCurrentPageUpComing,
    currentPageProgress,
    setCurrentPageProgress,
    currentPageHistory,
    setCurrentPageHistory,
    refetchListClaimOrderUpcoming,
    refetchListClaimOrderProgress,
    refetchListClaimOrderHistory,
    searchTextUpComing,
    onSearchTextUpcoming,
    onClearSearchTextUpcoming,
    searchTextProgress,
    onSearchTextProgress,
    onClearSearchTextProgress,
    searchTextHistory,
    onSearchTextHistory,
    onClearSearchTextHistory,
    setSearchTextUpComing,
    setSearchTextProgress,
    setSearchTextHistory,
    //////////////////////////// DropdownUp ////////////////////////////
    brandsOptionsUpComing,
    brandUpComing,
    setBrandUpComing,
    feedOptionsUpComing,
    feedUpComing,
    setFeedUpComing,
    //////////////////////////// DropdownIn ////////////////////////////
    brandsOptionsInProgress,
    brandPageInProgress,
    setBrandPageInProgress,
    feedOptionsInProgress,
    feedPageInProgress,
    setFeedPageInProgress,
    //////////////////////////// DropdownHis ////////////////////////////
    brandsOptionsHistory,
    brandPageHistory,
    setBrandPageHistory,
    feedOptionsHistory,
    feedPageHistory,
    setFeedPageHistory,
    /////////////////////////////////// DateUp ///////////////////////////////////
    shippingStartDate,
    setShippingStartDate,
    shippingEndDate,
    setShippingEndDate,
    orderStartDate,
    setOrderStartDate,
    orderEndDate,
    setOrderEndDate,
    itemSelectedUpComing,
    setItemSelectedUpComing,
    currentOrderByKey1,
    setCurrentOrderByKey1,
    orderByType1,
    setOrderByType1,
    onChangeFormUpComing,
    /////////////////////////////////// DateIn ///////////////////////////////////
    shippingStartDateIn,
    setShippingStartDateIn,
    shippingEndDateIn,
    setShippingEndDateIn,
    orderStartDateIn,
    setOrderStartDateIn,
    orderEndDateIn,
    setOrderEndDateIn,
    itemSelectedInProgress,
    setItemSelectedInProgress,
    currentOrderByKey2,
    setCurrentOrderByKey2,
    orderByType2,
    setOrderByType2,
    onChangeFormInProgress,
    /////////////////////////////////// DateHis ///////////////////////////////////
    shippingStartDateHis,
    setShippingStartDateHis,
    shippingEndDateHis,
    setShippingEndDateHis,
    orderStartDateHis,
    setOrderStartDateHis,
    orderEndDateHis,
    setOrderEndDateHis,
    itemSelectedHistory,
    setItemSelectedHistory,
    currentOrderByKey3,
    setCurrentOrderByKey3,
    orderByType3,
    setOrderByType3,
    onChangeFormHistory,
    /////////////////////////////////// CalenderUp ///////////////////////////////////
    isOpenCalenderShippingDate,
    setIsOpenCalenderShippingDate,
    refCalendarRangeShippingDate,
    handleSelectShippingDate,
    handleCloseCalenderShippingDate,
    handleCalenderSaveShippingDate,
    isOpenCalenderOrderDate,
    setIsOpenCalenderOrderDate,
    refCalendarRangeOrderDate,
    handleSelectOrderDate,
    handleCloseCalenderOrderDate,
    handleCalenderSaveOrderDate,
    /////////////////////////////////// CalenderIn ///////////////////////////////////
    isOpenCalenderShippingDateIn,
    setIsOpenCalenderShippingDateIn,
    refCalendarRangeShippingDateIn,
    handleSelectShippingDateIn,
    handleCloseCalenderShippingDateIn,
    handleCalenderSaveShippingDateIn,
    isOpenCalenderOrderDateIn,
    setIsOpenCalenderOrderDateIn,
    refCalendarRangeOrderDateIn,
    handleSelectOrderDateIn,
    handleCloseCalenderOrderDateIn,
    handleCalenderSaveOrderDateIn,
    /////////////////////////////////// CalenderHis ///////////////////////////////////
    isOpenCalenderShippingDateHis,
    setIsOpenCalenderShippingDateHis,
    refCalendarRangeShippingDateHis,
    handleSelectShippingDateHis,
    handleCloseCalenderShippingDateHis,
    handleCalenderSaveShippingDateHis,
    isOpenCalenderOrderDateHis,
    setIsOpenCalenderOrderDateHis,
    refCalendarRangeOrderDateHis,
    handleSelectOrderDateHis,
    handleCloseCalenderOrderDateHis,
    handleCalenderSaveOrderDateHis,
  };
};

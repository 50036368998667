import api from "./ApiService";
import {
  IGetAllFeedStock,
  IGetAllMedicine,
  IGetAllVaccine,
  IGetHistoryFeedStock,
  IGetHistoryMedicineStock,
  IGetHistoryOtherStock,
  IGetHistoryVaccineStock,
  IGetListFeedStock,
  IGetListMedicineStock,
  IGetListOtherStock,
  IGetListVaccineStock,
} from "../types/StockMangement.type";
import { IGetMessage } from "../types/AccountCenter.type";
import { IGetUploadUrl } from "../types/ManageStock.type";

interface files {
  fileId: string;
  fileName: string;
}

export const getAllFeedStock = async () => {
  const { data } = await api.get<IGetAllFeedStock>(
    `/farmers/stock/feed-brands`
  );
  return data;
};

export const getListFeedStock = async (params: { farmId: string }) => {
  const { data } = await api.get<IGetListFeedStock>(`/farmers/stock/feed`, {
    params: {
      farmId: params.farmId,
    },
  });
  return data;
};

export const createFeedStock = async (body: {
  farmId: string;
  addDate: Date;
  feedBrandId: number;
  feedBrandItemId: number;
  feedUnit: string;
  amount: number;
  price: number;
  documents: files[] | [];
}) => {
  const { data } = await api.post<IGetMessage>(`/farmers/stock/feed`, body);
  return data;
};

export const removeFeedStock = async (body: {
  feedStockId: string;
  addDate: Date;
  amount: number;
}) => {
  const { data } = await api.delete<IGetMessage>(
    `/farmers/stock/feed/${body.feedStockId}`,
    { data: body }
  );
  return data;
};

export const getHistoryFeedStock = async (
  feedStockId: string,
  params?: {
    direction?: "desc" | "asc";
    startDate?: Date;
    endDate?: Date;
  }
) => {
  const { data } = await api.get<IGetHistoryFeedStock>(
    `/farmers/stock/feed/history/${feedStockId}`,
    {
      params: {
        direction: params?.direction || "desc",
        startDate: params?.startDate?.toISOString(),
        endDate: params?.endDate?.toISOString(),
      },
    }
  );
  return data;
};

////// Vaccine //////

export const getAllVaccineStock = async () => {
  const { data } = await api.get<IGetAllVaccine>(`/vaccines`);
  return data;
};

export const getListVaccineStock = async (params: { farmId: string }) => {
  const { data } = await api.get<IGetListVaccineStock>(
    `/farmers/stock/vaccine`,
    {
      params: {
        farmId: params.farmId,
      },
    }
  );
  return data;
};

export const createVaccineStock = async (body: {
  farmId: string;

  addDate: Date;
  vaccineId: number;
  unit: string;
  amount: number;
  price: number;
  documents: files[] | [];
}) => {
  const { data } = await api.post<IGetMessage>(`/farmers/stock/vaccine`, body);
  return data;
};

export const removeVaccineStock = async (body: {
  farmerStockVaccineId: string;
  addDate: Date;
  amount: number;
}) => {
  const { data } = await api.delete<IGetMessage>(
    `/farmers/stock/vaccine/${body.farmerStockVaccineId}`,
    { data: body }
  );
  return data;
};

export const getHistoryVaccineStock = async (
  farmerStockVaccineId: string,
  params?: {
    direction?: "desc" | "asc";
    startDate?: Date;
    endDate?: Date;
  }
) => {
  const { data } = await api.get<IGetHistoryVaccineStock>(
    `/farmers/stock/vaccine/history/${farmerStockVaccineId}`,
    {
      params: {
        direction: params?.direction || "desc",
        startDate: params?.startDate?.toISOString(),
        endDate: params?.endDate?.toISOString(),
      },
    }
  );
  return data;
};

////// Medicine //////

export const getAllMedicineStock = async () => {
  const { data } = await api.get<IGetAllMedicine>(`/medicines`);
  return data;
};

export const getListMedicineStock = async (params: { farmId: string }) => {
  const { data } = await api.get<IGetListMedicineStock>(
    `/farmers/stock/medicine`,
    {
      params: {
        farmId: params.farmId,
      },
    }
  );
  return data;
};

export const createMedicineStock = async (body: {
  farmId: string;

  addDate: Date;
  medicineCategoryId: number | 16;
  medicineDetailId: number | null;
  name: string;
  unit: string;
  amount: number;
  price: number;
  documents: files[] | [];
}) => {
  const { data } = await api.post<IGetMessage>(`/farmers/stock/medicine`, body);
  return data;
};

export const removeMedicineStock = async (body: {
  farmerStockMedicineId: string;
  addDate: Date;
  amount: number;
}) => {
  const { data } = await api.delete<IGetMessage>(
    `/farmers/stock/medicine/${body.farmerStockMedicineId}`,
    { data: body }
  );
  return data;
};

export const getHistoryMedicineStock = async (
  farmerStockMedicineId: string,
  params?: {
    direction?: "desc" | "asc";
    startDate?: Date;
    endDate?: Date;
  }
) => {
  const { data } = await api.get<IGetHistoryMedicineStock>(
    `/farmers/stock/medicine/history/${farmerStockMedicineId}`,
    {
      params: {
        direction: params?.direction || "desc",
        startDate: params?.startDate?.toISOString(),
        endDate: params?.endDate?.toISOString(),
      },
    }
  );
  return data;
};

////// Other //////

export const getListOtherStock = async (params: { farmId: string }) => {
  const { data } = await api.get<IGetListOtherStock>(`/farmers/stock/other`, {
    params: {
      farmId: params.farmId,
    },
  });
  return data;
};

export const createOtherStock = async (body: {
  farmId: string;

  addDate: Date;
  stockName: string;
  unit: string;
  amount: number;
  price: number;
  documents: files[] | [];
}) => {
  const { data } = await api.post<IGetMessage>(`/farmers/stock/other`, body);
  return data;
};

export const removeOtherStock = async (body: {
  otherStockId: string;
  addDate: Date;
  amount: number;
}) => {
  const { data } = await api.delete<IGetMessage>(
    `/farmers/stock/other/${body.otherStockId}`,
    { data: body }
  );
  return data;
};

export const getHistoryOtherStock = async (params?: {
  direction?: "desc" | "asc";
  startDate?: Date;
  endDate?: Date;
  farmId: string;
}) => {
  const { data } = await api.get<IGetHistoryOtherStock>(
    `/farmers/stock/other/history`,
    {
      params: {
        direction: params?.direction || "desc",
        startDate: params?.startDate?.toISOString(),
        endDate: params?.endDate?.toISOString(),
        farmId: params?.farmId,
      },
    }
  );
  return data;
};

////// UploadUrl //////

export const getUpLoadUrlStockManagement = async () => {
  const { data } = await api.get<IGetUploadUrl>(`/farmers/stock/upload`);
  return data;
};

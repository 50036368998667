import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export function RestOfFarmsModal({
  open,
  farms,
  handleClose,
  onFarmClick,
}: any) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      sx={{ borderRadius: "2.5rem" }}
      PaperProps={{ sx: { borderRadius: "2.5rem" } }}
    >
      <DialogTitle id="alert-dialog-title" sx={{ paddingBottom: 0 }}>
        <div className="flex grow justify-end">
          <CloseIcon onClick={handleClose} />
        </div>
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <List component="nav" aria-label="main mailbox folders">
          {farms?.map((farm: any) => {
            return (
              <div key={farm.id}>
                <ListItemButton
                  onClick={(event) => onFarmClick?.(farm)}
                  sx={{ paddingX: "1.5rem", paddingY: "1rem" }}
                >
                  <ListItemText className="capitalize" primary={farm?.name} />
                </ListItemButton>
                <Divider />
              </div>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
}

import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { tw } from "../../../../utils/tw";
import { InputAdornment, Modal, Tab, Typography } from "@mui/material";
import AppBarFS from "../../../../components/app-bar/AppBar";
import DatePickerFS from "../../../../components/common/DatePicker";
import EventIcon from "@mui/icons-material/Event";
import { Dropdown } from "../../../../admin/pages/group-area/views/Dropdown";
import { Input } from "../../../../admin/pages/group-area/views/Input";
import TextInputCustom from "../../../../admin/components/Input/TextInputCustom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { InputImage } from "./InputImage";
import ButtonFillCustom from "../../../../admin/components/Button/ButtonFillCustom";
import TextAreaCustom from "../../../../admin/components/Input/TextAreaCustom";
import { defaultFormOtherStock, TFormOtherStock } from "../defaultForm.type";
import * as Y from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import {
  createOtherStock,
  getUpLoadUrlStockManagement,
  removeOtherStock,
} from "../../../../services/StockManagement.service";
import { loadingActions } from "../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../utils/getErrorMessage";
import useFormValidator from "../../../../hooks/useFormValidator.hook";
import dayjs from "dayjs";
import { FILTER_DATE_FORMAT } from "../../../../admin/pages/sale-order/components/SaleOrderSearchFilter";
import axios from "axios";
import { RowListOtherStock } from "../../../../types/StockMangement.type";
import { get, isEmpty, some } from "lodash";
import { getCommaNumber } from "../../../../utils/number";
import Tabs from "../../../../admin/pages/group-area/views/Tabs";

interface IAddOtherStockModal {
  isOpen: boolean;
  onClose: () => void;
  form: TFormOtherStock;
  setForm: Dispatch<SetStateAction<TFormOtherStock>>;
  onChangeForm: (key: keyof TFormOtherStock) => (value: any) => void;
  itemEdit: RowListOtherStock[];
  isEdit: boolean;
  onCloseEdit: () => void;
  setIdEdit: Dispatch<SetStateAction<string>>;
  farmId: string;
}

const classes = {
  wrapper: tw(`!z-0 w-full h-full overflow-scroll !bg-[white]`),
  container: tw(`w-full h-full bg-[white] relative table`),
  headerContainer: tw(`min-h-[120px] flex items-center rounded-b-[40px]`),
  detailsContainer: tw(`flex flex-col px-4 gap-4`),
  button: tw(`!h-[59px] w-full !rounded-[50px] !text-[16px] !font-bold`),
};

const AddOtherStockModal = (props: IAddOtherStockModal) => {
  const {
    isOpen,
    onClose,
    form,
    setForm,
    onChangeForm,
    itemEdit,
    isEdit,
    onCloseEdit,
    setIdEdit,
    farmId,
  } = props;

  const maxAmount = get(itemEdit, [0, "amount"], 0);

  const errorSchema = Y.object({
    stockName: Y.string()
      .required("กรุณากรอกชื่อสินค้า")
      .max(50, "สามารถกรอกได้ไม่เกิน 50 ตัวอักษร"),
    unit: Y.string().required("กรุณากรอกหน่วยของสินค้า"),
    amount: Y.string()
      .required("กรุณากรอกจำนวนสินค้า")
      .test("is-greater-than-zero", "จำนวนสินค้าต้องมากกว่า 0", (value) => {
        const parsedValue = parseFloat(value || "");
        return !isNaN(parsedValue) && parsedValue > 0;
      }),
    price: Y.string().required("กรุณากรอกราคาสินค้า"),
  });

  const errorSchemaEdit = Y.object({
    amount: Y.string()
      .required("กรุณากรอกจำนวนสินค้า")
      .test("is-greater-than-zero", "จำนวนสินค้าต้องมากกว่า 0", (value) => {
        const parsedValue = parseFloat(value || "");
        return !isNaN(parsedValue) && parsedValue > 0;
      }),
    price: Y.string().required("กรุณากรอกราคาสินค้า"),
  });

  const errorSchemaRemove = Y.object({
    amount: Y.string()
      .required("กรุณากรอกจำนวนสินค้า")
      .test("is-greater-than-zero", "จำนวนสินค้าต้องมากกว่า 0", (value) => {
        const parsedValue = parseFloat(value || "");
        return !isNaN(parsedValue) && parsedValue > 0;
      })
      .test(
        "is-less-than-max",
        `จำนวนต้องไม่มากกว่าจำนวนสินค้าที่มี ${getCommaNumber(
          maxAmount,
          true
        )}`,
        (value) => {
          const parsedValue = parseFloat(value || "");
          return !isNaN(parsedValue) && parsedValue <= maxAmount;
        }
      ),
  });

  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();

  const [tabIndex, setTabIndex] = useState(0);

  const onChangeTab = useCallback((e: any, index: number) => {
    setTabIndex(index);
  }, []);

  const [images, setImages] = useState<(File | { id: string; url: string })[]>(
    []
  );

  const onAddImage = useCallback(
    (type: "image") => (images: File[]) => {
      const maxFile = 2;
      setImages((prev) => [...images, ...prev].slice(0, maxFile));
    },
    []
  );

  const onRemoveImage = useCallback(
    (type: "image", index: number) => () => {
      setImages((prev) => prev.filter((_, i) => i !== index));
    },
    []
  );

  const CreateOtherStockMutation = useMutation({
    mutationFn: createOtherStock,
    onSuccess: async (data) => {
      if (data.success) {
        await queryClient.refetchQueries({
          queryKey: ["list_other_stock"],
        });
        setTimeout(() => {
          onCloseFn();
          dispatch(loadingActions.hide());
          setTimeout(() => {
            dispatch(
              snakeActions.showMessage({
                message: isEdit ? "Edit Success!" : "Create Success!",
                type: "success",
              })
            );
          }, 500);
        });
      } else {
        setTimeout(() => {
          dispatch(loadingActions.hide());
          dispatch(
            snakeActions.showMessage({
              message: getErrorMessage(data.message),
              type: "error",
            })
          );
        }, 1500);
      }
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const RemoveOtherStockMutation = useMutation({
    mutationFn: removeOtherStock,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["list_other_stock"],
      });
      setTimeout(() => {
        onCloseFn();
        dispatch(loadingActions.hide());
        setTimeout(() => {
          dispatch(
            snakeActions.showMessage({
              message: "Remove Success!",
              type: "success",
            })
          );
        }, 500);
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const { onSubmit, errors, resetError } = useFormValidator({
    form: {
      ...form,
    },
    errorSchema: isEdit ? errorSchemaEdit : errorSchema,
    onValidateSuccess: async () => {
      const imgs = [...images];
      const fileArray: {
        id: string;
        url: string;
        fileName: string;
        fileSize: number;
      }[] = [];
      dispatch(loadingActions.show({ message: "loading..." }));
      try {
        for (let i = 0; i < imgs.length; i++) {
          const img = imgs[i];
          if (img instanceof File) {
            // upload new image
            const presign = await getUpLoadUrlStockManagement();
            await axios.put(presign.blob.blobUrl, img, {
              responseType: "blob",
              headers: {
                "x-ms-blob-type": "BlockBlob",
              },
            });
            fileArray.push({
              id: presign.blob.fileId,
              url: presign.blob.url || "",
              fileName: img.name,
              fileSize: img.size,
            });
          }
        }
        CreateOtherStockMutation.mutate({
          farmId: farmId,
          addDate: dayjs(form.date, FILTER_DATE_FORMAT).startOf("day").toDate(),
          stockName: isEdit ? itemEdit[0].name : form.stockName,
          unit: isEdit ? itemEdit[0].unit : form.unit,
          amount: +form.amount,
          price: +form.price,
          documents: fileArray.map((f) => ({
            fileId: f.id,
            fileName: f.fileName,
          })),
        });
      } catch (e) {
        setTimeout(() => {
          dispatch(loadingActions.hide());
          dispatch(
            snakeActions.showMessage({
              message: getErrorMessage(e),
              type: "error",
            })
          );
        }, 1500);
      }
    },
  });

  const {
    onSubmit: onRemove,
    errors: errorsRemove,
    resetError: resetErrorRemove,
  } = useFormValidator({
    form: {
      ...form,
    },
    errorSchema: errorSchemaRemove,
    onValidateSuccess: () => {
      dispatch(loadingActions.show({ message: "loading..." }));
      RemoveOtherStockMutation.mutate({
        otherStockId: itemEdit[0].id,
        addDate: dayjs(form.date, FILTER_DATE_FORMAT).startOf("day").toDate(),
        amount: +form.amount,
      });
    },
  });

  const onCloseFn = useCallback(() => {
    onClose();
    onCloseEdit();
    setForm(defaultFormOtherStock);
    setImages([]);
    resetError();
    setIdEdit("");
    setTabIndex(0);
    resetError();
    resetErrorRemove();
  }, []);

  useEffect(() => {
    setForm(defaultFormOtherStock);
    setImages([]);
    resetError();
    resetErrorRemove();
  }, [tabIndex]);

  return (
    <Modal open={isOpen} className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <AppBarFS
            title={isEdit ? "Edit Other Stock" : "Add Other Stock"}
            onIconPress={onCloseFn}
          />
        </div>
        <div className={classes.detailsContainer}>
          {isEdit ? (
            <div className="flex flex-col gap-3 pb-4">
              <div className="text-[#34407B] text-[20px] font-semibold">
                {get(itemEdit, [0, "name"], "")}
              </div>
              <div className="bg-[#F6FBFF] py-3 border-[2px] border-[#3777BC] rounded-[20px] flex flex-col gap-4 items-center">
                <div className="font-bold text-[16px] text-[#3777BC]">
                  สต๊อกคงเหลือ
                </div>
                <div className="font-bold text-[22px] text-[#34407B]">
                  {getCommaNumber(get(itemEdit, [0, "amount"], 0), true)}{" "}
                  {get(itemEdit, [0, "unit"], "")}
                </div>
              </div>
            </div>
          ) : (
            <div className="text-[#3777BC] text-[20px] font-semibold">
              Add Other Stock
            </div>
          )}
          {isEdit && (
            <div className="flex flex-row justify-center">
              <Tabs tabIndex={tabIndex} onChange={onChangeTab} isScroll>
                <Tab
                  label="Add Stock"
                  className="!font-semibold !text-[18px]"
                />
                <Tab
                  label="Remove Stock"
                  className="!font-semibold !text-[18px]"
                />
              </Tabs>
            </div>
          )}
          {tabIndex === 0 && (
            <>
              <DatePickerFS
                value={form.date}
                label="Date"
                placeholder={`Enter Date`}
                className="!h-[56px]"
                slotProps={{
                  textField: {
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <EventIcon color="info" />
                        </InputAdornment>
                      ),
                    },
                  },
                }}
                onChange={onChangeForm("date")}
                onClose={() => {}}
              />
              {!isEdit && (
                <div className="flex flex-col gap-1">
                  <div className="flex flex-row justify-between px-[18px]">
                    <div
                      className={tw(
                        "text-[#3777BC] text-[12px] font-normal",
                        errors.stockName && "text-[#d32f2f]"
                      )}
                    >
                      ชื่อสินค้า
                    </div>
                    <div className="text-[#979797] text-[12px] font-normal">
                      {+form.stockName.valueOf().length}/50
                    </div>
                  </div>
                  <TextAreaCustom
                    value={form.stockName}
                    onChange={(e: any) =>
                      onChangeForm("stockName")(e.target.value)
                    }
                    placeholder="ชื่อสินค้า"
                    className={tw(
                      "bg-[#F6FBFF] !p-[10px] !rounded-[20px] !border-[1px] !border-[#3777BC]",
                      errors.stockName && "!border-[#d32f2f]"
                    )}
                    errormessage={errors.stockName || ""}
                  />
                </div>
              )}
              <Input
                label2="จำนวน"
                focused
                placeholder="จำนวน"
                value={form.amount}
                onChangeText={onChangeForm("amount")}
                inputType="number"
                customInputStyle={tw(
                  "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
                )}
                containerDetailClassName="pb-0"
                borderRadius={"28px"}
                errorMessage={errors.amount || ""}
              />
              {isEdit ? (
                <TextInputCustom
                  focused={true}
                  disabled
                  label={"หน่วย"}
                  placeholder="หน่วย"
                  value={form.unit || get(itemEdit, [0, "unit"], "")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon color="disabled" fontSize="small" />
                      </InputAdornment>
                    ),
                    style: {
                      border: "1px",
                    },
                    className:
                      "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] !border-[1px]",
                    sx: {
                      borderRadius: "28px !important",
                      "& .MuiInputBase-input": {
                        padding: 0,
                        color: "#979797 !important",
                      },
                      "& .Mui-disabled": {
                        color: "#979797  !important",
                        WebkitTextFillColor: "#979797  !important",
                        bgcolor: "#FBFBFB !important",
                      },
                    },
                  }}
                />
              ) : (
                <Input
                  label2="หน่วย"
                  focused
                  placeholder="หน่วย"
                  value={form.unit}
                  onChangeText={onChangeForm("unit")}
                  customInputStyle={tw(
                    "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
                  )}
                  containerDetailClassName="pb-0"
                  borderRadius={"28px"}
                  errorMessage={errors.unit || ""}
                />
              )}
              <Input
                label2="ราคาทั้งหมด"
                focused
                placeholder="ราคา"
                value={form.price}
                onChangeText={onChangeForm("price")}
                inputType="number"
                customInputStyle={tw(
                  "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
                )}
                containerDetailClassName="pb-0"
                borderRadius={"28px"}
                borderColor={"#3777BC !important"}
                customSuffix={
                  <div className="pr-[5px] font-medium text-[16px] leading-[24px] text-[#D9EBFE]">
                    | <span className="text-[#3777BC]">บาท</span>
                  </div>
                }
                errorMessage={errors.price || ""}
              />
              <InputImage
                onDrop={onAddImage("image")}
                maxFiles={2}
                images={images}
                onRemove={onRemoveImage}
              />
              <div className="w-full pt-1 pb-6">
                <ButtonFillCustom
                  onClick={onSubmit}
                  title="ยืนยัน"
                  btnTextColor="#ffffff"
                  btnBgColor="#68C184"
                  className={classes.button}
                  disabled={
                    isEdit
                      ? some([form.amount, form.price], isEmpty)
                      : some(
                          [form.amount, form.price, form.stockName, form.unit],
                          isEmpty
                        )
                  }
                />
              </div>
            </>
          )}
          {tabIndex === 1 && (
            <>
              <DatePickerFS
                value={form.date}
                label="Date"
                placeholder={`Enter Date`}
                className="!h-[56px]"
                slotProps={{
                  textField: {
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <EventIcon color="info" />
                        </InputAdornment>
                      ),
                    },
                  },
                }}
                onChange={onChangeForm("date")}
                onClose={() => {}}
              />
              <Input
                label2="จำนวน"
                focused
                placeholder="จำนวน"
                value={form.amount}
                onChangeText={onChangeForm("amount")}
                inputType="number"
                customInputStyle={tw(
                  "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
                )}
                containerDetailClassName="pb-0"
                borderRadius={"28px"}
                errorMessage={errorsRemove.amount}
              />
              <TextInputCustom
                focused={true}
                disabled
                label={"หน่วย"}
                placeholder="หน่วย"
                value={form.unit || get(itemEdit, [0, "unit"], "")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon color="disabled" fontSize="small" />
                    </InputAdornment>
                  ),
                  style: {
                    border: "1px",
                  },
                  className:
                    "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] !border-[1px]",
                  sx: {
                    borderRadius: "28px !important",
                    "& .MuiInputBase-input": {
                      padding: 0,
                      color: "#979797 !important",
                    },
                    "& .Mui-disabled": {
                      color: "#979797  !important",
                      WebkitTextFillColor: "#979797  !important",
                      bgcolor: "#FBFBFB !important",
                    },
                  },
                }}
              />
              <div className="w-full pt-1 pb-6">
                <ButtonFillCustom
                  onClick={onRemove}
                  title="ยืนยัน"
                  btnTextColor="#ffffff"
                  btnBgColor="#68C184"
                  className={classes.button}
                  disabled={some([form.amount], isEmpty)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default memo(AddOtherStockModal);

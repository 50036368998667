import { Link, SvgIcon } from "@mui/material";
import StockImage from "../../../../assets/images/stock-img.png";
import PaperClip from "../../../../assets/svg/PaperClip";
import ImagePreview from "../../../../components/common/ImagePreviewWithModal/ImagePreview";
import { ITableRowDetail } from "../../../../types/EditOrderFeed";
import {
  handleDownloadAzure,
  handleDownloadBlobAzure,
} from "../../../../utils/downloadFile";

const mockFile = [
  {
    url: `https://picsum.photos/2048`,
    name: "example.jpg",
  },
];

const mockImagePreview = [
  {
    url: `https://picsum.photos/512`,
    name: "example.jpg",
  },
  {
    url: `https://picsum.photos/512`,
    name: "example2.jpg",
  },
];

const mockFileStatic = [
  {
    url: StockImage,
    name: "stock-img.png",
  },
];

const mockImagePreviewStatic = [
  {
    url: StockImage,
    name: "example.png",
  },
  {
    url: StockImage,
    name: "example.png",
  },
];

interface DocumentDetailProps {
  order?: ITableRowDetail;
}

function DocumentDetail({ order }: DocumentDetailProps) {
  const customerPickingSlip =
    order?.orderFeedFiles.filter(
      (row) => row.documentType === "customerPickingSlip"
    ) || [];
  const customerInvoice =
    order?.orderFeedFiles.filter(
      (row) => row.documentType === "customerInvoice"
    ) || [];
  const customerReceipt =
    order?.orderFeedFiles.filter(
      (row) => row.documentType === "customerReceipt"
    ) || [];
  const partnerPickingSlipForSale =
    order?.orderFeedFiles.filter(
      (row) => row.documentType === "partnerPickingSlipForSale"
    ) || [];
  const partnerPickingSlip =
    order?.orderFeedFiles.filter(
      (row) => row.documentType === "partnerPickingSlip"
    ) || [];
  const partnerProofOfDelivery =
    order?.orderFeedFiles.filter(
      (row) => row.documentType === "partnerProofOfDelivery"
    ) || [];

  return (
    <div className="flex flex-col gap-8 w-[1024px]">
      <div>
        <p className="text-[1rem] text-secondary font-bold">เอกสาร</p>
        <div className="flex w-full pt-4">
          {/* farmer */}
          <div className="w-1/2">
            <p className="text-tertiary font-bold underline mb-4">ลูกค้า</p>
            <div className="flex flex-col gap-4">
              <div>
                <p className="font-bold">ใบ Picking Slip</p>
                <div className="flex flex-col gap-2 mt-2">
                  {customerPickingSlip.map((row, idx) => (
                    <div key={idx}>
                      <Link
                        underline="hover"
                        component="button"
                        color="#3777BC"
                        className="text-[14px]"
                        onClick={() =>
                          handleDownloadAzure(
                            order?.id || "",
                            row.url,
                            row.fileName
                          )
                        }
                      >
                        <SvgIcon>
                          <PaperClip />
                        </SvgIcon>
                        <span>{row.fileName}</span>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <p className="font-bold">ใบ Invoice</p>
                <div className="flex flex-col gap-2 mt-2">
                  {customerInvoice.map((row, idx) => (
                    <div key={idx}>
                      <Link
                        underline="hover"
                        component="button"
                        color="#3777BC"
                        className="text-[14px]"
                        onClick={() =>
                          handleDownloadAzure(
                            order?.id || "",
                            row.url,
                            row.fileName
                          )
                        }
                      >
                        <SvgIcon>
                          <PaperClip />
                        </SvgIcon>
                        <span>{row.fileName}</span>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <p className="font-bold">ใบเสร็จ</p>
                <div className="flex flex-col gap-2 mt-2">
                  {customerReceipt.map((row, idx) => (
                    <div key={idx}>
                      <Link
                        underline="hover"
                        component="button"
                        color="#3777BC"
                        className="text-[14px]"
                        onClick={() =>
                          handleDownloadAzure(
                            order?.id || "",
                            row.url,
                            row.fileName
                          )
                        }
                      >
                        <SvgIcon>
                          <PaperClip />
                        </SvgIcon>
                        <span>{row.fileName}</span>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {/* partner */}
          <div className="w-1/2">
            <p className="text-tertiary font-bold underline mb-4">พาร์ทเนอร์</p>
            <div>
              <p className="font-bold">ใบส่งของ</p>
              <div className="flex flex-col gap-2 mt-2">
                {partnerPickingSlipForSale.map((row, idx) => (
                  <div key={idx}>
                    <Link
                      underline="hover"
                      component="button"
                      color="#3777BC"
                      className="text-[14px]"
                      onClick={() =>
                        handleDownloadAzure(
                          order?.id || "",
                          row.url,
                          row.fileName
                        )
                      }
                    >
                      <SvgIcon>
                        <PaperClip />
                      </SvgIcon>
                      <span>{row.fileName}</span>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p className="text-[1rem] text-secondary font-bold mb-4">รูปหลักฐาน</p>
        <div className="flex flex-row w-full">
          {/* farmer */}
          <div className="w-1/2">
            <p className="font-bold">หลักฐานการจัดส่ง</p>
            <div>
              <div className="flex flex-row mt-2 gap-2">
                {partnerProofOfDelivery.map((row, index) => (
                  <ImagePreview
                    key={index}
                    id={row.id}
                    url={row.url}
                    useRealImage={true}
                    onDownloadImage={handleDownloadBlobAzure}
                  />
                ))}
              </div>
            </div>
          </div>
          {/* partner */}
          <div className="w-1/2">
            <p className="font-bold">ใบรับของ</p>
            <div>
              <div className="flex flex-row mt-2 gap-2">
                {partnerPickingSlip.map((row, index) => (
                  <ImagePreview
                    key={index}
                    id={row.id}
                    url={row.url}
                    useRealImage={true}
                    onDownloadImage={handleDownloadBlobAzure}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentDetail;

import { Card, CardContent } from "@mui/material";
import { IGetPartnerStockDetail } from "../../../../../types/ManageStock.type";
import { groupBy, map, orderBy, sumBy } from "lodash";

interface props {
  data?: IGetPartnerStockDetail;
}

function PartnerInfoCard(props: props) {
  const { data } = props;

  let userName = `${data?.name} ${data?.surname}`;

  let address = `${data?.address} ${data?.district.nameTh} ${data?.subDistrict.nameTh} ${data?.province.nameTh} ${data?.subDistrict.zipCode}`;

  const groupedByUnit = groupBy(data?.stocks, "fbi.unit");

  const combinedUnits = map(groupedByUnit, (items, unit) => {
    return {
      unit: unit,
      totalAmount: sumBy(items, "amount"),
    };
  });

  const translatedUnits = combinedUnits.map((item) => {
    if (item.unit === "bag") {
      return { ...item, unit: "ถุง" };
    } else if (item.unit === "kg") {
      return { ...item, unit: "กิโลกรัม" };
    }
    return item;
  });

  const sortedUnits = orderBy(translatedUnits, ["unit"], ["desc"]);

  const totalAmountSum = sumBy(sortedUnits, "totalAmount");

  let resultString;
  if (totalAmountSum === 0) {
    resultString = "ไม่มีสินค้าคงเหลือ";
  } else {
    resultString = sortedUnits
      .map((item) => `${item.totalAmount} ${item.unit}`)
      .join(" + ");
  }

  return (
    <Card
      variant="outlined"
      sx={{
        background: "#F6FBFF",
        borderRadius: "20px",
        borderColor: "#E2E2E2",
        color: "#1B2128",
        fontSize: "14px",
      }}
    >
      <CardContent className="flex flex-col gap-4">
        <div className="flex leading-6">
          <div className="w-1/2">
            <p className="font-bold">รหัสพาร์ทเนอร์</p>
            <p>{data?.partnerCode}</p>
          </div>
          <div className="w-1/2">
            <p className="font-bold">ชื่อพาร์ทเนอร์</p>
            <p>{userName}</p>
          </div>
        </div>
        <div className="flex leading-6">
          <div className="w-1/2">
            <p className="font-bold">ที่อยู่</p>
            <p>{address}</p>
          </div>
          <div className="w-1/2">
            <p className="font-bold">จำนวนรวมทั้งหมด</p>
            <p>{resultString}</p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default PartnerInfoCard;

import { memo, useCallback, useState } from "react";
import Dropzone from "react-dropzone";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import Close from "@mui/icons-material/Close";
import { tw } from "../../../../utils/tw";

interface IInputImageProps {
  images: (
    | File
    | {
        id: string;
        url: string;
      }
  )[];
  maxFiles: number;
  onDrop: (images: File[]) => void;
  onRemove: (type: "image", index: number) => () => void;
  placeholder?: string;
}

const classes = {
  image: tw(
    `!w-[98px] !h-[98px] rounded-[17px] hover:brightness-110 duration-150 transition-all relative z-10`
  ),
  remove: tw(
    `absolute top-[5.3px] right-[5.3px] w-[19px] h-[19px] rounded-full z-10 bg-[rgba(255,255,255,0.9)] flex justify-center items-center cursor-pointer`
  ),
  img: tw(`!w-[98px] !h-[98px] object-cover rounded-[17px]`),
};

export const InputImage = memo((props: IInputImageProps) => {
  const {
    images,
    maxFiles,
    onDrop,
    onRemove,
    placeholder = "Upload Receipt (Optional)",
  } = props;

  return (
    <div className="flex flex-col gap-2">
      <Dropzone
        accept={{ "image/*": [] }}
        maxFiles={maxFiles}
        maxSize={100 * 1024 * 1024} // 100mb
        onDrop={onDrop}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className="w-full min-h-[56px] rounded-[28px] border-[1px] border-[#3777BC] bg-[#F6FBFF] flex items-center justify-start px-[16px] py-2 relative"
          >
            <div className="flex flex-row gap-2">
              {images.map((image, index) => {
                const url =
                  image instanceof File
                    ? URL.createObjectURL(image)
                    : image.url;
                return (
                  <div key={`${index}`} className={classes.image}>
                    <div
                      onClick={onRemove("image", index)}
                      className={classes.remove}
                    >
                      <Close className="!text-[#979797] !text-[15px] -mr-[0.6px]" />
                    </div>
                    <img src={url} className={classes.img} />
                  </div>
                );
              })}
            </div>
            <input {...getInputProps()} />
            <div
              className={tw(
                "w-full h-[56px] flex items-center justify-end z-0 absolute pr-[32px] py-2",
                images.length === 0 && "justify-between"
              )}
            >
              {images.length === 0 && (
                <div className="text-[#979797] text-[16px] font-semibold">
                  {placeholder}
                </div>
              )}
              <AddPhotoAlternateIcon className="text-[#3777BC]" />
            </div>
          </div>
        )}
      </Dropzone>
      <div className="px-4 text-[#3777BC] text-[14px] font-normal">
        Maximum 2 Photos, Maximum 50 MB
      </div>
    </div>
  );
});

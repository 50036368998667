import ImageIcon from "@mui/icons-material/Image";
import { useEffect, useRef, useState } from "react";
import { IUploadImageProps } from "./types";
import { Paper } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
const CustomUploadImage = ({
  imageUrl,
  onChange,
  handleRemoveImage,
  index,
  errorMessage,
  t,
}: IUploadImageProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    index && setCurrentIndex(index);
  }, [index]);
  const imageRef = useRef<(HTMLInputElement | null)[]>([]);
  const handleTextInputFSClick = () => {
    const clickedElement = imageRef?.current[currentIndex];
    if (clickedElement) {
      clickedElement.click();
    }
  };

  return (
    <div className="min-h-[55px] px-4 pt-1 pb-3 items-center relative">
      <div className="w-full h-[97px]  overflow-hidden flex relative">
        {imageUrl &&
          imageUrl.length > 0 &&
          imageUrl.map((ele, index) => {
            return (
              <div key={`image-preview-${index}`} className="relative">
                <CloseRoundedIcon
                  style={{
                    position: "absolute",
                    transform: "translate(75px, 5px)",
                    color: "#979797",
                    zIndex: 1,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "100%",
                    border: "0px",
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    handleRemoveImage(ele);
                  }}
                />

                <img
                  src={ele}
                  className="object-cover h-full w-[100px] mx-[5px] rounded-[18px]"
                  alt={"Image Preview - " + ele}
                  style={{
                    objectFit: "cover",
                  }}
                />
              </div>
            );
          })}

        {Array.isArray(imageUrl) && imageUrl?.length <= 1 && (
          <Paper
            elevation={0}
            sx={{
              position: "relative",
              width: "97px",
              height: "97px",
              borderRadius: "18px",
              border: "1px solid #97BFEA",
              bgcolor: "#F6FBFF",
            }}
          >
            <div className="items-center justify-center flex flex-col h-full opacity-50">
              <ImageIcon
                className="w-[24px] h-[24px]"
                style={{ color: "#3777BC", left: 70 }}
                onClick={handleTextInputFSClick}
              />
              <div className="text-sm text-tertiary">
                + {t("moveOutPigSelling.uploadReceipt.titleInfo")}
              </div>
            </div>
          </Paper>
        )}
      </div>

      <div className="text-sm text-tertiary mt-3">
        {t("moveOutPigSelling.input.helperText.picturesOfReceipt")}
      </div>
      <input
        className="hidden"
        ref={(el) => (imageRef.current[currentIndex] = el)}
        type={imageUrl && imageUrl.length >= 2 ? "text" : "file"}
        accept=".jpg, .jpeg, .png, .svg, .heic"
        onChange={onChange}
        multiple={true}
      />
    </div>
  );
};

export default CustomUploadImage;

import { useState } from "react";
import { Order } from "../../../../components/Table/TableCustom";

export const useMoneyDebtFilter = () => {
  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  const [tab, setTab] = useState("");
  const [direction, setDirection] = useState<Order>("desc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [toggleFetch, setToggleFetch] = useState(false);

  return {
    status,
    searchText,
    direction,
    page,
    limit,
    toggleFetch,
    tab,
    setStatus,
    setSearchText,
    setDirection,
    setPage,
    setLimit,
    setToggleFetch,
    setTab,
  };
};

export type TUseMoneyDebtFilter = ReturnType<typeof useMoneyDebtFilter>;

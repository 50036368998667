import { faCircleCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircleIcon from "@mui/icons-material/Circle";
import { Backdrop, CircularProgress, IconButton } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import AppBarFS from "../../components/app-bar/AppBar";
import ConfirmDialog from "../../components/common/CofirmDialog";
import { StepperConnectorFS } from "../../components/common/Stepper";
import { snakeActions } from "../../redux/slices/snakeSlice";
import { AppDispatch } from "../../redux/store";
import {
  addFarmPost,
  checkFarmAddress,
  checkFarmAddressEdit,
  editFarmPut,
  farmDelete,
} from "../../services/FarmService";
import { District, Province, SubDistrict } from "../../services/Geography.type";
import AddFarmAddress from "./add-farm-address";
import AddFarmDetails from "./add-farm-details";

export default function AddFarm() {
  const { provinces: provincesData } = useLoaderData() as any;

  const [activeStep, setActiveStep] = useState<number>(0);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [districts, setDistricts] = useState<District[]>([]);
  const [subDistricts, setSubDistricts] = useState<SubDistrict[]>([]);
  const [zipCode, setZipCode] = useState<string>("");
  const [step1FormData, setStep1FormData] = useState<any>({});
  const [step2FormData, setStep2FormData] = useState<any>(null);
  let navigate = useNavigate();
  const { state } = useLocation();
  // let step1FormData: any;
  const dispatch = useDispatch<AppDispatch>();
  const [editForm, setEditForm] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const { t } = useTranslation();

  const stepsData = [
    {
      // Step 0 - Address
      id: "step-1-address",
      label: t("edit_farm.address"),
      completed: false,
    },
    {
      // Step 1 - Details
      id: "step-2-details",
      label: t("edit_farm.details"),
      completed: false,
    },
  ];
  const [steps, setSteps] = useState<any[]>(stepsData);
  useEffect(() => {
    setInitialState(provincesData);
  }, []);

  async function setInitialState(provincesData: any) {
    if (provincesData) {
      setProvinces(provincesData);
    } else {
      return false;
    }

    if (state && state?.farmDetail) {
      const { farmDetail } = state;
      setEditForm(farmDetail);
      const { provinceCode, districtCode, subDistrictCode } = farmDetail;

      const selectedProvince: Province = provincesData.find(
        (province: Province) => province.code === provinceCode
      );
      let selectedDistrictData: District | undefined =
        selectedProvince?.districts?.find((district: District) => {
          return district.code === districtCode;
        });

      setDistricts(selectedProvince?.districts);
      if (selectedDistrictData) {
        setSubDistricts(selectedDistrictData?.subDistricts);
      }

      setStep1FormData({
        name: farmDetail?.name.trim(),
        gorSorNumber: farmDetail?.gorSorNumber || "",
        latitude: farmDetail?.latitude,
        longitude: farmDetail?.longitude,
        address: farmDetail?.address,
        postalCode: farmDetail?.postalCode,
        provinceCode: farmDetail?.provinceCode,
        districtCode: farmDetail?.districtCode,
        subDistrictCode: farmDetail?.subDistrictCode,
      });

      setStep2FormData({
        establishmentDate: dayjs(farmDetail?.establishmentDate),
        businessType: farmDetail?.businessType,
        partnerRefaralNumber: farmDetail?.partnerRefaralNumber || "",
        farmStatus: farmDetail?.farmStatus,
      });
    }
  }

  const handleBack = (index: number) => {
    if (index < 0) {
      // navigate("/farms");
    }
    if (steps[index]?.completed) {
      setActiveStep(index);
      setSteps((currentSteps: any[]) => {
        return currentSteps.map((step, indexStep: number) => {
          return {
            ...step,
            completed: indexStep < index,
          };
        });
      });
      setZipCode("");
    }
  };

  const CustomStepIcon = (isComplete: boolean, isPending: boolean) => {
    // TODO : MOVE color code to one common themeing
    return isComplete ? (
      <FontAwesomeIcon
        color="secondary"
        className="text-[#68C184]"
        size="lg"
        icon={faCircleCheck}
      />
    ) : (
      <CircleIcon
        fontSize="small"
        className="text-[#EBEBEB]"
        color={isPending ? "secondary" : undefined}
      />
    );
  };

  async function handleAddressSubmit(formValue: any) {
    try {
      const {
        name,
        address,
        provinceCode,
        districtCode,
        subDistrictCode,
        postalCode,
      } = formValue;
      const payload = {
        name: name,
        address: address,
        provinceCode: provinceCode,
        districtCode: districtCode,
        subDistrictCode: subDistrictCode,
        postalCode: parseInt(postalCode, 10),
      };

      let resp;
      if (editForm && editForm.id) {
        resp = await checkFarmAddressEdit(payload, editForm.id);
      } else {
        resp = await checkFarmAddress(payload);
      }

      return resp;
    } catch (err) {
      throw err;
    }
  }

  function addressSubmitSuccess(formValue: any) {
    setStep1FormData(formValue);
    setActiveStep(1);
    setSteps((currentSteps) => {
      currentSteps[0] = {
        ...currentSteps[0],
        completed: true,
      };
      return currentSteps;
    });
  }

  async function handleStep2Submit(detailForm: any) {
    try {
      const finalForm: any = {
        name: step1FormData?.name,
        establishmentDate: parseInt(detailForm?.establishmentDate),
        farmStatus: Boolean(detailForm?.farmStatus),
        businessType: detailForm?.businessType,
        address: step1FormData?.address,
        provinceCode: step1FormData?.provinceCode,
        districtCode: step1FormData?.districtCode,
        subDistrictCode: step1FormData?.subDistrictCode,
        postalCode: parseInt(step1FormData?.postalCode, 10),
      };

      if (step1FormData.gorSorNumber) {
        finalForm["gorSorNumber"] = step1FormData?.gorSorNumber;
      }
      if (detailForm.partnerRefaralNumber) {
        finalForm["partnerRefaralNumber"] = parseInt(
          detailForm?.partnerRefaralNumber,
          10
        );
      }
      if (step1FormData.latitude && step1FormData.longitude) {
        finalForm["latitude"] = step1FormData?.latitude;
        finalForm["longitude"] = step1FormData?.longitude;
      }
      let resp;
      if (editForm && editForm.id) {
        finalForm["farmId"] = editForm.id;
        resp = await editFarmPut(finalForm);
      } else {
        resp = await addFarmPost(finalForm);
      }

      return { resp, editForm };
    } catch (err) {
      throw err;
    }
  }

  const mapComponentToStep = () => {
    if (activeStep === 1) {
      return (
        <AddFarmDetails
          formState={step2FormData}
          onSubmit={handleStep2Submit}
        />
      );
    } else if (activeStep === 0) {
      return (
        <AddFarmAddress
          provinces={provinces}
          districts={districts}
          subDistricts={subDistricts}
          zipCode={zipCode}
          formState={step1FormData}
          onDistrictChange={onDistrictChange}
          onProvinceChange={onProvinceChange}
          onSubDistrictChange={onSubDistrictChange}
          onSubmit={handleAddressSubmit}
          onAddressSuccess={addressSubmitSuccess}
        />
      );
    }
  };

  function onProvinceChange(selectedProvince: number) {
    let selectedProvinceData = provinces.find((province: Province) => {
      return province.code === selectedProvince;
    });
    if (selectedProvinceData) {
      setDistricts(selectedProvinceData.districts);
      setSubDistricts([]);
      setZipCode("");
    }
  }

  function onDistrictChange(selectedDistrict: number) {
    let selectedDistrictData = districts?.find((district: District) => {
      return district.code === selectedDistrict;
    });
    if (selectedDistrictData) {
      setSubDistricts(selectedDistrictData.subDistricts);
      setZipCode("");
    }
  }

  function onSubDistrictChange(selectedSubDistrict: number) {
    let selectedSubDistrictData = subDistricts?.find(
      (subDistrict: SubDistrict) => {
        return subDistrict.code === selectedSubDistrict;
      }
    );
    if (selectedSubDistrictData) {
      setZipCode(String(selectedSubDistrictData.zipCode));
    }
  }

  function confirmDelete() {
    setConfirmDialogOpen(true);
  }

  async function handleDialogClose(confirm: boolean) {
    setConfirmDialogOpen(false);

    if (confirm) {
      setLoading(true);
      const resp = await farmDelete(editForm?.id);
      if (resp.status === 204) {
        navigate("/farms");
      } else {
        dispatch(
          snakeActions.showMessage({
            type: "error",
            message: resp.data?.message || "Failed to delete",
            horizontalAnchor: "center",
          })
        );
      }
    }
  }

  return (
    <div className="p-4 h-full  flex flex-col justify-between w-full">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <AppBarFS
        title={editForm ? t("edit_farm.app_header") : t("add_farm.app_header")}
        onIconPress={() =>
          activeStep == 0 ? navigate(-1) : handleBack(activeStep - 1)
        }
        endComponent={
          editForm && (
            <IconButton
              size="large"
              edge="end"
              aria-label="menu"
              color="error"
              sx={{ flex: 1, justifyContent: "end" }}
              onClick={confirmDelete}
            >
              <FontAwesomeIcon icon={faTrash} size="xs" />
            </IconButton>
          )
        }
      ></AppBarFS>
      <ConfirmDialog
        open={confirmDialogOpen}
        title={t("edit_farm.dialog_title") || ""}
        bodyText={t("edit_farm.dialog_body_text") || ""}
        agreeButtonLabel={t("edit_farm.dialog_agree_button_label") || ""}
        disagreeButtonLabel={t("edit_farm.dialog_disagree_button_label") || ""}
        handleClose={handleDialogClose}
      />
      <div className="px-4 py-2 mt-8">
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<StepperConnectorFS />}
        >
          {steps?.map((step, index) => {
            return (
              <Step key={step?.id} completed={step?.completed} disabled={false}>
                <StepLabel
                  StepIconComponent={() =>
                    CustomStepIcon(step?.completed, index <= activeStep)
                  }
                >
                  {step?.label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      <div className="flex grow mt-4">{mapComponentToStep()}</div>
    </div>
  );
}

import PushPinIcon from "@mui/icons-material/PushPin";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import radioIcon from "../../../assets/images/feed/Radio button.png";
import FeedDialog from "./FeedDialog";
import GrowJourney from "./GrowJourney";

const FeedProgram = (props: any) => {
  const {
    selectedFeedBrand,
    feedBrands,
    feedData,
    title,
    totalAmount,
    addHouse,
  } = props;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isEnglish, setEnglish] = useState<boolean>(true);
  const { t, i18n } = useTranslation();

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const getBrandName = (id: any) => {
    let arr = feedBrands.filter(
      (val: any) =>
        val.feed_brand_items.filter((item: any) => item.brand_id === id)
          .length > 0
    );
    return isEnglish ? arr[0].nameEn : arr[0].nameTh;
  };

  React.useEffect(() => {
    if (i18n.language === "en") setEnglish(true);
    else setEnglish(false);
  }, [i18n]);

  // console.log("addHouse",addHouse)
  return (
    <div className="flex flex-col gap-3">
      <h4 className="text-[#3777BC] text-xl font-bold">
        {title || t("feedingProgram.recommendFeedProgram")}
      </h4>
      {props.showPin && (
        <div
          style={{
            background: "#68C184",
            width: "9rem",
            borderRadius: "10px 10px 0px 0",
            color: "white",
            display: "flex",
            position: "relative",
            top: "10px",
            left: "20px",
          }}
        >
          <PushPinIcon
            style={{
              transform: "rotate(45deg)",
              marginTop: "4px",
              marginLeft: "20px",
              width: "20px",
              height: "35px",
            }}
          />
          <button
            style={{
              padding: "8px",
              fontSize: "17px",
              fontWeight: "700",
              lineHeight: "19.2px",
              fontStyle: "normal",
            }}
          >
            {t("feedingProgram.pinned")}
          </button>
        </div>
      )}
      <div className="rounded-xl bg-[#D9EBFE] flex flex-col">
        <div className="flex px-6 justify-between items-center">
          <div className="flex gap-3 py-6 items-center">
            <img
              src={radioIcon}
              alt="radio-button"
              className="w-[20px] h-[20px]"
            />
            <h2
              className="text-xl font-semibold text-black"
              style={{
                color: "#191919",
                fontSize: "20px",
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: "24px",
              }}
            >
              {t("feedingProgram.feedProgram")}
            </h2>
          </div>
          <div>
            {addHouse === false ? (
              ""
            ) : (
              <div
                className="border border-[#68C184] rounded-[50px] px-5 py-1 bg-white text-[#68C184] text-base"
                onClick={() => setOpenModal(true)}
              >
                {t("feedingProgram.edit")}
              </div>
            )}
          </div>
        </div>
        <div className="pl-1">
          <GrowJourney
            feedBrands={feedBrands}
            selectedFeedBrand={selectedFeedBrand}
            feedData={feedData}
            totalAmount={totalAmount}
          />
        </div>
        {/* <div className='pt-4 pb-6'>
                    {selectedFeedBrand?.feed_brand_items?.map((element: any, index: number) => <div
                        className={`flex px-6 gap-8 ${index % 2 === 0 && 'bg-[#FFFFFF99]'}  py-2 items-center`}
                        key={index}
                    >
                        <p className={` text-[#3777BC] font-semibold text-base`}>{t(`feedingProgram.No${index+1}`)}</p>
                        <p className={` ${index !== 0 ? "text-[#4B4B4B] font-normal" : "text-[#3777BC] font-semibold "} text-base`}>{isEnglish ? getBrandName(element.brand_id) + '/' + element.skuEn : getBrandName(element.brand_id) + '/' + element.skuTh}</p>
                    </div>)}
                </div> */}
        <div className="pt-4 pb-6">
          {feedData?.feed_brand_items?.map((element: any, index: number) => (
            <div
              className={`flex px-6 gap-8 ${
                index % 2 === 0 && "bg-[#FFFFFF99]"
              }  py-2 items-center`}
              key={index}
            >
              <p className={` text-[#3777BC] font-6 text-base`}>
                {t(`feedingProgram.No`) + ` ${index + 1}`}
              </p>
              <p
                className={` ${
                  index !== 0 ? "text-[#4B4B4B] font-normal" : "text-[#000000] "
                } text-base`}
              >
                {isEnglish
                  ? element?.quantity !== undefined &&
                    element?.quantity !== null
                    ? element?.quantity > 0
                      ? (element?.brand_id
                          ? getBrandName(element?.brand_id)
                          : feedData?.nameEn) +
                        "/" +
                        `${
                          element?.skuEn
                            ? element.skuEn
                            : " Please click edit to add feed."
                        }`
                      : "-"
                    : (element?.brand_id
                        ? getBrandName(element?.brand_id)
                        : feedData?.nameEn) +
                      "/" +
                      `${
                        element?.skuEn
                          ? element.skuEn
                          : " Please click edit to add feed."
                      }`
                  : element?.quantity !== undefined &&
                    element?.quantity !== null
                  ? element?.quantity > 0
                    ? (element?.brand_id
                        ? getBrandName(element?.brand_id)
                        : feedData?.nameTh) +
                      "/" +
                      `${element?.skuTh ? element.skuTh : "กดแก้ไข เบอร์อาหาร"}`
                    : "-"
                  : (element?.brand_id
                      ? getBrandName(element?.brand_id)
                      : feedData?.nameTh) +
                    "/" +
                    `${element?.skuTh ? element.skuTh : "กดแก้ไข เบอร์อาหาร"}`}
              </p>
            </div>
          ))}
        </div>
        <FeedDialog handleModalClose={handleModalClose} openModal={openModal} />
      </div>
    </div>
  );
};

export default FeedProgram;

import { useFormik } from "formik";
import { array, date, number, object, string } from "yup";
import TextInputFS from "../../../components/common/Input/TextInput";
import { Divider, InputAdornment, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  formError,
  formErrorMessage,
  twoDecimalPlacesValidator,
} from "../../../utils/form-validator";
import CustomButton from "../../../components/common/Button";
import { useEffect, useState } from "react";
import { House } from "../../../types/House.type";
import UploadImage from "../../../components/common/uploadImage";
import Resizer from "react-image-file-resizer";
import { setUploadImg } from "../../../services/DailyLogService";

export interface DeathDailyLogProps {
  form?: any;
  totalPigs: number;
  house?: House;
  deathDailyLogSubmit?: (val: any) => void;
  finalDailyLogData?: any;
  breederPigCount: number;
  currentDeadPigsCount?: any;
  isEdit: boolean;
}

export default function DeathDailyLog({
  totalPigs,
  form,
  finalDailyLogData,
  deathDailyLogSubmit,
  breederPigCount,
  currentDeadPigsCount,
  isEdit,
}: DeathDailyLogProps) {
  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = useState<string[]>([]);
  // const [files, setFiles] = useState<any>();
  const [files, setFiles] = useState<File[]>([]);
  const Image_Resize_limit = 2097152;
  const MAX_FILE_SIZE = 5242880; //5mb
  type MyFormValues = {
    pictureofDeadPig: any[];
  };

  useEffect(() => {
    if (finalDailyLogData?.deathData?.numberOfDeadPigs) {
      formik.setFieldTouched("numberOfDeadPigs", true);
    }
    if (finalDailyLogData?.deathData?.numberOfPigsSellingCarcass) {
      formik.setFieldTouched("numberOfPigsSellingCarcass", true);
    }
    if (finalDailyLogData?.deathData?.reasonOfDeath) {
      formik.setFieldTouched("reasonOfDeath", true);
    }
    if (finalDailyLogData?.deathData?.sellingPriceCarcass) {
      formik.setFieldTouched("sellingPriceCarcass", true);
    }
    if (finalDailyLogData?.deathData?.totalWeightDeadPigs) {
      formik.setFieldTouched("totalWeightDeadPigs", true);
    }
    if (finalDailyLogData?.deathData?.weightOfPigsSellingCarcass) {
      formik.setFieldTouched("weightOfPigsSellingCarcass", true);
    }
    if (finalDailyLogData?.deathData)
      if (
        finalDailyLogData?.deathData.numberOfDeadPigs ||
        finalDailyLogData.deathData.numberOfPigsSellingCarcass
      )
        formik.setValues({ ...finalDailyLogData?.deathData });
  }, [finalDailyLogData]);
  const formik = useFormik({
    initialValues: {
      numberOfDeadPigs: "",
      totalWeightDeadPigs: "",
      reasonOfDeath: "",
      numberOfPigsSellingCarcass: "",
      weightOfPigsSellingCarcass: "",
      sellingPriceCarcass: "",
      pictureofDeadPig: [[]],
    },
    validationSchema: object({
      numberOfDeadPigs: number()
        .positive(`${t("dailyLog.shouldBePossitiveIntegerErrorMessage")}`)
        .integer(`${t("dailyLog.shouldBePossitiveNumberErrorMessage")}`)
        .typeError(`${t("dailyLog.shouldBePossitiveNumberErrorMessage")}`)
        .test(
          "max-value",
          `${t("dailyLog.shouldNotBeMoreThanTotalHousePigsErrorMessage")}`,
          function (value) {
            if (
              value &&
              value >
                totalPigs -
                  breederPigCount -
                  currentDeadPigsCount +
                  (isEdit ? finalDailyLogData.deathData.numberOfDeadPigs : 0)
            ) {
              return false;
            }

            return true;
          }
        )
        .test(
          "max-100000",
          `${t("dailyLog.shouldBeMoreThan100000ErrorMessage")}`,
          function (value) {
            if (totalPigs > 100000 && value && value > 100000) {
              return false;
            }
            return true;
          }
        )
        .optional(),
      totalWeightDeadPigs: number()
        .positive(`${t("dailyLog.shouldBePossitiveIntegerErrorMessage")}`)
        .typeError(`${t("dailyLog.notAnNumberErrorMessage")}`)
        .test(
          "two-decimal",
          `${t("dailyLog.onlyTwoDecimialErrorMessage")}`,
          twoDecimalPlacesValidator
        )
        .when(["numberOfDeadPigs"], {
          is: (numberOfDeadPigs: any) => numberOfDeadPigs > 0,
          then: (schema) =>
            schema.required(
              `${t("dailyLog.totalWeightDeadPigsRequiredErrorMessage")}`
            ),
          otherwise: (schema) => schema.optional(),
        }),
      reasonOfDeath: string().max(50, "Should not be greater than 50"),
      numberOfPigsSellingCarcass: number()
        .positive("Should be a positive number")
        .integer("Should be a number")
        .typeError("Should be a number")
        .test(
          "max-value",
          `${t("dailyLog.shouldNotBeMoreThanTotalHousePigsErrorMessage")}`,
          function (value) {
            if (
              value &&
              value >
                totalPigs -
                  breederPigCount -
                  currentDeadPigsCount +
                  (isEdit ? finalDailyLogData.deathData.numberOfDeadPigs : 0)
            ) {
              return false;
            }
            return true;
          }
        )
        .test(
          "max-100000",
          `${t("dailyLog.shouldBeMoreThan100000ErrorMessage")}`,
          function (value) {
            if (totalPigs > 100000 && value && value > 100000) {
              return false;
            }
            return true;
          }
        )
        .optional(),
      weightOfPigsSellingCarcass: number()
        .positive(`${t("dailyLog.shouldBePossitiveIntegerErrorMessage")}`)
        .typeError(`${t("dailyLog.shouldBePossitiveIntegerErrorMessage")}`)
        .max(99999999, `${t("dailyLog.shouldBePossitiveIntegerErrorMessage")}`)
        .test(
          "two-decimal",
          `${t("dailyLog.shouldBeMax5digitErrorMessage")}`,
          twoDecimalPlacesValidator
        )
        .when(["numberOfPigsSellingCarcass"], {
          is: (numberOfPigsSellingCarcass: any) =>
            numberOfPigsSellingCarcass > 0,
          then: (schema) =>
            schema.required(
              `${t("dailyLog.weightOfSellingPigsRequiredErrorMessage")}`
            ),
          otherwise: (schema) => schema.optional(),
        }),
      sellingPriceCarcass: number()
        .positive(`${t("dailyLog.shouldBePossitiveIntegerErrorMessage")}`)
        .typeError(`${t("dailyLog.shouldBePossitiveIntegerErrorMessage")}`)
        .max(99999999, `${t("dailyLog.shouldBeMax6digitErrorMessage")}`)
        .test(
          "two-decimal",
          `${t("dailyLog.onlyTwoDecimialErrorMessage")}`,
          twoDecimalPlacesValidator
        )
        .when(["numberOfPigsSellingCarcass"], {
          is: (numberOfPigsSellingCarcass: any) =>
            numberOfPigsSellingCarcass > 0,
          then: (schema) =>
            schema.required(
              `${t("dailyLog.sellingPriceRequiredErrorMessage")}`
            ),
          otherwise: (schema) => schema.optional(),
        }),
      pictureofUnwellPig: array()
        .test(
          "file-validation",
          `${t("dailyLog.imageQuantityOrSizeErrorMessage")}`,
          (value) => {
            if (!value) return true;
            if (value.length > 2) {
              return false;
            }
          }
        )
        .test(
          "FILE_SIZE",
          `${t("dailyLog.uploadFileSizeErrorMessage")}`,
          (value) => !value || (value && value[0].size <= MAX_FILE_SIZE)
        ),
    }),
    onSubmit: async (values) => {
      try {
        deathDailyLogSubmit?.(values);
      } catch (err) {
        console.error(err);
      }
    },
  });
  useEffect(() => {
    if (finalDailyLogData?.deathData?.pictureofDeadPig) {
      let url = finalDailyLogData?.deathData.pictureofDeadPig.map(
        (ele: any) => {
          if (ele.name) {
            return URL.createObjectURL(ele);
          }
        }
      );
      setImageUrl(url);
    }
    if (form) {
      populateForm(form);
    }
  }, []);
  useEffect(() => {
    if (
      formik.values.weightOfPigsSellingCarcass >
      formik?.values?.totalWeightDeadPigs
    ) {
      formik.setFieldError(
        "numberOfPigsSellingCarcass",
        "Should be less than total Weight of Dead Pigs"
      );
    }

    if (
      formik.values.numberOfPigsSellingCarcass >
      formik?.values?.numberOfDeadPigs
    ) {
      formik.setFieldError(
        "numberOfPigsSellingCarcass",
        "Should be less than Dead Pigs"
      );
    }

    if (
      !formik.values.numberOfPigsSellingCarcass ||
      formik.values.numberOfPigsSellingCarcass === ""
    ) {
      formik.setFieldValue("weightOfPigsSellingCarcass", "");
      formik.setFieldValue("sellingPriceCarcass", "");
    }
  }, [formik.values.numberOfPigsSellingCarcass]);

  useEffect(() => {
    if (
      !formik.values.numberOfDeadPigs ||
      formik.values.numberOfDeadPigs === ""
    ) {
      formik.setFieldValue("totalWeightDeadPigs", "");
      formik.setFieldValue("reasonOfDeath", "");
      formik.setFieldValue("numberOfPigsSellingCarcass", "");
    }
  }, [formik.values.numberOfDeadPigs]);

  async function populateForm(formValue: any) {
    await formik.setValues(formValue, true);
    await formik.validateForm();
  }
  const handleFileChange = (
    e: any,
    formikValue: keyof MyFormValues,
    index: number
  ) => {
    const inputElement = e.target as HTMLInputElement;

    setFiles([...files, ...e.target.files]);
    if (inputElement?.files) {
      const file: File = inputElement.files[0];
      if (file.size > Image_Resize_limit) {
        Resizer.imageFileResizer(
          file,
          300, // maxWidth
          300, // maxHeight
          "JPEG", // compressFormat
          80, // quality
          0, // rotation
          async (uri: any) => {
            // Convert data URL to Blob
            const response = await fetch(uri);
            const blob = await response.blob();

            // Create a File object from the Blob
            const compressedFile = new File([blob], "compressed.jpg", {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
            setImageUrl((prevImageUrl) => [...prevImageUrl, uri]);
            const fieldName = formikValue;
            const updatedArray: any = [...formik.values[fieldName]];
            updatedArray[index] = [...updatedArray[index], compressedFile];
            formik.setFieldValue(fieldName, updatedArray);

            // formik.setFieldValue("pictureofUnwellPig", [...formik.values.pictureofUnwellPig, compressedFile]);
          },
          "base64" // outputType
        );
      } else {
        const url = URL.createObjectURL(file);
        if (file?.size < MAX_FILE_SIZE) {
          setImageUrl((prevImageUrl) => [...prevImageUrl, url]);

          if (e && e.target && e.target.files !== null) {
            const fieldName = formikValue;
            const updatedArray: any = [...formik.values[fieldName]];
            updatedArray.push(e.target.files[0]);
            formik.setFieldValue(fieldName, updatedArray);
          }
        } else {
          formik.setFieldError(
            "pictureOfDeadPig",
            "File size should be less than 2mb"
          );
        }
      }
    }
  };

  // ########don't delete this #############
  // const handleFileChange1 = (event:any, formikValue: keyof MyFormValues, index: number) => {
  //     console.log("file: ", event.target.files[0])
  //     setFiles(event.target.files[0]);
  // };

  // ################# will be fixed when upload form vaccine################
  const savefile = async () => {
    console.log("files: ", files);
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
      console.log(formData);
    }

    // console.log("formdata",formData)
    const res = await setUploadImg(formData);
    console.log("res", res);
  };

  const handleRemoveimage = (ele: any) => {
    let index = imageUrl.indexOf(ele);
    imageUrl.splice(index, 1);
    if (imageUrl.length == 0) {
      formik.setValues({
        ...formik.values,
        pictureofDeadPig: [], // Assign an empty array
      });
    } else {
      formik.setValues({
        ...formik.values,
        pictureofDeadPig: formik.values.pictureofDeadPig.splice(index, 1), // Assign an empty array
      });
    }
  };
  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4 ">
      <TextInputFS
        fullWidth
        type="tel"
        helperText={formErrorMessage(formik, "numberOfDeadPigs")}
        error={formError(formik, "numberOfDeadPigs")}
        label={t("dailyLog.numberOfDeadPigsLabel")}
        {...formik.getFieldProps("numberOfDeadPigs")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <h6 className="text-tertiary font-medium text-base">
                | {t("dailyLog.pronoun")}
              </h6>
            </InputAdornment>
          ),
        }}
      />

      <TextInputFS
        fullWidth
        type="number"
        helperText={formErrorMessage(formik, "totalWeightDeadPigs")}
        error={formError(formik, "totalWeightDeadPigs")}
        label={t("dailyLog.totalWeightDeadPigsLabel")}
        {...formik.getFieldProps("totalWeightDeadPigs")}
        InputProps={{
          inputProps: {
            inputMode: "decimal",
          },
          endAdornment: (
            <InputAdornment position="end">
              <h6 className="text-tertiary font-medium">| {t("common.kg")}</h6>
            </InputAdornment>
          ),
        }}
        disabled={
          formik.values.numberOfDeadPigs === "" ||
          formik.errors.numberOfDeadPigs !== undefined
        }
      />

      <TextInputFS
        fullWidth
        helperText={formErrorMessage(formik, "reasonOfDeath")}
        error={formError(formik, "reasonOfDeath")}
        label={t("dailyLog.reasonOfDeathLabel")}
        {...formik.getFieldProps("reasonOfDeath")}
        disabled={
          formik.values.numberOfDeadPigs === "" ||
          formik.errors.numberOfDeadPigs !== undefined
        }
      />

      <Divider variant="middle" sx={{ marginX: "2rem", marginY: "1rem" }} />

      <TextInputFS
        fullWidth
        type="tel"
        helperText={formErrorMessage(formik, "numberOfPigsSellingCarcass")}
        error={formError(formik, "numberOfPigsSellingCarcass")}
        label={t("dailyLog.numberOfPigsSellingCarcassLabel")}
        {...formik.getFieldProps("numberOfPigsSellingCarcass")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <h6 className="text-tertiary font-medium">
                | {t("dailyLog.pronoun")}
              </h6>
            </InputAdornment>
          ),
        }}
      />

      <TextInputFS
        fullWidth
        type="number"
        helperText={formErrorMessage(formik, "weightOfPigsSellingCarcass")}
        error={formError(formik, "weightOfPigsSellingCarcass")}
        label={t("dailyLog.weightOfPigsSellingCarcassLabel")}
        {...formik.getFieldProps("weightOfPigsSellingCarcass")}
        InputProps={{
          inputProps: {
            inputMode: "decimal",
          },
          endAdornment: (
            <InputAdornment position="end">
              <h6 className="text-tertiary font-medium">| {t("common.kg")}</h6>
            </InputAdornment>
          ),
        }}
        disabled={
          formik.values.numberOfPigsSellingCarcass === "" ||
          formik.errors.numberOfPigsSellingCarcass !== undefined
        }
      />

      <TextInputFS
        fullWidth
        type="number"
        helperText={formErrorMessage(formik, "sellingPriceCarcass")}
        error={formError(formik, "sellingPriceCarcass")}
        label={t("dailyLog.sellingPriceCarcassLabel")}
        {...formik.getFieldProps("sellingPriceCarcass")}
        InputProps={{
          inputProps: {
            inputMode: "decimal",
          },
          endAdornment: (
            <InputAdornment position="end">
              <h6 className="text-tertiary font-medium">
                | {t("common.currency")}
              </h6>
            </InputAdornment>
          ),
        }}
        disabled={
          formik.values.numberOfPigsSellingCarcass === "" ||
          formik.errors.numberOfPigsSellingCarcass !== undefined
        }
      />

      <div>
        <UploadImage
          onChange={(e) => handleFileChange(e, "pictureofDeadPig", 1)}
          imageUrl={imageUrl}
          handleRemoveImage={handleRemoveimage}
          t={t}
        />
        <span>{formErrorMessage(formik, "pictureofunwellPig")}</span>
      </div>

      <div className="flex flex-col gap-4 mb-4">
        <CustomButton
          type="submit"
          disabled={!formik?.isValid || formik?.isSubmitting}
          variant="tertiary"
          label={t("addHouseDetail.NextButtonLabel")}
          onClick={() => savefile()}
        />
      </div>
      {/* 
            <div>
                <img src="https://farmtech.blob.core.windows.net/farmtech/pigs/231ef12d-3d86-4216-bc67-df54529b2957.jpg" />
            </div> */}
    </form>
  );
}

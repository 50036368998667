import { Dialog, DialogContent, IconButton } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { object, string } from "yup";
import { useSnackBar } from "../../../../provider/SnackBarProvider";
import {
  editFarmer,
  getFarmerDetail,
} from "../../../../services/admin/user-management/customer.service";
import {
  IFarmerEditForm,
  IFarmerSubmitForm,
} from "../../../../types/admin/user-management/customer";
import delay from "../../../../utils/delay";
import { formError, formErrorMessage } from "../../../../utils/form-validator";
import CrossIcon from "../../../assets/svg/CrossIcon.svg";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import TextInputCustom from "../../../components/Input/TextInputCustom";
import ConfirmCustomModal from "../../../components/Modal/ConfirmCustomModal";

type CreatePartnerModalProps = {
  isOpen: boolean;
  handleClose: (isSuccess: boolean, values?: any) => void;
  selectedRow?: string;
};

const initialValues: IFarmerEditForm = {
  oldName: "",
  name: "",
  oldSurname: "",
  surname: "",
  oldMobileNo: "",
  mobileNo: "",
  oldEmail: "",
  email: "",
};
const validationSchema = object().shape({
  name: string().ensure().required("กรุณากรอกชื่อ"),
  email: string().email("กรุณากรอกอีเมลให้ถูกต้อง"),
  surname: string().ensure().required("กรุณากรอกนามสกุล"),
});

const EditCustomerModal = ({
  isOpen,
  handleClose,
  selectedRow,
}: CreatePartnerModalProps) => {
  const { showSnackBar, showErrorSnackBar } = useSnackBar();
  const [isOpenConfirmCreateModal, setIsOpenConfirmCreateModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (value: IFarmerSubmitForm) => {
    setIsSubmitting(true);
    try {
      const payload = {
        name: value.name,
        surname: value.surname,
        mobileNo: value.mobileNo || undefined,
        email: value.email || undefined,
      };
      await delay(500);
      await editFarmer(payload, selectedRow ?? "");
      showSnackBar("บันทึกสำเร็จ");
      handleClose(true);
    } catch (error) {
      const axiosError = error as any;
      const status = axiosError?.status || 500;
      const errorMessage = axiosError.data?.message || "Request error";
      showErrorSnackBar(`${status}: ${errorMessage}`);
      throw error;
    } finally {
      setIsOpenConfirmCreateModal(false);
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: validationSchema,
  });

  const handleInitForm = async () => {
    if (selectedRow) {
      setIsLoading(true);
      try {
        const farmer = await getFarmerDetail(selectedRow);
        if (farmer) {
          formik.setValues({
            oldName: farmer.farmer.name,
            oldSurname: farmer.farmer.surname,
            oldMobileNo: farmer.farmer.mobileNumber,
            oldEmail: farmer.farmer.email,
            name: "",
            surname: "",
            mobileNo: "",
            email: "",
          });
        }
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleInitForm();
    }
  }, [isOpen]);

  useEffect(() => {
    formik.resetForm();
  }, [handleClose]);

  return (
    <>
      <Dialog
        open={!isLoading && isOpen}
        maxWidth="lg"
        onClose={() => handleClose(true)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={{
          backdrop: {
            style: { backgroundColor: "#000000", opacity: "10%" },
            timeout: 500,
          },
        }}
      >
        <DialogContent sx={{ width: "750px" }}>
          <div className="flex justify-between items-center">
            <p className="text-lg font-bold pb-3 mb-1">แก้ไขข้อมูล</p>
            <IconButton onClick={() => handleClose(true)}>
              <CrossIcon />
            </IconButton>
          </div>

          <div className="flex">
            <div className="w-[50%]">
              <p className="text-[14px] mb-1">
                ชื่อ<span className="text-error-300">*</span>
              </p>
              <div className="w-[340px] pb-3">
                <TextInputCustom
                  disabled
                  placeholder="กรอก"
                  {...formik.getFieldProps("oldName")}
                />
              </div>
            </div>
            <div className="w-[50%]">
              <p className="text-[14px] mb-1">
                ชื่อใหม่<span className="text-error-300">*</span>
              </p>
              <div className="w-[340px] pb-3">
                <TextInputCustom
                  placeholder="กรอก"
                  error={formError(formik, "name")}
                  helperText={formErrorMessage(formik, "name")}
                  {...formik.getFieldProps("name")}
                />
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-[50%]">
              <p className="text-[14px] mb-1">
                นามสกุล<span className="text-error-300">*</span>
              </p>
              <div className="w-[340px] pb-3">
                <TextInputCustom
                  disabled
                  placeholder="กรอก"
                  {...formik.getFieldProps("oldSurname")}
                />
              </div>
            </div>
            <div className="w-[50%]">
              <p className="text-[14px] mb-1">
                นามสกุลใหม่<span className="text-error-300">*</span>
              </p>
              <div className="w-[340px] pb-3">
                <TextInputCustom
                  placeholder="กรอก"
                  error={formError(formik, "surname")}
                  helperText={formErrorMessage(formik, "surname")}
                  {...formik.getFieldProps("surname")}
                />
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-[50%]">
              <p className="text-[14px] mb-1">อีเมล</p>
              <div className="w-[340px] pb-3">
                <TextInputCustom
                  disabled
                  placeholder="กรอก"
                  {...formik.getFieldProps("oldEmail")}
                />
              </div>
            </div>
            <div className="w-[50%]">
              <p className="text-[14px] mb-1">อีเมลใหม่</p>
              <div className="w-[340px] pb-3">
                <TextInputCustom
                  placeholder="กรอก"
                  error={formError(formik, "email")}
                  helperText={formErrorMessage(formik, "email")}
                  {...formik.getFieldProps("email")}
                />
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-[50%]">
              <p className="text-[14px] mb-1">เบอร์โทรศัพท์</p>
              <div className="w-[340px] pb-3">
                <TextInputCustom
                  disabled
                  placeholder="กรอก"
                  error={formError(formik, "oldMobileNo")}
                  helperText={formErrorMessage(formik, "oldMobileNo")}
                  {...formik.getFieldProps("oldMobileNo")}
                />
              </div>
            </div>
            <div className="w-[50%]">
              <p className="text-[14px] mb-1">เบอร์โทรศัพท์ใหม่</p>
              <div className="w-[340px] pb-3">
                <TextInputCustom
                  placeholder="กรอก"
                  error={formError(formik, "mobileNo")}
                  helperText={formErrorMessage(formik, "mobileNo")}
                  {...formik.getFieldProps("mobileNo")}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end mr-3">
            <ButtonFillCustom
              title={"ยืนยัน"}
              onClick={async () => {
                await formik.validateForm();
                if (formik.isValid) {
                  setIsOpenConfirmCreateModal(true);
                } else {
                  formik.setTouched({
                    name: true,
                    surname: true,
                    mobileNo: true,
                    email: true,
                  });
                }
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
      <ConfirmCustomModal
        title={"ยืนยันการบันทึก"}
        subtitleFirstRow={"คุณต้องการบันทึกรายการนี้ใช่หรือไม่?"}
        subtitleSecondRow="การดำเนินการนี้ไม่สามารถยกเลิกได้"
        buttonPrimaryText="ยืนยัน"
        buttonSecondaryText="กลับไปแก้ไข"
        disablePrimaryBtn={isSubmitting}
        disableSecondaryBtn={isSubmitting}
        open={isOpenConfirmCreateModal}
        onClose={() => setIsOpenConfirmCreateModal(false)}
        onSubmit={formik.handleSubmit}
      />
    </>
  );
};

export default EditCustomerModal;

export default function WalletSvg() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#34407B" />
      <mask
        id="mask0_17680_126468"
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_17680_126468)">
        <path
          d="M24 21.5C24.4333 21.5 24.7917 21.3583 25.075 21.075C25.3583 20.7917 25.5 20.4333 25.5 20C25.5 19.5667 25.3583 19.2083 25.075 18.925C24.7917 18.6417 24.4333 18.5 24 18.5C23.5667 18.5 23.2083 18.6417 22.925 18.925C22.6417 19.2083 22.5 19.5667 22.5 20C22.5 20.4333 22.6417 20.7917 22.925 21.075C23.2083 21.3583 23.5667 21.5 24 21.5ZM21 25C20.45 25 19.9792 24.8042 19.5875 24.4125C19.1958 24.0208 19 23.55 19 23V17C19 16.45 19.1958 15.9792 19.5875 15.5875C19.9792 15.1958 20.45 15 21 15H28C28.55 15 29.0208 15.1958 29.4125 15.5875C29.8042 15.9792 30 16.45 30 17V23C30 23.55 29.8042 24.0208 29.4125 24.4125C29.0208 24.8042 28.55 25 28 25H21ZM13 29C12.45 29 11.9792 28.8042 11.5875 28.4125C11.1958 28.0208 11 27.55 11 27V13C11 12.45 11.1958 11.9792 11.5875 11.5875C11.9792 11.1958 12.45 11 13 11H27C27.55 11 28.0208 11.1958 28.4125 11.5875C28.8042 11.9792 29 12.45 29 13H21C19.8167 13 18.8542 13.3708 18.1125 14.1125C17.3708 14.8542 17 15.8167 17 17V23C17 24.1833 17.3708 25.1458 18.1125 25.8875C18.8542 26.6292 19.8167 27 21 27H29C29 27.55 28.8042 28.0208 28.4125 28.4125C28.0208 28.8042 27.55 29 27 29H13Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

import { Dialog, DialogContent, IconButton, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { object, string } from "yup";
import { useSnackBar } from "../../../../provider/SnackBarProvider";
import {
  createEmployee,
  getEmployeeById,
  updateEmployee,
} from "../../../../services/admin/user-management/employee.service";
import { IEmployeeForm } from "../../../../types/admin/user-management/employee";
import delay from "../../../../utils/delay";
import { formError, formErrorMessage } from "../../../../utils/form-validator";
import CrossIcon from "../../../assets/svg/CrossIcon.svg";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import TextInputCustom from "../../../components/Input/TextInputCustom";
import ConfirmCustomModal from "../../../components/Modal/ConfirmCustomModal";
import SelectAdminCustom from "../../../components/Select/Select";
import { loadUserManagementData } from "../loader";

type CreatePartnerModalProps = {
  isOpen: boolean;
  handleClose: (isSuccess: boolean, values?: any) => void;
  selectedRow?: string;
  currentPage?: number;
  handleFetch: (targetPage?: number) => void;
};

const initialValues: IEmployeeForm = {
  email: "",
  role: "",
};
const validationSchema = object().shape({
  email: string().email("กรุณากรอกอีเมลให้ถูกต้อง").required("กรุณากรอกอีเมล"),
  role: string().ensure().required("กรุณาเลือกตำแหน่ง"),
});

const CreateEmployeeModal = ({
  isOpen,
  handleClose,
  selectedRow,
  handleFetch,
  currentPage,
}: CreatePartnerModalProps) => {
  const { showSnackBar, showErrorSnackBar } = useSnackBar();
  const [isOpenConfirmCreateModal, setIsOpenConfirmCreateModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { farmTechRole } = useLoaderData() as Awaited<
    ReturnType<typeof loadUserManagementData>
  >;

  const handleSubmit = async (value: IEmployeeForm) => {
    setIsSubmitting(true);
    try {
      const payload = {
        id: selectedRow ?? "",
        email: value.email,
        role: value.role,
      };
      await delay(500);
      await (selectedRow ? updateEmployee(payload) : createEmployee(payload));
      showSnackBar(selectedRow ? "บันทึกสำเร็จ" : "เพิ่มผู้ใช้สำเร็จ");
      handleClose(true);
      handleFetch(currentPage);
    } catch (error) {
      const axiosError = error as any;
      const status = axiosError?.status || 500;
      const errorMessage = axiosError.data?.message || "Request error";
      showErrorSnackBar(`${status}: ${errorMessage}`);
      throw error;
    } finally {
      setIsOpenConfirmCreateModal(false);
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: validationSchema,
  });

  const handleInitForm = async () => {
    if (selectedRow) {
      setIsLoading(true);
      try {
        const employee = await getEmployeeById(selectedRow);
        if (employee) {
          formik.setValues({
            email: employee.result.email || "",
            role: employee.result.userRole || "",
          });
        }
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleInitForm();
    }
  }, [isOpen]);

  useEffect(() => {
    formik.resetForm();
  }, [handleClose]);

  return (
    <>
      <Dialog
        open={!isLoading && isOpen}
        maxWidth="lg"
        onClose={() => handleClose(true)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={{
          backdrop: {
            style: { backgroundColor: "#000000", opacity: "10%" },
            timeout: 500,
          },
        }}
      >
        <DialogContent>
          <div className="flex justify-between items-center">
            <p className="text-lg font-bold pb-3 mb-1">
              {selectedRow ? "แก้ไขข้อมูล" : "เพิ่มผู้ใช้"}
            </p>
            <IconButton onClick={() => handleClose(true)}>
              <CrossIcon />
            </IconButton>
          </div>

          <div>
            <div className="w-full">
              <p className="text-[14px] mb-1">
                อีเมล<span className="text-error-300">*</span>
              </p>
              <div className="w-[340px] pb-3">
                <TextInputCustom
                  placeholder="กรอก"
                  error={formError(formik, "email")}
                  helperText={formErrorMessage(formik, "email")}
                  {...formik.getFieldProps("email")}
                />
              </div>
            </div>
            <div className="w-full">
              <p className="text-[14px] mb-1">
                Role<span className="text-error-300">*</span>
              </p>
              <div className="w-[340px] pb-3">
                <div className="w-[340px] pb-3">
                  <SelectAdminCustom
                    placeholder="เลือก"
                    error={formError(formik, "role")}
                    helperText={formErrorMessage(formik, "role")}
                    {...formik.getFieldProps("role")}
                    menuItems={farmTechRole.roles.map((option) => (
                      <MenuItem key={option.name} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                    renderValueArray={[]}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <ButtonFillCustom
              title={"ยืนยัน"}
              onClick={async () => {
                setIsOpenConfirmCreateModal(true);
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
      <ConfirmCustomModal
        title={selectedRow ? "ยืนยันการบันทึก" : "ยืนยันการเพิ่มผู้ใช้"}
        subtitleFirstRow={
          selectedRow
            ? "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?"
            : "คุณต้องการเพิ่มพนักงานใช่หรือไม่?"
        }
        subtitleSecondRow="การดำเนินการนี้ไม่สามารถยกเลิกได้"
        buttonPrimaryText="ยืนยัน"
        buttonSecondaryText="กลับไปแก้ไข"
        disablePrimaryBtn={isSubmitting}
        disableSecondaryBtn={isSubmitting}
        open={isOpenConfirmCreateModal}
        onClose={() => setIsOpenConfirmCreateModal(false)}
        onSubmit={formik.handleSubmit}
      />
    </>
  );
};

export default CreateEmployeeModal;

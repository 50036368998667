export default function FenceSVG() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1791 16.0356V8.51929H19V6.73294H17.1558V3.46884L15.8572 2L14.5557 3.46884V6.73294H12.2942V3.46884L10.9927 2L9.69123 3.46884V6.73294H7.35382V3.46884L6.05526 2L4.75378 3.46884V6.73294H3V8.55193H4.75378V16.0326H3V17.8516H4.75378V20H7.35382V17.8516H9.69123V20H12.2913V17.8516H14.5528V20H17.1528V17.8516H18.9971V16.0356H17.1762H17.1791ZM9.735 16.0356H7.35382V8.51929H9.735V16.0356ZM14.5557 16.0356H14.5265H12.1454V8.51929H14.5265V8.55193H14.5557V16.0326V16.0356Z"
        fill="#68C184"
      />
    </svg>
  );
}

import api from "./ApiService";
import { deleteItem } from "./StorageService";
import { AUTH_TOKEN_KEY } from "../const/app.constant";

type UserLoginType = {
  userName: string;
  pin: string;
};

type UserRegisterTYpe = {
  name: string;
  surname: string;
  dob: string; //dd-mm-yyy  //optional
  idCardNumber: string; //optional
  mobileNumber: string;
  email: string; //optional
  pdpaAccepted: boolean;
  pdpaLink: string;
  subscribeNewsLetter: boolean;
  userName: string;
  pin: string;
  referralId: string;
};

type verifyOTPtype = {
  mobileNumber: string;
  otp: string;
};

type resetPINtype = {
  mobileNumber: string;
  pin: string;
};

export const userLoginService = async (data: UserLoginType) => {
  return api.post("/login", data, {});
};

export const regiterUserService = async (data: UserRegisterTYpe) => {
  return api.post("/register", data);
};

export const getOTPByMobileService = async (mobileNumber: string) => {
  deleteItem(AUTH_TOKEN_KEY);
  return api.get(`/send-otp?mobileNumber=${mobileNumber}`);
};

export const verifyMobileNumber = async (mobileNumber: string) => {
  return api.get(`/check-mobilenumber?mobileNumber=${mobileNumber}`);
};

export const checkUserEmail = async (data: object) => {
  return api.post("/check-email", data);
};

export const checkUserName = (userName: string) => {
  return api.get(`/check-username?username=${userName}`);
};

export function validateOTPSignup(dataObj: any) {
  return api.post("/mobile-verification", dataObj);
}
export const getOTPByUsernameSevice = (userName: string) => {
  return api.get(`/reset-password?username=${userName}`);
};

export const verifyOTPService = (data: verifyOTPtype) => {
  return api.post("/mobile-verification", data);
};

export const resetPINService = (data: resetPINtype) => {
  return api.post("/reset-password", data);
};

export const farmList = () => {
  return api.get("/farms");
};

export const vaccineProgramList = (age: number) => {
  return api.get(`/vaccine-program?age=${age}`);
};

export const vaccinesList = () => {
  return api.get(`/vaccines`);
};

export const addHouseService = (data: any) => {
  return api.post("/house", data);
};
export const getHousePerformnceReport = (id: string) => {
  return api.get(`/house-performance/${id}`);
};
export const editHouseService = (data: any) => {
  return api.put("/edit-house", data);
};
export const deleteHouseService = (id: string) => {
  return api.delete(`/delete-house/${id}`);
};

export const getByHouseIdService = (id: string) => {
  return api.get(`/house/${id}`);
};
export const getPerformanceDetailsService = (
  id: string,
  startDate: string,
  endDate: string
) => {
  return api.get(
    `houses/${id}/performance?startDate=${startDate}&endDate=${endDate}`
  );
};

// const commonGetWithToken = async (
//     endpoint: string,
//     token: string
// ) => {
//     try {
//         const response = await Axios.get(endpoint, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//         return response.data;
//     } catch (error) {
//         // Handle the error as needed
//         throw error;
//     }
// };

// const commonGetWithoutToken = async (
//     endpoint: string
// ) => {
//     try {
//         const response = await Axios.get(endpoint);
//         return response.data;
//     } catch (error) {
//         // Handle the error as needed
//         throw error;
//     }
// };

// export { commonGetWithToken, commonGetWithoutToken };

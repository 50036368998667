const StockSvgWithBg = () => (
  <div
    className="w-[54px] h-[54px] rounded-[10px] gap-[10px] p-[10px]"
    style={{
      background:
        "linear-gradient(90deg, #3777BC 0%, #68C184 71.01%, #77F2A1 100%)",
      boxShadow: "4px 4px 20px 0px #0000001A",
    }}
  >
    <svg xmlns="http://www.w3.org/2000/svg" width={34} height={34} fill="none">
      <path
        fill="#fff"
        d="M6.798 5.102a3.4 3.4 0 1 0 0 6.8h20.4a3.4 3.4 0 0 0 0-6.8h-20.4Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M5.098 13.602h23.8v11.9a3.4 3.4 0 0 1-3.4 3.4h-17a3.4 3.4 0 0 1-3.4-3.4v-11.9Zm8.5 5.1a1.7 1.7 0 0 1 1.7-1.7h3.4a1.7 1.7 0 1 1 0 3.4h-3.4a1.7 1.7 0 0 1-1.7-1.7Z"
        clipRule="evenodd"
      />
    </svg>
  </div>
);
export default StockSvgWithBg;

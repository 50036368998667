import {
  IDeleteFarmer,
  IFarmerSubmitForm,
  IGetCustomer,
  IGetDetailFarmer,
  IUpdateStatusFarmerForm,
} from "../../../types/admin/user-management/customer";
import adminAPI from "../../AdminApiService";

export const getCustomer = async (searchParams?: URLSearchParams) => {
  try {
    const { data } = await adminAPI.get<IGetCustomer>(
      `/user-management/farmer?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const deleteFarmer = async (id: string) => {
  return adminAPI.delete("/user-management/farmer/" + id);
};

export const updateStatusFarmer = async (payload: IUpdateStatusFarmerForm) => {
  return adminAPI.put("/user-management/farmer/active", payload);
};

export const getFarmerDetail = async (farmerId: string) => {
  try {
    const { data } = await adminAPI.get<IGetDetailFarmer>(
      `/user-management/farmer/${farmerId}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const editFarmer = async (
  payload: IFarmerSubmitForm,
  idFarmer: string
) => {
  return adminAPI.put(`/user-management/farmer/${idFarmer}`, payload);
};

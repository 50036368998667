import { tw } from "../../../utils/tw";
import dayjs from "dayjs";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getCommaNumber } from "../../../utils/number";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import React from "react";

interface IProps {
  icon: React.ReactNode;
  title: string;
  value: number;
}

const classes = {
  container: tw(`px-4 flex flex-col gap-[6px]`),
};

const ShowIncome = (props: IProps) => {
  const { icon, title, value } = props;

  return (
    <div className={classes.container}>
      <div className="bg-[#DDE4FF] p-4 rounded-[15px] gap-3 flex flex-col">
        <div className="flex flex-row gap-2 items-center">
          {icon}
          {/* <PersonIcon className="text-[#68C184] text-[22px]" /> */}
          <div className="font-sans text-[#34407B] text-[16px] font-semibold">
            {title}
          </div>
        </div>
        <div className="flex flex-row gap-2 items-center justify-center text-[#191919] font-sans">
          <div className="text-[30px] font-bold">{getCommaNumber(value)}</div>
          <div className="flex flex-col items-center justify-center">
            <ArrowDropUpIcon className="text-[#68C184] -mt-[10px]" />
            <div className="text-[12px] font-semibold -mt-[12px]">บาท</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowIncome;

import { useQuery } from "@tanstack/react-query";
import {
  getGraphMonthBasicAccounting,
  getGraphMonthBasicAccountingWithFitter,
  getGraphYearBasicAccounting,
  getListPerFlock,
  getListPerMonth,
} from "../../../../services/BasicAccounting.service";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import {
  defaultFormFilterModal,
  defaultFormSeeDetailsBasicAccounting,
  defaultFormSelectBillType,
  TFormFilterModal,
  TFormSeeDetailsBasicAccounting,
  TFormSelectBillType,
} from "./defaultFormSeeDatails";

export const useSeeDetailsBasicAccountingController = (farmId: string) => {
  const [formSeeDetailsBasicAccounting, setFormSeeDetailsBasicAccounting] =
    useState<TFormSeeDetailsBasicAccounting>(
      defaultFormSeeDetailsBasicAccounting
    );

  const onChangeFormSeeDetailsBasicAccounting = useCallback(
    (key: keyof TFormSeeDetailsBasicAccounting) => (value: any) => {
      setFormSeeDetailsBasicAccounting((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const [formFilterModal, setFormFilterModal] = useState<TFormFilterModal>(
    defaultFormFilterModal
  );

  const onChangeFormFilterModal = useCallback(
    (key: keyof TFormFilterModal) => (value: any) => {
      setFormFilterModal((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const [formImportModal, setFormImportModal] = useState<TFormFilterModal>(
    defaultFormFilterModal
  );

  const onChangeFormImportModal = useCallback(
    (key: keyof TFormFilterModal) => (value: any) => {
      setFormImportModal((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const [formSelectBillType, setFormSelectBillType] =
    useState<TFormSelectBillType>(defaultFormSelectBillType);

  const onChangeFormSelectBillType = useCallback(
    (key: keyof TFormSelectBillType) => (value: any) => {
      setFormSelectBillType((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);

  const onOpenFilterModal = useCallback(() => setIsOpenFilterModal(true), []);
  const onHideFilterModal = useCallback(() => setIsOpenFilterModal(false), []);

  const [isOpenFilterLockYearModal, setIsOpenFilterLockYearModal] =
    useState(false);

  const onOpenFilterLockYearModal = useCallback(
    () => setIsOpenFilterLockYearModal(true),
    []
  );
  const onHideFilterLockYearModal = useCallback(
    () => setIsOpenFilterLockYearModal(false),
    []
  );

  const [isOpenImportModal, setIsOpenImportModal] = useState(false);

  const onOpenImportModal = useCallback(() => setIsOpenImportModal(true), []);
  const onHideImportModal = useCallback(() => setIsOpenImportModal(false), []);

  const [isOpenSelectBillTypeModal, setIsOpenSelectBillTypeModal] =
    useState(false);

  const onOpenSelectBillTypeModal = useCallback(
    () => setIsOpenSelectBillTypeModal(true),
    []
  );
  const onHideSelectBillTypeModal = useCallback(
    () => setIsOpenSelectBillTypeModal(false),
    []
  );

  const { data: DataGraphYearBasicAccounting } = useQuery({
    queryKey: ["get-graph-year-basic-accounting", farmId],
    queryFn: async () => {
      const result = await getGraphYearBasicAccounting({
        farmId,
      });
      return result.result;
    },
  });

  const { data: DataGraphMonthBasicAccounting } = useQuery({
    queryKey: [
      "get-graph-month-basic-accounting",
      farmId,
      formSeeDetailsBasicAccounting.christianYear,
    ],
    queryFn: async () => {
      const result = await getGraphMonthBasicAccounting({
        farmId,
        christianYear:
          +formSeeDetailsBasicAccounting?.christianYear[0]?.value ||
          dayjs().year(),
      });
      return result.result;
    },
  });

  const { data: DataGraphMonthBasicAccountingWithFilter } = useQuery({
    queryKey: [
      "get-graph-month-basic-accounting-with-filter",
      farmId,
      formSeeDetailsBasicAccounting.christianYear,
      formSelectBillType.billType,
    ],
    queryFn: async () => {
      const billType = formSelectBillType.billType
        .map((item) => item.value)
        .join(",");

      const result = await getGraphMonthBasicAccountingWithFitter({
        farmId,
        christianYear:
          +formSeeDetailsBasicAccounting?.christianYear[0]?.value ||
          dayjs().year(),
        expenseTypes: billType,
      });
      return result.result;
    },
  });

  const { data: DataListPerFlock } = useQuery({
    queryKey: [
      "get-list-per-flock",
      farmId,
      formFilterModal.startDate,
      formFilterModal.endDate,
    ],
    queryFn: async () => {
      const result = await getListPerFlock({
        farmId,
        christianYear: dayjs().year(),
        startDate: formFilterModal.startDate
          ? dayjs(formFilterModal.startDate, "YYYY-MM-DD")
              .startOf("day")
              .toDate()
          : undefined,
        endDate: formFilterModal.endDate
          ? dayjs(formFilterModal.endDate, "YYYY-MM-DD").startOf("day").toDate()
          : undefined,
      });
      return result.result;
    },
  });

  const { data: DataListPerMonth } = useQuery({
    queryKey: [
      "get-list-per-month",
      farmId,
      formFilterModal.startDate,
      formFilterModal.endDate,
    ],
    queryFn: async () => {
      const result = await getListPerMonth({
        farmId,
        christianYear: dayjs().year(),
        startDate: formFilterModal.startDate
          ? dayjs(formFilterModal.startDate, "YYYY-MM-DD")
              .startOf("day")
              .toDate()
          : undefined,
        endDate: formFilterModal.endDate
          ? dayjs(formFilterModal.endDate, "YYYY-MM-DD").startOf("day").toDate()
          : undefined,
      });
      return result.result;
    },
  });

  console.log(formSelectBillType);

  return {
    DataGraphYearBasicAccounting,
    DataGraphMonthBasicAccounting,
    DataGraphMonthBasicAccountingWithFilter,
    DataListPerFlock,
    DataListPerMonth,
    formSeeDetailsBasicAccounting,
    setFormSeeDetailsBasicAccounting,
    onChangeFormSeeDetailsBasicAccounting,
    formFilterModal,
    setFormFilterModal,
    onChangeFormFilterModal,
    isOpenFilterModal,
    onOpenFilterModal,
    onHideFilterModal,
    formImportModal,
    setFormImportModal,
    onChangeFormImportModal,
    isOpenImportModal,
    onOpenImportModal,
    onHideImportModal,
    isOpenFilterLockYearModal,
    onOpenFilterLockYearModal,
    onHideFilterLockYearModal,
    formSelectBillType,
    setFormSelectBillType,
    onChangeFormSelectBillType,
    isOpenSelectBillTypeModal,
    onOpenSelectBillTypeModal,
    onHideSelectBillTypeModal,
  };
};

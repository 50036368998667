import dayjs from "dayjs";
import { TDropdownValue } from "../../group-area/views/Dropdown";

export type TFormCreditStatus = {
  grade: TDropdownValue[];
  statusCredit: TDropdownValue[];
  isActive: TDropdownValue[];
  startDate: string;
  endDate: string;
  duration: TDropdownValue[];
};

export const defaultFormCreditStatus: TFormCreditStatus = {
  grade: [],
  statusCredit: [],
  isActive: [],
  startDate: "",
  endDate: "",
  duration: [],
};

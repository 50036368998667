const SortDescending = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="none">
    <path
      stroke="#4B4B4B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3.75 4.5h13m-13 4h9m-9 4h9m5-4v12m0 0-4-4m4 4 4-4"
    />
  </svg>
);
export default SortDescending;

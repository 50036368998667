import {
  Backdrop,
  Box,
  Fade,
  MenuItem,
  Modal,
  SvgIcon,
  SxProps,
  Theme,
} from "@mui/material";
import React, { useEffect } from "react";
import ExclamationCircle from "../../../assets/svg/ExclamationCircle";
import ButtonFillCustom from "../Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../Button/ButtonOutlinedCustom";
import SelectAdminCustom from "../Select/Select";

const style: SxProps<Theme> = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "350px",
  bgcolor: "background.paper",
  boxShadow: 12,
  padding: "24px",
  borderRadius: "12px",
};

interface IRejectOrderUpcomingModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (reason: RejectType) => void;
}

type RejectType = "out_of_stock" | "farmer_cancel_order" | "";

const RejectTypeItem: {
  id: RejectType;
  label: string;
}[] = [
  {
    id: "out_of_stock",
    label: "สต็อคสินค้าไม่พอ",
  },
  {
    id: "farmer_cancel_order",
    label: "ลูกค้ายกเลิกออเดอร์",
  },
];

function RejectOrderUpcomingModal({
  open,
  onClose,
  onSubmit,
}: IRejectOrderUpcomingModalProps) {
  const [typeSelected, setTypeSelected] = React.useState<RejectType>("");
  useEffect(() => {
    if (open) {
      setTypeSelected("");
    }
  }, [open]);
  return (
    <Modal
      aria-labelledby="reject-upcoming-order-modal-title"
      aria-describedby="reject-upcoming-order-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="w-full h-full">
            <div className="flex items-center gap-1">
              <SvgIcon sx={{ color: "#68C184" }}>
                <ExclamationCircle />
              </SvgIcon>
              <span className="font-sans font-bold text-[1rem] text-[#191919]">
                ยืนยันไม่อนุมัติ
              </span>
            </div>
            <div className="pt-6 flex flex-col">
              <span className="font-sans font-medium text-[14px] leading-5 text-[#191919] pb-2">
                เหตุผล
              </span>
              <SelectAdminCustom
                placeholder="เลือก"
                value={typeSelected}
                renderValueArray={RejectTypeItem.map((row) => ({
                  value: row.id,
                  label: row.label,
                }))}
                onChange={(e) => setTypeSelected(e.target.value as RejectType)}
                menuItems={RejectTypeItem.map((item, index) => (
                  <MenuItem key={`reject-item-${index}`} value={item.id}>
                    {item.label}
                  </MenuItem>
                ))}
              />
            </div>
            <div className="flex w-full justify-end gap-2 pt-6">
              <ButtonOutlinedCustom
                title="ยกเลิก"
                btnBorderColor="#68C184"
                btnTextColor="#68C184"
                onClick={onClose}
              />
              <ButtonFillCustom
                title="ยืนยัน"
                disabled={!typeSelected}
                onClick={() => onSubmit(typeSelected)}
              />
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
export default RejectOrderUpcomingModal;

import { useLoaderData, useNavigate } from "react-router-dom";
import backgroundImage from "../../../assets/images/OrderFeedImage/bg.png";
import CheckSuccessIcon from "../../../assets/images/check-success.svg";
import CustomButton from "../../../components/common/Button";

export function ReportSuccess() {
  const navigate = useNavigate();
  const { farmId } = useLoaderData() as { farmId: string };
  return (
    <div
      className="h-full py-4 flex flex-col justify-between"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        height: "100%",
        backgroundSize: "cover",
      }}
    >
      <div className="flex flex-col gap-4 items-center h-[80%] justify-center">
        <img
          width="100px"
          height="100px"
          src={CheckSuccessIcon}
          alt="success"
        />
        <span className="text-[36px] text-[#ffff] font-semibold text-center">
          เราได้รับการรายงาน <br />
          ของคุณแล้ว
        </span>
        <span className="text-[16px] text-[#ffff] font-medium text-center">
          รอการอนุมัติรายงาน <br />
          ไม่เกิน 24 ชม. ขอบคุณค่ะ
        </span>
      </div>
      <div className="flex flex-col gap-4 items-center px-4 h-[20%] justify-end">
        <CustomButton
          variant="secondary"
          label="ติดต่อเรา"
          onClick={() => navigate("/contact-seller")}
        />
        <CustomButton
          label="ออเดอร์ของฉัน"
          onClick={() => navigate("/my-order/" + farmId)}
        />
      </div>
    </div>
  );
}

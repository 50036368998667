import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Modal,
  SvgIcon,
  Typography,
} from "@mui/material";
import { memo, useCallback, useState } from "react";
import backgroundImage from "../../../../../assets/images/OrderFeedImage/bg.png";
import CheckSuccessIcon from "../../../../../assets/images/check-success.svg";

import { useNavigate } from "react-router-dom";
import { tw } from "../../../../../utils/tw";
import CustomButton from "../../../../../components/common/Button";

interface INewPinSaveModal {
  isOpen: boolean;
}

const classes = {
  wrapper: tw(`overflow-scroll`),
  container: tw(`w-full h-full bg-[white]`),
  headerContainer: tw(`h-[120px] flex items-center`),
  detailContainer: tw(`flex flex-col w-full h-full gap-[16px] px-[16px]`),
  text1: tw(`font-bold text-[16px] text-[#3777BC]`),
};

const NewPinSaveModal = (props: INewPinSaveModal) => {
  const { isOpen } = props;

  const navigate = useNavigate();

  return (
    <Modal open={isOpen} className={classes.wrapper}>
      <div
        className="h-full py-4 flex flex-col justify-between"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          height: "100%",
          backgroundSize: "cover",
        }}
      >
        <div className="flex flex-col gap-4 items-center h-[80%] justify-center px-5">
          <img
            width="100px"
            height="100px"
            src={CheckSuccessIcon}
            alt="success"
          />
          <span className="text-[36px] text-[#ffff] font-semibold">
            New PIN Saved!
          </span>
          <span className="text-[16px] text-[#ffff] font-medium text-balance text-center">
            We already set your new pin Note out your new PIN for seamless log
            in next time
          </span>
        </div>
        <div className="flex flex-col gap-4 items-center px-4 h-[20%] justify-end">
          <CustomButton
            label={"Homepage"}
            onClick={() => navigate("/farmer")}
          />
        </div>
      </div>
    </Modal>
  );
};

export default memo(NewPinSaveModal);

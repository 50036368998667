const MockQrCode = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={269}
    height={269}
    viewBox="0 0 400 400"
  >
    <path d="M12 57.5V103h91V12H12v45.5zm78 0V90H25V25h65v32.5z" />
    <path d="M38 57.5V77h39V38H38v19.5z" />
    <path d="M129 18.5V25h-13v13h13v13h-13v13h25v39h13V64h13v13h39v13h-13v51h-39v13h-13v-25h13v-13H90v13H77v-13H12v13h26v12H25v13H12v13h13v39H12v13h26v-13h13v13h13v13H38v13h13v13h13v25h13v-25H64v-26h39v-13H77v-13h39v103h13v-13h12v39h-12v-13h-13v65h25v-13h-12v-13h12v-26h13v-52h13v13h13v13h-13v26h13v-26h13v39h13v13h-13v13h13v-13h13v26h51v-13h13v13h13v-13h-13v-13h-13v-26h39v13h-13v13h13v26h52v-13h13v-26h13v-39h-13v13h-26v13h-13v-26h26v-13h26v-26h-13v-12h-13v-39h13v-13h-13v-13h-26v-13h26v-13h13v-13h-13v13h-13v-13h-13v26h-13v13h-13v-13h-26v13h-13v-26h13v-13h26v13h13v-13h-13v-13h26v-12h13v-13h-13v13h-13v-13h-13v13h-13v-13h-13v13h-25v12h25v13h-25v26h-13v-13h-26v-13h26v-13h-13v-12h13v-13h-13V90h13v13h13v13h12v-13h13V64h-13V51h13V12h-64v13h-13V12h-13v13h-39v13h-13V12h-12v6.5zm141 13V38h-12v13h-26V38h-13V25h51v6.5zm-77 13V51h13v13h26v13h-26V64h-13V51h-13v13h-13V38h26v6.5zm-39 13V64h-13V51h13v6.5zM270 90v13h-12V90h-13V77h25v13zm-51 13v13h-13V90h13v13zM64 141.5V154h39v-13H90v-12h13v12h13v-12h13v64h25v-13h-13v-13h13v13h13v-26h26v13h-13v13h26v13h-26v13h13v26h-13v13h26v-13h13v-13h13v-13h-13v-13h13v-13h13v26h13v-13h12v26h13v-26h13v26h13v13h-13v13h13v25h-39v26h-12v-13h-13v13h-13v13h26v13h12v13h-25v-13h-13v-13h-13v13h-13v-13h-13v-13h-13v-13h-13v-25h13v-13h-26v-26h-25v-13h-13v-13H77v13H64v-13H51v-13h13v-13H25v-13h13v-13h13v-12h13v12.5zm155-6.5v6h-13v-12h13v6zm77 0v6h-13v-12h13v6zm39 64.5v6.5h26v13h-26v13h13v26h13v12h-13v13h-13v-25h-13v-13h13v-13h-13v-13h13v-13h-13v-13h13v6.5zm-194 39v6.5h-12v-13h12v6.5zm13 32V283h-25v-13h12v-12h13v12.5zm168 32V322h-39v-39h39v19.5zm-103 39v6.5h-13v-13h13v6.5zm116 0v6.5h-13v-13h13v6.5zM245 361v13h-13v-26h13v13zm90 6.5v6.5h-13v-13h13v6.5zm26 0v6.5h-13v-13h13v6.5z" />
    <path d="M77 173.5v6.5H64v13h13v-13h26v-13H77v6.5zM167 186.5v6.5h13v-13h-13v6.5zM167 219v13h13v-26h-13v13zM232 225.5v6.5h-13v13h-13v25h-26v13h26v-13h13v13h26v-13h25v-12h-25v12h-13v-38h13v-13h-13v6.5z" />
    <path d="M258 238.5v6.5h25v-13h-25v6.5zM206 302.5v6.5h13v-13h-13v6.5zM296 302.5v6.5h13v-13h-13v6.5zM296 57.5V103h91V12h-91v45.5zm78 0V90h-65V25h65v32.5z" />
    <path d="M322 57.5V77h39V38h-39v19.5zM116 90v13h13V77h-13v13zM167 109.5V129h13V90h-13v19.5zM361 122.5v6.5h13v12h13v-12h-13v-13h-13v6.5zM374 186.5v6.5h13v-13h-13v6.5zM12 257.5V283h13v-51H12v25.5zM374 238.5v6.5h13v-13h-13v6.5zM90 251.5v6.5h13v-13H90v6.5zM90 276.5v6.5h13v-13H90v6.5zM12 341.5V387h91v-91H12v45.5zm78 0V374H25v-65h65v32.5z" />
    <path d="M38 341.5V361h39v-39H38v19.5zM154 367.5V387h26v-13h-13v-13h13v-13h-26v19.5z" />
  </svg>
);
export default MockQrCode;

import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import AppBarFS from "../../../components/app-bar/AppBar";
import { getOrderHistory } from "../../../services/admin/sale-order/OrderHistory.service";
import { IMyOrder, ListOrder } from "../../../types/MyOrderFeed.type";
import { Order } from "../../farm-past-performance/types";
import CardFarmerOrder from "../my-order/my-order-component/CardFarmerOrder";
import FilterOrderAndOrderTime from "../my-order/my-order-component/filter/FilterOrderAndOrderTime";
// import customParseFormat from "dayjs/plugin/customParseFormat";
// dayjs.extend(customParseFormat);

interface ISearchFilter {
  orderBy: Order;
  startDate: string;
  endDate: string;
  nextPageCursor: string;
}

export default function OrderHistory() {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const { orderHistory, farmId } = useLoaderData() as {
    orderHistory: IMyOrder;
    farmId: string;
  };

  const [orders, setOrders] = useState<ListOrder[]>(
    orderHistory.result.orderFeeds
  );
  const [searchFilter, _setSearchFilter] = useState<ISearchFilter>({
    orderBy: "desc",
    startDate: "",
    endDate: "",
    nextPageCursor: orderHistory.result.nextPageCursor,
  });
  const searchFilterRef = useRef(searchFilter);
  const setSearchFilter = (val: Partial<ISearchFilter>) => {
    searchFilterRef.current = {
      ...searchFilterRef.current,
      ...val,
    };
    _setSearchFilter((prev) => ({
      ...prev,
      ...val,
    }));
  };

  /* get api on scroll max height */
  const handleFetchInfiniteScroll = async () => {
    const records = await getOrderHistory(
      farmId,
      searchFilterRef.current.orderBy,
      searchFilterRef.current.startDate,
      searchFilterRef.current.endDate,
      searchFilterRef.current.nextPageCursor
    );
    const newOrders = [...orders, ...(records?.result?.orderFeeds ?? [])];
    setSearchFilter({ nextPageCursor: records?.result?.nextPageCursor });
    setOrders(newOrders);
  };

  const handleScroll = async () => {
    const scrollPercent = Math.round(
      (window.scrollY /
        (document.documentElement.scrollHeight - window.innerHeight)) *
        100
    );
    if (scrollPercent === 100 && searchFilterRef.current.nextPageCursor) {
      await handleFetchInfiniteScroll();
    }
  };

  /* function filter order history */
  const handleOnSubmitFilter = async (
    sortStartDate: string,
    sortEndDate: string,
    sortOrderBy: Order
  ) => {
    const d1 = dayjs(sortStartDate, "YYYY-MM-DD").isValid()
      ? dayjs(sortStartDate, "YYYY-MM-DD")
      : "";
    const d2 = dayjs(sortEndDate, "YYYY-MM-DD").isValid()
      ? dayjs(sortEndDate, "YYYY-MM-DD")
      : "";
    setOrders([]);
    const data = await getOrderHistory(
      farmId,
      sortOrderBy,
      d1.toString(),
      d2.toString()
    );
    setSearchFilter({
      orderBy: sortOrderBy,
      nextPageCursor: data?.result.nextPageCursor,
      startDate: d1.toString(),
      endDate: d2.toString(),
    });
    setOrders(data?.result.orderFeeds ?? []);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className="flex flex-col w-full bg-[#ffff] overflow-scroll"
      ref={ref}
      style={{ maxHeight: "200vh" }}
    >
      <div className="py-4 rounded-b-[40px] bg-[#ffff]">
        <AppBarFS
          onIconPress={() => navigate("/feed-order-and-delivery/" + farmId)}
          title="ประวัติคำสั่งซื้อ"
        />
      </div>
      {orders.length > 0 ? (
        <FilterOrderAndOrderTime
          handleOnResetFilter={() =>
            handleOnSubmitFilter("", "", searchFilter.orderBy)
          }
          handleOnSubmitFilter={(startDate, endDate, orderBy) =>
            handleOnSubmitFilter(startDate, endDate, orderBy)
          }
          title="ประวัติคำสั่งซื้อทั้งหมด"
        />
      ) : null}
      {orders.length > 0 ? (
        orders.map((order, key) => {
          return (
            <CardFarmerOrder
              key={key}
              primaryButtonText={"สั่งซื้ออีกครั้ง"}
              isPrimaryDisabled={false}
              onPrimaryClick={() => {
                navigate(
                  "/feed-order-and-delivery/order-feed/" +
                    farmId +
                    "?orderId=" +
                    order.id
                );
              }}
              isDisplaySecondaryButton={false}
              onMenuClick={() =>
                navigate("/order-history-detail/" + farmId + "/" + order.id)
              }
              order={order}
            />
          );
        })
      ) : (
        <div className="mt-[50%] flex justify-center items-center text-[15px]">
          ไม่มีประวัติคำสั่งซื้อ
        </div>
      )}
    </div>
  );
}

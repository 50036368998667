import { get } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Location } from "react-router-dom";
import { Schema } from "yup";

interface TUseFormValidatorProps {
  errorSchema: Schema<any>;
  onValidateSuccess: (props?: any) => void;
  onValidateError?: (firstErrorMessage: string) => void;
  form: any;
  disableClearIsSubmit?: boolean;
  validationContext?: object;
}
export interface IValidationContext {
  location: Location;
}

const useFormValidator = (
  props: TUseFormValidatorProps & { validationContext?: IValidationContext }
) => {
  const {
    errorSchema,
    onValidateSuccess,
    onValidateError,
    form,
    disableClearIsSubmit,
    validationContext = {},
  } = props;
  const [isSubmit, setIsSubmit] = useState(false);
  const [errors, setErrors] = useState<any>({});

  const validator = useCallback(
    (formIn?: any) => {
      try {
        errorSchema.validateSync(formIn || form, {
          context: validationContext,
          abortEarly: false,
        });
        setErrors({});
        return {
          isValid: true,
          errorTexts: {},
          firstError: "",
        };
      } catch (error: any) {
        let errorTexts = {};
        for (let i = 0; i < error.inner.length; i++) {
          const e = error.inner[i];
          if (e.path) {
            errorTexts = { ...errorTexts, [e.path]: e.message };
          }
        }
        setErrors(errorTexts);
        return {
          isValid: false,
          errorTexts,
          firstError: get(
            errorTexts,
            [Object.keys(errorTexts)[0]],
            "something went wrong."
          ),
        };
      }
    },
    [errorSchema, form, validationContext]
  );

  const onSubmit = useCallback(
    (props?: any) => {
      const result = validator();
      if (result.isValid) {
        onValidateSuccess(props);
        if (!disableClearIsSubmit) {
          setIsSubmit(false);
        } else {
          setIsSubmit(true);
        }
      } else if (!isSubmit) {
        setIsSubmit(true);
        onValidateError &&
          onValidateError(
            get(
              result.errorTexts,
              [Object.keys(result.errorTexts)[0]],
              "something went wrong."
            )
          );
      } else {
        onValidateError &&
          onValidateError(
            get(
              result.errorTexts,
              [Object.keys(result.errorTexts)[0]],
              "something went wrong."
            )
          );
      }
      return result.isValid;
    },
    [errorSchema, form, isSubmit, onValidateSuccess, validationContext]
  );

  useEffect(() => {
    if (isSubmit) {
      validator();
    }
  }, [isSubmit, JSON.stringify(form)]);

  const resetError = () => {
    setErrors({});
    setIsSubmit(false);
  };

  return {
    errors,
    isSubmit,
    setIsSubmit,
    onSubmit,
    resetError,
    validator,
  };
};

export default useFormValidator;

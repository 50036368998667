import { datadogRum } from "@datadog/browser-rum";
import { createContext, useContext, useEffect, useMemo } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { AUTH_TOKEN_KEY } from "../../../const/app.constant";
import { getItem } from "../../../services/StorageService";
import { useLocalStorage } from "../../../utils/useAuth.hook";

const AuthContext = createContext({});

export const ProtectedRoute = ({ children }: any) => {
  const { user, setUser } = useAuth();

  if (!getItem(AUTH_TOKEN_KEY) && user) {
    setUser(null);
    return <Navigate to="/login" />;
  }
  if (!user || !user.isLoggedIn) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }

  return children;
};

export const UnprotectedRoute = ({ children }: any) => {
  const { user } = useAuth();
  if (user && user.isLoggedIn && getItem(AUTH_TOKEN_KEY)) {
    // user is not authenticated
    return <Navigate to="/farms" />;
  }
  return children;
};

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useLocalStorage("token", "");
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data: string, role: string, firstTimeLogin: boolean) => {
    setUser(data);
    if (role === "partner") {
      if (firstTimeLogin) {
        navigate("/partner/reset-pin/pin-authentication");
      } else {
        navigate("/partner");
      }
    } else if (role === "farmer") {
      navigate("/farms");
    }
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    navigate("/login", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      setUser,
      login,
      logout,
    }),
    [user]
  );

  useEffect(() => {
    if (user) {
      console.log("current username:", user.value);
      console.log("current user type:", user.userRole ? "partner" : "farmer");
      datadogRum.setUser({ id: user.value });
      datadogRum.setUserProperty("type", user.role ? "partner" : "farmer");
    }
  }, [user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): any => {
  return useContext(AuthContext);
};

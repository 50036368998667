import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router-dom";
import BackgroundImage from "../../../assets/images/OnboardingImages/BackgrounImage.png";
// import BackgroundImage2 from "../../../assets/images/OnboardingImages/BackgrounImage2.png";
import BackgroundImage3 from "../../../assets/images/OnboardingImages/BackgroundImage3.png";
import FarmIcon from "../../../assets/images/OnboardingImages/FarmIcon.svg";
import CustomButton from "../../../components/common/Button";

const OnboardingPage = () => {
  const carouselRef: any = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNextSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.onClickNext();
    }
  };
  useEffect(() => {
    localStorage.removeItem("vaccineProgram");
  }, []);

  return (
    <Carousel className="onboardingPageSlider" ref={carouselRef}>
      <div className="flex h-screen">
        <div className="flex-grow">
          <div
            className={`h-[60%] w-full bg-gray-200  bg-cover`}
            style={{ backgroundImage: `url(${BackgroundImage})` }}
          >
            <div className="flex relative justify-center top-10 z-1">
              <img src={FarmIcon} style={{ width: "50%" }} />
            </div>
          </div>
          <div className="h-1/2  flex flex-col justify-around w-full bg-white-200 border-l border-r py-[15px] px-[25px] rounded-[45px] bg-[#ffffff] transform translate-y-[-60px]">
            <div className=" text-left">
              <h3 className="font-sansThai font-medium text-[30px] text-justify text-[#34407B]">
                {t("onboarding.intro_title_one")}
              </h3>
              <p className="flex-none order-1 self-stretch flex-grow-0 font-sansThai font-[500] text-[20px] text-[#646464]">
                {t("onboarding.intro_description_one")}
              </p>
            </div>
            <div className="flex justify-between mt-[70px] ">
              <div
                data-testid="skip-btn"
                onClick={() => navigate("/welcome")}
                className="flex"
                style={{
                  color: "#68C184",
                  fontWeight: 400,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>{t("onboarding.skip_button_label")}</span>
              </div>
              <div style={{ width: "151px", height: "59px" }}>
                <CustomButton
                  datatestid="nextSlideBtn"
                  onClick={handleNextSlide}
                  variant={"tertiary"}
                  label={t("onboarding.next_button_label")}
                  size={"large"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex h-screen">
        <div className="flex-grow">
          <div
            className={`h-[60%] w-full bg-gray-200  bg-cover`}
            style={{ backgroundImage: `url(${BackgroundImage3})` }}
          ></div>
          <div className="h-1/2 w-full flex flex-col justify-around bg-white-200 border-l border-r py-[15px] px-[25px] rounded-[45px] bg-[#ffffff] transform translate-y-[-60px]">
            <div className=" text-left">
              <h3 className="font-sansThai font-medium text-[30px] text-justify text-[#34407B]">
                {t("onboarding.intro_title_two")}
              </h3>
              <p className="flex-none order-1 self-stretch flex-grow-0 font-sansThai font-[500] text-[20px] text-[#646464]">
                {t("onboarding.intro_description_two")}
                {/* บริหารฟาร์ม ไม่ว่าจะเป็น Farm management, Financial support and training */}
              </p>
            </div>
            <div className="flex justify-between">
              <div
                data-testid="skip-btn2"
                onClick={() => navigate("/welcome")}
                className="flex"
                style={{
                  color: "#68C184",
                  fontWeight: 400,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>{t("onboarding.skip_button_label")}</span>
              </div>
              <div style={{ width: "151px", height: "59px" }}>
                <CustomButton
                  datatestid="next-btn"
                  variant={"tertiary"}
                  label={t("onboarding.next_button_label")}
                  size={"large"}
                  onClick={() => {
                    navigate("/welcome");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  );
};

export default OnboardingPage;

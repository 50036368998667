import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

export interface ITableBasicCustomHeadCell {
  disablePadding: boolean;
  label: string;
  width: string;
}

export const StyledTableBasicCustomCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#D9EBFE",
    color: "black",
  },
  [`&.${tableCellClasses.body}`]: {
    // fontSize: 14,
    // fontWeight: 500,
    padding: "0px",
    border: "0px",
    paddingBottom: "12px",
  },
}));

export const StyledTableBasicCustomRow = styled(TableRow)(() => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface ITableBasicCustomProps {
  headerItems: ITableBasicCustomHeadCell[];
  tableBodyChildren: React.ReactNode;
}

function TableBasicCustom({
  headerItems,
  tableBodyChildren,
}: ITableBasicCustomProps) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: "0px",
        boxShadow: "none",
        bgcolor: "transparent",
        overflow: "unset",
      }}
    >
      <Table aria-labelledby="table-show-data" size={"medium"}>
        <TableHead
          sx={{
            bgcolor: "transparent",
            border: "0px",
          }}
        >
          <TableRow key={`header`}>
            {headerItems.map((item, index) => (
              <TableCell
                key={index}
                align={"left"}
                sx={{
                  color: "#1B2128",
                  height: "50px",
                  justifyContent: "start",
                  fontFamily: "IBM Plex Sans Thai",
                  fontWeight: 700,
                  fontSize: "14px",
                  border: "0px",
                  padding: "0px 0px",
                }}
              >
                <div className="flex flex-col" style={{ width: item.width }}>
                  <span>{item.label}</span>
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{tableBodyChildren}</TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableBasicCustom;

import dayjs, { Dayjs } from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLoaderData, useNavigate } from "react-router-dom";
import AppBarFS from "../../../components/app-bar/AppBar";
import { snakeActions } from "../../../redux/slices/snakeSlice";
import {
  getMyOrder,
  getMyOrderNextPage,
  putConfirmReceive,
} from "../../../services/MyOrder.service";
import {
  putCancelOrder,
  putChangeShippingDate,
} from "../../../services/OrderFeed.service";
import { IMyOrder, ListOrder } from "../../../types/MyOrderFeed.type";
import delay from "../../../utils/delay";
import { Order } from "../../farm-past-performance/types";
import { OrderFeedBaseSnackBar } from "../order-feed/OrderFeedBaseSnackbar";
import CardFarmerOrder from "./my-order-component/CardFarmerOrder";
import FilterOrder from "./my-order-component/filter/FilterOrder";
import ChangeConfirmDateModal from "./my-order-component/modal/ChangeConfirmDateModal";
import ConfirmModal from "./my-order-component/modal/ConfirmModal";
import ConfirmToCancelModal from "./my-order-component/modal/ConfirmToCancelModal";
import ReceiptProductModal from "./my-order-component/modal/ReceiptProductModal";

export default function MyOrder() {
  const navigate = useNavigate();
  const { myOrder, farmId } = useLoaderData() as {
    myOrder: IMyOrder;
    farmId: string;
  };
  const dispatch = useDispatch();
  const [orders, setOrders] = useState<ListOrder[]>(myOrder.result.orderFeeds);
  const [confirmDate, setConfirmDate] = useState<Dayjs | null>(null);
  const [isOpenReceiptProductModal, setIsOpenReceiptProductModal] =
    useState<boolean>(false);
  const [isOpenChangeConfirmDateModal, setIsOpenChangeConfirmDateModal] =
    useState<boolean>(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [isOpenConfirmToCancelModal, setIsOpenConfirmToCancelModal] =
    useState<boolean>(false);
  const [openSnackbarEditDate, setOpenSnackbarEditDate] = useState(false);
  const [openSnackbarCancel, setOpenSnackbarCancel] = useState(false);
  const [selectedOrderId, setSelectedOrder] = useState<string>("");
  const [orderBy, setOrderBy] = useState<Order>("desc");
  const [nextPageCursor, _setNextPageCursor] = useState<string>(
    myOrder.result.nextPageCursor
  );
  const nextPageCursorRef = useRef(nextPageCursor);
  const setNextPageCursor = (value: string) => {
    nextPageCursorRef.current = value;
    _setNextPageCursor(value);
  };
  const ref = useRef<HTMLDivElement>(null);
  const fetchData = async (nextPageCursor: string) => {
    const records = await getMyOrderNextPage(farmId, orderBy, nextPageCursor);
    const newOrders = [...orders, ...records.result.orderFeeds];
    setNextPageCursor(records.result.nextPageCursor);
    setOrders(newOrders);
  };
  const handleScroll = async () => {
    const scrollPercent = Math.round(
      (window.scrollY /
        (document.documentElement.scrollHeight - window.innerHeight)) *
        100
    );
    if (scrollPercent === 100 && nextPageCursorRef.current) {
      await fetchData(nextPageCursorRef.current);
    }
  };
  const handleCloseSnackbarEdit = (
    _: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbarEditDate(false);
  };
  const handleCloseSnackbarCancel = (
    _: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbarCancel(false);
  };
  const handleResetOrderBy = async () => {
    setOrders([]);
    const myOrder = await getMyOrder(farmId, orderBy);
    if (myOrder) {
      setNextPageCursor(myOrder.result.nextPageCursor);
      setOrders(myOrder.result.orderFeeds);
    }
  };
  const [changeDate, setChangeDate] = useState(dayjs());

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    handleResetOrderBy();
  }, [orderBy]);
  return (
    <div
      className="h-screen flex flex-col w-full bg-[#ffff] overflow-scroll"
      ref={ref}
      style={{ minHeight: "200vh" }}
      key={myOrder.result.nextPageCursor}
    >
      <div className="py-4 rounded-b-[40px] bg-white">
        <AppBarFS
          onIconPress={() => navigate("/feed-order-and-delivery/" + farmId)}
          title={"ออเดอร์ของฉัน"}
        />
      </div>
      <FilterOrder
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        title={"ออเดอร์ทั้งหมด"}
      />
      {orders.map((order, key) => {
        //status
        const waitingForSaleApproval =
          order.status === "waiting_for_sale_approval";
        const waitToForDelivery = order.status === "waiting_for_delivery";
        const waitingForSaleSendToLender =
          order.status === "waiting_for_sale_send_to_lender";
        const delivery = order.status === "delivery";
        const waitingForLenderApprove =
          order.status === "waiting_for_lender_approval";

        // date
        const shippingDate = dayjs(order.shippingDate);
        const today = new Date();

        let isDisplaySecondaryButton: boolean;
        let textDescription: string;
        let primaryButtonText: string;
        let onPrimaryClick: () => void;
        let isPrimaryDisabled: boolean;
        const isSecondaryDisabled = dayjs().isBefore(shippingDate)
          ? Math.abs(shippingDate.diff(today, "day")) <= 4
          : true;

        if (
          waitingForSaleApproval ||
          waitToForDelivery ||
          waitingForSaleSendToLender ||
          waitingForLenderApprove
        ) {
          primaryButtonText = "เปลี่ยนวันจัดส่ง";
          onPrimaryClick = () => {
            setIsOpenChangeConfirmDateModal(true);
          };
          isPrimaryDisabled = dayjs().isBefore(shippingDate)
            ? Math.abs(shippingDate.diff(today, "day")) <= 2
            : true;
          isDisplaySecondaryButton = true;
          textDescription =
            "หากมีการเปลี่ยนแปลงคำสั่งซื้อ กรุณาดำเนินการก่อนวันจัดส่ง";
        } else {
          primaryButtonText = "ยืนยันรับสินค้า";
          onPrimaryClick = () => {
            setIsOpenReceiptProductModal(true);
          };
          isPrimaryDisabled = delivery;
          isDisplaySecondaryButton = false;
          textDescription = "กรุณากดยืนยันหลังจากได้รับและตรวจสอบสินค้าแล้ว";
        }

        return (
          <CardFarmerOrder
            key={key}
            primaryButtonText={primaryButtonText}
            isPrimaryDisabled={isPrimaryDisabled || delivery}
            onPrimaryClick={() => {
              setSelectedOrder(order.id);
              setChangeDate(dayjs(order.shippingDate));
              onPrimaryClick();
            }}
            isSecondaryDisabled={isSecondaryDisabled}
            onSecondaryClick={() => {
              setSelectedOrder(order.id);
              setIsOpenConfirmToCancelModal(true);
            }}
            isDisplaySecondaryButton={isDisplaySecondaryButton}
            textDescription={textDescription}
            isTotalItemPrice={true}
            onMenuClick={() =>
              navigate("/my-order-detail/" + farmId + "/" + order.id)
            }
            order={order}
          />
        );
      })}
      {/* change delivery date */}
      <ChangeConfirmDateModal
        defaultDate={changeDate}
        onOpenModal={isOpenChangeConfirmDateModal}
        onCloseModal={(isConfirm, value) => {
          setIsOpenChangeConfirmDateModal(false);
          if (isConfirm) {
            setConfirmDate(value);
            setIsOpenConfirmModal(true);
          }
        }}
      />
      {/* receipt product modal */}
      <ReceiptProductModal
        onOpenModal={isOpenReceiptProductModal}
        onCloseModal={async (isConfirm) => {
          setIsOpenReceiptProductModal(false);
          if (isConfirm) {
            await putConfirmReceive(selectedOrderId);
            navigate(
              "/feed-order-and-delivery/" +
                farmId +
                "/" +
                selectedOrderId +
                "/view-document"
            );
          }
        }}
      />
      {/* Change delivery date modal */}

      {/* confirm to change delivery date modal */}
      <ConfirmModal
        onOpenModal={isOpenConfirmModal}
        onCloseModal={async (isConfirm) => {
          setIsOpenConfirmModal(false);
          if (isConfirm) {
            const response = await putChangeShippingDate(
              selectedOrderId,
              dayjs(confirmDate)
            );
            if (response.status !== 200) {
              dispatch(
                snakeActions.showMessage({
                  message: "แก้ไขวันที่จัดส่งไม่สำเร็จ",
                  type: "error",
                })
              );
            } else {
              setOpenSnackbarEditDate(true);
              await delay(2000);
              window.location.reload();
            }
          } else {
            setIsOpenChangeConfirmDateModal(true);
          }
        }}
      />
      {/* confirm to cancel order modal */}
      <ConfirmToCancelModal
        onOpenModal={isOpenConfirmToCancelModal}
        onCloseModal={async (isConfirm) => {
          setIsOpenConfirmToCancelModal(false);
          if (isConfirm) {
            await putCancelOrder(selectedOrderId);
            setOpenSnackbarCancel(true);
            await delay(2000);
            navigate("/feed-order-and-delivery/" + farmId);
          }
        }}
      />
      {/* alert message on change delivery date success */}
      <OrderFeedBaseSnackBar
        openSnackbar={openSnackbarEditDate}
        handleCloseSnackbar={handleCloseSnackbarEdit}
        snackbarText={"เปลี่ยนวันจัดส่งสำเร็จ"}
        styleCSS={{
          bottom: "2em",
          zIndex: 1000,
          margin: "0 16px 0 16px",
        }}
      />
      {/* alert message on cancel success */}
      <OrderFeedBaseSnackBar
        openSnackbar={openSnackbarCancel}
        handleCloseSnackbar={handleCloseSnackbarCancel}
        snackbarText={"ยกเลิกคำสั่งซื้อสำเร็จ"}
        styleCSS={{
          bottom: "2em",
          zIndex: 1000,
          margin: "0 16px 0 16px",
        }}
      />
    </div>
  );
}

import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import orderByLodash from "lodash/orderBy";
import { Order } from "../../farm-past-performance/types";
import { IOrderItemProps } from "../views/OrderItem";
import { useQuery } from "@tanstack/react-query";
import { dtoOrderList } from "./dto/dtoOrderList";
import {
  getHistoryOrderList,
  getNewOrderList,
} from "../../../services/PartnerOrderService";

export const useNewOrderController = (isHistory: boolean) => {
  const navigate = useNavigate();
  const [orderBy, setOrderBy] = useState<Order>("desc");
  const [actionModalDetail, setActionModalDetail] = useState<IOrderItemProps>();
  const [isShowActionModal, setIsShowActionModal] = useState(false);

  const onBack = useCallback(() => {
    navigate("/order");
  }, []);

  const onMoreDetail = useCallback(
    (id: string) => () => {
      navigate(`/order/detail/${id}?isHistory=${isHistory ? "yes" : "no"}`);
    },
    []
  );

  const onAction = useCallback(
    (orderDetail: IOrderItemProps) => () => {
      setActionModalDetail(orderDetail);
      setIsShowActionModal(true);
    },
    []
  );

  const onCloseAction = useCallback(() => {
    setIsShowActionModal(false);
    setTimeout(() => {
      setActionModalDetail(undefined);
    }, 400);
  }, []);

  const {
    data: _orderList,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["order", isHistory ? "history" : "new"],
    queryFn: async () => {
      if (!isHistory) {
        const result = await getNewOrderList();
        if (result?.orderFeeds) return dtoOrderList(result);
      } else {
        const result = await getHistoryOrderList();
        if (result?.orderFeeds) return dtoOrderList(result);
      }
      return [];
    },
  });

  const orderList = useMemo(() => {
    return orderByLodash(_orderList || [], ["orderDate"], [orderBy]);
  }, [_orderList, orderBy]);

  return {
    orderList,
    onBack,
    onMoreDetail,
    orderBy,
    setOrderBy,
    onAction,
    onCloseAction,
    isShowActionModal,
    actionModalDetail,
  };
};

export function InvoiceListSvg() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 16.8796C2.30556 16.8796 1.71528 16.6365 1.22917 16.1504C0.743056 15.6643 0.5 15.074 0.5 14.3796V11.8796H3V0.212891L4.25 1.46289L5.5 0.212891L6.75 1.46289L8 0.212891L9.25 1.46289L10.5 0.212891L11.75 1.46289L13 0.212891L14.25 1.46289L15.5 0.212891V14.3796C15.5 15.074 15.2569 15.6643 14.7708 16.1504C14.2847 16.6365 13.6944 16.8796 13 16.8796H3ZM13 15.2129C13.2361 15.2129 13.434 15.133 13.5938 14.9733C13.7535 14.8136 13.8333 14.6157 13.8333 14.3796V2.71289H4.66667V11.8796H12.1667V14.3796C12.1667 14.6157 12.2465 14.8136 12.4062 14.9733C12.566 15.133 12.7639 15.2129 13 15.2129ZM5.5 6.04622V4.37956H10.5V6.04622H5.5ZM5.5 8.54622V6.87956H10.5V8.54622H5.5ZM12.1667 6.04622C11.9306 6.04622 11.7326 5.96636 11.5729 5.80664C11.4132 5.64692 11.3333 5.449 11.3333 5.21289C11.3333 4.97678 11.4132 4.77886 11.5729 4.61914C11.7326 4.45942 11.9306 4.37956 12.1667 4.37956C12.4028 4.37956 12.6007 4.45942 12.7604 4.61914C12.9201 4.77886 13 4.97678 13 5.21289C13 5.449 12.9201 5.64692 12.7604 5.80664C12.6007 5.96636 12.4028 6.04622 12.1667 6.04622ZM12.1667 8.54622C11.9306 8.54622 11.7326 8.46636 11.5729 8.30664C11.4132 8.14692 11.3333 7.949 11.3333 7.71289C11.3333 7.47678 11.4132 7.27886 11.5729 7.11914C11.7326 6.95942 11.9306 6.87956 12.1667 6.87956C12.4028 6.87956 12.6007 6.95942 12.7604 7.11914C12.9201 7.27886 13 7.47678 13 7.71289C13 7.949 12.9201 8.14692 12.7604 8.30664C12.6007 8.46636 12.4028 8.54622 12.1667 8.54622ZM3 15.2129H10.5V13.5462H2.16667V14.3796C2.16667 14.6157 2.24653 14.8136 2.40625 14.9733C2.56597 15.133 2.76389 15.2129 3 15.2129Z"
        fill="currentColor"
      />
    </svg>
  );
}

import { Dialog, DialogContent, IconButton } from "@mui/material";
import { useState } from "react";
import CrossIcon from "../../../../assets/svg/CrossIcon.svg";
import {
  EInvoiceDebtPaymentOptionDisplay,
  EInvoiceDebtStatus,
  EInvoiceDebtStatusDisplay,
  LogDetail,
} from "../../../../../types/invoice/log.type";

type OverdueModalProps = {
  isOpen: boolean;
  handleClose: (isSuccess: boolean, values?: any) => void;
  logDetail: LogDetail;
};

const classes = {
  container: `items-start w-[50%]`,
  label: `text-sm font-bold pb-3 mb-1`,
  title: `text-sm pb-3 mb-1`,
  flexCenter: `flex justify-between items-center`,
};

const TrackingOverdueModal = ({
  isOpen,
  handleClose,
  logDetail,
}: OverdueModalProps) => {
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => handleClose(true)}
        maxWidth="lg"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={{
          backdrop: {
            style: { backgroundColor: "#000000", opacity: "10%" },
            timeout: 500,
          },
        }}
        PaperProps={{
          sx: {
            borderRadius: "20px",
          },
        }}
      >
        <DialogContent sx={{ width: "600px" }}>
          <div className={classes.flexCenter}>
            <p className="text-lg font-bold pb-3">รายละเอียด</p>
            <IconButton onClick={() => handleClose(true)}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className="flex">
            <div className={classes.container}>
              <p className={classes.label}>เลข Invoice</p>
              <p className={classes.title}>
                {logDetail.invoiceNumbers?.join(", ")}
              </p>
            </div>
            <div className={classes.container}>
              <p className={classes.label}>รับสภาพหนี้/ไม่รับสภาพหนี้</p>
              <p className={classes.title}>
                {
                  EInvoiceDebtStatusDisplay[
                    logDetail.debtStatus as keyof typeof EInvoiceDebtStatusDisplay
                  ]
                }
              </p>
            </div>
          </div>
          <div className="flex">
            <div className={classes.container}>
              <p className={classes.label}>รูปแบบการจ่าย</p>
              <p className={classes.title}>
                {
                  EInvoiceDebtPaymentOptionDisplay[
                    logDetail.debtPaymentOption as keyof typeof EInvoiceDebtPaymentOptionDisplay
                  ]
                }
              </p>
            </div>
            <div className={classes.container}>
              <p className={classes.label}>นัดชำระทุกวันที่</p>
              <p className={classes.title}>-</p>
            </div>
          </div>
          <div className="flex">
            <div className={classes.container}>
              <p className={classes.label}>จำนวนเดือนที่ผ่อน</p>
              <p className={classes.title}>{logDetail.debtPayPerMonth}</p>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TrackingOverdueModal;

import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import React, { ClipboardEvent, KeyboardEvent, useEffect, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { NumericFormat, NumericFormatProps } from "react-number-format";

export interface IRHFTextInputCustomProps {
  borderColor?: string;
  borderRadius?: string;
  textAlign?: string;
  suffix?: string;
  customSuffix?: React.ReactNode;
  customInputStyle?: string;
  focused?: boolean;
  controlName: string;
  numberWithoutComma?: boolean;
  maxLength?: number;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  thousandSeparator?: boolean;
  maxLength?: number;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, thousandSeparator = true, maxLength, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        maxLength={maxLength}
        thousandSeparator={thousandSeparator}
        valueIsNumericString
        allowLeadingZeros
      />
    );
  }
);

function RHFTextInputCustom({
  borderColor,
  borderRadius,
  textAlign,
  suffix,
  customSuffix,
  customInputStyle,
  focused: _focused = false,
  controlName,
  numberWithoutComma,
  maxLength,
  type,
  ...rest
}: TextFieldProps & IRHFTextInputCustomProps) {
  //* Positive number only
  const textFieldRef = useRef<HTMLDivElement>(null);
  const { control } = useFormContext();
  const handleSanitizedKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    //* Get the current value and the key being pressed
    const { key, target } = event;
    const value = (target as HTMLInputElement).value;

    //* Prevent if the key is '-' or if '--' would be formed
    if (key === "-" || (key === "-" && value.includes("-"))) {
      event.preventDefault();
    }
    if (key === "ArrowUp" || key === "ArrowDown") {
      event.preventDefault();
    }
  };

  //* Positive number only
  const handleSanitizedPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const paste = (event.clipboardData || window.Clipboard).getData("text");
    const sanitizedPaste = paste.replace(/-/g, "");

    const target = event.target as HTMLInputElement;
    const start = target.selectionStart || 0;
    const end = target.selectionEnd || 0;

    //* Insert the sanitized text at the cursor position
    const newValue =
      target.value.substring(0, start) +
      sanitizedPaste +
      target.value.substring(end);

    //* Update the input value
    target.value = newValue;
    //* Trigger the change event
    const eventInput = new Event("input", { bubbles: true });
    target.dispatchEvent(eventInput);
  };

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      e.preventDefault();
    };

    const textField = textFieldRef.current;
    if (textField && type === "number") {
      textField.addEventListener("wheel", handleWheel, { passive: false });
    }

    return () => {
      if (textField && type === "number") {
        textField.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  return (
    <Controller
      name={controlName || ""}
      control={control}
      render={({ field }) => (
        <TextField
          {...rest}
          {...field}
          inputRef={textFieldRef}
          fullWidth
          variant="outlined"
          autoComplete="off"
          focused={_focused}
          color={_focused ? "tertiary" : "primary"}
          onPaste={type === "number" ? handleSanitizedPaste : undefined}
          onKeyDown={type === "number" ? handleSanitizedKeyDown : undefined}
          InputProps={{
            inputComponent:
              type === "number" ? (NumericFormatCustom as any) : undefined,
            inputProps: {
              thousandSeparator: !numberWithoutComma,
              maxLength: maxLength,
            },
            sx: {
              borderRadius: borderRadius || "6px !important",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              color: "#191919",
              background: "#FFFFFF",
              borderColor: borderColor || "#E2E2E2",
              textAlign: textAlign || "",
              height: "32px",
              width: "100%",
              paddingRight: "6px",
            },
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: "14px",
                    color: "#191919",
                    paddingRight: "10px",
                  },
                }}
              >
                {customSuffix ? customSuffix : suffix}
              </InputAdornment>
            ),
            className: customInputStyle,
            ...rest?.InputProps,
          }}
          sx={{
            "& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline": {
              border: borderColor ? "none" : "",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: borderColor ? "transparent" : "#E2E2E2",
            },
            // "& .MuiOutlinedInput-notchedOutline": {
            //   borderColor: "#E2E2E2",
            // },
            "& .Mui-focused": {
              background: _focused ? "#FFFFFF" : "",
              "& > svg": {
                color: "#000000 !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderWidth: borderColor ? "0px !important" : "1px !important",
              },
            },
            "& .Mui-disabled": {
              color: "black !important",
              WebkitTextFillColor: "black !important",
              bgcolor: "#FBFBFB !important",
            },
            "& input::placeholder": {
              opacity: 1,
              color: "#979797",
            },
            "& .MuiInputBase-input": {
              height: "1.475em",
              padding: "0px 15px",
              ":-webkit-autofill": {
                bgcolor: "transparent",
              },
            },
          }}
          SelectProps={{
            sx: {
              borderRadius: borderRadius || "28px",
              fontWeight: 500,
              color: "#191919",
              background: "#F7FBFF",
              borderColor: borderColor || "#E2E2E2",
              textAlign: textAlign || "",

              "& .Mui-disabled": {
                color: "#979797 !important",
                WebkitTextFillColor: "#979797 !important",
                bgcolor: "#FFFFFF !important",
              },
            },
          }}
          InputLabelProps={{
            sx: {
              "&.Mui-disabled": {
                color: "#979797 !important",
              },
            },
          }}
        />
      )}
    />
  );
}

export default RHFTextInputCustom;

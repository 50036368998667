import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Table, TableProps } from "@mui/material";

export interface IDataTable {
  detailName?: number | string | null;
  detailValue?: number | string | null;
  color?: string;
  fontSize?: string;
  headerColor?: string;
}
export interface ITableBasicInfoProps {
  tableData: IDataTable[];
  tableProps?: TableProps;
  titleWidth?: string;
}
const TableBasicInfo = ({
  tableData,
  tableProps,
  titleWidth,
}: ITableBasicInfoProps) => {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#D9EBFE",
      color: "black",
    },
    [`&.${tableCellClasses.body}`]: {
      // fontSize: 14,
      // fontWeight: 500,
      padding: "0px",
      border: "0px",
      paddingBottom: "12px",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    // padding: "0px",
    // "&:nth-of-type(odd)": {
    //   backgroundColor: `${swapCellColor ? "#ffffff" : "#f6faff"}`,
    // },
    // "&:nth-of-type(even)": {
    //   backgroundColor: `${swapCellColor ? "#f6faff" : "#ffffff"}`,
    // },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: "0px",
        boxShadow: "none",
        bgcolor: "transparent",
        overflow: "unset",
      }}
    >
      <Table aria-label="customized table" {...tableProps}>
        <TableBody>
          {tableData &&
            tableData.map((item: IDataTable, index: number) => (
              <StyledTableRow key={index}>
                <StyledTableCell
                  align="left"
                  sx={{
                    color: item?.headerColor ?? "#1B2128",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "165px",
                    fontSize: "1rem",
                    fontWeight: 700,
                    verticalAlign: "baseline",
                    ...(item?.fontSize ? { fontSize: item.fontSize } : {}),
                    ...(item?.color ? { color: item.color } : {}),
                    ...(titleWidth ? { width: titleWidth } : {}),
                  }}
                >
                  <span className="font-bold">{item?.detailName}</span>
                </StyledTableCell>
                <StyledTableCell
                  component="th"
                  scope="row"
                  align="left"
                  sx={{
                    color: "#3777BC",
                    fontSize: "1rem",
                    ...(item?.fontSize ? { fontSize: item.fontSize } : {}),
                    ...(item?.color ? { color: item.color } : {}),
                  }}
                >
                  <span className="font-normal">{item?.detailValue}</span>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default TableBasicInfo;

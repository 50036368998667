import { Box, Button, DialogActions } from "@mui/material";
import {
  DatePickerProps,
  DatePickerToolbarProps,
  LocalizationProvider,
  MobileDatePicker,
  PickersActionBarProps,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import i18next, { t } from "i18next";
import { useState } from "react";
import { AppLocale } from "../../../types";
import CustomTextDate from "../Input/CustomTextDate";

function CustomActionBar(props: PickersActionBarProps) {
  const { onAccept, onClear, onCancel, onSetToday, actions, className } = props;

  const handleCancel = () => {
    onCancel();
  };

  const handleSave = () => {
    onAccept();
  };

  return (
    <DialogActions className={className}>
      {actions?.includes("cancel") && (
        <Button
          sx={{ paddingX: "1.5rem" }}
          variant="outlined"
          size="medium"
          color="secondary"
          onClick={handleCancel}
        >
          {t("datePicker.cancelButtonLabel")}
        </Button>
      )}
      {actions?.includes("accept") && (
        <Button
          sx={{ paddingX: "2rem" }}
          variant="contained"
          size="medium"
          onClick={handleSave}
        >
          {t("datePicker.saveButtonLabel")}
        </Button>
      )}
    </DialogActions>
  );
}

const CustomToolBar = (props: DatePickerToolbarProps<dayjs.Dayjs>) => {
  const adapterLocale = i18next.language;
  let textFiledPropsDefault = {
    locale: "th",
  };
  let format = "D MMMM";
  if (props.view === "year") {
    if (adapterLocale === AppLocale.THAI) {
      format = "BBBB";
    } else {
      format = "YYYY";
    }
  } else if (props.view === "month") {
    format = "MMMM";
  }

  return (
    <Box
      className={props.className}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {/* <DatePickerToolbar {...props} /> */}
      <div className="px-6 py-4 flex flex-col gap-2">
        <span className="text-[#9c9c9c]">
          {t("common.datePickerToolBarTitle")}
        </span>
        <span className="font-normal text-4xl">
          {props.value
            ? dayjs(props.value).locale(i18next.language).format(format)
            : "--"}
        </span>
      </div>
    </Box>
  );
};
const DatePickerFS = (
  props: DatePickerProps<Dayjs> & { placeholder?: string }
) => {
  const adapterLocale = i18next.language;
  let textFiledPropsDefault = {
    locale: "th",
  };
  const localeProps = defaultAdapterLocale();
  const textFiledProps = textFiledPropsDefault as any;
  const localeFormat = defaultLocaleFormat();
  function defaultAdapterLocale() {
    if (adapterLocale === AppLocale.THAI) {
      return {
        adapterLocale: AppLocale.THAI,
        dateFormats: {
          year: "BBBB",
          monthAndYear: "MMMM BBBB",
          fullDate: "DD/MM/BBBB",
          // year: "MMMM YYYY",
          // monthAndYear: "MMMM YYYY",
          // fullDate: "DD/MM/YYYY",
        },
      };
    } else {
      return {
        adapterLocale: AppLocale.ENGLISH,
        dateFormats: {
          year: "YYYY",
          monthAndYear: "MMMM YYYY",
          fullDate: "DD/MM/YYYY",
        },
      };
    }
    // }
    // return {};
  }

  function defaultLocaleFormat() {
    if (adapterLocale === AppLocale.THAI) {
      if (!props?.views || props?.views?.includes("day")) {
        return "DD/MM/BBBB";
        // return "DD/MM/YYYY";
      }
    } else if (adapterLocale === AppLocale.ENGLISH) {
      if (!props?.views || props?.views?.includes("day")) {
        return "DD/MM/YYYY";
      }
    }
  }

  // useEffect(() => {
  //   console.log(props?.slotProps?.textField);
  //   if (props?.slotProps?.textField) {
  //     setTextFiledProps((current: any) => {
  //       return {
  //         ...current,
  //         ...props?.slotProps?.textField,
  //       };
  //     });
  //   }
  // }, [props?.slotProps?.textField]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} {...localeProps}>
        <MobileDatePicker
          localeText={{ start: "Check-in", end: "Check-out" }}
          closeOnSelect={false}
          onSelectedSectionsChange={(e) => false}
          format={localeFormat}
          slotProps={{
            actionBar: { actions: ["cancel", "accept"] },
            textField: {
              ...textFiledProps,
            },
          }}
          dayOfWeekFormatter={(_day: string) => _day}
          slots={{
            actionBar: CustomActionBar,
            textField: (textFieldProps) =>
              CustomTextDate({
                ...textFieldProps,
                placeholder: props.placeholder ?? textFieldProps.placeholder,
              }),
            toolbar: CustomToolBar,
          }}
          {...props}
        />
      </LocalizationProvider>
    </>
  );
};

export default DatePickerFS;

import React, { useEffect } from "react";
import SmileFaceStatus from "../../assets/svg/SmileFaceStatus";
import NormalFaceStatus from "../../assets/svg/NormalFaceStatus";
import DizzyFaceStatus from "../../assets/svg/DizzyFaceStatus";
import { SellingReportLevelStatusEnum } from "../farm-past-performance/types";
import { TFunction } from "i18next";

interface IBoxSize {
  defaultBoxSize: string;
  defaultIconSize: string;
  activeBoxSize: string;
  activeIconSize: string;
}

export const DEFAULT_BOX_SIZE: IBoxSize = {
  defaultBoxSize: "36px",
  defaultIconSize: "28px",
  activeBoxSize: "46px",
  activeIconSize: "38px",
};

interface IStatus {
  name: string;
  key: string;
  color: string;
  widthBar: string;
  box: IBoxSize;
  activePointColor: string;
  noneActivePointColor: string;
}

const SliderStatusMap: Record<SellingReportLevelStatusEnum, IStatus> = {
  [SellingReportLevelStatusEnum.None]: {
    name: "None",
    key: "none",
    color: "transparent",
    widthBar: "0%",
    box: DEFAULT_BOX_SIZE,
    activePointColor: "#FFFFFF",
    noneActivePointColor: "#FFFFFF",
  },
  [SellingReportLevelStatusEnum.Poor]: {
    name: "Poor",
    key: "poor",
    color: "#D32F2F",
    widthBar: "35%",
    box: DEFAULT_BOX_SIZE,
    activePointColor: "#FFFFFF",
    noneActivePointColor: "#D32F2F",
  },
  [SellingReportLevelStatusEnum.Medium]: {
    name: "Medium",
    key: "medium",
    color: "#F6C94B",
    widthBar: "65%",
    box: DEFAULT_BOX_SIZE,
    activePointColor: "#FFFFFF",
    noneActivePointColor: "#F6C94B",
  },
  [SellingReportLevelStatusEnum.Good]: {
    name: "Good",
    key: "good",
    color: "#68C184",
    widthBar: "100%",
    box: DEFAULT_BOX_SIZE,
    activePointColor: "#FFFFFF",
    noneActivePointColor: "#FFFFFF",
  },
};

function HousePerformanceStatusSlideV2({
  status,
  t,
}: {
  status: SellingReportLevelStatusEnum;
  t: TFunction<"translation", undefined, "translation">;
}) {
  useEffect(() => {}, [status]);
  return (
    <div className="pt-28 pb-4 h-40 relative">
      <div className="relative w-full h-3 rounded-[64px] bg-[#E2E2E2]">
        <div
          className={`absolute rounded-[64px] h-[12px] top-[0%]`}
          style={{
            backgroundColor: SliderStatusMap[status].color,
            width: SliderStatusMap[status].widthBar,
            border: `1px solid ${SliderStatusMap[status].color}`,
          }}
        ></div>

        <div className="grid grid-cols-11 h-full w-full">
          <div className="col-span-3 w-full self-center flex justify-end relative">
            <div
              className="w-1 h-1 rounded-full"
              style={{
                backgroundColor:
                  SellingReportLevelStatusEnum.Poor === status ||
                  SellingReportLevelStatusEnum.Medium === status
                    ? SliderStatusMap[status].activePointColor
                    : SliderStatusMap[status].noneActivePointColor,
              }}
            ></div>
            <div className="absolute right-0 bottom-6 translate-x-[45%]">
              <div
                className="bg-white rounded-full w-[25px] h-[25px] shadow-box-custom relative"
                style={{
                  width:
                    SellingReportLevelStatusEnum.Poor === status
                      ? SliderStatusMap[status].box.activeBoxSize
                      : SliderStatusMap[status].box.defaultBoxSize,
                  height:
                    SellingReportLevelStatusEnum.Poor === status
                      ? SliderStatusMap[status].box.activeBoxSize
                      : SliderStatusMap[status].box.defaultBoxSize,
                }}
              >
                <div className="triangle-custom"></div>
                {SellingReportLevelStatusEnum.Poor === status && (
                  <div
                    className="absolute capitalize top-[-100%] font-semibold text-[1rem]"
                    style={{ color: SliderStatusMap[status].color }}
                  >
                    {t(
                      `sellingReport.summaryReportTable.status.${SliderStatusMap[status].key}` as any
                    )}
                  </div>
                )}
                <div
                  className="bg-white rounded-full"
                  style={{
                    filter:
                      SellingReportLevelStatusEnum.Poor === status
                        ? `drop-shadow(0px 0px 10px ${SliderStatusMap[status].color})`
                        : "",
                    boxShadow:
                      SellingReportLevelStatusEnum.Poor === status
                        ? `0px 0px 7px 0px ${SliderStatusMap[status].color} inset`
                        : "",
                    width:
                      SellingReportLevelStatusEnum.Poor === status
                        ? SliderStatusMap[status].box.activeBoxSize
                        : SliderStatusMap[status].box.defaultBoxSize,
                    height:
                      SellingReportLevelStatusEnum.Poor === status
                        ? SliderStatusMap[status].box.activeBoxSize
                        : SliderStatusMap[status].box.defaultBoxSize,
                  }}
                >
                  <div className="flex items-center justify-center self-center w-full h-full">
                    <div
                      style={{
                        width:
                          SellingReportLevelStatusEnum.Poor === status
                            ? SliderStatusMap[status].box.activeIconSize
                            : SliderStatusMap[status].box.defaultIconSize,
                        height:
                          SellingReportLevelStatusEnum.Poor === status
                            ? SliderStatusMap[status].box.activeIconSize
                            : SliderStatusMap[status].box.defaultIconSize,
                      }}
                    >
                      <DizzyFaceStatus />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-3 w-full self-center flex justify-end relative">
            <div
              className="w-1 h-1 rounded-full"
              style={{
                backgroundColor:
                  SellingReportLevelStatusEnum.Medium === status
                    ? SliderStatusMap[status].activePointColor
                    : SliderStatusMap[status].noneActivePointColor,
              }}
            ></div>
            <div className="absolute right-0 bottom-6 translate-x-[45%]">
              <div
                className="bg-white rounded-full w-[25px] h-[25px] shadow-box-custom relative"
                style={{
                  width:
                    SellingReportLevelStatusEnum.Medium === status
                      ? SliderStatusMap[status].box.activeBoxSize
                      : SliderStatusMap[status].box.defaultBoxSize,
                  height:
                    SellingReportLevelStatusEnum.Medium === status
                      ? SliderStatusMap[status].box.activeBoxSize
                      : SliderStatusMap[status].box.defaultBoxSize,
                }}
              >
                <div className="triangle-custom"></div>
                {SellingReportLevelStatusEnum.Medium === status && (
                  <div
                    className="absolute capitalize top-[-100%] left-[-20%] font-semibold text-[1rem] w-[70px]"
                    style={{ color: SliderStatusMap[status].color }}
                  >
                    {t(
                      `sellingReport.summaryReportTable.status.${SliderStatusMap[status].key}` as any
                    )}
                  </div>
                )}
                <div
                  className="bg-white rounded-full"
                  style={{
                    filter:
                      SellingReportLevelStatusEnum.Medium === status
                        ? `drop-shadow(0px 0px 10px ${SliderStatusMap[status].color})`
                        : "",
                    boxShadow:
                      SellingReportLevelStatusEnum.Medium === status
                        ? `0px 0px 5px 0px ${SliderStatusMap[status].color} inset`
                        : "",
                    width:
                      SellingReportLevelStatusEnum.Medium === status
                        ? SliderStatusMap[status].box.activeBoxSize
                        : SliderStatusMap[status].box.defaultBoxSize,
                    height:
                      SellingReportLevelStatusEnum.Medium === status
                        ? SliderStatusMap[status].box.activeBoxSize
                        : SliderStatusMap[status].box.defaultBoxSize,
                  }}
                >
                  <div className="flex items-center justify-center self-center w-full h-full">
                    <div
                      style={{
                        width:
                          SellingReportLevelStatusEnum.Medium === status
                            ? SliderStatusMap[status].box.activeIconSize
                            : SliderStatusMap[status].box.defaultIconSize,
                        height:
                          SellingReportLevelStatusEnum.Medium === status
                            ? SliderStatusMap[status].box.activeIconSize
                            : SliderStatusMap[status].box.defaultIconSize,
                      }}
                    >
                      <NormalFaceStatus />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-3 w-full self-center flex justify-end relative">
            <div
              className="w-1 h-1 rounded-full"
              style={{
                backgroundColor:
                  SellingReportLevelStatusEnum.Good === status
                    ? SliderStatusMap[status].activePointColor
                    : SliderStatusMap[status].noneActivePointColor,
              }}
            ></div>
            <div className="absolute right-0 bottom-6 translate-x-[45%]">
              <div
                className="bg-white rounded-full w-[25px] h-[25px] shadow-box-custom relative"
                style={{
                  width:
                    SellingReportLevelStatusEnum.Good === status
                      ? SliderStatusMap[status].box.activeBoxSize
                      : SliderStatusMap[status].box.defaultBoxSize,
                  height:
                    SellingReportLevelStatusEnum.Good === status
                      ? SliderStatusMap[status].box.activeBoxSize
                      : SliderStatusMap[status].box.defaultBoxSize,
                }}
              >
                <div className="triangle-custom"></div>
                {SellingReportLevelStatusEnum.Good === status && (
                  <div
                    className="absolute capitalize top-[-100%] translate-x-[10%] font-semibold text-[1rem]"
                    style={{ color: SliderStatusMap[status].color }}
                  >
                    {t(
                      `sellingReport.summaryReportTable.status.${SliderStatusMap[status].key}` as any
                    )}
                  </div>
                )}
                <div
                  className="bg-white rounded-full"
                  style={{
                    filter:
                      SellingReportLevelStatusEnum.Good === status
                        ? `drop-shadow(0px 0px 10px ${SliderStatusMap[status].color})`
                        : "",
                    boxShadow:
                      SellingReportLevelStatusEnum.Good === status
                        ? `0px 0px 5px 0px ${SliderStatusMap[status].color} inset`
                        : "",
                    width:
                      SellingReportLevelStatusEnum.Good === status
                        ? SliderStatusMap[status].box.activeBoxSize
                        : SliderStatusMap[status].box.defaultBoxSize,
                    height:
                      SellingReportLevelStatusEnum.Good === status
                        ? SliderStatusMap[status].box.activeBoxSize
                        : SliderStatusMap[status].box.defaultBoxSize,
                  }}
                >
                  <div className="flex items-center justify-center self-center w-full h-full">
                    <div
                      style={{
                        width:
                          SellingReportLevelStatusEnum.Good === status
                            ? SliderStatusMap[status].box.activeIconSize
                            : SliderStatusMap[status].box.defaultIconSize,
                        height:
                          SellingReportLevelStatusEnum.Good === status
                            ? SliderStatusMap[status].box.activeIconSize
                            : SliderStatusMap[status].box.defaultIconSize,
                      }}
                    >
                      <SmileFaceStatus />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2"></div>
        </div>
      </div>
    </div>
  );
}

export default HousePerformanceStatusSlideV2;

import { Farm } from "../../types";
import { House } from "../../types/House.type";

export enum PigTypes {
  BREEDER = "breeder",
  FATTENER = "fattener",
  NURSERY = "nursery",
}

export interface FormDashboardFormStatus {
  success: boolean;
  message: string;
  pigAgeInWeeks: number;
  edible: number;
  avgFeedInatke: number;
  standardEdible: number;
  health: number;
  loss: number;
  lossThreshold: number;
}
export interface FarmDetail extends Farm {
  totalPigs: number;
  fattenerPigs: number;
  breederPigs: number;
  nurseryPigs: number;
  chartData?: any[];
  province?: any;
  district?: any;
}

export interface HouseDetail extends House {
  totalPigs?: number;
  chartData?: any[];
}

export type FarmStatusType = "GOOD" | "POOR" | "AVERAGE";
export type PigFaceType = "GOOD" | "POOR" | "AVERAGE";

export interface FormStatusDetail extends FormDashboardFormStatus {
  edibleStatus?: FarmStatusType;
  healthStatus?: FarmStatusType;
  losePercentStatus?: FarmStatusType;
  pigFaceEmotion?: PigFaceType;
  pigFaceEmotionText?: string;
  logsAdded?: boolean;
}

import { memo, useCallback, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { tw } from "../../../../../../utils/tw";
import {
  Dropdown,
  TDropdownValue,
} from "../../../../group-area/views/Dropdown";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import TextInputSearchCustom from "../../../../../components/Input/TextInputSearchCustom";
import Table from "./Table";
import CrossIcon from "../../../../../assets/svg/CrossIcon.svg";
import { IGetOverdueFarmerInvoice } from "../../../../../../types/invoice/invoiceSale.type";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createInvoiceAssignment } from "../../../../../../services/admin/invoice/invoiceSale.service";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../redux/store";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import { alertAction } from "../../../../../../redux/slices/alertSlice";

interface ICreateAssignmentModalProps {
  isOpen: boolean;
  onCloseFn: () => void;
  overdueFarmer: TDropdownValue[];
  setOverdueFarmer: React.Dispatch<React.SetStateAction<TDropdownValue[]>>;
  OverdueFarmerOptions: TDropdownValue[];
  listPartner: TDropdownValue[];
  setListPartner: React.Dispatch<React.SetStateAction<TDropdownValue[]>>;
  PartnerOptions: TDropdownValue[];
  OverdueFarmerInvoice: IGetOverdueFarmerInvoice["rows"];
  searchText: string;
  onSearchText: (e: any) => void;
  onClearSearchText: (e: any) => void;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  container: tw(
    `w-[100vw] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary [&_.MuiDialog-paperScrollPaper]:!m-0 max-w-[950px]`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold`),
  row: tw(
    `w-full flex flex-col md:flex-row item-center justify-between gap-x-3`
  ),
  space: tw(`h-[12px]`),
  actionContainer: tw(`flex w-full justify-end items-end mt-6`),
};

const CreateAssignmentModal = (props: ICreateAssignmentModalProps) => {
  const {
    isOpen,
    onCloseFn,
    overdueFarmer,
    setOverdueFarmer,
    OverdueFarmerOptions,
    PartnerOptions,
    listPartner,
    setListPartner,
    OverdueFarmerInvoice,
    searchText,
    onSearchText,
    onClearSearchText,
    currentPage,
    setCurrentPage,
  } = props;

  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();

  const [ids, setIds] = useState<string[]>([]);

  const createInvoiceAssignmentMutation = useMutation({
    mutationFn: createInvoiceAssignment,
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ["overdue_list_farmer"] });
      await queryClient.refetchQueries({
        queryKey: ["overdue_list_farmer_invoice"],
      });
      setTimeout(() => {
        dispatch(loadingActions.hide());
        setTimeout(() => {
          onClose();
          dispatch(
            snakeActions.showMessage({
              message: "สร้างใบงานสำเร็จ",
              type: "success",
            })
          );
        }, 500);
      }, 1500);
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const onCreate = useCallback(() => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการสร้างใบงาน",
        text: "คุณต้องการสร้างใบงานนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          dispatch(alertAction.hide());
          dispatch(loadingActions.show({ message: "กำลังสร้างใบงาน..." }));
          createInvoiceAssignmentMutation.mutate({
            farmerId: overdueFarmer[0].value || "",
            partnerId: listPartner[0].value || "",
            invoiceIds: ids,
          });
        },
      })
    );
  }, [overdueFarmer, listPartner, ids]);

  const onClose = useCallback(() => {
    onCloseFn();
    setOverdueFarmer([]);
    setListPartner([]);
  }, []);

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: "w-full" }}
    >
      <DialogContent>
        <div className={classes.titleContainer}>
          <p className={classes.title}>สร้างใบงาน</p>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </div>
        <div className={tw(classes.row, "pt-6")}>
          <div className="text-[#3777BC] font-bold text-[18px]">
            เลือกลูกค้าและ Invoice
          </div>
        </div>
        <div className={tw(classes.row, "pt-6")}>
          <Dropdown
            label="ลูกค้า"
            placeholder="เลือก"
            containerClassName="max-w-[400px]"
            options={OverdueFarmerOptions}
            onChange={setOverdueFarmer}
            renderValueArray={overdueFarmer}
          />
        </div>
        <div className={classes.space} />
        <div className={classes.row}>
          <TextInputSearchCustom
            value={""}
            className="max-w-[400px]"
            onChange={() => {}}
            placeholder="ค้นหาโดยเลข Invoice"
            InputProps={{
              endAdornment: searchText ? (
                <CloseRoundedIcon
                  sx={{ cursor: "pointer" }}
                  onClick={onClearSearchText}
                />
              ) : null,
            }}
            disabled
          />
        </div>
        {/* BASE TABLE SHOULD FOLLOW */}
        <Table
          data={overdueFarmer.length === 0 ? [] : OverdueFarmerInvoice}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalTableSize={
            overdueFarmer.length === 0 ? 0 : OverdueFarmerInvoice.length
          }
          ids={ids}
          setIds={setIds}
        />
        <div className={tw(classes.row, "pt-6")}>
          <div className="text-[#3777BC] font-bold text-[18px]">
            เลือกพาร์ทเนอร์
          </div>
        </div>
        <div className={tw(classes.row, "pt-6")}>
          <Dropdown
            label="พาร์ทเนอร์"
            placeholder="เลือก"
            containerClassName="max-w-[400px]"
            options={PartnerOptions}
            onChange={setListPartner}
            renderValueArray={listPartner}
          />
        </div>
        <div className={classes.actionContainer}>
          <ButtonFillCustom title="ยืนยัน" onClick={onCreate} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(CreateAssignmentModal);

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Dialog,
  DialogContent,
  IconButton,
  MenuItem,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useEffect, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import RadioButton from "../../../../components/common/RadioButton";
import { DATE_FORMAT_DATE_PICKER2 } from "../../../../const/app.constant";
import { alertAction } from "../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import { putWastedDeptInvoice } from "../../../../services/admin/invoice/invoiceSale.service";
import { thousandComma } from "../../../../utils/common/string";
import getErrorMessage from "../../../../utils/getErrorMessage";
import { tw } from "../../../../utils/tw";
import { CalendarSvg } from "../../../assets/svg/Calendar.svg";
import CrossIcon from "../../../assets/svg/CrossIcon.svg";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import ChipOrderStatus from "../../../components/Chip/ChipOrderStatus";
import DatePickerPopover from "../../../components/Input/DatePickerPopover/DatePickerPopover";
import RHFTextInputCustom from "../../../components/Input/RHFTextInputCustom";
import RHFSelectCustom from "../../../components/Select/RHFSelectCustom";
import { TableCellHeaderCustom } from "../../master-data/pages/credit/Modal/TableCellCustom";
import { IInvoiceTable, TInvoiceOverdueStatus } from "../type";

const classes = {
  flexCenter: tw(`flex justify-between items-center`),
  flex: tw(`flex`),
  flexCol: tw(`flex flex-col`),
  flexRow: tw(`flex flex-row`),
  container: tw(
    `w-[100vw] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary [&_.MuiDialog-paperScrollPaper]:!m-0 max-w-[1780px]`
  ),
  header: tw(`text-sm mb-1 mt-4`),
  headerModal: tw(`text-lg font-bold`),
  label: tw(`font-normal text-[14px] text-[#646464]`),
  title: tw(`text-sm pb-3 mb-1`),
  marginTop: tw(`mt-4`),
};

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  selectedInvoice?: IInvoiceTable[];
};

const OrderStatusMap: Record<
  TInvoiceOverdueStatus,
  { color: string; bgColor: string; label: string }
> = {
  not_follow: {
    color: "#F57C00",
    bgColor: "#FDECD9",
    label: "ยังไม่ได้ติดตาม",
  },
  followed: {
    color: "#E9FFEF",
    bgColor: "#409261",
    label: "กำลังติดตาม",
  },
  followed_three_days: {
    color: "#409261",
    bgColor: "#E9FFEF",
    label: "กำลังติดตาม 3 วัน",
  },
  not_followed_seven_days: {
    color: "#F57C00",
    bgColor: "#FDECD9",
    label: "ยังไม่ได้ติดตาม 7-30 วัน",
  },
  followed_seven_days: {
    color: "#3777BC",
    bgColor: "#D9EBFE",
    label: "กำลังติดตาม 7-30 วัน",
  },
  not_followed_thirty_one_days: {
    color: "#F57C00",
    bgColor: "#FDECD9",
    label: "ยังไม่ได้ติดตาม 31-60 วัน",
  },
  followed_thirty_one_days: {
    color: "#5572EB",
    bgColor: "#DDE4FF",
    label: "กำลังติดตาม 31-60 วัน",
  },
  not_followed_sixty_days: {
    color: "#F57C00",
    bgColor: "#FDECD9",
    label: "ยังไม่ได้ติดตาม 60 วัน",
  },
  followed_sixty_days: {
    color: "#D32F2F",
    bgColor: "#F3DADA",
    label: "กำลังติดตาม 60 วัน",
  },
  waiting_for_approval: {
    color: "#F57C00",
    bgColor: "#FDECD9",
    label: "รออนุมัติ",
  },
};

export const tableCellHeader = [
  {
    value: "เลข Invoice",
    width: "200px",
  },
  {
    value: "รหัสลูกค้า",
    width: "180px",
  },
  {
    value: "ชื่อลูกค้า",
    width: "300px",
  },
  {
    value: "ยอดเงินที่ต้องชำระ",
    width: "370px",
  },
  {
    value: "ยอดเงินที่ชำระเเล้ว",
    width: "400px",
  },
  {
    value: "สถานะ",
    width: "150px",
  },
  {
    value: "วันครบกำหนดชำระ",
    width: "400px",
  },
  {
    value: "ยอดเงินที่ต้องชำระ/เดือน",
    width: "400px",
  },
];

export type TWastedDeptForm = {
  invoiceIds?: string[];
  debtStatus: TDebtStatus | "";
  debtPaymentOption?: TDeptPaymentOption | "";
  debtPaymentDate?: string;
  debtNumberOfMonth?: number | string;
  debtPerMonth?: number;
};

export type TWastedDeptSubmitForm = {
  invoiceIds?: string[];
  debtStatus: TDebtStatus | "";
  debtPaymentOption?: TDeptPaymentOption | "";
  debtPaymentDate?: Date;
  debtNumberOfMonth?: number | string;
  debtPerMonth?: number;
};

type TDeptPaymentOption = "full_amount" | "pay_by_installments";

type TDebtStatus = "accept_debt" | "not_accept_debt";

const WastedDeptModal = ({ isOpen, handleClose, selectedInvoice }: Props) => {
  const dispatch = useDispatch();
  const methods = useForm<TWastedDeptForm>({
    defaultValues: {
      invoiceIds: [],
      debtStatus: "",
      debtPaymentOption: "",
      debtPaymentDate: "",
      debtNumberOfMonth: 0,
      debtPerMonth: 0,
    },
  });

  const watchDeptStatus = methods.watch("debtStatus");
  const watchPaymentOption = methods.watch("debtPaymentOption");
  const watchDeptStatusIsAccept = watchDeptStatus === "accept_debt";
  const watchDeptStatusIsNotAccept = watchDeptStatus === "not_accept_debt";
  const watchPaymentIsFullAmount = watchPaymentOption === "full_amount";
  const watchPaymentIsInstallments =
    watchPaymentOption === "pay_by_installments";

  const ItemSelectNumberOfMonth = Array.from(
    { length: 28 },
    (_, index) => index + 1
  );

  const refCalendarDate =
    useRef<React.ElementRef<typeof DatePickerPopover>>(null);

  const handleCalenderSave = (value: Date | undefined) => {
    if (value) {
      methods.setValue(
        "debtPaymentDate",
        dayjs(value).format(DATE_FORMAT_DATE_PICKER2)
      );
    }
  };

  const handleCalendarClear = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    methods.setValue("debtPaymentDate", "");
  };

  const calDeptPerMonth = useCallback(
    (amountToBePaid: number, month: number): string => {
      if (month === 0 || month.toString() === "") {
        return "0 บาท";
      } else {
        return thousandComma(amountToBePaid / month, 2) + " บาท";
      }
    },
    [methods.watch("debtPerMonth")]
  );

  const submitAPI = async (value: TWastedDeptForm) => {
    try {
      const invoiceIds = selectedInvoice?.map((item) => item.id);

      const payload = {
        invoiceIds: invoiceIds,
        debtStatus: value.debtStatus,
        debtPaymentOption: value.debtPaymentOption,
        debtPaymentDate: dayjs(
          value.debtPaymentDate,
          DATE_FORMAT_DATE_PICKER2
        ).toDate(),
        debtNumberOfMonth: value.debtPerMonth,
        debtDayToPay: value.debtNumberOfMonth
          ? +value.debtNumberOfMonth
          : undefined,
      };
      await putWastedDeptInvoice(payload);
    } catch (error) {}
  };

  const handleSubmit = (value: TWastedDeptForm) => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
            await submitAPI(value);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "บันทึกสำเร็จ",
              })
            );
            handleClose();
          } catch (error) {
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  useEffect(() => {
    if (methods.watch("debtStatus") === "not_accept_debt") {
      methods.setValue("debtPaymentOption", "");
    }
  }, [methods.watch("debtStatus") === "not_accept_debt"]);

  useEffect(() => {
    methods.reset();
  }, [handleClose]);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth="xl"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={slotProps}
        className={classes.container}
        classes={{ paper: "w-full" }}
      >
        <DialogContent>
          <div className={classes.flexCenter}>
            <p className={classes.headerModal}>
              ติดตาม Invoice ที่เลยกำหนดชำระ
            </p>
            <IconButton onClick={() => handleClose()}>
              <CrossIcon />
            </IconButton>
          </div>
          <TableCell colSpan={11} sx={{ padding: 0 }}>
            <div className="w-full h-full">
              <TableContainer
                sx={{
                  scrollBehavior: "smooth",
                  overflow: "auto",
                  borderRadius: "6px",
                  boxShadow: "none",
                  border: "1px solid #E1E1E1",
                  borderBottom: "none",
                  width: "100%",
                  marginTop: "12px",
                }}
              >
                <Table aria-labelledby="table-show-data" size={"medium"}>
                  <TableHead
                    sx={{
                      bgcolor: "#FBFBFB",
                      borderBottom: "1px solid #E1E1E1",
                    }}
                  >
                    <TableRow>
                      {tableCellHeader.map((cell, index) => (
                        <TableCellHeaderCustom
                          key={index}
                          value={cell.value}
                          width={cell.width}
                        />
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedInvoice?.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.invoiceNumber}
                        </TableCell>
                        <TableCell align="left">{row.customerId}</TableCell>
                        <TableCell align="left">{row.customerName}</TableCell>
                        <TableCell align="left">
                          {row.amountToBePaid &&
                            thousandComma(+row.amountToBePaid, 2) + " บาท"}
                        </TableCell>
                        <TableCell align="left">
                          {row.paidAmount &&
                            thousandComma(+row.paidAmount, 2) + " บาท"}
                        </TableCell>
                        <TableCell align="left">
                          {row.status ? (
                            <div className="flex flex-row w-full">
                              <ChipOrderStatus
                                bgColor={
                                  OrderStatusMap[
                                    row.status as TInvoiceOverdueStatus
                                  ].bgColor
                                }
                                fontColor={
                                  OrderStatusMap[
                                    row.status as TInvoiceOverdueStatus
                                  ].color
                                }
                                label={
                                  OrderStatusMap[
                                    row.status as TInvoiceOverdueStatus
                                  ].label
                                }
                              />
                            </div>
                          ) : null}
                        </TableCell>
                        <TableCell align="left">{row.paymentDueDate}</TableCell>
                        <TableCell align="left">
                          {row.amountToBePaid
                            ? calDeptPerMonth(
                                +row.amountToBePaid,
                                methods.watch("debtPerMonth") || 0
                              )
                            : "-"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </TableCell>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
              <div className={classes.marginTop}>
                <p className={classes.header}>รับสภาพหนี้/ไม่รับสภาพหนี้</p>
                <div className={tw(classes.flexCol)}>
                  <div className={tw(classes.flexRow, "items-center w-full")}>
                    <div className={tw(classes.flex, "items-center w-[10%]")}>
                      <RadioButton
                        checked={watchDeptStatusIsAccept}
                        onClick={() => {
                          methods.setValue("debtStatus", "accept_debt");
                        }}
                      />
                      <p className={classes.label}>รับสภาพหนี้</p>
                    </div>
                    <div className={tw(classes.flex, "items-center w-[10%]")}>
                      <RadioButton
                        checked={watchDeptStatusIsNotAccept}
                        onClick={() => {
                          methods.setValue("debtStatus", "not_accept_debt");
                        }}
                      />
                      <p className={classes.label}>ไม่รับสภาพหนี้</p>
                    </div>
                  </div>
                </div>
                <p className={classes.header}>รูปแบบการจ่าย</p>
                <div className={classes.flexCol}>
                  <div className={tw(classes.flexRow, "items-center")}>
                    <div className={tw(classes.flex, "items-center w-[10%]")}>
                      <RadioButton
                        disable={watchDeptStatusIsNotAccept}
                        checked={watchPaymentIsFullAmount}
                        onClick={() => {
                          methods.setValue("debtPaymentOption", "full_amount");
                        }}
                      />
                      <p className={classes.label}>เต็มจำนวน</p>
                    </div>
                    <div className={tw(classes.flex, "items-center w-[10%]")}>
                      <RadioButton
                        disable={watchDeptStatusIsNotAccept}
                        checked={watchPaymentIsInstallments}
                        onClick={() => {
                          methods.setValue(
                            "debtPaymentOption",
                            "pay_by_installments"
                          );
                        }}
                      />
                      <p className={classes.label}>ผ่อน</p>
                    </div>
                  </div>
                </div>
                <div className={tw(classes.flex, "items-center gap-2")}>
                  <div className={tw(classes.flexCol, "w-[33%]")}>
                    <span className={classes.header}>วันที่ชำระ</span>
                    <RHFTextInputCustom
                      disabled={
                        watchPaymentIsInstallments || watchDeptStatusIsNotAccept
                      }
                      controlName="debtPaymentDate"
                      placeholder="เลือก"
                      InputProps={{
                        endAdornment:
                          methods.watch("debtPaymentDate") !== "" ? (
                            <CloseRoundedIcon
                              sx={{
                                cursor: "pointer",
                                fontSize: "18px !important",
                              }}
                              onClick={handleCalendarClear}
                            />
                          ) : (
                            <SvgIcon
                              sx={{
                                fontSize: "24px !important",
                                color: "#646464",
                              }}
                              fontSize="small"
                            >
                              <CalendarSvg />
                            </SvgIcon>
                          ),
                      }}
                      onClick={(
                        e: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => {
                        e.stopPropagation();
                        refCalendarDate.current?.onOpen(e);
                      }}
                    />
                  </div>
                  <div className={tw(classes.flexCol, "w-[33%]")}>
                    <span className={classes.header}>นัดชำระทุกวันที่</span>
                    <RHFSelectCustom
                      disabled={watchPaymentIsFullAmount}
                      controlName="debtNumberOfMonth"
                      renderValueArray={ItemSelectNumberOfMonth.map((row) => ({
                        value: String(row),
                        label: String(row),
                      }))}
                      value={methods.watch("debtNumberOfMonth")}
                      menuItems={ItemSelectNumberOfMonth.map((item) => (
                        <MenuItem key={item} value={String(item)}>
                          {item}
                        </MenuItem>
                      ))}
                    />
                  </div>
                  <div className={tw(classes.flexCol, "w-[33%]")}>
                    <span className={classes.header}>จำนวนเดือนที่ผ่อน</span>
                    <RHFTextInputCustom
                      disabled={
                        watchDeptStatusIsNotAccept || watchPaymentIsFullAmount
                      }
                      placeholder="กรอก"
                      controlName="debtPerMonth"
                    />
                  </div>
                </div>
                <div className={tw(classes.flex, "justify-end mt-4")}>
                  <ButtonFillCustom title={"บันทึก"} type="submit" />
                </div>
              </div>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
      <DatePickerPopover
        ref={refCalendarDate}
        id={"date-picker"}
        date={methods.watch("debtPaymentDate") || ""}
        handleCalenderSave={handleCalenderSave}
      />
    </>
  );
};

export default WastedDeptModal;

import { TableCell, TableRow, styled, tableCellClasses } from "@mui/material";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, memo, useMemo, useState } from "react";
import { TFarmerGetListBillItem } from "../../../../../types/Invoice.type";
import { getCommaNumber } from "../../../../../utils/number";
import { tw } from "../../../../../utils/tw";
import TableCustomMobile from "../../unpaid-bill/views/TableCustomMobile";

const classes = {
  container: tw(`mt-6 min-h-[350px] px-[16px]`),
  emptyTable: tw(
    `font-sans text-center w-full text-[#979797] text-[14px] py-3`
  ),
};

const headerItems = [
  {
    id: "invoiceNo",
    numeric: false,
    disablePadding: false,
    label: "เลขที่ Invoice",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
  {
    id: "paymentDueDate",
    numeric: false,
    disablePadding: false,
    label: "วันครบกําหนดชําระ",
    width: "auto",
    isSorting: true,
    className: tw(`whitespace-pre`),
  },
  {
    id: "totalPay",
    numeric: false,
    disablePadding: false,
    label: "ยอดที่ต้องชำระ",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
  {
    id: "totalPending",
    numeric: false,
    disablePadding: false,
    label: "ยอดเงินที่รอตวจสอบ",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
  {
    id: "totalSuccess",
    numeric: false,
    disablePadding: false,
    label: "ยอดที่ชำระแล้ว",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
  {
    id: "paymentDate",
    numeric: false,
    disablePadding: false,
    label: "วันที่ชำระ",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
];

interface ITable {
  swapCellColor?: boolean;
  data: TFarmerGetListBillItem[];
  orderByType: "desc" | "asc";
  setOrderByType: Dispatch<SetStateAction<"desc" | "asc">>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  count: number;
}

const Table = (props: ITable) => {
  const {
    swapCellColor,
    data,
    orderByType,
    setOrderByType,
    currentPage,
    setCurrentPage,
    count,
  } = props;
  // const [currentPage, setCurrentPage] = useState(1);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#D9EBFE",
      color: "#4B4B4B",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: 400,
      textAlign: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: `${swapCellColor ? "#ffffff" : "#f6faff"}`,
    },
    "&:nth-of-type(even)": {
      backgroundColor: `${swapCellColor ? "#f6faff" : "#ffffff"}`,
    },
  }));

  const rowChildren = useMemo(() => {
    return data.map((row, index) => {
      return (
        <StyledTableRow key={row.id + index}>
          <StyledTableCell
            padding="checkbox"
            sx={{
              border: "0px !important",
              paddingX: "4px",
              whiteSpace: "pre",
            }}
          >
            {row.invoiceNumber}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
            }}
          >
            {dayjs(row.dueDate).format("DD/MM/YYYY")}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
            }}
          >
            {getCommaNumber(row.totalPrice)}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
            }}
          >
            {getCommaNumber(row.totalPending)}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
            }}
          >
            {getCommaNumber(row.totalPaid)}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
              whiteSpace: "pre",
            }}
          >
            {dayjs(row.paidAt).format("DD/MM/YYYY HH:mm")}
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  }, [data, currentPage]);

  return (
    <div className={classes.container}>
      <TableCustomMobile
        orderBy={"paymentDueDate"}
        order={orderByType}
        onChangeOrder={(order) => setOrderByType(order)}
        onChangeOrderBy={() => {}}
        totalItemSize={count}
        headerItems={headerItems}
        page={currentPage}
        onChangePage={setCurrentPage}
        rowsPerPage={10}
        rows={data}
        EmptyComponent={
          data.length === 0 ? (
            <div className={classes.emptyTable}>ไม่มีข้อมูล</div>
          ) : null
        }
        rowChildren={rowChildren}
        currentPageItems={data.map(({ id }) => id)}
        itemsSelected={[]}
        onSelectAllClick={() => {}}
        onSelectItem={() => {}}
        hideSelect
      />
    </div>
  );
};

export default memo(Table);

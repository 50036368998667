import { useCallback, useState } from "react";
import React from "react";

export const useOrderClaimDetailController = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const onChangeTab = useCallback((e: any, index: number) => {
    setTabIndex(index);
  }, []);

  return {
    tabIndex,
    setTabIndex,
    onChangeTab,
  };
};

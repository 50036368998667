import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip, Link, Paper } from "@mui/material";
import axios from "axios";
import get from "lodash/get";
import { useCallback, useEffect, useState } from "react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import AppBarFS from "../../../components/app-bar/AppBar";
import CustomButton from "../../../components/common/Button";
import ViewImageAndPreviewCarousel from "../../../components/common/ViewImageAndPreviewCarousel/ViewImageAndPreviewCarousel";
import { getDownloadUrlOrderFarmer } from "../../../services/MyOrder.service";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { tw } from "../../../utils/tw";
import ZoomImageSvg from "../../../assets/svg/ZoomImage";
import PdfRenderer from "../../../admin/components/Modal/DocumentModal/ModalPanel/PdfRenderer";
import PreviewAndZoomImageCarouselModal from "../../../components/common/ViewImageAndPreviewCarousel/PreviewAndZoomImageCarouselModal";
import { handleDownloadBlob } from "../../../utils/downloadFile";

const classes = {
  wrapper: tw(`overflow-scroll`),
  container: tw(`min-h-screen w-full flex flex-col bg-[white] pb-[24px]`),
  appbarContainer: tw(`py-4`),
  headerContainer: tw(
    `mb-[16px] px-[16.5px] text-[18px] leading-[21.6px] font-semibold flex flex-row items-center justify-between`
  ),
  label: tw(
    `border-[1px] border-warning rounded-full font-normal flex justify-center items-center text-[14px] leading-[19.6px] text-warning bg-[#FEF9F2] py-[2px] px-[8px]`
  ),
  swiperWrapper: tw(`[&_.swiper-wrapper]:items-center`),
  swiperContainer: tw(`px-[45px] relative pb-[24px]`),
  swiperImage: tw(`w-full h-auto object-contain max-w-[450px] m-auto`),
  danger: tw(
    `text-status-bad font-semibold text-[14px] leading-[21px] whitespace-pre-line text-center`
  ),
  actionContainer: tw(
    `flex flex-col gap-[16px] mt-[16px] max-w-[450px] px-[16px] w-full mx-auto`
  ),
  button: tw(
    `bg-secondary border-[1px] border-secondary flex justify-center items-center text-center text-[white] text-[16px] leading-[25.6px] font-bold w-full py-[16.5px] rounded-full hover:brightness-105 duration-150 transition-all`
  ),
};

function OrderViewDocument() {
  const navigate = useNavigate();
  const [images, setImages] = useState<string[]>([]);
  const { farmId, orderId, myOrderList } = useLoaderData() as any;
  const { farmId: _farmId } = useParams();

  const [docs, setDocs] = useState<{ url: string; fileName: string }[]>([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const [slideIndex, setSlideIndex] = useState<number>(0);

  const createDocs = async () => {
    if (myOrderList && myOrderList?.result?.orderFeedFiles) {
      const docsData = [];
      const orderNo = get(myOrderList, ["result", "orderNumber"], "");
      for (let i = 0; i < myOrderList?.result?.orderFeedFiles?.length; i++) {
        const img = myOrderList?.result?.orderFeedFiles[i];
        if (img) {
          let urlId: any = img.url.split("/") as string[];
          urlId = urlId[urlId.length - 1];
          const preSignURL = await getDownloadUrlOrderFarmer(
            orderNo,
            img.documentType,
            urlId
          );
          if (preSignURL) {
            const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
              responseType: "blob",
            });
            const objectURL = window.URL.createObjectURL(response.data);
            docsData.push({
              url: objectURL,
              fileName: img.fileName,
            });
          }
        }
      }
      setDocs(docsData);
    }
  };

  const handleDownloadByUrl = useCallback(
    (slideIndex: number) => {
      handleDownloadBlob(docs[slideIndex].url, docs[slideIndex].fileName);
    },
    [docs]
  );

  useEffect(() => {
    createDocs();
  }, [myOrderList]);

  return (
    <div className="h-screen overflow-auto flex flex-col w-full bg-[#FFFFF]">
      <div className="rounded-b-[40px] bg-white">
        <AppBarFS onIconPress={() => navigate(-1)} title={"เอกสารการซื้อขาย"} />
      </div>
      <div className="flex flex-col p-4 w-full h-full">
        <div className="flex flex-row w-full justify-between pt-4 align-middle items-center">
          <span className="font-semibold text-[18px]">
            หมายเลขสั่งซื้อ {get(myOrderList, ["result", "orderNumber"])}
          </span>
          <Chip
            label="รับสินค้าสำเร็จ"
            variant="outlined"
            sx={{
              color: "#2C702F",
              borderColor: "#2C702F",
              bgcolor: "#F5F9F5",
              height: "24px",
              width: "fit-content",
              fontWeight: "normal",
              fontSize: "14px",
            }}
          />
        </div>
        <div className="h-full w-full pb-4 mt-6 pt-4">
          <Swiper
            pagination
            modules={[Pagination]}
            loop
            className={classes.swiperWrapper}
            initialSlide={slideIndex}
            onActiveIndexChange={(event) => {
              setSlideIndex(event.realIndex);
            }}
          >
            {docs.length > 0 ? (
              docs.map((doc, index) => {
                const isPdf = doc.fileName.indexOf(".pdf") !== -1;
                return (
                  <SwiperSlide
                    key={`${index}`}
                    className={classes.swiperContainer}
                    onClick={() => setIsOpenModal(true)}
                  >
                    {!isPdf ? (
                      <>
                        <div className="absolute top-[45%] bottom-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2">
                          <div className="w-8 h-8">
                            <ZoomImageSvg />
                          </div>
                        </div>
                        <img src={doc.url} className={classes.swiperImage} />
                      </>
                    ) : (
                      <PdfRenderer
                        url={doc.url}
                        className={tw(
                          classes.swiperImage,
                          "min-h-[420px] border-[1px] border-[#555]"
                        )}
                      />
                    )}
                  </SwiperSlide>
                );
              })
            ) : (
              <div className="text-center font-normal text-[14px]">
                ยังไม่มีเอกสารการซื้อขาย
              </div>
            )}
          </Swiper>
        </div>
        <div className="w-full h-full">
          <Paper
            elevation={0}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              bgcolor: "#F5F9F5",
              borderRadius: "10px",
              height: "70px",
              padding: "1rem",
              alignItems: "center",
            }}
          >
            <div className="flex gap-3 h-full items-center self-center content-center">
              <FontAwesomeIcon
                style={{
                  background: "transparent",
                }}
                className="bg-white rounded-full text-[22px]"
                color="#68C184"
                size="lg"
                icon={faCircleCheck}
              />
              <span className="text-[1rem] font-semibold leading-5">
                เราได้เพิ่มสินค้าในสต๊อก <br /> ของคุณเรียบร้อยแล้ว
              </span>
            </div>
            <Link
              component="button"
              variant="body2"
              onClick={() => navigate(`/farm/${_farmId}/manage-stock`)}
              sx={{
                color: "#646464",
              }}
            >
              ดูสต๊อก
            </Link>
          </Paper>
        </div>
        <span className="text-[14px] font-semibold leading-5 text-[#D32F2F] text-center">
          หากไม่ได้กดบันทึกเอกสารการขาย <br />
          คุณสามารถกลับมาดูย้อนหลังได้ที่เมนูประวัติคำสั่งซื้อ
        </span>
        <div className="flex flex-col gap-4 my-4 pb-4">
          <CustomButton
            variant="tertiary"
            disabled={false}
            removeBoxShadow={true}
            label={"บันทึกรูปภาพ"}
            onClick={() => handleDownloadByUrl(slideIndex)}
          />
          <CustomButton
            variant="secondary"
            disabled={false}
            label={"ปิด"}
            onClick={() => {
              navigate("/order-history/" + farmId);
            }}
          />
        </div>
      </div>
      <PreviewAndZoomImageCarouselModal
        isOpen={isOpenModal}
        initIndex={slideIndex}
        onClose={() => {
          setIsOpenModal(false);
        }}
        docs={docs}
      />
    </div>
  );
}

export default OrderViewDocument;

import { FormikProps, useFormik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ValidationError, array, number, object, string } from "yup";
import CustomButton from "../../../components/common/Button";
import { FeedBrands } from "../../../types/FeedBrand.type";
import { IOrderFeedForm, IOrderList } from "../../../types/OrderFeed.type";
import { BaseModal } from "./OrderFeedBaseModal";
import OrderFeedSingleOrder from "./OrderFeedSingleOrder";

interface IOrderFeedEditModal {
  formik: FormikProps<IOrderFeedForm>;
  orderIndex: number;
  isOpen: boolean;
  handleClose: (isSuccess: boolean, values?: IOrderList) => void;
  feedBrands: FeedBrands[];
}

export function OrderFeedEditModal({
  formik,
  orderIndex,
  isOpen,
  handleClose,
  feedBrands,
}: IOrderFeedEditModal) {
  const handleFormSubmit = (value: IOrderFeedForm) => {
    handleClose(true, value.orders[0]);
  };
  const { t } = useTranslation();
  const formikEdit = useFormik<IOrderFeedForm>({
    initialValues: {
      houseId: formik.values.houseId,
      houseName: formik.values.houseName,
      contractName: formik.values.contractName,
      contractId: formik.values.contractId,
      deliveryType: formik.values.deliveryType,
      deliveryDate: formik.values.deliveryDate,
      remainingCredit: formik.values.remainingCredit,
      orders: [{ ...formik.values.orders[orderIndex] }],
    },
    onSubmit: handleFormSubmit,
    validationSchema: object().shape({
      orders: array()
        .of(
          object({
            brandId: string().required(
              `${t("orderFeed.input.helperText.brandId")}`
            ),
            brandSKU: string().required(
              `${t("orderFeed.input.helperText.brandSKU")}`
            ),
            amount: number()
              .positive(`${t("orderFeed.input.helperText.positiveAmount")}`)
              .typeError(`${t("orderFeed.input.helperText.amount")}`)
              .required(`${t("orderFeed.input.helperText.amount")}`)
              .when("unit", {
                is: "BAG",
                then: (schema) =>
                  schema.integer(
                    `${t("orderFeed.input.helperText.integerAmount")}`
                  ),
              }),
          })
        )
        .test(
          "duplicate-sku",
          `${t("orderFeed.input.helperText.duplicateSKU")}`,
          function (orders) {
            if (!orders) {
              return true;
            }
            const errors: ValidationError[] = [];
            orders.forEach((row) => {
              if (row.brandSKU) {
                const firstOccurrenceIndex = formik.values.orders.findIndex(
                  (first) => row.brandSKU === first.brandSKU
                );
                if (
                  firstOccurrenceIndex !== -1 &&
                  firstOccurrenceIndex !== orderIndex
                ) {
                  errors.push(
                    this.createError({
                      path: `orders[0].brandSKU`,
                      message: `${t(
                        "orderFeed.input.helperText.duplicateSKU"
                      )}`,
                    })
                  );
                }
              }
            });
            // if (errors.length !== 0) {
            //   throw new ValidationError(errors);
            // }
            return true;
          }
        ),
    }),
  });

  useEffect(() => {
    formikEdit.setFieldValue("orders", [
      { ...formik.values.orders[orderIndex] },
    ]);
    // eslint-disable-next-line
  }, [isOpen, orderIndex]);

  return (
    <BaseModal isOpen={isOpen} handleClose={handleClose} iconCloseModal={true}>
      <div className="flex flex-col gap-4">
        <p className="text-[20px] font-semibold text-tertiary text-center mb-2">
          {t("orderFeed.summary.modal.header.edit")}
        </p>
        <div className="flex flex-col gap-4">
          <OrderFeedSingleOrder
            orderIndex={0}
            formik={formikEdit}
            feedBrands={feedBrands}
          />
        </div>
        <CustomButton
          disabled={!formikEdit?.isValid}
          variant="tertiary"
          label={t("orderFeed.button.submit")}
          onClick={formikEdit.handleSubmit}
        />
      </div>
    </BaseModal>
  );
}

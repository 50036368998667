import DownloadIcon from "@mui/icons-material/Download";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import AppBarFS from "../../components/app-bar/AppBar";
import CustomButton from "../../components/common/Button";
import HousePerformanceTable from "../../components/common/Table/HousePerformanceTable";
import { getHousePerformnceReport } from "../../services/AuthService";

const HousePerformanceReport = () => {
  const { state } = useLocation();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [performanceData, setPerformanceData] = useState<any>([]);
  const language = localStorage.getItem("i18nextLng");

  const tableHeadData = [
    {
      nameEn: "Date",
      nameTh: "วันที่",
    },
    {
      nameEn: "pig age (day)",
      nameTh: "อายุสุกร (วัน)",
    },
    {
      nameEn: "feed intake (kg/day)",
      nameTh: "บริมาณการกินอาหาร (กก./วัน)",
    },
    {
      nameEn: "Feed brand",
      nameTh: "แบรนด์อาหาร",
    },
    {
      nameEn: "Feed sku",
      nameTh: "รุ่นอาหาร (SKU)",
    },
    {
      nameEn: "cumulative volume (kg/week)",
      nameTh: "ปริมาณสะสม (กก./สัปดาห์)",
    },
    {
      nameEn: "Number of dead pigs (pigs)",
      nameTh: "จำนวนสุกรตาย (ตัว)",
    },
    {
      nameEn: "number of pigs die accumulated (pigs)",
      nameTh: "จำนวนสุกรตายสะสม (ตัว)",
    },
    {
      nameEn: "vaccine name",
      nameTh: "ชื่อวัคซีน",
    },
    {
      nameEn: "number of pig that take treat",
      nameTh: "จำนวนตัวที่รักษา",
    },
    {
      nameEn: "medicine treatment",
      nameTh: "ยารักษาการรักษา",
    },
  ];

  const handleDownloadFile = () => {
    const data = performanceData.map((item: any, index: number) => {
      return {
        Date: dayjs(item?.logDate).format("DD-MM-YYYY"),
        "Pig Age (day)": item?.house?.pigAge,
        "Feed intake (kg/day)": item.feedIntake,
        "Feed Brand": item?.feed_brand?.nameTh || "",
        "Feed SKU": item?.feed_brand_item?.skuTh || "",
        "Cumulative Volume (kg/week)":
          index > 0
            ? performanceData[index - 1].feedIntake * 7 + item?.feedIntake * 7
            : item?.feedIntake * 7,
        "Number of Dead pigs (pigs)": item.deadPigCount,
        "Number of pigs die accumulated (pigs)":
          index > 0
            ? performanceData[index - 1].deadPigCount + item?.deadPigCount
            : item?.deadPigCount,
        "Vaccine Name":
          item?.vaccineName &&
          (language === "th"
            ? item?.vaccineName?.nameTh
            : item?.vaccineName.nameEn),
        "Vaccine Pig Count": item?.vaccinePigCount,
        medicineTreatment: item?.medicineNames.join(", "),
      };
    });
    try {
      var worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      worksheet["!cols"] = [
        { wch: "YYYY-MM-DD".length },
        { wch: "Pig Age (day)".length },
        { wch: "Feed intake (kg/day)".length },
        { wch: "Feed Brand".length + 20 },
        { wch: "Feed SKU".length + 20 },
        { wch: "Cumulative Volume (kg/week)".length },
        { wch: "Number of Dead pigs (pigs)".length },
        { wch: "Number of pigs die accumulated (pigs)".length },
        { wch: "Vaccine Name".length + 20 },
        { wch: "Vaccine Pig Count".length },
        { wch: "medicineTreatment".length + 30 },
      ];
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "DataSheet.xlsx");
    } catch (error) {
      console.error("Error while generating Excel file:", error);
    }
  };
  useEffect(() => {
    fetchPerformanceDetails();
  }, []);

  const fetchPerformanceDetails = async () => {
    const resp = await getHousePerformnceReport(state.houseId);
    if (resp.data.success) {
      setPerformanceData(resp.data.housePerformnce);
    }
  };
  return (
    <div
      className="h-full  flex flex-col  w-full  overflow-hidden"
      style={{ backgroundColor: performanceData.length > 0 ? "#DDF3E4" : "" }}
    >
      <div className="bg-[#fff] py-4 rounded-b-[40px]">
        <AppBarFS
          title={t("housePeformanceReport.appHeader")}
          onIconPress={() => navigate(-1)}
        ></AppBarFS>
      </div>
      {performanceData.length > 0 ? (
        <div className="p-4">
          <h3 className="text-[#34407B] text-[16px] font-semibold">
            {t("housePeformanceReport.housePerformanceTitle")}
          </h3>
          <div
            className="flex flex-col gap-4 mb-4"
            onClick={handleDownloadFile}
          >
            <DownloadIcon className="z-[1] translate-x-[105px] translate-y-[56px] text-[#fff]" />
            <CustomButton
              variant="tertiary"
              label={t("housePeformanceReport.downloadButtonText")}
            />
            <span className="text-center text-[12px] text-[#979797]">
              {t("housePeformanceReport.downloadButtonHelperText")}
            </span>
          </div>
          <div className="scrollbarCustom">
            <HousePerformanceTable
              tableData={performanceData}
              tableHeadData={tableHeadData}
            />
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center text-[#C9C9C9] h-[100%] flex-col">
          <SignalCellularAltIcon style={{ fontSize: "50px" }} />
          <span className="text-[14px] font-normal whitespace-break-spaces">
            {t("housePeformanceReport.noDataText")}{" "}
            <a
              className="text-[#3777BC]"
              onClick={() => navigate("/daily-log")}
            >
              {t("housePeformanceReport.dailyLogLabel")}
            </a>
          </span>
        </div>
      )}
    </div>
  );
};

export default HousePerformanceReport;

export function SortingDescSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10662_324703)">
        <path
          d="M5.625 11.25C5.625 11.0807 5.68298 10.9342 5.79895 10.8105C5.91492 10.6868 6.05225 10.625 6.21094 10.625H14.4141C14.5728 10.625 14.7101 10.6868 14.826 10.8105C14.942 10.9342 15 11.0807 15 11.25C15 11.4193 14.942 11.5658 14.826 11.6895L10.7245 16.0645C10.6085 16.1882 10.4712 16.25 10.3125 16.25C10.1538 16.25 10.0165 16.1882 9.90051 16.0645L5.79895 11.6895C5.68298 11.5658 5.625 11.4193 5.625 11.25Z"
          fill="#333333"
          fillOpacity="0.7"
        />
        <path
          d="M15 8.125C15 8.29427 14.942 8.44075 14.826 8.56445C14.7101 8.68815 14.5728 8.75 14.4141 8.75H6.21094C6.05225 8.75 5.91492 8.68815 5.79895 8.56445C5.68298 8.44075 5.625 8.29427 5.625 8.125C5.625 7.95573 5.68298 7.80924 5.79895 7.68555L9.90051 3.31055C10.0165 3.18685 10.1538 3.125 10.3125 3.125C10.4712 3.125 10.6085 3.18685 10.7245 3.31055L14.826 7.68555C14.942 7.80924 15 7.95573 15 8.125Z"
          fill="#DADADA"
        />
      </g>
      <defs>
        <clipPath id="clip0_10662_324703">
          <rect
            width="9.375"
            height="13.125"
            fill="white"
            transform="translate(5.625 3.125)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

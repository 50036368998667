export default function OrderHistorySVG() {
  return (
    <svg
      width="77"
      height="77"
      viewBox="0 0 77 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="77" height="77" rx="20" fill="#3777BC" />
      <mask
        id="mask0_9733_77367"
        maskUnits="userSpaceOnUse"
        x="16"
        y="16"
        width="45"
        height="45"
      >
        <rect x="16" y="16" width="45" height="45" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_9733_77367)">
        <path
          d="M38.5 55.375C34.1875 55.375 30.4297 53.9453 27.2266 51.0859C24.0234 48.2266 22.1875 44.6562 21.7188 40.375H25.5625C26 43.625 27.4453 46.3125 29.8984 48.4375C32.3516 50.5625 35.2188 51.625 38.5 51.625C42.1562 51.625 45.2578 50.3516 47.8047 47.8047C50.3516 45.2578 51.625 42.1562 51.625 38.5C51.625 34.8438 50.3516 31.7422 47.8047 29.1953C45.2578 26.6484 42.1562 25.375 38.5 25.375C36.3438 25.375 34.3281 25.875 32.4531 26.875C30.5781 27.875 29 29.25 27.7188 31H32.875V34.75H21.625V23.5H25.375V27.9063C26.9688 25.9062 28.9141 24.3594 31.2109 23.2656C33.5078 22.1719 35.9375 21.625 38.5 21.625C40.8438 21.625 43.0391 22.0703 45.0859 22.9609C47.1328 23.8516 48.9141 25.0547 50.4297 26.5703C51.9453 28.0859 53.1484 29.8672 54.0391 31.9141C54.9297 33.9609 55.375 36.1562 55.375 38.5C55.375 40.8438 54.9297 43.0391 54.0391 45.0859C53.1484 47.1328 51.9453 48.9141 50.4297 50.4297C48.9141 51.9453 47.1328 53.1484 45.0859 54.0391C43.0391 54.9297 40.8438 55.375 38.5 55.375ZM43.75 46.375L36.625 39.25V29.125H40.375V37.75L46.375 43.75L43.75 46.375Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

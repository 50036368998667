import React from "react";
import { IButtonBackgroundImageCardProps } from "./types";
import { Button } from "@mui/material";

function ButtonBackgroundImageCard({
  image,
  title,
  btnTitle,
  onClick,
}: IButtonBackgroundImageCardProps) {
  return (
    <div
      className="relative flex w-full h-[119px] max-h-[199px] rounded-[20px] bg-cover"
      style={{
        backgroundImage: `url(${image})`,
        backgroundRepeat: "no-repeat",
        backgroundOrigin: "content-box",
        backgroundPositionY: "47%",
        backgroundPositionX: "15%",
        backgroundSize: "cover",
      }}
      onClick={onClick}
    >
      <div className="w-full flex flex-col self-center justify-end items-end px-5 gap-1">
        <span className="text-sm text-[#FFFFFF] font-normal">{title}</span>
        <Button
          variant="outlined"
          sx={{
            color: "#FFFFFF",
            borderColor: "#FFFFFF",
            fontWeight: 600,
            ":active": {
              bgcolor: "#FFF",
              color: "#34407b",
              borderColor: "#FFFFFF",
            },
          }}
          onClick={onClick}
        >
          {btnTitle}
        </Button>
      </div>
    </div>
  );
}

export default ButtonBackgroundImageCard;

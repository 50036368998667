import { useNavigate } from "react-router-dom";
import OrderFeedSVG from "../../assets/svg/OrderFeed";
import OrderHistorySVG from "../../assets/svg/OrderHistory";
import AppBarFS from "../../components/app-bar/AppBar";
import DashboardInfoCard from "../../components/common/DashboardInfoCard/DashboardInfoCard";

export default function Stock() {
  const navigate = useNavigate();

  return (
    <div className="h-screen overflow-auto flex flex-col bg-[#ffff]">
      <div className="py-4 rounded-b-[40px] bg-white">
        <AppBarFS onIconPress={() => navigate("/partner")} title={"สต๊อก"} />
        <div className="mx-4 mt-4">
          <DashboardInfoCard
            title={"สต๊อกเข้าใหม่"}
            subTitle={"รายละเอียดสต๊อกเข้าใหม่"}
            icon={<OrderFeedSVG />}
            removeBgIcon={true}
            bgColor="#F0FDF4"
            onPress={() => navigate("/stock/upcoming-stock")}
          />
        </div>
        <div className="mx-4 mt-4">
          <DashboardInfoCard
            title={"ประวัติสต๊อกรับเข้า"}
            subTitle={"รายละเอียดประวัติสต๊อกรับเข้า"}
            icon={<OrderHistorySVG />}
            removeBgIcon={true}
            bgColor="#D9EBFEB2"
            onPress={() => navigate("/stock/stock-history")}
          />
        </div>
      </div>
    </div>
  );
}

import dayjs from "dayjs";
import { TDropdownValue } from "../../../admin/pages/group-area/views/Dropdown";

////// Feed //////

export type TFormFeedStock = {
  id?: string;
  date: any;
  foodBrand: TDropdownValue[];
  sku: TDropdownValue[];
  amount: string;
  unit: string;
  price: string;
};

export const defaultFormFeedStock: TFormFeedStock = {
  id: "",
  date: dayjs(),
  foodBrand: [],
  sku: [],
  amount: "",
  unit: "",
  price: "",
};

////// Vaccine //////

export type TFormVaccineStock = {
  date: any;
  vaccine: TDropdownValue[];
  amount: string;
  unit: string;
  price: string;
};

export const defaultFormVaccineStock: TFormVaccineStock = {
  date: dayjs(),
  vaccine: [],
  amount: "",
  unit: "",
  price: "",
};

////// Medicine //////

export type TFormMedicineStock = {
  date: any;
  medicineCategoryId: TDropdownValue[];
  medicineDetailId: TDropdownValue[];
  name: string;
  amount: string;
  unit: string;
  price: string;
};

export const defaultFormMedicineStock: TFormMedicineStock = {
  date: dayjs(),
  medicineCategoryId: [],
  medicineDetailId: [],
  name: "",
  amount: "",
  unit: "",
  price: "",
};

////// Other //////

export type TFormOtherStock = {
  date: any;
  stockName: string;
  amount: string;
  unit: string;
  price: string;
};

export const defaultFormOtherStock: TFormOtherStock = {
  date: dayjs(),
  stockName: "",
  amount: "",
  unit: "",
  price: "",
};

////// History //////

export type TFormHistory = {
  direction: TDropdownValue[];
  startDate: string;
  endDate: string;
};

export const defaultFormHistory: TFormHistory = {
  direction: [],
  startDate: "",
  endDate: "",
};

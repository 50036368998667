import { Button, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Y from "yup";
import { ref } from "yup";
import AppBarFS from "../../../../components/app-bar/AppBar";
import AuthBox from "../../../../components/common/AuthBox";
import useFormValidator, {
  IValidationContext,
} from "../../../../hooks/useFormValidator.hook";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../../redux/store";
import { resetPin } from "../../../../services/AccountCenter.service";
import getErrorMessage from "../../../../utils/getErrorMessage";
import NewPinSaveModal from "./views/NewPinSaveModal";

interface TForm {
  pin: string;
  confirmPin: string;
}

const defaultForm: TForm = {
  pin: "",
  confirmPin: "",
};

const errorSchema = Y.object({
  pin: Y.string()
    .trim()
    .min(4, "Minimum 4 digits")
    .max(4, "Maximum 4 digits")
    .matches(/^\d+$/, "Only digits allowed")
    .required("PIN is required")
    .test("Confirm PIN must not match the PIN from location", function (value) {
      const { location } = this.options.context as IValidationContext;
      if (location?.state && value === location.state) {
        return this.createError({
          message: "Cannot use the old pin as your new one",
        });
      }
      return true;
    }),
  confirmPin: Y.string()
    .trim()
    .min(4, "Minimum 4 digits")
    .max(4, "Maximum 4 digits")
    .required("Confirm PIN is required")
    .matches(/^\d+$/, "Only digits allowed")
    .oneOf([ref("pin")], "Not matching PIN please Retry")
    .test("Confirm PIN must not match the PIN from location", function (value) {
      const { location } = this.options.context as IValidationContext;
      if (location?.state && value === location.state) {
        return this.createError({
          message: "Cannot use the old pin as your new one",
        });
      }
      return true;
    }),
});

const ChangePin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form, setForm] = useState(defaultForm);
  const dispatch = useDispatch<AppDispatch>();

  const onChangeForm = useCallback(
    (key: keyof typeof form) => (value: any) => {
      setForm((prev) => ({ ...prev, [key]: value }));
    },
    []
  );

  const [isShowNewPinSave, setIsShowNewPinSave] = useState(false);

  const onShowNewPinSave = useCallback(() => {
    setIsShowNewPinSave(true);
  }, []);

  const resetPinMutation = useMutation({
    mutationFn: resetPin,
    onSuccess: async () => {
      setTimeout(() => {
        setTimeout(() => {
          onShowNewPinSave();
          dispatch(
            snakeActions.showMessage({
              message: "Successful Saved!",
              type: "success",
            })
          );
        }, 500);
      });
    },
    onError: async (error) => {
      setTimeout(() => {
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
      resetError();
    },
  });

  const { onSubmit, errors, resetError } = useFormValidator({
    form,
    errorSchema,
    onValidateSuccess: async () => {
      resetPinMutation.mutate({
        pin: location.state || "",
        newPin: form.confirmPin,
      });
    },
    validationContext: { location },
  });

  return (
    <div className="!h-screen !min-h-screen flex flex-col">
      <div className="h-[120px] flex items-center bg-[#FFFF] rounded-b-[40px]">
        <AppBarFS onIconPress={() => navigate(-1)} title={"ตั้ง PIN"} />
      </div>
      <div className="flex flex-col gap-8 px-4 h-[60%]">
        <div className="flex flex-col gap-2">
          <Typography variant="h6" align="left">
            Set your PIN
          </Typography>
          <AuthBox
            boxLength={4}
            inputType="password"
            placeholder="*"
            error={errors.pin}
            errorMessage={""}
            labelStyle={{ textAlign: "center" }}
            onChange={onChangeForm("pin")}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Typography variant="h6" align="left">
            Confirm your PIN
          </Typography>
          <AuthBox
            boxLength={4}
            inputType="password"
            placeholder="*"
            error={errors.confirmPin}
            errorMessage={""}
            labelStyle={{ textAlign: "center" }}
            onChange={onChangeForm("confirmPin")}
          />
        </div>
      </div>
      <div className="px-4 w-full flex flex-col gap-4 items-center justify-end pb-[24px] flex-1">
        <div className="text-[14px] text-[#D32F2F] font-normal">
          {errors.confirmPin}
        </div>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          color="secondary"
          size="large"
          onClick={onSubmit}
        >
          Confirm
        </Button>
      </div>
      <NewPinSaveModal isOpen={isShowNewPinSave} />
    </div>
  );
};

export default ChangePin;

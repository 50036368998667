export default function UserGroupSvg() {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3 6.72645C14.3 8.58388 12.8225 10.0896 11 10.0896C9.17743 10.0896 7.69997 8.58388 7.69997 6.72645C7.69997 4.86902 9.17743 3.36328 11 3.36328C12.8225 3.36328 14.3 4.86902 14.3 6.72645Z"
        fill="white"
      />
      <path
        d="M19.8 8.96856C19.8 10.2068 18.815 11.2107 17.6 11.2107C16.3849 11.2107 15.4 10.2068 15.4 8.96856C15.4 7.73028 16.3849 6.72645 17.6 6.72645C18.815 6.72645 19.8 7.73028 19.8 8.96856Z"
        fill="white"
      />
      <path
        d="M15.4 16.816C15.4 14.3394 13.43 12.3317 11 12.3317C8.56992 12.3317 6.59997 14.3394 6.59997 16.816V20.1791H15.4V16.816Z"
        fill="white"
      />
      <path
        d="M6.59997 8.96856C6.59997 10.2068 5.615 11.2107 4.39997 11.2107C3.18495 11.2107 2.19997 10.2068 2.19997 8.96856C2.19997 7.73028 3.18495 6.72645 4.39997 6.72645C5.615 6.72645 6.59997 7.73028 6.59997 8.96856Z"
        fill="white"
      />
      <path
        d="M17.6 20.1791V16.816C17.6 15.6342 17.3009 14.5236 16.7758 13.5585C17.0392 13.4895 17.3154 13.4528 17.6 13.4528C19.4225 13.4528 20.9 14.9585 20.9 16.816V20.1791H17.6Z"
        fill="white"
      />
      <path
        d="M5.22416 13.5585C4.69901 14.5236 4.39997 15.6342 4.39997 16.816V20.1791H1.09998V16.816C1.09998 14.9585 2.57743 13.4528 4.39997 13.4528C4.68455 13.4528 4.96072 13.4895 5.22416 13.5585Z"
        fill="white"
      />
    </svg>
  );
}

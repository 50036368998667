import { useState } from "react";
import { Order } from "../../../components/Table/TableCustom";

export const useCustomerFilterHook = () => {
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [direction, setDirection] = useState<Order>("desc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [toggleFetch, setToggleFetch] = useState(false);

  return {
    startDate,
    endDate,
    searchText,
    direction,
    page,
    limit,
    toggleFetch,
    setStartDate,
    setEndDate,
    setSearchText,
    setDirection,
    setPage,
    setLimit,
    setToggleFetch,
  };
};

export type TUseCustomerFilterHook = ReturnType<typeof useCustomerFilterHook>;

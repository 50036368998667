export function OrderStockFood() {
  return (
    <svg
      width="50"
      height="51"
      viewBox="0 0 50 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11948_280464)">
        <path
          d="M14.7803 7.89746C14.6162 8.05469 14.6094 8.0957 14.6094 8.99121V9.91406H25H35.3906V9.00488C35.3906 8.18457 35.377 8.08203 35.2539 7.94531C34.9805 7.6582 34.8164 7.67188 33.9756 8.0957L33.2031 8.47852L32.4512 8.10254C32.041 7.89746 31.6377 7.72656 31.5625 7.72656C31.4873 7.72656 31.084 7.89746 30.6738 8.10254L29.9219 8.47852L29.1699 8.10254C28.7598 7.89746 28.3564 7.72656 28.2812 7.72656C28.2061 7.72656 27.8027 7.89746 27.3926 8.10254L26.6406 8.47852L25.8887 8.10254C25.4785 7.89746 25.0752 7.72656 25 7.72656C24.9248 7.72656 24.5215 7.89746 24.1113 8.10254L23.3594 8.47852L22.6074 8.10254C22.1973 7.89746 21.7939 7.72656 21.7188 7.72656C21.6436 7.72656 21.2402 7.89746 20.8301 8.10254L20.0781 8.47852L19.3262 8.10254C18.916 7.89746 18.5127 7.72656 18.4375 7.72656C18.3623 7.72656 17.959 7.89746 17.5488 8.10254L16.7969 8.47852L16.0449 8.10254C15.6348 7.89746 15.2178 7.72656 15.1221 7.72656C15.0264 7.72656 14.8691 7.80176 14.7803 7.89746Z"
          fill="#68C184"
        />
        <path
          d="M34.1319 12.1824C32.628 13.9871 32.1495 14.6638 31.671 15.6209C31.0011 16.9607 31.0558 16.1609 31.0353 24.781C31.0148 29.8806 31.0353 32.5193 31.0831 32.8406C31.295 34.2351 31.9171 35.3425 33.8243 37.7488C34.4122 38.4802 34.9864 39.2254 35.1163 39.3962C35.2394 39.574 35.3624 39.7175 35.3829 39.7107C35.4034 39.7107 35.5538 39.533 35.7042 39.3211C35.8614 39.1091 36.4425 38.405 37.003 37.7693C37.5567 37.1267 38.1788 36.3816 38.3839 36.1013C38.8692 35.4451 39.3751 34.488 39.5733 33.8386L39.7306 33.3259L39.7511 25.5193C39.7716 16.8787 39.7853 17.2547 39.3273 16.1882C39.047 15.5388 38.63 14.8279 38.0353 14.0007C37.5567 13.3377 35.4444 10.7332 35.3829 10.7332C35.3624 10.7332 34.795 11.3894 34.1319 12.1824Z"
          fill="#68C184"
        />
        <path
          d="M13.6123 12.0537C12.1494 13.8857 11.4248 15.1025 11.0078 16.4424L10.8164 17.0576V25.0557V33.0537L11.0078 33.6689C11.2197 34.3662 11.6846 35.3711 12.0947 36.0273C12.2451 36.2734 12.8467 37.1416 13.4346 37.9619L14.501 39.4453H24.126C31.8164 39.4453 33.751 39.4248 33.71 39.3564C33.6758 39.3154 33.2383 38.748 32.7256 38.1123C31.3311 36.3691 30.6816 35.3096 30.292 34.1611C29.9228 33.0742 29.9228 33.1289 29.9228 25.0283C29.9297 16.8457 29.916 17.0439 30.333 15.9229C30.7705 14.7061 31.666 13.3867 33.4434 11.2949L33.6963 11.0078H24.0645L14.4395 11.0146L13.6123 12.0537ZM14.1387 17.8711C14.7607 18.0898 15.2734 18.4453 15.8408 19.0537L16.3193 19.5664L16.8799 19.3271C18.9443 18.4521 21.248 18.4521 23.2646 19.3203L23.832 19.5664L24.4678 18.9238C24.9805 18.4111 25.2129 18.2266 25.6504 18.0078C26.1768 17.748 26.2178 17.7412 26.8945 17.7412C27.5303 17.7412 27.5986 17.7549 27.7627 17.9053C28.0908 18.2129 28.2412 18.7461 28.2412 19.5869C28.248 20.5986 28.0361 21.1934 27.2432 22.4443C27.0107 22.7998 26.915 23.0117 26.9492 23.0938C26.9766 23.1553 27.0312 23.415 27.0859 23.668L27.1748 24.1328H26.2725C25.3359 24.1396 25.2334 24.167 25.0762 24.4609C24.9668 24.666 25.0283 24.9053 25.2197 25.0898C25.3564 25.2129 25.459 25.2266 26.3066 25.2266H27.2432L27.5576 25.5752C27.9951 26.0605 28.2139 26.5391 28.2617 27.127C28.3984 28.7266 27.3594 30.1826 25.7871 30.5928C25.4385 30.6816 24.6318 30.6953 20.0791 30.6885C15.3008 30.6885 14.7402 30.6748 14.3301 30.5723C12.4639 30.0801 11.3633 27.9268 12.1426 26.2861C12.252 26.0605 12.4775 25.7324 12.6553 25.5479L12.9697 25.2266H13.8926C14.7881 25.2266 14.8291 25.2197 14.9863 25.0557C15.2119 24.8369 15.2119 24.5225 14.9863 24.3037C14.8291 24.1396 14.7881 24.1328 13.9268 24.1328C12.9082 24.1328 12.9697 24.1875 13.1475 23.415L13.2637 22.9365L12.8877 22.3555C12.1357 21.1934 11.9102 20.5645 11.917 19.5869C11.917 18.6846 12.1631 18.042 12.5937 17.7891C12.833 17.6523 13.6396 17.6934 14.1387 17.8711Z"
          fill="#68C184"
        />
        <path
          d="M13.0772 18.9033C12.8379 19.5391 13.0977 20.7217 13.665 21.5625L13.8359 21.8086L14.0479 21.5146C14.3145 21.1592 14.6904 20.7559 15.0801 20.4277L15.374 20.1748L15.1211 19.8604C14.9775 19.6895 14.7109 19.4434 14.5264 19.3066C13.9522 18.8828 13.1729 18.6641 13.0772 18.9033Z"
          fill="#68C184"
        />
        <path
          d="M26.3467 18.9033C26.2031 18.958 25.9912 19.0674 25.8682 19.1494C25.5605 19.3613 24.8633 20.0449 24.8633 20.1338C24.8633 20.1748 25.0547 20.3799 25.2939 20.5918C25.5264 20.7969 25.8545 21.1592 26.0254 21.3916L26.333 21.8086L26.4492 21.6582C26.6611 21.3848 27.0303 20.5576 27.1191 20.1748C27.2012 19.8193 27.1807 19.0264 27.0918 18.876C27.0234 18.7734 26.668 18.7871 26.3467 18.9033Z"
          fill="#68C184"
        />
        <path
          d="M18.3485 23.1064C17.0086 23.4414 16.0584 24.8701 16.3045 26.1826C16.4754 27.126 17.0291 27.8506 17.8768 28.2539L18.3348 28.4727H20.0438C21.9647 28.4727 22.1698 28.4316 22.7918 27.96C24.2684 26.8525 24.2684 24.6992 22.7987 23.585C22.1766 23.1133 22.0057 23.0791 20.2147 23.0654C19.3328 23.0518 18.492 23.0723 18.3485 23.1064ZM19.3602 25.3965C19.8387 25.8682 19.1483 26.6406 18.6561 26.1826C18.4647 25.998 18.4032 25.7588 18.5125 25.5537C18.7039 25.1914 19.0867 25.1162 19.3602 25.3965ZM21.5477 25.3965C22.0262 25.8682 21.3358 26.6406 20.8436 26.1826C20.6522 25.998 20.5907 25.7588 20.7 25.5537C20.8914 25.1914 21.2742 25.1162 21.5477 25.3965Z"
          fill="#68C184"
        />
        <path
          d="M14.6094 41.4482C14.6094 42.2686 14.623 42.3711 14.7461 42.5078C15.0195 42.7949 15.1836 42.7812 16.0244 42.3574L16.7969 41.9746L17.5488 42.3506C17.959 42.5557 18.3623 42.7266 18.4375 42.7266C18.5127 42.7266 18.916 42.5557 19.3262 42.3506L20.0781 41.9746L20.8301 42.3506C21.2402 42.5557 21.6436 42.7266 21.7188 42.7266C21.7939 42.7266 22.1973 42.5557 22.6074 42.3506L23.3594 41.9746L24.1113 42.3506C24.5215 42.5557 24.9248 42.7266 25 42.7266C25.0752 42.7266 25.4785 42.5557 25.8887 42.3506L26.6406 41.9746L27.3926 42.3506C27.8027 42.5557 28.2061 42.7266 28.2812 42.7266C28.3564 42.7266 28.7598 42.5557 29.1699 42.3506L29.9219 41.9746L30.6738 42.3506C31.084 42.5557 31.4873 42.7266 31.5625 42.7266C31.6377 42.7266 32.041 42.5557 32.4512 42.3506L33.2031 41.9746L33.9756 42.3574C34.8164 42.7812 34.9805 42.7949 35.2539 42.5078C35.377 42.3711 35.3906 42.2686 35.3906 41.4482V40.5391H25H14.6094V41.4482Z"
          fill="#68C184"
        />
      </g>
      <defs>
        <clipPath id="clip0_11948_280464">
          <rect
            width="35"
            height="35"
            fill="white"
            transform="translate(7.5 7.72656)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

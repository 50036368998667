export function EditSvg() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.91634 1.91667H1.99967C1.35534 1.91667 0.833008 2.439 0.833008 3.08333V9.5C0.833008 10.1443 1.35534 10.6667 1.99967 10.6667H8.41634C9.06067 10.6667 9.58301 10.1443 9.58301 9.5V6.58333M8.75805 1.09171C9.21366 0.636097 9.95235 0.636097 10.408 1.09171C10.8636 1.54732 10.8636 2.28601 10.408 2.74162L5.39959 7.75H3.74967L3.74967 6.10008L8.75805 1.09171Z"
        stroke="#68C184"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

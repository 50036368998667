import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import { Button, Link } from "@mui/material";
import { useLoaderData, useNavigate } from "react-router-dom";
import backgroundImage from "../../assets/images/OrderFeedImage/bg.png";
import LllustationSVG from "../../assets/svg/Lllustation";
import AppBarFS from "../../components/app-bar/AppBar";
import { loaderContactFarmer } from "./loader";

const format = (value: string, pattern: string) => {
  let i = 0;
  const v = value.toString();
  return pattern.replace(/#/g, () => v[i++]);
};

export function ContactFarmer() {
  const navigate = useNavigate();
  const { email, mobileNumber } = useLoaderData() as Awaited<
    ReturnType<typeof loaderContactFarmer>
  >;
  return (
    <div
      className="pb-6 flex flex-col justify-between items-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        height: "100%",
        backgroundSize: "cover",
      }}
    >
      <div className="p-4 rounded-b-[40px] bg-white w-full">
        <AppBarFS onIconPress={() => navigate(-1)} title={"ติดต่อผู้ซื้อ"} />
      </div>
      <div className="flex flex-col gap-4 items-center h-[80%] justify-center">
        <p className="text-[24px] font-medium text-[#ffff]">ติดต่อเกษตรกร</p>
        <LllustationSVG />
        <p className="text-[#ffff]">ช่องทางการติดต่อเกษตรกร</p>
        {mobileNumber && (
          <div className="text-[#ffff]">
            <CallIcon />
            <Link
              href={`tel:${mobileNumber}`}
              sx={{
                color: "#ffff",
                fontWeight: "600",
                marginLeft: "8px",
                textDecorationColor: "white",
              }}
            >
              {format(mobileNumber, "###-###-####")}
            </Link>
          </div>
        )}
        {email && (
          <div className="text-[#ffff]">
            <EmailIcon />
            <Link
              href={`mailto:${email}`}
              sx={{
                color: "#ffff",
                fontWeight: "600",
                marginLeft: "8px",
                textDecorationColor: "white",
              }}
            >
              {email}
            </Link>
          </div>
        )}
      </div>
      {mobileNumber && (
        <Link
          href={`tel:${mobileNumber}`}
          sx={{
            width: "90%",
          }}
        >
          <Button
            size="large"
            variant="contained"
            startIcon={<CallIcon />}
            sx={{
              width: "100%",
            }}
          >
            โทรทันที
          </Button>
        </Link>
      )}
    </div>
  );
}

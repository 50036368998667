const IconCarSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none">
    <mask
      id="a"
      width={16}
      height={16}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M0 0h16v16H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#fff"
        d="M3.997 13.335a1.929 1.929 0 0 1-1.416-.584 1.929 1.929 0 0 1-.584-1.416H.664V4c0-.366.13-.68.392-.941.26-.261.575-.392.941-.392h9.334v2.667h2l2 2.666v3.334h-1.334c0 .555-.194 1.027-.583 1.416a1.929 1.929 0 0 1-1.417.584 1.929 1.929 0 0 1-1.416-.584 1.929 1.929 0 0 1-.584-1.416h-4c0 .555-.194 1.027-.583 1.416a1.929 1.929 0 0 1-1.417.584Zm0-1.334a.645.645 0 0 0 .475-.191.645.645 0 0 0 .192-.475.645.645 0 0 0-.192-.475.645.645 0 0 0-.475-.192.645.645 0 0 0-.475.192.645.645 0 0 0-.191.475c0 .188.064.347.191.475a.645.645 0 0 0 .475.191Zm8 0a.645.645 0 0 0 .475-.191.645.645 0 0 0 .192-.475.645.645 0 0 0-.192-.475.645.645 0 0 0-.475-.192.645.645 0 0 0-.475.192.645.645 0 0 0-.191.475c0 .188.064.347.191.475a.645.645 0 0 0 .475.191Zm-.666-3.333h2.833l-1.5-2h-1.333v2Z"
      />
    </g>
  </svg>
);
export default IconCarSvg;

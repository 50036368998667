const ContractSvgWithBg = () => (
  <div
    className="w-[54px] h-[54px] rounded-[10px] gap-[10px] p-[10px]"
    style={{
      background:
        "linear-gradient(90deg, #3777BC 0%, #68C184 71.01%, #77F2A1 100%)",
      boxShadow: "4px 4px 20px 0px #0000001A",
    }}
  >
    <svg xmlns="http://www.w3.org/2000/svg" width={34} height={34} fill="none">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M6.797 6.798a3.4 3.4 0 0 1 3.4-3.4h7.796a3.4 3.4 0 0 1 2.404.996l5.804 5.804a3.4 3.4 0 0 1 .996 2.405v14.595a3.4 3.4 0 0 1-3.4 3.4h-13.6a3.4 3.4 0 0 1-3.4-3.4v-20.4Zm3.4 10.2a1.7 1.7 0 0 1 1.7-1.7h10.2a1.7 1.7 0 0 1 0 3.4h-10.2a1.7 1.7 0 0 1-1.7-1.7Zm1.7 5.1a1.7 1.7 0 0 0 0 3.4h10.2a1.7 1.7 0 1 0 0-3.4h-10.2Z"
        clipRule="evenodd"
      />
    </svg>
  </div>
);
export default ContractSvgWithBg;

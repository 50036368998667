import { TDropdownValue } from "./views/Dropdown"

export type TForm = {
  area: TDropdownValue[]
  province: TDropdownValue[]
  district: TDropdownValue[]
}

export type TColumnSelected = {
  area: TDropdownValue[]
  group: TDropdownValue[]
}

export const defaultForm: TForm = {
  area: [],
  province: [],
  district: [],
}

export const defaultColumnSelected: TColumnSelected = {
  area: [],
  group: [],
}

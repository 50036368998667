import { useEffect, useState } from "react";
import PersonSvg from "../../../../../assets/svg/PersonSvg";
import { thousandComma } from "../../../../../utils/common/string";
import { getPersonalInfoPartner } from "../../services/partnerDetail.service";
import { IGetPersonalInfoPartner } from "../../types/personalInformation.type";
import dayjs from "dayjs";
import { DATE_FORMAT_DATE_PICKER2 } from "../../../../../const/app.constant";

type Props = {
  partnerId?: string;
};

const PersonalInformation = ({ partnerId }: Props) => {
  const [partnerInfo, setPartnerInfo] =
    useState<IGetPersonalInfoPartner | null>();

  const handleGetPartnerInfo = async () => {
    const data = await getPersonalInfoPartner(partnerId);
    setPartnerInfo(data);
  };
  useEffect(() => {
    handleGetPartnerInfo();
  }, []);
  return (
    <>
      <div className="flex gap-2 text-center items-center mt-6">
        <PersonSvg />
        <p className="text-lg font-bold text-[#3777BC] font-sans">
          ข้อมูลส่วนตัว
        </p>
      </div>

      <div className="flex gap-3 mt-4">
        <div className="w-[50%]">
          <p className="text-[14px] mb-3 font-sans font-bold">รหัสพาร์ทเนอร์</p>
          <p className="text-[14px] w-[340px] pb-3 font-sans">
            {partnerInfo?.result.partnerCode}
          </p>
        </div>
        <div className="w-[50%]">
          <p className="text-[14px] mb-3 font-sans font-bold">ชื่อพาร์ทเนอร์</p>
          <p className="text-[14px] w-[340px] pb-3 font-sans">
            {partnerInfo?.result.name + " " + partnerInfo?.result.surname}
          </p>
        </div>
      </div>
      <div className="flex gap-3 mt-4">
        <div className="w-[50%]">
          <p className="text-[14px] mb-3 font-sans font-bold">เบอร์โทรศัพท์</p>
          <p className="text-[14px] w-[340px] pb-3 font-sans">
            {partnerInfo?.result.mobileNumber || "-"}
          </p>
        </div>
        <div className="w-[50%]">
          <p className="text-[14px] mb-3 font-sans font-bold">ประเภท</p>
          <p className="text-[14px] w-[340px] pb-3 font-sans">
            {partnerInfo?.result.type || "-"}
          </p>
        </div>
      </div>
      <div className="flex gap-3 mt-4">
        <div className="w-[50%]">
          <p className="text-[14px] mb-3 font-sans font-bold">ชื่อพนักงานขาย</p>
          <div className="w-full pb-3">
            {partnerInfo?.result.groupAreas
              .map((group) => group.sellerName)
              .join(" , ")}
          </div>
        </div>
        <div className="w-[50%]">
          <p className="text-[14px] mb-3 font-sans font-bold">Group Area</p>
          <div className="w-full pb-3 font-sans">
            {partnerInfo?.result.groupAreas
              .map((group) => group.name)
              .join(" , ")}
          </div>
        </div>
      </div>
      <div className="flex gap-3 mt-4">
        <div className="w-[50%]">
          <p className="text-[14px] mb-3 font-sans font-bold">จังหวัด</p>
          <div className="w-[340px] pb-3 font-sans">
            {" "}
            {partnerInfo?.result.groupAreas
              .map((group) => group.groupProvince.nameTh)
              .join(" , ")}
          </div>
        </div>
        <div className="w-[50%]">
          <p className="text-[14px] mb-3 font-sans font-bold">
            วันที่เซ็นสัญญา
          </p>
          <div className="w-[340px] pb-3 font-sans">
            {partnerInfo?.result.contractSignDate
              ? dayjs(partnerInfo.result.contractSignDate).format(
                  DATE_FORMAT_DATE_PICKER2
                )
              : "-"}
          </div>
        </div>
      </div>
      <div className="flex gap-3 mt-4">
        <div className="w-full">
          <p className="text-[14px] mb-3 font-sans font-bold">รายได้ทั้งหมด</p>
          <p className="text-[14px] w-[340px] pb-3 font-sans">
            {thousandComma(partnerInfo?.result.totalRevenue || 0, 2) + " บาท"}
          </p>
        </div>
      </div>
    </>
  );
};

export default PersonalInformation;

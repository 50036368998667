import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SuccessCard from "../../../components/common/SuccessCard";

export default function AddHouseSuccess() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { farmId } = useParams();

  return (
    <SuccessCard
      title={`${t("addHouseSuccess.successTitle")}`}
      buttonLabel={`${t("addHouseSuccess.buttonLabelHome")}`}
      buttonLabelSecondary={`${t("addHouseSuccess.buttonLabelSecondary")}`}
      onButtonPress={() => navigate("/farms")}
      onButtonPressSecondary={() => navigate(`/farm/${farmId}`)}
      subtitle1={`${t("addHouseSuccess.subTitle1")}`}
      subtitle2={`${t("addHouseSuccess.subTitle2")}`}
    />
  );
}

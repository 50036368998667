import { Pie, PieChart, ResponsiveContainer, Sector, Cell } from "recharts";
import { getCommaNumber } from "../../../../utils/number";
import { tw } from "../../../../utils/tw";

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  // const sin = Math.sin(-RADIAN * midAngle);
  // const cos = Math.cos(-RADIAN * midAngle);
  // const sx = cx + (outerRadius + 10) * cos;
  // const sy = cy + (outerRadius + 10) * sin;
  // const mx = cx + (outerRadius + 30) * cos;
  // const my = cy + (outerRadius + 30) * sin;
  // const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  // const ey = my;
  // const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

export interface ICountChartProps {
  dataset: {
    name: string;
    value: number;
    fill?: string;
  }[];
}

export default function CountChart({ dataset }: ICountChartProps) {
  return (
    <div className="flex items-center w-[300px] h-[300px] grow justify-center relative">
      {dataset?.length > 0 && (
        <>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={280} height={280}>
              <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" stopColor="#F57C00" />
                  <stop offset="71.01%" stopColor="#D32F2F" />
                  <stop offset="100%" stopColor="#D32F2F" />
                </linearGradient>
              </defs>
              <defs>
                <linearGradient
                  id="gradient-0"
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="0%"
                >
                  <stop offset="0%" stopColor="#3777BC" />
                  <stop offset="71.01%" stopColor="#68C184" />
                  <stop offset="100%" stopColor="#77F2A1" />
                </linearGradient>
              </defs>
              <Pie
                activeIndex={0}
                activeShape={renderActiveShape}
                data={dataset}
                cx="50%"
                cy="50%"
                innerRadius={70}
                outerRadius={100}
                startAngle={-270}
                endAngle={90}
                fill="url(#gradient)"
                stroke="#fff" // เพิ่มเส้นขอบสีขาว
                strokeWidth={2} // ความหนาของเส้นขอบ
                dataKey="value"
              >
                {dataset.map((value: any, index: number) => {
                  const isNegative =
                    dataset[2].value - dataset[0].value - dataset[1].value <= 0;
                  return (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        isNegative
                          ? "#B0B0B0"
                          : value.fill || "url(#gradient-0)"
                      }
                      style={{
                        filter: isNegative
                          ? "none"
                          : `drop-shadow(0px 0px 5px ${
                              value?.fill || "#5CB8A0"
                            })`,
                      }}
                      stroke="0"
                    />
                  );
                })}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <div className="absolute">
            <div className="text-[14px] text-[#646464] font-medium text-center">
              ยอดเงินคงเหลือ
            </div>
            <div
              className={tw(
                "text-[16px] text-[#000000] font-bold text-center",
                dataset[2].value - dataset[0].value - dataset[1].value < 0 &&
                  "text-error-300"
              )}
            >
              {getCommaNumber(
                dataset[2].value - dataset[0].value - dataset[1].value,
                true
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

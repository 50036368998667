import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Dialog, DialogContent, DialogTitle, SvgIcon } from "@mui/material";
import dayjs from "dayjs";
import { get } from "lodash";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useLoaderData } from "react-router-dom";
import * as Y from "yup";
import { unitMap } from "..";
import CrossIcon from "../../../../../assets/images/feed/close.svg";
import useFormValidator from "../../../../../hooks/useFormValidator.hook";
import { alertAction } from "../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../../../redux/store";
import { createStockRefill } from "../../../../../services/ManageStock.service";
import { FeedBrands } from "../../../../../types/FeedBrand.type";
import { IGetPartnerStock } from "../../../../../types/ManageStock.type";
import getErrorMessage from "../../../../../utils/getErrorMessage";
import { CalendarSvg } from "../../../../assets/svg/Calendar.svg";
import PlusCircleIcon from "../../../../assets/svg/PlusCircleIcon.svg";
import TrashIconSVG from "../../../../assets/svg/TrashIcon.svg";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../../components/Button/ButtonOutlinedCustom";
import DatePickerPopover from "../../../../components/Input/DatePickerPopover/DatePickerPopover";
import DateRangePickerPopover from "../../../../components/Input/DateRangePickerPopover/DateRangePickerPopover";
import TextInputCustom from "../../../../components/Input/TextInputCustom";
import { Dropdown } from "../../../group-area/views/Dropdown";
import { Input } from "../../../group-area/views/Input";
import { FILTER_DATE_FORMAT } from "../../../sale-order/components/SaleOrderSearchFilter";
import { TDetail, TForm, defaultForm } from "../defaultForm";
import PartnerPartialInfoCard from "./PartnerPartialInfoCard";

interface FillProductModalProps {
  open: boolean;
  onClose: () => void;
  data?: IGetPartnerStock;
  refetch: () => void;
}

const errorSchema = Y.object({
  numberOrder: Y.string()
    .required("กรุณากรอกหมายเลขคำสั่งซื้อ")
    .max(20, "สามารถกรอกได้ไม่เกิน 20 ตัวอักษร"),
  numberDeliver: Y.string()
    .required("กรุณากรอกหมายเลขใบส่งของ")
    .max(20, "สามารถกรอกได้ไม่เกิน 20 ตัวอักษร"),
  detail: Y.array(
    Y.object({
      brandName: Y.array(
        Y.object({
          value: Y.string(),
        })
      ).min(1, "กรุณาเลือกแบรนด์อาหารสัตว์"),
      brandNumber: Y.array(
        Y.object({
          value: Y.string(),
        })
      ).min(1, "กรุณาเลือกเบอร์อาหารสัตว์"),
      amount: Y.string().required("กรุณากรอกจำนวน"),
    })
  ).min(1),
});

function FillProductModal({
  open,
  onClose,
  data,
  refetch,
}: FillProductModalProps) {
  const dispatch = useDispatch<AppDispatch>();
  const refCalendarStartDate =
    React.useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);
  const handleSelectStartDate = (value?: Date) => {
    const formattedDate = dayjs(value).format(FILTER_DATE_FORMAT);
    setStartDate(formattedDate);
  };
  const { feedBrands } = useLoaderData() as { feedBrands: FeedBrands[] };
  const feedSKU = (id: string) =>
    feedBrands.find((row) => row.id === id)?.feed_brand_items || [];

  const [form, setForm] = useState<TForm>(defaultForm);
  const [startDate, setStartDate] = useState("");

  const onChangeForm = useCallback(
    (key: keyof TForm, index?: number, arrayKeyName?: keyof TDetail) =>
      (value: any) => {
        if (typeof index === "number" && arrayKeyName) {
          setForm((prev) => {
            const newDetail = prev.detail.map((row, idx) => {
              if (idx === index) {
                return {
                  ...row,
                  [arrayKeyName]: value,
                };
              } else {
                return row;
              }
            });
            return { ...prev, detail: newDetail };
          });
        } else {
          setForm((prev) => {
            return { ...prev, [key]: value };
          });
        }
      },
    []
  );

  const handleAdd = () => {
    const newArray = [
      ...form.detail,
      {
        brandName: [],
        brandNumber: [],
        unit: "",
        amount: "",
      },
    ];
    setForm({
      numberOrder: form.numberOrder,
      numberDeliver: form.numberDeliver,
      detail: newArray,
    });
  };

  const handleDelete = (index: number) => {
    const newArray = form.detail.filter((_, idx) => idx !== index);
    setForm({ detail: newArray });
  };

  const { onSubmit, errors, resetError } = useFormValidator({
    form: {
      ...form,
    },
    errorSchema,
    onValidateSuccess: () => {
      dispatch(
        alertAction.showDialog({
          title: "ยืนยันการบันทึก",
          text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
          cancelText: "กลับไปแก้ไข",
          confirmText: "ยืนยัน",
          onCancel: () => {
            dispatch(alertAction.hide());
          },
          onSubmit: async () => {
            dispatch(
              loadingActions.show({
                message: "กำลังบันทึกข้อมูล...",
              })
            );
            createStockRefill({
              partnerId: data?.result.id as string,
              refillNumber: get(form, ["numberOrder"])!,
              deliveryNumber: get(form, ["numberDeliver"])!,
              listOfFeeds: (get(form, ["detail"]) || []).map((detail: any) => ({
                feedBrandId: +get(detail, ["brandName", 0, "value"]),
                feedBrandItemId: +get(detail, ["brandNumber", 0, "value"]),
                feedUnit: get(detail, ["unit"]),
                feedAmount: +get(detail, ["amount"]),
                feedPricePerUnit: 1,
              })),
              shippingDate: dayjs(startDate, FILTER_DATE_FORMAT)
                .startOf("day")
                .toDate(),
            })
              .then(() => {
                setTimeout(() => {
                  dispatch(alertAction.hide());
                  dispatch(loadingActions.hide());
                  dispatch(
                    snakeActions.showMessage({
                      message: "บันทึกข้อมูลสำเร็จ",
                      type: "success",
                    })
                  );
                  onCloseFn();
                }, 1500);
              })
              .catch((e) => {
                setTimeout(() => {
                  dispatch(alertAction.hide());
                  dispatch(loadingActions.hide());
                  dispatch(
                    snakeActions.showMessage({
                      message: getErrorMessage(e),
                      type: "error",
                    })
                  );
                }, 1500);
              });
          },
        })
      );
    },
  });

  const onCloseFn = useCallback(() => {
    onClose();
    setTimeout(() => {
      resetError();
      setForm(defaultForm);
      refetch();
      setStartDate("");
    }, 500);
  }, []);

  return (
    <Dialog
      aria-describedby="fill-product-modal"
      aria-labelledby="fill-product-modal"
      open={open}
      onClose={onCloseFn}
      fullWidth
      slotProps={{
        backdrop: { sx: { background: "rgba(0,0,0,0.3)" } },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span className="font-bold text-[18px]">สร้างรายการเติมสินค้า</span>
        <img
          src={CrossIcon}
          alt="close"
          className="cursor-pointer"
          onClick={onCloseFn}
        />
      </DialogTitle>
      <DialogContent className="text-sm">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col">
            <div className="grid grid-cols-2 gap-6">
              <Input
                label="หมายเลขสั่งซื้อ"
                inputType="number"
                value={form.numberOrder as any}
                onChangeText={onChangeForm("numberOrder")}
                containerClassName="flex flex-col gap-1"
                errorMessage={errors.numberOrder}
                isNotComma
              />
              <div className="w-full">
                <span className="font-medium text-[14px] mb-2">
                  วันที่จัดส่งสินค้า
                </span>
                <div className="pt-2">
                  <TextInputCustom
                    placeholder="เริ่มต้น"
                    value={startDate}
                    InputProps={{
                      endAdornment:
                        startDate !== "" ? (
                          <CloseRoundedIcon
                            sx={{
                              cursor: "pointer",
                              fontSize: "18px !important",
                            }}
                            onClick={() => {}}
                          />
                        ) : (
                          <SvgIcon
                            sx={{
                              fontSize: "24px !important",
                              color: "#646464",
                            }}
                            fontSize="small"
                          >
                            <CalendarSvg />
                          </SvgIcon>
                        ),
                    }}
                    onClick={(
                      e: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => {
                      e.stopPropagation();
                      refCalendarStartDate.current?.onOpen(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2 pr-3">
              <Input
                label="หมายเลขใบส่งของ"
                inputType="number"
                value={form.numberDeliver as any}
                onChangeText={onChangeForm("numberDeliver")}
                containerClassName="flex flex-col gap-1"
                errorMessage={errors.numberDeliver}
                isNotComma
              />
            </div>
          </div>
          <div className="flex flex-col gap-6">
            {form.detail.map((row, index) => {
              return (
                <div className="" key={index}>
                  {form.detail.length > 1 && (
                    <div className="flex justify-between text-base font-bold text-tertiary">
                      <span>รายการที่ {index + 1}</span>
                      <SvgIcon
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={(_) => handleDelete(index)}
                      >
                        <TrashIconSVG />
                      </SvgIcon>
                    </div>
                  )}
                  <div className="flex gap-6">
                    <div className="w-1/2">
                      <Dropdown
                        label="แบรนด์อาหารสัตว์"
                        options={feedBrands.map((row) => {
                          return {
                            value: row.id,
                            label: row.nameTh,
                          };
                        })}
                        onChange={onChangeForm("detail", index, "brandName")}
                        renderValueArray={row.brandName}
                        errorMessage={errors[`detail[${index}].brandName`]}
                      />
                    </div>
                    <div className="w-1/2">
                      <Dropdown
                        label="เบอร์อาหารสัตว์"
                        options={feedSKU(row.brandName[0]?.value).map(
                          (row) => ({
                            value: row.id,
                            label: row.skuTh,
                          })
                        )}
                        onChange={(e) => {
                          onChangeForm("detail", index, "brandNumber")(e);
                          const selectedSKU = feedSKU(
                            row.brandName[0].value
                          ).find((row) => row.id === e[0].value);
                          onChangeForm(
                            "detail",
                            index,
                            "unit"
                          )(selectedSKU?.unit);
                        }}
                        renderValueArray={row.brandNumber}
                        errorMessage={errors[`detail[${index}].brandNumber`]}
                      />
                    </div>
                  </div>
                  <div className="flex gap-6">
                    <div className="w-1/2">
                      <Input
                        label="จำนวน"
                        inputType="number"
                        value={form.detail[index].amount as string}
                        onChangeText={onChangeForm("detail", index, "amount")}
                        errorMessage={errors[`detail[${index}].amount`]}
                      />
                    </div>
                    <div className="w-1/2">
                      <Input
                        label="หน่วย (กิโลกรัม/ถุง)"
                        placeholder=""
                        value={
                          unitMap[form.detail[index].unit as "kg" | "bag"] || ""
                        }
                        onChangeText={onChangeForm("detail", index, "unit")}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mx-auto">
            <ButtonOutlinedCustom
              btnTextColor="#68C184"
              btnBorderColor="#68C184"
              title="เพิ่ม SKU"
              startIcon={<PlusCircleIcon />}
              onClick={handleAdd}
            />
          </div>
          <div>
            <p className="mb-2">รหัสพาร์ทเนอร์</p>
            <TextInputCustom disabled value={data?.result.partnerCode} />
          </div>
          <PartnerPartialInfoCard data={data} />
          <div className="flex items-center justify-end">
            <ButtonFillCustom title="ยืนยัน" onClick={onSubmit} />
          </div>
        </div>
        <DatePickerPopover
          ref={refCalendarStartDate}
          id={"date-picker-start-date"}
          date={startDate}
          handleCalenderSave={handleSelectStartDate}
        />
      </DialogContent>
    </Dialog>
  );
}
export default FillProductModal;

import { Button, Typography } from "@mui/material";
import CustomButton from "../../../components/common/Button";

import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactangleBackGround } from "../../../const/assest-url";
import { SuccessCardProps } from "./type";

export default function SuccessCard({
  title,
  subtitle1,
  subtitle2,
  subtitle3,
  buttonLabel,
  buttonLabelSecondary,
  onButtonPressSecondary,
  onButtonPress,
}: SuccessCardProps) {
  return (
    <div
      className={`bg-cont main-container bg-parent w-full bg-cover`}
      style={{ backgroundImage: `url(${ReactangleBackGround})` }}
    >
      <div className="px-4 py-6 h-screen flex flex-col w-full z-10">
        <div className="flex flex-col grow justify-center items-center mt-12">
          <div className="flex flex-col gap-6 justify-center items-center">
            <FontAwesomeIcon
              style={{ background: "transparent", marginBottom: "0.75rem" }}
              className="bg-white rounded-full text-[100px]"
              color="#68C184"
              size="lg"
              icon={faCircleCheck}
            />

            <Typography
              sx={{ color: "white", fontWeight: 600, textAlign: "center" }}
              variant="h4"
            >
              {title}
            </Typography>
            <div>
              <Typography
                sx={{
                  color: "white",
                  paddingX: "2rem",
                  fontWeight: 500,
                  textAlign: "center",
                }}
                variant="subtitle1"
              >
                {subtitle1}
              </Typography>
              <Typography
                sx={{
                  color: "white",
                  paddingX: "2rem",
                  fontWeight: 500,
                  textAlign: "center",
                }}
                variant="subtitle1"
              >
                {subtitle2}
              </Typography>
              {subtitle3 && (
                <Typography
                  sx={{
                    color: "white",
                    paddingX: "2rem",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                  variant="subtitle1"
                >
                  {subtitle3}
                </Typography>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          {buttonLabelSecondary && (
            <Button
              variant="outlined"
              sx={{ background: "white" }}
              fullWidth
              color="secondary"
              size="large"
              onClick={onButtonPressSecondary}
            >
              {buttonLabelSecondary || ""}
            </Button>
          )}
          <CustomButton label={buttonLabel || ""} onClick={onButtonPress} />
        </div>
      </div>
    </div>
  );
}

import { createContext, useContext, useEffect, useState } from "react";
import { SaleOrderCount } from "../../../../types/SaleOrder.type";
import { getCountSaleOrder } from "../../../../services/SaleOrderService";

const SaleOrderContext = createContext<{
  countOrder: SaleOrderCount;
  resetCount: () => void;
}>({
  resetCount: () => {},
  countOrder: {
    countCreditHoldOrder: 0,
    countNewOrder: 0,
    countOrderHistory: 0,
    countWaitingForShippingOrder: 0,
  },
});

export const SaleOrderProvider = ({ children }: any) => {
  const [count, setCount] = useState<SaleOrderCount>({
    countCreditHoldOrder: 0,
    countNewOrder: 0,
    countOrderHistory: 0,
    countWaitingForShippingOrder: 0,
  });

  const resetCount = async () => {
    const count = await getCountSaleOrder();
    if (count) {
      setCount(count);
    }
  };

  useEffect(() => {
    resetCount();
  }, []);

  return (
    <SaleOrderContext.Provider value={{ countOrder: count, resetCount }}>
      {children}
    </SaleOrderContext.Provider>
  );
};

export const useCountSaleOrder = () => {
  return useContext(SaleOrderContext);
};

import {
  IApproveInvoicePayment,
  IGetPendingLending,
  IGetSuccessInvoiceLending,
  IInvoiceDetail,
} from "../../../types/invoice/invoiceLending.type";
import { IGetCountInvoiceSale } from "../../../types/invoice/invoiceSale.type";
import adminAPI from "../../AdminApiService";

export const getCountInvoiceLending = async (tabName: string) => {
  try {
    const { data } = await adminAPI.get<IGetCountInvoiceSale>(
      `/invoice/lending/count/${tabName}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getPendingInvoiceLending = async (
  searchParams?: URLSearchParams
) => {
  try {
    const { data } = await adminAPI.get<IGetPendingLending>(
      `/invoice/lending/upcoming?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};
export const getOverDueInvoiceLending = async (
  searchParams?: URLSearchParams
) => {
  try {
    const { data } = await adminAPI.get<IGetPendingLending>(
      `/invoice/lending/overdue?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getSuccessInvoiceLending = async (
  searchParams?: URLSearchParams
) => {
  try {
    const { data } = await adminAPI.get<IGetSuccessInvoiceLending>(
      `/invoice/lending/complete?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getListExportInvoiceLending = async (ids: string[]) => {
  try {
    const { data } = await adminAPI.post<IGetPendingLending>(
      `/invoice/sale/upcoming/export`,
      { ids }
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getInvoiceApproveDetail = async (invoiceId: string) => {
  return adminAPI.get<IInvoiceDetail>("/invoice/lending/payment/" + invoiceId);
};

export const approveInvoicePayment = async (
  invoiceId: string,
  body: IApproveInvoicePayment
) => {
  return adminAPI.put("invoice/lending/payment/" + invoiceId, body);
};

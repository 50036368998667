import { LoaderFunctionArgs } from "react-router-dom";
import { getMyOrder } from "../../../services/MyOrder.service";

export async function myOrderLoader(loader: LoaderFunctionArgs) {
  const { request, params } = loader;
  const farmId = params["farmId"] ?? "";
  const orderId = params["orderId"] ?? "";
  const myOrder = await getMyOrder(farmId, "desc");

  return {
    farmId,
    orderId,
    myOrder,
  };
}

import dayjs from "dayjs";
import { useState } from "react";
import { DATE_FORMAT_DATE_PICKER2 } from "../../../../const/app.constant";
import {
  getCustomerInformation,
  postExportCustomer,
} from "../services/customer.service";
import { CustomerStatus, ICustomerTable } from "../types/customerTable.type";
import { TUseCustomerFilterHook } from "./useCustomerFilter.hook";
import { makeXlsx } from "../../../../utils/make-xlsx";

export const useCustomerTableHook = (
  searchCustomerFilter: TUseCustomerFilterHook,
  type: string,
  partnerId: string
) => {
  const [itemSelected, setItemSelected] = useState<string[]>([]);
  const [list, setList] = useState<ICustomerTable[]>([]);
  const [count, setCount] = useState(0);

  const createData = (
    id: string,
    customerId: string,
    customerName: string,
    activationDate: string,
    status: CustomerStatus
  ): ICustomerTable => {
    return {
      id,
      customerId,
      customerName,
      activationDate: activationDate
        ? dayjs(activationDate).format(DATE_FORMAT_DATE_PICKER2)
        : "-",
      status,
    };
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = list.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };

  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("searchText", searchCustomerFilter.searchText);
    searchParams.append("page", searchCustomerFilter.page.toString());
    searchParams.append("limit", searchCustomerFilter.limit.toString());
    searchParams.append("direction", searchCustomerFilter.direction);
    searchParams.append("startDate", searchCustomerFilter.startDate);
    searchParams.append("endDate", searchCustomerFilter.endDate);
    setList([]);
    const data = await getCustomerInformation(partnerId, type, searchParams);
    if (data?.rows) {
      const newList = data.rows.map((row) => {
        return createData(
          row.id,
          row.registrationId,
          row.name + " " + row.surname,
          row.createdAt,
          row.status as CustomerStatus
        );
      });
      setCount(data.count);
      setList(newList);
    }
  };

  const getExportExcelData = async () => {
    type TColumnName =
      | "รหัสลูกค้่า"
      | "ชื่อลูกค้า"
      | "วันที่เริ่มใช้งาน"
      | "สถานะ";

    type TExcelData = {
      [k in TColumnName]: string | number;
    };
    const allSelectItemData = await postExportCustomer(
      itemSelected,
      partnerId,
      type
    );
    if (allSelectItemData?.rows) {
      return allSelectItemData.rows
        .map<TExcelData>((row) => {
          return {
            รหัสลูกค้่า: row.registrationId,
            ชื่อลูกค้า: row.name + " " + row.surname,
            วันที่เริ่มใช้งาน: dayjs(row.createdAt).format("DD/MM/YYYY"),
            สถานะ: row.status,
          };
        })
        .flat();
    }
    return [];
  };

  const handleExportExcel = async () => {
    const data = await getExportExcelData();
    const fileName = `customer_detail_${type}_${dayjs().format(
      "YYYYMMDDHHmmss"
    )}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  const CustomerStatusMap: Record<
    CustomerStatus,
    { color: string; bgColor: string; label: string }
  > = {
    not_active: {
      color: "#3F3748",
      bgColor: "#E4E4E4",
      label: "ปิดใช้งาน",
    },
    onboard: {
      color: "#3777BC",
      bgColor: "#D9EBFE",
      label: "เริ่ม on board",
    },
    revenue: {
      color: "#409261",
      bgColor: "#E9FFEF",
      label: "ได้ revenue",
    },
  };

  return {
    handleSelectAllClick,
    handleClick,
    isSelected,
    count,
    list,
    itemSelected,
    setItemSelected,
    handleFetch,
    handleExportExcel,
    CustomerStatusMap,
  };
};

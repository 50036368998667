import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { Button } from "@mui/material";
import dayjs from "dayjs";
import DashIcon from "../../assets/images/Farms/Dash-dash.svg";
import BackgroundImage from "../../assets/images/Farms/FarmBackground.png";
import ArrowDropIcon from "../../assets/images/Farms/arrow_dropup.svg";
import DeadPigIcon from "../../assets/images/Farms/deadpig_icon.svg";
import LocationIcon from "../../assets/images/Farms/farm-icon-figma.svg";
import MoneyPocket from "../../assets/images/Farms/money_pocket.svg";
import NodataGraphIcon from "../../assets/images/Farms/noData_graph.svg";
import FarmIcon from "../../assets/images/OnboardingImages/FarmIcon.svg";
import topIcon from "../../assets/images/OnboardingImages/top_img-2.svg";
import topIcon3 from "../../assets/images/OnboardingImages/top_img-3.svg";

import NotificationsIcon from "@mui/icons-material/Notifications";
import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import FarmFrame from "../../assets/images/Farms/FarmFrame.svg";
import AddFarmsModal from "../../components/common/AddFarmsModal/AddFarmsModal";
import Footer from "../../components/common/Footer/Footer";
import { RestOfFarmsModal } from "../../components/common/RestOfFarmsModal/RestOfFarmsModal";
import { AUTH_USERNAME } from "../../const/app.constant";
import { farmList } from "../../services/AuthService";
import {
  getAllHousesOfFarm,
  getDeadPigData,
  getFarmOverviewService,
} from "../../services/FarmService";
import "../../styles/farms.scss";
import { FeedIntakeGraph } from "./FeedIntakeGraph";
import ManageFarm from "./ManageFarm";
import PigPrice from "./PigPrice";
import RegisterFarms from "./RegisterFarms";
import { useFarmController } from "./controller";

const Farms = () => {
  const { financialDetail } = useFarmController();
  const [render, setRender] = useState<boolean>(false);
  const [farmData, setFarmData] = useState<any>([]);
  const [farmCount, setFormCount] = useState<number>(0);
  const [houseCount, sethouseCount] = useState<number>(0);
  const [feedIntake, setFeedIntake] = useState<any>([]);
  const [farmDetail, setFarmDetail] = useState<any>({});
  const [deadPig, setDeadPig] = useState<any>(0);
  const navigate = useNavigate();
  const [addFarmModal, setAddFarmModal] = useState<boolean>(false);
  const firstTimeLogin = localStorage.getItem("firstTimeLogin");
  const { t } = useTranslation();
  const [isAllFarmsOpen, setIsAllFarmsOpen] = useState(false);
  const theme = useTheme();

  const handleChange = () => {
    farmData.length && houseCount > 0 && navigate("/quick-daily-log");
  };

  const handleModalClose = () => {
    setAddFarmModal(false);
  };

  const getfarmList = async () => {
    const resp = await farmList();
    if (resp.status === 200) {
      const { data } = await getAllHousesOfFarm(resp?.data?.farms?.at(0)?.id);
      const data1 = await getFarmOverviewService();
      const deadPigData = await getDeadPigData();

      if (
        deadPigData.data.deadPigCount > data1.data.allFarmData.fattenerPigCount
      ) {
        const tempDeadLeft =
          deadPigData.data.deadPigCount -
          data1.data.allFarmData.fattenerPigCount;
        data1.data.allFarmData.fattenerPigCount = 0;
        data1.data.allFarmData.nurseryPigCount -= tempDeadLeft;
      } else
        data1.data.allFarmData.fattenerPigCount -=
          deadPigData.data.deadPigCount;
      const emp: any = [];
      for (let i = 0; i < 7; i++) {
        const date = dayjs().subtract(i, "day");
        emp.push({
          logDate: date.format("YYYY-MM-DD"),
          feedIntake: 0,
        });
      }
      let tempData = data1.data?.feedintake;
      if (tempData?.length > 0) {
        tempData.forEach((item: any, i: number) => {
          tempData[i].logDate = dayjs(tempData[i].logDate).format("YYYY-MM-DD");
          const index = emp.findIndex(
            (log: { logDate: any }) => log.logDate == tempData[i].logDate
          );
          emp[index] = tempData[i];
        });
      }

      setDeadPig(deadPigData.data?.deadPigCount);
      sethouseCount(data?.count);
      setFeedIntake(emp);
      setFarmDetail(data1.data?.allFarmData || {});
      setFarmData(resp?.data?.farms);
      // if (resp?.data?.farms) setRender()
      setFormCount(resp?.data?.count);
    }
  };
  useEffect(() => {
    getfarmList();
  }, []);

  useEffect(() => {
    if (firstTimeLogin === "true") {
      setAddFarmModal(true);
      localStorage.setItem("firstTimeLogin", "false");
    }
  }, []);

  function handleAllFarmsClicked() {
    if (farmData?.length > 3) {
      setIsAllFarmsOpen(true);
    }
  }

  function handleAllFarmsClosed(closePayload: any) {
    setIsAllFarmsOpen(false);
  }

  function handleFarmClicked(farm: any) {
    if (farm && farm.id) {
      setIsAllFarmsOpen(false);
      navigate(`/farm/${farm?.id}`);
    }
  }

  return (
    <div>
      {!render ? (
        <div className="flex">
          <div className="flex-grow">
            <div
              className={`top-img-container bg-gray-200 flex flex-col bg-cover relative`}
              style={{
                backgroundColor: "rgb(229 231 235 / 0)",
                backgroundImage: `url(${BackgroundImage})`,
              }}
            >
              <img
                src={topIcon}
                className="w-[65%] top-10 right-4 opacity-25 absolute"
                alt="Top Icon"
              />
              <img
                src={topIcon3}
                className="topIcon1 w-[20%] top-[19%] right-12 opacity-25 absolute"
                alt="Top Icon 3"
              />
              <div className="flex w-11/12 relative justify-between items-center top-6 z-1 mx-auto">
                <img src={FarmIcon} className="w-[60%]" alt="farm-icon" />
                <Link to={"/notifications"}>
                  <IconButton className="relative">
                    <NotificationsIcon className="notification-icon" />
                    <span className="absolute w-[7px] h-[7px] bg-[#F57C00] rounded-full top-[12px] left-[12px]"></span>
                  </IconButton>
                </Link>
              </div>
              <div className="farms-headTitle-container flex relative justify-left top-6 z-1 ml-4 mt-9">
                <h1 className="farms-headTitle text-[#ffff]">
                  {t("farms.welcomeTitle")}{" "}
                  {localStorage.getItem(AUTH_USERNAME)}!
                </h1>
              </div>
              <div
                className="h-[32%] w-11/12 mt-[35px] m-auto flex flex-col bg-white-200 py-[10px] px-[25px] rounded-[30px] bg-[#ffffff] transform "
                style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
              >
                <div className="flex justify-start mt-[10px] gap-0">
                  <img
                    src={LocationIcon}
                    alt="LocationIcon"
                    className="md:w-[48px]"
                  />
                  <h1 className="text-[16px] text-tertiary flex justify-start ml-3  font-semibold">
                    {farmCount < 1 ? (
                      <img
                        src={DashIcon}
                        alt="DashIcon"
                        className="sm:w-4 md:w-8"
                      />
                    ) : (
                      <p className="responsive-tablet-title text-[16px]">
                        {t("farms.farmsCountText1")}{" "}
                        <span className="responsive-tablet-title text-[16px] mx-1">
                          {" "}
                          {farmCount}{" "}
                        </span>{" "}
                        {t("farms.farmsCountText2")}
                      </p>
                    )}
                  </h1>
                </div>
                <div className="farms-top-box w-11/12 my-auto flex justify-between loading-[28px] gap-5">
                  <div>
                    {farmDetail?.breederPigCount &&
                    farmDetail?.breederPigCount !== "" &&
                    farmDetail?.breederPigCount !== null ? (
                      <p className="responsive-tablet-title text-[16px]">
                        {parseInt(
                          farmDetail?.breederPigCount
                        )?.toLocaleString()}
                      </p>
                    ) : (
                      <p className="responsive-tablet-title text-[16px] font-bold text-[#34407B]">
                        {t("farms.farmsValue")}
                      </p>
                    )}
                    <p className="responsive-tablet-title text-[16px] text-[#34407B] whitespace-nowrap font-medium">
                      {t("common.breeder_pig")}
                    </p>
                  </div>
                  <div>
                    {farmDetail?.fattenerPigCount &&
                    farmDetail?.fattenerPigCount !== "" &&
                    farmDetail?.fattenerPigCount !== null ? (
                      <p className="responsive-tablet-title text-[16px]">
                        {parseInt(
                          farmDetail?.fattenerPigCount
                        )?.toLocaleString()}
                      </p>
                    ) : (
                      <p className="responsive-tablet-title text-[16px] font-bold text-[#34407B]">
                        {t("farms.farmsValue")}
                      </p>
                    )}

                    <p className="responsive-tablet-title text-[16px] text-[#34407B] whitespace-nowrap font-medium	">
                      {t("common.fattener_pig")}
                    </p>
                  </div>
                  <div>
                    {farmDetail?.nurseryPigCount &&
                    farmDetail?.nurseryPigCount !== "" &&
                    farmDetail?.nurseryPigCount !== null ? (
                      <p className="responsive-tablet-title text-[16px]">
                        {parseInt(
                          farmDetail?.nurseryPigCount
                        )?.toLocaleString()}
                      </p>
                    ) : (
                      <p className="responsive-tablet-title text-[16px] font-bold text-[#34407B]">
                        {t("farms.farmsValue")}
                      </p>
                    )}
                    <p className="responsive-tablet-title text-[16px] text-[#34407B] whitespace-nowrap font-medium	">
                      {t("common.nursery_pig")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* farms overview */}
            <div className="farms-overview-head w-full  bg-white-200 transform">
              <h1 className="responsive-tablet-headTitle text-[25px] flex justify-start ml-4 text-[#34407B] font-semibold">
                {t("farms.overviewTitle")}
              </h1>

              {/* Non farm registration */}
              {farmCount === 0 && (
                <Button
                  sx={{
                    textTransform: "none",
                    fontSize: "1rem",
                    paddingX: "1rem",
                    paddingTop: ".8rem",
                  }}
                  className="add-farm-button px-[26px] flex m-auto md:text-[18px]"
                  variant="contained"
                  color="secondary"
                  size="medium"
                  onClick={() => {
                    navigate("/add-farm");
                  }}
                  startIcon={
                    <AddCircleOutlinedIcon style={{ marginTop: "-3px" }} />
                  }
                >
                  {t("farms.buttonAddFarm")}
                </Button>
              )}
              {/*  registration farm with no house*/}

              {farmCount > 0 && (
                <div className="ml-4">
                  <p className="responsive-tablet-title text-[16px]">
                    {t("farms.feedIntakeTittle")}
                  </p>
                  <div className="flex justify-center items-center flex-col w-full  ">
                    {/* {
                                        houseCount === 0 && farmCount > 0 && <div className="my-[1rem]">
                                            <img src={NodataGraphIcon} alt="NodataGraphIcon" className="w-[50px] h-[50px]" />
                                            <h1 className="text-[14px] flex leading-6" >{t("farms.emptyTittle")} </h1>
                                        </div>
                                    } */}
                    {houseCount > 0 &&
                      farmCount > 0 &&
                      (feedIntake || []).length === 0 && (
                        <div className="my-[1rem] w-full flex justify-center items-center flex-col">
                          <img
                            src={NodataGraphIcon}
                            alt="NodataGraphIcon"
                            className="w-[50px] h-[50px]"
                          />
                          <h1 className="responsive-tablet-SecondTitle text-[14px] w-[65%] text-center text-[#C9C9C9]  leading-6">
                            {t("farms.emptyTittle1")}{" "}
                            <Link
                              className="responsive-tablet-SecondTitle text-[14px] font text-tertiary font-semibold "
                              to={"/daily-log"}
                            >
                              {" "}
                              {t("dailyLog.logHeader")}
                            </Link>{" "}
                          </h1>
                        </div>
                      )}
                    {houseCount >= 0 &&
                      farmCount > 0 &&
                      (feedIntake || []).length !== 0 && (
                        <div className="w-full py-[1rem]">
                          {<FeedIntakeGraph feedIntake={feedIntake} />}
                        </div>
                      )}

                    <div className="flex w-full gap-2 h-full">
                      <div className="flex rounded-md bg-[#EEF2FF]  px-0 py-2 text-start w-[35%]">
                        <div className="flex flex-col w-auto px-2 items-start gap-[4px] text-start ">
                          <p className="responsive-tablet-SecondTitle text-[12px] font-semibold text-tertiary whitespace ">
                            {" "}
                            {t("farms.deadPigText")}
                            <br />
                            {" (ตัว)"}
                          </p>
                          {deadPig !== "0" &&
                          deadPig !== "" &&
                          deadPig !== null ? (
                            <h1 className="farms-top-box-text text-[20px] font-medium ">
                              {" "}
                              {parseInt(deadPig).toLocaleString()}{" "}
                            </h1>
                          ) : (
                            <p className="farms-top-box-text text-[20px] py-2 font-bold text-[#34407B]">
                              {t("farms.farmsValue")}
                            </p>
                          )}
                        </div>
                        <div className="flex items-end justify-end">
                          <img
                            src={DeadPigIcon}
                            alt="DeadPigIcon"
                            className="sm:w-[29px] sm:h-[22px] md:w-[58px] md:h-[44px]"
                          />
                        </div>
                      </div>
                      <div className="flex  flex-col flex-nowrap bg-[#EEF2FF] self-stretch justify-center items-center mr-4 px-0 py-3 rounded-md w-[70%] ">
                        <div className="px-2">
                          <h1 className="responsive-tablet-text text-[12px] text-tertiary">
                            {" "}
                            {t("farms.incomeEstimateText")}{" "}
                            {t("farms.nearSaleText")} :{" "}
                            {t("farms.nearSaleValue")}{" "}
                          </h1>

                          <div className="flex gap-2 mt-1 ">
                            <span className="responsive-tablet-text rounded-2xl text-[12px] bg-[#3777BC] py-[2px] px-[4px] text-[#ffffff]  whitespace-nowrap">
                              {t("farms.defaultdate")}
                            </span>
                            <div>
                              <span className="responsive-tablet-text text-[12px] text-tertiary">
                                {t("farms.announcedPriceText")}{" "}
                                {t("farms.defaultcurrency")}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="flex space-auto justify-between w-full ">
                          <div className="flex gap-4 items-center">
                            <div>
                              <p className="responsive-tablet-SecondHeadTitle text-[20px] py-1 font-bold text-[#34407B]">
                                {t("farms.farmsValue")}
                              </p>
                            </div>
                            <div className="flex flex-col justify-center items-center ">
                              <img
                                src={ArrowDropIcon}
                                alt="ArrowDropIcon"
                                className="sm:w-[10px] sm:h-[5px] md:w-[20px] md:h-[10px]"
                              />
                              <h1 className="responsive-tablet-text text-[12px] font-semibold text-tertiary ">
                                {t("farms.bath")}
                              </h1>
                            </div>
                          </div>
                          <img
                            src={MoneyPocket}
                            alt="MoneyPocket"
                            className="sm:w-[29px] sm:h-[22px] md:w-[58px] md:h-[44px]"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* // */}
            {/*manage farms*/}
            <div className="mt-[50px] w-full bg-white-200 transform ">
              <RestOfFarmsModal
                farms={farmData}
                open={isAllFarmsOpen}
                onFarmClick={handleFarmClicked}
                handleClose={handleAllFarmsClosed}
              />
              <div
                className="flex justify-between"
                onClick={() => handleAllFarmsClicked()}
              >
                <h1 className="responsive-tablet-headTitle text-[20px] flex justify-start ml-4 text-[#34407B] font-semibold">
                  {t("farms.manageFarmTitle")}
                </h1>
                <div className="flex justify-around mr-[15px] items-center">
                  <h3
                    className={`${
                      farmData?.length > 3 ? "text-tertiary" : "text-disabled"
                    } farms-manage-text text-[17px] flex`}
                  >
                    {t("farms.allFarmsText")}{" "}
                  </h3>
                  <ChevronRightOutlinedIcon
                    color={farmData?.length > 3 ? "info" : "disabled"}
                  />
                </div>
              </div>
              <h1 className="responsive-tablet-SecondTitle flex text-[grey] text-[14px] ml-4 md:ml-8">
                {t("farms.manageFarmText")}
              </h1>
              <ManageFarm farmData={farmData} />
              {/* <button className="px-[26px] m-auto flex py-[10px] rounded-[40px] border border-green-500 mt-10 font-bold text-[#68C184]" onClick={() => navigate("/add-farm")}>{t("farms.buttonAddFarm")}</button> */}
              <Button
                sx={{
                  textTransform: "none",
                  fontSize: "1rem",
                  paddingX: "1rem",
                  paddingTop: ".8rem",
                }}
                className="add-farm-button px-[26px] flex m-auto md:!text-[18px]"
                variant="contained"
                color="secondary"
                size="medium"
                onClick={() => {
                  navigate("/add-farm");
                }}
                startIcon={
                  <AddCircleOutlinedIcon style={{ marginTop: "-3px" }} />
                }
              >
                {t("farms.buttonAddFarm")}
              </Button>
            </div>
            {/* // */}
            {/*Financial Support */}
            {(get(financialDetail, ["contracts"])?.length ?? 0) > 0 && (
              <div className="mt-[50px] w-full bg-white-200 transform ">
                <h1 className="responsive-tablet-headTitle text-[20px] flex justify-start ml-4 text-[#34407B] font-semibold">
                  {t("farms.financialSupportTitle")}
                </h1>
                <h1 className="farms-manage-text flex justify-start text-[grey] text-[14px] ml-4">
                  {t("farms.commonSubTitle")}
                </h1>
                <div className="relative w-full h-full pt-[16px]">
                  <img
                    src={FarmFrame}
                    alt="FarmFrame"
                    className="w-[100%] mx-auto px-[16px]"
                  />
                  <div className=" absolute top-[32%] left-[40%] md:left-[55%] flex flex-col items-end gap-1">
                    <div className="text-[#FFFF] font-normal w-full responsive-tablet-title">
                      ให้เรามีส่วนช่วยธุรกิจของคุณ
                    </div>
                    <Button
                      variant="outlined"
                      className="md:!text-[21px]"
                      sx={{
                        color: "#FFFFFF",
                        borderColor: "#FFFFFF",
                        border: "1px solid",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                      color="inherit"
                      onClick={() => {
                        navigate("/financial-support");
                      }}
                    >
                      ปรึกษาเรา
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {/* // */}
            {/*pig Price */}
            <div className="mt-[50px] w-full bg-white-200 transform pb-[35vw]">
              <h1 className="responsive-tablet-headTitle text-[20px] flex justify-start ml-4 text-[#34407B] font-semibold">
                {t("farms.pigPriceTitle")}
              </h1>
              <h1 className="farms-manage-text flex justify-start text-[grey] text-[14px] ml-4">
                {t("farms.commonSubTitle")}
              </h1>
              <PigPrice />
            </div>
            {/* // */}
          </div>
        </div>
      ) : (
        <RegisterFarms />
      )}
      <Footer handleChange={handleChange} selectedTab={1} />
      <AddFarmsModal
        handleModalClose={handleModalClose}
        addFarmModal={addFarmModal}
      />
    </div>
  );
};

export default Farms;

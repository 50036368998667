import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircleIcon from "@mui/icons-material/Circle";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import AppBarFS from "../../components/app-bar/AppBar";
import { StepperConnectorFS } from "../../components/common/Stepper";
import { getAllFarms } from "../../services/FarmService";
import { Farm } from "../../types";
import BasicDetails from "./basic-details";
import FeedingProgram from "./feeding-program";
import VaccineProgram from "./vaccine-program";
import FeedingContext from "./feeding-program/Provider/FeedingContext";

export default function AddHouse() {
  const { state } = useLocation();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [veccEdited, setveccEdited] = useState<boolean>(false);
  const [farms, setFarms] = useState<Farm[]>([]);
  const [edited, setEdited] = useState<boolean>(false);
  const [preSelectedFarm, setPreSelectedFarm] = useState<string>("");
  const {
    isEditFeed,
    isEditVaccine,
    setEditFeed,
    setEditVaccine,
    finalAddHouseData,
    setAddHouseData,
  } = useContext(FeedingContext);

  const { t } = useTranslation();

  const stepsData = [
    {
      //STEP 1: Basic Details
      id: "step-1-details",
      label: t("addHouse.stepTitleBasicDetails"),
      completed: false,
    },
    {
      //STEP 2: Feeding Program
      id: "step-2-feeding",
      label: t("addHouse.stepTitleFeedingProgram"),
      completed: false,
    },
    {
      //STEP 3: Vaccine Program
      id: "step-2-vaccine",
      label: t("addHouse.stepTitleVaccineProgram"),
      completed: false,
    },
  ];

  let navigate = useNavigate();
  const [steps, setSteps] = useState<any[]>(stepsData);

  useEffect(() => {
    if (state?.selectedFarm) {
      setPreSelectedFarm(state?.selectedFarm);
    }
    fetchFarms();
  }, []);

  useEffect(() => {
    if (isEditFeed) {
      setActiveStep(1);
      setEditFeed && setEditFeed(false);
    }
    if (isEditVaccine) {
      setActiveStep(2);
      setEditVaccine && setEditVaccine(false);
    }
  }, [isEditFeed, isEditVaccine]);

  const handleBack = (index: number) => {
    if (index < 0) {
      navigate("/farms");
    }
    if (steps[index]?.completed) {
      setActiveStep(index);
      setSteps((currentSteps: any[]) => {
        return currentSteps.map((step, indexStep: number) => {
          return {
            ...step,
            completed: indexStep < index,
          };
        });
      });
    }
  };

  const handleNext = (current: number) => {
    if (current === 0) {
      setEdited(true);
    }

    if (current === 1) {
      setveccEdited(true);
    }
    setActiveStep(current + 1);
    setSteps((currentSteps) => {
      currentSteps[current] = {
        ...currentSteps[current],
        completed: true,
      };
      return currentSteps;
    });
  };

  function handleBasicDetailSubmit(value: any) {
    if (value) {
      setAddHouseData &&
        setAddHouseData({ ...finalAddHouseData, basicDetails: value });
      if (value.fattenerPig || value.nurseryPig) {
        handleNext(0);
      } else {
        navigate("/program-overview");
      }
    }
  }

  function handleFeedingProgramSubmit(value: any) {
    !finalAddHouseData.feedDetails &&
      setAddHouseData &&
      setAddHouseData({ ...finalAddHouseData, feedDetails: value });
    handleNext(1);
  }

  async function fetchFarms() {
    try {
      const { data } = await getAllFarms();
      if (Array.isArray(data?.farms) && data.farms?.length) {
        setFarms(data.farms);
        if (!preSelectedFarm) {
          setPreSelectedFarm(data?.farms?.[0]?.id);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  const CustomStepIcon = (isComplete: boolean, isPending: boolean) => {
    // TODO : MOVE color code to one common themeing
    return isComplete ? (
      <FontAwesomeIcon
        color="secondary"
        className="text-secondary"
        size="lg"
        icon={faCircleCheck}
      />
    ) : (
      <CircleIcon
        fontSize="small"
        className="text-[#EBEBEB]"
        color={isPending ? "secondary" : undefined}
      />
    );
  };

  const mapComponentToStep = () => {
    if (activeStep === 0) {
      return (
        <BasicDetails
          farms={farms}
          selectedFarm={preSelectedFarm}
          basicDetailForm={finalAddHouseData.basicDetails}
          onSubmit={handleBasicDetailSubmit}
        />
      );
    } else if (activeStep === 1) {
      return (
        <FeedingProgram
          handleNext={handleNext}
          edited={edited}
          basicDetailForm={finalAddHouseData.basicDetails}
          handleFeedingProgramSubmit={handleFeedingProgramSubmit}
        />
      );
    } else if (activeStep === 2) {
      return (
        <VaccineProgram
          basicDetailForm={finalAddHouseData.basicDetails}
          veccEdited={veccEdited}
        />
      );
    }
  };

  return (
    <div className="p-4 h-full  flex flex-col justify-between w-full">
      <AppBarFS
        title={t("addHouse.appHeader")}
        onIconPress={() => handleBack(activeStep - 1)}
      ></AppBarFS>
      <div className="px-4 py-2 mt-8">
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<StepperConnectorFS />}
        >
          {steps?.map((step, index) => {
            return (
              <Step key={step?.id} completed={step?.completed} disabled={false}>
                <StepLabel
                  StepIconComponent={() =>
                    CustomStepIcon(step?.completed, index <= activeStep)
                  }
                >
                  {step?.label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      <div className="flex grow mt-4">{mapComponentToStep()}</div>
    </div>
  );
}

import React from "react";
import AccordionCreditCustom from "../../../components/AccordionCreditCustom";
import TableCreditCustom from "../../../components/TableCreditCustom";
import SegmentWeightInput from "../../../components/SegmentWeightInput";

type FarmFinancialAccordionProps = {
  viewAction: boolean;
};

const FarmFinancialAccordion = ({
  viewAction,
}: FarmFinancialAccordionProps) => {
  return (
    <AccordionCreditCustom
      title={"Farm Financial Status & Installment"}
      rowChildren={
        <>
          <SegmentWeightInput
            name={"farmFinancialStatusAndInstallment"}
            viewAction={viewAction}
          />
          <TableCreditCustom
            isDisplayAddRow={true}
            name={"farmFinancialStatusAndInstallment"}
            viewAction={viewAction}
          />
        </>
      }
    />
  );
};

export default FarmFinancialAccordion;

import { useQuery } from "@tanstack/react-query";
import { getSummaryAssignment } from "../../services/Invoice.service";
import {
  getBarChartInfo,
  getListRevenue,
  getProfilePartner,
  getSummaryCustomer,
  getSummaryRevenue,
} from "../../services/partner/PartnerLanding.service";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import {
  defaultFormFilterModalFinancialOverview,
  defaultFormFinancialOverview,
  TFormFilterModalFinancialOverview,
  TFormFinancialOverview,
} from "./defaultFormFinancialOverview";

export const useFinancialOverview = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const onChangeTab = useCallback((e: any, index: number) => {
    setTabIndex(index);
  }, []);

  const [formPartnerRevenue, setFormPartnerRevenue] = useState(
    defaultFormFinancialOverview
  );

  const onChangeFormPartnerRevenue = useCallback(
    (key: keyof TFormFinancialOverview) => (value: any) => {
      setFormPartnerRevenue((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const [formPartnerRevenueFilterModal, setFormPartnerRevenueFilterModal] =
    useState(defaultFormFilterModalFinancialOverview);

  const onChangeFormPartnerRevenueFilterModal = useCallback(
    (key: keyof TFormFilterModalFinancialOverview) => (value: any) => {
      setFormPartnerRevenueFilterModal((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const { data: GetProfilePartner } = useQuery({
    queryKey: ["profile-partner"],
    queryFn: async () => {
      const result = await getProfilePartner();
      return result;
    },
  });

  const { data: SummaryRevenue } = useQuery({
    queryKey: ["summary-revenue", formPartnerRevenue],
    queryFn: async () => {
      const monthValues = formPartnerRevenue.mount
        .map((item) => item.value)
        .join(",");

      const result = await getSummaryRevenue({
        christianYear: +formPartnerRevenue?.year[0]?.value || dayjs().year(),
        month: monthValues,
      });
      return result;
    },
  });

  const { data: ListRevenue } = useQuery({
    queryKey: ["list-revenue", formPartnerRevenueFilterModal],
    queryFn: async () => {
      const result = await getListRevenue({
        dateStart: formPartnerRevenueFilterModal.startDate
          ? dayjs(formPartnerRevenueFilterModal.startDate, "YYYY-MM-DD")
              .startOf("day")
              .toDate()
          : undefined,
        dateEnd: formPartnerRevenueFilterModal.endDate
          ? dayjs(formPartnerRevenueFilterModal.endDate, "YYYY-MM-DD")
              .startOf("day")
              .toDate()
          : undefined,
      });
      return result;
    },
  });

  const { data: BarChartInfo } = useQuery({
    queryKey: ["bar-chart-info", formPartnerRevenue],
    queryFn: async () => {
      const result = await getBarChartInfo({
        christianYear: +formPartnerRevenue?.year[0]?.value || dayjs().year(),
        revenueType:
          tabIndex === 1
            ? "onboard"
            : tabIndex === 2
            ? "order_feed"
            : tabIndex === 3
            ? "invoice"
            : "",
      });
      return result;
    },
    enabled: tabIndex !== 0,
  });

  return {
    GetProfilePartner,
    formPartnerRevenue,
    setFormPartnerRevenue,
    onChangeFormPartnerRevenue,
    formPartnerRevenueFilterModal,
    setFormPartnerRevenueFilterModal,
    onChangeFormPartnerRevenueFilterModal,
    SummaryRevenue,
    ListRevenue,
    BarChartInfo,
    tabIndex,
    onChangeTab,
  };
};

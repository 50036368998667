import { IRoleListResponse } from "../types/Role.type"
import adminAPI from "./AdminApiService"

export const getRoleListByType = async (type: 'partner' | 'farmTech') => {
  const { data } = await adminAPI.get<IRoleListResponse>(`/manage-role?usedBy=${type}`)
  return data
}

export const deleteRole = async (id: string) => {
  const { data } = await adminAPI.delete(`/manage-role/${id}`)
  return data
}

export const addRole = async (body: {
  usedBy: 'farmTech' | 'partner',
  name: string
}) => {
  const { data } = await adminAPI.post('/manage-role', body)
  return data
}

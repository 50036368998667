import React from "react";

import TableCustom, {
  ExpandableTableRow,
  ITableCustomHeadCell,
} from "../../../../components/Table/TableCustom";

import { Checkbox, Link, TableCell } from "@mui/material";
import dayjs from "dayjs";
import { useInvoiceSearchFilter } from "../../../../../hooks/useInvoiceSearchFilter.hook";
import {
  getListExportInvoiceLending,
  getOverDueInvoiceLending,
} from "../../../../../services/admin/invoice/invoiceLending.service";
import { IGetPendingLendingRow } from "../../../../../types/invoice/invoiceLending.type";
import { thousandComma } from "../../../../../utils/common/string";
import { makeXlsx } from "../../../../../utils/make-xlsx";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import ChipOrderStatus from "../../../../components/Chip/ChipOrderStatus";
import ExpandUpdateDocumentInvoice from "../../components/ExpandUpdateDocumentInvoice";
import { FilterInvoice } from "../../components/FilterInvoice";
import { IInvoiceTable, TInvoicePaymentStatus } from "../../type";
import PaymentModal from "../Modals/PaymentModal";
import DocumentModal from "../../../../components/Modal/DocumentModal/DocumentModal";
import { tw } from "../../../../../utils/tw";

type Props = { handleFetchCount: () => void };

const OverDueInvoicePanel = ({ handleFetchCount }: Props) => {
  const [itemSelected, setItemSelected] = React.useState<string[]>([]);
  const [orderInvoice, setOrderInvoice] = React.useState<IInvoiceTable[]>([]);
  const [count, setCount] = React.useState(0);

  const [invoiceLog, setInvoiceLog] = React.useState<IGetPendingLendingRow[]>(
    []
  );
  const [invoiceId, setInvoiceId] = React.useState("");
  const [isFullPaid, setIsFullPaid] = React.useState(false);
  const handleCloseModal = (isSuccess: boolean) => {
    setInvoiceId("");
    if (isSuccess) {
      handleFetch();
    }
  };

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [modalData, setModalData] = React.useState<IInvoiceTable>();

  function createData(
    id: string,
    orderId: string,
    customerId: string,
    customerName: string,
    invoiceNumber: string,
    amountToBePaid: number,
    totalPending: number,
    paidAmount: number,
    receiveSuccessDate: string,
    paymentDueDate: string,
    updateDate: string,
    status: TInvoicePaymentStatus
  ): IInvoiceTable {
    return {
      id,
      orderId: orderId ? orderId : "-",
      customerId: customerId ? customerId : "-",
      customerName: customerName ? customerName : "-",
      invoiceNumber: invoiceNumber ? invoiceNumber : "-",
      amountToBePaid: amountToBePaid
        ? `${thousandComma(amountToBePaid, 2)} บาท`
        : "-",
      totalPending: totalPending
        ? `${thousandComma(totalPending, 2)} บาท`
        : "-",
      paidAmount: paidAmount ? `${thousandComma(paidAmount, 2)} บาท` : "-",
      receiveSuccessDate: receiveSuccessDate
        ? dayjs(receiveSuccessDate).format("DD/MM/YYYY")
        : "-",
      paymentDueDate: paymentDueDate
        ? dayjs(paymentDueDate).format("DD/MM/YYYY")
        : "-",
      updateDate: updateDate ? dayjs(updateDate).format("DD/MM/YYYY") : "-",
      status: status as TInvoicePaymentStatus,
    };
  }

  const headCells: ITableCustomHeadCell<keyof IInvoiceTable>[] = [
    {
      id: "orderId",
      numeric: false,
      disablePadding: false,
      label: "หมายเลขสั่งซื้อ",
      width: "150px",
      isSorting: false,
    },
    {
      id: "customerId",
      numeric: false,
      disablePadding: false,
      label: "รหัสลูกค้า",
      width: "150px",
      isSorting: false,
    },
    {
      id: "customerName",
      numeric: false,
      disablePadding: false,
      label: "ชื่อลูกค้า",
      width: "150px",
      isSorting: false,
    },
    {
      id: "invoiceNumber",
      numeric: false,
      disablePadding: false,
      label: "เลข Invoice",
      width: "150px",
      isSorting: false,
    },
    {
      id: "amountToBePaid",
      numeric: false,
      disablePadding: false,
      label: "ยอดเงินที่ต้องชำระ",
      width: "150px",
      isSorting: false,
    },
    {
      id: "totalPending",
      numeric: false,
      disablePadding: false,
      label: "ยอดเงินที่รอตวจสอบ",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "paidAmount",
      numeric: false,
      disablePadding: false,
      label: "ยอดเงินที่ชำระเเล้ว",
      width: "150px",
      isSorting: false,
    },
    {
      id: "receiveSuccessDate",
      numeric: false,
      disablePadding: false,
      label: "วันรับสินค้าสำเร็จ",
      width: "150px",
      isSorting: true,
    },
    {
      id: "paymentDueDate",
      numeric: false,
      disablePadding: false,
      label: "วันครบกำหนดชำระ",
      width: "150px",
      isSorting: false,
    },
    {
      id: "updateDate",
      numeric: false,
      disablePadding: false,
      label: "วันที่อัปเดตล่าสุด",
      width: "150px",
      isSorting: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "สถานะ",
      width: "150px",
      isSorting: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "80px",
      isSorting: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "100px",
      isSorting: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "120px",
      isSorting: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "20px",
      isSorting: false,
    },
  ];

  const OrderStatusMap: Record<
    TInvoicePaymentStatus,
    { color: string; bgColor: string; label: string }
  > = {
    not_yet_due: {
      color: "",
      bgColor: "",
      label: "",
    },
    wait_for_check_balance: {
      color: "#F57C00",
      bgColor: "#FBCFA166",
      label: "รอตรวจสอบยอดเงิน",
    },
    overdue: {
      color: "#F57C00",
      bgColor: "#FBCFA166",
      label: "เลยกำหนดชำระ",
    },
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = orderInvoice.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };
  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const MapOrderByWithFilter: {
    [K in keyof IInvoiceTable]?: string;
  } = {
    receiveSuccessDate: "receive_date",
    updateDate: "updated_at",
  };

  const searchFilterInvoice = useInvoiceSearchFilter();

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("searchText", searchFilterInvoice.searchText);
    searchParams.append("page", searchFilterInvoice.page.toString());
    searchParams.append("limit", searchFilterInvoice.limit.toString());
    searchParams.append(
      "orderBy",
      MapOrderByWithFilter[searchFilterInvoice.orderBy] ?? ""
    );
    searchParams.append("direction", searchFilterInvoice.direction);
    setOrderInvoice([]);

    const data = await getOverDueInvoiceLending(searchParams);
    if (data?.rows) {
      const newList = data.rows.map((row: any) => {
        return createData(
          row.id,
          row.orderNumber,
          row.farmerRegistrationId,
          row.farmerName + " " + row.farmerSurname,
          row.invoiceNumber,
          row.totalPrice,
          row.totalPending,
          row.totalPaid,
          row.receiveDate,
          row.dueDate,
          row.updatedAt,
          row.paymentStatus as TInvoicePaymentStatus
        );
      });
      setCount(data.count);
      setOrderInvoice(newList);
      setInvoiceLog(data.rows.map((row) => row));
    }
    handleFetchCount();
  };

  React.useEffect(() => {
    handleFetch();
  }, [
    searchFilterInvoice.orderBy,
    searchFilterInvoice.direction,
    searchFilterInvoice.page,
  ]);

  const handleOpenModalDocument = (row: IInvoiceTable) => {
    setModalData(row);
    setIsOpen(true);
  };

  const handleCloseModalDocument = (isSuccess: boolean) => {
    setIsOpen(false);
    if (isSuccess) {
      handleFetch();
    }
  };

  const getExportExcelData = async () => {
    type TColumnName =
      | "หมายเลขสั่งซื้อ"
      | "รหัสลูกค้า"
      | "ชื่อลูกค้า"
      | "เลข Invoice"
      | "ยอดเงินที่ต้องชำระ"
      | "ยอดเงินที่รอตรวจสอบ"
      | "ยอดเงินที่ชำระเเล้ว"
      | "วันรับสินค้าสำเร็จ"
      | "วันครบกำหนดชำระ"
      | "วันที่อัปเดตล่าสุด"
      | "สถานะ";
    type TExcelData = {
      [k in TColumnName]: string | number;
    };
    const allSelectItemData = await getListExportInvoiceLending(itemSelected);
    if (allSelectItemData?.rows) {
      return allSelectItemData.rows
        .map<TExcelData>((row) => {
          return {
            หมายเลขสั่งซื้อ: row.orderNumber,
            รหัสลูกค้า: row.farmerRegistrationId,
            ชื่อลูกค้า: row.farmerName + " " + row.farmerSurname,
            "เลข Invoice": row.invoiceNumber,
            ยอดเงินที่ต้องชำระ: `${thousandComma(row.totalPrice, 2)} บาท`,
            ยอดเงินที่รอตรวจสอบ: `${thousandComma(row.totalPending, 2)} บาท`,
            ยอดเงินที่ชำระเเล้ว: `${thousandComma(row.totalPaid, 2)} บาท`,
            วันรับสินค้าสำเร็จ: dayjs(row.receiveDate).format("DD/MM/YYYY"),
            วันครบกำหนดชำระ: dayjs(row.dueDate).format("DD/MM/YYYY"),
            วันที่อัปเดตล่าสุด: dayjs(row.updatedAt).format("DD/MM/YYYY"),
            สถานะ: row.status as TInvoicePaymentStatus,
          };
        })
        .flat();
    }
    return [];
  };

  const handleExportExcel = async () => {
    const data = await getExportExcelData();
    const fileName = `overdue_invoice_lending_${dayjs().format(
      "YYYYMMDDHHmmss"
    )}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  return (
    <div className="w-full h-full pt-8">
      <FilterInvoice
        searchFilterInvoice={searchFilterInvoice}
        onSearch={handleFetch}
        placeholder={"ค้นหาโดยเลข invoice"}
        itemSelected={itemSelected}
        onExport={handleExportExcel}
      />
      <div className="flex w-full pt-6">
        <TableCustom
          order={searchFilterInvoice.direction}
          orderBy={searchFilterInvoice.orderBy}
          rowsPerPage={searchFilterInvoice.limit}
          rows={orderInvoice}
          itemsSelected={itemSelected}
          headerItems={headCells}
          page={1}
          onChangeOrderBy={(val) =>
            searchFilterInvoice.setOrderBy(val as keyof IInvoiceTable)
          }
          onChangeOrder={(val) => searchFilterInvoice.setDirection(val)}
          onChangePage={(val) => searchFilterInvoice.setPage(val)}
          onSelectAllClick={(val) => handleSelectAllClick(val)}
          onSelectItem={(val) => setItemSelected(val as string[])}
          totalItemSize={count}
          currentPageItems={orderInvoice.map((row) => row.id)}
          rowChildren={
            <>
              {orderInvoice.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <ExpandableTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.orderId}
                    selected={isItemSelected}
                    expandComponent={
                      <ExpandUpdateDocumentInvoice
                        key={row.id}
                        id={row.id}
                        colSpan={16}
                        invoiceLog={invoiceLog[index].invoiceLogs}
                      />
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(event) => handleClick(event, row.id)}
                        sx={{
                          borderWidth: "1px",
                          color: "#C9C9C9",
                          "&.Mui-checked": {
                            color: "#68C184",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        padding: "0px 16px",
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.orderId}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                          console.info("I'm a button.");
                        }}
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                          ":hover": {
                            fontSize: "16px",
                          },
                        }}
                      >
                        {row.customerId}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.customerName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.invoiceNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.amountToBePaid}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.totalPending}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.paidAmount}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.receiveSuccessDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.paymentDueDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.updateDate}
                    </TableCell>
                    <TableCell align="left">
                      <div className="flex flex-row w-full">
                        <ChipOrderStatus
                          bgColor={
                            OrderStatusMap[row.status as TInvoicePaymentStatus]
                              .bgColor
                          }
                          fontColor={
                            OrderStatusMap[row.status as TInvoicePaymentStatus]
                              .color
                          }
                          label={
                            OrderStatusMap[row.status as TInvoicePaymentStatus]
                              .label
                          }
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        onClick={() => handleOpenModalDocument(row)}
                        component="button"
                        variant="body2"
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                          ":hover": {
                            fontSize: "16px",
                          },
                        }}
                      >
                        ดูเอกสาร
                      </Link>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.status === "wait_for_check_balance" && (
                        <ButtonFillCustom
                          title={"จ่ายตรงยอด"}
                          onClick={() => {
                            setInvoiceId(row.id);
                            setIsFullPaid(true);
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.status === "wait_for_check_balance" && (
                        <ButtonFillCustom
                          btnBgColor="#D32F2F"
                          btnTextColor="#FFFFFF"
                          title={"จ่ายไม่ตรงยอด"}
                          onClick={() => {
                            setInvoiceId(row.id);
                            setIsFullPaid(false);
                          }}
                        />
                      )}
                    </TableCell>
                  </ExpandableTableRow>
                );
              })}
            </>
          }
        ></TableCustom>
      </div>
      <PaymentModal
        key={invoiceId}
        isOpen={!!invoiceId}
        invoiceId={invoiceId}
        handleClose={handleCloseModal}
        isFullPaid={isFullPaid}
      />
      <DocumentModal
        handleModalClose={handleCloseModalDocument}
        isOpen={isOpen}
        data={modalData}
      />
    </div>
  );
};

export default OverDueInvoicePanel;

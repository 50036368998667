export default function FireSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <mask
        id="mask0_5995_23259"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <rect y="0.0234375" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5995_23259)">
        <path
          d="M4 14.0234C4 12.2734 4.41667 10.7151 5.25 9.34844C6.08333 7.98177 7 6.83177 8 5.89844C9 4.9651 9.91667 4.2526 10.75 3.76094L12 3.02344V6.32344C12 6.9401 12.2083 7.4276 12.625 7.78594C13.0417 8.14427 13.5083 8.32344 14.025 8.32344C14.3083 8.32344 14.5792 8.2651 14.8375 8.14844C15.0958 8.03177 15.3333 7.8401 15.55 7.57344L16 7.02344C17.2 7.72344 18.1667 8.69427 18.9 9.93594C19.6333 11.1776 20 12.5401 20 14.0234C20 15.4901 19.6417 16.8276 18.925 18.0359C18.2083 19.2443 17.2667 20.1984 16.1 20.8984C16.3833 20.4984 16.6042 20.0609 16.7625 19.5859C16.9208 19.1109 17 18.6068 17 18.0734C17 17.4068 16.875 16.7776 16.625 16.1859C16.375 15.5943 16.0167 15.0651 15.55 14.5984L12 11.1234L8.475 14.5984C7.99167 15.0818 7.625 15.6151 7.375 16.1984C7.125 16.7818 7 17.4068 7 18.0734C7 18.6068 7.07917 19.1109 7.2375 19.5859C7.39583 20.0609 7.61667 20.4984 7.9 20.8984C6.73333 20.1984 5.79167 19.2443 5.075 18.0359C4.35833 16.8276 4 15.4901 4 14.0234ZM12 13.9234L14.125 15.9984C14.4083 16.2818 14.625 16.5984 14.775 16.9484C14.925 17.2984 15 17.6734 15 18.0734C15 18.8901 14.7083 19.5859 14.125 20.1609C13.5417 20.7359 12.8333 21.0234 12 21.0234C11.1667 21.0234 10.4583 20.7359 9.875 20.1609C9.29167 19.5859 9 18.8901 9 18.0734C9 17.6901 9.075 17.3193 9.225 16.9609C9.375 16.6026 9.59167 16.2818 9.875 15.9984L12 13.9234Z"
          fill="#F79836"
        />
      </g>
    </svg>
  );
}

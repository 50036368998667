import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Accordion,
  AccordionSummary,
  Dialog,
  DialogContent,
  IconButton,
  SvgIcon,
  Tab,
  Tabs,
} from "@mui/material";
import * as Y from "yup";
import { useDispatch } from "react-redux";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React from "react";
import dayjs from "dayjs";
import axios from "axios";
import {
  defaultFormForApprove,
  TFormForApprove,
} from "../credit-tab/TabProcessing/DefaultFormTabProcessing";
import { tw } from "../../../../../utils/tw";
import { AppDispatch } from "../../../../../redux/store";
import {
  approveFeed,
  getDownloadUrl,
  getUploadUrlCreateFeed,
} from "../../../../../services/CreditFeed.service";
import { handleDownloadBlob } from "../../../../../utils/downloadFile";
import useFormValidator from "../../../../../hooks/useFormValidator.hook";
import { FILTER_DATE_FORMAT } from "../../../sale-order/components/SaleOrderSearchFilter";
import DateRangePickerPopover from "../../../../components/Input/DateRangePickerPopover/DateRangePickerPopover";
import { alertAction } from "../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../redux/slices/loadingSlice";
import CrossIcon from "../../../../assets/svg/CrossIcon.svg";
import { Dropdown } from "../../../group-area/views/Dropdown";
import { Input } from "../../../group-area/views/Input";
import DragAndDropUploadImage from "../../../../components/Input/DragAndDropUploadImage";
import TextInputCustom from "../../../../components/Input/TextInputCustom";
import ListOfFile from "../../../../../components/common/File/ListOfFile";
import DatePickerPopover from "../../../../components/Input/DatePickerPopover/DatePickerPopover";
import { ICreditFeedData } from "../../../../../types/CreditFeed.type";
import DownloadLinkWithDelete from "../../../../../components/common/File/DownloadLinkWithDelete";
import { get } from "lodash";
import { CalendarSvg } from "../../../../assets/svg/Calendar.svg";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import { useMutation } from "@tanstack/react-query";
import { snakeActions } from "../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../utils/getErrorMessage";

interface FileBeforeUpload {
  fileName: string;
  fileSize: number;
  id: string;
}
interface IModalApproveCredit {
  isOpen: boolean;
  onClose: () => void;
  data?: ICreditFeedData;
  form: TFormForApprove;
  setForm: Dispatch<SetStateAction<TFormForApprove>>;
  onChangeForm: (key: keyof TFormForApprove) => (value: any) => void;
  refetchListCreditFeedTabProcessing: () => void;
  refetchListCreditFeedTabHistory: () => void;
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  row: tw(
    `w-full flex flex-col md:flex-row item-start justify-between gap-x-3 pt-6`
  ),
  row2: tw(
    `w-full flex flex-col md:flex-row item-start justify-between gap-x-3 pt-2`
  ),
  space: tw(`h-3`),
  space2: tw(`h-6`),
  container: tw(
    `w-[100vw] !max-w-[1311px] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  title2: tw(`text-[16px] font-bold text-[#3777BC]`),
  text: tw(`font-["IBM Plex Sans Thai"] font-bold text-[16px] leading-5`),
};

const errorSchema = Y.object({
  farmSize: Y.array().min(1, "กรุณาเลือกขนาดฟาร์ม"),
  financialAmount: Y.string().required("กรุณากรอกวงเงินลูกค้า"),
  contractType: Y.array().min(1, "กรุณาเลือกประเภทสัญญา"),
  timeCredit: Y.string().required("กรุณากรอกระยะเครดิต"),
});

const ModalApproveCredit = (props: IModalApproveCredit) => {
  const {
    isOpen,
    onClose,
    data,
    form,
    setForm,
    onChangeForm,
    refetchListCreditFeedTabProcessing,
    refetchListCreditFeedTabHistory,
  } = props;
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const dispatch = useDispatch<AppDispatch>();

  const handleDownload = useCallback(
    async (file: File) => {
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${file.name}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    },
    [form.creditCalculationDocuments, form.contractDocuments]
  );

  const handleDownloadByUrl = useCallback(
    (id: string, fileId: string, name: string) => async () => {
      const preSignURL = await getDownloadUrl(id, fileId);
      if (preSignURL) {
        const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
          responseType: "blob",
        });
        const objectURL = window.URL.createObjectURL(response.data);
        handleDownloadBlob(objectURL, name);
      }
    },
    [form.creditCalculationDocumentsUpload]
  );

  const refCalendarStartDate =
    React.useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);
  const handleSelectStartDate = (value?: Date) => {
    const formattedDate = dayjs(value).format(FILTER_DATE_FORMAT);
    setStartDate(formattedDate);
  };

  const refCalendarEndDate =
    React.useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);
  const handleSelectEndDate = (value?: Date) => {
    const formattedDate = dayjs(value).format(FILTER_DATE_FORMAT);
    setEndDate(formattedDate);
  };

  const approveFeedMutation = useMutation({
    mutationFn: approveFeed,
    onSuccess: async () => {
      refetchListCreditFeedTabProcessing();
      refetchListCreditFeedTabHistory();
      setTimeout(() => {
        dispatch(alertAction.hide());
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: "อัปเดตข้อมูลสำเร็จ",
            type: "success",
          })
        );
        onCloseFn();
      }, 500);
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(alertAction.hide());
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const { onSubmit, errors, resetError } = useFormValidator({
    form: {
      ...form,
    },
    errorSchema,
    onValidateSuccess: () => {
      dispatch(
        alertAction.showDialog({
          title: "ยืนยันการอัปเดตข้อมูล",
          text: "คุณต้องการอัปเดตข้อมูลรายการนี้ใช่หรือไม่",
          cancelText: "กลับไปแก้ไข",
          confirmText: "ยืนยัน",
          onCancel: () => {
            dispatch(alertAction.hide());
          },
          onSubmit: async () => {
            try {
              const fileArray = [];
              const fileArrayContract = [];
              dispatch(
                loadingActions.show({
                  message: "กำลังยืนยันการอัปเดตข้อมูล...",
                })
              );
              if (get(form, ["creditCalculationDocuments"]).length > 0) {
                // upload image
                const presign = await getUploadUrlCreateFeed(data?.id || "");
                await axios.put(
                  presign.blob.blobUrl,
                  get(form, ["creditCalculationDocuments", 0]),
                  {
                    responseType: "blob",
                    headers: {
                      "Content-Type": get(form, [
                        "creditCalculationDocuments",
                        0,
                      ]).type,
                      "x-ms-blob-type": "BlockBlob",
                    },
                  }
                );
                fileArray.push({
                  id: presign.blob.fileId,
                  fileName: get(form, ["creditCalculationDocuments", 0]).name,
                  fileSize: get(form, ["creditCalculationDocuments", 0]).size,
                });
              }
              if (get(form, ["contractDocuments"]).length > 0) {
                // upload image
                const presign = await getUploadUrlCreateFeed(data?.id || "");
                await axios.put(
                  presign.blob.blobUrl,
                  get(form, ["contractDocuments", 0]),
                  {
                    responseType: "blob",
                    headers: {
                      "Content-Type": get(form, ["contractDocuments", 0]).type,
                      "x-ms-blob-type": "BlockBlob",
                    },
                  }
                );
                fileArrayContract.push({
                  id: presign.blob.fileId,
                  fileName: get(form, ["contractDocuments", 0]).name,
                  fileSize: get(form, ["contractDocuments", 0]).size,
                });
              }
              approveFeedMutation.mutate({
                id: data?.id || "",
                creditInformation: {
                  farmSize: get(form, ["farmSize", 0, "value"], "-"),
                  houseId: data?.houseId || "",
                  creditAmount: +form.financialAmount,
                  customerGrade: data?.customerGrade || "",
                  contractType: get(form, ["contractType", 0, "value"], "-"),
                  creditPeriod: +form.timeCredit,
                  files:
                    form.creditCalculationDocuments.length === 0
                      ? get(data, ["creditInformation", "files"], [])
                      : fileArray,
                },
                expireDate: dayjs(endDate, FILTER_DATE_FORMAT).toDate(),
                startDate: dayjs(startDate, FILTER_DATE_FORMAT).toDate(),
                contractDocument:
                  form.contractDocuments.length === 0 ? [] : fileArrayContract,
              });
            } catch (error) {
              setTimeout(() => {
                dispatch(alertAction.hide());
                dispatch(loadingActions.hide());
                dispatch(
                  snakeActions.showMessage({
                    message: getErrorMessage(error),
                    type: "error",
                  })
                );
              }, 500);
            }
          },
        })
      );
    },
  });

  const onCloseFn = useCallback(() => {
    onClose();
    setTimeout(() => {
      resetError();
      setForm(defaultFormForApprove);
    }, 500);
  }, []);

  const contractMap = {
    pay_after_expire: "จ่ายหลังหมดสัญญา",
    fill_credit: "จ่ายตาม invoice",
  };

  useEffect(() => {
    if (isOpen && data) {
      setForm((prev) => ({
        ...prev,
        farmSize: [
          {
            label: data.creditInformation.farmSize.toUpperCase(),
            value: data.creditInformation.farmSize,
          },
        ],
        farmName: [{ label: data.farmName, value: data.farmId }],
        greenHouse: [
          {
            label: data.houseName,
            value: data.houseId,
            fattenerPigCount: `${data.fattenerPigCount}`,
          },
        ],
        pigType: "หมูขุน",
        countPig: `${data.fattenerPigCount}`,
        financialAmount: `${data.creditInformation.creditAmount}`,
        grade: data.customerGrade.toUpperCase(),
        contractType: [
          {
            label:
              contractMap[
                data.creditInformation.contractType as
                  | "pay_after_expire"
                  | "fill_credit"
              ],
            value: data.creditInformation.contractType,
          },
        ], // TODO: will fix later
        timeCredit: `${data.creditInformation.creditPeriod}`,
      }));
      //   setCustomerPickingSlip(
      //     get(data, ["fullData", "creditInformation", "files"], [])
      //   );
    }
  }, [isOpen, data]);

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: "w-full" }}
    >
      <DialogContent>
        <div className={classes.titleContainer}>
          <p className={classes.title}>อัปเดตข้อมูล</p>
          <IconButton onClick={onCloseFn}>
            <CrossIcon />
          </IconButton>
        </div>
        <div className={classes.title2}>ข้อมูลการให้เครดิต</div>
        <div className={classes.row}>
          <Dropdown
            label="ขนาดฟาร์ม"
            options={[
              { label: "S", value: "s" },
              { label: "M", value: "m" },
              { label: "L", value: "l" },
              { label: "XL", value: "xl" },
            ]}
            onChange={onChangeForm("farmSize")}
            renderValueArray={form.farmSize}
            errorMessage={errors.farmSize}
          />
          <Input
            label="ฟาร์ม"
            value={data?.farmName || ""}
            onChangeText={() => {}}
            placeholder=""
            disabled
          />
          <Input
            label="โรงเรือน"
            value={data?.houseName || ""}
            onChangeText={() => {}}
            placeholder=""
            disabled
          />
        </div>
        <div className={classes.row2}>
          <Input
            label="ประเภทหมู"
            value={form.pigType}
            onChangeText={onChangeForm("pigType")}
            disabled
          />
          <Input
            label="จำนวนหมู"
            value={form.countPig}
            onChangeText={onChangeForm("countPig")}
            placeholder=""
            disabled
          />
          <Input
            label="วงเงินลูกค้า"
            value={form.financialAmount}
            onChangeText={onChangeForm("financialAmount")}
            inputType="number"
            suffix="บาท"
            errorMessage={errors.financialAmount}
          />
        </div>
        <div className={classes.row2}>
          <Input
            label="เกรดลูกค้า"
            // options={[
            //   { label: "A", value: "a" },
            //   { label: "B", value: "b" },
            //   { label: "C", value: "c" },
            //   { label: "D", value: "d" },
            //   { label: "E", value: "e" },
            //   { label: "F", value: "f" },
            // ]}
            value={form.grade}
            onChangeText={onChangeForm("grade")}
            // renderValueArray={form.grade}
            disabled
            // errorMessage={errors.grade}
          />
          <Dropdown
            label="ประเภทสัญญา"
            options={[
              { label: "จ่ายหลังหมดสัญญา", value: "pay_after_expire" },
              { label: "จ่ายตาม invoice", value: "fill_credit" },
            ]}
            onChange={onChangeForm("contractType")}
            renderValueArray={form.contractType}
            errorMessage={errors.contractType}
          />
          <Input
            label="ระยะเครดิต"
            value={form.timeCredit}
            onChangeText={onChangeForm("timeCredit")}
            inputType="number"
            suffix="วัน"
            errorMessage={errors.timeCredit}
          />
        </div>
        <div className="h-[8px]"></div>
        <p className="font-normal text-[14px] mb-2">เอกสารการคำนวณเครดิต</p>
        {(form.creditCalculationDocumentsUpload.length || 0) > 0 ? (
          form.creditCalculationDocumentsUpload.map((row, idx) => {
            return (
              <DownloadLinkWithDelete
                onDownload={handleDownloadByUrl(
                  get(data, ["id"], ""),
                  row.id,
                  row.fileName
                )}
                onDelete={async () => {
                  onChangeForm("creditCalculationDocuments")([]);
                  onChangeForm("creditCalculationDocumentsUpload")([]);
                }}
                name={row.fileName}
              />
            );
          })
        ) : (
          <ListOfFile
            files={form.creditCalculationDocuments}
            onDownload={handleDownload}
            onDelete={async (idx) => {
              onChangeForm("creditCalculationDocuments")([]);
            }}
          />
        )}
        <DragAndDropUploadImage
          onUpload={(files) => {
            onChangeForm("creditCalculationDocuments")(files);
          }}
          type={{ "application/pdf": [".pdf"] }}
        />
        <div className={tw(classes.title2, "pt-10")}>ข้อมูลสัญญา</div>
        <div className="flex flex-row gap-[12px] pt-6">
          <div className="w-[50%]">
            <span className="font-normal text-[14px] mb-2">วันเริ่มสัญญา</span>
            <div className="pt-2">
              <TextInputCustom
                placeholder="เริ่มต้น"
                value={startDate}
                InputProps={{
                  endAdornment:
                    startDate !== "" ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "18px !important",
                        }}
                        onClick={() => {}}
                      />
                    ) : (
                      <SvgIcon
                        sx={{
                          fontSize: "24px !important",
                          color: "#646464",
                        }}
                        fontSize="small"
                      >
                        <CalendarSvg />
                      </SvgIcon>
                    ),
                }}
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  refCalendarStartDate.current?.onOpen(e);
                }}
              />
            </div>
          </div>
          <div className="w-[50%]">
            <span className="font-normal text-[14px] mb-2">
              วันสิ้นสุดสัญญา
            </span>
            <div className="pt-2">
              <TextInputCustom
                placeholder="สิ้นสุด"
                value={endDate}
                InputProps={{
                  endAdornment:
                    startDate !== "" ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "18px !important",
                        }}
                        onClick={() => {}}
                      />
                    ) : (
                      <SvgIcon
                        sx={{
                          fontSize: "24px !important",
                          color: "#646464",
                        }}
                        fontSize="small"
                      >
                        <CalendarSvg />
                      </SvgIcon>
                    ),
                }}
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  refCalendarEndDate.current?.onOpen(e);
                }}
              />
            </div>
          </div>
        </div>
        <p className="font-normal text-[14px] mb-2 pt-6">เอกสารสัญญา</p>
        <ListOfFile
          files={form.contractDocuments}
          onDownload={handleDownload}
          onDelete={async (idx) => {
            onChangeForm("contractDocuments")([]);
          }}
        />
        <DragAndDropUploadImage
          onUpload={(files) => {
            onChangeForm("contractDocuments")(files);
          }}
          type={{ "application/pdf": [".pdf"] }}
        />
        <div className={tw(classes.row, "justify-end")}>
          <ButtonFillCustom title={"ยืนยัน"} onClick={onSubmit} />
        </div>
      </DialogContent>
      <DatePickerPopover
        ref={refCalendarStartDate}
        id={"date-picker-start-date"}
        date={startDate}
        handleCalenderSave={handleSelectStartDate}
        minDate={dayjs().toDate()}
      />
      <DatePickerPopover
        ref={refCalendarEndDate}
        id={"date-picker-end-date"}
        date={endDate}
        handleCalenderSave={handleSelectEndDate}
        minDate={dayjs().toDate()}
      />
    </Dialog>
  );
};

export default memo(ModalApproveCredit);

import { Chip, ChipProps } from "@mui/material";
import React from "react";
import CircleIcon from "@mui/icons-material/Circle";

interface IChipOrderStatusProps extends ChipProps {
  label: string;
  fontColor: string;
  bgColor: string;
}

function ChipOrderStatus({
  label,
  fontColor,
  bgColor,
  ...rest
}: IChipOrderStatusProps) {
  return (
    <Chip
      {...rest}
      sx={{
        height: "25px",
        width: "fit-content",
        padding: "4px",
        bgcolor: bgColor,
        color: fontColor,
        fontFamily: "IBM Plex Sans Thai",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "18px",
        paddingBottom: "2px",
      }}
      icon={
        <CircleIcon
          sx={{
            color: "currentcolor !important",
            fontSize: "7px",
          }}
        />
      }
      label={label}
    />
  );
}

export default ChipOrderStatus;

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import { Avatar, Paper } from "@mui/material";
import dayjs from "dayjs";
import { TCreditLogDetails } from "../../../../../../types/CreditFeed.type";
import { tw } from "../../../../../../utils/tw";
import { useCallback, useState } from "react";
import ViewDetailsLog from "./ViewDetailsLog";

type Props = {
  logDetail: TCreditLogDetails;
  index: number;
  countIndex: number;
};

const classes = {
  header: tw("font-bold text-[#3777BC]"),
  label: tw(
    `text-secondary leading-[14px] tracking-[-5%] font-medium underline hover:brightness-110 cursor-pointer ml-2`
  ),
};

export enum LogType {
  sale_apply_offer = "อนุมัติยื่นการให้เครดิต",
  sale_lead_approve_offer = "อนุมัติจากหัวหน้าเซลล์",
  sale_lead_deny_offer = "ไม่อนุมัติจากหัวหน้าเซลล์",
  lending_approve_offer = "อนุมัติจาก Lending",
  lending_deny_offer = "ไม่อนุมัติจาก Lending",
  farmer_approve_offer = "ลูกค้ายอมรับการพิจารณาเครดิต",
  farmer_deny_offer = "ลูกค้าไม่ยอมรับการพิจารณาเครดิต",
  sale_forward_consideration = "อนุมัติคำขอจากเซลล์",
  sale_discard_consideration = "ไม่อนุมัติคำขอจากเซลล์",
  lending_confirm_consideration = "อนุมัติคำขอจาก Lending",
  lending_decline_consideration = "ไม่อนุมัติคำขอจาก Lending",
  sale_approve_consideration = "อนุมัติการให้เครดิตจากเซลล์",
  sale_deny_consideration = "ไม่อนุมัติการให้เครดิตจากเซลล์",
  sale_reapply_credit = "อนุมัติยื่นการให้เครดิตซ้ำ",
  sale_discard_credit = "ไม่อนุมัติยื่นการให้เครดิต",
}

const TimelineLog = ({ logDetail, index, countIndex }: Props) => {
  const [isOpenSeeDetails, setIsOpenSeeDetails] = useState(false);

  const onOpenSeeDetails = useCallback(() => setIsOpenSeeDetails(true), []);
  const onHideSeeDetails = useCallback(() => setIsOpenSeeDetails(false), []);

  const MapEnumLogType = (invoiceLog: TCreditLogDetails) => {
    switch (invoiceLog?.logType) {
      case "sale_apply_offer":
        return (
          <>
            <span className={classes.header}>อนุมัติ</span>
            {` ยื่นการให้เครดิต`}
          </>
        );
      case "sale_lead_approve_offer":
        return (
          <>
            <span className={classes.header}>อนุมัติ</span>
            {` การให้เครดิตจากหัวหน้าเซลล์`}
            <button onClick={onOpenSeeDetails} className={classes.label}>
              ดูรายละเอียด
            </button>
          </>
        );
      case "sale_lead_deny_offer":
        return (
          <>
            <span className={classes.header}>ไม่อนุมัติ</span>
            {` การให้เครดิตจากหัวหน้าเซลล์`}
            <button onClick={onOpenSeeDetails} className={classes.label}>
              ดูรายละเอียด
            </button>
          </>
        );
      case "lending_approve_offer":
        return (
          <>
            <span className={classes.header}>อนุมัติ</span>
            {` การให้เครดิตจาก Lending`}
            <button onClick={onOpenSeeDetails} className={classes.label}>
              ดูรายละเอียด
            </button>
          </>
        );
      case "lending_deny_offer":
        return (
          <>
            <span className={classes.header}>ไม่อนุมัติ</span>
            {` การให้เครดิตจาก Lending`}
            <button onClick={onOpenSeeDetails} className={classes.label}>
              ดูรายละเอียด
            </button>
          </>
        );
      case "farmer_approve_offer":
        return (
          <>
            <span className={classes.header}>ลูกค้ายอมรับ</span>
            {` การพิจารณาเครดิต`}
            <button className={classes.label}>ดูรายละเอียด</button>
          </>
        );
      case "farmer_deny_offer":
        return (
          <>
            <span className={classes.header}>ลูกค้าไม่ยอมรับ</span>
            {` การพิจารณาเครดิต`}
            <button className={classes.label}>ดูรายละเอียด</button>
          </>
        );
      case "sale_forward_consideration":
        return (
          <>
            <span className={classes.header}>อนุมัติ</span>
            {` คำขอจากเซลล์`}
            <button onClick={onOpenSeeDetails} className={classes.label}>
              ดูรายละเอียด
            </button>
          </>
        );
      case "sale_discard_consideration":
        return (
          <>
            <span className={classes.header}>ไม่อนุมัติ</span>
            {` คำขอจากเซลล์`}
            <button onClick={onOpenSeeDetails} className={classes.label}>
              ดูรายละเอียด
            </button>
          </>
        );
      case "lending_confirm_consideration":
        return (
          <>
            <span className={classes.header}>อนุมัติ</span>
            {` คำขอจาก Lending"`}
            <button onClick={onOpenSeeDetails} className={classes.label}>
              ดูรายละเอียด
            </button>
          </>
        );
      case "lending_decline_consideration":
        return (
          <>
            <span className={classes.header}>ไม่อนุมัติ</span>
            {` คำขอจาก Lending"`}
            <button onClick={onOpenSeeDetails} className={classes.label}>
              ดูรายละเอียด
            </button>
          </>
        );
      case "sale_approve_consideration":
        return (
          <>
            <span className={classes.header}>อนุมัติ</span>
            {` การให้เครดิตจากเซลล์`}
            <button onClick={onOpenSeeDetails} className={classes.label}>
              ดูรายละเอียด
            </button>
          </>
        );
      case "sale_deny_consideration":
        return (
          <>
            <span className={classes.header}>ไม่อนุมัติ</span>
            {` การให้เครดิตจากเซลล์`}
            <button onClick={onOpenSeeDetails} className={classes.label}>
              ดูรายละเอียด
            </button>
          </>
        );
      case "sale_reapply_credit":
        return (
          <>
            <span className={classes.header}>อนุมัติ</span>
            {` ยื่นการให้เครดิตซ้ำ`}
            <button onClick={onOpenSeeDetails} className={classes.label}>
              ดูรายละเอียด
            </button>
          </>
        );
      case "sale_discard_credit":
        return (
          <>
            <span className={classes.header}>ไม่อนุมัติ</span>
            {` ยื่นการให้เครดิตซ้ำ`}
            <button onClick={onOpenSeeDetails} className={classes.label}>
              ดูรายละเอียด
            </button>
          </>
        );
      default:
        return "-";
    }
  };

  return (
    <div className="py-2">
      <Timeline
        sx={{
          padding: "2px 12px",
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        <TimelineItem key={`item-detail-${index + 1}`}>
          <TimelineSeparator>
            <TimelineDot
              sx={{
                bgcolor: "#3777BC",
                boxShadow: "unset",
              }}
            />
            {countIndex - 1 > index && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <div className="flex">
              <div className="w-[100px]">
                <span className="text-[14px] font-normal text-[#1B2128] font-sans ">
                  {dayjs(logDetail.createdAt).format("hh:mm A")}
                </span>
              </div>
              <div>
                <Paper
                  elevation={0}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    bgcolor: "#FBFBFB",
                    border: "1px solid #EBEBEB",
                    width: "800px",
                    height: "100%",
                    borderRadius: "20px",
                    padding: "12px",
                  }}
                >
                  <Avatar
                    alt="user-avatar"
                    src="https://i.pravatar.cc/300"
                    sx={{
                      width: "25px",
                      height: "25px",
                      border: "1px solid #D9D9D9",
                    }}
                  />
                  <span className="font-bold font-sans text-[1rem] text-[#1B2128]">
                    {logDetail.logType === "farmer_approve_offer" ||
                    logDetail.logType === "farmer_deny_offer"
                      ? logDetail.farmer?.name
                      : logDetail.user?.name}
                  </span>
                  <span className="font-bold font-sans text-[1rem] text-[#1B2128]">
                    {logDetail.logType === "farmer_approve_offer" ||
                    logDetail.logType === "farmer_deny_offer"
                      ? logDetail.farmer?.surname
                      : logDetail.user?.surname}
                  </span>
                  <span>{MapEnumLogType(logDetail)}</span>
                </Paper>
              </div>
            </div>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
      <ViewDetailsLog
        isOpen={isOpenSeeDetails}
        onClose={onHideSeeDetails}
        dataLog={logDetail}
      />
    </div>
  );
};

export default TimelineLog;

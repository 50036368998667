export function DocumentTextIcon() {
  return (
    <svg
      width="16"
      height="21"
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.799805 3.30002C0.799805 1.97454 1.87432 0.900024 3.1998 0.900024H8.70275C9.33927 0.900024 9.94972 1.15288 10.3998 1.60297L14.4969 5.70002C14.9469 6.15011 15.1998 6.76056 15.1998 7.39708V17.7C15.1998 19.0255 14.1253 20.1 12.7998 20.1H3.1998C1.87432 20.1 0.799805 19.0255 0.799805 17.7V3.30002ZM3.1998 10.5C3.1998 9.83728 3.73706 9.30002 4.3998 9.30002H11.5998C12.2625 9.30002 12.7998 9.83728 12.7998 10.5C12.7998 11.1628 12.2625 11.7 11.5998 11.7H4.3998C3.73706 11.7 3.1998 11.1628 3.1998 10.5ZM4.3998 14.1C3.73706 14.1 3.1998 14.6373 3.1998 15.3C3.1998 15.9628 3.73706 16.5 4.3998 16.5H11.5998C12.2625 16.5 12.7998 15.9628 12.7998 15.3C12.7998 14.6373 12.2625 14.1 11.5998 14.1H4.3998Z"
        fill="#68C184"
      />
    </svg>
  );
}

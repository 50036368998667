import { useCallback, useState } from "react";

export const useEditAccountModalController = () => {
  const [isShowPinConfirm, setIsShowPinConfirm] = useState(false);

  const onShowPinConfirm = useCallback(() => {
    setIsShowPinConfirm(true);
  }, []);

  const onHidePinConfirm = useCallback(() => {
    setIsShowPinConfirm(false);
  }, []);

  return {
    isShowPinConfirm,
    onShowPinConfirm,
    onHidePinConfirm,
  };
};

export default function ReportGoodPigSVG() {
  return (
    <svg
      width="86"
      height="76"
      viewBox="0 0 96 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M65.2779 14.1398C67.9625 11.2638 71.7824 9.64601 75.6557 8.92701C78.8478 8.3345 82.2871 7.975 85.4525 8.93367C88.6179 9.89234 88.0636 12.795 87.7564 15.7775C87.102 22.182 84.0835 28.3734 79.2619 32.6874"
        fill="#EF9AB5"
      />
      <path
        d="M30.7189 14.14C28.0343 11.2707 24.2144 9.64626 20.3411 8.92726C17.149 8.3414 13.7097 7.97524 10.5443 8.93392C7.37886 9.89259 7.93982 12.8019 8.24034 15.7778C8.8948 22.1822 11.9133 28.3736 16.7349 32.6877"
        fill="#EF9AB5"
      />
      <g style={{ mixBlendMode: "multiply" }} opacity="0.66">
        <path
          d="M68.6306 18.1808C70.1398 16.563 72.2902 15.6509 74.4739 15.2448C76.2704 14.912 78.207 14.7056 79.9901 15.2448C81.7731 15.7841 81.4592 17.4218 81.2856 19.0995C80.9183 22.7078 79.2154 26.1897 76.5041 28.6196"
          fill="#E5ACB5"
        />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.66">
        <path
          d="M27.3661 18.1808C25.8568 16.563 23.7065 15.6509 21.5227 15.2448C19.7263 14.912 17.7897 14.7056 16.0066 15.2448C14.2236 15.7841 14.5374 17.4218 14.7111 19.0995C15.0784 22.7078 16.7813 26.1897 19.4926 28.6196"
          fill="#E5ACB5"
        />
      </g>
      <path
        d="M84.6384 51.741C84.1442 48.4256 82.8286 45.2633 82.4546 41.9346C82.1207 38.9587 82.8553 35.8497 82.0807 32.9603C81.493 30.7834 80.2375 28.2802 79.2024 26.263C76.7916 21.5695 73.1587 17.4552 68.7979 14.4726C63.5222 10.8643 56.4701 9.49952 49.3913 9.69259C42.3125 9.49952 35.2604 10.8643 29.9847 14.4726C25.6238 17.4552 21.9909 21.5695 19.5801 26.263C18.545 28.2802 17.2895 30.7834 16.7019 32.9603C15.9272 35.8497 16.6618 38.9587 16.3279 41.9346C15.9539 45.2633 14.6383 48.4256 14.1442 51.741C11.4328 69.9091 29.2167 76.6331 46.8135 77.5386V77.6451C47.6683 77.6517 48.5298 77.6451 49.3979 77.6318C50.2594 77.6517 51.1276 77.6584 51.9824 77.6451V77.5386C69.5792 76.6331 87.363 69.9025 84.6517 51.741H84.6384Z"
        fill="#FBABC2"
      />
      <path
        d="M46.0654 56.2614C47.3876 55.822 48.7566 55.549 50.1257 55.802C51.7484 56.1016 53.3979 56.4544 55.0474 56.5876C59.1211 56.9071 63.0812 53.7648 63.7023 49.797C64.2766 46.1221 62.7139 42.1276 60.3499 39.3448C59.8089 38.7124 59.2079 38.1199 58.5401 37.6205C54.206 34.385 48.169 33.3531 42.9934 34.9176C37.4706 36.582 32.7959 41.888 32.856 47.8331C32.8894 51.1418 34.7727 54.5171 37.8446 55.7621C39.8681 56.5809 42.2855 56.8139 44.4559 56.6342C45.0169 56.5876 45.5445 56.4411 46.072 56.268L46.0654 56.2614Z"
        fill="#EF819F"
      />
      <path
        d="M30.5783 41.5017C34.0242 41.5017 34.0242 36.1758 30.5783 36.1758C27.1324 36.1758 27.1324 41.5017 30.5783 41.5017Z"
        fill="#BA5D6F"
      />
      <path
        d="M65.7989 41.5017C69.2448 41.5017 69.2448 36.1758 65.7989 36.1758C62.353 36.1758 62.353 41.5017 65.7989 41.5017Z"
        fill="#BA5D6F"
      />
      <path
        d="M42.6729 50.1567C46.5462 50.1567 46.5462 44.165 42.6729 44.165C38.7996 44.165 38.7996 50.1567 42.6729 50.1567Z"
        fill="#BA5D6F"
      />
      <path
        d="M53.9852 50.1567C57.8585 50.1567 57.8585 44.165 53.9852 44.165C50.1119 44.165 50.1119 50.1567 53.9852 50.1567Z"
        fill="#BA5D6F"
      />
      <g style={{ mixBlendMode: "multiply" }} opacity="0.22">
        <path
          d="M28.5548 54.7301C30.8722 52.5598 30.6518 48.319 27.6333 46.8477C25.0288 45.5828 20.1872 46.3884 18.7447 49.0447C18.0435 50.3362 18.0702 51.9606 18.718 53.2788C19.3658 54.597 20.5812 55.6023 21.9635 56.0949C24.1874 56.8938 26.8386 56.3412 28.5615 54.7301H28.5548Z"
          fill="#E5ACB5"
        />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.22">
        <path
          d="M68.3298 54.7301C66.0125 52.5598 66.2329 48.319 69.2514 46.8477C71.8559 45.5828 76.6975 46.3884 78.14 49.0447C78.8412 50.3362 78.8145 51.9606 78.1667 53.2788C77.5189 54.597 76.3035 55.6023 74.9211 56.0949C72.6973 56.8938 70.0461 56.3412 68.3232 54.7301H68.3298Z"
          fill="#E5ACB5"
        />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.09">
        <path
          d="M84.638 51.7409C84.1439 48.4255 82.8283 45.2632 82.4543 41.9345C82.1204 38.9586 82.855 35.8496 82.0803 32.9603C81.4926 30.7833 80.2372 28.2801 79.202 26.2629C76.7912 21.5694 73.1584 17.4551 68.7975 14.4726C66.0061 12.5619 62.7071 11.297 59.1744 10.5447C64.971 14.5791 69.1248 21.7225 71.7626 27.8008C73.0315 30.73 73.5791 33.4929 73.4522 36.6951C73.352 39.2249 74.1734 40.9625 74.7477 43.3592C75.9832 48.5387 76.07 55.1828 73.4522 59.9762C68.9511 68.2048 59.0141 72.1593 50.0253 73.2178C43.9215 74.5959 37.3903 74.8489 31.1931 74.8888C36.0213 76.4267 41.4173 77.2655 46.8065 77.5452V77.6517C47.6613 77.6583 48.5228 77.6517 49.3909 77.6384C50.2524 77.6583 51.1206 77.665 51.9754 77.6517V77.5452C69.5722 76.6397 87.356 69.9091 84.6447 51.7476L84.638 51.7409Z"
          fill="#E5ACB5"
        />
      </g>
      <g style={{ mixBlendMode: "multiply" }} opacity="0.09">
        <path
          d="M60.343 39.3449C59.8822 38.8056 59.368 38.313 58.8203 37.8669C59.034 38.3463 59.221 38.8389 59.3479 39.3582C60.7236 44.7308 58.4998 49.4908 54.3594 52.9926C53.184 53.9913 51.8818 54.9899 50.4927 55.8753C51.9953 56.1549 53.5112 56.4678 55.0405 56.5877C59.1142 56.9072 63.0743 53.7649 63.6954 49.7971C64.2697 46.1222 62.707 42.1277 60.343 39.3449Z"
          fill="#E5ACB5"
        />
      </g>
      <path
        d="M48.4408 69.8663C54.2603 69.8663 59.0768 65.9351 59.867 60.8301C59.99 60.0447 59.3229 59.3413 58.4523 59.3413H38.4293C37.5588 59.3413 36.8916 60.0447 37.0147 60.8301C37.8095 65.9394 42.6213 69.8663 48.4408 69.8663Z"
        fill="#BA5D6F"
      />
      <mask
        id="mask0_2452_36913"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="37"
        y="59"
        width="23"
        height="11"
      >
        <path
          d="M48.4408 69.8663C54.2603 69.8663 59.0768 65.9351 59.867 60.8301C59.99 60.0447 59.3229 59.3413 58.4523 59.3413H38.4293C37.5588 59.3413 36.8916 60.0447 37.0147 60.8301C37.8095 65.9394 42.6213 69.8663 48.4408 69.8663Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2452_36913)">
        <path
          d="M50.6267 75.8665C53.5757 74.7623 54.985 71.6866 53.7742 68.9969C52.5635 66.3071 49.1914 65.0218 46.2423 66.1261C43.2933 67.2303 41.8841 70.306 43.0948 72.9957C44.3055 75.6854 47.6776 76.9707 50.6267 75.8665Z"
          fill="#ED7D9B"
        />
      </g>
    </svg>
  );
}

import { CircularProgress } from "@mui/material";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  ADMIN_AUTH_REFRESH_TOKEN_KEY,
  ADMIN_AUTH_TOKEN_KEY,
} from "../../../../const/app.constant";
import { setItem } from "../../../../services/StorageService";
import { FarmSookSVG } from "../../../assets/svg/FarmSook.svg";
import { UnAuthorizedSVG } from "../../../assets/svg/Unauthorized.svg";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";

const AzureCallBackPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const adminAuthToken = searchParams.get("adminAuthToken") as string;
  const refreshToken = searchParams.get("refreshToken") as string;
  const [isAuthenticate, setIsAuthenticate] = useState(true);
  const handleLogin = useCallback(async () => {
    try {
      setItem(ADMIN_AUTH_TOKEN_KEY, adminAuthToken);
      setItem(ADMIN_AUTH_REFRESH_TOKEN_KEY, refreshToken);
      navigate("/admin/home");
    } catch (error: any) {
      setIsAuthenticate(false);
      console.log("error admin login azure:", error);
      // TODO: will handle 401 page later
      navigate("/admin/login");
    }
  }, [adminAuthToken, refreshToken]);

  useEffect(() => {
    if (!isEmpty(adminAuthToken) && !isEmpty(refreshToken)) {
      handleLogin();
    } else {
      setIsAuthenticate(false);
    }
  }, [adminAuthToken, refreshToken]);

  const handleNavigateToLogin = useCallback(() => {
    navigate("/admin/login");
  }, []);

  return (
    <>
      {isAuthenticate ? (
        <>
          <div className="flex flex-col w-full h-screen justify-center items-center m-auto">
            <CircularProgress color="secondary" size={36} />
          </div>
        </>
      ) : (
        <>
          <div className="absolute top-0 left-0 p-3">
            <FarmSookSVG sizeMultiplier={8} />
          </div>
          <div className="flex flex-col w-full h-screen justify-center items-center m-auto gap-5">
            <UnAuthorizedSVG />
            <div className="flex flex-col justify-center gap-1 text-center">
              <p className="font-bold text-4xl">Unauthorized</p>
              <p className="text-[#777777]">
                You don't have access to this system. <br />
                Please contact your supervisor to add user.
              </p>
            </div>
            <ButtonFillCustom
              title="Back to Login Page"
              onClick={handleNavigateToLogin}
            />
          </div>
        </>
      )}
    </>
  );
};

export default AzureCallBackPage;

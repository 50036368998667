import React from "react";
import { useTranslation } from "react-i18next";
import NoticeLogo from "../../../assets/images/Farms/Notice-logo.svg";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router";
import PopupImage from "../../../assets/images/FarmRegistration/popup_bg.png";
import CustomButton from "../Button";

export interface PopupType {
  onCancel: () => void;
  onClick: () => void;
}
const EditHouseNoticePopup = ({
  onClick,
  onCancel,
  isWithinMonth,
  handleEditHouseNavigate,
}: PopupType | any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      className="flex bg-bottom bg-no-repeat"
      style={{ backgroundImage: `url(${PopupImage})` }}
    >
      <div className="p-3 h-full flex flex-col justify-between w-full">
        <div className="flex flex-col items-end">
          <ClearIcon
            className="w-[30px] h-[30px] mr-2 mt-2"
            onClick={onCancel}
          />
        </div>
        <div className="flex flex-col" style={{ gap: " calc(100vh - 610px)" }}>
          <div className="flex flex-col grow mt-10 items-center">
            <img
              src={NoticeLogo}
              className="mb-[32px] w-[100%] max-w-[130px]"
              alt="Notice-Logo"
            />
            <div className="flex flex-col items-center">
              <h3 className="font-medium text-[24px] text-justify text-[#F57C00]">
                {t("editHouse.canEditHouseTitle")}
              </h3>
              <h4 className="py-2 text-center text-[#646464] font-normal px-[12%]">
                {t("editHouse.editHousePopupMessage")}
              </h4>
              <h4 className="py-2 text-center text-[#646464] font-normal px-[12%]">
                {isWithinMonth
                  ? t("editHouse.canEditHousetext")
                  : t("editHouse.cannotEditHouseText")}
              </h4>
              {!isWithinMonth && (
                <a href="tel:+999-9999" className="text-[#37A1DB] underline">
                  999-9999
                </a>
              )}
            </div>
          </div>
          <div>
            <div className="flex items-center justify-center translate-y-[75px]">
              <CustomButton
                variant="tertiary"
                className="max-w-[300px]"
                label={
                  isWithinMonth
                    ? t("editHouse.IunderstoodButtonText")
                    : t("editHouse.closeButtonText")
                }
                onClick={() =>
                  isWithinMonth ? handleEditHouseNavigate() : onCancel()
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditHouseNoticePopup;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ISnakeProps {
  isVisible: boolean;
  showMessage: (message: string, type: "success" | "error") => void;
  type: "success" | "error";
  message: string;
  horizontalAnchor?: "left" | "right" | "center";
}

const initialState: ISnakeProps = {
  isVisible: false,
  type: "success",
  message: "",
  showMessage: () => {},
};

const snakeSlice = createSlice({
  name: "snake",
  initialState,
  reducers: {
    showMessage: (
      state,
      action: PayloadAction<{
        message: string;
        type: "success" | "error";
        horizontalAnchor?: "left" | "right" | "center";
      }>
    ) => {
      const { message, type, horizontalAnchor } = action.payload;
      state.isVisible = true;
      state.message = message;
      state.type = type;
      state.horizontalAnchor = horizontalAnchor || "right";
    },
    hide: (state) => {
      state.isVisible = false;
    },
  },
});

export const snakeActions = snakeSlice.actions;

export default snakeSlice;

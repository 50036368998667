export function AddressIcon() {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7649 8.50206L19.2786 4.11258C19.2555 4.06724 19.2185 4.03549 19.1723 4.01282L11.4315 0.258182C11.3621 0.226439 11.2789 0.226439 11.2096 0.258182L3.4688 3.92213C3.42721 3.94027 3.39023 3.97201 3.36713 4.01282L0.501867 8.41137C0.395575 8.57008 0.511109 8.78321 0.705208 8.78774L1.43539 8.81041C1.56941 8.81041 1.6757 8.92378 1.6757 9.05528V18.1879C1.6757 18.9407 2.29497 19.5483 3.06212 19.5483H19.3109C20.0781 19.5483 20.6973 18.9407 20.6973 18.1879V9.11423C20.6973 8.97819 20.8083 8.86936 20.9469 8.86936H21.5431C21.7325 8.86936 21.8527 8.66984 21.7603 8.50206H21.7649ZM11.1172 4.67034C11.8705 4.67034 12.4805 5.2689 12.4805 6.00804C12.4805 6.74718 11.8705 7.34574 11.1172 7.34574C10.3639 7.34574 9.75389 6.74718 9.75389 6.00804C9.75389 5.2689 10.3639 4.67034 11.1172 4.67034ZM16.6998 15.5397C16.6998 16.2653 16.0991 16.8548 15.3596 16.8548H6.87476C6.13534 16.8548 5.53456 16.2653 5.53456 15.5397V12.6059C5.53456 11.8803 6.13534 11.2908 6.87476 11.2908H15.355C16.0944 11.2908 16.6952 11.8803 16.6952 12.6059V15.5397H16.6998Z"
        fill="#68C184"
      />
    </svg>
  );
}

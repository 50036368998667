export default function UserSVG() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_5932_22244"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="25"
      >
        <rect x="0.538574" y="0.269531" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5932_22244)">
        <path
          d="M12.5386 12.2695C11.4386 12.2695 10.4969 11.8779 9.71357 11.0945C8.93024 10.3112 8.53857 9.36953 8.53857 8.26953C8.53857 7.16953 8.93024 6.22786 9.71357 5.44453C10.4969 4.6612 11.4386 4.26953 12.5386 4.26953C13.6386 4.26953 14.5802 4.6612 15.3636 5.44453C16.1469 6.22786 16.5386 7.16953 16.5386 8.26953C16.5386 9.36953 16.1469 10.3112 15.3636 11.0945C14.5802 11.8779 13.6386 12.2695 12.5386 12.2695ZM4.53857 20.2695V17.4695C4.53857 16.9029 4.68441 16.382 4.97607 15.907C5.26774 15.432 5.65524 15.0695 6.13857 14.8195C7.17191 14.3029 8.22191 13.9154 9.28857 13.657C10.3552 13.3987 11.4386 13.2695 12.5386 13.2695C13.6386 13.2695 14.7219 13.3987 15.7886 13.657C16.8552 13.9154 17.9052 14.3029 18.9386 14.8195C19.4219 15.0695 19.8094 15.432 20.1011 15.907C20.3927 16.382 20.5386 16.9029 20.5386 17.4695V20.2695H4.53857Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

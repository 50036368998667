import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { FormEvent, useEffect, useState } from "react";
import { useShowButtonByRoles } from "../../../../hooks/useActionButtonByRoles";
import { useSnackBar } from "../../../../provider/SnackBarProvider";
import { updateStatusFarmer } from "../../../../services/admin/user-management/customer.service";
import { updateStatusEmployee } from "../../../../services/admin/user-management/employee.service";
import { updateStatusPartner } from "../../../../services/admin/user-management/partner.service";
import { IUpdateStatusEmployeeForm } from "../../../../types/admin/user-management/employee";
import { UserRole } from "../../../../utils/enum/user-role.enum";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import TextInputSearchCustom from "../../../components/Input/TextInputSearchCustom";
import ConfirmCustomModal from "../../../components/Modal/ConfirmCustomModal";

type SearchPartnerProps = {
  searchText: string;
  setSearchText: (value: string) => void;
  onSearch: () => void;
  itemSelected: string[];
  placeholder: string;
  feature: string;
  onClickChangeStatus: () => void;
};

const UserManagementSearch = ({
  searchText,
  setSearchText,
  onSearch,
  itemSelected,
  placeholder,
  feature,
  onClickChangeStatus,
}: SearchPartnerProps) => {
  const { showSnackBar } = useSnackBar();
  const [isOpenDisableModal, setIsOpenDisableModal] = useState(false);
  const [isOpenEnableModal, setIsOpenEnableModal] = useState(false);
  const [toggleClear, setToggleClear] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (onSearch) {
      onSearch();
    }
  };

  useEffect(() => {
    onSearch();
  }, [toggleClear]);

  const handlePostAPI = async (payload: IUpdateStatusEmployeeForm) => {
    if (feature === "partner") {
      await updateStatusPartner(payload);
    }
    if (feature === "employee") {
      await updateStatusEmployee(payload);
    }
    if (feature === "customer") {
      await updateStatusFarmer(payload);
    }
  };

  const { showButton } = useShowButtonByRoles([
    UserRole.Admin,
    UserRole.Management,
  ]);

  return (
    <>
      <div className="flex justify-between">
        <div className="flex gap-4">
          <form className="w-[400px]" onSubmit={handleSubmit}>
            <TextInputSearchCustom
              fullWidth
              value={searchText}
              placeholder={placeholder}
              onChange={(event) => setSearchText(event.target.value)}
              InputProps={{
                endAdornment:
                  searchText.length > 0 ? (
                    <CloseRoundedIcon
                      sx={{ cursor: "pointer" }}
                      onClick={(_) => {
                        setSearchText("");
                        setToggleClear((prev) => !prev);
                      }}
                    />
                  ) : null,
              }}
            />
          </form>
          {itemSelected.length > 0 && (
            <div className="max-w-[170px] flex items-center font-sans text-[14px] text-[#646464]">{`เลือกไว้ ${itemSelected.length} รายการ`}</div>
          )}
        </div>
        {itemSelected.length > 0 && showButton && (
          <div className="flex gap-3">
            <ButtonFillCustom
              title={"ปิดใช้งาน"}
              btnBgColor="#D32F2F"
              onClick={() => setIsOpenDisableModal(true)}
            />
            <ButtonFillCustom
              title={"เปิดใช้งาน"}
              onClick={() => setIsOpenEnableModal(true)}
            />
          </div>
        )}
      </div>
      <ConfirmCustomModal
        title="ยืนยันการปิดใช้งาน"
        subtitleFirstRow="คุณต้องการอนุมัติรายการนี้ใช่หรือไม่?"
        subtitleSecondRow="การดำเนินการนี้ไม่สามารถยกเลิกได้?"
        buttonPrimaryText="ยืนยัน"
        buttonSecondaryText="ยกเลิก"
        open={isOpenDisableModal}
        onClose={() => setIsOpenDisableModal(false)}
        onSubmit={async () => {
          const payloadDisable: IUpdateStatusEmployeeForm = {
            ids: itemSelected,
            isActive: false,
          };
          await handlePostAPI(payloadDisable);
          setIsOpenDisableModal(false);
          showSnackBar("ปิดใช้งานสำเร็จ");
          onSearch();
          onClickChangeStatus();
        }}
      />
      <ConfirmCustomModal
        title="ยืนยันการเปิดใช้งาน"
        subtitleFirstRow="คุณต้องการอนุมัติรายการนี้ใช่หรือไม่?"
        subtitleSecondRow="การดำเนินการนี้ไม่สามารถยกเลิกได้?"
        buttonPrimaryText="ยืนยัน"
        buttonSecondaryText="ยกเลิก"
        open={isOpenEnableModal}
        onClose={() => setIsOpenEnableModal(false)}
        onSubmit={async () => {
          const payloadActive: IUpdateStatusEmployeeForm = {
            ids: itemSelected,
            isActive: true,
          };
          await handlePostAPI(payloadActive);
          setIsOpenEnableModal(false);
          showSnackBar("เปิดใช้งานสำเร็จ");
          onSearch();
          onClickChangeStatus();
        }}
      />
    </>
  );
};

export default UserManagementSearch;

import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircleIcon from "@mui/icons-material/Circle";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { date, object, string } from "yup";
import AppBarFS from "../../../components/app-bar/AppBar";
import DailyLogContext from "../../../provider/DailyLogProvider/DailyLogContext";
import {
  getDailyLog,
  getFeedBrands,
  getHousesFromFarmId,
  getMedicines,
} from "../../../services/DailyLogService";
import { getAllFarms } from "../../../services/FarmService";
import { Farm } from "../../../types";
import { FeedBrands } from "../../../types/FeedBrand.type";
import { House } from "../../../types/House.type";
import { useTranslation } from "react-i18next";
import BasicDetailTable from "../../../components/common/Table/BasicDetailTable";
import EditLog from "./daily-log-edit";
const DailyLogEdit = () => {
  const [isEnglish, setEnglish] = useState<boolean>(true);
  const { t, i18n } = useTranslation();
  const currentDate = dayjs();
  let navigate = useNavigate();
  // Loader
  const VACCINES = useLoaderData() as any;
  const { state } = useLocation();
  // const [steps, setSteps] = useState<any[]>(stepsData);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [farms, setFarms] = useState<Farm[]>([]);
  const [preSelectedFarm, setPreSelectedFarm] = useState<string>("");
  const [preSelectedHouse, setPreSelectedHouse] = useState<string>("");
  const [houses, setHouses] = useState<House[]>([]);
  const [feedBrands, setFeedBrands] = useState<FeedBrands[]>([]);
  const [basicDetailFeed, setBasicDetailsFeed] = useState<any>([]);
  const [basicDetailDeath, setbasicDetailDeath] = useState<any>([]);
  const [basicVaccine, setbasicVaccine] = useState<any>([]);
  const [basicTreatment, setbasicTreatment] = useState<any>([]);
  const { finalDailyLogData, setDailyLogData } = useContext(DailyLogContext);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [item, setItem] = useState<any>(null);
  const [medicines, setMedicine] = useState<any[]>([]);
  const CustomStepIcon = (isComplete: boolean, isPending: boolean) => {
    return isComplete ? (
      <FontAwesomeIcon
        color="secondary"
        className="text-secondary"
        size="lg"
        icon={faCircleCheck}
      />
    ) : (
      <CircleIcon
        fontSize="small"
        className="text-[#EBEBEB]"
        color={isPending ? "secondary" : undefined}
      />
    );
  };
  const language = localStorage.getItem("i18nextLng");
  useEffect(() => {
    if (i18n.language === "en") setEnglish(true);
    else setEnglish(false);
  }, [i18n]);
  useEffect(() => {
    if (state?.selectedFarm) {
      formik.setFieldValue("farmId", state?.selectedFarm);
      setPreSelectedFarm(state?.selectedFarm);
    }
    if (state?.houseId) {
      formik.setFieldValue("houseId", state?.houseId);
      setPreSelectedHouse(state?.houseId);
    }
    if (state?.logDate) {
      formik.setFieldValue("date", state?.logDate);
    }
    if (state.step) setActiveStep(state.step);
    if (setDailyLogData) setDailyLogData({});
    setIsEdit(state.isEdit);
    fetchFarms();
    fetchFeedBrand();
    getMinDate();
    fetchMedicines();
  }, [state]);
  useEffect(() => {
    fetchdailylog(state?.houseId);
  }, [farms, houses, state]);
  async function fetchMedicines() {
    try {
      const { data } = await getMedicines();
      setMedicine(data.medicinCategories);
    } catch (err) {
      console.error(err);
    }
  }
  const fetchdailylog = async (houseId: any) => {
    try {
      const { data } = await getDailyLog(houseId);
      data.dailyLogs
        .filter((x: any) => x.logDate === state?.logDate)
        .map(
          (item: any) => (
            ///set feed  will back to fixed///
            setBasicDetailsFeed([
              {
                detailName: t("dailyLog.dateLabel"),
                detailValue: item.logDate ? item.logDate : "-",
              },
              {
                detailName: t("dailyLog.farmNameLabel"),
                detailValue: item.farmId
                  ? farms.find((farm) => farm.id === item.farmId)?.name
                  : "-",
              },
              {
                detailName: t("dailyLog.houseNamelabel"),
                detailValue: item.houseId
                  ? houses.find((house) => house.id === item.houseId)?.name
                  : "-",
              },
              {
                detailName: t("dailyLog.feedBrandlabel"),
                detailValue: item.feedBrandId
                  ? language === "th"
                    ? feedBrands.find((feed) => feed.id === item.feedBrandId)
                        ?.nameTh
                    : feedBrands.find((feed) => feed.id === item.feedBrandId)
                        ?.nameEn
                  : "-",
              },
              {
                detailName: t("dailyLog.ageofpig"),
                detailValue: item.houseId
                  ? getPigAge(
                      houses.find((house) => house.id === item.houseId)?.pigAge,
                      item.logDate,
                      houses.find((house) => house.id === item.houseId)
                        ?.houseStartDate
                    )
                  : "-",
                unit: t("addHouseDetail.days").toLocaleLowerCase(),
              },
              {
                detailName: t("dailyLog.sku"),
                detailValue: item.feedBrandId
                  ? item.feedBrandItemId
                    ? language === "th"
                      ? feedBrands
                          .find((feed) => feed.id === item.feedBrandId)
                          ?.feed_brand_items.find(
                            (feedItem) => feedItem.id === item.feedBrandItemId
                          )?.skuTh
                      : feedBrands
                          .find((feed) => feed.id === item.feedBrandId)
                          ?.feed_brand_items.find(
                            (feedItem) => feedItem.id === item.feedBrandItemId
                          )?.skuEn
                    : "-"
                  : "-",
              },
              {
                detailName: t("dailyLog.quantity"),
                detailValue: item.feedIntake ? item.feedIntake : "-",
                unit: t("common.kg").toLocaleLowerCase(),
                doFormat: true,
              },
            ]),
            setbasicDetailDeath([
              {
                detailName: t("dailyLog.pigdead"),
                detailValue: item.deadPigCount ? item.deadPigCount : "-",
                unit: t("common.pronoun").toLocaleLowerCase(),
                doFormat: true,
              },
              {
                detailName: t("dailyLog.totalofdead"),
                detailValue: item.deadPigWeight ? item.deadPigWeight : "-",
                unit: t("common.kg").toLocaleLowerCase(),
                doFormat: true,
              },
              {
                detailName: t("dailyLog.reasonOfDeathLabel"),
                detailValue: item.deathReason ? item.deathReason : "-",
              },
              {
                detailName: t("dailyLog.pigselling"),
                detailValue: item.pigCarcassSellingCount
                  ? item.pigCarcassSellingCount
                  : "-",
                unit: t("common.pronoun").toLocaleLowerCase(),
                doFormat: true,
              },
              {
                detailName: t("dailyLog.weightselling"),
                detailValue: item.pigCarcassSellingWeight
                  ? item.pigCarcassSellingWeight
                  : "-",
                unit: t("common.kg").toLocaleLowerCase(),
                doFormat: true,
              },
              {
                detailName: t("dailyLog.priceselling"),
                detailValue: item.pigCarcassSellingPrice
                  ? item.pigCarcassSellingPrice
                  : "-",
              },
              { detailName: t("dailyLog.pictureofpig"), detailValue: "-" },
            ]),
            setbasicVaccine([
              {
                detailName: t("dailyLog.nameofvaccine"),
                detailValue: item.vaccineId
                  ? getVaccinename(item.vaccineId)
                  : "-",
              },
              {
                detailName: t("dailyLog.nameofpigvaccine"),
                detailValue: item.vaccinePigCount ? item.vaccinePigCount : "-",
                unit: t("common.pronoun").toLocaleLowerCase(),
                doFormat: true,
              },
              {
                detailName: t("dailyLog.vaccineperpig"),
                detailValue: item.vaccineQuantity ? item.vaccineQuantity : "-",
                unit: t("common.cc").toLocaleLowerCase(),
                doFormat: true,
              },
              {
                detailName: t("dailyLog.totalbottlevaccine"),
                detailValue: item.vaccineBottleCount
                  ? item.vaccineBottleCount
                  : "-",
              },
            ]),
            setbasicTreatment([
              {
                detailName: t("dailyLog.nameofcageno"),
                detailValue: item.dailyLogTreatments[0]
                  ? getAllCage(item.dailyLogTreatments)
                  : "-",
              },
              {
                detailName: t("dailyLog.numberofpig"),
                detailValue: item.dailyLogTreatments[0]
                  ? getSickPig(item.dailyLogTreatments)
                  : "-",
                unit: t("common.pronoun").toLocaleLowerCase(),
                doFormat: true,
              },
              {
                detailName: t("dailyLog.symptom"),
                detailValue: item.dailyLogTreatments[0]
                  ? getSymptom(item.dailyLogTreatments)
                  : "-",
              },
              {
                detailName: t("dailyLog.nameofmedicine"),
                detailValue: item.dailyLogTreatments[0]
                  ? getMedicineName(item.dailyLogTreatments)
                  : "-",
              },
              {
                detailName: t("dailyLog.totalofmedicine"),
                detailValue: item.dailyLogTreatments[0]
                  ? item.dailyLogTreatments[0].medicineCount
                  : "-",
              },
              { detailName: t("dailyLog.unwellofpig"), detailValue: "-" },
            ]),
            setItem(item)
          )
        );
    } catch (err) {
      console.error(err);
    }
  };
  function getVaccinename(vaccineId: string) {
    const vaccine = VACCINES.find((vac: any) => vac.id === vaccineId);
    return isEnglish ? vaccine.nameEn : vaccine.nameTh;
  }
  function getAllCage(treatments: Array<any>) {
    let cageNo = "";
    treatments.forEach((treatment) => {
      cageNo += treatment.cageNumber + ",";
    });
    return cageNo.substring(0, cageNo.length - 1);
  }
  function getSickPig(treatments: Array<any>) {
    let SickPig = "";
    treatments.forEach((treatment) => {
      SickPig += treatment.treatmentPigCount + ",";
    });
    return SickPig.substring(0, SickPig.length - 1);
  }
  function getSymptom(treatments: Array<any>) {
    let symptom = "";
    treatments.forEach((treatment) => {
      if (treatment.symptom !== null) symptom += treatment.symptom + ",";
    });
    return symptom.length > 1 ? symptom.substring(0, symptom.length - 1) : "-";
  }
  function getMedicineName(treatments: Array<any>) {
    try {
      let medicine = "";
      treatments.forEach((treatment) => {
        if (treatment.medicineDetailId)
          medicines.forEach((med) => {
            if (
              med.medicineDetails.find(
                (detail: any) =>
                  detail.id.trim() === treatment.medicineDetailId.toString() &&
                  treatment.medicineDetailId.toString() !== "8979"
              )
            )
              medicine +=
                med.medicineDetails.find(
                  (detail: any) =>
                    detail.id === treatment.medicineDetailId.toString()
                ).tradeName + ",";
          });
        if (treatment.medicineDetailId?.toString() === "8979")
          medicine += treatment.medicineName + ",";
      });
      if (medicine === "") medicine = "-,";
      return medicine.substring(0, medicine.length - 1);
    } catch (err) {
      console.error(err);
    }
  }
  const fetchFeedBrand = async () => {
    try {
      const { data } = await getFeedBrands();
      setFeedBrands(data.feedDetails);
    } catch (err) {
      console.error(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      date: finalDailyLogData?.feedData?.date || currentDate,
      farmId: finalDailyLogData?.feedData?.farmId || "",
      houseId: finalDailyLogData?.feedData?.houseId || "",
    },
    validationSchema: object({
      date: date().required(`${t("dailyLog.dateRequiredErrorMessage")}`),
      farmId: string().required(
        `${t("dailyLog.farmNameRequiredErrorMessage")}`
      ),
      houseId: string().required(
        `${t("dailyLog.houseNameRequiredErrorMessage")}`
      ),
    }),
    onSubmit: async (values) => {
      try {
      } catch (err) {
        console.error(err);
      }
    },
  });

  async function fetchFarms() {
    try {
      const { data } = await getAllFarms();
      if (Array.isArray(data?.farms) && data.farms?.length) {
        setFarms(data.farms);
        if (!preSelectedFarm) {
          setPreSelectedFarm(data?.farms?.[0]?.id);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  const fetchHousesFromFarmId = async (farmId: string) => {
    try {
      const { data } = await getHousesFromFarmId(farmId);
      setHouses(data?.houses);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getMinDate();
  }, [formik.values.houseId]);
  function getPigAge(
    pigAge: number | undefined,
    logDate: string,
    startDate: string | undefined
  ) {
    const diff = dayjs(logDate).diff(dayjs(startDate), "d");
    if (pigAge) return pigAge + diff;
    return 0;
  }
  function handleBack(index: number) {
    if (index < 0) {
      if (setDailyLogData) setDailyLogData({});
      navigate("/house/" + state?.houseId);
    }
  }

  function handleNext(current: number) {
    setActiveStep(current + 1);
    // setSteps((currentSteps) => {
    //     currentSteps[current] = {
    //         ...currentSteps[current],
    //         completed: true
    //     };
    //     return currentSteps;
    // })
  }

  useEffect(() => {
    if (formik?.values?.farmId) fetchHousesFromFarmId(formik?.values?.farmId);
  }, [formik?.values?.farmId]);

  const getMinDate = () => {
    const data = finalDailyLogData?.houseId
      ? houses.find((ele: any) => ele.id === finalDailyLogData?.houseId)
      : preSelectedHouse
      ? houses.find((ele: any) => ele.id === preSelectedHouse)
      : houses.find((ele: any) => ele.id === formik.values.houseId);
    return dayjs(data?.houseStartDate);
  };

  const startEdit = (step: number) => {
    setIsEdit(true);
    setActiveStep(step);
  };
  return !isEdit ? (
    <div className="p-4 h-full  flex flex-col justify-between w-full">
      <AppBarFS
        title={t("dailyLog.carlendardate")}
        onIconPress={() => handleBack(activeStep - 1)}
      />
      <div className="px-4 py-2 mt-8 ">
        <h5 className="text-[24px] font-medium text-tertiary">
          {t("dailyLog.youtdailylog")}
        </h5>
        {/* <div>
                    {t('dailyLog.carlendardate')}
                </div>
                <div>
                    {t('dailyLog.vaccinedate')}
                </div> */}
      </div>

      {/* Feed */}
      <div className="flex flex-col grow mt-1 gap-4">
        <div className="py-4 px-4">
          <div className="bg-[#D9EBFE] flex items-center p-[16px] rounded-t-lg justify-between">
            <h5 className="text-[24px] font-medium">
              {t("dailyLog.feedBrandlabel")}
            </h5>
            <button
              onClick={() => startEdit(0)}
              className="add-farms-btn px-[15px] py-[5px] rounded-[40px] border border-green-500 flex text-[#68C184] bg-[#fff]"
            >
              {t("addHouse.editButtonText")}
            </button>
          </div>
          <BasicDetailTable tableData={basicDetailFeed} />
          <div className="bg-[#D9EBFE] rounded-b-lg h-[20px]"></div>
        </div>
      </div>

      {/* Death */}
      <div className="flex flex-col grow mt-1 gap-4">
        <div className="py-4 px-4">
          {/* <h5 className="text-[24px] font-medium text-tertiary">{t("dailyLog.youtdailylog")}</h5> */}
          <div className="bg-[#D9EBFE] flex items-center p-[16px] rounded-t-lg justify-between">
            <h5 className="text-[24px] font-medium">
              {t("dailyLog.step2Label")}
            </h5>
            <button
              onClick={() => startEdit(1)}
              className="add-farms-btn px-[15px] py-[5px] rounded-[40px] border border-green-500 flex text-[#68C184] bg-[#fff]"
            >
              {t("addHouse.editButtonText")}
            </button>
          </div>
          <BasicDetailTable tableData={basicDetailDeath} />
          <div className="bg-[#D9EBFE] rounded-b-lg h-[20px]"></div>
        </div>
      </div>

      {/* Vaccine */}
      <div className="flex flex-col grow mt-1 gap-4">
        <div className="py-4 px-4">
          {/* <h5 className="text-[24px] font-medium text-tertiary">{t("dailyLog.youtdailylog")}</h5> */}
          <div className="bg-[#D9EBFE] flex items-center p-[16px] rounded-t-lg justify-between">
            <h5 className="text-[24px] font-medium">
              {t("dailyLog.vaccinelog")}
            </h5>
            <button
              onClick={() => startEdit(2)}
              className="add-farms-btn px-[15px] py-[5px] rounded-[40px] border border-green-500 flex text-[#68C184] bg-[#fff]"
            >
              {t("addHouse.editButtonText")}
            </button>
          </div>
          <BasicDetailTable tableData={basicVaccine} />
          <div className="bg-[#D9EBFE] rounded-b-lg h-[20px]"></div>
        </div>
      </div>

      {/* Treatment */}
      <div className="flex flex-col grow mt-1 gap-4">
        <div className="py-4 px-4">
          {/* <h5 className="text-[24px] font-medium text-tertiary">{t("dailyLog.youtdailylog")}</h5> */}
          <div className="bg-[#D9EBFE] flex items-center p-[16px] rounded-t-lg justify-between">
            <h5 className="text-[24px] font-medium">
              {t("dailyLog.treatmentlog")}
            </h5>
            <button
              onClick={() => startEdit(3)}
              className="add-farms-btn px-[15px] py-[5px] rounded-[40px] border border-green-500 flex text-[#68C184] bg-[#fff]"
            >
              {t("addHouse.editButtonText")}
            </button>
          </div>
          <BasicDetailTable tableData={basicTreatment} />
          <div className="bg-[#D9EBFE] rounded-b-lg h-[20px]"></div>
        </div>
      </div>
    </div>
  ) : (
    <EditLog
      logDetail={item}
      step={activeStep}
      houseStartDate={
        houses.find((house) => house.id === state.houseId)?.houseStartDate
      }
      houseId={preSelectedHouse}
      farmId={preSelectedFarm}
    />
  );
};

export default DailyLogEdit;

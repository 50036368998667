export default function ReportMediumPigSVG() {
  return (
    <svg
      width="86"
      height="76"
      viewBox="0 0 96 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2603_32027)">
        <path
          d="M65.271 13.796C67.9543 10.9213 71.7724 9.30434 75.6439 8.58567C78.8346 7.99344 82.2722 7.6341 85.4362 8.59233C88.6001 9.55055 88.0461 12.4518 87.739 15.433C87.0849 21.8345 84.0678 28.023 79.2484 32.335"
          fill="#EF9AB5"
        />
        <path
          d="M30.728 13.7963C28.0446 10.9283 24.2265 9.30459 20.355 8.58592C17.1643 8.00034 13.7267 7.63435 10.5628 8.59258C7.39881 9.5508 7.95951 12.4587 8.25988 15.4332C8.91403 21.8347 11.9311 28.0233 16.7505 32.3353"
          fill="#EF9AB5"
        />
        <g style={{ mixBlendMode: "multiply" }} opacity="0.66">
          <path
            d="M68.6221 17.8353C70.1307 16.2182 72.28 15.3066 74.4627 14.9007C76.2583 14.568 78.1941 14.3617 79.9763 14.9007C81.7585 15.4397 81.4448 17.0767 81.2712 18.7536C80.9041 22.3602 79.202 25.8404 76.4919 28.2693"
            fill="#E5ACB5"
          />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.66">
          <path
            d="M27.377 17.8353C25.8684 16.2182 23.7191 15.3066 21.5364 14.9007C19.7408 14.568 17.805 14.3617 16.0228 14.9007C14.2406 15.4397 14.5543 17.0767 14.7278 18.7536C15.095 22.3602 16.7971 25.8404 19.5071 28.2693"
            fill="#E5ACB5"
          />
        </g>
        <path
          d="M84.6222 51.3796C84.1282 48.0657 82.8132 44.9049 82.4394 41.5778C82.1057 38.6033 82.8399 35.4957 82.0656 32.6077C81.4782 30.4317 80.2233 27.9297 79.1887 25.9134C76.779 21.2221 73.1478 17.1097 68.789 14.1286C63.5158 10.5219 56.467 9.15781 49.3915 9.35078C42.3159 9.15781 35.2671 10.5219 29.9939 14.1286C25.6351 17.1097 22.0039 21.2221 19.5942 25.9134C18.5596 27.9297 17.3047 30.4317 16.7173 32.6077C15.943 35.4957 16.6772 38.6033 16.3435 41.5778C15.9697 44.9049 14.6547 48.0657 14.1607 51.3796C11.4507 69.5393 29.2262 76.2602 46.8149 77.1652V77.2716C47.6693 77.2783 48.5304 77.2716 49.3981 77.2583C50.2592 77.2783 51.127 77.285 51.9814 77.2716V77.1652C69.57 76.2602 87.3456 69.5326 84.6355 51.3796H84.6222Z"
          fill="#FBABC2"
        />
        <path
          d="M46.0671 55.8981C47.3888 55.4589 48.7572 55.1861 50.1256 55.4389C51.7476 55.7384 53.3963 56.0911 55.045 56.2241C59.1168 56.5435 63.0751 53.4027 63.6959 49.4367C64.2699 45.7635 62.7079 41.7709 60.345 38.9894C59.8043 38.3572 59.2036 37.765 58.5361 37.2659C54.204 34.0319 48.1698 33.0005 42.9966 34.5643C37.4764 36.2278 32.8039 41.5314 32.864 47.4737C32.8974 50.7809 34.7797 54.1546 37.8502 55.399C39.8727 56.2175 42.2891 56.4504 44.4585 56.2707C45.0192 56.2241 45.5465 56.0777 46.0738 55.9047L46.0671 55.8981Z"
          fill="#EF819F"
        />
        <path
          d="M30.5877 41.1453C34.032 41.1453 34.032 35.8218 30.5877 35.8218C27.1434 35.8218 27.1434 41.1453 30.5877 41.1453Z"
          fill="#BA5D6F"
        />
        <path
          d="M65.7916 41.1453C69.2359 41.1453 69.2359 35.8218 65.7916 35.8218C62.3473 35.8218 62.3473 41.1453 65.7916 41.1453Z"
          fill="#BA5D6F"
        />
        <path
          d="M42.6763 49.796C46.5478 49.796 46.5478 43.8071 42.6763 43.8071C38.8048 43.8071 38.8048 49.796 42.6763 49.796Z"
          fill="#BA5D6F"
        />
        <path
          d="M53.9837 49.796C57.8552 49.796 57.8552 43.8071 53.9837 43.8071C50.1122 43.8071 50.1122 49.796 53.9837 49.796Z"
          fill="#BA5D6F"
        />
        <g style={{ mixBlendMode: "multiply" }} opacity="0.22">
          <path
            d="M28.5651 54.3675C30.8814 52.1982 30.6611 47.9594 27.644 46.4888C25.0407 45.2245 20.2014 46.0297 18.7596 48.6847C18.0587 49.9757 18.0854 51.5993 18.7329 52.9169C19.3803 54.2345 20.5952 55.2393 21.9769 55.7317C24.1997 56.5302 26.8497 55.9779 28.5718 54.3675H28.5651Z"
            fill="#E5ACB5"
          />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.22">
          <path
            d="M68.3216 54.3675C66.0054 52.1982 66.2257 47.9594 69.2428 46.4888C71.846 45.2245 76.6854 46.0297 78.1272 48.6847C78.8281 49.9757 78.8014 51.5993 78.1539 52.9169C77.5064 54.2345 76.2916 55.2393 74.9098 55.7317C72.6871 56.5302 70.0371 55.9779 68.3149 54.3675H68.3216Z"
            fill="#E5ACB5"
          />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.09">
          <path
            d="M84.6221 51.3797C84.1281 48.0658 82.8131 44.905 82.4393 41.5779C82.1056 38.6034 82.8398 35.4958 82.0655 32.6078C81.4781 30.4318 80.2232 27.9298 79.1886 25.9135C76.7789 21.2222 73.1477 17.1098 68.789 14.1287C65.9988 12.2189 62.7013 10.9546 59.1703 10.2026C64.9642 14.2352 69.116 21.3753 71.7527 27.4507C73.0209 30.3786 73.5683 33.1402 73.4414 36.3409C73.3413 38.8695 74.1623 40.6063 74.7364 43.0019C75.9713 48.179 76.058 54.82 73.4414 59.6111C68.9425 67.8359 59.0101 71.7886 50.0255 72.8466C43.9245 74.2241 37.3964 74.4769 31.202 74.5168C36.028 76.054 41.4214 76.8924 46.8081 77.1719V77.2784C47.6625 77.285 48.5236 77.2784 49.3914 77.2651C50.2524 77.285 51.1202 77.2917 51.9746 77.2784V77.1719C69.5633 76.2669 87.3388 69.5394 84.6288 51.3864L84.6221 51.3797Z"
            fill="#E5ACB5"
          />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.09">
          <path
            d="M60.3384 38.9895C59.8778 38.4505 59.3638 37.958 58.8165 37.5122C59.0301 37.9913 59.217 38.4837 59.3438 39.0028C60.7189 44.3728 58.4961 49.1307 54.3576 52.6309C53.1828 53.629 51.8811 54.6272 50.4927 55.5122C51.9946 55.7917 53.5098 56.1044 55.0384 56.2242C59.1102 56.5436 63.0685 53.4028 63.6892 49.4368C64.2633 45.7636 62.7013 41.771 60.3384 38.9895Z"
            fill="#E5ACB5"
          />
        </g>
        <g clipPath="url(#clip1_2603_32027)">
          <path
            d="M58.8176 63.4474C58.6716 63.4474 58.5257 63.4042 58.3992 63.3177C48.2844 56.3556 40.5634 62.8805 40.4855 62.9477C40.1839 63.212 39.7217 63.1832 39.4541 62.8853C39.1865 62.5874 39.2157 62.1309 39.5174 61.8667C39.6001 61.7898 48.1823 54.5345 59.2311 62.1357C59.562 62.3616 59.6447 62.8132 59.4111 63.1399C59.2701 63.3417 59.0414 63.4474 58.8127 63.4474H58.8176Z"
            fill="#BA5D6F"
          />
          <path
            d="M40.3297 64.7257C40.0037 64.7257 39.7069 64.5047 39.6242 64.178C39.2399 62.6693 37.8679 62.136 37.8095 62.1167C37.43 61.9822 37.2403 61.5642 37.3765 61.1942C37.5176 60.8242 37.9312 60.632 38.3058 60.7666C38.3933 60.8002 40.4611 61.5642 41.0351 63.832C41.1324 64.2164 40.8941 64.6104 40.5048 64.7065C40.4465 64.7209 40.3832 64.7257 40.3248 64.7257H40.3297Z"
            fill="#BA5D6F"
          />
          <path
            d="M58.3311 64.889C58.2727 64.889 58.2143 64.8842 58.1511 64.8698C57.7618 64.7737 57.5234 64.3797 57.6208 63.9953C58.1948 61.7275 60.2626 60.9635 60.3501 60.9299C60.7296 60.7953 61.148 60.9875 61.2842 61.3623C61.4205 61.7371 61.2259 62.1455 60.8512 62.2848C60.788 62.3088 59.416 62.8422 59.0365 64.3461C58.9538 64.6728 58.657 64.8938 58.3311 64.8938V64.889Z"
            fill="#BA5D6F"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2603_32027">
          <rect
            width="80"
            height="69.2784"
            fill="white"
            transform="translate(7.99976 8)"
          />
        </clipPath>
        <clipPath id="clip1_2603_32027">
          <rect
            width="24"
            height="6.22222"
            fill="white"
            transform="translate(37.3331 58.6667)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

import { Checkbox, Link, TableCell, TableRow } from "@mui/material";
import { useEffect } from "react";
import TableCustom, {
  ITableCustomHeadCell,
} from "../../../components/Table/TableCustom";
import { ETypeRevenue } from "../enum/typeRevenue.enum";
import { useRevenueFilterHook } from "../hooks/useRevenueFilter.hook";
import { useRevenueTableHook } from "../hooks/useRevenueTable.hook";
import { IRevenueTable } from "../types/revenueTable.type";
import RevenueFilter from "./RevenueFilter";
import RevenueModal from "./RevenueModal";

type Props = { type: string; id: string };

const RevenueTab = ({ type, id }: Props) => {
  const searchRevenueFilter = useRevenueFilterHook();
  const {
    count,
    list,
    itemSelected,
    handleClick,
    handleSelectAllClick,
    setItemSelected,
    isSelected,
    handleFetch,
    customerId,
    setCustomerId,
    isOpenDetailModal,
    setIsOpenDetailModal,
    handleExportExcel,
    handleChangeOrder,
  } = useRevenueTableHook(searchRevenueFilter, type, id);

  const headCells: ITableCustomHeadCell<keyof IRevenueTable>[] = [
    {
      id: "customer_code",
      numeric: false,
      disablePadding: false,
      label: "รหัสลูกค้า",
      width: "200px",
      isSorting: true,
    },
    {
      id: "farmerName",
      numeric: false,
      disablePadding: false,
      label: "ชื่อลูกค้า",
      width: "200px",
      isSorting: false,
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "ประเภท",
      width: "200px",
      isSorting: true,
    },
    {
      id: "totalPrice",
      numeric: false,
      disablePadding: false,
      label: "รายได้ทั้งหมด",
      width: "200px",
      isSorting: false,
    },
    {
      id: "totalPrice",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "100px",
      isSorting: false,
    },
  ];

  const filteredHeadCells = type === "all" ? headCells.slice(0, -1) : headCells;

  useEffect(() => {
    handleFetch();
  }, [searchRevenueFilter.direction, searchRevenueFilter.page]);
  return (
    <>
      <RevenueFilter
        itemSelected={itemSelected}
        onSearch={handleFetch}
        searchRevenueFilter={searchRevenueFilter}
        onExport={handleExportExcel}
      />
      <div className="flex w-full pt-6">
        <div className="flex w-full">
          <TableCustom
            order={searchRevenueFilter.direction}
            orderBy={searchRevenueFilter.orderBy}
            rowsPerPage={10}
            rows={list}
            itemsSelected={itemSelected}
            headerItems={filteredHeadCells}
            page={searchRevenueFilter.page}
            onChangeOrderBy={(val) =>
              handleChangeOrder(val as keyof IRevenueTable)
            }
            onChangeOrder={(val) => {
              searchRevenueFilter.setDirection(val);
            }}
            onChangePage={(val) => searchRevenueFilter.setPage(val)}
            onSelectAllClick={(val) => handleSelectAllClick(val)}
            onSelectItem={(val) => setItemSelected(val as string[])}
            totalItemSize={count}
            currentPageItems={list.map((row) => row.id)}
            rowChildren={
              <>
                {list.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          onClick={(event) => handleClick(event, row.id)}
                          sx={{
                            borderWidth: "1px",
                            color: "#C9C9C9",
                            "&.Mui-checked": {
                              color: "#68C184",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        <Link
                          component="button"
                          variant="body2"
                          sx={{
                            color: "#68C184",
                            textDecorationColor: "#68C184",
                          }}
                        >
                          {row.customer_code}
                        </Link>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        {row.farmerName}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        {ETypeRevenue[row.type as keyof typeof ETypeRevenue]}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        {row.totalPrice}
                      </TableCell>

                      {type !== "all" && (
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          <Link
                            component="button"
                            variant="body2"
                            sx={{
                              color: "#68C184",
                              textDecorationColor: "#68C184",
                            }}
                            onClick={() => {
                              setIsOpenDetailModal(true);
                              setCustomerId(row.id);
                            }}
                          >
                            รายละเอียด
                          </Link>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </>
            }
          ></TableCustom>
        </div>
      </div>
      {customerId && (
        <RevenueModal
          isOpen={isOpenDetailModal}
          handleClose={() => setIsOpenDetailModal(false)}
          type={type}
          customerId={customerId}
          partnerId={id}
        />
      )}
    </>
  );
};

export default RevenueTab;

import { LoaderFunctionArgs } from "react-router-dom";
import { getMyOrderDetailById } from "../../../../services/MyOrder.service";
import { getPartnerIncomingStockById } from "../../../../services/partner/stock.service";

export async function upComingStockDetailLoader(loader: LoaderFunctionArgs) {
  const { params } = loader;
  const stockId = params["stockId"] ?? "";
  const stockList = await getPartnerIncomingStockById(stockId);
  return {
    stockId,
    stockList,
  };
}

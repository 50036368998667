const IconVaccinesWithBgSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={41} height={42} fill="none">
    <rect width={41} height={41} y={0.129} fill="#3777BC" rx={20.5} />
    <mask
      id="a"
      width={27}
      height={28}
      x={7}
      y={7}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M7 7.129h27v27H7z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#fff"
        d="M14.875 32.441c-.319 0-.586-.107-.802-.323a1.089 1.089 0 0 1-.323-.802v-5.062h-1.125c-.619 0-1.148-.22-1.59-.661-.44-.44-.66-.97-.66-1.59v-8.437c-.319 0-.586-.107-.802-.323a1.089 1.089 0 0 1-.323-.802c0-.318.108-.586.323-.801.216-.216.483-.324.802-.324h3.375V11.63h-.563c-.318 0-.585-.108-.801-.323a1.089 1.089 0 0 1-.323-.802c0-.319.107-.586.323-.802.216-.215.483-.323.802-.323h3.374c.32 0 .586.108.802.323.216.216.323.483.323.802 0 .319-.107.586-.323.802a1.089 1.089 0 0 1-.802.323H16v1.687h3.375c.319 0 .586.108.802.324.215.215.323.483.323.801 0 .32-.108.586-.323.802a1.089 1.089 0 0 1-.802.323v8.438c0 .619-.22 1.148-.66 1.589-.442.44-.971.66-1.59.66H16v5.063c0 .32-.108.586-.323.802a1.089 1.089 0 0 1-.802.323Zm-2.25-8.437h4.5v-1.688h-1.969a.81.81 0 0 1-.59-.253.81.81 0 0 1-.254-.59.81.81 0 0 1 .254-.591.81.81 0 0 1 .59-.253h1.969V18.94h-1.969a.81.81 0 0 1-.59-.253.81.81 0 0 1-.254-.59.81.81 0 0 1 .254-.591.81.81 0 0 1 .59-.253h1.969v-1.688h-4.5v8.438Zm11.25 7.875c-.619 0-1.148-.22-1.59-.661-.44-.44-.66-.97-.66-1.59v-7.312c0-.543.094-.993.281-1.35a6.104 6.104 0 0 1 1.153-1.56c.17-.16.253-.315.253-.465v-.562c-.318 0-.585-.108-.801-.324a1.089 1.089 0 0 1-.323-.801c0-.319.107-.586.323-.802.216-.215.483-.323.802-.323h5.625c.318 0 .585.108.801.323.216.216.323.483.323.802 0 .319-.107.586-.323.801a1.089 1.089 0 0 1-.802.324v.562c0 .15.094.32.282.507.187.187.393.412.618.675.207.262.39.572.549.928.16.356.239.778.239 1.265v7.313c0 .619-.22 1.148-.66 1.589-.442.44-.971.66-1.59.66h-4.5Zm0-9h4.5v-.563a1.12 1.12 0 0 0-.253-.73 53.633 53.633 0 0 0-.563-.676 7.008 7.008 0 0 1-.59-.816c-.188-.3-.282-.684-.282-1.153v-.562h-1.125v.562c0 .45-.089.825-.267 1.125-.178.3-.37.572-.576.816-.207.244-.399.473-.577.69a1.137 1.137 0 0 0-.267.744v.563Zm0 6.75h4.5V27.94h-4.5v1.688Z"
      />
    </g>
  </svg>
);
export default IconVaccinesWithBgSvg;

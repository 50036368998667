import { memo, useCallback, useEffect, useState } from "react";
import { Modal } from "@mui/material";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { tw } from "../../../../utils/tw";
import AppBarFS from "../../../../components/app-bar/AppBar";
import { IContracts } from "../../../../types/Financial.type";
import { getDownloadUrl } from "../../../../services/Financial.service";
import axios from "axios";
import ZoomImageSvg from "../../../../assets/svg/ZoomImage";
import PdfRenderer from "../../../../admin/components/Modal/DocumentModal/ModalPanel/PdfRenderer";
import PreviewAndZoomImageCarouselModal from "../../../../components/common/ViewImageAndPreviewCarousel/PreviewAndZoomImageCarouselModal";
import { get } from "lodash";
import { handleDownloadBlob } from "../../../../utils/downloadFile";

interface IViewDocumentModalProps {
  isOpen: boolean;
  onClose: () => void;
  dataView?: IContracts;
}

const classes = {
  wrapper: tw(`overflow-scroll`),
  container: tw(`min-h-screen w-full flex flex-col bg-[white] pb-[24px]`),
  appbarContainer: tw(`py-4`),
  headerContainer: tw(
    `mb-[16px] px-[16.5px] text-[18px] leading-[21.6px] font-semibold flex flex-row items-center justify-between`
  ),
  label: tw(
    `border-[1px] border-[#2C702F] rounded-full font-normal flex justify-center items-center text-[14px] leading-[19.6px] text-[#2C702F] py-[2px] px-[8px]`
  ),
  swiperWrapper: tw(`[&_.swiper-wrapper]:items-center`),
  swiperContainer: tw(`px-[45px] relative pb-[24px]`),
  swiperImage: tw(`w-full object-contain max-w-[450px] m-auto`),
  danger: tw(
    `text-status-bad font-semibold text-[14px] leading-[21px] whitespace-pre-line text-center`
  ),
  actionContainer: tw(
    `flex flex-col gap-[16px] mt-[16px] max-w-[450px] px-[16px] w-full mx-auto`
  ),
  button: tw(
    `bg-secondary border-[1px] border-secondary flex justify-center items-center text-center text-[white] text-[16px] leading-[25.6px] font-bold w-full py-[16.5px] rounded-full hover:brightness-105 duration-150 transition-all`
  ),
};

const ViewDocumentModal = (props: IViewDocumentModalProps) => {
  const { isOpen, onClose, dataView } = props;
  const [docs, setDocs] = useState<
    { url: string; fileName: string; fileId: string }[]
  >([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const createDocs = async () => {
    if (dataView && dataView) {
      const docsData = [];
      if (dataView.creditInformation.citizenDocument.length > 0) {
        const img1 = dataView.creditInformation.citizenDocument[0];
        const preSignURL1 = await getDownloadUrl(dataView.id, img1.id);
        if (preSignURL1) {
          const response = await axios.get<Blob>(preSignURL1.blob.blobUrl, {
            responseType: "blob",
          });
          const objectURL = window.URL.createObjectURL(response.data);
          docsData.push({
            url: objectURL,
            fileName: img1.fileName,
            fileId: img1.id,
          });
        }
        if (dataView.creditInformation.creditBureauDocument.length > 0) {
          const img2 = dataView.creditInformation.creditBureauDocument[0];
          const preSignURL2 = await getDownloadUrl(dataView.id, img2.id);
          if (preSignURL2) {
            const response = await axios.get<Blob>(preSignURL2.blob.blobUrl, {
              responseType: "blob",
            });
            const objectURL = window.URL.createObjectURL(response.data);
            docsData.push({
              url: objectURL,
              fileName: img2.fileName,
              fileId: img2.id,
            });
          }
        }
      }
      setDocs(docsData);
      setIsLoading(false);
    }
  };

  const handleDownloadByUrl = useCallback(
    (id: string, fileId: string, name: string) => async () => {
      const preSignURL = await getDownloadUrl(id, fileId);
      if (preSignURL) {
        const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
          responseType: "blob",
        });
        if (typeof window !== "undefined") {
          const objectURL = window.URL.createObjectURL(response.data);
          handleDownloadBlob(objectURL, name);
        }
      }
    },
    [
      dataView?.creditInformation.citizenDocument,
      dataView?.creditInformation.creditBureauDocument,
      docs,
    ]
  );

  useEffect(() => {
    createDocs();
  }, [dataView]);

  return (
    <Modal open={isOpen} className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.appbarContainer}>
          <AppBarFS title="เอกสารสัญญา" onIconPress={onClose} />
        </div>
        <div className="p-4 font-semibold text-[18px] text-[#000000]">
          เลขที่สัญญา {get(dataView, ["contractNumber"], "")}
        </div>
        {!isLoading && (
          <Swiper
            pagination
            modules={[Pagination]}
            loop
            className={classes.swiperWrapper}
            initialSlide={slideIndex}
            onRealIndexChange={(e) => {
              setSlideIndex(e.realIndex || 0);
            }}
          >
            {docs.map((doc, index) => {
              const isPdf = doc.fileName.indexOf(".pdf") !== -1;
              return (
                <SwiperSlide
                  key={`${index}`}
                  className={classes.swiperContainer}
                  onClick={() => setIsOpenModal(true)}
                >
                  {!isPdf ? (
                    <>
                      <div className="absolute top-[45%] bottom-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2">
                        <div className="w-8 h-8">
                          <ZoomImageSvg />
                        </div>
                      </div>
                      <img src={doc.url} className={classes.swiperImage} />
                    </>
                  ) : (
                    <PdfRenderer
                      url={doc.url}
                      className={tw(
                        classes.swiperImage,
                        "min-h-[420px] border-[1px] border-[#555]"
                      )}
                    />
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
        <div className="flex flex-1" />
        <div className={classes.actionContainer}>
          <button
            onClick={handleDownloadByUrl(
              get(dataView, ["id"], ""),
              get(docs, [slideIndex, "fileId"]),
              get(docs, [slideIndex, "fileName"])
            )}
            className={tw(classes.button)}
          >
            บันทึกรูปภาพ
          </button>
          <button
            onClick={onClose}
            className={tw(classes.button, "bg-[white] text-secondary")}
          >
            ปิด
          </button>
        </div>
        <PreviewAndZoomImageCarouselModal
          isOpen={isOpenModal}
          initIndex={slideIndex}
          onClose={() => {
            setIsOpenModal(false);
          }}
          docs={docs}
        />
      </div>
    </Modal>
  );
};

export default memo(ViewDocumentModal);

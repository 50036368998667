import {
  ICreditFeedData,
  IDownloadUrlCreateFeed,
  IExportFarmer,
  IFilter,
  IGetCreditFeedData,
  IGetCreditSetting,
  IGetFarmAndHouse,
  IGetFarmers,
  IGetGrade,
  IGetId,
  IGetSettingById,
  IGetTab4Response,
  IGetUploadUrlCreateFeed,
} from "../types/CreditFeed.type";
import adminAPI from "./AdminApiService";

export const getFarmers = async (params: { page: number }) => {
  try {
    const { data } = await adminAPI.get<IGetFarmers>("/credit-feed/farmers", {
      params: {
        limit: 9999999,
        // orderBy: 'type',
      },
    });
    return data;
  } catch (error) {
    return null;
  }
};

export const getFarmersCreditFeed = async (params?: {
  page?: number;
  direction?: "desc" | "asc";
  searchText?: string;
  orderBy?: "farmer_code" | "credit_rating" | "updated_at";
}) => {
  const { data } = await adminAPI.get<IGetFarmers>(`/credit-feed/farmers`, {
    params: {
      page: params?.page,
      direction: params?.direction,
      searchText: params?.searchText,
      orderBy: params?.orderBy,
    },
  });
  return data;
};

export const getCreditFeedData = async (params?: {
  page?: number;
  limit?: number;
  direction?: "desc" | "asc";
  searchText?: string;
  orderBy?: "farmer_code" | "credit_rating" | "updated_at";
  farmerGrade?: "a" | "b" | "c" | "d" | "e" | "f";
  isActive?: true | false;
  startDate?: Date;
  endDate?: Date;
  status?:
    | "pending_sale_lead_approve_offer"
    | "pending_lending_approve_offer"
    | "pending_farmer_approve_offer"
    | "pending_sale_forward_consideration"
    | "pending_lending_confirm_consideration"
    | "pending_sale_approve_consideration"
    | "pending_confirm_reject"
    | "approved"
    | "denied"
    | "farmer_denied";
  tabName?:
    | "pending"
    | "denied"
    | "processing"
    | "history"
    | "credit_status"
    | "approved";
  contractType?: "pay_after_expire" | "fill_credit";
  durationStart?: number;
  durationEnd?: number;
}) => {
  const { data } = await adminAPI.get<IGetCreditFeedData>(`/credit-feed`, {
    params: {
      page: params?.page,
      direction: params?.direction || "desc",
      searchText: params?.searchText,
      orderBy: params?.orderBy,
      farmerGrade: params?.farmerGrade,
      isActive: params?.isActive,
      startDate: params?.startDate?.toISOString(),
      endDate: params?.endDate?.toISOString(),
      status: params?.status,
      tabName: params?.tabName,
      contractType: params?.contractType,
      durationStart: params?.durationStart,
      durationEnd: params?.durationEnd,
    },
  });
  return data;
};

export const getCreditSetting = async () => {
  const { data } = await adminAPI.get<IGetCreditSetting>(
    `/credit-feed/credit-settings`
  );
  return data;
};

export const getCreditSettingById = async (farmerId: string) => {
  const { data } = await adminAPI.get<IGetSettingById>(
    `/farm-detail/${farmerId}/credit-info`
  );
  return data;
};

export const getCalGrade = async (body: {
  creditSettingId: string;
  farmerType: string;
  generalPersonalInformation: { id: string; score: number }[] | [];
  generalLegalInformation: { id: string; score: number }[] | [];
  generalFarmInformation: { id: string; score: number }[] | [];
  farmPerformance: { id: string; score: number }[] | [];
  farmFinancialStatusAndInstallment: { id: string; score: number }[] | [];
  applicationUsage: { id: string; score: number }[] | [];
}) => {
  const { data } = await adminAPI.post<IGetGrade>(
    `credit-feed/calculate-grade`,
    body
  );
  return data;
};

export const getFarmAndHouse = async (farmersId: string) => {
  const { data } = await adminAPI.get<IGetFarmAndHouse>(
    `/credit-feed/farms/${farmersId}`
  );
  return data;
};

export const getUploadUrlCreateFeed = async (id: string) => {
  const { data } = await adminAPI.get<IGetUploadUrlCreateFeed>(
    `/credit-feed/upload/${id}`
  );
  return data;
};

export const getDownloadUrl = async (id: string, fileId: string) => {
  const { data } = await adminAPI.get<IDownloadUrlCreateFeed>(
    `/credit-feed/download/${id}/${fileId}`
  );
  return data;
};

export const createFeed = async (body: {
  id: string;
  creditInformation: {
    farmSize: string;
    houseId: string;
    creditAmount: number;
    customerGrade: string;
    contractType: string;
    creditPeriod: number;
    files?: {
      id: string;
      fileName: string;
      fileSize: number;
    }[];
  };
}) => {
  const { id, ...props } = body;
  const { data } = await adminAPI.post(`/credit-feed/${id}`, props);
  return data;
};

export const createFeed2 = async (body: {
  id: string;
  creditInformation: {
    farmSize: string;
    houseId: string;
    creditAmount: number;
    contractType: string;
    creditPeriod: number;
    reason: string;
    calculatedCreditAmount: number;
    files?: {
      id: string;
      fileName: string;
      fileSize: number;
    }[];
  };
  creditSettingId: string;
  farmerType: string;
  generalPersonalInformation: { id: string; score: number }[] | [];
  generalLegalInformation: { id: string; score: number }[] | [];
  generalFarmInformation: { id: string; score: number }[] | [];
  farmPerformance: { id: string; score: number }[] | [];
  farmFinancialStatusAndInstallment: { id: string; score: number }[] | [];
  applicationUsage: { id: string; score: number }[] | [];
}) => {
  const { id, ...props } = body;
  const { data } = await adminAPI.post(`/credit-feed/${id}`, props);
  return data;
};

export const ApproveSaleLead = async (body: {
  id: string;
  isApprove: boolean;
  detail: string;
}) => {
  const { id, ...props } = body;
  const { data } = await adminAPI.put(`/credit-feed/sale-lead/${id}`, props);
  return data;
};

export const ApproveLending = async (body: {
  id: string;
  isApprove: boolean;
  detail: string;
}) => {
  const { id, ...props } = body;
  const { data } = await adminAPI.put(
    `/credit-feed/lending/confirm-offer/${id}`,
    props
  );
  return data;
};

export const ApproveLendingConsideration = async (body: {
  id: string;
  isApprove: boolean;
  detail: string;
}) => {
  const { id, ...props } = body;
  const { data } = await adminAPI.put(
    `/credit-feed/lending/confirm-consideration/${id}`,
    props
  );
  return data;
};

export const ApproveSaleForward = async (body: {
  id: string;
  isApprove: boolean;
  detail: string;
  rejectReason: string;
}) => {
  const { id, ...props } = body;
  const { data } = await adminAPI.put(`/credit-feed/forward/${id}`, props);
  return data;
};

export const DiscardCredit = async (body: {
  id: string;
  duration: string;
  detail: string;
}) => {
  const { id, ...props } = body;
  const { data } = await adminAPI.put(`/credit-feed/discard/${id}`, props);
  return data;
};

export const ReapplyCredit = async (body: {
  id: string;
  creditInformation: {
    farmSize: string;
    houseId: string;
    creditAmount: number;
    contractType: string;
    creditPeriod: number;
    reason: string;
    calculatedCreditAmount: number;
    files?: {
      id: string;
      fileName: string;
      fileSize: number;
    }[];
  };
  creditSettingId: string;
  farmerType: string;
  generalPersonalInformation: { id: string; score: number }[] | [];
  generalLegalInformation: { id: string; score: number }[] | [];
  generalFarmInformation: { id: string; score: number }[] | [];
  farmPerformance: { id: string; score: number }[] | [];
  farmFinancialStatusAndInstallment: { id: string; score: number }[] | [];
  applicationUsage: { id: string; score: number }[] | [];
}) => {
  const { id, ...props } = body;
  const { data } = await adminAPI.put(`/credit-feed/re-apply/${id}`, props);
  return data;
};

export const UploadFileCredit = async (body: {
  id: string;
  citizenDocument: {
    id: string;
    fileName: string;
    fileSize: number;
  }[];
  creditBureauDocument: {
    id: string;
    fileName: string;
    fileSize: number;
  }[];
}) => {
  const { id, ...props } = body;
  const { data } = await adminAPI.put(`/credit-feed/document/${id}`, props);
  return data;
};

// TODO: will replace to pagination
export const getTabList3 = async (params: { page: number; limit: number }) => {
  const { data } = await adminAPI.get<IGetTab4Response>(`/credit-feed`, {
    params: {
      page: params.page,
      limit: params.limit,
      tabName: "processing",
    },
  });
  return data;
};

// TODO: will replace to pagination
export const getTabList4 = async (params: { page: number; limit: number }) => {
  const { data } = await adminAPI.get<IGetTab4Response>(`/credit-feed`, {
    params: {
      page: params.page,
      limit: params.limit,
      tabName: "history",
      orderBy: "updated_at",
    },
  });
  return data;
};

export const rejectFeed = async (body: {
  id: string;
  duration?: string;
  reason?: string;
  detail?: string;
}) => {
  const { data } = await adminAPI.put(`/credit-feed/reject/${body.id}`, {
    duration: body.duration || "-",
    reason: body.reason || "other",
    detail: body.detail || "-",
  });

  return data;
};

export const approveFeed = async (body: {
  id: string;
  creditInformation: {
    farmSize: string;
    houseId: string;
    creditAmount: number;
    customerGrade: string;
    contractType: string;
    creditPeriod: number;
    files?: {
      id: string;
      fileName: string;
      fileSize: number;
    }[];
  };
  startDate: Date;
  expireDate: Date;
  contractDocument?: {
    id: string;
    fileName: string;
    fileSize: number;
  }[];
}) => {
  const { id, ...props } = body;
  const { data } = await adminAPI.put(`/credit-feed/approve/${body.id}`, props);
  return data;
};

export const getCreditStatus = async () => {
  const { data } = await adminAPI.get<IGetTab4Response>(
    `/credit-feed?page=1&limit=999999&tabName=credit_status`,
    {
      params: {
        orderBy: "updated_at",
      },
    }
  );
  return data;
};

export const getId = async () => {
  const { data } = await adminAPI.get<IGetId>(`/credit-feed/id`);
  return data;
};

export const filterCustomerTable = async (params: {
  page?: number;
  limit: number;
  farmerGrade: string;
  searchText?: string;
  status?: string;
  tabName: "processing" | "history" | "credit_status";
  isActive?: string;
  startDate?: string;
  endDate?: string;
}) => {
  const { data } = await adminAPI.get<IFilter>("/credit-feed", {
    params: {
      orderBy: "updated_at",
      ...params,
    },
  });
  return data;
};

export const getExportFarmer = async (ids: string[]) => {
  try {
    const { data } = await adminAPI.post<IExportFarmer>(
      `/credit-feed/farmers/export`,
      { ids }
    );
    return data.farmers;
  } catch (error) {
    return null;
  }
};

export const getExportCreditFeed = async (ids: string[]) => {
  try {
    const { data } = await adminAPI.post<IGetCreditFeedData>(
      `/credit-feed/contract/export`,
      { ids }
    );
    return data.creditFeeds;
  } catch (error) {
    return null;
  }
};

import {
  IDownloadUrlCreateFeed,
  IGetUploadUrlCreateFeed,
} from "../types/CreditFeed.type";
import { IGetAllContract } from "../types/Financial.type";
import api from "./ApiService";

export const getAllContract = async () => {
  try {
    const { data } = await api.get<IGetAllContract>("/farmers/contract");
    return data;
  } catch (error) {
    return null;
  }
};

export const getUploadUrlContract = async (id: string) => {
  const { data } = await api.get<IGetUploadUrlCreateFeed>(
    `/farmers/contract/upload/${id}`
  );
  return data;
};

export const getDownloadUrl = async (id: string, fileId: string) => {
  const { data } = await api.get<IDownloadUrlCreateFeed>(
    `/farmers/contract/download/${id}/${fileId}`
  );
  return data;
};

export const UpdateContract = async (body: {
  id: string;
  fattenerPigCount: number;
}) => {
  const { id, ...props } = body;
  const { data } = await api.put(`/farmers/contract/edit/${id}`, props);
  return data;
};

export const ApproveOrDeniedContract = async (body: {
  id: string;
  isApprove: boolean;
  citizenDocument: {
    id: string;
    fileName: string;
    fileSize: number;
  }[];
  creditBureauDocument: {
    id: string;
    fileName: string;
    fileSize: number;
  }[];
}) => {
  const { id, ...props } = body;
  const { data } = await api.put(`/farmers/contract/accept/${id}`, props);
  return data;
};

import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { tw } from "../../../../utils/tw";
import {
  createFilterOptions,
  InputAdornment,
  Modal,
  Typography,
  Autocomplete,
  TextField,
  Tab,
  FormControl,
  FormHelperText,
} from "@mui/material";
import AppBarFS from "../../../../components/app-bar/AppBar";
import DatePickerFS from "../../../../components/common/DatePicker";
import EventIcon from "@mui/icons-material/Event";
import {
  Dropdown,
  TDropdownValue,
} from "../../../../admin/pages/group-area/views/Dropdown";
import { Input } from "../../../../admin/pages/group-area/views/Input";
import { InputImage } from "./InputImage";
import ButtonFillCustom from "../../../../admin/components/Button/ButtonFillCustom";
import SearchIcon from "@mui/icons-material/Search";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import TextInputCustom from "../../../../admin/components/Input/TextInputCustom";
import {
  defaultFormMedicineStock,
  TFormMedicineStock,
} from "../defaultForm.type";
import { get, isEmpty, some } from "lodash";
import { IMedicineAllDetail } from "../controller";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import {
  createMedicineStock,
  getUpLoadUrlStockManagement,
  removeMedicineStock,
} from "../../../../services/StockManagement.service";
import { loadingActions } from "../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../utils/getErrorMessage";
import axios from "axios";
import dayjs from "dayjs";
import { FILTER_DATE_FORMAT } from "../../../../admin/pages/sale-order/components/SaleOrderSearchFilter";
import { RowListMedicineStock } from "../../../../types/StockMangement.type";
import { getCommaNumber } from "../../../../utils/number";
import Tabs from "../../../../admin/pages/group-area/views/Tabs";
import * as Y from "yup";
import useFormValidator from "../../../../hooks/useFormValidator.hook";

interface IAddMedicineStockModal {
  isOpen: boolean;
  onClose: () => void;
  medicineCategoryOption: TDropdownValue[];
  medicineDetailIdOption: TDropdownValue[];
  medicineAllDetailIdOption: IMedicineAllDetail[];
  form: TFormMedicineStock;
  setForm: Dispatch<SetStateAction<TFormMedicineStock>>;
  onChangeForm: (key: keyof TFormMedicineStock) => (value: any) => void;
  addMedicine: {
    inputValue: string;
  };
  setAddMedicine: Dispatch<
    SetStateAction<{
      inputValue: string;
    }>
  >;
  itemEdit: RowListMedicineStock[];
  isEdit: boolean;
  onCloseEdit: () => void;
  setIdEdit: Dispatch<SetStateAction<string>>;
  farmId: string;
}

const classes = {
  wrapper: tw(`!z-0 w-full h-full overflow-scroll !bg-[white]`),
  container: tw(`w-full h-full relative table bg-[white] `),
  headerContainer: tw(`min-h-[120px] flex items-center rounded-b-[40px]`),
  detailsContainer: tw(`flex flex-col px-4 gap-4`),
  button: tw(`!h-[59px] w-full !rounded-[50px] !text-[16px] !font-bold`),
};

const AddMedicineStockModal = (props: IAddMedicineStockModal) => {
  const {
    isOpen,
    onClose,
    medicineCategoryOption,
    medicineDetailIdOption,
    medicineAllDetailIdOption,
    form,
    setForm,
    onChangeForm,
    addMedicine,
    setAddMedicine,
    itemEdit,
    isEdit,
    onCloseEdit,
    setIdEdit,
    farmId,
  } = props;

  const maxAmount = get(itemEdit, [0, "amount"], 0);

  const errorSchemaSelect = Y.object({
    medicineCategoryId: Y.array().min(1, "กรุณาเลือกหมวดหมู่ของยา"),
    medicineDetailId: Y.array().min(1, "กรุณาเลือกชื่อยา"),
    amount: Y.string()
      .required("กรุณากรอกจำนวนยา")
      .test("is-greater-than-zero", "จำนวนยาต้องมากกว่า 0", (value) => {
        const parsedValue = parseFloat(value || "");
        return !isNaN(parsedValue) && parsedValue > 0;
      }),
    price: Y.string().required("กรุณากรอกราคายา"),
  });

  const errorSchemaSearch = Y.object({
    name: Y.string().required("กรุณากรอกชื่อยา"),
    unit: Y.string().required("กรุณากรอกหน่วยของยา"),
    amount: Y.string()
      .required("กรุณากรอกจำนวนยา")
      .test("is-greater-than-zero", "จำนวนยาต้องมากกว่า 0", (value) => {
        const parsedValue = parseFloat(value || "");
        return !isNaN(parsedValue) && parsedValue > 0;
      }),
    price: Y.string().required("กรุณากรอกราคายา"),
  });

  const errorSchemaEdit = Y.object({
    amount: Y.string()
      .required("กรุณากรอกจำนวนยา")
      .test("is-greater-than-zero", "จำนวนยาต้องมากกว่า 0", (value) => {
        const parsedValue = parseFloat(value || "");
        return !isNaN(parsedValue) && parsedValue > 0;
      }),
    price: Y.string().required("กรุณากรอกราคายา"),
  });

  const errorSchemaRemove = Y.object({
    amount: Y.string()
      .required("กรุณากรอกจำนวนยา")
      .test("is-greater-than-zero", "จำนวนยาต้องมากกว่า 0", (value) => {
        const parsedValue = parseFloat(value || "");
        return !isNaN(parsedValue) && parsedValue > 0;
      })
      .test(
        "is-less-than-max",
        `จำนวนต้องไม่มากกว่าจำนวนยาที่มี ${getCommaNumber(maxAmount, true)}`,
        (value) => {
          const parsedValue = parseFloat(value || "");
          return !isNaN(parsedValue) && parsedValue <= maxAmount;
        }
      ),
  });

  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();

  const [tabIndex, setTabIndex] = useState(0);

  const onChangeTab = useCallback((e: any, index: number) => {
    setTabIndex(index);
  }, []);

  const [images, setImages] = useState<(File | { id: string; url: string })[]>(
    []
  );

  const onAddImage = useCallback(
    (type: "image") => (images: File[]) => {
      const maxFile = 2;
      setImages((prev) => [...images, ...prev].slice(0, maxFile));
    },
    []
  );

  const onRemoveImage = useCallback(
    (type: "image", index: number) => () => {
      setImages((prev) => prev.filter((_, i) => i !== index));
    },
    []
  );

  const filter = createFilterOptions<IMedicineAllDetail>();

  const checkAdd = addMedicine.inputValue.indexOf("เพิ่ม");

  const CreateMedicineStockMutation = useMutation({
    mutationFn: createMedicineStock,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["list_medicine_stock"],
      });
      setTimeout(() => {
        onCloseFn();
        dispatch(loadingActions.hide());
        setTimeout(() => {
          dispatch(
            snakeActions.showMessage({
              message: isEdit ? "Edit Success!" : "Create Success!",
              type: "success",
            })
          );
        }, 500);
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const RemoveMedicineStockMutation = useMutation({
    mutationFn: removeMedicineStock,
    onSuccess: async (data) => {
      if (data.success) {
        await queryClient.refetchQueries({
          queryKey: ["list_medicine_stock"],
        });
        setTimeout(() => {
          onCloseFn();
          dispatch(loadingActions.hide());
          setTimeout(() => {
            dispatch(
              snakeActions.showMessage({
                message: "Remove Success!",
                type: "success",
              })
            );
          }, 500);
        });
      } else {
        setTimeout(() => {
          dispatch(loadingActions.hide());
          dispatch(
            snakeActions.showMessage({
              message: getErrorMessage(data.message),
              type: "error",
            })
          );
        }, 1500);
      }
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const { onSubmit, errors, resetError } = useFormValidator({
    form: {
      ...form,
    },
    errorSchema: isEdit
      ? errorSchemaEdit
      : isEmpty(form.name)
      ? errorSchemaSelect
      : errorSchemaSearch,
    onValidateSuccess: async () => {
      const imgs = [...images];
      const fileArray: {
        id: string;
        url: string;
        fileName: string;
        fileSize: number;
      }[] = [];
      dispatch(loadingActions.show({ message: "loading..." }));
      try {
        for (let i = 0; i < imgs.length; i++) {
          const img = imgs[i];
          if (img instanceof File) {
            // upload new image
            const presign = await getUpLoadUrlStockManagement();
            await axios.put(presign.blob.blobUrl, img, {
              responseType: "blob",
              headers: {
                "x-ms-blob-type": "BlockBlob",
              },
            });
            fileArray.push({
              id: presign.blob.fileId,
              url: presign.blob.url || "",
              fileName: img.name,
              fileSize: img.size,
            });
          }
        }
        CreateMedicineStockMutation.mutate({
          farmId: farmId,
          addDate: dayjs(form.date, FILTER_DATE_FORMAT).startOf("day").toDate(),
          medicineCategoryId: isEdit
            ? +itemEdit[0].medicineCategoryId
            : form.medicineCategoryId[0] === undefined
            ? 16
            : +form.medicineCategoryId[0].value,
          medicineDetailId: isEdit
            ? +itemEdit[0].medicineDetailId
            : form.medicineCategoryId[0] === undefined
            ? null
            : +form.medicineDetailId[0].value,
          name: isEdit ? itemEdit[0].medicineDetail.tradeName : form.name,
          unit: isEdit ? itemEdit[0].medicineDetail.unit : form.unit,
          amount: +form.amount,
          price: +form.price,
          documents: fileArray.map((f) => ({
            fileId: f.id,
            fileName: f.fileName,
          })),
        });
      } catch (e) {
        setTimeout(() => {
          dispatch(loadingActions.hide());
          dispatch(
            snakeActions.showMessage({
              message: getErrorMessage(e),
              type: "error",
            })
          );
        }, 1500);
      }
    },
  });

  const {
    onSubmit: onRemove,
    errors: errorsRemove,
    resetError: resetErrorRemove,
  } = useFormValidator({
    form: {
      ...form,
    },
    errorSchema: errorSchemaRemove,
    onValidateSuccess: () => {
      dispatch(loadingActions.show({ message: "loading..." }));
      RemoveMedicineStockMutation.mutate({
        farmerStockMedicineId: itemEdit[0].id,
        addDate: dayjs(form.date, FILTER_DATE_FORMAT).startOf("day").toDate(),
        amount: +form.amount,
      });
    },
  });

  const onCloseFn = useCallback(() => {
    onClose();
    onCloseEdit();
    setForm(defaultFormMedicineStock);
    setImages([]);
    setAddMedicine({ inputValue: "" });
    setIdEdit("");
    setTabIndex(0);
    resetError();
    resetErrorRemove();
  }, []);

  useEffect(() => {
    if (isEmpty(form.name)) {
      resetError();
    }
  }, [form.name]);

  useEffect(() => {
    setForm(defaultFormMedicineStock);
    setImages([]);
    resetError();
    resetErrorRemove();
  }, [tabIndex]);

  return (
    <Modal open={isOpen} className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <AppBarFS
            title={isEdit ? "Edit Medicine Stock" : "Add Medicine Stock"}
            onIconPress={onCloseFn}
          />
        </div>
        <div className={classes.detailsContainer}>
          {isEdit ? (
            <div className="flex flex-col gap-3 pb-4">
              <div className="text-[#34407B] text-[20px] font-semibold">
                {get(itemEdit, [0, "medicineDetail", "tradeName"], "")}
              </div>
              <div className="bg-[#F6FBFF] py-3 border-[2px] border-[#3777BC] rounded-[20px] flex flex-col gap-4 items-center">
                <div className="font-bold text-[16px] text-[#3777BC]">
                  สต๊อกคงเหลือ
                </div>
                <div className="font-bold text-[22px] text-[#34407B]">
                  {getCommaNumber(get(itemEdit, [0, "amount"], 0), true)}{" "}
                  {get(itemEdit, [0, "medicineDetail", "unit"], "")}
                </div>
              </div>
            </div>
          ) : (
            <div className="text-[#3777BC] text-[20px] font-semibold">
              Add Medicine Stock
            </div>
          )}
          {isEdit && (
            <div className="flex flex-row justify-center">
              <Tabs tabIndex={tabIndex} onChange={onChangeTab} isScroll>
                <Tab
                  label="Add Stock"
                  className="!font-semibold !text-[18px]"
                />
                <Tab
                  label="Remove Stock"
                  className="!font-semibold !text-[18px]"
                />
              </Tabs>
            </div>
          )}
          {tabIndex === 0 && (
            <>
              <DatePickerFS
                value={form.date}
                label="Date"
                placeholder={`Enter Date`}
                className="!h-[56px]"
                slotProps={{
                  textField: {
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <EventIcon color="info" />
                        </InputAdornment>
                      ),
                    },
                  },
                }}
                onChange={onChangeForm("date")}
                onClose={() => {}}
              />
              {!isEdit && (
                <>
                  <Dropdown
                    options={medicineCategoryOption}
                    onChange={onChangeForm("medicineCategoryId")}
                    renderValueArray={form.medicineCategoryId}
                    shrink
                    label2="Medicine Category"
                    switchStyle
                    placeholder="Select Category"
                    containerSelect="pb-0"
                    disabled={!isEmpty(form.name)}
                    onReset={() => {
                      setForm((prev) => ({
                        ...prev,
                        medicineCategoryId: [],
                        medicineDetailId: [],
                      }));
                      resetError();
                    }}
                    errorMessage={errors.medicineCategoryId}
                  />
                  <Dropdown
                    options={medicineDetailIdOption}
                    onChange={onChangeForm("medicineDetailId")}
                    renderValueArray={form.medicineDetailId}
                    shrink
                    label2="Name of Medicine"
                    switchStyle
                    placeholder="Select Medicine"
                    containerSelect="pb-0"
                    disabled={isEmpty(form.medicineCategoryId)}
                    onReset={() =>
                      setForm((prev) => {
                        return {
                          ...prev,
                          medicineDetailId: [],
                          unit: "",
                        };
                      })
                    }
                    errorMessage={errors.medicineDetailId}
                  />
                  <div className="flex justify-center text-[#3777BC] text-[14px] font-normal">
                    or
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="text-[#3777BC] text-[16px] font-medium px-2">
                      Search Medicine Label
                    </div>
                    <FormControl
                      fullWidth
                      error={!!errors.name}
                      style={{
                        borderColor: "#3777BC",
                      }}
                    >
                      <Autocomplete
                        disabled={!isEmpty(form.medicineCategoryId)}
                        options={medicineAllDetailIdOption}
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          return option.label;
                        }}
                        renderOption={(props, option) => {
                          const { ...optionProps } = props;

                          return (
                            <li {...optionProps} key={option.key}>
                              {option.label}
                            </li>
                          );
                        }}
                        value={form.name || ""}
                        onChange={(event: any, newValue: any) => {
                          if (typeof newValue === "string") {
                            onChangeForm("name")(newValue);
                          } else if (newValue && newValue.inputValue) {
                            onChangeForm("name")(newValue.inputValue);
                            setAddMedicine((prev) => ({
                              ...prev,
                              inputValue: newValue?.label || "",
                            }));
                          } else {
                            onChangeForm("name")(newValue?.label || "");
                            onChangeForm("unit")(newValue?.unit || "");
                            setAddMedicine((prev) => ({
                              ...prev,
                              inputValue: newValue?.label || "",
                            }));
                          }
                        }}
                        filterOptions={(options: any, params: any) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;

                          const isExisting = options.some(
                            (option: any) => inputValue === option.label
                          );

                          if (inputValue !== "" && !isExisting) {
                            filtered.push({
                              inputValue,
                              label: `เพิ่ม "${inputValue}"`,
                              value: "",
                              package: "",
                              unit: "",
                            });
                          }
                          return filtered;
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        freeSolo
                        className={tw(
                          "rounded-[28px] border-[#3777BC] py-[8px] px-[0px] flex flex-row justify-center items-center w-[328px] betagro-autocomplete",
                          "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] !w-full"
                        )}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            placeholder={"Search Medicine"}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon
                                    className={tw(
                                      "text-[24px] text-[#3777BC]",
                                      !isEmpty(form.medicineCategoryId) &&
                                        "text-[#979797]"
                                    )}
                                  />
                                </InputAdornment>
                              ),
                              style: {
                                border: "1px",
                              },
                              className:
                                "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] !border-[1px] !w-full !px-[14px] ",
                              sx: {
                                borderRadius: "28px !important",
                                "& .MuiInputBase-input": {
                                  padding: "0px !important",
                                  ":-webkit-autofill": {
                                    bgcolor: "transparent",
                                  },
                                },
                                "& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline":
                                  {
                                    border: "none",
                                  },
                                "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: !isEmpty(
                                      form.medicineCategoryId
                                    )
                                      ? "#979797  !important"
                                      : "#3777BC  !important",
                                  },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "transparent",
                                },
                                "& .Mui-focused": {
                                  background: "#FFFFFF",
                                  "& > svg": {
                                    color: "#000000 !important",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderWidth: "0px !important",
                                  },
                                },
                                "& input::placeholder": {
                                  opacity: 1,
                                  color: "#979797",
                                },
                              },
                            }}
                            sx={{
                              "& .Mui-disabled": {
                                color: "#979797 !important",
                                WebkitTextFillColor: "#979797 !important",
                                bgcolor: "#FFFFFF !important",
                                borderColor: "#979797 !important",
                              },
                            }}
                          />
                        )}
                      />
                      {errors.name && (
                        <FormHelperText>{errors.name}</FormHelperText>
                      )}
                    </FormControl>
                  </div>
                </>
              )}
              <Input
                label2="จำนวน"
                focused
                placeholder="จำนวน"
                value={form.amount}
                onChangeText={onChangeForm("amount")}
                inputType="number"
                customInputStyle={tw(
                  "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
                )}
                containerDetailClassName="pb-0"
                borderRadius={"28px"}
                borderColor={"#3777BC !important"}
                errorMessage={errors.amount}
              />
              {checkAdd === 0 ? (
                <Input
                  label2="หน่วย"
                  focused
                  placeholder="หน่วย"
                  value={form.unit}
                  onChangeText={onChangeForm("unit")}
                  customInputStyle={tw(
                    "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
                  )}
                  containerDetailClassName="pb-0"
                  borderRadius={"28px"}
                  borderColor={"#3777BC !important"}
                />
              ) : (
                <TextInputCustom
                  focused={true}
                  disabled
                  label={"หน่วย"}
                  placeholder="หน่วย"
                  value={
                    form.unit ||
                    get(itemEdit, [0, "medicineDetail", "unit"], "")
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon color="disabled" fontSize="small" />
                      </InputAdornment>
                    ),
                    style: {
                      border: "1px",
                    },
                    className:
                      "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] !border-[1px]",
                    sx: {
                      borderRadius: "28px !important",
                      "& .MuiInputBase-input": {
                        padding: 0,
                        color: "#979797 !important",
                      },
                      "& .Mui-disabled": {
                        color: "#979797  !important",
                        WebkitTextFillColor: "#979797  !important",
                        bgcolor: "#FBFBFB !important",
                      },
                    },
                  }}
                  error={errors.unit}
                />
              )}
              <Input
                label2="ราคาทั้งหมด"
                focused
                placeholder="ราคา"
                value={form.price}
                onChangeText={onChangeForm("price")}
                inputType="number"
                customInputStyle={tw(
                  "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
                )}
                containerDetailClassName="pb-0"
                borderRadius={"28px"}
                borderColor={"#3777BC !important"}
                customSuffix={
                  <div className="pr-[5px] font-medium text-[16px] leading-[24px] text-[#D9EBFE]">
                    | <span className="text-[#3777BC]">บาท</span>
                  </div>
                }
                errorMessage={errors.price}
              />
              <InputImage
                onDrop={onAddImage("image")}
                maxFiles={2}
                images={images}
                onRemove={onRemoveImage}
              />
              <div className="w-full pt-1 pb-6">
                <ButtonFillCustom
                  onClick={onSubmit}
                  title="ยืนยัน"
                  btnTextColor="#ffffff"
                  btnBgColor="#68C184"
                  className={classes.button}
                  disabled={
                    isEdit
                      ? some([form.amount, form.price], isEmpty)
                      : isEmpty(form.name)
                      ? some(
                          [
                            form.medicineCategoryId,
                            form.medicineDetailId,
                            form.amount,
                            form.price,
                          ],
                          isEmpty
                        )
                      : some(
                          [form.name, form.unit, form.amount, form.price],
                          isEmpty
                        )
                  }
                />
              </div>
            </>
          )}
          {tabIndex === 1 && (
            <>
              <DatePickerFS
                value={form.date}
                label="Date"
                placeholder={`Enter Date`}
                className="!h-[56px]"
                slotProps={{
                  textField: {
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <EventIcon color="info" />
                        </InputAdornment>
                      ),
                    },
                  },
                }}
                onChange={onChangeForm("date")}
                onClose={() => {}}
              />
              <Input
                label2="จำนวน"
                focused
                placeholder="จำนวน"
                value={form.amount}
                onChangeText={onChangeForm("amount")}
                inputType="number"
                customInputStyle={tw(
                  "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
                )}
                containerDetailClassName="pb-0"
                borderRadius={"28px"}
                borderColor={"#3777BC !important"}
                errorMessage={errorsRemove.amount}
              />
              <TextInputCustom
                focused={true}
                disabled
                label={"หน่วย"}
                placeholder="หน่วย"
                value={
                  form.unit || get(itemEdit, [0, "medicineDetail", "unit"], "")
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon color="disabled" fontSize="small" />
                    </InputAdornment>
                  ),
                  style: {
                    border: "1px",
                  },
                  className:
                    "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] !border-[1px]",
                  sx: {
                    borderRadius: "28px !important",
                    "& .MuiInputBase-input": {
                      padding: 0,
                      color: "#979797 !important",
                    },
                    "& .Mui-disabled": {
                      color: "#979797  !important",
                      WebkitTextFillColor: "#979797  !important",
                      bgcolor: "#FBFBFB !important",
                    },
                  },
                }}
              />
              <div className="w-full pt-1 pb-6">
                <ButtonFillCustom
                  onClick={onRemove}
                  title="ยืนยัน"
                  btnTextColor="#ffffff"
                  btnBgColor="#68C184"
                  className={classes.button}
                  disabled={some([form.amount], isEmpty)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default memo(AddMedicineStockModal);

import { MenuItem } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import PersonSvg from "../../../../../assets/svg/PersonSvg";
import { loadingActions } from "../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../utils/getErrorMessage";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import RHFTextInputCustom from "../../../../components/Input/RHFTextInputCustom";
import RHFSelectCustom from "../../../../components/Select/RHFSelectCustom";
import {
  getPersonalInformation,
  putPersonalInformation,
} from "../../services/farmDetail.service";
import {
  IGetPersonalInformation,
  TPersonalInformationForm,
} from "../../types/personalInformations.type";

type Props = {
  personalId?: string;
};

const PersonalInformation = ({ personalId }: Props) => {
  const [personal, setPersonal] = useState<IGetPersonalInformation | null>();
  const segmentOption = ["Potential", "Grower", "Independent"];
  const dispatch = useDispatch();

  const methods = useForm<TPersonalInformationForm>({
    defaultValues: {
      segment: "",
      totalPig: "",
    },
  });

  const handleFetchPersonal = async () => {
    const data = await getPersonalInformation(personalId || "");
    setPersonal(data);
  };
  const handleSubmit = async (value: TPersonalInformationForm) => {
    try {
      dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
      await putPersonalInformation(personalId || "", value);
      dispatch(loadingActions.hide());
      dispatch(
        snakeActions.showMessage({
          message: "บันทึกสำเร็จ",
          type: "success",
        })
      );
    } catch (error) {
      dispatch(loadingActions.hide());
      dispatch(
        snakeActions.showMessage({
          message: getErrorMessage(error),
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    if (personal) {
      methods.reset({
        segment: personal?.farmer.segment,
        totalPig: personal?.farmer.totalPig,
      });
    }
  }, [personal]);

  useEffect(() => {
    handleFetchPersonal();
  }, []);
  return (
    <>
      <div className="flex gap-2 text-center items-center mt-6">
        <PersonSvg />
        <p className="text-lg font-bold text-[#3777BC] font-sans">
          ข้อมูลส่วนตัว
        </p>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <div className="flex gap-3 mt-4">
            <div className="w-[50%]">
              <p className="text-[14px] mb-3 font-sans font-bold">รหัสลูกค้า</p>
              <p className="text-[14px] w-[340px] pb-3 font-sans">
                {personal?.farmer.registrationId || "-"}
              </p>
            </div>
            <div className="w-[50%]">
              <p className="text-[14px] mb-3 font-sans font-bold">ชื่อลูกค้า</p>
              <p className="text-[14px] w-[340px] pb-3 font-sans">
                {personal?.farmer.name + " " + personal?.farmer.surname}
              </p>
            </div>
          </div>
          <div className="flex gap-3 mt-4">
            <div className="w-[50%]">
              <p className="text-[14px] mb-3 font-sans font-bold">
                เบอร์โทรศัพท์
              </p>
              <p className="text-[14px] w-[340px] pb-3 font-sans">
                {personal?.farmer.mobileNumber || "-"}
              </p>
            </div>
            <div className="w-[50%]">
              <p className="text-[14px] mb-3 font-sans font-bold">อีเมล</p>
              <p className="text-[14px] w-[340px] pb-3 font-sans">
                {personal?.farmer.name + " " + personal?.farmer.email}
              </p>
            </div>
          </div>
          <div className="flex gap-3 mt-4">
            <div className="w-[50%]">
              <p className="text-[14px] mb-3 font-sans font-bold">Segment</p>
              <div className="w-full pb-3">
                <RHFSelectCustom
                  value={methods.watch("segment")}
                  controlName="segment"
                  menuItems={segmentOption.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                  renderValueArray={segmentOption.map((row) => ({
                    value: row,
                    label: row,
                  }))}
                />
              </div>
            </div>
            <div className="w-[50%]">
              <p className="text-[14px] mb-3 font-sans font-bold">ขนาดฟาร์ม</p>
              <div className="w-full pb-3 font-sans">
                <RHFTextInputCustom controlName="totalPig" placeholder="กรอก" />
              </div>
            </div>
          </div>
          <div className="flex gap-3 mt-4">
            <div className="w-full">
              <p className="text-[14px] mb-3 font-sans font-bold">Type</p>
              <div className="w-[340px] pb-3 font-sans">
                {personal?.farmer.farmerType || "-"}
              </div>
            </div>
          </div>
          <div className="flex gap-3 mt-4">
            <div className="w-[50%]">
              <p className="text-[14px] mb-3 font-sans font-bold">
                วันเเรกที่เริ่มใช้งานเเอป
              </p>
              <p className="text-[14px] w-[340px] pb-3 font-sans">
                {dayjs(personal?.farmer.createdAt).format("DD/MM/YYYY")}
              </p>
            </div>
            <div className="w-[50%]">
              <p className="text-[14px] mb-3 font-sans font-bold">Group Area</p>
              <p className="text-[14px] w-[340px] pb-3 font-sans">
                {personal?.farmer.userGroup?.name || "-"}
              </p>
            </div>
          </div>
          <div className="flex gap-3 mt-4">
            <div className="w-[50%]">
              <p className="text-[14px] mb-3 font-sans font-bold">
                ชื่อพนักงานขาย
              </p>
              <p className="text-[14px] w-[340px] pb-3 font-sans">
                {personal?.farmer.userGroup
                  ? personal?.farmer.userGroup?.seller?.name
                  : "-"}
              </p>
            </div>
            <div className="w-[50%]">
              <p className="text-[14px] mb-3 font-sans font-bold">
                ชื่อพาร์ทเนอร์
              </p>
              <p className="text-[14px] w-[340px] pb-3 font-sans">
                {personal?.farmer.userGroup
                  ? personal?.farmer.userGroup?.partner?.name +
                    " " +
                    personal.farmer.userGroup.partner.surname
                  : "-"}
              </p>
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <ButtonFillCustom
              title={"บันทึก"}
              type="submit"
              disabled={!methods.formState.isDirty}
            />
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default PersonalInformation;

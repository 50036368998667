import axios from "axios";
import dayjs from "dayjs";
import i18next from "i18next";
import { get } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FenceSVG from "../../../../assets/svg/Fence";
import Receipt from "../../../../assets/svg/Receipt";
import AppBarFS from "../../../../components/app-bar/AppBar";
import DownloadLinkWithDelete from "../../../../components/common/File/DownloadLinkWithDelete";
import { getDownloadUrl } from "../../../../services/Financial.service";
import { handleDownloadBlob } from "../../../../utils/downloadFile";
import { getCommaNumber } from "../../../../utils/number";
import CountChart from "../../financial-contract/pages/CountChart";
import DetailAndIcon from "../../financial-contract/pages/DetailAndIcon";

interface FileBeforeUpload {
  fileName: string;
  fileSize: number;
  id: string;
}

const FinancialShowHistoryDetail = () => {
  let navigate = useNavigate();
  const { state } = useLocation();

  const { data } = state;

  const handleBack = useCallback(() => {
    navigate("/financial-support/financial-history");
  }, []);

  const [customerPickingSlip, setCustomerPickingSlip] = useState<
    FileBeforeUpload[]
  >([]);

  const handleDownloadByUrl = useCallback(
    (id: string, fileId: string, name: string) => async () => {
      const preSignURL = await getDownloadUrl(id, fileId);
      if (preSignURL) {
        const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
          responseType: "blob",
        });
        const objectURL = window.URL.createObjectURL(response.data);
        handleDownloadBlob(objectURL, name);
      }
    },
    [customerPickingSlip]
  );

  let statusContractType: any =
    data.creditInformation.contractType === "pay_after_expire"
      ? "จ่ายหลังหมดสัญญา"
      : data.creditInformation.contractType === "fill_credit" &&
        "จ่ายตาม invoice";

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <div className="bg-[#d9ebfe] w-full min-h-full">
      <div className="w-full h-full">
        <div className="h-[120px] flex items-center bg-[#FFFF] rounded-b-[40px]">
          <AppBarFS title={"ประวัติของสัญญาทั้งหมด"} onIconPress={handleBack} />
        </div>
        <div className="flex flex-row py-[16px] px-4 justify-between">
          <span className="font-semibold text-[20px] text-[#3777BC] flex flex-col justify-center">
            สัญญาทั้งหมด
          </span>
        </div>
        <div className="px-4 flex flex-col gap-4 pb-[32px]">
          <div className="bg-[#FFFF] rounded-[15px] px-4 pt-4 pb-6 flex flex-col gap-3">
            <div className="text-[#34407B] text-[16px] font-semibold flex flex-row gap-[11px]">
              <FenceSVG />
              {data.contractNumber}
            </div>
            <div className="flex flex-col gap-[6px] text-[#4B4B4B] text-[14px] font-medium leading-5">
              <div className="flex flex-row justify-between">
                <div className="">เลขที่สัญญา</div>
                <div className="text-end">{data.contractNumber}</div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="">ประเภทสัญญา</div>
                <div className="text-end">{statusContractType}</div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="">วันเริ่มสัญญา</div>
                <div className="text-end">
                  {/* {dayjs(data.startDate).isValid()
                    ? dayjs(data.startDate)
                        .locale(i18next.language)
                        .format(
                          i18next.language === "th"
                            ? "DD/MM/BBBB"
                            : "DD/MM/YYYY"
                        )
                    : "-"} */}
                  {dayjs(data.startDate).isValid()
                    ? dayjs(data.startDate)
                        .locale(i18next.language)
                        .format("DD/MM/YYYY")
                    : "-"}
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="">วันสิ้นสุดสัญญา</div>
                <div className="text-end">
                  {/* {dayjs(data.expireDate).isValid()
                    ? dayjs(data.expireDate)
                        .locale(i18next.language)
                        .format(
                          i18next.language === "th"
                            ? "DD/MM/BBBB"
                            : "DD/MM/YYYY"
                        )
                    : "-"} */}
                  {dayjs(data.expireDate).isValid()
                    ? dayjs(data.expireDate)
                        .locale(i18next.language)
                        .format("DD/MM/YYYY")
                    : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-auto px-4 pb-[32px]">
          <div className="flex flex-col gap-3 w-full h-auto bg-[#FFFFFF] rounded-[20px]">
            <div className="w-full h-full flex flex-col items-center py-4 ">
              <CountChart
                dataset={[
                  {
                    name: "remaining-pigs",
                    value: data.creditUsed,
                    fill: "#F1EFFB",
                  },
                  {
                    name: "exceedAmount",
                    value: data.exceedAmount,
                    fill: "#F1EFFB",
                  },
                  {
                    name: "sold-pigs",
                    value: data.credit,
                  },
                ]}
              />
            </div>
            <DetailAndIcon data={data} />
            <div className="flex flex-col px-3 pb-3">
              <div className="bg-[#F6FBFF] rounded-[10px] gap-1">
                <div className="flex flex-row justify-between p-3 border-[#D9EBFE] border-b-[1px]">
                  <div className="text-[#4B4B4B] text-[16px] font-normal">
                    ชื่อฟาร์ม
                  </div>
                  <div className="text-[#3777BC] text-right text-[16px] font-normal">
                    {data.house.farm.name}
                  </div>
                </div>
                <div className="flex flex-row justify-between p-3 border-[#D9EBFE] border-b-[1px]">
                  <div className="text-[#4B4B4B] text-[16px] font-normal">
                    ชื่อโรงเรือน
                  </div>
                  <div className="text-[#3777BC] text-right text-[16px] font-normal">
                    {data.house.name}
                  </div>
                </div>
                <div className="flex flex-row justify-between p-3 border-[#D9EBFE] border-b-[1px]">
                  <div className="text-[#4B4B4B] text-[16px] font-normal">
                    ประเภทหมู
                  </div>
                  <div className="text-[#3777BC] text-right text-[16px] font-normal">
                    หมูขุน
                  </div>
                </div>
                <div className="flex flex-row justify-between p-3 ">
                  <div className="text-[#4B4B4B] text-[16px] font-normal">
                    จำนวนหมู
                  </div>
                  <div className="text-[#3777BC] text-right text-[16px] font-normal">
                    {getCommaNumber(data.pigAmount)}
                  </div>
                </div>
              </div>
            </div>
            {data?.creditInformation?.contractDocument?.length > 0 && (
              <div className="flex flex-col px-3 pb-4 gap-2">
                <div className="flex flex-row px-3 gap-2">
                  <Receipt />
                  <div className="text-[#3777BC] text-[16px] font-normal">
                    เอกสารสัญญา
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <div className="text-[#979797] text-[16px] font-normal pl-[48px]">
                    เอกสารสัญญา
                  </div>
                  <DownloadLinkWithDelete
                    onDownload={handleDownloadByUrl(
                      get(data, ["id"]),
                      get(data, [
                        "creditInformation",
                        "contractDocument",
                        0,
                        "id",
                      ]),
                      get(data, [
                        "creditInformation",
                        "contractDocument",
                        0,
                        "fileName",
                      ])
                    )}
                    onDelete={async () => {
                      setCustomerPickingSlip([]);
                    }}
                    sx={{
                      fontSize: "16px",
                      color: "#979797",
                      width: "auto",
                      padding: "0 !important",
                      margin: "0 !important",
                      ":hover": {
                        background: "#FFFFFF",
                      },
                    }}
                    hideClip
                    hideDeleteBtn
                    name={"ดูเอกสาร"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialShowHistoryDetail;

import { Button, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { object, string } from "yup";
import AppBarFS from "../../../../components/app-bar/AppBar";
import AuthBox from "../../../../components/common/AuthBox";
import { CountdownTime } from "../../../../components/common/CountdownTimer/CountdownTime";
import {
  partnerRequestResetPINService,
  partnerVerifyPINService,
} from "../../../../services/PartnerResetPinService";

export default function PartnerPinAuthentication() {
  const { t } = useTranslation();
  const username = localStorage.getItem("username");
  const navigate = useNavigate();
  const [timeShowing, setTimeShowing] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const formik = useFormik({
    initialValues: {
      otp: "",
      formToken: "",
      mobileNumber: "",
    },
    validationSchema: object({
      otp: string().min(4, "").required("OTP is required"),
    }),
    onSubmit: async ({ otp, formToken, mobileNumber }) => {
      try {
        const res = await partnerVerifyPINService(formToken, mobileNumber, otp);
        setTimeShowing(false);
        setError(false);
        navigate("/partner/reset-pin/update-pin", {
          state: {
            mobileNumber: mobileNumber,
            token: res.data.token,
            otpCode: otp,
          },
        });
      } catch (error) {
        setTimeShowing(false);
        setError(true);
      }
    },
  });

  const handleResendOTP = () => {
    resendOTP();
    setTimeShowing(true);
  };

  const handleTimerComplete = () => {
    setTimeShowing(false);
  };

  const handleOTPChange = (val: string) => {
    formik.setFieldValue("otp", val);
    error && setError(false);
  };

  const otpTemplate = (
    <div className="flex items-center gap-x-4">
      <CountdownTime duration={60} onComplete={handleTimerComplete} />
      <Typography variant="body2">Auto-verifying the OTP</Typography>
    </div>
  );

  const sendOTPTemplate = (
    <div className="flex items-center">
      {/* TODO: Move Color to common Place */}
      <Typography sx={{ color: "#3777BC" }}>Didn't receive OTP?</Typography>
      <Button
        variant="text"
        sx={{ textTransform: "none" }}
        color="error"
        onClick={handleResendOTP}
      >
        Ask Again
      </Button>
    </div>
  );

  const messageTemplate = () => {
    if (timeShowing) {
      return otpTemplate;
    } else {
      return sendOTPTemplate;
    }
  };

  const resendOTP = async () => {
    try {
      const resp = await partnerRequestResetPINService(username || "");
      setTimeShowing(true);
      formik.setFieldValue("mobileNumber", resp.data.mobileNumber);
      formik.setFieldValue("formToken", resp.data.token);
    } catch (error) {}
  };

  useEffect(() => {
    const loader = async () => {
      await resendOTP();
      setTimeShowing(true);
      setIsLoading(false);
    };
    loader();
  }, []);

  if (!username) {
    return <Navigate to="/partner" />;
  }

  return (
    !isLoading && (
      <form
        onSubmit={formik.handleSubmit}
        className="p-4 h-screen flex flex-col justify-between w-full"
      >
        <AppBarFS
          title={t("authentication.app_header")}
          onIconPress={() => navigate("/partner")}
        />

        <div className="flex flex-col grow mt-8 ">
          <div>
            <Typography variant="body2" textAlign="left">
              We have sent a 4-digit One-Time Password (OTP) to
            </Typography>
            <Typography variant="subtitle2" textAlign="left">
              +66 {formik.values.mobileNumber}
            </Typography>
          </div>

          <div className="flex items-center justify-center mt-8 gap-x-4">
            {messageTemplate()}
          </div>

          <div className="flex items-center justify-center mt-8">
            <AuthBox
              value={formik?.values?.otp}
              placeholder=""
              onChange={handleOTPChange}
              error={error}
              errorMessage="Incorrect OTP Please try again"
              labelStyle={{
                position: "absolute",
                bottom: "14%",
                fontSize: "14px",
                fontWeight: "400",
                left: "50%",
                transform: "translateX(-50%)",
                whiteSpace: "nowrap",
              }}
            />
          </div>
        </div>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          color="secondary"
          size="large"
        >
          {t("authentication.confirm_button")}
        </Button>
      </form>
    )
  );
}

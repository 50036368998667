import {
  alphaNumericRegExpforen,
  alphaNumericRegExpforth,
  alphaNumericSpaceRegExpforen,
  alphaNumericSpaceRegExpforth,
} from "../../../const/regex-pattern.constant";
import i18n from "../../../i18n/i18n";

// const currentLanguage = i18n.language;

export const validateFarmName = (name: string) => {
  return (
    alphaNumericSpaceRegExpforth.test(name) ||
    alphaNumericSpaceRegExpforen.test(name)
  );
};
export const validateNumiric = (gorSorNumber: string) => {
  return (
    alphaNumericRegExpforth.test(gorSorNumber) ||
    alphaNumericRegExpforen.test(gorSorNumber)
  );
};

import ReactDOMServer from "react-dom/server";
import IconFeedPigSvg from "../../../../../assets/svg/IconFeedPigSvg";
import IconUserGroupSvg from "../../../../../assets/svg/IconUserGroupSvg";
import IconVaccinesSvg from "../../../../../assets/svg/IconVaccinesSvg";
import IconWaterSvg from "../../../../../assets/svg/IconWaterSvg";
import IconProgramSvg from "../../../../../assets/svg/IconProgramSvg";
import IconDotSvg from "../../../../../assets/svg/IconDotSvg";
import IconCarSvg from "../../../../../assets/svg/IconCarSvg";
import IconFlashSvg from "../../../../../assets/svg/IconFlashSvg";
import { IGetHouseDetailsPerFlockResult } from "../../../../../types/BasicAccounting.type";
import { get } from "lodash";
import { getCommaNumber } from "../../../../../utils/number";

const RenderChangeToString = (tsxToChangeString: () => JSX.Element) =>
  ReactDOMServer.renderToString(tsxToChangeString());

const iconToBase64 = (icon: string) =>
  `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(icon)))}`;

export const DonutChartOptions = (props: {
  data?: IGetHouseDetailsPerFlockResult;
}) => {
  const { data } = props;

  const DataToShow = [
    {
      value: get(data, ["vaccineAndMedicineBill"], 0),
      name: "ค่ายาเเละวัคซีน",
      label: {
        formatter: (params: any) => {
          return [`{icon|}`].join("\n");
        },
        rich: {
          icon: {
            backgroundColor: {
              image: iconToBase64(RenderChangeToString(IconVaccinesSvg)),
            },
            width: 18,
            height: 18,
            align: "center",
            verticalAlign: "middle",
          },
        },
        backgroundColor: "#3777BC",
        borderRadius: 100,
        width: 26,
        height: 26,
        padding: 2,
        lineHeight: 26,
        textAlign: "center",
      },
      itemStyle: {
        color: "#3777BC",
        shadowColor: "rgba(55, 119, 188, 0.7)",
      },
    },
    {
      value: get(data, ["waterBill"], 0),
      name: "ค่าน้ำ",
      label: {
        formatter: (params: any) => {
          return [`{icon|}`].join("\n");
        },
        rich: {
          icon: {
            backgroundColor: {
              image: iconToBase64(RenderChangeToString(IconWaterSvg)),
            },
            width: 18,
            height: 18,
            align: "center",
            verticalAlign: "middle",
          },
        },
        backgroundColor: "#5572EB",
        borderRadius: 100,
        width: 26,
        height: 26,
        padding: 2,
        lineHeight: 26,
        textAlign: "center",
      },
      itemStyle: {
        color: "#5572EB",
        shadowColor: "rgba(85, 114, 235, 0.7)",
      },
    },
    {
      value: get(data, ["geneticBill"], 0),
      name: "ค่าพันธุ์สัตว์",
      label: {
        formatter: (params: any) => {
          return [`{icon|}`].join("\n");
        },
        rich: {
          icon: {
            backgroundColor: {
              image: iconToBase64(RenderChangeToString(IconWaterSvg)),
            },
            width: 18,
            height: 18,
            align: "center",
            verticalAlign: "middle",
          },
        },
        backgroundColor: "#EFB2B2",
        borderRadius: 100,
        width: 26,
        height: 26,
        padding: 2,
        lineHeight: 26,
        textAlign: "center",
      },
      itemStyle: {
        color: "#EFB2B2",
        shadowColor: "rgba(239, 178, 178, 0.7)",
      },
    },
    {
      value: get(data, ["programBill"], 0),
      name: "ค่าโปรมแกรม",
      label: {
        formatter: (params: any) => {
          return [`{icon|}`].join("\n");
        },
        rich: {
          icon: {
            backgroundColor: {
              image: iconToBase64(RenderChangeToString(IconProgramSvg)),
            },
            width: 18,
            height: 18,
            align: "center",
            verticalAlign: "middle",
          },
        },
        backgroundColor: "#DC5B5B",
        borderRadius: 100,
        width: 26,
        height: 26,
        padding: 2,
        lineHeight: 26,
        textAlign: "center",
      },
      itemStyle: {
        color: "#DC5B5B",
        shadowColor: "rgba(220, 91, 91, 0.7)",
      },
    },
    {
      value: get(data, ["otherBill"], 0),
      name: "ค่าใช้จ่ายอื่นๆ",
      label: {
        formatter: (params: any) => {
          return [`{icon|}`].join("\n");
        },
        rich: {
          icon: {
            backgroundColor: {
              image: iconToBase64(RenderChangeToString(IconDotSvg)),
            },
            width: 18,
            height: 18,
            align: "center",
            verticalAlign: "middle",
          },
        },
        backgroundColor: "#7A1B1B",
        borderRadius: 100,
        width: 26,
        height: 26,
        padding: 2,
        lineHeight: 26,
        textAlign: "center",
      },
      itemStyle: {
        color: "#7A1B1B",
        shadowColor: "rgba(122, 27, 27, 0.7)",
      },
    },
    {
      value: get(data, ["transportationBill"], 0),
      name: "ค่าขนส่ง",
      label: {
        formatter: (params: any) => {
          return [`{icon|}`].join("\n");
        },
        rich: {
          icon: {
            backgroundColor: {
              image: iconToBase64(RenderChangeToString(IconCarSvg)),
            },
            width: 18,
            height: 18,
            align: "center",
            verticalAlign: "middle",
          },
        },
        backgroundColor: "#F57C00",
        borderRadius: 100,
        width: 26,
        height: 26,
        padding: 2,
        lineHeight: 26,
        textAlign: "center",
      },
      itemStyle: {
        color: "#F57C00",
        shadowColor: "rgba(245, 124, 0, 0.7)",
      },
    },
    {
      value: get(data, ["electricityBill"], 0),
      name: "ค่าไฟ",
      label: {
        formatter: (params: any) => {
          return [`{icon|}`].join("\n");
        },
        rich: {
          icon: {
            backgroundColor: {
              image: iconToBase64(RenderChangeToString(IconFlashSvg)),
            },
            width: 18,
            height: 18,
            align: "center",
            verticalAlign: "middle",
          },
        },
        backgroundColor: "#F79836",
        borderRadius: 100,
        width: 26,
        height: 26,
        padding: 2,
        lineHeight: 26,
        textAlign: "center",
      },
      itemStyle: {
        color: "#F79836",
        shadowColor: "rgba(247, 152, 54, 0.7)",
      },
    },
    {
      value: get(data, ["feedBill"], 0),
      name: "ค่าอาหาร",
      label: {
        formatter: (params: any) => {
          return [`{icon|}`].join("\n");
        },
        rich: {
          icon: {
            backgroundColor: {
              image: iconToBase64(RenderChangeToString(IconFeedPigSvg)),
            },
            width: 18,
            height: 18,
            align: "center",
            verticalAlign: "middle",
          },
        },
        backgroundColor: "#68C184",
        borderRadius: 100,
        width: 26,
        height: 26,
        padding: 2,
        lineHeight: 26,
        textAlign: "center",
      },
      itemStyle: {
        color: "#68C184",
        shadowColor: "rgba(104, 193, 132, 0.7)",
      },
    },
    {
      value: get(data, ["staffBill"], 0),
      name: "ค่าพนักงาน",
      label: {
        formatter: (params: any) => {
          return [`{icon|}`].join("\n");
        },
        rich: {
          icon: {
            backgroundColor: {
              image: iconToBase64(RenderChangeToString(IconUserGroupSvg)),
            },
            width: 18,
            height: 18,
            align: "center",
            verticalAlign: "middle",
          },
        },
        backgroundColor: "#97BFEA",
        borderRadius: 100,
        width: 26,
        height: 26,
        padding: 2,
        lineHeight: 26,
        textAlign: "center",
      },
      itemStyle: {
        color: "#97BFEA",
        shadowColor: "rgba(85, 114, 235, 0.7)",
      },
    },
  ].filter((item) => item.value > 0);

  return {
    legend: {
      bottom: "2.5%",
      left: "center",
      data: [
        "ค่าพันธุ์สัตว์",
        "ค่าอาหาร",
        "ค่ายาเเละวัคซีน",
        "ค่าพนักงาน",
        "ค่าน้ำ",
        "ค่าไฟ",
        "ค่าขนส่ง",
        "ค่าโปรเเกรม",
        "ค่าใช้จ่ายอื่นๆ",
      ],
      textStyle: {
        fontSize: 14,
        color: "#000000",
      },
      itemGap: 20,
      icon: "circle",
      show: false,
    },
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        type: "pie",
        radius: ["50%", "70%"],
        center: ["50%", "50%"],
        padAngle: 5,
        itemStyle: {
          color: undefined,
          borderRadius: 5,
          borderWidth: 2,
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 30,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        labelLine: {
          length: 20,
          length2: 15,
          smooth: true,
        },
        tooltip: {
          formatter: (params: any) => {
            return `${params.name}: ${getCommaNumber(params.value, true)} บาท`;
          },
        },
        data: DataToShow,
      },
    ],
  };
};

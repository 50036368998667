import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Backdrop,
  Box,
  Fade,
  IconButton,
  Modal,
  SxProps,
  Theme,
} from "@mui/material";
import { useEffect, useState } from "react";
import ListOfFile from "../../../components/common/File/ListOfFile";
import ListOfUploadedFile from "../../../components/common/File/ListOfUploadedFile";
import { DocumentURLRow } from "../../../types/EditOrderFeed";
import { IImportDocument } from "../../../types/ImportDocument.type";
import { handleDownloadAzure } from "../../../utils/downloadFile";
import ButtonWithLoader from "../Button/ButtonWithLoader";
import DragAndDropUploadImage from "../Input/DragAndDropUploadImage";
import ConfirmCancelImportDocumentModal from "./ConfirmCancelImportDocumentModal";
import ConfirmImportDocumentModal from "./ConfirmImportDocumentModal";

const style: SxProps<Theme> = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "840px",
  bgcolor: "background.paper",
  boxShadow: 12,
  padding: "24px",
  borderRadius: "12px",
};

interface IImportDocumentModalProps {
  id: string;
  initialValues?: DocumentURLRow[];
  open: boolean;
  onClose: () => void;
  onSubmit: (files: IImportDocument) => void;
}

function ImportDocumentModal({
  id,
  open,
  onClose,
  onSubmit,
  initialValues = [],
}: IImportDocumentModalProps) {
  const [customerPickingSlip, setCustomerPickingSlip] = useState<File[]>([]);
  const [customerInvoice, setCustomerInvoice] = useState<File[]>([]);
  const [customerReceipt, setCustomerReceipt] = useState<File[]>([]);
  const [partnerPickingSlip, setPartnerPickingSlip] = useState<File[]>([]);
  const initCustomerPickingSlip = initialValues.filter(
    (row) => row.documentType === "customerPickingSlip"
  );
  const initCustomerInvoice = initialValues.filter(
    (row) => row.documentType === "customerInvoice"
  );
  const initCustomerReceipt = initialValues.filter(
    (row) => row.documentType === "customerReceipt"
  );
  const initPartnerPickingSlip = initialValues.filter(
    (row) => row.documentType === "partnerPickingSlipForSale"
  );
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openConfirmCancelModal, setOpenConfirmCancelModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleResetDocument = () => {
    setCustomerPickingSlip([]);
    setCustomerInvoice([]);
    setCustomerReceipt([]);
    setPartnerPickingSlip([]);
    setIsLoading(false);
  };

  const handleDownload = async (file: File) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${file.name}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  const handleOnClose = () => {
    if (isLoading) {
      return;
    }
    setOpenConfirmCancelModal(true);
  };

  useEffect(() => {
    handleResetDocument();
  }, [open]);

  return (
    <>
      <Modal
        aria-labelledby="import-document-modal-title"
        aria-describedby="import-document-modal-description"
        open={open}
        onClose={handleOnClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="w-full h-full">
              <div className="flex justify-between items-center">
                <span className="font-sans font-bold text-[1rem] text-[#191919]">
                  นำเข้าเอกสาร
                </span>
                <IconButton
                  aria-label="close-modal"
                  size="small"
                  onClick={handleOnClose}
                  disabled={isLoading}
                >
                  <CloseRoundedIcon
                    sx={{
                      fontSize: "24px",
                      color: "#191919",
                    }}
                  />
                </IconButton>
              </div>
              <div className="flex mt-6">
                <div className="flex flex-col w-1/2 pr-2 gap-4">
                  <div>
                    <p className="text-[#3777BC] font-bold text-[16px] mb-6">
                      ลูกค้า
                    </p>
                    <p className="font-bold text-[14px] mb-2">
                      ใบ Picking Slip
                    </p>
                    {customerPickingSlip.length > 0 ? (
                      <ListOfFile
                        files={customerPickingSlip}
                        onDownload={handleDownload}
                        onDelete={async (idx) => {
                          setCustomerPickingSlip([]);
                        }}
                      />
                    ) : (
                      <ListOfUploadedFile
                        files={initCustomerPickingSlip}
                        onDownload={async (row) =>
                          await handleDownloadAzure(id, row.url, row.fileName)
                        }
                      />
                    )}
                    <DragAndDropUploadImage
                      onUpload={(files) => {
                        setCustomerPickingSlip(files);
                      }}
                    />
                  </div>
                  <div>
                    <p className="font-bold text-[14px] my-2">ใบ Invoice</p>
                    {customerInvoice.length > 0 ? (
                      <ListOfFile
                        files={customerInvoice}
                        onDownload={handleDownload}
                        onDelete={async (idx) => {
                          setCustomerInvoice([]);
                        }}
                      />
                    ) : (
                      <ListOfUploadedFile
                        files={initCustomerInvoice}
                        onDownload={async (row) =>
                          await handleDownloadAzure(id, row.url, row.fileName)
                        }
                      />
                    )}
                    <DragAndDropUploadImage
                      onUpload={(files) => {
                        setCustomerInvoice(files);
                      }}
                    />
                  </div>
                  <div>
                    <p className="font-bold text-[14px] my-2">ใบเสร็จ</p>
                    {customerReceipt.length > 0 ? (
                      <ListOfFile
                        files={customerReceipt}
                        onDownload={handleDownload}
                        onDelete={async (idx) => {
                          setCustomerReceipt([]);
                        }}
                      />
                    ) : (
                      <ListOfUploadedFile
                        files={initCustomerReceipt}
                        onDownload={async (row) =>
                          await handleDownloadAzure(id, row.url, row.fileName)
                        }
                      />
                    )}
                    <DragAndDropUploadImage
                      onUpload={(files) => {
                        setCustomerReceipt(files);
                      }}
                    />
                  </div>
                </div>
                <div className="w-1/2 pl-2">
                  <p className="text-[#3777BC] font-bold text-[16px] mb-6">
                    พาร์ทเนอร์
                  </p>
                  <p className="font-bold text-[14px] mb-2">ใบ Picking Slip</p>
                  {partnerPickingSlip.length > 0 ? (
                    <ListOfFile
                      files={partnerPickingSlip}
                      onDownload={handleDownload}
                      onDelete={async (idx) => {
                        setPartnerPickingSlip([]);
                      }}
                    />
                  ) : (
                    <ListOfUploadedFile
                      files={initPartnerPickingSlip}
                      onDownload={async (row) =>
                        await handleDownloadAzure(id, row.url, row.fileName)
                      }
                    />
                  )}
                  <DragAndDropUploadImage
                    onUpload={(files) => {
                      setPartnerPickingSlip(files);
                    }}
                  />
                </div>
              </div>
              <div className="flex w-full justify-end gap-2 pt-6">
                <ButtonWithLoader
                  isLoading={isLoading}
                  isValid={true}
                  title={"ยืนยัน"}
                  onClick={() => {
                    setOpenConfirmModal(true);
                  }}
                />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <ConfirmImportDocumentModal
        open={openConfirmModal}
        onClose={() => {
          setOpenConfirmModal(false);
        }}
        onSubmit={async () => {
          setOpenConfirmModal(false);
          setIsLoading(true);
          onSubmit({
            customerInvoice,
            customerPickingSlip,
            customerReceipt,
            partnerPickingSlip,
          });
        }}
      />
      <ConfirmCancelImportDocumentModal
        open={openConfirmCancelModal}
        onClose={() => {
          setOpenConfirmCancelModal(false);
        }}
        onSubmit={() => {
          setOpenConfirmCancelModal(false);
          onClose();
        }}
      />
    </>
  );
}
export default ImportDocumentModal;

import { Dialog, DialogContent, IconButton, Tab } from "@mui/material";
import CrossIcon from "../../../assets/svg/CrossIcon.svg";
import { useCallback, useEffect, useState } from "react";
import {
  getDownloadUrlUserPartner,
  getPartnerById,
} from "../../../../services/admin/user-management/partner.service";
import { IGetPartnerByID } from "../../../../types/admin/user-management/partner";
import Tabs from "../../group-area/views/Tabs";
import dayjs from "dayjs";
import { getCommaNumber } from "../../../../utils/number";
import DownloadLinkWithDelete from "../../../../components/common/File/DownloadLinkWithDelete";
import axios from "axios";
import { handleDownloadBlob } from "../../../../utils/downloadFile";
import { isEmpty, isNil } from "lodash";

type DetailPartnerModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  selectedRow: string;
};

const DetailPartnerModal = ({
  isOpen,
  handleClose,
  selectedRow,
}: DetailPartnerModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [partnerDetail, setPartnerDetail] = useState<IGetPartnerByID | null>();

  const [tabIndex, setTabIndex] = useState(0);

  const onChangeTab = useCallback((e: any, index: number) => {
    setTabIndex(index);
  }, []);

  const [showFiles, setShowFiles] = useState<{ id: string; name: string }[]>(
    []
  );

  const handleFetch = async () => {
    if (selectedRow) {
      setIsLoading(true);
      try {
        const partner = await getPartnerById(selectedRow);
        setPartnerDetail(partner);
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleDownloadByUrl = useCallback(
    (fileId: string, name: string) => async () => {
      const preSignURL = await getDownloadUrlUserPartner(fileId);
      if (preSignURL) {
        const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
          responseType: "blob",
        });
        const objectURL = window.URL.createObjectURL(response.data);
        handleDownloadBlob(objectURL, name);
      }
    },
    [showFiles]
  );

  useEffect(() => {
    if (isOpen && selectedRow) {
      handleFetch();
      setTabIndex(0);
    }
  }, [isOpen, selectedRow]);

  useEffect(() => {
    setShowFiles([
      {
        id: partnerDetail?.result.contractFileId || "",
        name: partnerDetail?.result.contractFileName || "",
      },
    ]);
  }, [
    partnerDetail?.result.contractFileId,
    partnerDetail?.result.contractFileName,
  ]);

  const onClose = useCallback(() => {
    setTabIndex(0);
    setShowFiles([]);
    handleClose();
  }, []);

  return (
    <>
      <Dialog
        open={!isLoading && isOpen}
        onClose={onClose}
        maxWidth="lg"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={{
          backdrop: {
            style: { backgroundColor: "#000000", opacity: "10%" },
            timeout: 500,
          },
        }}
      >
        <DialogContent sx={{ width: "750px" }}>
          <div className="flex justify-between items-center">
            <p className="text-lg font-bold pb-3 mb-1">รายละเอียดพาร์ทเนอร์</p>
            <IconButton onClick={onClose}>
              <CrossIcon />
            </IconButton>
          </div>
          <Tabs tabIndex={tabIndex} onChange={onChangeTab}>
            <Tab label="ข้อมูลส่วนตัว" />
            <Tab label="ข้อมูลสัญญา" />
          </Tabs>
          {tabIndex === 0 && (
            <>
              <div className="flex pt-6">
                <div className="items-start w-[50%]">
                  <p className="text-sm font-bold pb-3 mb-1">ชื่อ</p>
                  <p className="text-sm pb-3 mb-1">
                    {partnerDetail?.result.name}
                  </p>
                </div>
                <div className="items-start w-[50%]">
                  <p className=" text-sm font-bold pb-3 mb-1">นามสกุล</p>
                  <p className=" text-sm pb-3 mb-1">
                    {partnerDetail?.result.surname}
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="items-start w-[50%]">
                  <p className="text-sm font-bold pb-3 mb-1">
                    ประเภทพาร์ทเนอร์
                  </p>
                  <p className="text-sm pb-3 mb-1">
                    {partnerDetail?.result.partnerType}
                  </p>
                </div>
                <div className="items-start w-[50%]">
                  <p className="text-sm font-bold pb-3 mb-1">รหัสพาร์ทเนอร์</p>
                  <p className="text-sm pb-3 mb-1">
                    {partnerDetail?.result.partnerCode}
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="items-start w-[50%]">
                  <p className="text-sm font-bold pb-3 mb-1">อีเมล</p>
                  <p className="text-sm pb-3 mb-1">
                    {partnerDetail?.result.email || "-"}
                  </p>
                </div>
                <div className="items-start w-[50%]">
                  <p className="text-sm font-bold pb-3 mb-1">เบอร์โทรศัพท์</p>
                  <p className="text-sm pb-3 mb-1">
                    {partnerDetail?.result.mobileNumber}
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="items-start w-[100%]">
                  <p className="text-sm font-bold pb-3 mb-1">ชื่อกลุ่ม</p>
                  <p className="text-sm pb-3 mb-1">
                    {(partnerDetail?.result.userGroups || []).length > 0
                      ? partnerDetail?.result.userGroups
                          .map((role) => role.name)
                          .join(", ")
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="items-start w-[50%]">
                  <p className="text-sm font-bold pb-3 mb-1">ชื่อผู้ใช้</p>
                  <p className="text-sm pb-3 mb-1">
                    {partnerDetail?.result.username}
                  </p>
                </div>
                <div className="items-start w-[50%]">
                  <p className="text-sm font-bold pb-3 mb-1">Initial PIN</p>
                  <p className="text-sm pb-3 mb-1">
                    {partnerDetail?.result.initialPin}
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="items-start w-[100%]">
                  <p className="text-sm font-bold pb-3 mb-1">ที่อยู่</p>
                  <p className="text-sm pb-3 mb-1">
                    {`${partnerDetail?.result.address} ${partnerDetail?.result.subDistrict.nameTh} ${partnerDetail?.result.district.nameTh} ${partnerDetail?.result.province.nameTh} ${partnerDetail?.result.subDistrict.zipCode}`}
                  </p>
                </div>
              </div>
            </>
          )}
          {tabIndex === 1 && (
            <>
              <div className="flex pt-6">
                <div className="items-start w-[50%]">
                  <p className="text-sm font-bold pb-3 mb-1">วันที่เซ็นสัญญา</p>
                  <p className="text-sm pb-3 mb-1">
                    {dayjs(partnerDetail?.result.contractSignDate).isValid()
                      ? dayjs(partnerDetail?.result.contractSignDate).format(
                          "DD/MM/YYYY"
                        )
                      : "-"}
                  </p>
                </div>
                <div className="items-start w-[50%]">
                  <p className=" text-sm font-bold pb-3 mb-1">
                    รายได้ลูกค้าใหม่
                  </p>
                  <p className=" text-sm pb-3 mb-1">
                    {getCommaNumber(partnerDetail?.result.onboardRate || 0)}{" "}
                    บาท/คน
                  </p>
                </div>
              </div>
              <div className="flex">
                <div className="items-start w-[50%]">
                  <p className="text-sm font-bold pb-3 mb-1">
                    เปอร์เซ็นต์รายได้ยอดขาย
                  </p>
                  <p className="text-sm pb-3 mb-1">
                    {getCommaNumber(partnerDetail?.result.revenueRate || 0)}%
                  </p>
                </div>
                <div className="items-start w-[50%]">
                  <p className=" text-sm font-bold pb-3 mb-1">
                    เปอร์เซ็นต์รายได้เก็บหนี้
                  </p>
                  <p className=" text-sm pb-3 mb-1">
                    {getCommaNumber(
                      partnerDetail?.result.invoiceFollowRate || 0
                    )}
                    %
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <p className="font-bold text-[#000000] text-[14px] font-sans">
                  ใบสัญญา
                </p>
                {!isNil(showFiles[0]?.id) && !isEmpty(showFiles[0].id) ? (
                  <DownloadLinkWithDelete
                    onDownload={handleDownloadByUrl(
                      showFiles[0].id,
                      showFiles[0].name
                    )}
                    hideDeleteBtn
                    name={showFiles[0].name}
                  />
                ) : (
                  "-"
                )}
              </div>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DetailPartnerModal;

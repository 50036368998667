import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { get } from "lodash";
import { tw } from "../../../utils/tw";
import AppBarFS from "../../../components/app-bar/AppBar";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
} from "@mui/material";
import { usePartnerViewHistoryController } from "./controller";
import { IRateChangeHistory } from "../../../types/partner/PartnerSectionContract";
import TableCustomMobile from "../../financial-support/financial-billPayment/unpaid-bill/views/TableCustomMobile";
import dayjs from "dayjs";
import { getShortDate } from "../../../utils/date";
import FilterListIcon from "@mui/icons-material/FilterList";
import { getCommaNumber } from "../../../utils/number";
import FilterModal from "./views/FilterModal";

const classes = {
  container: tw(`min-h-screen w-full flex flex-col bg-[white] pb-[24px]`),
  appbarContainer: tw(`py-4 h-[120px] flex items-center`),
  emptyTable: tw(
    `font-sans text-center w-full text-[#979797] text-[14px] py-3`
  ),
};

const RevenueTypeMap = {
  onboard: "ลูกค้าใหม่",
  order_feed: "ยอดขาย",
  invoice: "เก็บหนี้",
  "": "",
};

const headerItems = [
  {
    id: "updateDate",
    numeric: false,
    disablePadding: false,
    label: "วันที่อัปเดต",
    width: "auto",
    isSorting: true,
    className: tw(`whitespace-pre`),
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "ประเภท",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
  {
    id: "income",
    numeric: false,
    disablePadding: false,
    label: "รายได้",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
];

interface ITable {
  swapCellColor?: boolean;
  data: IRateChangeHistory[];
  orderByType: "desc" | "asc";
  setOrderByType: Dispatch<SetStateAction<"desc" | "asc">>;
  isBasicPartner: boolean;
}

const TableHistory = (props: ITable) => {
  const { swapCellColor, data, orderByType, setOrderByType, isBasicPartner } =
    props;
  const [currentPage, setCurrentPage] = useState(1);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#D9EBFE",
      color: "#4B4B4B",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: 400,
      textAlign: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: `${swapCellColor ? "#ffffff" : "#f6faff"}`,
    },
    "&:nth-of-type(even)": {
      backgroundColor: `${swapCellColor ? "#f6faff" : "#ffffff"}`,
    },
  }));

  const dataBasic = data.filter((i) => i.revenueType === "onboard");

  const rowChildren = useMemo(() => {
    return (isBasicPartner ? dataBasic : data).map((row, index) => {
      return (
        <StyledTableRow key={row.id + index}>
          <StyledTableCell
            sx={{
              border: "0px !important",
              whiteSpace: "pre",
            }}
          >
            {getShortDate(row.createdAt)}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
              whiteSpace: "pre",
            }}
          >
            {
              RevenueTypeMap[
                get(row, ["revenueType"], "") as
                  | "onboard"
                  | "order_feed"
                  | "invoice"
              ]
            }
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
              whiteSpace: "pre",
            }}
          >
            {getCommaNumber(get(row, ["to"], 0))}{" "}
            {row.revenueType === "onboard" ? "บาท" : "%"}
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  }, [data, currentPage]);

  return (
    <div className={"min-h-[240px] px-4"}>
      <TableCustomMobile
        orderBy={"updateDate"}
        order={orderByType}
        onChangeOrder={setOrderByType}
        onChangeOrderBy={() => {}}
        totalItemSize={data.length}
        headerItems={headerItems}
        page={currentPage}
        onChangePage={setCurrentPage}
        rowsPerPage={data.length}
        rows={data}
        EmptyComponent={
          data?.length === 0 ? (
            <div className={classes.emptyTable}>ไม่มีข้อมูล</div>
          ) : null
        }
        rowChildren={rowChildren}
        currentPageItems={data.map(({ id }) => id)}
        itemsSelected={[]}
        onSelectAllClick={() => {}}
        onSelectItem={() => {}}
        hideSelect
        hidePaginator
      />
    </div>
  );
};

const HistoryPartnerContract = () => {
  const {
    GetProfilePartner,
    RevenueRateChangeHistory,
    orderByType,
    setOrderByType,
    formFilterModal,
    setFormFilterModal,
    onChangeFormFilterModal,
  } = usePartnerViewHistoryController();

  let navigate = useNavigate();

  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);

  const onOpenFilterModal = useCallback(() => setIsOpenFilterModal(true), []);
  const onHideFilterModal = useCallback(() => setIsOpenFilterModal(false), []);

  const handleBack = useCallback(() => {
    navigate("/partner/partner-contract");
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.appbarContainer}>
        <AppBarFS title="ประวัติเปอร์เซ็นต์รายได้" onIconPress={handleBack} />
      </div>
      <div className="flex flex-row px-4 justify-end pb-4">
        <div className="h-full flex gap-2 items-center">
          <span className="text-secondary font-normal ml-1 text-[1rem] flex flex-col items-center">
            กรองโดย
          </span>
          <IconButton
            aria-label="filter"
            sx={{
              color: "#68C184",
              border: "1px solid #68C184",
              height: "32px",
              width: "32px",
              ":hover": {
                color: "#FFFFFF",
                border: "1px solid #68C184",
                bgcolor: "#68C184",
              },
              ":active": {
                color: "#FFFFFF",
                border: "1px solid #68C184",
                bgcolor: "#68C184",
              },
            }}
            onClick={onOpenFilterModal}
          >
            <FilterListIcon />
          </IconButton>
        </div>
      </div>
      <TableHistory
        data={RevenueRateChangeHistory?.history || []}
        orderByType={orderByType}
        setOrderByType={setOrderByType}
        isBasicPartner={!(GetProfilePartner?.profile?.type === "core")}
      />
      <FilterModal
        title="กรองโดย"
        isOpen={isOpenFilterModal}
        onClose={onHideFilterModal}
        form={formFilterModal}
        setForm={setFormFilterModal}
        onChangeForm={onChangeFormFilterModal}
      />
    </div>
  );
};

export default HistoryPartnerContract;

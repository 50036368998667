import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import PopupImage from "../../../assets/images/FarmRegistration/popup_bg.png";
import warning from "../../../assets/images/feed/Iconwarning.svg";
import close from "../../../assets/images/feed/close.svg";
import CustomButton from "../../../components/common/Button";

const FeedDialog = ({ handleModalClose, openModal }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  return (
    <>
      <Transition.Root show={openModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleModalClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform h-[90%] overflow-hidden rounded-[50px] bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg bg-white-important">
                  <div
                    className="w-full relative h-full bg-no-repeat bg-cover"
                    style={{ backgroundImage: `url(${PopupImage})` }}
                  >
                    <div className=" w-full">
                      <div className="flex flex-col items-center w-full">
                        <div
                          className="flex justify-end w-full pr-8 pt-6"
                          onClick={handleModalClose}
                        >
                          <img src={close} alt="close" />
                        </div>
                        <div className="mt-12">
                          <img src={warning} alt="warning" />
                        </div>

                        <div className="mt-8 w-[70%]">
                          <h2 className="text-2xl font-medium text-[#F57C00] text-center">
                            {t("feedingProgram.Notice!")}
                          </h2>
                          <p className="text-base font-normal text-[#646464] text-center mt-3">
                            {t(
                              "feedingProgram.farmResultsAndLivestockImpactFromRecipeChanges"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-full absolute bottom-5 ">
                      <div className="flex flex-col gap-3 w-[80%] mx-auto">
                        <CustomButton
                          variant="secondary"
                          label={t(
                            "feedingProgram.noticeModalModifyButtonText"
                          )}
                          // onClick={() => navigate('/edit-house', { state: { editRoute: "edit-feed-program"}})}  />
                          onClick={() =>
                            navigate("/edit-feed-program", {
                              state: { ...state },
                            })
                          }
                        />
                        <CustomButton
                          variant="tertiary"
                          label={t("feedingProgram.noticeModalStickButtonText")}
                          onClick={handleModalClose}
                        />
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default FeedDialog;

import { Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import AppBarFS from "../../../../components/app-bar/AppBar";
import AddHouseNoticeModal from "../../../../components/common/AddHouseNoticeModal/AddHouseNoticeModal";
import Loader from "../../../../components/common/Loader";
import CustomTable from "../../../../components/common/Table";
import BasicDetailTable from "../../../../components/common/Table/BasicDetailTable";
import {
  addHouseService,
  editHouseService,
  getByHouseIdService,
} from "../../../../services/AuthService";
import FeedProgram from "../../feeding-program/FeedProgram";
import FeedingContext from "../../feeding-program/Provider/FeedingContext";
import { AddHouseType } from "../../types/type";

const ProgramOverview = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const language = localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const [houseDetails, setHouseDetails] = useState<any>([]);
  const [addHouseNoticeModal, setAddHouseNoticeModal] = useState(false);
  const {
    finalAddHouseData,
    isEditHouse,
    feedData,
    feedBrands,
    setSelectedBrand,
    setFeedData,
    setAddHouseData,
    setActiveStep,
  } = useContext(FeedingContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [houseName, sethouseName] = useState("");
  const [basicDetailData, setBasicDetailsData] = useState<any>([
    { detailName: t("programOverview.basicTableFarmName"), detailValue: "" },
    { detailName: t("programOverview.basicTableHouseName"), detailValue: "" },
    { detailName: t("programOverview.basicTableTypeOfPig"), detailValue: "" },
    {
      detailName: t("programOverview.basicTableStartHouseDate"),
      detailValue: "",
    },
    {
      detailName: t("programOverview.basicTableAgeOfPig"),
      detailValue: "",
      unit: t("addHouseDetail.days").toLocaleLowerCase(),
    },
    {
      detailName: t("programOverview.basicTableAmountOfPig"),
      detailValue: "",
      unit: t("common.pig").toLocaleLowerCase(),
      doFormat: true,
    },
    {
      detailName: t("programOverview.basicTableTotalWeight"),
      detailValue: "",
      unit: t("common.kg").toLocaleLowerCase(),
      doFormat: true,
    },
    {
      detailName: t("programOverview.basicTableNameTotalPrice"),
      detailValue: "",
      unit: t("common.currency"),
      doFormat: true,
    },
    {
      detailName: t("programOverview.basicTableNamePricePerPig"),
      detailValue: "",
      unit: t("common.currency"),
      doFormat: true,
    },
  ]);
  const handleModalClose = () => {
    setAddHouseNoticeModal(false);
  };
  useEffect(() => {
    if (!!state?.houseId) {
      getHouseDetails();
    }
  }, [state?.houseId]);

  const getHouseDetails = async () => {
    const resp = await getByHouseIdService(state.houseId);
    if (resp?.data?.success) {
      setHouseDetails(resp?.data?.house);
    }
  };

  const skipVaccine = localStorage.getItem("skip");
  useEffect(() => {
    if (finalAddHouseData?.basicDetails) {
      const { breederPig, fattenerPig, nurseryPig } =
        finalAddHouseData?.basicDetails;

      let typeOfPig = [],
        totalPig = 0;
      if (finalAddHouseData?.basicDetails?.breederPig) {
        typeOfPig.push(t("common.breeder_pig"));
        totalPig +=
          typeof breederPig === "string" ? parseInt(breederPig) : breederPig;
      }
      if (finalAddHouseData?.basicDetails?.fattenerPig) {
        typeOfPig.push(t("common.fattener_pig"));
        totalPig +=
          typeof fattenerPig === "string" ? parseInt(fattenerPig) : fattenerPig;
      }

      if (finalAddHouseData?.basicDetails?.nurseryPig) {
        typeOfPig.push(t("common.nursery_pig"));
        totalPig +=
          typeof nurseryPig === "string" ? parseInt(nurseryPig) : nurseryPig;
      }

      let basicObj = [...basicDetailData];
      basicObj[0].detailValue = finalAddHouseData?.basicDetails?.farmName;
      basicObj[1].detailValue = finalAddHouseData?.basicDetails?.houseName;
      basicObj[2].detailValue = typeOfPig.join(", ");
      basicObj[3].detailValue =
        finalAddHouseData?.basicDetails?.houseStartDate?.format("YYYY-MM-DD");
      basicObj[4].detailValue = finalAddHouseData?.basicDetails?.ageOfPig;
      basicObj[5].detailValue = totalPig.toLocaleString();
      basicObj[6].detailValue = parseFloat(
        finalAddHouseData?.basicDetails?.totalWeightOfPigs
      );
      basicObj[7].detailValue = parseFloat(
        finalAddHouseData?.basicDetails?.totalPriceOfPigs
      );
      basicObj[8].detailValue = parseFloat(
        finalAddHouseData?.basicDetails?.pricePerPig
      );
      setBasicDetailsData(basicObj);
    }
  }, [finalAddHouseData]);

  const tableHeadData = [
    { nameEn: "pig age", nameTh: "อายุสุกร" },
    { nameEn: "Vaccine", nameTh: "วัคซีน" },
    { nameEn: "quantity", nameTh: "ปริมาณ" },
  ];

  const calculateAndCreateFeedData = (f: any, n: any): [] => {
    const { feedDetails } = finalAddHouseData;

    let feedData: any = [];
    if (feedDetails && feedDetails?.feed_brand_items) {
      feedDetails?.feed_brand_items?.forEach((item: any, index: number) => {
        let obj = isEditHouse
          ? {
              id: houseDetails.houseFeeds[index]?.id,
              feedBrandId: Number(item.brand_id),
              feedBrandItemId: Number(item.id),
              quantity:
                item.quantity || item.quantity == 0
                  ? item.quantity
                  : (f + n) * item.consumption,
            }
          : {
              feedBrandId: Number(item.brand_id),
              feedBrandItemId: Number(item.id),
              quantity:
                item.quantity || item.quantity == 0
                  ? item.quantity
                  : (f + n) * item.consumption,
            };
        feedData.push(obj);
      });
    }
    return feedData;
  };
  const createVaccineData = (data: any) => {
    let vaccineData: any = [];
    data?.forEach((item: any, index: number) => {
      let obj = isEditHouse
        ? {
            id: houseDetails.houseVaccines[index]?.id,
            pigAge: Number(item.pigAge),
            vaccineId: Number(item.vaccineId),
            quantity: Number(item.vaccine.quantity),
          }
        : {
            pigAge: Number(item.pigAge),
            vaccineId: Number(item.vaccineId),
            quantity: Number(item.vaccine.quantity),
          };
      vaccineData.push(obj);
    });

    return vaccineData;
  };

  const handleSubmit = async () => {
    const {
      farmId,
      houseName,
      breederPig,
      fattenerPig,
      nurseryPig,
      houseStartDate,
      ageOfPig,
      totalWeightOfPigs,
      totalPriceOfPigs,
      feedHomeMix,
    } = finalAddHouseData?.basicDetails;

    let apiData: AddHouseType = {
      farmId: farmId,
      name: houseName,
      houseStartDate: houseStartDate?.format("MM-DD-YY"), //mm-dd-yy  //
      pigAge: Number(ageOfPig),
      totalPigWeight: Number(totalWeightOfPigs),
      totalPigPrice: Number(totalPriceOfPigs),
      feedHomeMix: feedHomeMix,
    };
    if (Number(breederPig) > 0) apiData.breederPigCount = Number(breederPig);

    if (Number(fattenerPig) > 0) apiData.fattenerPigCount = Number(fattenerPig);

    if (Number(nurseryPig) > 0) apiData.nurseryPigCount = Number(nurseryPig);

    if (fattenerPig || nurseryPig) {
      apiData.feed = calculateAndCreateFeedData(fattenerPig, nurseryPig);
    }

    if (finalAddHouseData?.vaccineDetails?.length > 0) {
      apiData.vaccine = createVaccineData(
        finalAddHouseData?.vaccineDetails[0]?.vaccineProgramDetails
      );
    }
    setLoading(true);
    // คำนวณน้ำหนัก
    apiData.totalPigWeight =
      ((apiData.fattenerPigCount || 0) + (apiData.nurseryPigCount || 0)) *
      apiData.totalPigWeight;

    // คำนวณราคา
    apiData.totalPigPrice =
      apiData.totalPigPrice * (apiData.fattenerPigCount || 0);

    if (isEditHouse) {
      apiData.id = houseDetails?.id;
      const resp: any = await editHouseService(apiData);
      setLoading(false);
      if (setAddHouseData) setAddHouseData({});
      if (setSelectedBrand) setSelectedBrand([]);
      if (setFeedData) setFeedData([{}]);
      if (resp?.data?.success) {
        navigate("/edit-house-success", { state: { ...state } });
      } else {
        setErrorMessage(resp?.data?.message);
      }
    } else {
      const resp: any = await addHouseService(apiData);
      setLoading(false);
      if (setAddHouseData) setAddHouseData({});
      if (setSelectedBrand) setSelectedBrand([]);
      if (setFeedData) setFeedData([{}]);
      if (resp?.data?.success) {
        setAddHouseData && setAddHouseData({});
        navigate(`/house-success/${farmId}`);
      } else {
        setErrorMessage(resp?.data?.message);
      }
    }
  };

  useEffect(() => {
    basicDetailData.filter((item: any) => {
      if (item.detailName === "House Name") {
        sethouseName(item.detailValue);
      }
    });
  }, [basicDetailData]);

  const instructionsData = (instructionValue: any) => {
    const dataArray = instructionValue?.split("\n");
    const instructionsArray = dataArray.reduce((acc: any, item: any) => {
      const trimmedItem = item.trim();
      if (trimmedItem !== "") {
        const instructionsItem = trimmedItem.replace(/^\d+\s/g, "");
        acc.push({ instructions: instructionsItem });
      }
      return acc;
    }, []);

    return (
      <>
        {instructionsArray &&
          instructionsArray.map((item: any, index: any) => {
            return (
              <div key={index} className="flex">
                <span
                  className="flex-none"
                  style={{
                    height: "22px",
                    width: "5px",
                    backgroundColor: "#DC5B5B",
                    borderRadius: "99px",
                    marginRight: "10px",
                  }}
                ></span>
                <span className="text-[#7A1B1B] text-[14px]">
                  {item?.instructions}
                </span>
              </div>
            );
          })}
      </>
    );
  };

  return (
    <div className="min-h-screen bg-[#fff] justify-between flex flex-col w-full">
      <div>
        <div className="p-4 bg-[#fff] pb-8">
          <AppBarFS
            title={t("programOverview.programOverviewHeading")}
            onIconPress={() => {
              navigate(-1);
              if (setActiveStep) setActiveStep(0);
            }}
          ></AppBarFS>
        </div>
        {loading && <Loader />}
        <div className="py-4 px-4">
          <h3 className="text-[#34407B] text-[20px] font-semibold">
            {t("programOverview.programOverviewTopTitle", {
              houseName: finalAddHouseData?.basicDetails?.houseName,
            })}
          </h3>
          <h3 className="text-[#747474] text-[14px]">
            {t("programOverview.programOverviewTopText")}
          </h3>
          <div className="text-[#3777BC] text-[20px] font-semibold mb-[24px] mt-8">
            {t("programOverview.basicDetailTitle")}
          </div>
          <div className="bg-[#D9EBFE] flex items-center p-[16px] rounded-t-lg justify-between">
            <h5 className="text-[24px] font-medium">
              {t("programOverview.homeDetailTitle")}
            </h5>
            <button
              className="add-farms-btn px-[15px] py-[5px] rounded-[40px] border border-green-500 flex text-[#68C184] bg-[#fff]"
              onClick={() =>
                isEditHouse ? navigate("/edit-house") : navigate("/add-house")
              }
            >
              {t("addHouse.editButtonText")}
            </button>
          </div>
          <BasicDetailTable tableData={basicDetailData} />
          <div className="bg-[#D9EBFE] rounded-b-lg h-[24px]"></div>

          {finalAddHouseData?.feedDetails && (
            <>
              <div className="text-[#3777BC] text-[20px] font-semibold mb-[24px] mt-8"></div>
              <FeedProgram
                title={t("programOverview.feedProgram")}
                selectedFeedBrand={finalAddHouseData?.feedDetails}
                feedBrands={feedBrands}
                feedData={feedData}
              />
            </>
          )}
          {skipVaccine === "true" ? (
            <></>
          ) : (
            <>
              {finalAddHouseData?.vaccineDetails?.length &&
                finalAddHouseData?.vaccineDetails?.map(
                  (item: any, index: number) => {
                    return (
                      <div key={index} className="mb-[20px]">
                        <div className="text-[#3777BC] text-[20px] font-semibold mb-[24px] mt-8">
                          {t("programOverview.vaccineProgramTitle")}
                        </div>
                        <div className="bg-[#D9EBFE] flex items-center p-[16px] pb-[8px] rounded-t-lg justify-between">
                          <h5 className="text-[20px] font-medium mr-[10px]">
                            {language === "th" ? (
                              <>{item?.titleTh}</>
                            ) : (
                              <>{item?.titleEn}</>
                            )}
                          </h5>
                          <button
                            className="add-farms-btn px-[15px] py-[5px] rounded-[40px] border border-green-500 flex text-[#68C184] bg-[#fff]"
                            onClick={() => {
                              setAddHouseNoticeModal(true);
                            }}
                          >
                            {t("addHouse.editButtonText")}
                          </button>
                        </div>
                        <CustomTable
                          tableData={item?.vaccineProgramDetails}
                          tableHeadData={tableHeadData}
                        />
                        <div className="bg-[#D9EBFE] rounded-b-lg">
                          <div className="flex items-center justify-between p-[16px]">
                            <h5 className="text-[16px] font-medium px-[3%] text-[#3777BC]">
                              {t("addHouse.tableFooterWeekText")}
                            </h5>
                            <h5 className="px-[6%] text-[16px] font-medium text-[#3777BC]">
                              {t("addHouse.tableFooterCCText")}
                            </h5>
                          </div>
                          <div className="flex flex-col pt-0 p-[16px] gap-[8px]">
                            {language === "th" ? (
                              <>{instructionsData(item?.instructionsTh)}</>
                            ) : (
                              <>{instructionsData(item?.instructionsEn)}</>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
            </>
          )}
          <div className="text-[#D32F2F] text-[14px] text-center mt-6 m-auto w-[65%]">
            {t("programOverview.bottomMessageText")}
          </div>
          {errorMessage && (
            <div className="text-[#D32F2F] text-[14px] text-center mt-6 m-auto w-[65%]">
              {errorMessage}
            </div>
          )}
        </div>
      </div>
      <Button
        onClick={handleSubmit}
        sx={{ m: 2, mt: 2 }}
        variant="contained"
        color="secondary"
        size="large"
      >
        {t("programOverview.programOverviewConfirmBtn")}
      </Button>
      <AddHouseNoticeModal
        addHouseNoticeModal={addHouseNoticeModal}
        handleModalClose={handleModalClose}
      />
    </div>
  );
};
export default ProgramOverview;

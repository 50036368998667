import { useQuery } from "@tanstack/react-query";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_AUTH_REFRESH_TOKEN_KEY,
  ADMIN_AUTH_TOKEN_KEY,
} from "../../../const/app.constant";
import { getAllUsers, login } from "../../../services/AdminAuthService";
import { setItem } from "../../../services/StorageService";
import ButtonWithLoader from "../../components/Button/ButtonWithLoader";
import { Dropdown } from "../group-area/views/Dropdown";

function Login() {
  const { data: users, refetch } = useQuery({
    queryKey: ["all-users"],
    enabled: false,
    queryFn: async () => {
      const response = await getAllUsers();
      return response;
    },
  });

  const [email, setEmail] = useState("");
  const [emailDropdown, setEmailDropdown] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await login({ email: email });
      setItem(ADMIN_AUTH_TOKEN_KEY, response.result.token);
      setItem(ADMIN_AUTH_REFRESH_TOKEN_KEY, response.result.refreshToken);
      navigate("/admin/home");
    } catch (error: any) {
      setErrorMessage(error.response?.data?.message ?? "Login error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setErrorMessage("");
  }, [email]);

  return (
    <div className="h-full flex flex-col items-center justify-center">
      <h1 className="font-bold text-2xl my-4">Farmtech Login</h1>
      <form onSubmit={handleLogin}>
        <div className="flex flex-col gap-4 w-[320px]">
          {/* <div className="flex flex-col gap-1">
            <p className="text-sm font-bold text-[#307546]">
              Please fill an email
            </p>
            <TextInputCustom
              autoComplete="email"
              id="email"
              name="email"
              placeholder="Username"
              value={email}
              onChange={(e) => {
                const value = e.target.value;
                const emailDropdown = (users || []).find(
                  (row) => row.email === value
                );
                setEmail(value);
                setEmailDropdown(
                  emailDropdown
                    ? [
                        {
                          label: `${emailDropdown.email} - ${emailDropdown.userRole}`,
                          value: emailDropdown.email,
                        },
                      ]
                    : []
                );
              }}
            />
          </div> */}
          <div className="flex flex-col gap-1">
            <p className="text-sm font-bold text-[#307546]">Choose an email</p>
            <Dropdown
              placeholder="กรุณาเลือก"
              options={(users || []).map((row) => ({
                label: `${row.userRole}${" "}${row.email}`,
                value: row.email,
              }))}
              onChange={(e) => {
                setEmail(e[0].value);
                setEmailDropdown(e);
              }}
              renderValueArray={emailDropdown}
            />
          </div>
          <ButtonWithLoader
            isLoading={isLoading}
            isValid={!!email}
            title={"Submit"}
          />
          <div className="min-h-[24px]">
            {errorMessage && (
              <p className="text-error font-bold">{errorMessage}</p>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;

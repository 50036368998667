const IconUserGroupWithBgSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={41} height={42} fill="none">
    <rect width={41} height={41} y={0.129} fill="#97BFEA" rx={20.5} />
    <path
      fill="#fff"
      d="M24.552 15.23a4.05 4.05 0 1 1-8.1 0 4.05 4.05 0 0 1 8.1 0ZM31.302 17.93a2.7 2.7 0 1 1-5.4 0 2.7 2.7 0 0 1 5.4 0ZM25.901 27.38a5.4 5.4 0 1 0-10.8 0v4.05h10.8v-4.05ZM15.102 17.93a2.7 2.7 0 1 1-5.4 0 2.7 2.7 0 0 1 5.4 0ZM28.602 31.43v-4.05c0-1.423-.367-2.76-1.012-3.923a4.05 4.05 0 0 1 5.061 3.923v4.05h-4.05ZM13.413 23.457a8.064 8.064 0 0 0-1.011 3.923v4.05h-4.05v-4.05a4.05 4.05 0 0 1 5.061-3.923Z"
    />
  </svg>
);
export default IconUserGroupWithBgSvg;

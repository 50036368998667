import CircleIcon from "@mui/icons-material/Circle";
import { UnitEnum } from "../../../../types/FeedBrand.type";
import { ListOrderDetail } from "../../../../types/MyOrderFeed.type";
import { thousandComma } from "../../../../utils/common/string";

type OrderFeedFarmerItemListProp = {
  isTotalItemPrice?: boolean;
  listOrder: ListOrderDetail;
  index?: number;
};

const OrderFeedFarmerItemList = ({
  isTotalItemPrice,
  listOrder,
  index,
}: OrderFeedFarmerItemListProp) => {
  return (
    <>
      <div className="pt-4 items-center">
        <CircleIcon
          sx={{
            fontSize: "8px",
            color: "#014F79",
            marginLeft: "12px",
          }}
        />
        <span className="ml-[10px] text-tertiary font-medium text-sm">
          รายการที่ {index ?? 1}
        </span>
        <div className="px-4">
          <div className="flex justify-between text-sm mt-2">
            <p className="text-tertiary">แบรนด์อาหารสัตว์</p>
            <p className="text-primary">{listOrder.feedBrand.nameTh}</p>
          </div>
          <div className="flex justify-between text-sm mt-2">
            <p className="text-tertiary">เบอร์อาหารสัตว์</p>
            <p className="text-primary">{listOrder.feedBrandItem.skuTh}</p>
          </div>
          <div className="flex justify-between text-sm mt-2">
            <p className="text-tertiary">จำนวน</p>
            <p className="text-primary">
              {listOrder.feedAmount}{" "}
              {UnitEnum[listOrder.feedUnit as keyof typeof UnitEnum]}
            </p>
          </div>
          {isTotalItemPrice ? (
            <div className="flex justify-between text-sm mt-2">
              <p className="text-tertiary">ราคา</p>
              <p className="text-primary">
                {thousandComma(listOrder.totalItemPrice)} บาท
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default OrderFeedFarmerItemList;

import { Backdrop, Box, CircularProgress, Fade, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import StockImage from "../../../assets/images/stock-img.png";

interface IImagePreview {
  useRealImage?: boolean;
  onDownloadImage?: (...values: any) => Promise<Blob | null>;
  id?: string;
  url: string;
  previewWidth?: string;
  previewHeight?: string;
  imageWidth?: string;
}

const mockImagePreviewStatic = [
  {
    url: StockImage,
    name: "example.png",
  },
];

function ImagePreview({
  id,
  url,
  useRealImage,
  previewHeight = "80px",
  previewWidth = "80px",
  imageWidth = "500px",
  onDownloadImage,
}: IImagePreview) {
  const [isLoading, setIsLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(false);

  const handleLoadImage = async () => {
    if (onDownloadImage) {
      try {
        setIsLoading(true);
        const blobImage = await onDownloadImage(id || "", url);
        if (blobImage) {
          const url = URL.createObjectURL(blobImage);
          setImageSrc(url);
        }
      } catch (error) {
        setError(true);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleLoadStaticImage = async () => {
    setImageSrc(mockImagePreviewStatic[0].url);
  };

  useEffect(() => {
    if (useRealImage) {
      handleLoadImage();
    } else {
      handleLoadStaticImage();
    }
  }, [url]);

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            width: previewWidth,
            height: previewHeight,
            background: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
          }}
        >
          <CircularProgress color="success" />
        </Box>
      ) : (
        <img
          className="rounded-[10px] cursor-zoom-in object-contain"
          width={previewWidth}
          height={previewHeight}
          onClick={!error ? () => setIsOpen(true) : undefined}
          src={imageSrc}
          alt={`preview-img`}
        />
      )}
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            style: { backgroundColor: "#000000", opacity: "10%" },
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "transparent",
              p: 4,
            }}
          >
            <img src={imageSrc} alt={"display-modal-img"} width={imageWidth} />
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export default ImagePreview;

import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import {
  createCreditSetting,
  createCreditSettingDraft,
  editCreditSetting,
  editDraftCreditSetting,
  getDefaultTemplateCredit,
} from "../../../../../../services/admin/masterdata/Credit.service";
import { ICreditForm } from "../../../../../../types/admin/masterdata/creditForm";
import delay from "../../../../../../utils/delay";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import { tw } from "../../../../../../utils/tw";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../../../components/Button/ButtonOutlinedCustom";
import ApplicationUsageAccordion from "../Accordion/ApplicationUsageAccordion";
import FarmFinancialAccordion from "../Accordion/FarmFinancialAccordion";
import FarmPerformanceAccordion from "../Accordion/FarmPerformanceAccordion";
import GeneralAccordion from "../Accordion/GeneralAccordion";
import GradeSetupAccordion from "../Accordion/GradeSetupAccordion";
import OverallAccordion from "../Accordion/OverallAccordion";
import CreditFormSchema from "./CreditFormSchema";
import ConfirmCustomModal from "../../../../../components/Modal/ConfirmCustomModal";
import CrossIcon from "../../../../../assets/svg/CrossIcon.svg";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  selectCreditId: string;
  action: string;
  handleFetch: () => void;
};

export const classes = {
  container: tw(
    `w-[100vw] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary [&_.MuiDialog-paperScrollPaper]:!m-0 max-w-[1780px]`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  label: tw(`text-[14px] mb-1 font-medium`),
  input: tw(`w-[350px] pb-3`),
  inputSpace: tw(`w-full flex gap-4 mb-4`),
  redColor: tw(`text-[#d32f2f]`),
};

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const defaultValues = {
  name: "",
  generalPersonalInformation: {
    segmentWeight: "",
    checklists: [
      {
        options: [{ key: "", value: "" }],
        id: "",
        isActive: false,
        isActivatable: false,
        isDeletable: false,
        general: "",
        weight: "",
      },
    ],
  },
  generalLegalInformation: {
    segmentWeight: "",
    checklists: [
      {
        options: [{ key: "", value: "" }],
        id: "",
        isActive: false,
        isActivatable: false,
        isDeletable: false,
        general: "",
        weight: "",
      },
    ],
  },
  generalFarmInformation: {
    segmentWeight: "",
    checklists: [
      {
        options: [{ key: "", value: "" }],
        id: "",
        isActive: false,
        isActivatable: false,
        isDeletable: false,
        general: "",
        weight: "",
      },
    ],
  },
  farmPerformance: {
    segmentWeight: "",
    checklists: [
      {
        options: [{ key: "", value: "" }],
        id: "",
        isActive: false,
        isActivatable: false,
        isDeletable: false,
        general: "",
        weight: "",
      },
    ],
  },
  farmFinancialStatusAndInstallment: {
    segmentWeight: "",
    checklists: [
      {
        options: [{ key: "", value: "" }],
        id: "",
        isActive: false,
        isActivatable: false,
        isDeletable: false,
        general: "",
        weight: "",
      },
    ],
  },
  applicationUsage: {
    segmentWeight: "",
    checklists: [
      {
        options: [{ key: "", value: "" }],
        id: "",
        isActive: false,
        isActivatable: false,
        isDeletable: false,
        general: "",
        weight: "",
      },
    ],
  },
  gradeSetup: {
    minimumA: 0,
    minimumB: 0,
    minimumC: 0,
    minimumD: 0,
  },
};

export const tableCellHeader = [
  {
    value: "เปิดใช้งาน",
    width: "120px",
  },
  {
    value: "General",
    width: "350px",
  },
  {
    value: "Weight",
    width: "200px",
  },
  {
    value: "1",
    width: "200px",
  },
  {
    value: "2",
    width: "200px",
  },
  {
    value: "3",
    width: "200px",
  },
  {
    value: "4",
    width: "200px",
  },
  {
    value: "5",
    width: "200px",
  },
  {
    value: "",
    width: "50px",
  },
];
export const tableCellGradeSetupHeader = [
  {
    value: "General",
    width: "450px",
  },
  {
    value: "A",
    width: "200px",
  },
  {
    value: "B",
    width: "200px",
  },
  {
    value: "C",
    width: "200px",
  },
  {
    value: "D",
    width: "200px",
  },
  {
    value: "F",
    width: "210px",
  },
];

const CreditModal = ({
  isOpen,
  onClose,
  selectCreditId,
  action,
  handleFetch,
}: Props) => {
  const [isOpenSaveDraftModal, setIsOpenSaveDraftModal] =
    useState<boolean>(false);
  const [isOpenSaveModal, setIsOpenSaveModal] = useState<boolean>(false);
  const { schema } = CreditFormSchema();

  const viewDetail = action === "view";
  const repeat = action === "repeat";

  const headerModal = (action: string) => {
    switch (action) {
      case "view":
        return "การให้เครดิต";
      case "repeat":
        return "แก้ไขการให้เครดิต";
      case "create":
        return "เพิ่มการให้เครดิต";
      case "edit":
        return "แก้ไขการให้เครดิต";
    }
  };

  const methods = useForm<ICreditForm>({
    // mode: "onChange",
    defaultValues,
    resolver: yupResolver<ICreditForm>(schema as any),
  });

  const dispatch = useDispatch();

  const handleFetchDefaultTemplate = async () => {
    dispatch(loadingActions.show({ message: "กำลังโหลด..." }));
    methods.reset(defaultValues);
    const data = await getDefaultTemplateCredit(selectCreditId);
    const defaultForm = data?.creditSetting;
    const newValues = {
      id: defaultForm?.id,
      name: repeat ? "" : defaultForm?.name || "",
      generalPersonalInformation: {
        segmentWeight:
          defaultForm?.generalPersonalInformation.segmentWeight || "",
        checklists: defaultForm?.generalPersonalInformation.checklists.map(
          (checklist) => {
            return {
              id: checklist.id,
              isActive: checklist.isActive,
              isActivatable: checklist.isActivatable,
              isDeletable: checklist.isDeletable,
              general: checklist.general,
              weight: checklist.weight || "",
              options: checklist.options.map((option) => {
                return {
                  key: option.key,
                  value: option.value,
                };
              }),
            };
          }
        ),
      },
      generalLegalInformation: {
        segmentWeight: defaultForm?.generalLegalInformation.segmentWeight || "",
        checklists: defaultForm?.generalLegalInformation.checklists.map(
          (checklist) => {
            return {
              id: checklist.id,
              isActive: checklist.isActive,
              isActivatable: checklist.isActivatable,
              isDeletable: checklist.isDeletable,
              general: checklist.general,
              weight: checklist.weight || "",
              options: checklist.options.map((option) => {
                return {
                  key: option.key,
                  value: option.value,
                };
              }),
            };
          }
        ),
      },
      generalFarmInformation: {
        segmentWeight: defaultForm?.generalFarmInformation.segmentWeight || "",
        checklists: defaultForm?.generalFarmInformation.checklists.map(
          (checklist) => {
            return {
              id: checklist.id,
              isActive: checklist.isActive,
              isActivatable: checklist.isActivatable,
              isDeletable: checklist.isDeletable,
              general: checklist.general,
              weight: checklist.weight || "",
              options: checklist.options.map((option) => {
                return {
                  key: option.key,
                  value: option.value,
                };
              }),
            };
          }
        ),
      },
      farmPerformance: {
        segmentWeight: defaultForm?.farmPerformance.segmentWeight || "",
        checklists: defaultForm?.farmPerformance.checklists.map((checklist) => {
          return {
            id: checklist.id,
            isActive: checklist.isActive,
            isActivatable: checklist.isActivatable,
            isDeletable: checklist.isDeletable,
            general: checklist.general,
            weight: checklist.weight || "",
            options: checklist.options.map((option) => {
              return {
                key: option.key,
                value: option.value,
              };
            }),
          };
        }),
      },
      farmFinancialStatusAndInstallment: {
        segmentWeight:
          defaultForm?.farmFinancialStatusAndInstallment.segmentWeight || "",
        checklists:
          defaultForm?.farmFinancialStatusAndInstallment.checklists.map(
            (checklist) => {
              return {
                id: checklist.id,
                isActive: checklist.isActive,
                isActivatable: checklist.isActivatable,
                isDeletable: checklist.isDeletable,
                general: checklist.general,
                weight: checklist.weight || "",
                options: checklist.options.map((option) => {
                  return {
                    key: option.key,
                    value: option.value,
                  };
                }),
              };
            }
          ),
      },
      applicationUsage: {
        segmentWeight: defaultForm?.applicationUsage.segmentWeight || "",
        checklists: defaultForm?.applicationUsage.checklists.map(
          (checklist) => {
            return {
              id: checklist.id,
              isActive: checklist.isActive,
              isActivatable: checklist.isActivatable,
              isDeletable: checklist.isDeletable,
              general: checklist.general,
              weight: checklist.weight || "",
              options: checklist.options.map((option) => {
                return {
                  key: option.key,
                  value: option.value,
                };
              }),
            };
          }
        ),
      },
      gradeSetup: {
        minimumA: defaultForm?.gradeSetup.minimumA || "",
        minimumB: defaultForm?.gradeSetup.minimumB || "",
        minimumC: defaultForm?.gradeSetup.minimumC || "",
        minimumD: defaultForm?.gradeSetup.minimumD || "",
      },
    };
    methods.reset(newValues);
    await delay(2000);
    dispatch(loadingActions.hide());
  };

  useEffect(() => {
    if (isOpen) {
      handleFetchDefaultTemplate();
    }
  }, [isOpen, selectCreditId]);

  const onSaveDraft = async (data: ICreditForm) => {
    try {
      const payload = {
        id: data?.id,
        name: data?.name || "",
        generalPersonalInformation: {
          segmentWeight: Number(data?.generalPersonalInformation.segmentWeight),
          checklists: data?.generalPersonalInformation.checklists.map(
            (checklist) => {
              return {
                id: checklist.id,
                isActive: checklist.isActive,
                isActivatable: checklist.isActivatable,
                isDeletable: checklist.isDeletable,
                general: checklist.general,
                weight: Number(checklist.weight),
                options: checklist.options.map((option) => {
                  return {
                    key: option.key,
                    value: option.value,
                  };
                }),
              };
            }
          ),
        },
        generalLegalInformation: {
          segmentWeight: Number(data?.generalLegalInformation.segmentWeight),
          checklists: data?.generalLegalInformation.checklists.map(
            (checklist) => {
              return {
                id: checklist.id,
                isActive: checklist.isActive,
                isActivatable: checklist.isActivatable,
                isDeletable: checklist.isDeletable,
                general: checklist.general,
                weight: Number(checklist.weight),
                options: checklist.options.map((option) => {
                  return {
                    key: option.key,
                    value: option.value,
                  };
                }),
              };
            }
          ),
        },
        generalFarmInformation: {
          segmentWeight: Number(data?.generalFarmInformation.segmentWeight),
          checklists: data?.generalFarmInformation.checklists.map(
            (checklist) => {
              return {
                id: checklist.id,
                isActive: checklist.isActive,
                isActivatable: checklist.isActivatable,
                isDeletable: checklist.isDeletable,
                general: checklist.general,
                weight: Number(checklist.weight),
                options: checklist.options.map((option) => {
                  return {
                    key: option.key,
                    value: option.value,
                  };
                }),
              };
            }
          ),
        },
        farmPerformance: {
          segmentWeight: Number(data?.farmPerformance.segmentWeight),
          checklists: data?.farmPerformance.checklists.map((checklist) => {
            return {
              id: checklist.id,
              isActive: checklist.isActive,
              isActivatable: checklist.isActivatable,
              isDeletable: checklist.isDeletable,
              general: checklist.general,
              weight: Number(checklist.weight),
              options: checklist.options.map((option) => {
                return {
                  key: option.key,
                  value: option.value,
                };
              }),
            };
          }),
        },
        farmFinancialStatusAndInstallment: {
          segmentWeight: Number(
            data?.farmFinancialStatusAndInstallment.segmentWeight
          ),
          checklists: data?.farmFinancialStatusAndInstallment.checklists.map(
            (checklist) => {
              return {
                id: checklist.id,
                isActive: checklist.isActive,
                isActivatable: checklist.isActivatable,
                isDeletable: checklist.isDeletable,
                general: checklist.general,
                weight: Number(checklist.weight),
                options: checklist.options.map((option) => {
                  return {
                    key: option.key,
                    value: option.value,
                  };
                }),
              };
            }
          ),
        },
        applicationUsage: {
          segmentWeight: Number(data?.applicationUsage.segmentWeight),
          checklists: data?.applicationUsage.checklists.map((checklist) => {
            return {
              id: checklist.id,
              isActive: checklist.isActive,
              isActivatable: checklist.isActivatable,
              isDeletable: checklist.isDeletable,
              general: checklist.general,
              weight: Number(checklist.weight),
              options: checklist.options.map((option) => {
                return {
                  key: option.key,
                  value: option.value,
                };
              }),
            };
          }),
        },
        gradeSetup: {
          minimumA: Number(data?.gradeSetup.minimumA),
          minimumB: Number(data?.gradeSetup.minimumB),
          minimumC: Number(data?.gradeSetup.minimumC),
          minimumD: Number(data?.gradeSetup.minimumD),
        },
      };
      dispatch(loadingActions.show({ message: "กำลังบันทึกเเบบร่าง..." }));
      if (selectCreditId && !repeat) {
        await editDraftCreditSetting(selectCreditId, payload);
      } else {
        await createCreditSettingDraft(payload);
      }
      dispatch(loadingActions.hide());
      onClose();
      setIsOpenSaveDraftModal(false);
      dispatch(
        snakeActions.showMessage({
          message: "บันทึกสำเร็จ",
          type: "success",
        })
      );
      handleFetch();
    } catch (error) {
      dispatch(loadingActions.hide());
      dispatch(
        snakeActions.showMessage({
          message: getErrorMessage(error),
          type: "error",
        })
      );
    }
  };

  const onSubmit = async (data: ICreditForm) => {
    try {
      const payload = {
        id: data?.id,
        name: data?.name || "",
        generalPersonalInformation: {
          segmentWeight: Number(data?.generalPersonalInformation.segmentWeight),
          checklists: data?.generalPersonalInformation.checklists.map(
            (checklist) => {
              return {
                id: checklist.id,
                isActive: checklist.isActive,
                isActivatable: checklist.isActivatable,
                isDeletable: checklist.isDeletable,
                general: checklist.general,
                weight: Number(checklist.weight),
                options: checklist.options.map((option) => {
                  return {
                    key: option.key,
                    value: option.value,
                  };
                }),
              };
            }
          ),
        },
        generalLegalInformation: {
          segmentWeight: Number(data?.generalLegalInformation.segmentWeight),
          checklists: data?.generalLegalInformation.checklists.map(
            (checklist) => {
              return {
                id: checklist.id,
                isActive: checklist.isActive,
                isActivatable: checklist.isActivatable,
                isDeletable: checklist.isDeletable,
                general: checklist.general,
                weight: Number(checklist.weight),
                options: checklist.options.map((option) => {
                  return {
                    key: option.key,
                    value: option.value,
                  };
                }),
              };
            }
          ),
        },
        generalFarmInformation: {
          segmentWeight: Number(data?.generalFarmInformation.segmentWeight),
          checklists: data?.generalFarmInformation.checklists.map(
            (checklist) => {
              return {
                id: checklist.id,
                isActive: checklist.isActive,
                isActivatable: checklist.isActivatable,
                isDeletable: checklist.isDeletable,
                general: checklist.general,
                weight: Number(checklist.weight),
                options: checklist.options.map((option) => {
                  return {
                    key: option.key,
                    value: option.value,
                  };
                }),
              };
            }
          ),
        },
        farmPerformance: {
          segmentWeight: Number(data?.farmPerformance.segmentWeight),
          checklists: data?.farmPerformance.checklists.map((checklist) => {
            return {
              id: checklist.id,
              isActive: checklist.isActive,
              isActivatable: checklist.isActivatable,
              isDeletable: checklist.isDeletable,
              general: checklist.general,
              weight: Number(checklist.weight),
              options: checklist.options.map((option) => {
                return {
                  key: option.key,
                  value: option.value,
                };
              }),
            };
          }),
        },
        farmFinancialStatusAndInstallment: {
          segmentWeight: Number(
            data?.farmFinancialStatusAndInstallment.segmentWeight
          ),
          checklists: data?.farmFinancialStatusAndInstallment.checklists.map(
            (checklist) => {
              return {
                id: checklist.id,
                isActive: checklist.isActive,
                isActivatable: checklist.isActivatable,
                isDeletable: checklist.isDeletable,
                general: checklist.general,
                weight: Number(checklist.weight),
                options: checklist.options.map((option) => {
                  return {
                    key: option.key,
                    value: option.value,
                  };
                }),
              };
            }
          ),
        },
        applicationUsage: {
          segmentWeight: Number(data?.applicationUsage.segmentWeight),
          checklists: data?.applicationUsage.checklists.map((checklist) => {
            return {
              id: checklist.id,
              isActive: checklist.isActive,
              isActivatable: checklist.isActivatable,
              isDeletable: checklist.isDeletable,
              general: checklist.general,
              weight: Number(checklist.weight),
              options: checklist.options.map((option) => {
                return {
                  key: option.key,
                  value: option.value,
                };
              }),
            };
          }),
        },
        gradeSetup: {
          minimumA: Number(data?.gradeSetup.minimumA),
          minimumB: Number(data?.gradeSetup.minimumB),
          minimumC: Number(data?.gradeSetup.minimumC),
          minimumD: Number(data?.gradeSetup.minimumD),
        },
      };
      dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
      if (selectCreditId && !repeat) {
        await editCreditSetting(selectCreditId, payload);
      } else {
        await createCreditSetting(payload);
      }
      dispatch(loadingActions.hide());
      onClose();
      setIsOpenSaveModal(false);
      dispatch(
        snakeActions.showMessage({
          message: "บันทึกสำเร็จ",
          type: "success",
        })
      );
      handleFetch();
    } catch (error) {
      dispatch(loadingActions.hide());
      dispatch(
        snakeActions.showMessage({
          message: getErrorMessage(error),
          type: "error",
        })
      );
    }
  };

  const handleSubmit = async (values: any) => {
    setIsOpenSaveModal(true);
  };
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        maxWidth="xl"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={slotProps}
        className={classes.container}
        classes={{ paper: "w-full" }}
      >
        <DialogContent>
          <div className={classes.titleContainer}>
            <p className={classes.title}>{headerModal(action)}</p>
            <IconButton onClick={onClose}>
              <CrossIcon />
            </IconButton>
          </div>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
              <OverallAccordion viewAction={viewDetail} />
              <GeneralAccordion viewAction={viewDetail} />
              <FarmPerformanceAccordion viewAction={viewDetail} />
              <FarmFinancialAccordion viewAction={viewDetail} />
              <ApplicationUsageAccordion viewAction={viewDetail} />
              <GradeSetupAccordion viewAction={viewDetail} />
              {!viewDetail && (
                <div className="flex justify-end gap-3 ">
                  <ButtonOutlinedCustom
                    title={"บันทึกเเบบร่าง"}
                    onClick={() => setIsOpenSaveDraftModal(true)}
                  />
                  <ButtonFillCustom title={"บันทึก"} type="submit" />
                </div>
              )}
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
      <ConfirmCustomModal
        open={isOpenSaveDraftModal}
        onClose={() => setIsOpenSaveDraftModal(false)}
        title="ยืนยันการบันทึกแบบร่าง"
        onSubmit={async () => onSaveDraft(methods.getValues())}
        subtitleFirstRow="คุณต้องการบันทึกแบบร่างรายการนี้ใช่หรือไม่?"
        subtitleSecondRow="การดำเนินการนี้ไม่สามารถยกเลิกได้"
        buttonPrimaryText={"ยืนยัน"}
        buttonSecondaryText={"กลับไปเเก้ไข"}
      />
      <ConfirmCustomModal
        open={isOpenSaveModal}
        onClose={() => setIsOpenSaveModal(false)}
        title="ยืนยันการบันทึก"
        onSubmit={async () => onSubmit(methods.getValues())}
        subtitleFirstRow="คุณต้องการบันทึกรายการนี้ใช่หรือไม่?"
        subtitleSecondRow="การดำเนินการนี้ไม่สามารถยกเลิกได้"
        buttonPrimaryText={"ยืนยัน"}
        buttonSecondaryText={"กลับไปเเก้ไข"}
      />
    </>
  );
};

export default CreditModal;

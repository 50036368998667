import { IDownloadUrl } from "../../types/ManageStock.type";
import {
  IGetMyStockPartner,
  IGetMyStockWithID,
  IGetStockHistoryDetail,
  TBaseGetListPartnerStockList,
  TUintStock,
} from "../../types/partner/stock.type";
import api from "../ApiService";

export const getPartnerHistoryStock = async () => {
  try {
    const response = await api.get<TBaseGetListPartnerStockList>(
      "/partner/stock/history"
    );

    if (![200, 201].includes(response.status)) {
      throw new Error();
    }

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getPartnerHistoryStockById = async (stockId: string) => {
  try {
    const response = await api.get<TBaseGetListPartnerStockList>(
      "/partner/stock/history/" + stockId
    );

    if (![200, 201].includes(response.status)) {
      throw new Error();
    }

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getDownloadUrlStockPartner = async (
  id: string,
  documentType: string,
  fileId: string
) => {
  const { data } = await api.get<IDownloadUrl>(
    `partner/stock/download/${id}/${documentType}/${fileId}`
  );
  return data;
};

export const getPartnerUnitStock = async () => {
  try {
    const response = await api.get<TUintStock>("/partner/stock/in-stock");

    return response.data;
  } catch (error) {
    return null;
  }
};

export const getMyStockPartner = async (params?: { searchText?: string }) => {
  const { data } = await api.get<IGetMyStockPartner>(
    "/partner/stock/my-stock",
    {
      params: {
        searchText: params?.searchText,
      },
    }
  );
  return data;
};

export const getMyStockPartnerId = async (id: string) => {
  const { data } = await api.get<IGetMyStockWithID>(
    `/partner/stock/my-stock/${id}`
  );
  return data;
};

export const getMyStockPartnerHistoryId = async (id: string) => {
  const { data } = await api.get<IGetStockHistoryDetail>(
    `/partner/stock/my-stock/${id}/history`
  );
  return data;
};

import { useNavigate } from "react-router-dom";
import BackgrounImage from "../../../../assets/images/Login/BackGroungImage.png";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { useCallback, useState } from "react";
import { cloneDeep, get, set } from "lodash";
import AppBarFS from "../../../../components/app-bar/AppBar";
import RadioButton from "../../../../components/common/RadioButton";
import CustomButton from "../../../../components/common/Button";
import { alertAction } from "../../../../redux/slices/alertSlice";
import { deleteAccount } from "../../../../services/AccountCenter.service";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../utils/getErrorMessage";
import { useMutation } from "@tanstack/react-query";
import {
  AUTH_TOKEN_KEY,
  AUTH_USERNAME,
  IS_SAVED,
  LOCK_OUT,
  NUMBER_OF_MISTAKE,
} from "../../../../const/app.constant";

interface IState {
  option: number;
}

const defaultState: IState = {
  option: 0,
};

const RemoveAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [state, setState] = useState<IState>(defaultState);

  const onChangeState = useCallback(
    (key: keyof IState) => (value: any) => {
      setState((prev) => {
        const prevState = cloneDeep(prev);
        return set(prevState, [key], value);
      });
    },
    []
  );

  const currentOption = get(state, ["option"]);

  const RemoveAccountMutation = useMutation({
    mutationFn: deleteAccount,
    onSuccess: async () => {
      setTimeout(() => {
        setTimeout(() => {
          localStorage.removeItem("firstTimeLogin");
          localStorage.removeItem(AUTH_TOKEN_KEY);
          localStorage.removeItem(AUTH_USERNAME);
          localStorage.removeItem("role");
          localStorage.removeItem(IS_SAVED);
          localStorage.removeItem(LOCK_OUT);
          localStorage.removeItem(NUMBER_OF_MISTAKE);
          navigate("/login");
          dispatch(
            snakeActions.showMessage({
              message: "Successful Saved!",
              type: "success",
            })
          );
        }, 500);
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const onRemove = useCallback(() => {
    dispatch(
      alertAction.showDialog({
        isFullButtonStyle: true,
        title: "Hold on!",
        text: "Remove Account can effect data loss and permanently delete. Are you sure?",
        cancelText: "Close",
        confirmText: "Continue",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          dispatch(alertAction.hide());
          await RemoveAccountMutation.mutate("request_change_account");
        },
      })
    );
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${BackgrounImage})`,
        height: "auto !important",
        backgroundSize: "cover",
        backgroundColor: "#4882c8",
      }}
      className="!h-screen !min-h-screen"
    >
      <div className="h-[120px] flex items-center bg-[#FFFF] rounded-b-[40px]">
        <AppBarFS title={"Remove Account"} onIconPress={() => navigate(-1)} />
      </div>
      <div className="flex flex-col h-[80%] w-full flex-1">
        <div className="flex flex-1 flex-col px-4 pt-4 gap-[30px]">
          <div className="bg-[#FFFFFF] rounded-[20px] flex flex-col pb-[48px]">
            <div className="pt-4 flex items-center">
              <div className="pl-[20px] pr-[10px] flex flex-col gap-4 justify-between w-full h-auto">
                <div className="text-[16px] font-medium text-[#3777BC] flex flex-row gap-4">
                  Why you leaving? Let us know your feedback for our further
                  improve.
                </div>
                <div className="flex flex-row " onClick={() => {}}>
                  <RadioButton
                    checked={currentOption === 0}
                    onClick={() => onChangeState("option")(0)}
                  />
                  <div className="text-[16px] text-[#646464] font-normal flex items-center">
                    ต้องการเปลี่ยน Account
                  </div>
                </div>
                <div className="flex flex-row " onClick={() => {}}>
                  <RadioButton
                    checked={currentOption === 1}
                    onClick={() => onChangeState("option")(1)}
                  />
                  <div className="text-[16px] text-[#646464] font-normal flex items-center">
                    Option 2
                  </div>
                </div>
                <div className="flex flex-row " onClick={() => {}}>
                  <RadioButton
                    checked={currentOption === 2}
                    onClick={() => onChangeState("option")(2)}
                  />
                  <div className="text-[16px] text-[#646464] font-normal flex items-center">
                    Option 3
                  </div>
                </div>
                <div className="flex flex-row " onClick={() => {}}>
                  <RadioButton
                    checked={currentOption === 3}
                    onClick={() => onChangeState("option")(3)}
                  />
                  <div className="text-[16px] text-[#646464] font-normal flex items-center">
                    Option 4
                  </div>
                </div>
                <div className="flex flex-row " onClick={() => {}}>
                  <RadioButton
                    checked={currentOption === 4}
                    onClick={() => onChangeState("option")(4)}
                  />
                  <div className="text-[16px] text-[#646464] font-normal flex items-center">
                    Option 5
                  </div>
                </div>
                <div className="flex flex-row " onClick={() => {}}>
                  <RadioButton
                    checked={currentOption === 5}
                    onClick={() => onChangeState("option")(5)}
                  />
                  <div className="text-[16px] text-[#646464] font-normal flex items-center">
                    Option 6
                  </div>
                </div>
                <div className="flex flex-row " onClick={() => {}}>
                  <RadioButton
                    checked={currentOption === 6}
                    onClick={() => onChangeState("option")(6)}
                  />
                  <div className="text-[16px] text-[#646464] font-normal flex items-center">
                    Option 7
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CustomButton label={"Confirm"} onClick={onRemove} />
        </div>
      </div>
    </div>
  );
};

export default RemoveAccount;

import CircleIcon from "@mui/icons-material/Circle";
import { FieldArray, FormikProps } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AddressIcon } from "../../../assets/svg/Address";
import { DeleteOutlinedIcon } from "../../../assets/svg/DeleteOutlined";
import { DocumentTextIcon } from "../../../assets/svg/DocumentGreen";
import { PencilAlternateIcon } from "../../../assets/svg/PencilAlternate";
import { PigFeedIcon } from "../../../assets/svg/PigFeed";
import CustomButton from "../../../components/common/Button";
import { postOrderFeed } from "../../../services/OrderFeed.service";
import { Farm } from "../../../types";
import { FeedBrands } from "../../../types/FeedBrand.type";
import { IOrderFeedForm, IOrderList } from "../../../types/OrderFeed.type";
import { formatPhoneNumber, thousandComma } from "../../../utils/common/string";
import { OrderFeedBaseSnackBar } from "./OrderFeedBaseSnackbar";
import { OrderFeedDeleteModal } from "./OrderFeedDeleteModal";
import { OrderFeedEditModal } from "./OrderFeedEditModal";
interface IOrderFeedSummaryForm {
  formik: FormikProps<IOrderFeedForm>;
  feedBrands: FeedBrands[];
  houseAddress: Farm;
  farmerPhoneNumber: string;
  farmId: string;
}

export default function OrderFeedSummaryForm({
  formik,
  feedBrands,
  houseAddress,
  farmerPhoneNumber,
  farmId,
}: IOrderFeedSummaryForm) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [orderEditIndex, setOrderEditIndex] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [orderDeleteIndex, setOrderDeleteIndex] = useState(0);
  const [openSnackbarEdit, setOpenSnackbarEdit] = useState(false);
  const [openSnackbarDelete, setOpenSnackbarDelete] = useState(false);
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false);

  const handleCloseSnackbarEdit = (
    _: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbarEdit(false);
  };
  const handleCloseSnackbarDelete = (
    _: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbarDelete(false);
  };
  const handleRevalidateForm = () => {
    formik.validateForm();
  };

  const handleSubmitForm = async () => {
    try {
      setIsDisabledSubmit(true);
      const filterListOfFeeds = formik.values.orders.map((item) => {
        return {
          feedBrandId: Number(item.brandId),
          feedBrandItemId: Number(item.brandSKU),
          feedAmount: Number(item.amount),
          feedUnit: item.unit?.toLowerCase(),
          feedPricePerUnit: item.pricePerUnit,
        };
      });
      const payload = {
        houseId: formik.values.houseId,
        contractId: formik.values.contractId,
        remainingCredit: formik.values.remainingCredit,
        shippingType: formik.values.deliveryType,
        shippingDate: formik.values.deliveryDate,
        listOfFeeds: filterListOfFeeds,
      };

      await postOrderFeed(payload);
      navigate("/feed-order-and-delivery/order-feed/" + farmId + "/success");
    } catch (error) {
      return null;
    }
  };

  return (
    <>
      <div className="flex flex-col justify-between w-full">
        <div className="flex flex-col gap-4 mb-4">
          <div className="flex flex-col gap-2">
            {/* Contract Header */}
            <div className="flex rounded-t-[20px] bg-[#F6FBFF] h-[42px] items-center px-[16px] gap-4">
              <DocumentTextIcon />
              <span className="text-[16px] font-semibold text-primary">
                {t("orderFeed.summary.contract")} {formik.values.contractName}
              </span>
            </div>
            {/* Contract Body */}
            <div className="px-[16px] leading-7">
              <div className="flex justify-between">
                <span className="text-tertiary">
                  {t("orderFeed.summary.house")}
                </span>
                <span className="text-primary">{formik.values.houseName}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-tertiary">
                  {t("orderFeed.summary.remainingCredit")}
                </span>
                <span className="text-primary">
                  {thousandComma(formik.values.remainingCredit)}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-tertiary">
                  {t("orderFeed.summary.deliveryDate")}
                </span>
                <span className="text-primary">
                  {/* {formik.values.deliveryDate?.format("DD/MM/BBBB")} */}
                  {formik.values.deliveryDate?.format("DD/MM/YYYY")}
                </span>
              </div>
            </div>
            {/* Address Header */}
            <div className="flex bg-[#F6FBFF] h-[42px] items-center px-[16px] gap-4">
              <AddressIcon />
              <span className="text-[16px] font-semibold text-primary">
                {t("orderFeed.summary.address")}
              </span>
            </div>
            {/* Address Body */}
            <div className="px-[16px] leading-7">
              <div className="flex justify-between">
                <span className="text-tertiary">
                  {t("orderFeed.summary.deliveryType")}
                </span>
                <span className="text-primary">
                  {t(
                    `orderFeed.input.option.${formik.values.deliveryType?.toLowerCase()}` as any
                  )}
                </span>
              </div>
              {/* Case received type == shipping */}
              {formik.values.deliveryType === "shipping" && (
                <div className="flex justify-between">
                  <span className="w-1/2 text-tertiary">
                    {t("orderFeed.summary.address")}
                  </span>
                  <span className="w-1/2 text-end text-primary">
                    {`${houseAddress.address} ${houseAddress.subDistrict?.nameTh} ${houseAddress.district?.nameTh} ${houseAddress.province?.nameTh} ${houseAddress.postalCode}`}
                  </span>
                </div>
              )}
              {/* farmer tel. */}
              <div className="flex justify-between">
                <span className="text-tertiary">{"เบอร์โทรศัพท์(ลูกค้า)"}</span>
                <span className="text-primary">
                  {formatPhoneNumber(farmerPhoneNumber)}
                </span>
              </div>
            </div>
            {/* Card Footer */}
            <div className="rounded-b-[20px] bg-[#F6FBFF] h-[42px]"></div>
          </div>
          <div className="flex flex-col">
            {/* Contract Header */}
            <div className="flex rounded-t-[20px] bg-[#F6FBFF] h-[42px] items-center px-[16px] gap-4 mb-2">
              <PigFeedIcon />
              <span className="text-[16px] font-semibold text-primary">
                {t("orderFeed.summary.orderList")}
              </span>
            </div>
            {/* Order List */}
            <FieldArray name={"orders"}>
              {() => (
                <div>
                  {formik.values.orders.map((row, idx) => (
                    <div key={`orders-${idx}`}>
                      <div className="px-[16px] leading-7 my-2">
                        <div className="flex justify-between">
                          <div className="flex gap-2 items-center">
                            <CircleIcon
                              className="text-primary"
                              sx={{ fontSize: "10px" }}
                            />
                            <span className="text-tertiary font-semibold">
                              {`${t("orderFeed.listNumber")} ${idx + 1}`}
                            </span>
                          </div>
                          <div className="flex gap-2">
                            {formik.values.orders.length > 1 && (
                              <div
                                className="cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setOrderDeleteIndex(idx);
                                  setOpenDeleteModal(true);
                                }}
                              >
                                <DeleteOutlinedIcon />
                              </div>
                            )}
                            <div
                              className="cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                setOrderEditIndex(idx);
                                setOpenEditModal(true);
                              }}
                            >
                              <PencilAlternateIcon />
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-tertiary">
                            {t("orderFeed.summary.brandId")}
                          </span>
                          <span className="text-primary">{row.nameTh}</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-tertiary">
                            {t("orderFeed.summary.brandSKU")}
                          </span>
                          <span className="text-primary">{row.skuTh}</span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-tertiary">
                            {t("orderFeed.summary.amount")}
                          </span>
                          <span className="text-primary">
                            {row.amount + " " + row.unitLabel}
                          </span>
                        </div>
                        <div className="flex justify-between">
                          <span className="text-tertiary">
                            {t("orderFeed.summary.price")}
                          </span>
                          <span className="text-primary">
                            {thousandComma(
                              Number(row.pricePerUnit) * Number(row.amount)
                            ) + " "}
                            {t("orderFeed.input.unit.baht")}
                          </span>
                        </div>
                      </div>
                      <div className="bg-[#F6FBFF] h-[6px]"></div>
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>
            {/* Card Footer */}
            <div className="rounded-b-[20px] bg-[#F6FBFF] h-[36px]"></div>
          </div>
        </div>
        <div
          style={{
            position: "sticky",
            bottom: 0,
            zIndex: 1000,
            paddingBottom: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {/* Snackbar/Toast Edit */}
          <OrderFeedBaseSnackBar
            openSnackbar={openSnackbarEdit}
            handleCloseSnackbar={handleCloseSnackbarEdit}
            snackbarText={t("orderFeed.summary.snackbar.edit")}
          />
          {/* Snackbar/Toast Delete*/}
          <OrderFeedBaseSnackBar
            openSnackbar={openSnackbarDelete}
            handleCloseSnackbar={handleCloseSnackbarDelete}
            snackbarText={t("orderFeed.summary.snackbar.delete")}
          />
          {/* Submit Button */}
          <CustomButton
            disabled={isDisabledSubmit}
            variant="tertiary"
            label={t("orderFeed.button.submit")}
            onClick={handleSubmitForm}
          />
        </div>
      </div>
      {/* Edit Modal */}
      <OrderFeedEditModal
        formik={formik}
        orderIndex={orderEditIndex}
        feedBrands={feedBrands}
        isOpen={openEditModal}
        handleClose={(isSuccess: boolean, values?: IOrderList) => {
          setOpenEditModal(false);
          if (isSuccess) {
            const editIdx = orderEditIndex;
            setOrderEditIndex(0);
            if (values) {
              const duplicateOrderIndex = formik.values.orders.findIndex(
                (item, index) => {
                  return (
                    item.brandId === values?.brandId &&
                    item.brandSKU === values.brandSKU &&
                    index !== editIdx
                  );
                }
              );

              if (duplicateOrderIndex >= 0) {
                formik.values.orders[duplicateOrderIndex].amount =
                  Number(values?.amount) +
                  Number(formik.values.orders[duplicateOrderIndex].amount);

                formik.values.orders = formik.values.orders.filter(
                  (_, index) => {
                    return index !== editIdx;
                  }
                );
              } else {
                formik.values.orders = formik.values.orders.map(
                  (item, index): IOrderList => {
                    if (index === editIdx) {
                      return {
                        brandId: values?.brandId ?? "",
                        brandSKU: values?.brandSKU ?? "",
                        amount: values?.amount ?? "",
                        unit: values?.unit ?? "",
                        unitLabel: values?.unitLabel ?? "",
                        pricePerUnit: values?.pricePerUnit ?? "",
                        nameTh: values?.nameTh ?? "",
                        nameEn: values?.nameEn ?? "",
                        skuTh: values?.skuTh ?? "",
                        skuEn: values?.skuEn ?? "",
                      };
                    } else {
                      return {
                        brandId: item?.brandId ?? "",
                        brandSKU: item?.brandSKU ?? "",
                        amount: item?.amount ?? "",
                        unit: item?.unit ?? "",
                        unitLabel: item?.unitLabel ?? "",
                        pricePerUnit: item?.pricePerUnit ?? "",
                        nameTh: item?.nameTh ?? "",
                        nameEn: item?.nameEn ?? "",
                        skuTh: item?.skuTh ?? "",
                        skuEn: item?.skuEn ?? "",
                      };
                    }
                  }
                );
              }
            }

            handleRevalidateForm();
            setOpenSnackbarEdit(true);
          }
        }}
      />
      {/* Delete Modal */}
      <OrderFeedDeleteModal
        isOpen={openDeleteModal}
        handleClose={(isSuccess) => {
          setOpenDeleteModal(false);
          if (isSuccess) {
            const newArray = formik.values.orders.filter(
              (_, idx) => idx !== orderDeleteIndex
            );
            formik.values.orders = newArray;
            handleRevalidateForm();
            setOpenSnackbarDelete(true);
          }
        }}
      />
    </>
  );
}

const IconFeedPigWithBgSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={41} height={42} fill="none">
    <rect width={41} height={41} y={0.129} fill="#68C184" rx={20.5} />
    <g fill="#fff" clipPath="url(#a)">
      <path d="M13.2 8.251c-.117.112-.122.142-.122.781v.66h14.844v-.65c0-.586-.01-.66-.098-.757-.195-.205-.312-.195-.913.108l-.552.273-.537-.269c-.293-.146-.58-.268-.634-.268-.054 0-.342.122-.635.268l-.537.269-.537-.269c-.293-.146-.581-.268-.635-.268-.054 0-.342.122-.635.268l-.537.269-.537-.269c-.293-.146-.581-.268-.635-.268-.054 0-.342.122-.635.268l-.537.269-.537-.269c-.293-.146-.581-.268-.635-.268-.053 0-.341.122-.634.268l-.538.269-.537-.269c-.293-.146-.58-.268-.634-.268-.054 0-.342.122-.635.268l-.537.269-.537-.269c-.293-.146-.591-.268-.66-.268a.402.402 0 0 0-.244.122ZM27.022 11.313c-1.074 1.29-1.416 1.773-1.758 2.456-.478.957-.439.386-.454 6.543-.014 3.643 0 5.528.035 5.757.15.996.595 1.787 1.957 3.506.42.523.83 1.055.923 1.177.088.127.176.23.19.224.015 0 .123-.127.23-.278.113-.151.528-.654.928-1.108.395-.46.84-.991.986-1.192.347-.468.708-1.152.85-1.616l.112-.366.015-5.576c.015-6.172.024-5.904-.303-6.665-.2-.464-.498-.972-.923-1.563-.341-.473-1.85-2.334-1.894-2.334-.015 0-.42.469-.894 1.035Z" />
      <path d="M12.364 11.22c-1.045 1.308-1.562 2.178-1.86 3.134l-.137.44V26.22l.137.44a8.17 8.17 0 0 0 .776 1.684c.108.175.537.796.957 1.382l.762 1.06h6.875c5.493 0 6.875-.016 6.846-.064-.025-.03-.337-.435-.703-.889-.997-1.245-1.46-2.002-1.739-2.822-.263-.777-.263-.738-.263-6.524.005-5.844-.005-5.703.293-6.504.312-.869.952-1.811 2.221-3.305l.181-.205h-6.88l-6.875.004-.59.743Zm.376 4.155c.445.156.81.41 1.216.845l.342.366.4-.171a5.801 5.801 0 0 1 4.56-.005l.406.176.454-.459c.366-.366.532-.498.845-.654.376-.186.405-.19.889-.19.454 0 .503.009.62.116.234.22.341.601.341 1.202.005.722-.146 1.147-.712 2.04-.166.255-.235.406-.21.465.02.043.058.229.097.41l.064.332h-.645c-.669.005-.742.024-.854.234-.078.146-.034.317.102.45.098.087.171.097.777.097h.669l.224.249c.313.347.469.688.503 1.108a2.353 2.353 0 0 1-1.767 2.476c-.25.063-.826.073-4.078.068-3.413 0-3.813-.01-4.106-.083-1.333-.351-2.12-1.89-1.563-3.061.079-.161.24-.396.367-.528l.224-.23h.66c.64 0 .668-.004.78-.121a.359.359 0 0 0 0-.537c-.112-.117-.14-.122-.756-.122-.728 0-.684.039-.557-.513l.083-.342-.268-.415c-.537-.83-.699-1.28-.694-1.977 0-.645.176-1.104.484-1.285.17-.097.747-.068 1.103.059Z" />
      <path d="M11.985 16.112c-.17.454.015 1.3.42 1.9l.122.175.152-.21c.19-.253.459-.541.737-.776l.21-.18-.18-.225a2.97 2.97 0 0 0-.425-.396c-.41-.302-.967-.459-1.036-.288ZM21.458 16.112c-.102.04-.254.118-.342.176-.22.151-.718.64-.718.703 0 .03.137.176.308.327.166.147.4.406.523.572l.22.297.082-.107c.152-.195.415-.786.479-1.06.058-.253.044-.82-.02-.927-.049-.073-.302-.064-.532.02ZM15.746 19.116c-.957.24-1.636 1.26-1.46 2.198a1.976 1.976 0 0 0 1.123 1.479l.327.156h1.22c1.373 0 1.519-.03 1.963-.366a1.941 1.941 0 0 0 .005-3.125c-.444-.337-.566-.361-1.845-.371-.63-.01-1.23.005-1.333.03Zm.722 1.636c.342.337-.151.889-.503.562-.136-.132-.18-.303-.102-.45.136-.259.41-.312.605-.112Zm1.563 0c.341.337-.152.889-.503.562-.137-.132-.18-.303-.103-.45.137-.259.41-.312.606-.112ZM13.078 32.216c0 .586.01.659.098.757.195.205.312.195.913-.108l.552-.273.537.268c.293.147.58.269.634.269.054 0 .342-.122.635-.269l.537-.268.537.268c.293.147.581.269.635.269.054 0 .342-.122.635-.269l.537-.268.537.268c.293.147.581.269.635.269.054 0 .342-.122.635-.269l.537-.268.537.268c.293.147.581.269.635.269.053 0 .341-.122.634-.269l.538-.268.537.268c.293.147.58.269.634.269.054 0 .342-.122.635-.269l.537-.268.552.273c.6.303.718.313.913.108.088-.098.098-.171.098-.757v-.65H13.078v.65Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M8 8.129h25v25H8z" />
      </clipPath>
    </defs>
  </svg>
);
export default IconFeedPigWithBgSvg;

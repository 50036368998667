import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { IconButton } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import dayjs from "dayjs";
import AppBarFS from "../../../components/app-bar/AppBar";
import TextInputSearchCustom from "../../../admin/components/Input/TextInputSearchCustom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PersonIcon from "@mui/icons-material/Person";
import { get } from "lodash";
import { useAcceptedAssignmentController } from "./controller";
import DetailsAcceptedAssignmentModal from "./views/DetailsAcceptedAssignmentModal";

const AcceptedAssignment = () => {
  const {
    listAcceptedAssignment,
    isShowDetailsAssignment,
    onShowDetailsAssignment,
    onHideDetailsAssignment,
    DetailsAcceptedAssignment,
    InvoiceOption,
    invoice,
    setInvoice,
    selectedId,
    RefetchUpdateAcceptedAssignment,
    RefetchDetailsAcceptedAssignment,
    refetchAcceptedAssignment,
    handleOnClick,
  } = useAcceptedAssignmentController();

  let navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate("/partner/track-debt");
  }, []);

  return (
    <div className="bg-[#d9ebfe] w-full min-h-full">
      <div className="w-full h-full">
        <div className="h-[120px] flex items-center bg-[#FFFF] rounded-b-[40px]">
          <AppBarFS
            title={"ลูกค้าที่รับรายการเเล้ว"}
            onIconPress={handleBack}
          />
        </div>
        <div className="flex flex-col gap-4 py-[16px] px-4 justify-between">
          <span className="font-semibold text-[20px] text-[#3777BC] flex flex-col justify-center">
            ใบงานที่รับรายการเเล้ว
          </span>
          <div className="w-full flex justify-between">
            <TextInputSearchCustom
              value={""}
              className="max-w-[470px]"
              onChange={() => {}}
              placeholder="ค้นหาโดยชื่อลูกค้า"
              InputProps={{
                endAdornment: (
                  <CloseRoundedIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {}}
                  />
                ),
                sx: {
                  borderRadius: "30px",
                  height: "48px",
                },
              }}
            />
          </div>
        </div>
        {get(listAcceptedAssignment, ["count"], 0) > 0 ? (
          <>
            <div className="px-4 flex flex-col gap-4 pb-[37px]">
              {get(listAcceptedAssignment, ["rows"], []).map((d, index) => {
                return (
                  <div
                    key={`history-${index}`}
                    className="bg-[#FFFF] rounded-[15px] px-4 pt-4 pb-6 flex flex-col gap-3"
                    onClick={() => handleOnClick(d.id || "")}
                  >
                    <div className="flex flex-row gap-2 items-center">
                      <PersonIcon className="w-[22px] h-[22px] text-[#68C184]" />
                      <div className="text-[#34407B] text-[16px] font-semibold">
                        {d.farmerName}
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#4B4B4B] text-[14px] font-medium">
                        เลขใบงาน
                      </div>
                      <div className="text-[#4B4B4B] text-[14px] font-medium">
                        {d.assignmentNumber}
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#4B4B4B] text-[14px] font-medium">
                        เบอร์โทรศัพท์
                      </div>
                      <div className="text-[#4B4B4B] text-[14px] font-medium">
                        {d.farmerMobileNumber}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className="w-full flex flex-row justify-center">
            ไม่มีข้อมูลใบงานที่รับรายการเเล้ว
          </div>
        )}
      </div>
      <DetailsAcceptedAssignmentModal
        isOpen={isShowDetailsAssignment}
        onClose={onHideDetailsAssignment}
        onShowButton
        InvoiceOption={InvoiceOption}
        invoice={invoice}
        selectedId={selectedId}
        setInvoice={setInvoice}
        RefetchUpdateAcceptedAssignment={RefetchUpdateAcceptedAssignment}
        RefetchDetailsAcceptedAssignment={RefetchDetailsAcceptedAssignment}
        refetchAcceptedAssignment={refetchAcceptedAssignment}
        DetailsAcceptedAssignment={DetailsAcceptedAssignment}
      />
    </div>
  );
};

export default AcceptedAssignment;

import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useEffect, useRef, useState } from "react";
import { IUploadImageProps } from "./types";
const UploadImage = ({
  imageUrl,
  onChange,
  handleRemoveImage,
  index,
}: IUploadImageProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    index && setCurrentIndex(index);
  }, [index]);
  const imageRef = useRef<(HTMLInputElement | null)[]>([]);
  const handleTextInputFSClick = () => {
    const clickedElement = imageRef?.current[currentIndex];
    if (clickedElement) {
      clickedElement.click();
    }
  };

  return (
    <div
      onClick={handleTextInputFSClick}
      className="min-h-[55px] rounded-[28px] border-[#3777BC] border-[1px] bg-[#F7FBFF] p-3 flex items-center "
    >
      <AddPhotoAlternateIcon
        className="absolute translate-x-[280px]"
        style={{ color: "#3777BC", left: 70 }}
      />

      <input
        className="hidden"
        ref={(el) => (imageRef.current[currentIndex] = el)}
        type={imageUrl && imageUrl.length >= 2 ? "text" : "file"}
        accept=".jpg, .jpeg, .png, .svg"
        onChange={onChange}
        multiple={true}
      />
      {imageUrl && imageUrl.length > 0 && (
        <div className="w-[250px] h-[97px]  overflow-hidden flex">
          {imageUrl.map((ele) => {
            return (
              <div>
                <HighlightOffIcon
                  style={{
                    position: "absolute",
                    transform: "translate(75px, 5px)",
                    color: "#979797",
                  }}
                  onClick={(event) => {
                    event?.stopPropagation();
                    handleRemoveImage(ele);
                  }}
                />
                <img
                  src={ele}
                  className="object-cover h-full w-[100px] mx-[5px] rounded-3xl"
                  alt={"Image Icon " + ele}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default UploadImage;

import { useState } from "react";
import { Order } from "../../../components/Table/TableCustom";
import { TDropdownFarmSize } from "../../farm-detail/components/FarmDetailFilter";
import { IRevenueTable } from "../types/revenueTable.type";

export const useRevenueFilterHook = () => {
  const [searchText, setSearchText] = useState("");
  const [direction, setDirection] = useState<Order>("desc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [orderBy, setOrderBy] = useState<keyof IRevenueTable>("customer_code");

  return {
    searchText,
    direction,
    page,
    limit,
    setSearchText,
    setDirection,
    setPage,
    setLimit,
    orderBy,
    setOrderBy,
  };
};

export type TUseRevenueFilterHook = ReturnType<typeof useRevenueFilterHook>;

import { FormikProps, FormikState, getIn } from "formik";
import { patternTwoDigisAfterComma } from "../const/regex-pattern.constant";

export function formErrorMessage(
  formik: FormikState<any>,
  fieldName: string
): string | undefined {
  if (formik?.touched[fieldName] && formik?.errors[fieldName]) {
    const message: any = formik?.errors[fieldName] as string;
    let errMsg =
      message && typeof message === "object" && message?.length > 0
        ? message[0]?.trim()
        : typeof message == "string"
        ? message
        : "";
    return errMsg;
  } else {
    return;
  }
}

export function formError(
  formik: FormikState<any>,
  fieldName: string
): boolean {
  return (formik?.touched[fieldName] &&
    !!formik?.errors?.[fieldName]) as boolean;
}

export function twoDecimalPlacesValidator(val: any): boolean {
  if (val !== undefined) {
    return patternTwoDigisAfterComma.test(val);
  }
  return true;
}

export function formArrayErrorMessage(
  formik: FormikProps<any>,
  key: string,
): string {
  const error: string = getIn(formik.errors, key);
  const touched: boolean = getIn(formik.touched, key);
  return touched ? error : "";
}

export function formArrayError(
  formik: FormikState<any>,
  key: string,
): boolean {
  const error: string = getIn(formik.errors, key);
  const touched: boolean = getIn(formik.touched, key); 
  return touched && !!error;
}

import { UnitEnum } from "../../types/FeedBrand.type";

export const mapThaiTextToEnum: Record<EnumTopicReport, string> = {
  สินค้าชำรุด: "feed_damaged",
  สินค้าไม่ได้คุณภาพ: "feed_low_quality",
  สินค้าไม่ตรงกับออเดอร์: "feed_not_match_with_order",
};

export enum EnumTopicReport {
  DAMAGED_PRODUCT = "สินค้าชำรุด",
  PRODUCT_IS_NOT_OF_QUALITY = "สินค้าไม่ได้คุณภาพ",
  PRODUCT_DOES_NOT_MATCH = "สินค้าไม่ตรงกับออเดอร์",
}

export enum MapEnumTopicReport {
  feed_damaged = "สินค้าชำรุด",
  feed_low_quality = "สินค้าไม่ได้คุณภาพ",
  feed_not_match_with_order = "สินค้าไม่ตรงกับออเดอร์",
}

export type TOrderDetail = {
  nameTh: string;
  skuTh: string;
  amount: number;
  unit: UnitEnum;
  totalPrice: number;
};

export type TClaim = {
  orderClaimItemId: string;
  orderFeedId: string;
  reportReason: EnumTopicReport;
  reportAmount: number;
  detail: string;
  file1: File[];
  file2: File[];
  orderDetail: TOrderDetail;
};

export type TReportForm = {
  claims: TClaim[];
};

export interface TSubmitClaimForm {
  claimList: ClaimList[];
}

export interface ClaimList {
  orderClaimItemId: string;
  reportOrderReason: string;
  orderFeedItemId: string;
  reportAmount: number;
  reportDetail: string;
}

export interface TEditClaimDocument {
  documents: ClaimDocument[];
}

export interface ClaimDocument {
  fileId: string;
  fileName: string;
  fileSize: number;
}

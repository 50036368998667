import { Dialog, DialogContent, IconButton } from "@mui/material";
import {
  InvoiceLogFile,
  LogDetail,
} from "../../../../../types/invoice/log.type";
import CrossIcon from "../../../../assets/svg/CrossIcon.svg";
import PdfRenderer from "../../../../components/Modal/DocumentModal/ModalPanel/PdfRenderer";
import { useEffect, useState } from "react";
import { getDownloadInvoiceLog } from "../../../../../services/admin/invoice/invoiceSale.service";
import { covertToBlobUrl } from "../../../../../utils/downloadFile";

type ReverseLogModalProps = {
  isOpen: boolean;
  handleClose: (isSuccess: boolean, values?: any) => void;
  invoiceFile: InvoiceLogFile;
};

const classes = {
  container: `items-start w-[50%]`,
  label: `text-sm text-[#7E7E7E] mb-2`,
  title: `text-sm font-semibold pb-3 mb-1`,
  flexCenter: `flex justify-between items-center`,
};

const ImagePreviewModal = ({
  isOpen,
  handleClose,
  invoiceFile,
}: ReverseLogModalProps) => {
  const [invoiceUrl, setInvoiceUrl] = useState<string>("");

  useEffect(() => {
    if (invoiceFile) {
      const fetch = async () => {
        try {
          const invoice = await getDownloadInvoiceLog(
            invoiceFile.documentType,
            invoiceFile.id
          );
          if (invoice) {
            const objectURL = await covertToBlobUrl(invoice?.blob?.blobUrl);
            setInvoiceUrl(objectURL);
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetch();
    }
  }, [invoiceFile]);
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => handleClose(true)}
        maxWidth="lg"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={{
          backdrop: {
            style: { backgroundColor: "#000000", opacity: "10%" },
            timeout: 500,
          },
        }}
        PaperProps={{
          sx: {
            borderRadius: "20px",
          },
        }}
      >
        <DialogContent sx={{ width: "600px" }}>
          <div className={classes.flexCenter}>
            <p className="text-lg font-bold pb-3">ใบเสร็จรับเงิน</p>
            <IconButton onClick={() => handleClose(true)}>
              <CrossIcon />
            </IconButton>
          </div>
          <PdfRenderer url={invoiceUrl ?? ""} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImagePreviewModal;

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableRowProps,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import React, { useEffect, useRef, useState } from "react";
import { SortingAscSvg } from "../../assets/svg/SortingAsc.svg";
import { SortingDefaultSvg } from "../../assets/svg/SortingDefault.svg";
import { SortingDescSvg } from "../../assets/svg/SortingDesc.svg";
import PageOfPages from "./PageOfPages";
import PaginationCustom from "./Pagination";

export type Order = "asc" | "desc";
export interface ITableCustomHeadCell<Key extends keyof any> {
  disablePadding: boolean;
  id: Key;
  label: string;
  numeric: boolean;
  width: string;
  isSorting?: boolean;
  className?: string;
  textClassName?: string;
}

export interface IExpandableTableRowProps extends TableRowProps {
  children: React.ReactNode;
  expandComponent: React.ReactNode;
}

export const ExpandableTableRow = ({
  children,
  expandComponent,
  ...otherProps
}: IExpandableTableRowProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <>
      <TableRow
        {...otherProps}
        onDoubleClick={() => setIsExpanded(!isExpanded)}
        sx={{
          borderBottom: isExpanded ? "0px !important" : "1px solid #E1E1E1",
          "> td, th": {
            borderBottom: isExpanded ? "0px !important" : "1px solid #E1E1E1",
          },
        }}
      >
        {children}
        <TableCell
          padding="checkbox"
          sx={{
            borderRight: "1px solid rgba(224, 224, 224, 1)",
          }}
        >
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      {isExpanded && <TableRow>{expandComponent}</TableRow>}
    </>
  );
};

interface EnhancedTableProps {
  numSelected: number;
  headerItems: ITableCustomHeadCell<any>[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  itemSelected: (string | number)[];
  currentPageItems: (string | number)[];
  hideSelect?: boolean;
  fixHeader?: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    rowCount,
    headerItems,
    onRequestSort,
    itemSelected,
    currentPageItems,
    hideSelect,
    fixHeader = false,
  } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const countSelect = currentPageItems.filter((row) =>
    itemSelected.includes(row)
  ).length;

  return (
    <TableHead
      sx={{
        bgcolor: "#FBFBFB !important",
        border: "1px solid #E1E1E1",
        position: fixHeader ? "sticky" : "static",
        top: 0,
        zIndex: 1,
      }}
    >
      <TableRow>
        {!hideSelect && (
          <TableCell
            padding="checkbox"
            align="left"
            sx={{
              height: "50px",
              justifyContent: "start",
              fontFamily: "IBM Plex Sans Thai",
              fontWeight: 700,
              fontSize: "14px",
              border: "1px solid",
              bgcolor: "#FBFBFB !important",
              borderColor: "#E1E1E1",
              borderLeft: "1px solid #E1E1E1", // Ensure left border for the first cell
              borderRight: "0px",
            }}
          >
            <Checkbox
              color="primary"
              indeterminate={
                rowCount > 0 && countSelect < rowCount && countSelect > 0
              }
              checked={rowCount > 0 && countSelect === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
              sx={{
                borderWidth: "1px",
                color: "#C9C9C9",
                "&.Mui-checked": {
                  color: "#68C184",
                },
                "&.MuiCheckbox-indeterminate": {
                  color: "#68C184",
                },
              }}
            />
          </TableCell>
        )}
        {headerItems.map((item, index) =>
          item?.isSorting ? (
            <TableCell
              key={index}
              sortDirection={orderBy === item.id ? order : false}
              sx={{
                height: "50px",
                justifyContent: "start",
                fontFamily: "IBM Plex Sans Thai",
                fontWeight: 700,
                fontSize: "14px",
                border: "1px solid",
                padding: "0px 16px",
                bgcolor: "#FBFBFB !important",
                borderColor: "#E1E1E1",
                borderLeft:
                  index === 0
                    ? !hideSelect
                      ? "none"
                      : "1px solid #E1E1E1"
                    : "none", // Left border for the first item
                borderRight:
                  index === headerItems.length - 1
                    ? "1px solid #E1E1E1"
                    : "none", // Right border for the last item
              }}
              className={item.className}
            >
              <TableSortLabel
                active={orderBy === item.id}
                direction={orderBy === item.id ? order : "asc"}
                onClick={createSortHandler(item.id)}
                IconComponent={
                  orderBy === item.id
                    ? order === "desc"
                      ? SortingDescSvg
                      : SortingAscSvg
                    : SortingDefaultSvg
                }
                sx={{
                  width: item.width,
                  gap: "12px",
                }}
              >
                {item.label}
                {orderBy === item.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              key={index}
              sx={{
                height: "50px",
                justifyContent: "start",
                fontFamily: "IBM Plex Sans Thai",
                fontWeight: 700,
                fontSize: "14px",
                border: "1px solid",
                padding: "0px 16px",
                bgcolor: "#FBFBFB !important",
                borderColor: "#E1E1E1",
                borderLeft:
                  index === 0
                    ? !hideSelect
                      ? "none"
                      : "1px solid #E1E1E1"
                    : "none", // Left border for the first item
                borderRight:
                  index === headerItems.length - 1
                    ? "1px solid #E1E1E1"
                    : "none", // Right border for the last item
              }}
              className={item.className}
            >
              <div className="flex flex-col" style={{ width: item.width }}>
                <span className={item.textClassName}>{item.label}</span>
              </div>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

export interface ITableCustomProps<T> {
  order: Order;
  orderBy: string;
  itemsSelected: (string | number)[];
  rowsPerPage: number;
  page: number;
  headerItems: ITableCustomHeadCell<any>[];
  rows: T[];
  onChangePage: (page: number) => void;
  onChangeOrder: (value: Order) => void;
  onChangeOrderBy: (value: string) => void;
  onScrollToBottom?: () => Promise<void> | void;
  onSelectItem: (value: (string | number)[]) => void;
  onSelectAllClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  rowChildren: React.ReactNode;
  totalItemSize: number;
  currentPageItems: (string | number)[];
  hideSelect?: boolean;
  hidePaginator?: boolean;
  EmptyComponent?: React.ReactNode;
  maxHeightForScroll?: string;
}

function TableCustom<T>({
  order,
  orderBy,
  itemsSelected,
  rowsPerPage,
  page,
  headerItems,
  rows,
  totalItemSize,
  onChangeOrder,
  onChangeOrderBy,
  onChangePage,
  onSelectItem,
  onSelectAllClick,
  onScrollToBottom,
  rowChildren,
  currentPageItems,
  hideSelect,
  hidePaginator,
  EmptyComponent,
  maxHeightForScroll,
}: ITableCustomProps<T>) {
  const [isAtBottom, setIsAtBottom] = useState(false);
  const tableRef = useRef<HTMLElement>(null);
  const tableContainerRef = useRef<HTMLTableElement>(null);
  const _totalPage = Math.ceil(totalItemSize / rowsPerPage);
  const totalPage = _totalPage > 0 ? _totalPage : 1;
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    onChangeOrder(isAsc ? "desc" : "asc");
    onChangeOrderBy(property);
  };

  // const emptyRows =
  //   page - 1 > 0 ? Math.max(0, (1 + page - 1) * rowsPerPage - rows.length) : 0;

  useEffect(() => {
    if (!tableRef) return;
    const tableData = tableRef.current;
    // console.log(
    //   "Hit Table Ref",
    //   tableData?.offsetWidth,
    //   tableData?.offsetHeight
    // );
  }, [tableRef]);

  const handleScroll = () => {
    const div = tableContainerRef.current;
    if (div) {
      const isBottom = div.scrollHeight - div.scrollTop === div.clientHeight;
      setIsAtBottom(isBottom);
    }
  };

  useEffect(() => {
    const div = tableContainerRef.current;
    if (div) {
      div.addEventListener("scroll", handleScroll);
      return () => div.removeEventListener("scroll", handleScroll);
    }
  }, []);

  useEffect(() => {
    if (isAtBottom && onScrollToBottom) {
      onScrollToBottom();
    }
  }, [isAtBottom]);

  return (
    <Box component={"div"} sx={{ width: "100%" }} ref={tableRef}>
      <Paper sx={{ width: "100%", mb: 2 }} elevation={0}>
        <TableContainer
          ref={tableContainerRef}
          sx={{
            scrollBehavior: "smooth",
            overflow: "auto",
            maxHeight: maxHeightForScroll,
          }}
        >
          <Table
            sx={{
              width: "100%",
            }}
            aria-labelledby="table-show-data"
            size={"medium"}
            stickyHeader
          >
            <EnhancedTableHead
              numSelected={itemsSelected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={onSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headerItems={headerItems}
              itemSelected={itemsSelected}
              currentPageItems={currentPageItems}
              hideSelect={hideSelect}
            />
            <TableBody>{rowChildren}</TableBody>
          </Table>
        </TableContainer>
        {EmptyComponent}
      </Paper>
      {!hidePaginator && (
        <div className="flex w-full justify-between">
          <PageOfPages
            page={page}
            totalPage={totalPage}
            onChangePage={(val) => onChangePage(val)}
          />
          <PaginationCustom
            page={page}
            onChangePage={(val) => onChangePage(val)}
            defaultCount={totalPage}
          />
        </div>
      )}
    </Box>
  );
}

export default TableCustom;

import { useState } from "react";
import { decodeJWTToken } from "./decode-jwt";
import { deleteItem } from "../services/StorageService";
import { AUTH_TOKEN_KEY } from "../const/app.constant";

export const useLocalStorage = (
  keyName: string,
  defaultValue: string | null
): any => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);

      if (value) {
        const decodedToken = decodeJWTToken(value);
        if (decodedToken) {
          return decodedToken;
        } else {
          deleteItem(AUTH_TOKEN_KEY);
        }
        return null;
      } else {
        if (defaultValue) {
          window.localStorage.setItem(keyName, defaultValue);
        } else {
          deleteItem(keyName);
        }
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = (newValue: string) => {
    try {
      window.localStorage.setItem(keyName, newValue);
    } catch (err) {}
    const decodedToken = decodeJWTToken(newValue);
    if (decodedToken) {
      setStoredValue(decodedToken);
    } else {
      setStoredValue(null);
    }
  };
  return [storedValue, setValue];
};

import {
  getHouseDashboardHouseStatus,
  getHouseDetail,
} from "../../services/FarmService";
import { getMoveOutPigHouseDetail } from "../../services/MoveOutPig.service";

export async function houseDashboardLoader({ params }: any) {
  try {
    const { houseId } = params;
    if (!houseId) {
      throw new Response("Not Found", { status: 404 });
    }

    const [houseDetailResp, houseStatusResp] = await Promise.all([
      getHouseDetail(houseId),
      getHouseDashboardHouseStatus(houseId),
    ]);

    if (houseDetailResp && houseDetailResp?.data?.success) {
      return {
        house: houseDetailResp.data?.house,
        houseStatus: houseStatusResp?.data?.success
          ? houseStatusResp?.data
          : null,
        farm: houseDetailResp.data?.farm,
      };
    } else {
      throw new Response("Not Found", { status: 404 });
    }
  } catch (err) {
    throw new Response("Not Found", { status: 404 });
  }
}

export async function moveOutPigHouseLoader({
  params,
}: {
  params: { houseId: string };
}) {
  try {
    const { houseId } = params;
    if (!houseId) {
      throw new Response("Not Found", { status: 404 });
    }

    const house = await getMoveOutPigHouseDetail(houseId);

    if (house.success) {
      return {
        house,
      };
    } else {
      throw new Response("Not Found", { status: 404 });
    }
  } catch (err) {
    throw new Response("Not Found", { status: 404 });
  }
}

import React, { useCallback, useEffect, useState } from "react";
import { OrderClaimItem } from "../../../types/Report";
import { thousandComma } from "../../../utils/common/string";
import { UnitEnum } from "../../../types/FeedBrand.type";
import { MapEnumTopicReport } from "../type";
import { get } from "lodash";
import { getDownloadUrlReportFarmer } from "../../../services/FarmerReport.service";
import axios from "axios";

type Props = {
  order: OrderClaimItem;
  idx: number;
};

const ClaimItem = ({ order, idx }: Props) => {
  const [images, setImages] = useState<string[]>([]);
  const initImage = useCallback(async () => {
    const imgs = get(order, ["orderClaimItemFiles"]);

    const images = [];
    if (order && imgs.length > 0) {
      if (imgs.length > 0) {
        for (let i = 0; i < imgs.length; i++) {
          const img = imgs[i];
          //get api
          const preSignURL = await getDownloadUrlReportFarmer(order.id, img.id);
          if (preSignURL) {
            const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
              responseType: "blob",
            });
            const objectURL = window.URL.createObjectURL(response.data);
            images.push(objectURL);
          }
        }
      }
    }
    setImages(images);
  }, [order.id]);

  useEffect(() => {
    initImage();
  }, [order.id]);

  return (
    <div className="border-b-8 border-[#E2E2E2] py-2">
      <div className="mx-4">
        {/* รายการที่ */}
        <div className="flex justify-between font-medium text-[18px]">
          <span className=" text-tertiary">&#9679; รายการที่ {idx + 1}</span>
        </div>
        <div className="flex justify-between text-sm mt-2">
          <p className="text-tertiary">แบรนด์อาหารสัตว์</p>
          <p className="text-primary">{order?.feedBrand?.nameTh}</p>
        </div>
        <div className="flex justify-between text-sm mt-2">
          <p className="text-tertiary">เบอร์อาหารสัตว์</p>
          <p className="text-primary">{order?.feedBrandItem?.skuTh}</p>
        </div>
        <div className="flex justify-between text-sm mt-2">
          <p className="text-tertiary">จำนวน</p>
          <p className="text-primary">
            {thousandComma(order?.amount)}{" "}
            {UnitEnum[order?.feedUnit as keyof typeof UnitEnum]}
          </p>
        </div>
        <p className="text-end text-sm my-3">
          {thousandComma(order.totalItemPrice)} บาท
        </p>
        <div className="flex justify-between text-sm">
          <p className="text-tertiary">เหตุผล</p>
          <p>
            {
              MapEnumTopicReport[
                order.reportReason as keyof typeof MapEnumTopicReport
              ]
            }
          </p>
        </div>
        <div className="flex justify-between text-sm mt-2">
          <p className="text-tertiary">จำนวนที่รายงานปัญหา</p>
          <p>{order.reportAmount}</p>
        </div>
        <div className="flex justify-between text-sm mt-2 ">
          <p className="text-tertiary">รายละเอียดเหตุผล</p>
          <p>{order.reportDetail}</p>
        </div>
        <div className="flex gap-[10px] mt-4">
          {images!.map((img1Src, index) => (
            <img
              key={`${index}`}
              className={`w-[80px] h-[80px] rounded-[10px]`}
              alt="image1"
              src={img1Src}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClaimItem;

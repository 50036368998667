import FilterListIcon from "@mui/icons-material/FilterList";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { MenuItem } from "@mui/material";
import TextInputFS from "../../../../../components/common/Input/TextInput";
import { IFilterOrderYear, Order } from "../type";

const FilterOrder = ({
  orderBy,
  setOrderBy,
  title,
  titleContainer = "",
}: {
  orderBy: Order;
  setOrderBy: (value: Order) => void;
  title: string;
  titleContainer?: string;
}) => {
  const orderCloseDateItems: IFilterOrderYear[] = [
    {
      id: "new-old",
      name: "ใหม่-เก่า",
      key: "desc",
    },
    {
      id: "old-new",
      name: "เก่า-ใหม่",
      key: "asc",
    },
  ];

  return (
    <div className="flex justify-between items-center mx-4 mb-4">
      <div className={titleContainer}>
        <span className="text-lg font-semibold text-tertiary">{title}</span>
      </div>
      <div className="flex flex-row">
        <div className="text-secondary flex-shrink-0">
          <FilterListIcon />
          <span className="text-sm mx-2">กรองโดย</span>
        </div>
        <div>
          <TextInputFS
            select
            name="closeDate"
            id="closeDate"
            onChange={(e) => {
              setOrderBy(e.target.value as Order);
            }}
            value={orderBy}
            InputProps={{
              startAdornment: null,
              sx: {
                height: "2rem",
                width: "fit-content",
                borderRadius: "10px",
                border: "1px solid #68C184",
              },
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "10px !important",
                border: "1px solid #68C184",
                bgcolor: "#F0FDF4",
                color: "#68C184",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "0px",
              },
            }}
            SelectProps={{
              sx: {
                "& .MuiSelect-icon": {
                  color: "#68C184",
                  transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
                "& .MuiSelect-select": {
                  padding: "4px",
                  width: "100%",
                  textAlign: "center",
                  justifyContent: "center",
                },
                height: "32px",
                width: "130px",
              },
              IconComponent: KeyboardArrowDownIcon,
            }}
          >
            {orderCloseDateItems.map((item, index) => (
              <MenuItem key={`farm-item-${index}`} value={item.key}>
                {item.name}
              </MenuItem>
            ))}
          </TextInputFS>
        </div>
      </div>
    </div>
  );
};

export default FilterOrder;

import { TextField, TextFieldProps } from "@mui/material";

interface IcustomStylesProps {
  borderRadius?: string;
  textAlign?: string;
  borderColor?: string;
}

export default function TextInputCustom(
  props: TextFieldProps & IcustomStylesProps
) {
  const { borderRadius, textAlign, borderColor, ...restProps } = props;
  return (
    <TextField
      {...restProps}
      color="tertiary"
      variant="outlined"
      fullWidth
      autoComplete="off"
      InputProps={{
        sx: {
          borderRadius: borderRadius || "28px",
          fontWeight: 500,
          color: "#191919",
          background: "#F7FBFF",
          borderColor: borderColor || "#3777BC",
          textAlign: textAlign || "",
        },
        ...restProps?.InputProps,
      }}
      sx={{
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px !important",
        },
        "& .Mui-disabled": {
          color: "#979797 !important",
          WebkitTextFillColor: "#979797 !important",
          bgcolor: "#FFFFFF !important",
        },
        "& input::placeholder": {
          opacity: 1,
          color: "#979797",
        },
        "& .MuiInputBase-input": {
          ":-webkit-autofill": {
            height: "19px",
            bgcolor: "transparent",
          },
        },
      }}
      SelectProps={{
        sx: {
          borderRadius: borderRadius || "28px",
          fontWeight: 500,
          color: "#191919",
          background: "#F7FBFF",
          borderColor: borderColor || "#3777BC",
          textAlign: textAlign || "",

          "& .Mui-disabled": {
            color: "#979797 !important",
            WebkitTextFillColor: "#979797 !important",
            bgcolor: "#FFFFFF !important",
          },
        },
      }}
      InputLabelProps={{
        sx: {
          "&.Mui-disabled": {
            color: "#979797 !important",
          },
        },
      }}
      // InputLabelProps={{ shrink: restProps.value ? true : false }}
    />
  );
}

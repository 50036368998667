export default function CartSVG() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_5932_22241"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <rect y="0.308594" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5932_22241)">
        <path
          d="M7 21.9883C6.45 21.9883 5.97917 21.7924 5.5875 21.4008C5.19583 21.0091 5 20.5383 5 19.9883C5 19.4383 5.19583 18.9674 5.5875 18.5758C5.97917 18.1841 6.45 17.9883 7 17.9883C7.55 17.9883 8.02083 18.1841 8.4125 18.5758C8.80417 18.9674 9 19.4383 9 19.9883C9 20.5383 8.80417 21.0091 8.4125 21.4008C8.02083 21.7924 7.55 21.9883 7 21.9883ZM17 21.9883C16.45 21.9883 15.9792 21.7924 15.5875 21.4008C15.1958 21.0091 15 20.5383 15 19.9883C15 19.4383 15.1958 18.9674 15.5875 18.5758C15.9792 18.1841 16.45 17.9883 17 17.9883C17.55 17.9883 18.0208 18.1841 18.4125 18.5758C18.8042 18.9674 19 19.4383 19 19.9883C19 20.5383 18.8042 21.0091 18.4125 21.4008C18.0208 21.7924 17.55 21.9883 17 21.9883ZM5.2 3.98828H19.95C20.3333 3.98828 20.625 4.15911 20.825 4.50078C21.025 4.84245 21.0333 5.18828 20.85 5.53828L17.3 11.9383C17.1167 12.2716 16.8708 12.5299 16.5625 12.7133C16.2542 12.8966 15.9167 12.9883 15.55 12.9883H8.1L7 14.9883H19V16.9883H7C6.25 16.9883 5.68333 16.6591 5.3 16.0008C4.91667 15.3424 4.9 14.6883 5.25 14.0383L6.6 11.5883L3 3.98828H1V1.98828H4.25L5.2 3.98828Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

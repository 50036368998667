import React from "react";
import { useTranslation } from "react-i18next";
import NoticeLogo from "../../../assets/images/Farms/Notice-logo.svg";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router";
import PopupImage from "../../../assets/images/FarmRegistration/popup_bg.png";
import CustomButton from "../Button";

export interface PopupType {
  onCancel: () => void;
  onClick: () => void;
  vaccineProgramDetailsData: any;
}
const AddHouseNoticePopup = ({
  onClick,
  onCancel,
  vaccineProgramDetailsData,
}: PopupType | any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      className="flex bg-bottom bg-no-repeat"
      style={{ backgroundImage: `url(${PopupImage})` }}
    >
      <div className="p-3 h-full flex flex-col justify-between w-full">
        <div className="flex flex-col items-end">
          <ClearIcon
            className="w-[30px] h-[30px] mr-2 mt-2"
            onClick={onCancel}
          />
        </div>
        <div className="flex flex-col" style={{ gap: " calc(100vh - 610px)" }}>
          <div className="flex flex-col grow mt-10 items-center">
            <img
              src={NoticeLogo}
              className="mb-[32px] w-[100%] max-w-[130px]"
              alt="Notice-Logo"
            />
            <div className="flex flex-col items-center">
              <h3 className="font-medium text-[24px] text-justify text-[#F57C00]">
                {t("addHouse.noticeModalTitle")}
              </h3>
              <h4 className="py-2 text-center text-[#646464] font-normal px-[14%]">
                {t("addHouse.noticeModalText")}
              </h4>
            </div>
          </div>
          <div>
            <div className="mb-5 flex items-center justify-center">
              <button
                className="add-farms-btn h-[59px] px-[26px] py-[10px] rounded-[40px] border border-green-500 mt-10 m-auto flex font-bold text-[#68C184] bg-[#fff] max-w-[300px] justify-center items-center w-full"
                onClick={() => {
                  navigate("/edit-vaccine", {
                    state: vaccineProgramDetailsData,
                  });
                }}
              >
                {t("addHouse.noticeModalModifyButtonText")}
              </button>
            </div>
            <div className="mb-5 flex items-center justify-center">
              <CustomButton
                variant="tertiary"
                className="max-w-[300px]"
                label={t("addHouse.noticeModalStickButtonText")}
                onClick={() => onCancel()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddHouseNoticePopup;

export default function MyOrderSVG() {
  return (
    <svg
      width="77"
      height="77"
      viewBox="0 0 77 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="77" height="77" rx="20" fill="#5572EB" />
      <path
        d="M36.25 20.5C35.0074 20.5 34 21.5074 34 22.75C34 23.9926 35.0074 25 36.25 25H40.75C41.9926 25 43 23.9926 43 22.75C43 21.5074 41.9926 20.5 40.75 20.5H36.25Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25 27.25C25 24.7647 27.0147 22.75 29.5 22.75C29.5 26.4779 32.5221 29.5 36.25 29.5H40.75C44.4779 29.5 47.5 26.4779 47.5 22.75C49.9853 22.75 52 24.7647 52 27.25V52C52 54.4853 49.9853 56.5 47.5 56.5H29.5C27.0147 56.5 25 54.4853 25 52V27.25ZM31.75 36.25C30.5074 36.25 29.5 37.2574 29.5 38.5C29.5 39.7426 30.5074 40.75 31.75 40.75H31.7725C33.0151 40.75 34.0225 39.7426 34.0225 38.5C34.0225 37.2574 33.0151 36.25 31.7725 36.25H31.75ZM38.5 36.25C37.2574 36.25 36.25 37.2574 36.25 38.5C36.25 39.7426 37.2574 40.75 38.5 40.75H45.25C46.4926 40.75 47.5 39.7426 47.5 38.5C47.5 37.2574 46.4926 36.25 45.25 36.25H38.5ZM31.75 45.25C30.5074 45.25 29.5 46.2574 29.5 47.5C29.5 48.7426 30.5074 49.75 31.75 49.75H31.7725C33.0151 49.75 34.0225 48.7426 34.0225 47.5C34.0225 46.2574 33.0151 45.25 31.7725 45.25H31.75ZM38.5 45.25C37.2574 45.25 36.25 46.2574 36.25 47.5C36.25 48.7426 37.2574 49.75 38.5 49.75H45.25C46.4926 49.75 47.5 48.7426 47.5 47.5C47.5 46.2574 46.4926 45.25 45.25 45.25H38.5Z"
        fill="white"
      />
    </svg>
  );
}

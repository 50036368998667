import { TDropdownValue } from "../../../../../admin/pages/group-area/views/Dropdown";

export type TFormSeeHouseDetailsBasicAccounting = {
  month: TDropdownValue[];
};

export const defaultFormSeeHouseDetailsBasicAccounting: TFormSeeHouseDetailsBasicAccounting =
  {
    month: [],
  };

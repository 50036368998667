import { Chip, Paper } from "@mui/material";
import { IPastPerformanceInfoCardProps } from "./types";

export default function PastPerformanceInfoCard({
  title,
  value,
  bgColor,
  t,
}: IPastPerformanceInfoCardProps) {
  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: "20px",
        bgcolor: `${bgColor ?? "#FFFFFF"}`,
        width: "100%",
      }}
    >
      <div className="flex flex-col px-2 py-6 w-full">
        <div className="text-primary text-3xl text-center leading-none font-medium">
          {value}
        </div>
        <div className="text-tertiary text-sm text-center font-normal">
          {t("farmPastPerformance.card.average")}
        </div>
        <div className="w-full flex justify-center pt-2">
          <Chip
            label={title}
            variant="filled"
            color="primary"
            sx={{
              border: "1px solid #5572EB",
              backgroundColor: "#5572EB",
              color: "#FFFFFF",
              fontWeight: 400,
              fontSize: "16px",
            }}
          />
        </div>
      </div>
    </Paper>
  );
}

import Typography from "@mui/material/Typography";
import { InputAdornment, MenuItem } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useFormik } from "formik";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { date, number, object, string } from "yup";
import CustomButton from "../../../components/common/Button";
import ButtonGroupControl from "../../../components/common/ButtonGroupControl";
import DatePickerFS from "../../../components/common/DatePicker";
import TextInputFS from "../../../components/common/Input/TextInput";
import { checkHouseName } from "../../../services/FarmService";
import { Farm } from "../../../types";
import {
  formError,
  formErrorMessage,
  twoDecimalPlacesValidator,
} from "../../../utils/form-validator";
import EventIcon from "@mui/icons-material/Event";
import { validateFarmName } from "../../../components/common/validations";
import { getCommaNumber } from "../../../utils/number";

const AgeOfPig = [
  { value: 7, label: "7" },
  { value: 14, label: "14" },
  { value: 21, label: "21" },
  { value: 28, label: "28" },
];
interface AddHouseDetailsProps {
  selectedFarm?: string;
  farms: Farm[];
  basicDetailForm?: any;
  onSubmit?: (form: any) => void;
  isEdit?: boolean;
}
export default function BasicDetails({
  farms,
  basicDetailForm,
  onSubmit: onFormSubmit,
  isEdit,
}: AddHouseDetailsProps) {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const location = useLocation();
  const ref = useRef<any>(null);

  const maxDate = dayjs();
  const currentDate = dayjs();

  // Effects
  // useEffect(() => {
  //     if (selectedFarm) {
  //         formik.setFieldValue('farmId', selectedFarm)
  //     }
  // }, [selectedFarm]);
  useEffect(() => {
    if (basicDetailForm && isEdit) {
      const data = {
        farmId: basicDetailForm.farmId != null ? basicDetailForm.farmId : "",
        houseName:
          basicDetailForm.houseName != null ? basicDetailForm.houseName : "",
        breederPig:
          basicDetailForm.breederPig != null ? basicDetailForm.breederPig : "",
        fattenerPig:
          basicDetailForm.fattenerPig != null
            ? basicDetailForm.fattenerPig
            : "",
        nurseryPig:
          basicDetailForm.nurseryPig != null ? basicDetailForm.nurseryPig : "",
        houseStartDate:
          basicDetailForm.houseStartDate != null
            ? basicDetailForm.houseStartDate
            : currentDate,
        ageOfPig:
          basicDetailForm.ageOfPig != null ? basicDetailForm.ageOfPig : "",
        totalWeightOfPigs:
          basicDetailForm.totalWeightOfPigs &&
          (basicDetailForm.fattenerPig != null ||
            basicDetailForm.nurseryPig != null)
            ? Math.ceil(
                basicDetailForm.totalWeightOfPigs /
                  ((basicDetailForm.fattenerPig || 0) +
                    (basicDetailForm.nurseryPig || 0))
              )
            : "",
        totalPriceOfPigs:
          (basicDetailForm.totalPriceOfPigs && basicDetailForm.fattenerPig) !=
          null
            ? Math.ceil(
                basicDetailForm.totalPriceOfPigs /
                  (basicDetailForm.fattenerPig || 0)
              )
            : "",
        pricePerPig:
          basicDetailForm.pricePerPig != null ? basicDetailForm.pricePerPig : 0,
      };
      setFormValues(data);
    } else if (basicDetailForm) {
      const data = {
        farmId: basicDetailForm.farmId != null ? basicDetailForm.farmId : "",
        houseName:
          basicDetailForm.houseName != null ? basicDetailForm.houseName : "",
        breederPig:
          basicDetailForm.breederPig != null ? basicDetailForm.breederPig : "",
        fattenerPig:
          basicDetailForm.fattenerPig != null
            ? basicDetailForm.fattenerPig
            : "",
        nurseryPig:
          basicDetailForm.nurseryPig != null ? basicDetailForm.nurseryPig : "",
        houseStartDate:
          basicDetailForm.houseStartDate != null
            ? basicDetailForm.houseStartDate
            : currentDate,
        ageOfPig:
          basicDetailForm.ageOfPig != null ? basicDetailForm.ageOfPig : "",
        totalWeightOfPigs:
          basicDetailForm.totalWeightOfPigs &&
          (basicDetailForm.fattenerPig != null ||
            basicDetailForm.nurseryPig != null)
            ? basicDetailForm.totalWeightOfPigs
            : "",
        totalPriceOfPigs:
          (basicDetailForm.totalPriceOfPigs && basicDetailForm.fattenerPig) !=
          null
            ? basicDetailForm.totalPriceOfPigs
            : "",
        pricePerPig:
          basicDetailForm.pricePerPig != null ? basicDetailForm.pricePerPig : 0,
      };
      setFormValues(data);
    }
  }, [basicDetailForm, isEdit]);

  async function setFormValues(basicDetailForm: any) {
    try {
      if (basicDetailForm) {
        await formik.setValues(basicDetailForm, true);
        await formik.validateForm();
      }
    } catch (err) {
      console.error("ERRRRR", err);
    }
  }

  const formik = useFormik({
    initialValues: {
      farmId: "",
      houseName: "",
      breederPig: "",
      fattenerPig: "",
      nurseryPig: "",
      houseStartDate: currentDate,
      ageOfPig: "",
      totalWeightOfPigs: "",
      totalPriceOfPigs: "",
      pricePerPig: "0",
    },
    validationSchema: object({
      farmId: string().required(`${t("addHouseDetail.farmNameRequired")}`),
      houseName: string()
        .required(`${t("addHouseDetail.houseNameRequired")}`)
        .test(
          `${t("addHouseDetail.invalidHouseName")}`,
          (value: string, ctx: any) => {
            return !validateFarmName(value)
              ? ctx.createError({
                  message: `${t("addHouseDetail.invalidHouseName")}`,
                })
              : true;
          }
        )
        .max(15, `${t("addHouseDetail.mustBe15CharactersLess")}`),
      breederPig: number()
        .positive(`${t("addHouseDetail.invalidBreederPigsNumber")}`)
        .integer(`${t("addHouseDetail.invalidBreederPigsNumber")}`)
        .typeError(`${t("addHouseDetail.invalidBreederPigsNumber")}`)
        .optional()
        .max(9999, `${t("addHouseDetail.shouldbe4digit")}`),
      fattenerPig: number()
        .positive(`${t("addHouseDetail.invalidBreederPigsNumber")}`)
        .integer(`${t("addHouseDetail.InvalidFattenerPigsNumber")}`)
        .typeError(`${t("addHouseDetail.InvalidFattenerPigsNumber")}`)
        .optional()
        .max(9999, `${t("addHouseDetail.shouldbe4digit")}`),
      nurseryPig: number()
        .positive(`${t("addHouseDetail.ShouldPositiveInteger")}`)
        .integer(`${t("addHouseDetail.ShouldbeIntegeronly")}`)
        .typeError(`${t("addHouseDetail.NotAnInteger")}`)
        .optional()
        .max(9999, `${t("addHouseDetail.shouldbeMax4Digit")}`),
      houseStartDate: date()
        .nullable()
        .required(`${t("addHouseDetail.houseStartDateRequired")}`),
      ageOfPig: number()
        .positive(`${t("addHouseDetail.ShouldPositiveInteger")}`)
        .integer(`${t("addHouseDetail.ShouldbeIntegeronly")}`)
        .typeError(`${t("addHouseDetail.NotAnInteger")}`)
        .max(999, `${t("addHouseDetail.ShouldbeMax3Digit")}`)
        .when(["fattenerPig", "nurseryPig"], {
          is: (fattenerPig: any, nurseryPig: any) =>
            fattenerPig > 0 || nurseryPig > 0,
          then: (schema) =>
            schema.required(`${t("addHouseDetail.AgeOfPigRequired")}`),
          otherwise: (schema) => schema.optional(),
        }),
      totalWeightOfPigs: number()
        .positive(`${t("addHouseDetail.ShouldPositiveInteger")}`)
        .typeError(`${t("addHouseDetail.NotAnInteger")}`)
        .max(99999999, `${t("addHouseDetail.ShouldbeMax5Digit")}`)
        .test(
          "two-decimal",
          `${t("addHouseDetail.OnlyTwoDecimalsAllowed")}`,
          twoDecimalPlacesValidator
        )
        .when(["fattenerPig", "nurseryPig"], {
          is: (fattenerPig: any, nurseryPig: any) =>
            fattenerPig > 0 || nurseryPig > 0,
          then: (schema) =>
            schema.required(`${t("addHouseDetail.totalWeightPigsisRequired")}`),
          otherwise: (schema) => schema.optional(),
        }),
      totalPriceOfPigs: number()
        .positive(`${t("addHouseDetail.ShouldPositiveInteger")}`)
        .typeError(`${t("addHouseDetail.NotAnInteger")}`)
        .max(99999999, `${t("addHouseDetail.shouldMax6Digit")}`)
        .test(
          "two-decimal",
          `${t("addHouseDetail.OnlyTwoDecimalsAllowed")}`,
          twoDecimalPlacesValidator
        )
        .when(["fattenerPig", "nurseryPig"], {
          is: (fattenerPig: any, nurseryPig: any) =>
            fattenerPig > 0 || nurseryPig > 0,
          then: (schema) =>
            schema.required(`${t("addHouseDetail.totalPricePigsRequired")}`),
          otherwise: (schema) => schema.optional(),
        }),
      pricePerPig: number().optional(),
    }),
    onSubmit: async (values) => {
      try {
        if (!validatePigNumber()) {
          markErrorOnPigNumber();
          return false;
        }

        if (
          formik?.values?.totalWeightOfPigs &&
          (formik.values?.fattenerPig || formik?.values?.nurseryPig)
        ) {
          const totalWeight = formik?.values?.totalWeightOfPigs as any;

          if (totalWeight > 100) {
            formik.setFieldError(
              "totalWeightOfPigs",
              "Average weight of fattener and nursery must be 100 or less"
            );
            // formik?.values?.fattenerPig &&
            //   formik.setFieldError(
            //     "fattenerPig",
            //     "Average weight of fattener and nursery must be 100 or less"
            //   );

            // formik?.values?.nurseryPig &&
            //   formik.setFieldError(
            //     "nurseryPig",
            //     "Average weight of fattener and nursery must be 100 or less"
            //   );
            return false;
          }
        }

        const resp = await checkHouseName({
          name: formik?.values?.houseName,
          farmId: formik?.values?.farmId,
        });

        if (resp?.data?.success && location.pathname != "/edit-house") {
          formik.setFieldError("houseName", resp?.data?.message);
          ref?.current?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        } else {
          let farmName = getFarmName(formik?.values?.farmId);
          onFormSubmit?.({ ...values, farmName });
        }

        return true;
      } catch (err) {
        console.error(err);
      }
    },
  });

  const getFarmName = (farmId: string) => {
    let value = farms?.find((item) => item.id == farmId);
    return value?.name ? value.name : "";
  };

  useEffect(() => {
    const fattenerPig: number = (formik?.values?.fattenerPig as any) || 0;
    const nurseryPig: number = (formik?.values?.nurseryPig as any) || 0;
    const totalPriceOfPigs: number =
      ((typeof formik?.values?.totalPriceOfPigs === "string"
        ? parseInt(formik?.values?.totalPriceOfPigs)
        : formik?.values?.totalPriceOfPigs) as any) || 0;
    const totalPigs =
      (typeof fattenerPig === "string" ? parseInt(fattenerPig) : fattenerPig) +
      (typeof nurseryPig === "string" ? parseInt(nurseryPig) : nurseryPig);
    const totalPigsFattenerPig =
      typeof fattenerPig === "string" ? parseInt(fattenerPig) : fattenerPig;
    if (totalPriceOfPigs > 0 && totalPigs > 0) {
      const pricePerPig = totalPriceOfPigs * totalPigsFattenerPig;
      formik.setFieldValue("pricePerPig", pricePerPig.toFixed(2));
    } else {
      formik.setFieldValue("pricePerPig", "0");
    }
  }, [
    formik.values.nurseryPig,
    formik.values.fattenerPig,
    formik.values.totalPriceOfPigs,
  ]);

  function validatePigNumber(): boolean {
    const { breederPig, nurseryPig, fattenerPig } = formik.values;
    if (breederPig || nurseryPig || fattenerPig) {
      return true;
    }
    return false;
  }

  function markErrorOnPigNumber(): void {
    ["breederPig", "nurseryPig", "fattenerPig"].forEach((field: string) => {
      formik.setFieldError(
        field,
        "Either of Breeder,Fattening or Nursery is required"
      );
    });
  }

  const handleDateChange = (val: Dayjs | null) => {
    if (val) {
      formik.setFieldTouched("houseStartDate", true);
      formik.setFieldValue("houseStartDate", val);
    }
  };

  function handlePigAgeChange(age: string) {
    formik?.setFieldValue("ageOfPig", parseInt(age, 10));
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="flex flex-col grow gap-4 justify-between"
    >
      <div className="flex flex-col gap-4">
        <TextInputFS
          select
          fullWidth
          helperText={formErrorMessage(formik, "farmId")}
          error={formError(formik, "farmId")}
          placeholder={t("addHouseDetail.farmNamePlaceholder") || ""}
          label={t("addHouseDetail.farmNameLabel")}
          {...formik.getFieldProps("farmId")}
        >
          {farms.map((option: Farm) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextInputFS>
        <TextInputFS
          inputRef={ref}
          helperText={formErrorMessage(formik, "houseName")}
          error={formError(formik, "houseName")}
          placeholder={t("addHouseDetail.houseNamePlaceholder") || ""}
          label={t("addHouseDetail.houseNameLabel")}
          {...formik.getFieldProps("houseName")}
        />
        <div>
          <Typography className="text-primary-grey font-medium" variant="h6">
            {t("addHouseDetail.typeOfPigHeading")}
          </Typography>
        </div>
        <TextInputFS
          helperText={formErrorMessage(formik, "breederPig")}
          error={formError(formik, "breederPig")}
          type="tel"
          placeholder={t("addHouseDetail.breederPigPlaceholder") || ""}
          label={t("addHouseDetail.breederPigLabel")}
          {...formik.getFieldProps("breederPig")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="h6" className="text-tertiary font-medium">
                  | {t("addHouseDetail.numberOfPigsSuffix")}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
        {/* จำนวนหมูขุน */}
        <TextInputFS
          helperText={formErrorMessage(formik, "fattenerPig")}
          error={formError(formik, "fattenerPig")}
          type="tel"
          placeholder={t("addHouseDetail.fattenerPigPlaceholder") || ""}
          label={t("addHouseDetail.fattenerPigLabel")}
          {...formik.getFieldProps("fattenerPig")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="h6" className="text-tertiary font-medium">
                  | {t("addHouseDetail.numberOfPigsSuffix")}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
        <TextInputFS
          helperText={formErrorMessage(formik, "nurseryPig")}
          error={formError(formik, "nurseryPig")}
          type="tel"
          placeholder={t("addHouseDetail.nurseryPigPlaceholder") || ""}
          label={t("addHouseDetail.nurseryPigLabel")}
          {...formik.getFieldProps("nurseryPig")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="h6" className="text-tertiary font-medium">
                  | {t("addHouseDetail.numberOfPigsSuffix")}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
        <DatePickerFS
          maxDate={maxDate}
          value={formik?.values?.houseStartDate}
          slotProps={{
            textField: {
              helperText: formErrorMessage(formik, "houseStartDate"),
              error: formError(formik, "houseStartDate"),
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <EventIcon color="info" />
                  </InputAdornment>
                ),
              },
            },
          }}
          label={t("addHouseDetail.houseStartDateLabel")}
          onChange={(value) => handleDateChange(value)}
        />
        <div className="flex flex-col gap-1">
          <Typography variant="h6" className="text-primary-grey font-medium">
            {t("addHouseDetail.ageOfPigHeading")}
          </Typography>
          {/* <Typography component="subtitle2" className="text-tertiary text-sm"> */}
          {/* ############## can not build component="subtitle2"################# */}
          <Typography className="text-tertiary text-sm">
            {t("addHouseDetail.ageOfPigSubtitle")}
          </Typography>
        </div>
        <ButtonGroupControl
          options={AgeOfPig}
          name="ageOfPig"
          value={formik?.values?.ageOfPig}
          id="ageOfPig"
          onChange={handlePigAgeChange}
          className="flex justify-between"
        />
        <TextInputFS
          helperText={
            formik?.errors?.ageOfPig ? (
              formErrorMessage(formik, "ageOfPig")
            ) : (
              <span className="text-tertiary">
                {t("addHouse.calculateForFattenerNursery")}
              </span>
            )
          }
          error={formError(formik, "ageOfPig")}
          type="tel"
          placeholder={t("addHouseDetail.ageOfPigsPlaceholder") || ""}
          label={t("addHouseDetail.ageOfPigsLabel")}
          {...formik.getFieldProps("ageOfPig")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="h6" className="text-tertiary font-medium">
                  | {t("addHouseDetail.days")}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
        <DatePickerFS
          //   format={"DD/MM/YYYY"}
          maxDate={maxDate}
          value={formik?.values?.houseStartDate}
          slotProps={{
            textField: {
              helperText: formErrorMessage(formik, "houseStartDate"),
              error: formError(formik, "houseStartDate"),
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <EventIcon color="info" />
                  </InputAdornment>
                ),
              },
            },
          }}
          label={t("addHouseDetail.houseStartDateLabel")}
          onChange={(value) => handleDateChange(value)}
        />
        {/* น้ำหนักต่อตัว */}
        <TextInputFS
          helperText={
            formik?.errors?.totalWeightOfPigs ? (
              formErrorMessage(formik, "totalWeightOfPigs")
            ) : (
              <span className="text-tertiary">
                {t("addHouse.calculateForFattenerNursery")}
              </span>
            )
          }
          error={formError(formik, "totalWeightOfPigs")}
          type="number"
          placeholder={t("addHouseDetail.totalWeightOfPigsPlaceholder") || ""}
          label={t("addHouseDetail.totalWeightOfPigsLabel")}
          {...formik.getFieldProps("totalWeightOfPigs")}
          InputProps={{
            inputProps: {
              inputMode: "decimal",
            },
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="h6" className="text-tertiary font-medium">
                  | {t("addHouseDetail.kg")}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
        {/* ราคาต่อตัว */}
        <TextInputFS
          helperText={
            formik?.errors?.totalPriceOfPigs ? (
              formErrorMessage(formik, "totalPriceOfPigs")
            ) : (
              <span className="text-tertiary">
                {t("addHouse.calculateForFattener")}
              </span>
            )
          }
          error={formError(formik, "totalPriceOfPigs")}
          type="number"
          placeholder={t("addHouseDetail.totalPriceOfPigsPlaceholder") || ""}
          label={t("addHouseDetail.totalPriceOfPigsLabel")}
          {...formik.getFieldProps("totalPriceOfPigs")}
          InputProps={{
            inputProps: {
              inputMode: "decimal",
            },
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="h6" className="text-tertiary font-medium">
                  | {t("addHouseDetail.currencyThai")}
                </Typography>
              </InputAdornment>
            ),
          }}
        />
        <div className="flex justify-between px-2 py-2">
          <Typography className="text-primary-grey font-medium">
            {t("addHouseDetail.pricePerPig")}
          </Typography>
          <Typography className="text-tertiary font-medium">
            {getCommaNumber(+formik?.values?.pricePerPig)}
          </Typography>
          <Typography className="text-tertiary font-medium">
            | {t("addHouseDetail.currencyThai")}
          </Typography>
        </div>
      </div>
      <div className="flex flex-col gap-4 mb-4">
        <CustomButton
          disabled={!formik?.isValid || formik?.isSubmitting}
          variant="tertiary"
          label={t("addHouseDetail.NextButtonLabel")}
        />
      </div>
    </form>
  );
}

import React, { memo } from 'react'
import { Backdrop, CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'

const GlobalLoading = () => {
  const loadingState = useSelector((state: RootState) => state.loading)

  return (
    <Backdrop className="z-[999999]" open={loadingState.isVisible}>
      <div className="flex flex-col justify-center items-center">
        <CircularProgress color="secondary" />
        {loadingState.message && <small className="mt-4 font-sans text-[16px] text-[white]">{loadingState.message}</small>}
      </div>
    </Backdrop>
  )
}

export default memo(GlobalLoading)

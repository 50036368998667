import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import OrderFeedSVG from "../../assets/svg/OrderFeed";
import OrderHistorySVG from "../../assets/svg/OrderHistory";
import AppBarFS from "../../components/app-bar/AppBar";
import DashboardInfoCard from "../../components/common/DashboardInfoCard/DashboardInfoCard";

const OrderHistory = () => {
  const navigate = useNavigate();

  const onNavigate = useCallback(
    (path: string) => () => {
      navigate(path);
    },
    []
  );

  return (
    <div className="h-screen overflow-auto flex flex-col bg-[white]">
      <div className="py-4 rounded-b-[40px] bg-white">
        <AppBarFS
          onIconPress={onNavigate("/partner")}
          title={"ออเดอร์ของฉัน"}
        />
        <div className="mx-4 mt-4">
          <DashboardInfoCard
            title={"ออเดอร์ใหม่"}
            subTitle={"รายละเอียดออเดอร์ใหม่"}
            icon={<OrderFeedSVG />}
            removeBgIcon={true}
            bgColor="#F0FDF4"
            onPress={onNavigate("/order/new")}
          />
        </div>
        <div className="mx-4 mt-4">
          <DashboardInfoCard
            title={"ประวัติออเดอร์"}
            subTitle={"รายละเอียดประวัติออเดอร์"}
            icon={<OrderHistorySVG />}
            removeBgIcon={true}
            bgColor="#D9EBFEB2"
            onPress={onNavigate("/order/history")}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;

import { TFunction } from "i18next";

export enum SellingReportLevelStatusEnum {
  None = "none",
  Poor = "level_poor",
  Medium = "level_medium",
  Good = "level_good",
}

export interface ITableScrollBarProps {
  onClick: (val: string) => void;
  onSort: (key: keyof ITableData, orderBy: Order) => void;
  orderBy: Order;
  sortBy: keyof ITableData | "generationClosedAt";
  items: ITableData[];
}

export interface HeadCell {
  id: keyof ITableData;
  label: string;
  width: string;
  unit?: string;
  isSort: boolean;
}

export interface ITableData {
  id: string;
  name: string;
  fcr: string;
  adg: string;
  fcg: string;
  death: string;
  price: string;
  pigAmount: number;
}

export type Order = "asc" | "desc";

export interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ITableData
  ) => void;
  order: Order;
  orderBy: string;
}

export interface IFilterModalProps {
  labelSelectFilter?: string;
  labelDateRangeFilter?: string;
  isOpen: boolean;
  onClose: () => void;
  onReset: () => void;
  onSubmit: (startDate: string, endDate: string, orderBy: Order) => void;
  title: string;
  t: TFunction<"translation", undefined, "translation">;
}

export type FilterOrderYear = "new-old" | "old-new";

export interface IFilterOrderYear {
  id: FilterOrderYear;
  name: string;
  key: Order;
}

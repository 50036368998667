export default function HouseSvg() {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6393 4.87426L23.1332 9.27706L23.1353 9.2809H22.8478C22.4375 9.2809 22.0908 9.60805 22.0908 10.0328V19.9314C22.0908 20.4762 21.6409 20.9307 21.0631 20.9307H3.33712C2.75926 20.9307 2.30945 20.4762 2.30945 19.9314V9.96851C2.30945 9.57539 1.99601 9.22177 1.57184 9.21665L1.16314 9.20396L4.04495 4.78002L12.3452 0.851266L20.6393 4.87426ZM18.6994 17.0425V16.5577H18.6944V13.8419C18.6944 12.774 17.8132 11.9225 16.7475 11.9225H7.49637C6.43069 11.9225 5.54954 12.774 5.54954 13.8419V17.0425C5.54954 18.1103 6.43069 18.9618 7.49637 18.9618H16.7526C17.8183 18.9618 18.6994 18.1103 18.6994 17.0425ZM14.0965 6.64425C14.0965 5.56155 13.2053 4.70015 12.1245 4.70015C11.0437 4.70015 10.1524 5.56155 10.1524 6.64425C10.1524 7.72694 11.0437 8.58834 12.1245 8.58834C13.2053 8.58834 14.0965 7.72694 14.0965 6.64425Z"
        fill="#68C184"
        stroke="#68C184"
        stroke-width="0.969574"
      />
    </svg>
  );
}

import React, { memo, useCallback } from 'react'
import { tw } from '../../../../utils/tw'

type TValue = {
  label: string
  value: string
}

interface ICardListProps {
  title: string
  items: TValue[]
  selectedItems: TValue[]
  onSelect: (value: TValue) => void
  containerClassName?: string
  titleClassName?: string
  itemClassName?: string
  disabled?: boolean
  lastIndex?: number
}

const classes = {
  container: tw(`min-w-[170px] flex flex-col rounded-[10px] border-[#E2E2E2] border-[1px] border-t-0`),
  title: tw(`font-sans text-[#191919] text-[14px] font-bold p-[12px] bg-[#F6FBFF] w-full border-b-[1px] border-[#E2E2E2] rounded-t-[10px] border-t-[1px] whitespace-pre`),
  item: tw(`font-sans text-[#646464] text-[14px] font-medium px-[12px] py-[5px] hover:bg-[#f5f5f5] duration-150 transition-colors cursor-pointer whitespace-pre`),
}

const CardList = (props: ICardListProps) => {
  const {
    title,
    items,
    selectedItems,
    onSelect,
    containerClassName,
    titleClassName,
    itemClassName,
    disabled,
    lastIndex = 6,
  } = props

  const onSelectFn = useCallback((value: TValue) => () => {
    onSelect(value)
  }, [])

  return (
    <div className={tw(classes.container, containerClassName, disabled && 'pointer-events-none')}>
      <div className={tw(classes.title, titleClassName)}>
        {title}
      </div>
      {items.map(({ value, label }, index) => {
        const isSelect = selectedItems.findIndex(({ value: v }) => v === value) !== -1
        return (
          <div
            key={`${index}`}
            className={tw(
              classes.item,
              isSelect && 'bg-secondary text-[white] hover:bg-secondary hover:brightness-[1.05]',
              index === items.length - 1 && items.length >= lastIndex && 'rounded-b-[10px]', // 6 is area in thailand
              itemClassName,
            )}
            onClick={onSelectFn({ value, label })}
          >
            {label}
          </div>
        )
      })}
    </div>
  )
}

export default memo(CardList)

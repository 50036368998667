import { useCallback, useEffect, useState } from "react";
import { getCreditFeedData } from "../../../../services/CreditFeed.service";
import { useQuery } from "@tanstack/react-query";
import {
  defaultFormCreditStatus,
  TFormCreditStatus,
} from "./DefaultFormCreditStatus";
import { TFarmerGrade } from "../credit-feed/controller";
import dayjs from "dayjs";

type TContract = "pay_after_expire" | "fill_credit";

export const useCreditStatusController = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [currentOrderByKey, setCurrentOrderByKey] = useState<
    "farmer_code" | "credit_rating" | "updated_at"
  >("farmer_code");

  const [orderByType, setOrderByType] = useState<"desc" | "asc">("desc");

  const [form, setForm] = useState<TFormCreditStatus>(defaultFormCreditStatus);

  const onChangeForm = useCallback(
    (key: keyof TFormCreditStatus) => (value: any) => {
      setForm((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const onSearch = useCallback(() => {}, []);

  const onChangeTab = useCallback((e: any, index: number) => {
    setTabIndex(index);
  }, []);

  const onSearchText = useCallback((e: any) => {
    const text = e.target.value;
    setSearchText(text);
  }, []);

  const onClearSearchText = useCallback(() => {
    setSearchText("");
  }, []);

  const [countData, setCountData] = useState(0);

  const durationStart =
    form.duration[0]?.value === "1"
      ? 0
      : form.duration[0]?.value === "2"
      ? 31
      : form.duration[0]?.value === "3"
      ? 61
      : form.duration[0]?.value === "4"
      ? 90
      : 0;

  const durationEnd =
    form.duration[0]?.value === "1"
      ? 30
      : form.duration[0]?.value === "2"
      ? 60
      : form.duration[0]?.value === "3"
      ? 90
      : undefined;

  const { data: listCreditStatusPage, refetch: refetchListCreditStatusPage } =
    useQuery({
      queryKey: [
        "list_credit_status_page",
        searchText,
        form.grade,
        form.isActive,
        form.statusCredit,
        form.startDate,
        form.statusCredit,
        durationStart,
        durationEnd,
        currentPage,
        currentOrderByKey,
        orderByType,
      ],
      queryFn: async () => {
        const result = await getCreditFeedData({
          page: currentPage,
          limit: 10,
          isActive: form.isActive[0]
            ? form.isActive[0]?.value === "true"
            : undefined,
          startDate: form.startDate
            ? dayjs(form.startDate, "YYYY-MM-DD").startOf("day").toDate()
            : undefined,
          endDate: form.endDate
            ? dayjs(form.endDate, "YYYY-MM-DD").startOf("day").toDate()
            : undefined,
          farmerGrade: form.grade[0]?.value as TFarmerGrade,
          searchText: searchText,
          tabName: "credit_status",
          contractType: form.statusCredit[0]?.value as TContract,
          durationStart: durationStart ?? undefined,
          durationEnd: durationEnd ?? undefined,
          direction: orderByType,
          orderBy: currentOrderByKey,
        });
        return result;
      },
    });

  useEffect(() => {
    if (listCreditStatusPage && listCreditStatusPage.count > 0) {
      setCountData(listCreditStatusPage.count);
    } else if (searchText !== "") {
      setCountData(listCreditStatusPage?.count || 0);
    }
  }, [listCreditStatusPage]);

  useEffect(() => {
    setCountData(listCreditStatusPage?.count || 0);
  }, [form]);

  return {
    onSearch,
    tabIndex,
    onChangeTab,
    searchText,
    onSearchText,
    onClearSearchText,
    listCreditStatusPage,
    refetchListCreditStatusPage,
    form,
    setForm,
    onChangeForm,
    currentPage,
    setCurrentPage,
    countData,
    setCountData,
    currentOrderByKey,
    setCurrentOrderByKey,
    orderByType,
    setOrderByType,
  };
};

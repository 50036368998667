import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IGetHouseDetailsPerFlockResult } from "../../../../../../types/BasicAccounting.type";
import IconPigWithBgSvg from "../../../../../../assets/svg/IconPigWithBgSvg";
import { get } from "lodash";
import { getCommaNumber } from "../../../../../../utils/number";
import { useState } from "react";
import IconFeedPigWithBgSvg from "../../../../../../assets/svg/IconFeedPigWithBgSvg";
import IconVaccinesWithBgSvg from "../../../../../../assets/svg/IconVaccinesWithBgSvg";
import IconUserGroupWithBgSvg from "../../../../../../assets/svg/IconUserGroupWithBgSvg";
import IconWaterWithBgSvg from "../../../../../../assets/svg/IconWaterWithBgSvg";
import IconFlashWithBgSvg from "../../../../../../assets/svg/IconFlashWithBgSvg";
import IconCarWithBgSvg from "../../../../../../assets/svg/IconCarWithBgSvg";
import IconProgramWithBgSvg from "../../../../../../assets/svg/IconProgramWithBgSvg";
import IconDotWithBgSvg from "../../../../../../assets/svg/IconDotWithBgSvg";

interface ShowDetailsProps {
  icon: () => JSX.Element;
  name: string;
  value: number;
  expense: number;
}

const showDetailsPresent = (props: ShowDetailsProps) => {
  const { icon, name, value, expense } = props;

  return (
    <div className="bg-[#FBFBFB] p-3 flex flex-row justify-between">
      <div className="flex flex-row gap-1 items-center">
        {icon()}
        <div className="text-[#3777BC] text-[14px] font-normal">{name}</div>
      </div>
      <div className="flex flex-col gap-2 items-end">
        <div className="text-[#34407B] text-[16px] font-normal">
          {getCommaNumber(value, true)} บาท
        </div>
        <div className="text-[#979797]  text-[16px] font-normal">
          {getCommaNumber((value / expense) * 100, true)}%
        </div>
      </div>
    </div>
  );
};

const ReverseAccordion = (prop: { data?: IGetHouseDetailsPerFlockResult }) => {
  const { data } = prop;

  const [isExpanded, setIsExpanded] = useState(false);

  const handleChange = (expanded: boolean) => {
    setIsExpanded(expanded);
  };

  const expense =
    get(data, ["geneticBill"], 0) +
    get(data, ["feedBill"], 0) +
    get(data, ["vaccineAndMedicineBill"], 0) +
    get(data, ["staffBill"], 0) +
    get(data, ["waterBill"], 0) +
    get(data, ["electricityBill"], 0) +
    get(data, ["transportationBill"], 0) +
    get(data, ["programBill"], 0) +
    get(data, ["otherBill"], 0);

  return (
    <Accordion
      sx={{
        margin: "0px !important",
        boxShadow: "none",
        backgroundColor: "transparent",
        "::before": {
          backgroundColor: "transparent",
        },
      }}
      className="!flex !flex-col-reverse !font-sans"
      expanded={isExpanded}
      onChange={(e, expanded) => handleChange(expanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#68C184" }} />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          color: "#68C184",
          fontSize: "16px",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid #68C184",
          borderRadius: "50px",
          backgroundColor: "#FFFFFF",
          margin: "0 auto",
        }}
        className="!font-normal !h-[30px] !min-h-[30px] !py-[4.5px] !px-[13.5px] !gap-[4.5px]"
      >
        {isExpanded === false ? "ดูข้อมูลเพิ่มเติม" : "ย่อข้อมูลเพิ่มเติม"}
      </AccordionSummary>
      <AccordionDetails className="!p-0 !pb-[12px]">
        <div className="bg-[#FFFFFF] flex flex-col p-4 gap-3">
          {showDetailsPresent({
            icon: IconPigWithBgSvg,
            name: "ค่าพันธุ์สัตว์",
            value: get(data, ["geneticBill"], 0),
            expense: expense,
          })}
          {showDetailsPresent({
            icon: IconFeedPigWithBgSvg,
            name: "ค่าอาหาร",
            value: get(data, ["feedBill"], 0),
            expense: expense,
          })}
          {showDetailsPresent({
            icon: IconVaccinesWithBgSvg,
            name: "ค่ายาเเละวัคซีน",
            value: get(data, ["vaccineAndMedicineBill"], 0),
            expense: expense,
          })}
          {showDetailsPresent({
            icon: IconUserGroupWithBgSvg,
            name: "ค่าพนักงาน",
            value: get(data, ["staffBill"], 0),
            expense: expense,
          })}
          {showDetailsPresent({
            icon: IconWaterWithBgSvg,
            name: "ค่าน้ำ",
            value: get(data, ["waterBill"], 0),
            expense: expense,
          })}
          {showDetailsPresent({
            icon: IconFlashWithBgSvg,
            name: "ค่าไฟ",
            value: get(data, ["electricityBill"], 0),
            expense: expense,
          })}
          {showDetailsPresent({
            icon: IconCarWithBgSvg,
            name: "ค่าขนส่ง",
            value: get(data, ["transportationBill"], 0),
            expense: expense,
          })}
          {showDetailsPresent({
            icon: IconProgramWithBgSvg,
            name: "ค่าโปรแกรม",
            value: get(data, ["programBill"], 0),
            expense: expense,
          })}
          {showDetailsPresent({
            icon: IconDotWithBgSvg,
            name: "ค่าใช้จ่ายอื่นๆ",
            value: get(data, ["otherBill"], 0),
            expense: expense,
          })}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default ReverseAccordion;

import { FillProductData } from "../admin/pages/manage-stock/[id]/type";
import {
  IDownloadUrl,
  IGetListPartner,
  IGetPartnerStock,
  IGetStockRefill,
  IGetUploadUrl,
} from "../types/ManageStock.type";
import delay from "../utils/delay";
import adminAPI from "./AdminApiService";

// export const mockData: FillProductData[] = [
//   {
//     deliveryDate: "23/01/2024",
//     id: "000001",
//     orderId: "100001",
//     overallAmount: "10 ถุง",
//     status: "create",
//     fillProductItems: [
//       {
//         id: "1",
//         amount: "50",
//         feedBrandId: "1",
//         feedBrandItemId: "1",
//         nameTh: "Betagro",
//         skuTh: "SKU-0001",
//         unit: "bag",
//       },
//       {
//         id: "2",
//         amount: "50",
//         feedBrandId: "1",
//         feedBrandItemId: "1",
//         nameTh: "Betagro",
//         skuTh: "SKU-0001",
//         unit: "bag",
//       },
//       {
//         id: "3",
//         amount: "50",
//         feedBrandId: "1",
//         feedBrandItemId: "1",
//         nameTh: "Betagro",
//         skuTh: "SKU-0001",
//         unit: "bag",
//       },
//     ],
//   },
//   {
//     deliveryDate: "23/01/2024",
//     id: "000002",
//     orderId: "100002",
//     overallAmount: "10 ถุง",
//     status: "currently_shipping",
//     fillProductItems: [
//       {
//         id: "1",
//         amount: "50",
//         feedBrandId: "1",
//         feedBrandItemId: "1",
//         nameTh: "Betagro",
//         skuTh: "SKU-0001",
//         unit: "bag",
//       },
//       {
//         id: "2",
//         amount: "50",
//         feedBrandId: "1",
//         feedBrandItemId: "1",
//         nameTh: "Betagro",
//         skuTh: "SKU-0001",
//         unit: "bag",
//       },
//       {
//         id: "3",
//         amount: "50",
//         feedBrandId: "1",
//         feedBrandItemId: "1",
//         nameTh: "Betagro",
//         skuTh: "SKU-0001",
//         unit: "bag",
//       },
//     ],
//   },
//   {
//     deliveryDate: "23/01/2024",
//     id: "000003",
//     orderId: "100003",
//     overallAmount: "10 ถุง",
//     status: "success_shipping",
//     fillProductItems: [
//       {
//         id: "1",
//         amount: "50",
//         feedBrandId: "1",
//         feedBrandItemId: "1",
//         nameTh: "Betagro",
//         skuTh: "SKU-0001",
//         unit: "bag",
//       },
//       {
//         id: "2",
//         amount: "50",
//         feedBrandId: "1",
//         feedBrandItemId: "1",
//         nameTh: "Betagro",
//         skuTh: "SKU-0001",
//         unit: "bag",
//       },
//       {
//         id: "3",
//         amount: "50",
//         feedBrandId: "1",
//         feedBrandItemId: "1",
//         nameTh: "Betagro",
//         skuTh: "SKU-0001",
//         unit: "bag",
//       },
//     ],
//   },
//   {
//     deliveryDate: "23/01/2024",
//     id: "000004",
//     orderId: "100004",
//     overallAmount: "10 ถุง",
//     status: "success_receive",
//     fillProductItems: [
//       {
//         id: "1",
//         amount: "50",
//         feedBrandId: "1",
//         feedBrandItemId: "1",
//         nameTh: "Betagro",
//         skuTh: "SKU-0001",
//         unit: "bag",
//       },
//       {
//         id: "2",
//         amount: "50",
//         feedBrandId: "1",
//         feedBrandItemId: "1",
//         nameTh: "Betagro",
//         skuTh: "SKU-0001",
//         unit: "bag",
//       },
//       {
//         id: "3",
//         amount: "50",
//         feedBrandId: "1",
//         feedBrandItemId: "1",
//         nameTh: "Betagro",
//         skuTh: "SKU-0001",
//         unit: "bag",
//       },
//     ],
//   },
// ];

// export const getFillStockListById = async (partnerId: string) => {
//   await delay(500);
//   return mockData;
// };

export const getListPartner = async (params?: {
  page?: number;
  searchText?: string;
}) => {
  const { data } = await adminAPI.get<IGetListPartner>("/stock-management", {
    params: {
      page: params?.page,
      searchText: params?.searchText,
    },
  });
  return data;
};

export const getListPartnerById = async (partnerId: string) => {
  try {
    const { data } = await adminAPI.get<IGetPartnerStock>(
      `/stock-management/${partnerId}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getListStockRefill = async (partnerId: string) => {
  try {
    const { data } = await adminAPI.get<IGetStockRefill>(
      `/stock-management/${partnerId}/refill-stock`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const createStockRefill = async (body: {
  partnerId: string;
  refillNumber?: string | null;
  deliveryNumber?: string | null;
  listOfFeeds?: {
    feedBrandId: number;
    feedBrandItemId: number;
    feedAmount: number;
    feedUnit: string;
    feedPricePerUnit: number;
  }[];
  shippingDate: Date;
}) => {
  const { partnerId, ...props } = body;
  const { data } = await adminAPI.post(
    `/stock-management/${partnerId}/refill-stock`,
    props
  );
  return data;
};

export const getUploadUrlStockRefill = async (
  partnerId: string,
  stockRefillId: string,
  documentType: string
) => {
  const { data } = await adminAPI.get<IGetUploadUrl>(
    `stock-management/${partnerId}/${stockRefillId}/upload/${documentType}`
  );
  return data;
};

export const getDownloadUrlStockRefill = async (
  partnerId: string,
  stockRefillId: string,
  fileId: string,
  documentType: string
) => {
  const { data } = await adminAPI.get<IDownloadUrl>(
    `stock-management/${partnerId}/${stockRefillId}/download/${documentType}/${fileId}`
  );
  return data;
};

export const editStockRefillDocuments = async (body: {
  partnerId: string;
  stockRefillId: string;
  documents?: {
    id: string;
    fileName: string;
    fileSize: number;
    documentType: string;
  }[];
}) => {
  const { partnerId, stockRefillId, ...props } = body;
  const { data } = await adminAPI.put(
    `stock-management/${partnerId}/${stockRefillId}/document`,
    props
  );
  return data;
};

export const deliverStockRefill = async (
  action: "deliver" | "deliver-success",
  body: {
    partnerId: string;
    stockRefillId: string;
  }
) => {
  const { partnerId, stockRefillId, ...props } = body;
  const { data } = await adminAPI.put(
    `stock-management/${partnerId}/${stockRefillId}/${action}`,
    props
  );
  return data;
};

const CoinSvgWithBg = () => (
  <div
    className="w-[54px] h-[54px] rounded-[10px] gap-[10px] p-[10px]"
    style={{
      background:
        "linear-gradient(90deg, #3777BC 0%, #68C184 71.01%, #77F2A1 100%)",
      boxShadow: "4px 4px 20px 0px #0000001A",
    }}
  >
    <svg xmlns="http://www.w3.org/2000/svg" width={34} height={34} fill="none">
      <path
        fill="#fff"
        d="M14.335 12.609c.263-.176.588-.332.963-.454v2.887a3.912 3.912 0 0 1-.963-.454c-.619-.412-.737-.796-.737-.99 0-.193.118-.577.737-.99ZM18.698 21.842v-2.887c.375.122.7.278.964.454.619.412.736.796.736.99 0 .193-.117.576-.736.99a3.917 3.917 0 0 1-.963.453Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M16.998 30.598c7.511 0 13.6-6.088 13.6-13.6 0-7.51-6.088-13.6-13.6-13.6-7.51 0-13.6 6.09-13.6 13.6 0 7.511 6.09 13.6 13.6 13.6Zm1.7-22.1a1.7 1.7 0 1 0-3.4 0v.157c-1.056.198-2.034.581-2.849 1.125-1.227.818-2.25 2.134-2.25 3.818 0 1.685 1.023 3.001 2.25 3.82.815.543 1.793.926 2.85 1.124v3.3c-.665-.216-1.158-.54-1.434-.858a1.7 1.7 0 0 0-2.568 2.229c.956 1.102 2.403 1.828 4.001 2.128v.157a1.7 1.7 0 0 0 3.4 0v-.156c1.057-.198 2.035-.581 2.85-1.125 1.227-.818 2.25-2.134 2.25-3.819 0-1.684-1.023-3-2.25-3.818-.815-.544-1.793-.927-2.85-1.125v-3.3c.665.216 1.158.54 1.434.858a1.7 1.7 0 0 0 2.568-2.229c-.956-1.102-2.403-1.828-4.002-2.129v-.157Z"
        clipRule="evenodd"
      />
    </svg>
  </div>
);
export default CoinSvgWithBg;

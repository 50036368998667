import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Close from "@mui/icons-material/Close";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { Button, Modal } from "@mui/material";
import { FormikErrors, FormikProps } from "formik";
import { memo } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import TextAreaCustom from "../../admin/components/Input/TextAreaCustom";
import DeleteIcon from "../../assets/images/Farms/delete-icon.svg";
import AppBarFS from "../../components/app-bar/AppBar";
import CustomButton from "../../components/common/Button";
import TextInputCustom from "../../components/common/Input/TextInputCustom";
import { tw } from "../../utils/tw";
import { TClaim, TReportForm } from "./type";
import { formError, formErrorMessage } from "../../utils/form-validator";
import { thousandComma } from "../../utils/common/string";
type Props = {
  isOpen: boolean;
  formik: FormikProps<TReportForm>;
  onClose: () => void;
};

const classes = {
  container: tw(`flex flex-col min-h-screen w-full pb-[24px]`),
  appbarContainer: tw(`py-4`),
  title: tw(
    `text-[18px] leading-[18px] text-tertiary font-semibold mb-[24px] px-[16px]`
  ),
  uploadContainer: tw(`px-[32px] flex flex-col w-full`),
  helperText: tw(`text-[14px] leading-[18px] text-tertiary mt-[8px]`),
  actionButton: tw(
    `self-center font-bold max-w-[450px] bg-secondary transition-all hover:brightness-105 duration-150 rounded-full py-[13.5px] flex justify-center items-center cursor-pointer text-center text-[16px] leading-[20.8px] text-[white]`,
    "w-[calc(100%-32px)]"
  ),
  upload: {
    container: tw(
      `w-[97px] h-[97px] rounded-[17px] flex flex-col justify-center items-center border-[0.76px] border-[#97BFEA] cursor-pointer bg-[#F6FBFF] hover:brightness-105 duration-150 transition-all`
    ),
    listContainer: tw(`flex flex-row flex-wrap gap-3`),
    // image container
    image: tw(
      `w-[98px] h-[98px] rounded-[17px] hover:brightness-110 duration-150 transition-all relative`
    ),
    remove: tw(
      `absolute top-[5.3px] right-[5.3px] w-[19px] h-[19px] rounded-full z-10 bg-[rgba(255,255,255,0.9)] flex justify-center items-center cursor-pointer`
    ),
    img: tw(`w-full h-full object-cover rounded-[17px]`),
  },
};

const ProblemSKU = ({ isOpen, onClose, formik }: Props) => {
  interface IUploaderProps {
    maxFiles: number;
    onDrop: (images: File[]) => void;
  }
  const Uploader = memo((props: IUploaderProps) => {
    const { maxFiles, onDrop } = props;
    return (
      <Dropzone
        accept={{ "image/*": [] }}
        maxFiles={maxFiles}
        multiple={maxFiles > 1}
        maxSize={100 * 1024 * 1024} //* 100mb
        onDrop={onDrop}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="w-[97px] h-[97px] rounded-[17px] flex flex-col justify-center items-center border-[0.76px] border-[#97BFEA] cursor-pointer bg-[#F6FBFF] hover:brightness-105 duration-150 transition-all">
              <InsertPhotoIcon className="!text-[#97BFEA] !text-[26px] !mb-[7.6px]" />
              <div className="flex flex-row gap-x-[2px] items-center -ml-[2px] -mb-[2px]">
                <AddIcon className="!text-[#97BFEA] !text-[14px] -mt-[2px]" />
                <div className="text-[14px] leading-[18px] text-[#97BFEA]">
                  Photo
                </div>
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    );
  });
  const { t } = useTranslation();
  const handleDrop = (files: File[], idx: number, fileIndex: number) => {
    formik.setFieldValue(`claims[${idx}].file${fileIndex}`, files);
    console.log(formik.values);
  };
  const onRemoveImage = (idx: number, fileIndex: number) => {
    formik.setFieldValue(`claims[${idx}].file${fileIndex}`, []);
  };
  const handleDeleteClaim = (idx: number) => {
    const claims = formik.values.claims;
    const newClaimArr = [...claims.slice(0, idx), ...claims.slice(idx + 1)];
    formik.setFieldValue("claims", newClaimArr);
  };

  return (
    <Modal open={isOpen} onClose={onClose} style={{ overflow: "auto" }}>
      <div className="min-h-screen w-full flex flex-col gap-4 bg-[#E2E2E2]">
        <div className="flex flex-col bg-[white] py-4">
          <AppBarFS title="Problem SKU" onIconPress={onClose} />
          <p className="text-[20px] text-[#3777BC] font-bold pl-4">
            รายละเอียด
          </p>
          {formik.values.claims.map((row, idx) => (
            <>
              {idx > 0 && (
                <div key={idx} className="w-full h-[1rem] bg-[#E2E2E2]"></div>
              )}
              <div
                key={idx}
                className="flex flex-col gap-4 items-between w-full p-4"
              >
                <div>
                  {/* รายการที่ */}
                  <div className="flex justify-between font-medium text-[18px]">
                    <span className=" text-tertiary">
                      &#9679; รายการที่ {idx + 1}
                    </span>
                    {formik.values.claims.length > 1 && (
                      <span>
                        <div
                          className="flex cursor-pointer gap-2"
                          onClick={(e) => {
                            e.preventDefault();
                            handleDeleteClaim(idx);
                          }}
                        >
                          <span className="text-error">ลบ</span>
                          <img src={DeleteIcon} alt="delete-icon" />
                        </div>
                      </span>
                    )}
                  </div>
                  <div className="flex justify-between text-sm mt-2">
                    <p className="text-tertiary">แบรนด์อาหารสัตว์</p>
                    <p className="text-primary">{row.orderDetail.nameTh}</p>
                  </div>
                  <div className="flex justify-between text-sm mt-2">
                    <p className="text-tertiary">เบอร์อาหารสัตว์</p>
                    <p className="text-primary">{row.orderDetail.skuTh}</p>
                  </div>
                  <div className="flex justify-between text-sm mt-2">
                    <p className="text-tertiary">จำนวน</p>
                    <p className="text-primary">
                      {thousandComma(row.orderDetail.amount)}{" "}
                      {row.orderDetail.unit}
                    </p>
                  </div>
                  <p className="text-end text-sm my-3">
                    {thousandComma(row.orderDetail.totalPrice)} บาท
                  </p>
                  <div className="flex justify-between text-sm">
                    <p className="text-tertiary">เหตุผล</p>
                    <p>{row.reportReason}</p>
                  </div>
                </div>
                <div>
                  <TextInputCustom
                    focused={true}
                    {...formik.getFieldProps(`claims[${idx}].reportAmount`)}
                    type="number"
                    label={"จำนวนที่รายงานปัญหา"}
                    placeholder="จำนวนที่รายงานปัญหา"
                    error={Boolean(
                      (
                        formik.errors.claims as
                          | FormikErrors<TClaim>[]
                          | undefined
                      )?.[idx]?.reportAmount &&
                        (formik.touched.claims as any)?.[idx]?.reportAmount
                    )}
                    helperText={
                      (formik.touched.claims as any)?.[idx]?.reportAmount &&
                      (
                        formik.errors.claims as
                          | FormikErrors<TClaim>[]
                          | undefined
                      )?.[idx]?.reportAmount
                        ? (
                            formik.errors.claims as
                              | FormikErrors<TClaim>[]
                              | undefined
                          )?.[idx]?.reportAmount
                        : ""
                    }
                  />
                </div>
                <div className="flex flex-col w-full text-[12px] text-tertiary gap-2">
                  <p className="flex justify-between px-4">
                    <span>รายละเอียดเหตุผล</span>
                    <span className="text-[#979797]">
                      {row.detail.length}/500
                    </span>
                  </p>
                  <TextAreaCustom
                    placeholder="รายละเอียดเหตุผล"
                    style={{
                      borderColor: "#3777BC",
                      backgroundColor: "#F7FBFF",
                      color: "#191919",
                      fontWeight: 500,
                      borderRadius: "20px",
                      fontSize: "16px",
                      padding: "16px",
                    }}
                    {...formik.getFieldProps(`claims[${idx}].detail`)}
                  />
                </div>
                <div className="flex gap-4">
                  {formik.values.claims[idx].file1.map((image, index) => {
                    const url = URL.createObjectURL(image);
                    return (
                      <div key={`${index}`} className={classes.upload.image}>
                        <div
                          onClick={() => onRemoveImage(idx, 1)}
                          className={classes.upload.remove}
                        >
                          <Close className="!text-[#979797] !text-[15px] -mr-[0.6px]" />
                        </div>
                        <img
                          alt={"upload-img"}
                          src={url}
                          className={classes.upload.img}
                        />
                      </div>
                    );
                  })}
                  {formik.values.claims[idx].file1.length === 0 && (
                    <Uploader
                      onDrop={(files) => handleDrop(files, idx, 1)}
                      maxFiles={1}
                    />
                  )}
                  {formik.values.claims[idx].file2.map((image, index) => {
                    const url = URL.createObjectURL(image);
                    return (
                      <div key={`${index}`} className={classes.upload.image}>
                        <div
                          onClick={() => onRemoveImage(idx, 2)}
                          className={classes.upload.remove}
                        >
                          <Close className="!text-[#979797] !text-[15px] -mr-[0.6px]" />
                        </div>
                        <img
                          alt={"upload-img"}
                          src={url}
                          className={classes.upload.img}
                        />
                      </div>
                    );
                  })}
                  {formik.values.claims[idx].file2.length === 0 && (
                    <Uploader
                      onDrop={(files) => handleDrop(files, idx, 2)}
                      maxFiles={1}
                    />
                  )}
                </div>
                <div className={"text-[14px] leading-[18px] text-tertiary"}>
                  Maximum 2 Photos, Maximum 100 MB
                </div>
                {idx === formik.values.claims.length - 1 && (
                  <div className="flex justify-center items-center">
                    <Button
                      variant="outlined"
                      startIcon={<AddCircleIcon />}
                      sx={{
                        color: "#68C184",
                        borderColor: "#68C184",
                        fontWeight: "600",
                        width: "80%",
                      }}
                      onClick={onClose}
                    >
                      {t("orderFeed.button.add")}
                    </Button>
                  </div>
                )}
              </div>
            </>
          ))}
        </div>
        <div className="flex flex-col bg-[white] p-4 gap-2 text-[15px]">
          <p className="flex justify-between">
            <span>โทรศัพท์</span>
            <span>02-833-8000</span>
          </p>
          <p className="flex justify-between">
            <span>อีเมล์</span>
            <span>farmsook@betagro.com</span>
          </p>
        </div>
        <div className="bg-[white] p-4">
          <CustomButton
            variant="tertiary"
            className="w-full"
            label={"ถัดไป"}
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ProblemSKU;

import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  IconButton,
  Modal,
  TableCell,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import { tw } from "../../../../utils/tw";
import AppBarFS from "../../../../components/app-bar/AppBar";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterModal from "./FilterModal";
import { defaultFormHistory, TFormHistory } from "../defaultForm.type";
import {
  RowHistoryFeedStock,
  RowListFeedStock,
} from "../../../../types/StockMangement.type";
import { get } from "lodash";
import TableCustomMobile from "../../../financial-support/financial-billPayment/unpaid-bill/views/TableCustomMobile";
import dayjs from "dayjs";
import { FILTER_DATE_FORMAT } from "../../../../admin/pages/sale-order/components/SaleOrderSearchFilter";
import DotSuccessSvg from "../../../../assets/svg/DotSuccessSvg";
import DotWarningSvg from "../../../../assets/svg/DotWarningSvg";
import { getCommaNumber } from "../../../../utils/number";

interface IHistoryFeedStockModal {
  isOpen: boolean;
  onClose: () => void;
  form: TFormHistory;
  setForm: (form: TFormHistory) => void;
  onChangeForm: (key: keyof TFormHistory) => (value: any) => void;
  data: RowHistoryFeedStock[];
  item: RowListFeedStock[];
  setItemId: Dispatch<SetStateAction<string>>;
}

const headerItems = [
  {
    id: "addDate",
    numeric: false,
    disablePadding: false,
    label: "วันที่รับเข้า",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "จำนวนสินค้า",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
  {
    id: "daysInStock",
    numeric: false,
    disablePadding: false,
    label: "อายุสินค้าในคลัง",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
];

const classes = {
  wrapper: tw(`!z-0 w-full h-full overflow-scroll !bg-[white]`),
  container: tw(`w-full h-full relative table bg-[white] `),
  headerContainer: tw(`min-h-[120px] flex items-center rounded-b-[40px]`),
  detailsContainer: tw(`flex flex-col px-4 gap-3`),
  button: tw(`!h-[59px] w-full !rounded-[50px] !text-[16px] !font-bold`),
  container2: tw(`min-h-[240px]`),
  emptyTable: tw(
    `font-sans text-center w-full text-[#979797] text-[14px] py-3`
  ),
};

interface ITable {
  swapCellColor?: boolean;
  data: RowHistoryFeedStock[];
}

const TableHistoryFeedStock = (props: ITable) => {
  const { swapCellColor, data } = props;
  const [currentPage, setCurrentPage] = useState(1);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#D9EBFE",
      color: "#4B4B4B",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: 400,
      textAlign: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: `${swapCellColor ? "#ffffff" : "#f6faff"}`,
    },
    "&:nth-of-type(even)": {
      backgroundColor: `${swapCellColor ? "#f6faff" : "#ffffff"}`,
    },
  }));

  const rowChildren = useMemo(() => {
    return data.map((row, index) => {
      const getColor = row.amount < 0 ? "#D32F2F" : "#4B4B4B";
      const getType =
        row.addType === "system" ? (
          <DotSuccessSvg />
        ) : (
          row.addType === "manual" && <DotWarningSvg />
        );

      return (
        <StyledTableRow key={row.id + index}>
          <StyledTableCell
            sx={{
              border: "0px !important",
              whiteSpace: "pre",
              color: getColor,
            }}
            className="!flex !flex-row !gap-[6px]"
          >
            {getType} {dayjs(row.addDate).format(FILTER_DATE_FORMAT)}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
              whiteSpace: "pre",
              color: getColor,
            }}
          >
            {getCommaNumber(row.amount, true)}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
              whiteSpace: "pre",
              color: getColor,
            }}
          >
            {row.amount > 0 && getCommaNumber(row.daysInStock) + " วัน"}
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  }, [data, currentPage]);

  return (
    <div className={classes.container2}>
      <TableCustomMobile
        orderBy={""}
        order={"desc"}
        onChangeOrder={() => {}}
        onChangeOrderBy={() => {}}
        totalItemSize={data.length}
        headerItems={headerItems}
        page={currentPage}
        onChangePage={setCurrentPage}
        rowsPerPage={data.length}
        rows={data}
        EmptyComponent={
          data?.length === 0 ? (
            <div className={classes.emptyTable}>ไม่มีข้อมูล</div>
          ) : null
        }
        rowChildren={rowChildren}
        currentPageItems={data.map(({ id }) => id)}
        itemsSelected={[]}
        onSelectAllClick={() => {}}
        onSelectItem={() => {}}
        hideSelect
        hidePaginator
      />
    </div>
  );
};

const HistoryFeedStockModal = (props: IHistoryFeedStockModal) => {
  const {
    isOpen,
    onClose,
    form,
    setForm,
    onChangeForm,
    data,
    item,
    setItemId,
  } = props;

  const onCloseFn = useCallback(() => {
    onClose();
    setItemId("");
    setForm(defaultFormHistory);
  }, []);

  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);

  const onOpenFilterModal = useCallback(() => setIsOpenFilterModal(true), []);
  const onHideFilterModal = useCallback(() => setIsOpenFilterModal(false), []);

  return (
    <Modal open={isOpen} className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <AppBarFS title={"ประวัติสต๊อก"} onIconPress={onCloseFn} />
        </div>
        <div className={classes.detailsContainer}>
          <div className="flex flex-row justify-between items-center">
            <div className="text-[#34407B] text-[20px] font-semibold">
              SKU {get(item, [0, "feedBrandItem", "skuTh"], "")}
            </div>
            <div className="h-full flex gap-2 items-center">
              <span className="text-secondary font-normal ml-1 text-[1rem] flex flex-col items-center">
                กรองโดย
              </span>
              <IconButton
                aria-label="filter"
                sx={{
                  color: "#68C184",
                  border: "1px solid #68C184",
                  ":hover": {
                    color: "#FFFFFF",
                    border: "1px solid #68C184",
                    bgcolor: "#68C184",
                  },
                  ":active": {
                    color: "#FFFFFF",
                    border: "1px solid #68C184",
                    bgcolor: "#68C184",
                  },
                }}
                onClick={onOpenFilterModal}
              >
                <FilterListIcon />
              </IconButton>
            </div>
          </div>
          <div className="flex flex-row justify-center gap-3">
            <div className="flex flex-row gap-2 text-[14px] text-[#000000] font-normal">
              <DotSuccessSvg />
              System
            </div>
            <div className="flex flex-row gap-2 text-[14px] text-[#000000] font-normal">
              <DotWarningSvg />
              Manually
            </div>
          </div>
          <TableHistoryFeedStock swapCellColor={true} data={data} />
        </div>
        <FilterModal
          title="กรองโดย"
          labelDateRangeFilter="ช่วงวันที่รับเข้า"
          isOpen={isOpenFilterModal}
          onClose={onHideFilterModal}
          form={form}
          setForm={setForm}
          onChangeForm={onChangeForm}
        />
      </div>
    </Modal>
  );
};

export default memo(HistoryFeedStockModal);

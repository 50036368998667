import { Box, Modal } from "@mui/material";
import Fade from "@mui/material/Fade";
import { ReactNode } from "react";
import Backdrop from "@mui/material/Backdrop";
import CrossIcon from "../../../assets/images/feed/close.svg";

interface IBaseModal {
  isOpen: boolean;
  handleClose: (isSuccess: boolean, values?: any) => void;
  children: ReactNode;
  minHeight?: string | number;
  iconCloseModal?: boolean;
  containerClassName?: string;
  withoutOutSideClose?: boolean
}

export function BaseModal({
  isOpen,
  handleClose,
  children,
  minHeight,
  iconCloseModal,
  containerClassName = '',
  withoutOutSideClose = false,
}: IBaseModal) {
  return (
    <Modal
      open={isOpen}
      onClose={!withoutOutSideClose ? () => handleClose(false) : undefined}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          style: { backgroundColor: "#000000", opacity: "10%" },
          timeout: 500,
        },
      }}
      className={containerClassName}
    >
      <Fade in={isOpen}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            backgroundColor: "white",
            boxShadow: 5,
            borderRadius: "40px",
            p: 4,
            minHeight,
          }}
        >
          {iconCloseModal ? (
            <div className="flex justify-end">
              <img
                className="cursor-pointer"
                onClick={() => handleClose(false)}
                src={CrossIcon}
                alt="close-button"
              />
            </div>
          ) : null}

          {children}
        </Box>
      </Fade>
    </Modal>
  );
}

import { useState } from "react";
import { Order } from "../../../components/Table/TableCustom";
import { TDropdownFarmSize } from "../components/FarmDetailFilter";

export const useFarmDetailFilterHook = () => {
  const [groupArea, setGroupArea] = useState("");
  const [saleName, setSaleName] = useState("");
  const [segment, setSegment] = useState("");
  const [farmSize, setFarmSize] = useState<TDropdownFarmSize>("");
  const [searchText, setSearchText] = useState("");
  const [direction, setDirection] = useState<Order>("desc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  return {
    groupArea,
    saleName,
    segment,
    searchText,
    direction,
    page,
    limit,
    farmSize,
    setGroupArea,
    setSaleName,
    setSegment,
    setSearchText,
    setDirection,
    setPage,
    setLimit,
    setFarmSize,
  };
};

export type TUseFarmDetailFilter = ReturnType<typeof useFarmDetailFilterHook>;

const PigFeedSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <g fill="#68C184" clipPath="url(#a)">
      <path d="M4.992.14c-.112.108-.117.137-.117.75v.633h14.25V.9c0-.563-.01-.633-.094-.727-.187-.196-.3-.187-.876.104l-.53.262-.516-.258c-.28-.14-.557-.258-.609-.258-.052 0-.328.118-.61.258l-.515.258-.516-.258c-.28-.14-.557-.258-.609-.258-.052 0-.328.118-.61.258l-.515.258-.516-.258c-.28-.14-.557-.258-.609-.258-.052 0-.328.118-.61.258l-.515.258-.516-.258c-.28-.14-.557-.258-.609-.258-.052 0-.328.118-.61.258L8.626.54 8.109.281C7.83.141 7.552.023 7.5.023c-.052 0-.328.118-.61.258L6.376.54 5.859.281c-.28-.14-.567-.258-.632-.258a.386.386 0 0 0-.235.118ZM18.262 3.076c-1.03 1.238-1.359 1.702-1.687 2.358-.46.919-.422.37-.436 6.282-.014 3.496 0 5.306.033 5.526.145.956.572 1.716 1.88 3.366.403.501.797 1.012.886 1.13.084.121.168.22.182.215.014 0 .117-.122.22-.267.108-.145.507-.628.891-1.064.38-.44.806-.952.947-1.144.333-.45.68-1.106.816-1.552l.108-.351.014-5.353c.014-5.925.023-5.667-.291-6.399-.192-.445-.478-.933-.886-1.5-.328-.454-1.776-2.24-1.819-2.24-.014 0-.403.45-.858.993Z" />
      <path d="M4.19 2.99C3.188 4.248 2.69 5.082 2.406 6l-.132.422V17.39l.132.422a7.84 7.84 0 0 0 .745 1.617c.103.168.516.764.919 1.326l.731 1.017h6.6c5.273 0 6.6-.014 6.572-.06-.024-.029-.324-.418-.675-.854-.956-1.195-1.402-1.922-1.669-2.709-.253-.745-.253-.708-.253-6.262.005-5.611-.005-5.476.281-6.244.3-.835.914-1.74 2.133-3.174l.173-.197h-6.604l-6.6.005-.567.713Zm.362 3.99c.426.15.778.393 1.167.81l.328.352.384-.164a5.57 5.57 0 0 1 4.378-.005l.39.17.435-.441c.352-.352.511-.479.811-.629.361-.178.39-.182.853-.182.436 0 .483.009.596.112.225.211.328.577.328 1.153.005.694-.14 1.102-.684 1.96-.16.243-.226.389-.202.445.019.042.056.22.094.394l.06.318h-.618c-.642.005-.713.024-.82.225-.075.141-.033.305.098.432.094.084.164.093.745.093h.643l.215.24c.3.332.45.66.483 1.064a2.259 2.259 0 0 1-1.697 2.376c-.239.061-.792.07-3.914.066-3.277 0-3.66-.01-3.942-.08-1.28-.337-2.035-1.814-1.5-2.939.075-.155.23-.38.351-.506l.216-.22h.633c.614 0 .642-.005.75-.118a.344.344 0 0 0 0-.515c-.108-.113-.136-.118-.727-.118-.698 0-.656.038-.534-.492l.08-.328-.258-.398c-.516-.797-.67-1.228-.666-1.899 0-.619.169-1.06.464-1.233.164-.093.717-.065 1.06.057Z" />
      <path d="M3.825 7.688c-.164.435.014 1.246.403 1.823l.117.169.146-.202c.182-.244.44-.52.707-.745l.202-.174-.173-.215a2.856 2.856 0 0 0-.408-.38c-.394-.29-.928-.44-.994-.277ZM12.923 7.688a1.97 1.97 0 0 0-.328.168c-.21.146-.689.614-.689.675 0 .028.131.169.296.314.159.14.384.39.501.549l.211.286.08-.103c.145-.188.398-.755.46-1.018.055-.243.041-.787-.02-.89-.046-.07-.29-.061-.51.019ZM7.44 10.57c-.92.23-1.571 1.21-1.402 2.11a1.897 1.897 0 0 0 1.078 1.42l.314.15h1.172c1.317 0 1.457-.028 1.884-.352a1.864 1.864 0 0 0 .005-3c-.427-.323-.544-.346-1.772-.356-.605-.01-1.181.005-1.28.028Zm.693 1.57c.328.324-.145.854-.483.54-.131-.127-.173-.29-.098-.432.13-.248.393-.3.58-.107Zm1.5 0c.328.324-.145.854-.483.54-.131-.127-.173-.29-.098-.432.13-.248.393-.3.58-.107ZM4.875 23.147c0 .562.01.633.094.726.187.197.3.188.876-.103l.53-.262.516.258c.28.14.557.257.609.257.052 0 .328-.117.61-.257l.515-.258.516.258c.28.14.557.257.609.257.052 0 .328-.117.61-.257l.515-.258.516.258c.28.14.557.257.609.257.052 0 .328-.117.61-.257l.515-.258.516.258c.28.14.557.257.609.257.052 0 .328-.117.61-.257l.515-.258.516.258c.28.14.557.257.609.257.052 0 .328-.117.61-.257l.515-.258.53.262c.576.29.689.3.876.103.085-.093.094-.164.094-.726v-.624H4.875v.624Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .023h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default PigFeedSvg;

import { useState } from "react";
import { styled } from "@mui/material";
import { Document, Page } from "react-pdf";

type PDFRendererProps = {
  url: string;
};

const PDFRenderer = ({ url }: PDFRendererProps) => {
  const [numPages, setNumPages] = useState();

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  return (
    <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
      {Array.apply(null, Array(numPages))
        .map((x, i) => i + 1)
        .map((page) => {
          return (
            <PDFPageWrapper
              key={`key ${page}`}
              pageNumber={page}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              renderForms={true}
              className={"w-full"}
            />
          );
        })}
    </Document>
  );
};

export default PDFRenderer;

const PDFPageWrapper = styled(Page)(() => ({
  ".react-pdf__Page__canvas": {
    width: "100% !important",
    height: "auto !important",
  },
}));

import React, { memo, useCallback, useState } from 'react'
import { isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import { tw } from '../../../../utils/tw'
import CrossIcon from '../../../assets/svg/CrossIcon.svg'
import ButtonFillCustom from '../../../components/Button/ButtonFillCustom'
import { Input } from '../../group-area/views/Input'
import { AppDispatch } from '../../../../redux/store'
import { alertAction } from '../../../../redux/slices/alertSlice'
import { loadingActions } from '../../../../redux/slices/loadingSlice'
import { snakeActions } from '../../../../redux/slices/snakeSlice'
import getErrorMessage from '../../../../utils/getErrorMessage'
import { addRole } from '../../../../services/Role.service'

interface IAddModalProps {
  isOpen: boolean
  onClose: () => void
  refetch: () => void
  currentTab: { value: string, label: string }
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
}

const classes = {
  container: tw(`max-w-[352px] w-[100vw] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary [&_.MuiDialog-paperScrollPaper]:!m-0`),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  submitContainer: tw(`flex w-full justify-end items-end`),
}

const AddModal = (props: IAddModalProps) => {
  const {
    isOpen,
    onClose,
    refetch,
    currentTab,
  } = props

  const dispatch = useDispatch<AppDispatch>()
  const [name, setName] = useState('')

  const onCloseFn = useCallback(() => {
    onClose()
  }, [])

  const onCreate = useCallback(() => {
    dispatch(alertAction.showDialog({
      title: 'ยืนยันการเพิ่มตำแหน่ง',
      text: 'คุณต้องการเพิ่มตำแหน่งนี้ใช่หรือไม่?',
      cancelText: 'กลับไปแก้ไข',
      confirmText: 'ยืนยัน',
      onCancel: () => {
        dispatch(alertAction.hide())
      },
      onSubmit: () => {
        dispatch(loadingActions.show({ message: 'กำลังเพิ่มตำแหน่ง...' }))
        const type = currentTab.value === 'employee' ? 'farmTech' : 'partner'
        addRole({
          usedBy: type,
          name,
        }).then(() => {
          refetch()
          dispatch(alertAction.hide())
          setTimeout(() => {
            onCloseFn()
            dispatch(loadingActions.hide())
            dispatch(snakeActions.showMessage({
              message: 'เพิ่มตำแหน่งสำเร็จ',
              type: 'success',
            }))
          }, 1000)
        }).catch((e) => {
          console.log('fail to add new role error:', e)
          dispatch(alertAction.hide())
          setTimeout(() => {
            dispatch(loadingActions.hide())
            dispatch(snakeActions.showMessage({
              message: getErrorMessage(e),
              type: 'error',
            }))
          }, 1000)
        })
      },
    }))
  }, [name])

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: 'w-full' }}
    >
      <DialogContent>
        <div className={classes.titleContainer}>
          <p className={classes.title}>Add Role</p>
          <IconButton onClick={onCloseFn}>
            <CrossIcon />
          </IconButton>
        </div>
        <Input
          label="Role Name"
          required
          value={name}
          onChangeText={setName}
        />
        <div className={classes.submitContainer}>
          <ButtonFillCustom
            title="ยืนยัน"
            disabled={isEmpty(name)}
            onClick={onCreate}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default memo(AddModal)

import { MenuItem } from "@mui/material";
import { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import CustomButton from "../../../components/common/Button";
import TextInputFS from "../../../components/common/Input/TextInput";
import { getFeed } from "../../../services/FarmService";
import { optional } from "../../../utils/optional";
import FeedProgram from "./FeedProgram";
import FeedingContext from "./Provider/FeedingContext";

interface FeedingProgramType {
  basicDetailForm?: any;
  handleFeedingProgramSubmit: Function;
  handleNext?: Function;
  edited?: boolean;
}

const FeedingProgram = (props: FeedingProgramType) => {
  const { t, i18n } = useTranslation();
  const [isEnglish, setEnglish] = useState<boolean>(true);
  const {
    feedBrands,
    selectedBrand,
    setSelectedBrand,
    setFeedBrands,
    feedData,
    setFeedData,
    finalAddHouseData,
    setAddHouseData,
  } = useContext(FeedingContext);
  const [homeMix, setHomeMix] = useState<any>();
  const [selectBrand, setSelectBrand] = useState<any>("");
  const [blurred, setBlurred] = useState(false);
  const [showFeedProgram, setShowFeedProgram] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const location = useLocation();

  useEffect(() => {
    fetchFeedData();
  }, [setFeedBrands, props.basicDetailForm]);

  useEffect(() => {
    setSelectBrand(selectedBrand.id);
    if (location.state?.edited && !props?.edited) {
      setShowFeedProgram(true);
    }
  }, [selectedBrand]);

  useEffect(() => {
    if (i18n.language === "en") setEnglish(true);
    else setEnglish(false);
  }, [i18n]);

  const fetchFeedData = async () => {
    const resp: AxiosResponse = await getFeed(calculateAvgWeight());
    changeFeedData(resp?.data?.feedDetails);
  };

  const calculateAvgWeight = () => {
    let fattenerPig = props?.basicDetailForm?.fattenerPig,
      nurseryPig = props?.basicDetailForm?.nurseryPig,
      totalWeightOfPigs = props?.basicDetailForm?.totalWeightOfPigs;
    const value =
      optional(parseInt(totalWeightOfPigs), 0) /
      (optional(parseInt(nurseryPig), 0) + optional(parseInt(fattenerPig), 0));
    return parseFloat(value.toFixed(2));
  };

  const changeFeedData = (feedData: any) => {
    feedData?.forEach((fdata: any) => {
      fdata?.feed_brand_items?.forEach((data: any) => {
        data.brand_id = fdata.id;
      });
    });
    if (setFeedBrands) setFeedBrands(feedData);
  };

  const handleBlur = () => {
    setBlurred(true);
  };

  async function handelFeedBrandChange(event: any) {
    const { value } = event.target;
    setSelectBrand(value);
    setDisableNext(false);
    const selectedData = feedBrands?.find((ele: any) => ele.id === value);
    let newData: any = [];
    newData = selectedData?.feed_brand_items.map((ele: any, index: any) => {
      if (ele.consumption === 0) setDisableNext(true);
      if (
        `${selectedData.feed_brand_items[index - 1]?.startWeight} - ${
          selectedData.feed_brand_items[index - 1]?.endWeight
        }` !== `${ele.startWeight} - ${ele.endWeight}` &&
        `${selectedData.feed_brand_items[index + 1]?.startWeight} - ${
          selectedData.feed_brand_items[index + 1]?.endWeight
        }` !== `${ele.startWeight} - ${ele.endWeight}`
      ) {
        return { key: `${ele.startWeight} - ${ele.endWeight}`, ...ele };
      } else if (
        `${selectedData.feed_brand_items[index - 1]?.startWeight} - ${
          selectedData.feed_brand_items[index - 1]?.endWeight
        }` !== `${ele.startWeight} - ${ele.endWeight}`
      ) {
        setDisableNext(true);
        return { key: `${ele.startWeight} - ${ele.endWeight}` };
      }
    });
    const updatedData = {
      id: selectedData.id,
      nameEn: selectedData.nameEn,
      nameTh: selectedData.nameTh,
      feed_brand_items: newData.filter((element: any) => element !== undefined),
    };
    setFeedData && setFeedData(updatedData);
    if (setSelectedBrand) setSelectedBrand(selectedData);
    if (finalAddHouseData && finalAddHouseData.feedDetails) {
      const { basicDetails } = finalAddHouseData;
      setAddHouseData && setAddHouseData(basicDetails);
    }
  }

  const handleSubmit = () => {
    if (showFeedProgram) props.handleFeedingProgramSubmit(selectedBrand);
    if (selectBrand) {
      setShowFeedProgram(true);
      disableNextFunc();
    }
  };

  function disableNextFunc() {
    const selectedData = feedBrands?.find((ele: any) => ele.id === selectBrand);
    selectedData?.feed_brand_items.map((ele: any, index: any) => {
      if (ele.consumption === 0) setDisableNext(true);
      if (
        `${selectedData.feed_brand_items[index - 1]?.startWeight} - ${
          selectedData.feed_brand_items[index - 1]?.endWeight
        }` !== `${ele.startWeight} - ${ele.endWeight}` &&
        `${selectedData.feed_brand_items[index + 1]?.startWeight} - ${
          selectedData.feed_brand_items[index + 1]?.endWeight
        }` !== `${ele.startWeight} - ${ele.endWeight}`
      ) {
        return { key: `${ele.startWeight} - ${ele.endWeight}`, ...ele };
      } else if (
        `${selectedData.feed_brand_items[index - 1]?.startWeight} - ${
          selectedData.feed_brand_items[index - 1]?.endWeight
        }` !== `${ele.startWeight} - ${ele.endWeight}`
      ) {
        setDisableNext(true);
        return { key: `${ele.startWeight} - ${ele.endWeight}` };
      }
    });
  }
  return (
    <div className="flex flex-col grow gap-4 justify-between pt-3 relative px-1 w-full h-full">
      <div className="flex flex-col gap-4 h-full">
        <TextInputFS
          select
          fullWidth
          // helperText={t('feedingProgram.selectBrand')}
          error={blurred && !selectBrand}
          placeholder={t("feedingProgram.currentFeedBrandPlaceholder") || ""}
          label={t("feedingProgram.currentFeedBrandLabel")}
          value={selectBrand ? selectBrand : ""}
          onChange={handelFeedBrandChange}
          onBlur={handleBlur}
        >
          {feedBrands?.map((option: any, index: any) => (
            <MenuItem key={index} value={option?.id}>
              {isEnglish ? option?.nameEn : option?.nameTh}
            </MenuItem>
          ))}
        </TextInputFS>

        {/* {!selectedBrand?.id && */}
        {!showFeedProgram && (
          <TextInputFS
            placeholder={t("feedingProgram.homeMixPlaceholder") || ""}
            label={t("feedingProgram.homeMixLabel")}
            value={homeMix}
            onChange={(e) => {
              setHomeMix(e.target.value);
            }}
          />
        )}

        {/* {selectedBrand?.id && <FeedProgram */}
        {showFeedProgram && (
          <FeedProgram
            feedData={feedData}
            selectedFeedBrand={selectedBrand}
            feedBrands={feedBrands}
            showPin={location.state?.edited && !props?.edited}
            addHouse={true}
          />
        )}
      </div>
      <div
        className={`${
          selectedBrand?.feed_brand_items?.id
            ? "pb-4"
            : "relative mt-4 bottom-4"
        } w-full`}
      >
        <CustomButton
          variant="tertiary"
          label={t("feedingProgram.nextButtonLabel")}
          onClick={handleSubmit}
          disabled={!selectBrand || (showFeedProgram && disableNext)}
        />
      </div>
    </div>
  );
};

export default FeedingProgram;

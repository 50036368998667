import {
  IEmployeeForm,
  IGetEmployee,
  IGetEmployeeForm,
  IUpdateStatusEmployeeForm,
} from "../../../types/admin/user-management/employee";
import adminAPI from "../../AdminApiService";

export const getEmployee = async (searchParams?: URLSearchParams) => {
  try {
    const { data } = await adminAPI.get<IGetEmployee>(
      `/user-management/employee?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const createEmployee = async (payload: IEmployeeForm) => {
  return adminAPI.post("/user-management/employee", payload);
};
export const updateEmployee = async (payload: IEmployeeForm) => {
  return adminAPI.put("/user-management/employee", payload);
};

export const updateStatusEmployee = async (
  payload: IUpdateStatusEmployeeForm
) => {
  return adminAPI.put("/user-management/employee/active", payload);
};

export const getEmployeeById = async (employeeId: string) => {
  try {
    const { data } = await adminAPI.get<IGetEmployeeForm>(
      `/user-management/employee/${employeeId}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

import { Dialog, DialogContent, IconButton, SvgIcon } from "@mui/material";
import dayjs from "dayjs";
import { FormikProps, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { postEditOrder } from "../../../../../services/admin/sale-order/CreditUpcomingOrder.service";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  IEditOrderForm,
  IListOfFeeds,
  ITableRowDetail,
} from "../../../../../types/EditOrderFeed";
import { FeedBrands } from "../../../../../types/FeedBrand.type";
import { thousandComma } from "../../../../../utils/common/string";
import delay from "../../../../../utils/delay";
import { CalendarSvg } from "../../../../assets/svg/Calendar.svg";
import CrossIcon from "../../../../assets/svg/CrossIcon.svg";
import PlusIconSVG from "../../../../assets/svg/PlusIcon.svg";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../../components/Button/ButtonOutlinedCustom";
import DatePickerPopover from "../../../../components/Input/DatePickerPopover/DatePickerPopover";
import TextInputCustom from "../../../../components/Input/TextInputCustom";
import ConfirmCustomModal from "../../../../components/Modal/ConfirmCustomModal";
import SuccessSnackBar from "../../../../components/SnackBar/SuccessSnackBar";
import SingleOrderFeed from "./SingleOrderFeed";
import { array, number, object, string, mixed } from "yup";

type EditOrderModalProps = {
  isOpen: boolean;
  handleClose: (isSuccess: boolean, values?: any) => void;
  feedBrands: FeedBrands[];
  order?: ITableRowDetail;
  handleFetch: () => void;
};

const ORDER_DATE_FORMAT = "YYYY-MM-DD";

const handleDateChange = (
  formik: FormikProps<IEditOrderForm>,
  value: string | null
) => {
  formik.setFieldValue("shippingDate", value);
};

const EditOrderModal = ({
  isOpen,
  handleClose,
  feedBrands,
  order,
  handleFetch,
}: EditOrderModalProps) => {
  const refCalendarOrderDate =
    React.useRef<React.ElementRef<typeof DatePickerPopover>>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [openAttachedToCreditModal, setOpenAttachedToCreditModal] =
    useState<boolean>(false);
  const [openDeleteAllOrderModal, setOpenDeleteAllOrderModal] =
    useState<boolean>(false);
  const [openConfirmSnackBar, setOpenConfirmSnackBar] =
    useState<boolean>(false);
  const [openDeleteAllSnackBar, setOpenDeleteAllSnackBar] =
    useState<boolean>(false);
  const [mode, setMode] = useState<"normal" | "delete" | "over-limit">(
    "normal"
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const calculateSumPrice = (value: IEditOrderForm) => {
    return value.listOfFeeds.reduce((prev, curr) => {
      return prev + Number(curr.feedAmount) * Number(curr.feedPricePerUnit);
    }, 0);
  };
  const isCheckExcessCredit = (value: IEditOrderForm) => {
    return (
      calculateSumPrice(value) >
      Number(order?.contract.credit) - Number(order?.contract.creditUsed)
    );
  };

  let initialValue: IEditOrderForm = {
    houseId: "",
    contractId: "",
    shippingDate: "",
    listOfFeeds: [
      {
        id: null,
        feedBrandId: null,
        feedBrandItemId: null,
        feedAmount: null,
        feedUnit: null,
        feedPricePerUnit: null,
      },
    ],
  };

  if (order) {
    initialValue = {
      houseId: order.contract.house.id,
      contractId: order.contract.id,
      shippingDate: dayjs(order.shippingDate).format(ORDER_DATE_FORMAT),
      listOfFeeds: order.orderFeedItems.map((item): IListOfFeeds => {
        return {
          id: item.id,
          feedBrandId: Number(item.feedBrand.id),
          feedBrandItemId: Number(item.feedBrandItem.id),
          feedAmount: item.feedAmount,
          feedUnit: item.feedBrandItem.unit,
          feedPricePerUnit: item.feedBrandItem.pricePerUnit,
        };
      }),
    };
  }

  const validationSchema = object({
    listOfFeeds: array().of(
      object().shape({
        feedBrandId: number().required("กรุณาเลือก"),
        feedBrandItemId: number().required("กรุณาเลือก"),
        feedAmount: number().min(0.00001).required("กรุณากรอก"),
      })
    ),
  });

  const handleSubmit = async (value: IEditOrderForm) => {
    setIsSubmitting(true);
    try {
      const filterListOfFeeds = value.listOfFeeds.map((item) => {
        return {
          id: item.id,
          feedBrandId: Number(item.feedBrandId),
          feedBrandItemId: Number(item.feedBrandItemId),
          feedAmount: Number(item.feedAmount),
          feedUnit: item.feedUnit,
          feedPricePerUnit: item.feedPricePerUnit,
        };
      });

      const payload = {
        houseId: value.houseId,
        contractId: value.contractId,
        shippingDate: dayjs(value.shippingDate).toISOString(),
        listOfFeeds: filterListOfFeeds,
      };
      const orderFeedId = order?.id;

      await postEditOrder(payload, orderFeedId);
    } catch (error) {
      throw error;
    } finally {
      setIsSubmitting(false);
      handleClose(true);
      switch (mode) {
        case "normal":
          setOpenConfirmModal(false);
          setOpenConfirmSnackBar(true);
          break;
        case "delete":
          setOpenDeleteAllOrderModal(false);
          setOpenDeleteAllSnackBar(true);
          break;
        case "over-limit":
          setOpenAttachedToCreditModal(false);
          setOpenConfirmSnackBar(true);
          break;
      }
      await delay(1500);
      handleFetch();
    }
  };

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: handleSubmit,
    validationSchema: validationSchema,
  });

  const handlePush = () => {
    formik.setFieldValue("listOfFeeds", [
      ...formik.values.listOfFeeds,
      {
        id: "",
        feedBrandId: "",
        feedBrandItemId: "",
        feedAmount: null,
        feedUnit: "",
        feedPricePerUnit: 0,
      },
    ]);
  };
  const handleRemove = (index: number) => {
    const newArr = formik.values.listOfFeeds.toSpliced(index, 1);
    formik.setFieldValue("listOfFeeds", newArr);
  };

  const handleCloseEdit = () => {
    if (formik.dirty) {
      setOpenCancelModal(true);
    } else {
      handleClose(false);
    }
  };

  useEffect(() => {
    formik.resetForm();
  }, [isOpen]);

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth="sm"
        onClose={handleCloseEdit}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={{
          backdrop: {
            style: { backgroundColor: "#000000", opacity: "10%" },
            timeout: 500,
          },
        }}
      >
        <DialogContent>
          <div className="flex justify-between items-center mb-6">
            <p className="text-lg font-bold">แก้ไขออเดอร์</p>
            <IconButton onClick={handleCloseEdit}>
              <CrossIcon />
            </IconButton>
          </div>
          <>
            <div className="flex">
              <div className="w-[50%]">
                <p className="font-bold mb-1">เครดิตคงเหลือ</p>
                <p>
                  {thousandComma(
                    Number(order?.contract.credit) -
                      Number(order?.contract.creditUsed),
                    2
                  )}{" "}
                  บาท
                </p>
              </div>
              <div className="w-[50%]">
                <p className="font-bold mb-1 text-[16px]">เครดิตที่ใช้</p>
                {isCheckExcessCredit(formik.values) ? (
                  <>
                    <p className="text-error-200">
                      {thousandComma(calculateSumPrice(formik.values), 2)} บาท
                    </p>
                    <p className="text-error-200 text-[12px] font-medium">
                      *เครดิตเกินวงเงินที่ได้รับ
                    </p>
                  </>
                ) : (
                  <p>
                    {thousandComma(calculateSumPrice(formik.values), 2)} บาท
                  </p>
                )}
              </div>
            </div>
            <p className="font-medium font-sans mt-4">วันที่ต้องการรับสินค้า</p>
            <div className="pt-2">
              <TextInputCustom
                placeholder="เลือกวันที่ต้องการรับสินค้า"
                value={formik?.values?.shippingDate}
                InputProps={{
                  endAdornment: formik?.values?.shippingDate ? (
                    <CloseRoundedIcon
                      sx={{ cursor: "pointer", fontSize: "18px !important" }}
                      onClick={(e) => {
                        formik.setFieldValue("shippingDate", "");
                        e.stopPropagation();
                      }}
                    />
                  ) : (
                    <SvgIcon
                      sx={{
                        fontSize: "24px !important",
                        color: "#646464",
                      }}
                      fontSize="small"
                    >
                      <CalendarSvg />
                    </SvgIcon>
                  ),
                }}
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  refCalendarOrderDate.current?.onOpen(e);
                }}
              />
            </div>
            <div className="my-4">
              <div className="flex justify-between">
                <p className="text-[#3777BC] font-bold text-lg mb-3">
                  รายการสินค้า
                </p>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    handlePush();
                  }}
                >
                  <PlusIconSVG />
                </div>
              </div>
              <div className="flex flex-col gap-4" key="listOfFeeds-array">
                <>
                  {formik.values.listOfFeeds.map((_, idx) => (
                    <div
                      className="flex flex-col gap-4"
                      key={`listOfFeeds-${idx}`}
                    >
                      <SingleOrderFeed
                        key={idx}
                        formik={formik}
                        feedBrands={feedBrands}
                        idx={idx}
                        remove={() =>
                          formik.values.listOfFeeds.length <= 1
                            ? setOpenDeleteAllOrderModal(true)
                            : handleRemove(idx)
                        }
                      />
                    </div>
                  ))}
                </>
              </div>
            </div>
            <div className="flex justify-end gap-3">
              <ButtonOutlinedCustom title="ยกเลิก" onClick={handleCloseEdit} />
              <ButtonFillCustom
                title="ยืนยัน"
                onClick={async () => {
                  await formik.validateForm();
                  if (formik.isValid) {
                    isCheckExcessCredit(formik.values)
                      ? setOpenAttachedToCreditModal(true)
                      : setOpenConfirmModal(true);
                  } else {
                    formik.setTouched({
                      listOfFeeds: formik.values.listOfFeeds.map((_, idx) => ({
                        feedBrandId: true,
                        feedBrandItemId: true,
                        feedAmount: true,
                      })),
                    });
                  }
                  await formik.validateForm();
                }}
                disabled={!formik.isValid}
              />
            </div>
            <DatePickerPopover
              ref={refCalendarOrderDate}
              id={"calendar-popover-order"}
              handleCalenderSave={(value) => {
                if (value) {
                  const dateString = dayjs(value).format(ORDER_DATE_FORMAT);
                  handleDateChange(formik, dateString);
                }
              }}
              date={formik.values.shippingDate}
            />
            <ConfirmCustomModal
              title="ยืนยันการลบทั้งหมด"
              subtitleFirstRow="การลบทุกรายการออเดอร์นี้จะถูกปิดอัตโนมัติ"
              subtitleSecondRow="คุณจะยืนยันการทำรายการหรือไม่"
              buttonPrimaryText="ใช่"
              buttonSecondaryText="ไม่ใช่"
              open={openDeleteAllOrderModal}
              onClose={() => setOpenDeleteAllOrderModal(false)}
              disablePrimaryBtn={isSubmitting}
              disableSecondaryBtn={isSubmitting}
              onSubmit={async () => {
                setMode("delete");
                formik.setFieldValue("listOfFeeds", []);
                formik.handleSubmit();
              }}
            />
            <ConfirmCustomModal
              title="ยืนยันการบันทึก"
              subtitleFirstRow="คุณต้องการบันทึกรายการนี้ใช่หรือไม่?"
              subtitleSecondRow="การดำเนินการนี้ไม่สามารถยกเลิกได้"
              buttonPrimaryText="ยืนยัน"
              buttonSecondaryText="กลับไปแก้ไข"
              open={openConfirmModal}
              onClose={() => setOpenConfirmModal(false)}
              disablePrimaryBtn={isSubmitting}
              disableSecondaryBtn={isSubmitting}
              onSubmit={async () => {
                setMode("normal");
                formik.handleSubmit();
              }}
            />
            <ConfirmCustomModal
              title="บันทึกการเปลี่ยนเเปลง"
              subtitleFirstRow="คุณต้องการบันทึกใช่หรือไม่?"
              subtitleSecondRow="การดำเนินการนี้ไม่สามารถยกเลิกได้"
              buttonPrimaryText="ยืนยัน"
              buttonSecondaryText="ยกเลิก"
              open={openCancelModal}
              onClose={() => setOpenCancelModal(false)}
              onSubmit={() => {
                handleClose(true);
                setOpenCancelModal(false);
              }}
            />
            <ConfirmCustomModal
              title="ยืนยันการบันทึก"
              subtitleFirstRow="คุณต้องการบันทึกรายการนี้ใช่หรือไม่?"
              subtitleSecondRow="การดำเนินการนี้ไม่สามารถยกเลิกได้"
              buttonPrimaryText="ยืนยัน"
              buttonSecondaryText="กลับไปแก้ไข"
              description="*เครดิตที่เกินวงเงินจะต้องทำการให้Lending อนุมัติใหม่"
              open={openAttachedToCreditModal}
              onClose={() => setOpenAttachedToCreditModal(false)}
              disablePrimaryBtn={isSubmitting}
              disableSecondaryBtn={isSubmitting}
              onSubmit={async () => {
                setMode("over-limit");
                formik.handleSubmit();
              }}
            />
          </>
        </DialogContent>
      </Dialog>
      <SuccessSnackBar
        openSnackbar={openConfirmSnackBar}
        handleCloseSnackbar={() => {
          setOpenConfirmSnackBar(false);
        }}
        snackbarText={"บันทึกสำเร็จ"}
      />
      <SuccessSnackBar
        openSnackbar={openDeleteAllSnackBar}
        handleCloseSnackbar={() => {
          setOpenDeleteAllSnackBar(false);
        }}
        snackbarText={"ลบทั้งหมดสำเร็จ"}
      />
    </>
  );
};

export default EditOrderModal;

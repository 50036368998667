import { useCallback, useEffect, useState } from "react";
import { cloneDeep, get, isEmpty, result, set } from "lodash";
import { getListPartner } from "../../../services/ManageStock.service";
import { IGetListPartnerDetails } from "../../../types/ManageStock.type";
import { useQuery } from "@tanstack/react-query";

export const useManageStockController = () => {
  const [searchText, setSearchText] = useState("");

  const [countDataManageStock, setCountDataManageStock] = useState(0);

  const [currentPageManageStock, setCurrentPageManageStock] = useState(1);

  const onSearchText = useCallback((e: any) => {
    const text = e.target.value;
    setSearchText(text);
  }, []);

  const onClearSearchText = useCallback(() => {
    setSearchText("");
  }, []);

  const { data: listPartnerData } = useQuery({
    queryKey: ["list-partner", currentPageManageStock, searchText],
    queryFn: async () => {
      const result = await getListPartner({
        page: currentPageManageStock,
        searchText: searchText,
      });
      return result;
    },
  });

  useEffect(() => {
    if (listPartnerData && listPartnerData.result.count > 0) {
      setCountDataManageStock(listPartnerData.result.count);
    }
  }, [listPartnerData]);

  return {
    searchText,
    onSearchText,
    onClearSearchText,
    listPartnerData,
    currentPageManageStock,
    setCurrentPageManageStock,
    countDataManageStock,
  };
};

import React from "react";
import { Paper } from "@mui/material";
import ZoomImageSvg from "../../../../assets/svg/ZoomImage";

export interface IDocumentCardProps {
  image: string;
  hiddenZoomIcon?: boolean;
}

function DocumentCard({ image, hiddenZoomIcon = false }: IDocumentCardProps) {
  return (
    <div className="flex h-full w-full justify-center relative">
      <Paper
        elevation={0}
        sx={{
          position: "relative",
        }}
      >
        {!hiddenZoomIcon && (
          <div className="absolute top-[45%] bottom-[55%] left-[45%] right-[55%] translate-x-1/2 -translate-y-1/2">
            <div className="w-8 h-8">
              <ZoomImageSvg />
            </div>
          </div>
        )}
        <img
          src={image}
          alt={`invoice-item`}
          width="100%"
          height="auto"
          className="object-contain w-[271px]"
        />
      </Paper>
    </div>
  );
}

export default DocumentCard;

import { memo, useEffect } from "react";
import AccordionCreditCustom from "../../../components/AccordionCreditCustom";
import SegmentWeightInput from "../../../components/SegmentWeightInput";
import TableCreditCustom from "../../../components/TableCreditCustom";
import { useFormContext, useWatch } from "react-hook-form";
import { ICreditForm } from "../../../../../../types/admin/masterdata/creditForm";

type TGeneralAccordion = {
  viewAction: boolean;
};
const GeneralAccordion = ({ viewAction }: TGeneralAccordion) => {
  const methods = useFormContext<ICreditForm>();

  const generalPersonalInformationWeight = useWatch({
    name: "generalPersonalInformation.segmentWeight",
    control: methods.control,
  });

  useEffect(() => {
    methods.setValue(
      "generalLegalInformation.segmentWeight",
      generalPersonalInformationWeight
    );
  }, [generalPersonalInformationWeight]);

  return (
    <AccordionCreditCustom
      title={"General"}
      rowChildren={
        <>
          {/* Person info */}
          <SegmentWeightInput
            name="generalPersonalInformation"
            viewAction={viewAction}
          />
          <TableCreditCustom
            name="generalPersonalInformation"
            isDisplayAddRow={false}
            viewAction={viewAction}
          />
          {/*  Legal  info*/}
          <SegmentWeightInput
            value={methods.watch("generalLegalInformation.segmentWeight")}
            name="generalLegalInformation"
            viewAction={true}
          />
          <TableCreditCustom
            name="generalLegalInformation"
            isDisplayAddRow={false}
            viewAction={viewAction}
          />
          {/* Farm info */}
          <SegmentWeightInput
            name="generalFarmInformation"
            viewAction={viewAction}
          />
          <TableCreditCustom
            name="generalFarmInformation"
            isDisplayAddRow={true}
            viewAction={viewAction}
          />
        </>
      }
    />
  );
};

export default memo(GeneralAccordion);

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import get from "lodash/get";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  getDownloadUrlOrderPartner,
  getOrderDetail,
} from "../../../../services/PartnerOrderService";
import { getStepsFromStatus } from "../../constants";
import { IOrderItemProps } from "../../views/OrderItem";
import { dtoOrderList, getStatusFromPartnerStatus } from "../dto/dtoOrderList";

export const useOrderDetailController = (orderId: string) => {
  const { pathname } = useLocation();
  const [images1, setImages1] = useState<string[]>([]);
  const [images2, setImages2] = useState<string[]>([]);
  const [images3, setImages3] = useState<string[]>([]);
  const navigate = useNavigate();
  const {
    data: orderDetail,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["order", "detail", orderId],
    enabled: !!orderId,
    queryFn: async () => {
      const result = await getOrderDetail(orderId);
      if (result) {
        const order = dtoOrderList({ orderFeeds: [result] } as any);
        return order[0];
      }
      return undefined;
    },
  });

  const [searchParams] = useSearchParams();
  const isHistory = (searchParams.get("isHistory") as string) === "yes";
  const isCanBack =
    typeof window !== "undefined" ? window.history.state.idx > 0 : false;

  const [actionModalDetail, setActionModalDetail] = useState<IOrderItemProps>();
  const [isShowActionModal, setIsShowActionModal] = useState(false);
  const [isShowSummaryPdf, setIsShowSummaryPdf] = useState(false);

  const onBack = useCallback(() => {
    if (!isCanBack) {
      navigate(!isHistory ? "/order/new" : "/order/history");
    } else if (pathname.indexOf("/order/detail") !== -1) {
      navigate(!isHistory ? "/order/new" : "/order/history");
    } else {
      navigate(-1);
    }
  }, [isHistory, isCanBack]);

  const onShowSummaryPdf = useCallback(() => {
    setIsShowSummaryPdf(true);
  }, []);

  const onCloseSummaryPdf = useCallback(() => {
    setIsShowSummaryPdf(false);
  }, []);

  const onEditImage = useCallback(() => {
    if (orderDetail) {
      navigate(`/order/prove-shipping/${orderDetail.id}?isEdit=yes`);
    }
  }, [orderDetail]);

  const onContact = useCallback(() => {
    if (orderDetail) {
      navigate(`/partner/contact-buyer/${orderDetail.id}`);
    }
  }, [orderDetail]);

  const onAction = useCallback(
    (orderDetail: IOrderItemProps) => () => {
      setActionModalDetail(orderDetail);
      setIsShowActionModal(true);
    },
    [orderDetail]
  );

  const onCloseAction = useCallback(() => {
    setIsShowActionModal(false);
    setTimeout(() => {
      setActionModalDetail(undefined);
    }, 400);
  }, []);

  const stepsFromStatus = useMemo(() => {
    const status = orderDetail?.status;
    if (status) {
      if (status === "canceled") {
        const newStatus = getStatusFromPartnerStatus(orderDetail.partnerStatus);
        return getStepsFromStatus(newStatus as any);
      }
      return getStepsFromStatus(status);
    }
    return [];
  }, [orderDetail?.status]);

  const initImage = useCallback(async () => {
    const imgs = get(orderDetail, ["images"], []);
    const imagesPartnerProofOfDelivery = [];
    const imagesPartnerPickingSlip = [];
    const imagesSalePickingSlip = [];
    if (orderDetail && imgs.length > 0) {
      if (imgs.length > 0) {
        for (let i = 0; i < imgs.length; i++) {
          try {
            const img = imgs[i];
            let urlId: any = img.url.split("/") as string[];
            urlId = urlId[urlId.length - 1];
            const preSignURL = await getDownloadUrlOrderPartner(
              orderDetail?.orderNo,
              img.documentType,
              urlId
            );
            if (preSignURL) {
              const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
                responseType: "blob",
              });
              const objectURL = window.URL.createObjectURL(response.data);
              if (img.documentType === "partnerPickingSlip") {
                imagesPartnerPickingSlip.push(objectURL);
              } else if (img.documentType === "partnerProofOfDelivery") {
                imagesPartnerProofOfDelivery.push(objectURL);
              } else if (img.documentType === "partnerPickingSlipForSale") {
                imagesSalePickingSlip.push(objectURL);
              }
            }
          } catch (e) {
            console.log("error to fetch image <Maybe timeout> e:", e);
          }
        }
      }
    }
    setImages1(imagesPartnerProofOfDelivery);
    setImages2(imagesPartnerPickingSlip);
    setImages3(imagesSalePickingSlip);
  }, [orderDetail]);

  useEffect(() => {
    initImage();
  }, [orderDetail]);

  return {
    onBack,
    orderDetail,
    isShowSummaryPdf,
    onShowSummaryPdf,
    onCloseSummaryPdf,
    onEditImage,
    onContact,
    stepsFromStatus,
    onAction,
    onCloseAction,
    isShowActionModal,
    actionModalDetail,
    images1,
    images2,
  };
};

import { Divider } from "@mui/material";
import { tw } from "../../../utils/tw";
import { useAdminAuth } from "../../components/AdminAuth/AdminAuthProvider";
import MenuBar from "../../components/MenuBar/MenuBar";

const classes = {
  container: tw(`pt-16 w-full h-full relative`),
  title: tw(`text-black font-bold text-3xl`),
  body: tw(`text-black font-medium text-xl mt-3`),
  mainContainer: tw(`pt-6 flex flex-col w-full`),
  filterRowContainer: tw(`flex flex-row flex-wrap items-end gap-3`),
  columnContainer: tw(`w-full flex flex-row overflow-x-auto mt-6 gap-x-3`),
};

function Home() {
  const { user } = useAdminAuth();
  return (
    <>
      <MenuBar>
        <div className={classes.container}>
          <div className="p-10">
            <div className="flex justify-between">
              <span className={classes.title}>
                Hello, {user?.name || user?.value || "Guest"}
              </span>
            </div>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className={classes.body}>
              <span>
                You are currently logged in as{" "}
                <span className={tw("font-extrabold")}>"{user?.userRole}"</span>
              </span>
            </div>
          </div>
        </div>
      </MenuBar>
    </>
  );
}
export default Home;

import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CallIcon from "@mui/icons-material/Call";
import CircleIcon from "@mui/icons-material/Circle";
import InfoIcon from "@mui/icons-material/Info";
import { Button, Link, Step, StepLabel, Stepper } from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DocumentBlue from "../../../../assets/svg/DocumentBlue";
import { StepperConnectorFS } from "../../../../components/common/Stepper";
import { IGetMyOrderByID } from "../../../../types/MyOrderFeed.type";
import { thousandComma } from "../../../../utils/common/string";
import { CreditHoldRejectOption } from "../../../../utils/enum/creditHoldRejectOption.enum";
import OrderFeedFarmerItemList from "./OrderFeedFarmerItemList";
import StatusFarmerOrderFeed from "./StatusFarmerOrderFeed";
import { StatusEnum } from "./type";
import ViewSummaryModal from "./views/ViewSummaryModalPage";
import { getDownloadUrlOrderFarmer } from "../../../../services/MyOrder.service";
import get from "lodash/get";
import axios from "axios";

type OrderFeedFarmerDetailProps = {
  // props primary button
  primaryButtonText: string;
  isPrimaryDisabled?: boolean;
  onPrimaryClick: () => void;
  //props secondary button
  secondaryButtonText?: string;
  isSecondaryDisabled?: boolean;
  onSecondaryClick?: () => void;
  //props display
  isDisplaySecondaryButton?: boolean;
  textDescription?: string;
  isTotalItemPrice?: boolean;
  isTradingDocument?: boolean;
  //props order data
  orderDetail: IGetMyOrderByID;
};

export default function OrderFeedFarmerDetail({
  primaryButtonText,
  isPrimaryDisabled,
  onPrimaryClick,
  secondaryButtonText,
  isSecondaryDisabled,
  onSecondaryClick,
  isDisplaySecondaryButton,
  textDescription,
  isTotalItemPrice,
  isTradingDocument,
  orderDetail,
}: OrderFeedFarmerDetailProps) {
  const [activeStep, setActiveStep] = useState<number>(0);
  const navigate = useNavigate();
  const cancelStatus =
    orderDetail.result.status === "rejected"
      ? orderDetail.result.rejectedStatus
      : orderDetail.result.canceledStatus;
  const currentStatus = orderDetail.result.status;

  const firstStatusSteps = [
    "waiting_for_sale_send_to_lender",
    "waiting_for_sale_approval",
    "waiting_for_lender_approval",
  ];

  const stepsData = [
    {
      id: "step-1",
      label: <div className="text-[13px]">สั่งซื้อสำเร็จ</div>,
      completed: false,
    },
    {
      id: "step-2",
      label: <div className="text-[13px]">ที่ต้องได้รับ</div>,
      completed: false,
    },
    {
      id: "step-3",
      label: <div className="text-[13px]">อยู่ระหว่างจัดส่ง</div>,
      completed: false,
    },
    {
      id: "step-4",
      label: <div className="text-[13px]">ยืนยันการรับ</div>,
      completed: false,
    },
    {
      id: "step-5",
      label: <div className="text-[13px]">รับสินค้าสำเร็จ</div>,
      completed: false,
    },
  ];
  const [steps, setSteps] = useState<any[]>(stepsData);

  useEffect(() => {
    const tempStepsData = [...stepsData];
    if (
      firstStatusSteps.includes(cancelStatus) ||
      firstStatusSteps.includes(currentStatus)
    ) {
      setActiveStep(0);
      tempStepsData[0].completed = true;
    }
    if (
      currentStatus === "waiting_for_delivery" ||
      cancelStatus === "waiting_for_delivery"
    ) {
      setActiveStep(1);
      tempStepsData[0].completed = true;
    }
    if (currentStatus === "delivery" || cancelStatus === "delivery") {
      setActiveStep(2);
      tempStepsData[0].completed = true;
      tempStepsData[1].completed = true;
    }
    if (
      currentStatus === "waiting_for_acceptance" ||
      cancelStatus === "waiting_for_acceptance"
    ) {
      setActiveStep(3);
      tempStepsData[0].completed = true;
      tempStepsData[1].completed = true;
      tempStepsData[2].completed = true;
    }
    if (currentStatus === "accepted" || cancelStatus === "accepted") {
      setActiveStep(4);
      tempStepsData[0].completed = true;
      tempStepsData[1].completed = true;
      tempStepsData[2].completed = true;
      tempStepsData[3].completed = true;
      tempStepsData[4].completed = true;
    }
    setSteps(tempStepsData);
  }, [cancelStatus, currentStatus]);

  const CustomStepIcon = (isComplete: boolean, isPending: boolean) => {
    // TODO : MOVE color code to one common themeing
    return isComplete ? (
      <FontAwesomeIcon
        color="secondary"
        className={cancelStatus === null ? "text-secondary" : "text-error"}
        size="lg"
        icon={faCircleCheck}
      />
    ) : (
      <CircleIcon
        fontSize="small"
        className="text-[#EBEBEB]"
        color={
          isPending
            ? cancelStatus === null
              ? "secondary"
              : "error"
            : undefined
        }
      />
    );
  };

  const [isShowSummary, setIsShowSummary] = useState(false);

  const onShowSummary = useCallback(() => {
    setIsShowSummary(true);
  }, []);

  const onCloseSummary = useCallback(() => {
    setIsShowSummary(false);
  }, []);

  const [images, setImages] = useState<string[]>([]);

  const initImage = useCallback(async () => {
    const imgs = get(orderDetail, ["result", "orderFeedFiles"], []);
    const orderNo = get(orderDetail, ["result", "orderNumber"], "");
    const imageList = [];
    if (imgs.length > 0) {
      for (let i = 0; i < imgs.length; i++) {
        const img = imgs[i];
        const preSignURL = await getDownloadUrlOrderFarmer(
          orderNo,
          img.documentType,
          img.id
        );
        if (preSignURL) {
          try {
            const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
              responseType: "blob",
            });
            const objectURL = window.URL.createObjectURL(response.data);
            imageList.push(objectURL);
          } catch (error) {
            console.error(error);
          }
        }
      }
    }
    setImages(imageList);
  }, [orderDetail]);

  useEffect(() => {
    initImage();
  }, [orderDetail]);

  return (
    <>
      <div className="flex justify-between mx-4 items-center">
        <p className=" font-semibold text-[18px]">
          หมายเลขคำสั่งซื้อ {orderDetail?.result?.orderNumber}
        </p>
        <div>
          <StatusFarmerOrderFeed
            status={orderDetail?.result?.status as StatusEnum}
            isReportOrder={orderDetail.result.isReportOrder}
          />
        </div>
      </div>
      {orderDetail?.result.rejectedBy && (
        <div className="mx-4 mt-3">
          <div>
            <InfoIcon color="info" />
            <span className="text-[#3777BC] ml-3">
              ไม่อนุมัติคำสั่งซื้อโดย {orderDetail?.result?.rejectedBy}
            </span>
          </div>
          <span className="text-[#979797] ml-9">
            {CreditHoldRejectOption[
              orderDetail?.result
                .rejectReason as keyof typeof CreditHoldRejectOption
            ] || orderDetail?.result.rejectReason}
          </span>
        </div>
      )}
      <div className="mt-3">
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<StepperConnectorFS />}
        >
          {steps?.map((step, index) => {
            return (
              <Step
                key={step?.id}
                completed={step?.completed}
                disabled={!!cancelStatus && index <= activeStep}
              >
                <StepLabel
                  key={index}
                  StepIconComponent={() =>
                    CustomStepIcon(step?.completed, index <= activeStep)
                  }
                >
                  {step?.label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      {orderDetail?.result?.orderFeedItems.map((order, index) => {
        return (
          <div
            className="border-b border-[#D9EBFE] pb-2"
            key={`row-order-feed-${index}`}
          >
            <div className="mx-2">
              <OrderFeedFarmerItemList
                isTotalItemPrice={isTotalItemPrice}
                key={index}
                listOrder={order}
                index={index + 1}
              />
            </div>
          </div>
        );
      })}
      <div className="flex justify-between text-sm my-2 mx-4">
        <p className="text-primary-grey-200">
          {orderDetail?.result?.orderFeedItems?.length} รายการ
        </p>
        <p>{thousandComma(orderDetail?.result?.totalFeedPrice)} บาท</p>
      </div>
      {isTradingDocument ? (
        <div className="border-t-8 border-[#E2E2E2]">
          <div className="mx-4 my-2">
            <div className="flex items-center">
              <DocumentBlue />
              <p className="text-[15px] mt-2 text-tertiary ml-2">
                เอกสารการซื้อขาย
              </p>
            </div>
            <div className="flex justify-between text-[15px] mt-2 ml-6 text-primary-grey-200">
              <p>หลักฐานการซื้อขาย</p>
              <Link
                sx={{
                  color: "#B0B0B0",
                }}
                onClick={onShowSummary}
              >
                ดูหลักฐาน
              </Link>
            </div>
          </div>
        </div>
      ) : null}
      <div className="border-y-8 border-[#E2E2E2] py-2">
        <div className="mx-4">
          <div className="flex justify-between text-[15px] mt-2 text-tertiary">
            <p>หมายเลขคำสั่งซื้อ</p>
            <p>{orderDetail?.result?.orderNumber}</p>
          </div>
          <div className="flex justify-between text-[15px] mt-2 text-primary-grey-200">
            <p>วันสั่งสินค้า</p>
            {/* <p>{dayjs(orderDetail?.result?.createdAt).format("DD/MM/BBBB")}</p> */}
            <p>{dayjs(orderDetail?.result?.createdAt).format("DD/MM/YYYY")}</p>
          </div>
          <div className="flex justify-between text-[15px] mt-2 text-primary-grey-200">
            <p>วันที่ต้องการรับสินค้า</p>
            <p>
              {/* {dayjs(orderDetail?.result?.shippingDate).format("DD/MM/BBBB")} */}
              {dayjs(orderDetail?.result?.shippingDate).format("DD/MM/YYYY")}
            </p>
          </div>
          {orderDetail.result.receiveDate ? (
            <div className="flex justify-between text-[15px] mt-2 text-primary-grey-200">
              <p>วันที่ได้รับสินค้า</p>
              <p>
                {/* {dayjs(orderDetail.result.receiveDate).format("DD/MM/BBBB")} */}
                {dayjs(orderDetail.result.receiveDate).format("DD/MM/YYYY")}
              </p>
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex justify-center mt-4 mb-8">
        <Button
          onClick={() => navigate("/contact-seller")}
          variant="outlined"
          startIcon={<CallIcon />}
          sx={{
            color: "#68C184",
            borderColor: "#68C184",
            fontWeight: "600",
            width: "50%",
          }}
        >
          ติดต่อผู้ขาย
        </Button>
      </div>
      {textDescription ? (
        <p className="text-center text-status-bad font-semibold text-[15px]">
          กรุณากดยืนยันหลังจากได้รับ <br /> และตรวจสอบสินค้าแล้ว
        </p>
      ) : null}
      <div className="flex justify-center gap-2 mt-4">
        {isDisplaySecondaryButton ? (
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              width: "45%",
              border: "1px solid #68C184",
              backgroundColor: "#ffff",
              position: "sticky",
            }}
            onClick={onSecondaryClick}
            disabled={isSecondaryDisabled}
          >
            {secondaryButtonText}
          </Button>
        ) : null}
        <Button
          color="secondary"
          variant="contained"
          sx={{
            width: isDisplaySecondaryButton ? "45%" : "90%",
            position: "sticky",
            top: 0,
          }}
          onClick={onPrimaryClick}
          disabled={isPrimaryDisabled}
        >
          {primaryButtonText}
        </Button>
        {/* orderDetail */}
        <ViewSummaryModal
          isOpen={isShowSummary}
          onClose={onCloseSummary}
          images={images}
          orderDetail={orderDetail}
        />
      </div>
    </>
  );
}

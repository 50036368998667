import { rand, randFullName, randFutureDate, randNumber } from "@ngneat/falso";
import { tw } from "../../../utils/tw";
import dayjs from "dayjs";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getCommaNumber } from "../../../utils/number";
import { ListRevenue } from "../../../types/partner/PartnerLanding.type";
import { get } from "lodash";

interface IProps {
  data?: ListRevenue[];
  isDataNew?: boolean;
  isDataSales?: boolean;
  isDataIncome?: boolean;
}

const RevenueTypeMap = {
  onboard: "ลูกค้าใหม่",
  order_feed: "ยอดขาย",
  invoice: "เก็บหนี้",
  "": "",
};

const RevenueDetailMap = {
  onboard: "ลูกค้าใหม่",
  order_feed: "หมายเลขสั่งซื้อ",
  invoice: "เลขที่ใบงาน",
  "": "",
};

const classes = {
  container: tw(`px-4 flex flex-col gap-[6px]`),
};

const ShowDetails = (props: IProps) => {
  const { data, isDataNew, isDataSales, isDataIncome } = props;

  const dataOnboard = data
    ?.map((item) => ({
      ...item,
      rows: item.rows.filter((dataItem) => dataItem.revenueType === "onboard"),
    }))
    .filter((item) => item.rows.length > 0);

  const dataOrderFeed = data
    ?.map((item) => ({
      ...item,
      rows: item.rows.filter(
        (dataItem) => dataItem.revenueType === "order_feed"
      ),
    }))
    .filter((item) => item.rows.length > 0);

  const dataInvoice = data
    ?.map((item) => ({
      ...item,
      rows: item.rows.filter((dataItem) => dataItem.revenueType === "invoice"),
    }))
    .filter((item) => item.rows.length > 0);

  return (
    <div className={classes.container}>
      {(
        (isDataNew
          ? dataOnboard
          : isDataSales
          ? dataOrderFeed
          : isDataIncome
          ? dataInvoice
          : data) || []
      ).map((i, index) => {
        return (
          <div key={`data-${index}`}>
            {i.rows.length > 0 ? (
              <div
                className="flex flex-col gap-[6px]"
                key={index + get(i, ["rows", 0, "id"], "")}
              >
                <div className="font-sans text-[#34407B] text-[16px] font-semibold">
                  {dayjs(get(i, ["date"], "")).format("DD/MM/YYYY")}
                </div>
                {get(i, ["rows"], []).map((d) => {
                  return (
                    <Accordion
                      key={d.id}
                      sx={{
                        border: "0px",
                        boxShadow: "none",
                        backgroundColor: "#FBFBFB",
                        borderRadius: "15px",
                        "&.Mui-expanded": {
                          margin: "0px !important",
                        },
                        ":before": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          boxShadow: "none",
                          direction: "flex",
                          flexDirection: "column",
                          alignItems: "end",
                          width: "100%",
                          gap: "2px",
                          ".MuiAccordionSummary-content": {
                            width: "100%",
                            margin: 0,
                            marginTop: "12px",
                          },
                          ".MuiAccordionSummary-root": {
                            ".Mui-expanded": {
                              minHeight: "72px",
                              maxHeight: "72px",
                            },
                          },
                        }}
                      >
                        <div className="flex !flex-row !justify-between w-full min-w-max font-sans relative">
                          <div className="text-[#34407B] font-semibold text-[16px]">
                            {
                              RevenueTypeMap[
                                get(d, ["revenueType"], "") as
                                  | "onboard"
                                  | "order_feed"
                                  | "invoice"
                              ]
                            }
                          </div>
                          <div className="absolute top-[32px] text-[#979797] text-[12px] leading-5 font-medium">
                            {dayjs(get(d, ["createdAt"], "")).format("hh:mm")}{" "}
                            น.
                          </div>
                          <div className="text-[#68C184] font-semibold text-[16px]">
                            +
                            {getCommaNumber(
                              get(d, ["multiplier"], 0) *
                                get(d, ["originalAmount"], 0),
                              true
                            )}
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Divider />
                        <div className="flex flex-col gap-[2px]">
                          <div className="pt-2 flex flex-row justify-between font-sans">
                            <div className="text-[#979797] font-normal text-[12px]">
                              ชื่อลูกค้า:
                            </div>
                            <div className="text-[#4B4B4B] font-semibold text-[12px]">
                              {get(d, ["farmer", "name"], "") +
                                " " +
                                get(d, ["farmer", "surname"], "")}
                            </div>
                          </div>
                          {get(d, ["revenueDetail", "id"], "") && (
                            <div className="pt-2 flex flex-row justify-between font-sans">
                              <div className="text-[#979797] font-normal text-[12px]">
                                {
                                  RevenueDetailMap[
                                    get(d, ["revenueType"], "") as
                                      | "onboard"
                                      | "order_feed"
                                      | "invoice"
                                  ]
                                }
                                :
                              </div>
                              <div className="text-[#4B4B4B] font-semibold text-[12px]">
                                {get(d, ["revenueDetail", "id"], "")}
                              </div>
                            </div>
                          )}
                          {get(d, ["revenueDetail", "totalDebt"], "") && (
                            <div className="pt-2 flex flex-row justify-between font-sans">
                              <div className="text-[#979797] font-normal text-[12px]">
                                ยอดหนี้ทั้งหมด:
                              </div>
                              <div className="text-[#4B4B4B] font-semibold text-[12px]">
                                {getCommaNumber(
                                  get(d, ["revenueDetail", "totalDebt"], 0),
                                  true
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </div>
            ) : (
              <div
                className="font-sans text-[#34407B] text-[16px] font-semibold"
                key={dayjs(get(i, ["date"], "")).format("DD/MM/YYYY:hh:mm:ss")}
              >
                ยังไม่มีข้อมูล
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ShowDetails;

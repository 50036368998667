import dayjs from "dayjs";
import { AppLocale } from "../types";
import "dayjs/locale/th";
import "dayjs/locale/en-gb";
import buddhistEra from "dayjs/plugin/buddhistEra";
import updatedLocale from "dayjs/plugin/updateLocale";
import localizedFormat from "dayjs/plugin/localizedFormat";
import localeData from "dayjs/plugin/localeData";
import preParsePostFormat from "dayjs/plugin/preParsePostFormat";
import isLeapYear from "dayjs/plugin/isLeapYear";

dayjs.extend(localeData);
dayjs.extend(buddhistEra);
dayjs.extend(updatedLocale);
dayjs.extend(localizedFormat);
dayjs.extend(preParsePostFormat);
dayjs.extend(isLeapYear);

dayjs.updateLocale("th", {
  formats: {
    // abbreviated format options allowing localization
    LTS: "h:mm:ss A",
    LT: "h:mm A",
    L: "DD/MM/BBBB",
    LL: "MMMM D, BBBB",
    LLL: "MMMM D, BBBB h:mm A",
    LLLL: "dddd, MMMM D, BBBB h:mm A",
    // lowercase/short, optional formats for localization
    l: "D/M/BBBB",
    ll: "D MMM, BBBB",
    lll: "D MMM, BBBB h:mm A",
    llll: "ddd, MMM D, BBBB h:mm A",
  },
});

// TODO Update on locale change0
export async function updateLocaleApp(localeType: AppLocale) {
  try {
    if (localeType === AppLocale.ENGLISH) {
      dayjs.locale("en");
    } else if (localeType === AppLocale.THAI) {
      dayjs.locale("th");
    }
    // console.log(dayjs.locale());
  } catch (err) {
    console.error("Error While loading DayJS module", err);
  }
}

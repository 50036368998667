import adminAPI from "../../../../services/AdminApiService";
import {
  IExportPartnerDetail,
  IGetPartnerDetail,
} from "../types/partnerDetailTable.type";
import { IGetPersonalInfoPartner } from "../types/personalInformation.type";

export const getPartnerDetail = async (searchParams?: URLSearchParams) => {
  try {
    const { data } = await adminAPI.get<IGetPartnerDetail>(
      `/partner-detail?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getPersonalInfoPartner = async (partnerId?: string) => {
  try {
    const { data } = await adminAPI.get<IGetPersonalInfoPartner>(
      `/partner-detail/${partnerId}/personal-info`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const postExportPartnerDetail = async (ids: string[]) => {
  try {
    const { data } = await adminAPI.post<IExportPartnerDetail>(
      `/partner-detail/export`,
      { ids }
    );
    return data;
  } catch (error) {
    return null;
  }
};

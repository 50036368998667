const Assignment2 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={29} height={30} fill="none">
    <mask
      id="a"
      width={29}
      height={30}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M0 .5h29v29H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#ED0B60"
        d="M6.042 25.878a2.327 2.327 0 0 1-1.707-.71 2.327 2.327 0 0 1-.71-1.707V6.544c0-.664.237-1.233.71-1.706a2.327 2.327 0 0 1 1.707-.71h5.075c.261-.725.7-1.31 1.314-1.752A3.452 3.452 0 0 1 14.5 1.71c.765 0 1.455.221 2.07.665a3.629 3.629 0 0 1 1.313 1.752h5.075c.665 0 1.234.236 1.707.71.473.473.71 1.042.71 1.706v16.917c0 .665-.237 1.233-.71 1.707a2.327 2.327 0 0 1-1.707.71H6.042Zm2.416-4.834h8.459v-2.416H8.458v2.416Zm0-4.833h12.084v-2.417H8.458v2.417Zm0-4.833h12.084V8.96H8.458v2.417Zm6.042-5.74a.88.88 0 0 0 .65-.257.88.88 0 0 0 .256-.65.88.88 0 0 0-.257-.649.88.88 0 0 0-.649-.256.88.88 0 0 0-.65.256.88.88 0 0 0-.256.65.88.88 0 0 0 .257.65.88.88 0 0 0 .649.256Z"
      />
    </g>
  </svg>
);
export default Assignment2;

import adminAPI from "../../../../../services/AdminApiService";
import { IConfirmInvoicePayment } from "../components/Modals/ConfirmPaymentModal";
import {
  IExportExcelLendingDebt,
  IGetCountTabLending,
  IGetTrackingDebt,
} from "../types/trackingDebt.type";

export const getTrackingDebt = async (
  searchParams?: URLSearchParams,
  tab?: string
) => {
  try {
    const { data } = await adminAPI.get<IGetTrackingDebt>(
      `/money-debt/lending/${tab}?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getCountDebtLending = async () => {
  try {
    const { data } = await adminAPI.get<IGetCountTabLending>(
      `/money-debt/lending/count`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const confirmInvoicePayment = async (body: IConfirmInvoicePayment) => {
  return adminAPI.put("/money-debt/lending/confirm", body);
};

export const exportExcelLendingDebt = async (
  tabName: string,
  installmentIds: string[]
) => {
  try {
    const { data } = await adminAPI.post<IExportExcelLendingDebt>(
      `/money-debt/lending/${tabName}/export`,
      { installmentIds }
    );
    return data;
  } catch (error) {
    return null;
  }
};

import { useNavigate } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import AppBarFS from "../../components/app-bar/AppBar";
import DashboardInfoCard from "../../components/common/DashboardInfoCard/DashboardInfoCard";
import OrderHistorySVG from "../../assets/svg/OrderHistory";
import ContractActive from "../../assets/svg/ContractActive";
import List from "../../assets/svg/List";

const TrackDebt = () => {
  let navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate("/partner");
  }, []);

  const handleClickToNewAssignment = useCallback(() => {
    navigate("/partner/track-debt/new-assignment");
  }, []);

  const handleClickToAcceptedAssignment = useCallback(() => {
    navigate("/partner/track-debt/accepted-assignment");
  }, []);

  const handleClickToHistoryAssignment = useCallback(() => {
    navigate("/partner/track-debt/history-assignment");
  }, []);

  return (
    <div>
      <div className="w-full h-full">
        <div className="h-[120px] flex items-center">
          <AppBarFS title={"การติดตามหนี้สิน"} onIconPress={handleBack} />
        </div>
        <div className="flex flex-col w-full h-full gap-[16px] px-[16px]">
          <DashboardInfoCard
            title={"ใบงานใหม่"}
            subTitle={"ใบงานใหม่ทั้งหมด"}
            icon={<List />}
            removeBgIcon={true}
            bgColor="#F0FDF4"
            onPress={handleClickToNewAssignment}
          />
          <DashboardInfoCard
            title={"ใบงานที่รับรายการแล้ว"}
            subTitle={"ใบงานที่รับรายการเเล้ว"}
            icon={<ContractActive />}
            removeBgIcon={true}
            bgColor="#EEF2FF"
            onPress={handleClickToAcceptedAssignment}
          />
          <DashboardInfoCard
            title={"ประวัติใบงาน"}
            subTitle={"ประวัติใบงานทั้งหมด"}
            icon={<OrderHistorySVG />}
            removeBgIcon={true}
            bgColor="#D9EBFEB2"
            onPress={handleClickToHistoryAssignment}
          />
        </div>
      </div>
    </div>
  );
};

export default TrackDebt;

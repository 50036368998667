const IconPersonSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="none">
    <mask
      id="a"
      width={25}
      height={25}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <path fill="#D9D9D9" d="M.539.266h24v24h-24z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#fff"
        d="M12.539 12.266c-1.1 0-2.042-.392-2.825-1.175-.784-.784-1.175-1.725-1.175-2.825S8.93 6.224 9.714 5.44c.783-.784 1.725-1.175 2.825-1.175s2.041.391 2.825 1.175c.783.783 1.175 1.725 1.175 2.825s-.392 2.041-1.175 2.825c-.784.783-1.725 1.175-2.825 1.175Zm-8 8v-2.8c0-.567.145-1.088.437-1.563.292-.475.68-.837 1.163-1.087a14.854 14.854 0 0 1 3.15-1.163 13.76 13.76 0 0 1 3.25-.387c1.1 0 2.183.129 3.25.387 1.066.258 2.116.646 3.15 1.163.483.25.87.612 1.162 1.087.292.475.438.996.438 1.563v2.8h-16Z"
      />
    </g>
  </svg>
);
export default IconPersonSvg;

import {
  getMoveOutPigHouseDetail,
  getMoveOutPigSellingReport,
} from "../../services/MoveOutPig.service";

export async function sellingReportLoader({
  params,
}: {
  params: { houseId: string };
}) {
  try {
    const { houseId } = params;
    if (!houseId) {
      throw new Response("Not Found", { status: 404 });
    }

    const sellingReport = await getMoveOutPigSellingReport(houseId);
    const house = await getMoveOutPigHouseDetail(houseId);

    if (sellingReport?.success && house?.success) {
      return {
        sellingReport,
        house,
      };
    } else {
      throw new Response("Not Found", { status: 404 });
    }
  } catch (err) {
    throw new Response("Not Found", { status: 404 });
  }
}

import { useLoaderData, useNavigate } from "react-router-dom";
import AppBarFS from "../../components/app-bar/AppBar";
import { useTranslation } from "react-i18next";
import FenceSVG from "../../assets/svg/Fence";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { Paper } from "@mui/material";
import AssignmentSVG from "../../assets/svg/Assignment";
import BookSVG from "../../assets/svg/Book";
import AirlineSVG from "../../assets/svg/Airline";
import DashboardInfoCard from "../../components/common/DashboardInfoCard/DashboardInfoCard";
import FireSVG from "../../assets/svg/Fire";
import DashboardSectionHeader from "../../components/common/DashboardSectionHeader/DashboardSectionHeader";

import PigPercentSVG from "../../assets/svg/PigPercent";

import TimerSVG from "../../assets/svg/Timer";
import {
  HouseActivityCalendarDTO,
  HouseStatusDetail,
} from "./house-dashboard.type";

import DashboardPigStatus from "../../components/common/DashboardPigStatus/DashboardPigStatus";
import { useEffect, useState, useContext } from "react";
import mapFarmStatusWithBusinesLogic from "./house-dashboard.service";
import DashboardStatusLabel from "../../components/common/DashboardStatusLabel/DashboardStatusLabel";

import dayjs, { Dayjs } from "dayjs";
import DateCalendarFS from "../../components/common/DateCalendar/DateCalendar";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import "./HouseDashboard.scss";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditHouseNoticeModal from "../../components/common/EditHouseNoticeModal/EditHouseNoticeModal";
import {
  getHouseActivityCalendar,
  getAllEdible,
} from "../../services/FarmService";
import PerformanceGraph from "../../components/common/PerformanceGraph";
import { getDailyLog, getFeedBrands } from "../../services/DailyLogService";
import DailyLogContext from "../../provider/DailyLogProvider/DailyLogContext";
import FeedingContext from "../add-house/feeding-program/Provider/FeedingContext";
import DollarCoin from "../../assets/svg/DollarCoin";
import { thousandComma } from "../../utils/common/string";
interface CustomizedDay extends PickersDayProps<Dayjs> {
  currentDate?: Dayjs;
  datesLogged?: Set<string>;
  vaccineDays?: Set<string>;
  feedChangedDates?: Set<string>;
}

function ServerDay(props: CustomizedDay) {
  const {
    datesLogged,
    currentDate,
    vaccineDays,
    feedChangedDates,
    today,
    day,
    outsideCurrentMonth,
    ...other
  } = props;
  function mapComponentToDay() {
    const currentDateString = day.format("YYYY-MM-DD");

    if (datesLogged?.has(currentDateString)) {
      return (
        <PickersDay
          {...other}
          sx={{}}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        >
          <div className="relative border-white bg-secondary rounded-full">
            <p className="absolute z-0 border-white rounded-full  top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <CheckCircleIcon color="secondary" fontSize="large" />
            </p>
            <span className="absolute z-10	 rounded-full text-white top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              {day?.get("date")}
            </span>
          </div>
        </PickersDay>
      );
    }

    if (feedChangedDates?.has(currentDateString)) {
      return (
        <PickersDay
          {...other}
          sx={{
            backgroundColor: "#F57C00",
            color: "white",
          }}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        ></PickersDay>
      );
    }
    if (vaccineDays?.has(currentDateString)) {
      return (
        <PickersDay
          {...other}
          sx={{
            backgroundColor: "#37A1DB",
            color: "white",
          }}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        ></PickersDay>
      );
    }

    if (today) {
      return (
        <PickersDay
          {...other}
          sx={{ border: "1px solid rgba(0,128,0,0.7)" }}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        ></PickersDay>
      );
    }
    return (
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    );
  }

  return <>{mapComponentToDay()}</>;
}

export function HouseDashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { house, houseStatus } = useLoaderData() as any;
  const [houseStatusDetail, setHouseStatusDetail] =
    useState<HouseStatusDetail | null>(null);
  const [isWithinMonth, setWithinMonth] = useState(false);
  const [minDate, setMinDate] = useState<any>(dayjs());
  const [vaccineDays, setVaccineDays] = useState(new Set());
  const [feedChangeDays, setFeedChangeDays] = useState(new Set());
  const [editHouseNoticeModal, setEditHouseNoticeModal] = useState(false);
  const [calendarDateLoading, setCalendarDataLoading] = useState(false);
  const currentDate = dayjs();
  const [datesLogged, setDatesLogged] = useState<any>(new Set());
  const [allEdible, setAllEdible] = useState<any>(new Set());
  const [pigStartAge, setPigStartAge] = useState<Number>(0);
  const [noLogDateCount, setNoLogDateCount] = useState<string>("0");
  const { setDailyLogData } = useContext(DailyLogContext);
  const {
    feedBrands,
    setAddHouseData,
    setSelectedBrand,
    setFeedBrands,
    setFeedData,
    setActiveStep,
    setSteps,
  } = useContext(FeedingContext);
  useEffect(() => {
    if (houseStatus) {
      const data = mapFarmStatusWithBusinesLogic(houseStatus);
      setHouseStatusDetail(data);
    }

    if (house) {
      setMinDate(dayjs(house?.houseStartDate));
      const currentDate = new Date();
      const oneMonthAgo = new Date(currentDate);
      oneMonthAgo.setMonth(currentDate.getMonth() - 1);
      setWithinMonth(
        new Date(house.createdAt) <= currentDate &&
          new Date(house?.createdAt) >= oneMonthAgo
      );
      const startPigAge = Math.ceil(house.pigAge / 7);
      setPigStartAge(startPigAge);
    }
    fetchDataForActivityCalendar(dayjs());
    fetchFeedBrand();
    fetchAllEdible();
    setNoLoggedDateCount();
    if (setDailyLogData) setDailyLogData({});
    if (setAddHouseData) setAddHouseData({});
    if (setSelectedBrand) setSelectedBrand([]);
    if (setFeedData) setFeedData([{}]);
    if (setActiveStep) setActiveStep(0);
    if (setSteps)
      setSteps([
        {
          //STEP 1: Basic Details
          id: "step-1-details",
          label: t("addHouse.stepTitleBasicDetails"),
          completed: false,
        },
        {
          //STEP 2: Feeding Program
          id: "step-2-feeding",
          label: t("addHouse.stepTitleFeedingProgram"),
          completed: false,
        },
        {
          //STEP 3: Vaccine Program
          id: "step-2-vaccine",
          label: t("addHouse.stepTitleVaccineProgram"),
          completed: false,
        },
      ]);
  }, []);
  const fetchFeedBrand = async () => {
    try {
      const { data } = await getFeedBrands();
      data.feedDetails?.forEach((fdata: any) => {
        fdata?.feed_brand_items?.forEach((data: any) => {
          data.brand_id = fdata.id;
        });
      });
      if (setFeedBrands) setFeedBrands(data.feedDetails);
    } catch (err) {
      console.error(err);
    }
  };
  async function fetchAllEdible() {
    const edible = await getAllEdible();
    setAllEdible(edible.data.AllEdible);
  }

  async function fetchDataForActivityCalendar(currentMonth: Dayjs) {
    try {
      setCalendarDataLoading(true);
      const { data } = await getHouseActivityCalendar(
        house?.id,
        currentMonth?.get("year"),
        currentMonth.get("month") + 1
      );

      const activityCalendar: HouseActivityCalendarDTO = data;
      let loggedDates = new Set<String>();
      if (Array.isArray(activityCalendar?.houseActivity?.dailyLogs))
        loggedDates = new Set(
          activityCalendar?.houseActivity?.dailyLogs?.map(
            (log: { logDate: any }) => {
              return new Date(log?.logDate).toISOString().split("T")[0];
            }
          )
        );
      else {
        const temploggedDates = [activityCalendar?.houseActivity?.dailyLogs];
        loggedDates = new Set(
          temploggedDates.map((log: { logDate: any }) => {
            return new Date(log?.logDate).toISOString().split("T")[0];
          })
        );
      }
      let vaccineDates = new Set<any>();
      if (Array.isArray(activityCalendar?.houseActivity?.houseVaccines))
        vaccineDates = new Set(
          activityCalendar?.houseActivity?.houseVaccines?.map(
            (vaccine: { vaccineDate: any }) => {
              return vaccine?.vaccineDate;
            }
          )
        );
      else {
        const tempVaccine = [activityCalendar?.houseActivity?.houseVaccines];
        vaccineDates = new Set(
          tempVaccine.map((vaccine: { vaccineDate: any }) => {
            return vaccine?.vaccineDate;
          })
        );
      }
      let feedDates = new Set<any>();
      if (Array.isArray(activityCalendar?.houseActivity?.houseFeeds))
        feedDates = new Set(
          activityCalendar?.houseActivity?.houseFeeds?.map(
            (feed: { feedChangeDate: any }) => {
              return feed?.feedChangeDate;
            }
          )
        );
      else {
        const tempFeedDates = [activityCalendar?.houseActivity?.houseFeeds];
        feedDates = new Set(
          tempFeedDates.map((feed: { feedChangeDate: any }) => {
            return feed?.feedChangeDate;
          })
        );
      }
      setDatesLogged(loggedDates);
      setVaccineDays(vaccineDates);
      setFeedChangeDays(feedDates);
      setCalendarDataLoading(false);
    } catch (err) {
      console.log(err);
      setCalendarDataLoading(false);
    }
  }

  async function setNoLoggedDateCount() {
    const resp: any = await getDailyLog(house?.id);
    const dailyLogCount = resp.data.dailyLogs.length;
    const diff = dayjs().diff(dayjs(house.houseStartDate, "YYYY-MM-DD"), "d");
    setNoLogDateCount((diff - dailyLogCount + 1).toString());
  }

  function getHouseStatusComponent() {
    if (houseStatusDetail) {
      return (
        <div className="p-4 flex-col">
          <DashboardPigStatus
            pigFaceEmotion={houseStatusDetail?.pigFaceEmotion}
            edibleStatus={houseStatusDetail?.edibleStatus}
            healthStatus={houseStatusDetail?.healthStatus}
            losePercentStatus={houseStatusDetail?.losePercentStatus}
            losePercent={houseStatusDetail?.loss}
          />
          <div className="mt-8 flex justify-center gap-8">
            <DashboardStatusLabel
              color="secondary"
              label={t("houseDashboard.statusGood")}
            />
            <DashboardStatusLabel
              color="error"
              label={t("houseDashboard.statusPoor")}
            />
          </div>
          <div className="flex items-center gap-2 mt-4">
            <div>
              <PigPercentSVG
                percentage={
                  (Number(houseStatusDetail?.pigAgeInWeeks) /
                    Number(t("houseDashboard.totalWeeks"))) *
                  100
                }
              />
            </div>
            <div className="flex flex-col items-center grow gap-1">
              <div className="flex gap-2 items-center">
                <TimerSVG />
                <p className="text-primary-200">
                  {t("houseDashboard.pigCycle")}
                </p>
              </div>
              <div className="px-2 py-1 rounded-2xl border-solid border-tertiary-200 border">
                <p className="text-primary font-semibold ">
                  {t("houseDashboard.pigAge")} :{" "}
                  {houseStatusDetail?.pigAgeInWeeks} /{" "}
                  {t("houseDashboard.totalWeeks")} {t("houseDashboard.weeks")}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="p-4 flex items-center justify-center">
          <p>No Data</p>
        </div>
      );
    }
  }

  const handleEditModalClose = () => {
    setEditHouseNoticeModal(false);
  };

  const handleEditHouseNavigate = () => {
    const data = {
      farmId: house.farmId,
      houseName: house.name,
      breederPig: house.breederPigCount,
      fattenerPig: house.fattenerPigCount,
      nurseryPig: house?.nurseryPigCount,
      houseStartDate: dayjs(house?.houseStartDate),
      ageOfPig: house?.pigAge,
      totalWeightOfPigs: house?.totalPigWeight,
      totalPriceOfPigs: house?.totalPigPrice,
      pricePerPig: "",
      id: house?.id,
      vaccineDetails: house?.houseVaccines,
    };
    isWithinMonth &&
      navigate("/edit-house", {
        state: {
          houseDetails: data,
        },
      });
  };

  const handleDateClick = (date: any) => {
    /** Check if the date is not already logged and date is not future date */
    if (
      !datesLogged.has(date.format("YYYY-MM-DD")) &&
      !date.isAfter(new Date())
    ) {
      navigate("/daily-log", {
        state: {
          selectedFarm: house?.farmId,
          houseId: house?.id,
          logDate: date,
        },
      });
    } else {
      navigate("/daily-log-edit", {
        state: {
          selectedFarm: house?.farmId,
          houseId: house?.id,
          logDate: date.format("YYYY-MM-DD"),
          isEdit: false,
        },
      });
    }
  };
  return (
    <div className="h-screen overflow-auto flex flex-col w-full bg-[#DDF3E4]">
      <div className="p-4 rounded-b-[40px] bg-white">
        <AppBarFS
          onIconPress={() => navigate(`/farm/${house?.farmId}`)}
          title={t("houseDashboard.appHeader")}
        />
      </div>
      <div className="flex p-4 mb-16">
        {/* <div>
                    <div className="flex items-center  gap-2">
                        <FenceSVG />
                        <h2 className="text-primary font-semibold ml-1 capitalize text-2xl" >หลัง PB001</h2>
                    </div>
                </div> */}

        <div className="flex flex-col grow">
          <div className="flex grow justify-between">
            <div className="flex items-center  gap-2">
              <FenceSVG />
              <h2 className="text-primary font-semibold ml-1 capitalize text-2xl">
                {house?.name}
              </h2>
            </div>
            {/**
             * TODO Handle Navigate to edit house onClick={() => navigate('/edit-house')}
             */}
            <div
              className="flex items-center"
              onClick={() => setEditHouseNoticeModal(true)}
            >
              <h2 className="text-primary">{t("houseDashboard.editHouse")}</h2>
              <ChevronRightOutlinedIcon color="primary" />
            </div>
          </div>

          <div className="mt-4">
            <DashboardInfoCard
              title={t("houseDashboard.currentProgramBlockHeading")}
              subTitle={t("houseDashboard.feedAndVaccineSubHeading")}
              icon={<AssignmentSVG />}
              onPress={() => navigate(`/houses/${house?.id}/program-overview`)}
            />
          </div>

          <div className="mt-8 flex flex-col gap-4">
            <DashboardSectionHeader
              title={t("houseDashboard.houseStatusHeading")}
            />
            <Paper elevation={0} sx={{ borderRadius: "20px" }}>
              {getHouseStatusComponent()}
            </Paper>
          </div>

          <div className="mt-8 flex flex-col gap-4">
            <DashboardSectionHeader
              onClick={() =>
                navigate("/house-performance-report", {
                  state: { houseId: house?.id },
                })
              }
              title={t("houseDashboard.housePerformanceHeading")}
              navigatorTitle={t("houseDashboard.housePerformanceReport")}
              info={t("houseDashboard.housePerformanceDetail")}
            />
            <Paper elevation={0} sx={{ borderRadius: "20px" }}></Paper>
          </div>
          <div>
            <PerformanceGraph
              standardEdible={houseStatusDetail?.standardEdible}
              houseId={house?.id}
              houseStartDate={dayjs(house?.houseStartDate)}
              allEdible={allEdible}
              pigStartAge={pigStartAge}
            />
          </div>

          <div className="mt-8 flex flex-col gap-4">
            <DashboardSectionHeader
              title={t("houseDashboard.activityCalendarHeading")}
              info={t("houseDashboard.activityCalendarInfo")}
            />
            <Paper elevation={0} sx={{ borderRadius: "20px" }}>
              <DateCalendarFS
                loading={calendarDateLoading}
                disabled={calendarDateLoading}
                renderLoading={() => <DayCalendarSkeleton />}
                minDate={minDate}
                slots={{
                  day: ServerDay,
                }}
                slotProps={{
                  day: {
                    datesLogged: datesLogged,
                    currentDate: currentDate,
                    vaccineDays: vaccineDays,
                    feedChangedDates: feedChangeDays,
                    today: currentDate,
                    disableRipple: true,
                    disableTouchRipple: true,
                    disabled: true,
                  } as any,
                }}
                onMonthChange={fetchDataForActivityCalendar}
                views={["day", "month", "year"]}
                onChange={handleDateClick}
              />
            </Paper>

            <div className="flex mx-10 justify-center flex-wrap">
              <div className="flex grow w-1/2 whitespace-nowrap  pr-4">
                <DashboardStatusLabel
                  color="error"
                  label={t("houseDashboard.today")}
                  icon={
                    <CircleOutlinedIcon
                      fontSize="small"
                      className="text-secondary"
                    />
                  }
                />
              </div>
              <div className="flex grow w-1/2 whitespace-nowrap  pl-4">
                <DashboardStatusLabel
                  color="error"
                  label={t("houseDashboard.dateLogged")}
                  icon={
                    <CheckCircleIcon
                      fontSize="small"
                      className="text-secondary"
                    />
                  }
                />
              </div>
              <div className="flex grow w-1/2 whitespace-nowrap pr-4 mt-1">
                <DashboardStatusLabel
                  color="error"
                  label={t("houseDashboard.vaccineDay")}
                  icon={
                    <CircleIcon
                      fontSize="small"
                      className="text-tertiary-300"
                    />
                  }
                />
              </div>
              <div className="flex grow w-1/2 whitespace-nowrap pl-4 mt-1">
                <DashboardStatusLabel
                  color="warning"
                  label={t("houseDashboard.feedChange")}
                  icon={
                    <CircleIcon fontSize="small" className="text-warning" />
                  }
                />
              </div>
            </div>
          </div>

          <div className="mt-8">
            <DashboardInfoCard
              title={t("houseDashboard.dailyLogBlockHeading")}
              // subTitle={t('houseDashboard.dailyLogDetail')}
              icon={<BookSVG />}
              onPress={() => {
                navigate("/daily-log", {
                  state: { selectedFarm: house?.farmId, houseId: house?.id },
                });
              }}
              infoComponent={
                <div className="flex gap-1 items-center">
                  <FireSVG />
                  <p className="text-tertiary text-sm">
                    {t("houseDashboard.logstreak").replace("-", noLogDateCount)}
                  </p>
                </div>
              }
            />
          </div>
          <div className="mt-4">
            <DashboardInfoCard
              title={t("houseDashboard.moveOutPigsHeading")}
              // subTitle={t('houseDashboard.moveOutPigsDetail')}
              icon={<AirlineSVG />}
              infoComponent={
                <div className="flex gap-1 items-center">
                  <DollarCoin />
                  <p className="text-tertiary text-sm pl-1">
                    {t("houseDashboard.todayPrice", {
                      price: thousandComma(20000),
                    })}
                  </p>
                </div>
              }
              onPress={() => navigate(`/house/${house?.id}/move-out-pig`)}
            />
          </div>
        </div>
      </div>
      <EditHouseNoticeModal
        editHouseNoticeModal={editHouseNoticeModal}
        handleEditModalClose={handleEditModalClose}
        isWithinMonth={isWithinMonth}
        handleEditHouseNavigate={handleEditHouseNavigate}
      />
    </div>
  );
}

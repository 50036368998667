import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Divider, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { tw } from "../../../../utils/tw";
import MenuBar from "../../../components/MenuBar/MenuBar";
import { TabPanelCustom } from "../../../components/TabPanelCustom/TabPanelCustom";
import { PersonalInfoPartnerResult } from "../types/personalInformation.type";
import CustomerInformation from "./InformationTabs/CustomerInformation";
import PersonalInformation from "./InformationTabs/PersonalInformation";
import RevenueInfoBasicSale from "./InformationTabs/RevenueInfoBasicPartner";
import RevenueInfoCorePartner from "./InformationTabs/RevenueInfoCorePartner";
import RevenueInfoBasicPartner from "./InformationTabs/RevenueInfoBasicPartner";
import { IPartnerDetailTable } from "../types/partnerDetailTable.type";

type Props = {};

const classes = {
  container: tw(`pt-16 w-full h-full relative`),
  header: tw(`text-[#000] font-bold text-3xl font-sans`),
  paddingTop: tw(`p-10`),
  flexItemCenter: tw(`flex items-center`),
};

const PartnerInformation = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const partnerDetail: IPartnerDetailTable = location.state;

  const { id } = useParams();

  const [tabIndex, setTabIndex] = useState<number>(0);
  const onChangeTabIndex = (
    event: React.SyntheticEvent<Element, Event>,
    value: number
  ) => {
    setTabIndex(value);
  };
  return (
    <MenuBar>
      <div className={classes.container}>
        <div className={classes.paddingTop}>
          <div className={classes.flexItemCenter}>
            <div onClick={() => navigate(-1)}>
              <ChevronLeftIcon fontSize="large" />
            </div>
            <span className={classes.header}>
              {partnerDetail.partnerId + " " + partnerDetail.partnerName}
            </span>
          </div>
          <Divider sx={{ paddingTop: "12px" }} />
          <div className="mt-4">
            <Tabs
              value={tabIndex}
              onChange={onChangeTabIndex}
              aria-label="farm-information-tab-control"
              TabIndicatorProps={{
                sx: {
                  "&.MuiTabs-indicator": {
                    transform: "unset",
                    width: "unset",
                    bgcolor: "#68C184",
                    height: "3px",
                  },
                },
              }}
              sx={{
                height: "36px",
                minHeight: "unset",
                "& .MuiTabs-scroller": {
                  "& .MuiTabs-flexContainer": {
                    alignItems: "end",
                    height: "100%",
                    display: "flex",
                    gap: "12px",
                    "& .MuiTab-root": {
                      padding: "6px 28px",
                      textTransform: "unset",
                      fontFamily: "IBM Plex Sans Thai",
                      fontWeight: 400,
                      fontSize: "1rem",
                      lineHeight: "20px",
                      height: "32px",
                      minHeight: "unset",
                      color: "#000000",
                      borderBottom: "3px solid #DDF3E4",
                      "& .Mui-selected": {
                        color: "#000000",
                      },
                    },
                  },
                },
              }}
            >
              <Tab label={"ข้อมูลส่วนตัว"} />
              <Tab label={"ข้อมูลรายได้"} />
              <Tab label={"ข้อมูลลูกค้า"} />
            </Tabs>
          </div>
          <TabPanelCustom
            value={tabIndex}
            index={0}
            prefix="personal-information"
          >
            <PersonalInformation partnerId={id} />
          </TabPanelCustom>
          <TabPanelCustom
            value={tabIndex}
            index={1}
            prefix="revenue-information"
          >
            {partnerDetail.type === "basic" ? (
              <RevenueInfoBasicPartner />
            ) : (
              <RevenueInfoCorePartner partnerId={id} />
            )}
          </TabPanelCustom>
          <TabPanelCustom
            value={tabIndex}
            index={2}
            prefix="customer-information"
          >
            {id && <CustomerInformation partnerId={id} />}
          </TabPanelCustom>
        </div>
      </div>
    </MenuBar>
  );
};

export default PartnerInformation;

import { Breakpoint, Dialog, DialogContent, IconButton } from "@mui/material";
import { ReactNode, memo } from "react";
import CrossIcon from "../../assets/svg/CrossIcon.svg";

type TAdminModalProps = {
  label: string;
  isOpen: boolean;
  size: Breakpoint;
  handleClose: (isSuccess: boolean) => void;
  children: ReactNode;
};
const AdminModal = ({
  children,
  isOpen,
  handleClose,
  label,
  size,
}: TAdminModalProps) => {
  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={size}
      onClose={() => handleClose(false)}
      aria-labelledby={`modal-title-${label}`}
      aria-describedby={`modal-description-${label}`}
      slotProps={{
        backdrop: {
          style: { backgroundColor: "#000000", opacity: "10%" },
          timeout: 500,
        },
      }}
    >
      <DialogContent>
        <div className="flex justify-between items-center text-center mb-4">
          <p className="text-lg font-bold">{label}</p>
          <IconButton onClick={() => handleClose(false)}>
            <CrossIcon />
          </IconButton>
        </div>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default memo(AdminModal);

const FooterPartnerSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={58} height={58} fill="none">
    <circle cx={28.769} cy={28.769} r={28.769} fill="#3777BC" />
    <path
      fill="#fff"
      d="M27.8 19.398a1.2 1.2 0 1 0 0 2.4h2.4a1.2 1.2 0 1 0 0-2.4h-2.4Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M21.8 22.998a2.4 2.4 0 0 1 2.4-2.4 3.6 3.6 0 0 0 3.6 3.6h2.4a3.6 3.6 0 0 0 3.6-3.6 2.4 2.4 0 0 1 2.4 2.4v13.2a2.4 2.4 0 0 1-2.4 2.4h-9.6a2.4 2.4 0 0 1-2.4-2.4v-13.2Zm3.6 4.8a1.2 1.2 0 1 0 0 2.4h.012a1.2 1.2 0 1 0 0-2.4H25.4Zm3.6 0a1.2 1.2 0 1 0 0 2.4h3.6a1.2 1.2 0 1 0 0-2.4H29Zm-3.6 4.8a1.2 1.2 0 1 0 0 2.4h.012a1.2 1.2 0 1 0 0-2.4H25.4Zm3.6 0a1.2 1.2 0 1 0 0 2.4h3.6a1.2 1.2 0 1 0 0-2.4H29Z"
      clipRule="evenodd"
    />
  </svg>
);
export default FooterPartnerSvg;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ShowDialogPayload {
  title: string;
  text: string;
  cancelText: string;
  confirmText: string;
  onCancel: () => void;
  onSubmit: () => void;
  isFullButtonStyle?: boolean;
  direction?: "flex-row" | "flex-col" | "flex-col-reverse" | "flex-row-reverse";
}

interface AlertState extends ShowDialogPayload {
  isVisible: boolean;
}

const initialState: AlertState = {
  isVisible: false,
  title: "",
  text: "",
  cancelText: "",
  confirmText: "",
  onCancel: () => {},
  onSubmit: () => {},
  isFullButtonStyle: false,
  direction: "flex-col",
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    showDialog: (state, action: PayloadAction<ShowDialogPayload>) => {
      const {
        title,
        text,
        cancelText,
        confirmText,
        isFullButtonStyle,
        onCancel,
        onSubmit,
        direction,
      } = action.payload;
      state.isVisible = true;
      state.title = title;
      state.text = text;
      state.cancelText = cancelText;
      state.confirmText = confirmText;
      state.onCancel = onCancel;
      state.onSubmit = onSubmit;
      state.isFullButtonStyle = isFullButtonStyle;
      state.direction = direction;
    },
    hide: (state) => {
      state.isVisible = false;
    },
  },
});

export const alertAction = alertSlice.actions;

export default alertSlice;

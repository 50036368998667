import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import AppBarFS from "../../components/app-bar/AppBar";
import * as echarts from "echarts";
import {
  Dropdown,
  TDropdownValue,
} from "../../admin/pages/group-area/views/Dropdown";
import { IconButton, Tab, Tabs } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import FilterListIcon from "@mui/icons-material/FilterList";
import PaymentsIcon from "@mui/icons-material/Payments";
import ShowDetails from "./views/ShowDetails";
import { useTranslation } from "react-i18next";
import ReactECharts from "echarts-for-react";
import { EChartsOption } from "echarts";
import { getCommaNumber } from "../../utils/number";
import ShowIncome from "./views/ShowIncome";
import ChartSquareBarSvg from "../../assets/svg/ChartSquareBarSvg";
import { useFinancialOverview } from "./controller";
import { get } from "lodash";
import dayjs from "dayjs";
import { defaultFormPartnerLanding } from "../stock/defaultFormPartnerLanding";
import FilterModal from "./views/FilterModal";
import { defaultFormFilterModalFinancialOverview } from "./defaultFormFinancialOverview";

const FinancialOverview = () => {
  const {
    GetProfilePartner,
    formPartnerRevenue,
    setFormPartnerRevenue,
    onChangeFormPartnerRevenue,
    formPartnerRevenueFilterModal,
    setFormPartnerRevenueFilterModal,
    onChangeFormPartnerRevenueFilterModal,
    SummaryRevenue,
    ListRevenue,
    BarChartInfo,
    tabIndex,
    onChangeTab,
  } = useFinancialOverview();

  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);

  const onOpenFilterModal = useCallback(() => setIsOpenFilterModal(true), []);
  const onHideFilterModal = useCallback(() => setIsOpenFilterModal(false), []);

  const getThaiYears = (
    currentYear: number,
    range: number
  ): { label: string; value: string }[] => {
    const thaiYears: { label: string; value: string }[] = [];

    for (let i = 0; i <= range; i++) {
      const yearAD = currentYear - i;
      const yearThai = yearAD + 543;
      thaiYears.push({
        label: yearThai.toString(),
        value: yearAD.toString(),
      });
    }

    return thaiYears;
  };

  const dataArrayBar = Object.values(BarChartInfo?.summary || {});

  const { t } = useTranslation();

  const [selectYear, setSelectYear] = useState<TDropdownValue[]>([
    {
      label: (dayjs().year() + 543).toString(),
      value: dayjs().year().toString(),
    },
  ]);

  let navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate("/partner");
  }, []);

  const isDonutDataEmpty =
    get(SummaryRevenue?.revenue, ["onboard"], 0) === 0 &&
    get(SummaryRevenue?.revenue, ["orderFeed"], 0) === 0 &&
    get(SummaryRevenue?.revenue, ["debtCollection"], 0) === 0;

  const seriesDonutData = [
    ...(get(SummaryRevenue?.revenue, ["onboard"], 0) > 0
      ? [
          {
            value: get(SummaryRevenue?.revenue, ["onboard"], 0),
            name: "ลูกค้าใหม่",
            itemStyle: {
              color: "#68C184",
              shadowColor: "rgba(104, 193, 132, 0.7)",
            },
            label: {
              borderColor: "#68C184",
              shadowColor: "rgba(104, 193, 132, 0.7)",
            },
          },
        ]
      : []),
    ...(get(SummaryRevenue?.revenue, ["orderFeed"], 0) > 0
      ? [
          {
            value: get(SummaryRevenue?.revenue, ["orderFeed"], 0),
            name: "ยอดขาย",
            itemStyle: {
              color: "#F79836",
              shadowColor: "rgba(247, 152, 54, 0.7)",
            },
            label: {
              borderColor: "#F79836",
              shadowColor: "rgba(247, 152, 54, 0.7)",
            },
          },
        ]
      : []),
    ...(get(SummaryRevenue?.revenue, ["debtCollection"], 0) > 0
      ? [
          {
            value: get(SummaryRevenue?.revenue, ["debtCollection"], 0),
            name: "เก็บหนี้",
            itemStyle: {
              color: "#5572EB",
              shadowColor: "rgba(85, 114, 235, 0.7)",
            },
            label: {
              borderColor: "#5572EB",
              shadowColor: "rgba(85, 114, 235, 0.7)",
            },
          },
        ]
      : []),
  ];

  const legendDonutData = seriesDonutData.map((item) => item.name);

  const itemPadDonut = seriesDonutData.length === 1 ? 0 : 1;

  const optionDonut: EChartsOption = {
    legend: {
      bottom: "2.5%",
      left: "center",
      data: legendDonutData,
      textStyle: {
        fontSize: 14,
        color: "#000000",
      },
      itemGap: 20,
      icon: "circle",
    },
    series: [
      {
        type: "pie",
        radius: ["35%", "50%"],
        center: ["50%", "50%"],
        padAngle: itemPadDonut,
        label: {
          formatter: (params: any) => {
            return isDonutDataEmpty
              ? ""
              : `{c|${getCommaNumber(+params.value)}}`;
          },
          position: isDonutDataEmpty ? "center" : "outside",
          backgroundColor: isDonutDataEmpty ? "transparent" : "#FFFFFF",
          borderWidth: isDonutDataEmpty ? 0 : 2,
          borderRadius: isDonutDataEmpty ? 0 : 10,
          padding: isDonutDataEmpty ? 0 : [8, 12],
          shadowBlur: isDonutDataEmpty ? 0 : 2,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          rich: {
            c: {
              color: "#000000",
              fontSize: 12,
              fontWeight: "bold",
              lineHeight: 20,
            },
          },
        },
        labelLine: {
          show: !isDonutDataEmpty,
          length: 20,
          length2: 15,
          smooth: true,
        },
        itemStyle: {
          color: isDonutDataEmpty ? "#D3D3D3" : undefined,
          borderRadius: 5,
          borderWidth: 2,
          shadowBlur: isDonutDataEmpty ? 0 : 20,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: isDonutDataEmpty ? 0 : 30,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowColor: isDonutDataEmpty
              ? "transparent"
              : "rgba(0, 0, 0, 0.5)",
          },
        },
        data: seriesDonutData,
      },
    ],
  };

  const optionBar = (props: { dataArrayBar?: number[] }): EChartsOption => {
    const { dataArrayBar } = props;

    return {
      grid: {
        left: "10%",
        right: "12px",
      },
      xAxis: {
        type: "category",
        data: [
          "ม.ค.",
          "ก.พ.",
          "มี.ค.",
          "เม.ย.",
          "พ.ค.",
          "มิ.ย.",
          "ก.ค.",
          "ส.ค.",
          "ก.ย.",
          "ต.ค.",
          "พ.ย.",
          "ธ.ค.",
        ],
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          fontSize: "10px",
          fontFamily: "IBM Plex Sans Thai",
          color: "#979797",
          fontWeight: "normal",
          rotate: 45,
          interval: 0,
          hideOverlap: false,
        },
      },
      yAxis: {
        type: "value",
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value) => {
            if (value < 1000) {
              return `{c| ${value}}`;
            }
            if (value < 1000000) {
              return `{c| ${value / 1000}k}`;
            }
            return `{c| ${value / 1000000}m}`;
          },
          rich: {
            c: {
              fontFamily: "IBM Plex Sans Thai",
              fontSize: "12px",
              color: "#979797",
              fontWeight: "normal",
            },
          },
        },
      },
      tooltip: {
        trigger: "axis",
        backgroundColor: "transparent",
        borderWidth: 0,
        padding: 0,
        textStyle: {
          color: "#000",
          fontSize: 14,
        },
        formatter: (params: any) => {
          if (params[0] && params[0].value != null) {
            const value = getCommaNumber(params[0].value as number, true);
            const xPos = params[0].axisValueLabel;

            const positionStyle = `
                        left: calc(${xPos}px - 50%);
                        transform: translateX(-50%);
                    `;

            return `
                        <div style="
                            background-color: #FFFFFF;
                            padding: 10px 15px;
                            border: 1px solid #E0E0E0;
                            border-radius: 8px;
                            box-shadow: none; /* Ensure no shadow */
                            position: absolute; /* Use absolute positioning */
                            ${positionStyle}
                        ">
                            ${value}
                            <div style="
                                position: absolute;
                                bottom: -6px;
                                left: 50%;
                                transform: translateX(-50%);
                                width: 0;
                                height: 0;
                                border-left: 6px solid transparent;
                                border-right: 6px solid transparent;
                                border-top: 6px solid #E0E0E0;
                            "></div>
                        </div>
                    `;
          } else {
            return "";
          }
        },
        position: function (pos, params, dom, rect, size) {
          const obj: Record<string, number> = { top: pos[1] - 50 };
          obj["left"] = pos[0] - size.contentSize[0] / 2;
          return obj;
        },
        axisPointer: {
          type: "shadow",
          shadowStyle: {
            color: "transparent",
          },
        },
      },
      series: [
        {
          data: dataArrayBar,
          type: "bar",
          showBackground: true,
          backgroundStyle: {
            color: "#EDF2F7",
            borderRadius: 42.23,
          },
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#3777BC" },
              { offset: 0.3886, color: "#68C184" },
              { offset: 0.6698, color: "#77F2A1" },
              { offset: 0.9817, color: "rgba(119, 242, 161, 0)" },
            ]),
            borderRadius: 42.23,
          },
          barCategoryGap: "50%",
        },
      ],
    };
  };

  useEffect(() => {
    if (selectYear) {
      onChangeFormPartnerRevenue("year")(selectYear);
    }
  }, [selectYear]);

  useEffect(() => {
    if (tabIndex) {
      setSelectYear([
        {
          label: (dayjs().year() + 543).toString(),
          value: dayjs().year().toString(),
        },
      ]);
      setFormPartnerRevenue(defaultFormPartnerLanding);
      setFormPartnerRevenueFilterModal(defaultFormFilterModalFinancialOverview);
    }
  }, [tabIndex]);

  return (
    <div className="w-full h-full">
      <div className="h-[120px] flex items-center">
        <AppBarFS title={"รายละเอียดการเงิน"} onIconPress={handleBack} />
      </div>
      <div className="px-4 pb-[22px]">
        <Tabs
          value={tabIndex}
          onChange={onChangeTab}
          variant="scrollable"
          aria-label="sale-order-tab-control"
          scrollButtons={false}
          TabIndicatorProps={{
            sx: {
              "&.MuiTabs-indicator": {
                transform: "unset",
                width: "unset",
                bgcolor: "transparent",
                height: "3px",
              },
            },
          }}
          sx={{
            "& .MuiTabs-scroller": {
              "& .MuiTabs-flexContainer": {
                alignItems: "end",
                height: "100%",
                display: "flex",
                gap: "10px",
                "& .MuiTab-root": {
                  padding: "8px 14px !important",
                  textTransform: "unset",
                  fontFamily: "IBM Plex Sans Thai",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "16px",
                  height: "32px",
                  minHeight: "unset",
                  color: "#68C184",
                  bgcolor: "#FFFFFF",
                  borderRadius: "20px",
                  transition: "background-color 0.3s ease, color 0.3s ease",
                  "&.Mui-selected": {
                    bgcolor: "#68C184 !important",
                    color: "#FFFFFF !important",
                  },
                  "&.Mui-selected:hover": {
                    bgcolor: "#68C184 !important",
                    color: "#FFFFFF !important",
                  },
                },
              },
            },
          }}
        >
          <Tab label="ทั้งหมด" />
          <Tab label="ลูกค้าใหม่" />
          {GetProfilePartner?.profile?.type === "core" && (
            <>
              <Tab label="ยอดขาย" />
              <Tab label="เก็บหนี้" />
            </>
          )}
        </Tabs>
      </div>
      {tabIndex === 0 && (
        <>
          <ShowIncome
            icon={<PersonIcon className="text-[#68C184] text-[22px]" />}
            title="รายได้ที่คาดว่าจะได้รับ"
            value={
              get(SummaryRevenue?.revenue, ["onboard"], 0) +
              get(SummaryRevenue?.revenue, ["orderFeed"], 0) +
              get(SummaryRevenue?.revenue, ["debtCollection"], 0)
            }
          />
          <div className="flex flex-col py-[22px]">
            <div className="flex flex-row items-center justify-between px-4">
              <div className="text-[#68C184] text-[16px] font-semibold">
                ปี {selectYear[0].label}
              </div>
              <div>
                <Dropdown
                  options={getThaiYears(dayjs().year(), 19)}
                  placeholder="ปี"
                  onChange={(value) => setSelectYear(value)}
                  renderValueArray={selectYear}
                  choseStyle2
                  containerDropdown="pb-0"
                />
              </div>
            </div>
            <ReactECharts option={optionDonut} />
          </div>
          <div className="flex flex-col gap-[22px] pb-[22px]">
            <div className="flex flex-row px-4 justify-between">
              <span className="font-semibold text-[18px] text-[#3777BC] flex flex-col justify-center">
                รายการ
              </span>
              <div className="h-full flex gap-2 items-center">
                <span className="text-secondary font-normal ml-1 text-[1rem] flex flex-col items-center">
                  กรองโดย
                </span>
                <IconButton
                  aria-label="filter"
                  sx={{
                    color: "#68C184",
                    border: "1px solid #68C184",
                    height: "28px",
                    width: "28px",
                    ":hover": {
                      color: "#FFFFFF",
                      border: "1px solid #68C184",
                      bgcolor: "#68C184",
                    },
                    ":active": {
                      color: "#FFFFFF",
                      border: "1px solid #68C184",
                      bgcolor: "#68C184",
                    },
                  }}
                  onClick={onOpenFilterModal}
                >
                  <FilterListIcon />
                </IconButton>
              </div>
            </div>
            <ShowDetails
              data={ListRevenue?.revenues}
              isDataNew={GetProfilePartner?.profile?.type === "basic"}
            />
          </div>
        </>
      )}
      {tabIndex === 1 && (
        <>
          <ShowIncome
            icon={<PersonIcon className="text-[#68C184] text-[22px]" />}
            title="ลูกค้าใหม่"
            value={get(SummaryRevenue?.revenue, ["onboard"], 0)}
          />
          <div className="flex flex-col py-[22px]">
            <div className="flex flex-row items-center justify-between px-4">
              <div className="text-[#68C184] text-[16px] font-semibold">
                ปี {selectYear[0].label}
              </div>
              <div>
                <Dropdown
                  options={getThaiYears(dayjs().year(), 19)}
                  placeholder="ปี"
                  onChange={(value) => setSelectYear(value)}
                  renderValueArray={selectYear}
                  choseStyle2
                  containerDropdown="pb-0"
                />
              </div>
            </div>
            <ReactECharts option={optionBar({ dataArrayBar })} />
          </div>
          <div className="flex flex-col gap-[22px] pb-[22px]">
            <div className="flex flex-row px-4 justify-between">
              <span className="font-semibold text-[18px] text-[#3777BC] flex flex-col justify-center">
                รายการ
              </span>
              <div className="h-full flex gap-2 items-center">
                <span className="text-secondary font-normal ml-1 text-[1rem] flex flex-col items-center">
                  กรองโดย
                </span>
                <IconButton
                  aria-label="filter"
                  sx={{
                    color: "#68C184",
                    border: "1px solid #68C184",
                    height: "28px",
                    width: "28px",
                    ":hover": {
                      color: "#FFFFFF",
                      border: "1px solid #68C184",
                      bgcolor: "#68C184",
                    },
                    ":active": {
                      color: "#FFFFFF",
                      border: "1px solid #68C184",
                      bgcolor: "#68C184",
                    },
                  }}
                  onClick={onOpenFilterModal}
                >
                  <FilterListIcon />
                </IconButton>
              </div>
            </div>
            <ShowDetails data={ListRevenue?.revenues} isDataNew />
          </div>
        </>
      )}
      {tabIndex === 2 && (
        <>
          <ShowIncome
            icon={<ChartSquareBarSvg />}
            title="ยอดขาย"
            value={get(SummaryRevenue?.revenue, ["orderFeed"], 0)}
          />
          <div className="flex flex-col py-[22px]">
            <div className="flex flex-row items-center justify-between px-4">
              <div className="text-[#68C184] text-[16px] font-semibold">
                ปี {selectYear[0].label}
              </div>
              <div>
                <Dropdown
                  options={getThaiYears(dayjs().year(), 19)}
                  placeholder="ปี"
                  onChange={(value) => setSelectYear(value)}
                  renderValueArray={selectYear}
                  choseStyle2
                  containerDropdown="pb-0"
                />
              </div>
            </div>
            <ReactECharts option={optionBar({ dataArrayBar })} />
          </div>
          <div className="flex flex-col gap-[22px] pb-[22px]">
            <div className="flex flex-row px-4 justify-between">
              <span className="font-semibold text-[18px] text-[#3777BC] flex flex-col justify-center">
                รายการ
              </span>
              <div className="h-full flex gap-2 items-center">
                <span className="text-secondary font-normal ml-1 text-[1rem] flex flex-col items-center">
                  กรองโดย
                </span>
                <IconButton
                  aria-label="filter"
                  sx={{
                    color: "#68C184",
                    border: "1px solid #68C184",
                    height: "28px",
                    width: "28px",
                    ":hover": {
                      color: "#FFFFFF",
                      border: "1px solid #68C184",
                      bgcolor: "#68C184",
                    },
                    ":active": {
                      color: "#FFFFFF",
                      border: "1px solid #68C184",
                      bgcolor: "#68C184",
                    },
                  }}
                  onClick={onOpenFilterModal}
                >
                  <FilterListIcon />
                </IconButton>
              </div>
            </div>
            <ShowDetails data={ListRevenue?.revenues} isDataSales />
          </div>
        </>
      )}
      {tabIndex === 3 && (
        <>
          <ShowIncome
            icon={<PaymentsIcon className="rotate-180 text-[#68C184]" />}
            title="เก็บหนี้"
            value={get(SummaryRevenue?.revenue, ["debtCollection"], 0)}
          />
          <div className="flex flex-col py-[22px]">
            <div className="flex flex-row items-center justify-between px-4">
              <div className="text-[#68C184] text-[16px] font-semibold">
                ปี {selectYear[0].label}
              </div>
              <div>
                <Dropdown
                  options={getThaiYears(dayjs().year(), 19)}
                  placeholder="ปี"
                  onChange={(value) => setSelectYear(value)}
                  renderValueArray={selectYear}
                  choseStyle2
                  containerDropdown="pb-0"
                />
              </div>
            </div>
            <ReactECharts option={optionBar({ dataArrayBar })} />
          </div>
          <div className="flex flex-col gap-[22px] pb-[22px]">
            <div className="flex flex-row px-4 justify-between">
              <span className="font-semibold text-[18px] text-[#3777BC] flex flex-col justify-center">
                รายการ
              </span>
              <div className="h-full flex gap-2 items-center">
                <span className="text-secondary font-normal ml-1 text-[1rem] flex flex-col items-center">
                  กรองโดย
                </span>
                <IconButton
                  aria-label="filter"
                  sx={{
                    color: "#68C184",
                    border: "1px solid #68C184",
                    height: "28px",
                    width: "28px",
                    ":hover": {
                      color: "#FFFFFF",
                      border: "1px solid #68C184",
                      bgcolor: "#68C184",
                    },
                    ":active": {
                      color: "#FFFFFF",
                      border: "1px solid #68C184",
                      bgcolor: "#68C184",
                    },
                  }}
                  onClick={onOpenFilterModal}
                >
                  <FilterListIcon />
                </IconButton>
              </div>
            </div>
            <ShowDetails data={ListRevenue?.revenues} isDataIncome />
          </div>
        </>
      )}
      <FilterModal
        isOpen={isOpenFilterModal}
        onClose={onHideFilterModal}
        title="กรองข้อมูล"
        form={formPartnerRevenueFilterModal}
        setForm={setFormPartnerRevenueFilterModal}
        onChangeForm={onChangeFormPartnerRevenueFilterModal}
      />
    </div>
  );
};

export default FinancialOverview;

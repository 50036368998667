import { Divider, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import MenuBar from "../../components/MenuBar/MenuBar";
import {
  TabPanelCustom,
  a11yProps,
} from "../../components/TabPanelCustom/TabPanelCustom";
import ApprovedOrderPanel from "./ApprovedOrderPanel/ApprovedOrderPanel";
import CreditHoldOrderPanel from "./CreditHoldOrderPanel/CreditHoldOrderPanel";
import OrderHistoryPanel from "./OrderHistoryPanel/OrderHistoryPanel";
import { useCountSaleOrder } from "./Provider/SaleOrderProvider";
import UpcomingOrderPanel from "./UpcomingOrderPanel/UpcomingOrderPanel";

function SaleOrder() {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [countCreditHold, setCountCreditHold] = useState(0);
  const onChangeTabIndex = (
    event: React.SyntheticEvent<Element, Event>,
    value: number
  ) => {
    setTabIndex(value);
  };
  const { countOrder } = useCountSaleOrder();
  return (
    <MenuBar>
      <div className="pt-16 w-full h-full relative">
        <div className="p-10">
          <span className="text-[#000] font-bold text-3xl">Sale Order</span>
          <Divider sx={{ paddingTop: "12px" }} />
          <div className="pt-6">
            <Tabs
              value={tabIndex}
              onChange={onChangeTabIndex}
              aria-label="sale-order-tab-control"
              TabIndicatorProps={{
                sx: {
                  "&.MuiTabs-indicator": {
                    transform: "unset",
                    width: "unset",
                    bgcolor: "#68C184",
                    height: "3px",
                  },
                },
              }}
              sx={{
                height: "36px",
                minHeight: "unset",
                "& .MuiTabs-scroller": {
                  "& .MuiTabs-flexContainer": {
                    alignItems: "end",
                    height: "100%",
                    display: "flex",
                    gap: "12px",
                    "& .MuiTab-root": {
                      padding: "6px 28px",
                      textTransform: "unset",
                      fontFamily: "IBM Plex Sans Thai",
                      fontWeight: 400,
                      fontSize: "1rem",
                      lineHeight: "20px",
                      height: "32px",
                      minHeight: "unset",
                      color: "#000000",
                      borderBottom: "3px solid #DDF3E4",
                      "& .Mui-selected": {
                        color: "#000000",
                      },
                    },
                  },
                },
              }}
            >
              <Tab
                label={`ออเดอร์ติดเครดิต (${countCreditHold ?? 0})`}
                {...a11yProps("sale-order-order", 0)}
              />
              <Tab
                label={`ออเดอร์ใหม่ (${countOrder.countNewOrder ?? 0})`}
                {...a11yProps("sale-order-order", 1)}
              />
              <Tab
                label={`ออเดอร์รอจัดส่ง (${
                  countOrder.countWaitingForShippingOrder ?? 0
                })`}
                {...a11yProps("sale-order-order", 2)}
              />
              <Tab
                label={`ประวัติออเดอร์ (${countOrder.countOrderHistory ?? 0})`}
                {...a11yProps("sale-order-order", 3)}
              />
            </Tabs>
          </div>
          <TabPanelCustom value={tabIndex} index={0} prefix="sale-order-order">
            <CreditHoldOrderPanel
              setCountCreditHoldOrder={(value) => {
                setCountCreditHold(value);
              }}
            />
          </TabPanelCustom>
          <TabPanelCustom value={tabIndex} index={1} prefix="sale-order-order">
            <UpcomingOrderPanel />
          </TabPanelCustom>
          <TabPanelCustom value={tabIndex} index={2} prefix="sale-order-order">
            <ApprovedOrderPanel />
          </TabPanelCustom>
          <TabPanelCustom value={tabIndex} index={3} prefix="sale-order-order">
            <OrderHistoryPanel />
          </TabPanelCustom>
        </div>
      </div>
    </MenuBar>
  );
}

export default SaleOrder;

import FilterListIcon from "@mui/icons-material/FilterList";
import { IconButton } from "@mui/material";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import FilterModal from "../../../../farm-past-performance/FilterModal";
import { Order } from "../type";

type FilterOrderAndOrderTimeProps = {
  handleOnResetFilter: () => void;
  handleOnSubmitFilter: (
    startDate: string,
    endDate: string,
    orderBy: Order
  ) => void;
  title: string;
};

const FilterOrderAndOrderTime = ({
  handleOnResetFilter,
  handleOnSubmitFilter,
  title,
}: FilterOrderAndOrderTimeProps) => {
  const { t } = useTranslation();
  const [isOpenFilterModal, setIsOpenFilterModal] = useState<boolean>(false);

  return (
    <div className="flex justify-between items-center mb-2 mx-4">
      <span className="text-tertiary font-semibold ml-1 capitalize text-[18px]">
        {title}
      </span>
      <div className="h-full flex gap-2 items-center">
        <span className="text-secondary font-normal ml-1 text-[1rem] items-center">
          กรองโดย
        </span>
        <IconButton
          aria-label="filter"
          sx={{
            color: "#68C184",
            bgcolor: "#FFF",
            border: "1px solid #68C184",
            ":hover": {
              color: "#FFFFFF",
              border: "1px solid #68C184",
              bgcolor: "#68C184",
            },
            ":active": {
              color: "#FFFFFF",
              border: "1px solid #68C184",
              bgcolor: "#68C184",
            },
          }}
          onClick={() => setIsOpenFilterModal(true)}
        >
          <FilterListIcon />
        </IconButton>
      </div>
      <FilterModal
        title="กรองโดย"
        labelSelectFilter="วันที่สั่งซื้อ"
        labelDateRangeFilter="ช่วงวันที่สั่งซื้อ"
        isOpen={isOpenFilterModal}
        onClose={() => setIsOpenFilterModal(false)}
        t={t}
        onReset={() => {
          handleOnResetFilter();
        }}
        onSubmit={handleOnSubmitFilter}
      />
    </div>
  );
};

export default FilterOrderAndOrderTime;

import {
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
} from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { tw } from "../../../../../utils/tw";
import { Input } from "../../../../../admin/pages/group-area/views/Input";
import ButtonFillCustom from "../../../../../admin/components/Button/ButtonFillCustom";
import CrossIcon from "../../../../../admin/assets/svg/CrossIcon.svg";
import FilterListIcon from "@mui/icons-material/FilterList";
import RadioButton from "../../../../../components/common/RadioButton";
import { getCommaNumber } from "../../../../../utils/number";
import { cloneDeep, get, set } from "lodash";
import {
  Dropdown,
  TDropdownValue,
} from "../../../../../admin/pages/group-area/views/Dropdown";
import DatePickerFS from "../../../../../components/common/DatePicker";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  completeAssignment,
  notifyAssignment,
} from "../../../../../services/Invoice.service";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../redux/store";
import { loadingActions } from "../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../utils/getErrorMessage";
import { alertAction } from "../../../../../redux/slices/alertSlice";
import TextAreaCustom from "../../../../../admin/components/Input/TextAreaCustom";
import * as Y from "yup";
import useFormValidator from "../../../../../hooks/useFormValidator.hook";

interface IInvoiceTrackingFailModal {
  isOpen: boolean;
  onCloseFn: () => void;
  selectedId: string;
  onCloseDetails: () => void;
}

const errorSchema = Y.object({
  reason: Y.string()
    .required("กรุณากรอกเหตุผลที่ติดตามไม่สำเร็จ")
    .max(2000, "สามารถกรอกได้ไม่เกิน 2000 ตัวอักษร"),
});

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  container: tw(
    `w-[100vw] m-auto [&_.MuiDialog-paperScrollPaper]:rounded-[40px] [&_.MuiButtonBase-root.Mui-selected]:!bg-[#FFFFFF]  [&_.MuiButtonBase-root.Mui-selected]:!text-[#000000] [&_.MuiDialog-paperScrollPaper]:!m-0 max-w-[313px]`
  ),
  titleContainer: tw(`flex items-center w-full justify-end`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  row: tw(
    `w-full flex flex-col md:flex-row item-center justify-between gap-x-3`
  ),
  button: tw(`!h-[59px] w-full !rounded-[50px] !text-[16px] !font-bold`),
  space: tw(`h-[12px]`),
};

const defaultForm = {
  reason: "",
};

const InvoiceTrackingFailModal = (props: IInvoiceTrackingFailModal) => {
  const { isOpen, onCloseFn, selectedId, onCloseDetails } = props;

  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();

  const [form, setForm] = useState(defaultForm);

  const onChangeForm = useCallback(
    (key: keyof typeof form) => (value: any) => {
      setForm((prev) => ({ ...prev, [key]: value }));
    },
    []
  );

  const completeMutation = useMutation({
    mutationFn: completeAssignment,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["list_accepted_assignment"],
      });
      setTimeout(() => {
        dispatch(loadingActions.hide());
        onClose();
        setTimeout(() => {
          dispatch(
            snakeActions.showMessage({
              message: "ยืนยันติดตามไม่สำเร็จ",
              type: "success",
            })
          );
        }, 500);
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const { onSubmit, errors, resetError } = useFormValidator({
    form: {
      ...form,
    },
    errorSchema,
    onValidateSuccess: () => {
      dispatch(
        loadingActions.show({
          message: "กำลังบันทึกข้อมูล...",
        })
      );
      completeMutation.mutate({
        assignmentId: selectedId,
        isComplete: false,
        reason: form.reason,
      });
    },
  });

  const onClose = useCallback(() => {
    onCloseFn();
    onCloseDetails();
    setTimeout(() => {
      resetError();
      setForm(defaultForm);
    }, 500);
  }, []);

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: "w-full" }}
    >
      <DialogContent className="w-[313px] flex flex-col gap-4">
        <div className={classes.titleContainer}>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </div>
        <div className="text-[#3777BC] text-[20px] font-semibold flex w-full justify-center">
          ติดตามไม่สำเร็จ
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex flex-row justify-between">
            <div className="text-[#3777BC] text-[12px] font-normal">เหตุผล</div>
            <div className="text-[#979797] text-[12px] font-normal">
              {form.reason.valueOf().length}/2000
            </div>
          </div>
          <TextAreaCustom
            value={form.reason}
            onChange={(e: any) => onChangeForm("reason")(e.target.value)}
            placeholder="เหตุผล"
            className="bg-[#F6FBFF] !p-[10px] !rounded-[20px] !border-[1px] !border-[#3777BC]"
            errormessage={errors.reason || ""}
          />
        </div>
        <ButtonFillCustom
          onClick={onSubmit}
          title="ยืนยัน"
          btnTextColor="#ffffff"
          btnBgColor="#68C184"
          className={classes.button}
        />
      </DialogContent>
    </Dialog>
  );
};

export default memo(InvoiceTrackingFailModal);

import { LoaderFunctionArgs } from "react-router-dom";
import { getPartnerIncomingStock } from "../../../services/partner/stock.service";
import { getPartnerHistoryStock } from "../../../services/partner/historyStock.service";

export async function historyStockLoader(loader: LoaderFunctionArgs) {
  const stockHistory = await getPartnerHistoryStock();
  return {
    stockHistory,
  };
}

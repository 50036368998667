export default function CreditFarmSvg() {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.19687 0.400391C1.87139 0.400391 0.796875 1.47491 0.796875 2.80039V17.2004C0.796875 18.5259 1.87139 19.6004 3.19687 19.6004H12.7969C14.1224 19.6004 15.1969 18.5259 15.1969 17.2004V6.89745C15.1969 6.26093 14.944 5.65048 14.4939 5.20039L10.3969 1.10333C9.94679 0.653247 9.33634 0.400391 8.69982 0.400391H3.19687ZM5.59687 12.4004C5.59687 11.7376 5.05962 11.2004 4.39687 11.2004C3.73413 11.2004 3.19687 11.7376 3.19687 12.4004V16.0004C3.19687 16.6631 3.73413 17.2004 4.39687 17.2004C5.05962 17.2004 5.59687 16.6631 5.59687 16.0004V12.4004ZM7.99687 8.80039C8.65962 8.80039 9.19687 9.33765 9.19687 10.0004V16.0004C9.19687 16.6631 8.65962 17.2004 7.99687 17.2004C7.33413 17.2004 6.79687 16.6631 6.79687 16.0004V10.0004C6.79687 9.33765 7.33413 8.80039 7.99687 8.80039ZM12.7969 7.60039C12.7969 6.93765 12.2596 6.40039 11.5969 6.40039C10.9341 6.40039 10.3969 6.93765 10.3969 7.60039V16.0004C10.3969 16.6631 10.9341 17.2004 11.5969 17.2004C12.2596 17.2004 12.7969 16.6631 12.7969 16.0004V7.60039Z"
        fill="#68C184"
      />
    </svg>
  );
}

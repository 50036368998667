import React, { useEffect } from "react";
import AdminModal from "../../../../../components/Modal/AdminModal";
import { FormProvider, useForm } from "react-hook-form";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import RHFTextAreaCustom from "../../../../../components/Input/RHFTextAreaCustom";
import { tw } from "../../../../../../utils/tw";
import { useDispatch } from "react-redux";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import { closeDebtNotAcceptDebt } from "../../services/notAcceptDebt.service";

export type TCloseDebtNotAcceptDeptForm = {
  detail: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  invoiceId: string;
  fetchData: () => void;
};

const classes = {
  flexCol: tw(`flex flex-col`),
  header: tw(`text-sm font-bold pb-2 mb-1`),
};

const CloseDebtNotAcceptModal = ({
  isOpen,
  onClose,
  invoiceId,
  fetchData,
}: Props) => {
  const dispatch = useDispatch();
  const methods = useForm<TCloseDebtNotAcceptDeptForm>({
    defaultValues: {
      detail: "",
    },
  });

  const handleSubmit = (value: TCloseDebtNotAcceptDeptForm) => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
            await closeDebtNotAcceptDebt(invoiceId, value);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "ปิดเคสสำเร็จ",
              })
            );
            fetchData();
            onClose();
          } catch (error) {
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  useEffect(() => {
    methods.reset();
  }, [isOpen]);
  return (
    <div>
      <AdminModal
        label={"ยืนยันการปิดเคส"}
        isOpen={isOpen}
        size={"xs"}
        handleClose={onClose}
        children={
          <FormProvider {...methods}>
            <div className={classes.flexCol}>
              <span className={tw(classes.header, "font-medium pb-0")}>
                รายละเอียด
              </span>
              <RHFTextAreaCustom placeholder="กรอก" controlName="detail" />
              <div className="w-full flex justify-end pt-[2px]">
                <span className="font-normal text-[12px] text-[#979797]">
                  (maximum 500 characters)
                </span>
              </div>
            </div>

            <div className="flex justify-end mt-4">
              <ButtonFillCustom
                title="บันทึก"
                onClick={methods.handleSubmit(handleSubmit)}
              />
            </div>
          </FormProvider>
        }
      ></AdminModal>
    </div>
  );
};

export default CloseDebtNotAcceptModal;

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackgrounImage from "../../assets/images/Login/BackGroungImage.png";
import AdjustmentsSvg from "../../assets/svg/AdjustmentsSvg";
import LogoutSvg from "../../assets/svg/LogoutSvg";
import OperatorSvg from "../../assets/svg/OperatorSvg";
import PencilSvg from "../../assets/svg/PencilSvg";
import AppBarFS from "../../components/app-bar/AppBar";
import { useAuth } from "../../components/common/ProtectedRoute/ProtectedRoute";
import {
  AUTH_TOKEN_KEY,
  AUTH_USERNAME,
  IS_SAVED,
  LOCK_OUT,
  NUMBER_OF_MISTAKE,
} from "../../const/app.constant";
import { alertAction } from "../../redux/slices/alertSlice";
import { AppDispatch } from "../../redux/store";
import { useAccountCenterController } from "./controller";
import EditAccountModal from "./views/EditAccountModal";

const AccountCenter = () => {
  const {
    isShowEditAccountModal,
    onShowEditAccountModal,
    onHideEditAccountModal,
    profileFarmer,
  } = useAccountCenterController();

  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const { logout } = useAuth();

  const onNavigate = useCallback(
    (path: string) => () => {
      navigate(path);
    },
    []
  );

  const onLogout = useCallback(() => {
    dispatch(
      alertAction.showDialog({
        isFullButtonStyle: true,
        title: "Are you sure?",
        text: "Are you sure you want to log out from this account?",
        cancelText: "Close",
        confirmText: "Yes",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          dispatch(alertAction.hide());
          localStorage.removeItem("firstTimeLogin");
          localStorage.removeItem(AUTH_TOKEN_KEY);
          localStorage.removeItem(AUTH_USERNAME);
          localStorage.removeItem("role");
          localStorage.removeItem(IS_SAVED);
          localStorage.removeItem(LOCK_OUT);
          localStorage.removeItem(NUMBER_OF_MISTAKE);
          logout();
        },
      })
    );
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${BackgrounImage})`,
        height: "auto !important",
        backgroundSize: "cover",
        backgroundColor: "#4882c8",
      }}
      className="!h-auto !min-h-screen"
    >
      <div className="h-[120px] flex items-center bg-[#FFFF] rounded-b-[40px]">
        <AppBarFS
          title={t("accountCenter.setting.header")}
          onIconPress={() => navigate(-1)}
        />
      </div>
      <div className="flex flex-col gap-6 px-4 pt-4">
        <div className="bg-[#FFFFFF] rounded-[20px] py-4 pl-[20px] pr-[10px]">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col">
              <div className="text-[16px] font-semibold text-[#000000]">
                Hi {profileFarmer?.userName || ""}!
              </div>
              <div className="text-[16px] font-semibold text-[#3777BC]">
                {profileFarmer?.name || ""} {profileFarmer?.surname || ""}
              </div>
            </div>
            <div className="w-[21px] h-[21px]" onClick={onShowEditAccountModal}>
              <PencilSvg />
            </div>
          </div>
        </div>
        <div className="bg-[#FFFFFF] rounded-[10px] flex flex-col">
          <div
            className="py-2 flex items-center"
            style={{
              boxShadow: "0px -1px 0px 0px #3777BC40 inset",
            }}
          >
            <div
              className="pl-[20px] pr-[10px] flex flex-row items-center justify-between w-full h-[40px]"
              onClick={onNavigate("/farms/account-center/support")}
            >
              <div className="text-[16px] font-medium text-[#3777BC] flex flex-row gap-4">
                <OperatorSvg /> Support
              </div>
              <KeyboardArrowRightIcon className="text-[#3777BC]" />
            </div>
          </div>
          <div
            className="py-2 flex items-center"
            style={{
              boxShadow: "0px -1px 0px 0px #3777BC40 inset",
            }}
          >
            <div
              className="pl-[20px] pr-[10px] flex flex-row items-center justify-between w-full h-[40px]"
              onClick={onNavigate("/farms/account-center/setting")}
            >
              <div className="text-[16px] font-medium text-[#3777BC] flex flex-row gap-4">
                <AdjustmentsSvg /> Setting
              </div>
              <KeyboardArrowRightIcon className="text-[#3777BC]" />
            </div>
          </div>
          <div
            className="py-2 flex items-center"
            style={{
              boxShadow: "0px -1px 0px 0px #3777BC40 inset",
            }}
          >
            <div
              className="pl-[20px] pr-[10px] flex flex-row items-center justify-center w-full h-[40px]"
              onClick={onLogout}
            >
              <div className="text-[16px] font-medium text-[#DC5B5B] flex flex-row gap-2">
                <LogoutSvg /> Log Out
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditAccountModal
        isOpen={isShowEditAccountModal}
        onClose={onHideEditAccountModal}
        profileFarmer={profileFarmer}
      />
    </div>
  );
};

export default AccountCenter;

import {
  IEditOrderForm,
  IGetCreditOrderIncoming,
} from "../../../types/EditOrderFeed";
import { IExportOrder } from "../../../types/ExportOrder.type";
import adminAPI from "../../AdminApiService";

export const getCreditUpcomingOrder = async (
  searchParams?: URLSearchParams
) => {
  try {
    const { data } = await adminAPI.get<IGetCreditOrderIncoming>(
      `/sale-order/upcoming-order?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const postEditOrder = async (
  payload: IEditOrderForm,
  orderFeedId?: string
) => {
  return adminAPI.post(
    "/sale-order/upcoming-order/" + orderFeedId + "/edit",
    payload
  );
};

export const getListExportCreditUpcomingOrder = async (ids: string[]) => {
  try {
    const { data } = await adminAPI.post<IExportOrder>(
      `/sale-order/upcoming-order/export`,
      { ids }
    );
    return data;
  } catch (error) {
    return null;
  }
};

import { Checkbox, TableBody, TableCell, TableRow } from "@mui/material";
import { Controller, Path, useFormContext } from "react-hook-form";
import { ICreditForm } from "../../../../../../types/admin/masterdata/creditForm";
import RHFTextInputCustom from "../../../../../components/Input/RHFTextInputCustom";
import DeleteFillGreen from "../../../../../assets/svg/DeleteFillGreen.svg";

export type AllowedFieldChecklistsName = Extract<
  Path<ICreditForm>,
  | "generalPersonalInformation"
  | "generalLegalInformation"
  | "generalFarmInformation"
  | "farmPerformance"
  | "farmFinancialStatusAndInstallment"
  | "applicationUsage"
>;

type TableCellHeaderCustomProps = {
  value: string;
  width: string;
};

export const TableCellHeaderCustom = ({
  value,
  width,
}: TableCellHeaderCustomProps) => {
  return (
    <>
      <TableCell
        sx={{
          height: "50px",
          justifyContent: "start",
          fontFamily: "IBM Plex Sans Thai",
          fontWeight: 700,
          fontSize: "14px",
          border: "0px",
          padding: "0px 16px",
          width: width,
        }}
        align="left"
      >
        {value}
      </TableCell>
    </>
  );
};

type TableCellCustomProps = {
  name: AllowedFieldChecklistsName;
  index: number;
  removeCell: (value: number) => void;
  viewAction: boolean;
};

export const TableCellCustom = ({
  name,
  index,
  removeCell,
  viewAction,
}: TableCellCustomProps) => {
  const {
    getValues,
    formState: { errors },
  } = useFormContext<ICreditForm>();

  return (
    <>
      <TableBody>
        <TableRow
          sx={{
            "&:last-child td": {
              borderLeft: "1px solid #E1E1E1",
              borderRight: "1px solid #E1E1E1",
              padding: "10px 12px !important",
            },
            "& td:first-of-type": {
              borderLeft: "none",
            },
            "& td:last-of-type": {
              borderRight: "none",
            },
          }}
        >
          <TableCell align="center">
            {getValues(`${name}.checklists`)[index].isActivatable && (
              <Controller
                name={
                  `${name}.checklists[${index}].isActive` as Path<ICreditForm>
                }
                render={({ field }) => (
                  <Checkbox
                    disabled={viewAction}
                    checked={getValues(`${name}.checklists`)[index].isActive}
                    {...field}
                    color="primary"
                    sx={{
                      borderWidth: "1px",
                      color: "#C9C9C9",
                      "&.Mui-checked": {
                        color: "#68C184",
                      },
                    }}
                  />
                )}
              />
            )}
          </TableCell>
          <TableCell align="left">
            {getValues(`${name}.checklists`)[index].isDeletable &&
            !viewAction ? (
              <RHFTextInputCustom
                controlName={`${name}.checklists[${index}].general`}
                placeholder="กรอก"
              />
            ) : (
              getValues(`${name}.checklists.${index}.general`)
            )}
          </TableCell>
          <TableCell align="left">
            {viewAction ? (
              getValues(`${name}.checklists.${index}.weight`)
            ) : (
              <RHFTextInputCustom
                controlName={`${name}.checklists[${index}].weight`}
                placeholder="กรอก"
                type="number"
                error={Boolean(errors[name]?.checklists?.[index]?.weight)}
                helperText={errors[name]?.checklists?.[index]?.weight?.message}
              />
            )}
          </TableCell>
          {/* 1 */}
          <TableCell align="left">
            {viewAction ? (
              getValues(`${name}.checklists.${index}.options.0.key`)
            ) : (
              <RHFTextInputCustom
                controlName={`${name}.checklists[${index}].options[0].key`}
                placeholder="กรอก"
              />
            )}
          </TableCell>
          {/* 2 */}
          <TableCell align="left">
            {viewAction ? (
              getValues(`${name}.checklists.${index}.options.1.key`)
            ) : (
              <RHFTextInputCustom
                controlName={`${name}.checklists[${index}].options[1].key`}
                placeholder="กรอก"
              />
            )}
          </TableCell>
          {/* 3 */}
          <TableCell align="left">
            {viewAction ? (
              getValues(`${name}.checklists.${index}.options.2.key`)
            ) : (
              <RHFTextInputCustom
                controlName={`${name}.checklists[${index}].options[2].key`}
                placeholder="กรอก"
              />
            )}
          </TableCell>
          {/* 4 */}
          <TableCell align="left">
            {viewAction ? (
              getValues(`${name}.checklists.${index}.options.3.key`)
            ) : (
              <RHFTextInputCustom
                controlName={`${name}.checklists[${index}].options[3].key`}
                placeholder="กรอก"
              />
            )}
          </TableCell>
          {/* 5 */}
          <TableCell align="left">
            {viewAction ? (
              getValues(`${name}.checklists.${index}.options.4.key`)
            ) : (
              <RHFTextInputCustom
                controlName={`${name}.checklists[${index}].options[4].key`}
                placeholder="กรอก"
              />
            )}
          </TableCell>

          <TableCell align="left">
            {!viewAction &&
              getValues(`${name}.checklists`)[index].isDeletable && (
                <div onClick={() => removeCell(index)}>
                  <DeleteFillGreen />
                </div>
              )}
          </TableCell>
        </TableRow>
      </TableBody>
    </>
  );
};

import {
  FormControl,
  FormHelperText,
  TextareaAutosize,
  TextareaAutosizeProps,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

interface ITextAreaCustomProps extends TextareaAutosizeProps {
  errormessage?: string;
  controlName: string;
}

function TextAreaCustom({ controlName, ...rest }: ITextAreaCustomProps) {
  const { errormessage } = rest;
  const { control } = useFormContext();
  return (
    <Controller
      name={controlName || ""}
      control={control}
      render={({ field }) => (
        <FormControl error={!!errormessage}>
          <TextareaAutosize
            {...rest}
            {...field}
            autoComplete="off"
            style={{
              border: "1px solid #C9C9C9",
              borderRadius: "6px",
              height: "120px",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 1px",
              resize: "none",
              padding: "8px",
              fontWeight: 400,
              fontSize: "14px",
              fontFamily: "IBM Plex Sans Thai",
              ...rest?.style,
            }}
          />
          {!!errormessage && <FormHelperText>{errormessage}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}

export default TextAreaCustom;

import React, { useEffect, useState } from "react";
import CashSvg from "../../../../assets/svg/Cash.svg";
import WalletSvg from "../../../../assets/svg/Wallet.svg";
import PersonSvg from "../../../../assets/svg/Person.svg";
import DocumentWithBgSvg from "../../../../assets/svg/DocumentWithBg.svg";
import WalletBgSvg from "../../../../assets/svg/WalletWithBg.svg";
import { ChipCount } from "../../../sale-order/CreditHoldOrderPanel/CreditHoldOrderPanel";
import AllRevenue from "../../components/RevenueTab";
import RevenueTab from "../../components/RevenueTab";
import {
  getCountRevenue,
  getRevenueSummary,
} from "../../services/revenue.service";
import { thousandComma } from "../../../../../utils/common/string";
import {
  IGetCountRevenue,
  IGetRevenueSummary,
} from "../../types/revenueTable.type";

type Props = {
  partnerId?: string;
};

const RevenueInfoCorePartner = ({ partnerId }: Props) => {
  const [toggleState, setToggleState] = useState(1);
  const [revenueSummary, setRevenueSummary] =
    useState<IGetRevenueSummary | null>();
  const [countRevenue, setCountRevenue] = useState<IGetCountRevenue | null>();

  const toggleTab = (index: number) => {
    setToggleState(index);
  };

  const handleFetchRevenueSummary = async () => {
    const data = await getRevenueSummary(partnerId || "");
    setRevenueSummary(data);
  };
  const handleFetchCountRevenue = async () => {
    const data = await getCountRevenue(partnerId || "");
    setCountRevenue(data);
  };

  useEffect(() => {
    handleFetchRevenueSummary();
    handleFetchCountRevenue();
  }, []);

  return (
    <>
      <div className="flex gap-2 text-center items-center mt-6">
        <CashSvg />
        <p className="text-lg font-bold text-[#3777BC] font-sans">
          ข้อมูลรายได้
        </p>
      </div>
      <div className="flex space-x-4 mt-6">
        <div className="bg-[#EEF2FF] py-5 px-6 rounded-2xl w-[25%]">
          <WalletSvg />
          <div className="mt-3">
            <p className="text-[24px] font-extrabold font-sans">
              {revenueSummary?.result.revenueTotal
                ? thousandComma(revenueSummary?.result.revenueTotal, 2)
                : "0.00"}
              <span className="text-[#151D48] text-[18px] font-semibold font-[DM Sans]">
                {" บาท"}
              </span>
            </p>
            <p className="font-sans text-[16px] font-medium text-[#425166]">
              รายได้ทั้งหมด
            </p>
          </div>
        </div>
        <div className="bg-[#F0FDF4] py-5 px-6 rounded-2xl w-[25%]">
          <PersonSvg />
          <div className="mt-3">
            <p className="text-[24px] font-extrabold font-sans">
              {revenueSummary?.result.onBoardTotal
                ? thousandComma(revenueSummary?.result.onBoardTotal, 2)
                : "0.00"}
              <span className="text-[#151D48] text-[18px] font-semibold font-[DM Sans]">
                {" บาท"}
              </span>
            </p>
            <p className="font-sans text-[16px] font-medium text-[#425166]">
              On board
            </p>
          </div>
        </div>
        <div className="bg-[#FEF9F2] py-5 px-6 rounded-2xl w-[25%]">
          <DocumentWithBgSvg />
          <div className="mt-3">
            <p className="text-[24px] font-extrabold font-sans">
              {revenueSummary?.result.orderFeedTotal
                ? thousandComma(revenueSummary?.result.orderFeedTotal, 2)
                : "0.00"}
              <span className="text-[#151D48] text-[18px] font-semibold font-[DM Sans]">
                {" บาท"}
              </span>
            </p>
            <p className="font-sans text-[16px] font-medium text-[#425166]">
              ออเดอร์
            </p>
          </div>
        </div>
        <div className="bg-[#FDF5F5] py-5 px-6 rounded-2xl w-[25%]">
          <WalletBgSvg />
          <div className="mt-3">
            <p className="text-[24px] font-extrabold font-sans">
              {revenueSummary?.result.invoiceTotal
                ? thousandComma(revenueSummary?.result.invoiceTotal, 2)
                : "0.00"}
              <span className="text-[#151D48] text-[18px] font-semibold font-[DM Sans]">
                {" บาท"}
              </span>
            </p>
            <p className="font-sans text-[16px] font-medium text-[#425166]">
              เก็บหนี้
            </p>
          </div>
        </div>
      </div>

      <div className="w-full h-full pt-8">
        <div className="fs-tab-custom-container">
          <div className="fs-tab-custom-bloc-tabs">
            <button
              className={`fs-tab-custom-button ${
                toggleState === 1
                  ? "fs-tab-custom-tabs fs-tab-custom-active-tabs"
                  : "fs-tab-custom-tabs"
              }`}
              onClick={() => toggleTab(1)}
            >
              <div className="flex w-full justify-center gap-3 items-center">
                รายได้ทั้งหมด{" "}
                <ChipCount value={countRevenue?.result.countAll || 0} />
              </div>
            </button>
            <button
              className={`fs-tab-custom-button ${
                toggleState === 2
                  ? "fs-tab-custom-tabs fs-tab-custom-active-tabs"
                  : "fs-tab-custom-tabs"
              }`}
              onClick={() => toggleTab(2)}
            >
              <div className="flex w-full justify-center gap-3 items-center">
                On board
                <ChipCount value={countRevenue?.result.countOnboard || 0} />
              </div>
            </button>
            <button
              className={`fs-tab-custom-button ${
                toggleState === 3
                  ? "fs-tab-custom-tabs fs-tab-custom-active-tabs"
                  : "fs-tab-custom-tabs"
              }`}
              onClick={() => toggleTab(3)}
            >
              <div className="flex w-full justify-center gap-3 items-center">
                ออเดอร์
                <ChipCount value={countRevenue?.result.countOrderFeed || 0} />
              </div>
            </button>
            <button
              className={`fs-tab-custom-button ${
                toggleState === 4
                  ? "fs-tab-custom-tabs fs-tab-custom-active-tabs"
                  : "fs-tab-custom-tabs"
              }`}
              onClick={() => toggleTab(4)}
            >
              <div className="flex w-full justify-center gap-3 items-center">
                เก็บหนี้
                <ChipCount value={countRevenue?.result.countInvoice || 0} />
              </div>
            </button>
          </div>
        </div>
        <div className="fs-tab-custom-content-tabs">
          <div
            className={
              toggleState === 1
                ? "fs-tab-custom-content  fs-tab-custom-active-content"
                : "fs-tab-custom-content"
            }
          >
            {partnerId ? <RevenueTab type="all" id={partnerId} /> : null}
          </div>

          <div
            className={
              toggleState === 2
                ? "fs-tab-custom-content  fs-tab-custom-active-content"
                : "fs-tab-custom-content"
            }
          >
            {partnerId ? <RevenueTab type="onboard" id={partnerId} /> : null}
          </div>
          <div
            className={
              toggleState === 3
                ? "fs-tab-custom-content  fs-tab-custom-active-content"
                : "fs-tab-custom-content"
            }
          >
            {partnerId ? <RevenueTab type="order_feed" id={partnerId} /> : null}
          </div>
          <div
            className={
              toggleState === 4
                ? "fs-tab-custom-content  fs-tab-custom-active-content"
                : "fs-tab-custom-content"
            }
          >
            {partnerId ? <RevenueTab type="invoice" id={partnerId} /> : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default RevenueInfoCorePartner;

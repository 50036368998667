import { useState } from "react";
import { Order } from "../../../../components/Table/TableCustom";
import { IVaccineTable } from "../../types/vaccine.type";
import { IMedicineTable } from "../../types/medicine.type";

export const useMedicineFilterHook = () => {
  const [searchText, setSearchText] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [direction, setDirection] = useState<Order>("desc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [orderBy, setOrderBy] = useState<keyof IMedicineTable>("categoryTh");
  const [count, setCount] = useState(0);

  return {
    categoryId,
    searchText,
    direction,
    page,
    limit,
    toggleFetch,
    orderBy,
    count,
    setCategoryId,
    setOrderBy,
    setSearchText,
    setDirection,
    setPage,
    setLimit,
    setToggleFetch,
    setCount,
  };
};

export type TUseMedicineFilterHook = ReturnType<typeof useMedicineFilterHook>;

import { TableCell, TableRow } from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import DeleteFillGreen from "../../../../../assets/svg/DeleteFillGreen.svg";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import AdminModal from "../../../../../components/Modal/AdminModal";
import TableCustom, {
  ITableCustomHeadCell,
} from "../../../../../components/Table/TableCustom";
import { useVaccineFilterHook } from "../../../hooks/vaccine/useVaccineFilter.hook";
import { IVaccineTable, VaccineRow } from "../../../types/vaccine.type";
import RHFTextInputCustom from "../../../../../components/Input/RHFTextInputCustom";
import TextInputSearchCustom from "../../../../../components/Input/TextInputSearchCustom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import { editVaccine } from "../../../services/vaccine.service";
import { useVaccineTableHook } from "../../../hooks/vaccine/useVaccineTable.hook";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  handleFetch: () => void;
};

interface IEditVaccineForm {
  vaccines: VaccineRow[];
}

const headerItems: ITableCustomHeadCell<keyof IVaccineTable>[] = [
  {
    id: "vaccineNameTH",
    numeric: false,
    disablePadding: false,
    label: "ชื่อวัคซีน (TH)",
    width: "200px",
    isSorting: false,
  },
  {
    id: "vaccineNameEN",
    numeric: false,
    disablePadding: false,
    label: "ชื่อวัคซีน (EN)",
    width: "200px",
    isSorting: false,
  },
  {
    id: "vaccineNameEN",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "20px",
    isSorting: false,
  },
];

const EditVaccineModal = ({ isOpen, onClose, handleFetch }: Props) => {
  const dispatch = useDispatch();
  const searchVaccine = useVaccineFilterHook();
  const { handleFetchEditData, vaccine } = useVaccineTableHook(searchVaccine);
  const vaccines = { vaccines: vaccine || [] };

  const [deletedIds, setDeletedIds] = useState<string[]>([]);

  const methods = useForm<IEditVaccineForm>({
    defaultValues: {
      vaccines: [
        {
          id: "",
          nameEn: "",
          nameTh: "",
          isActive: false,
        },
      ],
    },
  });

  const { fields, remove } = useFieldArray({
    name: "vaccines",
    control: methods.control,
  });

  const handleDelete = (index: number) => {
    const deletedFieldId = methods.getValues(`vaccines.${index}.id`);

    if (deletedFieldId) {
      setDeletedIds((prev) => [...prev, deletedFieldId]);
    }
    remove(index);
  };

  const handleSubmit = (value: IEditVaccineForm) => {
    const { dirtyFields } = methods.formState;

    const filterFieldIsDirty = value.vaccines.filter((_, index) => {
      const dirtyField = dirtyFields.vaccines?.[index];
      return dirtyField?.nameEn || dirtyField?.nameTh;
    });

    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
            await editVaccine(filterFieldIsDirty, deletedIds);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "บันทึกสำเร็จ",
              })
            );
            handleFetch();
            onClose();
          } catch (error) {
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  const handleSearch = async () => {
    if (searchVaccine.searchText.trim()) {
      const filteredMedicines = (vaccine || []).filter(
        (vac) =>
          vac.nameTh
            ?.toLowerCase()
            .includes(searchVaccine.searchText.toLowerCase()) ||
          vac.nameEn
            ?.toLowerCase()
            .includes(searchVaccine.searchText.toLowerCase())
      );
      methods.reset({ vaccines: filteredMedicines });
    } else {
      methods.reset(vaccines);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [searchVaccine.searchText, searchVaccine.toggleFetch]);

  useEffect(() => {
    handleFetchEditData();
    methods.reset(vaccines);
  }, [isOpen]);

  return (
    <>
      <AdminModal
        label={"เเก้ไขวัคซีน"}
        isOpen={isOpen}
        size={"md"}
        handleClose={onClose}
        children={
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
              <TextInputSearchCustom
                value={searchVaccine.searchText}
                placeholder="ค้นหาโดยชื่อวัคซีน"
                onChange={(event) =>
                  searchVaccine.setSearchText(event.target.value)
                }
                InputProps={{
                  endAdornment:
                    searchVaccine.searchText.length > 0 ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "18px !important",
                        }}
                        onClick={() => {
                          searchVaccine.setSearchText("");
                          searchVaccine.setToggleFetch((prev) => !prev);
                        }}
                      />
                    ) : null,
                }}
              />
              <div className="mt-4">
                <TableCustom
                  order={"desc"}
                  orderBy={""}
                  itemsSelected={[]}
                  rowsPerPage={vaccine?.length || 0}
                  page={vaccine?.length || 0}
                  headerItems={headerItems}
                  rows={vaccine ? vaccine : []}
                  onChangeOrderBy={() => {}}
                  onChangeOrder={() => {}}
                  onChangePage={() => {}}
                  onSelectItem={() => {}}
                  onSelectAllClick={() => {}}
                  totalItemSize={vaccine?.length || 0}
                  currentPageItems={[]}
                  hideSelect
                  hidePaginator
                  maxHeightForScroll="550px"
                  rowChildren={fields.map((row, index) => {
                    return (
                      <TableRow hover key={row.id}>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {/* vaccine name thai */}
                          <RHFTextInputCustom
                            controlName={`vaccines[${index}].nameTh`}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {/* vaccine name eng */}
                          <RHFTextInputCustom
                            controlName={`vaccines[${index}].nameEn`}
                          />
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          <div
                            onClick={() => {
                              handleDelete(index);
                            }}
                          >
                            <DeleteFillGreen />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                />
              </div>
              <div className="flex justify-end">
                <ButtonFillCustom title="ยืนยัน" type="submit" />
              </div>
            </form>
          </FormProvider>
        }
      />
    </>
  );
};

export default EditVaccineModal;

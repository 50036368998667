import React, { useState } from "react";

import TableCustom, {
  ITableCustomHeadCell,
} from "../../../../components/Table/TableCustom";

import { TableCell, TableRow } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { getInvoiceFollowLog } from "../../../../../services/admin/invoice/invoiceSale.service";
import { thousandComma } from "../../../../../utils/common/string";

import { ISaleInvoiceDetail } from "./type";
import FilterSaleInvoiceDetail from "./filterSaleInvoiceDetail";

type Props = {
  saleInvoiceId?: string;
};

enum EStatusInvoiceDetail {
  not_follow = "ยังไม่ได้ติดตาม",
  followed = "กำลังติดตาม",
  followed_three_days = "กำลังติดตาม 3 วัน",
  not_followed_seven_days = "ยังไม่ได้ติดตาม 7-30 วัน",
  followed_seven_days = "กำลังติดตาม 7-30 วัน",
  not_followed_thirty_one_days = "ยังไม่ได้ติดตาม 31-60 วัน",
  followed_thirty_one_days = "กำลังติดตาม 31-60 วัน",
  not_followed_sixty_days = "ยังไม่ได้ติดตาม 60 วัน",
  followed_sixty_days = "กำลังติดตาม 60 วัน",
  waiting_for_approval = "รออนุมัติ",
}
const TableSaleInvoiceDetail = ({ saleInvoiceId }: Props) => {
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [invoiceDetail, setInvoiceDetail] = useState<ISaleInvoiceDetail[]>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  function createData(
    id: string,
    createdAt: string,
    type: string,
    appointmentDate: string,
    paidAmount: number,
    user: string,
    detail: string
  ): ISaleInvoiceDetail {
    return {
      id,
      // createdAt: createdAt
      //   ? dayjs(createdAt).format("DD/MM/BBBB HH:mm น.")
      //   : "-",
      createdAt: createdAt
        ? dayjs(createdAt).format("DD/MM/YYYY HH:mm น.")
        : "-",
      type: type
        ? EStatusInvoiceDetail[type as keyof typeof EStatusInvoiceDetail]
        : "-",
      // appointmentDate: appointmentDate
      //   ? `วันนัดชำระ : ${dayjs(appointmentDate).format("DD/MM/BBBB")}`
      //   : "-",
      appointmentDate: appointmentDate
        ? `วันนัดชำระ : ${dayjs(appointmentDate).format("DD/MM/YYYY")}`
        : "-",
      paidAmount: paidAmount && `ยอดนัดชำระ : ${thousandComma(paidAmount)} บาท`,
      user: user ? user : "-",
      detail: detail && `รายละเอียด : ${detail}`,
    };
  }

  const headCells: ITableCustomHeadCell<keyof ISaleInvoiceDetail>[] = [
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "วันที่และเวลา",
      width: "200px",
      isSorting: false,
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "ประเภท",
      width: "200px",
      isSorting: false,
    },
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "รายละเอียด",
      width: "600px",
      isSorting: false,
    },
    {
      id: "user",
      numeric: false,
      disablePadding: false,
      label: "อัปเดตโดย",
      width: "200px",
      isSorting: false,
    },
  ];

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("page", page.toString());
    searchParams.append("limit", limit.toString());
    searchParams.append("startDate", startDate);
    searchParams.append("endDate", endDate);
    setInvoiceDetail([]);
    const data = await getInvoiceFollowLog(saleInvoiceId!, searchParams);
    if (data?.rows) {
      const newList = data.rows.map((row: any) => {
        return createData(
          row.id,
          row.createdAt,
          row.type,
          row.appointmentDate,
          row.paidAmount,
          row.user
            ? row.user?.name + " " + row.user?.surname
            : row.partner?.name + " " + row.partner?.surname,
          row.detail
        );
      });
      setCount(data.count);
      setInvoiceDetail(newList);
    }
  };

  React.useEffect(() => {
    handleFetch();
  }, [page]);

  return (
    <div className="w-full h-full pt-8">
      <FilterSaleInvoiceDetail
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        handleFetch={handleFetch}
      />
      <div className="flex w-full pt-6">
        <TableCustom
          order={"desc"}
          orderBy={""}
          rowsPerPage={limit}
          rows={invoiceDetail}
          itemsSelected={[]}
          headerItems={headCells}
          page={1}
          onChangeOrderBy={() => {}}
          onChangeOrder={() => {}}
          onChangePage={(val) => setPage(val)}
          onSelectAllClick={() => {}}
          onSelectItem={() => {}}
          totalItemSize={count}
          currentPageItems={[]}
          hideSelect
          rowChildren={
            <>
              {invoiceDetail.map((row) => {
                return (
                  <TableRow sx={{ cursor: "pointer" }}>
                    <TableCell
                      sx={{
                        padding: "0px 16px",
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.createdAt}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.type}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <p className="mt-1">{row.appointmentDate}</p>
                      <p className="mt-1">{row.paidAmount}</p>
                      <p className="mt-1">{row.detail}</p>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <p>{row.user}</p>
                    </TableCell>
                  </TableRow>
                );
              })}
            </>
          }
        ></TableCustom>
      </div>
    </div>
  );
};

export default TableSaleInvoiceDetail;

import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import { MouseEventHandler } from "react";
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 20,
  height: 20,
  borderColor: "#C9C9C9",
  backgroundColor: "#FBFBFB",
  borderWidth: "1px",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#68C184",
  display: "block",
  width: 20,
  height: 20,
  backgroundImage: "radial-gradient(#fff,#fff 30%,transparent 0%)",
  content: '""',
  borderWidth: 0,
});

const DisabledIcon = styled(BpIcon)({
  backgroundColor: "#F0F0F0",
  borderColor: "#C9C9C9",
});

const DisabledCheckedIcon = styled(BpCheckedIcon)({
  backgroundColor: "#8C8C8C",
  backgroundImage: "radial-gradient(#CCC,#CCC 30%,transparent 0%)",
});

function BpRadio(props: any) {
  const { disabled, isCheck, ...restProps } = props;
  return (
    <Radio
      sx={{
        cursor: disabled ? "default" : "pointer",
      }}
      disableRipple
      color="success"
      checkedIcon={
        disabled ? (
          isCheck ? (
            <BpCheckedIcon />
          ) : (
            <DisabledCheckedIcon />
          )
        ) : (
          <BpCheckedIcon />
        )
      }
      icon={disabled ? isCheck ? <BpIcon /> : <DisabledIcon /> : <BpIcon />}
      {...restProps}
    />
  );
}

const RadioButton = ({
  checked = true,
  onClick,
  disable = false,
  isCheck = false,
}: {
  checked?: boolean;
  onClick?: MouseEventHandler<HTMLLabelElement>;
  disable?: boolean;
  isCheck?: boolean;
}) => {
  return (
    <RadioGroup
      defaultValue=""
      aria-labelledby="demo-customized-radios"
      name="customized-radios"
    >
      <FormControlLabel
        sx={{ mr: 0 }}
        value=""
        checked={!disable ? checked : isCheck ? checked : false}
        onClick={disable ? undefined : onClick}
        control={<BpRadio disabled={disable} isCheck={isCheck} />}
        label=""
        disabled={disable}
      />
    </RadioGroup>
  );
};
export default RadioButton;

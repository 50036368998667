export default function TrashIconSVG() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="24" height="24" rx="12" fill="white" />
      <path
        d="M17.834 8.33333L17.1112 18.4521C17.0489 19.3243 16.3232 20 15.4488 20H8.55252C7.67812 20 6.95238 19.3243 6.89009 18.4521L6.16732 8.33333M10.334 11.6667V16.6667M13.6673 11.6667V16.6667M14.5007 8.33333V5.83333C14.5007 5.3731 14.1276 5 13.6673 5H10.334C9.87375 5 9.50065 5.3731 9.50065 5.83333V8.33333M5.33398 8.33333H18.6673"
        stroke="#68C184"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { useState } from "react";
import { IFarmDetailTable } from "../types/farmDetailTable.type";
import {
  TUseFarmDetailFilter,
  useFarmDetailFilterHook,
} from "./useFarmDetailFilter.hook";
import {
  getExportExcelDailyLogFarmer,
  getFarmDetailTable,
} from "../services/farmDetail.service";
import { TDropdownFarmSize } from "../components/FarmDetailFilter";
import dayjs from "dayjs";
import { makeXlsx } from "../../../../utils/make-xlsx";

const farmSizeValueMapper: Record<
  TDropdownFarmSize,
  {
    start?: number;
    end?: number;
  }
> = {
  "0_50": {
    start: 0,
    end: 50,
  },
  "51_500": {
    start: 51,
    end: 500,
  },
  "501_1000": {
    start: 501,
    end: 1000,
  },
  "1000UP": {
    start: 1000,
  },
  "": {},
};

export const useFarmDetailTableHook = (
  farmDetailFilter: TUseFarmDetailFilter
) => {
  const [itemSelected, setItemSelected] = useState<string[]>([]);
  const [list, setList] = useState<IFarmDetailTable[]>([]);
  const [count, setCount] = useState(0);

  const createData = (
    id: string,
    customerId: string,
    customerName: string,
    segment: string,
    farmSize: string,
    farmAmount: string,
    houseAmount: string
  ): IFarmDetailTable => {
    return {
      id,
      customerId,
      customerName,
      segment: segment ? segment : "-",
      farmSize: farmSize ? farmSize : "-",
      farmAmount: farmAmount ? farmAmount : "-",
      houseAmount: houseAmount ? houseAmount : "-",
    };
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = list.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };

  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("searchText", farmDetailFilter.searchText);
    searchParams.append("page", farmDetailFilter.page.toString());
    searchParams.append("limit", farmDetailFilter.limit.toString());
    searchParams.append("direction", farmDetailFilter.direction);
    searchParams.append("segment", farmDetailFilter.segment);
    searchParams.append("userId", farmDetailFilter.saleName);
    searchParams.append("userGroupId", farmDetailFilter.groupArea);
    searchParams.append(
      "farmSizeStart",
      farmSizeValueMapper[farmDetailFilter.farmSize]?.start?.toString() || ""
    );
    searchParams.append(
      "farmSizeEnd",
      farmSizeValueMapper[farmDetailFilter.farmSize]?.end?.toString() || ""
    );

    setList([]);
    const data = await getFarmDetailTable(searchParams);
    if (data?.rows) {
      const newList = data.rows.map((row) => {
        return createData(
          row.id,
          row.registrationId,
          row.name + " " + row.surname,
          row.segment,
          row.totalPig,
          row.farmAmount,
          row.houseAmount
        );
      });

      setCount(data.count);
      setList(newList);
    }
  };

  const handleExportExcel = async (farmId: string) => {
    const data = await getExportExcelDailyLogFarmer(farmId);
    const dataExcel = data?.rows
      .map((item) => {
        return item?.houses.map((house) => {
          return (house?.dailyLogs || []).map((log) => {
            return {
              "Farm name": item.farmName,
              "House name": house.name,
              ...log,
            };
          });
        });
      })
      .flat(2);

    const fileName = `daily_log_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(dataExcel || [], fileName, sheetName);
  };

  return {
    handleSelectAllClick,
    handleClick,
    isSelected,
    count,
    list,
    itemSelected,
    setItemSelected,
    handleFetch,
    handleExportExcel,
  };
};

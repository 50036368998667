export function DownloadSvg() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.33301 9.33268L2.33301 9.91602C2.33301 10.8825 3.11651 11.666 4.08301 11.666L9.91634 11.666C10.8828 11.666 11.6663 10.8825 11.6663 9.91602L11.6663 9.33268M9.33301 6.99935L6.99967 9.33268M6.99967 9.33268L4.66634 6.99935M6.99967 9.33268L6.99967 2.33268"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import axios, { AxiosRequestConfig } from "axios";
import api from "./ApiService";
import {
  ICreateMoveOutPigRequest,
  ICreatedMoveOutPigResponse,
  IMoveOutPigFarmPastPerformanceResponse,
  IMoveOutPigHouseDetail,
  IMoveOutPigPresignedUploadReceiptResponse,
  IMoveOutPigSellingHistoryResponse,
  IMoveOutPigSellingReportResponse,
  IMovePutPigFarmPastPerformanceResponse,
} from "../types/MoveOutPig.type";

export const getMoveOutPigHouseDetail = async (
  houseId: string,
  config?: AxiosRequestConfig
): Promise<IMoveOutPigHouseDetail> => {
  try {
    const data = await api.get(`/farmers/move-out-pig/house/${houseId}/detail`);
    return data.data;
  } catch (err: any) {
    throw new Error(err?.message ?? err);
  }
};

export const getMoveOutPigGenerateId = async (): Promise<{
  moveOutPigId: string;
}> => {
  try {
    const result = await api.get(`/farmers/move-out-pig/generate-id`);
    return result.data;
  } catch (err: any) {
    throw new Error(err?.message ?? err);
  }
};

export const postMoveOutPig = async (
  payload: ICreateMoveOutPigRequest
): Promise<ICreatedMoveOutPigResponse> => {
  try {
    const result = await api.post(`/farmers/move-out-pig`, payload);
    return result.data;
  } catch (err: any) {
    throw new Error(err?.message ?? err);
  }
};

export const getMoveOutPigSellingHistory = async (
  houseId: string
): Promise<IMoveOutPigSellingHistoryResponse> => {
  try {
    const result = await api.get(
      `farmers/move-out-pig/house/${houseId}/history`
    );
    return result.data;
  } catch (err: any) {
    throw new Error(err?.message ?? err);
  }
};

export const getMoveOutPigFarmPastPerformance = async (
  farmId: string
): Promise<IMoveOutPigFarmPastPerformanceResponse> => {
  try {
    const result = await api.get(
      `farmers/move-out-pig/farm/${farmId}/performance`
    );
    return result.data;
  } catch (err: any) {
    throw new Error(err?.message ?? err);
  }
};

export const getMoveOutPigHousesFarmPastPerformance = async (
  farmId: string,
  config?: AxiosRequestConfig
): Promise<IMovePutPigFarmPastPerformanceResponse> => {
  try {
    const result = await api.get(
      `farmers/move-out-pig/farm/${farmId}/houses`,
      config
    );
    return result.data;
  } catch (err: any) {
    throw new Error(err?.message ?? err);
  }
};

export const getMoveOutPigSellingReport = async (
  houseId: string
): Promise<IMoveOutPigSellingReportResponse> => {
  try {
    const result = await api.get(
      `/farmers/move-out-pig/house/${houseId}/report`
    );
    return result.data;
  } catch (err: any) {
    throw new Error(err?.message ?? err);
  }
};

export const getPresignedUploadReceipt = async (
  moveOutPigId: string,
  contentType: string,
  config?: AxiosRequestConfig
): Promise<IMoveOutPigPresignedUploadReceiptResponse> => {
  try {
    const result = await api.get(
      `/farmers/move-out-pig/generate-receipt-upload-url?moveOutPigId=${moveOutPigId}&contentType=${contentType}`,
      config
    );
    return result.data;
  } catch (err: any) {
    throw new Error(err?.message ?? err);
  }
};

export const putUploadPresigned = async (
  url: string,
  data: ArrayBuffer,
  config?: AxiosRequestConfig
) => {
  try {
    const result = await axios.put(url, data, config);
    return result.data;
  } catch (err: any) {
    throw new Error(err?.message ?? err);
  }
};

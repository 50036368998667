import DownloadLinkWithDelete from "./DownloadLinkWithDelete";

interface ListOfUploadedFileProps<T> {
  files: T[];
  fileKey: keyof T;
  onDownload: (row: T) => Promise<void>;
}

function ListOfUploadedFile2<T>({
  files,
  onDownload,
  fileKey,
}: ListOfUploadedFileProps<T>) {
  return files.map((row: any, idx) => {
    return (
      <div key={idx}>
        <DownloadLinkWithDelete
          hideDeleteBtn
          onDownload={() => onDownload(row)}
          name={row[fileKey]}
        />
      </div>
    );
  });
}

export default ListOfUploadedFile2;

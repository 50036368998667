import {
  HouseDashboardHouseStatus,
  HouseStatusDetail,
  HouseStatusType,
  PigFaceType,
} from "./house-dashboard.type";

export default function mapFarmStatusWithBusinesLogic(
  farmStatus: HouseDashboardHouseStatus
): HouseStatusDetail {
  const edibleStatus: HouseStatusType =
    farmStatus.edible >= farmStatus.standardEdible ? "GOOD" : "POOR";
  const healthStatus: HouseStatusType =
    farmStatus.health <= 30 ? "GOOD" : "POOR";
  const losePercentStatus: HouseStatusType =
    farmStatus.loss <= farmStatus?.lossThreshold ? "GOOD" : "POOR";
  return {
    ...farmStatus,
    edibleStatus,
    healthStatus,
    losePercentStatus,
    pigFaceEmotion: getOverallStatus(
      edibleStatus,
      healthStatus,
      losePercentStatus
    ),
  };
}

function getOverallStatus(
  edibleStatus: HouseStatusType,
  healthStatus: HouseStatusType,
  losePercent: HouseStatusType
): PigFaceType {
  const total =
    mapStatusToNumber(edibleStatus) +
    mapStatusToNumber(healthStatus) +
    mapStatusToNumber(losePercent);

  if (total === 3) {
    return "GOOD";
  } else if (total === 2) {
    return "AVERAGE";
  } else {
    return "POOR";
  }
}

function mapStatusToNumber(status: HouseStatusType): number {
  return status === "GOOD" ? 1 : 0;
}

import adminAPI from "../../../../services/AdminApiService";
import {
  IGetCountRevenue,
  IGetExportRevenue,
  IGetRevenue,
  IGetRevenueModal,
  IGetRevenueSummary,
} from "../types/revenueTable.type";

export const getRevenue = async (
  partnerId: string,
  type: string,
  searchParams?: URLSearchParams
) => {
  try {
    const { data } = await adminAPI.get<IGetRevenue>(
      `/partner-detail/${partnerId}/revenue-info/${type}?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getRevenueSummary = async (partnerId: string) => {
  try {
    const { data } = await adminAPI.get<IGetRevenueSummary>(
      `/partner-detail/${partnerId}/revenue-info/summary`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getCountRevenue = async (partnerId: string) => {
  try {
    const { data } = await adminAPI.get<IGetCountRevenue>(
      `/partner-detail/${partnerId}/revenue-info/count`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getRevenueDetail = async (
  partnerId: string,
  customerId: string,
  type: string,
  searchParams?: URLSearchParams
) => {
  try {
    const { data } = await adminAPI.get<IGetRevenueModal>(
      `/partner-detail/${partnerId}/revenue-info/${type}/${customerId}?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const postExportRevenue = async (
  ids: string[],
  partnerId: string,
  type: string
) => {
  try {
    const { data } = await adminAPI.post<IGetExportRevenue>(
      `/partner-detail/${partnerId}/revenue-info/${type}/export`,
      { ids }
    );
    return data;
  } catch (error) {
    return null;
  }
};

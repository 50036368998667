export function FarmSvg() {
  return (
    <svg
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.1665 14.2129H1.6665V4.21289L6.6665 1.71289L11.6665 4.21289V14.2129H9.1665M4.1665 14.2129V8.37956H9.1665V14.2129M4.1665 14.2129H9.1665M14.9998 9.21289V14.2129M11.6665 11.7129H18.3332M11.6665 9.21289H18.3332"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { regexCheckZeroPrefix } from "./regex";

export const thousandComma = (input: number, decimal = 0) => {
  if (input == null) return "";
  const options: Intl.NumberFormatOptions = {
    style: "decimal",
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  };
  return input.toLocaleString("en-US", options);
};

export const checkAndReplaceZeroPrefix = (
  value: string,
  countStr = 1
): string => {
  let newValue = value;
  if (value.length > countStr && regexCheckZeroPrefix.test(value)) {
    newValue = value.replace(regexCheckZeroPrefix, "");
  }
  return newValue;
};

export const formatPhoneNumber = (phoneNumberString: string) => {
  return phoneNumberString.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
};

import { Button, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { object, string } from "yup";
import AppBarFS from "../../../components/app-bar/AppBar";
import AuthBox from "../../../components/common/AuthBox";

import { CountdownTime } from "../../../components/common/CountdownTimer/CountdownTime";
import { AUTH_TOKEN_KEY } from "../../../const/app.constant";
import {
  getOTPByUsernameSevice,
  verifyOTPService,
} from "../../../services/AuthService";

export default function PinAuthentication() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { state } = useLocation();
  const [timeShowing, setTimeShowing] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  type verifyOTPtype = {
    mobileNumber: string;
    otp: string;
  };

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: object({
      otp: string().min(4, "").required("OTP is required"),
    }),
    onSubmit: async (values) => {
      const data: verifyOTPtype = {
        mobileNumber: mobileNumber,
        otp: values.otp,
      };
      const resp: any = await verifyOTPService(data);
      if (resp.status == 200) {
        setTimeShowing(false);
        setError(false);
        localStorage.setItem(AUTH_TOKEN_KEY, resp.data.token);
        navigate("/update-pin", { state: { mobileNumber: mobileNumber } });
      } else {
        setTimeShowing(false);
        setError(true);
        formik.setFieldValue("otp", "");
      }
    },
  });

  const handleResendOTP = () => {
    userSendOTP();
    setTimeShowing(true);
  };

  const handleTimerComplete = () => {
    setTimeShowing(false);
  };

  const handleOTPChange = (val: string) => {
    formik.setFieldValue("otp", val);
    error && setError(false);
  };

  const otpTemplate = (
    <div className="flex items-center gap-x-4">
      <CountdownTime duration={60} onComplete={handleTimerComplete} />
      <Typography variant="body2">Auto-verifying the OTP</Typography>
    </div>
  );

  const sendOTPTemplate = (
    <div className="flex items-center">
      {/* TODO: Move Color to common Place */}
      <Typography sx={{ color: "#3777BC" }}>Didn't receive OTP?</Typography>
      <Button
        variant="text"
        sx={{ textTransform: "none" }}
        color="error"
        onClick={handleResendOTP}
      >
        Ask Again
      </Button>
    </div>
  );

  const messageTemplate = () => {
    if (timeShowing) {
      return otpTemplate;
    } else {
      return sendOTPTemplate;
    }
  };

  const userSendOTP = async () => {
    const resp: any = await getOTPByUsernameSevice(state.username);
    if (resp.status == 200) {
      setTimeShowing(true);
      setMobileNumber(resp.data.mobileNumber);
      localStorage.setItem(AUTH_TOKEN_KEY, resp.data.token);
    }
  };

  useEffect(() => {
    if (state.mobileNumber) {
      setTimeShowing(true);
      setMobileNumber(state.mobileNumber);
    }
  }, [state.mobileNumber]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="p-4 h-screen flex flex-col justify-between w-full"
    >
      <AppBarFS
        title={t("authentication.app_header")}
        onIconPress={() => navigate("/login")}
      />

      <div className="flex flex-col grow mt-8 ">
        <div>
          <Typography variant="body2" textAlign="left">
            We have sent a 4-digit One-Time Password (OTP) to
          </Typography>
          <Typography variant="subtitle2" textAlign="left">
            +66 {mobileNumber}
          </Typography>
        </div>

        <div className="flex items-center justify-center mt-8 gap-x-4">
          {messageTemplate()}
        </div>

        <div className="flex items-center justify-center mt-8">
          <AuthBox
            value={formik?.values?.otp}
            placeholder=""
            onChange={handleOTPChange}
            error={error}
            errorMessage="Incorrect OTP Please try again"
            labelStyle={{
              position: "absolute",
              bottom: "14%",
              fontSize: "14px",
              fontWeight: "400",
              left: "50%",
              transform: "translateX(-50%)",
              whiteSpace: "nowrap",
            }}
          />
        </div>
      </div>
      <Button
        type="submit"
        variant="contained"
        fullWidth
        color="secondary"
        size="large"
      >
        {t("authentication.confirm_button")}
      </Button>
    </form>
  );
}

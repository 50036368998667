import { MenuItem } from "@mui/material";
import { FormikProps } from "formik";
import { IEditOrderForm } from "../../../../../types/EditOrderFeed";
import { FeedBrands } from "../../../../../types/FeedBrand.type";
import { thousandComma } from "../../../../../utils/common/string";
import {
  formError,
  formErrorMessage,
} from "../../../../../utils/form-validator";
import TrashIconSVG from "../../../../assets/svg/TrashIcon.svg";
import TextInputCustom from "../../../../components/Input/TextInputCustom";
import SelectAdminCustom from "../../../../components/Select/Select";

type singleOrderFeedProps = {
  feedBrands: FeedBrands[];
  idx: number;
  remove: () => void;
  formik: FormikProps<IEditOrderForm>;
};

const SingleOrderFeed = ({
  feedBrands,
  remove,
  formik,
  idx,
}: singleOrderFeedProps) => {
  const handleChangeFeedBrand = (value: any) => {
    const feedBrand = feedBrands.find((row) => row.id === value);
    formik.setFieldValue(
      `listOfFeeds[${idx}].feedBrandId`,
      feedBrand?.id ?? null
    );
    formik.setFieldValue(`listOfFeeds[${idx}].feedBrandItemId`, null);
    formik.setFieldValue(`listOfFeeds[${idx}].feedPricePerUnit`, null);
    formik.setFieldValue(`listOfFeeds[${idx}].feedAmount`, null);
    formik.setFieldValue(`listOfFeeds[${idx}].feedUnit`, null);
  };
  const handleChangeFeedSKU = (value: any) => {
    const feedSKU = getFeedSKU().find((row) => row.id === value);
    if (feedSKU) {
      formik.setFieldValue(
        `listOfFeeds[${idx}].feedBrandItemId`,
        feedSKU.id ?? ""
      );
      formik.setFieldValue(`listOfFeeds[${idx}].feedPricePerUnit`, 0);
      formik.setFieldValue(`listOfFeeds[${idx}].feedAmount`, 0);
      formik.setFieldValue(
        `listOfFeeds[${idx}].feedPricePerUnit`,
        feedSKU.pricePerUnit ?? 0
      );

      formik.setFieldValue(`listOfFeeds[${idx}].feedUnit`, feedSKU.unit);
    }
  };

  const getFeedSKU = () => {
    const selectedFeedBrand = feedBrands.find(
      (row) => row.id === String(formik.values.listOfFeeds[idx].feedBrandId)
    );
    const feedSKUs = selectedFeedBrand?.feed_brand_items ?? [];
    return feedSKUs;
  };

  return (
    <div className="border-2 border-[#E2E2E2] rounded-[20px] overflow-y-auto">
      <div className="flex m-4 justify-between">
        <div className="w-[32%] ">
          <p className=" font-medium font-sans mb-1">เเบรนด์อาหารสัตว์</p>
          <div>
            <SelectAdminCustom
              placeholder="เลือก"
              {...formik.getFieldProps(`listOfFeeds[${idx}].feedBrandId`)}
              onChange={(e) => {
                const { onChange } = formik.getFieldProps(
                  `listOfFeeds[${idx}].feedBrandId`
                );
                onChange(e);
                handleChangeFeedBrand(e.target.value);
              }}
              renderValueArray={feedBrands.map((row) => ({
                value: row.id,
                label: row.nameTh,
              }))}
              error={formError(formik, `listOfFeeds[${idx}].feedBrandId`)}
              helperText={formErrorMessage(
                formik,
                `listOfFeeds[${idx}].feedBrandId`
              )}
              menuItems={feedBrands.map((option, idx) => (
                <MenuItem key={idx} value={option.id}>
                  {option.nameTh}
                </MenuItem>
              ))}
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className=" font-medium font-sans mb-1">เบอร์อาหารสัตว์</p>
          <div>
            <SelectAdminCustom
              placeholder="เลือก"
              {...formik.getFieldProps(`listOfFeeds[${idx}].feedBrandItemId`)}
              onChange={(e) => {
                const { onChange } = formik.getFieldProps(
                  `listOfFeeds[${idx}].feedBrandItemId`
                );
                onChange(e);
                handleChangeFeedSKU(e.target.value);
              }}
              renderValueArray={getFeedSKU().map((row) => ({
                value: row.id,
                label: row.skuTh,
              }))}
              error={formError(formik, `listOfFeeds[${idx}].feedBrandItemId`)}
              helperText={formErrorMessage(
                formik,
                `listOfFeeds[${idx}].feedBrandItemId`
              )}
              menuItems={getFeedSKU().map((option, idx) => (
                <MenuItem key={idx} value={option.id}>
                  {option.skuTh}
                </MenuItem>
              ))}
            />
          </div>
        </div>
        <div className="w-[32%]">
          <p className=" font-medium font-sans mb-1">จำนวน</p>
          <div>
            <TextInputCustom
              placeholder="กรอก"
              type="number"
              error={formError(formik, `listOfFeeds[${idx}].feedAmount`)}
              helperText={formErrorMessage(
                formik,
                `listOfFeeds[${idx}].feedAmount`
              )}
              {...formik.getFieldProps(`listOfFeeds[${idx}].feedAmount`)}
            />
          </div>
        </div>
      </div>
      <div className="flex mx-4 mt-4">
        <div className="w-[35%]">
          <p className="font-bold mb-2">ราคาต่อหน่วย</p>
          <span>{formik.values.listOfFeeds[idx].feedPricePerUnit} บาท</span>
        </div>
        <div className="w-[35%]">
          <p className="font-bold mb-2">ราคารวม</p>
          <span>
            {thousandComma(
              Number(formik.values.listOfFeeds[idx].feedPricePerUnit) *
                Number(formik.values.listOfFeeds[idx].feedAmount),
              2
            )}{" "}
            บาท
          </span>
        </div>
      </div>
      <div className="flex justify-end mr-4 mb-4">
        <div onClick={remove} style={{ cursor: "pointer" }}>
          <TrashIconSVG />
        </div>
      </div>
    </div>
  );
};

export default SingleOrderFeed;

export function PigFeedIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.57617 0.107422C4.47305 0.20625 4.46875 0.232031 4.46875 0.794922V1.375H11H17.5312V0.803516C17.5312 0.287891 17.5227 0.223437 17.4453 0.1375C17.2734 -0.0429688 17.1703 -0.0343751 16.6418 0.232031L16.1562 0.472656L15.6836 0.236328C15.4258 0.107422 15.1723 0 15.125 0C15.0777 0 14.8242 0.107422 14.5664 0.236328L14.0938 0.472656L13.6211 0.236328C13.3633 0.107422 13.1098 0 13.0625 0C13.0152 0 12.7617 0.107422 12.5039 0.236328L12.0312 0.472656L11.5586 0.236328C11.3008 0.107422 11.0473 0 11 0C10.9527 0 10.6992 0.107422 10.4414 0.236328L9.96875 0.472656L9.49609 0.236328C9.23828 0.107422 8.98477 0 8.9375 0C8.89023 0 8.63672 0.107422 8.37891 0.236328L7.90625 0.472656L7.43359 0.236328C7.17578 0.107422 6.92227 0 6.875 0C6.82773 0 6.57422 0.107422 6.31641 0.236328L5.84375 0.472656L5.37109 0.236328C5.11328 0.107422 4.85117 0 4.79102 0C4.73086 0 4.63203 0.0472656 4.57617 0.107422Z"
        fill="#68C184"
      />
      <path
        d="M16.7405 2.80181C15.7952 3.93618 15.4944 4.36157 15.1936 4.96313C14.7726 5.80532 14.8069 5.30259 14.794 10.7209C14.7811 13.9264 14.794 15.585 14.8241 15.787C14.9573 16.6635 15.3483 17.3596 16.5472 18.8721C16.9167 19.3319 17.2776 19.8002 17.3593 19.9077C17.4366 20.0194 17.514 20.1096 17.5269 20.1053C17.5397 20.1053 17.6343 19.9936 17.7288 19.8604C17.8276 19.7272 18.1929 19.2846 18.5452 18.885C18.8933 18.4811 19.2843 18.0127 19.4132 17.8366C19.7183 17.4241 20.0362 16.8225 20.1608 16.4143L20.2597 16.092L20.2726 11.185C20.2854 5.75376 20.294 5.99009 20.0061 5.31977C19.83 4.91157 19.5679 4.4647 19.194 3.94477C18.8933 3.52798 17.5655 1.89087 17.5269 1.89087C17.514 1.89087 17.1573 2.30337 16.7405 2.80181Z"
        fill="#68C184"
      />
      <path
        d="M3.84141 2.71992C2.92188 3.87148 2.46641 4.63633 2.2043 5.47852L2.08398 5.86523V10.8926V15.9199L2.2043 16.3066C2.3375 16.7449 2.62969 17.3766 2.8875 17.7891C2.98203 17.9437 3.36016 18.4895 3.72969 19.0051L4.4 19.9375H10.45C15.284 19.9375 16.5 19.9246 16.4742 19.8816C16.4527 19.8559 16.1777 19.4992 15.8555 19.0996C14.9789 18.0039 14.5707 17.3379 14.3258 16.616C14.0938 15.9328 14.0938 15.9672 14.0938 10.8754C14.098 5.73203 14.0895 5.85664 14.3516 5.15195C14.6266 4.38711 15.1895 3.55781 16.3066 2.24297L16.4656 2.0625H10.4113L4.36133 2.0668L3.84141 2.71992ZM4.17227 6.37656C4.56328 6.51406 4.88555 6.7375 5.24219 7.11992L5.54297 7.44219L5.89531 7.2918C7.19297 6.7418 8.64102 6.7418 9.90859 7.2875L10.2652 7.44219L10.6648 7.03828C10.9871 6.71601 11.1332 6.6 11.4082 6.4625C11.7391 6.29922 11.7648 6.29492 12.1902 6.29492C12.5898 6.29492 12.6328 6.30352 12.7359 6.39805C12.9422 6.59141 13.0367 6.92656 13.0367 7.45508C13.041 8.09102 12.9078 8.46484 12.4094 9.25117C12.2633 9.47461 12.2031 9.60781 12.2246 9.65938C12.2418 9.69805 12.2762 9.86133 12.3105 10.0203L12.3664 10.3125H11.7992C11.2105 10.3168 11.1461 10.334 11.0473 10.5188C10.9785 10.6477 11.0172 10.798 11.1375 10.9141C11.2234 10.9914 11.2879 11 11.8207 11H12.4094L12.607 11.2191C12.882 11.5242 13.0195 11.825 13.0496 12.1945C13.1355 13.2 12.4824 14.1152 11.4941 14.373C11.275 14.4289 10.768 14.4375 7.90625 14.4332C4.90273 14.4332 4.55039 14.4246 4.29258 14.3602C3.11953 14.0508 2.42773 12.6973 2.91758 11.666C2.98633 11.5242 3.12813 11.318 3.23984 11.202L3.4375 11H4.01758C4.58047 11 4.60625 10.9957 4.70508 10.8926C4.84687 10.7551 4.84687 10.5574 4.70508 10.4199C4.60625 10.3168 4.58047 10.3125 4.03906 10.3125C3.39883 10.3125 3.4375 10.3469 3.54922 9.86133L3.62227 9.56055L3.38594 9.19531C2.91328 8.46484 2.77148 8.06953 2.77578 7.45508C2.77578 6.88789 2.93047 6.48398 3.20117 6.325C3.35156 6.23906 3.85859 6.26484 4.17227 6.37656Z"
        fill="#68C184"
      />
      <path
        d="M3.50606 7.02539C3.35567 7.425 3.51895 8.16836 3.87559 8.69687L3.98301 8.85156L4.11621 8.6668C4.28379 8.44336 4.52012 8.18984 4.76504 7.98359L4.94981 7.82461L4.79082 7.62695C4.70059 7.51953 4.53301 7.36484 4.417 7.27891C4.05606 7.0125 3.56621 6.875 3.50606 7.02539Z"
        fill="#68C184"
      />
      <path
        d="M11.8465 7.02539C11.7562 7.05977 11.623 7.12852 11.5457 7.18008C11.3523 7.31328 10.9141 7.74297 10.9141 7.79883C10.9141 7.82461 11.0344 7.95352 11.1848 8.08672C11.3309 8.21563 11.5371 8.44336 11.6445 8.58945L11.8379 8.85156L11.9109 8.75703C12.0441 8.58516 12.2762 8.06523 12.332 7.82461C12.3836 7.60117 12.3707 7.10273 12.3148 7.0082C12.2719 6.94375 12.0484 6.95234 11.8465 7.02539Z"
        fill="#68C184"
      />
      <path
        d="M6.81898 9.66602C5.97679 9.87656 5.37953 10.7746 5.53421 11.5996C5.64164 12.1926 5.98968 12.648 6.5225 12.9016L6.81039 13.0391H7.8846C9.09203 13.0391 9.22093 13.0133 9.61195 12.7168C10.5401 12.0207 10.5401 10.6672 9.61625 9.9668C9.22523 9.67031 9.11781 9.64883 7.99203 9.64023C7.43773 9.63164 6.90921 9.64453 6.81898 9.66602ZM7.45492 11.1055C7.7557 11.402 7.32171 11.8875 7.01234 11.5996C6.89203 11.4836 6.85335 11.3332 6.9221 11.2043C7.04242 10.9766 7.28304 10.9293 7.45492 11.1055ZM8.82992 11.1055C9.1307 11.402 8.69671 11.8875 8.38734 11.5996C8.26703 11.4836 8.22835 11.3332 8.29711 11.2043C8.41742 10.9766 8.65804 10.9293 8.82992 11.1055Z"
        fill="#68C184"
      />
      <path
        d="M4.46875 21.1965C4.46875 21.7121 4.47734 21.7766 4.55469 21.8625C4.72656 22.043 4.82969 22.0344 5.3582 21.768L5.84375 21.5273L6.31641 21.7637C6.57422 21.8926 6.82773 22 6.875 22C6.92227 22 7.17578 21.8926 7.43359 21.7637L7.90625 21.5273L8.37891 21.7637C8.63672 21.8926 8.89023 22 8.9375 22C8.98477 22 9.23828 21.8926 9.49609 21.7637L9.96875 21.5273L10.4414 21.7637C10.6992 21.8926 10.9527 22 11 22C11.0473 22 11.3008 21.8926 11.5586 21.7637L12.0312 21.5273L12.5039 21.7637C12.7617 21.8926 13.0152 22 13.0625 22C13.1098 22 13.3633 21.8926 13.6211 21.7637L14.0938 21.5273L14.5664 21.7637C14.8242 21.8926 15.0777 22 15.125 22C15.1723 22 15.4258 21.8926 15.6836 21.7637L16.1562 21.5273L16.6418 21.768C17.1703 22.0344 17.2734 22.043 17.4453 21.8625C17.5227 21.7766 17.5312 21.7121 17.5312 21.1965V20.625H11H4.46875V21.1965Z"
        fill="#68C184"
      />
    </svg>
  );
}

import {
  ResponsiveContainer,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  AreaChart,
  Area,
} from "recharts";

const DataChart = () => {
  const pdata = [
    {
      name: "MongoDb",
      student: 11,
    },
    {
      name: "Javascript",
      student: 15,
    },
    {
      name: "PHP",
      student: 5,
    },
    {
      name: "Java",
      student: 5,
    },
  ];

  return (
    <div className="mt-5 w-[95%]">
      <ResponsiveContainer width="100%" aspect={3}>
        <AreaChart data={pdata} width={900} height={500}>
          <CartesianGrid strokeDasharray="" horizontal={false} />
          <Area
            dataKey="student"
            type="monotone"
            fill="#8884d8"
            stroke="#8884d8"
          />
          <XAxis dataKey="name" interval={"preserveStartEnd"} dy={10} />
          <YAxis tickFormatter={(value) => value + "g"} dx={-10} />
          <Legend />
          <Tooltip />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DataChart;

import React from "react";

import TableCustom, {
  ExpandableTableRow,
  ITableCustomHeadCell,
} from "../../../../components/Table/TableCustom";

import { Checkbox, Link, TableCell } from "@mui/material";
import dayjs from "dayjs";
import { useInvoiceSearchFilter } from "../../../../../hooks/useInvoiceSearchFilter.hook";
import {
  getInvoiceManager,
  getListExportPastDueManager,
  updateStatusAssignment,
} from "../../../../../services/admin/invoice/invoiceManager.service";
import {
  IResultInvoiceManager,
  IUpdateStatusAssignment,
} from "../../../../../types/invoice/invoiceManager.type";
import { thousandComma } from "../../../../../utils/common/string";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import { FilterInvoice } from "../../components/FilterInvoice";
import { IInvoiceTable } from "../../type";
import ExpandInvoiceTable from "../../components/ExpandInvoiceTable";
import { makeXlsx } from "../../../../../utils/make-xlsx";
import ConfirmCustomModal from "../../../../components/Modal/ConfirmCustomModal";
import { useDispatch } from "react-redux";
import { alertAction } from "../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../utils/getErrorMessage";

type Props = { handleFetchCount: () => void };

const PastDueInvoicePanel = ({ handleFetchCount }: Props) => {
  const [itemSelected, setItemSelected] = React.useState<string[]>([]);
  //TODO : change type IUpdateDocumentInvoiceSaleTable
  const [orderInvoice, setOrderInvoice] = React.useState<IInvoiceTable[]>([]);
  const [count, setCount] = React.useState(0);
  const [invoiceExpand, setInvoiceExpand] = React.useState<
    IResultInvoiceManager[]
  >([]);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = React.useState(false);
  const [isOpenCancelModal, setIsOpenCancelModal] = React.useState(false);
  const [assignmentId, setAssignmentId] = React.useState("");

  const dispatch = useDispatch();

  function createData(
    id: string,
    workSheetNumber: string,
    customerId: string,
    customerName: string,
    partnerFollowInvoice: string,
    saleName: string,
    totalAmountDue: number,
    receiveDate: string
  ): IInvoiceTable {
    return {
      id,
      workSheetNumber: workSheetNumber ? workSheetNumber : "-",
      customerId: customerId ? customerId : "-",
      customerName: customerName ? customerName : "-",
      partnerFollowInvoice: partnerFollowInvoice ? partnerFollowInvoice : "-",
      saleName: saleName ? saleName : "-",
      totalAmountDue: totalAmountDue
        ? `${thousandComma(totalAmountDue, 2)} บาท`
        : "-",
      receiveDate: receiveDate ? dayjs(receiveDate).format("DD/MM/YYYY") : "-",
    };
  }

  const headCells: ITableCustomHeadCell<keyof IInvoiceTable>[] = [
    {
      id: "workSheetNumber",
      numeric: false,
      disablePadding: false,
      label: "เลขที่ใบงาน",
      width: "150px",
      isSorting: true,
    },
    {
      id: "customerId",
      numeric: false,
      disablePadding: false,
      label: "รหัสลูกค้า",
      width: "120px",
      isSorting: false,
    },
    {
      id: "customerName",
      numeric: false,
      disablePadding: false,
      label: "ชื่อลูกค้า",
      width: "150px",
      isSorting: false,
    },
    {
      id: "partnerFollowInvoice",
      numeric: false,
      disablePadding: false,
      label: "พาร์ทเนอร์ที่ติดตาม",
      width: "150px",
      isSorting: false,
    },
    {
      id: "saleName",
      numeric: false,
      disablePadding: false,
      label: "พนักงานขาย",
      width: "150px",
      isSorting: false,
    },
    {
      id: "totalAmountDue",
      numeric: false,
      disablePadding: false,
      label: "ยอดเงินที่ต้องชำระทั้งหมด",
      width: "200px",
      isSorting: false,
    },
    {
      id: "receiveDate",
      numeric: false,
      disablePadding: false,
      label: "วันที่ได้รับคำขอ",
      width: "120px",
      isSorting: true,
    },
    {
      id: "workSheetNumber",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "80px",
      isSorting: false,
    },
    {
      id: "workSheetNumber",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "60px",
      isSorting: false,
    },
    {
      id: "workSheetNumber",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "20px",
      isSorting: false,
    },
  ];

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = orderInvoice.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };
  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const MapOrderByWithFilter: {
    [K in keyof IInvoiceTable]?: string;
  } = {
    workSheetNumber: "assignment_number",
    receiveDate: "created_at",
  };

  const searchFilterInvoice = useInvoiceSearchFilter();

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("searchText", searchFilterInvoice.searchText);
    searchParams.append("page", searchFilterInvoice.page.toString());
    searchParams.append("limit", searchFilterInvoice.limit.toString());
    searchParams.append(
      "orderBy",
      MapOrderByWithFilter[searchFilterInvoice.orderBy] ?? ""
    );
    searchParams.append("direction", searchFilterInvoice.direction);
    setOrderInvoice([]);

    const data = await getInvoiceManager(searchParams);
    if (data?.rows) {
      const newList = data.rows.map((row: any) => {
        return createData(
          row.id,
          row.assignmentNumber,
          row.farmerRegistrationId,
          row.farmerName,
          row.partnerName,
          row.saleName,
          +row.totalAmount,
          row.assignmentReceiveDate
        );
      });
      setCount(data.count);
      setOrderInvoice(newList);
      setInvoiceExpand(data.rows.map((invoice) => invoice));
    }
    handleFetchCount();
  };

  React.useEffect(() => {
    handleFetch();
  }, [
    searchFilterInvoice.orderBy,
    searchFilterInvoice.direction,
    searchFilterInvoice.page,
  ]);

  const getExportExcelData = async () => {
    type TColumnName =
      | "เลขที่ใบงาน"
      | "รหัสลูกค้า"
      | "ชื่อลูกค้า"
      | "พาร์ทเนอร์ที่ติดตาม"
      | "พนักงานขาย"
      | "ยอดเงินที่ต้องชำระทั้งหมด"
      | "วันที่ได้รับคำขอ";
    type TExcelData = {
      [k in TColumnName]: string | number;
    };
    const allSelectItemData = await getListExportPastDueManager(itemSelected);
    if (allSelectItemData?.rows) {
      return allSelectItemData.rows
        .map<TExcelData>((row) => {
          return {
            เลขที่ใบงาน: row.assignmentNumber,
            รหัสลูกค้า: row.farmerRegistrationId,
            ชื่อลูกค้า: row.farmerName,
            พาร์ทเนอร์ที่ติดตาม: row.partnerName,
            พนักงานขาย: row.saleName,
            ยอดเงินที่ต้องชำระทั้งหมด: `${thousandComma(
              +row.totalAmount,
              2
            )} บาท`,
            วันที่ได้รับคำขอ: dayjs(row.assignmentReceiveDate).format(
              "DD/MM/YYYY"
            ),
          };
        })
        .flat();
    }
    return [];
  };

  const handleExportExcel = async () => {
    const data = await getExportExcelData();
    console.log(data);
    const fileName = `over_due_invoice_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  return (
    <div className="w-full h-full pt-8">
      <FilterInvoice
        searchFilterInvoice={searchFilterInvoice}
        onSearch={handleFetch}
        isDisplaySelect={false}
        placeholder={"ค้นหาโดยเลขที่ใบงาน"}
        itemSelected={itemSelected}
        onExport={handleExportExcel}
      />
      <div className="flex w-full pt-6">
        <TableCustom
          order={searchFilterInvoice.direction}
          orderBy={searchFilterInvoice.orderBy}
          rowsPerPage={searchFilterInvoice.limit}
          rows={orderInvoice}
          itemsSelected={itemSelected}
          headerItems={headCells}
          page={1}
          onChangeOrderBy={(val) =>
            searchFilterInvoice.setOrderBy(val as keyof IInvoiceTable)
          }
          onChangeOrder={(val) => searchFilterInvoice.setDirection(val)}
          onChangePage={(val) => searchFilterInvoice.setPage(val)}
          onSelectAllClick={(val) => handleSelectAllClick(val)}
          onSelectItem={(val) => setItemSelected(val as string[])}
          totalItemSize={count}
          currentPageItems={orderInvoice.map((row) => row.id)}
          rowChildren={
            <>
              {orderInvoice.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <ExpandableTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.orderId}
                    selected={isItemSelected}
                    expandComponent={
                      <ExpandInvoiceTable
                        invoiceExpand={invoiceExpand[index]}
                      />
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(event) => handleClick(event, row.id)}
                        sx={{
                          borderWidth: "1px",
                          color: "#C9C9C9",
                          "&.Mui-checked": {
                            color: "#68C184",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        padding: "0px 16px",
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.workSheetNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                          ":hover": {
                            fontSize: "16px",
                          },
                        }}
                      >
                        {row.customerId}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.customerName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.partnerFollowInvoice}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.saleName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.totalAmountDue}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.receiveDate}
                    </TableCell>
                    <TableCell align="left">
                      <div className="flex flex-row w-full justify-center">
                        <ButtonFillCustom
                          btnBgColor="#D32F2F"
                          btnTextColor="#FFFFFF"
                          title={"ไม่อนุมัติ"}
                          onClick={() => {
                            setAssignmentId(row.id);
                            setIsOpenCancelModal(true);
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      <div className="flex flex-row w-full justify-center">
                        <ButtonFillCustom
                          title={"อนุมัติ"}
                          onClick={() => {
                            setAssignmentId(row.id);
                            setIsOpenConfirmModal(true);
                          }}
                        />
                      </div>
                    </TableCell>
                  </ExpandableTableRow>
                );
              })}
            </>
          }
        ></TableCustom>
      </div>
      <ConfirmCustomModal
        title="ยืนยันการอนุมัติ"
        subtitleFirstRow="คุณต้องการอนุมัติรายการนี้ใช่หรือไม่?"
        subtitleSecondRow="การดำเนินการนี้ไม่สามารถยกเลิกได้"
        buttonPrimaryText="ยืนยัน"
        buttonSecondaryText="ยกเลิก"
        open={isOpenConfirmModal}
        onClose={() => setIsOpenConfirmModal(false)}
        onSubmit={async () => {
          const payload: IUpdateStatusAssignment = {
            isApprove: true,
          };
          try {
            dispatch(alertAction.hide());
            dispatch(
              loadingActions.show({ message: "กำลังบันทึกการอนุมัติ..." })
            );
            await updateStatusAssignment(assignmentId, payload);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "อนุมัติสำเร็จ",
              })
            );
            handleFetch();
            setIsOpenConfirmModal(false);
          } catch (error) {
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        }}
      />
      <ConfirmCustomModal
        title="ยืนยันไม่อนุมัติ"
        subtitleFirstRow="คุณต้องการอนุมัติรายการนี้ใช่หรือไม่?"
        subtitleSecondRow="การดำเนินการนี้ไม่สามารถยกเลิกได้"
        buttonPrimaryText="ยืนยัน"
        buttonSecondaryText="ยกเลิก"
        open={isOpenCancelModal}
        onClose={() => setIsOpenCancelModal(false)}
        onSubmit={async () => {
          const payload: IUpdateStatusAssignment = {
            isApprove: false,
          };
          try {
            dispatch(alertAction.hide());
            dispatch(
              loadingActions.show({ message: "กำลังบันทึกการไม่อนุมัติ..." })
            );
            await updateStatusAssignment(assignmentId, payload);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "ไม่อนุมัติสำเร็จ",
              })
            );
            handleFetch();
            setIsOpenCancelModal(false);
          } catch (error) {
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        }}
      />
    </div>
  );
};

export default PastDueInvoicePanel;

import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import {
  getBasicAccountingByMonth,
  getSummaryBasicAccounting,
} from "../../../services/BasicAccounting.service";
import {
  defaultFormBasicAccounting,
  TFormBasicAccounting,
} from "./defaultFormBasicAccounting";
import {
  defaultFormSaveAccounting,
  TFormSaveAccounting,
} from "./save-accounting/defaultFormSaveAccounting";
import { isNull } from "lodash";

export const useBasicAccountingController = (farmId: string) => {
  const [form, setForm] = useState(defaultFormBasicAccounting);

  const onChangeForm = useCallback(
    (key: keyof TFormBasicAccounting) => (value: any) => {
      setForm((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  // Query for all 12 months
  const { data: summaryAllMonths } = useQuery({
    queryKey: ["get-summary-basic-accounting-all-months", farmId],
    queryFn: async () => {
      const monthValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].join(",");
      const result = await getSummaryBasicAccounting({
        farmId,
        christianYear: dayjs().year(),
        months: monthValues,
      });
      return result.farmerExpense;
    },
  });

  // Query for the current month
  const { data: summaryCurrentMonth } = useQuery({
    queryKey: [
      "get-summary-basic-accounting-current-month",
      farmId,
      form.month,
    ],
    queryFn: async () => {
      const currentMonth = form?.month[0]?.value;
      const result = await getSummaryBasicAccounting({
        farmId,
        christianYear: dayjs().year(),
        months: currentMonth.toString(),
      });
      return result.farmerExpense;
    },
  });

  const [formSaveBasic, setFormSaveBasic] = useState(defaultFormSaveAccounting);

  const onChangeFormSaveBasic = useCallback(
    (key: keyof TFormSaveAccounting) => (value: any) => {
      setFormSaveBasic((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const { data: BasicAccountingByMonth } = useQuery({
    queryKey: ["get-basic-accounting-by-month", farmId, form.month],
    queryFn: async () => {
      const currentMonth = form?.month[0]?.value;
      const result = await getBasicAccountingByMonth({
        farmId,
        christianYear: dayjs().year(),
        month: currentMonth.toString(),
      });
      return result;
    },
  });

  useEffect(() => {
    if (BasicAccountingByMonth) {
      setFormSaveBasic((prev) => ({
        ...prev,
        incomeFromPigSelling: isNull(
          BasicAccountingByMonth?.farmerExpense.incomeFromPigSelling
        )
          ? BasicAccountingByMonth?.systemExpenses.summarySystemAccounting.incomeFromMoveOutPig.toString()
          : BasicAccountingByMonth?.farmerExpense.incomeFromPigSelling.toString(),
        geneticBill: isNull(BasicAccountingByMonth?.farmerExpense.geneticBill)
          ? BasicAccountingByMonth?.systemExpenses.summarySystemAccounting.systemGeneticBill.toString()
          : BasicAccountingByMonth?.farmerExpense.geneticBill.toString(),
        feedBill: isNull(BasicAccountingByMonth?.farmerExpense.feedBill)
          ? BasicAccountingByMonth?.systemExpenses.summarySystemAccounting.systemFeedBill.toString()
          : BasicAccountingByMonth?.farmerExpense.feedBill.toString(),
        vaccineAndMedicineBill: isNull(
          BasicAccountingByMonth?.farmerExpense.vaccineAndMedicineBill
        )
          ? BasicAccountingByMonth?.systemExpenses.summarySystemAccounting.systemVaccineAndMedicineBill.toString()
          : BasicAccountingByMonth?.farmerExpense.vaccineAndMedicineBill.toString(),
        staffBill: isNull(BasicAccountingByMonth?.farmerExpense.staffBill)
          ? ""
          : BasicAccountingByMonth?.farmerExpense.staffBill.toString(),
        waterBill: isNull(BasicAccountingByMonth?.farmerExpense.waterBill)
          ? ""
          : BasicAccountingByMonth?.farmerExpense.waterBill.toString(),
        electricityBill: isNull(
          BasicAccountingByMonth?.farmerExpense.electricityBill
        )
          ? ""
          : BasicAccountingByMonth?.farmerExpense.electricityBill.toString(),
        transportationBill: isNull(
          BasicAccountingByMonth?.farmerExpense.transportationBill
        )
          ? ""
          : BasicAccountingByMonth?.farmerExpense.transportationBill.toString(),
        programBill: isNull(BasicAccountingByMonth?.farmerExpense.programBill)
          ? ""
          : BasicAccountingByMonth?.farmerExpense.programBill.toString(),
        otherBill: isNull(BasicAccountingByMonth?.farmerExpense.otherBill)
          ? ""
          : BasicAccountingByMonth?.farmerExpense.otherBill.toString(),
      }));
    } else {
      setFormSaveBasic(defaultFormSaveAccounting);
    }
  }, [BasicAccountingByMonth]);

  return {
    form,
    onChangeForm,
    summaryAllMonths,
    summaryCurrentMonth,
    formSaveBasic,
    setFormSaveBasic,
    onChangeFormSaveBasic,
  };
};

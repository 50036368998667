import { useLocation, useNavigate } from "react-router-dom";
import SuccessCard from "../../components/common/SuccessCard";
import { useEffect } from "react";

export default function EditFarmSuccess() {
  let navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    // if (!state && !state?.mobileNumber) {
    //     navigate('/welcome');
    // }
  }, []);

  return (
    <SuccessCard
      title="Saved!"
      buttonLabel="Manage Farm"
      onButtonPress={() => navigate("/farms")}
      subtitle1="Your new information have been
        collected and saved"
    />
  );
}

import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectProps,
} from "@mui/material";

interface IcustomStylesProps {
  borderRadius?: string;
  textAlign?: string;
  borderColor?: string;
  helperText?: string;
  label?: string;
  menuItems: JSX.Element[];
  shrink?: boolean;
  isHiddenBorder?: boolean;
}

export default function SelectCustom(props: SelectProps & IcustomStylesProps) {
  const {
    borderRadius,
    textAlign,
    borderColor,
    menuItems,
    label,
    helperText,
    error,
    shrink = false,
    isHiddenBorder = false,
    ...restProps
  } = props;
  return (
    <FormControl
      fullWidth
      error={error}
      style={{
        borderColor: borderColor || "#3777BC",
      }}
    >
      {label && (
        <InputLabel
          id={`label-${restProps?.name}`}
          shrink={shrink}
          sx={{
            bgcolor: "#FFFFFF !important",
            color: shrink ? "#3777BC" : "transparent",
            "&.Mui-focused": {
              color: "#3777BC",
            },
          }}
        >
          {label}
        </InputLabel>
      )}
      <Select
        {...restProps}
        variant="outlined"
        labelId={`label-${restProps?.name}`}
        fullWidth
        SelectDisplayProps={{
          ...restProps?.SelectDisplayProps,
        }}
        sx={{
          borderRadius: borderRadius || "28px",
          fontWeight: 500,
          color: "#191919",
          background: isHiddenBorder ? "transparent" : "#F7FBFF",
          borderColor: borderColor || "#3777BC",
          textAlign: textAlign || "",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#3777BC",
            ...(isHiddenBorder ? { border: "0px" } : {}),
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px !important",
            color: "#191919",
            borderColor: "#3777BC",
          },
          "& .Mui-disabled": {
            color: "#979797 !important",
            WebkitTextFillColor: "#979797 !important",
            bgcolor: "#FFFFFF !important",
          },
          "& input::placeholder": {
            opacity: 1,
            color: "#979797",
          },
        }}
      >
        {menuItems}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

import {
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
} from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { tw } from "../../../../../utils/tw";
import { Input } from "../../../../../admin/pages/group-area/views/Input";
import ButtonFillCustom from "../../../../../admin/components/Button/ButtonFillCustom";
import CrossIcon from "../../../../../admin/assets/svg/CrossIcon.svg";
import FilterListIcon from "@mui/icons-material/FilterList";
import RadioButton from "../../../../../components/common/RadioButton";
import { getCommaNumber } from "../../../../../utils/number";
import { cloneDeep, get, set } from "lodash";
import {
  Dropdown,
  TDropdownValue,
} from "../../../../../admin/pages/group-area/views/Dropdown";
import DatePickerFS from "../../../../../components/common/DatePicker";
import EventIcon from "@mui/icons-material/Event";

import dayjs from "dayjs";
import { useAcceptedAssignmentController } from "../../controller";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  notifyAssignment,
  updateAssignment,
} from "../../../../../services/Invoice.service";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../redux/store";
import { loadingActions } from "../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../utils/getErrorMessage";
import { alertAction } from "../../../../../redux/slices/alertSlice";
import { FILTER_DATE_FORMAT } from "../../../../../admin/pages/sale-order/components/SaleOrderSearchFilter";

interface IUpdateListInvoiceModal {
  isOpen: boolean;
  onCloseFn: () => void;
  InvoiceOption: TDropdownValue[];
  invoice: TDropdownValue[];
  setInvoice: Dispatch<SetStateAction<TDropdownValue[]>>;
  selectedId: string;
  RefetchDetailsAcceptedAssignment: () => void;
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  container: tw(
    `w-[100vw] m-auto [&_.MuiDialog-paperScrollPaper]:rounded-[40px] [&_.MuiButtonBase-root.Mui-selected]:!bg-[#FFFFFF]  [&_.MuiButtonBase-root.Mui-selected]:!text-[#000000] [&_.MuiDialog-paperScrollPaper]:!m-0 max-w-[313px]`
  ),
  titleContainer: tw(`flex items-center w-full justify-end`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  row: tw(
    `w-full flex flex-col md:flex-row item-center justify-between gap-x-3`
  ),
  button: tw(`!h-[59px] w-full !rounded-[50px] !text-[16px] !font-bold`),
  space: tw(`h-[12px]`),
};

const defaultForm = {
  PaymentDate: null,
  PaymentAmount: "",
};

const UpdateListInvoiceModal = (props: IUpdateListInvoiceModal) => {
  const {
    isOpen,
    onCloseFn,
    InvoiceOption,
    invoice,
    setInvoice,
    selectedId,
    RefetchDetailsAcceptedAssignment,
  } = props;

  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();

  const [form, setForm] = useState(defaultForm);

  const onChangeForm = useCallback(
    (key: keyof typeof form) => (value: any) => {
      setForm((prev) => ({ ...prev, [key]: value }));
    },
    []
  );

  const upDateMutation = useMutation({
    mutationFn: updateAssignment,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["details_accepted_update_assignment"],
      });
      RefetchDetailsAcceptedAssignment();
      setTimeout(() => {
        dispatch(loadingActions.hide());
        onClose();
        setTimeout(() => {
          dispatch(
            snakeActions.showMessage({
              message: "อัปเดตรายการสำเร็จ",
              type: "success",
            })
          );
        }, 500);
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const notifyMutation = useMutation({
    mutationFn: notifyAssignment,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["details_accepted_update_assignment"],
      });
      RefetchDetailsAcceptedAssignment();
      setTimeout(() => {
        dispatch(loadingActions.hide());
        onClose();
        setTimeout(() => {
          dispatch(
            snakeActions.showMessage({
              message: "ยืนยันติดตามสำเร็จ",
              type: "success",
            })
          );
        }, 500);
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const onSubmit = useCallback(() => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการอัปเดตรายการ",
        text: "คุณต้องการสร้างอัปเดตรายการนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          dispatch(alertAction.hide());
          dispatch(loadingActions.show({ message: "กำลังอัปเดตรายการ..." }));
          upDateMutation.mutate({
            assignmentId: selectedId,
            invoiceIds: invoice.map((id) => id.value),
            appointmentDate: dayjs(form.PaymentDate, FILTER_DATE_FORMAT)
              .startOf("day")
              .toDate(),
            paidAmount: +form.PaymentAmount,
          });
        },
      })
    );
  }, [selectedId, invoice, form]);

  const onNotify = useCallback(() => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการอัปเดตรายการ",
        text: "คุณต้องการสร้างอัปเดตรายการนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          dispatch(alertAction.hide());
          dispatch(loadingActions.show({ message: "กำลังอัปเดตรายการ..." }));
          notifyMutation.mutate({
            assignmentId: selectedId,
            invoiceIds: invoice.map((id) => id.value),
          });
        },
      })
    );
  }, [selectedId, invoice]);

  const onClose = useCallback(() => {
    onCloseFn();
    setInvoice([]);
    setForm(defaultForm);
  }, []);

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: "w-full" }}
    >
      <DialogContent className="w-[313px] flex flex-col gap-4">
        <div className={classes.titleContainer}>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </div>
        <div className="text-[#3777BC] text-[20px] font-semibold flex w-full justify-center">
          อัปเดตรายการ
        </div>
        <div className="flex flex-col gap-4">
          <Dropdown
            options={InvoiceOption}
            onChange={setInvoice}
            renderValueArray={invoice}
            shrink
            label2="เลข Invoice"
            switchStyle
            placeholder="เลข Invoice"
            containerSelect="pb-0"
          />
          <DatePickerFS
            value={form.PaymentDate}
            placeholder={`วันที่นัดชำระ`}
            slotProps={{
              textField: {
                // helperText: formErrorMessage(formik, "deliveryDate"),
                // error: formError(formik, "deliveryDate"),
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <EventIcon color="info" />
                    </InputAdornment>
                  ),
                },
              },
            }}
            label={"วันที่นัดชำระ"}
            onChange={onChangeForm("PaymentDate")}
            onClose={() => {}}
          />
          <Input
            label2="ยอดนัดชำระ"
            focused
            placeholder="ยอดนัดชำระ"
            value={form.PaymentAmount}
            onChangeText={onChangeForm("PaymentAmount")}
            inputType="number"
            customInputStyle={tw(
              "!h-[63px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
            )}
            borderRadius={"28px"}
            borderColor={"#3777BC !important"}
            customSuffix={
              <div className="pr-[5px] font-medium text-[16px] leading-[24px] text-[#D9EBFE]">
                | <span className="text-[#3777BC]">บาท</span>
              </div>
            }
          />
        </div>
        <ButtonFillCustom
          onClick={onSubmit}
          title="ยืนยัน"
          btnTextColor="#ffffff"
          btnBgColor="#68C184"
          className={classes.button}
        />
      </DialogContent>
    </Dialog>
  );
};

export default memo(UpdateListInvoiceModal);

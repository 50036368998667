import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Divider, Link, TableCell, TableRow } from "@mui/material";
import { FormEvent, useMemo, useState } from "react";
import TextInputSearchCustom from "../../components/Input/TextInputSearchCustom";
import MenuBar from "../../components/MenuBar/MenuBar";
import TableCustom, {
  ITableCustomHeadCell,
  Order,
} from "../../components/Table/TableCustom";
import { usePaginationFilter } from "../../hooks/usePaginationFilter.hook";
import { useManageStockController } from "./controller";
import { get, groupBy, map, sumBy, orderBy } from "lodash";

interface Data {
  id: string;
  partnerId: string;
  partnerName: string;
  remainingProduct: string;
}

const headCells: ITableCustomHeadCell<keyof Data>[] = [
  {
    id: "partnerId",
    numeric: false,
    disablePadding: false,
    label: "รหัสพาร์ทเนอร์",
    width: "150px",
    isSorting: true,
  },
  {
    id: "partnerName",
    numeric: false,
    disablePadding: false,
    label: "ชื่อพาร์ทเนอร์",
    width: "200px",
    isSorting: false,
  },
  {
    id: "remainingProduct",
    numeric: false,
    disablePadding: false,
    label: "จำนวนสินค้าคงเหลือ",
    width: "150px",
    isSorting: false,
  },
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "150px",
    isSorting: false,
  },
];

function ManageStock() {
  const {
    listPartnerData,
    searchText,
    onSearchText,
    onClearSearchText,
    currentPageManageStock,
    setCurrentPageManageStock,
    countDataManageStock,
  } = useManageStockController();
  const [orderBys, setOrderBys] = useState("");
  const [direction, setDirection] = useState<Order>("desc");

  const rowChildren = useMemo(() => {
    return listPartnerData?.result.rows.map((row, index) => {
      let userName = `${row.name} ${row.surname}`;

      const groupedByUnit = groupBy(row.stocks, "fbi.unit");

      const combinedUnits = map(groupedByUnit, (items, unit) => {
        return {
          unit: unit,
          totalAmount: sumBy(items, "amount"),
        };
      });

      const translatedUnits = combinedUnits.map((item) => {
        if (item.unit === "bag") {
          return { ...item, unit: "ถุง" };
        } else if (item.unit === "kg") {
          return { ...item, unit: "กิโลกรัม" };
        }
        return item;
      });

      const sortedUnits = orderBy(translatedUnits, ["unit"], ["desc"]);

      const totalAmountSum = sumBy(sortedUnits, "totalAmount");

      let resultString;
      if (totalAmountSum === 0) {
        resultString = "ไม่มีสินค้าคงเหลือ";
      } else {
        resultString = sortedUnits
          .map((item) => `${item.totalAmount} ${item.unit}`)
          .join(" + ");
      }

      return (
        <TableRow key={`manage-stock-${index}`} hover>
          <TableCell>{row.partnerCode}</TableCell>
          <TableCell>{userName}</TableCell>
          <TableCell>{resultString}</TableCell>
          <TableCell align="right">
            <Link
              variant="body2"
              href={`/admin/manage-stock/${row.id}`}
              color={"secondary"}
              fontWeight={500}
              paddingRight={"15px"}
            >
              {"ดูรายละเอียด"}
            </Link>
          </TableCell>
        </TableRow>
      );
    });
  }, [listPartnerData, currentPageManageStock]);

  return (
    <MenuBar>
      <div className="pt-16 w-full h-full relative font-sans">
        <div className="p-10">
          {/* Header */}
          <span className="text-[#000] font-bold text-3xl">จัดการสต็อก</span>
          <Divider sx={{ paddingTop: "12px" }} />
          {/* Search Box */}
          <div className="my-6 w-[500px]">
            {/* <form onSubmit={handleFetch}> */}
            <TextInputSearchCustom
              value={searchText}
              placeholder="ค้นหาโดยรหัสพาร์ทเนอร์, ชื่อพาร์ทเนอร์"
              onChange={onSearchText}
              InputProps={{
                endAdornment:
                  searchText.length > 0 ? (
                    <CloseRoundedIcon
                      sx={{ cursor: "pointer" }}
                      onClick={onClearSearchText}
                    />
                  ) : null,
              }}
            />
            {/* </form> */}
          </div>
          {/* Table */}
          <TableCustom
            order={direction}
            orderBy={""}
            totalItemSize={countDataManageStock}
            rowsPerPage={10}
            page={currentPageManageStock}
            headerItems={headCells}
            rows={listPartnerData?.result.rows || []}
            onChangePage={setCurrentPageManageStock}
            onChangeOrder={(val) => {
              setDirection(val);
            }}
            onChangeOrderBy={(val) => {
              setOrderBys(val);
            }}
            currentPageItems={(listPartnerData?.result.rows || []).map(
              ({ id }) => id
            )}
            itemsSelected={[]}
            onSelectItem={(_) => {}}
            onSelectAllClick={(_) => {}}
            hideSelect
            rowChildren={rowChildren}
          />
        </div>
      </div>
    </MenuBar>
  );
}

export default ManageStock;

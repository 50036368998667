import { MenuItem } from "@mui/material";
import { range } from "lodash";
import SelectAdminCustom from "../Select/Select";

interface IPageOfPagesProps {
  page: number;
  onChangePage: (page: number) => void;
  totalPage: number;
}
function PageOfPages({ page, totalPage, onChangePage }: IPageOfPagesProps) {
  return (
    <div className="flex flex-row gap-2 h-full items-center font-medium text-[14px]">
      <span>Page</span>
      <SelectAdminCustom
        value={page}
        renderValueArray={range(totalPage).map((row) => ({
          value: (row + 1).toString(),
          label: (row + 1).toString(),
        }))}
        onChange={(e) => onChangePage(e.target.value as number)}
        disabled={totalPage === 0}
        menuItems={range(totalPage).map((iPage) => (
          <MenuItem
            key={`page-item-${iPage}`}
            id={`${iPage}`}
            value={iPage + 1}
            sx={{
              fontFamily: "DM Sans",
              fontWeight: 500,
              fontSize: "14px",
            }}
          >
            {iPage + 1}
          </MenuItem>
        ))}
      />
      <span>of</span>
      <span>{totalPage}</span>
    </div>
  );
}

export default PageOfPages;

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import AppBarFS from "../../../../components/app-bar/AppBar";
import dayjs from "dayjs";
import {
  Dropdown,
  TDropdownValue,
} from "../../../../admin/pages/group-area/views/Dropdown";
import { Input } from "../../../../admin/pages/group-area/views/Input";
import { tw } from "../../../../utils/tw";
import { isEmpty } from "lodash";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { saveAccounting } from "../../../../services/BasicAccounting.service";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { loadingActions } from "../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../utils/getErrorMessage";
import { useBasicAccountingController } from "../controller";

const SaveBasicAccounting = () => {
  const { farmId } = useParams();
  const location = useLocation();

  const { form, onChangeForm, formSaveBasic, onChangeFormSaveBasic } =
    useBasicAccountingController(farmId || "");

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();

  const [selectMonth, setSelectMonth] = useState<TDropdownValue[]>(
    location.state.month
  );

  const SaveAccountingMutation = useMutation({
    mutationFn: saveAccounting,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["get-summary-basic-accounting-current-month"],
      });
      setTimeout(() => {
        dispatch(loadingActions.hide());
        navigate(-1);
        setTimeout(() => {
          dispatch(
            snakeActions.showMessage({
              message: "บันทึกสำเร็จ",
              type: "success",
            })
          );
        }, 500);
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const onSaveAccounting = useCallback(() => {
    dispatch(loadingActions.show({ message: "loading..." }));
    try {
      SaveAccountingMutation.mutate({
        farmId: farmId || "",
        christianYear: dayjs().year(),
        month: +form?.month[0]?.value,
        incomeFromPigSelling:
          formSaveBasic.incomeFromPigSelling !== ""
            ? +formSaveBasic.incomeFromPigSelling
            : null,
        geneticBill:
          formSaveBasic.geneticBill !== "" ? +formSaveBasic.geneticBill : null,
        feedBill:
          formSaveBasic.feedBill !== "" ? +formSaveBasic.feedBill : null,
        vaccineAndMedicineBill:
          formSaveBasic.vaccineAndMedicineBill !== ""
            ? +formSaveBasic.vaccineAndMedicineBill
            : null,
        staffBill:
          formSaveBasic.staffBill !== "" ? +formSaveBasic.staffBill : null,
        waterBill:
          formSaveBasic.waterBill !== "" ? +formSaveBasic.waterBill : null,
        electricityBill:
          formSaveBasic.electricityBill !== ""
            ? +formSaveBasic.electricityBill
            : null,
        transportationBill:
          formSaveBasic.transportationBill !== ""
            ? +formSaveBasic.transportationBill
            : null,
        programBill:
          formSaveBasic.programBill !== "" ? +formSaveBasic.programBill : null,
        otherBill:
          formSaveBasic.otherBill !== "" ? +formSaveBasic.otherBill : null,
      });
    } catch (e) {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(e),
            type: "error",
          })
        );
      }, 1500);
    }
  }, [formSaveBasic, farmId]);

  useEffect(() => {
    if (selectMonth) {
      onChangeForm("month")(selectMonth);
    }
  }, [selectMonth]);

  return (
    <div
      style={{
        height: "auto !important",
        backgroundSize: "cover",
        backgroundColor: "#FFFF",
      }}
      className="!h-auto !min-h-screen"
    >
      <div className="h-[120px] flex items-center bg-[#FFFF]">
        <AppBarFS title={"บัญชี"} onIconPress={() => navigate(-1)} />
      </div>

      <div className="bg-[#FFFF] px-4 py-4 flex flex-col gap-4">
        <div className="flex flex-row items-center justify-between">
          <div className="text-[#68C184] text-[16px] font-semibold">
            ปี {dayjs().year() + 543}
          </div>
          <div>
            <Dropdown
              options={[
                { label: "มกราคม", value: "1" },
                { label: "กุมภาพันธ์", value: "2" },
                { label: "มีนาคม", value: "3" },
                { label: "เมษายน", value: "4" },
                { label: "พฤษภาคม", value: "5" },
                { label: "มิถุนายน", value: "6" },
                { label: "กรกฎาคม", value: "7" },
                { label: "สิงหาคม", value: "8" },
                { label: "กันยายน", value: "9" },
                { label: "ตุลาคม", value: "10" },
                { label: "พฤศจิกายน", value: "11" },
                { label: "ธันวาคม", value: "12" },
              ]}
              placeholder="เดือน"
              onChange={(value) => setSelectMonth(value)}
              renderValueArray={selectMonth}
              choseStyle2
              containerDropdown="pb-0 text-center"
            />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <Input
            label2="รายได้การขายสุกร"
            focused
            placeholder="รายได้การขายสุกร"
            value={formSaveBasic.incomeFromPigSelling}
            onChangeText={onChangeFormSaveBasic("incomeFromPigSelling")}
            inputType="number"
            customInputStyle={tw(
              "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
            )}
            containerDetailClassName="pb-0"
            borderRadius={"28px"}
            borderColor={"#3777BC !important"}
            customSuffix={
              <div className="pr-[5px] font-medium text-[16px] leading-[24px] text-[#D9EBFE]">
                | <span className="text-[#3777BC]">บาท</span>
              </div>
            }
          />
          <Input
            label2="ค่าพันธุ์สัตว์"
            focused
            placeholder="ค่าพันธุ์สัตว์"
            value={formSaveBasic.geneticBill}
            onChangeText={onChangeFormSaveBasic("geneticBill")}
            inputType="number"
            customInputStyle={tw(
              "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
            )}
            containerDetailClassName="pb-0"
            borderRadius={"28px"}
            borderColor={"#3777BC !important"}
            customSuffix={
              <div className="pr-[5px] font-medium text-[16px] leading-[24px] text-[#D9EBFE]">
                | <span className="text-[#3777BC]">บาท/เดือน</span>
              </div>
            }
          />
          <Input
            label2="ค่าอาหาร"
            focused
            placeholder="ค่าอาหาร"
            value={formSaveBasic.feedBill}
            onChangeText={onChangeFormSaveBasic("feedBill")}
            inputType="number"
            customInputStyle={tw(
              "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
            )}
            containerDetailClassName="pb-0"
            borderRadius={"28px"}
            borderColor={"#3777BC !important"}
            customSuffix={
              <div className="pr-[5px] font-medium text-[16px] leading-[24px] text-[#D9EBFE]">
                | <span className="text-[#3777BC]">บาท/เดือน</span>
              </div>
            }
          />
          <Input
            label2="ค่ายาเเละวัคซีน"
            focused
            placeholder="ค่ายาเเละวัคซีน"
            value={formSaveBasic.vaccineAndMedicineBill}
            onChangeText={onChangeFormSaveBasic("vaccineAndMedicineBill")}
            inputType="number"
            customInputStyle={tw(
              "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
            )}
            containerDetailClassName="pb-0"
            borderRadius={"28px"}
            borderColor={"#3777BC !important"}
            customSuffix={
              <div className="pr-[5px] font-medium text-[16px] leading-[24px] text-[#D9EBFE]">
                | <span className="text-[#3777BC]">บาท/เดือน</span>
              </div>
            }
          />
          <Input
            label2="ค่าพนักงาน"
            focused
            placeholder="ค่าพนักงาน"
            value={formSaveBasic.staffBill}
            onChangeText={onChangeFormSaveBasic("staffBill")}
            inputType="number"
            customInputStyle={tw(
              "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
            )}
            containerDetailClassName="pb-0"
            borderRadius={"28px"}
            borderColor={"#3777BC !important"}
            customSuffix={
              <div className="pr-[5px] font-medium text-[16px] leading-[24px] text-[#D9EBFE]">
                | <span className="text-[#3777BC]">บาท/เดือน</span>
              </div>
            }
          />
          <Input
            label2="ค่าน้ำ"
            focused
            placeholder="ค่าน้ำ"
            value={formSaveBasic.waterBill}
            onChangeText={onChangeFormSaveBasic("waterBill")}
            inputType="number"
            customInputStyle={tw(
              "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
            )}
            containerDetailClassName="pb-0"
            borderRadius={"28px"}
            borderColor={"#3777BC !important"}
            customSuffix={
              <div className="pr-[5px] font-medium text-[16px] leading-[24px] text-[#D9EBFE]">
                | <span className="text-[#3777BC]">บาท/เดือน</span>
              </div>
            }
          />
          <Input
            label2="ค่าไฟ"
            focused
            placeholder="ค่าไฟ"
            value={formSaveBasic.electricityBill}
            onChangeText={onChangeFormSaveBasic("electricityBill")}
            inputType="number"
            customInputStyle={tw(
              "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
            )}
            containerDetailClassName="pb-0"
            borderRadius={"28px"}
            borderColor={"#3777BC !important"}
            customSuffix={
              <div className="pr-[5px] font-medium text-[16px] leading-[24px] text-[#D9EBFE]">
                | <span className="text-[#3777BC]">บาท/เดือน</span>
              </div>
            }
          />
          <Input
            label2="ค่าขนส่ง"
            focused
            placeholder="ค่าขนส่ง"
            value={formSaveBasic.transportationBill}
            onChangeText={onChangeFormSaveBasic("transportationBill")}
            inputType="number"
            customInputStyle={tw(
              "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
            )}
            containerDetailClassName="pb-0"
            borderRadius={"28px"}
            borderColor={"#3777BC !important"}
            customSuffix={
              <div className="pr-[5px] font-medium text-[16px] leading-[24px] text-[#D9EBFE]">
                | <span className="text-[#3777BC]">บาท/เดือน</span>
              </div>
            }
          />
          <Input
            label2="ค่าโปรเเกรม"
            focused
            placeholder="ค่าโปรเเกรม"
            value={formSaveBasic.programBill}
            onChangeText={onChangeFormSaveBasic("programBill")}
            inputType="number"
            customInputStyle={tw(
              "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
            )}
            containerDetailClassName="pb-0"
            borderRadius={"28px"}
            borderColor={"#3777BC !important"}
            customSuffix={
              <div className="pr-[5px] font-medium text-[16px] leading-[24px] text-[#D9EBFE]">
                | <span className="text-[#3777BC]">บาท/เดือน</span>
              </div>
            }
          />
          <Input
            label2="ค่าใช้จ่ายอื่นๆ"
            focused
            placeholder="ค่าใช้จ่ายอื่นๆ"
            value={formSaveBasic.otherBill}
            onChangeText={onChangeFormSaveBasic("otherBill")}
            inputType="number"
            customInputStyle={tw(
              "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
            )}
            containerDetailClassName="pb-0"
            borderRadius={"28px"}
            borderColor={"#3777BC !important"}
            customSuffix={
              <div className="pr-[5px] font-medium text-[16px] leading-[24px] text-[#D9EBFE]">
                | <span className="text-[#3777BC]">บาท/เดือน</span>
              </div>
            }
          />
        </div>
        <div className="flex w-full pb-[24px] py-[12px]">
          <button
            className="rounded-[50px] bg-[#68C184] text-[white] w-full h-[59px] font-[700] text-[16px] px-[13.5px] py-[4.5px] gap-[4.5px]"
            disabled={isEmpty(formSaveBasic)}
            onClick={onSaveAccounting}
          >
            บันทึก
          </button>
        </div>
      </div>
    </div>
  );
};

export default SaveBasicAccounting;

export default function WalletBgSvg() {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.25" width="40" height="40" rx="20" fill="#D32F2F" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6484 12.8008C11.5439 12.8008 10.6484 13.6962 10.6484 14.8008V20.4008C10.6484 21.5054 11.5439 22.4008 12.6484 22.4008H13.0484V15.2008H25.0485V14.8008C25.0485 13.6962 24.153 12.8008 23.0485 12.8008H12.6484ZM15.4484 19.6008C15.4484 18.4962 16.3439 17.6008 17.4484 17.6008H27.8485C28.953 17.6008 29.8485 18.4962 29.8485 19.6008V25.2008C29.8485 26.3054 28.953 27.2008 27.8485 27.2008H17.4484C16.3439 27.2008 15.4484 26.3054 15.4484 25.2008V19.6008ZM22.6485 24.8008C23.9739 24.8008 25.0485 23.7263 25.0485 22.4008C25.0485 21.0753 23.9739 20.0008 22.6485 20.0008C21.323 20.0008 20.2485 21.0753 20.2485 22.4008C20.2485 23.7263 21.323 24.8008 22.6485 24.8008Z"
        fill="white"
      />
    </svg>
  );
}

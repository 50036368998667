import { memo, useCallback, useState } from "react";
import { tw } from "../../../../../../utils/tw";
import { Tab, TableCell } from "@mui/material";
import Tabs from "../../../../group-area/views/Tabs";
import { AccordionFillData } from "./AccordionFillData";
import { ICreditFeedData } from "../../../../../../types/CreditFeed.type";
import { get } from "lodash";
import { getCommaNumber } from "../../../../../../utils/number";
import DownloadLinkWithDelete from "../../../../../../components/common/File/DownloadLinkWithDelete";
import { getDownloadUrl } from "../../../../../../services/CreditFeed.service";
import { handleDownloadBlob } from "../../../../../../utils/downloadFile";
import axios from "axios";
import ExpandUpdateLog from "./ExpandUpdateLog";
import dayjs from "dayjs";

type Props = {
  data: ICreditFeedData;
  isShowButton?: boolean;
  button?: React.ReactNode;
  isShowButtonImport?: boolean;
  buttonImport?: React.ReactNode;
  isCreditStatus?: boolean;
  isFarmDetail?: boolean;
};

const type = {
  personal: "บุคคลธรรมดา/คณะบุคคล",
  legal: "นิติบุลคล",
  "": "",
};

const contractType = {
  pay_after_expire: "จ่ายหลังหมดสัญญา",
  fill_credit: "จ่ายตาม invoice",
  "": "",
};

const classes = {
  row: tw(
    `w-full flex flex-col md:flex-row item-start justify-between gap-x-3 pt-6`
  ),
  row2: tw(
    `w-full flex flex-col md:flex-row item-start justify-between gap-x-3 pt-2`
  ),
  space: tw(`h-3`),
  space2: tw(`h-6`),
  container: tw(
    `w-[100vw] !max-w-[1311px] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  text: tw(`font-sans font-bold text-[16px] leading-5`),
};

const FillDataExpand = (props: Props) => {
  const {
    data,
    isShowButton,
    button,
    isShowButtonImport,
    buttonImport,
    isCreditStatus,
    isFarmDetail,
  } = props;

  const [tabIndex, setTabIndex] = useState(0);

  const onChangeTab = useCallback((e: any, index: number) => {
    setTabIndex(index);
  }, []);

  const getShowDataGeneralFarmInformation = () => {
    return get(data, ["generalFarmInformation"], []).map((info) => {
      if (info) {
        return {
          label: info.label,
          value: info.option || "-",
        };
      } else {
        return [];
      }
    });
  };

  const getShowDataFarmPerformance = () => {
    return get(data, ["farmPerformance"], []).map((info) => {
      if (info) {
        return {
          label: info.label,
          value: info.option || "-",
        };
      } else {
        return [];
      }
    });
  };

  const getShowDataFarmFinancialStatusAndInstallment = () => {
    return get(data, ["farmFinancialStatusAndInstallment"], []).map((info) => {
      if (info) {
        return {
          label: info.label,
          value: info.option || "-",
        };
      } else {
        return [];
      }
    });
  };

  const getShowDataApplicationUsage = () => {
    return get(data, ["applicationUsage"], []).map((info) => {
      if (info) {
        return {
          label: info.label,
          value: info.option || "-",
        };
      } else {
        return [];
      }
    });
  };

  const handleDownloadByUrl = useCallback(
    (id: string, fileId: string, name: string) => async () => {
      const preSignURL = await getDownloadUrl(id, fileId);
      if (preSignURL) {
        const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
          responseType: "blob",
        });
        const objectURL = window.URL.createObjectURL(response.data);
        handleDownloadBlob(objectURL, name);
      }
    },
    [
      get(data, ["creditInformation", "files"], []),
      get(data, ["creditInformation", "citizenDocument"], []),
      get(data, ["creditInformation", "creditBureauDocument"], []),
    ]
  );

  return (
    <TableCell
      colSpan={12}
      sx={{
        background: !isFarmDetail ? "#F6FBFF" : "#FFFFFF",
        padding: !isFarmDetail ? "24px" : "0px",
        borderRight: !isFarmDetail ? "1px solid rgba(224, 224, 224, 1)" : "",
        borderLeft: !isFarmDetail ? "1px solid rgba(224, 224, 224, 1)" : "",
        borderBottom: "0px",
      }}
    >
      <div className={!isFarmDetail ? "w-full h-full bg-[#FFFFFF] p-6" : ""}>
        {!isFarmDetail && (
          <Tabs tabIndex={tabIndex} onChange={onChangeTab}>
            <Tab label="รายละเอียดการให้เครดิต" />
            <Tab label="บันทึกกิจกรรม" />
          </Tabs>
        )}
        {tabIndex === 0 && (
          <div className="flex flex-col gap-6 pt-6">
            {!isFarmDetail && (
              <div className="flex justify-between items-center">
                <span className="text-lg font-bold text-secondary">
                  รายละเอียดการให้เครดิต
                </span>
                {isShowButton && <>{button && button}</>}
              </div>
            )}
            <div className="flex justify-between items-center">
              <div className={classes.text}>
                {data.creditSettingName || "-"}
              </div>
              {isFarmDetail && (
                <div>
                  <p className="font-sans text-[12px] font text-[#7E7E7E]">
                    อัปเดตล่าสุด
                  </p>
                  <p className="font-sans text-[12px] font-bold">{`${
                    data.updatedBy?.name
                  } ${dayjs(data.updatedAt).format("DD/MM/YYYY")}`}</p>
                </div>
              )}
              {isShowButtonImport && <>{buttonImport && buttonImport}</>}
            </div>
            {isCreditStatus && (
              <div className="flex flex-row gap-6">
                <div className="flex flex-row gap-6 text-[#1B2128] text-[14px] w-full">
                  <div className="w-[143px] font-bold">วันเริ่มสัญญา</div>
                  <div className="font-normal ">
                    {dayjs(get(data, ["startDate"], "")).isValid()
                      ? dayjs(get(data, ["startDate"], "")).format("DD/MM/YYYY")
                      : "-"}
                  </div>
                </div>
                <div className="flex flex-row gap-6 text-[#1B2128] text-[14px] w-full">
                  <div className="w-[143px] font-bold">วันสิ้นสุดสัญญา</div>
                  <div className="font-normal">
                    {dayjs(get(data, ["expireDate"], "")).isValid()
                      ? dayjs(get(data, ["expireDate"], "")).format(
                          "DD/MM/YYYY"
                        )
                      : "-"}
                  </div>
                </div>
              </div>
            )}
            <AccordionFillData
              accordionTitle="General"
              showData={[
                {
                  label: "ประเภทธุรกิจ",
                  value: type[get(data, ["farmerType"], "")] || "-",
                },
                {
                  label: "ประเภทนิติบุคคล",
                  value: data.generalLegalInformation?.[0]?.option || "-",
                },
                {
                  label: "ประเภทบุคคลธรรมดา/คณะบุคคล",
                  value: data.generalPersonalInformation?.[0]?.option || "-",
                },
                {
                  label: "อายุ",
                  value: data.generalPersonalInformation?.[1]?.option || "-",
                },
                ...(getShowDataGeneralFarmInformation() as {
                  label: string;
                  value: string;
                }[]),
              ]}
            />
            {getShowDataFarmPerformance().length > 0 && (
              <AccordionFillData
                accordionTitle="Farm Performance"
                showData={[
                  ...(getShowDataFarmPerformance() as {
                    label: string;
                    value: string;
                  }[]),
                ]}
              />
            )}
            {getShowDataFarmFinancialStatusAndInstallment().length > 0 && (
              <AccordionFillData
                accordionTitle="Farm Financial Status & Installment"
                showData={[
                  ...(getShowDataFarmFinancialStatusAndInstallment() as {
                    label: string;
                    value: string;
                  }[]),
                ]}
              />
            )}
            {getShowDataApplicationUsage().length > 0 && (
              <AccordionFillData
                accordionTitle="Application Usage"
                showData={[
                  ...(getShowDataApplicationUsage() as {
                    label: string;
                    value: string;
                  }[]),
                ]}
              />
            )}
            {!isFarmDetail ? (
              <>
                <AccordionFillData
                  accordionTitle="Credit Information"
                  showData={[
                    {
                      label: "ขนาดฟาร์ม",
                      value:
                        data.creditInformation.farmSize.toLocaleUpperCase() ||
                        "",
                    },
                    {
                      label: "ฟาร์ม",
                      value: data.farmName || "",
                    },
                    { label: "โรงเรือน", value: data.houseName || "" },
                    { label: "ประเภทหมู", value: "หมูขุน" },
                    {
                      label: "จำนวนหมู",
                      value:
                        getCommaNumber(+get(data, ["fattenerPigCount"], 0)) +
                          " ตัว" || "",
                    },
                    {
                      label: "เกรดลูกค้า",
                      value: data.customerGrade.toLocaleUpperCase() || "",
                    },
                    {
                      label: "ประเภทสัญญา",
                      value:
                        contractType[
                          get(data, ["creditInformation", "contractType"], "")
                        ],
                    },
                    {
                      label: "ระยะเครดิต",
                      value:
                        getCommaNumber(
                          +get(data, ["creditInformation", "creditPeriod"], "")
                        ) + " วัน" || "",
                    },
                    {
                      label: "วงเงินลูกค้าที่คำนวณจากระบบ",
                      value:
                        getCommaNumber(
                          +get(
                            data,
                            ["creditInformation", "calculatedCreditAmount"],
                            0
                          ),
                          true
                        ) + " บาท" || "",
                    },
                    {
                      label: "วงเงินที่นำเสนอลูกค้า",
                      value:
                        getCommaNumber(
                          +get(data, ["creditInformation", "creditAmount"], ""),
                          true
                        ) + " บาท" || "",
                    },
                    {
                      label: "เหตุผล",
                      value: get(data, ["creditInformation", "reason"], ""),
                    },
                  ]}
                />
                {get(data, ["creditInformation", "files"], []).length > 0 && (
                  <div className="flex flex-col gap-3">
                    <div
                      className={tw(
                        "text-[#7E7E7E] font-medium text-[14px] font-sans",
                        "text-[#000000]"
                      )}
                    >
                      เอกสารการคำนวณเครดิต
                    </div>
                    {get(data, ["creditInformation", "files"]) &&
                      get(data, ["creditInformation", "files"], [])?.map(
                        (row) => {
                          return (
                            <DownloadLinkWithDelete
                              hideDeleteBtn
                              onDownload={handleDownloadByUrl(
                                data.id,
                                row.id,
                                row.fileName
                              )}
                              name={row.fileName}
                              key={row.id}
                            />
                          );
                        }
                      )}
                  </div>
                )}
                {get(data, ["creditInformation", "citizenDocument"], [])
                  .length > 0 && (
                  <div className="flex flex-col gap-3">
                    <div
                      className={tw(
                        "text-[#7E7E7E] font-medium text-[14px] font-sans",
                        "text-[#000000]"
                      )}
                    >
                      รูปบัตรประชาชน
                    </div>
                    {get(data, ["creditInformation", "citizenDocument"]) &&
                      get(
                        data,
                        ["creditInformation", "citizenDocument"],
                        []
                      )?.map((row) => {
                        return (
                          <DownloadLinkWithDelete
                            hideDeleteBtn
                            onDownload={handleDownloadByUrl(
                              data.id,
                              row.id,
                              row.fileName
                            )}
                            name={row.fileName}
                            key={row.id}
                          />
                        );
                      })}
                  </div>
                )}
                {get(data, ["creditInformation", "creditBureauDocument"], [])
                  .length > 0 && (
                  <div className="flex flex-col gap-3">
                    <div
                      className={tw(
                        "text-[#7E7E7E] font-medium text-[14px] font-sans",
                        "text-[#000000]"
                      )}
                    >
                      เอกสารเครดิตบูโร
                    </div>
                    {get(data, ["creditInformation", "creditBureauDocument"]) &&
                      get(
                        data,
                        ["creditInformation", "creditBureauDocument"],
                        []
                      )?.map((row) => {
                        return (
                          <DownloadLinkWithDelete
                            hideDeleteBtn
                            onDownload={handleDownloadByUrl(
                              data.id,
                              row.id,
                              row.fileName
                            )}
                            name={row.fileName}
                            key={row.id}
                          />
                        );
                      })}
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        )}
        {tabIndex === 1 && (
          <>
            <ExpandUpdateLog
              key={""}
              id={""}
              contractLogModel={get(data, ["contractLogs"], [])}
            />
          </>
        )}
      </div>
    </TableCell>
  );
};

export default memo(FillDataExpand);

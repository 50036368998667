import adminAPI from "../../../../../services/AdminApiService";
import { getDownloadUrlResponse } from "../../../../../services/admin/invoice/type";
import { IMoneyDebtDoc } from "../components/Modals/UpdateDocMoneyDebtModal";
import {
  IExportExcelSaleDebt,
  IGetAcceptDebt,
  IGetAcceptDebtCount,
  IGetMoneyDebtCountSale,
} from "../types/acceptDebt.type";

export const getAcceptDebt = async (searchParams?: URLSearchParams) => {
  try {
    const { data } = await adminAPI.get<IGetAcceptDebt>(
      `/money-debt/sale/accept-debt?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getMoneyDebtUploadUrl = async (
  invoiceId: string,
  documentType: string
) => {
  try {
    const response = await adminAPI.get<getDownloadUrlResponse>(
      `/money-debt/sale/${invoiceId}/upload/${documentType}`
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log("getInvoiceDownloadUrlAPI", error);
  }
};

export const editMoneyDebtDoc = async (
  invoiceId: string,
  body: IMoneyDebtDoc
) => {
  return adminAPI.put(
    `/money-debt/sale/${invoiceId}/update/debt_acknowledgement_letter`,
    body
  );
};

export const getMoneyDebtCountSale = async () => {
  try {
    const { data } = await adminAPI.get<IGetMoneyDebtCountSale>(
      `/money-debt/sale/count`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getAcceptDebtCountSale = async (tabName: string) => {
  try {
    const { data } = await adminAPI.get<IGetAcceptDebtCount>(
      `/money-debt/sale/count/${tabName}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const exportExcelSaleDebt = async (tabName: string, ids: string[]) => {
  try {
    const { data } = await adminAPI.post<IExportExcelSaleDebt>(
      `/money-debt/sale/accept-debt/export`,
      { ids }
    );
    return data;
  } catch (error) {
    return null;
  }
};

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import BackgrounImage from "../../assets/images/Login/BackGroungImage.png";
import FrameICon from "../../assets/images/Login/FrameIcon.png";
import CustomButton from "../../components/common/Button";
import LoginAuthenticationPage from "../authentication/loginAuthentication";

const SignupPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      style={{
        backgroundImage: `url(${BackgrounImage})`,
        height: "100vh",
        backgroundSize: "cover",
        backgroundColor: "#4882c8",
      }}
      className="main-container"
    >
      <div
        className="flex justify-center flex-col rounded-b-[45px] bg-[#ffffff] h-[25%]"
        onClick={() => isOpen && setIsOpen(false)}
      >
        <img
          src={FrameICon}
          className="mb-[20px] w-[50%] max-w-[400px] ml-[28px]"
        />
        <div className="flex items-center text-left w-[100%] px-[20px]">
          <IconButton onClick={() => navigate(-1)}>
            <NavigateBeforeIcon className="!text-[35px] text-[#3777BC]" />
          </IconButton>
          <h2 className="text-[#3777BC] text-[30px] font-[500] ">
            {t("signup_page.app_bar_heading") || ""}
          </h2>
        </div>
      </div>
      <div className="h-[75%]">
        <div className="flex items-center flex-col relative top-[20%] max-w-[400px] mx-auto">
          <div className="w-full p-3">
            <CustomButton
              variant="quaternary"
              label={t("signup_page.button_registration_farmer") || ""}
              size="large"
              onClick={() => navigate("/signup")}
            />
          </div>
          <div className="w-full p-3">
            <CustomButton
              variant="secondary"
              label={t("signup_page.button_registration_partner") || ""}
              size="large"
              onClick={() => setIsOpen(!isOpen)}
              disabled={true}
            />
          </div>
          <div className="w-full p-3">
            <CustomButton
              variant="secondary"
              label={t("signup_page.button_registration_admin") || ""}
              size="large"
              disabled={true}
              onClick={() => setIsOpen(!isOpen)}
            />
          </div>
        </div>
        <div className="fixed bottom-[12%] w-[100%] text-center">
          <p className="text-[14px] text-[#012055] font-[400]">
            {t("set_pin.new_user_signup")}{" "}
            <Link to="/login" className="font-[500] font-bold underline">
              {t("login.link_login_now")}
            </Link>
          </p>
        </div>
        <div className="fixed bottom-[1%] w-[100%]">
          <p className="text-[10px] text-[#000000] font-[400] text-center">
            {t("signup_page.copyrightText")}
          </p>
        </div>
      </div>
      {isOpen && <LoginAuthenticationPage isOpen={isOpen} />}
    </div>
  );
};

export default SignupPage;

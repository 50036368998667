import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import dayjs from "dayjs";
import { IGetStocks } from "../../../../../types/ManageStock.type";
import { DATE_FORMAT_DATE_PICKER2 } from "../../../../../const/app.constant";
import { UnitEnum } from "../../../../../types/FeedBrand.type";

type Props = {
  stockExpand: IGetStocks;
};

const ExpandStockTable = ({ stockExpand }: Props) => {
  return (
    <TableCell colSpan={11} sx={{ bgcolor: "#F6FBFF" }}>
      <div className="w-3/4 h-full px-6 py-2">
        <div className="px-[40px] py-[24px] bg-[#FFFFFF] rounded-[20px]">
          <TableContainer
            sx={{
              scrollBehavior: "smooth",
              overflow: "auto",
            }}
          >
            <Table
              sx={{
                width: "100%",
              }}
              aria-labelledby="table-show-data"
              size={"medium"}
            >
              <TableHead
                sx={{
                  bgcolor: "#FBFBFB",
                  borderBottom: "1px solid #E1E1E1",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      height: "50px",
                      justifyContent: "start",
                      fontFamily: "IBM Plex Sans Thai",
                      fontWeight: 700,
                      fontSize: "14px",
                      border: "0px",
                      padding: "0px 16px",
                    }}
                  >
                    วันที่รับสินค้า
                  </TableCell>
                  <TableCell
                    sx={{
                      height: "50px",
                      justifyContent: "start",
                      fontFamily: "IBM Plex Sans Thai",
                      fontWeight: 700,
                      fontSize: "14px",
                      border: "0px",
                      padding: "0px 16px",
                    }}
                    align="left"
                  >
                    จำนวนสินค้า
                  </TableCell>
                  <TableCell
                    sx={{
                      height: "50px",
                      justifyContent: "start",
                      fontFamily: "IBM Plex Sans Thai",
                      fontWeight: 700,
                      fontSize: "14px",
                      border: "0px",
                      padding: "0px 16px",
                    }}
                    align="left"
                  >
                    จำนวนสินค้าคงเหลือ
                  </TableCell>
                  <TableCell
                    sx={{
                      height: "50px",
                      justifyContent: "start",
                      fontFamily: "IBM Plex Sans Thai",
                      fontWeight: 700,
                      fontSize: "14px",
                      border: "0px",
                      padding: "0px 16px",
                    }}
                    align="left"
                  >
                    อายุสินค้าในคลัง
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stockExpand.remaining.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.createdAt
                        ? dayjs(row.createdAt).format(DATE_FORMAT_DATE_PICKER2)
                        : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {row.receiveAmount
                        ? row.receiveAmount +
                          " " +
                          UnitEnum[row.unit as keyof typeof UnitEnum]
                        : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {row.remainingAmount
                        ? row.remainingAmount +
                          " " +
                          UnitEnum[row.unit as keyof typeof UnitEnum]
                        : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {row.shelfLife ? row.shelfLife + " วัน" : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </TableCell>
  );
};

export default ExpandStockTable;

import adminAPI from "../../../../services/AdminApiService";
import { Medicine } from "../pages/medicine/Modals/EditMedicine";

import {
  IGetCategoryMedicine,
  IGetMedicine,
  TCreateMedicineForm,
} from "../types/medicine.type";

export const getCategoryMedicine = async () => {
  try {
    const { data } = await adminAPI.get<IGetCategoryMedicine>(
      `/master-data/medicine/category`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getMedicineMasterData = async (searchParams?: URLSearchParams) => {
  try {
    const { data } = await adminAPI.get<IGetMedicine>(
      `/master-data/medicine?${searchParams}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const createMedicine = async (payload: TCreateMedicineForm) => {
  try {
    const { data } = await adminAPI.post<TCreateMedicineForm>(
      `/master-data/medicine`,
      payload
    );
    return data;
  } catch (error) {
    return null;
  }
};
export const editMedicine = async (
  payloadEdit: Medicine[],
  MedicineIds: string[]
) => {
  try {
    const { data } = await adminAPI.put<TCreateMedicineForm>(
      `/master-data/medicine`,
      { updateMedicines: payloadEdit, deleteMedicineIds: MedicineIds }
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const toggleMedicine = async (body: {
  medicines: {
    id: string;
    isActive: boolean;
  }[];
}) => {
  return adminAPI.put("/master-data/medicine/toggle", body);
};

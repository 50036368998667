import {
  getAllHousesOfFarm,
  getFarmDashboardStatus,
  getFarmDetail,
} from "../../services/FarmService";

export async function farmDashboardLoader({ params }: any) {
  try {
    const { farmId } = params;
    if (!farmId) {
      throw new Response("Not Found", { status: 404 });
    }

    const [farmDetailResp, housesResp, farmStatus] = await Promise.all([
      getFarmDetail(farmId),
      getAllHousesOfFarm(farmId),
      getFarmDashboardStatus(farmId),
    ]);

    const { data } = farmDetailResp;
    const { data: dataHouses } = housesResp;
    const { data: farmStatusData } = farmStatus;
    if (data && data.farm) {
      return {
        farm: data.farm,
        houses: (dataHouses?.houses?.length && dataHouses?.houses) || [],
        farmStatus: farmStatusData,
      };
    } else {
      throw new Response("Not Found", { status: 404 });
    }
  } catch (err) {
    throw new Response("Not Found", { status: 404 });
  }
}

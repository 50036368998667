import {
  Backdrop,
  Box,
  Fade,
  Modal,
  SvgIcon,
  SxProps,
  Theme,
} from "@mui/material";
import ExclamationCircle from "../../../assets/svg/ExclamationCircle";
import ButtonFillCustom from "../Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../Button/ButtonOutlinedCustom";
import { tw } from "../../../utils/tw";
import CrossIcon from "../../../assets/images/feed/close.svg";

const style: SxProps<Theme> = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "350px",
  bgcolor: "background.paper",
  boxShadow: 12,
  padding: "24px",
  borderRadius: "12px",
};

const classes = {
  container: tw(`flex flex-col min-h-[280px]`),
  title: tw(
    `text-warning text-[24px] leading-[28.8px] text-center font-medium mb-[16px] whitespace-pre-line`
  ),
  text: tw(
    `text-[16px] leading-[20.8px] text-[#646464] text-center text-balance whitespace-pre-line`
  ),
  confirmButton: tw(
    `w-full p-[20px] flex justify-center items-center text-center text-[white] bg-secondary border-[1px] border-secondary rounded-full text-[16px] leading-[19.2px] font-bold hover:brightness-110 transition-all duration-150`
  ),
};

interface ICommonConfirmModalProps {
  direction: "flex-row" | "flex-col" | "flex-col-reverse" | "flex-row-reverse";
  isFullButtonStyle: boolean;
  header: string;
  body: string;
  cancelBtnText: string;
  confirmBtnText?: string;
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

function CommonConfirmModal({
  direction = "flex-col",
  isFullButtonStyle,
  header,
  body,
  cancelBtnText,
  confirmBtnText = "ยืนยัน",
  open,
  onClose,
  onSubmit,
}: ICommonConfirmModalProps) {
  return (
    <Modal
      aria-labelledby="confirm-modal-title"
      aria-describedby="confirm-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box
          sx={{ ...style, borderRadius: !isFullButtonStyle ? "12px" : "40px" }}
        >
          {!isFullButtonStyle ? (
            <div className="w-full h-full">
              <div className="flex items-center gap-1">
                <SvgIcon sx={{ color: "#68C184" }}>
                  <ExclamationCircle />
                </SvgIcon>
                <span className="font-sans font-bold text-[1rem] text-[#191919]">
                  {header}
                </span>
              </div>
              <div className="pt-6 flex flex-col">
                <span className="font-sans font-medium text-[14px] leading-5 text-[#191919]">
                  {body}
                </span>
                <span className="font-sans font-bold text-[14px] leading-5 text-[#191919]">
                  การดำเนินการนี้ไม่สามารถยกเลิกได้
                </span>
              </div>
              <div className="flex w-full justify-end gap-2 pt-6">
                <ButtonOutlinedCustom
                  title={cancelBtnText}
                  btnBorderColor="#68C184"
                  btnTextColor="#68C184"
                  onClick={onClose}
                />
                <ButtonFillCustom title={confirmBtnText} onClick={onSubmit} />
              </div>
            </div>
          ) : (
            <>
              <div className="flex justify-end">
                <img
                  className="cursor-pointer"
                  onClick={onClose}
                  src={CrossIcon}
                  alt="close-button"
                />
              </div>
              <div className={classes.container}>
                <div className={classes.title}>{header}</div>
                <div className={classes.text}>{body}</div>
                <div className="flex flex-1" />
                {!cancelBtnText ? (
                  <button onClick={onSubmit} className={classes.confirmButton}>
                    {confirmBtnText}
                  </button>
                ) : (
                  <div
                    className={tw(
                      "flex w-full p-[20px] items-center justify-between max-w-[450px] gap-y-[24px]",
                      direction
                    )}
                  >
                    <button
                      onClick={onSubmit}
                      className={tw(
                        classes.confirmButton,
                        "bg-[white] text-secondary border-[1px] border-secondary"
                      )}
                    >
                      {confirmBtnText}
                    </button>
                    <button onClick={onClose} className={classes.confirmButton}>
                      {cancelBtnText}
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </Box>
      </Fade>
    </Modal>
  );
}
export default CommonConfirmModal;

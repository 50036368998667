export function PartnerSvg() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1665 17.2122H18.3332V15.5456C18.3332 14.1649 17.2139 13.0456 15.8332 13.0456C15.0368 13.0456 14.3275 13.4179 13.8696 13.998M14.1665 17.2122H5.83317M14.1665 17.2122V15.5456C14.1665 14.9987 14.0612 14.4764 13.8696 13.998M5.83317 17.2122H1.6665V15.5456C1.6665 14.1649 2.78579 13.0456 4.1665 13.0456C4.96284 13.0456 5.67222 13.4179 6.13004 13.998M5.83317 17.2122V15.5456C5.83317 14.9987 5.93852 14.4764 6.13004 13.998M6.13004 13.998C6.74442 12.4631 8.24552 11.3789 9.99984 11.3789C11.7542 11.3789 13.2553 12.4631 13.8696 13.998M12.4998 6.37891C12.4998 7.75962 11.3805 8.87891 9.99984 8.87891C8.61913 8.87891 7.49984 7.75962 7.49984 6.37891C7.49984 4.99819 8.61913 3.87891 9.99984 3.87891C11.3805 3.87891 12.4998 4.99819 12.4998 6.37891ZM17.4998 8.87891C17.4998 9.79938 16.7536 10.5456 15.8332 10.5456C14.9127 10.5456 14.1665 9.79938 14.1665 8.87891C14.1665 7.95843 14.9127 7.21224 15.8332 7.21224C16.7536 7.21224 17.4998 7.95843 17.4998 8.87891ZM5.83317 8.87891C5.83317 9.79938 5.08698 10.5456 4.1665 10.5456C3.24603 10.5456 2.49984 9.79938 2.49984 8.87891C2.49984 7.95843 3.24603 7.21224 4.1665 7.21224C5.08698 7.21224 5.83317 7.95843 5.83317 8.87891Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

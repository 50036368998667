import { MenuItem } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import plus from "../../../assets/images/feed/add.svg";
import minus from "../../../assets/images/feed/remove.svg";
import TextInputFS from "../../../components/common/Input/TextInput";
import FeedingContext from "./Provider/FeedingContext";

interface ISkuAndBrandProps {
  no: number | 0;
  feedBrands: any;
  value: any;
  selectedFeedBrand: any;
  onSelectedBrand: any;
  onSelectedSku: any;
  errorObj: any;
  updatedFeedData: any;
  setHasValidationError: any;
  hasValidationError: any;
  managQuantity: any;
}

const EditSkuAndBrand = ({
  no,
  feedBrands,
  value,
  selectedFeedBrand,
  onSelectedBrand,
  onSelectedSku,
  errorObj,
  updatedFeedData,
  hasValidationError,
  setHasValidationError,
  managQuantity,
}: ISkuAndBrandProps) => {
  const { t, i18n } = useTranslation();
  const [quantity, setQuantity] = useState<number>(0);
  const [initialVal, setInitialVal] = useState<number>(0);
  const [minVal, setMinVal] = useState<number>(0);
  const [maxVal, setMaxVal] = useState<number>(0);
  const [error, setError] = useState(false);
  const { finalAddHouseData } = useContext(FeedingContext);

  useEffect(() => {
    const basicDetailsData = finalAddHouseData.basicDetails;
    if (basicDetailsData && selectedFeedBrand) {
      const updatedQuant =
        finalAddHouseData?.feedDetails?.feed_brand_items[no]?.quantity;
      const fattenerPig =
        basicDetailsData?.fattenerPig !== ""
          ? parseInt(basicDetailsData?.fattenerPig)
          : 0;
      const nurseryPig =
        basicDetailsData.nurseryPig !== ""
          ? parseInt(basicDetailsData.nurseryPig)
          : 0;
      const initialValue =
        (fattenerPig + nurseryPig) *
        feedBrands?.find((ele: any) => ele.id === selectedFeedBrand?.id)
          ?.feed_brand_items[no]?.consumption;
      setInitialVal(initialValue);
      setQuantity(
        updatedQuant || updatedQuant == 0 ? updatedQuant : initialValue
      );
      managQuantity(
        updatedQuant || updatedQuant == 0 ? updatedQuant : initialValue,
        no
      );
    }
  }, [feedBrands, no, finalAddHouseData]);

  const handleQuantityChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = event.target;
    const numericValue = Number(value);

    if (!isNaN(numericValue)) {
      setQuantity(numericValue);
      managQuantity(numericValue, index);

      validateQuantity(numericValue);
    }
  };

  const handleIncrement = (index: number) => {
    setQuantity((prevQuantity) => {
      validateQuantity(prevQuantity + 1);
      managQuantity(quantity + 1, index);
      return prevQuantity + 1;
    });
  };

  const getQuantity = (): number => {
    const formula =
      (parseInt(finalAddHouseData?.basicDetails?.fattenerPig) +
        parseInt(finalAddHouseData?.basicDetails?.nurseryPig)) *
      feedBrands?.find((ele: any) => ele.id === selectedFeedBrand?.id)
        ?.feed_brand_items[no]?.consumption;
    return formula;
  };

  const validateQuantity = (quant: number) => {
    const diff = (initialVal / 100) * 20;
    if (
      (quant > initialVal + diff || quant < initialVal - diff) &&
      quant !== 0 &&
      initialVal !== 0
    ) {
      setMinVal(Math.ceil(initialVal - diff));
      setMaxVal(Math.floor(initialVal + diff));
      setError(true);
      const arr = [...hasValidationError];
      arr.push(no);
      setHasValidationError(arr);
    } else {
      setError(false);
      const arr = [...hasValidationError];
      const finalArr = arr.filter((number) => number !== no);
      setHasValidationError(finalArr);
    }
  };

  const handleDecrement = (index: number) => {
    setQuantity((prevQuantity) => {
      validateQuantity(prevQuantity - 1);
      managQuantity(quantity - 1, index);
      return prevQuantity - 1;
    });
  };

  return (
    <div className="bg-[#FFFFFFCC] px-5 border-b border-[#D9EBFE]" key={no}>
      {" "}
      <p className="pt-5 pb-3  text-[#3777BC] font-medium text-xl">
        {t(`feedingProgram.No`) + ` ${no + 1}`}
      </p>
      <div className="flex items-center  gap-2  pb-4">
        <p className="text-base font-normal text-[#979797] w-[30%]">
          {t("feedingProgram.brand")}
        </p>
        <div className="w-full custom-select">
          <TextInputFS
            select
            size="small"
            borderRadius="10px"
            textAlign="center"
            borderColor="#97BFEA"
            value={value?.brand_id}
            helperText={errorObj?.msg}
            error={
              errorObj?.isError &&
              errorObj.type == "brand" &&
              errorObj?.index == no
            }
            onChange={(e) => onSelectedBrand(e.target.value, no)}
          >
            {feedBrands?.map((option: any, index: number) => (
              <MenuItem key={index} value={option?.id} className="text-right">
                {i18n.language == "en" ? option?.nameEn : option?.nameTh}
              </MenuItem>
            ))}
          </TextInputFS>
        </div>
      </div>
      <div className="flex items-center  gap-2  pb-4">
        <p className="text-base font-normal text-[#979797] w-[30%]">
          {t("feedingProgram.sku")}
        </p>
        <div className="w-full custom-select">
          <TextInputFS
            select
            size="small"
            borderRadius="10px"
            textAlign="center"
            borderColor="#97BFEA"
            value={value.id}
            onChange={(e) => onSelectedSku(e.target.value, no)}
            helperText={errorObj?.msg}
            error={
              errorObj?.isError &&
              errorObj?.type === "sku" &&
              errorObj?.index === no
            }
          >
            {feedBrands
              ?.find(
                (ele: any) =>
                  ele.id ===
                  (value?.brand_id ? value?.brand_id : updatedFeedData.id)
              )
              ?.feed_brand_items?.map((option: any, index: number) => (
                <MenuItem key={index} value={option.id} className="text-right">
                  {i18n.language == "en" ? option?.skuEn : option?.skuTh}
                </MenuItem>
              ))}
          </TextInputFS>
        </div>
      </div>
      <div className="flex items-center gap-2  pb-4">
        <p className="text-base font-normal text-[#979797] w-[30%]">
          {t("feedingProgram.quatity")}kg
        </p>
        <div className="w-full">
          <div className=" flex justify-between border bg-[#F6FBFF] border-[#97BFEA] rounded-[10px] px-5 py-[2.5px]">
            <img
              src={minus}
              alt="minus icon"
              className="cursor-pointer"
              onClick={() => handleDecrement(no)}
            />
            <input
              // type="number"
              inputMode="numeric"
              name="quantity"
              min={getQuantity()}
              value={quantity}
              className="text-center focus:outline-none bg-[#F6FBFF] w-[30%]"
              onChange={(e) => handleQuantityChange(e, no)}
            />
            <img
              src={plus}
              alt="plus icon"
              className="cursor-pointer"
              onClick={() => handleIncrement(no)}
            />
          </div>
          <div
            className="text-xs leading-5 text-left mt-[4px] mx-[14px]  font-['IBM Plex Sans Thai,Roboto,Arial,Apple Color Emoji']"
            style={{ color: error ? "#d32f2f" : "#00000099" }}
          >
            {error ? (
              `Quantity can't be ${
                quantity < initialVal ? "less" : "more"
              } than ${quantity < initialVal ? minVal : maxVal}`
            ) : (
              <>{t("feedingProgram.helperTextQuantity")}</>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSkuAndBrand;

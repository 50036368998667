import { TDropdownValue } from "../../admin/pages/group-area/views/Dropdown";

export type TFormPartnerLanding = {
  mount: TDropdownValue[];
  year: TDropdownValue[];
};

export const defaultFormPartnerLanding: TFormPartnerLanding = {
  mount: [],
  year: [],
};

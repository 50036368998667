import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Checkbox, TableCell, TableRow } from "@mui/material";
import get from "lodash/get";
import { IFeedFoodListResponse } from "../../../../../../types/FoodFeed.type";
import { tw } from "../../../../../../utils/tw";
import TableCustom from "../../../../../components/Table/TableCustom";
import CheckBoxUnCheck from "../../../../../../assets/svg/CheckBoxUnCheck";
import { cloneDeep, set } from "lodash";
import { IGetOverdueFarmerInvoice } from "../../../../../../types/invoice/invoiceSale.type";
import dayjs from "dayjs";
import { TInvoiceOverdueStatus } from "../../../type";
import ChipOrderStatus from "../../../../../components/Chip/ChipOrderStatus";
import { getCommaNumber } from "../../../../../../utils/number";

interface ITableProps {
  data: IGetOverdueFarmerInvoice["rows"];
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  totalTableSize: number;
  ids: string[];
  setIds: Dispatch<SetStateAction<string[]>>;
}

const classes = {
  container: tw(`mt-6`),
  emptyTable: tw(
    `font-sans text-center w-full text-[#979797] text-[14px] py-3`
  ),
};

const headerItems = [
  {
    id: "invoiceNumber",
    numeric: false,
    disablePadding: false,
    label: "เลข Invoice",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
  {
    id: "totalPrice",
    numeric: false,
    disablePadding: false,
    label: "ยอดเงินที่ต้องชำระ",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
  {
    id: "totalPending",
    numeric: false,
    disablePadding: false,
    label: "ยอดเงินที่รอตวจสอบ",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
  {
    id: "totalPaid",
    numeric: false,
    disablePadding: false,
    label: "ยอดเงินที่ชำระแล้ว",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
  {
    id: "overdueStatus",
    numeric: false,
    disablePadding: false,
    label: "สถานะ",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
  {
    id: "dueDate",
    numeric: false,
    disablePadding: false,
    label: "วันครบกำหนดชำระ",
    width: "auto",
    isSorting: false,
    className: tw(`whitespace-pre`),
  },
];

const OrderStatusMap: Record<
  TInvoiceOverdueStatus,
  { color: string; bgColor: string; label: string }
> = {
  not_follow: {
    color: "#F57C00",
    bgColor: "#FDECD9",
    label: "ยังไม่ได้ติดตาม",
  },
  followed: {
    color: "#E9FFEF",
    bgColor: "#409261",
    label: "กำลังติดตาม",
  },
  followed_three_days: {
    color: "#409261",
    bgColor: "#E9FFEF",
    label: "กำลังติดตาม 3 วัน",
  },
  not_followed_seven_days: {
    color: "#F57C00",
    bgColor: "#FDECD9",
    label: "ยังไม่ได้ติดตาม 7-30 วัน",
  },
  followed_seven_days: {
    color: "#3777BC",
    bgColor: "#D9EBFE",
    label: "กำลังติดตาม 7-30 วัน",
  },
  not_followed_thirty_one_days: {
    color: "#F57C00",
    bgColor: "#FDECD9",
    label: "ยังไม่ได้ติดตาม 31-60 วัน",
  },
  followed_thirty_one_days: {
    color: "#5572EB",
    bgColor: "#DDE4FF",
    label: "กำลังติดตาม 31-60 วัน",
  },
  not_followed_sixty_days: {
    color: "#F57C00",
    bgColor: "#FDECD9",
    label: "ยังไม่ได้ติดตาม 60 วัน",
  },
  followed_sixty_days: {
    color: "#D32F2F",
    bgColor: "#F3DADA",
    label: "กำลังติดตาม 60 วัน",
  },
  waiting_for_approval: {
    color: "#F57C00",
    bgColor: "#FDECD9",
    label: "รออนุมัติ",
  },
};

const statusPastDueInvoice = [
  {
    statusEN: "not_follow",
    statusTH: "ยังไม่ได้ติดตาม",
  },
  {
    statusEN: "followed",
    statusTH: "กำลังติดตาม",
  },
  {
    statusEN: "followed_three_days",
    statusTH: "กำลังติดตาม",
  },
  {
    statusEN: "not_followed_seven_days",
    statusTH: "ยังไม่ได้ติดตาม 7-30 วัน",
  },
  {
    statusEN: "followed_seven_days",
    statusTH: "กำลังติดตาม 7-30 วัน",
  },
  {
    statusEN: "not_followed_thirty_one_days",
    statusTH: "ยังไม่ได้ติดตาม 31-60 วัน",
  },
  {
    statusEN: "followed_thirty_one_days",
    statusTH: "กำลังติดตาม 31-60 วัน",
  },
  {
    statusEN: "not_followed_sixty_days",
    statusTH: "ยังไม่ได้ติดตาม 60 วัน",
  },
  {
    statusEN: "followed_sixty_days",
    statusTH: "กำลังติดตาม 60 วัน",
  },
  {
    statusEN: "waiting_for_approval",
    statusTH: "รออนุมัติ",
  },
];

// @@@ BASE TABLE SHOULD FOLLOW THIS @@@
const Table = (props: ITableProps) => {
  const { data, currentPage, setCurrentPage, totalTableSize, ids, setIds } =
    props;

  const [selectedList, setSelectedList] = useState<{
    [key: string]: boolean;
  }>({});

  const startIndex = (currentPage - 1) * 4;
  const paginatedData = data.slice(startIndex, startIndex + 4);

  const onSelect = useCallback(
    (id: string) => () => {
      setSelectedList((prev) => {
        const prevState = cloneDeep(prev);
        const isSelected = get(prevState, id, false);
        return set(prevState, [id], !isSelected);
      });
    },
    []
  );

  const itemSelected = useMemo(() => {
    return Object.entries(selectedList)
      .map(([key, value]) => ({ key, value }))
      .filter(({ value }) => value === true)
      .map(({ key }) => key);
  }, [selectedList]);

  const onSelectAllClick = useCallback(
    (e: any) => {
      const isCheck = e.target.checked;
      let selected = {};
      if (isCheck) {
        for (let i = 0; i < data!.length; i++) {
          selected = { ...selected, [data![i].id]: true };
        }
        setSelectedList(selected);
      } else {
        setSelectedList({});
      }
    },
    [data, paginatedData, selectedList]
  );

  const rowChildren = useMemo(() => {
    return paginatedData.map((row, index) => {
      const isSelected = get(selectedList, row.id, false);

      return (
        <TableRow
          key={row.id + index}
          hover
          className="border-[1px] border-[#ddd]"
        >
          <TableCell
            padding="checkbox"
            sx={{
              border: "1px !important",
              paddingX: "4px",
            }}
          >
            <Checkbox
              color="primary"
              checked={isSelected}
              onClick={onSelect(row.id)}
              sx={{
                borderWidth: "1px",
                color: "#C9C9C9",
                "&.Mui-checked": {
                  color: "#68C184",
                },
              }}
              icon={<CheckBoxUnCheck />}
            />
          </TableCell>
          <TableCell className="whitespace-pre">
            {row.invoiceNumber || "-"}
          </TableCell>
          <TableCell>{getCommaNumber(row.totalPrice, true)} บาท</TableCell>
          <TableCell>
            {getCommaNumber(get(row, ["totalPending"], 0), true)} บาท
          </TableCell>
          <TableCell>{getCommaNumber(row.totalPaid, true)} บาท</TableCell>
          <TableCell align="left">
            <div className="flex flex-row w-full">
              {row.overdueStatus ? (
                <div className="flex flex-row w-full">
                  <ChipOrderStatus
                    bgColor={
                      OrderStatusMap[row.overdueStatus as TInvoiceOverdueStatus]
                        .bgColor
                    }
                    fontColor={
                      OrderStatusMap[row.overdueStatus as TInvoiceOverdueStatus]
                        .color
                    }
                    label={
                      OrderStatusMap[row.overdueStatus as TInvoiceOverdueStatus]
                        .label
                    }
                  />
                </div>
              ) : null}
            </div>
          </TableCell>
          <TableCell>{dayjs(row.dueDate).format("DD/MM/YYYY")}</TableCell>
        </TableRow>
      );
    });
  }, [data, paginatedData, selectedList, currentPage]);

  useEffect(() => {
    setIds(itemSelected);
  }, [itemSelected]);

  return (
    <div className={classes.container}>
      <TableCustom
        orderBy={""}
        order={"desc"}
        onChangeOrder={() => {}}
        onChangeOrderBy={() => {}}
        totalItemSize={totalTableSize}
        headerItems={headerItems}
        page={currentPage}
        onChangePage={setCurrentPage}
        rowsPerPage={4}
        rows={data}
        EmptyComponent={
          data.length === 0 ? (
            <div className={classes.emptyTable}>ไม่มีข้อมูล</div>
          ) : null
        }
        rowChildren={rowChildren}
        currentPageItems={data.map(({ id }) => id)}
        itemsSelected={itemSelected}
        onSelectAllClick={onSelectAllClick}
        onSelectItem={() => {}}
      />
    </div>
  );
};

export default memo(Table);

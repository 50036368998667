const IconDotWithBgSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={41} height={42} fill="none">
    <rect width={41} height={41} y={0.129} fill="#7A1B1B" rx={20.5} />
    <path
      fill="#fff"
      d="M13.75 20.629a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0ZM21.625 20.629a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0ZM29.5 20.629a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12.625 20.629h.011m7.864 0h.011m7.864 0h.011m-14.636 0a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Zm7.875 0a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Zm7.875 0a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0Z"
    />
  </svg>
);
export default IconDotWithBgSvg;

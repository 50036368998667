import { Button, CircularProgress } from "@mui/material";

interface ButtonWithLoaderProps {
  isValid: boolean;
  isLoading: boolean;
  title: string;
  onClick?: () => void;
}

function ButtonWithLoader({
  isValid,
  isLoading,
  title,
  onClick,
}: ButtonWithLoaderProps) {
  return (
    <Button
      disabled={!isValid || isLoading}
      type="submit"
      variant="text"
      sx={{
        bgcolor: "#68C184",
        fontWeight: 500,
        fontSize: "14px",
        color: "#ffffff",
        height: "30px",
        borderRadius: "4px",
        ":hover": {
          bgcolor: "#68C184",
          opacity: "0.8",
        },
      }}
      onClick={onClick}
    >
      {isLoading ? (
        <CircularProgress size={"20px"} thickness={6} color="inherit" />
      ) : (
        title
      )}
    </Button>
  );
}

export default ButtonWithLoader;

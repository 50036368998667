export default function WaveSVG() {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" width="47" height="18" viewBox="0 0 47 18" fill="none">
    //     <g clipPath="url(#clip0_5426_20069)">
    //         <path d="M17.2916 18C17.2916 18 17.2908 18 17.2903 18C17.1239 17.9994 16.9789 17.8861 16.9382 17.7246L12.9335 1.78827L9.32446 14.7709C9.2832 14.9193 9.15303 15.0258 8.99942 15.0367C8.84596 15.0476 8.70188 14.9609 8.64 14.8197L6.04592 8.90929L5.45381 10.4172C5.39912 10.5567 5.26457 10.6484 5.11486 10.6484H0.364265C0.163146 10.6484 0 10.4852 0 10.284C0 10.0828 0.163146 9.91963 0.364265 9.91963H4.86655L5.68822 7.82719C5.74198 7.69025 5.87293 7.59895 6.01998 7.59614C6.16765 7.59317 6.30158 7.67915 6.36065 7.8139L8.89628 13.591L12.6003 0.266731C12.6447 0.107434 12.7905 -0.00230805 12.9559 3.68644e-05C13.1212 0.00206912 13.2643 0.11525 13.3045 0.275486L17.2966 16.1608L19.6473 7.06744C19.6872 6.91346 19.822 6.80309 19.9808 6.7948C20.1393 6.78636 20.2854 6.88204 20.341 7.03086L22.2552 12.1445L22.8627 10.1701C22.9098 10.0172 23.0509 9.91291 23.2109 9.91291H46.6357C46.8369 9.91291 47 10.0761 47 10.2773C47 10.4785 46.8369 10.6417 46.6357 10.6417H23.4798L22.6369 13.3809C22.5911 13.5299 22.4555 13.6332 22.2997 13.6379C22.1437 13.6427 22.0023 13.5475 21.9478 13.4015L20.0636 8.36793L17.6441 17.7269C17.6025 17.8878 17.4575 18 17.2916 18Z" fill="#EFB2B2" />
    //     </g>
    //     <defs>
    //         <clipPath id="clip0_5426_20069">
    //             <rect width="47" height="18" fill="white" />
    //         </clipPath>
    //     </defs>
    // </svg>
    <svg
      width="67"
      height="25"
      viewBox="0 0 67 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1602_63592"
        maskUnits="userSpaceOnUse"
        x="-1"
        y="-2"
        width="29"
        height="29"
      >
        <rect x="-1" y="-2" width="29" height="29" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1602_63592)">
        <path
          d="M13.4999 24.5846L11.5726 22.8726C9.3352 20.8751 7.48544 19.1521 6.02336 17.7034C4.56127 16.2547 3.39825 14.9542 2.53429 13.8018C1.67034 12.6495 1.06667 11.5904 0.723304 10.6246C0.379936 9.65881 0.208252 8.67107 0.208252 7.66138C0.208252 5.59811 0.906064 3.87505 2.30169 2.49222C3.69731 1.10939 5.43631 0.417969 7.51867 0.417969C8.67061 0.417969 9.76718 0.659416 10.8084 1.14231C11.8495 1.6252 12.7467 2.30565 13.4999 3.18364C14.2531 2.30565 15.1503 1.6252 16.1915 1.14231C17.2327 0.659416 18.3292 0.417969 19.4812 0.417969C21.5635 0.417969 23.3025 1.10939 24.6981 2.49222C26.0938 3.87505 26.7916 5.59811 26.7916 7.66138C26.7916 8.67107 26.6199 9.65881 26.2765 10.6246C25.9332 11.5904 25.3295 12.6495 24.4655 13.8018C23.6016 14.9542 22.4386 16.2547 20.9765 17.7034C19.5144 19.1521 17.6646 20.8751 15.4272 22.8726L13.4999 24.5846Z"
          fill="#FFEBEB"
        />
        <path
          d="M13.5001 20.9596L12.2736 19.7612C10.8498 18.363 9.67269 17.1568 8.74227 16.1428C7.81185 15.1287 7.07175 14.2183 6.52196 13.4117C5.97216 12.605 5.58802 11.8637 5.36951 11.1876C5.151 10.5116 5.04175 9.82014 5.04175 9.11336C5.04175 7.66907 5.48581 6.46293 6.37394 5.49494C7.26206 4.52696 8.36869 4.04297 9.69383 4.04297C10.4269 4.04297 11.1247 4.21198 11.7873 4.55001C12.4498 4.88803 13.0208 5.36434 13.5001 5.97894C13.9794 5.36434 14.5503 4.88803 15.2129 4.55001C15.8755 4.21198 16.5733 4.04297 17.3063 4.04297C18.6315 4.04297 19.7381 4.52696 20.6262 5.49494C21.5144 6.46293 21.9584 7.66907 21.9584 9.11336C21.9584 9.82014 21.8492 10.5116 21.6307 11.1876C21.4121 11.8637 21.028 12.605 20.4782 13.4117C19.9284 14.2183 19.1883 15.1287 18.2579 16.1428C17.3275 17.1568 16.1504 18.363 14.7265 19.7612L13.5001 20.9596Z"
          fill="#EFB2B2"
        />
        <path
          d="M13.4999 17.332L12.6239 16.5616C11.6069 15.6628 10.7661 14.8874 10.1015 14.2355C9.4369 13.5836 8.90825 12.9983 8.51554 12.4798C8.12284 11.9612 7.84844 11.4846 7.69237 11.05C7.53629 10.6154 7.45825 10.1709 7.45825 9.71657C7.45825 8.78809 7.77544 8.01272 8.40981 7.39044C9.04419 6.76817 9.83464 6.45703 10.7812 6.45703C11.3048 6.45703 11.8032 6.56568 12.2765 6.78298C12.7497 7.00029 13.1576 7.30649 13.4999 7.70158C13.8423 7.30649 14.2501 7.00029 14.7234 6.78298C15.1966 6.56568 15.6951 6.45703 16.2187 6.45703C17.1652 6.45703 17.9556 6.76817 18.59 7.39044C19.2244 8.01272 19.5416 8.78809 19.5416 9.71657C19.5416 10.1709 19.4635 10.6154 19.3075 11.05C19.1514 11.4846 18.877 11.9612 18.4843 12.4798C18.0916 12.9983 17.5629 13.5836 16.8984 14.2355C16.2338 14.8874 15.393 15.6628 14.376 16.5616L13.4999 17.332Z"
          fill="#DC5B5B"
        />
      </g>
      <g clipPath="url(#clip0_1602_63592)">
        <path
          d="M37.2916 20C37.2916 20 37.2908 20 37.2903 20C37.1239 19.9994 36.9789 19.8861 36.9382 19.7246L32.9335 3.78827L29.3245 16.7709C29.2832 16.9193 29.153 17.0258 28.9994 17.0367C28.846 17.0476 28.7019 16.9609 28.64 16.8197L26.0459 10.9093L25.4538 12.4172C25.3991 12.5567 25.2646 12.6484 25.1149 12.6484H20.3643C20.1631 12.6484 20 12.4852 20 12.284C20 12.0828 20.1631 11.9196 20.3643 11.9196H24.8666L25.6882 9.82719C25.742 9.69025 25.8729 9.59895 26.02 9.59614C26.1677 9.59317 26.3016 9.67915 26.3606 9.8139L28.8963 15.591L32.6003 2.26673C32.6447 2.10743 32.7905 1.99769 32.9559 2.00004C33.1212 2.00207 33.2643 2.11525 33.3045 2.27549L37.2966 18.1608L39.6473 9.06744C39.6872 8.91346 39.822 8.80309 39.9808 8.7948C40.1393 8.78636 40.2854 8.88204 40.341 9.03086L42.2552 14.1445L42.8627 12.1701C42.9098 12.0172 43.0509 11.9129 43.2109 11.9129H66.6357C66.8369 11.9129 67 12.0761 67 12.2773C67 12.4785 66.8369 12.6417 66.6357 12.6417H43.4798L42.6369 15.3809C42.5911 15.5299 42.4555 15.6332 42.2997 15.6379C42.1437 15.6427 42.0023 15.5475 41.9478 15.4015L40.0636 10.3679L37.6441 19.7269C37.6025 19.8878 37.4575 20 37.2916 20Z"
          fill="#EFB2B2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1602_63592">
          <rect
            width="47"
            height="18"
            fill="white"
            transform="translate(20 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

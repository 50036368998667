import { cloneDeep, get, set } from "lodash";
import { getCommaNumber } from "../../../../../utils/number";
import { mockData } from "../../orderClaim/orderClaimDetail/Tabs/mock";
import RadioButton from "../../../../../components/common/RadioButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import TextAreaCustom from "../../../../components/Input/TextAreaCustom";
import { Input } from "../../../group-area/views/Input";
import {
  EClaimOrderOption,
  IDataClaimOrder,
} from "../../../../../types/ClaimOrder.type";
import { unitMap } from "../../../manage-stock/[id]";
import {
  reason,
  reasonOption,
} from "../../orderClaim/orderClaimDetail/Tabs/ReportDetail";

interface ISummaryDetail {
  dataEdit?: IDataClaimOrder;
  tabIndex: number;
  state: IState[];
}

interface IState {
  option: number;
  creditReturnUse: string;
  creditReturnReturn: string;
  creditAddUse: string;
  creditAddAdd: string;
  remark: string;
}

const SummaryDetail = (props: ISummaryDetail) => {
  const { dataEdit, state, tabIndex } = props;

  const getOptionValue = useCallback((type: number) => {
    if (type === 0) {
      return EClaimOrderOption.DeliverProduct;
    } else if (type === 1) {
      return EClaimOrderOption.RefundCredit;
    } else if (type === 2) {
      return EClaimOrderOption.AddDebt;
    } else {
      return EClaimOrderOption.AddDebt;
    }
  }, []);

  return (
    <div className="py-6">
      <div className="flex flex-col w-full gap-3">
        <div className="font-bold text-[14px] text-[#1B2128]">
          หมายเลขคำสั่งซื้อ
        </div>
        <div className="font-normal text-[14px] text-[#1B2128]">
          {get(dataEdit, ["orderNumber"], "-")}
        </div>
      </div>
      <div className="pt-6 flex flex-col gap-6">
        {get(dataEdit, ["orderClaimItems"], []).map((row, index) => {
          return (
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-3">
                <div className="font-bold text-[18px] text-[#3777BC]">
                  รายการที่ {index + 1}
                </div>
                <div className="w-full rounded-[20px] border-[1px] border-[#E2E2E2] p-3">
                  <div className="grid grid-cols-3 gap-3">
                    <div className="h-[54px] flex flex-col gap-3">
                      <div className="font-bold text-[14px] text-[#1B2128]">
                        แบรนด์อาหารสัตว์
                      </div>
                      <div className="font-normal text-[14px] text-[#1B2128]">
                        {get(row, ["orderFeedItem", "feedBrand", "nameTh"], "")}
                      </div>
                    </div>
                    <div className="h-[54px] flex flex-col gap-3">
                      <div className="font-bold text-[14px] text-[#1B2128]">
                        เบอร์อาหารสัตว์
                      </div>
                      <div className="font-normal text-[14px] text-[#1B2128]">
                        {get(
                          row,
                          ["orderFeedItem", "feedBrandItem", "skuTh"],
                          ""
                        )}
                      </div>
                    </div>
                    <div className="h-[54px] flex flex-col gap-3">
                      <div className="font-bold text-[14px] text-[#1B2128]">
                        ราคาต่อหน่วย
                      </div>
                      <div className="font-normal text-[14px] text-[#1B2128]">
                        {getCommaNumber(
                          +get(row, ["orderFeedItem", "feedPricePerUnit"], 0),
                          true
                        )}{" "}
                        บาท
                      </div>
                    </div>
                    <div className="h-[54px] flex flex-col gap-3">
                      <div className="font-bold text-[14px] text-[#1B2128]">
                        ราคารวม
                      </div>
                      <div className="font-normal text-[14px] text-[#1B2128]">
                        {getCommaNumber(
                          get(row, ["orderFeedItem", "totalItemPrice"], 0),
                          true
                        )}{" "}
                        บาท
                      </div>
                    </div>
                    <div className="h-[54px] flex flex-col gap-3">
                      <div className="font-bold text-[14px] text-[#1B2128]">
                        จำนวน
                      </div>
                      <div className="font-normal text-[14px] text-[#1B2128]">
                        {get(row, [
                          "orderFeedItem",
                          "feedBrandItem",
                          "unit",
                        ]) === "bag"
                          ? getCommaNumber(
                              +get(row, ["orderFeedItem", "feedAmount"], 0)
                            )
                          : getCommaNumber(
                              +get(row, ["orderFeedItem", "feedAmount"], 0),
                              true
                            )}{" "}
                        {
                          unitMap[
                            get(
                              row,
                              ["orderFeedItem", "feedBrandItem", "unit"],
                              ""
                            )
                          ]
                        }
                      </div>
                    </div>
                    <div className="h-[54px] flex flex-col gap-3">
                      <div className="font-bold text-[14px] text-[#1B2128]">
                        จำนวนที่รายงานปัญหา
                      </div>
                      <div className="font-normal text-[14px] text-[#1B2128]">
                        {get(row, [
                          "orderFeedItem",
                          "feedBrandItem",
                          "unit",
                        ]) === "bag"
                          ? getCommaNumber(+get(row, ["reportAmount"], 0))
                          : getCommaNumber(
                              +get(row, ["reportAmount"], 0),
                              true
                            )}{" "}
                        {
                          unitMap[
                            get(
                              row,
                              ["orderFeedItem", "feedBrandItem", "unit"],
                              ""
                            )
                          ]
                        }
                      </div>
                    </div>
                    <div className="h-[54px] flex flex-col gap-3">
                      <div className="font-bold text-[14px] text-[#1B2128]">
                        เหตุผล
                      </div>
                      <div className="font-normal text-[14px] text-[#1B2128]">
                        {reason[get(row, ["reportReason"], "")]}
                      </div>
                    </div>
                    <div className="h-[54px] flex flex-col gap-3 col-span-2">
                      <div className="font-bold text-[14px] text-[#1B2128]">
                        รายละเอียดเหตุผล
                      </div>
                      <div className="font-normal text-[14px] text-[#1B2128]">
                        {get(row, ["reportDetail"], "-")}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-6 pt-6">
                    <div className="font-bold text-[#3777BC] text-[16px]">
                      ตัวเลือกการแก้ไข
                    </div>
                    <div className="flex flex-col gap-3">
                      <div className="flex flex-col gap-[6px]">
                        <div className="font-bold text-[14px] text-[#1B2128]">
                          ตัวเลือก
                        </div>
                        <div className="font-normal text-[14px] text-[#1B2128]">
                          {reasonOption[getOptionValue(state[index].option)]}
                        </div>
                      </div>
                      <div className="flex flex-col gap-[6px]">
                        <div className="font-bold text-[14px] text-[#1B2128]">
                          Remark
                        </div>
                        <div className="font-normal text-[14px] text-[#1B2128]">
                          {state[index].remark || "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SummaryDetail;

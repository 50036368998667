import { Slide, SlideProps, Snackbar } from "@mui/material";
import React from "react";
import CheckSuccessIcon from "../../../assets/images/check-success.svg";

interface ISuccessSnackBar {
  openSnackbar: boolean;
  handleCloseSnackbar: () => void;
  snackbarText: string;
  horizontalAnchor?: "left" | "right" | "center";
}

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

function SuccessSnackBar({
  openSnackbar,
  handleCloseSnackbar,
  snackbarText,
  horizontalAnchor = "right",
}: ISuccessSnackBar) {
  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    handleCloseSnackbar();
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: horizontalAnchor }}
      open={openSnackbar}
      autoHideDuration={3000}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
    >
      <div className="flex items-center bg-[#F5F9F5] w-[300px] h-[70px] p-4 gap-2 font-bold rounded-[10px]">
        <img
          width={"24px"}
          height={"24px"}
          src={CheckSuccessIcon}
          alt="toast-successful"
        />
        <span className="font-sans text-base">{snackbarText}</span>
      </div>
    </Snackbar>
  );
}

export default SuccessSnackBar;

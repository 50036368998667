import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { get, isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getAllFeedStock,
  getAllMedicineStock,
  getAllVaccineStock,
  getHistoryFeedStock,
  getHistoryMedicineStock,
  getHistoryOtherStock,
  getHistoryVaccineStock,
  getListFeedStock,
  getListMedicineStock,
  getListOtherStock,
  getListVaccineStock,
} from "../../../services/StockManagement.service";
import {
  defaultFormFeedStock,
  defaultFormHistory,
  defaultFormMedicineStock,
  defaultFormOtherStock,
  defaultFormVaccineStock,
  TFormFeedStock,
  TFormHistory,
  TFormMedicineStock,
  TFormOtherStock,
  TFormVaccineStock,
} from "./defaultForm.type";
export interface IMedicineAllDetail {
  inputValue?: string;
  label: string;
  value: string;
  package: string;
  unit: string;
}

export const useManageStockFarmerController = (farmId: string) => {
  const [tabIndex, setTabIndex] = useState(0);

  const onChangeTab = useCallback((e: any, index: number) => {
    setTabIndex(index);
  }, []);

  const [skuOptions, setSkuOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [medicineDetailIdOption, setMedicineDetailIdOption] = useState<
    { value: string; label: string }[]
  >([]);

  const [addMedicine, setAddMedicine] = useState<{
    inputValue: string;
  }>({ inputValue: "" });

  ////// History //////

  const [formHistory, setFormHistory] =
    useState<TFormHistory>(defaultFormHistory);

  const onChangeFormHistory = useCallback(
    (key: keyof TFormHistory) => (value: any) => {
      setFormHistory((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  ////// Feed //////

  const [formFeedStock, setFormFeedStock] = useState(defaultFormFeedStock);

  const onChangeFormFeedStock = useCallback(
    (key: keyof TFormFeedStock) => (value: any) => {
      if (key === "foodBrand") {
        setFormFeedStock((prev) => ({
          ...prev,
          sku: [],
          [key]: value,
        }));
      } else {
        setFormFeedStock((prev) => {
          return { ...prev, [key]: value };
        });
      }
    },
    []
  );

  const { data: listAllFeedStock } = useQuery({
    queryKey: ["list_all_feed_stock"],
    queryFn: async () => {
      const result = await getAllFeedStock();
      return result;
    },
  });

  const { data: listFeedStock } = useQuery({
    queryKey: ["list_feed_stock", farmId],
    queryFn: async () => {
      const result = await getListFeedStock({ farmId: farmId });
      return result.rows;
    },
  });

  const feedBrandOption = useMemo(() => {
    return get(listAllFeedStock, ["result"], []).map(({ id, nameTh }) => ({
      label: nameTh,
      value: id,
    }));
  }, [listAllFeedStock]);

  const [itemIdFeedStock, setItemIdFeedStock] = useState("");

  const itemByIdFeedStock = listFeedStock?.filter(
    (i) => i.id === itemIdFeedStock
  );

  ////// Vaccine //////

  const [formVaccineStock, setFormVaccineStock] = useState(
    defaultFormVaccineStock
  );

  const onChangeFormVaccineStock = useCallback(
    (key: keyof TFormVaccineStock) => (value: any) => {
      setFormVaccineStock((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const { data: listAllVaccineStock } = useQuery({
    queryKey: ["list_all_vaccine_stock"],
    queryFn: async () => {
      const result = await getAllVaccineStock();
      return result;
    },
  });

  const { data: listVaccineStock } = useQuery({
    queryKey: ["list_vaccine_stock", farmId],
    queryFn: async () => {
      const result = await getListVaccineStock({
        farmId: farmId,
      });
      return result.rows;
    },
  });

  const vaccineOption = useMemo(() => {
    return get(listAllVaccineStock, ["vaccines"], []).map(({ id, nameTh }) => ({
      label: nameTh,
      value: id,
    }));
  }, [listAllVaccineStock]);

  const [itemIdVaccineStock, setItemIdVaccineStock] = useState("");

  const itemByIdVaccineStock = listVaccineStock?.filter(
    (i) => i.id === itemIdVaccineStock
  );

  ////// Medicine //////

  const [formMedicineStock, setFormMedicineStock] = useState(
    defaultFormMedicineStock
  );

  const onChangeFormMedicineStock = useCallback(
    (key: keyof TFormMedicineStock) => (value: any) => {
      if (key === "medicineCategoryId") {
        setFormMedicineStock((prev) => ({
          ...prev,
          medicineDetailId: [],
          [key]: value,
        }));
      } else {
        setFormMedicineStock((prev) => {
          return { ...prev, [key]: value };
        });
      }
    },
    []
  );

  const { data: listAllMedicineStock } = useQuery({
    queryKey: ["list_all_medicine_stock"],
    queryFn: async () => {
      const result = await getAllMedicineStock();
      return result;
    },
  });

  const { data: listMedicineStock } = useQuery({
    queryKey: ["list_medicine_stock", farmId],
    queryFn: async () => {
      const result = await getListMedicineStock({
        farmId: farmId,
      });
      return result.rows;
    },
  });

  const medicineCategoryOption = useMemo(() => {
    return get(listAllMedicineStock, ["medicinCategories"], []).map(
      ({ id, nameTh }) => ({
        label: nameTh,
        value: id,
      })
    );
  }, [listAllMedicineStock]);

  const medicineAllDetailIdOption = <IMedicineAllDetail[]>useMemo(() => {
    return get(listAllMedicineStock, ["medicinCategories"], []).flatMap(
      (item) =>
        item.medicineDetails.map(
          ({ id, tradeName, package: pkg, unit }, index) => ({
            label: tradeName + " " + pkg + " " + unit,
            value: id,
            package: pkg,
            unit: unit,
            key: `optionSearch-${id}-${index}`,
          })
        )
    );
  }, [listAllMedicineStock]);

  const [itemIdMedicineStock, setItemIdMedicineStock] = useState("");

  const itemByIdMedicineStock = listMedicineStock?.filter(
    (i) => i.id === itemIdMedicineStock
  );

  ////// Other //////

  const [formOtherStock, setFormOtherStock] = useState(defaultFormOtherStock);

  const onChangeFormOtherStock = useCallback(
    (key: keyof TFormOtherStock) => (value: any) => {
      setFormOtherStock((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const { data: listOtherStock } = useQuery({
    queryKey: ["list_other_stock", farmId],
    queryFn: async () => {
      const result = await getListOtherStock({
        farmId: farmId,
      });
      return result.rows;
    },
  });

  const [itemIdOtherStock, setItemIdOtherStock] = useState("");

  const itemByIdOtherStock = listOtherStock?.filter(
    (i) => i.id === itemIdOtherStock
  );

  ////// Feed //////

  const [isOpenAddFeedModal, setIsOpenAddFeedModal] = useState(false);

  const onOpenAddFeedModal = useCallback(() => setIsOpenAddFeedModal(true), []);
  const onHideAddFeedModal = useCallback(
    () => setIsOpenAddFeedModal(false),
    []
  );

  const [isEditFeed, setIsEditFeed] = useState(false);

  const onOpenEditFeedModal = useCallback(() => setIsEditFeed(true), []);
  const onHideEditFeedModal = useCallback(() => setIsEditFeed(false), []);
  const onOpenEditFeed = useCallback(() => {
    onOpenEditFeedModal();
    onOpenAddFeedModal();
  }, []);

  const [isOpenHistoryFeedModal, setIsOpenHistoryFeedModal] = useState(false);

  const onOpenHistoryFeedModal = useCallback(
    () => setIsOpenHistoryFeedModal(true),
    []
  );
  const onHideHistoryFeedModal = useCallback(
    () => setIsOpenHistoryFeedModal(false),
    []
  );

  const { data: listHistoryFeedStock, refetch: refetchHistoryFeedStock } =
    useQuery({
      queryKey: [
        "list_history_feed_stock",
        formHistory.direction[0]?.value || "desc",
        formHistory.startDate || "",
        itemIdFeedStock,
      ],
      enabled: false,
      queryFn: async () => {
        try {
          const result = await getHistoryFeedStock(itemIdFeedStock, {
            direction: formHistory.direction[0]?.value as "desc" | "asc",
            startDate: formHistory.startDate
              ? dayjs(formHistory.startDate, "YYYY-MM-DD")
                  .startOf("day")
                  .toDate()
              : undefined,
            endDate: formHistory.endDate
              ? dayjs(formHistory.endDate, "YYYY-MM-DD").startOf("day").toDate()
              : undefined,
          });
          return result.rows;
        } catch (error) {
          console.error(error);
        }
      },
    });

  ////// Vaccine //////

  const [isOpenAddVaccineModal, setIsOpenAddVaccineModal] = useState(false);

  const onOpenAddVaccineModal = useCallback(
    () => setIsOpenAddVaccineModal(true),
    []
  );
  const onHideAddVaccineModal = useCallback(
    () => setIsOpenAddVaccineModal(false),
    []
  );

  const [isEditVaccine, setIsEditVaccine] = useState(false);

  const onOpenEditVaccineModal = useCallback(() => setIsEditVaccine(true), []);
  const onHideEditVaccineModal = useCallback(() => setIsEditVaccine(false), []);
  const onOpenEditVaccine = useCallback(() => {
    onOpenEditVaccineModal();
    onOpenAddVaccineModal();
  }, []);

  const [isOpenHistoryVaccineModal, setIsOpenHistoryVaccineModal] =
    useState(false);

  const onOpenHistoryVaccineModal = useCallback(
    () => setIsOpenHistoryVaccineModal(true),
    []
  );
  const onHideHistoryVaccineModal = useCallback(
    () => setIsOpenHistoryVaccineModal(false),
    []
  );

  const { data: listHistoryVaccineStock, refetch: refetchHistoryVaccineStock } =
    useQuery({
      queryKey: [
        "list_history_vaccine_stock",
        formHistory.direction[0]?.value || "desc",
        formHistory.startDate || "",
        itemIdVaccineStock,
      ],
      enabled: false,
      queryFn: async () => {
        const result = await getHistoryVaccineStock(itemIdVaccineStock, {
          direction: formHistory.direction[0]?.value as "desc" | "asc",
          startDate: formHistory.startDate
            ? dayjs(formHistory.startDate, "YYYY-MM-DD").startOf("day").toDate()
            : undefined,
          endDate: formHistory.endDate
            ? dayjs(formHistory.endDate, "YYYY-MM-DD").startOf("day").toDate()
            : undefined,
        });
        return result;
      },
    });

  ////// Medicine //////

  const [isOpenAddMedicineModal, setIsOpenAddMedicineModal] = useState(false);

  const onOpenAddMedicineModal = useCallback(
    () => setIsOpenAddMedicineModal(true),
    []
  );
  const onHideAddMedicineModal = useCallback(
    () => setIsOpenAddMedicineModal(false),
    []
  );

  const [isEditMedicine, setIsEditMedicine] = useState(false);

  const onOpenEditMedicineModal = useCallback(
    () => setIsEditMedicine(true),
    []
  );
  const onHideEditMedicineModal = useCallback(
    () => setIsEditMedicine(false),
    []
  );
  const onOpenEditMedicine = useCallback(() => {
    onOpenEditMedicineModal();
    onOpenAddMedicineModal();
  }, []);

  const [isOpenHistoryMedicineModal, setIsOpenHistoryMedicineModal] =
    useState(false);

  const onOpenHistoryMedicineModal = useCallback(
    () => setIsOpenHistoryMedicineModal(true),
    []
  );
  const onHideHistoryMedicineModal = useCallback(
    () => setIsOpenHistoryMedicineModal(false),
    []
  );

  const {
    data: listHistoryMedicineStock,
    refetch: refetchHistoryMedicineStock,
  } = useQuery({
    queryKey: [
      "list_history_medicine_stock",
      formHistory.direction[0]?.value || "desc",
      formHistory.startDate || "",
      itemIdMedicineStock,
    ],
    enabled: false,
    queryFn: async () => {
      const result = await getHistoryMedicineStock(itemIdMedicineStock, {
        direction: formHistory.direction[0]?.value as "desc" | "asc",
        startDate: formHistory.startDate
          ? dayjs(formHistory.startDate, "YYYY-MM-DD").startOf("day").toDate()
          : undefined,
        endDate: formHistory.endDate
          ? dayjs(formHistory.endDate, "YYYY-MM-DD").startOf("day").toDate()
          : undefined,
      });
      return result;
    },
  });

  ////// Other //////

  const [isOpenAddOthersModal, setIsOpenAddOthersModal] = useState(false);

  const onOpenAddOthersModal = useCallback(
    () => setIsOpenAddOthersModal(true),
    []
  );
  const onHideAddOthersModal = useCallback(
    () => setIsOpenAddOthersModal(false),
    []
  );

  const [isEditOther, setIsEditOther] = useState(false);

  const onOpenEditOtherModal = useCallback(() => setIsEditOther(true), []);
  const onHideEditOtherModal = useCallback(() => setIsEditOther(false), []);
  const onOpenEditOther = useCallback(() => {
    onOpenEditOtherModal();
    onOpenAddOthersModal();
  }, []);

  const [isOpenHistoryOtherModal, setIsOpenHistoryOtherModal] = useState(false);

  const onOpenHistoryOtherModal = useCallback(
    () => setIsOpenHistoryOtherModal(true),
    []
  );
  const onHideHistoryOtherModal = useCallback(
    () => setIsOpenHistoryOtherModal(false),
    []
  );

  const { data: listHistoryOtherStock, refetch: refetchHistoryOtherStock } =
    useQuery({
      queryKey: [
        "list_history_other_stock",
        formHistory.direction[0]?.value || "desc",
        formHistory.startDate || "",
        farmId,
      ],
      enabled: isOpenHistoryOtherModal === true,
      queryFn: async () => {
        const result = await getHistoryOtherStock({
          direction: formHistory.direction[0]?.value as "desc" | "asc",
          startDate: formHistory.startDate
            ? dayjs(formHistory.startDate, "YYYY-MM-DD").startOf("day").toDate()
            : undefined,
          endDate: formHistory.endDate
            ? dayjs(formHistory.endDate, "YYYY-MM-DD").startOf("day").toDate()
            : undefined,
          farmId: farmId,
        });
        return result.rows;
      },
    });

  ////// History //////

  useEffect(() => {
    if (itemIdFeedStock) {
      refetchHistoryFeedStock();
    }
    if (itemIdVaccineStock) {
      refetchHistoryVaccineStock();
    }
    if (itemIdMedicineStock) {
      refetchHistoryMedicineStock();
    }
    if (isOpenHistoryOtherModal) {
      refetchHistoryOtherStock();
    }
  }, [
    itemIdFeedStock,
    itemIdVaccineStock,
    itemIdMedicineStock,
    formHistory.direction,
    formHistory.startDate,
    formHistory.endDate,
  ]);

  ////// Feed //////

  useEffect(() => {
    if (!isEmpty(formFeedStock.foodBrand)) {
      setSkuOptions(
        get(
          listAllFeedStock!.result.find(
            (item) => item.id === formFeedStock.foodBrand[0].value
          ),
          ["feed_brand_items"],
          []
        ).map(({ id, skuTh }) => ({
          label: skuTh,
          value: id,
        }))
      );
    } else {
      setSkuOptions([]);
    }
  }, [formFeedStock.foodBrand]);

  useEffect(() => {
    if (
      !isEmpty(formFeedStock.sku) &&
      formFeedStock.foodBrand.length > 0 &&
      listAllFeedStock
    ) {
      const feedStockItem = listAllFeedStock.result.find(
        (item) => item.id === formFeedStock.foodBrand[0].value
      );

      const feedBrandItems = get(feedStockItem, "feed_brand_items", []);
      const updatedForm = feedBrandItems.find(
        (item) => item.id === formFeedStock.sku[0].value
      );

      setFormFeedStock((prev) => ({
        ...prev,
        foodBrand: formFeedStock.foodBrand,
        sku: formFeedStock.sku,
        amount: "",
        unit: updatedForm?.unit || "",
        price:
          updatedForm?.pricePerUnit != null
            ? updatedForm.pricePerUnit.toString()
            : "",
      }));
    }
  }, [formFeedStock.sku, formFeedStock.foodBrand]);

  ////// Vaccine //////

  useEffect(() => {
    if (!isEmpty(formVaccineStock.vaccine)) {
      const vaccineItem = listAllVaccineStock?.vaccines.find(
        (item) => item.id === formVaccineStock.vaccine[0].value
      );

      setFormVaccineStock((prev) => ({
        ...prev,
        vaccine: formVaccineStock.vaccine,
        amount: "",
        unit: "bottle",
        price: "",
      }));
    }
  }, [formVaccineStock.vaccine]);

  ////// Medicine //////

  useEffect(() => {
    if (!isEmpty(formMedicineStock.medicineCategoryId)) {
      setMedicineDetailIdOption(
        get(
          listAllMedicineStock!.medicinCategories.find(
            (item) => item.id === formMedicineStock.medicineCategoryId[0].value
          ),
          ["medicineDetails"],
          []
        ).map(({ id, tradeName, package: pkg, unit }) => ({
          label: tradeName + " " + pkg + " " + unit,
          value: id,
        }))
      );
    } else {
      setMedicineDetailIdOption([]);
    }
  }, [formMedicineStock.medicineCategoryId]);

  useEffect(() => {
    if (
      !isEmpty(formMedicineStock.medicineDetailId) &&
      formMedicineStock.medicineCategoryId.length > 0 &&
      listAllMedicineStock
    ) {
      const medicineStockItem = listAllMedicineStock.medicinCategories.find(
        (item) => item.id === formMedicineStock.medicineCategoryId[0].value
      );

      const medicineItems = get(medicineStockItem, "medicineDetails", []);
      const updatedForm = medicineItems.find(
        (item) => item.id === formMedicineStock.medicineDetailId[0].value
      );

      setFormMedicineStock((prev) => ({
        ...prev,
        medicineCategoryId: formMedicineStock.medicineCategoryId,
        medicineDetailId: formMedicineStock.medicineDetailId,
        amount: "",
        unit: updatedForm?.unit || "",
      }));
    }
  }, [
    formMedicineStock.medicineCategoryId,
    formMedicineStock.medicineDetailId,
  ]);

  return {
    tabIndex,
    onChangeTab,
    isOpenAddFeedModal,
    onOpenAddFeedModal,
    onHideAddFeedModal,
    isOpenAddVaccineModal,
    onOpenAddVaccineModal,
    onHideAddVaccineModal,
    isOpenAddMedicineModal,
    onOpenAddMedicineModal,
    onHideAddMedicineModal,
    isOpenAddOthersModal,
    onOpenAddOthersModal,
    onHideAddOthersModal,
    ////// Feed //////
    listFeedStock,
    feedBrandOption,
    skuOptions,
    formFeedStock,
    setFormFeedStock,
    onChangeFormFeedStock,
    itemByIdFeedStock,
    setItemIdFeedStock,
    isEditFeed,
    onOpenEditFeed,
    onHideEditFeedModal,
    isOpenHistoryFeedModal,
    onOpenHistoryFeedModal,
    onHideHistoryFeedModal,
    listHistoryFeedStock,
    ////// Vaccine //////
    listVaccineStock,
    vaccineOption,
    formVaccineStock,
    setFormVaccineStock,
    onChangeFormVaccineStock,
    itemByIdVaccineStock,
    setItemIdVaccineStock,
    isEditVaccine,
    onOpenEditVaccine,
    onHideEditVaccineModal,
    isOpenHistoryVaccineModal,
    onOpenHistoryVaccineModal,
    onHideHistoryVaccineModal,
    listHistoryVaccineStock,
    ////// Medicine //////
    listMedicineStock,
    medicineCategoryOption,
    medicineDetailIdOption,
    medicineAllDetailIdOption,
    formMedicineStock,
    setFormMedicineStock,
    onChangeFormMedicineStock,
    addMedicine,
    setAddMedicine,
    itemByIdMedicineStock,
    setItemIdMedicineStock,
    isEditMedicine,
    onOpenEditMedicine,
    onHideEditMedicineModal,
    isOpenHistoryMedicineModal,
    onOpenHistoryMedicineModal,
    onHideHistoryMedicineModal,
    listHistoryMedicineStock,
    ////// Other //////
    listOtherStock,
    formOtherStock,
    setFormOtherStock,
    onChangeFormOtherStock,
    itemByIdOtherStock,
    setItemIdOtherStock,
    isEditOther,
    onOpenEditOther,
    onHideEditOtherModal,
    isOpenHistoryOtherModal,
    onOpenHistoryOtherModal,
    onHideHistoryOtherModal,
    listHistoryOtherStock,
    ////// History //////
    formHistory,
    setFormHistory,
    onChangeFormHistory,
  };
};

import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { tw } from "../../../../utils/tw";
import { IconButton } from "@mui/material";
import AppBarFS from "../../../../components/app-bar/AppBar";
import FilterListIcon from "@mui/icons-material/FilterList";
import Table from "./views/Table";
import { useHistoryBillController } from "./controller";
import FilterModal from "../unpaid-bill/views/FilterModal";
import { set } from "lodash";

interface IState {
  value: string;
}

const classes = {
  container: tw(`w-full h-full`),
  headerContainer: tw(`h-[120px] flex items-center`),
  detailContainer: tw(`flex flex-col w-full h-full gap-[16px] px-[16px]`),
  tableContainer: tw(`pb-[163px]`),
  textAndFilterContainer: tw(`flex flex-row py-[16px] px-4 justify-between`),
  buttonContainer: tw(`pt-1`),
  button: tw(`!h-[59px] w-full !rounded-[50px] !text-[16px] !font-bold`),
  buttonTextContainer: tw(
    `flex flex-col gap-6 pt-8 px-[17.5px] pb-6 fixed bottom-0 bg-[#FFFFFF] w-full z-10`
  ),
  textContainer: tw(`flex flex-row justify-between`),
  text1: tw(`font-semibold text-[18px] text-[#3777BC]`),
  text2: tw(`font-normal text-[1rem] text-secondary`),
};

const HistoryBill = () => {
  const {
    listInvoice,
    orderByType,
    setOrderByType,
    formHistoryBill,
    setFormHistoryBill,
    onChangeFormHistoryBill,
    currentPage,
    setCurrentPage,
  } = useHistoryBillController();

  let navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(-1);
  }, []);

  const [isOpenFilterModal, setIsOpenFilterModal] = useState(false);

  const onOpenFilterModal = useCallback(() => setIsOpenFilterModal(true), []);
  const onHideFilterModal = useCallback(() => setIsOpenFilterModal(false), []);

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <AppBarFS title={"ประวัติการชำระบิล"} onIconPress={handleBack} />
        </div>
        <div className={classes.detailContainer}>
          <div className={classes.textAndFilterContainer}>
            <span className={tw(classes.text1, "flex flex-col justify-center")}>
              บิลทั้งหมด
            </span>
            <div className="h-full flex gap-2 items-center">
              <span className={classes.text2}>กรองโดย</span>
              <IconButton
                aria-label="filter"
                sx={{
                  color: "#68C184",
                  border: "1px solid #68C184",
                  ":hover": {
                    color: "#FFFFFF",
                    border: "1px solid #68C184",
                    bgcolor: "#68C184",
                  },
                  ":active": {
                    color: "#FFFFFF",
                    border: "1px solid #68C184",
                    bgcolor: "#68C184",
                  },
                }}
                onClick={onOpenFilterModal}
              >
                <FilterListIcon />
              </IconButton>
            </div>
          </div>
        </div>
        <div className={classes.tableContainer}>
          <Table
            data={listInvoice?.rows || []}
            orderByType={orderByType}
            setOrderByType={setOrderByType}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            count={listInvoice?.count || 0}
          />
        </div>
      </div>
      <FilterModal
        title="กรองโดย"
        isOpen={isOpenFilterModal}
        onClose={onHideFilterModal}
        form={formHistoryBill}
        setForm={setFormHistoryBill}
        onChangeForm={onChangeFormHistoryBill}
      />
    </div>
  );
};

export default HistoryBill;

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PigFeedSvg from "../../../../assets/svg/PigFeedSvg";
import { IMyStock } from "../../../../types/partner/stock.type";
import { get } from "lodash";
import { getCommaNumber } from "../../../../utils/number";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

interface IProps {
  data: IMyStock[];
  id: string;
  setId: Dispatch<SetStateAction<string>>;
}

const unitMap = {
  bag: "ถุง",
  kg: "กิโลกรัม",
  "": "",
};

const AccordionShowList = (props: IProps) => {
  const { data, id, setId } = props;

  let navigate = useNavigate();

  const handleClickToSeeDetails = useCallback(
    (id: string) => {
      navigate("/partner/stock-details/" + id, {
        state: data,
      });
    },
    [id, data]
  );

  return (
    <div className="flex flex-col gap-6">
      {(data || []).map((d, index) => (
        <Accordion
          key={`${index} + ${get(d, ["feedBrand", "id"], "")}`}
          sx={{
            margin: "0px !important",
            boxShadow: "none",
            width: "100%",
            border: "0px",
            backgroundColor: "transparent",
            borderRadius: "15px",
            display: "flex",
            flexDirection: "column",
            gap: "12px !important",
            "&.Mui-expanded": {
              gap: "12px !important",
              margin: "0px !important",
            },
            ":before": {
              backgroundColor: "transparent",
            },
          }}
        >
          <AccordionSummary
            expandIcon={
              <ArrowDropDownIcon className="!text-[32px] !text-[#3777BC]" />
            }
            sx={{
              boxShadow: "none",
              direction: "flex",
              alignItems: "end",
              width: "100%",
              minHeight: "32px !important",
              height: "32px !important",
              ".MuiAccordionSummary-content": {
                width: "100%",
                margin: "0px !important",
              },
            }}
            className="!text-[#3777BC] text-[18px] font-semibold"
          >
            {get(d, ["feedBrand", "nameTh"])} {get(d, ["totalAmountString"])}
          </AccordionSummary>
          <div className="flex flex-col gap-[6px]">
            {get(d, ["items"], []).map((i, index) => (
              <AccordionDetails
                key={`${i.id} + ${index}`}
                onClick={() => (setId(i.id), handleClickToSeeDetails(i.id))}
                sx={{
                  padding: "0px !important",
                  paddingX: "16px !important",
                }}
                className="cursor-pointer"
              >
                <div className="bg-[#FFFFFF] h-[58px] rounded-[15px] p-4 gap-3 border-[1px] border-[#E2E2E2] flex justify-between font-sans">
                  <div className="flex flex-row gap-2">
                    <PigFeedSvg />
                    <div className="text-[#34407B] text-[16px] font-semibold">
                      {get(i, ["feedBrandItem", "skuTh"])}
                    </div>
                  </div>
                  <div className="text-[#4B4B4B] text-[14px] font-medium">
                    {get(i, ["amount"], 0) > 0
                      ? getCommaNumber(get(i, ["amount"], 0))
                      : 0}{" "}
                    {
                      unitMap[
                        get(i, ["feedBrandItem", "unit"], "") as "bag" | "kg"
                      ]
                    }
                  </div>
                </div>
              </AccordionDetails>
            ))}
          </div>
        </Accordion>
      ))}
    </div>
  );
};

export default AccordionShowList;

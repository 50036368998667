import { vaccinesList } from "../../services/AuthService";

export async function dailyLogLoader() {
  try {
    const { data, status } = await vaccinesList();

    if (data?.success) {
      return data.vaccines;
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
}

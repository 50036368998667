import { useFormik } from "formik";
import { date, number, object, string } from "yup";
import TextInputFS from "../../components/common/Input/TextInput";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { formError, formErrorMessage } from "../../utils/form-validator";
import DatePickerFS from "../../components/common/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDot } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import EventIcon from "@mui/icons-material/Event";

enum FarmBusinessType {
  ContractFarm = "CF",
  NonContractFarm = "NCF",
}

interface AddFarmDetailsProps {
  formState?: any;
  onSubmit?: (form: any) => Promise<any>;
}
export default function AddFarmDetails({
  formState,
  onSubmit,
}: AddFarmDetailsProps) {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const maxDate = dayjs();

  const [serverError, setServerError] = useState<string>("");
  const farmStatus = [
    {
      value: "true",
      label: t("add_farm_details.status_of_farm_in_business"),
    },
    {
      value: "false",
      label: t("add_farm_details.status_of_farm_out_of_business"),
    },
  ];

  useEffect(() => {
    setInitialState();
  }, []);

  async function setInitialState() {
    if (formState) {
      await formik.setValues(formState, true);

      setTimeout(async () => {
        await formik.validateForm();
      }, 10);
    }
  }
  const formik = useFormik({
    initialValues: {
      establishmentDate: null,
      farmStatus: "",
      businessType: null,
      partnerRefaralNumber: "",
    },
    validationSchema: object({
      establishmentDate: date()
        .nullable()
        .required("Establishment Year required"),
      farmStatus: string().required("Status of Farm Required"),
      businessType: string().required(),
      partnerRefaralNumber: number()
        .positive("Invalid Partner Referral Number")
        .integer("Invalid Partner Referral Number")
        .typeError("Invalid Partner Referral Number")
        .optional()
        .min(8000001, "Must be above 8000000")
        .max(9999999, "Must be in form 8000000 or 9000000"),
    }),
    onSubmit: async (values) => {
      try {
        const { resp, editForm } = await onSubmit?.({
          ...values,
          establishmentDate: dayjs(values.establishmentDate).format("YYYY"),
        });

        if (editForm && editForm.id) {
          if (resp.status === 204) {
            navigate("/edit-farm-success", {
              state: {
                selectedFarm: resp?.data?.farm,
              },
            });

            return true;
          }
        } else {
          if (resp?.data?.success) {
            navigate("/add-farm-success", {
              state: {
                selectedFarm: resp?.data?.farm,
              },
            });
            return false;
          }
        }

        setServerError(resp?.data?.message);
        setTimeout(() => {
          setServerError("");
        }, 5000);

        return true;
      } catch (err) {
        console.error(err);
      }
    },
  });

  const handleDateChange = (val: Dayjs | null) => {
    if (val) {
      formik.setFieldTouched("establishmentDate", true);
      formik.setFieldValue("establishmentDate", val);
    }
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="flex flex-col grow gap-4 justify-between"
    >
      <div className="flex flex-col gap-4">
        <Box>
          <DatePickerFS
            views={["year"]}
            maxDate={maxDate}
            placeholder={t("add_farm_details.establishment_year_placeholder")}
            value={formik?.values?.establishmentDate}
            slotProps={{
              textField: {
                helperText: formErrorMessage(formik, "establishmentDate"),
                error: formError(formik, "establishmentDate"),
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <EventIcon color="info" />
                    </InputAdornment>
                  ),
                },
              },
            }}
            label={t("add_farm_details.establishment_year_label")}
            onChange={(value) => handleDateChange(value)}
          />
        </Box>

        <TextInputFS
          select
          fullWidth
          helperText={formErrorMessage(formik, "farmStatus")}
          error={formError(formik, "farmStatus")}
          placeholder={t("add_farm_details.status_of_farm_placeholder") || ""}
          label={t("add_farm_details.status_of_farm_label")}
          {...formik.getFieldProps("farmStatus")}
        >
          {farmStatus.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextInputFS>

        <FormControl
          className="flex flex-col gap-2"
          sx={{ paddingX: "0.5rem" }}
          error={formError(formik, "businessType")}
        >
          <FormLabel
            sx={{ fontWeight: 500, textAlign: "left" }}
            id="businessType"
          >
            {t("add_farm_details.business_type_label")}
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            {...formik.getFieldProps("businessType")}
          >
            <FormControlLabel
              value={FarmBusinessType.ContractFarm}
              control={
                <Radio
                  size="small"
                  color="secondary"
                  checkedIcon={<FontAwesomeIcon size="lg" icon={faCircleDot} />}
                />
              }
              label={t("add_farm_details.business_type_contract_farm")}
            />
            <FormControlLabel
              value={FarmBusinessType.NonContractFarm}
              control={
                <Radio
                  size="small"
                  color="secondary"
                  checkedIcon={<FontAwesomeIcon size="lg" icon={faCircleDot} />}
                />
              }
              label={t("add_farm_details.business_type_non_contract_farm")}
            />
          </RadioGroup>
          <FormHelperText>
            {formErrorMessage(formik, "businessType")}
          </FormHelperText>
        </FormControl>

        <TextInputFS
          helperText={formErrorMessage(formik, "partnerRefaralNumber")}
          error={formError(formik, "partnerRefaralNumber")}
          placeholder={
            t("add_farm_details.partner_referral_number_placeholder") || ""
          }
          label={t("add_farm_details.partner_referral_number_label")}
          {...formik.getFieldProps("partnerRefaralNumber")}
        />
      </div>
      <div className="flex flex-col gap-4 mb-4">
        {serverError && (
          <Typography
            variant="body2"
            className="text-[#D32F2F] px-4"
            align="center"
          >
            {serverError}
          </Typography>
        )}
        <Button
          type="submit"
          disabled={!formik?.isValid || formik?.isSubmitting}
          variant="contained"
          fullWidth
          color="secondary"
          size="large"
        >
          {t("add_farm_details.next_button_label")}
        </Button>
      </div>
    </form>
  );
}

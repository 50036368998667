import { useDispatch } from "react-redux"
import * as Y from 'yup'
import { AppDispatch } from "../../../../../redux/store"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { TForm, defaultForm } from "../defaultForm"
import { cloneDeep, get, isEmpty, set } from "lodash"
import useFormValidator from "../../../../../hooks/useFormValidator.hook"
import { ICreateGroupAreaModalProps } from "./interface"
import { createGroup, editGroupArea } from "../../../../../services/GroupArea.service"
import { alertAction } from "../../../../../redux/slices/alertSlice"
import { loadingActions } from "../../../../../redux/slices/loadingSlice"
import { snakeActions } from "../../../../../redux/slices/snakeSlice"
import getErrorMessage from "../../../../../utils/getErrorMessage"

const errorSchema = Y.object({
  name: Y.string().required('กรุณากรอกชื่อกลุ่ม'),
  area: Y.array().min(1, 'กรุณาเลือกภาค'),
  province: Y.array().min(1, 'กรุณาเลือกจังหวัด'),
  district: Y.array().min(1, 'กรุณาเลือกเขต/อำเภอ'),
  employee: Y.array().min(1, 'กรุณาเลือกพนักงานขาย'),
  partner: Y.array().min(1, 'กรุณาเลือกพาร์ทเนอร์'),
})

export const useCreateAndEditGroupAreaModal = (props: ICreateGroupAreaModalProps) => {
  const {
    isOpen,
    editGroupId,
    setEditGroupId,
    onClose,
    options,
    refetch,
    editDetail,
    setDetail,
  } = props

  const dispatch = useDispatch<AppDispatch>()
  const [currentPage, setCurrentPage] = useState(1)
  const [customerCount, setCustomerCount] = useState(0)
  const [customerListInit, setCustomerList] = useState<{ id: string, code: string, name: string }[]>([])
  const [form, setForm] = useState<TForm>(defaultForm)
  const [customerSelectedList, setCustomerSelectedList] = useState<{ [key: string]: boolean }>({})
  const [searchText, setSearchText] = useState('')

  const [isOpenMoveToModal, setIsOpenMoveToModal] = useState(false)
  const openMoveTo = useCallback(() => {
    isInit.current = false
    setIsOpenMoveToModal(true)
  }, [])
  const closeMoveTo = useCallback(() => setIsOpenMoveToModal(false), [])

  const [isOpenAddModal, setIsOpenAddModal] = useState(false)
  const openAdd = useCallback(() => {
    isInit.current = false
    setIsOpenAddModal(true)
  }, [])
  const closeAdd = useCallback(() => setIsOpenAddModal(false), [])

  const onSearch = useCallback((e: any) => {
    const text = e.target.value
    setSearchText(text)
  }, [])

  const onClearSearchText = useCallback(() => {
    setSearchText('')
  }, [])
  
  const onSelectCustomer = useCallback((customerId: string) => () => {
    setCustomerSelectedList(prev => {
      const prevState = cloneDeep(prev)
      const isSelected = get(prevState, customerId, false)
      return set(prevState, [customerId], !isSelected)
    })
  }, [])

  const itemSelected = useMemo(() => {
    return Object.entries(customerSelectedList).map(([key, value]) => ({ key, value })).filter(({ value }) => value === true).map(({ key }) => key)
  }, [customerSelectedList])

  const isEdit = useMemo(() => {
    return !isEmpty(editGroupId)
  }, [editGroupId])

  const onChangeForm = useCallback((key: keyof TForm) => (value: any) => {
    if (key === 'area') {
      setCustomerList([])
      setCustomerCount(0)
      setForm(prev => {
        return { ...prev, province: [], district: [], [key]: value }
      })
    } else if (key === 'province') {
      setCustomerList([])
      setCustomerCount(0)
      setForm(prev => {
        return { ...prev, district: [], [key]: value }
      })
    } else if (key === 'district') {
      setCustomerList([])
      setCustomerCount(0)
      setForm(prev => {
        return { ...prev, [key]: value }
      })
    } else {
      setForm(prev => {
        return { ...prev, [key]: value }
      })
    }
  }, [])

  const provinceOptions = useMemo(() => {
    if (isEmpty(form.area)) return []
    const normalOptions = options.provinceOptions.filter(({ areaCode }) => areaCode === get(form.area, [0, 'value']))
    if (!isEdit) {
      return normalOptions
    }
    return [
      ...options.allProvinceOptions.filter(({ value }) => {
        const isFound = editDetail?.userGroup.userGroupProvinces.findIndex(({ province }) => `${province.code}` === value) !== -1
        return isFound
      }),
      ...normalOptions
    ]
  }, [options.provinceOptions, form.area, isEdit, editDetail])

  const districtOptions = useMemo(() => {
    if (isEmpty(form.province)) return []
    const normalOptions = []
    for (let i = 0; i < options.districtOptions.length; i++) {
      const { provinceCode } = options.districtOptions[i]
      const isFound = form.province.findIndex(({ value }) => value === provinceCode) !== -1
      isFound && normalOptions.push(options.districtOptions[i])
    }
    if (!isEdit) {
      return normalOptions
    }
    const newOptions = options.allDistrictOptions.filter(({ value }) => {
      const isFound = editDetail?.userGroup.userGroupDistricts.findIndex(({ district }) => `${district.code}` === value) !== -1
      return isFound
    })
    
    return [
      ...newOptions,
      ...normalOptions,
    ]
  }, [options.districtOptions, form.province, isEdit, editDetail])

  const {
    onSubmit: onCreate,
    errors,
    resetError,
  } = useFormValidator({
    form: {
      ...form,
      isEdit,
      editGroupId,
    },
    errorSchema,
    onValidateSuccess: () => {
      if (!isEdit) {
        dispatch(alertAction.showDialog({
          title: 'ยืนยันการสร้างกลุ่ม',
          text: 'คุณต้องการสร้างรายการนี้หรือไม่?',
          cancelText: 'กลับไปแก้ไข',
          confirmText: 'ยืนยัน',
          onCancel: () => {
            dispatch(alertAction.hide())
          },
          onSubmit: () => {
            dispatch(loadingActions.show({ message: 'กำลังสร้างกลุ่ม...' }))
            createGroup({
              name: form.name,
              regionCode: +get(form, ['area', 0, 'value']),
              provinceCodes: get(form, ['province'], []).map(({ value }) => +value),
              districtCodes: get(form, ['district'], []).map(({ value }) => +value),
              sellerId: get(form, ['employee', 0, 'value']),
              partnerId: get(form, ['partner', 0, 'value']),
            }).then(() => {
              refetch()
              dispatch(alertAction.hide())
              setTimeout(() => {
                dispatch(loadingActions.hide())
                dispatch(snakeActions.showMessage({
                  message: 'สร้างกลุ่มสำเร็จ',
                  type: 'success',
                }))
                onCloseFn()
              }, 1000)
            }).catch((error) => {
              console.log('fail to creategroup error:', error)
              dispatch(alertAction.hide())
              setTimeout(() => {
                dispatch(loadingActions.hide())
                dispatch(snakeActions.showMessage({
                  message: getErrorMessage(error),
                  type: 'error',
                }))
              }, 1000)
            })
          },
        }))
      } else {
        if (!editGroupId) return false
        dispatch(alertAction.showDialog({
          title: 'ยืนยันการเปลี่ยนแปลง',
          text: 'คุณต้องการบันทึกใช่หรือไม่?',
          cancelText: 'ยกเลิก',
          confirmText: 'ยืนยัน',
          onCancel: () => {
            dispatch(alertAction.hide())
          },
          onSubmit: () => {
            dispatch(loadingActions.show({ message: 'กำลังแก้ไขกลุ่ม...' }))
            editGroupArea({
              id: editGroupId,
              name: form.name,
              regionCode: +get(form, ['area', 0, 'value']),
              provinceCodes: get(form, ['province'], []).map(({ value }) => +value),
              districtCodes: get(form, ['district'], []).map(({ value }) => +value),
              sellerId: get(form, ['employee', 0, 'value']),
              partnerId: get(form, ['partner', 0, 'value']),
            }).then(() => {
              refetch()
              dispatch(alertAction.hide())
              setTimeout(() => {
                dispatch(loadingActions.hide())
                dispatch(snakeActions.showMessage({
                  message: 'บันทึกสำเร็จ',
                  type: 'success',
                }))
                onCloseFn()
              }, 1000)
            }).catch((error) => {
              console.log('fail to create/edit group error:', error)
              dispatch(alertAction.hide())
              setTimeout(() => {
                dispatch(loadingActions.hide())
                dispatch(snakeActions.showMessage({
                  message: getErrorMessage(error),
                  type: 'error',
                }))
              }, 1000)
            })
          },
        }))
      }
    },
  })

  const isInit = useRef(false)

  const onCloseFn = useCallback(() => {
    onClose()
    setTimeout(() => {
      resetError()
      setForm(defaultForm)
      setEditGroupId('')
      setDetail(undefined)
      isInit.current = false
      setSearchText('')
      setCustomerSelectedList({})
    }, 500)
  }, [])

  useEffect(() => {
    if (isOpen && isEdit && editDetail && isInit.current === false) {
      isInit.current = true
      setForm(prev => ({
        ...prev,
        name: editDetail.userGroup.name,
        area: [{ value: `${editDetail.userGroup.region.code}`, label: editDetail.userGroup.region.nameTh }],
        province: editDetail.userGroup.userGroupProvinces.map(({ id, province }) => ({
          value: `${province.code}`,
          label: province.nameTh,
        })),
        district: editDetail.userGroup.userGroupDistricts.map(({ id, district }) => ({
          value: `${district.code}`,
          label: district.nameTh,
        })),
        employee: [{
          value: `${editDetail.userGroup.seller.id}`,
          label: get(editDetail.userGroup.seller, 'name', '-') !== '-'
            ? `${editDetail.userGroup.seller.name} ${editDetail.userGroup.seller.surname}`
            : editDetail.userGroup.seller.employeeCode
        }],
        partner: [{
          value: `${editDetail.userGroup.partner.id}`,
          label: `${editDetail.userGroup.partner.name} ${editDetail.userGroup.partner.surname}`,
        }],
      }))
      setCustomerList(editDetail.farmers.map(({ id, name, surname, registrationId }) => ({
        id,
        code: registrationId,
        name: `${name} ${surname}`,
      })))
    }
  }, [isOpen, isEdit, editDetail, provinceOptions, districtOptions, isInit.current])

  const customerList = useMemo(() => {
    return customerListInit.filter(({ code, name }) => {
      const a = searchText.toLowerCase()
      const b = code.toLowerCase()
      const c = name.toLowerCase()
      return b.indexOf(a) !== -1 || c.indexOf(a) !== -1
    })
  }, [customerListInit, searchText])

  const onSelectAllClick = useCallback((e: any) => {
    const isCheck = e.target.checked
    let selected = {}
    if (isCheck) {
      for (let i = 0; i < customerList.length; i++) {
        selected = { ...selected, [customerList[i].id]: true }
      }
      setCustomerSelectedList(selected)
    } else {
      setCustomerSelectedList({})
    }
  }, [editDetail, customerList])

  return {
    isEdit,
    currentPage,
    setCurrentPage,
    onCloseFn,
    errors,
    form,
    onChangeForm,
    provinceOptions,
    districtOptions,
    itemSelected,
    onSelectAllClick,
    customerCount,
    customerList,
    customerSelectedList,
    onSelectCustomer,
    onCreate,
    searchText,
    onSearch,
    onClearSearchText,
    isOpenMoveToModal,
    openMoveTo,
    closeMoveTo,
    isOpenAddModal,
    openAdd,
    closeAdd,
  }
}

export function DocumentSvg() {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.800781 2.80039C0.800781 1.47491 1.8753 0.400391 3.20078 0.400391H8.70372C9.34024 0.400391 9.95069 0.653247 10.4008 1.10333L14.4978 5.20039C14.9479 5.65048 15.2008 6.26093 15.2008 6.89745V17.2004C15.2008 18.5259 14.1263 19.6004 12.8008 19.6004H3.20078C1.8753 19.6004 0.800781 18.5259 0.800781 17.2004V2.80039ZM3.20078 10.0004C3.20078 9.33765 3.73804 8.80039 4.40078 8.80039H11.6008C12.2635 8.80039 12.8008 9.33765 12.8008 10.0004C12.8008 10.6631 12.2635 11.2004 11.6008 11.2004H4.40078C3.73804 11.2004 3.20078 10.6631 3.20078 10.0004ZM4.40078 13.6004C3.73804 13.6004 3.20078 14.1376 3.20078 14.8004C3.20078 15.4631 3.73804 16.0004 4.40078 16.0004H11.6008C12.2635 16.0004 12.8008 15.4631 12.8008 14.8004C12.8008 14.1376 12.2635 13.6004 11.6008 13.6004H4.40078Z"
        fill="currentColor"
      />
    </svg>
  );
}

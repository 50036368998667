import {
  IGetBarChartInfo,
  IGetListRevenue,
  IGetProfilePartner,
  IGetSummaryContract,
  IGetSummaryCustomer,
  IGetSummaryRevenue,
} from "../../types/partner/PartnerLanding.type";
import api from "../ApiService";

export const getProfilePartner = async () => {
  const { data } = await api.get<IGetProfilePartner>("/partner/auth/profile");
  return data;
};

export const getSummaryCustomer = async () => {
  const { data } = await api.get<IGetSummaryCustomer>(
    `/partner/revenue/summary/customer`
  );
  return data;
};

export const getSummaryRevenue = async (params?: {
  month?: string;
  christianYear?: number;
}) => {
  const { data } = await api.get<IGetSummaryRevenue>(
    `/partner/revenue/summary/income`,
    {
      params: {
        month: params?.month,
        christianYear: params?.christianYear,
      },
    }
  );
  return data;
};

export const getListRevenue = async (params?: {
  dateStart?: Date;
  dateEnd?: Date;
  revenueType?: string;
}) => {
  const { data } = await api.get<IGetListRevenue>(`/partner/revenue/list`, {
    params: {
      dateStart: params?.dateStart,
      dateEnd: params?.dateEnd,
      revenueType: params?.revenueType,
    },
  });
  return data;
};

export const getBarChartInfo = async (params?: {
  revenueType?: string;
  christianYear?: number;
}) => {
  const { data } = await api.get<IGetBarChartInfo>(`/partner/revenue/graph`, {
    params: {
      revenueType: params?.revenueType,
      christianYear: params?.christianYear,
    },
  });
  return data;
};

export const getSummaryContract = async () => {
  const { data } = await api.get<IGetSummaryContract>(
    `/partner/revenue/summary/contract`
  );
  return data;
};

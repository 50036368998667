import { useQuery } from "@tanstack/react-query";
import {
  getDownLoadReceiptUrl,
  getReceiptInvoice,
} from "../../../../../../../services/Invoice.service";
import { useCallback, useEffect, useState } from "react";
import { get } from "lodash";
import axios from "axios";
import { handleDownloadBlob } from "../../../../../../../utils/downloadFile";
import { TFarmerGetListBillItem } from "../../../../../../../types/Invoice.type";

export const useReceiptController = (
  id: string,
  isMoney: boolean,
  dataMon?: TFarmerGetListBillItem[]
) => {
  const [imgList, setImagesList] = useState<
    { id: string; url: string; name: string }[]
  >([]);

  const { data: listInvoice, refetch: refetchListInvoice } = useQuery({
    queryKey: ["receipt_invoice" + id],
    queryFn: async () => {
      if (id) {
        const result = await getReceiptInvoice({ invoiceId: id });
        return result;
      }
      return null;
    },
    enabled: !isMoney,
  });

  const initImageList = useCallback(async () => {
    const imgs = get(listInvoice, ["invoiceReceipts"], []);
    const imgsInvoice = get(dataMon, [0, "invoiceFiles"], []);
    const tmpImage = [];
    if (isMoney) {
      for (let i = 0; i < imgsInvoice.length; i++) {
        try {
          const img = imgsInvoice[i];
          const preSignURL = await getDownLoadReceiptUrl({
            fileId: img.id,
            docType: "debt_acknowledgement_letter",
          });
          if (preSignURL) {
            const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
              responseType: "blob",
            });
            const objectURL = window.URL.createObjectURL(response.data);
            tmpImage.push({
              id: img.id,
              url: objectURL,
              name: img.fileOriginalName,
            });
          }
        } catch (e) {
          console.log("error to fetch image <Maybe timeout> e:", e);
        }
      }
    } else
      for (let i = 0; i < imgs.length; i++) {
        try {
          const img = imgs[i];
          const preSignURL = await getDownLoadReceiptUrl({
            fileId: img.fileReceipt.id,
          });
          if (preSignURL) {
            const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
              responseType: "blob",
            });
            const objectURL = window.URL.createObjectURL(response.data);
            tmpImage.push({
              id: img.id,
              url: objectURL,
              name: img.fileReceipt.fileOriginalName,
            });
          }
        } catch (e) {
          console.log("error to fetch image <Maybe timeout> e:", e);
        }
      }
    setImagesList(tmpImage);
  }, [id, listInvoice, dataMon]);

  const handleDownloadByUrl = useCallback(() => {
    imgList.map((img) => {
      handleDownloadBlob(img.url, img.name);
    });
  }, [imgList]);

  useEffect(() => {
    refetchListInvoice();
  }, [id]);

  useEffect(() => {
    initImageList();
  }, [listInvoice, dataMon]);

  return {
    listInvoice,
    imgList,
    refetchListInvoice,
    handleDownloadByUrl,
  };
};

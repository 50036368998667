import { useQuery } from "@tanstack/react-query";
import { getContractDocument } from "../../../services/partner/PartnerSectionContract.service";

export const usePartnerViewContractController = () => {
  const { data: ContractDocument } = useQuery({
    queryKey: ["contract-document"],
    queryFn: async () => {
      const result = await getContractDocument();
      return result;
    },
  });

  return {
    ContractDocument,
  };
};

import DeleteIcon from "@mui/icons-material/Delete";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Switch from "@mui/material/Switch";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BackgrounImage from "../../../assets/images/Login/BackGroungImage.png";
import AppBarFS from "../../../components/app-bar/AppBar";
import { INotification } from "../../../types/Notification.type";
import { dateToString } from "../../../utils/date";
import { tw } from "../../../utils/tw";
import { useNotificationController } from "./controller";

export const notification_module_invoice = {
  invoice_payment_overdue: "ครบกำหนดชำระ",
  invoice_payment_today: "เลยกำหนดชำระ",
  invoice_payment_lending_approve: "คงค้างชำระ",
  invoice_follow_payment: "ติดตามหนี้สิน",
  invoice_success_payment: "จ่ายสำเร็จ",
  "": "",
};

const NotificationPage = () => {
  const { dataNotification, onReadAll, onRead, onRemove } =
    useNotificationController();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const createNotificationsCard = (item: INotification, index: any) => {
    return (
      <div
        key={`${index}-notifications-card`}
        className="max-w-sm rounded-[20px] overflow-hidden shadow-lg bg-[#ffffff] w-[90%] h-auto mt-3 relative"
      >
        <div
          className="flex flex-row py-4"
          onClick={() =>
            onRead(get(item, ["id"], ""), get(item, ["refId"], ""))
          }
        >
          {!get(item, ["read"], false) && (
            <div className="flex justify-center items-center pl-4">
              <div className="w-[13px] h-[13px] bg-[#37A1DB] rounded-[8px]"></div>
            </div>
          )}
          <div className="flex flex-col gap-4 px-4">
            <div className="flex flex-row justify-between w-full">
              <p
                className={tw(
                  "text-[#DC5B5B] font-[600] text-[20px]",
                  (item.module === "invoice_follow_payment" ||
                    item.module === "invoice_success_payment") &&
                    "text-[#68C184]"
                )}
              >
                {
                  notification_module_invoice[
                    get(item, ["module"], "") as
                      | "invoice_payment_overdue"
                      | "invoice_payment_today"
                      | "invoice_payment_lending_approve"
                      | "invoice_follow_payment"
                      | "invoice_success_payment"
                  ]
                }
              </p>
            </div>
            <div className="text-[15px] text-[#646464] font-medium">
              {get(item, ["message"], "")}
            </div>
          </div>
        </div>
        <div
          onClick={() => onRemove(get(item, ["id"], ""))}
          className="z-10 absolute top-4 right-4"
        >
          <DeleteIcon
            className={tw(
              "!text-[24px] text-[#DC5B5B]",
              (item.module === "invoice_follow_payment" ||
                item.module === "invoice_success_payment") &&
                "text-[#68C184]"
            )}
          />
        </div>
      </div>
    );
  };

  const groupNotificationsByDate = (notifications: INotification[]) => {
    if (notifications) {
      return notifications.reduce((acc, item) => {
        const date = dateToString(new Date(item.startShowAt)).split("T")[0];
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(item);
        return acc;
      }, {} as Record<string, INotification[]>);
    }
    return null;
  };

  const groupedNotifications = groupNotificationsByDate(dataNotification);

  return (
    <div
      style={{
        backgroundImage: `url(${BackgrounImage})`,
        height: "auto !important",
        backgroundSize: "cover",
        backgroundColor: "#4882c8",
      }}
      className="!h-auto !min-h-screen"
    >
      <div className="h-[120px] flex items-center bg-[#FFFF] rounded-b-[40px]">
        <AppBarFS
          title={`${t("notification.headerTitleText") || ""}`}
          onIconPress={() => navigate(-1)}
        />
      </div>
      <div className="h-[75%]">
        <div className="flex justify-end flex-row mt-4 gap-4 px-4 pb-2">
          <div
            className="flex flex-row px-[10px] py-1 bg-[#F0FDF4] border-[1px] border-[#68C184] rounded-[10px] items-center"
            onClick={onReadAll}
          >
            <div>
              <DoneOutlinedIcon className="text-[20px] text-[#68C184]" />
              <DoneOutlinedIcon className="text-[20px] text-[#68C184] ml-[-16px]" />
            </div>
            <p className="text-[#68C184] ml-[10px] font-normal text-[15px]">
              {" "}
              Mark All Read
            </p>
          </div>
          <div className="flex flex-row items-center gap-2">
            <NotificationsIcon className="text-[20px] text-[#FFFFFF]" />
            <Switch defaultChecked color="secondary" size="medium" />
          </div>
        </div>
        {groupedNotifications ? (
          Object.keys(groupedNotifications).map((date) => (
            <div key={date}>
              <div className="flex justify-start flex-row font-semibold ml-4 text-[#ffffff]">
                <p> {date} </p>
              </div>
              <div className="flex flex-col items-center mt-2 pb-4">
                {groupedNotifications[date].map((notification, index) =>
                  createNotificationsCard(notification, index)
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="w-full flex flex-row justify-center">
            ไม่มีการแจ้งเตือน
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationPage;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  prefix: string;
}

export function a11yProps(prefix = "fs-admin", index: number) {
  return {
    id: `${prefix}-tab-${index}`,
    "aria-controls": `${prefix}-tabpanel-${index}`,
  };
}

export function TabPanelCustom(props: TabPanelProps) {
  const { children, value, index, prefix = "fs-admin", ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${prefix}-tabpanel-${index}`}
      aria-labelledby={`${prefix}-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

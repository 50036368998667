import {
  Box,
  FilledTextFieldProps,
  MenuItem,
  OutlinedTextFieldProps,
  SelectProps,
  StandardTextFieldProps,
} from "@mui/material";
import "./Input.styles.scss";
import { useState } from "react";
import SelectCustom from "./SelectCustom";
import { FieldInputProps, FormikState } from "formik";
import { NumericFormat } from "react-number-format";
import { TFunction } from "i18next";

export interface IDropdownAndTextInput {
  dropdownItems: {
    id: string | number;
    name: string | number;
  }[];
  dropdownInputProps?: SelectProps;
  inputProps?:
    | FilledTextFieldProps
    | OutlinedTextFieldProps
    | StandardTextFieldProps;
  isError?: boolean;
  helperText?: string;
  getFieldDropDownProps: FieldInputProps<any>;
  getFieldInputProps: FieldInputProps<any>;
  formik: FormikState<any>;
  onChangeDropdown: (value: string) => void;
  onChangeInput: (value: string) => void;
  dropdownFieldName: string;
  inputFieldName: string;
  max: number;
  t: TFunction<"translation", undefined, "translation">;
}

export default function DropdownAndTextInput({
  dropdownItems,
  dropdownInputProps,
  inputProps,
  isError,
  helperText,
  getFieldDropDownProps,
  getFieldInputProps,
  formik,
  onChangeDropdown,
  onChangeInput,
  dropdownFieldName,
  inputFieldName,
  max,
  t,
}: IDropdownAndTextInput) {
  const [isFocus, setIsFocus] = useState<boolean>(true);
  return (
    <Box
      sx={{
        width: "100%",
        height: "56px",
        borderRadius: "28px",
        border: "1px solid",
        borderColor: `${
          isError
            ? "#d32f2f !important"
            : isFocus
            ? "#3777BC !important"
            : "#00000033"
        }`,
        backgroundColor: "#F6FBFF",
        position: "relative",
        ...(isError ? { mb: "1.5rem" } : {}),
      }}
      className="custom-input-and-dropdown"
    >
      <span
        className="absolute top-0 left-0 transform translate-x-[-14px] translate-y-[-8px] scale-[0.75] text-[1rem] bg-white px-2 leading-3"
        style={{
          color: `${
            isError ? "#d32f2f" : isFocus ? "#3777BC" : "rgba(0, 0, 0, 0.6)"
          }`,
        }}
      >
        {t("moveOutPigSelling.input.label.pigsMoveOut")}
      </span>
      <div className="flex justify-items-stretch w-full relative">
        <SelectCustom
          displayEmpty
          shrink={false}
          isHiddenBorder={true}
          error={isError}
          variant="outlined"
          menuItems={dropdownItems.map((val, index) => (
            <MenuItem key={`dropdown-${index}`} value={val.id}>
              {t(`moveOutPigSelling.moveOutTypeType.${val.id}` as any)}
            </MenuItem>
          ))}
          {...getFieldDropDownProps}
          onChange={(e) => onChangeDropdown(e.target.value as string)}
          renderValue={
            formik?.values[dropdownFieldName] ||
            formik?.values[dropdownFieldName] !== ""
              ? undefined
              : () => (
                  <div className="text-[1rem] text-primary-grey-100">
                    {t("moveOutPigSelling.input.placeholder.pigMoveOutType")}
                  </div>
                )
          }
        />
        <NumericFormat
          // name={inputFieldName}
          {...getFieldInputProps}
          onValueChange={function (event): void {
            onChangeInput(Number(event.value).toString());
          }}
          value={String(formik?.values[inputFieldName])}
          isAllowed={(values) => {
            onChangeInput(Number(values?.floatValue).toString());
            return (
              values?.floatValue === undefined || values?.floatValue <= max
            );
          }}
          // pattern="[0-9]*"
          onChange={(e) => {}}
          type="tel"
          autoComplete="off"
          placeholder={inputProps?.placeholder}
          // customInput={() => (
          //   <CssTextField inputProps={{ pattern: "[0-9]*" }} />
          // )}
          className="custom-input-number"
          decimalScale={0}
          fixedDecimalScale={false}
          thousandSeparator=","
          allowLeadingZeros={false}
          allowNegative={false}
          valueIsNumericString={true}
        />
      </div>
      {isError && (
        <span className={`pb-4 text-error text-[0.75rem] mx-[14px]`}>
          {helperText}
        </span>
      )}
    </Box>
  );
}

export interface Feed {
  pricePerUnit: number;
  unit: keyof typeof UnitEnum;
  consumption: number;
  endWeight: number;
  id: string;
  protein: number;
  skuEn: string;
  skuTh: string;
  startWeight: number;
}

export enum UnitEnum {
  kg = "กิโลกรัม",
  bag = "ถุง",
}

export interface FeedBrands {
  feed_brand_items: Feed[];
  id: string;
  nameEn: string;
  nameTh: string;
}

export interface IGetFeedBrands {
  feedBrands: FeedBrands[];
}

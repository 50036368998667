import { DocumentURL } from "../../../types/ImportDocument.type";
import DownloadLinkWithDelete from "./DownloadLinkWithDelete";

interface ListOfUploadedFileProps {
  files: DocumentURL[];
  onDownload: (row: DocumentURL) => Promise<void>;
}

function ListOfUploadedFile({ files, onDownload }: ListOfUploadedFileProps) {
  return files.map((row, idx) => {
    return (
      <div key={idx}>
        <DownloadLinkWithDelete
          hideDeleteBtn
          onDownload={() => onDownload(row)}
          name={row.fileName}
        />
      </div>
    );
  });
}

export default ListOfUploadedFile;

import { TDropdownValue } from "../../../../group-area/views/Dropdown";

type TFileBeforeUpload = {
  fileName: string;
  fileSize: number;
  id: string;
};

export type TFormTabProcessing = {
  grade: TDropdownValue[];
};

export const defaultFormTabProcessing: TFormTabProcessing = {
  grade: [],
};

export type TFormForApprove = {
  farmSize: TDropdownValue[];
  farmName: TDropdownValue[];
  greenHouse: TDropdownValue[];
  pigType: string;
  countPig: string;
  financialAmount: string;
  grade: string;
  contractType: TDropdownValue[];
  timeCredit: string;
  startContract: Date;
  endContract: Date;
  creditCalculationDocuments: File[];
  creditCalculationDocumentsUpload: TFileBeforeUpload[];
  contractDocuments: File[];
  customerCreditLimitCalculatedByTheSystem: string;
};

export const defaultFormForApprove: TFormForApprove = {
  farmSize: [],
  farmName: [],
  greenHouse: [],
  pigType: "หมูขุน",
  countPig: "",
  financialAmount: "",
  grade: "",
  contractType: [],
  timeCredit: "",
  startContract: new Date(),
  endContract: new Date(),
  creditCalculationDocuments: [],
  creditCalculationDocumentsUpload: [],
  contractDocuments: [],
  customerCreditLimitCalculatedByTheSystem: "",
};

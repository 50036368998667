import { Button, ButtonProps } from "@mui/material";
import React from "react";

export interface IButtonFillCustom extends ButtonProps {
  title: string;
  btnTextColor?: string;
  btnBgColor?: string;
  hoverTextColor?: string;
  hoverBgColor?: string;
}

function ButtonFillCustom({
  title,
  btnTextColor,
  btnBgColor,
  hoverTextColor,
  hoverBgColor,
  ...rest
}: IButtonFillCustom) {
  return (
    <Button
      {...rest}
      variant="text"
      sx={{
        lineHeight: "0px",
        bgcolor: btnBgColor || "#68C184",
        fontWeight: 500,
        fontSize: "14px",
        color: btnTextColor || "#ffffff",
        height: "30px",
        borderRadius: "4px",
        ":hover": {
          bgcolor: btnBgColor || "#68C184",
          opacity: "0.8",
        },
      }}
    >
      {title}
    </Button>
  );
}

export default ButtonFillCustom;

export default function CashSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.39844 4.80078C3.29387 4.80078 2.39844 5.69621 2.39844 6.80078V12.4008C2.39844 13.5054 3.29387 14.4008 4.39844 14.4008H4.79844V7.20078H16.7985V6.80078C16.7985 5.69621 15.903 4.80078 14.7985 4.80078H4.39844ZM7.19844 11.6008C7.19844 10.4962 8.09387 9.60078 9.19844 9.60078H19.5985C20.703 9.60078 21.5985 10.4962 21.5985 11.6008V17.2008C21.5985 18.3054 20.703 19.2008 19.5985 19.2008H9.19844C8.09387 19.2008 7.19844 18.3054 7.19844 17.2008V11.6008ZM14.3985 16.8008C15.7239 16.8008 16.7985 15.7263 16.7985 14.4008C16.7985 13.0753 15.7239 12.0008 14.3985 12.0008C13.073 12.0008 11.9985 13.0753 11.9985 14.4008C11.9985 15.7263 13.073 16.8008 14.3985 16.8008Z"
        fill="#68C184"
      />
    </svg>
  );
}

export default function DeleteIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.418 4.41667L14.6229 15.5473C14.5544 16.5067 13.7561 17.25 12.7943 17.25H5.20835C4.24652 17.25 3.44821 16.5067 3.37968 15.5473L2.58464 4.41667M7.16797 8.08333V13.5833M10.8346 8.08333V13.5833M11.7513 4.41667V1.66667C11.7513 1.16041 11.3409 0.75 10.8346 0.75H7.16797C6.66171 0.75 6.2513 1.16041 6.2513 1.66667V4.41667M1.66797 4.41667H16.3346"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

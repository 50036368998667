export const THAI_COUNTRY_CODE = "66";
export const AUTH_TOKEN_KEY = "token";
export const AUTH_USERNAME = "username";
export const NUMBER_OF_MISTAKE = "mistakecount";
export const LOCK_OUT = "lockout";
export const IS_SAVED = "issaved";
export const ADMIN_AUTH_TOKEN_KEY = "adminToken";
export const ADMIN_AUTH_REFRESH_TOKEN_KEY = "adminRefreshToken";
export const PARTNER_AUTH_TOKEN_KEY = "partnerAuthToken";
export const PARTNER_AUTH_REFRESH_TOKEN_KEY = "partnerRefreshToken";
export const DATE_FORMAT_DATE_PICKER = "YYYY-MM-DD";
export const DATE_FORMAT_TABLE = "DD/MM/BBBB";
export const DATE_FORMAT_DATE_PICKER2 = "DD/MM/YYYY";
export const UPLOAD_PREFIX = "/upload/";

export default function ReportPoorPigSVG() {
  return (
    <svg
      width="86"
      height="76"
      viewBox="0 0 96 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2452_36971)">
        <path
          d="M65.271 14.1569C67.9543 11.2822 71.7724 9.66518 75.6439 8.94651C78.8346 8.35428 82.2722 7.99494 85.4362 8.95317C88.6001 9.91139 88.0461 12.8127 87.739 15.7938C87.0849 22.1953 84.0678 28.3838 79.2484 32.6959"
          fill="#EF9AB5"
        />
        <path
          d="M30.728 14.1571C28.0446 11.2891 24.2265 9.66543 20.355 8.94676C17.1643 8.36118 13.7267 7.99519 10.5628 8.95342C7.39881 9.91164 7.95951 12.8196 8.25988 15.7941C8.91403 22.1956 11.9311 28.3841 16.7505 32.6961"
          fill="#EF9AB5"
        />
        <g style={{ mixBlendMode: "multiply" }} opacity="0.66">
          <path
            d="M68.6221 18.1961C70.1306 16.5791 72.28 15.6674 74.4627 15.2615C76.2583 14.9288 78.1941 14.7225 79.9763 15.2615C81.7585 15.8005 81.4448 17.4375 81.2712 19.1144C80.9041 22.721 79.202 26.2013 76.4919 28.6301"
            fill="#E5ACB5"
          />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.66">
          <path
            d="M27.377 18.1961C25.8684 16.5791 23.7191 15.6674 21.5364 15.2615C19.7408 14.9288 17.805 14.7225 16.0228 15.2615C14.2406 15.8005 14.5543 17.4375 14.7278 19.1144C15.095 22.721 16.7971 26.2013 19.5071 28.6301"
            fill="#E5ACB5"
          />
        </g>
        <path
          d="M84.6222 51.7404C84.1282 48.4266 82.8132 45.2658 82.4394 41.9386C82.1057 38.9641 82.8399 35.8565 82.0656 32.9685C81.4782 30.7926 80.2233 28.2905 79.1887 26.2743C76.779 21.583 73.1478 17.4706 68.789 14.4894C63.5158 10.8828 56.467 9.51865 49.3915 9.71162C42.3159 9.51865 35.2671 10.8828 29.9939 14.4894C25.6351 17.4706 22.0039 21.583 19.5942 26.2743C18.5596 28.2905 17.3047 30.7926 16.7173 32.9685C15.943 35.8565 16.6772 38.9641 16.3435 41.9386C15.9697 45.2658 14.6547 48.4266 14.1607 51.7404C11.4507 69.9001 29.2262 76.621 46.8149 77.526V77.6325C47.6693 77.6391 48.5304 77.6325 49.3981 77.6192C50.2592 77.6391 51.127 77.6458 51.9814 77.6325V77.526C69.57 76.621 87.3456 69.8935 84.6355 51.7404H84.6222Z"
          fill="#FBABC2"
        />
        <path
          d="M46.0671 56.2589C47.3888 55.8197 48.7572 55.5469 50.1256 55.7998C51.7476 56.0992 53.3963 56.4519 55.045 56.585C59.1168 56.9044 63.0751 53.7635 63.6959 49.7976C64.2699 46.1244 62.7079 42.1318 60.345 39.3502C59.8043 38.7181 59.2036 38.1258 58.5361 37.6268C54.204 34.3928 48.1698 33.3613 42.9966 34.9251C37.4764 36.5887 32.8039 41.8922 32.864 47.8345C32.8974 51.1417 34.7797 54.5155 37.8502 55.7598C39.8727 56.5783 42.2891 56.8112 44.4585 56.6316C45.0192 56.585 45.5465 56.4386 46.0738 56.2656L46.0671 56.2589Z"
          fill="#EF819F"
        />
        <path
          d="M30.5877 41.5061C34.032 41.5061 34.032 36.1826 30.5877 36.1826C27.1434 36.1826 27.1434 41.5061 30.5877 41.5061Z"
          fill="#BA5D6F"
        />
        <path
          d="M65.7916 41.5061C69.2359 41.5061 69.2359 36.1826 65.7916 36.1826C62.3473 36.1826 62.3473 41.5061 65.7916 41.5061Z"
          fill="#BA5D6F"
        />
        <path
          d="M42.6763 50.1569C46.5478 50.1569 46.5478 44.168 42.6763 44.168C38.8048 44.168 38.8048 50.1569 42.6763 50.1569Z"
          fill="#BA5D6F"
        />
        <path
          d="M53.9837 50.1569C57.8552 50.1569 57.8552 44.168 53.9837 44.168C50.1122 44.168 50.1122 50.1569 53.9837 50.1569Z"
          fill="#BA5D6F"
        />
        <g style={{ mixBlendMode: "multiply" }} opacity="0.22">
          <path
            d="M28.5651 54.7284C30.8814 52.5591 30.6611 48.3203 27.644 46.8496C25.0407 45.5853 20.2014 46.3905 18.7596 49.0456C18.0587 50.3365 18.0854 51.9602 18.7329 53.2777C19.3803 54.5953 20.5952 55.6001 21.9769 56.0925C24.1997 56.891 26.8497 56.3387 28.5718 54.7284H28.5651Z"
            fill="#E5ACB5"
          />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.22">
          <path
            d="M68.3216 54.7284C66.0054 52.5591 66.2257 48.3203 69.2428 46.8496C71.846 45.5853 76.6854 46.3905 78.1272 49.0456C78.8281 50.3365 78.8014 51.9602 78.1539 53.2777C77.5064 54.5953 76.2916 55.6001 74.9098 56.0925C72.6871 56.891 70.0371 56.3387 68.3149 54.7284H68.3216Z"
            fill="#E5ACB5"
          />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.09">
          <path
            d="M84.6221 51.7405C84.1281 48.4267 82.8131 45.2659 82.4393 41.9387C82.1056 38.9642 82.8398 35.8566 82.0655 32.9686C81.4781 30.7927 80.2232 28.2906 79.1886 26.2744C76.7789 21.5831 73.1477 17.4707 68.789 14.4895C65.9988 12.5797 62.7013 11.3154 59.1703 10.5635C64.9642 14.596 69.116 21.7361 71.7527 27.8115C73.0209 30.7394 73.5683 33.501 73.4414 36.7017C73.3413 39.2304 74.1623 40.9672 74.7364 43.3627C75.9713 48.5398 76.058 55.1808 73.4414 59.972C68.9425 68.1967 59.0101 72.1494 50.0255 73.2074C43.9245 74.5849 37.3964 74.8378 31.202 74.8777C36.028 76.4148 41.4214 77.2533 46.8081 77.5328V77.6392C47.6625 77.6459 48.5236 77.6392 49.3914 77.6259C50.2524 77.6459 51.1202 77.6525 51.9746 77.6392V77.5328C69.5633 76.6278 87.3388 69.9002 84.6288 51.7472L84.6221 51.7405Z"
            fill="#E5ACB5"
          />
        </g>
        <g style={{ mixBlendMode: "multiply" }} opacity="0.09">
          <path
            d="M60.3384 39.3503C59.8778 38.8113 59.3638 38.3189 58.8165 37.873C59.0301 38.3522 59.217 38.8446 59.3438 39.3636C60.7189 44.7337 58.4961 49.4915 54.3576 52.9917C53.1828 53.9899 51.8811 54.988 50.4927 55.873C51.9946 56.1525 53.5098 56.4653 55.0384 56.5851C59.1102 56.9045 63.0685 53.7636 63.6892 49.7976C64.2633 46.1244 62.7013 42.1318 60.3384 39.3503Z"
            fill="#E5ACB5"
          />
        </g>
        <path
          d="M58.3669 64.9617C58.3669 64.9617 56.3052 59.9163 48.5877 59.9163C40.8703 59.9163 38.9767 64.9617 38.9767 64.9617"
          stroke="#BA5D6F"
          strokeWidth="1.77778"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="27.9998" cy="34.583" r="4" fill="#FBABC2" />
        <circle cx="68.8886" cy="35.4719" r="4" fill="#FBABC2" />
        <path
          d="M67.8623 44.911L70.363 51.8295C70.8952 53.2945 70.1341 54.9082 68.6691 55.4404C67.2042 55.9726 65.5904 55.2115 65.0582 53.7466C64.8236 53.1056 64.8408 52.4247 65.0582 51.8295L67.559 44.911C67.5876 44.8309 67.6791 44.7851 67.7593 44.8137C67.805 44.8309 67.8394 44.8652 67.8565 44.911H67.8623Z"
          fill="#91B5E6"
        />
      </g>
      <defs>
        <clipPath id="clip0_2452_36971">
          <rect
            width="80"
            height="69.2784"
            fill="white"
            transform="translate(7.99976 8.36084)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

const DollarWithCoinSvgBlue = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none">
    <g fill="#3777BC" clipPath="url(#a)">
      <path d="M17.007 3.627c-.472.207-.773.28-1.306.305a2.957 2.957 0 0 1-.812-.047c-.455-.094-.627-.086-.8.026-.167.116-.3.348-.3.52 0 .296.4 1.065.808 1.56l.232.275h5.311l.232-.275c.408-.495.808-1.264.808-1.56 0-.172-.133-.404-.301-.52-.172-.112-.344-.12-.8-.026-.248.052-.463.065-.811.047a3.178 3.178 0 0 1-1.32-.309c-.184-.082-.4-.15-.477-.146-.077 0-.283.069-.464.15ZM14.021 7.618c-.339.12-.623.533-.623.902 0 .387.318.821.667.92.24.064 6.59.064 6.832 0 .348-.099.666-.533.666-.92 0-.386-.319-.82-.666-.92-.12-.03-1.178-.046-3.438-.046-2.703.004-3.296.012-3.438.064ZM13.312 11.192c-.623.645-1.1 1.255-1.594 2.058-.223.361-.507.89-.507.941 0 .013.464.022 1.031.022.589 0 1.126.021 1.255.047a1.96 1.96 0 0 1 1.49 1.491c.061.292.061 1.354 0 1.65-.15.713-.738 1.32-1.434 1.474l-.215.052V21.1l.215.051c.352.078.627.237.902.512.28.283.438.567.516.906l.051.215h3.24c3.223 0 3.24 0 3.622-.099 1.23-.309 2.183-1.1 2.738-2.256.365-.764.45-1.525.3-2.56a12.404 12.404 0 0 0-1.194-3.752 11.204 11.204 0 0 0-2.05-2.904l-.43-.439H13.72l-.408.417Zm4.469.683c.137.112.219.327.219.563 0 .133.013.142.262.21.322.091.74.357.855.542.19.31.056.696-.275.808-.189.06-.348.017-.58-.159a.967.967 0 0 0-.228-.142c-.017 0-.03.409-.025.907l.012.902.306.12c.563.22.91.533 1.147 1.036.111.228.137.344.15.662.026.473-.038.773-.24 1.164-.22.413-.563.756-.959.946-.172.081-.34.15-.37.15-.038 0-.055.069-.055.202 0 .494-.19.743-.567.743-.19 0-.232-.017-.361-.146-.138-.137-.146-.163-.146-.43 0-.274-.004-.283-.116-.305-.426-.081-.585-.137-.872-.283-.503-.262-.602-.374-.602-.67 0-.15.026-.215.142-.336.223-.245.374-.245.85-.004.215.107.439.198.495.198h.103V16.24l-.37-.206c-.498-.28-.717-.482-.88-.817a1.35 1.35 0 0 1-.16-.618c-.051-.916.452-1.65 1.337-1.947.06-.021.073-.077.073-.314 0-.266.008-.292.146-.43.129-.128.172-.145.36-.145.173 0 .246.026.349.111Z" />
      <path d="M16.779 13.905c-.18.198-.258.632-.155.838.052.1.207.241.267.245.017 0 .03-.262.03-.58 0-.318-.017-.58-.035-.58-.021 0-.068.035-.107.078ZM18 17.545c0 .498.017.88.039.88.081 0 .42-.429.468-.592.129-.421.013-.851-.284-1.057a.964.964 0 0 0-.193-.112c-.017 0-.03.395-.03.88ZM5.073 15.577a.759.759 0 0 0-.236.202c-.086.124-.095.18-.095.799s.009.675.095.8c.206.287-.035.274 4.395.274s4.19.013 4.396-.275c.086-.124.095-.18.095-.799s-.009-.675-.095-.8c-.206-.291.034-.274-4.404-.274-3.605 0-4.022.008-4.15.073ZM3.354 19.014a.759.759 0 0 0-.236.202c-.086.125-.095.18-.095.8 0 .618.009.674.095.799.206.288-.034.275 4.396.275s4.19.013 4.395-.275c.086-.125.095-.18.095-.8 0-.618-.009-.674-.095-.799-.206-.292.035-.275-4.404-.275-3.605 0-4.022.009-4.15.073ZM5.073 22.452a.759.759 0 0 0-.236.202c-.086.124-.095.18-.095.799s.009.675.095.8c.206.287-.035.274 4.395.274s4.19.013 4.396-.275c.086-.124.095-.18.095-.799s-.009-.675-.095-.8c-.206-.291.034-.274-4.404-.274-3.605 0-4.022.008-4.15.073Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M3 3h22v22H3z" />
      </clipPath>
    </defs>
  </svg>
);
export default DollarWithCoinSvgBlue;

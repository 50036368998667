import {
  getMoveOutPigHouseDetail,
  getMoveOutPigSellingHistory,
} from "../../services/MoveOutPig.service";

export async function moveOutPigSellingHistoryLoader({
  params,
}: {
  params: { houseId: string };
}) {
  try {
    const { houseId } = params;
    if (!houseId) {
      throw new Response("Not Found", { status: 404 });
    }

    const [histories, house] = await Promise.all([
      getMoveOutPigSellingHistory(houseId),
      getMoveOutPigHouseDetail(houseId),
    ]);

    if (house.success && histories.success) {
      return {
        house,
        histories,
      };
    } else {
      throw new Response("Not Found", { status: 404 });
    }
  } catch (err) {
    throw new Response("Not Found", { status: 404 });
  }
}

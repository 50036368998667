import { Divider, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuBar from "../../../components/MenuBar/MenuBar";
import { TabPanelCustom } from "../../../components/TabPanelCustom/TabPanelCustom";

import SuccessInvoicePanel from "./SuccessInvoicePanel";
import ProcessedInvoicePanel from "./ProcessedInvoicePanel";
import {
  getCountInvoiceTab1Manager,
  getCountInvoiceTab2Manager,
  getCountInvoiceTab3Manager,
} from "../../../../services/admin/invoice/invoiceManager.service";
import { TTabManager } from "../type";
import PastDueInvoicePanel from "./PastDueInvoicePanel";

function ManagerInvoice() {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [isHandleFetch, setIsHandleFetch] = useState<boolean>(false);
  const [countInvoice, setCountInvoice] = useState<TTabManager>({
    tabUpcomingAssignment: 0,
    tabProcessedAssignment: 0,
    tabSucceedInvoice: 0,
  });
  const onChangeTabIndex = (
    event: React.SyntheticEvent<Element, Event>,
    value: number
  ) => {
    setTabIndex(value);
  };

  const handleFetchCountInvoice = async () => {
    const countTabUpcomingAssignment = await getCountInvoiceTab1Manager();
    const countTabProcessedAssignment = await getCountInvoiceTab2Manager();
    const countTabSucceedInvoice = await getCountInvoiceTab3Manager();
    setCountInvoice({
      tabUpcomingAssignment: countTabUpcomingAssignment?.count ?? 0,
      tabProcessedAssignment: countTabProcessedAssignment?.count ?? 0,
      tabSucceedInvoice: countTabSucceedInvoice?.count ?? 0,
    });
  };

  useEffect(() => {
    handleFetchCountInvoice();
  }, [isHandleFetch]);
  return (
    <MenuBar>
      <div className="pt-16 w-full h-full relative">
        <div className="p-10">
          <span className="text-[#000] font-bold text-3xl">Invoice List</span>
          <Divider sx={{ paddingTop: "12px" }} />
          <div className="pt-6">
            <Tabs
              value={tabIndex}
              onChange={onChangeTabIndex}
              aria-label="sale-order-tab-control"
              TabIndicatorProps={{
                sx: {
                  "&.MuiTabs-indicator": {
                    transform: "unset",
                    width: "unset",
                    bgcolor: "#68C184",
                    height: "3px",
                  },
                },
              }}
              sx={{
                height: "36px",
                minHeight: "unset",
                "& .MuiTabs-scroller": {
                  "& .MuiTabs-flexContainer": {
                    alignItems: "end",
                    height: "100%",
                    display: "flex",
                    gap: "12px",
                    "& .MuiTab-root": {
                      padding: "6px 28px",
                      textTransform: "unset",
                      fontFamily: "IBM Plex Sans Thai",
                      fontWeight: 400,
                      fontSize: "1rem",
                      lineHeight: "20px",
                      height: "32px",
                      minHeight: "unset",
                      color: "#000000",
                      borderBottom: "3px solid #DDF3E4",
                      "& .Mui-selected": {
                        color: "#000000",
                      },
                    },
                  },
                },
              }}
            >
              <Tab
                label={`Invoice ที่เลยกำหนดชำระ (${countInvoice.tabUpcomingAssignment})`}
              />
              <Tab
                label={`Invoice ดำเนินการเเล้ว (${countInvoice.tabProcessedAssignment})`}
              />
              <Tab
                label={`Invoice ที่สำเร็จ (${countInvoice.tabSucceedInvoice})`}
              />
            </Tabs>
          </div>
          <TabPanelCustom
            value={tabIndex}
            index={0}
            prefix="update-document-invoice"
          >
            <PastDueInvoicePanel
              handleFetchCount={() => {
                setIsHandleFetch((prev) => !prev);
              }}
            />
          </TabPanelCustom>
          <TabPanelCustom value={tabIndex} index={1} prefix="past-due-invoice">
            <ProcessedInvoicePanel
              handleFetchCount={() => {
                setIsHandleFetch((prev) => !prev);
              }}
            />
          </TabPanelCustom>
          <TabPanelCustom value={tabIndex} index={2} prefix="success-invoice">
            <SuccessInvoicePanel
              handleFetchCount={() => {
                setIsHandleFetch((prev) => !prev);
              }}
            />
          </TabPanelCustom>
        </div>
      </div>
    </MenuBar>
  );
}

export default ManagerInvoice;

import { useQuery } from "@tanstack/react-query";
import { getListInvoice } from "../../../../services/Invoice.service";
import { useCallback, useEffect, useState } from "react";
import {
  defaultFormUnpaidBill,
  TFormUnpaidBill,
} from "../unpaid-bill/defaultFormUnpaidBill";
import dayjs from "dayjs";

export const useVerifyBillController = () => {
  const [orderByType, setOrderByType] = useState<"desc" | "asc">("desc");

  const [formVerifyBill, setFormVerifyBill] = useState(defaultFormUnpaidBill);

  const onChangeFormVerifyBill = useCallback(
    (key: keyof TFormUnpaidBill) => (value: any) => {
      setFormVerifyBill((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const [currentPage, setCurrentPage] = useState(1);

  const { data: listInvoice } = useQuery({
    queryKey: [
      "list_invoice_verifying",
      orderByType,
      formVerifyBill.direction,
      formVerifyBill.startDate,
      formVerifyBill.endDate,
      currentPage,
    ],
    queryFn: async () => {
      const result = await getListInvoice({
        page: currentPage,
        direction: formVerifyBill.direction[0]?.value || orderByType,
        tabName: "verifying",
        startDate: formVerifyBill.startDate
          ? dayjs(formVerifyBill.startDate, "YYYY-MM-DD")
              .startOf("day")
              .toDate()
          : undefined,
        endDate: formVerifyBill.endDate
          ? dayjs(formVerifyBill.endDate, "YYYY-MM-DD").startOf("day").toDate()
          : undefined,
      });
      return result;
    },
  });

  return {
    listInvoice,
    orderByType,
    setOrderByType,
    formVerifyBill,
    setFormVerifyBill,
    onChangeFormVerifyBill,
    currentPage,
    setCurrentPage,
  };
};

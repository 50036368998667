import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getNotificationFarmer,
  getNotificationPartner,
  markReadNotification,
  markReadNotificationAll,
  markReadNotificationAllPartner,
  markReadNotificationPartner,
  removeNotification,
  removeNotificationPartner,
} from "../../../services/Notification.service";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { loadingActions } from "../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../utils/getErrorMessage";
import { useCallback, useEffect, useState } from "react";
import { alertAction } from "../../../redux/slices/alertSlice";
import { useNavigate } from "react-router-dom";
import { useAdminAuth } from "../../../admin/components/AdminAuth/AdminAuthProvider";
import getNormalRole from "../../../utils/getNormalRole";
import { INotification } from "../../../types/Notification.type";

export const useNotificationController = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const role = getNormalRole();
  const [dataNotification, setDataNotification] = useState<INotification[]>([]);

  const { data: listNotificationsFarmer } = useQuery({
    queryKey: ["get_notifications_farmer"],
    enabled: role === "farmer",
    queryFn: async () => {
      const result = await getNotificationFarmer();
      return result;
    },
  });

  const { data: listNotificationsPartner } = useQuery({
    queryKey: ["get_notifications_partner"],
    enabled: role === "partner",
    queryFn: async () => {
      const result = await getNotificationPartner();
      return result;
    },
  });

  const readAllMutation = useMutation({
    mutationFn: markReadNotificationAll,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["get_notifications_farmer"],
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const readAllMutationPartner = useMutation({
    mutationFn: markReadNotificationAllPartner,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["get_notifications_partner"],
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const readMutation = useMutation({
    mutationFn: markReadNotification,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["get_notifications_farmer"],
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const readMutationPartner = useMutation({
    mutationFn: markReadNotificationPartner,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["get_notifications_partner"],
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const removeMutation = useMutation({
    mutationFn: removeNotification,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["get_notifications_farmer"],
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const removeMutationPartner = useMutation({
    mutationFn: removeNotificationPartner,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["get_notifications_partner"],
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const onReadAll = useCallback(() => {
    role === "partner"
      ? readAllMutationPartner.mutate()
      : readAllMutation.mutate();
  }, []);

  const onRead = useCallback((notificationId: string, refId: string) => {
    role === "partner"
      ? readMutationPartner.mutate({
          notificationId: notificationId,
        })
      : readMutation.mutate({
          notificationId: notificationId,
        });
    role === "farmer" &&
      navigate(
        `/financial-support/financial-bill-payment/unpaid-bill?id=${refId}`
      );
  }, []);

  const onRemove = useCallback((notificationId: string) => {
    role === "partner"
      ? removeMutationPartner.mutate({
          notificationId: notificationId,
        })
      : removeMutation.mutate({
          notificationId: notificationId,
        });
  }, []);

  useEffect(() => {
    role === "partner"
      ? setDataNotification(listNotificationsPartner?.notifications || [])
      : setDataNotification(listNotificationsFarmer?.notifications || []);
  }, [listNotificationsFarmer, listNotificationsPartner]);

  return {
    dataNotification,
    onReadAll,
    onRead,
    onRemove,
  };
};

import axios from "axios";

const BASE_URL = `${
  process.env.REACT_APP_BASE_API_URL || window.location.origin
}/api/`;

type TBaseResponse = { success: boolean; message: string };
export type TPartnerRequestResetPINRes = TBaseResponse & {
  mobileNumber: string;
  token: string;
};
export type TPartnerVerifyPIN = TBaseResponse & { token: string };
export type TPartnerResetPIN = TBaseResponse;

export const partnerRequestResetPINService = async (username: string) => {
  return axios.get<TPartnerRequestResetPINRes>(
    "/partner/auth/reset-pin?username=" + username,
    {
      baseURL: BASE_URL,
    }
  );
};

export const partnerVerifyPINService = async (
  token: string,
  mobileNumber: string,
  otp: string
) => {
  return axios.post<TPartnerVerifyPIN>(
    "/mobile-verification",
    { mobileNumber, otp },
    {
      baseURL: BASE_URL,
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const partnerResetPINService = async (
  token: string,
  mobileNumber: string,
  otpCode: string,
  pin: string
) => {
  return axios.post<TPartnerResetPIN>(
    "/partner/auth/reset-pin",
    { mobileNumber, otpCode, pin },
    {
      baseURL: BASE_URL,
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

const IconWaterWithBgSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={41} height={42} fill="none">
    <rect width={41} height={41} y={0.129} fill="#5572EB" rx={20.5} />
    <path
      fill="#fff"
      d="M20.096 8.654c-.264.158-.701.785-2.167 3.09-3.006 4.73-4.926 8.58-5.59 11.232-.137.533-.148.654-.143 1.556.006 1.07.08 1.56.39 2.494.992 2.974 3.597 5.152 6.735 5.622.49.074 1.861.074 2.352 0 3.522-.528 6.233-3.09 7.008-6.624.09-.422.11-.701.116-1.518.005-.65-.021-1.113-.063-1.319-.443-2.009-1.34-3.776-3.966-7.805-1.334-2.04-2.294-3.738-3.116-5.51-.438-.944-.549-1.113-.797-1.245-.2-.105-.564-.09-.76.027Zm-3.67 15.192c.221.137.369.385.406.686.084.76.147.997.353 1.419.49.99 1.577 1.692 2.616 1.692.321 0 .685.18.843.422.106.153.122.237.106.48-.037.548-.338.786-.986.786-1.783 0-3.576-1.26-4.272-3.006-.337-.854-.422-1.904-.18-2.247.238-.332.77-.443 1.114-.232Z"
    />
  </svg>
);
export default IconWaterWithBgSvg;

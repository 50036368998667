import React, { memo, useCallback, useEffect, useState } from 'react'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import { useDispatch } from 'react-redux'
import { get } from 'lodash'
import { tw } from '../../../../../../utils/tw'
import CrossIcon from '../../../../../assets/svg/CrossIcon.svg'
import { Dropdown, TDropdownValue } from '../../Dropdown'
import { addFarmerToGroup, getAllGroupArea } from '../../../../../../services/GroupArea.service'
import { IGroupAreaTableDetail } from '../../../../../../types/GroupArea.type'
import ButtonFillCustom from '../../../../../components/Button/ButtonFillCustom'
import { AppDispatch } from '../../../../../../redux/store'
import { alertAction } from '../../../../../../redux/slices/alertSlice'
import { loadingActions } from '../../../../../../redux/slices/loadingSlice'
import { snakeActions } from '../../../../../../redux/slices/snakeSlice'
import getErrorMessage from '../../../../../../utils/getErrorMessage'

interface IMoveToModalProps {
  isOpen: boolean
  onClose: () => void
  editDetail?: IGroupAreaTableDetail
  farmerSelected: string[]
  refetch: () => void
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
}

const classes = {
  container: tw(`max-w-[352px] w-[100vw] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary [&_.MuiDialog-paperScrollPaper]:!m-0`),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  submitContainer: tw(`flex w-full justify-end items-end`),
}

const MoveToModal = (props: IMoveToModalProps) => {
  const {
    isOpen,
    onClose,
    editDetail,
    farmerSelected,
    refetch,
  } = props

  const dispatch = useDispatch<AppDispatch>()
  const [group, setGroup] = useState<TDropdownValue[]>([])
  const [groupOptions, setGroupOptions] = useState<{ label: string, value: string }[]>([])

  const onCloseFn = useCallback(() => {
    onClose()
    setGroup([])
  }, [])

  const onSubmitMove = useCallback(() => {
    dispatch(alertAction.showDialog({
      title: 'ยืนยันการบันทึก',
      text: 'คุณต้องการบันทึกรายการนี้ใช่หรือไม่?',
      cancelText: 'กลับไปแก้ไข',
      confirmText: 'ยืนยัน',
      onCancel: () => {
        dispatch(alertAction.hide())
      },
      onSubmit: () => {
        dispatch(loadingActions.show({ message: 'กำลังบันทึกรายการ...' }))
        addFarmerToGroup({
          farmerIds: farmerSelected,
          groupId: get(group, [0, 'value']),
        }).then(() => {
          refetch()
          dispatch(alertAction.hide())
          setTimeout(() => {
            dispatch(loadingActions.hide())
            dispatch(snakeActions.showMessage({
              message: 'บันทึกสำเร็จ',
              type: 'success',
            }))
            onCloseFn()
          }, 1000)
        }).catch((error) => {
          console.log('fail to creategroup error:', error)
          dispatch(alertAction.hide())
          setTimeout(() => {
            dispatch(loadingActions.hide())
            dispatch(snakeActions.showMessage({
              message: getErrorMessage(error),
              type: 'error',
            }))
          }, 1000)
        })
      },
    }))
  }, [group, farmerSelected])

  useEffect(() => {
    if (isOpen) {
      getAllGroupArea().then((result) => {
        const options = result.userGroups.map(({ id, name }) => ({
          value: id,
          label: name,
        })).filter(({ value }) => value !== editDetail?.userGroup.id)
        setGroupOptions(options)
      }).catch((e) => {
        console.log('fail to getAllGroupArea error:', e)
      })
    }
  }, [isOpen, editDetail])

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: 'w-full' }}
    >
      <DialogContent>
        <div className={classes.titleContainer}>
          <p className={classes.title}>ย้ายไป</p>
          <IconButton onClick={onCloseFn}>
            <CrossIcon />
          </IconButton>
        </div>
        <Dropdown
          label="ชื่อกลุ่ม"
          required
          options={groupOptions}
          renderValueArray={group}
          onChange={setGroup}
        />
        <div className={classes.submitContainer}>
          <ButtonFillCustom
            title="ยืนยัน"
            disabled={group.length === 0}
            onClick={onSubmitMove}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default memo(MoveToModal)

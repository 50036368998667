import React from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../components/common/Button";
import farmRegistration from "../../assets/images/FarmRegistration/farm-success.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

const RegisterFarmSuccess = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { state } = useLocation();

  return (
    <div className="p-5 h-screen flex flex-col justify-between w-full">
      <div className="flex flex-col grow mt-4 ">
        <img
          src={farmRegistration}
          className="mb-[20px] w-[100%] max-w-[400px]"
        />
        <div className="flex flex-col items-center">
          <h3 className="font-medium text-[30px] text-justify text-[#68C184] mb-2">
            {t("registerSuccess.pageMainHeading")}
          </h3>
          <h4 className="px-2 text-center text-[20] text-[#646464] font-normal">
            {t("registerSuccess.pageSubHeading1")}
          </h4>
          <h4 className="px-2 text-center text-[20] text-[#646464] font-normal mb-6">
            {t("registerSuccess.pageSubHeading2")}
          </h4>
          <div className="p-4 flex flex-col w-full">
            <h4 className="text-left text-[18] text-[#68C184] font-semibold ">
              {t("registerSuccess.description_title")}
            </h4>
            <ul className="ml-3 flex flex-col items-start leading-[2] pl-4 list-disc">
              <li className="marker:text-[#646464]">
                {" "}
                <p className="text-left text-[18] text-[#646464] font-normal leading-normal">
                  {t("registerSuccess.description_point_one")}
                </p>
              </li>
              <li className="marker:text-[#646464]">
                {" "}
                <p className="text-left text-[18] text-[#646464] font-normal leading-normal">
                  {t("registerSuccess.description_point_two")}
                </p>
              </li>
              <li className="marker:text-[#646464]">
                {" "}
                <p className="text-left text-[18] text-[#646464] font-normal leading-normal">
                  {t("registerSuccess.description_point_three")}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <CustomButton
        variant="tertiary"
        label={t("registerSuccess.addHouseButtonText")}
        onClick={() => navigate("/add-house", { ...state })} // use your onclick here
      />
      {/* TODO: change redirection part below*/}
      <div className="m-2">
        <p className="text-[14px] text-[#646464] text-center">
          <Link to="/farms" className="font-[500]">
            {t("registerSuccess.addLaterButtonText")}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default RegisterFarmSuccess;

import { useLocation, useNavigate } from "react-router-dom";
import SuccessCard from "../../components/common/SuccessCard";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function SingupSuccess() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (!state && !state?.mobileNumber) {
      navigate("/welcome");
    }
  }, []);

  return (
    <SuccessCard
      title={t("signup.thankyou")!}
      buttonLabel="Homepage"
      onButtonPress={() => navigate("/welcome")}
      subtitle1={t("signup.welcome_thank")!}
      subtitle2={t("signup.Please_enjoy_farmsook")!}
    />
  );
}

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import { Avatar, Paper } from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import { useState } from "react";
import { getDownloadInvoiceLog } from "../../../../services/admin/invoice/invoiceSale.service";
import { InvoiceLogRow } from "../../../../types/invoice/invoiceSale.type";
import { thousandComma } from "../../../../utils/common/string";
import { handleDownloadBlob } from "../../../../utils/downloadFile";
import { tw } from "../../../../utils/tw";
import ImagePreviewModal from "./LogModal/ImagePreviewModal";
import ReverseLogModal from "./LogModal/ReverseLogModal";
import TrackingInvoice from "./LogModal/TrackingInvoice";
import OverdueModal from "./LogModal/TrackingOverdueModal";
import UpdateInvoiceLogModal from "./LogModal/UpdateInvoiceLogModal";

type Props = {
  invoiceLog: InvoiceLogRow;
  index: number;
  countIndex: number;
};

const classes = {
  header: tw("font-bold text-[#3777BC]"),
  label: tw(
    `text-secondary leading-[14px] tracking-[-5%] font-medium underline hover:brightness-110 cursor-pointer ml-2`
  ),
};

export const handleDownloadReceiveInvoice = async (
  documentType: string,
  fileId: string,
  fileName: string
) => {
  const preSignURL = await getDownloadInvoiceLog(documentType, fileId);
  if (preSignURL) {
    const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
      responseType: "blob",
    });
    const objectURL = window.URL.createObjectURL(response.data);
    handleDownloadBlob(objectURL, fileName);
  }
};

const TimelineItemComponents = ({ invoiceLog, index, countIndex }: Props) => {
  const [isOpenReverseModal, setIsOpenReverseModal] = useState<boolean>(false);
  const [isOverdueModal, setIsOverdueModal] = useState<boolean>(false);
  const [isTrackingModal, setIsTrackingModal] = useState<boolean>(false);
  const [isUpdateInvoiceModal, setIsUpdateInvoiceModal] =
    useState<boolean>(false);
  const [openImagePreviewModal, setOpenImagePreviewModal] = useState(false);

  const MapEnumLogType = (invoiceLog: InvoiceLogRow) => {
    switch (invoiceLog?.logType) {
      case "update_document":
        return (
          <>
            <span className={classes.header}>อัปเดตเอกสาร</span>
            {` เลข Invoice
            ${invoiceLog.logDetail?.invoiceNumber} `}
          </>
        );
      case "sale_reverse_status":
        return (
          <>
            <span className={classes.header}>ย้อนกลับ Invoice </span>
            {` ${invoiceLog.logDetail?.invoiceNumber} `}
            <button
              className={classes.label}
              onClick={() => setIsOpenReverseModal(true)}
            >
              ดูรายละเอียด
            </button>
          </>
        );
      case "lending_reverse_status":
        return (
          <>
            <span className={classes.header}>ย้อนกลับ Invoice </span>
            <button
              className={classes.label}
              onClick={() => setIsOpenReverseModal(true)}
            >
              ดูรายละเอียด
            </button>
          </>
        );
      case "follow_invoice":
        return invoiceLog.logDetail.overdueStatus === "followed_sixty_days" ? (
          <>
            <span className={classes.header}>ติดตาม</span>
            {` Invoice ที่เลยกำหนดชำระเกิน 60 วัน `}
            <button
              className={classes.label}
              onClick={() => setIsOverdueModal(true)}
            >
              ดูรายละเอียด
            </button>
          </>
        ) : (
          <>
            <span className={classes.header}>ติดตาม</span>
            {` Invoice ที่เลยกำหนดชำระ `}
            <button
              className={classes.label}
              onClick={() => setIsTrackingModal(true)}
            >
              ดูรายละเอียด
            </button>
          </>
        );

      case "create_assignment":
        return (
          <>
            <span className={classes.header}>สร้างใบงาน</span>
            {` เลขที่ใบงาน ${invoiceLog.logDetail?.assignmentNumber}`}
          </>
        );
      case "manager_verify_assignment":
        return (
          <>
            <span className={classes.header}>
              {invoiceLog.logDetail?.isApprove ? "อนุมัติ" : "ไม่อนุมัติ"}
            </span>
            {` เลขที่ใบงาน ${invoiceLog.logDetail?.assignmentNumber}`}
          </>
        );
      case "lending_verify_payment":
        return (
          <>
            <span className={classes.header}>อัปเดตการชำระเงิน</span>
            {` ${
              invoiceLog.logDetail.isCorrectPrice ? "ตรงยอด" : "ไม่ตรงยอด"
            }  ยอดเงิน ${thousandComma(
              invoiceLog.logDetail?.verifyAmount || 0,
              2
            )} บาท `}
            <button
              className={classes.label}
              onClick={() => {
                setOpenImagePreviewModal(true);
              }}
            >
              ดูใบเสร็จรับเงิน
            </button>{" "}
            <button
              className={classes.label}
              onClick={() =>
                handleDownloadReceiveInvoice(
                  invoiceLog.invoiceLogFiles[0].documentType,
                  invoiceLog.invoiceLogFiles[0].id,
                  invoiceLog.invoiceLogFiles[0].fileOriginalName
                )
              }
            >
              ดาวน์โหลดใบเสร็จรับเงิน
            </button>
          </>
        );
      case "partner_update_payment":
        return (
          <>
            <span className={classes.header}>อัปเดตรายการ</span>
            {` การนัดชำระเงิน `}
            <button
              className={classes.label}
              onClick={() => setIsUpdateInvoiceModal(true)}
            >
              ดูรายละเอียด
            </button>
          </>
        );
      default:
        return "-";
    }
  };

  return (
    <div className="py-2">
      <Timeline
        sx={{
          padding: "2px 12px",
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        <TimelineItem key={`item-label-${index + 1}`}>
          <TimelineSeparator>
            <TimelineDot
              sx={{
                bgcolor: "#3777BC",
                boxShadow: "unset",
              }}
            />
            {countIndex - 1 > index && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <div className="flex">
              <div className="w-[100px]">
                <span className="text-[14px] font-normal text-[#1B2128] font-sans ">
                  {dayjs(invoiceLog.createdAt).format("hh:mm A")}
                </span>
              </div>
              <div>
                <Paper
                  elevation={0}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    bgcolor: "#FBFBFB",
                    border: "1px solid #EBEBEB",
                    width: "1000px",
                    height: "100%",
                    borderRadius: "20px",
                    padding: "12px",
                  }}
                >
                  <Avatar
                    alt="user-avatar"
                    src="https://i.pravatar.cc/300"
                    sx={{
                      width: "25px",
                      height: "25px",
                      border: "1px solid #D9D9D9",
                    }}
                  />
                  <span className="font-bold font-sans text-[1rem] text-[#1B2128]">
                    {invoiceLog.user?.name}
                  </span>
                  <span className="font-bold font-sans text-[1rem] text-[#1B2128]">
                    {invoiceLog.user?.surname}
                  </span>
                  <span>{MapEnumLogType(invoiceLog)}</span>
                </Paper>
              </div>
            </div>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
      <UpdateInvoiceLogModal
        isOpen={isUpdateInvoiceModal}
        handleClose={() => setIsUpdateInvoiceModal(false)}
        logDetail={invoiceLog?.logDetail}
      />
      <OverdueModal
        isOpen={isOverdueModal}
        handleClose={() => setIsOverdueModal(false)}
        logDetail={invoiceLog?.logDetail}
      />
      <ReverseLogModal
        isOpen={isOpenReverseModal}
        handleClose={() => setIsOpenReverseModal(false)}
        logDetail={invoiceLog?.logDetail}
      />
      <TrackingInvoice
        isOpen={isTrackingModal}
        handleClose={() => setIsTrackingModal(false)}
        logDetail={invoiceLog?.logDetail}
      />
      <ImagePreviewModal
        key={invoiceLog.invoiceLogFiles[0]?.id || ""}
        isOpen={openImagePreviewModal}
        handleClose={() => setOpenImagePreviewModal(false)}
        invoiceFile={invoiceLog.invoiceLogFiles[0] || ""}
      />
    </div>
  );
};

export default TimelineItemComponents;

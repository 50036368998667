import React, { useEffect, useState } from "react";
import HouseSvg from "../../../../assets/svg/HouseSvg";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import { remove } from "lodash";
import {
  TableCellHeaderCustom,
  TableCellCustom,
} from "../../../master-data/pages/credit/Modal/TableCellCustom";
import { IGetFarmInformation } from "../../types/farmInformation.type";
import { getFarmInformation } from "../../services/farmDetail.service";
import { PigType } from "../../enums/pig.enum";

type Props = {
  farmId?: string;
};

const tableCellHeader = [
  {
    value: "ชื่อฟาร์ม",
    width: "300px",
  },
  {
    value: "ภาค",
    width: "300px",
  },
  {
    value: "จังหวัด",
    width: "300px",
  },
  {
    value: "เขต",
    width: "300px",
  },
  {
    value: "จำนวนโรงเรือน",
    width: "200px",
  },
  {
    value: "ประเภทหมู",
    width: "400px",
  },
];

const FarmInformationTab = ({ farmId }: Props) => {
  const [farm, setFarm] = useState<IGetFarmInformation | null>();

  const handleFetchFarm = async () => {
    const data = await getFarmInformation(farmId || "");
    setFarm(data);
  };
  useEffect(() => {
    handleFetchFarm();
  }, []);
  return (
    <>
      <div className="flex gap-2 text-center items-center mt-6">
        <HouseSvg />
        <p className="text-lg font-bold text-[#3777BC] font-sans">
          ข้อมูลฟาร์ม
        </p>
      </div>
      <div>
        <TableContainer
          component={Paper}
          sx={{
            scrollBehavior: "smooth",
            overflow: "auto",
            borderRadius: "6px",
            boxShadow: "none",
            border: "1px solid #E1E1E1",
            borderBottom: "none",
            width: "100%",
            marginTop: "32px",
          }}
        >
          <Table aria-labelledby="table-show-data" size={"medium"}>
            <TableHead
              sx={{
                bgcolor: "#FBFBFB",
                borderBottom: "1px solid #E1E1E1",
              }}
            >
              <TableRow>
                {tableCellHeader.map((cell, index) => (
                  <TableCellHeaderCustom
                    key={index}
                    value={cell.value}
                    width={cell.width}
                  />
                ))}
              </TableRow>
            </TableHead>
            {farm?.rows.map((farm, index) => (
              <TableRow>
                <TableCell
                  sx={{
                    padding: "0px 16px",
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  {farm.name}
                </TableCell>
                <TableCell
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  {farm.region.nameTh}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  {farm.province.nameTh}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  {farm.district.nameTh}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  {farm.houseAmount}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  {farm.pigTypes
                    .map((pig) => PigType[pig as keyof typeof PigType])
                    .join(" , ")}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default FarmInformationTab;

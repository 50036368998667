import { TDropdownValue } from "./Dropdown"

export type TForm = {
  name: string
  area: TDropdownValue[]
  province: TDropdownValue[]
  district: TDropdownValue[]
  employee: TDropdownValue[]
  partner: TDropdownValue[]
}

export const defaultForm: TForm = {
  name: '',
  area: [],
  province: [],
  district: [],
  employee: [],
  partner: [],
}

import { Paper } from "@mui/material";
import { IDashboardInfoCardProps } from "./types";

export default function DashboardInfoCard({
  icon,
  title,
  subTitle,
  infoComponent,
  onPress,
  removeBgIcon,
  bgColor,
}: IDashboardInfoCardProps) {
  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: "20px",
        bgcolor: `${bgColor ?? "background.default"}`,
      }}
      onClick={onPress}
    >
      <div className="p-4 flex flex-col gap-4">
        <div className="flex gap-4">
          {removeBgIcon ? (
            icon
          ) : (
            <div className="bg-gradient-to-b rounded-[20px] from-[#3777BC] to-[#68C184] p-4">
              {icon}
            </div>
          )}
          <div className="flex flex-col justify-center">
            <h2 className="text-[20px] text-primary font-medium">{title}</h2>
            {subTitle && <p className="text-sm text-tertiary">{subTitle}</p>}
            {infoComponent}
          </div>
        </div>
      </div>
    </Paper>
  );
}

import { Button } from "@mui/material";
import React, { useState } from "react";

interface ButtonControlGroupProps {
  options: any[];
  name: string;
  id: string;
  value?: string;
  className?: string;
  onChange?: (val: string) => void;
}

const ButtonGroupControl = (props: ButtonControlGroupProps) => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (newValue: any) => {
    setSelectedValue(newValue);
    props?.onChange?.(newValue);
  };

  function getButtonStyling(isSelected: boolean) {
    if (isSelected) {
      return {
        variant: "outlined",
      };
    }
  }

  return (
    <div className={props?.className}>
      {props?.options?.map((item: any, index: number) => {
        return (
          <Button
            disableRipple
            disableFocusRipple
            onClick={() => handleChange(item?.value)}
            key={`button-control-${props?.id}-${index}`}
            color="tertiary"
            variant={item?.value == props?.value ? "contained" : "outlined"}
          >
            {item?.label}
          </Button>
        );
      })}
    </div>
  );
};

export default ButtonGroupControl;

import { useNavigate } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import AppBarFS from "../../components/app-bar/AppBar";
import DashboardInfoCard from "../../components/common/DashboardInfoCard/DashboardInfoCard";
import List from "../../assets/svg/List";
import OrderHistoryBgPurpleSVG from "../../assets/svg/OrderHistoryBgPurple";

const PartnerContract = () => {
  let navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate("/partner");
  }, []);

  const handleClickToViewDocumentContract = useCallback(() => {
    navigate("/partner/partner-contract/view-document");
  }, []);

  const handleClickToHistory = useCallback(() => {
    navigate("/partner/partner-contract/history");
  }, []);

  return (
    <div>
      <div className="w-full h-full">
        <div className="h-[120px] flex items-center">
          <AppBarFS title={"รายละเอียด"} onIconPress={handleBack} />
        </div>
        <div className="flex flex-col w-full h-full gap-[16px] px-[16px]">
          <DashboardInfoCard
            title={"เอกสารสัญญา"}
            subTitle={"เอกสารสัญญา"}
            icon={<List />}
            removeBgIcon={true}
            bgColor="#F0FDF4"
            onPress={handleClickToViewDocumentContract}
          />

          <DashboardInfoCard
            title={"ประวัติเปอร์เซ็นต์รายได้"}
            subTitle={"ประวัติเปอร์เซ็นต์รายได้"}
            icon={<OrderHistoryBgPurpleSVG />}
            removeBgIcon={true}
            bgColor="#EEF2FF"
            onPress={handleClickToHistory}
          />
        </div>
      </div>
    </div>
  );
};

export default PartnerContract;

import { District, Province } from "../../services/Geography.type";
import { getLocations } from "../../services/GeographyService";
import { sortByProperty } from "../../utils/sort";
import { getI18n } from "react-i18next";

export async function farmLoader() {
  const language = localStorage.getItem("i18nextLng");
  let sortingInfo = {
    propertyId: "nameEn",
    locale: "en-US",
  };

  if (language === "th") {
    sortingInfo = {
      propertyId: "nameTh",
      locale: "th-TH",
    };
  }

  try {
    const resp = await getLocations();
    if (resp.data.success) {
      const sortedData = sortByProperty(
        resp.data.provinces.map((province: Province) => {
          return {
            ...province,
            districts: sortByProperty(
              province.districts.map((district: District) => {
                return {
                  ...district,
                  subDistricts: sortByProperty(
                    district?.subDistricts,
                    sortingInfo.propertyId,
                    sortingInfo.locale
                  ),
                };
              }),
              sortingInfo.propertyId,
              sortingInfo.locale
            ),
          };
        }),
        sortingInfo.propertyId,
        sortingInfo.locale
      );
      return { provinces: sortedData };
    }
    return { provinces: [] };
  } catch (err) {
    console.error(err);
    return { provinces: [] };
  }
}

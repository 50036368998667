import { InputAdornment } from "@mui/material";
import { Path, useFormContext } from "react-hook-form";
import { ICreditForm } from "../../../../types/admin/masterdata/creditForm";
import { tw } from "../../../../utils/tw";
import RHFTextInputCustom from "../../../components/Input/RHFTextInputCustom";
import { classes } from "../pages/credit/Modal/CreditModal";
import { memo, useEffect, useMemo } from "react";
import { use } from "i18next";

export type AllowedFieldName = Extract<
  Path<ICreditForm>,
  | "generalPersonalInformation"
  | "generalLegalInformation"
  | "generalFarmInformation"
  | "farmPerformance"
  | "farmFinancialStatusAndInstallment"
  | "applicationUsage"
>;

type Props = {
  name: AllowedFieldName;
  viewAction: boolean;
  value?: string | number;
};

const SegmentWeightInput = ({ name, viewAction, value }: Props) => {
  const {
    formState: { errors },
  } = useFormContext<ICreditForm>();

  return (
    <div className="mt-6">
      <p className={classes.label}>
        Segment Weight <span className={classes.redColor}>*</span>
      </p>
      <div className={tw(classes.input, "mb-4")}>
        <RHFTextInputCustom
          value={value}
          type="number"
          controlName={`${name}.segmentWeight`}
          placeholder="กรอก"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          disabled={viewAction}
          error={Boolean(errors[name]?.segmentWeight)}
          helperText={errors[name]?.segmentWeight?.message as string}
        />
      </div>
    </div>
  );
};

export default SegmentWeightInput;

import { LoaderFunctionArgs } from "react-router-dom";
import {
  getOrderHistory,
  getOrderHistoryDetailById,
} from "../../../services/admin/sale-order/OrderHistory.service";

export async function orderHistoryLoader(loader: LoaderFunctionArgs) {
  const { request, params } = loader;
  const farmId = params["farmId"] ?? "";
  const orderId = params["orderId"] ?? "";
  const orderHistoryList = await getOrderHistoryDetailById(farmId, orderId);
  const orderHistory = await getOrderHistory(farmId);
  return {
    farmId,
    orderId,
    orderHistory,
    orderHistoryList,
  };
}

export function ManageStockSvg() {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.16667 4.21224H14.8333M3.16667 4.21224C2.24619 4.21224 1.5 3.46605 1.5 2.54557C1.5 1.6251 2.24619 0.878906 3.16667 0.878906H14.8333C15.7538 0.878906 16.5 1.6251 16.5 2.54557C16.5 3.46605 15.7538 4.21224 14.8333 4.21224M3.16667 4.21224L3.16667 12.5456C3.16667 13.466 3.91286 14.2122 4.83333 14.2122H13.1667C14.0871 14.2122 14.8333 13.466 14.8333 12.5456V4.21224M7.33333 7.54557H10.6667"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

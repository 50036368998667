import { memo, useCallback, useEffect, useState } from "react";
import { Modal } from "@mui/material";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import axios from "axios";
import { get } from "lodash";
import { tw } from "../../../utils/tw";
import { IContracts } from "../../../types/Financial.type";
import { getDownloadContractDocument } from "../../../services/partner/PartnerSectionContract.service";
import { handleDownloadBlob } from "../../../utils/downloadFile";
import AppBarFS from "../../../components/app-bar/AppBar";
import ZoomImageSvg from "../../../assets/svg/ZoomImage";
import PdfRenderer from "../../../admin/components/Modal/DocumentModal/ModalPanel/PdfRenderer";
import PreviewAndZoomImageCarouselModal from "../../../components/common/ViewImageAndPreviewCarousel/PreviewAndZoomImageCarouselModal";
import { usePartnerViewContractController } from "./controller";
import { useNavigate } from "react-router-dom";

const classes = {
  wrapper: tw(`overflow-scroll`),
  container: tw(`min-h-screen w-full flex flex-col bg-[white] pb-[24px]`),
  appbarContainer: tw(`py-4 h-[120px] flex items-center`),
  headerContainer: tw(
    `mb-[16px] px-[16.5px] text-[18px] leading-[21.6px] font-semibold flex flex-row items-center justify-between`
  ),
  label: tw(
    `border-[1px] border-[#2C702F] rounded-full font-normal flex justify-center items-center text-[14px] leading-[19.6px] text-[#2C702F] py-[2px] px-[8px]`
  ),
  swiperWrapper: tw(`[&_.swiper-wrapper]:items-center`),
  swiperContainer: tw(`px-[45px] relative pb-[24px]`),
  swiperImage: tw(`w-full object-contain max-w-[450px] m-auto`),
  danger: tw(
    `text-status-bad font-semibold text-[14px] leading-[21px] whitespace-pre-line text-center`
  ),
  actionContainer: tw(
    `flex flex-col gap-[16px] mt-[16px] max-w-[450px] px-[16px] w-full mx-auto`
  ),
  button: tw(
    `bg-secondary border-[1px] border-secondary flex justify-center items-center text-center text-[white] text-[16px] leading-[25.6px] font-bold w-full py-[16.5px] rounded-full hover:brightness-105 duration-150 transition-all`
  ),
};

const ViewDocumentPartnerContract = () => {
  const { ContractDocument } = usePartnerViewContractController();

  let navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate("/partner/partner-contract");
  }, []);

  const [docs, setDocs] = useState<
    { url: string; fileName: string; fileId: string }[]
  >([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const createDocs = async () => {
    if (ContractDocument && ContractDocument.contract) {
      const docsData = [];

      const preSignURL1 = await getDownloadContractDocument({
        fileId: ContractDocument.contract.contractFileId,
      });
      if (preSignURL1) {
        const response = await axios.get<Blob>(preSignURL1.blob.blobUrl, {
          responseType: "blob",
        });
        const objectURL = window.URL.createObjectURL(response.data);
        docsData.push({
          url: objectURL,
          fileName: ContractDocument.contract.contractFileName,
          fileId: ContractDocument.contract.contractFileId,
        });
      }
      setDocs(docsData);
      setIsLoading(false);
    }
  };

  const handleDownloadByUrl = useCallback(
    (id: string, fileId: string, name: string) => async () => {
      const preSignURL = await getDownloadContractDocument({ fileId: fileId });
      if (preSignURL) {
        const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
          responseType: "blob",
        });
        if (typeof window !== "undefined") {
          const objectURL = window.URL.createObjectURL(response.data);
          handleDownloadBlob(objectURL, name);
        }
      }
    },
    [ContractDocument, docs]
  );

  useEffect(() => {
    createDocs();
  }, [ContractDocument]);

  return (
    <div className={classes.container}>
      <div className={classes.appbarContainer}>
        <AppBarFS title="เอกสารสัญญา" onIconPress={handleBack} />
      </div>
      {!isLoading && (
        <Swiper
          pagination
          modules={[Pagination]}
          className={classes.swiperWrapper}
          initialSlide={slideIndex}
          onRealIndexChange={(e) => {
            setSlideIndex(e.realIndex || 0);
          }}
          slidesPerView={1}
        >
          {docs.map((doc, index) => {
            const isPdf = doc.fileName.indexOf(".pdf") !== -1;
            return (
              <SwiperSlide
                key={`${index}`}
                className={classes.swiperContainer}
                onClick={() => setIsOpenModal(true)}
              >
                {!isPdf ? (
                  <>
                    <div className="absolute top-[45%] bottom-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2">
                      <div className="w-8 h-8">
                        <ZoomImageSvg />
                      </div>
                    </div>
                    <img src={doc.url} className={classes.swiperImage} />
                  </>
                ) : (
                  <PdfRenderer
                    url={doc.url}
                    className={tw(
                      classes.swiperImage,
                      "min-h-[420px] border-[1px] border-[#555]"
                    )}
                  />
                )}
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
      <div className="flex flex-1" />
      <div className={classes.actionContainer}>
        <button
          onClick={handleDownloadByUrl(
            get(ContractDocument?.contract, ["id"], ""),
            get(docs, [slideIndex, "fileId"]),
            get(docs, [slideIndex, "fileName"])
          )}
          className={tw(classes.button)}
        >
          บันทึกรูปภาพ
        </button>
        <button
          onClick={handleBack}
          className={tw(classes.button, "bg-[white] text-secondary")}
        >
          ปิด
        </button>
      </div>
      <PreviewAndZoomImageCarouselModal
        isOpen={isOpenModal}
        initIndex={slideIndex}
        onClose={() => {
          setIsOpenModal(false);
        }}
        docs={docs}
        isOneDoc
      />
    </div>
  );
};

export default ViewDocumentPartnerContract;

const DocumentSize20pxSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
    <path
      fill="#fff"
      d="M6.044 17.125h7.917c.874 0 1.583-.709 1.583-1.583v-7.59a.792.792 0 0 0-.232-.559l-4.286-4.286a.791.791 0 0 0-.56-.232H6.044c-.874 0-1.583.709-1.583 1.583v11.084c0 .874.709 1.583 1.583 1.583Z"
    />
    <path
      stroke="#68C184"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M7.628 10h4.75m-4.75 3.167h4.75m1.583 3.958H6.044a1.583 1.583 0 0 1-1.583-1.583V4.458c0-.874.709-1.583 1.583-1.583h4.422c.21 0 .412.083.56.232l4.286 4.286c.149.149.232.35.232.56v7.589c0 .874-.709 1.583-1.583 1.583Z"
    />
  </svg>
);
export default DocumentSize20pxSvg;

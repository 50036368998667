import { LoaderFunctionArgs } from "react-router-dom";
import { getMyOrderDetailById } from "../../../../services/MyOrder.service";

export async function myOrderDetailLoader(loader: LoaderFunctionArgs) {
  const { request, params } = loader;
  const farmId = params["farmId"] ?? "";
  const orderId = params["orderId"] ?? "";
  const myOrderList = await getMyOrderDetailById(orderId);
  return {
    farmId,
    orderId,
    myOrderList,
  };
}

import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircleIcon from "@mui/icons-material/Circle";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { Formik, FormikProps } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate } from "react-router-dom";
import { ValidationError, array, date, number, object, string } from "yup";
import AppBarFS from "../../../components/app-bar/AppBar";
import { StepperConnectorFS } from "../../../components/common/Stepper";

import { Farm } from "../../../types";
import { Contract } from "../../../types/ContactList.type";
import { FeedBrands, UnitEnum } from "../../../types/FeedBrand.type";
import { IGetMyOrderByID } from "../../../types/MyOrderFeed.type";
import { IOrderFeedForm, TUnit } from "../../../types/OrderFeed.type";
import OrderFeedDetailsForm from "./OrderFeedDetailsForm";
import OrderFeedSummaryForm from "./OrderFeedSummaryForm";

export default function OrderFeed() {
  const [activeStep, setActiveStep] = useState<number>(0);
  const { t } = useTranslation();
  const loaderData = useLoaderData() as any;
  const farmId = loaderData.farmId;
  const feedBrands: FeedBrands[] = loaderData.feedBrand;
  const contracts: Contract[] = loaderData.contractDetail;
  const houseAddress: Farm = loaderData.houseAddress;
  const farmerPhoneNumber = loaderData.farmerPhoneNumber;
  const orderById: IGetMyOrderByID = loaderData.getOrderById;

  let initialValues: IOrderFeedForm = {
    houseId: "",
    contractId: "",
    deliveryType: "",
    deliveryDate: null,
    houseName: "",
    contractName: "",
    remainingCredit: 0,
    orders: [
      {
        brandId: "",
        brandSKU: "",
        amount: "",
        unit: "",
        pricePerUnit: "",
        unitLabel: "",
        nameEn: "",
        nameTh: "",
        skuEn: "",
        skuTh: "",
      },
    ],
  };
  if (orderById) {
    initialValues = {
      houseId: orderById.result.house.id,
      contractId: orderById.result.contractId,
      deliveryType: orderById.result.shippingType,
      contractName: orderById.result.contract,
      deliveryDate: null,
      houseName: orderById.result.house.name,
      orders: orderById.result.orderFeedItems.map((order) => ({
        brandId: order.feedBrand.id,
        brandSKU: order.feedBrandItem.id,
        amount: order.feedAmount,
        unit: (order.feedUnit?.toUpperCase() as TUnit) || "",
        pricePerUnit: Number(order.feedBrandItem.pricePerUnit),
        unitLabel: UnitEnum[order.feedUnit as keyof typeof UnitEnum],
        nameEn: order.feedBrand.nameEn,
        nameTh: order.feedBrand.nameTh,
        skuEn: order.feedBrandItem.skuEn,
        skuTh: order.feedBrandItem.skuTh,
      })),
      remainingCredit: orderById.result.remainingCredit,
    };
  }
  const validationSchema = object().shape(
    {
      houseId: string()
        .ensure()
        .required(`${t("orderFeed.input.helperText.house")}`),
      contractId: string()
        .ensure()
        .required(`${t("orderFeed.input.helperText.contract")}`),
      deliveryType: string().required(
        `${t("orderFeed.input.helperText.deliveryType")}`
      ),
      deliveryDate: date().required(
        `${t("orderFeed.input.helperText.deliveryDate")}`
      ),
      orders: array()
        .of(
          object({
            brandId: string().required(
              `${t("orderFeed.input.helperText.brandId")}`
            ),
            brandSKU: string().required(
              `${t("orderFeed.input.helperText.brandSKU")}`
            ),
            amount: number()
              .positive(`${t("orderFeed.input.helperText.positiveAmount")}`)
              .typeError(`${t("orderFeed.input.helperText.amount")}`)
              .required(`${t("orderFeed.input.helperText.amount")}`)
              .when("unit", {
                is: "BAG",
                then: (schema) =>
                  schema.integer(
                    `${t("orderFeed.input.helperText.integerAmount")}`
                  ),
              }),
          })
        )
        .test(
          "duplicate-sku",
          `${t("orderFeed.input.helperText.duplicateSKU")}`,
          function (orders) {
            if (!orders) {
              return true;
            }
            const errors: ValidationError[] = [];
            orders.forEach((row, idx) => {
              if (row.brandSKU) {
                const firstOccurrenceIndex = orders.findIndex(
                  (first) => row.brandSKU === first.brandSKU
                );
                if (
                  firstOccurrenceIndex !== -1 &&
                  firstOccurrenceIndex !== idx
                ) {
                  errors.push(
                    this.createError({
                      path: `orders[${idx}].brandSKU`,
                      message: `${t(
                        "orderFeed.input.helperText.duplicateSKU"
                      )}`,
                    })
                  );
                }
              }
            });
            if (errors.length !== 0) {
              throw new ValidationError(errors);
            }
            return true;
          }
        ),
    },
    [["contractId", "houseId"]]
  );
  const onFormSubmit = () => {
    handleNext(0);
  };

  const stepsData = [
    {
      id: "step-1-details",
      label: t("orderFeed.step1Label"),
      completed: false,
    },
    {
      id: "step-2-summary",
      label: t("orderFeed.step2Label"),
      completed: false,
    },
  ];

  let navigate = useNavigate();
  const [steps, setSteps] = useState<any[]>(stepsData);

  const handleBack = (index: number) => {
    if (index < 0) {
      navigate("/feed-order-and-delivery/" + farmId);
    }
    if (steps[index]?.completed) {
      setActiveStep(index);
      setSteps((currentSteps: any[]) => {
        return currentSteps.map((step, indexStep: number) => {
          return {
            ...step,
            completed: indexStep < index,
          };
        });
      });
    }
  };

  const handleNext = (current: number) => {
    setActiveStep(current + 1);
    setSteps((currentSteps) => {
      currentSteps[current] = {
        ...currentSteps[current],
        completed: true,
      };
      return currentSteps;
    });
  };

  const CustomStepIcon = (isComplete: boolean, isPending: boolean) => {
    return isComplete ? (
      <FontAwesomeIcon
        color="secondary"
        className="text-secondary"
        size="lg"
        icon={faCircleCheck}
      />
    ) : (
      <CircleIcon
        fontSize="small"
        className="text-[#EBEBEB]"
        color={isPending ? "secondary" : undefined}
      />
    );
  };

  const mapComponentToStep = (formik: FormikProps<IOrderFeedForm>) => {
    if (activeStep === 0) {
      return (
        <OrderFeedDetailsForm
          feedBrands={feedBrands}
          formik={formik}
          contracts={contracts}
        />
      );
    } else if (activeStep === 1) {
      return (
        <OrderFeedSummaryForm
          feedBrands={feedBrands}
          formik={formik}
          houseAddress={houseAddress}
          farmerPhoneNumber={farmerPhoneNumber}
          farmId={farmId}
        />
      );
    }
  };

  return (
    <div className="py-4 h-full  flex flex-col justify-between w-full">
      <AppBarFS
        title={t("orderFeed.appHeader")}
        onIconPress={() => handleBack(activeStep - 1)}
      ></AppBarFS>
      <div className="px-4 py-2 mt-8">
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<StepperConnectorFS />}
        >
          {steps?.map((step, index) => {
            return (
              <Step key={step?.id} completed={step?.completed} disabled={false}>
                <StepLabel
                  StepIconComponent={() =>
                    CustomStepIcon(step?.completed, index <= activeStep)
                  }
                >
                  {step?.label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={onFormSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <div className="flex grow mt-4 px-4">
            {mapComponentToStep(formik)}
          </div>
        )}
      </Formik>
    </div>
  );
}

import { useState } from "react";

export const usePaginationFilter = (initialLimit: number = 10) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(initialLimit);
  const getTotalPage = (count: number) => {
    return Math.floor(count / page) + 1;
  };
  return {
    page,
    setPage,
    limit,
    setLimit,
    getTotalPage,
  };
};

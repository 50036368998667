import { useQuery } from "@tanstack/react-query";
import { getListInvoice } from "../../../../services/Invoice.service";
import { useCallback, useState } from "react";
import {
  defaultFormUnpaidBill,
  TFormUnpaidBill,
} from "../unpaid-bill/defaultFormUnpaidBill";
import dayjs from "dayjs";

export const useHistoryBillController = () => {
  const [orderByType, setOrderByType] = useState<"desc" | "asc">("desc");

  const [formHistoryBill, setFormHistoryBill] = useState(defaultFormUnpaidBill);

  const onChangeFormHistoryBill = useCallback(
    (key: keyof TFormUnpaidBill) => (value: any) => {
      setFormHistoryBill((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const [currentPage, setCurrentPage] = useState(1);

  const { data: listInvoice } = useQuery({
    queryKey: [
      "list_invoice_history",
      orderByType,
      formHistoryBill.direction,
      formHistoryBill.startDate,
      formHistoryBill.endDate,
      currentPage,
    ],
    queryFn: async () => {
      const result = await getListInvoice({
        page: currentPage,
        direction: formHistoryBill.direction[0]?.value || orderByType,
        tabName: "history",
        startDate: formHistoryBill.startDate
          ? dayjs(formHistoryBill.startDate, "YYYY-MM-DD")
              .startOf("day")
              .toDate()
          : undefined,
        endDate: formHistoryBill.endDate
          ? dayjs(formHistoryBill.endDate, "YYYY-MM-DD").startOf("day").toDate()
          : undefined,
      });
      return result;
    },
  });

  return {
    listInvoice,
    orderByType,
    setOrderByType,
    formHistoryBill,
    setFormHistoryBill,
    onChangeFormHistoryBill,
    currentPage,
    setCurrentPage,
  };
};

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { MenuItem, SelectChangeEvent } from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import { tw } from "../../../../utils/tw";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import TextInputSearchCustom from "../../../components/Input/TextInputSearchCustom";
import SelectAdminCustom from "../../../components/Select/Select";
import { TUsePartnerDetailFilter } from "../hooks/usePartnerDetailFilter.hook";

import ButtonOutlinedCustom from "../../../components/Button/ButtonOutlinedCustom";
import { getDropdownGroupArea } from "../../farm-detail/services/dropdownGroupArea.service";
import { getDropdownSellName } from "../../farm-detail/services/dropdownSale.service";
import { IGroupAreaRow } from "../../farm-detail/types/dropdownGroupArea.type";
import { ISaleNameRow } from "../../farm-detail/types/dropdownSellName.type";
import { DownloadSvg } from "../../../assets/svg/Download.svg";
import { SvgIcon } from "@mui/material";

const classes = {
  flexRow: tw(`flex flex-row`),
  flexCol: tw(`flex flex-col`),
  flex: tw(`flex`),
  fullScreen: tw(`w-full h-full`),
  label: tw(`text-sm font-medium font-sans`),
};

export type TDropdownFarmSize = "0_50" | "51_500" | "501_1000" | "1000UP" | "";

interface IPartnerDetailFilter {
  searchPartnerDetail: TUsePartnerDetailFilter;
  itemSelected: string[];
  onExport: () => void;
  onSearch: () => void;
}

export function PartnerDetailFilter({
  searchPartnerDetail,
  itemSelected,
  onExport,
  onSearch,
}: IPartnerDetailFilter) {
  const [toggleClear, setToggleClear] = useState(false);
  const [groupAreaRow, setGroupAreaRow] = useState<IGroupAreaRow[]>();
  const [saleNameRow, setSaleNameRow] = useState<ISaleNameRow[]>();
  const RolePartnerSelect = ["basic", "core"];

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchPartnerDetail.setPage(1);
    onSearch();
  };

  const fetchDataDropdownGroupArea = async () => {
    const groupArea = await getDropdownGroupArea();
    setGroupAreaRow(groupArea?.rows);
  };

  const fetchDataDropdownSellName = async () => {
    const sellName = await getDropdownSellName();
    setSaleNameRow(sellName?.rows);
  };

  useEffect(() => {
    onSearch();
  }, [toggleClear]);

  useEffect(() => {
    fetchDataDropdownGroupArea();
    fetchDataDropdownSellName();
  }, []);

  const handleChangeGroupArea = (e: SelectChangeEvent<unknown>) => {
    searchPartnerDetail.setGroupArea(e.target.value as string);
  };
  const handleChangeSaleName = (e: SelectChangeEvent<unknown>) => {
    searchPartnerDetail.setSaleName(e.target.value as string);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={classes.fullScreen}>
          <div className={classes.flexRow}>
            <div className="w-[33%]">
              <span className={classes.label}>Group Area</span>
              <div className="pt-2">
                <SelectAdminCustom
                  value={searchPartnerDetail.groupArea}
                  placeholder="เลือก"
                  menuItems={(groupAreaRow || []).map((row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                  renderValueArray={(groupAreaRow || []).map((row) => ({
                    value: row.id,
                    label: row.name,
                  }))}
                  endAdornment={
                    searchPartnerDetail.groupArea !== "" ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "16px !important",
                          marginRight: "20px",
                        }}
                        onClick={(_) => {
                          searchPartnerDetail.setGroupArea("");
                          setToggleClear((prev) => !prev);
                        }}
                      />
                    ) : null
                  }
                  onChange={(e) => handleChangeGroupArea(e)}
                />
              </div>
            </div>
            <div className="w-[33%] px-2">
              <span className={classes.label}>ชื่อพนักงานขาย</span>
              <div className="pt-2">
                <SelectAdminCustom
                  value={searchPartnerDetail.saleName}
                  placeholder="เลือก"
                  menuItems={(saleNameRow || []).map((row) => (
                    <MenuItem key={row.id} value={row.name}>
                      {row.name}
                    </MenuItem>
                  ))}
                  renderValueArray={(saleNameRow || []).map((row) => ({
                    value: row.id,
                    label: row.name,
                  }))}
                  endAdornment={
                    searchPartnerDetail.saleName !== "" ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "16px !important",
                          marginRight: "20px",
                        }}
                        onClick={(_) => {
                          searchPartnerDetail.setSaleName("");
                          setToggleClear((prev) => !prev);
                        }}
                      />
                    ) : null
                  }
                  onChange={(e) => handleChangeSaleName(e)}
                />
              </div>
            </div>
            <div className="w-[33%] px-2">
              <span className={classes.label}>ประเภท</span>
              <div className="pt-2">
                <SelectAdminCustom
                  value={searchPartnerDetail.partnerType}
                  placeholder="เลือก"
                  menuItems={RolePartnerSelect.map((row) => (
                    <MenuItem key={row} value={row}>
                      {row}
                    </MenuItem>
                  ))}
                  renderValueArray={RolePartnerSelect.map((row) => ({
                    value: row,
                    label: row,
                  }))}
                  endAdornment={
                    searchPartnerDetail.partnerType !== "" ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "16px !important",
                          marginRight: "20px",
                        }}
                        onClick={(_) => {
                          searchPartnerDetail.setPartnerType("");
                          setToggleClear((prev) => !prev);
                        }}
                      />
                    ) : null
                  }
                  onChange={(e) =>
                    searchPartnerDetail.setPartnerType(e.target.value as string)
                  }
                />
              </div>
            </div>
            <div className="h-full self-end">
              <ButtonFillCustom type="submit" title="ค้นหา" />
            </div>
          </div>
          <div className={tw(classes.flexRow, "w-full justify-between pt-6")}>
            <div className="flex w-full max-w-[656px]">
              <div className="w-full max-w-[470px] mr-4">
                <TextInputSearchCustom
                  value={searchPartnerDetail.searchText}
                  placeholder="ค้นหาโดยรหัสพาร์ทเนอร์, ชื่อพาร์ทเนอร์"
                  onChange={(event) =>
                    searchPartnerDetail.setSearchText(event.target.value)
                  }
                  InputProps={{
                    endAdornment:
                      searchPartnerDetail.searchText.length > 0 ? (
                        <CloseRoundedIcon
                          sx={{
                            cursor: "pointer",
                            fontSize: "18px !important",
                          }}
                          onClick={(_) => {
                            searchPartnerDetail.setSearchText("");
                            setToggleClear((prev) => !prev);
                          }}
                        />
                      ) : null,
                  }}
                />
              </div>
              {itemSelected.length > 0 && (
                <div className="w-full max-w-[170px] flex items-center font-sans text-[14px] text-[#646464]">{`เลือกไว้ ${itemSelected.length} รายการ`}</div>
              )}
            </div>
            <div className="flex justify-end">
              <ButtonOutlinedCustom
                disabled={itemSelected.length === 0}
                startIcon={
                  <SvgIcon
                    sx={{
                      fontSize: "14px !important",
                      color: "currentColor",
                      marginBottom: "3px",
                    }}
                    fontSize="small"
                  >
                    <DownloadSvg />
                  </SvgIcon>
                }
                title={"นำออก"}
                onClick={onExport}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

import {
  IGetContractDocument,
  IGetDownloadURLPartnerContract,
  IGetRevenueRateChangeHistory,
} from "../../types/partner/PartnerSectionContract";
import api from "../ApiService";

export const getContractDocument = async () => {
  const { data } = await api.get<IGetContractDocument>(
    `/partner/revenue/contract/document`
  );
  return data;
};

export const getDownloadContractDocument = async (params: {
  fileId: string;
}) => {
  const { data } = await api.get<IGetDownloadURLPartnerContract>(
    `/partner/revenue/contract/document/${params.fileId}`
  );
  return data;
};

export const getRevenueRateChangeHistory = async (params?: {
  orderBy?: string;
  dateStart?: Date;
  dateEnd?: Date;
}) => {
  const { data } = await api.get<IGetRevenueRateChangeHistory>(
    `/partner/revenue/contract/history`,
    {
      params: {
        orderBy: params?.orderBy,
        dateStart: params?.dateStart,
        dateEnd: params?.dateEnd,
      },
    }
  );
  return data;
};

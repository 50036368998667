import { useQuery } from "@tanstack/react-query";
import { getProfilePartner } from "../../services/partner/PartnerLanding.service";

export const useAccountCenterPartnerController = () => {
  const { data: GetProfilePartner } = useQuery({
    queryKey: ["profile-partner"],
    queryFn: async () => {
      const result = await getProfilePartner();
      return result;
    },
  });

  return {
    GetProfilePartner,
  };
};

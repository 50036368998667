import React from "react";

interface FeedingContextData {
  feedBrands: any;
  selectedBrand: any;
  basicDetailsData: any;
  finalAddHouseData: any;
  getSelectedBrands?: () => void;
  changeSelectedBrands?: () => void;
  setFeedBrands?: React.Dispatch<React.SetStateAction<any>>;
  setSelectedBrand?: React.Dispatch<React.SetStateAction<any>>;
  setBasicDetailsData?: React.Dispatch<React.SetStateAction<any>>;
  setAddHouseData?: React.Dispatch<React.SetStateAction<any>>;
  setEditVaccine?: React.Dispatch<React.SetStateAction<any>>;
  setEditFeed?: React.Dispatch<React.SetStateAction<any>>;
  isEditVaccine: boolean;
  isEditFeed: boolean;
  isEditHouse: boolean;
  setEditHouse?: React.Dispatch<React.SetStateAction<any>>;
  activeStep: number;
  setActiveStep?: React.Dispatch<React.SetStateAction<any>>;
  steps: any;
  setSteps?: React.Dispatch<React.SetStateAction<any>>;
  feedData: any;
  setFeedData?: React.Dispatch<React.SetStateAction<any>>;
}

const FeedingContext = React.createContext<FeedingContextData>({
  feedBrands: [],
  selectedBrand: [],
  basicDetailsData: [],
  finalAddHouseData: {},
  isEditFeed: false,
  isEditVaccine: false,
  isEditHouse: false,
  activeStep: 0,
  steps: [],
  feedData: [{}],
});

export default FeedingContext;

import { useQuery } from "@tanstack/react-query";
import { getSummaryAssignment } from "../../services/Invoice.service";
import {
  getProfilePartner,
  getSummaryContract,
  getSummaryCustomer,
  getSummaryRevenue,
} from "../../services/partner/PartnerLanding.service";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { TDropdownValue } from "../../admin/pages/group-area/views/Dropdown";
import {
  defaultFormPartnerLanding,
  TFormPartnerLanding,
} from "./defaultFormPartnerLanding";

export const usePartnerLandingController = () => {
  const [formPartnerRevenue, setFormPartnerRevenue] = useState(
    defaultFormPartnerLanding
  );

  const onChangeFormPartnerRevenue = useCallback(
    (key: keyof TFormPartnerLanding) => (value: any) => {
      setFormPartnerRevenue((prev) => {
        return { ...prev, [key]: value };
      });
    },
    []
  );

  const { data: GetProfilePartner } = useQuery({
    queryKey: ["profile-partner"],
    queryFn: async () => {
      const result = await getProfilePartner();
      return result;
    },
  });

  const { data: SummaryCustomer } = useQuery({
    queryKey: ["summary-customer"],
    queryFn: async () => {
      const result = await getSummaryCustomer();
      return result;
    },
  });

  const { data: SummaryRevenue } = useQuery({
    queryKey: ["summary-revenue", formPartnerRevenue],
    queryFn: async () => {
      const monthValues = formPartnerRevenue.mount
        .map((item) => item.value)
        .join(",");

      const result = await getSummaryRevenue({
        christianYear: dayjs().year(),
        month: monthValues,
      });
      return result;
    },
  });

  const { data: SummaryAssignment } = useQuery({
    queryKey: ["summary-assignment"],
    queryFn: async () => {
      const result = await getSummaryAssignment();
      return result;
    },
  });

  const { data: SummaryContract } = useQuery({
    queryKey: ["summary-contract"],
    queryFn: async () => {
      const result = await getSummaryContract();
      return result;
    },
  });

  return {
    GetProfilePartner,
    SummaryCustomer,
    SummaryRevenue,
    SummaryAssignment,
    SummaryContract,
    formPartnerRevenue,
    onChangeFormPartnerRevenue,
  };
};

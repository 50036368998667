import { useEffect, useRef, useState } from "react";
import { ColoredTypography } from "../ColoredTypography.tsx";
import { IAuthBox } from "./types";

const AuthBox = ({
  lockOutError,
  lockOutMessage = "lockout",
  error,
  errorMessage = "Invalid Pin",
  boxLength = 6,
  onChange,
  inputType = "text",
  placeholder = "",
  alignError = "left",
  checkOTP,
  labelStyle,
  inputStyle,
  sucess,
  datatestid,
  value,
  containerClassName,
}: IAuthBox) => {
  const [pin, setPin] = useState<string[]>(new Array(boxLength).fill(""));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handlePINChange = (value: string, index: number) => {
    const updatedPin = [...pin];
    updatedPin[index] = value;
    setPin(updatedPin);
    onChange?.(updatedPin.join("")); // Emit event to parent
    if (value === "" && index > 0) {
      inputRefs?.current[index - 1]?.focus(); // Move focus to the previous input field
    } else if (value !== "" && index < inputRefs.current.length - 1) {
      inputRefs?.current[index + 1]?.focus(); // Move focus to the next input field
    }
    if (inputRefs && inputRefs.current) {
      checkOTP && checkOTP(inputRefs.current);
    }
  };
  useEffect(() => {
    if (!value) {
      const paddedString = value
        ?.padEnd(boxLength)
        ?.split("")
        .map((item) => item.trim() || "");
      if (paddedString) {
        setPin(paddedString);
      }
    }
  }, [value]);
  return (
    <div
      className={
        containerClassName ? containerClassName : "flex flex-col gap-y-1"
      }
    >
      <div className="flex items-center gap-x-4">
        {pin.map((item, index) => {
          return (
            <div key={index} className="w-14 h-16 ">
              <input
                type={inputType}
                placeholder={placeholder}
                maxLength={1}
                className="w-full h-full flex flex-col items-center justify-center text-center  outline-none rounded-2xl border border-gray-200  text-3xl font-c font-medium bg-3777BC focus:bg-gray-50 focus:ring-1 ring-blue-700 text-[#012156]"
                onChange={(event) => handlePINChange(event.target.value, index)}
                ref={(ref) => (inputRefs.current[index] = ref)}
                style={{
                  ...inputStyle,
                  border: error
                    ? "1px solid #D32F2F"
                    : sucess
                    ? "1px solid #2C702F"
                    : "1px solid #97BFEA",
                  backgroundColor:
                    error || lockOutError
                      ? "#FDF5F5"
                      : sucess
                      ? "#F5F9F5"
                      : "#F6FBFF",
                }}
                data-testid={datatestid}
                inputMode="numeric"
              />
            </div>
          );
        })}
      </div>
      {!lockOutError && error && (
        <ColoredTypography
          sx={{ color: "#ff0000", ...labelStyle }}
          align={alignError}
          variant="body2"
        >
          {errorMessage}
        </ColoredTypography>
      )}
      {lockOutError && (
        <ColoredTypography
          sx={{ color: "#ff0000", ...labelStyle }}
          align={alignError}
          variant="body2"
        >
          {lockOutMessage}
        </ColoredTypography>
      )}
    </div>
  );
};

export default AuthBox;

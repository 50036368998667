import { useLoaderData, useNavigate } from "react-router-dom";
import AppBarFS from "../../../components/app-bar/AppBar";

import { TBaseGetListPartnerStock } from "../../../types/partner/stock.type";
import CardPartnerOrder from "../stock-component/CardPartnerOrder";
import FilterOrder from "../../feed-order-and-delivery/my-order/my-order-component/filter/FilterOrder";
import FilterOrderAndOrderTime from "../../feed-order-and-delivery/my-order/my-order-component/filter/FilterOrderAndOrderTime";

export default function StockHistory() {
  const navigate = useNavigate();
  const { stockHistory } = useLoaderData() as {
    stockHistory: TBaseGetListPartnerStock;
  };

  return (
    <div className="h-screen flex flex-col bg-[#ffff] overflow-scroll">
      <div className="py-4 rounded-b-[40px] bg-white">
        <AppBarFS
          onIconPress={() => navigate("/stock")}
          title={"ประวัติสต๊อกรับเข้า"}
        />
      </div>
      <FilterOrderAndOrderTime
        handleOnResetFilter={() => {}}
        handleOnSubmitFilter={() => {}}
        title="สต๊อกทั้งหมด"
      />
      {stockHistory.stockRefills.map((stock, key) => {
        return (
          <CardPartnerOrder
            key={key}
            onMenuClick={() => navigate("/stock/stock-history/" + stock.id)}
            stock={stock}
          />
        );
      })}
    </div>
  );
}

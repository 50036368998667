interface GeoLocationFS {
  latitude: number;
  longitude: number;
}

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

/**
 *
 * @returns Promise<GeoLocationFS>
 */
export function getCurrentLocation(): Promise<GeoLocationFS> {
  return new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          resolve({ latitude, longitude });
        },
        (error) => {
          reject(error.message);
        },
        options
      );
    } else {
      reject("Geolocation is not supported by this browser.");
    }
  });
}

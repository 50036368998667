import { useState } from "react";
import { Order } from "../admin/components/Table/TableCustom";
import { ISaleOrderData } from "../admin/pages/sale-order/UpcomingOrderPanel/UpcomingOrderPanel";

export const useSaleOrderSearchFilter = () => {
  const [searchText, setSearchText] = useState("");
  const [orderStartDate, setOrderStartDate] = useState("");
  const [orderEndDate, setOrderEndDate] = useState("");
  const [deliveryStartDate, setDeliveryStartDate] = useState("");
  const [deliveryEndDate, setDeliveryEndDate] = useState("");
  const [requestDeliveryStartDate, setRequestDeliveryStartDate] = useState("");
  const [requestDeliveryEndDate, setRequestDeliveryEndDate] = useState("");
  const [feedId, setFeedId] = useState("");
  const [feedBrandId, setFeedBrandId] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState<keyof ISaleOrderData>("orderId");
  const [direction, setDirection] = useState<Order>("desc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  return {
    searchText,
    orderStartDate,
    orderEndDate,
    deliveryStartDate,
    deliveryEndDate,
    requestDeliveryStartDate,
    requestDeliveryEndDate,
    feedId,
    feedBrandId,
    orderBy,
    direction,
    page,
    limit,
    setSearchText,
    setOrderStartDate,
    setOrderEndDate,
    setDeliveryStartDate,
    setDeliveryEndDate,
    setRequestDeliveryStartDate,
    setRequestDeliveryEndDate,
    setFeedId,
    setFeedBrandId,
    setOrderBy,
    setDirection,
    setPage,
    setLimit,
  };
};

export type TUseSaleOrderSearchFilter = ReturnType<
  typeof useSaleOrderSearchFilter
>;

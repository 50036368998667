import ReactECharts from "echarts-for-react";
import { useSeeHouseDetailsBasicAccountingController } from "./controller";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "lodash";
import { getCommaNumber } from "../../../../../utils/number";
import AppBarFS from "../../../../../components/app-bar/AppBar";
import FenceSVG from "../../../../../assets/svg/Fence";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { getShortDate } from "../../../../../utils/date";
import { tw } from "../../../../../utils/tw";
import { DocumentTextIcon } from "../../../../../assets/svg/DocumentGreen";
import ReverseAccordion from "./view/ReverseAccordion";
import dayjs from "dayjs";
import {
  Dropdown,
  TDropdownValue,
} from "../../../../../admin/pages/group-area/views/Dropdown";
import { useEffect, useState } from "react";
import { DonutChartOptions } from "./DonutChart";
import { useTranslation } from "react-i18next";
import { PencilAlternateIcon } from "../../../../../assets/svg/PencilAlternate";

const SeeHouseDetailsBasicAccounting = () => {
  const { farmId, houseId } = useParams();
  const { i18n } = useTranslation();

  const navigate = useNavigate();

  const {
    formSeeHouseDetailsBasicAccounting,
    onChangeFormSeeHouseDetailsBasicAccounting,
    DataHouseDetails,
    DataHousePerMonthDetails,
  } = useSeeHouseDetailsBasicAccountingController(farmId || "", houseId || "");

  const [selectMonth, setSelectMonth] = useState<TDropdownValue[]>([
    {
      label: dayjs().format("MMMM"),
      value: (dayjs().month() + 1).toString(),
    },
  ]);

  const expense =
    get(DataHouseDetails, ["geneticBill"], 0) +
    get(DataHouseDetails, ["feedBill"], 0) +
    get(DataHouseDetails, ["vaccineAndMedicineBill"], 0) +
    get(DataHouseDetails, ["staffBill"], 0) +
    get(DataHouseDetails, ["waterBill"], 0) +
    get(DataHouseDetails, ["electricityBill"], 0) +
    get(DataHouseDetails, ["transportationBill"], 0) +
    get(DataHouseDetails, ["programBill"], 0) +
    get(DataHouseDetails, ["otherBill"], 0);

  useEffect(() => {
    if (selectMonth) {
      onChangeFormSeeHouseDetailsBasicAccounting("month")(selectMonth);
    }
  }, [selectMonth]);

  return (
    <div
      style={{
        height: "auto !important",
        backgroundSize: "cover",
        backgroundColor: "#EEF2FF",
      }}
      className="!h-auto !min-h-screen font-sans"
    >
      <div className="h-[120px] flex items-center bg-[#FFFF] rounded-b-[40px]">
        <AppBarFS title={"รายละเอียด"} onIconPress={() => navigate(-1)} />
      </div>
      <div className="flex flex-col p-4 gap-6">
        <div className="text-[#3777BC] text-[18px] font-semibold flex flex-row gap-2">
          <FenceSVG />
          {DataHouseDetails?.flockName || ""}
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex flex-row p-2 justify-between">
            <div className="text-[#3777BC] text-[14px] font-normal">
              วันที่ปิดรุ่น
            </div>
            <div className="text-[#34407B] text-[14px] font-normal">
              {DataHouseDetails?.isActive === true
                ? "-"
                : getShortDate(DataHouseDetails?.generationCloseDate)}
            </div>
          </div>
          <div
            className="bg-[#F0FDF4] rounded-[20px] flex flex-col items-center justify-center p-4 gap-3"
            style={{
              boxShadow: "4px 4px 20px 0px #0000001A",
            }}
          >
            <div className="text-[#34407B] text-[15px] font-medium">
              กำไร/ขาดทุน
            </div>
            <div className="flex flex-row">
              <div
                className={tw(
                  "text-[24px] font-medium",
                  get(DataHouseDetails, ["income"], 0) - expense >= 0
                    ? "text-[#68C184]"
                    : "text-[#D32F2F]"
                )}
              >
                {get(DataHouseDetails, ["income"], 0) - expense >= 0 && "+"}
                {getCommaNumber(
                  get(DataHouseDetails, ["income"], 0) - expense,
                  true
                )}
              </div>
              <div className="flex flex-col items-center">
                <ArrowDropUpIcon
                  className="text-[#68C184] mt-[-4px]"
                  fontSize="large"
                />
                <div className="text-[#34407B] text-[10px] font-semibold mt-[-15px]">
                  บาท
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-3">
            <div className="border-[#68C184] border-[1px] rounded-[20px] p-4 gap-3 flex flex-col flex-1">
              <div className="text-[#34407B] text-[14px] font-medium">
                รายได้ (บาท)
              </div>
              <div className="text-[#68C184] text-[18px] font-bold ">
                {getCommaNumber(get(DataHouseDetails, ["income"], 0), true)}
              </div>
            </div>
            <div className="border-[#68C184] border-[1px] rounded-[20px] p-4 gap-3 flex flex-col flex-1">
              <div className="text-[#34407B] text-[14px] font-medium">
                ค่าใช้จ่าย (บาท)
              </div>
              <div className="text-[#D32F2F] text-[18px] font-bold">
                {getCommaNumber(expense, true)}
              </div>
            </div>
          </div>
          <div className="flex flex-col bg-[#F6FBFF] py-4 gap-3 rounded-[24px]">
            <div className="flex flew-row gap-2 px-4">
              <div className="w-[24px] h-[24px] flex items-center justify-center">
                <DocumentTextIcon />
              </div>
              <div className="text-[#34407B] text-[16px] font-semibold">
                ค่าใช้จ่ายทั้งหมด
              </div>
            </div>
            <div className="text-[#3777BC] px-4 text-[14px] font-normal">
              *รายได้การขายสุกร
            </div>
            <div>
              <ReactECharts
                option={DonutChartOptions({ data: DataHouseDetails })}
              />
            </div>
            <ReverseAccordion data={DataHouseDetails} />
            <div className="bg-[#FFFFFF] py-[16px] flex flex-col p-4 gap-3">
              <div className="flex flex-row items-center justify-between">
                <div className="text-[#68C184] text-[16px] font-semibold">
                  ปี {dayjs().year() + 543}
                </div>
                <div>
                  <Dropdown
                    options={[
                      { label: "มกราคม", value: "1" },
                      { label: "กุมภาพันธ์", value: "2" },
                      { label: "มีนาคม", value: "3" },
                      { label: "เมษายน", value: "4" },
                      { label: "พฤษภาคม", value: "5" },
                      { label: "มิถุนายน", value: "6" },
                      { label: "กรกฎาคม", value: "7" },
                      { label: "สิงหาคม", value: "8" },
                      { label: "กันยายน", value: "9" },
                      { label: "ตุลาคม", value: "10" },
                      { label: "พฤศจิกายน", value: "11" },
                      { label: "ธันวาคม", value: "12" },
                    ]}
                    placeholder="เดือน"
                    onChange={(value) => setSelectMonth(value)}
                    renderValueArray={selectMonth}
                    choseStyle2
                    containerDropdown="pb-0 text-center"
                  />
                </div>
              </div>
              <div className="flex flex-row items-center justify-between">
                <div className="flex flew-row gap-2">
                  <div className="w-[24px] h-[24px] flex items-center justify-center">
                    <DocumentTextIcon />
                  </div>
                  <div className="text-[#34407B] text-[16px] font-semibold">
                    {i18n.language === "th" && "เดือน"}
                    {dayjs(selectMonth[0].value).format("MMMM")}
                  </div>
                </div>
                <div
                  className="w-[22px] h-[22px] cursor-pointer flex items-center justify-center"
                  onClick={() =>
                    navigate(
                      `/farm/${farmId}/basic-accounting/save-accounting`,
                      {
                        state: formSeeHouseDetailsBasicAccounting,
                      }
                    )
                  }
                >
                  <PencilAlternateIcon />
                </div>
              </div>
              <div className="text-[#34407B] text-[16px] font-semibold">
                รายได้
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-[#3777BC] text-[14px] font-normal">
                  รายได้การขายสุกร
                </div>
                <div className="text-[#34407B] text-[16px] font-normal pr-4">
                  {getCommaNumber(
                    get(DataHousePerMonthDetails, ["income"], 0),
                    true
                  )}{" "}
                  บาท
                </div>
              </div>
              <div className="text-[#34407B] text-[16px] font-semibold">
                ค่าใช้จ่าย
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-[#3777BC] text-[14px] font-normal">
                  ค่าพันธุ์สัตว์
                </div>
                <div className="text-[#34407B] text-[16px] font-normal pr-4">
                  {getCommaNumber(
                    get(DataHousePerMonthDetails, ["geneticBill"], 0),
                    true
                  )}{" "}
                  บาท
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-[#3777BC] text-[14px] font-normal">
                  ค่าอาหาร
                </div>
                <div className="text-[#34407B] text-[16px] font-normal pr-4">
                  {getCommaNumber(
                    get(DataHousePerMonthDetails, ["feedBill"], 0),
                    true
                  )}{" "}
                  บาท
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-[#3777BC] text-[14px] font-normal">
                  ค่ายาเเละวัคซีน
                </div>
                <div className="text-[#34407B] text-[16px] font-normal pr-4">
                  {getCommaNumber(
                    get(
                      DataHousePerMonthDetails,
                      ["vaccineAndMedicineBill"],
                      0
                    ),
                    true
                  )}{" "}
                  บาท
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-[#3777BC] text-[14px] font-normal">
                  ค่าพนักงาน
                </div>
                <div className="text-[#34407B] text-[16px] font-normal pr-4">
                  {getCommaNumber(
                    get(DataHousePerMonthDetails, ["staffBill"], 0),
                    true
                  )}{" "}
                  บาท
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-[#3777BC] text-[14px] font-normal">
                  ค่าน้ำ
                </div>
                <div className="text-[#34407B] text-[16px] font-normal pr-4">
                  {getCommaNumber(
                    get(DataHousePerMonthDetails, ["waterBill"], 0),
                    true
                  )}{" "}
                  บาท
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-[#3777BC] text-[14px] font-normal">
                  ค่าไฟ
                </div>
                <div className="text-[#34407B] text-[16px] font-normal pr-4">
                  {getCommaNumber(
                    get(DataHousePerMonthDetails, ["electricityBill"], 0),
                    true
                  )}{" "}
                  บาท
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-[#3777BC] text-[14px] font-normal">
                  ค่าขนส่ง
                </div>
                <div className="text-[#34407B] text-[16px] font-normal pr-4">
                  {getCommaNumber(
                    get(DataHousePerMonthDetails, ["transportationBill"], 0),
                    true
                  )}{" "}
                  บาท
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-[#3777BC] text-[14px] font-normal">
                  ค่าโปรเเกรม
                </div>
                <div className="text-[#34407B] text-[16px] font-normal pr-4">
                  {getCommaNumber(
                    get(DataHousePerMonthDetails, ["programBill"], 0),
                    true
                  )}{" "}
                  บาท
                </div>
              </div>
              <div className="flex flex-row justify-between">
                <div className="text-[#3777BC] text-[14px] font-normal">
                  ค่าใช้จ่ายอื่นๆ
                </div>
                <div className="text-[#34407B] text-[16px] font-normal pr-4">
                  {getCommaNumber(
                    get(DataHousePerMonthDetails, ["otherBill"], 0),
                    true
                  )}{" "}
                  บาท
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeeHouseDetailsBasicAccounting;

import LoaderSVG from "../../../assets/images/loader.png";
import { ILoadingProps } from "./type";

export default function Loader({ fullScreen = true }: ILoadingProps) {
  return (
    <div className="fixed z-50 flex items-center justify-center w-full h-full bg-white	">
      <img className="animate-spin" src={LoaderSVG} alt="Loader Image" />
    </div>
  );
}

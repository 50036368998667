import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircleIcon from "@mui/icons-material/Circle";
import { Button, Step } from "@mui/material";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import dayjs, { Dayjs } from "dayjs";
import { useFormik } from "formik";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { date, object, string } from "yup";
import AppBarFS from "../../components/app-bar/AppBar";
import { StepperConnectorFS } from "../../components/common/Stepper";
import DailyLogContext from "../../provider/DailyLogProvider/DailyLogContext";
import {
  getFeedBrands,
  getHousesFromFarmId,
} from "../../services/DailyLogService";
import { getAllFarms } from "../../services/FarmService";
import { Farm } from "../../types";
import { FeedBrands } from "../../types/FeedBrand.type";
import { House } from "../../types/House.type";
import LogFeedDetails from "../daily-log/feed-details";
import BasicDetailDailyLog from "./basic-detail-daily-log";
import DailyLogVaccine from "./daily-log-vaccine";
import DeathDailyLog from "./death-daily-log";
import Treatment from "./treatment";
import { useTranslation } from "react-i18next";

const DailyLog = () => {
  const { t } = useTranslation();

  const stepsData = [
    {
      //STEP 1: Feed Details
      id: "step-1-feed",
      label: t("dailyLog.step1Label"),
      completed: false,
    },
    {
      //STEP 2: Death Program
      id: "step-2-death",
      label: t("dailyLog.step2Label"),
      completed: false,
    },
    {
      //STEP 3: Vaccine Program
      id: "step-3-vaccine",
      label: t("dailyLog.step3Label"),
      completed: false,
    },
    {
      //STEP 3: Treatment Program
      id: "step-4-treatment",
      label: t("dailyLog.step4Label"),
      completed: false,
    },
  ];
  const currentDate = dayjs();
  let navigate = useNavigate();
  // Loader
  const VACCINES = useLoaderData() as any;

  const { state } = useLocation();
  const [steps, setSteps] = useState<any[]>(stepsData);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [farms, setFarms] = useState<Farm[]>([]);
  const [preSelectedFarm, setPreSelectedFarm] = useState<string>("");
  const [preSelectedHouse, setPreSelectedHouse] = useState<string>("");
  const [houses, setHouses] = useState<House[]>([]);
  const [feedBrands, setFeedBrands] = useState<FeedBrands[]>([]);

  const [totalPig, setTotalPig] = useState<number>(0);
  const [breederPig, setBreederPig] = useState<number>(0);

  const [selectedHouse, setSelectedHouse] = useState<any>(null);
  const [feedForm, setFeedForm] = useState<any>(null);
  const [deathFormValues, setDeathFormValue] = useState<any>(null);
  const [vaccineFormValues, setVaccineFormValue] = useState<any>(null);
  const {
    setDailyLogData,
    finalDailyLogData,
    isEditMedicine,
    setEditMedicine,
  } = useContext(DailyLogContext);

  const CustomStepIcon = (isComplete: boolean, isPending: boolean) => {
    return isComplete ? (
      <FontAwesomeIcon
        color="secondary"
        className="text-secondary"
        size="lg"
        icon={faCircleCheck}
      />
    ) : (
      <CircleIcon
        fontSize="small"
        className="text-[#EBEBEB]"
        color={isPending ? "secondary" : undefined}
      />
    );
  };
  useEffect(() => {
    if (state?.selectedFarm) {
      formik.setFieldValue("farmId", state?.selectedFarm);
      setPreSelectedFarm(state?.selectedFarm);
    }
    if (state?.houseId) {
      formik.setFieldValue("houseId", state?.houseId);
      setPreSelectedHouse(state?.houseId);
    }
    if (state?.logDate) {
      formik.setFieldValue("date", state?.logDate);
    }
    fetchFarms();
    fetchFeedBrand();
    getMinDate();
  }, [state]);
  useEffect(() => {
    if (state?.houseId) {
      formik.setFieldValue("houseId", state?.houseId);
      setPreSelectedHouse(state?.houseId);
    }
  }, [preSelectedFarm]);
  useEffect(() => {
    if (isEditMedicine) {
      setActiveStep(3);
      const tempStepsData = [...stepsData];
      tempStepsData[0].completed = true;
      tempStepsData[1].completed = true;
      tempStepsData[2].completed = true;
      setSteps(tempStepsData);
      if (setEditMedicine) setEditMedicine(false);
    }
  }, [isEditMedicine]);

  const fetchFeedBrand = async () => {
    try {
      const { data } = await getFeedBrands();
      setFeedBrands(data.feedDetails);
    } catch (err) {
      console.error(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      date: finalDailyLogData?.feedData?.date || currentDate,
      farmId: finalDailyLogData?.feedData?.farmId || "",
      houseId: finalDailyLogData?.feedData?.houseId || "",
    },
    validationSchema: object({
      date: date().required(`${t("dailyLog.dateRequiredErrorMessage")}`),
      farmId: string().required(
        `${t("dailyLog.farmNameRequiredErrorMessage")}`
      ),
      houseId: string().required(
        `${t("dailyLog.houseNameRequiredErrorMessage")}`
      ),
    }),
    onSubmit: async (values) => {
      try {
      } catch (err) {
        console.error(err);
      }
    },
  });

  async function fetchFarms() {
    try {
      const { data } = await getAllFarms();
      if (Array.isArray(data?.farms) && data.farms?.length) {
        setFarms(data.farms);
        if (!preSelectedFarm) {
          setPreSelectedFarm(data?.farms?.[0]?.id);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  const fetchHousesFromFarmId = async (farmId: string) => {
    try {
      const { data } = await getHousesFromFarmId(farmId);
      if (isEditMedicine) {
        const selectedHouse = data.houses?.find(
          (house: any) => house.id === state.houseId
        );
        setSelectedHouse(selectedHouse);
        const {
          breederPigCount = 0,
          fattenerPigCount = 0,
          nurseryPigCount = 0,
        } = selectedHouse;
        setTotalPig(breederPigCount + fattenerPigCount + nurseryPigCount);
        if (setEditMedicine) setEditMedicine(false);
      }
      setHouses(data?.houses);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    getMinDate();
  }, [formik.values.houseId]);
  const handleFeedDetailSubmit = async (val: any) => {
    await formik.setTouched({ date: true, farmId: true, houseId: true }, true);
    if (!formik.isValid) {
      return false;
    }
    const { houseId } = formik.values;
    const selectedHouse = houses?.find((house) => house.id === houseId);
    setSelectedHouse(selectedHouse);
    if (selectedHouse) {
      const {
        breederPigCount = 0,
        fattenerPigCount = 0,
        nurseryPigCount = 0,
      } = selectedHouse;
      setTotalPig(breederPigCount + fattenerPigCount + nurseryPigCount);
    }
    setFeedForm(val);
    if (setDailyLogData)
      setDailyLogData({
        ...finalDailyLogData,
        feedData: { ...val, ...formik.values },
      });
    handleNext(activeStep);
  };

  function handleBack(index: number) {
    if (index < 0) {
      // navigate("/farms");
    }
    if (index === 0) {
      setDeathFormValue(null);
      setVaccineFormValue(null);
    } else if (index === 1) {
      setVaccineFormValue(null);
    }

    if (steps[index]?.completed) {
      setActiveStep(index);
      setSteps((currentSteps: any[]) => {
        return currentSteps.map((step, indexStep: number) => {
          return {
            ...step,
            completed: indexStep < index,
          };
        });
      });
    }
  }

  function handleNext(current: number) {
    setActiveStep(current + 1);
    setSteps((currentSteps) => {
      currentSteps[current] = {
        ...currentSteps[current],
        completed: true,
      };
      return currentSteps;
    });
  }

  function goToFeed() {
    handleBack(0);
  }

  // Handle Death Submit
  function handleDeathLog(formValue: any) {
    if (setDailyLogData)
      setDailyLogData({ ...finalDailyLogData, deathData: { ...formValue } });
    setDeathFormValue(formValue);
    handleNext(activeStep);
  }

  // Handle Vaccine Log
  function handleVaccineLog(formValue: any) {
    if (setDailyLogData)
      setDailyLogData({ ...finalDailyLogData, vaccineData: { ...formValue } });
    setVaccineFormValue(formValue);
    handleNext(activeStep);
  }

  function handleTreatmentLog(formValue: any) {
    if (setDailyLogData)
      setDailyLogData({
        ...finalDailyLogData,
        treatMentData: { ...formValue },
      });
    setVaccineFormValue(formValue);
    setActiveStep(0);
    setSteps(stepsData);
  }
  const handleDateChange = (val: Dayjs | null) => {
    if (val) {
      formik.setFieldTouched("houseStartDate", true);
      formik.setFieldValue("date", val);
    }
  };
  const mapComponentToStep = () => {
    if (activeStep === 0) {
      return (
        <LogFeedDetails
          onSubmit={handleFeedDetailSubmit}
          feedBrands={feedBrands}
          finalDailyLogData={finalDailyLogData}
        />
      );
    } else if (activeStep === 1) {
      return (
        <DeathDailyLog
          breederPigCount={selectedHouse?.breederPigCount}
          totalPigs={totalPig}
          form={deathFormValues}
          deathDailyLogSubmit={handleDeathLog}
          finalDailyLogData={finalDailyLogData}
          currentDeadPigsCount={parseInt(selectedHouse?.totalDeadPigs)}
          isEdit={false}
        />
      );
    } else if (activeStep === 2) {
      return (
        <DailyLogVaccine
          totalPigs={totalPig}
          form={vaccineFormValues}
          onDailyLogVaccineSubmit={handleVaccineLog}
          finalDailyLogData={finalDailyLogData}
          vaccines={VACCINES}
        />
      );
    } else if (activeStep === 3) {
      return (
        <Treatment
          onSubmit={handleTreatmentLog}
          finalDailyLogData={finalDailyLogData}
          isEdit={false}
          houseId={state ? state.houseId : formik.values.houseId}
          farmId={state ? state.farmId : formik.values.farmId}
          date={state ? state.logDate : formik.values.date}
          currentDeadPigsCount={parseInt(selectedHouse?.totalDeadPigs)}
          breederPigCount={selectedHouse?.breederPigCount}
          totalPigs={totalPig}
        />
      );
    }
  };
  useEffect(() => {
    if (formik?.values?.farmId) fetchHousesFromFarmId(formik?.values?.farmId);
  }, [formik?.values?.farmId]);

  const handleFarmChange = (value: any) => {
    formik.setFieldValue("farmId", value);
    formik.setFieldValue("houseId", "");
    // fetchHousesFromFarmId(event?.target?.value);
  };

  const getMinDate = () => {
    const data = finalDailyLogData?.houseId
      ? houses.find((ele: any) => ele.id == finalDailyLogData?.houseId)
      : preSelectedHouse
      ? houses.find((ele: any) => ele.id == preSelectedHouse)
      : houses.find((ele: any) => ele.id == formik.values.houseId);
    return dayjs(data?.houseStartDate);
  };

  return (
    <div className="p-4 h-full  flex flex-col justify-between w-full">
      <AppBarFS
        title={t("dailyLog.logHeader")}
        onIconPress={() =>
          activeStep == 0 ? navigate(-1) : handleBack(activeStep - 1)
        }
      />
      <div className="px-4 py-2 mt-8">
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<StepperConnectorFS />}
        >
          {steps?.map((step, index) => {
            return (
              <Step key={step?.id} completed={step?.completed} disabled={false}>
                <StepLabel
                  StepIconComponent={() =>
                    CustomStepIcon(step?.completed, index <= activeStep)
                  }
                >
                  {step?.label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      <div className="flex flex-col grow mt-4 gap-4">
        <BasicDetailDailyLog
          formik={formik}
          farms={farms}
          houses={houses}
          handleFarmChange={handleFarmChange}
          handleDateChange={handleDateChange}
          formDisabled={activeStep > 0}
          maxDate={currentDate}
          minDate={getMinDate()}
        />
        {activeStep > 0 && (
          <div className="text-tertiary px-4 text-sm  text-center">
            <p>
              {t("dailyLog.dailyLogDetailsLabel")}
              <Button
                variant="text"
                onClick={() => goToFeed()}
                color="info"
                sx={{ textTransform: "none", padding: 0 }}
              >
                {" "}
                {t("dailyLog.gobacktofeed")}
              </Button>
            </p>
          </div>
        )}
        {mapComponentToStep()}
      </div>
    </div>
  );
};

export default DailyLog;

import { yupResolver } from "@hookform/resolvers/yup";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { SvgIcon } from "@mui/material";
import dayjs from "dayjs";
import { memo, useEffect, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { number, object, ref, string } from "yup";
import ListOfFile from "../../../../../../components/common/File/ListOfFile";
import { DATE_FORMAT_DATE_PICKER2 } from "../../../../../../const/app.constant";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../../../../redux/store";
import {
  submitTrackingInvoice,
  uploadInvoiceOtherAPI,
} from "../../../../../../services/admin/invoice/invoiceSale.service";
import { ISubmitTrackingInvoice } from "../../../../../../types/invoice/invoiceSale.type";
import { thousandComma } from "../../../../../../utils/common/string";
import {
  handleDownloadObject,
  handleUploadFileToAzure,
} from "../../../../../../utils/downloadFile";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import { CalendarSvg } from "../../../../../assets/svg/Calendar.svg";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import DatePickerPopover from "../../../../../components/Input/DatePickerPopover/DatePickerPopover";
import DragAndDropUploadImage from "../../../../../components/Input/DragAndDropUploadImage";
import RHFTextAreaCustom from "../../../../../components/Input/RHFTextAreaCustom";
import RHFTextInputCustom from "../../../../../components/Input/RHFTextInputCustom";
import AdminModal from "../../../../../components/Modal/AdminModal";
import { IInvoiceTable } from "../../../type";

type TFollowInvoiceModalProps = {
  isOpen: boolean;
  data: IInvoiceTable | undefined;
  handleClose: (isSuccess: boolean) => void;
};

type TInvoiceFollowForm = {
  appointmentDate: string;
  paidAmount: string;
  requireAmount: number;
  detail: string;
  otherDocuments: File[];
};

const validationSchema = object({
  appointmentDate: string().required("กรุณาเลือกวันที่"),
  paidAmount: number()
    .typeError("กรุณากรอกยอดนัดชำระ")
    .positive("ยอดนัดชำระต้องมากกว่า 0")
    .max(ref("requireAmount"), "ยอดนัดชำระต้องน้อยกว่ายอดที่ต้องชำระ"),
});

const FollowInvoiceModal = ({
  handleClose,
  isOpen,
  data,
}: TFollowInvoiceModalProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const calendarRef = useRef<React.ElementRef<typeof DatePickerPopover>>(null);
  console.log(
    (Number(
      data?.amountToBePaid
        ?.match(/\d+(?:,\d{3})*(?:\.\d+)?/)?.[0]
        .replaceAll(",", "")
    ) || 0) -
      (Number(
        data?.paidAmount
          ?.match(/\d+(?:,\d{3})*(?:\.\d+)?/)?.[0]
          .replaceAll(",", "")
      ) || 0)
  );
  const methods = useForm<TInvoiceFollowForm>({
    defaultValues: {
      appointmentDate: "",
      detail: "",
      paidAmount: "",
      requireAmount:
        (Number(
          data?.amountToBePaid
            ?.match(/\d+(?:,\d{3})*(?:\.\d+)?/)?.[0]
            .replaceAll(",", "")
        ) || 0) -
        (Number(
          data?.paidAmount
            ?.match(/\d+(?:,\d{3})*(?:\.\d+)?/)?.[0]
            .replaceAll(",", "")
        ) || 0),
      otherDocuments: [],
    },
    resolver: yupResolver(validationSchema) as any,
  });

  const handleCalenderSave = (value: Date | undefined) => {
    if (value) {
      methods.setValue(
        "appointmentDate",
        dayjs(value).format(DATE_FORMAT_DATE_PICKER2)
      );
      if (methods.formState.submitCount > 0) {
        methods.trigger("appointmentDate");
      }
    }
  };

  const handleCalendarClear = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    methods.setValue("appointmentDate", "");
    if (methods.formState.submitCount > 0) {
      methods.trigger("appointmentDate");
    }
  };

  const handleSubmit = (value: TInvoiceFollowForm) => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
            await submitAPI(value);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "บันทึกสำเร็จ",
              })
            );
            handleClose(true);
          } catch (error) {
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  const handleUploadFiles = (files: File[]) => {
    methods.setValue("otherDocuments", files);
  };

  const handleDeleteFile = async () => {
    methods.setValue("otherDocuments", []);
  };

  const submitAPI = async (value: TInvoiceFollowForm) => {
    let otherDocument: ISubmitTrackingInvoice["otherDocument"];

    if (value.otherDocuments[0]) {
      const response = await uploadInvoiceOtherAPI(data?.id || "");
      if (response?.blob?.url) {
        await handleUploadFileToAzure(
          response?.blob?.url,
          value.otherDocuments[0]
        );
        otherDocument = {
          fileId: response.blob.fileId,
          fileName: value.otherDocuments[0].name,
        };
      }
    }

    const body: ISubmitTrackingInvoice = {
      appointmentDate: dayjs(
        value.appointmentDate,
        DATE_FORMAT_DATE_PICKER2
      ).toDate(),
      detail: value.detail,
      paidAmount: Number(value.paidAmount),
      otherDocument,
    };

    await submitTrackingInvoice(data?.id || "", body);
  };

  useEffect(() => {
    if (isOpen) {
      methods.reset({
        appointmentDate: "",
        detail: "",
        paidAmount: "",
        requireAmount:
          (Number(
            data?.amountToBePaid
              ?.match(/\d+(?:,\d{3})*(?:\.\d+)?/)?.[0]
              .replaceAll(",", "")
          ) || 0) -
          (Number(
            data?.paidAmount
              ?.match(/\d+(?:,\d{3})*(?:\.\d+)?/)?.[0]
              .replaceAll(",", "")
          ) || 0),
        otherDocuments: [],
      });
    }
  }, [isOpen]);

  return (
    <AdminModal
      isOpen={isOpen}
      handleClose={handleClose}
      label="ติดตาม Invoice ที่เลยกำหนดชำระ"
      size="sm"
    >
      <FormProvider {...methods}>
        <div className="flex flex-col gap-4 text-[14px]">
          {/* ข้อมูลลูกค้า */}
          <div className="flex flex-col gap-4">
            <div className="flex gap-2">
              <div className="flex flex-col gap-2 w-1/2">
                <p className="font-bold">รหัสลูกค้า</p>
                <p>{data?.customerId}</p>
              </div>
              <div className="flex flex-col gap-2 w-1/2">
                <p className="font-bold">ชื่อลูกค้า</p>
                <p>{data?.customerId}</p>
              </div>
            </div>
            <div className="flex gap-2">
              <div className="flex flex-col gap-2 w-1/2">
                <p className="font-bold">เลข Invoice</p>
                <p>{data?.invoiceNumber}</p>
              </div>
              <div className="flex flex-col gap-2 w-1/2">
                <p className="font-bold">ยอดเงินที่ต้องชำระ</p>
                <p>
                  {thousandComma(methods.getValues("requireAmount"), 2)}
                  &nbsp;&nbsp;บาท
                </p>
              </div>
            </div>
          </div>
          {/* Form */}
          <div className="flex flex-col gap-4">
            <div className="flex gap-2">
              <div className="flex flex-col gap-2 w-1/2">
                <label htmlFor="appointment-date">วันนัดชำระ</label>
                <RHFTextInputCustom
                  placeholder="กรอก"
                  controlName="appointmentDate"
                  error={!!methods.formState.errors.appointmentDate?.message}
                  helperText={methods.formState.errors.appointmentDate?.message}
                  InputProps={{
                    endAdornment:
                      methods.watch("appointmentDate") !== "" ? (
                        <CloseRoundedIcon
                          sx={{
                            cursor: "pointer",
                            fontSize: "18px !important",
                          }}
                          onClick={handleCalendarClear}
                        />
                      ) : (
                        <SvgIcon
                          sx={{
                            fontSize: "24px !important",
                            color: "#646464",
                          }}
                          fontSize="small"
                        >
                          <CalendarSvg />
                        </SvgIcon>
                      ),
                  }}
                  onClick={(
                    e: React.MouseEvent<HTMLDivElement, MouseEvent>
                  ) => {
                    e.stopPropagation();
                    calendarRef.current?.onOpen(e);
                  }}
                />
              </div>
              <div className="flex flex-col gap-2 w-1/2">
                <label htmlFor="paid-amount">ยอดนัดชำระ</label>
                <RHFTextInputCustom
                  type="number"
                  placeholder="กรอก"
                  controlName="paidAmount"
                  error={!!methods.formState.errors.paidAmount?.message}
                  helperText={methods.formState.errors.paidAmount?.message}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="details">รายละเอียด (ไม่บังคับ)</label>
              <RHFTextAreaCustom placeholder="กรอก" controlName="detail" />
              <p className="font-normal text-[12px] text-[#979797] text-end">
                (maximum 500 characters)
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <label className="font-bold" htmlFor="files">
                เอกสาร (ไม่บังคับ)
              </label>
              <ListOfFile
                files={methods.watch("otherDocuments")}
                onDownload={handleDownloadObject}
                onDelete={handleDeleteFile}
              />
              <DragAndDropUploadImage onUpload={handleUploadFiles} />
            </div>
          </div>
          {/* Submit Button */}
          <div className="flex justify-end">
            <ButtonFillCustom
              title="บันทึก"
              onClick={methods.handleSubmit(handleSubmit)}
            />
          </div>
          <DatePickerPopover
            ref={calendarRef}
            id={"invoice-appointment-date"}
            date={methods.watch("appointmentDate")}
            handleCalenderSave={handleCalenderSave}
          />
        </div>
      </FormProvider>
    </AdminModal>
  );
};

export default memo(FollowInvoiceModal);

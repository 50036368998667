import React, { useEffect, useState } from "react";
import TextInputCustom from "../../../../components/Input/TextInputCustom";
import dayjs from "dayjs";
import DateRangePickerPopover from "../../../../components/Input/DateRangePickerPopover/DateRangePickerPopover";
import { FILTER_DATE_FORMAT } from "../../components/ExpandUpdateDocumentInvoice";
import DatePickerPopover from "../../../../components/Input/DatePickerPopover/DatePickerPopover";
import { CalendarSvg } from "../../../../assets/svg/Calendar.svg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { SvgIcon } from "@mui/material";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import { tw } from "../../../../../utils/tw";

type Props = {
  startDate: string;
  endDate: string;
  setStartDate: (value: string) => void;
  setEndDate: (value: string) => void;
  handleFetch: () => void;
};

const classes = {
  marginTop: tw(`mt-4`),
  flexCenterEnd: tw(`flex justify-between items-end`),
  flexGap: tw(`flex gap-4`),
  span: tw(`font-normal text-[14px] mb-2 font-sans`),
  paddingTop: tw(`pt-2`),
};

const FilterSaleInvoiceDetail = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  handleFetch,
}: Props) => {
  const [dateJsStartFormat, setDateJsStartFormat] = useState("");
  const [dateJsEndFormat, setDateJsEndFormat] = useState("");
  const [toggleFetch, setToggleFetch] = useState<boolean>(false);
  const refCalendarStartDate =
    React.useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);
  const handleSelectStartDate = (value?: Date) => {
    const formattedDate = dayjs(value).toISOString();
    const formatDateJs = dayjs(value).format(FILTER_DATE_FORMAT);
    setStartDate(formattedDate);
    setDateJsStartFormat(formatDateJs);
  };

  const refCalendarEndDate =
    React.useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);
  const handleSelectEndDate = (value?: Date) => {
    const formattedDate = dayjs(value).toISOString();
    const formatDateJS = dayjs(value).format(FILTER_DATE_FORMAT);
    setEndDate(formattedDate);
    setDateJsEndFormat(formatDateJS);
  };

  useEffect(() => {
    handleFetch();
  }, [toggleFetch]);

  return (
    <div className={classes.marginTop}>
      <div className={classes.flexCenterEnd}>
        <div className={classes.flexGap}>
          <div>
            <span className={classes.span}>วันเริ่มต้น</span>
            <div className={classes.paddingTop}>
              <TextInputCustom
                placeholder="เลือก"
                value={dateJsStartFormat}
                InputProps={{
                  endAdornment:
                    startDate !== "" ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "18px !important",
                        }}
                        onClick={(e) => {
                          setStartDate("");
                          setDateJsStartFormat("");
                          setToggleFetch((prev) => !prev);
                          e.stopPropagation();
                        }}
                      />
                    ) : (
                      <SvgIcon
                        sx={{
                          fontSize: "24px !important",
                          color: "#646464",
                        }}
                        fontSize="small"
                      >
                        <CalendarSvg />
                      </SvgIcon>
                    ),
                }}
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  refCalendarStartDate.current?.onOpen(e);
                }}
              />
            </div>
          </div>
          <div>
            <span className={classes.span}>วันสิ้นสุด</span>
            <div className="pt-2">
              <TextInputCustom
                placeholder="เลือก"
                value={dateJsEndFormat}
                InputProps={{
                  endAdornment:
                    endDate !== "" ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "18px !important",
                        }}
                        onClick={(e) => {
                          setEndDate("");
                          setDateJsEndFormat("");
                          setToggleFetch((prev) => !prev);
                          e.stopPropagation();
                        }}
                      />
                    ) : (
                      <SvgIcon
                        sx={{
                          fontSize: "24px !important",
                          color: "#646464",
                        }}
                        fontSize="small"
                      >
                        <CalendarSvg />
                      </SvgIcon>
                    ),
                }}
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  refCalendarEndDate.current?.onOpen(e);
                }}
              />
            </div>
          </div>
        </div>
        <ButtonFillCustom title={"ค้นหา"} onClick={handleFetch} />
      </div>

      <DatePickerPopover
        ref={refCalendarStartDate}
        id={"date-picker-start-date"}
        date={startDate}
        handleCalenderSave={handleSelectStartDate}
      />
      <DatePickerPopover
        ref={refCalendarEndDate}
        id={"date-picker-end-date"}
        date={endDate}
        handleCalenderSave={handleSelectEndDate}
      />
    </div>
  );
};

export default FilterSaleInvoiceDetail;

import { Checkbox, Divider, Link, TableCell, TableRow } from "@mui/material";
import MenuBar from "../../../components/MenuBar/MenuBar";
import TableCustom, {
  ITableCustomHeadCell,
} from "../../../components/Table/TableCustom";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IPartnerDetailTable } from "../types/partnerDetailTable.type";
import { usePartnerDetailFilterHook } from "../hooks/usePartnerDetailFilter.hook";
import { usePartnerDetailTableHook } from "../hooks/usePartnerDetailTable.hook";
import { PartnerDetailFilter } from "../components/PartnerDetailFilter";

const FarmDetail = () => {
  const searchPartnerDetail = usePartnerDetailFilterHook();
  const {
    count,
    list,
    itemSelected,
    handleClick,
    handleSelectAllClick,
    setItemSelected,
    isSelected,
    handleFetch,
    handleExportExcel,
  } = usePartnerDetailTableHook(searchPartnerDetail);

  const navigate = useNavigate();

  const headCells: ITableCustomHeadCell<keyof IPartnerDetailTable>[] = [
    {
      id: "partnerId",
      numeric: false,
      disablePadding: false,
      label: "รหัสพาร์ทเนอร์",
      width: "150px",
      isSorting: true,
    },
    {
      id: "partnerName",
      numeric: false,
      disablePadding: false,
      label: "ชื่อพาร์ทเนอร์",
      width: "150px",
      isSorting: false,
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "ประเภท",
      width: "150px",
      isSorting: false,
    },
    {
      id: "groupArea",
      numeric: false,
      disablePadding: false,
      label: "Group Area",
      width: "250px",
      isSorting: false,
    },
    {
      id: "province",
      numeric: false,
      disablePadding: false,
      label: "จังหวัด",
      width: "150px",
      isSorting: false,
    },
    {
      id: "totalPrice",
      numeric: false,
      disablePadding: false,
      label: "รายได้ทั้งหมด",
      width: "200px",
      isSorting: false,
    },
    {
      id: "totalPrice",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "100px",
      isSorting: false,
    },
  ];

  useEffect(() => {
    handleFetch();
  }, [searchPartnerDetail.direction, searchPartnerDetail.page]);

  return (
    <MenuBar>
      <div className="pt-16 w-full h-full relative">
        <div className="p-10">
          <span className="text-[#000] font-bold text-3xl">Details</span>
          <Divider sx={{ paddingTop: "12px" }} />
          <div className="mt-6">
            <PartnerDetailFilter
              searchPartnerDetail={searchPartnerDetail}
              itemSelected={itemSelected}
              onExport={handleExportExcel}
              onSearch={handleFetch}
            />
          </div>
          <div className="flex w-full pt-6">
            <TableCustom
              order={searchPartnerDetail.direction}
              orderBy={"partnerId"}
              rowsPerPage={10}
              rows={list}
              itemsSelected={itemSelected}
              headerItems={headCells}
              page={searchPartnerDetail.page}
              onChangeOrderBy={() => {}}
              onChangeOrder={(val) => {
                searchPartnerDetail.setDirection(val);
              }}
              onChangePage={(val) => searchPartnerDetail.setPage(val)}
              onSelectAllClick={(val) => handleSelectAllClick(val)}
              onSelectItem={(val) => setItemSelected(val as string[])}
              totalItemSize={count}
              currentPageItems={list.map((row) => row.id)}
              rowChildren={
                <>
                  {list.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                            onClick={(event) => handleClick(event, row.id)}
                            sx={{
                              borderWidth: "1px",
                              color: "#C9C9C9",
                              "&.Mui-checked": {
                                color: "#68C184",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          <Link
                            component="button"
                            variant="body2"
                            sx={{
                              color: "#68C184",
                              textDecorationColor: "#68C184",
                            }}
                          >
                            {row.partnerId}
                          </Link>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {row.partnerName}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {row.type}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            maxWidth: "250px",
                            whiteSpace: "pre",
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {row.groupArea}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            maxWidth: "250px",
                            whiteSpace: "pre",
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                          }}
                        >
                          {row.province}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {row.totalPrice}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          <Link
                            component="button"
                            variant="body2"
                            sx={{
                              color: "#68C184",
                              textDecorationColor: "#68C184",
                            }}
                            onClick={() =>
                              navigate("/admin/partner/detail/" + row.id, {
                                state: list[index],
                              })
                            }
                          >
                            รายละเอียด
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              }
            ></TableCustom>
          </div>
        </div>
      </div>
    </MenuBar>
  );
};

export default FarmDetail;

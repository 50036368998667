import axios, { AxiosInstance, AxiosResponse } from "axios";

import { router } from "../App";
import {
  ADMIN_AUTH_REFRESH_TOKEN_KEY,
  ADMIN_AUTH_TOKEN_KEY,
} from "../const/app.constant";
import { deleteItem, getItem } from "./StorageService";
// Define the base URL for your API
const BASE_URL = `${
  process.env.REACT_APP_BASE_API_URL || window.location.origin
}/api/admin/`;

// Create an instance of Axios with the base URL
const adminAPI: AxiosInstance = axios.create({
  baseURL: BASE_URL,
});

// Set the default headers for the requests
adminAPI.defaults.headers.common["Content-Type"] = "application/json";

// Interceptor for adding the token to requests
adminAPI.interceptors.request.use(
  (config: any) => {
    const token = getItem(ADMIN_AUTH_TOKEN_KEY); // Retrieve the token from wherever you have stored it
    if (token && !config.disableToken) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor for handling token expiration or authentication errors
adminAPI.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      console.error("Unauthorized access or token expired");
      deleteItem(ADMIN_AUTH_TOKEN_KEY);
      deleteItem(ADMIN_AUTH_REFRESH_TOKEN_KEY);
      router.navigate("/admin/login");
    }
    return Promise.reject(error.response);
  }
);

export default adminAPI;

import {
  Box,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  styled,
  tableCellClasses,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import React, { useRef, useState } from "react";
import scrollImg from "../../assets/images/feed/bnt-scroll.png";
import { thousandComma } from "../../utils/common/string";
import {
  EnhancedTableProps,
  HeadCell,
  ITableData,
  ITableScrollBarProps,
} from "./types";

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    width: "150px",
    label: "ชื่อโรงเรือน",
    isSort: true,
  },
  {
    id: "fcr",
    width: "75px",
    label: "FCR",
    isSort: false,
  },
  {
    id: "adg",
    width: "75px",
    label: "ADG",
    isSort: false,
  },
  {
    id: "fcg",
    width: "75px",
    label: "FCG",
    isSort: false,
  },
  {
    id: "death",
    width: "85px",
    label: "Death",
    unit: "(%)",
    isSort: false,
  },
  {
    id: "price",
    width: "110px",
    label: "ราคาขาย",
    unit: "(บาท)",
    isSort: false,
  },
  {
    id: "pigAmount",
    width: "110px",
    label: "จำนวนหมู",
    unit: "(ตัว)",
    isSort: false,
  },
];

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof ITableData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead
      sx={{
        bgcolor: "#D9EBFE",
        width: "100%",
      }}
    >
      <TableRow sx={{ width: "100%" }}>
        {headCells.map((item) =>
          item.isSort ? (
            <TableCell
              key={item.id}
              align={"center"}
              sortDirection={orderBy === item.id ? order : false}
              sx={{
                padding: "8px 0px",
                border: "0px",
              }}
            >
              <TableSortLabel
                active={orderBy === item.id}
                direction={orderBy === item.id ? order : "asc"}
                onClick={createSortHandler(item.id as keyof ITableData)}
                sx={{
                  width: item.width,
                  height: "100%",
                  justifyContent: "center",
                  paddingLeft: "24px",
                  fontWeight: 700,
                  fontSize: "14px",
                }}
              >
                <div className="flex flex-col">
                  <span>{item.label}</span>
                  {item?.unit && <span>{item.unit}</span>}
                </div>
                {orderBy === item.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              key={item.id}
              align={"center"}
              sx={{
                height: "100%",
                justifyContent: "center",
                paddingLeft: "24px",
                fontWeight: 700,
                fontSize: "14px",
                border: "0px",
              }}
            >
              <div className="flex flex-col" style={{ width: item.width }}>
                <span>{item.label}</span>
                {item?.unit && <span>{item.unit}</span>}
              </div>
            </TableCell>
          )
        )}
        <TableCell
          key={"action"}
          align={"center"}
          sx={{
            border: "0px",
          }}
        ></TableCell>
      </TableRow>
    </TableHead>
  );
}

function TableScrollBar({
  onClick,
  onSort,
  orderBy,
  sortBy,
  items,
}: ITableScrollBarProps) {
  const [scrollValue, setScrollValue] = useState(0);
  // const [order, setOrder] = useState<Order>(xOrderBy);
  // const [orderBy, setOrderBy] = useState<
  //   keyof ITableData | "generationClosedAt"
  // >(xSortBy);
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ITableData
  ) => {
    const isAsc = sortBy === property && orderBy === "asc";
    // setOrder(isAsc ? "desc" : "asc");
    // setOrderBy(property);
    onSort(property, isAsc ? "desc" : "asc");
  };

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#D9EBFE",
      color: "black",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: 500,
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f6faff",
      border: 0,
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#ffffff",
      border: 0,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const scrollGridRef = useRef(null);
  const handleScroll = (e: any) => {
    const target: any = scrollGridRef.current;
    target.scrollLeft =
      (target.scrollWidth / 100) * e.target.value - (target.clientWidth ?? 200);
    setScrollValue(e.target.value);
  };
  return (
    <div className="relative w-full flex flex-col">
      <div className="overflow-hidden">
        <TableContainer
          component={Paper}
          sx={{
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            borderBottomLeftRadius: "0px",
            borderBottomRightRadius: "0px",
            boxShadow: "none",
            width: "100%",
            overflow: "hidden",
          }}
          ref={scrollGridRef}
        >
          <Table aria-label="customized table">
            <EnhancedTableHead
              order={orderBy}
              orderBy={sortBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {items.map((item, index) => (
                <StyledTableRow key={`table-cell-${index}`}>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#4B4B4B",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontWeight: 400,
                      fontSize: "14px",
                      border: "0px",
                    }}
                  >
                    <span
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.name}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#4B4B4B",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontWeight: 400,
                      fontSize: "14px",
                      border: "0px",
                    }}
                  >
                    {thousandComma(Number(item.fcr), 2)}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#4B4B4B",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontWeight: 400,
                      fontSize: "14px",
                      border: "0px",
                    }}
                  >
                    {thousandComma(Number(item.adg), 2)}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#4B4B4B",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontWeight: 400,
                      fontSize: "14px",
                      border: "0px",
                    }}
                  >
                    {item.fcg !== "-"
                      ? thousandComma(Number(item.fcg), 2)
                      : item.fcg}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#4B4B4B",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontWeight: 400,
                      fontSize: "14px",
                      border: "0px",
                    }}
                  >
                    {thousandComma(Number(item.death), 2)}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#4B4B4B",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontWeight: 400,
                      fontSize: "14px",
                      border: "0px",
                    }}
                  >
                    {thousandComma(Number(item.price), 2)}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#4B4B4B",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontWeight: 400,
                      fontSize: "14px",
                      border: "0px",
                    }}
                  >
                    {thousandComma(item.pigAmount)}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#68C184",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontWeight: 400,
                      fontSize: "14px",
                      border: "0px",
                      cursor: "pointer",
                    }}
                    onClick={() => onClick(item.id)}
                  >
                    ดูรายละเอียด
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="bg-[#D9EBFE] rounded-b-[20px] h-[20px]"></div>
      </div>
      <div className="flex flex-row justify-center w-[100%] p-2">
        <Slider
          sx={{
            width: "90%",
            "& .MuiSlider-thumb": {
              borderRadius: "10px",
              backgroundImage: `url(${scrollImg})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              color: "unset !important",
              backgroundColor: "transparent",
              width: 40,
            },
            "& .MuiSlider-rail": {
              color: "#E2E2E2 !important",
            },
            "& .MuiSlider-track": {
              backgroundColor: "grey !important",
              border: "1px solid grey !important",
            },
            "& .MuiSlider-valueLabelLabel": {
              fontSize: "20px",
              fontWeight: "bold",
              color: "unset",
              background: "unset",
            },
            cursor: "pointer",
          }}
          defaultValue={0}
          value={scrollValue}
          onChange={handleScroll}
          aria-label="Disabled slider"
        />
      </div>
    </div>
  );
}

export default TableScrollBar;

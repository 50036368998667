import React, { useState } from "react";
import MenuBar from "../../../../components/MenuBar/MenuBar";
import { Divider } from "@mui/material";
import { tw } from "../../../../../utils/tw";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate, useParams } from "react-router-dom";
import FilterSaleInvoiceDetail from "./filterSaleInvoiceDetail";
import TableSaleInvoiceDetail from "./tableSaleInvoiceDetail";

type Props = {};

const classes = {
  container: tw(`pt-16 w-full h-full relative`),
  header: tw(`text-[#000] font-bold text-3xl font-sans`),
  paddingTop: tw(`p-10`),
  flexItemCenter: tw(`flex items-center`),
};

const SaleInvoiceDetail = (props: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <MenuBar>
      <div className={classes.container}>
        <div className={classes.paddingTop}>
          <div className={classes.flexItemCenter}>
            <div onClick={() => navigate(-1)}>
              <ChevronLeftIcon fontSize="large" />
            </div>
            <span className={classes.header}>รายละเอียด</span>
          </div>
          <Divider sx={{ paddingTop: "12px" }} />
          <TableSaleInvoiceDetail saleInvoiceId={id} />
        </div>
      </div>
    </MenuBar>
  );
};

export default SaleInvoiceDetail;

const Calendar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={29} height={30} fill="none">
    <path
      fill="#F57C00"
      fillRule="evenodd"
      d="M8.698 3.398c-.8 0-1.45.65-1.45 1.45v1.45h-1.45a2.9 2.9 0 0 0-2.9 2.9v14.5a2.9 2.9 0 0 0 2.9 2.9h17.4a2.9 2.9 0 0 0 2.9-2.9v-14.5a2.9 2.9 0 0 0-2.9-2.9h-1.45v-1.45a1.45 1.45 0 0 0-2.9 0v1.45h-8.7v-1.45c0-.8-.649-1.45-1.45-1.45Zm0 7.25a1.45 1.45 0 0 0 0 2.9h11.6a1.45 1.45 0 1 0 0-2.9h-11.6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default Calendar;

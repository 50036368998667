import { IReverseInvoice } from "../../../admin/pages/invoice/components/ReverseInvoiceModal";
import {
  TWastedDeptForm,
  TWastedDeptSubmitForm,
} from "../../../admin/pages/invoice/components/WastedDeptModal";
import { IGetISaleInvoiceDetail } from "../../../admin/pages/invoice/sale-invoice/SaleInvoiceDetail/type";
import { apiPaths } from "../../../const/apiPaths";
import { BlobURL } from "../../../types/ImportDocument.type";
import {
  ICreateInvoiceAssignment,
  IGetCountInvoiceSale,
  IGetInvoiceOverDueSale,
  IGetInvoiceSale,
  IGetInvoiceSuccess,
  IGetListPartner,
  IGetOverdueFarmer,
  IGetOverdueFarmerInvoice,
  ISubmitTrackingInvoice,
} from "../../../types/invoice/invoiceSale.type";
import { IGetUploadUrl } from "../../../types/ManageStock.type";
import adminAPI from "../../AdminApiService";
import {
  EditInvoicePayload,
  getDownloadUrlResponse,
  IGetEditInvoiceDocumentResponse,
  IUpdateInvoicePayload,
  updateResponse,
  uploadPresignResponse,
} from "./type";

export const getInvoiceSale = async (searchParams?: URLSearchParams) => {
  try {
    const { data } = await adminAPI.get<IGetInvoiceSale>(
      `/invoice/sale/upcoming?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getListExportInvoiceSaleUpcoming = async (ids: string[]) => {
  try {
    const { data } = await adminAPI.post<IGetInvoiceSale>(
      `/invoice/sale/upcoming/export`,
      { ids }
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getListExportInvoiceSaleOverdue = async (ids: string[]) => {
  try {
    const { data } = await adminAPI.post<IGetInvoiceOverDueSale>(
      `/invoice/sale/overdue/export`,
      { ids }
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getListExportInvoiceSaleComplete = async (ids: string[]) => {
  try {
    const { data } = await adminAPI.post<IGetInvoiceSuccess>(
      `/invoice/sale/overdue/export`,
      { ids }
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getListInvoiceOverDueSale = async (
  searchParams?: URLSearchParams
) => {
  try {
    const { data } = await adminAPI.get<IGetInvoiceOverDueSale>(
      `/invoice/sale/overdue?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getListInvoiceSuccessSale = async (
  searchParams?: URLSearchParams
) => {
  try {
    const { data } = await adminAPI.get<IGetInvoiceSuccess>(
      `/invoice/sale/complete?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getCountInvoiceSale = async (tabName: string) => {
  try {
    const { data } = await adminAPI.get<IGetCountInvoiceSale>(
      `/invoice/sale/count/${tabName}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const uploadInvoiceAPI = async (invoiceId: string) => {
  try {
    const { data } = await adminAPI.get<IGetUploadUrl>(
      apiPaths.invoice.uploadInvoice(invoiceId)
    );
    return data;
  } catch (error) {
    console.log("uploadInvoiceAPI", error);
  }
};

export const uploadInvoiceOtherAPI = async (invoiceId: string) => {
  try {
    const { data } = await adminAPI.get<IGetUploadUrl>(
      apiPaths.invoice.uploadOther(invoiceId)
    );
    return data;
  } catch (error) {
    console.log("uploadInvoiceOtherAPI", error);
  }
};

export const updateInvoiceAPI = async (
  invoiceId: string,
  payload: IUpdateInvoicePayload
) => {
  return adminAPI.put(apiPaths.invoice.updateInvoice(invoiceId), payload);
};

export const getEditInvoiceDocumentAPI = async (invoiceId: string) => {
  return adminAPI.get<IGetEditInvoiceDocumentResponse>(
    apiPaths.invoice.editInvoiceDoc(invoiceId)
  );
};

export const getInvoiceDownloadUrlAPI = async (
  invoiceId: string,
  fileId: string
) => {
  try {
    const response = await adminAPI.get<getDownloadUrlResponse>(
      apiPaths.invoice.getInvoiceDownloadURL(invoiceId, "invoice", fileId)
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log("getInvoiceDownloadUrlAPI", error);
  }
};

export const getInvoiceOtherDownloadUrlAPI = async (
  invoiceId: string,
  fileId: string
) => {
  try {
    const response = await adminAPI.get<getDownloadUrlResponse>(
      apiPaths.invoice.getInvoiceDownloadURL(invoiceId, "other", fileId)
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log("getInvoiceDownloadUrlAPI", error);
  }
};

export const getReceiptDownloadUrlAPI = async (
  documentType: string,
  fileId: string
) => {
  try {
    const response = await adminAPI.get<getDownloadUrlResponse>(
      apiPaths.invoice.getReceiptDownloadURL(documentType, fileId)
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log("getReceiptDownloadUrlAPI", error);
  }
};

export const editInvoiceDocumentAPI = async (
  invoiceId: string,
  payload: EditInvoicePayload
) => {
  try {
    const response = await adminAPI.put<updateResponse>(
      apiPaths.invoice.editInvoiceDocument(invoiceId),
      payload
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log("editInvoiceDocumentAPI", error);
  }
};

export const uploadReceiptPresignAPI = async (documentType: string) => {
  try {
    const response = await adminAPI.get<uploadPresignResponse>(
      apiPaths.invoice.uploadPresign(documentType)
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log("uploadReceiptPresignAPI", error);
  }
};

export const getListOverdueFarmer = async () => {
  try {
    const { data } = await adminAPI.get<IGetOverdueFarmer>(
      `invoice/sale/overdue/farmers`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getListOverdueFarmerInvoice = async (params: {
  farmerId: string;
  searchText?: string;
}) => {
  try {
    const { data } = await adminAPI.get<IGetOverdueFarmerInvoice>(
      `invoice/sale/overdue/farmers/${params.farmerId}`,
      { params }
    );
    return data.rows;
  } catch (error) {
    return null;
  }
};

export const getListPartner = async () => {
  try {
    const { data } = await adminAPI.get<IGetListPartner>(
      `invoice/sale/overdue/partners`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const createInvoiceAssignment = async (body: {
  farmerId: string;
  partnerId: string;
  invoiceIds: string[];
}) => {
  try {
    const { data } = await adminAPI.post<ICreateInvoiceAssignment>(
      `invoice/sale/overdue/invoice-assignment`,
      body
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const submitTrackingInvoice = async (
  invoiceId: string,
  body: ISubmitTrackingInvoice
) => {
  return adminAPI.put("invoice/sale/overdue/tracking/" + invoiceId, body);
};

export const getDownloadInvoiceLog = async (
  documentType: string,
  fileId: string
) => {
  try {
    const { data } = await adminAPI.get<{ success: boolean; blob: BlobURL }>(
      `/invoice/common/receipt/document/download/${documentType}/${fileId}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getInvoiceFollowLog = async (
  invoiceFollowLogId: string,
  searchParams?: URLSearchParams
) => {
  try {
    const { data } = await adminAPI.get<IGetISaleInvoiceDetail>(
      `/invoice/common/follow-logs/${invoiceFollowLogId}?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const putWastedDeptInvoice = async (payload: TWastedDeptSubmitForm) => {
  return adminAPI.put(`/invoice/sale/overdue/wasted-debt`, payload);
};

export const putReverseInvoice = async (
  invoiceId: string,
  body: IReverseInvoice
) => {
  return adminAPI.put("/invoice/lending/revert/" + invoiceId, body);
};

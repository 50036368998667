import { FormControl, FormHelperText } from "@mui/material";
import { memo, useCallback } from "react";
import { tw } from "../../../../utils/tw";
import TextInputCustom from "../../../components/Input/TextInputCustom";

interface IInputProps {
  label?: string;
  label2?: string;
  required?: boolean;
  placeholder?: string;
  containerClassName?: string;
  errorMessage?: string;
  value: string;
  onChangeText: (text: string) => void;
  suffix?: string;
  inputType?: "number" | "text";
  disabled?: boolean;
  customInputStyle?: string;
  borderRadius?: string;
  borderColor?: string;
  customSuffix?: React.ReactNode;
  focused?: boolean;
  bottomMessage?: string;
  containerDetailClassName?: string;
  isNotComma?: boolean;
  maxLength?: number;
}

export const Input = memo((props: IInputProps) => {
  const {
    label,
    label2,
    required,
    value,
    onChangeText,
    placeholder = "กรอก",
    containerClassName,
    errorMessage = "",
    suffix,
    inputType = "text",
    disabled,
    customInputStyle,
    borderRadius,
    borderColor,
    customSuffix,
    focused,
    bottomMessage,
    containerDetailClassName,
    isNotComma,
    maxLength,
  } = props;

  const onChangeFn = useCallback((e: any) => {
    const text = e.target.value;
    onChangeText(text);
  }, []);

  return (
    <div className={tw("flex flex-col flex-1", containerClassName)}>
      {label && (
        <p className="font-medium text-[#191919] mb-[6px] text-[14px] font-sans">
          {label}
          {required && <span className="text-error-300">*</span>}
        </p>
      )}
      <div className={tw("w-full pb-3", containerDetailClassName)}>
        <FormControl className="w-full">
          <TextInputCustom
            type={inputType}
            error={!!errorMessage}
            placeholder={placeholder}
            helperText={errorMessage}
            value={value}
            onChange={onChangeFn}
            suffix={suffix}
            disabled={disabled}
            customInputStyle={customInputStyle}
            borderRadius={borderRadius}
            borderColor={borderColor}
            customSuffix={customSuffix}
            label={label2}
            focused={focused}
            isNotComma={isNotComma}
            maxLength={maxLength}
          />
          {!errorMessage && bottomMessage && (
            <FormHelperText className="!text-[12px] !text-[#3777BC] !font-normal">
              {bottomMessage}
            </FormHelperText>
          )}
        </FormControl>
      </div>
    </div>
  );
});

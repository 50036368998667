import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getListOverdueFarmer,
  getListOverdueFarmerInvoice,
  getListPartner,
} from "../../../../../services/admin/invoice/invoiceSale.service";
import { get } from "lodash";
import { TDropdownValue } from "../../../group-area/views/Dropdown";

export const usePastDueInvoiceController = () => {
  const queryClient = useQueryClient();

  const [overdueFarmer, setOverdueFarmer] = useState<TDropdownValue[]>([]);
  const [listPartner, setListPartner] = useState<TDropdownValue[]>([]);
  const [isShowCreateAssignment, setIsShowCreateAssignment] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const { data: OverdueFarmer } = useQuery({
    queryKey: ["overdue_list_farmer"],
    queryFn: async () => {
      const result = await getListOverdueFarmer();
      return result;
    },
  });

  const { data: OverdueFarmerInvoice } = useQuery({
    queryKey: ["overdue_list_farmer_invoice", searchText],
    enabled: overdueFarmer.length === 1,
    queryFn: async () => {
      const result = await getListOverdueFarmerInvoice({
        farmerId: overdueFarmer[0].value,
        // searchText,
      });
      return result;
    },
  });

  const { data: Partner } = useQuery({
    queryKey: ["overdue_list_partner_invoice"],
    queryFn: async () => {
      const result = await getListPartner();
      return result;
    },
  });

  const OverdueFarmerOptions = useMemo(() => {
    return get(OverdueFarmer, ["farmers"], []).map(
      ({ id, name, surname, registrationId }) => ({
        label:
          (registrationId || "") + " " + (name || "") + " " + (surname || ""),
        value: id,
      })
    );
  }, [OverdueFarmer]);

  const PartnerOptions = useMemo(() => {
    return get(Partner, ["partners"], []).map(
      ({ id, name, surname, partnerCode }) => ({
        label: (partnerCode || "") + " " + (name || "") + " " + (surname || ""),
        value: id,
      })
    );
  }, [Partner]);

  const onShowCreateAssignment = useCallback(() => {
    setIsShowCreateAssignment(true);
  }, []);

  const onHideCreateAssignment = useCallback(() => {
    setIsShowCreateAssignment(false);
  }, []);

  const onSearchText = useCallback((e: any) => {
    const text = e.target.value;
    setSearchText(text);
  }, []);

  const onClearSearchText = useCallback((e: any) => {
    setSearchText("");
  }, []);

  useEffect(() => {
    queryClient.refetchQueries({
      queryKey: ["overdue_list_farmer_invoice"],
    });
    setCurrentPage(1);
  }, [overdueFarmer]);

  return {
    isShowCreateAssignment,
    onShowCreateAssignment,
    onHideCreateAssignment,
    overdueFarmer,
    setOverdueFarmer,
    OverdueFarmerOptions,
    listPartner,
    setListPartner,
    PartnerOptions,
    OverdueFarmerInvoice,
    searchText,
    onSearchText,
    onClearSearchText,
    currentPage,
    setCurrentPage,
  };
};

import { Dialog, DialogContent, IconButton, Tab, Tabs } from "@mui/material";
import { tw } from "../../../../utils/tw";
import { memo, useCallback, useState } from "react";
import CrossIcon from "../../../assets/svg/CrossIcon.svg";
import { TabPanelCustom } from "../../../components/TabPanelCustom/TabPanelCustom";
import AddDetail from "./Tabs/AddDetail";
import { Input } from "../../group-area/views/Input";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../components/Button/ButtonOutlinedCustom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { updateClaimCode } from "../../../../services/ClaimOrder.service";
import { loadingActions } from "../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../utils/getErrorMessage";
import { alertAction } from "../../../../redux/slices/alertSlice";
import { IDataClaimOrder } from "../../../../types/ClaimOrder.type";
import { get } from "lodash";
import { textCode } from "../orderClaim/orderClaimDetail/Tabs/ReportDetail";

interface ICreateModal {
  isOpen: boolean;
  onCloseFn: () => void;
  refetchListClaimOrderProgress?: () => void;
  dataEdit?: IDataClaimOrder;
  ids: string;
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  container: tw(
    `w-[100vw] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-[#FFFFFF]  [&_.MuiButtonBase-root.Mui-selected]:!text-[#000000] [&_.MuiDialog-paperScrollPaper]:!m-0 max-w-[400px]`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  row: tw(
    `w-full flex flex-col md:flex-row item-center justify-between gap-x-3`
  ),
  space: tw(`h-[12px]`),
};

const defaultForm = {
  CodeNumber: "",
};

const UpdateClaimCodeModal = (props: ICreateModal) => {
  const { isOpen, onCloseFn, refetchListClaimOrderProgress, dataEdit, ids } =
    props;
  const [form, setForm] = useState(defaultForm);
  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();

  const onChangeForm = useCallback(
    (key: keyof typeof form) => (value: any) => {
      setForm((prev) => ({ ...prev, [key]: value }));
    },
    []
  );

  const dataById = dataEdit?.orderClaimItems.find((i) => i.id === ids);

  const upDateMutation = useMutation({
    mutationFn: updateClaimCode,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["list_claim_order_progress"],
      });
      {
        refetchListClaimOrderProgress && refetchListClaimOrderProgress();
      }
      setTimeout(() => {
        dispatch(loadingActions.hide());
        onClose();
        setTimeout(() => {
          dispatch(
            snakeActions.showMessage({
              message: "อัปเดตสำเร็จ",
              type: "success",
            })
          );
          setForm(defaultForm);
        }, 500);
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const onSubmit = useCallback(() => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการอัปเดตรายการ",
        text: "คุณต้องการอัปเดตรายการนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          dispatch(alertAction.hide());
          dispatch(loadingActions.show({ message: "กำลังอัปเดตรายการ..." }));
          upDateMutation.mutate({
            orderClaimItemId: dataById?.id || "",
            claimCode: form.CodeNumber,
          });
        },
      })
    );
  }, [form]);

  const onClose = useCallback(() => {
    onCloseFn();
    setForm(defaultForm);
  }, []);

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: "w-full" }}
    >
      <DialogContent className="w-[400px]">
        <div className={classes.titleContainer}>
          <p className={classes.title}>อัปเดตเลขที่ปิดเคส</p>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </div>
        <Input
          label={textCode[dataById ? dataById?.claimOption : ""]}
          placeholder="กรอก"
          value={form.CodeNumber}
          onChangeText={onChangeForm("CodeNumber")}
          inputType="number"
          isNotComma
        />{" "}
        <div className="w-full flex justify-end gap-[10px]">
          <ButtonOutlinedCustom
            onClick={onClose}
            title="ยกเลิก"
            btnTextColor="#68C184"
            btnBgColor="#ffffff"
            btnBorderColor="#68C184"
          />
          <ButtonFillCustom
            onClick={onSubmit}
            title="ยืนยัน"
            btnTextColor="#ffffff"
            btnBgColor="#68C184"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(UpdateClaimCodeModal);

function PaperClip() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9047 4.41667L5.8677 10.4536C5.15174 11.1696 5.15174 12.3304 5.8677 13.0464C6.58366 13.7623 7.74446 13.7623 8.46043 13.0464L14.3401 7.00939C15.772 5.57747 15.772 3.25586 14.3401 1.82394C12.9082 0.392019 10.5866 0.392019 9.15467 1.82394L3.27498 7.86091C1.12709 10.0088 1.12709 13.4912 3.27498 15.6391C5.42286 17.787 8.90527 17.787 11.0531 15.6391L16.7891 9.91667"
        stroke="#979797"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PaperClip;

import { Dialog, DialogContent, IconButton } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { tw } from "../../../../../../utils/tw";
import { AppDispatch } from "../../../../../../redux/store";
import { uploadInvoiceOtherAPI } from "../../../../../../services/admin/invoice/invoiceSale.service";
import {
  handleDownloadObject,
  handleUploadFileToAzure,
} from "../../../../../../utils/downloadFile";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import CrossIcon from "../../../../../assets/svg/CrossIcon.svg";
import ListOfFile from "../../../../../../components/common/File/ListOfFile";
import DragAndDropUploadImage from "../../../../../components/Input/DragAndDropUploadImage";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import { AcceptDebtRow } from "../../types/acceptDebt.type";
import {
  editMoneyDebtDoc,
  getMoneyDebtUploadUrl,
} from "../../services/acceptDebt.service";

const classes = {
  flexCenter: tw(`flex justify-between items-center`),
  flex: tw(`flex w-[100%]`),
  flexCol: tw(`flex flex-col`),
  container: tw(`items-start w-[50%]`),
  header: tw(`text-sm font-bold pb-2 mb-1`),
  headerModal: tw(`text-lg font-bold`),
  title: tw(`text-sm pb-3 mb-1`),
  marginTop: tw(`mt-4`),
};

type TReverseDetailForm = {
  debtAcknowledgementLetters: File[];
};

type Props = {
  isOpen: boolean;
  handleClose: (value: boolean) => void;
  invoiceId?: string;
};

type document = {
  fileId: string;
  fileName: string;
};
export interface IMoneyDebtDoc {
  DebtAcknowledgementLetters: document[];
}

const UpdateDocMoneyDebtModal = ({ isOpen, handleClose, invoiceId }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const debtAcknowledgementLetters: IMoneyDebtDoc["DebtAcknowledgementLetters"] =
    [];

  const submitAPI = async (value: TReverseDetailForm) => {
    if (value.debtAcknowledgementLetters.length) {
      const response = await getMoneyDebtUploadUrl(
        invoiceId || "",
        "debt_acknowledgement_letter"
      );
      console.log(response);

      if (response?.blob?.blobUrl) {
        await handleUploadFileToAzure(
          response?.blob?.blobUrl,
          value.debtAcknowledgementLetters[0]
        );

        debtAcknowledgementLetters.push({
          fileId: response.blob.fileId || "",
          fileName: value.debtAcknowledgementLetters[0].name,
        });
      }
    }
    const body: IMoneyDebtDoc = {
      DebtAcknowledgementLetters: debtAcknowledgementLetters || [],
    };

    console.log(body);

    await editMoneyDebtDoc(invoiceId || "", body);
  };

  const handleSubmit = (value: TReverseDetailForm) => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
            await submitAPI(value);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "บันทึกสำเร็จ",
              })
            );
            handleClose(true);
          } catch (error) {
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  const handleDeleteFile = async () => {
    methods.setValue("debtAcknowledgementLetters", []);
  };

  const handleUploadFiles = (files: File[]) => {
    methods.setValue("debtAcknowledgementLetters", files);
  };

  const methods = useForm<TReverseDetailForm>({
    defaultValues: {
      debtAcknowledgementLetters: [],
    },
  });

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => handleClose(true)}
        maxWidth="lg"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={{
          backdrop: {
            style: { backgroundColor: "#000000", opacity: "10%" },
            timeout: 500,
          },
        }}
      >
        <DialogContent sx={{ width: "750px" }}>
          <div className={classes.flexCenter}>
            <p className={classes.headerModal}>นำเข้าเอกสาร</p>
            <IconButton onClick={() => handleClose(true)}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className={classes.marginTop}>
            <FormProvider {...methods}>
              <div className="flex flex-col gap-2">
                <label className="font-bold" htmlFor="files">
                  ใบรับสภาพหนี้
                </label>
                <ListOfFile
                  files={methods.watch("debtAcknowledgementLetters")}
                  onDownload={handleDownloadObject}
                  onDelete={handleDeleteFile}
                />
                <DragAndDropUploadImage onUpload={handleUploadFiles} />
              </div>
              <div className="flex justify-end mt-4">
                <ButtonFillCustom
                  title="บันทึก"
                  onClick={methods.handleSubmit(handleSubmit)}
                />
              </div>
            </FormProvider>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdateDocMoneyDebtModal;

import { Modal, RadioGroup } from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { array, number, object, string } from "yup";
import AppBarFS from "../../components/app-bar/AppBar";
import CustomButton from "../../components/common/Button";
import RadioButton from "../../components/common/RadioButton";
import { loadingActions } from "../../redux/slices/loadingSlice";
import { snakeActions } from "../../redux/slices/snakeSlice";
import { AppDispatch } from "../../redux/store";
import {
  editClaimDocument,
  getUploadReportDocumentURL,
  submitReportForm,
} from "../../services/FarmerReport.service";
import { UnitEnum } from "../../types/FeedBrand.type";
import { IGetMyOrderByID } from "../../types/MyOrderFeed.type";
import delay from "../../utils/delay";
import getErrorMessage from "../../utils/getErrorMessage";
import OrderFeedFarmerItemList from "../feed-order-and-delivery/my-order/my-order-component/OrderFeedFarmerItemList";
import ProblemSKU from "./ProblemSKU";
import {
  EnumTopicReport,
  TClaim,
  TEditClaimDocument,
  TReportForm,
  TSubmitClaimForm,
  mapThaiTextToEnum,
} from "./type";
type Props = {
  isOpen: boolean;
  onClose: () => void;
  myOrderList: IGetMyOrderByID;
  selectReportTopic: EnumTopicReport;
  toggleResetForm: boolean;
};

const SelectSKU = ({
  isOpen,
  onClose,
  myOrderList,
  selectReportTopic,
  toggleResetForm,
}: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const maxAmount = myOrderList.result.orderFeedItems[selectedIndex].feedAmount;

  const validationSchema = object({
    claims: array(
      object({
        reportReason: string().required(),
        reportAmount: number()
          .required("กรุณากรอก")
          .min(1, "จำนวนการรายงานปัญหาต้องมากกว่า 0")
          .max(maxAmount, "จำนวนที่รายงานปัญหามากกว่ารายการสินค้า"),
        detail: string(),
        file1: array(),
        file2: array(),
      })
    ),
  });

  const onSubmit = async (values: TReportForm) => {
    const body: TSubmitClaimForm = {
      claimList: values.claims.map((row) => ({
        orderClaimItemId: row.orderClaimItemId,
        orderFeedItemId: row.orderFeedId,
        reportAmount: Number(row.reportAmount),
        reportDetail: row.detail,
        reportOrderReason: mapThaiTextToEnum[row.reportReason],
      })),
    };
    dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
    try {
      const res = await submitReportForm(myOrderList.result.id, body);

      // handle alert error because try catch error 422 is not working
      if (!res?.data.success) {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            type: "error",
            message: "รายงานปัญหาไม่สำเร็จ",
          })
        );
      } else {
        await Promise.allSettled(
          values.claims.map(async (row) => {
            const files = [row.file1[0], row.file2[0]].filter((file) => file);
            if (files.length > 0) {
              const body: TEditClaimDocument = {
                documents: [],
              };
              await Promise.allSettled(
                files.map(async (file) => {
                  const response = await getUploadReportDocumentURL(
                    row.orderClaimItemId
                  );
                  if (response) {
                    const {
                      blob: { blobUrl, fileId, headers },
                    } = response;
                    await axios.put(blobUrl, file, { headers });
                    body.documents.push({
                      fileId,
                      fileName: file.name,
                      fileSize: file.size,
                    });
                  }
                })
              );
              await editClaimDocument(row.orderClaimItemId, body);
            }
          })
        );
        dispatch(
          snakeActions.showMessage({
            type: "success",
            message: "รายงานปัญหาเสร็จสิ้น",
          })
        );
        dispatch(loadingActions.hide());
        await delay(1500);
        navigate("/report/success/" + myOrderList.result.house.farmId);
      }
    } catch (error) {
      dispatch(
        snakeActions.showMessage({
          type: "error",
          message: getErrorMessage(error),
        })
      );
    }
  };

  const handleSelect = (idx: number) => {
    setSelectedIndex(idx);
  };

  const [isOpenProblemSKU, setIsOpenProblemSKU] = useState(false);
  const handleAddNewSKU = () => {
    const selectedOrder = myOrderList.result.orderFeedItems[selectedIndex];
    const claim: TClaim = {
      orderClaimItemId: v4(),
      detail: "",
      file1: [],
      file2: [],
      reportAmount: 0,
      reportReason: selectReportTopic,
      orderFeedId: selectedOrder.id,
      orderDetail: {
        nameTh: selectedOrder.feedBrand.nameTh,
        skuTh: selectedOrder.feedBrandItem.skuTh,
        amount: selectedOrder.feedAmount,
        totalPrice: selectedOrder.totalItemPrice,
        unit: UnitEnum[selectedOrder.feedUnit as keyof typeof UnitEnum],
      },
    };
    const newArray = [...formik.values.claims, claim];
    formik.setFieldValue("claims", newArray);
    setIsOpenProblemSKU(true);
  };
  const formik = useFormik<TReportForm>({
    validationSchema,
    initialValues: { claims: [] },
    onSubmit,
  });

  useEffect(() => {
    formik.resetForm();
  }, [toggleResetForm]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="min-h-screen w-full flex flex-col bg-[white] pb-[24px]">
        <div className="py-4 bg-white">
          <AppBarFS title="Select SKU" onIconPress={onClose} />
        </div>
        <div className="m-3">
          <RadioGroup>
            {myOrderList.result.orderFeedItems.map((order, idx) => (
              <div
                key={idx}
                className="flex items-center gap-2 mb-6 cursor-pointer"
                onClick={() => handleSelect(idx)}
              >
                <RadioButton
                  checked={selectedIndex === idx}
                  onClick={() => handleSelect(idx)} // Keep this if you want individual clicks to also work
                />
                <div className="w-full bg-[#F6FBFF] rounded-2xl px-4 pb-4 cursor-pointer">
                  <OrderFeedFarmerItemList
                    listOrder={order}
                    index={idx + 1}
                    isTotalItemPrice={true}
                  />
                </div>
              </div>
            ))}
          </RadioGroup>
        </div>
        <div className="mx-4">
          <CustomButton
            variant="tertiary"
            className="w-full"
            label={"ถัดไป"}
            onClick={handleAddNewSKU}
          />
        </div>
        <ProblemSKU
          isOpen={isOpenProblemSKU}
          formik={formik}
          onClose={() => {
            setIsOpenProblemSKU(false);
            onClose();
          }}
        />
      </div>
    </Modal>
  );
};

export default SelectSKU;

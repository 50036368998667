function PlusCircleIcon() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 4.75V6.5M6.5 6.5V8.25M6.5 6.5H8.25M6.5 6.5H4.75M11.75 6.5C11.75 9.39949 9.39949 11.75 6.5 11.75C3.6005 11.75 1.25 9.39949 1.25 6.5C1.25 3.6005 3.6005 1.25 6.5 1.25C9.39949 1.25 11.75 3.6005 11.75 6.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PlusCircleIcon;

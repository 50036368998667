import { TDropdownValue } from "../../../../group-area/views/Dropdown";

export type TFormTabDenied = {
  grade: TDropdownValue[];
  statusCredit: TDropdownValue[];
};

export const defaultFormTabDenied: TFormTabDenied = {
  grade: [],
  statusCredit: [],
};

export default function DeleteFillGreen() {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.79998 0.399994C7.34545 0.399994 6.92993 0.656797 6.72666 1.06334L5.85834 2.79999H1.79998C1.13723 2.79999 0.599976 3.33725 0.599976 3.99999C0.599976 4.66274 1.13723 5.19999 1.79998 5.19999L1.79998 17.2C1.79998 18.5255 2.87449 19.6 4.19998 19.6H13.8C15.1255 19.6 16.2 18.5255 16.2 17.2V5.19999C16.8627 5.19999 17.4 4.66274 17.4 3.99999C17.4 3.33725 16.8627 2.79999 16.2 2.79999H12.1416L11.2733 1.06334C11.07 0.656797 10.6545 0.399994 10.2 0.399994H7.79998ZM5.39998 7.59999C5.39998 6.93725 5.93723 6.39999 6.59998 6.39999C7.26272 6.39999 7.79998 6.93725 7.79998 7.59999V14.8C7.79998 15.4627 7.26272 16 6.59998 16C5.93723 16 5.39998 15.4627 5.39998 14.8V7.59999ZM11.4 6.39999C10.7372 6.39999 10.2 6.93725 10.2 7.59999V14.8C10.2 15.4627 10.7372 16 11.4 16C12.0627 16 12.6 15.4627 12.6 14.8V7.59999C12.6 6.93725 12.0627 6.39999 11.4 6.39999Z"
        fill="#68C184"
      />
    </svg>
  );
}

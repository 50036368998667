import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import { tw } from "../../../../utils/tw";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import TextInputSearchCustom from "../../../components/Input/TextInputSearchCustom";
import SelectAdminCustom from "../../../components/Select/Select";
import { TUseFarmDetailFilter } from "../hooks/useFarmDetailFilter.hook";
import { getDropdownGroupArea } from "../services/dropdownGroupArea.service";
import { getDropdownSellName } from "../services/dropdownSale.service";
import { IGroupAreaRow } from "../types/dropdownGroupArea.type";
import { ISaleNameRow } from "../types/dropdownSellName.type";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import { makeXlsx } from "../../../../utils/make-xlsx";
import { postExportDailyLogFarmDetail } from "../services/farmDetail.service";

const classes = {
  flexRow: tw(`flex flex-row`),
  flexCol: tw(`flex flex-col`),
  flex: tw(`flex`),
  fullScreen: tw(`w-full h-full`),
  label: tw(`text-sm font-medium font-sans`),
};

export type TDropdownFarmSize = "0_50" | "51_500" | "501_1000" | "1000UP" | "";

interface IFarmDetailFilter {
  searchFarmDetail: TUseFarmDetailFilter;
  itemSelected: string[];
  onExport: () => void;
  onSearch: () => void;
}

export function FarmDetailFilter({
  searchFarmDetail,
  itemSelected,
  onExport,
  onSearch,
}: IFarmDetailFilter) {
  const [toggleClear, setToggleClear] = useState(false);
  const [groupAreaRow, setGroupAreaRow] = useState<IGroupAreaRow[]>();
  const [saleNameRow, setSaleNameRow] = useState<ISaleNameRow[]>();

  const segmentOption = ["Potential", "Grower", "Independent"];
  const farmSizeOption: { value: TDropdownFarmSize; label: string }[] = [
    {
      value: "0_50",
      label: "0 - 50",
    },
    {
      value: "51_500",
      label: "51 - 500",
    },
    {
      value: "501_1000",
      label: "501 - 1,000",
    },
    {
      value: "1000UP",
      label: "1,000 up",
    },
  ];

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchFarmDetail.setPage(1);
    onSearch();
  };

  const fetchDataDropdownGroupArea = async () => {
    const groupArea = await getDropdownGroupArea();
    setGroupAreaRow(groupArea?.rows);
  };

  const fetchDataDropdownSellName = async () => {
    const sellName = await getDropdownSellName();
    setSaleNameRow(sellName?.rows);
  };

  useEffect(() => {
    onSearch();
  }, [toggleClear]);

  useEffect(() => {
    fetchDataDropdownGroupArea();
    fetchDataDropdownSellName();
  }, []);

  const handleChangeGroupArea = (e: SelectChangeEvent<unknown>) => {
    searchFarmDetail.setGroupArea(e.target.value as string);
  };
  const handleChangeSaleName = (e: SelectChangeEvent<unknown>) => {
    searchFarmDetail.setSaleName(e.target.value as string);
  };

  const [selectExport, setSelectExport] = useState("");

  const exportDailyLog = async (e: SelectChangeEvent<unknown>) => {
    const selected = e.target.value as string;
    const data = await postExportDailyLogFarmDetail(selected, itemSelected);
    const dataExcel = data.rows;
    const fileName = `${selected}_daily_log_${dayjs().format(
      "YYYYMMDDHHmmss"
    )}`;
    const sheetName = "list";
    makeXlsx(dataExcel, fileName, sheetName);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={classes.fullScreen}>
          <div className={classes.flexRow}>
            <div className="w-[25%]">
              <span className={classes.label}>Group Area</span>
              <div className="pt-2">
                <SelectAdminCustom
                  value={searchFarmDetail.groupArea}
                  placeholder="เลือก"
                  menuItems={(groupAreaRow || []).map((row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                  renderValueArray={(groupAreaRow || []).map((row) => ({
                    value: row.id,
                    label: row.name,
                  }))}
                  endAdornment={
                    searchFarmDetail.groupArea !== "" ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "16px !important",
                          marginRight: "20px",
                        }}
                        onClick={(_) => {
                          searchFarmDetail.setGroupArea("");
                          setToggleClear((prev) => !prev);
                        }}
                      />
                    ) : null
                  }
                  onChange={(e) => handleChangeGroupArea(e)}
                />
              </div>
            </div>
            <div className="w-[25%] px-2">
              <span className={classes.label}>ชื่อพนักงานขาย</span>
              <div className="pt-2">
                <SelectAdminCustom
                  value={searchFarmDetail.saleName}
                  placeholder="เลือก"
                  menuItems={(saleNameRow || []).map((row) => (
                    <MenuItem key={row.id} value={row.name}>
                      {row.name}
                    </MenuItem>
                  ))}
                  renderValueArray={(saleNameRow || []).map((row) => ({
                    value: row.id,
                    label: row.name,
                  }))}
                  endAdornment={
                    searchFarmDetail.saleName !== "" ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "16px !important",
                          marginRight: "20px",
                        }}
                        onClick={(_) => {
                          searchFarmDetail.setSaleName("");
                          setToggleClear((prev) => !prev);
                        }}
                      />
                    ) : null
                  }
                  onChange={(e) => handleChangeSaleName(e)}
                />
              </div>
            </div>
            <div className="w-[25%] px-2">
              <span className={classes.label}>Segment</span>
              <div className="pt-2">
                <SelectAdminCustom
                  value={searchFarmDetail.segment}
                  placeholder="เลือก"
                  menuItems={segmentOption.map((row) => (
                    <MenuItem key={row} value={row}>
                      {row}
                    </MenuItem>
                  ))}
                  renderValueArray={segmentOption.map((row) => ({
                    value: row,
                    label: row,
                  }))}
                  endAdornment={
                    searchFarmDetail.segment !== "" ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "16px !important",
                          marginRight: "20px",
                        }}
                        onClick={(_) => {
                          searchFarmDetail.setSegment("");
                          setToggleClear((prev) => !prev);
                        }}
                      />
                    ) : null
                  }
                  onChange={(e) =>
                    searchFarmDetail.setSegment(e.target.value as string)
                  }
                />
              </div>
            </div>
            <div className="w-[25%] pr-2">
              <span className={classes.label}>ขนาดฟาร์ม</span>
              <div className="pt-2">
                <SelectAdminCustom
                  placeholder="เลือก"
                  onChange={(e) => {
                    searchFarmDetail.setFarmSize(
                      e.target.value as TDropdownFarmSize
                    );
                  }}
                  value={searchFarmDetail.farmSize}
                  menuItems={farmSizeOption.map((row) => (
                    <MenuItem key={row.value} value={row.value}>
                      {row.label}
                    </MenuItem>
                  ))}
                  renderValueArray={farmSizeOption.map((row) => ({
                    value: row.value,
                    label: row.label,
                  }))}
                  endAdornment={
                    searchFarmDetail.farmSize !== "" ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "16px !important",
                          marginRight: "20px",
                        }}
                        onClick={(_) => {
                          searchFarmDetail.setFarmSize("");
                          setToggleClear((prev) => !prev);
                        }}
                      />
                    ) : null
                  }
                />
              </div>
            </div>
            <div className="h-full self-end">
              <ButtonFillCustom type="submit" title="ค้นหา" />
            </div>
          </div>
          <div className={tw(classes.flexRow, "w-full justify-between pt-6")}>
            <div className="flex w-full max-w-[656px]">
              <div className="w-full max-w-[470px] mr-4">
                <TextInputSearchCustom
                  value={searchFarmDetail.searchText}
                  placeholder="ค้นหาโดยรหัสลูกค้า, ชื่อลูกค้า"
                  onChange={(event) =>
                    searchFarmDetail.setSearchText(event.target.value)
                  }
                  InputProps={{
                    endAdornment:
                      searchFarmDetail.searchText.length > 0 ? (
                        <CloseRoundedIcon
                          sx={{
                            cursor: "pointer",
                            fontSize: "18px !important",
                          }}
                          onClick={(_) => {
                            searchFarmDetail.setSearchText("");
                            setToggleClear((prev) => !prev);
                          }}
                        />
                      ) : null,
                  }}
                />
              </div>
              {itemSelected.length > 0 && (
                <div className="w-full max-w-[170px] flex items-center font-sans text-[14px] text-[#646464]">{`เลือกไว้ ${itemSelected.length} รายการ`}</div>
              )}
            </div>

            <FormControl variant="standard">
              <Select
                labelId="custom-select-label"
                id="custom-select"
                value={selectExport}
                onChange={exportDailyLog}
                disabled={itemSelected.length === 0}
                IconComponent={ExpandMoreIcon}
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    return <span style={{ color: "#888888" }}>นำออก</span>;
                  }
                  return selected;
                }}
                sx={{
                  "& .MuiSelect-select": {
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#888888",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottom: "1px solid #888888",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#888888",
                  },
                  "& .MuiSelect-select:focus": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <MenuItem value="farmer">ผู้ใช้ทั้งหมด</MenuItem>
                <MenuItem value="performance">Performance</MenuItem>
                <MenuItem value="credit">เครดิต</MenuItem>
                <MenuItem value="farm">ฟาร์ม</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </form>
    </>
  );
}

import React, { memo } from 'react'
import { BaseModal } from '../../feed-order-and-delivery/order-feed/OrderFeedBaseModal'
import { tw } from '../../../utils/tw'

interface IDialogProps {
  isOpen: boolean
  onClose: () => void
  withoutCloseIcon?: boolean
  title: string
  text: string
  titleClassName?: string
  textClassName?: string
  confirmText: string
  confirmClassName?: string
  onConfirm: () => void
  cancelText?: string
  cancelClassName?: string
  onCancel?: () => void
  actionContainerClassName?: string
}

const classes = {
  container: tw(`flex flex-col min-h-[280px]`),
  title: tw(`text-warning text-[24px] leading-[28.8px] text-center font-medium mb-[16px] whitespace-pre-line`),
  text: tw(`text-[16px] leading-[20.8px] text-[#646464] text-center text-balance whitespace-pre-line`),
  confirmButton: tw(`w-full p-[20px] flex justify-center items-center text-center text-[white] bg-secondary border-[1px] border-secondary rounded-full text-[16px] leading-[19.2px] font-bold hover:brightness-110 transition-all duration-150`),
}

const Dialog = (props: IDialogProps) => {
  const {
    isOpen,
    withoutCloseIcon = false,
    onClose,
    title,
    text,
    titleClassName,
    textClassName,
    confirmText,
    confirmClassName,
    onConfirm,
    cancelText,
    cancelClassName,
    onCancel,
    actionContainerClassName,
  } = props

  return (
    <BaseModal
      isOpen={isOpen}
      handleClose={onClose}
      iconCloseModal={!withoutCloseIcon}
      withoutOutSideClose
      containerClassName="max-w-[450px] mx-auto"
    >
      <div className={classes.container}>
        <div className={tw(classes.title, titleClassName)}>{title}</div>
        <div className={tw(classes.text, textClassName)}>{text}</div>
        <div className="flex flex-1" />
        {!cancelText ? (
          <button onClick={onConfirm} className={tw(classes.confirmButton, confirmClassName)}>
            {confirmText}
          </button>
        ) : (
            <div className={tw("flex flex-row w-full p-[20px] items-center justify-between max-w-[450px] gap-x-[24px]", actionContainerClassName)}>
            <button onClick={onCancel} className={tw(classes.confirmButton, 'bg-[white] text-secondary border-[1px] border-secondary', cancelClassName)}>
              {cancelText}
            </button>
            <button onClick={onConfirm} className={tw(classes.confirmButton, confirmClassName)}>
              {confirmText}
            </button>
          </div>
        )}
      </div>
    </BaseModal>
  )
}

export default memo(Dialog)

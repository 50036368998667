import { memo, useMemo, useEffect, useCallback, useState } from "react";
import { Modal } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { IOrderItemProps } from "../../../views/OrderItem";
import { tw } from "../../../../../utils/tw";
import AppBarFS from "../../../../../components/app-bar/AppBar";
import { useActionController } from "../../../actionController";
import { getDownloadUrlOrderPartner } from "../../../../../services/PartnerOrderService";
import axios from "axios";
import ZoomImageSvg from "../../../../../assets/svg/ZoomImage";
import "swiper/css";
import "swiper/css/pagination";
import get from "lodash/get";
import { getStatusFromPartnerStatus } from "../../dto/dtoOrderList";
import { statusWord } from "../../../constants";
import PreviewAndZoomImageCarouselModal from "../../../../../components/common/ViewImageAndPreviewCarousel/PreviewAndZoomImageCarouselModal";
import PdfRenderer from "../../../../../admin/components/Modal/DocumentModal/ModalPanel/PdfRenderer";
import { isEmpty } from "lodash";

type TImage = { id: string; url: string };

interface ISummaryModalPdfProps {
  isOpen: boolean;
  onClose: () => void;
  orderDetail?: IOrderItemProps;
}

const classes = {
  wrapper: tw(`overflow-scroll`),
  container: tw(`min-h-screen w-full flex flex-col bg-[white] pb-[24px]`),
  appbarContainer: tw(`py-4`),
  headerContainer: tw(
    `mb-[16px] px-[16.5px] text-[18px] leading-[21.6px] font-semibold flex flex-row items-center justify-between`
  ),
  label: tw(
    `border-[1px] border-warning rounded-full font-normal flex justify-center items-center text-[14px] leading-[19.6px] text-warning bg-[#FEF9F2] py-[2px] px-[8px]`
  ),
  swiperWrapper: tw(`[&_.swiper-wrapper]:items-center`),
  swiperContainer: tw(`px-[45px] relative pb-[24px]`),
  swiperImage: tw(`w-full h-auto object-contain max-w-[450px] m-auto`),
  danger: tw(
    `text-status-bad font-semibold text-[14px] leading-[21px] whitespace-pre-line text-center`
  ),
  actionContainer: tw(
    `flex flex-col gap-[16px] mt-[16px] max-w-[450px] px-[16px] w-full mx-auto`
  ),
  button: tw(
    `bg-secondary border-[1px] border-secondary flex justify-center items-center text-center text-[white] text-[16px] leading-[25.6px] font-bold w-full py-[16.5px] rounded-full hover:brightness-105 duration-150 transition-all`
  ),
};

const SummaryModalPdf = (props: ISummaryModalPdfProps) => {
  const { isOpen, onClose, orderDetail } = props;
  const [docs, setDocs] = useState<{ url: string; fileName: string }[]>([]);

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [slideIndex, setSlideIndex] = useState<number>(0);

  const createDocs = async () => {
    if (orderDetail && orderDetail?.images) {
      const docsData = [];
      for (let i = 0; i < orderDetail.images?.length; i++) {
        const img = orderDetail.images[i];
        if (img.documentType === "partnerPickingSlipForSale") {
          let urlId: any = img.url.split("/") as string[];
          urlId = urlId[urlId.length - 1];
          const preSignURL = await getDownloadUrlOrderPartner(
            orderDetail?.orderNo,
            img.documentType,
            urlId
          );
          if (preSignURL) {
            const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
              responseType: "blob",
            });
            const objectURL = window.URL.createObjectURL(response.data);
            docsData.push({
              url: objectURL,
              fileName: img.fileName,
            });
          }
        }
      }
      setDocs(docsData);
    }
  };

  const status = useMemo(() => {
    if (orderDetail && orderDetail.partnerStatus) {
      return getStatusFromPartnerStatus(orderDetail.partnerStatus);
    }
    return "";
  }, [orderDetail]);

  useEffect(() => {
    createDocs();
  }, [orderDetail]);

  return (
    <Modal open={isOpen} className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.appbarContainer}>
          <AppBarFS title="หลักฐานการจัดส่ง" onIconPress={onClose} />
        </div>
        <div className={classes.headerContainer}>
          หมายเลขสั่งซื้อ {orderDetail?.orderNo}
          <div
            className={tw(
              classes.label,
              status === "canceled" &&
                "text-status-bad border-status-bad bg-[#F9E0E0] ",
              status === "received" &&
                "text-[#409261] border-[#409261] bg-[#E9FFEF]"
            )}
          >
            {get(statusWord, status, "")}
          </div>
        </div>
        <Swiper
          pagination
          modules={[Pagination]}
          loop
          className={classes.swiperWrapper}
          initialSlide={slideIndex}
          onActiveIndexChange={(event) => {
            setSlideIndex(event.realIndex);
          }}
        >
          {docs.length > 0 ? (
            docs.map((doc, index) => {
              const isPdf = doc.fileName.indexOf(".pdf") !== -1;
              return (
                <SwiperSlide
                  key={`${index}`}
                  className={classes.swiperContainer}
                  onClick={() => setIsOpenModal(true)}
                >
                  {!isPdf ? (
                    <>
                      <div className="absolute top-[45%] bottom-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2">
                        <div className="w-8 h-8">
                          <ZoomImageSvg />
                        </div>
                      </div>
                      <img src={doc.url} className={classes.swiperImage} />
                    </>
                  ) : (
                    <PdfRenderer
                      url={doc.url}
                      className={tw(
                        classes.swiperImage,
                        "min-h-[420px] border-[1px] border-[#555]"
                      )}
                    />
                  )}
                </SwiperSlide>
              );
            })
          ) : (
            <div className="text-center font-normal text-[14px]">
              ยังไม่มีหลักฐานการจัดส่งของเซลล์
            </div>
          )}
        </Swiper>
        <div className="flex flex-1" />
        <div className={classes.danger}>
          {`หากไม่ได้กดบันทึกเอกสารการขาย\nคุณสามารถกลับมาดูย้อนหลังได้ที่เมนูประวัติคำสั่งซื้อ`}
        </div>
        <div className={classes.actionContainer}>
          <button
            onClick={() => {
              if (docs.length > 0) {
                window.open(docs[0].url, "_blank");
              }
            }}
            className={tw(
              classes.button,
              docs.length === 0 &&
                "bg-[#ccc] border-[#ccc] hover:brightness-100"
            )}
            disabled={docs.length === 0}
          >
            บันทึกหลักฐานการจัดส่ง
          </button>
          <button
            onClick={onClose}
            className={tw(classes.button, "bg-[white] text-secondary")}
          >
            ปิด
          </button>
        </div>
        <PreviewAndZoomImageCarouselModal
          isOpen={isOpenModal}
          initIndex={slideIndex}
          onClose={() => {
            setIsOpenModal(false);
          }}
          docs={docs}
        />
      </div>
    </Modal>
  );
};

export default memo(SummaryModalPdf);

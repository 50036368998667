import React from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../components/common/Button";
import farmRegistration from "../../assets/images/FarmRegistration/farmRegistration.png";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router";
import PopupImage from "../../assets/images/FarmRegistration/popup_bg.png";

export interface PopupType {
  onCancel: () => void;
  onClick: () => void;
}
//TODO: Remove below line any once you use this component as a popup template
const RegisterFarmPopup = ({ onClick, onCancel }: PopupType | any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div
      className="flex bg-bottom bg-no-repeat"
      style={{ backgroundImage: `url(${PopupImage})` }}
    >
      <div className="p-3 h-full flex flex-col justify-between w-full">
        <div className="flex flex-col items-end">
          <ClearIcon
            className="w-[30px] h-[30px] mr-2 mt-2"
            onClick={onCancel}
          />
        </div>
        <div className="flex flex-col grow mt-4 ">
          <img
            src={farmRegistration}
            className="mb-[20px] w-[100%] max-w-[400px]"
          />
          <div className="flex flex-col items-center">
            <h3 className="font-medium text-[30px] text-justify text-[#68C184]">
              {t("registerFarm.main_title")}
            </h3>
            <h4 className="p-2 text-center text-[20] text-[#646464] font-normal">
              {t("registerFarm.sub_title")}
            </h4>
            <div className="p-4 flex flex-col w-full">
              <h4 className="ml-2 text-left text-[18] text-[#68C184] font-semibold ">
                {t("registerFarm.description_title")}
              </h4>
              <div className="ml-5 flex flex-col items-start leading-[1.7]">
                <p className="text-center text-[18] text-[#646464] font-normal">
                  ● {t("registerFarm.description_point_one")}
                </p>
                <p className="text-center text-[18] text-[#646464] font-normal">
                  ● {t("registerFarm.description_point_two")}
                </p>
                <p className="text-center text-[18] text-[#646464] font-normal">
                  ● {t("registerFarm.description_point_three")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-5 flex items-center justify-center">
          <CustomButton
            variant="tertiary"
            className="!w-[255px]"
            label={t("registerFarm.registerFarmNow")}
            onClick={() => navigate("/add-farm")} // use your onclick here
          />
        </div>
      </div>
    </div>
  );
};

export default RegisterFarmPopup;

export default function DizzyFaceStatus() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 -0.5 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00007 16.7254C4.89445 16.7254 0.465088 14.1501 0.465088 8.4994C0.465088 2.84865 4.89445 0.273438 9.00007 0.273438C11.281 0.273438 13.3853 1.02393 14.9451 2.39247C16.6374 3.89345 17.5351 6.01248 17.5351 8.4994C17.5351 10.9863 16.6374 13.0906 14.9451 14.5916C13.3853 15.9601 11.2663 16.7254 9.00007 16.7254Z"
        fill="#D32F2F"
      />
      <path
        d="M16.0474 3.60938C16.8318 4.87491 17.2409 6.38177 17.2409 8.05787C17.2409 10.5448 16.3432 12.6491 14.6509 14.1501C13.0911 15.5186 10.9721 16.2838 8.70589 16.2838C6.04827 16.2838 3.26116 15.2022 1.61597 12.9081C3.19935 15.5068 6.17777 16.7253 9.0002 16.7253C11.2664 16.7253 13.3854 15.9601 14.9453 14.5915C16.6375 13.0906 17.5352 10.9863 17.5352 8.49933C17.5352 6.62458 17.0246 4.95879 16.0474 3.60938Z"
        fill="#D32F2F"
      />
      <path
        d="M11.7903 12.9027C11.7903 14.0182 10.5674 14.6686 9.05908 14.6686C7.55074 14.6686 6.32788 14.0137 6.32788 12.9027C6.32788 11.7917 7.55074 10.5703 9.05908 10.5703C10.5674 10.5703 11.7903 11.7873 11.7903 12.9027Z"
        fill="#323232"
      />
      <path
        d="M6.45006 7.5718L7.69499 6.41369C7.87158 6.19443 7.89807 5.89865 7.72001 5.72206C7.48603 5.48662 7.07106 5.54401 6.84591 5.81183C6.83414 5.8339 6.82531 5.83979 6.81354 5.86186L5.93061 7.14505L4.73571 6.03109C4.71805 6.01196 4.70775 6.00902 4.69156 5.98989C4.41344 5.77798 3.99552 5.8133 3.8204 6.09437C3.68355 6.30627 3.77479 6.58734 3.99552 6.76392L5.5274 7.67187L4.2295 8.8859C4.05585 9.10221 4.02348 9.39652 4.20301 9.57752C4.43551 9.81444 4.85049 9.76 5.07711 9.49365C5.08888 9.47157 5.09771 9.46569 5.11095 9.44361L6.04097 8.09862L7.17112 9.16402C7.18878 9.18315 7.19908 9.18756 7.21527 9.20669C7.49192 9.42007 7.90984 9.38769 8.0879 9.1081C8.22475 8.8962 8.13499 8.61366 7.91573 8.43707L6.45006 7.5718Z"
        fill="#323232"
      />
      <path
        d="M12.1125 7.5718L10.8676 6.41369C10.691 6.19443 10.6645 5.89865 10.844 5.72206C11.078 5.48662 11.4915 5.54401 11.7167 5.81183C11.7284 5.8339 11.7387 5.83979 11.7505 5.86186L12.6334 7.14505L13.8298 6.03109C13.846 6.01196 13.8563 6.00902 13.8725 5.98989C14.1521 5.77798 14.5685 5.8133 14.7451 6.09437C14.8805 6.30627 14.7892 6.58734 14.5685 6.76392L13.0322 7.67187L14.3301 8.8859C14.5082 9.10516 14.5376 9.39947 14.3581 9.57752C14.1271 9.81444 13.7121 9.76 13.4855 9.49365C13.4722 9.47157 13.4634 9.46569 13.4516 9.44361L12.5216 8.09862L11.39 9.16402C11.3738 9.18315 11.3635 9.18756 11.3458 9.20669C11.0692 9.42007 10.6527 9.38769 10.4747 9.1081C10.3363 8.8962 10.4261 8.61366 10.6454 8.43707L12.1125 7.5718Z"
        fill="#323232"
      />
      <path
        d="M6.35134 3.49345C6.68979 3.52288 6.82223 3.0667 6.46906 2.96369C6.19094 2.88865 5.90251 2.85921 5.61556 2.8754C4.83711 2.90483 4.09987 3.23152 3.55539 3.78776C3.30523 4.05264 3.6584 4.37638 3.92328 4.18508C4.63257 3.68917 5.48754 3.44489 6.35134 3.49345Z"
        fill="#323232"
      />
      <path
        d="M11.7372 3.49345C12.6054 3.44342 13.4662 3.68622 14.1799 4.18508C14.4448 4.37638 14.798 4.05264 14.5478 3.78776C14.0019 3.23446 13.2646 2.90778 12.4876 2.8754C12.2007 2.85921 11.9123 2.88865 11.6341 2.96369C11.4899 3.0049 11.4061 3.15352 11.4473 3.29774C11.4826 3.42576 11.6062 3.50817 11.7372 3.49345Z"
        fill="#323232"
      />
    </svg>
  );
}

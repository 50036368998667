import { IGetNotification } from "../types/Notification.type";
import api from "./ApiService";

export const getNotificationFarmer = async () => {
  const { data } = await api.get<IGetNotification>(`/farmers/notification`);
  return data;
};

export const markReadNotification = async (body: {
  notificationId: string;
}) => {
  const { data } = await api.put(
    `/farmers/notification/read/${body.notificationId}`
  );
  return data;
};

export const markReadNotificationAll = async () => {
  const { data } = await api.put(`/farmers/notification/read-all`);
  return data;
};

export const removeNotification = async (body: { notificationId: string }) => {
  const { data } = await api.delete(
    `/farmers/notification/${body.notificationId}`
  );
  return data;
};

//////////////// Partner ////////////////

export const getNotificationPartner = async () => {
  const { data } = await api.get<IGetNotification>(`/partner/notification`);
  return data;
};

export const markReadNotificationPartner = async (body: {
  notificationId: string;
}) => {
  const { data } = await api.put(
    `/partner/notification/read/${body.notificationId}`
  );
  return data;
};

export const markReadNotificationAllPartner = async () => {
  const { data } = await api.put(`/partner/notification/read-all`);
  return data;
};

export const removeNotificationPartner = async (body: {
  notificationId: string;
}) => {
  const { data } = await api.delete(
    `/partner/notification/${body.notificationId}`
  );
  return data;
};

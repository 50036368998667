export function FarmSookSVG({ sizeMultiplier = 1 }) {
  return (
    <svg
      width={28 * sizeMultiplier}
      height={6.45 * sizeMultiplier}
      viewBox="0 0 129 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.76428 10.9105V13.2425H5.92291C6.65664 13.2425 7.25386 13.8378 7.25386 14.5734C7.25386 15.3072 6.65853 15.9044 5.92291 15.9044H2.76428V18.9606C2.76428 19.7228 2.1462 20.3428 1.38214 20.3428C0.619971 20.3428 0 19.7247 0 18.9606V9.76724C0 8.92923 0.678746 8.25049 1.51675 8.25049H6.01012C6.74385 8.25049 7.34107 8.84581 7.34107 9.58144C7.34107 10.3152 6.74574 10.9124 6.01012 10.9124H2.76428V10.9105Z"
        fill="#35407B"
      />
      <path
        d="M17.7912 18.4411H13.3016L13.0115 19.3512C12.8238 19.9408 12.2759 20.3409 11.6578 20.3409C10.6852 20.3409 9.99885 19.3834 10.3136 18.462L13.3774 9.44872C13.622 8.73206 14.2932 8.25049 15.0515 8.25049H15.9445C16.7598 8.25049 17.484 8.76998 17.7475 9.54162L20.781 18.462C21.0939 19.3834 20.4094 20.3409 19.4368 20.3409C18.8188 20.3409 18.2708 19.9408 18.0831 19.3512L17.7931 18.4411H17.7912ZM16.9626 15.8513L15.5464 11.4129L14.1301 15.8513H16.9626Z"
        fill="#35407B"
      />
      <path
        d="M28.2599 16.3685H26.9479V18.9583C26.9479 19.7205 26.3298 20.3405 25.5657 20.3405C24.8036 20.3405 24.1836 19.7224 24.1836 18.9583V9.78008C24.1836 8.93639 24.868 8.25195 25.7117 8.25195H28.7073C30.2487 8.25195 31.7351 8.99326 32.5447 10.3053C33.9003 12.497 32.8784 14.9181 30.935 15.904L32.3911 18.4161C32.8878 19.2711 32.2698 20.3423 31.2801 20.3423C30.8213 20.3423 30.3966 20.0959 30.1672 19.6977L28.258 16.3704L28.2599 16.3685ZM26.9479 13.9492H29.0201C29.7804 13.9492 30.4023 13.2762 30.4023 12.3946C30.4023 11.513 29.7804 10.8418 29.0201 10.8418H26.9479V13.9492Z"
        fill="#35407B"
      />
      <path
        d="M47.2453 20.3409C46.4831 20.3409 45.8632 19.7228 45.8632 18.9587V13.3107L42.7368 18.4393H42.4258L39.2994 13.3107V18.9587C39.2994 19.7209 38.6814 20.3409 37.9173 20.3409C37.1551 20.3409 36.5352 19.7228 36.5352 18.9587V9.81653C36.5352 8.95198 37.2367 8.25049 38.1012 8.25049C38.8444 8.25049 39.5345 8.63726 39.9232 9.2724L42.5794 13.6217L45.3266 9.12452C45.6584 8.58038 46.2499 8.25049 46.8851 8.25049C47.8444 8.25049 48.6237 9.02782 48.6237 9.98906V18.9587C48.6237 19.7209 48.0056 20.3409 47.2415 20.3409H47.2453Z"
        fill="#35407B"
      />
      <path
        d="M53.0561 16.8799C53.6457 16.5387 54.3586 16.6998 54.8402 17.1814C55.2838 17.625 55.8943 17.8886 56.7475 17.8886C58.0614 17.8886 58.3875 17.371 58.3875 16.9046C58.3875 16.1614 57.6974 15.8675 55.883 15.367C54.0875 14.8665 52.3262 14.0038 52.3262 11.6889C52.3262 9.37392 54.2961 8.01074 56.3854 8.01074C57.849 8.01074 59.0776 8.52644 59.9763 9.51043C60.4996 10.083 60.3725 10.9949 59.7033 11.3855L59.4454 11.5353C58.943 11.8292 58.3325 11.6945 57.923 11.2812C57.5571 10.9134 57.0755 10.7049 56.3854 10.7049C55.5208 10.7049 55.0905 11.1371 55.0905 11.6206C55.0905 12.1742 55.3843 12.5534 57.267 13.1241C59.0966 13.6758 61.1537 14.3147 61.1537 16.8724C61.1537 19.2044 59.2881 20.5865 56.6641 20.5865C54.8629 20.5865 53.5225 19.9779 52.6542 18.9901C52.0854 18.3417 52.311 17.3179 53.058 16.8856L53.0561 16.8799Z"
        fill="#35407B"
      />
      <path
        d="M126.394 19.7816L122.911 14.746V18.9606C122.911 19.7228 122.293 20.3428 121.529 20.3428C120.766 20.3428 120.146 19.7247 120.146 18.9606V9.63263C120.146 8.87046 120.765 8.25049 121.529 8.25049C122.291 8.25049 122.911 8.86856 122.911 9.63263V13.5022L126.221 8.80221C126.464 8.45715 126.858 8.25238 127.281 8.25238C128.326 8.25238 128.94 9.42597 128.346 10.2848L125.677 14.1412L128.534 18.3103C129.124 19.1711 128.508 20.3428 127.463 20.3428C127.036 20.3428 126.638 20.1342 126.395 19.7835L126.394 19.7816Z"
        fill="#35407B"
      />
      <path
        d="M95.6641 13.1255H96.2158L96.5172 14.5968L96.8642 13.4895V13.1255H97.2642L97.6719 14.5968L98.0738 12.4468H98.6217L97.9771 15.4158H97.4804L97.1031 14.1057L96.6955 15.4158H96.1987L95.6641 13.1255Z"
        fill="#404041"
      />
      <path
        d="M99.6762 14.0078C99.6762 13.8428 99.644 13.7234 99.5814 13.6457C99.517 13.5679 99.4013 13.5281 99.2307 13.5281C99.1397 13.5281 99.043 13.5376 98.9387 13.5585C98.8344 13.5774 98.7396 13.6059 98.6543 13.64V13.2191C98.7415 13.1793 98.8477 13.147 98.9728 13.1224C99.098 13.0977 99.2193 13.0845 99.3425 13.0845C99.661 13.0845 99.8924 13.1527 100.036 13.2873C100.179 13.4238 100.251 13.6362 100.251 13.9282V15.4165H99.6781V14.0078H99.6762Z"
        fill="#404041"
      />
      <path
        d="M101.066 15.423C100.92 15.4002 100.805 15.3718 100.715 15.3339V14.8713C100.818 14.913 100.933 14.9471 101.064 14.9736C101.193 14.9983 101.313 15.0116 101.421 15.0116C101.612 15.0116 101.749 14.9964 101.828 14.966C101.908 14.9357 101.948 14.8788 101.948 14.7935C101.948 14.7404 101.933 14.6949 101.902 14.6608C101.872 14.6267 101.83 14.5982 101.775 14.5736C101.72 14.5489 101.633 14.5186 101.514 14.4788L101.332 14.42C101.102 14.3404 100.935 14.2551 100.829 14.1584C100.723 14.0636 100.67 13.9309 100.67 13.7621C100.67 13.5536 100.751 13.3867 100.915 13.2635C101.078 13.1403 101.315 13.0796 101.625 13.0796C101.753 13.0796 101.883 13.0891 102.018 13.1099C102.153 13.1289 102.263 13.1535 102.35 13.182V13.6408C102.263 13.6029 102.158 13.5763 102.037 13.5555C101.917 13.5365 101.806 13.5251 101.705 13.5251C101.553 13.5251 101.432 13.5422 101.345 13.5744C101.258 13.6067 101.214 13.6654 101.214 13.7508C101.214 13.8152 101.244 13.8664 101.303 13.8986C101.362 13.9328 101.472 13.9745 101.633 14.0257C101.684 14.0408 101.749 14.0617 101.828 14.0901C101.993 14.147 102.122 14.2039 102.217 14.2646C102.312 14.3233 102.38 14.3954 102.426 14.4788C102.469 14.5622 102.492 14.6684 102.492 14.7954C102.492 15.0134 102.407 15.1784 102.24 15.2903C102.071 15.4021 101.834 15.4571 101.529 15.4571C101.368 15.4571 101.214 15.4457 101.068 15.423H101.066Z"
        fill="#404041"
      />
      <path
        d="M101.314 12.1445H102.613L102.596 12.5351H101.783V12.81H101.316V12.1445H101.314Z"
        fill="#404041"
      />
      <path
        d="M103.44 15.1906L103.416 15.4162H102.889V13.1259H103.461V14.7754C103.524 14.8436 103.605 14.9005 103.71 14.9441C103.814 14.9896 103.916 15.0124 104.022 15.0124C104.161 15.0124 104.265 14.9782 104.331 14.91C104.398 14.8417 104.434 14.7318 104.434 14.5801V13.124H105.006V14.6123C105.006 14.8759 104.936 15.0825 104.794 15.2323C104.652 15.3821 104.459 15.4579 104.212 15.4579C103.918 15.4579 103.66 15.3688 103.44 15.1906Z"
        fill="#404041"
      />
      <path
        d="M105.657 15.2857C105.511 15.17 105.438 14.9861 105.438 14.7377C105.438 14.5083 105.515 14.332 105.671 14.2088C105.826 14.0855 106.05 14.0249 106.34 14.0249H106.99V13.9149C106.99 13.7727 106.95 13.6741 106.871 13.6153C106.791 13.5566 106.653 13.5281 106.452 13.5281C106.186 13.5281 105.932 13.566 105.692 13.6438V13.2153C105.813 13.1755 105.951 13.1432 106.103 13.1205C106.257 13.0958 106.403 13.0845 106.545 13.0845C106.704 13.0845 106.839 13.0996 106.952 13.1262H107.749L107.72 13.5622H107.46C107.529 13.6665 107.563 13.8049 107.563 13.9737V15.4165H106.99V14.4458H106.374C106.243 14.4458 106.147 14.4685 106.08 14.514C106.014 14.5595 105.98 14.6335 105.98 14.7396C105.98 14.8458 106.01 14.9159 106.071 14.9558C106.131 14.9956 106.236 15.0145 106.387 15.0145C106.518 15.0145 106.615 15.0032 106.679 14.9804V15.3994C106.552 15.4392 106.404 15.4582 106.234 15.4582C105.997 15.4582 105.803 15.3994 105.657 15.2838V15.2857Z"
        fill="#404041"
      />
      <path
        d="M107.156 15.9973H106.902L106.923 15.6313H107.64V16.4883H107.156V15.9973Z"
        fill="#404041"
      />
      <path
        d="M108.36 15.2242C108.183 15.0687 108.096 14.8507 108.096 14.5701V14.426C108.096 14.3236 108.117 14.2345 108.159 14.1568C108.2 14.079 108.255 13.9937 108.326 13.9008C108.371 13.842 108.403 13.7946 108.426 13.7586C108.447 13.7226 108.458 13.6885 108.458 13.6543C108.458 13.6126 108.449 13.5842 108.428 13.569C108.407 13.5539 108.373 13.5482 108.322 13.5482H108.047L108.094 13.1235H108.688C108.923 13.1235 109.04 13.2315 109.04 13.4496C109.04 13.5349 109.025 13.6107 108.993 13.6809C108.963 13.751 108.917 13.8345 108.858 13.933C108.796 14.0259 108.75 14.1094 108.718 14.1814C108.686 14.2535 108.671 14.3331 108.671 14.4203V14.6023C108.671 14.735 108.709 14.8374 108.786 14.9057C108.864 14.9758 108.978 15.0099 109.128 15.0099C109.438 15.0099 109.594 14.8677 109.594 14.5852V13.1216H110.167V14.5606C110.167 14.8374 110.074 15.0554 109.89 15.2166C109.704 15.3759 109.45 15.4555 109.128 15.4555C108.805 15.4555 108.534 15.3777 108.36 15.2223V15.2242Z"
        fill="#404041"
      />
      <path
        d="M78.8472 27.5459C77.666 27.5686 76.6157 26.7193 76.4204 25.5191C76.2062 24.2015 77.0992 22.9596 78.4168 22.7435C78.4566 22.7378 78.5476 22.7226 78.6917 22.7131C80.1023 22.607 84.9332 21.678 87.8491 14.1378C92.3558 2.48159 101.593 0.665286 101.983 0.59324C103.292 0.354352 104.556 1.22269 104.797 2.53658C105.038 3.85046 104.167 5.10936 102.854 5.35015C102.588 5.40323 95.85 6.85362 92.3577 15.8821C88.4198 26.0671 81.1773 27.3753 79.0501 27.5345C78.9818 27.5421 78.9136 27.5459 78.8472 27.5459ZM79.1961 27.5137C79.1487 27.5212 79.0994 27.5269 79.052 27.5326C79.1107 27.5269 79.1657 27.5174 79.1961 27.5137Z"
        fill="url(#paint0_linear_11251_457742)"
      />
      <path
        d="M86.3529 8.54033C87.9372 8.54033 89.2215 7.25603 89.2215 5.67178C89.2215 4.08752 87.9372 2.80322 86.3529 2.80322C84.7687 2.80322 83.4844 4.08752 83.4844 5.67178C83.4844 7.25603 84.7687 8.54033 86.3529 8.54033Z"
        fill="#68C184"
      />
      <path
        d="M72.2982 26.3821C73.8825 26.3821 75.1668 25.0978 75.1668 23.5136C75.1668 21.9293 73.8825 20.645 72.2982 20.645C70.714 20.645 69.4297 21.9293 69.4297 23.5136C69.4297 25.0978 70.714 26.3821 72.2982 26.3821Z"
        fill="#68C184"
      />
      <path
        d="M67.3876 21.7989C66.4662 21.816 65.5732 21.3135 65.1352 20.4319C62.6515 15.438 63.3417 9.4355 66.8928 5.13741C70.3585 0.943592 75.7999 -0.872716 81.0914 0.399458C82.4148 0.717976 83.2282 2.04703 82.9115 3.37039C82.593 4.69376 81.264 5.50332 79.9406 5.19049C76.4919 4.36197 72.9484 5.54503 70.6903 8.27708C68.3773 11.0774 67.9279 14.9868 69.5452 18.2402C70.1519 19.4593 69.6551 20.9363 68.436 21.543C68.0986 21.7117 67.7402 21.7951 67.3857 21.8008L67.3876 21.7989Z"
        fill="url(#paint1_linear_11251_457742)"
      />
      <path
        d="M109.023 7.22148C110.607 7.22148 111.891 5.93719 111.891 4.35293C111.891 2.76867 110.607 1.48438 109.023 1.48438C107.439 1.48438 106.154 2.76867 106.154 4.35293C106.154 5.93719 107.439 7.22148 109.023 7.22148Z"
        fill="#68C184"
      />
      <path
        d="M95.6928 23.0906C97.277 23.0906 98.5613 21.8063 98.5613 20.2221C98.5613 18.6378 97.277 17.3535 95.6928 17.3535C94.1085 17.3535 92.8242 18.6378 92.8242 20.2221C92.8242 21.8063 94.1085 23.0906 95.6928 23.0906Z"
        fill="#68C184"
      />
      <path
        d="M103.234 27.3561C102.212 27.3125 101.184 27.157 100.17 26.8821C98.8562 26.5276 98.0788 25.1758 98.4334 23.8619C98.7879 22.548 100.138 21.7726 101.454 22.1252C104.959 23.0694 108.706 21.8598 110.996 19.0405C113.229 16.2895 113.692 12.5811 112.2 9.36554C111.627 8.13129 112.164 6.66762 113.398 6.09505C114.632 5.52248 116.096 6.05903 116.669 7.29328C118.957 12.2322 118.248 17.9219 114.818 22.1461C111.965 25.6592 107.64 27.5457 103.232 27.3542L103.234 27.3561Z"
        fill="url(#paint2_linear_11251_457742)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11251_457742"
          x1="76.1895"
          y1="14.3168"
          x2="105.037"
          y2="13.7831"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#68C184" />
          <stop offset="0.27" stopColor="#53A19B" />
          <stop offset="0.54" stopColor="#4389AD" />
          <stop offset="0.79" stopColor="#3A7BB8" />
          <stop offset="1" stopColor="#3777BC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11251_457742"
          x1="63.4969"
          y1="11.3497"
          x2="83.3671"
          y2="10.3361"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#68C184" />
          <stop offset="0.27" stopColor="#53A19B" />
          <stop offset="0.54" stopColor="#4389AD" />
          <stop offset="0.79" stopColor="#3A7BB8" />
          <stop offset="1" stopColor="#3777BC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11251_457742"
          x1="98.5927"
          y1="16.2976"
          x2="117.896"
          y2="16.8705"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#68C184" />
          <stop offset="0.27" stopColor="#53A19B" />
          <stop offset="0.54" stopColor="#4389AD" />
          <stop offset="0.79" stopColor="#3A7BB8" />
          <stop offset="1" stopColor="#3777BC" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const BellSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={19} fill="none">
    <path
      stroke="#68C184"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12.372 13.458h3.959l-1.112-1.112a1.608 1.608 0 0 1-.472-1.137v-2.5a4.752 4.752 0 0 0-3.166-4.48v-.27a1.583 1.583 0 0 0-3.167 0v.27a4.752 4.752 0 0 0-3.167 4.48v2.5c0 .426-.17.835-.47 1.137l-1.113 1.112h3.958m4.75 0v.792a2.375 2.375 0 1 1-4.75 0v-.792m4.75 0h-4.75"
    />
  </svg>
);
export default BellSvg;

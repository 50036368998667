const ListBgBlue = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={77} height={77} fill="none">
    <rect width={77} height={77} fill="#3777BC" rx={20} />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M25 25a4.5 4.5 0 0 1 4.5-4.5h10.318A4.5 4.5 0 0 1 43 21.818l7.682 7.682A4.5 4.5 0 0 1 52 32.682V52a4.5 4.5 0 0 1-4.5 4.5h-18A4.5 4.5 0 0 1 25 52V25Zm4.5 13.5a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 0 4.5h-13.5a2.25 2.25 0 0 1-2.25-2.25Zm2.25 6.75a2.25 2.25 0 0 0 0 4.5h13.5a2.25 2.25 0 0 0 0-4.5h-13.5Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ListBgBlue;

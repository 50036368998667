export function SaleOrderSvg() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6673 4.38021L8.00065 1.04688L1.33398 4.38021M14.6673 4.38021L8.00065 7.71354M14.6673 4.38021V12.7135L8.00065 16.0469M8.00065 7.71354L1.33398 4.38021M8.00065 7.71354V16.0469M1.33398 4.38021V12.7135L8.00065 16.0469"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import GroupImg from "../../../assets/images/WelcomePageImages/Group.png";
import ReactangleBackGround from "../../../assets/images/WelcomePageImages/bg.png";
import CustomButton from "../../../components/common/Button";
import { IWelcome } from "./types";
import LoginAuthenticationPage from "../../authentication/loginAuthentication";
import { useState } from "react";

export const WelcomLoginPage = ({ footerLabel }: IWelcome) => {
  const naviagte = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div
      className="bg-cont main-container bg-parent w-full bg-cover"
      style={{
        backgroundImage: `url(${ReactangleBackGround})`,
        height: "100%",
      }}
    >
      <div
        className="w-full flex flex-col"
        onClick={() => isOpen && setIsOpen(false)}
      >
        <div className="flex w-full flex-col justify-end h-[45vh]">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={GroupImg} />
          </div>
        </div>
        <div className="p-4 flex w-full flex-col justify-end h-[45vh]">
          <CustomButton
            variant="primary"
            className={"margin-top-4"}
            size="large"
            label={t("welcome.login")}
            onClick={() => setIsOpen(true)}
            datatestid="loginNavigateButton"
          />
          <CustomButton
            label={t("welcome.signup")}
            className={"margin-top-4"}
            size="large"
            variant="secondary"
            onClick={() => naviagte("/signup")}
            datatestid="signupNavigateButton"
          />
        </div>
      </div>
      <div className="h-[5vh] flex flex-col justify-end">
        <span className="font-sans font-thai text-center text-xs font-normal leading-tight tracking-normal">
          Copyright © 2023 Farmtech Ventures All rights reserved
        </span>
      </div>
      {isOpen && <LoginAuthenticationPage isOpen={isOpen} />}
    </div>
  );
};

import { IconButton } from "@mui/material";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppBarFS from "../../../components/app-bar/AppBar";
import Loader from "../../../components/common/Loader";
import BasicDetailTable from "../../../components/common/Table/BasicDetailTable";
import CurrentProgramTable from "../../../components/common/Table/CurrentProgramTable";
import { getHouseDetail } from "../../../services/FarmService";
import FeedProgram from "../../add-house/feeding-program/FeedProgram";
import FeedingContext from "../../add-house/feeding-program/Provider/FeedingContext";
import { CurrentProgramType } from "./CurrentProgram";

const HouseCurrentProgram = () => {
  let { houseId } = useParams();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const language = localStorage.getItem("i18nextLng");
  const navigate = useNavigate();
  const [houseDetails, setHouseDetails] = useState<CurrentProgramType>();
  const [feedData, setFeedData] = useState<any>(null);
  const { feedBrands } = useContext(FeedingContext);
  const tableHeadData = [
    { nameEn: "pig age", nameTh: "อายุหมู" },
    { nameEn: "Vaccine", nameTh: "วัคซีน" },
    { nameEn: "quantity", nameTh: "ปริมาณ" },
  ];

  useEffect(() => {
    (async () => {
      if (houseId && houseId !== "") {
        try {
          const response = await getHouseDetail(houseId);

          if (!response?.data?.success) {
            navigate("/farms");
          }

          const houseDetailResponse = response?.data?.house;
          setHouseDetails(houseDetailResponse);

          let typeOfPig = [],
            totalPig = 0;
          if (houseDetailResponse?.breederPigCount) {
            typeOfPig.push(t("common.breeder_pig"));
            totalPig += houseDetailResponse?.breederPigCount;
          }
          if (houseDetailResponse?.fattenerPigCount) {
            typeOfPig.push(" " + t("common.fattener_pig"));
            totalPig += houseDetailResponse?.fattenerPigCount;
          }

          if (houseDetailResponse?.nurseryPigCount) {
            typeOfPig.push(" " + t("common.nursery_pig"));
            totalPig += houseDetailResponse?.nurseryPigCount;
          }

          let feedBrand: any = {};
          let selectedFeedBrand: any = { feed_brand_items: [] };
          if (
            houseDetailResponse?.houseFeeds &&
            houseDetailResponse?.houseFeeds?.length
          ) {
            feedBrand = houseDetailResponse?.houseFeeds[0].feed_brand;
            selectedFeedBrand = { feed_brand_items: [], ...feedBrand };

            houseDetailResponse?.houseFeeds.forEach((houseFeed: any) =>
              selectedFeedBrand.feed_brand_items.push({
                key: `${houseFeed?.feed_brand_item?.startWeight} - ${houseFeed?.feed_brand_item?.endWeight}`,
                ...houseFeed?.feed_brand_item,
              })
            );
          }
          let vaccines = houseDetailResponse?.houseVaccines?.reduce(
            (result: any, item: any) => {
              item.vaccine.vaccineProgramDetails.forEach((detail: any) => {
                const existingEntry = result.find(
                  (entry: any) =>
                    entry.vaccineProgramId === detail.vaccineProgramId
                );
                const vaccine = {
                  pigAge: item?.pigAge,
                  nameTh: item.vaccine.nameTh,
                  nameEn: item.vaccine.nameEn,
                  quantity: item.vaccine.quantity,
                };

                if (existingEntry) {
                  existingEntry.vaccines.push(vaccine);
                } else {
                  result.push({
                    vaccineProgramId: detail.vaccineProgramId,
                    titleEn: detail.titleEn,
                    titleTh: detail.titleTh,
                    instructionsTh: detail.instructionsTh,
                    instructionsEn: detail.instructionsEn,
                    vaccines: [vaccine],
                  });
                }
              });
              return result;
            },
            []
          );
          let vac = [];
          if (Array.isArray(vaccines))
            vaccines.forEach((data) => {
              if (Array.isArray(data.vaccines)) {
                data.vaccines = data.vaccines.sort(
                  (a: any, b: any) => a.pigAge - b.pigAge
                );
                if (
                  data.vaccines.length ===
                    houseDetailResponse.houseVaccines.length &&
                  vac.length === 0
                ) {
                  vac = data;
                }
              }
            });
          else {
            if (Array.isArray(vaccines.vaccines)) {
              vaccines.vaccines = vaccines.vaccines.sort(
                (a: any, b: any) => a.pigAge - b.pigAge
              );
              if (
                vaccines.vaccines.length ===
                houseDetailResponse.houseVaccines.length
              )
                vac = vaccines;
            } else if (houseDetailResponse.houseVaccines.length === 1)
              vac = [vaccines];
          }
          const inputVac = vac.vaccines?.length > 0 ? [vac] : vaccines;
          vaccines = inputVac;
          setHouseDetails({
            ...houseDetailResponse,
            totalPig,
            typeOfPig,
            selectedFeedBrand,
            feedBrand,
            vaccines,
          });
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [houseId]);
  useEffect(() => {
    const arr = new Array<any>();
    houseDetails?.houseFeeds.forEach((feed) => {
      arr.push({
        brand_id: feed.feed_brand.id,
        consumption: feed.feed_brand_item.consumption,
        endWeight: feed.feed_brand_item.endWeight,
        id: feed.feed_brand_item.id,
        key: feed.feed_brand_item.key,
        protein: feed.feed_brand_item.protein,
        skuEn: feed.feed_brand_item.skuEn,
        skuTh: feed.feed_brand_item.skuTh,
        startWeight: feed.feed_brand_item.startWeight,
        quantity: feed.quantity,
        nameTh: feed.feed_brand.nameTh,
        nameEn: feed.feed_brand.nameEn,
      });
    });
    setFeedData({
      feed_brand_items: arr,
    });
  }, [houseDetails]);
  const instructionsData = (instructionValue: any) => {
    const dataArray = instructionValue?.split("\n");
    const instructionsArray = dataArray.reduce((acc: any, item: any) => {
      const trimmedItem = item.trim();
      if (trimmedItem !== "") {
        const instructionsItem = trimmedItem.replace(/^\d+\s/g, "");
        acc.push({ instructions: instructionsItem });
      }
      return acc;
    }, []);

    return (
      <>
        {instructionsArray &&
          instructionsArray.map((item: any, index: any) => {
            return (
              <div key={index} className="flex">
                <span
                  className="flex-none"
                  style={{
                    height: "22px",
                    width: "5px",
                    backgroundColor: "#DC5B5B",
                    borderRadius: "99px",
                    marginRight: "10px",
                  }}
                ></span>
                <span className="text-[#7A1B1B] text-[14px]">
                  {item?.instructions}
                </span>
              </div>
            );
          })}
      </>
    );
  };
  return (
    <div className="p-4 h-full  flex flex-col justify-between w-full">
      <AppBarFS
        title={t("houseDashboard.currentProgramBlockHeading")}
        onIconPress={() => navigate(-1)}
        endComponent={
          <IconButton
            size="large"
            edge="end"
            aria-label="menu"
            color="primary"
            sx={{ flex: 1, justifyContent: "end" }}
            onClick={() => setIsEdit((editToggle) => !editToggle)}
          >
            {/* <FontAwesomeIcon icon={faPencil} size="xs" /> */}
          </IconButton>
        }
      />
      {loading && <Loader />}
      <div className="flex flex-col grow mt-1 gap-4">
        <div className="py-4 px-4">
          <h3 className="text-[#34407B] text-[20px] font-semibold capitalize">
            {t("programOverview.programOverviewTopTitle", {
              houseName: houseDetails?.name,
            })}
          </h3>
          <h3 className="text-[#747474] text-[14px]">
            {t("programOverview.programOverviewTopText")}
          </h3>
          <div className="text-[#3777BC] text-[px] font-semibold mb-[24px] mt-8">
            {t("programOverview.basicDetailTitle")}
          </div>
          <div className="bg-[#D9EBFE] flex items-center p-[16px] justify-between">
            <h5 className="text-[18px] font-medium">
              {t("programOverview.homeDetailTitle")}
            </h5>
            {isEdit && (
              <button className="add-farms-btn px-[15px] py-[5px] rounded-[40px] border border-green-500 flex text-[#68C184] bg-[#fff]">
                {t("addHouse.editButtonText")}
              </button>
            )}
          </div>
          <BasicDetailTable
            tableData={[
              {
                detailName: t("programOverview.basicTableFarmName"),
                detailValue: houseDetails?.farm?.name || "",
              },
              {
                detailName: t("programOverview.basicTableHouseName"),
                detailValue: houseDetails?.name || "",
              },
              {
                detailName: t("programOverview.basicTableTypeOfPig"),
                detailValue: houseDetails?.typeOfPig || "",
              },
              {
                detailName: t("programOverview.basicTableStartHouseDate"),
                detailValue: houseDetails?.houseStartDate || "",
              },
              {
                detailName: t("programOverview.basicTableAgeOfPig"),
                detailValue: houseDetails?.pigAge || "",
                unit: t("addHouseDetail.days").toLocaleLowerCase(),
              },
              {
                detailName: t("programOverview.basicTableAmountOfPig"),
                detailValue: String(houseDetails?.totalPig) || "",
                unit: t("common.pronoun").toLocaleLowerCase(),
                doFormat: true,
              },
              {
                detailName: t("programOverview.basicTableTotalWeight"),
                detailValue:
                  (houseDetails?.totalPigWeight || 0) /
                    ((houseDetails?.fattenerPigCount || 0) +
                      (houseDetails?.nurseryPigCount || 0) || 1) || "",
                unit: t("common.kg").toLocaleLowerCase(),
                doFormat: true,
              },
              {
                detailName: t("programOverview.basicTableNameTotalPrice"),
                detailValue:
                  (
                    (houseDetails?.totalPigPrice || 0) /
                    (houseDetails?.fattenerPigCount || 1)
                  ).toFixed(2) || "",
                unit: t("common.currency"),
                doFormat: true,
              },
              {
                detailName: t("programOverview.basicTableNamePricePerPig"),
                detailValue: houseDetails?.totalPigPrice || "",
                unit: t("common.currency"),
                doFormat: true,
              },
            ]}
          />
          <div className="mt-6">
            <FeedProgram
              title={t("programOverview.feedProgram")}
              feedData={feedData || {}}
              selectedFeedBrand={houseDetails?.selectedFeedBrand || {}}
              feedBrands={feedBrands}
              totalAmount={
                (houseDetails?.fattenerPigCount
                  ? houseDetails?.fattenerPigCount
                  : 0) +
                (houseDetails?.nurseryPigCount
                  ? houseDetails?.nurseryPigCount
                  : 0)
              }
              addHouse={false}
            />
          </div>
          <>
            {houseDetails?.vaccines?.length > 0 ? (
              <div className="text-[#3777BC] text-[20px] font-semibold mb-[24px] mt-8">
                {t("programOverview.vaccineProgramTitle")}
              </div>
            ) : (
              ""
            )}
            {houseDetails?.vaccines?.length > 0 &&
              houseDetails?.vaccines?.map((item: any, index: number) => {
                return (
                  <div key={index} className="mb-[20px]">
                    <div className="bg-[#D9EBFE] flex items-center p-[16px] pb-[8px] rounded-t-lg justify-between">
                      <h5 className="text-[20px] font-medium">
                        {language === "th" ? (
                          <>{item?.titleTh}</>
                        ) : (
                          <>{item?.titleEn}</>
                        )}
                      </h5>
                      {isEdit && (
                        <button className="add-farms-btn px-[15px] py-[5px] rounded-[40px] border border-green-500 flex text-[#68C184] bg-[#fff]">
                          {t("addHouse.editButtonText")}
                        </button>
                      )}
                    </div>
                    <CurrentProgramTable
                      tableData={item?.vaccines}
                      tableHeadData={tableHeadData}
                    />
                    <div className="bg-[#D9EBFE] rounded-b-lg">
                      <div className="flex items-center justify-between p-[16px]">
                        <h5 className="text-[16px] font-medium px-[3%] text-[#3777BC]">
                          {t("addHouse.tableFooterWeekText")}
                        </h5>
                        <h5 className="px-[6%] text-[16px] font-medium text-[#3777BC]">
                          {t("addHouse.tableFooterCCText")}
                        </h5>
                      </div>
                      <div className="flex flex-col pt-0 p-[16px] gap-[8px]">
                        {language === "th" ? (
                          <>{instructionsData(item?.instructionsTh)}</>
                        ) : (
                          <>{instructionsData(item?.instructionsEn)}</>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </>
        </div>
      </div>
    </div>
  );
};

export default HouseCurrentProgram;

interface PigCounts {
  fattenerPigCount: number | string;
  breederPigCount: number | string;
  nurseryPigCount: number | string;
  totalDeadPigs: number | string;
}

interface UpdatedPigCounts {
  fattenerPigCount: number;
  nurseryPigCount: number;
}

export interface ProcessedPigCounts {
  fattenerPigCount: number;
  nurseryPigCount: number;
  breederPigCount: number;
  totalDeadPigs: number;
  totalAlivePigCount: number;
}

export function processPigCounts(pigCounts: PigCounts): ProcessedPigCounts {
  // Convert string inputs to numbers if necessary
  let fattenerPigCount =
    typeof pigCounts.fattenerPigCount === "string"
      ? parseInt(pigCounts.fattenerPigCount, 10)
      : (pigCounts.fattenerPigCount as number);
  const breederPigCount =
    typeof pigCounts.breederPigCount === "string"
      ? parseInt(pigCounts.breederPigCount, 10)
      : (pigCounts.breederPigCount as number);
  let nurseryPigCount =
    typeof pigCounts.nurseryPigCount === "string"
      ? parseInt(pigCounts.nurseryPigCount, 10)
      : (pigCounts.nurseryPigCount as number);
  let totalDeadPigs =
    typeof pigCounts.totalDeadPigs === "string"
      ? parseInt(pigCounts.totalDeadPigs, 10)
      : (pigCounts.totalDeadPigs as number);

  const actualDeadPigs = totalDeadPigs;

  // Deduct deadPigCount from fattenerPigCount
  if (fattenerPigCount >= totalDeadPigs) {
    fattenerPigCount -= totalDeadPigs;
    totalDeadPigs = 0;
  } else {
    totalDeadPigs -= fattenerPigCount;
    fattenerPigCount = 0;

    // Deduct any remaining deadPigCount from nurseryPigCount
    if (nurseryPigCount >= totalDeadPigs) {
      nurseryPigCount -= totalDeadPigs;
      totalDeadPigs = 0;
    } else {
      totalDeadPigs -= nurseryPigCount;
      nurseryPigCount = 0;
    }
  }

  const updatedCounts: UpdatedPigCounts = {
    fattenerPigCount,
    nurseryPigCount,
  };

  const totalAlivePigCount =
    fattenerPigCount + breederPigCount + nurseryPigCount;

  return {
    ...updatedCounts,
    breederPigCount,
    totalDeadPigs: actualDeadPigs,
    totalAlivePigCount,
  };
}

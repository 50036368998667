import { useMemo, useState } from "react";
import {
  getCategoryMedicine,
  getMedicineMasterData,
} from "../../services/medicine.service";
import {
  CategoryMedicineRow,
  IMedicineTable,
  MedicineRow,
} from "../../types/medicine.type";
import { TUseMedicineFilterHook } from "./useMedicineFilter.hook";

interface ToggleMedicine {
  id: string;
  tradeName: string;
  tradeNameEn: string;
  isActive: boolean;
}

export const useMedicineTableHook = (
  searchMedicineFilter: TUseMedicineFilterHook
) => {
  const [list, setList] = useState<IMedicineTable[]>([]);
  const [categoryMedicine, setCategoryMedicine] =
    useState<CategoryMedicineRow[]>();
  const [filteredFields, setFilteredFields] = useState<ToggleMedicine[]>([]);
  const [medicine, setMedicine] = useState<MedicineRow[] | undefined>([]);

  const [currentScroll, setCurrentScroll] = useState(1);

  const createData = (
    id: string,
    categoryTh: string,
    categoryEn: string,
    medicineTh: string,
    medicineEn: string
  ): IMedicineTable => {
    return {
      id,
      categoryTh: categoryTh ? categoryTh : "-",
      categoryEn: categoryEn ? categoryEn : "-",
      medicineTh: medicineTh ? medicineTh : "-",
      medicineEn: medicineEn ? medicineEn : "-",
    };
  };

  const MapOrderByWithFilter: { [K in keyof IMedicineTable]?: string } = {
    medicineTh: "name",
    medicineEn: "name_en",
    categoryTh: "category_name",
    categoryEn: "category_name_en",
  };

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("searchText", searchMedicineFilter.searchText);
    searchParams.append("medicineCategoryId", searchMedicineFilter.categoryId);
    searchParams.append("page", searchMedicineFilter.page.toString());
    searchParams.append("limit", searchMedicineFilter.limit.toString());
    searchParams.append("direction", searchMedicineFilter.direction);
    searchParams.append(
      "orderBy",
      MapOrderByWithFilter[searchMedicineFilter.orderBy] || ""
    );
    setList([]);

    const data = await getMedicineMasterData(searchParams);
    if (data?.rows) {
      const newList = data.rows.map((row) => {
        return createData(
          row.id,
          row.medicineCategory ? row.medicineCategory.nameTh : "",
          row.medicineCategory ? row.medicineCategory.nameEn : "",
          row.tradeName ? row.tradeName : "",
          row.tradeNameEn ? row.tradeNameEn : ""
        );
      });
      searchMedicineFilter.setCount(data.count);
      setList(newList);
    }
  };

  const handleFetchCategory = async () => {
    const data = await getCategoryMedicine();
    setCategoryMedicine(data?.rows);
  };

  const handleFetchEditData = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("limit", "999");
    searchParams.append("searchText", searchMedicineFilter.searchText);

    const data = await getMedicineMasterData(searchParams);
    setMedicine(data?.rows);
  };

  const tableData = useMemo(() => {
    const listMedicine = medicine || [];
    if (listMedicine.length === 0) return [];

    const pageSize = 10;
    const startIndex = (currentScroll - 1) * pageSize;
    const endIndex = currentScroll * pageSize;
    return listMedicine.slice(startIndex, endIndex);
  }, [medicine, currentScroll]);

  const filterFields = (searchText: string) => {
    if (!searchText) {
      setFilteredFields(medicine || []);
      return;
    }
    const filtered = (medicine || []).filter((med) => {
      const tradeNameTH = med.tradeName ? med.tradeName.toLowerCase() : "";
      const tradeNameEN = med.tradeNameEn ? med.tradeNameEn.toLowerCase() : "";

      return (
        tradeNameTH.includes(searchText.toLowerCase()) ||
        tradeNameEN.includes(searchText.toLowerCase())
      );
    });
    setFilteredFields(filtered);
  };

  return {
    list,
    categoryMedicine: categoryMedicine ? categoryMedicine : null,
    handleFetchCategory,
    handleFetch,
    handleFetchEditData,
    medicine,
    tableData,
    currentScroll,
    setCurrentScroll,
    filterFields,
    filteredFields,
  };
};

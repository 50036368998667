import React, { useEffect, useState } from "react";
import { ChipCount } from "../../../../sale-order/CreditHoldOrderPanel/CreditHoldOrderPanel";
import AcceptDebtTab from "../../components/AcceptDebtTab";
import { TableCell } from "@mui/material";
import { useMoneyDebtFilter } from "../../hooks/useMoneyDebtFilter.hook";
import { ResultAcceptDebt } from "../../types/acceptDebt.type";
import { getAcceptDebtCountSale } from "../../services/acceptDebt.service";

type Props = {};

const AcceptDebt = (props: Props) => {
  const [toggleState, setToggleState] = useState(1);
  const [countTab, setCountTab] = useState<ResultAcceptDebt>();
  const [tabName, setTabName] = useState("following");

  const toggleTab = (index: number, tabName: string) => {
    setToggleState(index);
    setTabName(tabName);
  };

  const getCountAcceptDebt = async () => {
    const data = await getAcceptDebtCountSale("accept_debt");
    setCountTab(data?.result);
  };

  useEffect(() => {
    getCountAcceptDebt();
  }, [toggleState]);

  return (
    <>
      <div className="w-full h-full pt-8">
        <div className="fs-tab-custom-container">
          <div className="fs-tab-custom-bloc-tabs">
            <button
              className={`fs-tab-custom-button ${
                toggleState === 1
                  ? "fs-tab-custom-tabs fs-tab-custom-active-tabs"
                  : "fs-tab-custom-tabs"
              }`}
              onClick={() => toggleTab(1, "following")}
            >
              <div className="flex w-full justify-center gap-3 items-center">
                รายการที่กำลังติดตาม{" "}
                <ChipCount value={countTab?.followingAmount || 0} />
              </div>
            </button>
            <button
              className={`fs-tab-custom-button ${
                toggleState === 2
                  ? "fs-tab-custom-tabs fs-tab-custom-active-tabs"
                  : "fs-tab-custom-tabs"
              }`}
              onClick={() => toggleTab(2, "finished")}
            >
              <div className="flex w-full justify-center gap-3 items-center">
                รายการที่สำเร็จเเล้ว
                <ChipCount value={countTab?.completeAmount || 0} />
              </div>
            </button>
          </div>
        </div>
        <div className="fs-tab-custom-content-tabs">
          <div
            className={
              toggleState === 1
                ? "fs-tab-custom-content  fs-tab-custom-active-content"
                : "fs-tab-custom-content"
            }
          >
            {toggleState === 1 && (
              <AcceptDebtTab tab={tabName} showSelect={true} />
            )}
          </div>
          <div
            className={
              toggleState === 2
                ? "fs-tab-custom-content  fs-tab-custom-active-content"
                : "fs-tab-custom-content"
            }
          >
            {toggleState === 2 && (
              <AcceptDebtTab tab={tabName} showSelect={false} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AcceptDebt;

export default function AssignmentSVG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
    >
      <mask
        id="mask0_5995_22820"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="46"
        height="46"
      >
        <rect width="46" height="46" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5995_22820)">
        <path
          d="M9.58333 40.2513C8.52917 40.2513 7.62674 39.876 6.87604 39.1253C6.12535 38.3746 5.75 37.4721 5.75 36.418V9.58464C5.75 8.53047 6.12535 7.62804 6.87604 6.87734C7.62674 6.12665 8.52917 5.7513 9.58333 5.7513H17.6333C18.0486 4.6013 18.7434 3.67491 19.7177 2.97214C20.692 2.26936 21.7861 1.91797 23 1.91797C24.2139 1.91797 25.308 2.26936 26.2823 2.97214C27.2566 3.67491 27.9514 4.6013 28.3667 5.7513H36.4167C37.4708 5.7513 38.3733 6.12665 39.124 6.87734C39.8747 7.62804 40.25 8.53047 40.25 9.58464V36.418C40.25 37.4721 39.8747 38.3746 39.124 39.1253C38.3733 39.876 37.4708 40.2513 36.4167 40.2513H9.58333ZM13.4167 32.5846H26.8333V28.7513H13.4167V32.5846ZM13.4167 24.918H32.5833V21.0846H13.4167V24.918ZM13.4167 17.2513H32.5833V13.418H13.4167V17.2513ZM23 8.14714C23.4153 8.14714 23.7587 8.01137 24.0302 7.73984C24.3017 7.46832 24.4375 7.12491 24.4375 6.70964C24.4375 6.29436 24.3017 5.95095 24.0302 5.67943C23.7587 5.4079 23.4153 5.27214 23 5.27214C22.5847 5.27214 22.2413 5.4079 21.9698 5.67943C21.6983 5.95095 21.5625 6.29436 21.5625 6.70964C21.5625 7.12491 21.6983 7.46832 21.9698 7.73984C22.2413 8.01137 22.5847 8.14714 23 8.14714Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

import get from "lodash/get"

const DEFAULT_ERROR_MESSAGE = 'มีบางอย่างผิดพลาด'

const getErrorMessage = (error: any) => {
  if (typeof error === 'string') return error
  return get(error, ['data', 'message']) || get(error, 'message') || DEFAULT_ERROR_MESSAGE
}

export const getIsAPIError = (response: any) => {
  const errorStatus = [500, 501, 502, 503, 504, 400, 401, 402, 403, 404]
  const status = get(response, ['status'], '-')
  const isError = errorStatus.includes(status)
  if (isError) return {
    isError: true,
    errorMessage: get(response, ['statusText'])
  }
  return { isError: false, errorMessage: '' }
}

export default getErrorMessage

import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface ILoadingProps {
  isVisible: boolean
  message?: string
  show: () => void
  hide: () => void
}

const initialState: ILoadingProps = {
  isVisible: false,
  message: '',
  show: () => {},
  hide: () => {},
}

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    show: (state, action: PayloadAction<{ message?: string }>) => {
      const { message } = action.payload
      state.isVisible = true
      state.message = message || ''
    },
    hide: (state) => {
      state.isVisible = false
    },
  },
})

export const loadingActions = loadingSlice.actions

export default loadingSlice

import CircleIcon from "@mui/icons-material/Circle";
import { Chip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import AppBarFS from "../../components/app-bar/AppBar";
import CustomButton from "../../components/common/Button";
import HistoryPigCountChart from "../../components/common/HistoryPigCountChart/HistoryPigCountChart";
import HistoryStatusLabel from "../../components/common/HistoryStatusLabel/HistoryStatusLabel";
import BasicDetailTable, {
  IDataTable,
} from "../../components/common/Table/BasicDetailTable";
import { useDayjsLocale } from "../../hooks/useDayjsLocale.hook";
import {
  IMoveOutPigHouseDetail,
  IMoveOutPigSellingHistoryResponse,
} from "../../types/MoveOutPig.type";
import { thousandComma } from "../../utils/common/string";
import "./SellingHistory.styles.scss";

export function SellingHistory() {
  useDayjsLocale();
  const { t } = useTranslation();
  const { state } = useLocation() as {
    state: { from: string; subFrom: string };
  };
  const [historiesDataTable, setHistoriesDataTable] = useState<
    { orderNo: number; moveOutDate: string; dataTable: IDataTable[] }[]
  >([]);
  const { house, histories } = useLoaderData() as {
    house: IMoveOutPigHouseDetail;
    histories: IMoveOutPigSellingHistoryResponse;
  };
  const navigate = useNavigate();
  const [serverError, setserverError] = useState<string>("");

  useEffect(() => {
    if (!house || !histories) return;
    const historiesData: {
      orderNo: number;
      moveOutDate: string;
      dataTable: IDataTable[];
    }[] = [];
    histories.items.forEach((item) => {
      historiesData.push({
        orderNo: item.orderNo,
        moveOutDate: dayjs(item.moveOutDate).format("DD MMMM BBBB"),
        dataTable: [
          {
            detailName: t("sellingHistory.tableHeader.moveOutAmount"),
            detailValue: item.amount,
            unit: t("sellingHistory.unit.pig"),
            doFormat: false,
            color: "#4B4B4B",
            fontSize: "0.875rem",
          },
          {
            detailName: t("sellingHistory.tableHeader.totalWeight"),
            detailValue: thousandComma(item.totalWeightOfPig, 2),
            unit: t("sellingHistory.unit.weight"),
            doFormat: false,
            color: "#4B4B4B",
            fontSize: "0.875rem",
          },
          {
            detailName: t("sellingHistory.tableHeader.totalPrice"),
            detailValue: thousandComma(
              item.totalWeightOfPig * item.pricePerUnit,
              2
            ),
            unit: t("sellingHistory.unit.price"),
            doFormat: false,
            color: "#4B4B4B",
            fontSize: "0.875rem",
          },
          {
            detailName: t("sellingHistory.tableHeader.pricePerUnit"),
            detailValue: thousandComma(item.pricePerUnit, 2),
            unit: t("sellingHistory.unit.price"),
            doFormat: false,
            color: "#4B4B4B",
            fontSize: "0.875rem",
          },
          {
            detailName: t("sellingHistory.tableHeader.saleType"),
            detailValue: t(`moveOutPigSelling.saleType.${item.saleType}`),
            unit: "",
            doFormat: false,
            color: "#4B4B4B",
            fontSize: "0.875rem",
          },
          ...(item.creditDay || item.creditDay === 0
            ? [
                {
                  detailName: t("sellingHistory.tableHeader.creditDay"),
                  detailValue: item.creditDay,
                  unit: t("sellingHistory.unit.day"),
                  doFormat: false,
                  color: "#4B4B4B",
                  fontSize: "0.875rem",
                },
              ]
            : []),
          {
            detailName: t("sellingHistory.tableHeader.purchaseName"),
            detailValue: item.purchaserName ?? "-",
            unit: "",
            doFormat: false,
            color: "#4B4B4B",
            fontSize: "0.875rem",
          },
        ],
      });
    });
    setHistoriesDataTable([...historiesData]);
  }, [house, histories]);
  return (
    <div className="relative w-full bg-[#FFFFF]">
      <div className="h-screen overflow-auto flex flex-col pb-44">
        <div className="p-4 rounded-b-[40px] bg-white">
          <AppBarFS
            onIconPress={() => navigate(state?.from ?? `/farms`)}
            title={t("sellingHistory.titleBar")}
          />
        </div>
        {historiesDataTable.length > 0 ? (
          <div className="flex p-4">
            <div className="flex flex-col grow gap-4 w-full">
              <div className="flex flex-col justify-center w-full">
                <div className="flex whitespace-nowrap justify-center w-full">
                  <HistoryPigCountChart
                    dataset={[
                      {
                        name: "remaining-pigs",
                        value: house.remaining,
                        fill: "#E2E2E2",
                      },
                      {
                        name: "sold-pigs",
                        value: house.movedOutCount,
                        fill: "#F57C00",
                      },
                    ]}
                  />
                </div>
                <div className="flex justify-center">
                  <div className="flex flex-col min-w-[12rem] max-w-[12rem]">
                    <div className="flex whitespace-nowrap justify-between w-full">
                      <HistoryStatusLabel
                        color="error"
                        label={t("sellingHistory.statusLabel.remainingPigs")}
                        icon={
                          <CircleIcon
                            className="text-[#E2E2E2]"
                            sx={{
                              fontSize: "12px",
                            }}
                          />
                        }
                      />
                      <div className="text-sm font-semibold">
                        {thousandComma(house.remaining)}{" "}
                        {t("sellingHistory.unit.pig")}
                      </div>
                    </div>
                    <div className="flex whitespace-nowrap justify-between w-full mt-3">
                      <HistoryStatusLabel
                        color="error"
                        label={t("sellingHistory.statusLabel.soldPigs")}
                        icon={
                          <CircleIcon
                            className="text-[#F57C00]"
                            sx={{
                              fontSize: "12px",
                            }}
                          />
                        }
                      />
                      <div className="text-sm font-semibold">
                        {thousandComma(house.movedOutCount)}{" "}
                        {t("sellingHistory.unit.pig")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {historiesDataTable.map((item, index) => (
                <div
                  key={`table-list-${index}`}
                  className="mb-1 relative filter btg-drop-shadow-table"
                >
                  <div className="bg-[#D9EBFE] flex items-center p-[16px] rounded-t-[20px] justify-between">
                    <Chip
                      label={t("sellingHistory.titleChip", {
                        count: item.orderNo,
                      })}
                      variant="outlined"
                      color="primary"
                      sx={{
                        border: "1px solid #5572EB",
                        backgroundColor: "#DDE4FF",
                        color: "#5572EB",
                        fontSize: "0.875rem",
                        fontWeight: 400,
                      }}
                    />
                    <span className="text-[#4B4B4B] font-medium">
                      {item.moveOutDate}
                    </span>
                  </div>
                  <BasicDetailTable
                    tableData={item.dataTable}
                    tableProps={{
                      size: "medium",
                    }}
                    borderCell="0px !important"
                    isNewFormat={true}
                  />
                  <div className="bg-[#f6faff] rounded-b-[20px] h-[20px]"></div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="w-full h-full">
            <span className="flex h-full self-center justify-center items-center text-[1rem] font-normal text-primary-grey-100">
              {t("sellingHistory.titleNoData")}
            </span>
          </div>
        )}
      </div>
      {historiesDataTable.length > 0 ? (
        <div className="flex flex-col gap-4 px-4 absolute z-10 bottom-0 w-full bg-white">
          <div className="flex flex-col gap-4 my-4 px-4">
            {serverError && (
              <Typography
                variant="body2"
                className="text-[#D32F2F] px-4"
                align="center"
              >
                {serverError}
              </Typography>
            )}
            <CustomButton
              variant="tertiary"
              disabled={house.remaining === null || house.remaining > 0}
              label={t("sellingHistory.button.goViewReport")}
              onClick={() => {
                navigate(`/house/selling-report/${house.houseId}`, {
                  state: {
                    from: `/house/selling-history/${house.houseId}`,
                    subFrom: state?.subFrom,
                  },
                });
              }}
            />
            <CustomButton
              variant="secondary"
              disabled={false}
              label={t("sellingHistory.button.goMain")}
              onClick={() => {
                navigate(`/farms`);
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

import React, { useContext, useRef, useState } from "react";
import bulbIcon from "../../../assets/images/feed/Icon.png";
import pigIcon from "../../../assets/images/feed/pig.svg";
import scrollImg from "../../../assets/images/feed/ScrollBar.png";
import { ReactComponent as PigBarLight } from "../../../assets/images/feed/pig_bar_light.svg";
import { ReactComponent as PigBarMed } from "../../../assets/images/feed/pig_bar_med.svg";
import { ReactComponent as PigBarDark } from "../../../assets/images/feed/pig_bar_dark.svg";

import { Grid } from "@mui/material";
import { Slider, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import FeedingContext from "./Provider/FeedingContext";
import { color } from "@mui/system";
import { getCommaNumber } from "../../../utils/number";

const GrowJourney = (props: any) => {
  const { selectedFeedBrand, feedBrands, feedData, totalAmount } = props;
  const { t } = useTranslation();
  const [scrollValue, setScrollValue] = useState(0);
  const { finalAddHouseData } = useContext(FeedingContext);

  const scrollerGridRef = useRef(null);
  const getQuantity = (index: number): number => {
    const updatedQuant = finalAddHouseData?.feedDetails?.feed_brand_items[index]
      ?.quantity
      ? finalAddHouseData?.feedDetails?.feed_brand_items[index]?.quantity
      : feedData.feed_brand_items[index].quantity;
    const fattener = finalAddHouseData?.basicDetails?.fattenerPig
      ? finalAddHouseData?.basicDetails?.fattenerPig
      : 0;
    const nursery = finalAddHouseData?.basicDetails?.nurseryPig
      ? finalAddHouseData?.basicDetails?.nurseryPig
      : 0;
    const consumtion = feedBrands?.find(
      (ele: any) => ele.id === selectedFeedBrand?.id
    )?.feed_brand_items[index]?.consumption;
    const formula =
      fattener !== 0 || nursery !== 0
        ? (parseInt(fattener) + parseInt(nursery)) * consumtion
        : totalAmount
        ? totalAmount * consumtion
        : 0;
    return updatedQuant || updatedQuant == 0 ? updatedQuant : formula;
  };

  const showingPig = (index: number) => {
    return (
      <div className="flex justify-end flex-col" key={index}>
        <div className="flex items-center flex-col">
          <img
            src={pigIcon}
            style={{ width: 23 + index * 7, height: 13 + index * 7 }}
          />
        </div>
        {index <= 1 && <PigBarLight style={{ width: 62, height: 10 }} />}
        {index <= 3 && index > 1 && (
          <PigBarMed style={{ width: 62, height: 10 }} />
        )}
        {index > 3 && <PigBarDark style={{ width: 62, height: 10 }} />}
        <div className="flex flext-row text-[#3777BC]">
          <span className="text-[12px]">
            &nbsp;{t("feedingProgram.Number")}
          </span>
          <div className="flex items-center h-[15px] w-[15px] rounded-full text-center text-[12px] border-[1px] border-blue-500">
            <p>&nbsp;{index + 1}</p>
          </div>
        </div>
      </div>
    );
  };
  const handleScroll = (e: any) => {
    const target: any = scrollerGridRef.current;
    target.scrollLeft = (target.scrollWidth / 100) * e.target.value - 125;
    setScrollValue(e.target.value);
  };
  return (
    <div className="bg-[#FFFFFFCC] py-4 rounded-md w-full">
      <div className="flex gap-3 pl-3">
        <img src={bulbIcon} alt="bulb-icon" />
        <h4 className="text-base text-[#3777BC] font-bold">
          {t("feedingProgram.recommendationsForEachNo")}
        </h4>
      </div>
      <div className="">
        <Grid container spacing={2} className="px-3 pt-6">
          <Grid item xs={2.5}>
            <div
              className="h-full flex flex-col justify-end mt-[-30px]"
              style={{ marginTop: "-10px" }}
            >
              <div className="text-[#3777BC] font-medium text-[10px] text-center">
                <p>{t("feedingProgram.averageWeightOfPig")}</p>
              </div>
              <div className="text-[#3777BC] font-medium text-[10px] pt-1">
                <p className="text-center">
                  {t("feedingProgram.totalConsumption")}
                </p>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={9.5}
            ref={scrollerGridRef}
            className="overflow-x-hidden pb-4"
            id="feed-program"
          >
            <div className="pb-5 flex flex-row p-2">
              {feedData?.feed_brand_items?.map((elm: any, i: number) =>
                showingPig(i)
              )}
            </div>
            <div className="flex w-fit border-[#3777BC] border rounded-xl p-1">
              {feedData?.feed_brand_items?.map(
                (element: any, index: number) => (
                  <div
                    className={`border-[#3777BC]  text-xs flex flex-col text-black font-normal items-center ${
                      index % 2 !== 0 && "bg-white"
                    } `}
                    key={index}
                  >
                    <div className="border-b border-[#3777BC]  w-[62px]">
                      <p className="py-2 text-center text-sm">
                        {element?.startWeight
                          ? `${element?.startWeight} - ${element?.endWeight}`
                          : element.key}
                      </p>

                      {/* <p className='py-2 text-center text-sm'>{element?.startWeight}-{element?.endWeight}</p> */}
                    </div>
                    <div className="w-[63px]">
                      <p className="py-2 text-center text-sm">
                        {getQuantity(index) !== 0
                          ? getCommaNumber(getQuantity(index))
                          : "-"}
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>
            {/* <button className="absolute top-1/2 transform -translate-y-1/2 bg-gray-300 border-none text-white text-lg cursor-pointer"></button> */}
          </Grid>
          <div className="flex flex-row justify-end w-[100%] p-2">
            <Slider
              sx={{
                width: "70%",
                "& .MuiSlider-thumb": {
                  borderRadius: "10px",
                  backgroundImage: `url(${scrollImg})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  color: "unset !important",
                  width: 40,
                },
                "& .MuiSlider-rail": {
                  color: "#E2E2E2 !important",
                },
                "& .MuiSlider-track": {
                  backgroundColor: "grey !important",
                  border: "1px solid grey !important",
                },
                "& .MuiSlider-valueLabelLabel": {
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "unset",
                  background: "unset",
                },
              }}
              defaultValue={0}
              value={scrollValue}
              onChange={handleScroll}
              aria-label="Disabled slider"
            />
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default GrowJourney;

import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  getPartnerIncomingStockById,
  putStockConfirmReceive,
} from "../../../../services/partner/stock.service";

export const useProveShippingController = (stockId: string) => {
  const navigate = useNavigate();
  const [images1, setImages1] = useState<File[]>([]);
  const [images2, setImages2] = useState<File[]>([]);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const [isShowSummary, setIsShowSummary] = useState(false);

  const {
    data: stockDetail,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["order", "detail", stockId],
    enabled: !!stockId,
    queryFn: async () => {
      const result = await getPartnerIncomingStockById(stockId);
      return result;
    },
  });
  const stockRefillId = stockDetail?.stockRefill.id;
  const onBack = useCallback(() => {
    navigate(-1);
  }, []);

  const onShowConfirmShipped = useCallback(() => {
    setIsShowConfirmModal(true);
  }, []);

  const onCloseConfirmShipped = useCallback(() => {
    setIsShowConfirmModal(false);
  }, []);

  const onShowSummary = useCallback(async () => {
    await putStockConfirmReceive(stockRefillId!);
    onCloseConfirmShipped();
    setTimeout(() => {
      setIsShowSummary(true);
    }, 400);
  }, [stockRefillId]);

  const onCloseSummary = useCallback(() => {
    navigate("/stock");
  }, []);

  const onAddImage = useCallback(
    (type: "image1" | "image2") => (images: File[]) => {
      if (type === "image1") {
        const maxFile = 2;
        setImages1((prev) => [...images, ...prev].slice(0, maxFile));
      } else if (type === "image2") {
        const maxFile = 1;
        setImages2((prev) => [...images, ...prev].slice(0, maxFile));
      }
    },
    []
  );

  const onRemoveImage = useCallback(
    (type: "image1" | "image2", index: number) => () => {
      if (type === "image1") {
        setImages1((prev) => prev.filter((_, i) => i !== index));
      } else if (type === "image2") {
        setImages2((prev) => prev.filter((_, i) => i !== index));
      }
    },
    []
  );

  return {
    onBack,
    images1,
    setImages1,
    images2,
    setImages2,
    isShowConfirmModal,
    onShowConfirmShipped,
    onCloseConfirmShipped,
    isShowSummary,
    onShowSummary,
    onCloseSummary,
    onRemoveImage,
    onAddImage,
    stockDetail,
  };
};

import { useFormik } from "formik";
import { object, string, number } from "yup";
import TextInputFS from "../../components/common/Input/TextInput";
import {
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { formError, formErrorMessage } from "../../utils/form-validator";
import dayjs, { Dayjs } from "dayjs";
import {
  alphaNumericRegExpforen,
  alphaNumericRegExpforth,
  alphaNumericSpaceRegExpforen,
  alphaNumericSpaceRegExpforth,
  numericRegExp,
} from "../../const/regex-pattern.constant";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { getCurrentLocation } from "../../services/GeoLocation";
import { District, Province, SubDistrict } from "../../services/Geography.type";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/common/Button";
import i18n from "i18next";
import {
  validateFarmName,
  validateNumiric,
} from "../../components/common/validations";

interface AddFarmAddressProps {
  provinces: Province[];
  districts: District[];
  subDistricts: SubDistrict[];
  zipCode?: string;
  formState?: any;
  onProvinceChange?: (provinceId: number) => void;
  onDistrictChange?: (districtId: number) => void;
  onSubDistrictChange?: (subDistrictId: number) => void;
  onSubmit?: (form: any) => Promise<any>;
  onAddressSuccess?: (form: any) => void;
}
export default function AddFarmAddress({
  provinces,
  districts,
  subDistricts,
  zipCode,
  formState,
  onSubmit: onFormSubmit,
  onProvinceChange,
  onSubDistrictChange,
  onDistrictChange,
  onAddressSuccess,
}: AddFarmAddressProps) {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const currentLanguage = i18n.language;

  const [serverError, setServerError] = useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
      gorSorNumber: "",
      gpsLocation: "",
      latitude: null,
      longitude: null,
      address: "",
      subDistrictCode: "",
      districtCode: "",
      provinceCode: "",
      postalCode: "",
    },
    validationSchema: object({
      name: string()
        .required(`${t("add_farm_details.farm_name_required_error_message")}`)
        // .matches(alphaNumericSpaceRegExp, 'Invalid Farm Name')
        .test(
          `${t("add_farm_details.farm_name_invalid_error")}`,
          (value: string, ctx: any) => {
            return !validateFarmName(value)
              ? ctx.createError({
                  message: `${t("add_farm_details.farm-name_invalid_message")}`,
                })
              : true;
          }
        )
        .max(15, `${t("add_farm_details.farm_name_length_error_message")}`),
      gorSorNumber: string()
        .optional()
        .test(
          `${t("add_farm_details.gor_sor_invalid_error")}`,
          (value: string | undefined, ctx: any) => {
            if (value !== undefined) {
              return !validateNumiric(value)
                ? ctx.createError({
                    message: `${t("add_farm_details.gor_sor_invalid_error")}`,
                  })
                : true;
            }
            return true;
          }
        ),
      gpsLocation: string().optional(),
      latitude: number().optional().nullable(),
      longitude: number().optional().nullable(),
      address: string().required(
        `${t("add_farm_details.address_required_error_message")}`
      ),
      subDistrictCode: string().required(
        `${t("add_farm_details.sub_district_required_error_message")}`
      ),
      districtCode: string().required(
        `${t("add_farm_details.district_required_error_message")}`
      ),
      provinceCode: string().required(
        `${t("add_farm_details.province_required_error_message")}`
      ),
      postalCode: string()
        .required(`${t("add_farm_details.postal_code_required_error_message")}`)
        .min(5, `${t("add_farm_details.invalid_postal_message_min")}`)
        .max(5, `${t("add_farm_details.invalid_postal_message_max")}`)
        .matches(
          numericRegExp,
          `${t("add_farm_details.invalid_postal_message")}`
        ),
    }),

    onSubmit: async (values: any) => {
      try {
        const resp = await onFormSubmit?.(values);
        if (resp?.data?.success) {
          formik.setFieldError(
            "name",
            `${t("add_farm_details.farm_name_used_error")}`
          );
          setServerError(`${t("add_farm_details.farm_location_used_error")}`);
          markFormErrors();
        } else {
          onAddressSuccess?.(values);
        }
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
  });

  useEffect(() => {
    setInitialState();
  }, [formState]);

  async function setInitialState() {
    if (formState && formState?.name) {
      await formik.setValues(
        {
          ...formState,
        },
        true
      );
      if (formState?.latitude && formState?.longitude) {
        await formik.setFieldValue(
          "gpsLocation",
          `${formState?.latitude},${formState?.longitude}`,
          true
        );
      }
      setTimeout(async () => {
        await formik.validateForm();
      }, 10);
    }
  }

  function handleFormChanges() {
    if (serverError) {
      setServerError("");
    }
  }

  function markFormErrors() {
    const addressFarmFields = [
      "address",
      "subDistrictCode",
      "districtCode",
      "provinceCode",
      "postalCode",
      "address",
    ];
    addressFarmFields?.forEach((field) => {
      formik.setFieldError(field, " ");
    });
  }

  useEffect(() => {
    // Reset when drop downs are changed
    if (zipCode) {
      formik.setFieldValue("postalCode", zipCode, true);
      formik.validateField("postalCode");
    }
  }, [zipCode]);

  async function handleLocation() {
    try {
      const { latitude, longitude } = await getCurrentLocation();
      formik.setFieldValue("gpsLocation", `${latitude},${longitude}`);
      formik.setFieldValue("latitude", latitude);
      formik.setFieldValue("longitude", longitude);
    } catch (err) {
      console.error(err);
    }
  }

  function handleProvinceChange(event: any) {
    formik.handleChange(event);
    const { value } = event.target;
    if (value) {
      onProvinceChange?.(value);
      formik.setFieldValue("districtCode", "");
      formik.setFieldValue("subDistrictCode", "");
      formik.setFieldValue("postalCode", "");
    }
    handleFormChanges();
  }

  function handleDistrictChange(event: any) {
    formik.handleChange(event);
    const { value } = event.target;
    if (value) {
      onDistrictChange?.(value);
      formik.setFieldValue("subDistrictCode", "");
      formik.setFieldValue("postalCode", "");
    }
    handleFormChanges();
  }

  function handleSubDistrictChange(event: any) {
    formik.handleChange(event);
    const { value } = event.target;
    if (value) {
      onSubDistrictChange?.(value);
    }
    handleFormChanges();
  }

  return (
    <form
      onChange={handleFormChanges}
      onSubmit={formik.handleSubmit}
      className="flex flex-col grow gap-4 justify-between"
    >
      <div className="flex flex-col gap-8">
        <TextInputFS
          helperText={formErrorMessage(formik, "name")}
          error={formError(formik, "name")}
          placeholder={t("add_farm_details.farm_name_placeholder") || ""}
          label={t("add_farm_details.farm_name_label")}
          {...formik.getFieldProps("name")}
        />

        <TextInputFS
          helperText={formErrorMessage(formik, "gorSorNumber")}
          error={formError(formik, "gorSorNumber")}
          placeholder={t("add_farm_details.gor_sor_number_placeholder") || ""}
          label={t("add_farm_details.gor_sor_number_label")}
          {...formik.getFieldProps("gorSorNumber")}
        />

        <TextInputFS
          helperText={
            formErrorMessage(formik, "gpsLocation") ||
            `${t("addFarmAddress.gpsLocationHelperText")}`
          }
          error={formError(formik, "gpsLocation")}
          placeholder={t("addFarmAddress.gpsLocationPlaceholder") || ""}
          {...formik.getFieldProps("gpsLocation")}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleLocation}>
                  <MyLocationIcon color="info" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextInputFS
          helperText={formErrorMessage(formik, "address")}
          error={formError(formik, "address")}
          placeholder={t("addFarmAddress.addressPlaceholder") || ""}
          label={t("addFarmAddress.addressLabel")}
          {...formik.getFieldProps("address")}
        />

        <TextInputFS
          select
          fullWidth
          helperText={formErrorMessage(formik, "provinceCode")}
          error={formError(formik, "provinceCode")}
          placeholder={t("addFarmAddress.provincePlaceholder") || ""}
          id="provinceCode"
          name="provinceCode"
          label={t("addFarmAddress.provinceLabel")}
          value={formik?.values?.provinceCode}
          onChange={(event) => handleProvinceChange(event)}
        >
          {provinces?.map((option: Province) => (
            <MenuItem key={option?.code} value={option?.code}>
              {currentLanguage === "th" ? option?.nameTh : option?.nameEn}
            </MenuItem>
          ))}
        </TextInputFS>

        <TextInputFS
          select
          fullWidth
          helperText={formErrorMessage(formik, "districtCode")}
          error={formError(formik, "districtCode")}
          placeholder={t("addFarmAddress.districtPlaceholder") || ""}
          label={t("addFarmAddress.districtLabel")}
          id="districtCode"
          name="districtCode"
          value={formik?.values?.districtCode}
          onChange={handleDistrictChange}
        >
          {districts?.map((option: District) => (
            <MenuItem key={option.code} value={option.code}>
              {currentLanguage === "th" ? option.nameTh : option.nameEn}
            </MenuItem>
          ))}
        </TextInputFS>

        <TextInputFS
          select
          fullWidth
          helperText={formErrorMessage(formik, "subDistrictCode")}
          error={formError(formik, "subDistrictCode")}
          placeholder={t("addFarmAddress.subDistrictPlaceholder") || ""}
          label={t("addFarmAddress.subDistrictLabel")}
          id="subDistrictCode"
          name="subDistrictCode"
          value={formik?.values?.subDistrictCode}
          onChange={handleSubDistrictChange}
        >
          {subDistricts?.map((option: SubDistrict) => (
            <MenuItem key={option.code} value={option.code}>
              {currentLanguage === "th" ? option.nameTh : option?.nameEn}
            </MenuItem>
          ))}
        </TextInputFS>

        <TextInputFS
          helperText={formErrorMessage(formik, "postalCode")}
          error={formError(formik, "postalCode")}
          placeholder={t("addFarmAddress.postalCodePlaceholder") || ""}
          label={t("addFarmAddress.postalCodeLabel")}
          inputProps={{
            maxLength: 5,
            readOnly: true,
          }}
          {...formik.getFieldProps("postalCode")}
        />
      </div>
      <div className="flex flex-col gap-4 mb-4">
        {serverError && (
          <Typography
            variant="body2"
            className="text-[#D32F2F] px-4"
            align="center"
          >
            {serverError}
          </Typography>
        )}
        <CustomButton
          variant="tertiary"
          disabled={!formik?.isValid || formik.isSubmitting}
          label={t("addFarmAddress.nextButtonLabel")}
        />
      </div>
    </form>
  );
}

import DownloadLinkWithDelete from "./DownloadLinkWithDelete";

interface ListOfFileProps {
  files: File[];
  onDownload?: (row: File) => Promise<void>;
  onDelete?: (idx: number) => Promise<void>;
}

function ListOfFile({ files, onDownload, onDelete }: ListOfFileProps) {
  return files.map((row, idx) => {
    return (
      <div key={idx}>
        <DownloadLinkWithDelete
          onDownload={async () => onDownload && onDownload(row)}
          onDelete={() => onDelete && onDelete(idx)}
          hideDeleteBtn={!onDelete}
          name={row.name}
        />
      </div>
    );
  });
}

export default ListOfFile;

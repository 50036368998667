import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { tw } from "../../../../../utils/tw";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import CrossIcon from "../../../../assets/svg/CrossIcon.svg";
import {
  TFormTabFarmer,
  defaultFormTabFarmer,
} from "../credit-tab/TabFarmer/DefaultFormTabFarmer";
import TextAreaCustom from "../../../../components/Input/TextAreaCustom";
import ButtonOutlinedCustom from "../../../../components/Button/ButtonOutlinedCustom";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import { Dropdown } from "../../../group-area/views/Dropdown";

const classes = {
  containerModal: tw(
    `w-[100vw] !max-w-[612px] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary`
  ),
  container: tw(`flex flex-col gap-6`),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold text-[#191919]`),
  rowContainer: tw(`grid grid-cols-4 gap-3`),
  text: tw(`font-sans font-bold text-[16px]`),
};

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

interface IProps {
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
  form: TFormTabFarmer;
  setForm: Dispatch<SetStateAction<TFormTabFarmer>>;
  onChangeForm: (key: keyof TFormTabFarmer) => (value: any) => void;
  header?: string;
  title?: string;
  isOpenSelectTime?: boolean;
  errorText?: any;
  resetError?: () => void;
}

const ModalReason = (props: IProps) => {
  const {
    isOpen,
    onSubmit,
    onClose,
    form,
    setForm,
    onChangeForm,
    header,
    title,
    isOpenSelectTime,
    errorText,
    resetError,
  } = props;

  const onCloseFn = useCallback(() => {
    onClose();
    setForm(defaultFormTabFarmer);
    resetError && resetError();
  }, []);

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.containerModal}
      classes={{ paper: "w-[100vw] !max-w-[612px]" }}
    >
      <DialogContent>
        <div className={classes.container}>
          <div className={classes.titleContainer}>
            <p className={classes.title}>{header || ""}</p>
            <IconButton onClick={onCloseFn}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className="flex flex-col gap-6">
            {isOpenSelectTime && (
              <Dropdown
                label="ระยะเวลา"
                options={[
                  { label: "30 วัน", value: "30" },
                  { label: "60 วัน", value: "60" },
                  { label: "90 วัน", value: "90" },
                ]}
                onChange={onChangeForm("timeDate")}
                renderValueArray={form.timeDate}
                placeholder="เลือก"
                errorMessage={errorText?.timeDate}
              />
            )}
            <div className="flex flex-col">
              <span className="font-medium text-[#191919] mb-[6px] text-[14px] font-sans">
                {title || ""}
              </span>
              <TextAreaCustom
                placeholder="กรอก"
                value={form.tabFifth_reason}
                onChange={(e: any) =>
                  onChangeForm("tabFifth_reason")(e.target.value)
                }
                errormessage={errorText?.tabFifth_reason}
              />
              <div className="w-full flex justify-end pt-[2px]">
                <span className="font-normal text-[12px] text-[#979797]">
                  (maximum 500 characters)
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-end gap-[10px]">
            <ButtonOutlinedCustom
              title={"ยกเลิก"}
              btnTextColor="#68C184"
              btnBorderColor="#68C184"
              onClick={onCloseFn}
            />
            <ButtonFillCustom title={"ยืนยัน"} onClick={onSubmit} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(ModalReason);

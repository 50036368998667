import { LoaderFunctionArgs } from "react-router-dom";
import { getReportDetail } from "../../../services/FarmerReport.service";

export async function reportDetailLoader(loader: LoaderFunctionArgs) {
  const { request, params } = loader;
  const farmId = params["farmId"] ?? "";
  const orderId = params["orderId"] ?? "";
  const reportOrder = await getReportDetail(orderId);
  return {
    farmId,
    orderId,
    reportOrder,
  };
}

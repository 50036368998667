import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import { Avatar, Paper } from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import { useState } from "react";
import { tw } from "../../../../../../utils/tw";
import { InvoiceDebtLogRow } from "../../types/acceptDebt.type";
import MoneyDebtLogModal from "../MoneyDebtLogModal";

type Props = {
  invoiceLog: InvoiceDebtLogRow;
  index: number;
  countIndex: number;
};

const classes = {
  header: tw("font-bold text-[#3777BC]"),
  label: tw(
    `text-secondary leading-[14px] tracking-[-5%] font-medium underline hover:brightness-110 cursor-pointer ml-2`
  ),
};

const TimelineItemMoneyDebt = ({ invoiceLog, index, countIndex }: Props) => {
  const [isOpenLogModal, setIsOpenLogModal] = useState<boolean>(false);

  const MapEnumLogType = (invoiceLog: InvoiceDebtLogRow) => {
    switch (invoiceLog?.logType) {
      case "accept_debt":
        return (
          <>
            <span className={classes.header}>รับสภาพนี้</span>
            {` เลข Invoice
            ${invoiceLog.invoiceNumber} `}
            <button
              className={classes.label}
              onClick={() => setIsOpenLogModal(true)}
            >
              ดูรายละเอียด
            </button>
          </>
        );
      case "close":
        return (
          <>
            <span className={classes.header}>ปิดเคส</span>
            {` เลข Invoice
            ${invoiceLog.invoiceNumber} `}
            <button
              className={classes.label}
              onClick={() => setIsOpenLogModal(true)}
            >
              ดูรายละเอียด
            </button>
          </>
        );
      case "update":
        return (
          <>
            <span className={classes.header}>อัปเดตข้อมูล</span>
            {` เลข Invoice
            ${invoiceLog.invoiceNumber} `}
            <button
              className={classes.label}
              onClick={() => setIsOpenLogModal(true)}
            >
              ดูรายละเอียด
            </button>
          </>
        );

      default:
        return "-";
    }
  };

  return (
    <div className="py-2">
      <Timeline
        sx={{
          padding: "2px 12px",
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        <TimelineItem key={`item-label-${index + 1}`}>
          <TimelineSeparator>
            <TimelineDot
              sx={{
                bgcolor: "#3777BC",
                boxShadow: "unset",
              }}
            />
            {countIndex - 1 > index && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <div className="flex">
              <div className="w-[100px]">
                <span className="text-[14px] font-normal text-[#1B2128] font-sans ">
                  {dayjs(invoiceLog.createdAt).format("hh:mm A")}
                </span>
              </div>
              <div>
                <Paper
                  elevation={0}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    bgcolor: "#FBFBFB",
                    border: "1px solid #EBEBEB",
                    width: "1000px",
                    height: "100%",
                    borderRadius: "20px",
                    padding: "12px",
                  }}
                >
                  <Avatar
                    alt="user-avatar"
                    src="https://i.pravatar.cc/300"
                    sx={{
                      width: "25px",
                      height: "25px",
                      border: "1px solid #D9D9D9",
                    }}
                  />
                  <span className="font-bold font-sans text-[1rem] text-[#1B2128]">
                    {invoiceLog.user?.name}
                  </span>
                  <span className="font-bold font-sans text-[1rem] text-[#1B2128]">
                    {invoiceLog.user?.surname}
                  </span>
                  <span>{MapEnumLogType(invoiceLog)}</span>
                </Paper>
              </div>
            </div>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
      <MoneyDebtLogModal
        isOpen={isOpenLogModal}
        handleClose={() => setIsOpenLogModal(false)}
        logDetail={invoiceLog?.logDetail}
      />
    </div>
  );
};

export default TimelineItemMoneyDebt;

import { Button, ButtonProps } from "@mui/material";

export interface IButtonLinkProps extends ButtonProps {
  title: string;
}
export function ButtonLink({ title, ...rest }: IButtonLinkProps) {
  return (
    <Button {...rest}>
      <div className="underline">{title}</div>
    </Button>
  );
}

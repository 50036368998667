import {
  IPartnerNewOrderListResponse,
  TOrderPartnerStatusEnum,
} from "../../../../types/ParnerOrder.type";
import { IOrderItemProps } from "../../views/OrderItem";

export const getStatusFromPartnerStatus = (
  partnerStatus: TOrderPartnerStatusEnum
) => {
  const status = {
    new_order: "new",
    to_be_delivery: "ready-for-ship",
    being_delivery: "shipping",
    delivery_success: "shipped",
    accept_success: "received",
  };

  return status[partnerStatus];
};

export const dtoOrderList = (
  data: IPartnerNewOrderListResponse
): IOrderItemProps[] => {
  return data.orderFeeds.map(
    ({
      id,
      orderNumber,
      orderStatus,
      partnerStatus,
      createdAt,
      shippingDate,
      receiveDate,
      shippingType,
      address,
      orderFeedItems,
      orderFeedFiles,
    }) => {
      return {
        id,
        orderNo: orderNumber,
        receivedDate: receiveDate,
        orderDate: createdAt,
        expectReceivedDate: shippingDate,
        shippingType,
        address,
        status:
          orderStatus === "canceled"
            ? "canceled"
            : getStatusFromPartnerStatus(partnerStatus),
        orderStatus,
        partnerStatus,
        items: orderFeedItems.map(
          ({ id, feedAmount, feedUnit, feedBrand, feedBrandItem }) => ({
            id,
            brandName: feedBrand.nameTh,
            brandNo: feedBrandItem.skuTh,
            quantity: feedAmount,
            quantityUnit: feedUnit,
          })
        ),
        images: orderFeedFiles.map(
          ({ id, fileName, url, fileSize, documentType }) => ({
            id,
            fileName,
            url,
            fileSize,
            documentType,
          })
        ),
      } as IOrderItemProps;
    }
  );
};

import {
  Dialog,
  DialogContent,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import CrossIcon from "../../../assets/svg/CrossIcon.svg";
import { ILog } from "../../../../types/admin/user-management/partner";
import { useMemo } from "react";
import dayjs from "dayjs";
import { getCommaNumber } from "../../../../utils/number";
import { tw } from "../../../../utils/tw";
import TableCustom from "../../../components/Table/TableCustom";
import { useQuery } from "@tanstack/react-query";
import { getRevenueChangeLog } from "../../../../services/admin/user-management/partner.service";

type RevenueChangeLogModalProps = {
  isOpen: boolean;
  onClose: () => void;
  type: "onboard" | "order_feed" | "invoice";
  selectedRow: string;
};

const classes = {
  container: tw(`min-h-[240px]`),
  emptyTable: tw(
    `font-sans text-center w-full text-[#979797] text-[14px] py-3`
  ),
};

const getHeaderItems = (type: "onboard" | "order_feed" | "invoice") => [
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "วันที่",
    width: "auto",
    isSorting: false,
    className: "!whitespace-pre !w-[50%] !border-l !border-[#E1E1E1]",
  },
  {
    id: "typeRevenue",
    numeric: false,
    disablePadding: false,
    label:
      type === "onboard"
        ? "รายได้ (บาท/คน)"
        : type === "order_feed"
        ? "เปอร์เซ็นต์ยอดขาย"
        : type === "invoice"
        ? "เปอร์เซ็นต์เก็บหนี้"
        : "-",
    width: "auto",
    isSorting: false,
    className: "!whitespace-pre !w-[50%]",
  },
];

interface ITable {
  data: ILog[];
  headerItems: {
    id: string;
    numeric: boolean;
    disablePadding: boolean;
    label: string;
    width: string;
    isSorting: boolean;
    className: string;
  }[];
}

const TableRevenueChangeLog = (props: ITable) => {
  const { data, headerItems } = props;

  const rowChildren = useMemo(() => {
    return data.map((row, index) => {
      return (
        <TableRow key={row.id + index}>
          <TableCell
            sx={{
              whiteSpace: "pre",
              borderLeft: "1px solid",
              borderColor: "#E1E1E1",
            }}
          >
            {dayjs(row.createdAt).format("DD/MM/YYYY")}
          </TableCell>
          <TableCell
            sx={{
              whiteSpace: "pre",
              borderRight: "1px solid",
              borderColor: "#E1E1E1",
            }}
          >
            {getCommaNumber(row.to)}
          </TableCell>
        </TableRow>
      );
    });
  }, [data]);

  return (
    <div className={classes.container}>
      <TableCustom
        orderBy={""}
        order={"desc"}
        onChangeOrder={() => {}}
        onChangeOrderBy={() => {}}
        totalItemSize={data.length}
        headerItems={headerItems}
        page={1}
        onChangePage={() => {}}
        rowsPerPage={10}
        rows={data}
        EmptyComponent={
          data.length === 0 ? (
            <div className={classes.emptyTable}>ไม่มีข้อมูล</div>
          ) : null
        }
        rowChildren={rowChildren}
        currentPageItems={data.map(({ id }) => id)}
        itemsSelected={[]}
        onSelectAllClick={() => {}}
        onSelectItem={() => {}}
        hidePaginator
        hideSelect
        maxHeightForScroll="530px"
      />
    </div>
  );
};

const RevenueChangeLogModal = ({
  isOpen,
  onClose,
  type,
  selectedRow,
}: RevenueChangeLogModalProps) => {
  const headerItems = getHeaderItems(type);

  const { data: revenueChangeLog } = useQuery({
    queryKey: ["revenue-change-log", selectedRow, type],
    queryFn: async () => {
      const result = await getRevenueChangeLog({
        partnerId: selectedRow,
        revenueType: type,
      });
      return result;
    },
    enabled: isOpen,
  });

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={{
        backdrop: {
          style: { backgroundColor: "#000000", opacity: "10%" },
          timeout: 500,
        },
      }}
    >
      <DialogContent sx={{ width: "764px" }}>
        <div className="flex flex-col gap-6">
          <div className="flex justify-between items-center">
            <p className="text-lg font-bold text-[#191919]">
              {type === "onboard"
                ? "รายได้ลูกค้าใหม่"
                : type === "order_feed"
                ? "เปอร์เซ็นต์รายได้ยอดขาย"
                : type === "invoice"
                ? "เปอร์เซ็นต์รายได้เก็บหนี้"
                : "-"}
            </p>
            <IconButton onClick={onClose}>
              <CrossIcon />
            </IconButton>
          </div>
          <TableRevenueChangeLog
            data={revenueChangeLog?.logs || []}
            headerItems={headerItems}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RevenueChangeLogModal;

import { TextField, TextFieldProps } from "@mui/material";

interface IcustomStylesProps {
  borderRadius?: string;
  textAlign?: string;
  borderColor?: string;
}

export default function TextInputForDatePickerFS(
  props: TextFieldProps & IcustomStylesProps
) {
  const { borderRadius, textAlign, borderColor, ...restProps } = props;
  return (
    <TextField
      {...restProps}
      color="tertiary"
      variant="outlined"
      fullWidth
      InputProps={{
        sx: {
          borderRadius: borderRadius || "28px",
          fontWeight: 500,
          color: "#191919",
          background: "#F7FBFF",
          borderColor: borderColor || "#3777BC",
          textAlign: textAlign || "",
        },
        ...restProps?.InputProps,
      }}
      InputLabelProps={{ shrink: true }}
    />
  );
}

import { Paper } from "@mui/material";
import { IMoveOutPigSellingInfoCardProps } from "./types";

export default function MoveOutPigSellingInfoCard({
  title,
  items,
  bgColor,
}: IMoveOutPigSellingInfoCardProps) {
  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: "20px",
        bgcolor: `${bgColor ?? "#D9EBFE"}`,
        width: "100%",
      }}
    >
      <div className="flex flex-col p-6">
        <div className="text-tertiary font-semibold capitalize text-xl">
          {title}
        </div>
        <div className="flex flex-col grow mt-2 gap-1">
          {items.map((item, index) => (
            <Paper
              key={`info-${index}`}
              elevation={0}
              sx={{
                width: "100%",
                bgcolor: "#FFFFFF",
                padding: "0px 0px",
              }}
            >
              <div className="flex justify-between">
                <div className="flex py-1 px-2">
                  <div className="line-vertical ml-1"></div>
                  <div className="text-tertiary capitalize text-base pl-2">
                    {item.name}
                  </div>
                </div>
                <div className="py-1 px-2 text-tertiary font-semibold capitalize text-base">
                  {item.value}
                  {`${item?.unit ? ` ${item.unit}` : ""}`}
                </div>
              </div>
            </Paper>
          ))}
        </div>
      </div>
    </Paper>
  );
}

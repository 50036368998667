const FarmSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <g clipPath="url(#a)">
      <path
        fill="#68C184"
        d="M22.303 9.742 19.817 5.27a.227.227 0 0 0-.106-.102L11.97 1.341a.266.266 0 0 0-.222 0l-7.74 3.734a.219.219 0 0 0-.102.092L1.04 9.65a.249.249 0 0 0 .204.383l.73.024c.134 0 .24.115.24.25v9.307c0 .767.62 1.386 1.387 1.386h16.248c.768 0 1.387-.62 1.387-1.386v-9.248c0-.139.11-.25.25-.25h.596c.19 0 .31-.203.217-.374h.005ZM11.656 5.837a1.363 1.363 0 1 1 0 2.726 1.363 1.363 0 0 1 0-2.726Zm5.582 11.078c0 .74-.6 1.34-1.34 1.34H7.413c-.74 0-1.34-.6-1.34-1.34v-2.99c0-.74.6-1.34 1.34-1.34h8.48c.74 0 1.34.6 1.34 1.34v2.99h.005Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.539.059h22v22.42h-22z" />
      </clipPath>
    </defs>
  </svg>
);
export default FarmSvg;

import { configureStore } from "@reduxjs/toolkit";
import alertSlice from "./slices/alertSlice";
import clickSlice from "./slices/clickSlice";
import personalSlice from "./slices/exampleSlice";
import loadingSlice from "./slices/loadingSlice";
import snakeSlice from "./slices/snakeSlice";

export const store = configureStore({
  reducer: {
    click: clickSlice.reducer,
    personal: personalSlice.reducer,
    alert: alertSlice.reducer,
    snake: snakeSlice.reducer,
    loading: loadingSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
